import React, { useContext, useRef } from 'react';
import clsx from 'clsx';
import { Grid, Box, Divider } from '@mui/material';
import BasicDetail from './BasicDetail';
import PrintStyles from './PrintStyles';
import ProductDetail from './ProductDetail';
import owareLogo from '../../../assets/icons/O360DarkLogo.svg';
import { SharedContext } from '../../../utils/common';
import moment from 'moment/moment';
import { useReactToPrint } from 'react-to-print';

const PrintDetail = ({
  data,
  productDetails,
  isManufacturer,
  startDate,
  endDate,
  setIsExportPopoverOpen
}) => {
  const classes = PrintStyles();
  const componentRef = useRef();
  const { darkOrganizationLogoFile } = useContext(SharedContext);
  const { currentUser } = useContext(SharedContext);
  const handleClose = () => {
    setIsExportPopoverOpen(false);
  };
  const handlePrint = async () => {
    handlePrintTaskDetails();
  };
  const handlePrintTaskDetails = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <Box
        displayPrint="block"
        className={clsx(classes.pageContainer, classes.root)}
        display="none"
        ref={componentRef}
      >
        <table>
          <thead>
            <tr>
              <td>
                <div className={classes.topTextContainer} />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Box display="none" displayPrint="block">
                  <Grid container spacing={2} className="mb-4">
                    <Grid item xs={6}>
                      {darkOrganizationLogoFile ? (
                        <img
                          src={darkOrganizationLogoFile.location}
                          alt="Logo"
                          style={{
                            width: '100px',
                            height: 'auto',
                            maxHeight: '100px'
                          }}
                        />
                      ) : (
                        <img
                          style={{ width: 'auto', height: '18px' }}
                          src={owareLogo}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mb-5">
                    <Grid item xs={6}>
                      <p className={classes.pageSubHeading}>
                        {' '}
                        {productDetails?.Inventory?.Product.description}
                      </p>
                      <p className={classes.shipmentIdText}>
                        {`SKU ${
                          productDetails?.Inventory?.Product.name || '-'
                        }`}{' '}
                        <span
                          style={{
                            marginLeft: '4px',
                            marginRight: '4px',
                            fontSize: '12px',
                            alignItems: 'center'
                          }}
                        >
                          .
                        </span>{' '}
                        {`UoM ${productDetails?.Inventory?.Product?.UOM?.name}`}
                      </p>
                    </Grid>
                  </Grid>

                  <BasicDetail
                    productDetails={productDetails}
                    isManufacturer={isManufacturer}
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <Divider className={classes.dividerProperties} />
                  <ProductDetail data={data} productDetails={productDetails} />
                </Box>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className={classes.footerSpace}>
                  <Box
                    className={classes.bottomTextContainer}
                    display="none"
                    displayPrint="block"
                  >
                    <div>
                      <p className={classes.bottomText}>
                        {`This is an electronically generated document and does not require a signature.`}
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '7px'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex'
                          }}
                        >
                          <p className={classes.bottomTextNormal}>
                            Printed By:
                          </p>
                          <p
                            className={classes.bottomText}
                          >{` ${currentUser?.firstName} ${currentUser.lastName}`}</p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: '36px'
                          }}
                        >
                          <p className={classes.bottomTextNormal}>
                            Printed Date & Time:
                          </p>
                          <p className={classes.bottomText}>
                            {` ${moment().format('DD/MM/YYYY hh:mm A')}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </Box>
      <div
        className="w-full py-[12px] px-3 items-center text-xs font-normal text-dark800 cursor-pointer hover:bg-dark100"
        onClick={async (e) => {
          e.stopPropagation();
          handleClose();
          await handlePrint();
        }}
      >
        PDF Print
      </div>
    </>
  );
};

export default PrintDetail;

/* eslint-disable */

import * as Yup from 'yup';

export const initialValues = {
  receivedQuantity: '',
  manufacturingDate: null,
  expiryDate: null,
  recoverableDamageQuantity: 0,
  unsellableDamageQuantity: 0,
  promoName: '',
  promoQuantity: 0,
  actualWeight: null
};

export const validationSchema = Yup.object().shape({
  receivedQuantity: Yup.number()
    .required('Received Qty is required')
    .min(0, 'Received Qty must be a positive number')
    .test(
      'receivedQuantity',
      'Received Qty must be greater than sum of Recoverable, Unsellable and Promo Quantity',
      function (value) {
        const {
          recoverableDamageQuantity,
          unsellableDamageQuantity,
          receivedQuantity,
          promoQuantity
        } = this.parent;
        return (
          (recoverableDamageQuantity || 0) +
            (unsellableDamageQuantity || 0) +
            (promoQuantity || 0) <=
          receivedQuantity
        );
      }
    ),
  recoverableDamageQuantity: Yup.number().min(
    0,
    'Recoverable must be a positive number'
  ),
  unsellableDamageQuantity: Yup.number().min(
    0,
    'Unsellable must be a positive number'
  ),
  promoQuantity: Yup.number().when('promoName', {
    is: (value) => !!value,
    then: Yup.number()
      .required('Promo Qty is required')
      .min(0, 'Promo Qty must be a positive number'),
    otherwise: Yup.number()
  })
});

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { debounce } from 'lodash';
import { SharedContext } from '../../../../utils/common';
import { DEBOUNCE_CONST } from '../../../../Config';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import AddProductPopup from '../components/AddProductPopup';
import { Label } from '../../../../core-components/atoms/Text';
import ProductListComponent from '../components/ProductListComponent';
import { useQuery } from 'react-query';
import { ORGANIZATION_TYPES } from '../../../../constants';

const ProductListView = ({
  searchValue,
  products,
  setProducts,
  companyId,
  companyName,
  openAddProduct,
  setOpenAddProduct,
  editColumns
}) => {
  const limit = 15;
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState();
  const { organizationType, currentUser } = useContext(SharedContext);
  const [isHovering, setIsHovering] = useState(false);
  const tableContainerRef = useRef(null);

  const { refetch, isFetching: loading } = useQuery(
    ['fetchProducts'],
    async () => {
      const columns = ['name', 'description', 'barcode', 'perPallet'];
      const filters = {};

      return await API.get(`products`, {
        params: {
          page: page,
          limit: limit,
          search: searchValue,
          columns,
          companyId: companyId,
          filters
        }
      });
    },
    {
      enabled: true,
      onSuccess: (res) => {
        if (res) {
          const newArr = res?.data;
          if (page === 1) {
            setProducts(newArr);
            setPageCount(res?.pages);
          } else {
            setProducts([...products, ...newArr]);
            setPageCount(res?.pages);
          }
        }
      },
      onError: (err) => {
        onError(err);
      }
    }
  );

  const getDelayedProducts = useCallback(
    debounce(async () => {
      refetch();
    }, [DEBOUNCE_CONST]),
    []
  );

  useEffect(() => {
    getDelayedProducts();
  }, [searchValue, page]);

  const handleTableScroll = useCallback(
    debounce(() => {
      const container = tableContainerRef.current;
      if (
        container.scrollTop + container.clientHeight + 15 >=
          container.scrollHeight &&
        !loading &&
        page < pageCount
      ) {
        setPage(page + 1);
      }
    }, 200),
    [loading, page, pageCount]
  );

  useEffect(() => {
    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleTableScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleTableScroll);
      }
    };
  }, [handleTableScroll]);

  useEffect(() => {
    const handleScrolling = (event) => {
      if (tableContainerRef) {
        const tableBottom =
          tableContainerRef.current?.getBoundingClientRect().bottom;
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (isHovering === false && tableBottom + 68 === viewportHeight) {
          tableContainerRef.current.scrollTop += event.deltaY;
        }
      }
    };
    window.addEventListener('wheel', handleScrolling);
    return () => {
      window.removeEventListener('wheel', handleScrolling);
    };
  }, [isHovering, tableContainerRef.current]);

  useEffect(() => {
    if (!openAddProduct) {
      setSelectedProduct(null);
      setOpenAddProduct(false);
    }
  }, [openAddProduct]);

  return (
    <div className="no-scrollbar overflow-hidden w-full">
      {openAddProduct && (
        <AddProductPopup
          open={openAddProduct}
          setOpen={setOpenAddProduct}
          product={selectedProduct}
          setProduct={setSelectedProduct}
          company={{ id: companyId }}
          getProducts={refetch}
          organizationType={organizationType}
          companyName={companyName}
          setPage={setPage}
        />
      )}
      <Paper className="w-full no-scrollbar rounded-t-lg shadow-none">
        <TableContainer
          onMouseEnter={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
          style={{ overflow: 'auto' }}
          ref={tableContainerRef}
          onScroll={handleTableScroll}
          className="no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
        >
          <Table stickyHeader>
            <TableHead className="z-40 relative">
              <TableRow>
                <TableCell key={'image'} className="bg-white py-2 px-6">
                  <Label
                    variant={'xs'}
                    className={'text-dark500 text-[10px] flex'}
                  >
                    Product Image
                  </Label>
                </TableCell>
                <TableCell key={'sku'} className="bg-white py-2 px-6">
                  <Label
                    variant={'xs'}
                    className={'text-dark500 text-[10px] flex'}
                  >
                    SKU Code & Name
                  </Label>
                </TableCell>
                <TableCell key={'brand'} className="bg-white py-2 px-6">
                  <Label
                    variant={'xs'}
                    className={'text-dark500 text-[10px] flex'}
                  >
                    Brand & Category
                  </Label>
                </TableCell>
                <TableCell key={'uom'} className="bg-white py-2 px-6">
                  <Label
                    variant={'xs'}
                    className={'text-dark500 text-[10px] flex'}
                  >
                    UoM(s)
                  </Label>
                </TableCell>
                <TableCell key={'volume'} className="bg-white py-2 px-6">
                  {editColumns.find((val) => val.name === 'Weight & Volume')
                    ?.status && (
                    <Label
                      variant={'xs'}
                      className={'text-dark500 text-[10px] flex'}
                    >
                      Weight & Volume
                    </Label>
                  )}
                </TableCell>
                <TableCell key={'moq'} className="bg-white py-2 px-6">
                  {editColumns.find((val) => val.name === 'MoQ(s)')?.status && (
                    <Label
                      variant={'xs'}
                      className={'text-dark500  text-[10px]flex'}
                    >
                      MoQ(s)
                    </Label>
                  )}
                </TableCell>
                <TableCell key={'cost'} className="bg-white py-2 px-6">
                  {organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
                    editColumns.find((val) => val.name === 'Cost & Selling')
                      ?.status && (
                      <Label
                        variant={'xs'}
                        className={'text-dark500 text-[10px] flex'}
                      >
                        Cost & Selling
                      </Label>
                    )}
                </TableCell>
                <TableCell
                  key={'options'}
                  className="bg-white py-2 px-6 min-w-[100px]"
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.length > 0 &&
                products.map((product, id) => {
                  return (
                    <ProductListComponent
                      data={product}
                      key={id}
                      searchValue={searchValue}
                      getProducts={refetch}
                      onClick={() => {
                        setSelectedProduct(product);
                        setOpenAddProduct(true);
                      }}
                      currentUser={currentUser}
                      editColumns={editColumns}
                    />
                  );
                })}
              {page < pageCount && (
                <TableRow>
                  <TableCell className="py-2 h-12" colSpan={12}>
                    <div className="flex w-full justify-center">
                      <CircularProgress size="14px" />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default ProductListView;

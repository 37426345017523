/*eslint-disable*/

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  dateFormatSlashDivider,
  decimalDisplay,
  SharedContext
} from '../../utils/common';
import API from '../../libs/axios';
import clsx from 'clsx';
import { ORGANIZATION_TYPES } from '../../constants';
import Type from '../../assets/icons/button-pointer.svg';
import Filters from '../reporting/ledgerReports/Filters';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import Batch from '../../assets/icons/Batch.svg';

const InventoryHistory = ({ inventoryId }) => {
  const { setAPILoader, organizationType } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const IS_MANUFACTURER = organizationType === ORGANIZATION_TYPES.MANUFACTURER;

  const filterInitialState = {
    Type: [],
    Batches: []
  };

  const [productData, setProductData] = useState([]);
  const [advancedfilters, setFilters] = useState(filterInitialState);
  const [columnNames] = useState([
    'Date',
    'Type',
    'Created By',
    'Origin & Destination',
    'Batch',
    'Qty'
  ]);

  const inventoryStatus = {
    ...(IS_MANUFACTURER ? { PURCHASE: 'Purchase' } : {}),
    ...(IS_MANUFACTURER ? { SALES: 'Sales' } : {}),
    ...(IS_MANUFACTURER ? { SALES_RETURN: 'Sales Return' } : {}),
    ...(!IS_MANUFACTURER ? { INWARD: 'Inbound' } : {}),
    ...(!IS_MANUFACTURER ? { OUTBOUND: 'Outbound' } : {}),
    TRANSFER: 'Transfer',
    STOCK_ADJUSTMENT: 'Adjustment',
    GRN_REVERSAL: 'GRN Reversal',
    GDN_REVERSAL: 'GDN Reversal'
  };

  const setFilter = (key, value, type, filterType) => {
    let currentFilters;
    if (!filterType) {
      currentFilters = { ...advancedfilters };
    }
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    if (!filterType) {
      setFilters(currentFilters);
    }
  };

  let filterOptions = [
    {
      label: 'Type',
      name: 'Type',
      options: Object.keys(inventoryStatus).map((key, id) => ({
        value: key,
        label: inventoryStatus[key],
        id
      })),
      icon: Type
    },
    ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
      ? [
          {
            label: 'Batch(es)',
            name: 'Batches',
            icon: Batch
          }
        ]
      : [])
  ];

  const getInventoryDetails = () => {
    setAPILoader(true);
    let advanceFilters = Object.entries(advancedfilters).map(
      ([key, values]) => ({
        [key]: values.flat().map((item) => item.value || item)
      })
    );
    API.get(`reports/inventoryLedger/details`, {
      params: {
        inventoryId,
        advanceFilters
      }
    })
      .then((res) => {
        if (res.data.rows) {
          setProductData(res.data.rows);
        }
      })
      .finally(() => setAPILoader(false));
  };

  useEffect(() => {
    getInventoryDetails();
  }, [advancedfilters]);

  return (
    <Paper className="no-scrollbar rounded-t-lg shadow-none mt-[32px]">
      <div className="flex justify-end">
        <Filters
          options={filterOptions}
          filters={advancedfilters}
          setFilter={setFilter}
          height={220}
          isPrimary={true}
        />
      </div>

      <TableContainer
        style={{ overflow: 'auto' }}
        className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
      >
        <Table stickyHeader>
          <TableHead className="z-40 relative">
            <TableRow>
              {columnNames.map((columnName, index) => (
                <TableCell
                  key={index}
                  className="text-[10px] text-dark500 bg-white py-2 pl-[24px] pr-2 font-normal h-[40px]"
                >
                  {columnName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {productData.length > 0 &&
              productData.map((row, index) => (
                <TableRow key={index} className={clsx(' h-[48px]')}>
                  <TableCell
                    className={
                      'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                    }
                  >
                    <Tooltip title={dateFormatSlashDivider(row?.createdAt)}>
                      <p>{dateFormatSlashDivider(row.createdAt)}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={
                      'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                    }
                  >
                    <Tooltip
                      title={
                        row?.transactionType === 'INWARD'
                          ? 'Inbound'
                          : row?.transactionType === 'GRN_REVERSAL'
                          ? 'GRN Reversal'
                          : row?.transactionType === 'GDN_REVERSAL'
                          ? 'GDN Reversal'
                          : row?.transactionType === 'SALES_RETURN'
                          ? 'Sales Return'
                          : row?.transactionType === 'STOCK_ADJUSTMENT'
                          ? 'Adjustment'
                          : row?.transactionType?.toTitleCase()
                      }
                    >
                      <p className={'capitalize'}>
                        {row?.transactionType === 'INWARD'
                          ? 'Inbound'
                          : row?.transactionType === 'GRN_REVERSAL'
                          ? 'GRN Reversal'
                          : row?.transactionType === 'GDN_REVERSAL'
                          ? 'GDN Reversal'
                          : row?.transactionType === 'SALES_RETURN'
                          ? 'Sales Return'
                          : row?.transactionType === 'STOCK_ADJUSTMENT'
                          ? 'Adjustment'
                          : row?.transactionType?.toTitleCase()}
                      </p>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={
                      'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                    }
                  >
                    <Tooltip
                      title={`${row?.User?.firstName} ${row?.User?.lastName}`}
                    >
                      <p>{`${row?.User?.firstName} ${row?.User?.lastName}`}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={
                      'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                    }
                  >
                    <p>
                      {row?.originNode?.name}
                      {row?.transactionType !== 'STOCK_ADJUSTMENT' && (
                        <>
                          <span className="w-[8%] text-[24px] leading-[50%]">
                            &#8594;
                          </span>
                          {row?.destinationNode?.name}
                        </>
                      )}
                    </p>
                  </TableCell>
                  <TableCell
                    className={
                      'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                    }
                  >
                    <Tooltip title={row?.batchNumber}>
                      <div>
                        <div>{row?.batchNumber}</div>
                        <div>
                          {`${dateFormatSlashDivider(
                            row?.InventoryDetail?.manufacturingDate
                          )} - ${dateFormatSlashDivider(
                            row?.InventoryDetail?.expiryDate
                          )}` || ''}
                        </div>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={
                      'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                    }
                  >
                    <Tooltip title={row.quantity}>
                      <p
                        className={
                          row.operation === 'ADD'
                            ? 'text-jade700'
                            : 'text-crimson500'
                        }
                      >
                        {row.operation === 'ADD'
                          ? `${decimalDisplay(row.quantity)}`
                          : `-${decimalDisplay(row.quantity)}`}
                      </p>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default InventoryHistory;

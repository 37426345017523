import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import '../../assets/css/style.css';

const useStyles = makeStyles(() => ({
  root: {
    background: 'rgba(0, 0, 0, 0.5)',
    opacity: '0.5',
    height: 'calc(100% - 60px)',
    position: 'fixed',
    width: 'calc(100% - 256px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    padding: '0'
  },
  detail: {
    opacity: '0.5',
    height: 'calc(100% - 550px)',
    position: 'fixed',
    width: 'calc(100% - 40%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    padding: '0'
  }
}));

const LoaderOverlay = (props) => {
  const classes = useStyles();
  return (
    <div
      className={props?.type == 'detailPage' ? classes.detail : classes.root}
      id="loaderOverlay"
    >
      <CircularProgress />
    </div>
  );
};
export default LoaderOverlay;

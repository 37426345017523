import Lottie from 'react-lottie-player';
import { AnimationType } from './constants';
import { useState } from 'react';
import clsx from 'clsx';
import LogisticsStatic from '../../assets/icons/logisticsStatic.svg';

const OrderTypeBox = ({
  label,
  handleClick,
  animation,
  animationType,
  segment,
  disabled,
  description
}) => {
  const [play, setPlay] = useState(false);
  return (
    <div
      className="mr-4 w-[250px]"
      onMouseEnter={() => {
        if (disabled) return;

        setPlay(true);
        if (animationType === AnimationType.LOTTIE) {
          setPlay(true);
        }
      }}
      onMouseLeave={() => {
        if (disabled) return;

        setPlay(false);
        if (animationType === AnimationType.LOTTIE) {
          setPlay(false);
        }
      }}
    >
      <div
        className={clsx(
          'cursor-pointer w-[250px] h-[200px] rounded-lg overflow-hidden border border-dark300',
          {
            grayscale: !play
          }
        )}
        onClick={() => {
          handleClick();
        }}
      >
        {animationType === AnimationType.GIF && (
          <img src={play ? animation : LogisticsStatic} alt="" />
        )}
        {animationType === AnimationType.LOTTIE && (
          <Lottie
            loop
            autoplay={false}
            animationData={animation}
            play={play}
            segments={segment}
          />
        )}
      </div>
      <p className="mt-4 text-sm font-medium text-primaryBlue">{label}</p>
      <p className="text-dark500 text-xs mt-2">{description}</p>
    </div>
  );
};

export default OrderTypeBox;

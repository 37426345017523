import React, { useCallback, useContext, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import Button from '../../../core-components/atoms/Button';
import QR_GIF from '../../../assets/icons/blue-qr.gif';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { decryptDate, SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import moment from 'moment/moment';
import { toaster } from '../../../utils/toaster';
import LoaderOverlay from '../../../layouts/DashboardLayout/LoaderOverlay';
import { ORGANIZATION_TYPES } from '../../../constants';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import TextField from '../../../core-components/atoms/TextField';
import { NumericFormat } from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  bdGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  btnYes: {
    marginLeft: 10,
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green'
    }
  },
  container: {
    padding: 20
  },
  scanImgOverlay: {
    width: '60%'
  },
  tableHeadText: {
    fontWeight: 'bolder'
  }
}));

const ScanBatch = ({
  setOpen,
  formik,
  source,
  tableHeaders,
  rows,
  setRows,
  selectedProduct,
  parentIndex
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [scanQr, setScanQr] = useState('');
  const [loading, setLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(true);
  const { organizationType } = useContext(SharedContext);
  const [products, setProducts] = useState([]);

  const scanProductHandler = useCallback(
    debounce((val) => {
      keydownHandler(val);
    }, DEBOUNCE_CONST),
    []
  );

  const getValidDate = (date) => {
    return date ? moment(date, 'DD/MM/YYYY') : null;
  };

  const keydownHandler = async (product) => {
    if (!product.includes('}')) {
      product += '}'; //sometimes onchange does not change receive }, this line validates } at end.
    }
    try {
      product = JSON.parse(product);
    } catch (err) {
      setScanQr('');
      return false;
    } finally {
      setLoading(false);
    }
    if (product?.pid !== selectedProduct?.id) {
      toaster(
        'warning',
        `The scanned batch doesn't seem to exist for this SKU. Please verify the details and scan again.`
      );
      return;
    }
    product.manufacturingDate = getValidDate(product?.mfgd);
    product.expiryDate = getValidDate(decryptDate(product?.expd));
    product.batchNumber = product.batch;
    product.expd = decryptDate(product?.expd);

    const companyId = formik.values?.Order.companyId;
    const productId = product.pid;
    const warehouseId = formik.values.Order.pickupId;

    const columns = ['id'];
    let dbProduct;
    if (
      companyId &&
      organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
    ) {
      dbProduct = await API.get(`companies/${companyId}/products`, {
        params: {
          filters: {
            colVal: {
              id: productId
            }
          },
          columns
        }
      });
    } else if (organizationType === ORGANIZATION_TYPES.MANUFACTURER) {
      dbProduct = await API.get(`products`, {
        params: {
          filters: {
            colVal: {
              id: productId
            }
          },
          columns
        }
      });
    }

    const batches =
      source === 'GDN'
        ? await API.get(`inventories/batches`, {
            params: {
              nodeId: warehouseId,
              ...(organizationType ===
              ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
                ? { companyId }
                : {}),
              productId,
              orderId: formik?.values?.Order?.id
            }
          })
        : null;

    if (batches?.data) {
      batches?.data[0]?.InventoryDetail?.map((batch) => {
        let batchDetails = {
          id: batch?.id,
          batchNumber: batch?.batchNumber,
          expiryDate: batch?.expiryDate,
          actualExpiry: batch?.expiryDate,
          actualMfg: batch?.manufacturingDate,
          manufacturingDate: batch?.manufacturingDate,
          MRP: batch?.MRP?.toString(),
          promoName: batch?.promoName?.toString(),
          promoQuantity: batch?.promoQuantity?.toString()
        };
        if (
          batch?.promoName &&
          batch?.availableQuantity - batch?.promoQuantity > 0
        ) {
          return [
            {
              ...batchDetails,
              availableQuantity:
                batch?.availableQuantity - batch?.promoQuantity,
              promoName: null,
              promoQuantity: 0
            },
            {
              ...batchDetails,
              availableQuantity: batch?.promoQuantity
            }
          ];
        } else {
          return {
            ...batchDetails,
            availableQuantity: batch?.availableQuantity
          };
        }
      });
      const totalAvailable = (batches?.data[0]?.InventoryDetail || []).reduce(
        (acc, inventoryDetail) =>
          acc + (inventoryDetail.availableQuantity || 0),
        0
      );
      product.batchDetails = batches?.data[0]?.InventoryDetail.filter(
        (val) => val.batchNumber === product.batchNumber
      );
      product.availableQuantity = totalAvailable;
      if (!totalAvailable) {
        toaster('warning', 'Batch does not exist');
        setScanQr('');
        setLoading(false);
        return false;
      }
    }

    if (dbProduct?.data?.length) {
      product.name = dbProduct?.data[0]?.description;
      product['Product'] = dbProduct?.data[0];
      if (!updateIfProductExists(product)) {
        if (source === 'GRN') {
          product.receivedQuantity = 1;
        } else if (source === 'GDN') {
          product.actualDispatchedQuantity = 1;
        }
        let productsScanned = products;
        productsScanned.push(product);
        setProducts(productsScanned);
      }
    } else {
      toaster('warning', 'This product does not exist in current company');
    }

    setScanQr('');
    setTimeout(() => {
      setLoading(false);
    }, 10);
  };

  const updateIfProductExists = (product) => {
    let found = false;
    let scannedProducts = products;

    scannedProducts = scannedProducts.map((scannedProduct) => {
      if (
        scannedProduct.pid === product.pid &&
        scannedProduct.batch === product.batch &&
        moment(scannedProduct.expiryDate).format('YYYY-MM-DD') ===
          moment(product.expiryDate).format('YYYY-MM-DD')
      ) {
        found = true;
        if (source === 'GRN') {
          ++scannedProduct.receivedQuantity;
        } else if (source === 'GDN') {
          ++scannedProduct.actualDispatchedQuantity;
        }
      }

      return scannedProduct;
    });

    if (scannedProducts.length) {
      setProducts([...scannedProducts]);
    }

    return found;
  };

  const handleProductSubmit = async () => {
    if (source === 'GRN') {
      let toggleData = rows;
      if (parentIndex > -1) {
        let batchIndex = toggleData[parentIndex].GRNGroupBatches.findIndex(
          (batch) => {
            return (
              batch.batchNumber === products[0]?.batch &&
              getValidDate(batch?.expiryDate).format('YYYY-MM-DD') ===
                moment(products[0]?.expd, 'DD/MM/YYYY').format('YYYY-MM-DD')
            );
          }
        );

        if (batchIndex > -1) {
          toggleData[parentIndex].GRNGroupBatches[
            batchIndex
          ].receivedQuantity += Number(products[0]?.receivedQuantity);
        } else {
          toggleData[parentIndex].GRNGroupBatches.push({
            batchNumber: products[0]?.batch,
            manufacturingDate: moment(products[0]?.mfgd, 'DD/MM/YYYY'),
            expiryDate: moment(products[0]?.expd, 'DD/MM/YYYY'),
            receivedQuantity: Number(products[0]?.receivedQuantity),
            MRP: products[0]?.mrp ? products[0]?.mrp : null,
            recoverableDamageQuantity: 0,
            unsellableDamageQuantity: 0,
            promoName: products[0]?.promoName || '',
            promoQuantity: products[0]?.promoQuantity || 0
          });
        }
      } else {
        toggleData.push({
          ProductId: products[0]?.pid,
          Product: {
            id: products[0]?.pid,
            name: products[0]?.sku,
            description: products[0]?.name,
            barcode: products[0]?.Product?.barcode,
            UOM: {
              name: products[0]?.Product?.UOM?.name
            }
          },
          orderedQuantity: 0,
          GRNGroupBatches: [
            {
              batchNumber: products[0]?.batch,
              manufacturingDate: moment(products[0]?.mfgd, 'DD/MM/YYYY'),
              expiryDate: moment(products[0]?.expd, 'DD/MM/YYYY'),
              receivedQuantity: Number(products[0]?.receivedQuantity),
              MRP: products[0]?.mrp,
              recoverableDamageQuantity: 0,
              unsellableDamageQuantity: 0,
              promoName: products[0]?.promoName || '',
              promoQuantity: products[0]?.promoQuantity || 0
            }
          ]
        });
      }

      setRows([...toggleData]);
    } else if (source === 'GDN') {
      let toggleData = rows;
      toggleData[parentIndex].actualDispatchedQuantity =
        (+toggleData[parentIndex].actualQuantity || 0) +
        +products[0].actualDispatchedQuantity;

      if (parentIndex > -1) {
        let batchIndex = toggleData[parentIndex].GDNGroupBatches.findIndex(
          (batch) => {
            return (
              batch.batchNumber === products[0]?.batch &&
              getValidDate(batch?.expiryDate).format('YYYY-MM-DD') ===
                moment(products[0]?.expd, 'DD/MM/YYYY').format('YYYY-MM-DD') &&
              batch?.promoName === products[0]?.promoName
            );
          }
        );

        if (batchIndex > -1) {
          toggleData[parentIndex].GDNGroupBatches[
            batchIndex
          ].actualDispatchedQuantity += Number(
            products[0]?.actualDispatchedQuantity
          );
        } else {
          toggleData[parentIndex].GDNGroupBatches.push({
            batchNumber: products[0]?.batch,
            manufacturingDate: moment(products[0]?.mfgd, 'DD/MM/YYYY'),
            expiryDate: moment(products[0]?.expd, 'DD/MM/YYYY'),
            actualDispatchedQuantity: Number(
              products[0]?.actualDispatchedQuantity || 0
            ),
            availableQuantity: Number(products[0]?.availableQuantity || 0),
            MRP: products[0]?.mrp,
            promoName: products[0]?.promoName,
            promoQuantity: products[0]?.promoQuantity?.toString(),
            id: products[0]?.batchDetails[0].id
          });
        }
      } else {
        toggleData.push({
          ProductId: products[0]?.pid,
          Product: products[0]?.Product,
          availableQuantity: Number(products[0]?.actualDispatchedQuantity),
          quantity: Number(products[0]?.actualDispatchedQuantity),
          GDNGroupBatches: [
            {
              batchNumber: products[0]?.batch,
              manufacturingDate: moment(products[0]?.mfgd, 'DD/MM/YYYY'),
              expiryDate: moment(products[0]?.expd, 'DD/MM/YYYY'),
              actualDispatchedQuantity: Number(
                products[0]?.actualDispatchedQuantity || 0
              ),
              availableQuantity: Number(products[0]?.availableQuantity || 0),
              MRP: products[0]?.mrp,
              promoName: products[0]?.promoName,
              promoQuantity: products[0]?.promoQuantity,
              id: products[0]?.batchDetails[0].id
            }
          ]
        });
      }
      toggleData[parentIndex]['actualQuantity'] =
        toggleData[parentIndex]?.actualDispatchedQuantity;
      setRows([...toggleData]);
    }
    setOpen(false);
  };

  const handleChangeQuantity = (value, product) => {
    setProducts((current) =>
      current.map((scannedProduct) => {
        if (
          scannedProduct.pid === product.pid &&
          scannedProduct.batch === product.batch &&
          moment(scannedProduct.expiryDate).format('YYYY-MM-DD') ===
            moment(product.expiryDate).format('YYYY-MM-DD')
        ) {
          if (source === 'GRN') {
            setGRNQuantity(value, scannedProduct);
          } else if (source === 'GDN') {
            setGDNQuantity(value, product, scannedProduct);
          }
        }

        return scannedProduct;
      })
    );
  };

  const setGRNQuantity = (value, scannedProduct) => {
    scannedProduct.receivedQuantity = value;
  };

  const setGDNQuantity = (value, product, scannedProduct) => {
    if (Number(value) <= product.availableQuantity) {
      scannedProduct.actualDispatchedQuantity = value;
    } else {
      if (value) {
        scannedProduct.actualDispatchedQuantity =
          product.actualDispatchedQuantity;
      } else {
        scannedProduct.actualDispatchedQuantity = 0;
      }
    }
  };

  return (
    <>
      {loading && isScanning && <LoaderOverlay type="detailPage" />}
      <Grid item xs={12} className="my-[-50px]">
        {products.length > 0 ? (
          <TableContainer className={classes.container}>
            <div className="scanScrollTable">
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => {
                      return (
                        <TableCell
                          key={tableHeaders.id}
                          className={classes.tableHeadText}
                        >
                          {header.value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => {
                    return (
                      <TableRow key={product.id}>
                        {tableHeaders.map((header) => {
                          return (
                            <>
                              {header.key === 'pid' ||
                              header.key === 'Product' ? (
                                ''
                              ) : (
                                <TableCell>
                                  {header.key === 'receivedQuantity' ||
                                  header.key === 'actualDispatchedQuantity' ? (
                                    <NumericFormat
                                      value={product[header.key]}
                                      error={
                                        !product[header.key] ||
                                        product[header.key] < 0
                                      }
                                      customInput={TextField}
                                      onValueChange={(e) => {
                                        handleChangeQuantity(
                                          parseFloat(e.value),
                                          product
                                        );
                                      }}
                                      thousandSeparator=","
                                      decimalSeparator="."
                                      placeholder="-"
                                    />
                                  ) : header.key === 'skuName' ? (
                                    <>{`${product['sku']} - ${product['name']}`}</>
                                  ) : (
                                    <>{product[header.key] || '-'}</>
                                  )}
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        ) : (
          <img src={QR_GIF} alt="Blue QR" className="mx-20" />
        )}
      </Grid>
      <input
        ref={inputRef}
        style={{ border: 'none', color: 'white' }}
        type="text"
        value={scanQr}
        onChange={(e) => {
          setLoading(true);
          setScanQr(e.target.value);
          scanProductHandler(e.target.value);
        }}
        onBlur={() => {
          setIsScanning(false);
        }}
        autoFocus
      />
      <Grid item xs={12} className="flex justify-end">
        <Button
          size="large"
          label="Cancel"
          variant="text"
          onClick={() => setOpen(false)}
          className="mr-2"
        />
        <Button
          size="large"
          label="Add Details"
          variant="primary"
          onClick={() => handleProductSubmit()}
        />
      </Grid>
    </>
  );
};

export default ScanBatch;

import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  makeStyles,
  Grid,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody
} from '@material-ui/core';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import calenderIcon from '../../../assets/icons/calenderIcon.svg';
import Address from '../../../atomicComponents/Address';
import DetailText from '../../../atomicComponents/DetailText';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import {
  dividerDateFormat,
  findKeyValue,
  productLabelFormat,
  getPickUpLocationAttribute,
  getDropOffLocationAttribute
} from '../../../utils/common';
import '../../../assets/css/style.css';
import { OrderTypeMapping } from '../../../constants/index';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    padding: '10px !important',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: '10px !important'
    },
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      padding: '0px !important'
    }
  },
  detailTitleSec: {
    margin: '30px 0'
  },
  locationDetail: {
    marginTop: '25px',
    justifyContent: 'space-between'
  },
  secAddress: {
    marginLeft: '10px',
    '@media print': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '30px'
    },
    '@media only screen and (max-width: 1200px) and (min-width:992px)': {
      marginLeft: '0px !important',
      marginTop: '10px !important'
    },
    '@media only screen and (max-width: 992px) and (min-width:769px)': {
      marginTop: '10px !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      marginTop: '10px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      marginTop: '12px !important',
      marginLeft: '0px !important'
    },
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      marginTop: '12px !important',
      marginLeft: '0px !important'
    }
  },
  addressDetail: {
    marginTop: '15px'
  },
  calenderIcon: {
    marginRight: '10px'
  },
  detailInstructions: {
    margin: '30px 0 5px 0'
  },
  printDisplayNone: {
    '@media print': {
      display: 'none'
    }
  },
  fullWidthLocation: {
    '@media print': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '30px'
    }
  },
  headerCols: {
    '@media print': {
      maxWidth: '50%',
      flexBasis: '50%'
    }
  },
  footerCols: {
    '@media print': {
      maxWidth: '35%',
      flexBasis: '35%'
    }
  },
  submitBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '92%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #4B89C8',
    background: '#4B89C8',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#FFFFFF',
    '@media only screen and (max-width: 992px) and (min-width:768px)': {
      fontSize: '10px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      marginTop: '10px !important'
    }
  },
  detailsGRN: {
    '@media only screen and (max-width: 1200px) and (min-width:992px)': {
      padding: '8px !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      padding: '8px 0px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: '8px 0px !important'
    },
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      padding: '8px !important'
    }
  },
  title: {
    '@media (min-width:1024px)': {
      maxWidth: 'fit-content',
      paddingRight: '10px'
    }
  }
}));

const LogisticOrderDetails = ({ orderId }) => {
  const classes = useStyles();
  const [order, setOrder] = useState('');
  const moveTypes = {
    INWARD: 'Inward',
    OUTBOUND: 'Outbound',
    TRANSFER: 'Transfer',
    LOGISTIC: 'Logistics'
  };
  const logisticBy = {
    OWARE: 'Oware',
    CUSTOMER: 'Customer'
  };

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId]);
  const getOrder = async (orderId) => {
    try {
      const order = await API.get(`orders/order/${orderId}`);
      setOrder(order?.data);
    } catch (err) {
      onError(err);
    }
  };

  return (
    <DialogContent className={classes.detailsGRN}>
      <Grid item container className={classes.locationDetail}>
        <Grid item xs={12} lg={5} className={classes.headerCols}>
          <DetailTitle text="Move Type:" />
          <DetailText
            text={
              OrderTypeMapping[order?.moveType] ||
              moveTypes[order?.moveType] ||
              ''
            }
          />
        </Grid>
        <Grid item xs={12} lg={5} className={classes.headerCols}>
          <DetailTitle text="Customer:" />
          <DetailText text={order?.Company?.name} />
        </Grid>
      </Grid>
      <Grid item container className={classes.locationDetail}>
        <Grid item xs={12} lg={5} className={classes.fullWidthLocation}>
          <DetailTitle text="Pickup Location:" />
          <DetailText
            text={
              getPickUpLocationAttribute(order, 'businessWarehouseCode') ||
              getPickUpLocationAttribute(order, 'name')
            }
          />
          <Grid className={classes.addressDetail}>
            <Address
              name={`${getPickUpLocationAttribute(order, 'pocName')}`}
              pickupLocationPhone={getPickUpLocationAttribute(
                order,
                'pocContact'
              )}
              pickupLocation={`${getPickUpLocationAttribute(order, 'address')}`}
            />
          </Grid>
          <Grid className="display-flex mt-3">
            <img
              src={calenderIcon}
              className={classes.calenderIcon}
              alt="Calender Icon"
            />
            <DetailText text={dividerDateFormat(order?.pickupDate)} />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.secAddress}>
          <DetailTitle text="Dropoff Location:" />
          <DetailText
            text={
              getDropOffLocationAttribute(order, 'businessWarehouseCode') ||
              getDropOffLocationAttribute(order, 'name')
            }
          />
          <Grid className={classes.addressDetail}>
            <Address
              name={`${getDropOffLocationAttribute(order, 'pocName')}`}
              pickupLocationPhone={getDropOffLocationAttribute(
                order,
                'pocContact'
              )}
              pickupLocation={getDropOffLocationAttribute(order, 'address')}
            />
          </Grid>
          <Grid className="display-flex mt-3">
            <img
              src={calenderIcon}
              className={classes.calenderIcon}
              alt="Calender Icon"
            />
            <DetailText text={dividerDateFormat(order?.dropoffDate)} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={12} className={classes.detailInstructions}>
          <DetailTitle text="Additional Instruction:" />
          <Grid className={classes.addressDetail}>
            <DetailText text={order?.memo} />
          </Grid>
        </Grid>
      </Grid>

      <hr className="lineSpace" />
      <Grid item container>
        <Grid className="scrollGRN-table">
          <Table className="detailTable">
            <TableHead className="tableHeader">
              <TableCell>SKU Name & Bar Code</TableCell>
              <TableCell>UoM</TableCell>
              <TableCell className="quantityText">Quantity</TableCell>
            </TableHead>
            <TableBody>
              {order?.OrderProductGroups?.map((product, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {productLabelFormat(product?.Product)}{' '}
                      {product?.barcode ? `- ${product?.barcode}` : ``}
                    </TableCell>
                    <TableCell>{product?.Product?.UOM?.name}</TableCell>
                    <TableCell className="orderQuantity">
                      {product?.quantity}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <hr className="lineSpace" />

      <Grid item container className="rowSpacing">
        <Grid item xs={12} sm={6} md={2} className={classes.footerCols}>
          <DetailTitle text="Logistic By:" />
          <DetailText
            text={findKeyValue(logisticBy, order?.logisticBy) || ''}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} className={classes.footerCols}>
          <DetailTitle text="Pallet Requirement :" />
          <DetailText
            text={order?.palletize ? 'Palletized' : 'Non palletized'}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2} className={classes.footerCols}>
          <DetailTitle text="Weight :" />
          <DetailText text={order?.weight} />
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.footerCols}>
          <DetailTitle text="Vehicle Type :" />
          <DetailText
            text={
              order &&
              order.vehicleType &&
              order.vehicleType.CarMake &&
              order.vehicleType.CarModel
                ? `${order?.vehicleType?.CarMake?.name} ${order?.vehicleType?.CarModel?.name} ( ${order?.vehicleType?.Category?.name} )`
                : ''
            }
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default LogisticOrderDetails;

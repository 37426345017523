import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Title } from '../../../core-components/atoms/Text';
import {
  DARK_800,
  OASIS_PRIMARY_500,
  OASIS_PRIMARY_600
} from '../../../constants/colors';
import { decimalDisplay } from '../../../utils/common';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import GraphSkeleton from '../components/GraphSkeleton';
import {
  parseISO,
  differenceInDays,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInCalendarYears,
  format,
  endOfMonth,
  startOfMonth,
  eachMonthOfInterval,
  eachYearOfInterval,
  eachDayOfInterval
} from 'date-fns';

const noDataPlugin = {
  id: 'noDataPlugin',
  afterDraw: (chart) => {
    if (
      chart.data.datasets.length === 0 ||
      chart.data.datasets.every((dataset) =>
        dataset.data.every((value) => value === 0)
      )
    ) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = "16px normal 'Helvetica Nueue'";
      ctx.fillStyle = '#666';
      ctx.fillText('No data to display', width / 2, height / 2);
      ctx.restore();
    }
  }
};

const TotalShipments = ({ nodeIds, endDate, startDate }) => {
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);

  useEffect(() => {
    setIsLoading(true);
    getDelayedShipments(startDate, endDate, nodeIdsMemo);
  }, [startDate, endDate, nodeIdsMemo]);

  const getDelayedShipments = useCallback(
    debounce(
      async (startDate, endDate, nodeIds) => {
        getShipments(startDate, endDate, nodeIds);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getShipments = async (startDate, endDate, nodeIds) => {
    try {
      const data = await API.get(`intelligence/total-shipments`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          nodeIds: nodeIds
        }
      });
      setShipments(data);
      // console.log(data);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };

  function summarizeAndGroupData(data) {
    if (!data || data.length === 0) return [];

    // Sort the data by dates
    data.sort((a, b) => parseISO(a.date) - parseISO(b.date));

    const startDate = parseISO(data[0].date);
    const endDate = parseISO(data[data.length - 1].date);

    const dayDiff = differenceInDays(endDate, startDate);
    const monthDiff = differenceInCalendarMonths(endDate, startDate);
    const quarterDiff = differenceInCalendarQuarters(endDate, startDate);
    const yearDiff = differenceInCalendarYears(endDate, startDate);

    let groupedData = [];

    if (dayDiff <= 7) {
      // Daily data within a week
      eachDayOfInterval({ start: startDate, end: endDate }).forEach((day) => {
        const dayStr = format(day, 'yyyy-MM-dd');
        groupedData.push({
          range: dayStr,
          count: data
            .filter((d) => d.date === dayStr)
            .reduce((acc, curr) => acc + curr.count, 0)
        });
      });
    } else if (monthDiff <= 1) {
      // Monthly data within a month
      const monthRange = eachDayOfInterval({
        start: startOfMonth(startDate),
        end: endOfMonth(startDate)
      });
      monthRange.forEach((day) => {
        const dayStr = format(day, 'yyyy-MM-dd');
        groupedData.push({
          range: dayStr,
          count: data
            .filter((d) => d.date === dayStr)
            .reduce((acc, curr) => acc + curr.count, 0)
        });
      });
    } else if (quarterDiff <= 1 || monthDiff <= 6) {
      // Monthly data within a quarter or up to six months
      eachMonthOfInterval({ start: startDate, end: endDate }).forEach(
        (month) => {
          const monthStr = format(month, 'yyyy-MM');
          groupedData.push({
            range: monthStr,
            count: data
              .filter((d) => format(parseISO(d.date), 'yyyy-MM') === monthStr)
              .reduce((acc, curr) => acc + curr.count, 0)
          });
        }
      );
    } else if (yearDiff <= 1) {
      // Monthly data within a year
      eachMonthOfInterval({ start: startDate, end: endDate }).forEach(
        (month) => {
          const monthStr = format(month, 'yyyy-MM');
          groupedData.push({
            range: monthStr,
            count: data
              .filter((d) => format(parseISO(d.date), 'yyyy-MM') === monthStr)
              .reduce((acc, curr) => acc + curr.count, 0)
          });
        }
      );
    } else {
      // Yearly data for ranges over a year
      eachYearOfInterval({ start: startDate, end: endDate }).forEach((year) => {
        const yearStr = format(year, 'yyyy');
        groupedData.push({
          range: yearStr,
          count: data
            .filter((d) => format(parseISO(d.date), 'yyyy') === yearStr)
            .reduce((acc, curr) => acc + curr.count, 0)
        });
      });
    }

    return groupedData;
  }

  const divideData = summarizeAndGroupData(shipments);
  const ranges = divideData?.map((value) => value.range);
  const chartData = divideData?.map((value) => value.count);

  const data = {
    labels: ranges,
    datasets: [
      {
        label: 'Shipments',
        data: chartData,
        fill: false,
        borderColor: OASIS_PRIMARY_500,
        borderWidth: 0.5,
        tension: 0.4,
        borderRadius: 4,
        pointRadius: 4,
        pointBackgroundColor: OASIS_PRIMARY_600,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: DARK_800,
        pointHoverBorderWidth: 1,
        pointHoverBorderColor: OASIS_PRIMARY_600
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += decimalDisplay(context.parsed.y) + ' Shipments';
            }
            return label;
          }
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false
        },
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value;
          }
        }
      }
    }
  };

  return (
    <div className="p-4">
      <Title variant={'md'} className={'mb-4'}>
        Total Shipments
      </Title>
      <div className={'h-[240px]'} style={{ width: '100%' }}>
        {isLoading ? (
          <GraphSkeleton />
        ) : (
          <Line data={data} options={options} plugins={[noDataPlugin]} />
        )}
      </div>
    </div>
  );
};

export default TotalShipments;

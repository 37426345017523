import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { productLabelFormat } from '../../utils/common';
import useStyles from './taskStyles';

function Row(props) {
  const classes = useStyles();
  const { row, values, isPrint } = props;
  const [open, setOpen] = useState(false);

  const batchDetails = (
    <Box className={classes.margin0} sx={{ margin: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Batch Number</b>
            </TableCell>
            <TableCell align="center">
              <b>Location</b>
            </TableCell>
            {!values?.isBlind ? (
              <TableCell align="right">
                <b>COC Qty</b>
              </TableCell>
            ) : null}
            <TableCell align="right">
              <b>Physical Qty</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row?.WarehouseTaskProductDetails?.map((batch) => (
            <TableRow key={batch.id}>
              <TableCell component="th" scope="row">
                {batch?.InventoryDetail?.batchNumber}
              </TableCell>
              <TableCell align="center">
                {batch?.InventoryDetail?.WarehouseHall?.name}
              </TableCell>
              {!values?.isBlind ? (
                <TableCell align="right">{batch?.cocQuantity}</TableCell>
              ) : null}
              <TableCell align="right">{batch?.physicalQuantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: '1px solid rgba(224, 224, 224, 1)' } }}
      >
        <TableCell component="th" scope="row">
          {productLabelFormat(row?.Product)}
        </TableCell>
        <TableCell align="center">{row?.Product?.UOM.name}</TableCell>
        {!values?.isBlind ? (
          <TableCell align="right">
            {values?.taskStatus == 'COMPLETED' ? row?.totalCOCQty : ''}
          </TableCell>
        ) : null}
        <TableCell align="right">
          {values?.taskStatus == 'COMPLETED' ? row?.totalPhysicalQty : ''}
          {!isPrint && row?.Product?.batchEnabled ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {row?.Product?.batchEnabled && open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          ) : (
            <IconButton className={classes.visibilityNone}>
              <IconButton></IconButton>
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={
            open
              ? classes.leftBorder + ' ' + classes.padding0
              : classes.padding0
          }
          colSpan={12}
        >
          {isPrint ? (
            <>{row?.Product?.batchEnabled ? batchDetails : null}</>
          ) : (
            <Collapse
              in={open && row?.Product?.batchEnabled}
              timeout="auto"
              unmountOnExit
            >
              {batchDetails}
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const CycleCountTableView = ({ values, isPrint = false }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell align="left">
              <b>SKU Name</b>
            </TableCell>
            <TableCell align="center">
              <b>UoM</b>
            </TableCell>
            {!values?.isBlind ? (
              <TableCell align="right">
                <b>COC Qty</b>
              </TableCell>
            ) : null}
            <TableCell align="right">
              <b>Physical Qty</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values?.WarehouseTaskProducts?.map((row) => (
            <Row key={row.id} row={row} values={values} isPrint={isPrint} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CycleCountTableView;

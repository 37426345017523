import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Dialog, DialogTitle } from '@mui/material';

import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import warehouseBookingStyles from './warehouseBookingStyles';

const CancelBookingPopup = ({ openState, handleClose, confirmCancel }) => {
  const { confirmationPopup, setConfirmationPopup } = openState;
  const classes = warehouseBookingStyles();

  return (
    <>
      {confirmationPopup && (
        <Dialog
          className={classes.backdrop}
          open={confirmationPopup}
          onClose={handleClose}
          PaperProps={{ sx: { width: '40%' } }}
        >
          <Grid
            container
            className={classes.backdropGrid}
            justifyContent="flex-start"
          >
            <Grid item xs={12}>
              <DialogTitle>
                <DetailPageTitle text="Are you sure you want to cancel booking?" />
              </DialogTitle>
            </Grid>
            <div className={`${classes.popupFooter} mt-5`}>
              <Tooltip title="Back">
                <Button
                  className={`${classes.popupBtn}`}
                  variant="outlined"
                  onClick={() => setConfirmationPopup(false)}
                >
                  BACK
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  className={`${classes.popupBtn} ml-5`}
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    confirmCancel();
                  }}
                >
                  CANCEL
                </Button>
              </Tooltip>
            </div>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default CancelBookingPopup;

/*eslint-disable*/
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DEBOUNCE_CONST } from '../../../Config';
import '../../../assets/css/style.css';
import StockHandlingSection from '../../../components/StockHandlingSection';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';
import vendorTypes from '../../../utils/enums/vendorTypes';
import { upload } from '../../../utils/upload';
import { isPhone, isRequired } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  textBoxResponsive: {
    marginTop: '-2px'
  }
}));

export default function AddCompanyView({
  relationType,
  addCompany,
  users,
  customerTypes,
  open,
  handleClose,
  selectedCompany,
  formErrors,
  attemptedAlready,
  setAttemptedAlready
}) {
  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  // const [internalIdForBusiness, setInternalIdForBusiness] = useState('');
  const [contactId, setContactId] = useState('');
  const classes = useStyles();
  const { subdomain } = useContext(SharedContext);

  const [type, setType] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');
  const [isActive, setActive] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [logoImageSrc, setLogoImageSrc] = useState(null);
  const [logoDimension, setLogoDimension] = useState(false);
  const [logoType, setLogoType] = useState(false);
  const [logoSize, setLogoSize] = useState(false);
  const [selectedCompanyTempLogoId, setSelectedCompanyTempLogoId] =
    useState(null);
  const [explicitReRender, setExplicitReRender] = useState(false);
  const [companyPhone, setCompanyPhone] = useState('');
  const [ntn, setNtn] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [paymentTerms, setPaymentTerms] = useState(0);
  const [creditLimit, setCreditLimit] = useState(0);
  const [currency, setCurrency] = useState(null);
  const [shipAddrAsBillAddr, setShipAddrAsBillAddr] = useState(false);
  const [vendorType, setVendorType] = useState({
    Warehousing: false,
    Logistics: false,
    Technology: false
  });
  const [pocUsers, setPocUsers] = useState([]);
  const [pocUserId, setPocUserId] = useState();
  const [searchUser, setSearchUser] = useState([]);
  const [stockHandlingType, setStockHandlingType] = useState('FIFO');
  const [userss, setUsers] = useState([]);
  const currencyOpt = ['PKR', 'USD', 'AED'];

  useEffect(async () => {
    if (selectedCompany) {
      setPocUserId(selectedCompany.pocUserId);
      // will work on edit
      setName(selectedCompany.name || '');
      // setInternalIdForBusiness(selectedCompany.internalIdForBusiness || '');
      setType(selectedCompany.type || '');
      setContactId(selectedCompany.contactId || '');
      setNtn(selectedCompany.ntn || '');
      setBillingAddress(selectedCompany.billingAddress || '');
      setShippingAddress(selectedCompany.shippingAddress || '');
      setPaymentTerms(selectedCompany.paymentTerms || 0);
      setCreditLimit(selectedCompany.creditLimit || 0);
      setCurrency(selectedCompany.currency || null);
      setNotes(selectedCompany.notes || '');
      setActive(!!selectedCompany.isActive);
      setCompanyPhone(selectedCompany.phone || '');
      setStockHandlingType(selectedCompany.stockHandlingType || '');
      setEmail(selectedCompany.email || '');
      selectedCompany.logoId = !selectedCompany.logoId
        ? selectedCompanyTempLogoId
        : selectedCompany.logoId;
      let type =
        (selectedCompany.type?.length > 0 && selectedCompany.type.split(',')) ||
        [];
      type = {
        Logistics: type.indexOf('Logistics') != -1,
        Warehousing: type.indexOf('Warehousing') != -1,
        Technology: type.indexOf('Technology') !== -1
      };
      setVendorType(type);
      let srcImg = await API.get(
        `rides/loads/preview/${selectedCompany?.logoId}`
      );
      {
        selectedCompany && selectedCompany.logoId
          ? setLogoImageSrc(srcImg.preview)
          : setLogoImageSrc(null);
      }
    } else {
      setName('');
      // setInternalIdForBusiness('');
      setType('');
      setContactId('');
      setNotes('');
      setLogoImageSrc('');
      setNtn('');
      setVendorType('');
      relationType == 'VENDOR' ? setActive(true) : setActive(false);
    }
  }, [selectedCompany, explicitReRender]);

  useEffect(() => {
    if (relationType == 'VENDOR') setType(null);
  }, [relationType]);

  useEffect(() => {
    if (shipAddrAsBillAddr) {
      setShippingAddress(billingAddress);
    }
  }, [billingAddress, shipAddrAsBillAddr]);

  useEffect(async () => {
    if (selectedCompany) {
      const pocUser = await API.get(
        `companies/${selectedCompany.id}/poc-users`,
        { params: { type: 'CUSTOMER' } }
      );
      if (pocUser.users) setPocUsers(pocUser.users);
    }
  }, [selectedCompany]);

  const getUsers = async (value) => {
    try {
      let columns = [
        'firstName',
        'lastName',
        'username',
        'email',
        'phone',
        '$UserRoles.Role.name$'
      ];

      let colVal = {
        '$UserRoles.Role.allowedApps$': 'OPERATIONS',
        isActive: '1'
      };

      let filters = {
        colVal
      };

      const { data } = await API.get(`organizations/${subdomain}/users`, {
        params: { search: value || '', filters, columns }
      });

      setUsers(data);
    } catch (err) {
      onError(err);
    }
  };

  const getUsersAPI = useCallback(
    debounce((searchUser) => {
      // getUsers(searchUser);
      if (open) getUsers(searchUser);
    }, DEBOUNCE_CONST),
    [open]
  );

  useEffect(() => {
    if (open) {
      getUsersAPI(searchUser);
    }
  }, [searchUser, users, open]);

  useEffect(() => {
    if (!open) {
      setValidation({});
    }
  }, [open]);

  const validate = () => {
    if (relationType === 'CUSTOMER') {
      return (
        isRequired(name) &&
        isRequired(ntn) &&
        isRequired(billingAddress) &&
        isRequired(shippingAddress) &&
        isRequired(contactId) &&
        isRequired(type) &&
        isRequired(companyPhone) &&
        isPhone(companyPhone.replace(/-/g, ''))
      );
    } else {
      return (
        isRequired(name) &&
        isRequired(ntn) &&
        isRequired(contactId) &&
        (vendorType.Warehousing ||
          vendorType.Logistics ||
          vendorType.Technology)
      );
    }
  };

  const getPayload = () => {
    const payload = {
      name,
      contactId,
      ntn,
      pocUserId,
      relationType,
      stockHandlingType,
      type,
      logoId: (selectedCompany && selectedCompany.logoId) || logoImage || null,
      notes,
      phone: companyPhone.replace(/-/g, ''),
      isActive
    };

    if (relationType === 'CUSTOMER') {
      payload['billingAddress'] = billingAddress;
      payload['shippingAddress'] = shippingAddress;
      payload['paymentTerms'] = paymentTerms;
      payload['creditLimit'] = creditLimit;
      payload['currency'] = currency;
      payload['email'] = email;
    } else {
      let joinedType = '';
      if (vendorType.Logistics) {
        joinedType += 'Logistics,';
      }
      if (vendorType.Warehousing) {
        joinedType += 'Warehousing,';
      }
      if (vendorType.Technology) {
        joinedType += 'Technology,';
      }
      payload['type'] = joinedType;
    }
    return payload;
  };

  const handleSubmit = async () => {
    const newCompany = getPayload();
    setValidation({
      pocUserId: true,
      name: true,
      ntn: true,
      billingAddress: true,
      shippingAddress: true,
      vendorType: true,
      internalIdForBusiness: true,
      contactId: true,
      relationType: true,
      email: true,
      // logoImage: true,
      type: relationType == 'CUSTOMER',
      companyPhone: true
    });

    if (validate()) {
      if (logoImage) {
        [newCompany.logoId] = await upload([logoImage], 'customer');
      }
      // if ((pocUserId && isActive === true) || isActive === false || relationType == "VENDOR") {
      setSelectedCompanyTempLogoId(null);
      setAttemptedAlready(true);
      await addCompany(newCompany);
      handleClose();
      resetStates();
      setName('');
      setNotes('');
      setEmail('');
      // }
    }
  };

  const newValidateLogoImage = (event) => {
    const checkFile = event.target.files[0];
    setLogoType(false);
    setLogoSize(false);
    setLogoDimension(false);
    if (checkFile && !checkFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setLogoType(true);
      return false;
    }
    const isLt2M = checkFile && checkFile.size / 1024 / 1024 < 1; // < 1mb
    if (checkFile && !isLt2M) {
      setLogoSize(true);
      return false;
    }
    const reader = new FileReader();
    checkFile && reader.readAsDataURL(checkFile);
    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement('img');
      image.src = _loadedImageUrl;
      image.addEventListener('load', () => {
        const { width, height } = image;
        if (image && width > 142 && height > 37) {
          setLogoDimension(true);
          setLogoImageSrc(null);
          setLogoImage(null);
          return false;
        } else {
          setLogoImageSrc(_loadedImageUrl);
          const logoFile = checkFile ? checkFile : null;
          setLogoImage(logoFile);
        }
      });
    });
  };

  const resetStates = () => {
    setValidation('');
    setName('');
    setNtn('');
    setBillingAddress('');
    setShippingAddress('');
    setPaymentTerms('');
    setCreditLimit('');
    setCurrency('');
    setVendorType('');
    setType('');
    setContactId('');
    setNotes('');
    setActive(true);
    setCompanyPhone('');
    setLogoImage(null);
    setEmail('');
  };

  const removePreviewId = () => {
    setLogoImage(null);
    setLogoImageSrc(null);
    // setCurrentFileName(null)
    setSelectedCompanyTempLogoId(selectedCompany.logoId);
    selectedCompany.logoId = null;
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={() => handleClose()}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
          onBackdropClick={() => {
            // setValidation('');
            resetStates();
            setValidation('');
          }}
        >
          <DialogTitle disableTypography className="title">
            {!selectedCompany ? `Add ` : `Edit `}
            {relationType == 'CUSTOMER' ? 'Company' : 'Carrier'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="name"
                    label={
                      relationType == 'CUSTOMER' ? ` Company*` : ` Carrier*`
                    }
                    type="text"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => setValidation({ ...validation, name: true })}
                  />
                  {validation.name && !isRequired(name) ? (
                    <Typography color="error">
                      {relationType == 'CUSTOMER' ? 'Company' : 'Carrier'} name
                      is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                  {/* {validation.name && !isChar(name) ? (
                    <Typography color="error">
                      {relationType == "CUSTOMER" ? "Company" : "Vendor"} name is only characters!
                    </Typography>
                  ) : (
                    ""
                  )} */}
                </Grid>
                {relationType === 'CUSTOMER' && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      inputProps={{ className: classes.textBox }}
                      className={classes.labelBox}
                      margin="dense"
                      id="email"
                      label={`Email*`}
                      type="email"
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() =>
                        setValidation({ ...validation, email: true })
                      }
                    />
                    {validation.email && !isRequired(email) ? (
                      <Typography color="error">
                        Company Email is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                )}

                {relationType == 'CUSTOMER' ? (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      inputProps={{ className: classes.textBox }}
                      className={clsx({
                        [classes.labelBox]: true,
                        [classes.textBoxResponsive]: true
                      })}
                      margin="dense"
                      name="phone"
                      id="companyPhone"
                      label={'Company Phone*'}
                      placeholder=" "
                      type="text"
                      value={companyPhone}
                      onChange={(e) => {
                        setCompanyPhone(e.target.value);
                      }}
                      variant="outlined"
                      onBlur={() =>
                        setValidation({ ...validation, companyPhone: true })
                      }
                    />
                    {validation.companyPhone &&
                    !isRequired(companyPhone) &&
                    !isPhone(companyPhone.replace(/-/g, '')) ? (
                      <Typography color="error">
                        Phone number must be provided!
                      </Typography>
                    ) : (
                      ''
                    )}
                    {validation.companyPhone &&
                    isRequired(companyPhone) &&
                    !isPhone(companyPhone.replace(/-/g, '')) ? (
                      <Typography color="error">
                        Incorrect phone number!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
              {relationType == 'CUSTOMER' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      margin="dense"
                      fullWidth={true}
                      variant="outlined"
                    >
                      <StockHandlingSection
                        setStockHandlingType={setStockHandlingType}
                        stockHandlingType={stockHandlingType}
                      ></StockHandlingSection>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {relationType == 'CUSTOMER' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      margin="dense"
                      fullWidth={true}
                      variant="outlined"
                    >
                      <Autocomplete
                        id="customerTypes"
                        key={customerTypes}
                        options={customerTypes}
                        defaultValue={type ? type : ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Type"
                            variant="outlined"
                          />
                        )}
                        getOptionLabel={(customerType) => customerType || ''}
                        onBlur={() =>
                          setValidation({ ...validation, type: true })
                        }
                        onChange={(event, newValue) => {
                          if (newValue) setType(newValue);
                        }}
                      />
                      {validation.type && !isRequired(type) ? (
                        <Typography color="error">
                          {' '}
                          {relationType == 'CUSTOMER'
                            ? 'Company'
                            : 'Carrier'}{' '}
                          type is required!
                        </Typography>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="contactId"
                      key={'contactId'}
                      options={userss}
                      defaultValue={
                        selectedCompany
                          ? {
                              name: `${selectedCompany?.Contact?.firstName} ${selectedCompany?.Contact?.lastName}`,
                              id: selectedCompany?.Contact?.id
                            }
                          : ''
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contact Name*"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(user) => {
                        return user && user?.name
                          ? user?.name
                          : user?.firstName && user?.lastName
                          ? `${user?.firstName} ${user?.lastName}`
                          : '';
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, contactId: true })
                      }
                      onChange={(event, newValue) => {
                        if (newValue) setContactId(newValue.id);
                      }}
                      onInputChange={(event, newValue) => {
                        setSearchUser(newValue);
                      }}
                      onFocus={() => {
                        setSearchUser('');
                      }}
                    />
                    {validation.contactId && !isRequired(contactId) ? (
                      <Typography color="error">
                        Contact is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="ntn"
                    label={`${
                      relationType === 'VENDOR' ? 'Carrier' : 'Company'
                    } Tax Identification Number (TIN)*`}
                    type="text"
                    variant="outlined"
                    value={ntn}
                    onChange={(e) => setNtn(e.target.value)}
                    onBlur={() => setValidation({ ...validation, ntn: true })}
                  />
                  {validation.ntn && !isRequired(ntn) ? (
                    <Typography color="error">
                      {relationType === 'VENDOR' ? 'Carrier' : 'Company'} Tax
                      Identification Number (TIN) is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>

                {relationType === 'VENDOR' && (
                  <Grid item xs={12}>
                    <Grid item container>
                      <Grid item xs={12} className="pt-8">
                        <InputLabel>
                          <b>Carrier Type</b>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup row id="vendorType" name="vendorType">
                          {vendorTypes?.map((type, index) => {
                            return (
                              <div key={index} className="display-block mr-10">
                                <FormControlLabel
                                  className="mr-10"
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        setVendorType({
                                          ...vendorType,
                                          [type.label]: e.target.checked
                                        })
                                      }
                                      checked={vendorType[type.label]}
                                    />
                                  }
                                  label={type?.label}
                                />
                              </div>
                            );
                          })}
                        </FormGroup>
                        {validation.vendorType &&
                        !vendorType.Logistics &&
                        !vendorType.Technology &&
                        !vendorType.Warehousing ? (
                          <Typography color="error">
                            Carrier type is required
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                    {validation.vendorType && !isRequired(vendorType) ? (
                      <Typography color="error">
                        Vendor Type is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                )}

                {relationType === 'CUSTOMER' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        fullWidth={true}
                        margin="dense"
                        rows={6}
                        id="billingAddress"
                        label="Billing Address*"
                        type="text"
                        variant="outlined"
                        InputProps={{ inputProps: { maxLength: 1000 } }}
                        value={billingAddress}
                        onChange={(e) => setBillingAddress(e.target.value)}
                      />
                      {validation.billingAddress &&
                      !isRequired(billingAddress) ? (
                        <Typography color="error">
                          Billing Address is required!
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs={12} className="text-right">
                      <Checkbox
                        checked={shipAddrAsBillAddr}
                        onChange={(e) =>
                          setShipAddrAsBillAddr(e.target.checked)
                        }
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      Same as Billing Address
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        multiline
                        fullWidth={true}
                        margin="dense"
                        rows={6}
                        id="shippingAddress"
                        label="Shipping Address*"
                        type="text"
                        variant="outlined"
                        InputProps={{ inputProps: { maxLength: 1000 } }}
                        value={shippingAddress}
                        onChange={(e) => setShippingAddress(e.target.value)}
                        disabled={shipAddrAsBillAddr}
                      />
                      {validation.shippingAddress &&
                      !isRequired(shippingAddress) ? (
                        <Typography color="error">
                          Shipping Address is required!
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>

                    <Grid item xs={8}>
                      <TextField
                        fullWidth={true}
                        inputProps={{ className: classes.textBox }}
                        className={classes.labelBox}
                        margin="dense"
                        id="paymentDays"
                        label="Payment Terms"
                        type="number"
                        variant="outlined"
                        value={paymentTerms}
                        onChange={(e) => setPaymentTerms(e.target.value)}
                        onBlur={() =>
                          setValidation({ ...validation, paymentTerms: true })
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid item container spacing={2}>
                        <Grid item xs={8}>
                          <TextField
                            fullWidth={true}
                            inputProps={{ className: classes.textBox }}
                            className={classes.labelBox}
                            margin="dense"
                            id="creditLimit"
                            label="Credit Limit"
                            type="number"
                            variant="outlined"
                            value={creditLimit}
                            onChange={(e) => setCreditLimit(e.target.value)}
                            onBlur={() =>
                              setValidation({
                                ...validation,
                                creditLimit: true
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                          >
                            <Autocomplete
                              id="currency"
                              name="currency"
                              options={currencyOpt}
                              value={currency}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Currency"
                                  variant="outlined"
                                />
                              )}
                              getOptionLabel={(curr) => curr}
                              onChange={(e, value) => {
                                if (value) {
                                  setCurrency(value);
                                }
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              {selectedCompany && relationType == 'CUSTOMER' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      margin="dense"
                      fullWidth={true}
                      variant="outlined"
                    >
                      <Autocomplete
                        id="pocUserId"
                        key={pocUsers}
                        options={pocUsers}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="POCUser*"
                            variant="outlined"
                          />
                        )}
                        getOptionLabel={(user) => {
                          if (user)
                            return user && user.name
                              ? user.name
                              : user.firstName && user.lastName
                              ? `${user.firstName} ${user.lastName}`
                              : '';
                        }}
                        // onBlur={(e) => setValidation({ ...validation, pocUserId: true })}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setPocUserId(newValue.id);
                          } else {
                            setPocUserId(null);
                          }
                        }}
                        defaultValue={
                          !!selectedCompany && selectedCompany.pocUser
                            ? {
                                name: `${selectedCompany.pocUser.firstName} ${selectedCompany.pocUser.lastName}`,
                                id: selectedCompany.pocUser.id
                              }
                            : ''
                        }
                      />
                      {/* {validation.pocUserId && pocUserId === null && isActive === true ? (
                        <Typography color="error">POC user is required when company is active!</Typography>
                      ) : (
                        ""
                      )} */}
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth={true}
                    margin="dense"
                    rows={6}
                    id="notes"
                    label="Notes"
                    type="text"
                    variant="outlined"
                    InputProps={{ inputProps: { maxLength: 1000 } }}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                  <Typography style={{ color: '#1d1d1d', fontSize: 12 }}>
                    Max Length (1000 characters)
                  </Typography>
                </Grid>
              </Grid>

              <p>&nbsp;</p>
              {relationType == 'CUSTOMER' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="#03a9f4">
                      <strong>Note</strong>: Company logo needs to be 142 px x
                      37px or smaller. Size should be less than 1 MB. Only .jpg,
                      .jpeg or .png formats are allowed.
                    </Typography>
                    <p>&nbsp;</p>
                    <FormControl
                      margin="dense"
                      fullWidth={true}
                      variant="outlined"
                    >
                      <Tooltip
                        title={
                          relationType == 'CUSTOMER'
                            ? ` Upload Company Logo Image`
                            : ` Upload Carrier Logo Image`
                        }
                      >
                        <Button
                          variant="contained"
                          component="label"
                          color={logoImageSrc ? 'primary' : 'default'}
                          startIcon={<CloudUploadIcon />}
                        >
                          {relationType == 'CUSTOMER'
                            ? ` Company Logo Image`
                            : ` Carrier Logo Image`}{' '}
                          {logoImageSrc ? 'Uploaded' : ''}
                          <input
                            type="file"
                            hidden
                            value={(e) => e.target.value + 1}
                            onChange={(e) => newValidateLogoImage(e)}
                            accept=".jpg,.png,.jpeg"
                          />
                          {/* <img id="previewImage" src="#" alt="Company Logo" /> */}
                        </Button>
                      </Tooltip>
                      {logoSize == true ? (
                        <Typography color="error">
                          Logo image size should be less than 1 MB
                        </Typography>
                      ) : (
                        ''
                      )}
                      {logoType == true ? (
                        <Typography color="error">
                          Logo image accepted formats are .jpg, .jpeg or .png
                        </Typography>
                      ) : (
                        ''
                      )}
                      {logoDimension == true ? (
                        <Typography color="error">
                          Logo image dimensions should be 142 px x 37 px or
                          smaller
                        </Typography>
                      ) : (
                        ''
                      )}
                      {/* {!(selectedCompany && selectedCompany.logoId) && validation.logoImage && !isRequired(logoImage) ? <Typography color="error">Logo image is required!</Typography> : ''} */}
                    </FormControl>

                    <Grid style={{ textAlign: 'center' }}>
                      {!logoImageSrc ? (
                        ''
                      ) : (
                        <Grid item xs={12} style={{ marginLeft: 380 }}>
                          <DeleteSharpIcon onClick={() => removePreviewId()} />
                        </Grid>
                      )}
                      {logoImageSrc ? (
                        <img id="previewImage" src={logoImageSrc} />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {selectedCompany ||
              relationType === 'VENDOR' ||
              relationType === 'CUSTOMER' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Checkbox
                      checked={isActive}
                      onChange={(e) => setActive(e.target.checked)}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    Active
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </DialogContent>
          <DialogActions className="vendorBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  setExplicitReRender(!explicitReRender);
                  // setValidation('')
                  handleClose();
                  resetStates();
                  setValidation('');
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>

            <Tooltip
              title={
                !selectedCompany
                  ? `Add ${relationType == 'CUSTOMER' ? 'Company' : 'Carrier'}`
                  : `Update ${
                      relationType == 'CUSTOMER' ? 'Company' : 'Carrier'
                    }`
              }
            >
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                color="primary"
                variant="contained"
                disabled={attemptedAlready}
              >
                {!selectedCompany
                  ? `Add ${relationType == 'CUSTOMER' ? 'COMPANY' : 'CARRIER'}`
                  : `Update ${
                      relationType == 'CUSTOMER' ? 'COMPANY' : 'CARRIER'
                    }`}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import React, { useRef, useState } from 'react';

import PAPERCLIP_ICON from '../../assets/icons/paperclip.svg';
import UploadDocIcon from '../../assets/icons/uploadDocument.svg';
import CancelIcon from '../../assets/icons/cancelIcon.svg';
import Button from '../atoms/Button';
import { Grid } from '@mui/material';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { toaster } from '../../utils/toaster';

const SupportingDocumentsButton = ({ formik, propertyName }) => {
  const inputRef = useRef(null);
  const propertyNames = propertyName?.split('.') || 0;

  const uploadFiles = async (event) => {
    const newFiles = event.target.files;
    // Assuming maxSize is the maximum allowed total file size in bytes
    const maxSize = 1024 * 1024 * 5; // 5 MB (adjust as needed)

    // Calculate the total size of all files
    const totalSize = Array.from(newFiles).reduce(
      (acc, file) => acc + file.size,
      0
    );

    // Check if the total size is within the allowed limit
    if (totalSize <= maxSize) {
      formik?.setFieldValue(propertyName, [
        ...(propertyNames.length === 1
          ? formik.values[propertyName]
          : formik.values[propertyNames[0]][propertyNames[1]] || []),
        ...newFiles
      ]);
    } else {
      toaster('error', 'The combined size of the files is more than 5MB.');
    }
  };

  return (
    <>
      <Button
        variant="tertiary"
        size="medium"
        label="Attach"
        onClick={() => inputRef.current.click()}
        icon={<img src={PAPERCLIP_ICON} alt="Paperclip Icon" />}
      />
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={(e) => uploadFiles(e)}
        accept=".jpg,.png,.jpeg,.pdf,.xlsx"
        multiple
      />
    </>
  );
};

export const formatFileName = (file) => {
  if (file.id) {
    let ext = file.originalName?.split('.')?.pop();
    return `${file.originalName?.substring(0, 6)}...${ext}`;
  } else {
    let ext = file.name?.split('.')?.pop();
    return `${file.name?.substring(0, 6)}...${ext}`;
  }
};

const SupportingDocumentUpload = ({ formik, propertyName, title }) => {
  const [showMore, setShowMore] = useState(false);

  let docs = formik.values[propertyName];
  if (!showMore) {
    docs = docs?.slice(0, 6);
  }

  return (
    <Grid container className="pt-3 pb-3 mt-3">
      <Grid item xs={4} className="flex flex-col gap-1">
        <p className="font-semibold">{title || 'Upload Proof of Document'}</p>
        <p className="text-xs">Files must be pdf, jpeg & png upto 5 MB</p>
      </Grid>
      <Grid
        item
        xs={8}
        className="flex items-center justify-between border border-dashed p-4 border-[#C4C6CB]"
      >
        {docs?.length == 0 ? (
          <p className="flex gap-1">
            <img src={UploadDocIcon} className="mr-2" /> Upload a picture or
            document
          </p>
        ) : (
          <div className="flex gap-2">
            <div className="grid grid-cols-2 gap-4">
              {docs?.map((doc, id) => (
                <div
                  key={id}
                  className="rounded-full w-[140px] justify-between text-xs pt-2 pb-2 pl-3 pr-3 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                  onClick={async (e) => {
                    e.stopPropagation();
                    let fileUrl;
                    if (!doc?.location) fileUrl = URL.createObjectURL(doc);
                    else {
                      fileUrl = doc.location;
                    }
                    const a = document.createElement('a');
                    a.href = fileUrl;
                    a.target = '_blank';
                    a.click();
                  }}
                >
                  <p className="text-primary">{formatFileName(doc)}</p>
                  <div
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (formik.values[propertyName]?.length > 1) {
                        let updatedDocs = [...formik.values[propertyName]];
                        updatedDocs = updatedDocs.filter((v, i) => i != id);
                        formik.setFieldValue(propertyName, updatedDocs);
                      } else {
                        formik.setFieldValue(propertyName, []);
                      }
                    }}
                  >
                    <img src={CancelIcon} className="w-2 h-2" />
                  </div>
                </div>
              ))}
            </div>
            {formik.values[propertyName]?.length > 6 && (
              <div className="flex items-end">
                {showMore ? (
                  <span
                    className="text-primary text-xs font-medium cursor-pointer"
                    onClick={() => setShowMore(false)}
                  >
                    Show less <ExpandLess className="ml-1" />{' '}
                  </span>
                ) : (
                  <span
                    className="text-primary text-xs font-medium cursor-pointer"
                    onClick={() => setShowMore(true)}
                  >
                    Show more <ExpandMore className="ml-1" />
                  </span>
                )}
              </div>
            )}
          </div>
        )}
        <SupportingDocumentsButton
          formik={formik}
          propertyName={propertyName}
        />
      </Grid>
    </Grid>
  );
};

export default SupportingDocumentUpload;

import React from 'react';
import { Grid, Button, makeStyles, Dialog } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';

import { productLabelFormat } from '../../utils/common';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  bdGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  btnYes: {
    marginLeft: 10,
    color: 'white',
    backgroundColor: '#4B89C8',
    '&:hover': {
      backgroundColor: '#4B89C8'
    }
  },
  textStyle: {
    fontSize: '20px !important',
    fontWeight: 'bold',
    textAlign: 'left !important',
    padding: '20px 30px'
  },
  liStyle: {
    paddingLeft: '3%'
  }
}));

const ShelfLifeView = ({
  open,
  addProduct,
  productDetail,
  handleClose,
  existingMRP = '',
  alternativeDescription = false
}) => {
  const classes = useStyles();
  let description =
    'You are about to Receive / Dispatch a stock which does not match Shelf Life SLA or is already expired.Details are as below : ';
  const MRP_MESSAGE = `Are you sure you want to update the Product MRP (Rs) to ${productDetail?.MRP} or want to go with existing Product MRP (Rs) ${existingMRP} in the inventory?`;

  return (
    <>
      {open && (
        <Dialog className={classes.backdrop} open={open}>
          <Grid
            container
            className={classes.bdGrid}
            justifyContent="flex-start"
          >
            <p className={classes.textStyle}>
              {alternativeDescription ? MRP_MESSAGE : description}
            </p>
            {productDetail?.Product && !alternativeDescription ? (
              <li className={classes.liStyle}>
                {`${productLabelFormat(productDetail.Product)} Batch ${
                  productDetail?.batchNumber
                } is below ${productDetail.Product.shelfLife} days shelf life.`}
              </li>
            ) : null}
          </Grid>
          <div className="mt-5 mb-5 display-flex justify-content-center">
            <Button
              autoFocus
              variant="contained"
              onClick={() => handleClose(false)}
            >
              NO
            </Button>
            <Button
              variant="contained"
              className={`${classes.btnYes} ml-5`}
              onClick={addProduct}
              startIcon={<DoneIcon />}
            >
              YES
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ShelfLifeView;

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import mixpanel from 'mixpanel-browser';
import TableHeader from '../../../components/TableHeader';
import { checkPermission } from '../../../utils/auth';
import { SharedContext, dateFormat } from '../../../utils/common';

import { DataGrid } from '@mui/x-data-grid';
import classNames from 'classnames/bind';
import { debounce } from 'lodash';
import { MdFilterAlt, MdOutlineNorth, MdOutlineSouth } from 'react-icons/md';
import { DEBOUNCE_CONST } from '../../../Config';
import Filter from '../../../components/Filter';
import PaginationGrid from '../../../components/PaginationGrid';
import SearchInput from '../../../components/SearchInput';
import FilterButton from '../../../components/stats/FilterButton';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { OASIS_PRIMARY_600 } from '../../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInputStockManagement: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  addStockButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  backdropGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px'
  },
  backdropTitle: {
    fontSize: 24,
    marginBottom: 18
  },
  backdropAgreeButton: {
    marginLeft: 10
  },
  parentDataTable: {
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      transform: 'none !important'
    }
  }
}));

export default function StockManagementView() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [inventoryWastages, setInventoryWastages] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState('');

  // Filters
  // eslint-disable-next-line no-unused-vars
  const [customerWarehouses] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [customerProducts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedProduct, setSelectedProduct] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [companies, setCompanies] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { currentUser } = useContext(SharedContext);
  const [filterView, setFilterView] = useState(false);
  const [internalIdForBusinessVal, setInternalIdForBusinessVal] = useState('');
  const [sortable, setSortable] = useState('');
  const [fetchState, setFetchState] = useState('');

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const isPhone = useMediaQuery('(max-width:915px)');

  const columnsNew = [
    {
      field: 'ADJUSTMENT ID',
      headerName: 'Adjustment ID',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Adjustment ID
            {fetchState && internalIdForBusinessVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setInternalIdForBusinessVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('internalIdForBusiness');
              }}
              className={
                sortable.col == 'internalIdForBusiness' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'internalIdForBusiness' &&
              sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          if (params.row.customId) {
            result.push(params.row.customId);
          }
        }
        return result.join(', ');
      }
    },
    {
      field: 'createdAt',
      headerName: 'Adjustment Date',
      width: 180,
      disableColumnMenu: true,
      sortable: false,
      format: dateFormat,
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          if (params.row.createdAt) {
            result.push(dateFormat(params.row.createdAt));
          }
        }
        return result.join(', ');
      }
    },
    {
      field: 'Inventory',
      headerName: 'No. Of Products',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          result.push(params.row.Inventories?.length || '');
        }
        return result.join(', ');
      }
    },
    {
      field: 'Type',
      headerName: 'Adjustment Type',
      width: 180,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (param) => {
        if (param.value == 'Positive') {
          return (
            <span className="w-6 h-6 text-white bg-success rounded-full flex justify-center items-center">
              +
            </span>
          );
        } else if (param.value == 'Negative') {
          return (
            <span className="w-6 h-6 text-white bg-error rounded-full flex justify-center items-center">
              -
            </span>
          );
        } else {
          return (
            <div className="flex gap-2">
              <span className="w-6 h-6 text-white bg-success rounded-full flex justify-center items-center">
                +
              </span>
              <span className="w-6 h-6 text-white bg-error rounded-full flex justify-center items-center">
                -
              </span>
            </div>
          );
        }
      },
      valueGetter: (params) => {
        let result = [];
        let type = '';
        if (params.row) {
          if (params.row.AdjustmentInventory.length > 0) {
            params.row.AdjustmentInventory.forEach((ai) => {
              ai.StockAdjustmentBatch.forEach((sb) => {
                if (!type) {
                  type = sb.type == 5 ? 'Positive' : 'Negative';
                } else {
                  let newType = sb.type == 5 ? 'Positive' : 'Negative';
                  if (type !== newType && type !== 'Both') {
                    type = 'Both';
                  }
                }
              });
            });
          }
        }
        result.push(type);
        return result.join(', ');
      }
    },
    {
      field: 'creator',
      headerName: 'Created By',
      width: isPhone ? 140 : 400,
      disableColumnMenu: true,
      sortable: true,
      valueGetter: (params) => {
        let result = [];
        if (params.row.User) {
          if (params.row.User.firstName) {
            result.push(
              `${params.row.User.firstName} ${params.row.User.lastName}`
            );
          }
        }
        return result.join(', ');
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'driverDatatableActions',
      cellClassName: 'justify-content-right',
      renderCell: (params) => (
        <>
          {
            <Tooltip title="View">
              <VisibilityIcon
                key="view"
                onClick={() => navigate(`view/${params.row.customId}`)}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          }
        </>
      )
    }
  ];

  const [muiLoader, setMuiLoader] = useState(false);

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const _getinventoryWastages = async (
    page,
    searchKeyword,
    filters,
    columns
  ) => {
    try {
      setMuiLoader(true);
      const { data, pages } = await API.get(`stock-adjustments`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setPageCount(pages);
      setInventoryWastages(data);
    } catch (err) {
      onError(err);
    } finally {
      setMuiLoader(false);
    }
  };

  const getinventoryWastages = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        internalIdForBusiness: internalIdForBusinessVal
          ? internalIdForBusinessVal
          : '',
        sortable
      };
      let columns = ['internalIdForBusiness'];
      _getinventoryWastages(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getinventoryWastages(page, searchKeyword);
  }, [page, searchKeyword]);

  useEffect(() => {
    fetchRes();
  }, [internalIdForBusinessVal, sortable]);

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (internalIdForBusinessVal) {
      colVal = { internalIdForBusiness: internalIdForBusinessVal };
    }
    let filters = {
      colVal,
      sortable
    };
    let columns = ['internalIdForBusiness'];
    _getinventoryWastages(page, searchKeyword, filters, columns);
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = "Stock's Adjustments";
    mixpanel.track('Stock Adjustment', {});
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    // DONE: call stock mang API
    getinventoryWastages(
      page,
      searchKeyword,
      selectedWarehouse,
      selectedProduct,
      selectedCompany
    );
  }, [
    page,
    searchKeyword,
    selectedWarehouse,
    selectedProduct,
    selectedCompany
  ]);

  function CustomStockManagementToolbar() {
    return (
      <FilterButton searchKeyword openFilterView={() => openFilterView()} />
    );
  }

  const searchInputStockManagement = (
    <SearchInput
      mainClass={mainClass}
      searchKeyword={searchKeyword}
      setSearchKeyword={setSearchKeyword}
      setPage={() => setPage(1)}
    />
  );

  //Input Objects for filters
  let internalIdForBusinessObj = {
    type: 'text',
    className: 'textField',
    label: 'Adjustment ID',
    field: 'internalIdForBusiness',
    value: internalIdForBusinessVal ? internalIdForBusinessVal : '',
    setNameVal: (e) => setNameVal(setInternalIdForBusinessVal, e)
  };

  //Custom modal for filter....
  const filterCols = [internalIdForBusinessObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'Driver'}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  // const headerButtons = [addStockMangementButton];
  const headerButtons = [
    // checkPermission(currentUser, 'OPS_STOCK_ADJUSTMENT_CREATE')
    //   ? addStockMangementButton
    //   : null,
    checkPermission(currentUser, 'OPS_STOCK_ADJUSTMENT_READ')
      ? searchInputStockManagement
      : null,
    checkPermission(currentUser, 'OPS_STOCK_ADJUSTMENT_READ')
      ? filterModal
      : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table">
          <TableBody>
            <div style={{ height: 1100, width: '100%' }}>
              <DataGrid
                rows={inventoryWastages}
                hiddenColumnModel={['id']}
                columns={columnsNew}
                components={{ Toolbar: CustomStockManagementToolbar }}
                pageSize={20}
                rowsPerPageOptions={[6]}
                className={classes.parentDataTable}
                loading={muiLoader}
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
        customBtnColor={OASIS_PRIMARY_600}
      />
    </Paper>
  );
}

/*eslint-disable*/
import { useContext, useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
// import { useNavigate } from 'react-router';
import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import { useCallback } from 'react';
import clsx from 'clsx';

import { getVehicleTypes } from '../../../apis/tms';
import { ORGANIZATION_TYPES } from '../../../constants';
import { DEBOUNCE_CONST } from '../../../Config';
import { SharedContext, toSuperscript } from '../../../utils/common';
import { TableBody, Tooltip } from '@mui/material';
import Header from './components/VehicleTypeHeader';
import AddVehicleTypePopup from './components/vehicleTypePopup';
import EditIcon from '../../../assets/icons/editIconDark.svg';

const filterInitialState = {
  Category: [],
  Status: []
};

const VehicleTypeView = () => {
  const { setCurrentPageTitle, organizationType, settings } =
    useContext(SharedContext);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [vehicleTypeCount, setVehicleTypeCount] = useState(0);
  const [contentHover, setContentHover] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [addVehicleTypeView, setAddVehicleTypeView] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [filters, setFilters] = useState(filterInitialState);
  const [columnNames, setColumnNames] = useState([
    'Vehicle Type',
    'Category',
    `Capacity Volume (m${toSuperscript(3)})`,
    `Payload Weight (${settings?.weight?.symbol || 'Kg'})`,
    `Vehicle Weight (${settings?.weight?.symbol || 'Kg'})`,
    `Max Gross Vehicle Weight (${settings?.weight?.symbol || 'Kg'})`
  ]);

  const tableContainerRef = useRef(null);

  useEffect(() => {
    setCurrentPageTitle('Vehicle Type');
    if (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER) {
      setColumnNames([
        'Vehicle Type',
        'Category',
        `Capacity Volume (m${toSuperscript(3)})`,
        `Payload Weight (${settings?.weight?.symbol || 'Kg'})`,
        `Vehicle Weight (${settings?.weight?.symbol || 'Kg'})`,
        `Max Gross Vehicle Weight (${settings?.weight?.symbol || 'Kg'})`
      ]);
    }
  }, [organizationType]);

  const { refetch, isFetching: vehicleTypeLoading } = useQuery(
    ['fetchVehicleTypes', {}],
    () => {
      const payload = {
        page,
        searchKeyword,
        filters
      };
      return getVehicleTypes(payload);
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data) {
          if (page === 1) {
            setVehicleTypes(data.data);
            setVehicleTypeCount(data.count);
            setPageCount(data.pages);
          } else {
            setVehicleTypes([...vehicleTypes, ...data.data]);
            setVehicleTypeCount(data.count);
            setPageCount(data.pages);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const _getVehicleTypes = useCallback(
    debounce(() => {
      refetch();
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    _getVehicleTypes();
  }, [page, searchKeyword, filters]);

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !vehicleTypeLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
    }
  };

  const setFilter = (key, value, type) => {
    let currentFilters = { ...filters };
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    setFilters(currentFilters);
  };

  // const classes = useStyles();

  const tableCellClass = 'py-2 h-12 pl-0 pr-2';
  const cellValue = 'table-cell-trucate  text-normal';
  const cellSubValue = clsx(cellValue, 'text-[10px]');
  const topValue = clsx(cellValue, 'mb-[2px]', 'text-xs');
  const digitValue = clsx(cellValue, 'mt-[2px]', 'text-xs');

  return (
    <div className="no-scrollbar overflow-hidden">
      <div className="w-full mt-10 pl-8 pr-6">
        <Header
          onClick={() => setAddVehicleTypeView(true)}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          headerText={'All Vehicle Types'}
          addBtnLabel={'Add Vehicle Type'}
          searchPlaceHolder={'Search Vehicle Type'}
          setFilter={setFilter}
          filters={filters}
        />
        <div className="mb-5">
          <Paper className="no-scrollbar rounded-t-lg shadow-none">
            <TableContainer
              onMouseEnter={() => {
                setContentHover(true);
              }}
              onMouseLeave={() => {
                setContentHover(false);
              }}
              style={{ overflow: 'auto' }}
              ref={tableContainerRef}
              onScroll={handleTableScroll}
              className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
            >
              <Table stickyHeader>
                <TableHead className="z-40 relative">
                  <TableRow>
                    <TableCell
                      key={'checkbox'}
                      className="bg-white text-dark300 text-base py-2 px-6"
                    ></TableCell>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className="text-[10px] text-dark500 bg-white py-2 pl-0 pr-2 font-normal h-[40px]"
                      >
                        {columnName}
                      </TableCell>
                    ))}
                    <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicleTypes.map((row, index) => (
                    <TableRow
                      key={index}
                      onMouseEnter={() => {
                        setHoveredRow(index);
                      }}
                      onMouseLeave={() => {
                        setHoveredRow(-1);
                      }}
                      className={clsx(
                        'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                      )}
                      onClick={() => {}}
                    >
                      <TableCell className="rounded-none text-dark300 py-4 px-6 ">
                        <div
                          className={`h-[6px] w-[6px] rounded-full ${
                            row.isActive
                              ? 'border-[#1AAE2E] bg-[#1AAE2E]'
                              : 'border border-solid border-[#9DA3B3]'
                          }`}
                        />
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip
                          title={
                            row?.name
                              ? row.name
                              : `${row?.CarMake?.name} - ${row?.CarModel?.name}`
                          }
                        >
                          <p className={clsx(cellSubValue)}>
                            <span>
                              {row?.name ||
                                `${row?.CarMake?.name} - ${row?.CarModel?.name}`}
                            </span>
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip title={`${row?.Category?.name}`}>
                          <p className={clsx(cellSubValue)}>
                            {`${row?.Category?.name || '-'}`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip title={`${row?.VehicleType?.volume}`}>
                          <p className={clsx(cellSubValue)}>
                            {`${row?.VehicleType?.volume || '-'}`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip
                          title={`${row?.VehicleType?.payloadWeight} ${settings}`}
                        >
                          <p className={clsx(cellSubValue)}>
                            {`${row?.VehicleType?.payloadWeight || '-'}`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip
                          title={`${row?.VehicleType?.weight} ${settings?.weight?.symbol}`}
                        >
                          <p className={clsx(cellSubValue)}>
                            {`${row?.VehicleType?.weight || '-'}`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip
                          title={`${
                            !row?.VehicleType?.weight &&
                            !row?.VehicleType?.payloadWeight
                              ? '-'
                              : row?.VehicleType?.payloadWeight ||
                                0 + row?.VehicleType?.weight ||
                                0
                          } Kg`}
                        >
                          <p className={clsx(cellSubValue)}>
                            {`${
                              !row?.VehicleType?.weight &&
                              !row?.VehicleType?.payloadWeight
                                ? '-'
                                : row?.VehicleType?.payloadWeight ||
                                  0 + row?.VehicleType?.weight ||
                                  0
                            }`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        {hoveredRow == index && (
                          <div
                            className="flex gap-2"
                            onClick={() => {
                              setSelectedVehicleType(row);
                              setAddVehicleTypeView(true);
                            }}
                          >
                            <img src={EditIcon} />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        {addVehicleTypeView && (
          <AddVehicleTypePopup
            open={addVehicleTypeView}
            setOpen={setAddVehicleTypeView}
            organizationType={organizationType}
            selectedVehicleType={selectedVehicleType}
            refetch={refetch}
            setSelectedVehicleType={setSelectedVehicleType}
          />
        )}
      </div>
    </div>
  );
};

export default VehicleTypeView;

import { useEffect, useState, useCallback, useContext } from 'react';
import {
  makeStyles,
  Paper,
  Button,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@material-ui/core';
import { MdFilterAlt, MdOutlineNorth, MdOutlineSouth } from 'react-icons/md';
import classNames from 'classnames/bind';
import { DataGrid } from '@mui/x-data-grid';
import TableHeader from '../../../components/TableHeader';
import SearchInput from '../../../components/SearchInput';
import axios from 'axios';
import { getURL } from '../../../utils/common';
import AddRoleView from './AddRoleView';
import EditRoleView from './EditRoleView';
import WarehouseRoleView from './WarehouseRoleView';
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/EditOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import ConfirmDelete from '../../../components/ConfirmDelete';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import PaginationGrid from '../../../components/PaginationGrid';
import { checkPermission } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px'
  },
  container: {
    padding: 20
  },
  searchInputRole: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  addRoleButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  },
  active: {
    color: theme.palette.success.main
  }
}));
export default function RoleView() {
  const classes = useStyles();
  const { isFeatureEnabled } = useFeatureFlags();

  const columnsNew = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Name
            {fetchState && roleNameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  type="button"
                  onClick={() => {
                    setRoleNameVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          {isFeatureEnabled(FLAGS.USER_PERMISSION_ACCESS) &&
          checkPermission(currentUser, 'OPS_ROLE_UPDATE') ? (
            <Tooltip title="Edit Role">
              <EditIcon
                key={'edit' + params.row}
                onClick={() => openEditView(params.row)}
              />
            </Tooltip>
          ) : (
            ''
          )}
          {isFeatureEnabled(FLAGS.USER_PERMISSION_ACCESS) &&
          checkPermission(currentUser, 'OPS_ROLE_UPDATE') ? (
            <Tooltip title="Warehouse Permissions">
              <BusinessIcon
                key={'edit-warehouse' + params.row}
                onClick={() => openWarehouseView(params.row)}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [portals, setPortals] = useState([]);
  const [formErrors, setFormErrors] = useState('');
  const [addUserViewOpen, setAddUserViewOpen] = useState(false);
  const [editUserViewOpen, setEditUserViewOpen] = useState(false);
  const [warehouseViewOpen, setWarehouseViewOpen] = useState(false);
  const [deleteUserViewOpen, setDeleteUserViewOpen] = useState(false);
  const { currentUser, subdomain, setAPILoader } = useContext(SharedContext);

  const [fetchState, setFetchState] = useState(false);
  const [sortable, setSortable] = useState('');
  const [roleNameVal, setRoleNameVal] = useState('');
  const [clickedAlready, setClickedAlready] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const addRole = async (data) => {
    try {
      setClickedAlready(true);
      setAPILoader(true);
      if (!selectedUser) await API.post(`roles`, data);
      else await API.put(`roles/${data.roleId}`, data);
      !selectedUser
        ? (toaster(
            'success',
            'Role has been created. Please edit the role to give appropriate permissions'
          ),
          mixpanel.track('Role Created', {}))
        : (toaster('success', 'Role updated successfully.'),
          mixpanel.track('Role Updated', {
            roleId: data?.roleId
          }));
    } catch (err) {
      if (err.response.status == 400) {
        toaster('warning', err.response.data.message);
      } else {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          onError(data);
        });
      }
    } finally {
      closeAddUserView();
      getRoles();
      setClickedAlready(false);
      setAPILoader(false);
    }
  };

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'Role(s)';
    setCurrentPageTitle(title);
    mixpanel.track('Role Page', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  const addPermissionToRole = async (data) => {
    try {
      setAPILoader(true);
      if (data.roleId) await API.post(`permission-accesses`, data);
      toaster('success', 'Role new permissions attached successfully.');
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const removePermissionToRole = async (data) => {
    try {
      if (data.roleId) await API.delete(`permission-accesses`, { data });
      toaster('success', 'Role new permissions unattached successfully.');
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const addWarehoseToRole = async (data) => {
    setFormErrors('');
    try {
      setAPILoader(true);
      await API.post(`permission-accesses/role-warehouse`, data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      toaster('success', 'Warehouse list updated successfully.');
      mixpanel.track('Add Warehouse To Role', {});
      closeWarehouseRoleView();
      getRoles();
      setAPILoader(false);
    }
  };

  const deleteRole = () => {
    axios.delete(getURL(`user/${selectedUser.id}`)).then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      closeDeleteUserView();
      getRoles();
    });
  };

  const _getRolesAndPortals = async () => {
    try {
      const rolesAndPortals = await API.get(
        `organizations/${subdomain}/users/user-portal-roles`
      );
      setPortals(rolesAndPortals.portals);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    }
  };

  const openEditView = (user) => {
    setSelectedUser(user);
    setEditUserViewOpen(true);
  };

  const openWarehouseView = (user) => {
    setSelectedUser(user);
    setWarehouseViewOpen(true);
  };

  const closeAddUserView = () => {
    setSelectedUser(null);
    setAddUserViewOpen(false);
  };

  const closeEditRoleView = async () => {
    setSelectedUser(null);
    setEditUserViewOpen(false);
    getRoles();
  };

  const closeWarehouseRoleView = async () => {
    setSelectedUser(null);
    setWarehouseViewOpen(false);
    getRoles();
  };

  const closeDeleteUserView = () => {
    setSelectedUser(null);
    setDeleteUserViewOpen(false);
  };

  const _getRoles = async (page, searchKeyword, filters, columns) => {
    try {
      setMuiLoader(true);
      const { data, pages } = await API.get(`roles`, {
        params: { offset: page, page, search: searchKeyword, filters, columns }
      });
      setPageCount(pages);
      const rolesAll = data.map((d) => {
        const role = { ...d, id: d.roleId };
        return role;
      });
      setRoles(rolesAll);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setMuiLoader(false);
    }
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (roleNameVal) {
      colVal = { name: roleNameVal };
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = ['name'];

    _getRoles(page, searchKeyword, filters, columns);
  };

  useEffect(() => {
    fetchRes();
  }, [roleNameVal, sortable]);

  const getRoles = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        name: roleNameVal ? roleNameVal : '',
        sortable
      };

      let columns = ['name'];
      _getRoles(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getRoles(page, searchKeyword);
  }, [page, searchKeyword]);

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const searchInputRole = (
    <SearchInput
      mainClass={mainClass}
      searchKeyword={searchKeyword}
      setSearchKeyword={setSearchKeyword}
      setPage={() => setPage(1)}
    />
  );

  useEffect(() => {
    if (subdomain) _getRolesAndPortals();
  }, [subdomain]);

  const addRoleButton = (
    <Tooltip title="Add Role">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addRoleButton}
        size="small"
        onClick={() => setAddUserViewOpen(true)}
      >
        ADD ROLE
      </Button>
    </Tooltip>
  );

  const addRoleModal = (
    <AddRoleView
      key={3}
      formErrors={formErrors}
      portals={portals}
      selectedUser={selectedUser}
      open={addUserViewOpen}
      addRole={addRole}
      clickedAlready={clickedAlready}
      setClickedAlready={setClickedAlready}
      handleClose={() => closeAddUserView()}
    />
  );

  const editRoleModal = (
    <EditRoleView
      key={4}
      formErrors={formErrors}
      portals={portals}
      selectedUser={selectedUser}
      open={editUserViewOpen}
      addRole={addRole}
      addPermissionToRole={addPermissionToRole}
      removePermissionToRole={removePermissionToRole}
      currentUser={currentUser}
      handleClose={() => closeEditRoleView()}
    />
  );

  const editWarehouseModal = (
    <WarehouseRoleView
      key={10}
      formErrors={formErrors}
      selectedUser={selectedUser}
      open={warehouseViewOpen}
      addWarehoseToRole={addWarehoseToRole}
      addPermissionToRole={addPermissionToRole}
      removePermissionToRole={removePermissionToRole}
      currentUser={currentUser}
      handleClose={() => closeWarehouseRoleView()}
    />
  );

  const deleteRoleModal = (
    <ConfirmDelete
      key={5}
      confirmDelete={deleteRole}
      open={deleteUserViewOpen}
      handleClose={closeDeleteUserView}
      selectedEntity={
        selectedUser && selectedUser.firstName + ' ' + selectedUser.lastName
      }
      title={'User'}
    />
  );

  const visibleColumns = { name: true, id: false };

  const headerButtons = [
    isFeatureEnabled(FLAGS.ROLES_CREATION) &&
    checkPermission(currentUser, 'OPS_ROLE_CREATE')
      ? addRoleButton
      : null,
    checkPermission(currentUser, 'OPS_ROLE_READ') ? searchInputRole : null,
    checkPermission(currentUser, 'OPS_ROLE_READ') ? editRoleModal : null,
    checkPermission(currentUser, 'OPS_ROLE_READ') ? editWarehouseModal : null,
    checkPermission(currentUser, 'OPS_ROLE_CREATE') ? addRoleModal : null,
    checkPermission(currentUser, 'OPS_ROLE_DELETE') ? deleteRoleModal : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table" className="tableDiv">
          <TableBody>
            <div style={{ height: 1100, width: '100%' }}>
              <DataGrid
                rows={roles}
                hiddenColumnModel={['id']}
                visibleColumns={visibleColumns}
                columns={columnsNew}
                pageSize={20}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
                loading={muiLoader}
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}

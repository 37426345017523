import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { debounce } from 'lodash';

import FormContext from './OrganizationFormContext';
import CancelButton from '../../../components/CancelButton';
import { organizationDetailsValidationSchema } from './formikFields';
import { DEBOUNCE_CONST } from '../../../Config';
import { logError, onError } from '../../../libs/errorLib';
import useStyles from './OrganizationFormStyles';
import FormikCheckbox from '../../../atomicComponents/FormikCheckbox';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import { Title } from '../../../core-components/atoms/Text';
import { Checkbox, FormControlLabel } from '@mui/material';

const OrganizationPermissionForm = ({
  getOrganizationByName,
  getOrganizationBySubdomain,
  edit,
  newOrg
}) => {
  const {
    organizationDetails,
    setOrganizationDetails,
    next,
    prev,
    handleClose,
    reset
  } = useContext(FormContext);
  const { setAPILoader } = useContext(SharedContext);
  const [nameError, setNameError] = useState(false);
  const [subdomainError, setSubdomainError] = useState('');
  const [prevName, setPrevName] = useState('');
  const [prevSubdomain, setPrevSubdomain] = useState('');
  const [intelligencePermissions, setIntelligencePermissions] = useState([]);

  const formik = useFormik({
    initialValues: organizationDetails,
    onSubmit: async (values) => {
      try {
        setAPILoader(true);
        const err = await formik.validateForm();
        !edit &&
          (await API.post('organizations/verify-organization-details', {
            email: values.email
          }));
        if (Object.keys(err).length === 0 && !nameError && !subdomainError) {
          next();
        } else {
          Object.keys(err).forEach((key) => logError(err[key]));
          if (nameError) logError('Organization name already exists');
          if (subdomainError) logError('Organization subdomain already exists');
        }
      } catch (e) {
        logError(e);
      } finally {
        setAPILoader(false);
      }
    },
    validationSchema: organizationDetailsValidationSchema,
    validate: async (values) => {
      const fetchOrganization = debounce(async (values) => {
        if (values.name) {
          if (values.name != prevName) {
            const org = await getOrganizationByName(values.name);
            if (org.organization) {
              setNameError(true);
            } else {
              setNameError(false);
            }
            setPrevName(values.name);
          }
        } else {
          setNameError(false);
        }
        if (values.subdomain) {
          if (values.subdomain != prevSubdomain) {
            const org = await getOrganizationBySubdomain(values.subdomain);
            if (org.organization) {
              setSubdomainError(
                'Organization with this subdomain already exists'
              );
            } else {
              setSubdomainError('');
            }
            setPrevSubdomain(values.subdomain);
          }
          if (/[\W_]/.test(values.subdomain)) {
            setSubdomainError(
              'Organization Subdomain cannot have special characters'
            );
          }
        } else {
          setSubdomainError('');
        }
      }, DEBOUNCE_CONST);
      !edit && (await fetchOrganization(values));
    }
  });

  const classes = useStyles();

  useEffect(() => {
    setOrganizationDetails(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (!newOrg) getIntelligencePermissions(organizationDetails?.subdomain);
    else {
      setIntelligencePermissions(organizationDetails.kpis);
    }
  }, []);

  const onChangeIntelligence = async (event) => {
    setAPILoader(true);
    try {
      if (!newOrg) {
        await API.put(
          `organizations/${organizationDetails?.subdomain}/intelligence-kpis`,
          [
            {
              id: Number(event.target?.name),
              enabled: event.target?.checked
            }
          ]
        );
        await getIntelligencePermissions(organizationDetails?.subdomain);
      } else {
        const kpis = [...organizationDetails.kpis];
        let index = kpis.findIndex((kpi) => kpi.id == event.target?.name);
        kpis[index].enabled = event.target.checked;
        setIntelligencePermissions(kpis);
        formik.setValues({ ...organizationDetails, kpis });
      }
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const getIntelligencePermissions = async (organizationDetails) => {
    try {
      const response = await API.get(
        `organizations/${organizationDetails}/intelligence-kpis`
      );
      setIntelligencePermissions(response?.data);
    } catch (err) {
      onError(err);
    }
  };

  return (
    <div>
      <Title className={'subheader'}>Intelligence Permissions</Title>
      <div className={'margin-left'}>
        {intelligencePermissions.map((value, index) => {
          return (
            <div className={'column'} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value?.enabled}
                    onChange={onChangeIntelligence}
                    name={value?.id}
                  />
                }
                label={value?.name}
                labelPlacement="end"
              />
            </div>
          );
        })}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Title className={'subheader'}>Other Permissions</Title>
        <Grid item xs={12} lg={12} className={'margin-left'}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={6}>
              <FormikCheckbox
                formik={formik}
                label="Active status"
                name="active"
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormikCheckbox
                formik={formik}
                label="Notification Status"
                name="notifications"
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormikCheckbox formik={formik} label="TMS" name="TMS" />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormikCheckbox
                formik={formik}
                label="Land Tracking"
                name="landTrackingEnabled"
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormikCheckbox
                formik={formik}
                label="Shipment Management"
                name="sms"
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormikCheckbox
                formik={formik}
                label="Landed Cost Enabled"
                name="landedCost"
              />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.btnContainer}>
          <CancelButton
            handleClose={handleClose}
            setValidation={() => {}}
            resetStates={reset}
          />
          <Tooltip>
            <Button color="primary" variant="contained" onClick={prev}>
              Back
            </Button>
          </Tooltip>
          <Tooltip>
            <Button color="primary" variant="contained" type="submit">
              Next
            </Button>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};
export default OrganizationPermissionForm;

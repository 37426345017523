import React, { useContext, useRef } from 'react';
import CloudUploadIcon from '../../../../assets/icons/cloudUploadLight.svg';
import { Paragraph } from '../../../../core-components/atoms/Text';
import { upload } from '../../../../utils/upload';
import { toaster } from '../../../../utils/toaster';
import { onError } from '../../../../libs/errorLib';
import { SharedContext } from '../../../../utils/common';

export const FileUpload = ({ formik, propertyName, label = 'Upload' }) => {
  const inputRef = useRef(null);
  const { setAPILoader } = useContext(SharedContext);

  const uploadFiles = async (event) => {
    setAPILoader(true);
    const files = event.target.files;
    const filesArr = [];
    for (let file of files) {
      filesArr.push(file);
    }
    try {
      let uploadedData = await upload(
        filesArr.filter((file) => !file?.id),
        'products'
      );
      if (uploadedData) {
        formik?.setFieldValue(propertyName, uploadedData[0]);
      } else {
        toaster('error', 'File not uploaded');
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <>
      <div
        className={
          'grid justify-items-center bg-oasisPrimary200 p-4 rounded border border-oasisPrimary500'
        }
        onClick={() => inputRef.current.click()}
      >
        <img src={CloudUploadIcon} alt="Paperclip Icon" />
        <Paragraph variant={'sm'} className={'text-oasisPrimary500 mt-1'}>
          {label}
        </Paragraph>
      </div>
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={(e) => uploadFiles(e)}
        accept=".jpg,.png,.jpeg"
      />
    </>
  );
};

import { Grid, InputAdornment, List, ListItem } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { Paragraph, Title } from '../../core-components/atoms/Text';
import { makeStyles, TextField, ThemeProvider } from '@material-ui/core';
import AislesView from './NodeView/Aisle/AisleView';
import BaysView from './NodeView/Bays/BaysView';
import BinView from './NodeView/Bins/BinView';
import DocksView from './NodeView/Docks/DocksView';
import { ChevronLeft, Close, Search } from '@material-ui/icons';
import { createTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { exportToCSV, SharedContext } from '../../utils/common';
import Button from '../../core-components/atoms/Button';
import { OASIS_PRIMARY_500 } from '../../constants/colors';
import PopupButton from '../../core-components/molecules/PopupButton';
import BulkIcon from '../../assets/icons/blue-bulk-upload.svg';
import DownloadIcon from '../../assets/icons/download-template-light.svg';
import BulkFileAttacher from './NodeView/components/BulkFileAttacher';
import BullkUpload from './NodeView/components/BullkUpload';
import API from '../../libs/axios';
import { toaster } from '../../utils/toaster';
import { onError } from '../../libs/errorLib';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(() => ({
  nodeTitleText: {
    fontFamily: 'Roboto !important',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.048px',
    textTransform: 'uppercase',
    color: '#696F80'
  }
}));

const WarehouseNodeView = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const warehouse = {
    id: location.state?.Warehouse?.id
  };
  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });
  const { setCurrentPageTitle } = useContext(SharedContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [bulkUploadPopup, setBulkUploadPopup] = useState(false);
  const [bulkUploadData, setBulkUploadData] = useState(null);
  const [aisles, setAisles] = useState([]);
  const [bays, setBays] = useState([]);
  const [bins, setBins] = useState([]);
  const [capacity, setCapacity] = useState({});
  const [bulkUploadResponse, setBulkUploadResponse] = useState(null);
  const { setAPILoader } = useContext(SharedContext);

  useEffect(() => {
    setCurrentPageTitle(
      <div className="flex gap-2 items-center">
        <ChevronLeft
          onClick={() => navigate('/administration/supply-chain-network')}
        />
        <p className="flex items-center">
          {location.state?.code
            ? `${location.state?.code} - ${location.state?.name}`
            : location.state?.name}
        </p>
      </div>
    );
  }, []);

  const handleDownloadTemplate = async () => {
    const title = `Template for Aisles. Bays and Bins`;
    const header = [
      'BIN NAME',
      'BIN TYPE',
      'AISLE',
      'BAY',
      'CAPACITY (SQ. FT)',
      'CAPACITY (PALLETS)',
      'CLASSIFICATION (A B C)',
      'COORDINATES (x)',
      'COORDINATES (y)',
      'COORDINATES (z)'
    ];

    const exportSortedHalls = [
      {
        name: 'LH01-AB01-B1',
        sectionType: 'RACKED',
        aisle: 'AA',
        bay: '01',
        capacity: 5000,
        pallets: 1000,
        classifications: 'A',
        coordinates: '1.0, 45.3453453, 0.034343'
      }
    ];
    exportToCSV(header, exportSortedHalls, title);
  };

  const bulkUpload = (data) => {
    setBulkUploadData(data);
    setBulkUploadResponse(null);
    setBulkUploadPopup(true);
    setBulkUploadOpen(false);
    setSelectedFile(null);
  };

  const createBulkUpload = async () => {
    const arr = [];
    bulkUploadData.map((value) => {
      let data = {
        hallName: value['BIN NAME'],
        sectionType: value['BIN TYPE'],
        aisleName: value?.AISLE,
        bayName: value?.BAY,
        capacity: Number(value['CAPACITY (SQ. FT)']),
        pallets: Number(value['CAPACITY (PALLETS)']),
        classifications: value['CLASSIFICATION (A B C)'],
        coordinates: {
          x: value['COORDINATES (x)'],
          y: value['COORDINATES (y)'],
          z: value['COORDINATES (z)']
        }
      };
      arr.push(data);
    });
    setAPILoader(true);
    setBulkUploadResponse(null);
    try {
      await API.post(`warehouses/${warehouse?.id}/bulk`, arr);
      setBulkUploadData(null);
      setBulkUploadPopup(false);
      await getWarehouseAisles();
      await getWarehouseBays();
      await _getBins();
      toaster('success', `Bulk upload created successfully.`);
      setAPILoader(false);
    } catch (error) {
      const backendValidation = error?.response?.data;
      let allCorrectNodes = arr.filter((_, id) => {
        return !(
          backendValidation?.[id + 2] && backendValidation[id + 2]?.length > 0
        );
      });

      let allInCorrectNodes = arr
        .map((data, id) => ({ data, id }))
        // eslint-disable-next-line no-unused-vars
        .filter(({ data, id }) => {
          return (
            backendValidation?.[id + 2] && backendValidation[id + 2]?.length > 0
          );
        })
        .map(({ data, id }) => {
          // Transform the filtered data into your desired object structure
          return { ...data, issues: backendValidation?.[id + 2] };
        });
      setBulkUploadResponse({
        correct: allCorrectNodes,
        incorrect: [...allInCorrectNodes],
        issues: error?.response?.data,
        showPopup: true
      });
      const newArr = [];
      allCorrectNodes?.map((value) => {
        let data = {
          ['BIN NAME']: value?.hallName,
          ['BIN TYPE']: value?.sectionType,
          AISLE: value?.aisleName,
          BAY: value?.bayName,
          ['CAPACITY (SQ. FT)']: Number(value?.capacity),
          ['CAPACITY (PALLETS)']: Number(value?.pallets),
          ['CLASSIFICATION (A B C)']: value?.classifications,
          coordinates: {
            ['COORDINATES (x)']: value?.coordinates?.x,
            ['COORDINATES (y)']: value?.coordinates?.y,
            ['COORDINATES (z)']: value?.coordinates?.z
          }
        };
        newArr.push(data);
      });
      setBulkUploadData(newArr);
      setAPILoader(false);
    }
  };

  const getWarehouseAisles = async () => {
    setAPILoader(true);
    try {
      const cols = ['name'];
      const res = await API.get(`warehouses/${warehouse?.id}/aisles`, {
        params: {
          page: 1,
          limit: 10,
          search: '',
          columns: cols
        }
      });
      const newArr = res?.data;
      setAisles([...newArr]);
      setAPILoader(false);
    } catch (error) {
      onError(error);
      setAPILoader(false);
    }
  };

  const getWarehouseBays = async () => {
    setAPILoader(true);
    try {
      const cols = ['name'];
      const res = await API.get(`warehouses/${warehouse?.id}/bays`, {
        params: {
          page: 1,
          limit: 10,
          search: '',
          columns: cols
        }
      });
      const newArr = res?.data;
      setBays([...newArr]);
      setAPILoader(false);
    } catch (error) {
      onError(error);
      setAPILoader(false);
    }
  };

  const _getBins = async () => {
    setAPILoader(true);
    let columns = ['name'];
    let filters = {};
    try {
      const halls = await API.get(`warehouses/${warehouse?.id}/halls`, {
        params: {
          page: 1,
          limit: 10,
          search: '',
          filters,
          columns
        }
      });
      const finalHalls = [...halls.data];
      const finalHallMapper = finalHalls.map((d, index) => ({
        ...d,
        orderId: index
      }));
      const { totalPalletCapacity, totalSqCapacity } =
        finalHallMapper?.reduce((total, singleValue) => {
          if (!total.totalPalletCapacity) total.totalPalletCapacity = 0;
          if (!total.totalSqCapacity) total.totalSqCapacity = 0;

          total.totalPalletCapacity =
            singleValue?.sectionType === 'RACKED' ||
            singleValue?.sectionType === 'GROUNDED'
              ? total?.totalPalletCapacity + singleValue?.pallets
              : total.totalPalletCapacity;

          total.totalSqCapacity =
            singleValue?.sectionType === 'RACKED' ||
            singleValue?.sectionType === 'GROUNDED'
              ? total?.totalSqCapacity + singleValue?.capacity
              : total.totalSqCapacity;
          return total;
        }, {}) || {};
      setCapacity({ pallets: totalPalletCapacity, capacity: totalSqCapacity });
      setBins(finalHallMapper);
      setAPILoader(false);
    } catch (err) {
      onError(err);
      setAPILoader(false);
    }
  };

  return (
    <div className="scroll-my-3 mr-5 mx-10">
      <BullkUpload
        bulkUploadResponse={bulkUploadResponse}
        data={bulkUploadData}
        bulkUploadPopup={bulkUploadPopup}
        setBulkUploadPopup={setBulkUploadPopup}
        createBulkUpload={createBulkUpload}
      />
      <div className="display-flex mb-56 mt-4">
        <div className="display-flex border border-solid border-[#E1E5F0] px-4 py-8 mr-8 bd-4">
          <div className="mr-8">
            <p className={classes.nodeTitleText}>Status</p>
            <Paragraph className="text-pg-sm mt-2">
              {location.state?.isActive ? 'Active' : 'Inactive'}
            </Paragraph>
          </div>
          <div className="mr-8">
            <p className={classes.nodeTitleText}>Capacity (Sq.ft.)</p>
            <Paragraph className="text-pg-sm mt-2">
              {location.state?.Warehouse?.capacity} Sq. Ft.
            </Paragraph>
          </div>
          <div className="mr-8">
            <p className={classes.nodeTitleText}>Capacity (Pallet)</p>
            <Paragraph className="text-pg-sm mt-2">
              {location.state?.Warehouse?.pallets}
            </Paragraph>
          </div>
        </div>
        <div className="display-flex border border-solid border-[#E1E5F0] px-4 py-8 bd-4">
          <div className="mr-8">
            <p className={classes.nodeTitleText}>Warehouse Code</p>
            <Paragraph className="text-pg-sm mt-2">
              {location.state?.Warehouse?.businessWarehouseCode}
            </Paragraph>
          </div>
          <div className="mr-8">
            <p className={classes.nodeTitleText}>Address</p>
            <Paragraph className="text-pg-sm mt-2">
              {location.state?.address}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="display-flex mb-10 mr-[20px] justify-between items-center">
        <Title className={'text-xl font-semibold'}>
          {search ? `Result for ${search}` : 'Location Details'}
        </Title>
        <div className={'display-flex'}>
          <div className={'w-96 mr-3'}>
            <ThemeProvider theme={theme}>
              <TextField
                fullWidth
                placeholder="Search"
                variant="outlined"
                color={'primary'}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                size="small"
                inputProps={{
                  onFocus: () => search
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <Search
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '10px'
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {search && (
                        <Close
                          style={{
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setSearch('');
                          }}
                        />
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </ThemeProvider>
          </div>

          <PopupButton
            open={bulkUploadOpen}
            setOpen={setBulkUploadOpen}
            btn={
              <Button
                label="Bulk Upload"
                onClick={(e) => {
                  e.preventDefault();
                  setBulkUploadOpen(!bulkUploadOpen);
                }}
                className={'py-0 text-oasisPrimary500 border-oasisPrimary500'}
                variant="transparent"
                icon={<img src={BulkIcon} className={`mr-[2px]`} />}
              />
            }
            content={
              <List className="py-4">
                <ListItem
                  className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                  onClick={async (e) => {
                    e.preventDefault();
                    await handleDownloadTemplate();
                    setBulkUploadOpen(false);
                  }}
                >
                  <img src={DownloadIcon} className={`mr-[2px]`} />
                  <span>Download Template</span>
                </ListItem>
                <BulkFileAttacher
                  bulkUpload={bulkUpload}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                />
              </List>
            }
          />
        </div>
      </div>
      <div className="display-flex gap-4 mr-[20px]">
        <Grid container className="h-[65vh]">
          <AislesView
            warehouse={warehouse}
            searchValue={search}
            aisles={aisles}
            setAisles={setAisles}
          />
        </Grid>
        <Grid container className="h-[65vh]">
          <BaysView
            warehouse={warehouse}
            searchValue={search}
            bays={bays}
            setBays={setBays}
          />
        </Grid>
        <Grid container className="h-[65vh]">
          <BinView
            warehouse={warehouse}
            searchValue={search}
            warehouseDetail={location.state?.Warehouse}
            bins={bins}
            setBins={setBins}
            setCapacity={setCapacity}
            capacity={capacity}
          />
        </Grid>
        <Grid container className="h-[65vh]">
          <DocksView warehouse={warehouse} searchValue={search} />
        </Grid>
      </div>
    </div>
  );
};

export default WarehouseNodeView;

import React, { useContext, useState } from 'react';
import { Grid, Paper, Box, TextField, IconButton } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { toaster } from '../../utils/toaster';
import API from '../../libs/axios';
import { Close } from '@mui/icons-material';
import * as yup from 'yup';
import EmailSentPopup from './EmailSentPopup';
import DetailPageTitle from '../../atomicComponents/DetailPageTitle';
import popupStyles from './popupStyles';
import { SharedContext } from '../../utils/common';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required')
});

const ForgotPasswordPopup = (props) => {
  const [email, setEmail] = useState('');
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const classes = popupStyles();

  const { subdomain, setAPILoader } = useContext(SharedContext);

  const validateEmail = async (input) => {
    try {
      await schema.validate({ email: input });
      setErrors({});
    } catch (error) {
      setErrors({ email: error.message });
    }
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    validateEmail(inputValue).catch((error) => {
      // Handle any errors that occur during the execution of the promise
      console.error(error);
    });
  };

  const handleSubmit = async () => {
    try {
      setAPILoader(true);
      await API.post(`organizations/${subdomain}/auth/forgot-pass`, {
        email: email
      });

      setShowEmailSent(true);
    } catch (err) {
      toaster('error', err?.response?.data?.message);
    } finally {
      setAPILoader(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handlePopupClose = () => {
    setShowEmailSent(false);
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '25%' } }}
    >
      <Grid
        container
        className={classes.backdropGrid}
        justifyContent="flex-start"
      >
        <DialogTitle>
          <DetailPageTitle text="Forgot Password" />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.close}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper elevation={0} className={classes.paperStyle}>
            <Box mt={3}>
              <label>
                Please enter your registered email address.
                <br />
                We will send you a link to reset your password.
              </label>
            </Box>
            <Box mt={3}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                value={email}
                onChange={handleEmailChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Box>
            <Box mt={2} className={classes.btn}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Send Email
              </Button>
              {showEmailSent && (
                <EmailSentPopup email={email} onClose={handlePopupClose} />
              )}
            </Box>
          </Paper>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default ForgotPasswordPopup;

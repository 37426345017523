/*eslint-disable*/
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Button, useMediaQuery } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TextField, Tooltip } from '@material-ui/core';
import DetailText from '../../../atomicComponents/DetailText';
import Paper from '@mui/material/Paper';
import classNames from 'classnames/bind';
import * as XLSX from 'xlsx';
import { Autocomplete, FormControl, Grid, Typography } from '@mui/material';
import QrCodeScannerIcon from '../../../assets/icons/QRScannerIcon.svg';
import { useLocation, useNavigate } from 'react-router';
// Icons
import CrossIcon from '../../../assets/icons/cancelIcon.svg';
import TickIcon from '../../../assets/icons/checkIcon.svg';
import ReverseIcon from '../../../assets/icons/ReverseIcon.svg';
import chevronUp from '../../../assets/icons/chevronUpDark.svg';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import API from '../../../libs/axios';
import {
  compareDateFormat,
  decimalDisplay,
  productLabelFormat,
  SharedContext
} from '../../../utils/common';
import { isRequired } from '../../../utils/validators';
import { toaster } from '../../../utils/toaster';
import grnStyles from '../grn/grnMakeStyles';
import ShelfLifeView from '../ShelfLifeView';
import GdnProductsTableRow from './GdnProductsTableRow';
import ScanView from '../ScanView';
import NumberInput from '../../../atomicComponents/NumberInput';
import MoveTypes from '../../../utils/enums/moveTypes';
import ErrorFileUploaded from '../../../components/ErrorFileUploaded';
import GdnBulkUpload from './GdnBulkUpload';
import Dialogue from '../../../atomicComponents/Dialogue';
import { checkPermission } from '../../../utils/auth';
import { onError } from '../../../libs/errorLib';
import SORT_ICON from '../../../assets/icons/sort.svg';
import DEC_SORT_ICON from '../../../assets/icons/up-arrow.svg';
import ASC_SORT_ICON from '../../../assets/icons/down-arrow.svg';
import { ORGANIZATION_TYPES } from '../../../constants';
import WarningIcon from '../../../assets/icons/warning.svg';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import O360GdnProductsTableRow from './O360GdnProductsTableRow';

const GDNTableHeaders = [
  {
    key: 'sku',
    value: 'SKU Code'
  },
  {
    key: 'name',
    value: 'Name'
  },
  {
    key: 'batch',
    value: 'Batch'
  },
  {
    key: 'mfgd',
    value: 'Mfg Date'
  },
  {
    key: 'expd',
    value: 'Exp Date'
  },
  {
    key: 'mrp',
    value: 'MRP (Rs)'
  },
  {
    key: 'quantity',
    value: 'Quantity'
  }
];

const GdnProductsTable = ({
  vehicleType,
  formik,
  GDNview,
  setResetProducts,
  reversed,
  totalOrderedQuantity,
  setTotalOrderedQuantity,
  totalDispatchedQuantity,
  setTotalDispatchedQuantity,
  reverseFlag,
  pickingTask,
  totalReceivedQuantity,
  batchExpiry
}) => {
  const { setAPILoader, currentUser, organizationType } =
    useContext(SharedContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [rows, setRows] = useState(formik?.values?.Order?.products);
  const [batches, setBatches] = useState([]);
  const [shelfLifeOpen, setShelfLifeOpen] = useState(false);
  const [batchDisable, setBatchDisable] = useState(false);
  const [disabledAvailableQuantity, setDisabledAvailableQuantity] =
    useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [data, setData] = useState([]);
  const [sortOption, setSortOption] = useState(2);
  let initialValidation = {
    productId: false,
    batchNumber: false,
    expiryDate: false,
    actualDispatchedQuantity: false,
    recoverableDamageQuantity: false,
    unsellableDamageQuantity: false
  };

  const [validation, setValidation] = useState(initialValidation);

  let initialProductValues = {
    id: '',
    Product: {},
    orderedQuantity: 0,
    batch: '',
    useState,
    actualDispatchedQuantity: ''
  };
  const [productValues, setProductValues] = useState(initialProductValues);
  const [remainingQty, setRemainingQty] = useState(
    productValues?.orderedQuantity || 0
  );
  const [searchProductValue, setSearchProductValue] = useState('');
  const [products, setProducts] = useState([]);
  const [parentIndex, setParentIndex] = useState(null);
  const [scanOpen, setScanOpen] = useState(false);
  const [productCount, setProductCount] = useState(null);

  const reverseEntriesClass = () => {
    return [
      'COMPLETED',
      'LOAD_DISPATCHED',
      'PARTIALLY_COMPLETED_CLOSED',
      'PARTIALLY_COMPLETE'
    ].includes(formik?.values?.Order?.status);
  };

  const displayEntriesClass = () => {
    return (
      formik?.values?.Order?.status === 'DOCK_ASSIGNED' &&
      !formik?.values?.Order?.dropOffDockId
    );
  };

  useEffect(() => {
    if (reverseFlag) {
      setRows(formik.values.Order.products);
      setTotalOrderedQuantity(
        formik.values.Order.products.reduce(
          (acc, val) => acc + (+val.orderedQuantity || 0),
          0
        )
      );
      setTotalDispatchedQuantity(
        formik.values.Order.products?.reduce((acc, val) => {
          if (!val.GDNGroupBatches?.length) return acc;

          return (
            acc +
              val.GDNGroupBatches?.reduce((a, obj) => {
                return a + (Number(obj?.actualDispatchedQuantity) || 0);
              }, 0) || 0
          );
        }, 0)
      );
    } else {
      formik.setFieldValue('Order.products', rows);
      setTotalOrderedQuantity(
        rows.reduce((acc, val) => acc + (+val.orderedQuantity || 0), 0)
      );
      setTotalDispatchedQuantity(
        rows.reduce((acc, val) => {
          if (!val.GDNGroupBatches?.length) return acc;

          return (
            acc +
              val.GDNGroupBatches?.reduce((a, obj) => {
                return a + (Number(obj?.actualDispatchedQuantity) || 0);
              }, 0) || 0
          );
        }, 0)
      );
    }
  }, [rows, formik.values]);

  useEffect(() => {
    getProducts(searchProductValue);
  }, [searchProductValue]);

  const handleBatchChange = (value) => {
    if (!value) return;
    let batch = null;
    const product = rows?.find(
      (products) => products?.ProductId === productValues?.Product?.id
    );
    if (product) {
      batch = product?.GDNGroupBatches?.find(
        (batch) =>
          batch?.batchNumber === value?.batchNumber &&
          moment(batch?.expiryDate).format('DD/MM/YYYY') ===
            moment(value?.actualExpiry).format('DD/MM/YYYY') &&
          batch?.promoName === value?.promoName
      );
    }
    batch
      ? setProductValues({
          ...productValues,
          actualDispatchedQuantity: batch?.actualDispatchedQuantity,
          batch: value
        })
      : setProductValues({
          ...productValues,
          batch: value,
          actualDispatchedQuantity: ''
        });
  };

  const resetProductSection = () => {
    setBatches([]);
    setProductValues(initialProductValues);
    setParentIndex(null);
    setBatchDisable(false);
    setDisabledAvailableQuantity('');
    setValidation(initialValidation);
    setProductCount(null);
  };

  const sortProductsByName = () => {
    setRows((prev) => [
      ...prev.sort((a, b) => a.Product?.name.localeCompare(b?.Product?.name))
    ]);
    setSortOption(1);
  };

  const reverseProducts = () => {
    setRows((prev) => [...prev.reverse()]);
    setSortOption((prev) => +!+prev);
  };

  const insertChildRowHandler = (parentIndex, rowData) => {
    setBatches([]);
    setBatchDisable(false);
    setDisabledAvailableQuantity('');
    setProductValues({
      ...productValues,
      ...{
        Product: { ...rowData.Product },
        ...{ orderedQuantity: rowData.orderedQuantity }
      }
    });
    setParentIndex(parentIndex);
  };

  const validateProductValue = (productValues) => {
    if (
      productValues.Product.batchEnabled &&
      isRequired(productValues.Product.id) &&
      isRequired(productValues.actualDispatchedQuantity)
    ) {
      return true;
    } else if (
      !productValues.Product.batchEnabled &&
      isRequired(productValues.Product.id) &&
      isRequired(productValues.actualDispatchedQuantity)
    ) {
      return true;
    } else {
      toaster('warning', 'Please insert dispatch quantity');
      return false;
    }
  };

  const getValidDate = (date) => {
    return date ? moment(date) : null;
  };

  const handleErrorDialog = () => {
    setErrorDialog(false);
    setErrorData([]);
  };

  const validateShelfLife = () => {
    setValidation({
      productId: true,
      expiryDate: true,
      receivedQuantity: true,
      recoverableDamageQuantity: true,
      unsellableDamageQuantity: true
    });
    const status = validateProductValue(productValues);
    if (
      status &&
      productValues?.Product?.batchEnabled &&
      productValues?.Product?.shelfLife
    ) {
      let shelfLifeDate = moment().add(
        productValues?.Product?.shelfLife,
        'days'
      );
      if (moment(productValues?.expiryDate).isBefore(shelfLifeDate)) {
        let shelfLifeDifference =
          shelfLifeDate.diff(productValues.expiryDate, 'days') + 1;
        if (shelfLifeDifference < productValues?.Product?.shelfLife) {
          setShelfLifeOpen(true);
          return;
        }
      }
      addProduct();
    } else if (status) {
      addProduct();
    }
  };

  const addProduct = () => {
    let products = rows;
    if (parentIndex || parentIndex === 0) {
      let batchIndex = products[parentIndex].GDNGroupBatches.findIndex(
        (batch) =>
          batch?.batchNumber === productValues?.batch?.batchNumber &&
          batch?.expiryDate.format('DD-MM-yyyy') ===
            productValues?.batch?.expiryDate &&
          batch?.promoName === productValues?.batch?.promoName
      );

      const actualQuantity = products[parentIndex]?.actualQuantity;
      if (
        actualQuantity &&
        Number(productValues?.actualDispatchedQuantity) + actualQuantity >
          productValues?.orderedQuantity
      ) {
        toaster(
          'warning',
          'Dispatched quantity can not be more than ordered quantity'
        );
        return;
      }
      if (batchIndex > -1) {
        products[parentIndex].GDNGroupBatches[
          batchIndex
        ].actualDispatchedQuantity = productValues.actualDispatchedQuantity;
      } else {
        products[parentIndex].GDNGroupBatches.push({
          id: 0,
          batchNumber: productValues?.batch?.batchNumber,
          expiryDate: getValidDate(productValues?.batch?.actualExpiry),
          manufacturingDate: getValidDate(productValues?.batch.actualMfg),
          availableQuantity: productValues?.batch?.availableQuantity,
          actualDispatchedQuantity: productValues?.actualDispatchedQuantity,
          MRP: productValues?.batch?.MRP,
          promoName: productValues?.batch?.promoName,
          promoQuantity: productValues?.batch?.promoQuantity
        });
      }
    } else {
      let productIndex = products.findIndex(
        (product) => product?.Product?.id == productValues?.Product?.id
      );
      if (!productValues.Product.batchEnabled && productIndex > -1) {
        toaster('warning', 'Product is already in the list');
        return;
      } else if (productValues.Product.batchEnabled && productIndex > -1) {
        let batchIndex = products[productIndex].GDNGroupBatches.findIndex(
          (batch) =>
            batch?.batchNumber == productValues?.batch?.batchNumber &&
            batch?.expiryDate.format('DD-MM-yyyy') ==
              productValues?.batch?.expiryDate
        );
        if (batchIndex > -1) {
          products[productIndex].GDNGroupBatches[
            batchIndex
          ].actualDispatchedQuantity = productValues.actualDispatchedQuantity;
        } else {
          products[productIndex].GDNGroupBatches.push({
            id: 0,
            batchNumber: productValues?.batch?.batchNumber,
            manufacturingDate: getValidDate(productValues?.batch?.actualMfg),
            expiryDate: getValidDate(productValues?.batch?.actualExpiry),
            availableQuantity: productValues?.batch?.availableQuantity,
            actualDispatchedQuantity: productValues?.actualDispatchedQuantity,
            MRP: productValues?.batch?.MRP,
            promoName: productValues?.batch?.promoName,
            promoQuantity: productValues?.batch?.promoQuantity
          });
        }
      } else {
        products = [
          ...products,
          {
            id: 0,
            ProductId: productValues?.Product?.id,
            orderedQuantity: 0,
            Product: { ...productValues.Product },
            GDNGroupBatches: [
              {
                id: 0,
                batchNumber: productValues?.batch?.batchNumber,
                manufacturingDate: getValidDate(
                  productValues?.batch?.manufacturingDate
                ),
                expiryDate: getValidDate(productValues?.batch?.actualExpiry),
                availableQuantity: productValues?.batch?.availableQuantity,
                actualDispatchedQuantity:
                  productValues?.actualDispatchedQuantity,
                MRP: productValues?.batch?.MRP,
                promoName: productValues?.batch?.promoName,
                promoQuantity: productValues?.batch?.promoQuantity
              }
            ]
          }
        ];
      }
    }
    let productIndex = products.findIndex(
      (product) => product?.Product?.id == productValues?.Product?.id
    );
    let actualQuantity = products[productIndex]?.GDNGroupBatches?.reduce(
      function (acc, obj) {
        return Number(acc) + (Number(obj?.actualDispatchedQuantity) || 0);
      },
      0
    );
    products[productIndex]['actualQuantity'] = actualQuantity;

    setResetProducts(JSON.stringify([...products]));
    setRows([...products]);
    setBatches([]);
    setProductValues(initialProductValues);
    setParentIndex(null);
    setDisabledAvailableQuantity('');
    setValidation(initialValidation);
    setShelfLifeOpen(false);
    setBatches([]);
    setProductCount(null);
  };

  const getProducts = (searchProductValue) => {
    let filters = {
      colVal: {
        isActive: 1
      },
      sortable: ''
    };
    setAPILoader(true);
    API.get(`/products`, {
      params: {
        search: searchProductValue,
        filters,
        columns: ['name', 'barcode'],
        companyId: formik?.values?.Order?.companyId
      }
    })
      .then((res) => {
        if (res.data) {
          setProducts(res.data);
        }
      })
      .finally(() => setAPILoader(false));
  };

  const getBatches = async (nodeId, companyId, productId) => {
    setAPILoader(true);
    let batch = [];
    let res = await API.get(`inventories/batches`, {
      params: {
        nodeId,
        ...(organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
          ? { companyId }
          : {}),
        ...(productId ? { productId } : {}),
        orderId: formik?.values?.Order?.id,
        rejectReserved: pickingTask ? false : true
      }
    });
    if (res.data) {
      if (batchDisable) {
        let availableQuantity = res?.data[0]?.availableQuantity;
        setDisabledAvailableQuantity(availableQuantity);
      } else {
        if (productId?.length) {
          batch = res?.data?.map((idetail) => {
            return {
              productId: idetail?.Product?.id,
              batches: idetail.InventoryDetail?.flatMap((batch) => {
                let batchDetails = {
                  id: batch?.id,
                  batchNumber: batch?.batchNumber,
                  expiryDate: null,
                  actualExpiry: null,
                  actualMfg: null,
                  manufacturingDate: null,
                  MRP: null,
                  promoName: null,
                  promoQuantity: null
                };
                if (
                  batch?.promoName &&
                  batch?.availableQuantity - batch?.promoQuantity > 0
                ) {
                  return [
                    {
                      ...batchDetails,
                      availableQuantity:
                        batch?.availableQuantity - batch?.promoQuantity,
                      promoName: null,
                      promoQuantity: 0
                    },
                    {
                      ...batchDetails,
                      availableQuantity: batch?.promoQuantity
                    }
                  ];
                } else {
                  return {
                    ...batchDetails,
                    availableQuantity: batch?.availableQuantity
                  };
                }
              })
            };
          });
        } else {
          batch = res?.data[0]?.InventoryDetail?.flatMap((batch) => {
            let batchDetails = {
              id: batch?.id,
              batchNumber: batch?.batchNumber,
              expiryDate: compareDateFormat(batch?.expiryDate),
              actualExpiry: batch?.expiryDate,
              actualMfg: batch?.manufacturingDate,
              manufacturingDate: compareDateFormat(batch?.manufacturingDate),
              MRP: batch?.MRP?.toString(),
              promoName: batch?.promoName?.toString(),
              promoQuantity: batch?.promoQuantity?.toString()
            };
            if (
              batch?.promoName &&
              batch?.availableQuantity - batch?.promoQuantity > 0
            ) {
              return [
                {
                  ...batchDetails,
                  availableQuantity:
                    batch?.availableQuantity - batch?.promoQuantity,
                  promoName: null,
                  promoQuantity: 0
                },
                {
                  ...batchDetails,
                  availableQuantity: batch?.promoQuantity
                }
              ];
            } else {
              return {
                ...batchDetails,
                availableQuantity: batch?.availableQuantity
              };
            }
          });
          const totalAvailable = (res?.data[0]?.InventoryDetail || []).reduce(
            (acc, inventoryDetail) =>
              acc + (inventoryDetail.availableQuantity || 0),
            0
          );
          setProductCount(totalAvailable);
          setBatches(batch);
        }
      }
      setAPILoader(false);
    }

    return batch;
  };

  useEffect(() => {
    const setProductValues = async () => {
      const batches = await getBatches(
        formik.values.Order.pickupId,
        formik.values.Order.companyId,
        rows?.map((row) => row.Product?.id)
      );
      const copiedRows = [...rows];

      for (let i = 0; i < copiedRows.length; i++) {
        let productBatches = batches.filter(
          (b) => b.productId == copiedRows[i].Product?.id
        )?.[0];
        if (location.state == 'Dispatch_full' && !pickingTask) {
          const conversionFactor = element?.secondaryUomId
            ? element?.Product?.ProductUOMs?.find(
                (u) => u.uomId == element?.secondaryUomId
              )?.conversionFactor
            : 1;
          copiedRows[i].GDNGroupBatches = productBatches.batches.map((b) => ({
            ...b,
            actualDispatchedQuantity: Math.min(
              b.availableQuantity / conversionFactor,
              copiedRows[i].orderedQuantity / conversionFactor
            )
          }));
          copiedRows[i].actualQuantity = Math.min(
            productBatches?.batches?.[0]?.availableQuantity / conversionFactor,
            copiedRows[i].orderedQuantity / conversionFactor
          );
        } else {
          if (!GDNview && !pickingTask) {
            copiedRows[i].GDNGroupBatches = productBatches.batches;
            copiedRows[i].actualQuantity = 0;
          }
        }
      }
      setRows(copiedRows);
    };

    if (batchExpiry) {
      if (productValues.Product?.id) {
        getBatches(
          formik.values.Order.pickupId,
          formik.values.Order.companyId,
          productValues.Product.id
        );
      }
      const currentProduct = rows?.find(
        (product) => product?.Product?.id === productValues?.Product?.id
      );
      let actualQuantity = returnActualDispatchedQuantity(currentProduct);
      const remainingQty = productValues?.orderedQuantity
        ? productValues?.orderedQuantity - actualQuantity
        : 0;
      if (productValues?.actualDispatchedQuantity) {
        setRemainingQty(
          remainingQty - Number(productValues?.actualDispatchedQuantity) < 0
            ? 0
            : remainingQty - productValues?.actualDispatchedQuantity
        );
      } else {
        setRemainingQty(remainingQty < 0 ? 0 : remainingQty);
      }
    } else {
      setProductValues();
    }
  }, [productValues, batchExpiry, location]);

  const returnActualDispatchedQuantity = (product) => {
    let actualQuantity = product?.GDNGroupBatches?.reduce(function (acc, obj) {
      return Number(acc) + (Number(obj?.actualDispatchedQuantity) || 0);
    }, 0);

    return actualQuantity;
  };

  const exportToXlsx = () => {
    const incorrect = data.products?.filter((p, i) =>
      errorData.find((e) => e?.row == i + 2)
    );
    const messages = errorData
      .filter((e) => data.products.find((p, i) => e.row == i + 2))
      .map((e) => e.message);
    let rows = incorrect?.map((row, i) => [
      row?.name,
      row?.batchNumber,
      row?.manufacturingDate,
      row?.expiryDate,
      row?.dispatchedQuantity,
      messages[i]
    ]);
    const exportData = [
      [
        'SKU Name/SKU Code',
        'Batch #',
        'Manufacturing Date',
        'Expiry Date',
        'Dispatched Qty',
        'Reason'
      ],
      ...rows
    ];

    const ws = XLSX.utils.aoa_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Incorrect rows');

    XLSX.writeFile(wb, 'incorrect_rows.xlsx');
  };

  const validateDispatchedQts = (scannedProducts, currProduct) => {
    return scannedProducts?.reduce((previous, current) => {
      return (
        previous +
        (current?.Product?.id === currProduct?.Product?.id
          ? Number(current?.dispatchQuantity)
          : 0)
      );
    }, 0);
  };
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <div className="flex justify-between">
        {GDNview ? (
          <p className="text-xl font-semibold">
            GDN Details
            {reversed && (
              <span className="text-error">
                <pre className="font-bold inline"> · </pre>REVERSED
              </span>
            )}
          </p>
        ) : (
          <p className="text-xl font-semibold">
            Fill in The Inventory Details <span className="text-error">*</span>
          </p>
        )}
        <GdnBulkUpload
          setErrorData={setErrorData}
          setErrorDialog={setErrorDialog}
          formik={formik}
          rows={rows}
          setRows={setRows}
          getValidDate={getValidDate}
          GDNview={GDNview}
          reversed={reversed}
          errorData={errorData}
          returnActualDispatchedQuantity={returnActualDispatchedQuantity}
          setData={setData}
          vehicleType={vehicleType}
          totalOrderedQuantity={totalOrderedQuantity}
          totalDispatchedQuantity={totalDispatchedQuantity}
          totalReceivedQuantity={totalReceivedQuantity}
        />
      </div>
      {reversed && <p>{formik.values?.reversalReason}</p>}
      {errorData?.length > 0 && (
        <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
          <div className="flex gap-2">
            <img src={WarningIcon} alt="Warning" />
            <p className="text-sm">
              File not uploaded because of issues in file.
            </p>
          </div>
          <div onClick={exportToXlsx} className="flex gap-2 cursor-pointer">
            <img src={downloadFileIcon} alt="Download" />
            <p className="text-sm">Download File</p>
          </div>
        </div>
      )}
      <TableContainer
          component={Paper}
          className="mt-10"
          sx={{
            borderRadius: '16px',
            overflow: 'hidden',
            border: '1px solid rgba(75, 137, 200, 0.2) !important',
            boxShadow: '4px 4px 16px rgb(75 137 200 / 12%) !important',
            '& .MuiTable-root': {
              borderCollapse: 'unset'
            }
          }}
      >
      <Grid className="scroll-table">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className="w-1/5 text-sm color-headerCell">
                Sku Code & Name
              </TableCell>
              <TableCell className="w-1/6 text-sm color-headerCell">
                UoM
              </TableCell>
              <TableCell className="text-sm color-headerCell">
                Required Qty
              </TableCell>
              <TableCell className="w-[15%] text-sm color-headerCell">
                Dispatched Qty
              </TableCell>
              <TableCell className="text-sm color-headerCell">
                Remaining Qty
              </TableCell>
              {batchExpiry && (
                  <TableCell className="text-sm color-headerCell"></TableCell>
              )}
              {!batchExpiry && !GDNview && (
                  <TableCell className="text-sm color-headerCell">
                    Available Qty
                  </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, parentIndex) =>
              batchExpiry ? (
                <GdnProductsTableRow
                  formik={formik}
                  key={parentIndex}
                  GDNview={GDNview}
                  row={row}
                  headers={
                    isMobile
                      ? [
                          'Sku Code',
                          'UoM',
                          'Required Qty',
                          'Dispatched Qty',
                          'Remaining Qty'
                        ]
                      : []
                  }
                  batchDisable={batchDisable}
                  setBatchDisable={(val) => setBatchDisable(val)}
                  disabledAvailableQuantity={disabledAvailableQuantity}
                  setDisabledAvailableQuantity={(value) =>
                    setDisabledAvailableQuantity(value)
                  }
                  parentIndex={parentIndex}
                  rows={rows}
                  settleRemainingQty={(value) => setRemainingQty(value)}
                  setRows={setRows}
                  batchInfo={row.GDNGroupBatches}
                  insertChildRowHandler={() =>
                    insertChildRowHandler(parentIndex, row)
                  }
                  resetProductSection={() => resetProductSection()}
                  setScanOpen={setScanOpen}
                  setResetProducts={setResetProducts}
                  inventoryBatches={batches}
                  getBatches={getBatches}
                  pickingTask={pickingTask}
                />
              ) : (
                <O360GdnProductsTableRow
                  key={parentIndex}
                  formik={formik}
                  GDNview={GDNview}
                  row={row}
                  headers={
                    isMobile
                      ? [
                          'Sku Code',
                          'UoM',
                          'Required Qty',
                          'Dispatched Qty',
                          'Remaining Qty'
                        ]
                      : []
                  }
                  batchDisable={batchDisable}
                  setBatchDisable={(val) => setBatchDisable(val)}
                  disabledAvailableQuantity={disabledAvailableQuantity}
                  setDisabledAvailableQuantity={(value) =>
                    setDisabledAvailableQuantity(value)
                  }
                  parentIndex={parentIndex}
                  rows={rows}
                  settleRemainingQty={(value) => setRemainingQty(value)}
                  setRows={setRows}
                  batchInfo={row.GDNGroupBatches}
                  insertChildRowHandler={() =>
                    insertChildRowHandler(parentIndex, row)
                  }
                  resetProductSection={() => resetProductSection()}
                  setScanOpen={setScanOpen}
                  setResetProducts={setResetProducts}
                  inventoryBatches={batches}
                  getBatches={getBatches}
                  pickingTask={pickingTask}
                />
              )
            )}
          </TableBody>
        </Table>

        {!isMobile && (
          <Table
            aria-label="collapsible table"
            className="bg-light w-full rounded-b-lg"
          >
            <TableBody>
              <TableRow className="flex justify-between items-center p-5">
                <div></div>
                <div className="flex">
                  <p className="text-xs mx-2">
                    Total Ordered:{' '}
                    <span className="text-base">
                      {decimalDisplay(totalOrderedQuantity)}
                    </span>
                    <span className="text-xs ml-1">units</span>
                  </p>
                  <p className="text-xs mx-2">
                    Total Dispatched:{' '}
                    <span className="text-base">
                      {decimalDisplay(totalDispatchedQuantity)}
                    </span>
                    <span className="text-xs ml-1">units</span>
                  </p>
                  <p className="text-xs mx-2">
                    Total Remaining:{' '}
                    <span className="text-base">
                      {totalOrderedQuantity - totalDispatchedQuantity < 0
                        ? 0
                        : decimalDisplay(
                            totalOrderedQuantity - totalDispatchedQuantity
                          )}
                    </span>
                    <span className="text-xs ml-1">units</span>
                  </p>
                </div>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Grid>
      </TableContainer>
    </>
  );
};

export default GdnProductsTable;

/*eslint-disable*/
import { FormControl } from '@material-ui/core';
import { React, useContext, useEffect, useState, useCallback } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';
import TextField from '../../../core-components/atoms/TextField';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendarIconSmall.svg';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import {
  SupportingDocumentUpload,
  formatFileName
} from '../../revampedOrder/AddOrder';
import CancelIcon from '../../../assets/icons/cancelIcon.svg';
import UploadDocIcon from '../../../assets/icons/uploadDocument.svg';
import { FileUploadButton } from '../components/FileUploadButton';
import { DEBOUNCE_CONST } from '../../../Config';
import { debounce } from 'lodash';
import { UploadIconType } from '../components/constants';
import Switch from '../../../core-components/atoms/Switch';

const VehicleForm = ({ formik, edit }) => {
  const { setAPILoader, settings } = useContext(SharedContext);

  const [carriers, setCarriers] = useState([]);
  const [carrierSearchKeyword, setCarrierSearchKeyword] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedVehicleType, setVehicleType] = useState();
  const [vehicleTypeSearchKeyword, setVehicleTypeSearchKeyword] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [driverSearch, setDriverSearch] = useState('');

  useEffect(() => {
    getCarriers(carrierSearchKeyword);
  }, [carrierSearchKeyword]);

  const getCarriers = async (searchKeyword) => {
    try {
      setAPILoader(true);
      const carriers = await API.get('companies', {
        params: {
          search: searchKeyword,
          type: 'VENDOR',
          logisticOnly: true,
          mode: 'LAND'
        }
      });
      setCarriers(carriers.data?.rows || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const getDrivers = async (searchKeyword) => {
    try {
      setAPILoader(true);
      const drivers = await API.get('drivers', {
        params: { search: searchKeyword, companyId: formik.values.carrier?.id }
      });
      setDrivers(drivers.data || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const listBoxProps = {
    fontSize: 14
  };

  const inputProps = {
    classes: {
      input: 'text-sm h-7',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11.6px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '8px !important'
    }
  };

  const _getVehicleTypes = async (vehicleTypeSearchKeyword) => {
    const response = await API.get(`vehicles/types`, {
      params: { search: vehicleTypeSearchKeyword }
    });
    setVehicleTypes(response?.data);
  };

  const getVehicleTypes = useCallback(
    debounce((vehicleTypeSearchKeyword) => {
      _getVehicleTypes(vehicleTypeSearchKeyword);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getVehicleTypes(vehicleTypeSearchKeyword);
  }, [vehicleTypeSearchKeyword]);

  useEffect(() => {
    if (formik.values.carrier?.id) getDrivers(driverSearch);
  }, [driverSearch, formik.values.carrier]);

  return (
    <div className="px-8 pt-4 pb-5">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Carrier Name<span className="text-error">*</span>
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={[...carriers]}
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return option?.name === 'addSubCategory' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={() => {
                          setAddSubCategoryViewOpen(true);
                        }}
                      >
                        + {'Add New Sub Category'}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option">
                        <span>{option.name}</span>
                      </li>
                    );
                  }}
                  value={formik.values?.carrier}
                  inputValue={carrierSearchKeyword}
                  getOptionLabel={(carrier) => carrier?.name || ''}
                  placeholder="Select Carrier"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('carrierId', newValue.id);
                    formik.setFieldValue('carrier', newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setCarrierSearchKeyword(newValue);
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Registration Number <span className="text-error">*</span>
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="name"
                  placeholder="Enter Registration Number"
                  value={formik.values?.registrationNumber || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('registrationNumber', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Vehicle Type<span className="text-error">*</span>
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={vehicleTypes || []}
                  value={formik.values?.vehicleType || {}}
                  inputValue={vehicleTypeSearchKeyword}
                  onInputChange={(event, newValue) => {
                    setVehicleTypeSearchKeyword(newValue);
                  }}
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return (
                      <li {...rest} className="auto-complete-option-small">
                        {option?.name
                          ? option.name
                          : option?.CarMake
                          ? `${
                              option?.CarMake?.name || option?.CarMake?.carMake
                            } ${option?.CarModel?.name} - ${
                              option?.Category?.name
                            }`
                          : ''}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) =>
                    option?.name
                      ? option.name
                      : option?.CarMake
                      ? `${option?.CarMake?.name || option?.CarMake?.carMake} ${
                          option?.CarModel?.name
                        } - ${option?.Category?.name}`
                      : ''
                  }
                  placeholder="Select Vehicle Type"
                  onChange={async (e, value) => {
                    formik.setFieldValue('vehicleType', value);
                    formik.setFieldValue('vehicle', {});
                    setVehicleType(value?.id);
                    setVehicleTypeSearchKeyword('');
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Tracking Link
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="trackingLink"
                  placeholder="Enter Tracking Link"
                  value={formik.values?.trackingLink || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('trackingLink', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between items-center border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div>
            <p className="font-semibold text-sm text-primaryBlue">
              Upload Proof of Document
            </p>
            <p className="text-xs">File must be pdf, jpeg & png</p>
          </div>
          <div className="w-[479px] border border-dashed p-4 border-[#C4C6CB] rounded">
            <div className="flex justify-between items-center mb-2">
              {!formik.values.RoutePermit ? (
                <p className="flex gap-1 text-sm">
                  Upload a picture or document
                </p>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  {formik.values.RoutePermit && (
                    <div
                      className="rounded-full w-[123px] py-[1px] justify-between text-xs px-2 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                      onClick={async (e) => {
                        e.stopPropagation();
                        let fileUrl;
                        if (!formik.values.RoutePermit?.location)
                          fileUrl = URL.createObjectURL(
                            formik.values.RoutePermit?.location
                          );
                        else {
                          fileUrl = formik.values.RoutePermit?.location;
                        }
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.target = '_blank';
                        a.click();
                      }}
                    >
                      <p className="text-primary">
                        {formatFileName(formik.values.RoutePermit)}
                      </p>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue('RoutePermit', null);
                        }}
                      >
                        <img src={CancelIcon} className="w-2 h-2" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <FileUploadButton
                formik={formik}
                propertyName="RoutePermit"
                iconType={UploadIconType.UPLOAD}
                label="Route Permit"
              />
            </div>
            <div className="flex justify-between items-center">
              {!formik.values.RunningPaper ? (
                <p className="flex gap-1 text-sm">
                  Upload a picture or document
                </p>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  {formik.values?.RunningPaper && (
                    <div
                      className="rounded-full w-[123px] py-[1px] justify-between text-xs px-2 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                      onClick={async (e) => {
                        e.stopPropagation();
                        let fileUrl;
                        if (!formik.values.RunningPaper?.location)
                          fileUrl = URL.createObjectURL(
                            formik.values.RunningPaper?.location
                          );
                        else {
                          fileUrl = formik.values.RunningPaper?.location;
                        }
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.target = '_blank';
                        a.click();
                      }}
                    >
                      <p className="text-primary">
                        {formatFileName(formik.values.RunningPaper)}
                      </p>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue('RunningPaper', null);
                        }}
                      >
                        <img src={CancelIcon} className="w-2 h-2" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <FileUploadButton
                formik={formik}
                propertyName="RunningPaper"
                iconType={UploadIconType.UPLOAD}
                label="Running Paper"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Vehicle kms & Maintenance
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="distance"
                  placeholder="Enter Vehicle Current kms"
                  value={formik.values?.distance || ''}
                  size="small"
                  type="number"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('distance', e.target.value)
                  }
                />
              </FormControl>
            </div>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="maintainenceMileage"
                  placeholder="Maintenance Required"
                  value={formik.values?.maintainenceMileage || ''}
                  size="small"
                  type="number"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('maintainenceMileage', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Average Speed & Mileage
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="avgSpeed"
                  placeholder="Enter Vehicle Average Speed"
                  value={formik.values?.avgSpeed || ''}
                  size="small"
                  type="number"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('avgSpeed', e.target.value)
                  }
                />
              </FormControl>
            </div>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="mileage"
                  placeholder={`Enter Mileage (${
                    settings?.distance?.symbol || 'Km'})`}
                  value={formik.values?.mileage || ''}
                  size="small"
                  type="number"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('mileage', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between py-4 border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Last Maintenance Mileage
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="lastMaintainenceMileage"
                  placeholder="Enter Last Maintenance Mileage"
                  value={formik.values?.lastMaintainenceMileage || ''}
                  size="small"
                  type="number"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'lastMaintainenceMileage',
                      e.target.value
                    )
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between py-4 border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Dedicated Driver
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full">
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  options={drivers || []}
                  onChange={async (e, value) => {
                    formik.setFieldValue('driver', value);
                    setDriverSearch('');
                  }}
                  value={formik.values?.driver || {}}
                  inputValue={driverSearch}
                  onInputChange={(event, newValue) => {
                    setDriverSearch(newValue);
                  }}
                  getOptionLabel={(option) =>
                    option?.name && option?.phone
                      ? `${option?.name} - ${option?.phone}`
                      : ''
                  }
                  label="Driver Name"
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px'
                    },
                    '& textarea::placeholder': {
                      fontSize: '14px'
                    },
                    '& label': {
                      fontSize: '14px'
                    }
                  }}
                  clearOnBlur
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between py-4 border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0">
          <div>
            <p className="font-semibold text-sm text-primaryBlue">
              Upload Vehicle Image
            </p>
            <p className="text-xs">File must be pdf, jpeg & png</p>
          </div>
          <div className="w-[479px] border border-dashed p-4 border-[#C4C6CB] rounded">
            <div className="flex justify-between items-center">
              {!formik.values.Photo ? (
                <p className="flex gap-1 text-sm">
                  Upload a picture or document
                </p>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  {formik.values.Photo && (
                    <div
                      className="rounded-full w-[123px] py-[1px] justify-between text-xs px-2 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                      onClick={async (e) => {
                        e.stopPropagation();
                        let fileUrl;
                        if (!formik.values.Photo?.location)
                          fileUrl = URL.createObjectURL(
                            formik.values.Photo?.location
                          );
                        else {
                          fileUrl = formik.values.Photo?.location;
                        }
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.target = '_blank';
                        a.click();
                      }}
                    >
                      <p className="text-primary">
                        {formatFileName(formik.values.Photo)}
                      </p>
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue('Photo', null);
                        }}
                      >
                        <img src={CancelIcon} className="w-2 h-2" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <FileUploadButton
                formik={formik}
                propertyName="Photo"
                iconType={UploadIconType.ATTACHMENT}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between pt-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Tracking Enabled
            </p>
          </div>
          <div className="w-[479px]">
            <FormControl fullWidth={true} variant="outlined">
              <Switch
                checked={formik.values?.trackingEnabled}
                onChange={(newValue) => {
                  formik.setFieldValue('trackingEnabled', newValue);
                }}
                label="Tracking Enabled"
              ></Switch>
            </FormControl>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VehicleForm;

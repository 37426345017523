import { makeStyles } from '@material-ui/core';
import { Input, InputAdornment } from '@mui/material';
import '../../../../assets/css/style.css';
import Magnify from '../../../../assets/icons/magnify.svg';

const useStyles = makeStyles(() => ({
  searchBar: {
    color: '#0B1940',
    maxWidth: '336px',
    height: '35px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    padding: '8px 16px',
    width: '100%',
    overflow: 'hidden'
  }
}));

export default function NewSearchBar(props) {
  const classes = useStyles();

  return (
    <Input
      type="text"
      className={classes.searchBar}
      placeholder={props.placeholder || 'Search here'}
      onChange={(e) => props.setSearchValue(e.target.value)}
      sx={{
        ':before': { borderBottomColor: '#0C71D6' },
        ':after': { borderBottomColor: '#0C71D6' }
      }}
      startAdornment={
        <InputAdornment position="start">
          <img src={Magnify} alt="search" width={20} height={20} />
        </InputAdornment>
      }
    />
  );
}

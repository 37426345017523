import TruckIconGrey from '../../assets/icons/TruckIconGrey.svg';
import TruckIcon from '../../assets/icons/TruckIcon.svg';
import ShipIconGrey from '../../assets/icons/ShipIconGrey.svg';
import ShipIcon from '../../assets/icons/ShipIcon.svg';
import AirplaneIconGrey from '../../assets/icons/AirplaneIconGrey.svg';
import AirplaneIcon from '../../assets/icons/AirplaneIcon.svg';
import RightArrow from '../../assets/icons/rightArrow.svg';
import { ShipmentMode } from './constants';
import clsx from 'clsx';
const ShipmentHead = ({
  selectedShipmentMode,
  setShipmentMode,
  origin = '',
  destination = '',
  edit
}) => {
  const sectionBox =
    'w-1/3 h-10 border-b border-t border-solid flex items-center justify-center';
  return (
    <>
      <div className="ml-2 flex items-center mb-3">
        <p className="text-primaryBlue font-semibold text-lg">
          {origin.length > 20 ? `${origin.substring(0, 20)}...` : origin}
        </p>
        <img src={RightArrow} alt="right" className="mx-4" />
        <p className="text-primaryBlue font-semibold text-lg">
          {destination.length > 20
            ? `${destination.substring(0, 20)}...`
            : destination}
        </p>
      </div>
      <div className="flex items-center w-[590px] h-10 ">
        <div
          onClick={() => {
            if (!edit) {
              setShipmentMode(ShipmentMode.LAND);
            }
          }}
          className={clsx(
            sectionBox,
            'border-l rounded-l',
            {
              'bg-dark100 border-oasisPrimary500 border-r':
                selectedShipmentMode === ShipmentMode.LAND
            },
            {
              'bg-white border-dark300':
                selectedShipmentMode !== ShipmentMode.LAND
            },
            {
              'cursor-pointer': !edit
            }
          )}
        >
          <div className="flex gap-2">
            <img
              src={
                selectedShipmentMode === ShipmentMode.LAND
                  ? TruckIcon
                  : TruckIconGrey
              }
              alt="Truck"
            />
            <p
              className={clsx(
                'text-xs font-medium',
                {
                  'text-dark500': selectedShipmentMode !== ShipmentMode.LAND
                },
                {
                  'text-primaryBlue': selectedShipmentMode === ShipmentMode.LAND
                }
              )}
            >
              Land
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            if (!edit) {
              setShipmentMode(ShipmentMode.SEA);
            }
          }}
          className={clsx(
            sectionBox,
            {
              'border-l': selectedShipmentMode === ShipmentMode.AIR
            },
            {
              'border-r': selectedShipmentMode === ShipmentMode.LAND
            },
            {
              'bg-dark100 border-oasisPrimary500 border-l border-r':
                selectedShipmentMode === ShipmentMode.SEA
            },
            {
              'bg-white border-dark300':
                selectedShipmentMode !== ShipmentMode.SEA
            },
            {
              'cursor-pointer': !edit
            }
          )}
        >
          <div className="flex gap-2">
            <img
              src={
                selectedShipmentMode === ShipmentMode.SEA
                  ? ShipIcon
                  : ShipIconGrey
              }
              alt="Ship"
            />
            <p
              className={clsx(
                'text-xs font-medium',
                {
                  'text-dark500': selectedShipmentMode !== ShipmentMode.SEA
                },
                {
                  'text-primaryBlue': selectedShipmentMode === ShipmentMode.SEA
                }
              )}
            >
              Sea
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            if (!edit) {
              setShipmentMode(ShipmentMode.AIR);
            }
          }}
          className={clsx(
            sectionBox,
            'border-r rounded-r',
            {
              'bg-dark100 border-oasisPrimary500 border-l':
                selectedShipmentMode === ShipmentMode.AIR
            },
            {
              'bg-white border-dark300':
                selectedShipmentMode !== ShipmentMode.AIR
            },
            {
              'cursor-pointer': !edit
            }
          )}
        >
          <div className="flex gap-2">
            <img
              src={
                selectedShipmentMode === ShipmentMode.AIR
                  ? AirplaneIcon
                  : AirplaneIconGrey
              }
              alt="Plane"
            />
            <p
              className={clsx(
                'text-xs font-medium',
                {
                  'text-dark500': selectedShipmentMode !== ShipmentMode.AIR
                },
                {
                  'text-primaryBlue': selectedShipmentMode === ShipmentMode.AIR
                }
              )}
            >
              Air
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentHead;

import { Tooltip } from '@material-ui/core';

import { dateFormatSlashDivider } from '../../../utils/common';
import { Label } from '../../../core-components/atoms/Text';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
const orderPickingColumns = [
  {
    field: 'id',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader text-xs text-dark500">
          ORDER NUMBER
        </p>
      );
    },
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={`${params?.row?.customId}`}>
            <span className="table-cell-trucate">{params?.row?.customId}</span>
          </Tooltip>
        </>
      );
    }
  },
  {
    field: 'referenceID',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader text-xs text-dark500">
          SUPPORTING DOC ID
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={params?.row?.referenceId}>
        <span className="table-cell-trucate">{params?.row?.referenceId}</span>
      </Tooltip>
    )
  },
  {
    field: 'dateOfOrder',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader text-center text-xs text-dark500">
          DATE OF ORDER
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={`${dateFormatSlashDivider(params?.row?.dropoffDate)}`}>
        <span className="table-cell-trucate text-center">
          {dateFormatSlashDivider(params?.row?.dropoffDate)}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'source',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader text-xs text-dark500">
          ORIGIN & DESTINATION
        </p>
      );
    },
    renderCell: (params) => (
      <div className={'flex-1'}>
        <Tooltip title={params?.row?.pickupNode?.name}>
          <Label variant={'xs'}>
            {params?.row?.pickupNode?.name} <span>➜</span>{' '}
          </Label>
        </Tooltip>
        <Tooltip title={params?.row?.dropOffNode?.name}>
          <Label variant={'xs'}>{params?.row?.dropOffNode?.name}</Label>
        </Tooltip>
      </div>
    )
  },
  {
    field: 'pickupId',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader text-xs text-dark500">
          PICKING ID
        </p>
      );
    },
    renderCell: (params) => (
      <span className="table-cell-trucate text-center">
        {params?.row?.OrdersTasks?.map((o) => o.taskId).join(', ')}
      </span>
    )
  },
  {
    field: 'orderQty',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader text-xs text-dark500">
          ORDER QTY
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={params?.row?.dropOffNode?.name}>
        <span className="table-cell-trucate">
          {params?.row?.OrderProductGroups.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue.quantity;
            },
            0
          )}
        </span>
      </Tooltip>
    )
  }
];

export default orderPickingColumns;

/*eslint-disable*/

import { FormControl } from '@mui/material';
import { Title } from '../../../core-components/atoms/Text';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import CustomTextField from '../../../core-components/atoms/TextField';

const { useContext, useState, useEffect } = require('react');
const { SharedContext } = require('../../../utils/common');
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import Button from '../../../core-components/atoms/Button';

const UomDropDown = ({ value, onChange, filterUomNames = [] }) => {
  const [uoms, setUoms] = useState([]);
  const [search, setSearch] = useState('');

  const { setAPILoader } = useContext(SharedContext);

  useEffect(() => {
    // Debounce the API call to avoid making a request on every keystroke
    const delayDebounceFn = setTimeout(() => {
      getUoms(search);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const getUoms = async (search, page, columns) => {
    try {
      setAPILoader(true);
      const uoms = await API.get('uoms', {
        params: { page: page, columns, search }
      });
      setUoms(
        uoms?.data?.filter(
          (u) => u.isActive === true && filterUomNames?.indexOf(u?.name) == -1
        ) || []
      );
    } catch (err) {
      let errors = err.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const listBoxProps = {
    fontSize: 14
  };

  const inputProps = {
    classes: {
      input: 'text-sm h-7',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11.6px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  return (
    <FormControl fullWidth={true} variant="outlined">
      <Autocomplete
        options={uoms || []}
        value={value}
        getOptionLabel={(uom) => uom.name || ''}
        placeholder="Add Secondary UoM"
        onChange={onChange}
        sxProps={sxProps}
        customInputProps={inputProps}
        listBoxProps={listBoxProps}
        inputValue={search}
        onInputChange={(event, newValue) => setSearch(newValue)}
      />
    </FormControl>
  );
};

const UomConversion = ({
  secondaryUoms,
  primaryUom,
  setSecondaryUoms,
  setPrimaryUom
}) => {
  const listBoxProps = {
    fontSize: 14
  };

  const inputProps = {
    classes: {
      input: 'text-sm h-7',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11.6px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  return (
    <div className="w-[700px]">
      <div className="w-full flex justify-between items-center mb-4 gap-4">
        <Title variant={'md'} className="w-[40%]">
          Primary Unit of Measure (UoM)
        </Title>
        <div className="flex w-[60%] justify-between">
          <div className="w-full">
            <FormControl fullWidth={true} variant="outlined">
              <Autocomplete
                options={[]}
                value={primaryUom}
                getOptionLabel={(uom) => uom.name || ''}
                placeholder="Add Primary UoM"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setPrimaryUom(newValue);
                  }
                }}
                sxProps={sxProps}
                customInputProps={inputProps}
                listBoxProps={listBoxProps}
                disabled
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center number-input-container">
        <div className="w-full">
          {secondaryUoms?.map((uom, index) => (
            <div className="flex justify-between mb-4 gap-4 w-full items-center">
              <Title variant={'md'} className="w-[40%]">
                Secondary UoM & Conversion
              </Title>
              <div className="w-[60%] flex gap-4">
                <div className="w-[50%] h-[40px]">
                  <FormControl fullWidth={true} variant="outlined">
                    <UomDropDown
                      value={uom}
                      onChange={(event, newValue) => {
                        const values = [...secondaryUoms];
                        values[index]['uomId'] = newValue.id;
                        values[index]['name'] = newValue.name;
                        setSecondaryUoms(values);
                      }}
                      filterUomNames={secondaryUoms?.map((u) => u.name)}
                    />
                  </FormControl>
                </div>
                <div className="w-[50%] h-[40px]">
                  <FormControl fullWidth={true} variant="outlined">
                    <CustomTextField
                      placeholder="Conversion Rate"
                      type="number"
                      value={uom?.conversionFactor || ''}
                      onChange={(event) => {
                        const values = [...secondaryUoms];
                        values[index]['conversionFactor'] = Number(
                          event.target.value
                        );
                        setSecondaryUoms(values);
                      }}
                      size="small"
                      sxProps={sxProps}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <Button
          variant="text"
          label="+ Add More"
          onClick={() =>
            setSecondaryUoms([
              ...secondaryUoms,
              { uomId: null, conversionFactor: null }
            ])
          }
        />
      </div>
    </div>
  );
};

export default UomConversion;

import React, { useEffect, useState } from 'react';
import ExcelIcon from '../assets/icons/excel.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import '../index.css';

const ImagePreview = ({ file }) => {
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (file?.location) {
      setPreview(file.location);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }, []);

  return (
    <div className="image-preview-container relative">
      {preview && preview?.includes('pdf') ? (
        <embed className="image-preview br-3" src={preview} />
      ) : preview?.includes(
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml'
        ) || preview?.includes('.xlsx') ? (
        <img className="image-preview br-3" src={ExcelIcon} />
      ) : (
        <img className="image-preview br-3" src={preview} />
      )}
      {
        <div
          className="image-preview-doc"
          onClick={async () => {
            let fileUrl;
            if (!file?.location) fileUrl = URL.createObjectURL(file);
            else {
              fileUrl = file.location;
            }
            const a = document.createElement('a');
            a.href = fileUrl;
            a.target = '_blank';
            a.click();
          }}
        >
          <VisibilityIcon />
        </div>
      }
    </div>
  );
};

export default ImagePreview;

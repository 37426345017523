/*eslint-disable*/
import { Tooltip } from '@material-ui/core';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import * as React from 'react';
import HoldIcon from '../../../assets/icons/holdIcon.svg';
import ReleaseIcon from '../../../assets/icons/releaseIcon.svg';
import Button from '../../../core-components/atoms/Button';
import { decimalDisplay } from '../../../utils/common';
import HoldFadedIcon from '../../../assets/icons/holdFadedIcon.svg';
import ReleaseFadedIcon from '../../../assets/icons/releaseFadedIcon.svg';
import { ActionType, PopoverOrigin, Source } from '../constants';
import useStyles from '../inventoryStyles';

export default function ReleaseOrHoldPopover({
  closeCallback,
  enterCallBack,
  inventoryAmount = 0,
  source,
  id,
  type,
  actionCallback,
  SKUCount = 0,
  loading = false,
  locationCount = 0,
  origin,
  location,
  SKUName,
  SKUBrand
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const open = Boolean(inventoryAmount && anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    enterCallBack && enterCallBack();
  };
  const handleClose = () => {
    setAnchorEl(null);
    closeCallback && closeCallback();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {origin === PopoverOrigin.FOOTER && (
          <Button
            variant="transparent"
            label={`${
              type === ActionType.HOLD ? 'Hold ' : 'Release '
            } ${decimalDisplay(inventoryAmount)} qty`}
            className="py-2 px-3 ml-2 h-8 border-solid border "
            overrideSize={true}
            labelClass="font-medium text-xs"
            onClick={(e) => handleClick(e)}
            disabled={loading}
          />
        )}
        {origin === PopoverOrigin.BATCH && (
          <Tooltip
            title={type === ActionType.HOLD ? 'Hold' : 'Release'}
            placement="top"
            arrow
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.arrow
            }}
          >
            <span>
              <Button
                variant="tiny-transparent"
                icon={
                  <img
                    src={type === ActionType.HOLD ? HoldIcon : ReleaseIcon}
                    alt=""
                  />
                }
                className={'p-1 border-0 h-7 min-w-fit rounded-none'}
                overrideSize={true}
                labelClass="p-0"
                onClick={(e) => handleClick(e)}
              />
            </span>
          </Tooltip>
        )}
        {(origin === PopoverOrigin.DROPDOWN ||
          origin === PopoverOrigin.BATCH_DROPDOWN) && (
          <div className="flex gap-[6px]" onClick={(e) => handleClick(e)}>
            <img
              src={type === ActionType.HOLD ? HoldFadedIcon : ReleaseFadedIcon}
              className="w-4"
            />
            <span className="text-xs text-primaryBlue">
              {type === ActionType.HOLD ? 'Hold' : 'Release'}
            </span>
          </div>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 0,
            boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)'
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={0}
      >
        <div
          className={clsx(
            'w-[310px] p-4 rounded-[8px] bg-white z-10 transition-all shadow-elevation-2 overflow-hidden'
          )}
        >
          <p className="text-primaryBlue text-base font-medium my-2">
            Are you Sure?
          </p>
          {origin === PopoverOrigin.FOOTER && (
            <p className="text-primaryBlue text-sm mb-4">
              {decimalDisplay(inventoryAmount || 0)}{' '}
              {source === Source.INVENTORY
                ? `Item${inventoryAmount !== 1 ? 's' : ''} for ${SKUCount} SKU${
                    SKUCount !== 1 ? 's' : ''
                  } `
                : `Item${
                    inventoryAmount !== 1 ? 's' : ''
                  } from ${locationCount} location${
                    locationCount !== 1 ? 's' : ''
                  } `}
              will be {type === ActionType.HOLD ? 'put on hold' : 'released'}.{' '}
              You can always{' '}
              {type === ActionType.HOLD ? 'release' : 'put them on hold'} later.
            </p>
          )}
          {(origin === PopoverOrigin.BATCH ||
            origin === PopoverOrigin.BATCH_DROPDOWN) && (
            <p className="text-primaryBlue text-sm mb-4">
              {decimalDisplay(inventoryAmount || 0)} Items from Location{' '}
              {location} will be{' '}
              {type === ActionType.HOLD ? 'put on hold' : 'released'}. You can
              always {type === ActionType.HOLD ? 'release' : 'put them on hold'}{' '}
              later.
            </p>
          )}
          {origin === PopoverOrigin.DROPDOWN && (
            <p className="text-primaryBlue text-sm mb-4">
              {decimalDisplay(inventoryAmount || 0)} Items
              {SKUBrand ? ` for ${SKUBrand} ` : ' '}will be{' '}
              {type === ActionType.HOLD ? 'put on hold' : 'released'}. You can
              always {type === ActionType.HOLD ? 'release' : 'put them on hold'}{' '}
              later.
            </p>
          )}
          <div className="w-full flex justify-end">
            <Button
              size="small"
              variant="text"
              label="Cancel"
              className={'py-2 px-3 h-8'}
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={() => {
                handleClose();
              }}
            />
            <Button
              size="small"
              variant="primary"
              label="Confirm"
              className={
                'py-2 px-3 ml-1 h-8 border-solid border border-Primary'
              }
              overrideSize={true}
              labelClass="font-medium text-xs"
              disabled={loading}
              onClick={() => {
                actionCallback();
                handleClose();
              }}
            />
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
}

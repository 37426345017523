import { Menu, MenuItem } from '@material-ui/core';

const PrintMenu = ({ anchorEl, handleClose, setWatermark }) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          setWatermark && setWatermark('all');
        }}
      >
        All 3 Copies
      </MenuItem>
      <MenuItem
        onClick={() => {
          setWatermark && setWatermark('customer');
        }}
      >
        Customer Copy
      </MenuItem>
      <MenuItem
        onClick={() => {
          setWatermark && setWatermark('gatepass');
        }}
      >
        Gatepass Copy
      </MenuItem>
      <MenuItem
        onClick={() => {
          setWatermark && setWatermark('vendor');
        }}
      >
        Transport Vendor Copy
      </MenuItem>
    </Menu>
  );
};

export default PrintMenu;

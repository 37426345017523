import { Modal } from '@mui/material';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import Button from '../../core-components/atoms/Button';
import { useNavigate } from 'react-router';
import { CloseOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import PickingGenerationForm from './PickingGenerationForm';
import SimplifiedPickingPreviewDetail from './SimplifiedPickingDetail';
import BackArrowIcon from '../../assets/icons/chevronLeft.svg';

function AddCustomPicking() {
  const [company, setCompany] = useState(null);
  const [warehouse, setWarehouse] = useState();
  const [selectedOrdersForPicking, setSelectedOrdersForPicking] = useState([]);
  const [pickingPreviewFlag, setPickingPreviewFlag] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [generateResult, setGenerateResult] = useState(false);
  const [valid, setValid] = useState(false);
  const [completeTask, setCompleteTask] = useState(false);
  const navigate = useNavigate();
  const [enableStartPicking, setEnableStartPicking] = useState(false);

  const handleBackButtonPickingModalPreview = () => {
    setPickingPreviewFlag(false);
  };

  const handleCloseDialog = () => {
    setSelectedOrdersForPicking([]);
    setCompany(null);
    setWarehouse(null);
    setPickingPreviewFlag(false);
    setEnableStartPicking(false);
  };
  const pickingGeneration = (
    <>
      {pickingPreviewFlag ? (
        <SimplifiedPickingPreviewDetail
          warehouse={warehouse}
          company={company}
          ordersData={ordersData}
          completeTask={completeTask}
          setValid={setValid}
          setCompleteTask={setCompleteTask}
          setGenerateResult={setGenerateResult}
          generateResult={generateResult}
          selectedOrdersForPicking={selectedOrdersForPicking}
          handleBackButtonPickingModalPreview={
            handleBackButtonPickingModalPreview
          }
          handleCloseDialog={handleCloseDialog}
        />
      ) : (
        <PickingGenerationForm
          setWarehouse={setWarehouse}
          setCompany={setCompany}
          warehouse={warehouse}
          setEnableStartPicking={setEnableStartPicking}
          selectedOrdersForPicking={selectedOrdersForPicking}
          setSelectedOrdersForPicking={setSelectedOrdersForPicking}
          setOrdersData={setOrdersData}
          isGlobalPickingModal={true}
        />
      )}
    </>
  );

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open={true}
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <form>
          <FixedLayout
            content={pickingGeneration}
            header={
              <>
                <div className="flex justify-between w-full items-center">
                  <div className="flex items-center">
                    {pickingPreviewFlag && (
                      <img
                        src={BackArrowIcon}
                        alt="Back Icon"
                        className="cursor-pointer"
                        onClick={() => {
                          setPickingPreviewFlag(false);
                          handleCloseDialog();
                        }}
                      />
                    )}
                    <p className="font-bold text-2xl">
                      {pickingPreviewFlag
                        ? "2. Picking for all SKU's"
                        : '1. Orders for Picking'}
                    </p>
                  </div>
                  <div>
                    <CloseOutlined
                      onClick={() => {
                        navigate('/routing/task-management');
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div
                    className={`absolute -bottom-[6px] left-0 border-solid border-0 border-b-[6px] border-b-primary`}
                  />
                </div>
              </>
            }
            footer={
              <>
                <div className="flex justify-between w-full">
                  <Button
                    label="Cancel"
                    variant="tertiary"
                    onClick={() => navigate('/routing/task-management')}
                  />
                  {pickingPreviewFlag ? (
                    <div className="flex">
                      <Button
                        label="Complete Picking"
                        variant="secondary"
                        onClick={() => {
                          setGenerateResult(true);
                          setCompleteTask(true);
                        }}
                        className="mr-2"
                        disabled={!valid}
                      />
                      <Button
                        label="Create Picking"
                        variant="primary"
                        onClick={() => {
                          setGenerateResult(true);
                        }}
                        disabled={!valid}
                      />
                    </div>
                  ) : (
                    <Button
                      label={'Start Picking'}
                      variant="primary"
                      onClick={() => {
                        setPickingPreviewFlag(true);
                      }}
                      disabled={!enableStartPicking}
                    />
                  )}
                </div>
              </>
            }
          />
        </form>
      </div>
    </Modal>
  );
}

export default AddCustomPicking;

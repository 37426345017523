import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  labelPadding: {
    paddingTop: 5
  },
  selectBox: {
    height: 55
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    gap: 10
  },
  formControl: {
    padding: '0 5px'
  },
  error: {
    color: '#d32f2f'
  },
  removeLogoBtn: {
    marginTop: 5
  }
}));

export default useStyles;

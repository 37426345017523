import {
  Grid,
  Button,
  TextField,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { isRequired } from '../../../utils/validators';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useEffect, useState, useCallback } from 'react';
import { upload } from '../../../utils/upload';
import { Autocomplete } from '@material-ui/lab';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  driverBtn: {
    marginRight: '18px'
  }
}));

function AddDriverView({
  selectedDriver,
  formErrors,
  open,
  handleClose,
  addDriver,
  attemptedAlready,
  setAttemptedAlready
}) {
  const classes = useStyles();

  const [driverName, setDriverName] = useState('');
  const [driverPhone, setDriverPhone] = useState('');
  const [validation, setValidation] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [vendorName, setVendorName] = useState('');
  const [vendorId, setVendorId] = useState(null);
  const [cnicNumber, setCNICNumber] = useState('');
  const [drivingLicenseNumber, setDrivingLicenseNumber] = useState('');
  const [drivingLicenseImage, setDrivingLicenseImage] = useState(null);
  const [CNICImage, setCNICImage] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [searchVendor, setSearchVendor] = useState([]);
  useEffect(() => {
    if (open) resetLocalStates();
    if (selectedDriver) {
      setDriverName(selectedDriver ? selectedDriver.name : '');
      setDriverPhone(selectedDriver ? selectedDriver.phone : '');
      setDrivingLicenseNumber(
        selectedDriver ? selectedDriver.drivingLicenseNumber : ''
      );
      setVendorName(selectedDriver.Vendor ? selectedDriver.Vendor.name : '');
      setVendorId(selectedDriver.Vendor ? selectedDriver.Vendor.id : '');
      setCNICNumber(selectedDriver ? selectedDriver.cnicNumber : '');
    } else {
      resetLocalStates();
    }
  }, [open]);
  const resetLocalStates = () => {
    setDriverName('');
    setDriverPhone('');
    setValidation({});
    setDrivingLicenseNumber('');
    setVendorName('');
    setVendorId(null);
    setCNICNumber(null);
    setDrivingLicenseImage(null);
    setCNICImage(null);
  };

  async function getVendors(value) {
    try {
      // const {data} = await API.get(`/companies?search=${value || ''}&filters:{"colVal":{isActive:1}}&columns[]=name&columns[]=type&columns[]=$Contact.firstName$&columns[]=$Contact.email$&type=VENDOR
      // `);
      const { data } = await API.get(`companies`, {
        params: {
          search: value,
          filters: { colVal: { isActive: '1' }, sortable: '' },
          columns: ['name'],
          type: 'VENDOR'
        }
      });

      setVendors(data.rows);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  }

  const getVendorsAPI = useCallback(
    debounce((searchVendor) => {
      getVendors(searchVendor);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getVendorsAPI(searchVendor);
  }, [searchVendor, selectedDriver, open]);

  const handleSubmit = async () => {
    let strDriverNumber = driverPhone;
    let stringDriverNumber = strDriverNumber.replace(/-/g, '');
    const newDriver = {
      name: driverName,
      phone: stringDriverNumber,
      drivingLicenseNumber: drivingLicenseNumber,
      companyId: vendorId,
      cnicNumber: cnicNumber
    };
    setValidation({
      driverName: true,
      driverPhone: true,
      validation: true,
      drivingLicenseNumber: true,
      vendorId: true,
      cnicNumber: true,
      drivingLicenseImage: false,
      CNICImage: false
    });

    if (
      isRequired(driverName) &&
      isRequired(driverPhone) &&
      isRequired(validation) &&
      isRequired(drivingLicenseNumber) &&
      isRequired(vendorId) &&
      isRequired(cnicNumber)
    ) {
      if (drivingLicenseImage)
        [newDriver.drivingLicenseId] = await upload(
          [drivingLicenseImage],
          'driver'
        );
      if (CNICImage) [newDriver.cnicId] = await upload([CNICImage], 'driver');

      setAttemptedAlready(true);
      addDriver(newDriver);
    }
  };

  const validateDrivingLicenceImage = (event) => {
    const checkFile = event.target.files[0];
    // let dimentions
    if (!checkFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert('Driving lisence image must be only image file!');
      return false;
    }
    const isLt2M = checkFile.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      alert('Driving lisence image must smaller than 1MB!');
      return false;
    }
    setDrivingLicenseImage(checkFile);
  };

  const validateCnicImage = (event) => {
    const checkFile = event.target.files[0];
    if (!checkFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert('Driving lisence image must be only image file!');
      return false;
    }
    const isLt2M = checkFile.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      alert('Driving licence image must smaller than 1MB!');
      return false;
    }
    setCNICImage(checkFile);
  };
  // const phoneNumberMask = [/[0]/, /[3]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle disableTypography className="title">
            {!selectedDriver ? 'Add Driver' : 'Edit Driver'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="selectedDriver"
                    label="Driver Name"
                    type="text"
                    variant="outlined"
                    value={driverName}
                    onChange={(e) => setDriverName(e.target.value)}
                    onBlur={() =>
                      setValidation({ ...validation, driverName: true })
                    }
                  />
                  {validation.driverName && !isRequired(driverName) ? (
                    <Typography color="error">
                      Driver name is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                  {/* {validation.driverName && !isChar(driverName) ? (
                    <Typography color="error">
                      Driver name is only characters!
                    </Typography>
                  ) : (
                    ''
                  )} */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="driverliceneNumber"
                    label="Driving License Number"
                    type="text"
                    variant="outlined"
                    value={drivingLicenseNumber}
                    onChange={(e) => setDrivingLicenseNumber(e.target.value)}
                    onBlur={() =>
                      setValidation({
                        ...validation,
                        drivingLicenseNumber: true
                      })
                    }
                  />
                  {validation.drivingLicenseNumber &&
                  !isRequired(drivingLicenseNumber) ? (
                    <Typography color="error">
                      License number is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    margin="normal"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="companies"
                      key={'companies'}
                      options={vendors}
                      defaultValue={
                        selectedDriver
                          ? selectedDriver.Vendor
                          : // vendors.find((m) => m.id == selectedDriver.Vendor.id)
                            ''
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Carrier"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(vendor) => {
                        if (vendor && vendor.name) return vendor.name;
                        else {
                          return vendor || '';
                        }
                      }}
                      // filterOptions={filterOptions}
                      onBlur={() =>
                        setValidation({ ...validation, vendor: true })
                      }
                      onInputChange={(event, newValue) => {
                        setSearchVendor(newValue);
                      }}
                      onFocus={() => {
                        setSearchVendor('');
                      }}
                      onChange={(event, newValue) => {
                        setVendorId(newValue ? newValue.id : null);
                      }}
                    />
                    {validation.vendorId && !isRequired(vendorId) ? (
                      <Typography color="error">Vendor is required!</Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className="parentCompany">
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    {/* <InputMask
                      className={clsx({ ['mask-text']: true })}
                      variant="outlined"
                      name="phone"
                      mask={phoneNumberMask}
                      label="Driver Phone"
                      id="companyPhone"
                      type="text"
                      maskChar="_"
                      disabled={false}
                      value={driverPhone}
                      onChange={(e) => {
                        setDriverPhone(e.target.value);
                      }}
                      style={{
                        height: '17%',
                        width: '90%',
                        marginLeft: 0,
                        marginTop: 6,
                        borderColor: '#c4c4c4',
                        color: '#2f2727',
                        fontWeight: 600,
                        padding: '17px 12px'
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, driverPhone: true })
                      }
                    >
                      {() => (
                        <TextField
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          className={classes.labelBox}
                          margin="dense"
                          name="phone"
                          label="Driver Phone"
                          id="companyPhone"
                          type="text"
                          variant="outlined"
                        />
                      )}
                    </InputMask> */}
                    <PhoneInput
                      country={'pk'}
                      value={driverPhone}
                      onChange={(phone) => {
                        setDriverPhone(phone);
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, driverPhone: true })
                      }
                      containerStyle={{ marginTop: '8px' }}
                      inputStyle={{ width: '100%', height: '56px' }}
                      variant="outlined"
                    />
                    {validation.driverPhone && !isRequired(driverPhone) ? (
                      <Typography color="error">
                        Phone number is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                    {/* {validation.driverPhone && !isPhone(driverPhone) && isRequired(driverPhone) ? <Typography color="error">Format: 0343XXXXX79</Typography> : ''} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className="parentCompany">
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <TextField
                      fullWidth={true}
                      inputProps={{ className: classes.textBox }}
                      className={classes.labelBox}
                      margin="dense"
                      name="phone"
                      label="National ID Number"
                      id="cnic"
                      type="text"
                      variant="outlined"
                      value={cnicNumber}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9-]/g,
                          ''
                        );
                        setCNICNumber(sanitizedValue);
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, cnicNumber: true })
                      }
                    />
                    {validation.cnicNumber && !isRequired(cnicNumber) ? (
                      <Typography color="error">
                        National ID Number is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                    {/* {validation.driverPhone && !isRequired(driverPhone) ? <Typography color="error">Phone number is required!</Typography> : ''}
                    {validation.driverPhone && !isPhone(driverPhone.replace(/-/g, "")) && isRequired(driverPhone) ? <Typography color="error">Incorrect Phone number!</Typography> : ''} */}
                    {/* {validation.driverPhone && !isPhone(driverPhone) && isRequired(driverPhone) ? <Typography color="error">Format: 0343XXXXX79</Typography> : ''} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Tooltip title="Upload Driving License Image">
                      <Button
                        variant="contained"
                        component="label"
                        color={
                          (selectedDriver &&
                            selectedDriver?.DrivingLicense?.id) ||
                          drivingLicenseImage
                            ? 'primary'
                            : 'default'
                        }
                        startIcon={<CloudUploadIcon />}
                      >
                        Driving License{' '}
                        {(selectedDriver &&
                          selectedDriver?.DrivingLicense?.id) ||
                        drivingLicenseImage
                          ? 'Uploaded'
                          : ''}
                        <input
                          type="file"
                          hidden
                          onChange={(e) => validateDrivingLicenceImage(e)}
                          accept=".jpg,.png,.jpeg"
                        />
                      </Button>
                    </Tooltip>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Tooltip title="Upload CNIC Image">
                      <Button
                        variant="contained"
                        component="label"
                        color={
                          (selectedDriver && selectedDriver?.Cnic?.id) ||
                          CNICImage
                            ? 'primary'
                            : 'default'
                        }
                        startIcon={<CloudUploadIcon />}
                      >
                        CNIC Image{' '}
                        {(selectedDriver && selectedDriver?.Cnic?.id) ||
                        CNICImage
                          ? 'Uploaded'
                          : ''}
                        <input
                          type="file"
                          hidden
                          onChange={(e) => validateCnicImage(e)}
                          accept=".jpg,.png,.jpeg"
                        />
                      </Button>
                    </Tooltip>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.driverBtn}>
            <Tooltip title="Cancel">
              <Button onClick={handleClose} color="default" variant="contained">
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title={!selectedDriver ? 'Add Driver' : 'Update Driver'}>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={attemptedAlready}
              >
                {!selectedDriver ? 'Add Driver' : 'Update Driver'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

export default AddDriverView;

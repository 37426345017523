/* eslint-disable no-unused-vars */
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { Alert, Pagination } from '@material-ui/lab';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import axios from 'axios';
import classNames from 'classnames/bind';
import FileDownload from 'js-file-download';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import { DEBOUNCE_CONST } from '../../../Config';
import '../../../assets/css/style.css';
import ConfirmDelete from '../../../components/ConfirmDelete';
import Filter from '../../../components/Filter';
import MessageSnackbar from '../../../components/MessageSnackbar';
import TableHeader from '../../../components/TableHeader';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { checkPermission } from '../../../utils/auth';
import {
  SharedContext,
  dividerDateFormatForFilter,
  getURL
} from '../../../utils/common';
import { toaster } from '../../../utils/toaster';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    // maxHeight: 450,
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  tableCellStyle: {
    width: 200,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  exportBtn: {
    marginLeft: 5
  },
  blkBtn: {
    marginLeft: 5
  },
  productBtn: {
    marginRight: 5
  },
  placeholderText: {
    color: '#CAC9C9',
    '& .MuiSelect-outlined': {
      paddingTop: '6px',
      paddingBottom: '6px'
    }
    // marginRight: 5
  }
}));

export default function ProductView() {
  const classes = useStyles();
  const { currentUser, settings } = useContext(SharedContext);

  const productColumns = [
    {
      field: 'isActive',
      headerName: '',
      // flex:1,
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },

    {
      field: 'name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Customer SKU Code
            {fetchState && productName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'description',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            SKU Name
            {fetchState && productDescription ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductDescription('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('description');
              }}
              className={
                sortable.col == 'description' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'description' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'barcode',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            SKU Barcode
            {fetchState && productBarcode ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductBarcode('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('barcode');
              }}
              className={
                sortable.col == 'barcode' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'barcode' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.barcode}`}>
          <span className="table-cell-trucate">
            {params.row.barcode && params.row.barcode.length > 20
              ? `${params.row.barcode.substring(0, 20)}...`
              : `${params.row.barcode ? params.row.barcode : ''}`}
          </span>
        </Tooltip>
      )
    },

    {
      field: `Volume ${settings?.volume || 'cm³'}`,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Volume per UoM in {settings?.volume || 'cm³'}
            {fetchState && productVol ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductVol('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('dimensionsCBM');
              }}
              className={
                sortable.col == 'dimensionsCBM' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'dimensionsCBM' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row.dimensionsCBM) {
          result.push(params.row.dimensionsCBM);
        }
        return result.join(', ');
      }
    },

    {
      field: 'weight',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Weight per UoM in Kgs
            {fetchState && productWeight ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductWeight('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('weight');
              }}
              className={
                sortable.col == 'weight' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'weight' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'Category',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.Brand.Category) {
          result.push(params.row.Brand.Category.name);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return <p className="w-100 parentActions">SKU Category</p>;
      }
    },

    {
      field: 'Brand',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.Brand) {
          result.push(params.row.Brand.name);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            SKU Brand
            {fetchState && productBrandName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductBrandName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Brand.name');
              }}
              className={
                sortable.col == 'Brand.name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Brand.name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'Uom',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.UOM) {
          result.push(params.row.UOM.name);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            UoM
            {fetchState && productUomName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductUomName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('UOM.name');
              }}
              className={
                sortable.col == 'UOM.name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'UOM.name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'perPallet',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            UoM per Pallet
            {fetchState && productPerPallet ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductPerPallet('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('perPallet');
              }}
              className={
                sortable.col == 'perPallet' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'perPallet' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [filterView, setFilterView] = useState(false);
  const [productPerPallet, setProductPerPallet] = useState('');
  const [productUomName, setProductUomName] = useState('');
  const [productBrandName, setProductBrandName] = useState('');
  const [productCatName, setProductCatName] = useState('');
  const [productWeight, setProductWeight] = useState('');
  const [productVol, setProductVol] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productBarcode, setProductBarcode] = useState('');
  const [productName, setProductName] = useState('');
  const [fetchState, setFetchState] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formErrors, setFormErrors] = useState('');
  const [addProductViewOpen, setAddProductViewOpen] = useState(false);
  const [deleteProductViewOpen, setDeleteProductViewOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [trackDateFilterOpen, setTrackDateFilterOpen] = useState(false);
  const [sortable, setSortable] = useState('');
  const [days] = useState([
    {
      id: 7,
      name: '7 days'
    },
    {
      id: 14,
      name: '14 days'
    },
    {
      id: 30,
      name: '30 days'
    },
    {
      id: 60,
      name: '60 days'
    }
  ]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(false); // bool
  const [reRender, setReRender] = useState(false);

  const navigate = useNavigate();

  const addProduct = (data) => {
    let apiPromise = null;
    if (!selectedProduct) apiPromise = axios.post(getURL('product'), data);
    else apiPromise = axios.put(getURL(`product/${selectedProduct.id}`), data);
    apiPromise
      .then((res) => {
        if (!res.data.success) {
          setFormErrors(
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={() => setFormErrors('')}
            >
              {res.data.message}
            </Alert>
          );
          return;
        }
        toaster('success', 'New product has been created.');
        setPage(1);
        closeAddProductView(false);
        getProducts();
      })
      .catch((error) => {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {error.response && error.response.data
              ? error.response.data.message
              : 'something went wrong.'}
          </Alert>
        );
      });
  };

  const bulkUpload = (data) => {
    let apiPromise = axios.post(getURL('product/bulk'), data);
    apiPromise
      .then((res) => {
        if (!res.data.success) {
          setFormErrors(
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={() => setFormErrors('')}
            >
              {res.data.message}
            </Alert>
          );
          return;
        }
        toaster('success', 'New products has been created.');
        closeAddProductView(false);
        getProducts();
      })
      .catch((err) => {
        toaster(
          'warning',
          `${err.response.data.message || 'Invalid file data.'}`
        );
      });
  };

  const deleteProduct = (data) => {
    axios.delete(getURL(`product / ${selectedProduct.id}`)).then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      closeDeleteProductView();
      getProducts();
    });
  };

  const openEditView = (product) => {
    setSelectedProduct(product);
    setAddProductViewOpen(true);
  };

  const sortBy = (colName) => {
    // if (sortable.col==colName&&sortable.sorted==1) {
    //   sort =
    // }
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const openDeleteView = (product) => {
    setSelectedProduct(product);
    setDeleteProductViewOpen(true);
  };

  const closeAddProductView = () => {
    setSelectedProduct(null);
    setAddProductViewOpen(false);
  };

  const closeDeleteProductView = () => {
    setSelectedProduct(null);
    setDeleteProductViewOpen(false);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (productName) {
      colVal.name = productName;
    }
    if (productDescription) {
      colVal.description = productDescription;
    }
    if (productBarcode) {
      colVal.barcode = productBarcode;
    }
    if (productVol) {
      colVal.dimensionsCBM = productVol;
    }
    if (productWeight) {
      colVal.weight = productWeight;
    }

    if (productCatName) {
      colVal['$Brand.Category.name$'] = productCatName;
    }

    if (productBrandName) {
      colVal['$Brand.name$'] = productBrandName;
    }

    if (productUomName) {
      colVal['$UOM.name$'] = productUomName;
    }

    if (productPerPallet) {
      colVal.perPallet = productPerPallet;
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = [
      'name',
      'description',
      'barcode',
      'dimensionsCBM',
      'weight',
      '$Brand.Category.name$',
      '$Brand.name$',
      '$UOM.name$',
      'perPallet'
    ];
    _getProducts(page, searchKeyword, filters, columns);
  };

  const _getProducts = async (page, searchKeyword, filters, columns) => {
    try {
      const products = await API.get(`products`, {
        params: { page: page, search: searchKeyword, filters, columns }
      });
      setPageCount(products.pages);
      setProducts(products.data);
    } catch (err) {
      onError(err);
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  const getProducts = useCallback(
    debounce(
      (
        page,
        searchKeyword,
        selectedDay,
        selectedDateRange,
        startDate,
        endDate
      ) => {
        let colVal = {};
        if (productName) {
          colVal = { name: productName };
        }
        if (productDescription) {
          colVal = { description: productDescription };
        }
        if (productBarcode) {
          colVal = { barcode: productBarcode };
        }
        if (productVol) {
          colVal = { dimensionsCBM: productVol };
        }
        if (productWeight) {
          colVal = { weight: productWeight };
        }
        if (productCatName) {
          colVal = { '$Brand.Category.name$': productCatName };
        }
        if (productBrandName) {
          colVal = { '$Brand.name$': productBrandName };
        }
        if (productUomName) {
          colVal = { '$UOM.name$': productUomName };
        }
        if (productPerPallet) {
          colVal = { perPallet: productPerPallet };
        }

        let filters = {
          colVal,
          sortable
        };
        let columns = [
          'name',
          'description',
          'barcode',
          'dimensionsCBM',
          'weight',
          '$Brand.Category.name$',
          '$Brand.name$',
          '$UOM.name$',
          'perPallet'
        ];
        _getProducts(page, searchKeyword, filters, columns);
      },
      DEBOUNCE_CONST
    ),
    []
  );

  const setNameVal = (StateVal, e) => {
    if (StateVal == setProductPerPallet) {
      StateVal(parseInt(e));
    } else {
      StateVal(e);
    }
    setPage(1);
  };

  // const getRelations = () => {
  //   axios.get(getURL("product/relations")).then((res) => {
  //     setBrands(res.data.brands);
  //     setUoms(res.data.uoms);
  //     setCategories(res.data.categories);
  //   });
  // };

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'Product(s)';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes();
  }, [
    productName,
    sortable,
    productDescription,
    productBarcode,
    productVol,
    productWeight,
    productCatName,
    productBrandName,
    productUomName,
    productPerPallet
  ]);

  useEffect(() => {
    let colVal = {};
    if (productName) {
      colVal = { name: productName };
    }
    if (productDescription) {
      colVal = { description: productDescription };
    }
    if (productBarcode) {
      colVal = { barcode: productBarcode };
    }
    if (productVol) {
      colVal = { dimensionsCBM: productVol };
    }
    if (productWeight) {
      colVal = { weight: productWeight };
    }
    if (productCatName) {
      colVal = { '$Brand.Category.name$': productCatName };
    }
    if (productBrandName) {
      colVal = { '$Brand.name$': productBrandName };
    }
    if (productUomName) {
      colVal = { '$UOM.name$': productUomName };
    }
    if (productPerPallet) {
      colVal = { perPallet: parseInt(productPerPallet) };
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = [
      'name',
      'description',
      'barcode',
      'dimensionsCBM',
      'weight',
      '$Brand.Category.name$',
      '$Brand.name$',
      '$UOM.name$',
      'perPallet'
    ];
    // if ((selectedDay === 'custom' && !!selectedDateRange) || selectedDay !== 'custom') {
    getProducts(page, searchKeyword, filters, columns);
    // }
  }, [page, searchKeyword, selectedDay, reRender]);

  // useEffect(() => {
  //   getRelations();
  // }, []);

  const exportToExcel = () => {
    let startingDate = new Date(startDate);
    let endingDate = new Date(endDate);

    axios
      .get(getURL('product/export'), {
        responseType: 'blob',
        params: {
          page,
          search: searchKeyword,
          days: !selectedDateRange ? selectedDay : null,
          startingDate: selectedDateRange ? startingDate : null,
          endingDate: selectedDateRange ? endingDate : null,
          client_Tz: moment.tz.guess()
        }
      })
      .then((response) => {
        FileDownload(
          response.data,
          `Product ${moment().format('DD-MM-yyyy')}.xlsx`
        );
      });
  };

  // const addBulkProductsButton = <ProductsCsvReader bulkUpload={bulkUpload} />;

  const deleteProductModal = (
    <ConfirmDelete
      key={4}
      confirmDelete={deleteProduct}
      open={deleteProductViewOpen}
      handleClose={closeDeleteProductView}
      selectedEntity={selectedProduct && selectedProduct.name}
      title={'Product'}
    />
  );

  const exportButton = (
    <Button
      key={2}
      variant="contained"
      color="primary"
      size="small"
      className={classes.exportBtn}
      onClick={() => exportToExcel()}
    >
      {' '}
      EXPORT TO EXCEL
    </Button>
  );

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    // reset the local state to remove the helper text
    if (event.target.value !== 'custom' && selectedDateRange) {
      setSelectedDateRange(false);
    }
    setPage(1);
    setSelectedDay(event.target.value);
  };

  const daysSelect = (
    <FormControl className={classes.formControl}>
      <Select
        value={selectedDay}
        variant="outlined"
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        className={classes.placeholderText}
        startAdornment={
          <InputAdornment
            position="start"
            classes={{
              positionStart: classes.inputAdronmentStyle,
              root: classes.inputAdronmentStyle
            }}
          >
            <CalendarTodayOutlinedIcon fontSize="small" />
          </InputAdornment>
        }
        onOpen={() => setTrackDateFilterOpen(true)}
        onClose={() => setTrackDateFilterOpen(false)}
      >
        <MenuItem value={null} disabled>
          <span className={classes.dropdownListItem}>Select Days</span>
        </MenuItem>
        {[{ name: 'All' }, ...days].map((item, idx) => {
          return (
            <MenuItem key={idx} value={item.id}>
              <span className={classes.dropdownListItem}>
                {item.name || ''}
              </span>
            </MenuItem>
          );
        })}
        <MenuItem
          key={'custom'}
          value={'custom'}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <span className={classes.dropdownListItem}>
            {startDate !== '-' &&
            startDate !== null &&
            endDate !== null &&
            !trackDateFilterOpen
              ? moment(startDate).format('DD/MM/YYYY') +
                ' - ' +
                moment(endDate).format('DD/MM/YYYY')
              : 'Custom'}
          </span>
        </MenuItem>
      </Select>
    </FormControl>
  );

  const startDateRange = (
    <TextField
      id="date"
      label="From"
      type="date"
      variant="outlined"
      className={classes.textFieldRange}
      InputLabelProps={{
        shrink: true
      }}
      fullWidth
      inputProps={{
        max: endDate ? endDate : dividerDateFormatForFilter(Date.now())
      }}
      defaultValue={startDate}
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      margin="dense"
    />
  );

  const endDateRange = (
    <TextField
      id="date"
      label="To"
      type="date"
      variant="outlined"
      className={classes.textFieldRange}
      InputLabelProps={{
        shrink: true
      }}
      fullWidth
      inputProps={{
        min: startDate,
        max: dividerDateFormatForFilter(Date.now())
      }}
      defaultValue={endDate}
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
      margin="dense"
    />
  );

  const mainClass = classNames({
    [classes.container]: true,
    customFilterSettings: true
  });

  // const headerButtons = [
  //   checkPermission(currentUser, "OPS_PRODUCT_CREATE") ? addProductButton : null,
  //   checkPermission(currentUser, "OPS_PRODUCT_BULK_UPLOAD") ? addBulkProductsButton : null,
  //   checkPermission(currentUser, "OPS_PRODUCT_EXPORT") ? exportButton : null,
  //   checkPermission(currentUser, "OPS_PRODUCT_CREATE") || checkPermission(currentUser, "OPS_PRODUCT_UPDATE") ? addProductModal : null,
  //   checkPermission(currentUser, "OPS_PRODUCT_DELETE") ? deleteProductModal : null,
  // ];

  //Product FIlter objects start...
  let productNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Customer SKU Code',
    field: 'name',
    value: productName,
    setNameVal: (e) => setNameVal(setProductName, e)
  };

  let productDescObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Name',
    field: 'description',
    value: productDescription,
    setNameVal: (e) => setNameVal(setProductDescription, e)
  };

  let productBarcodeObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Barcode',
    field: 'barcode',
    value: productBarcode,
    setNameVal: (e) => setNameVal(setProductBarcode, e)
  };

  let productVolObj = {
    type: 'text',
    className: 'textField',
    label: 'Volume per UoM in cm3',
    field: 'dimensionsCBM',
    value: productVol,
    setNameVal: (e) => setNameVal(setProductVol, e)
  };

  let productWeightObj = {
    type: 'text',
    className: 'textField',
    label: 'Weight per UoM in Kgs',
    field: 'weight',
    value: productWeight,
    setNameVal: (e) => setNameVal(setProductWeight, e)
  };

  let productCatObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Category',
    field: 'Brand.Category.name',
    value: productCatName,
    setNameVal: (e) => setNameVal(setProductCatName, e)
  };

  let productBrandObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Brand',
    field: 'Brand.name',
    value: productBrandName,
    setNameVal: (e) => setNameVal(setProductBrandName, e)
  };

  let productUomObj = {
    type: 'text',
    className: 'textField',
    label: 'UoM',
    field: 'UOM.name',
    value: productUomName,
    setNameVal: (e) => setNameVal(setProductUomName, e)
  };

  let productPalletObj = {
    type: 'text',
    className: 'textField',
    label: 'UoM per Pallet',
    field: 'perPallet',
    value: productPerPallet,
    setNameVal: (e) => setNameVal(setProductPerPallet, e)
  };
  //Product columns objects....

  let filterCols = [
    productNameObj,
    productDescObj,
    productBarcodeObj,
    productVolObj,
    productWeightObj,
    productCatObj,
    productBrandObj,
    productUomObj,
    productPalletObj
  ];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'User'}
        handleClose={() => closeFilter()}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_USER_READ') ? filterModal : null
  ];

  // Custom toolbar
  function CustomToolbar(props) {
    return (
      <GridToolbarContainer
        {...props}
        className="toolbarContainer userToolbarContainer"
      >
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        {/* <GridToolbarExport /> */}
        <button
          className="mainFilter"
          id="search"
          label="Search"
          value={searchKeyword}
          key={3}
          onClick={() => openFilterView()}
        >
          FILTER
        </button>
      </GridToolbarContainer>
    );
  }
  // Custom toolbar

  // retur
  return (
    <Paper className={classes.root}>
      <TableContainer className={mainClass}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table">
          <TableBody>
            <div style={{ height: 1100, width: '100%' }}>
              <DataGrid
                rows={products}
                hiddenColumnModel={['id']}
                columns={productColumns}
                components={{ Toolbar: CustomToolbar }}
                pageSize={20}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="space-between">
        <Grid item></Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            className={classes.pagination}
            onChange={(e, newPage) => setPage(newPage)}
          />
        </Grid>
      </Grid>
      <MessageSnackbar showMessage={showMessage} />
    </Paper>
  );
}

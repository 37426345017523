import React, { useEffect, useState } from 'react';
import { GoogleMap, DirectionsRenderer, Marker } from 'react-google-maps';
import OriginIcon from '../assets/icons/origin.svg';
import DestinationIon from '../assets/icons/destination.svg';
import { Radio } from '@mui/material';
import { GOOGLE_MAP_API_KEY } from '../constants';

const PrimaryRouteMaps = ({
  origin,
  destination,
  wayPoints,
  options,
  zoom,
  pickupNode,
  dropOffNode
}) => {
  let [directions, setDirections] = useState({});

  useEffect(() => {
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: wayPoints,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      }
    );
  }, [origin, destination, wayPoints]);

  return (
    <section className="googleMap relative">
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: 30.3753, lng: 69.3451 }}
        options={options}
      >
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeColor: '#0C71D7',
                strokeWeight: 6
              },
              suppressMarkers: true
            }}
          />
        )}
        <Marker
          position={directions?.routes?.[0]?.legs?.[0]?.start_location}
          icon={{
            url: OriginIcon,
            scaledSize: { width: 28, height: 28 },
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15)
          }}
        />
        <Marker
          position={directions?.routes?.[0]?.legs?.[0]?.end_location}
          icon={{
            url: DestinationIon,
            scaledSize: { width: 28, height: 28 },
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15)
          }}
          anchor={new window.google.maps.Point(15, 30)}
        />
        {zoom && (
          <div className="absolute bottom-0 right-0 bg-white shadow-[0px -2px 4px 0px #00000014] border border-solid border-[#0B194033] w-[30%]">
            <div className="flex gap-2 p-3 flex-col justify-center">
              <div className="flex gap-3 text-xs items-center">
                <Radio checked sx={{ width: '16px', height: '16px' }} />
                <span className="text-xs">Pickup: {pickupNode}</span>
              </div>
              <div className="flex gap-3 text-xs items-center">
                <img src={DestinationIon} className="w-[20px] h-[20px] ml-0" />
                <span className="text-xs">Dropoff: {dropOffNode}</span>
              </div>
            </div>
          </div>
        )}{' '}
      </GoogleMap>
    </section>
  );
};
const RouteMaps = (props) => {
  const sortedWaypoints =
    props?.wayPoints?.sort((a, b) => a.sequenceNumber - b.sequenceNumber) || [];
  const wayPoints =
    sortedWaypoints?.length > 1
      ? sortedWaypoints?.map((order, idx) => {
          return {
            location:
              order?.dropOffNode?.address || order?.warehouseDropOff?.address,
            stopover: sortedWaypoints?.length !== idx + 1
          };
        })
      : [];
  const origin =
    props?.formattedOrigin && props?.formattedOrigin?.lat
      ? props?.formattedOrigin
      : sortedWaypoints[0]?.pickupNode?.address ||
        sortedWaypoints[0]?.warehousePickUp?.address;
  const destination =
    props?.formattedDestination && props?.formattedDestination?.lat
      ? props?.formattedDestination
      : sortedWaypoints[sortedWaypoints.length - 1]?.dropOffNode?.address ||
        sortedWaypoints[sortedWaypoints.length - 1]?.warehouseDropOff?.address;

  return (
    <PrimaryRouteMaps
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`}
      origin={origin}
      destination={destination}
      wayPoints={wayPoints}
      options={props.options}
      zoom={props.zoom}
      pickupNode={
        props.order?.pickupNode?.name ||
        props.order?.warehousePickUp?.businessWarehouseCode
      }
      dropOffNode={
        props.order?.dropOffNode?.name ||
        props.order?.warehouseDropOff?.businessWarehouseCode
      }
    />
  );
};

export default RouteMaps;

import { Box, Button, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { checkPermission } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import CancelledTab from '../invoiceManagement/CancelledTab';
import DraftsTab from '../invoiceManagement/DraftsTab';
import InvoicesTab from '../invoiceManagement/InvoicesTab';
import useStyles from '../invoiceStyles';
import mixpanel from 'mixpanel-browser';

export const InvoiceFiltersInitialValue = {
  Status: [],
  Type: [],
  Customer: [],
  Warehouse: [],
  Contract: [],
  Vendor: []
};

export const cancelledFilterInitialValue = {
  Type: [],
  Customer: [],
  Warehouse: [],
  Contract: [],
  Vendor: []
};

export default function ExpenseManagement() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [filterStateInvoices, setFilterStateInvoices] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterStateDrafts, setFilterStateDrafts] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterCancelledInvoice, setFilterCancelInvoice] = useState(
    cancelledFilterInitialValue
  );
  const [invoicesLength, setInvoicesLength] = useState(0);
  const [draftLength, setDraftLength] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const navigate = useNavigate();

  const { setCurrentPageTitle, setAPILoader, currentUser } =
    useContext(SharedContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStats = async () => {
    try {
      setAPILoader(true);
      const stats = await API.get('invoices/stats', {
        params: { billing: true }
      });
      setInvoicesLength(stats.invoices);
      setDraftLength(stats.drafts);
      setCancelled(stats.cancelled);
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    setCurrentPageTitle('Expenses');
    getStats();
    mixpanel.track('Expenses Page', {});
  }, []);

  return (
    <Grid
      item
      container
      className="mt-10"
      style={{ width: '95%', margin: 'auto' }}
    >
      <Box className={classes.borderBottom} mt={5}>
        <Box className={classes.tabsContainer}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={
                <Typography
                  className={value == 0 ? classes.active : classes.tabText}
                >{`Bills (${invoicesLength})`}</Typography>
              }
              style={{ textTransform: 'none', fontSize: '1rem' }}
            />
            <Tab
              label={
                <Typography
                  className={value == 1 ? classes.active : classes.tabText}
                >{`Drafts (${draftLength})`}</Typography>
              }
              style={{ textTransform: 'none', fontSize: '1rem' }}
              active={value == 1}
            />
            <Tab
              label={
                <Typography
                  className={value == 2 ? classes.active : classes.tabText}
                >{`Cancelled (${cancelled})`}</Typography>
              }
              style={{ textTransform: 'none', fontSize: '1rem' }}
              active={value == 2}
            />
          </Tabs>
        </Box>
        {checkPermission(currentUser, 'OPS_EXPENSE_CREATE') && (
          <Button
            variant="contained"
            color="primary"
            className={classes.invoiceBtn}
            onClick={() => navigate('/billing-invoice/payables/create')}
          >
            <span className={classes.addIcon}>+</span>
            <span>Add Expense</span>
          </Button>
        )}
      </Box>
      {value === 0 && (
        <InvoicesTab
          tab={value}
          billing={true}
          filterStateInvoices={filterStateInvoices}
          setFilterStateInvoices={setFilterStateInvoices}
          getStats={getStats}
        />
      )}
      {value === 1 && (
        <DraftsTab
          tab={value}
          billing={true}
          filterStateDrafts={filterStateDrafts}
          setFilterStateDrafts={setFilterStateDrafts}
        />
      )}
      {value === 2 && (
        <CancelledTab
          tab={value}
          billing={true}
          filterCancelledInvoice={filterCancelledInvoice}
          setFilterCancelInvoice={setFilterCancelInvoice}
        />
      )}
    </Grid>
  );
}

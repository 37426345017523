import { useState, useEffect, useMemo } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Tooltip
} from '@material-ui/core';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  labelPadding: {
    paddingTop: 5
  },
  selectBox: {
    height: 55
  },
  label: {
    width: '100% !important'
  }
}));

export default function WarehouseUserView({
  open,
  handleClose,
  selectedUser,
  addWarehoseToUser,
  formErrors
}) {
  const [selected, setSelected] = useState([]);
  const [organizationNodes, setOrganizationNodes] = useState([]);
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const selectedSet = useMemo(() => new Set(selected), [selected]);

  useEffect(() => {
    if (open) {
      setSelected([]);
      _getWarehouseSeleted();
    }
  }, [open, selectedUser]);

  useEffect(() => {
    if (search) {
      getOrgNodes(search);
    } else {
      getOrgNodes();
    }
  }, [search]);

  const getOrgNodes = async (search) => {
    try {
      const response = await API.get(`nodes`, {
        params: {
          limit: 10,
          search,
          nodeClass: 'INTERNAL',
          manageInventory: true
        }
      });
      setOrganizationNodes(response);
    } catch (err) {
      onError(err);
    }
  };
  const _getWarehouseSeleted = async () => {
    try {
      const wareHouse = await API.get(
        `permission-accesses/user-warehouse/${selectedUser.id}`
      );
      setSelected(
        wareHouse && wareHouse.warehouses && wareHouse.warehouses.warehouses
          ? wareHouse.warehouses.warehouses
          : []
      );
    } catch (err) {
      onError(err);
      // eslint-disable-next-line no-empty
    } finally {
    }
  };
  const handleSubmit = () => {
    const warehouses = [...new Set(selected)];
    const userId = selectedUser.id;

    const newRoleWarehouses = {
      userId,
      warehouses
    };

    addWarehoseToUser(newRoleWarehouses);
    handleClose();
    setSearch('');
  };

  const Item = ({ node, selectedSet }) => {
    const isChecked = selectedSet?.has(node?.Warehouse?.id);
    return (
      <div
        style={{
          display: 'flex',
          gap: '8px',
          marginTop: '16px',
          alignItems: 'center'
        }}
      >
        <div>
          <Checkbox
            checked={isChecked}
            onChange={(_, checked) => {
              if (checked) {
                setSelected([...selected, node.Warehouse.id]);
              } else {
                setSelected(
                  [...selected].filter((id) => id != node.Warehouse.id)
                );
              }
            }}
          />
        </div>
        <span>
          {node?.Warehouse?.name}{' '}
          {`(${node?.Warehouse?.businessWarehouseCode})`}
        </span>
      </div>
    );
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle disableTypography className="title">
            {'Add Warehouses To User'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container md={5} justify="space-around">
                <Grid item sm={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    autoFocus
                    margin="dense"
                    id="name"
                    // label="Name"
                    type="text"
                    variant="outlined"
                    value={selectedUser ? selectedUser.name : ''}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container md={1} />
              <Grid container md={5} justify="space-around">
                <Grid item sm={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Search Node"
                    type="text"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search Node"
                  />
                </Grid>
              </Grid>
              <Grid item sm={12}>
                {organizationNodes?.data?.length > 0 &&
                  organizationNodes?.data?.map((row, id) => (
                    <Item
                      node={row}
                      key={id}
                      selectedSet={selectedSet}
                      setSelected={setSelected}
                    />
                  ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="updateBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  handleClose();
                  setSearch('');
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title="Update Warehouse Roles">
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                {'Update'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import { useFlags } from 'launchdarkly-react-client-sdk';

const useFeatureFlags = () => {
  const flags = useFlags();

  const isFeatureEnabled = (key) => {
    return flags[key] ?? false;
  };

  return { flags, isFeatureEnabled };
};

export default useFeatureFlags;

/*eslint-disable*/
import React, { useContext } from 'react';
import { Grid, MenuItem } from '@material-ui/core';

import { ORGANIZATION_TYPES, CURRENCIES, COUNTRIES } from '../../constants';
import { SharedContext } from '../../utils/common';
import TextField from '../../core-components/atoms/TextField';

const OrganizationDetails = () => {
  const { organization } = useContext(SharedContext);

  const borderClass = 'mt-6 number-input-container';

  return (
    <div>
      <p className="font-bold text-[24px]">Organization Details</p>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Name & Sub domain</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="name"
            type="text"
            title="Organization Name"
            disabled
            max={255}
            value={organization?.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="subdomain"
            type="text"
            title="Subdomain"
            disabled
            max={255}
            value={organization?.subdomain}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Industry & Type</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="industry"
            type="text"
            disabled
            max={255}
            value={organization?.industry}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="type"
            label="Organization Type"
            select
            fullWidth
            variant="outlined"
            value={organization?.type}
            disabled
          >
            {Object.keys(ORGANIZATION_TYPES).map((key) => (
              <MenuItem
                key={ORGANIZATION_TYPES[key]}
                value={ORGANIZATION_TYPES[key]}
              >
                {key.replace(/_/g, ' ')}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Email & Contact</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="email"
            type="email"
            title="Organization Email"
            disabled
            value={organization?.email}
            max={255}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="phone"
            type="text"
            label="Contact"
            disabled
            value={organization?.contactNumber}
            max={255}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Address</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="address"
            type="text"
            label="address"
            disabled
            value={organization?.address}
            max={255}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">City & Country</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="country"
            label="Country"
            select
            fullWidth
            disabled
            variant="outlined"
            value={
              COUNTRIES.find((country) => country.code == organization?.country)
                ?.name
            }
          ></TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="city"
            label="City"
            select
            fullWidth
            disabled
            variant="outlined"
            value={organization?.city}
          ></TextField>
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Additional Information</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="additonalInfo"
            type="text"
            placeholder="Type here"
            disabled
            value={organization?.additionalInformation}
            max={255}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default OrganizationDetails;

/*eslint-disable*/
import Table from '../../atomicComponents/Table';
import AlertSign from '../../assets/icons/unifiedDashboard/AlertSign.svg';
import WarningSign from '../../assets/icons/unifiedDashboard/WarningSign.svg';
import React, { useContext, useState } from 'react';
import ExceptionDetailView from './ExceptionDetailView';
import {
  fetchExceptions,
  fetchShipment,
  fetchTrackingDetails
} from '../../apis/dashboard/index';
import { useQuery } from 'react-query';
import { getDateAndTime, SharedContext } from '../../utils/common';
import ShipmentDetail from './ShipmentDetail';
import clsx from 'clsx';
import { checkPermission } from '../../utils/auth';

const ExceptionsView = () => {
  const [exceptionDetailView, setExceptionDetailView] = useState(false);
  const [exceptionsData, setExceptionsData] = useState([]);
  const [exceptionsDataMain, setExceptionsDataMain] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const limit = 7;
  const { currentUser } = useContext(SharedContext);

  const [shipmentDetailPopup, setShipmentDetailPopup] = useState(false);
  const [shipmentData, setShipmentData] = useState(null);
  const [trackingData, setTrackingData] = useState([]);
  const [trackingLoading, setTrackingLoading] = useState(true);

  function openFileInNewTab(url) {
    const newTab = window.open(`${window.location.origin}/${url}`, '_blank');
    newTab.focus();
  }

  useQuery(
    ['exceptions', limit, page],
    () => {
      return fetchExceptions(Number(limit), Number(page));
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (page === 1) {
          setExceptionsDataMain(data.data);
          setPagesCount(data.pages);
        }
        setExceptionsData(data.data);
      }
    }
  );

  const handleShipmentClick = async (customId) => {
    setTrackingLoading(true);
    try {
      setShipmentDetailPopup(true);
      const response = await fetchTrackingDetails(customId);
      const shipmentResponse = await fetchShipment(customId);
      setShipmentData(shipmentResponse);
      setTrackingData(response);
      setTrackingLoading(false);
      setExceptionDetailView(false);
    } catch (error) {
      setTrackingLoading(false);
    }
  };

  const columns = [
    {
      key: 'impact',
      headerClasses:
        'py-2 pr-2 pl-4 text-xs text-headerCell uppercase tracking-wider font-medium',
      cellClasses:
        'pr-2 pl-4  font-roboto border-t border-l-0 border-r-0 border-b-0 border-solid border-gray-200 whitespace-no-wrap h-full',
      render: (row) => (
        <>
          {row?.keyImpact === 'NEGATIVE' ? (
            <div className="h-full flex items-start">
              <img className="w-5 h-5" src={AlertSign} alt="Negative Impact" />
            </div>
          ) : (
            <div className="h-full flex items-start">
              <img
                className="w-5 h-5"
                src={WarningSign}
                alt="Positive Impact"
              />
            </div>
          )}
        </>
      )
    },
    {
      key: 'shipmentId',
      header: 'ID',
      headerClasses:
        'text-left pr-4 py-2 text-left text-xs text-headerCell uppercase tracking-wider font-medium	',
      cellClasses:
        'font-roboto pr-4 py-3 border-t border-l-0 border-r-0 border-b-0 border-solid border-gray-200 whitespace-no-wrap',
      render: (row) => (
        <div className="flex-initial w-full">
          <div
            className={clsx(
              'text-sm font-normal select-none',
              checkPermission(currentUser, 'OPS_STOCK_ADJUSTMENT_READ') &&
                'hover:text-primary hover:underline'
            )}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (
                row?.StockAdjustment &&
                checkPermission(currentUser, 'OPS_STOCK_ADJUSTMENT_READ')
              ) {
                openFileInNewTab(
                  `operations/stock-adjustment/view/${row?.StockAdjustment?.customId}`
                );
              } else if (row?.Order) {
                openFileInNewTab(`routing/view-order/${row?.Order?.customId}`);
              } else if (row?.Shipment) {
                handleShipmentClick(row?.Shipment?.customId);
              }
            }}
          >
            {row?.StockAdjustment
              ? row?.StockAdjustment?.customId || '-'
              : row?.Order
              ? row?.Order?.customId || '-'
              : (row?.Shipment && row?.Shipment?.customId) || '-'}
          </div>
          <div className="text-xs font-normal text-stormGray">
            {`${getDateAndTime(row?.createdAt).date}`}{' '}
            <span className="mx-1 text-stormGray">•</span>{' '}
            {`${getDateAndTime(row?.createdAt).time}`}
          </div>
        </div>
      )
    },
    {
      key: 'description',
      header: 'DETAILS',
      headerClasses:
        'text-left pr-4 py-2 text-left text-xs text-headerCell uppercase tracking-wider font-medium	',
      cellClasses:
        'font-roboto pr-4 py-3 border-t border-l-0 border-r-0 border-b-0 border-solid border-gray-200 whitespace-no-wrap max-w-[286px]',
      render: (row) => (
        <>
          <div className="flex">
            <div className="flex-initial mr-1">
              <span className="text-sm font-medium text-deepCove">
                {row?.heading !== 'GDN Reversed' &&
                row?.heading !== 'GRN Reversed'
                  ? row?.heading
                  : 'Reversed'}
              </span>
              <span className="mx-1 text-deepWater">•</span>
              <span className="text-deepWater ml-1 text-sm font-normal">
                {row?.heading === 'Stock Condition' &&
                  `${row?.quantity} number of items has been marked as ${
                    row?.stockCondition == 'RECOVERABLE'
                      ? 'Damaged Recoverable'
                      : row?.stockCondition == 'GOOD'
                      ? 'Good'
                      : 'Damaged Unsellable'
                  } by ${row?.User?.firstName} ${row?.User?.lastName}`}
                {row?.heading === 'Late Shipment' &&
                  `Shipment ${row?.Shipment?.customId} has experienced a delay`}
                {row?.heading === 'Order Cancelled' &&
                  `Order ${row?.Order?.customId} has been cancelled by ${row?.User?.firstName} ${row?.User?.lastName}`}
                {row?.heading === 'Shipment Cancelled' &&
                  `Shipment ${row?.Shipment?.customId} has been cancelled by ${row?.User?.firstName} ${row?.User?.lastName}`}
                {row?.heading === 'GDN Reversed' &&
                  `GDN of shipment ${row?.Shipment?.customId} has been reversed by ${row?.User?.firstName} ${row?.User?.lastName}`}
                {row?.heading === 'GRN Reversed' &&
                  `GRN of shipment ${row?.Shipment?.customId} has been reversed by ${row?.User?.firstName} ${row?.User?.lastName}`}
                {row?.heading === 'Stock Adjustment' &&
                  `In ${row?.Warehouse?.name}, stock has been adjusted by ${row?.User?.firstName} ${row?.User?.lastName}`}
              </span>
            </div>
          </div>
        </>
      )
    }
  ];
  return (
    <>
      {ShipmentDetail && (
        <ShipmentDetail
          shipmentDetailPopup={shipmentDetailPopup}
          setShipmentDetailPopup={setShipmentDetailPopup}
          data={shipmentData}
          setOpen={() => {}}
          trackingData={trackingData}
          loading={trackingLoading}
          setLoading={setTrackingLoading}
        />
      )}
      <div className="w-50 border-r border-l-0 border-t-0 border-b-0 border-solid border-primaryBlue block">
        <ExceptionDetailView
          open={exceptionDetailView}
          setOpen={setExceptionDetailView}
          page={page}
          setPage={setPage}
          pagesCount={pagesCount}
          setPagesCount={pagesCount}
          columns={columns}
          limit={limit}
          data={exceptionsData}
        />
        <div className="flex mt-[32px] mb-[19px] items-start">
          <h1 className="text-xl font-bold text-primaryBlue">Exceptions</h1>
          <p className="ml-2 mt-1">(Last 7 days)</p>
        </div>
        {exceptionsData ? (
          <>
            <div className="block">
              {exceptionsData && (
                <>
                  <Table
                    paginated={true}
                    headerClasses="pt-3 pb-2"
                    data={exceptionsDataMain.slice(0, 4)}
                    columns={columns}
                    rowsPerPage={limit}
                    page={page}
                    setPagesCount={setPagesCount}
                  />
                </>
              )}
            </div>
            <div className="mt-6 flex justify-end pr-4">
              <div>
                <a
                  className="text-navyBlue text-base font-medium cursor-pointer"
                  onClick={() => setExceptionDetailView(true)}
                >
                  View All
                </a>
              </div>
            </div>
          </>
        ) : (
          <div className="w-100 h-[404px] flex justify-center items-center">
            <p className="text-primaryBlue text-sm font-medium text-center">
              No Exceptions!
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default ExceptionsView;

import { makeStyles, Tooltip } from '@material-ui/core';
import { Input, InputAdornment } from '@mui/material';
import '../assets/css/style.css';
import FilterSvg from '../assets/icons/filterIcon.svg';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles(() => ({
  searchBar: {
    maxWidth: '318px',
    height: '32px',
    border: '1px solid #87A2BD',
    borderRadius: '8px',
    paddingLeft: '12px',
    width: '100%'
  }
}));

export default function SearchBar(props) {
  const classes = useStyles();

  return (
    <Input
      type="text"
      value={props?.searchKeyword}
      autoFocus={true}
      className={props?.className ? props?.className : classes.searchBar}
      placeholder={props.placeholder || 'Search here'}
      onChange={(e) => props?.setSearchValue(e.target.value)}
      startAdornment={
        <InputAdornment position="start">
          <SearchOutlinedIcon className={props?.iconClass} />
        </InputAdornment>
      }
      endAdornment={
        <>
          {props?.setFilterView ? (
            <InputAdornment position="end">
              <Tooltip title="Advance Filter">
                <img
                  src={FilterSvg}
                  className={
                    props?.filter
                      ? 'filterApplied cursor-pointer'
                      : 'cursor-pointer filterIcon'
                  }
                  onClick={() => props?.setFilterView()}
                  alt="Filter SVG"
                />
              </Tooltip>
            </InputAdornment>
          ) : (
            ''
          )}
          {props.searchKeyword && ( // Render clear button only if searchKeyword is present
            <InputAdornment position="end">
              <Tooltip title="Clear">
                <ClearIcon
                  style={{
                    width: '20px',
                    height: '14px',
                    cursor: 'pointer',
                    marginRight: '-10px'
                  }}
                  onClick={() => props?.setSearchValue('')}
                />
              </Tooltip>
            </InputAdornment>
          )}
        </>
      }
    />
  );
}

import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

const StatCardSkeleton = ({ trend = true, image = true }) => {
  return (
    <Card className="shadow-none rounded-lg border-dark300 border">
      <CardContent className="py-2">
        {/* Skeleton for the title */}
        <Skeleton variant="text" width="40%" height={20} />

        {/* Skeleton for the value and icons */}
        <div className="flex items-center justify-between mt-3">
          <Skeleton variant="rectangular" width="60%" height={40} />
          {image && <Skeleton variant="rectangular" width={40} height={40} />}
        </div>

        {trend && (
          <div className="mt-2">
            <Skeleton variant="text" width="30%" height={10} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default StatCardSkeleton;

import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyConversation from '../../../assets/icons/conversation-empty.svg';
import { Paragraph } from '../../../core-components/atoms/Text';
import Comment from './Comment';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

const CommentList = ({
  order,
  shipment,
  loader,
  page,
  setPage,
  getUserComments,
  pageCount,
  chatList = [],
  editComment,
  deleteComment,
  className,
  customClassText,
  customClassEmpty,
  selectedSearch
}) => {
  const groupedChatList = {};
  const today = new Date();
  const scrollRef = useRef([]);

  useEffect(() => {
    if (selectedSearch) {
      if (scrollRef?.current?.length) {
        scrollRef.current[selectedSearch].scrollIntoView();
      }
    }
  }, [selectedSearch]);

  chatList.forEach((comment) => {
    const commentDate = new Date(comment.createdAt);
    const diffInDays = Math.floor(
      (today - commentDate) / (1000 * 60 * 60 * 24)
    );
    let dateLabel;
    if (diffInDays === 0) {
      dateLabel = 'Today';
    } else {
      dateLabel = commentDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    }
    if (!groupedChatList[dateLabel]) {
      groupedChatList[dateLabel] = [];
    }
    groupedChatList[dateLabel].push(comment);
  });
  return (
    <div
      id={'scrollableDiv'}
      style={{
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse'
      }}
      className={clsx(
        'flex-1 items-center select-none no-scrollbar py-6',
        className
      )}
    >
      <InfiniteScroll
        dataLength={chatList.length}
        inverse={true}
        next={() => {
          getUserComments(page + 1, chatList, order, shipment, false);
          setPage(page + 1);
        }}
        hasMore={page < pageCount}
        pullDownToRefresh={false}
        loader={
          <div className={'flex flex-1 overflow-hidden pb-4 justify-center'}>
            <CircularProgress size={20} />
          </div>
        }
        scrollThreshold={0.1}
        style={{
          display: 'flex',
          flexDirection: 'column-reverse'
        }}
        scrollableTarget="scrollableDiv"
      >
        {chatList?.length > 0 ? (
          Object.entries(groupedChatList).map(([date, comments], index) => (
            <React.Fragment key={index}>
              {comments.map((comment, id) => (
                <div
                  key={id}
                  className={'pb-6 flex-1'}
                  ref={(ref) => (scrollRef.current[comment?.content] = ref)}
                >
                  <Comment
                    comment={comment}
                    editComment={editComment}
                    deleteComment={deleteComment}
                    customClassText={customClassText}
                    selectedSearch={selectedSearch}
                  />
                </div>
              ))}
              <div className={'flex flex-1 mb-8 justify-center items-center'}>
                <div className={'border-t w-100'} />
                <div>
                  <Paragraph
                    className={'w-[200px] text-center border rounded-full'}
                    variant={'sm'}
                  >
                    {date}
                  </Paragraph>
                </div>
                <div className={'border-t w-100'} />
              </div>
            </React.Fragment>
          ))
        ) : loader ? (
          <div
            className={clsx(
              'overflow-hidden h-96 flex items-center justify-center',
              customClassEmpty
            )}
          >
            <CircularProgress size={30} />
          </div>
        ) : (
          <div
            className={clsx(
              customClassEmpty,
              'flex flex-col items-center justify-center h-96'
            )}
          >
            <img
              alt="empty-conversation"
              className={''}
              src={EmptyConversation}
            />
            <Paragraph
              variant={'sm'}
              className={'text-[#9DA3B3] w-[188px] text-center'}
            >
              {`Conversation is empty be the one \n to break the ice.`}
            </Paragraph>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default CommentList;

import Table from '../../atomicComponents/Table';
import Popup from '../../core-components/atoms/Popup';
import PaginationGrid from '../../components/PaginationGrid';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

const ExceptionDetailView = ({
  open,
  setOpen,
  columns,
  data,
  page,
  setPage,
  pagesCount,
  limit,
  setPagesCount
}) => {
  const Content = () => {
    useEffect(() => {
      mixpanel.track('View All Exception', {});
    }, []);
    return (
      <>
        <div className="w-100 pt-4 pl-8 pr-[30px]  h-[504px] overflow-y-scroll overflow-x-hidden dark-scrollbar">
          <Table
            paginated={true}
            data={data}
            columns={columns}
            rowsPerPage={limit}
            page={page}
            setPagesCount={setPagesCount}
            className="mt-1"
          />
        </div>
        <div className="flex items-center h-[98px] px-8">
          <PaginationGrid
            pageCount={pagesCount}
            color="primary"
            page={page}
            setPage={setPage}
            customBtnColor="#0C70D7"
          />
        </div>
      </>
    );
  };
  return (
    <Popup
      open={open}
      setOpen={setOpen}
      content={<Content />}
      noLineBreak={true}
      title={'Exceptions'}
      headerClass="p-4 border-platinum border-b border-solid border-t-0 border-l-0 border-r-0"
      closeIconSize={24}
      titleClass="text-primaryBlue text-lg font-semibold"
      paperProps={{
        style: {
          borderRadius: '8px',
          height: '664px',
          maxWidth: '752px',
          width: '752px'
        }
      }}
      noPadding={true}
    />
  );
};
export default ExceptionDetailView;

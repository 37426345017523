import TextField from '@mui/material/TextField';
import React, { useContext } from 'react';
import { Paragraph } from '../../../core-components/atoms/Text';
import { getCurrencySymbol, SharedContext } from '../../../utils/common';

const CustomTextField = ({
  value,
  onChange = () => {},
  onInput = () => {},
  label,
  placeholder,
  type = 'text',
  maxLength,
  step = 1,
  error,
  ...rest
}) => {
  const { organization } = useContext(SharedContext);
  const handleChange = (event) => {
    if (type === 'number') {
      const inputValue = event.target.value;
      if (inputValue < 0) {
        event.target.value = 0;
        onChange(event);
      }
      const regex = /^-?\d*\.?\d{0,3}$/;

      if (regex.test(inputValue) || inputValue === '' || inputValue === '-') {
        onChange(event);
      }
    } else {
      onChange(event);
    }
  };

  return (
    <TextField
      value={value || ''}
      onChange={handleChange}
      label={label}
      placeholder={placeholder}
      type={type}
      size={'small'}
      className={`hover:mt-0 text-input`}
      inputProps={{ maxLength, step }}
      autoComplete="off"
      error={error}
      onInput={onInput}
      InputProps={{
        endAdornment: (
          <Paragraph variant={'sm'}>
            {getCurrencySymbol(organization?.defaultCurrency)}
          </Paragraph>
        )
      }}
      sx={{
        '& .MuiOutlinedInput-input': {
          padding: '11px 14px !important'
        },
        '& .MuiInputBase-root': {
          fontSize: '14px'
        },
        '& .MuiAutocomplete-input': {
          padding: '0px 4px 0px 6px !important'
        },
        '& input::placeholder': {
          fontSize: '14px'
        },
        '& textarea::placeholder': {
          fontSize: '14px'
        }
      }}
      variant="outlined"
      {...rest}
    />
  );
};

export default CustomTextField;

import { Tooltip } from '@material-ui/core';
const headerClasses = 'text-xs font-normal text-headerCell';
const confirmationTableHeader = [
  {
    field: 'skuCodeAndName',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className={`${headerClasses} w-100 parentActions routeheader`}>
          SKU CODE AND NAME
        </p>
      );
    },
    renderCell: (params) => {
      return (
        <Tooltip title={`${params?.row?.product?.name}`}>
          <span className="table-cell-trucate">
            <p className="mb-1">{params?.row?.product?.description}</p>
            <div>SKU: {params?.row?.product?.name}</div>
          </span>
        </Tooltip>
      );
    }
  },
  {
    field: 'batches',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className={`${headerClasses} w-100 parentActions routeheader`}>
          NUMBER OF LOCATIONS
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={params?.row?.batchNumber}>
        <span className="table-cell-trucate">
          {params?.row?.batches.length}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'orderIds',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className={`${headerClasses} w-100 parentActions routeheader`}>
          ORDER ID
        </p>
      );
    },
    renderCell: (params) => (
      <span className="table-cell-trucate">
        {[...new Set(params?.row?.batches.map((b) => b.customId))].join(', ')}
      </span>
    )
  },
  {
    field: 'totalOrderedQuantity',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className={`${headerClasses} w-100 parentActions routeheader`}>
          TOTAL ORDERED QTY
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={params?.row?.totalOrderedQuantity}>
        <span className="table-cell-trucate text-center">
          {+(params?.row?.orderedCount ?? 0).toFixed(3)}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'totalPickedQuantity',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className={`${headerClasses} w-100 parentActions routeheader`}>
          TOTAL PICKED QTY
        </p>
      );
    },
    renderCell: (params) => (
      <span className="table-cell-trucate text-center">
        {
          +(
            params?.row?.batches?.reduce((sum, b) => sum + b.pickQuantity, 0) ??
            0
          ).toFixed(3)
        }
      </span>
    )
  }
];

export default confirmationTableHeader;

import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../../core-components/atoms/Button';
import Popup from '../../../../core-components/atoms/Popup';
import { makeStyles, TextField, ThemeProvider } from '@material-ui/core';
import { Grid, InputAdornment } from '@mui/material';
import { Search } from '@material-ui/icons';
import { createTheme } from '@material-ui/core/styles';
import { OASIS_PRIMARY_500 } from '../../../../constants/colors';
import {
  Label,
  Paragraph,
  Title
} from '../../../../core-components/atoms/Text';
import { ORGANIZATION_TYPES } from '../../../../constants';
import { exportToCSV, SharedContext } from '../../../../utils/common';
import card_alert from '../../../../assets/icons/sd_card_alert.svg';
import downloadIcon from '../../../../assets/icons/download-file.svg';
import FLAGS from '../../../../constants/featureFlags';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';

const useStyles = makeStyles(() => ({
  nodeTitleText: {
    fontFamily: 'Roboto !important',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.048px',
    textTransform: 'uppercase',
    color: '#696F80'
  }
}));

const BulkUpload = ({
  bulkUploadResponse,
  bulkUploadPopup,
  setBulkUploadPopup,
  data,
  createBulkUpload = [],
  company,
  setBulkUploadResponse,
  setCheckValidations
}) => {
  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });
  const [search, setSearch] = useState('');
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [brandsArr, setBrandsArr] = useState([]);
  const [productsArr, setProductsArr] = useState([]);
  const classes = useStyles();
  const { organizationType } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    const aisleArr = data
      ?.map((item) => item?.Category)
      ?.filter((value, index, self) => self.indexOf(value) === index);
    const bayArr = data
      ?.map((item) => item.Brand)
      ?.filter((value, index, self) => self.indexOf(value) === index);
    setCategoriesArr(aisleArr);
    setBrandsArr(bayArr);
    setProductsArr(data);
  }, [data]);

  useEffect(() => {
    if (!bulkUploadPopup) {
      setCheckValidations(true);
      setBulkUploadResponse(null);
    }
  }, [bulkUploadPopup]);

  const downloadbulkErrorFile = () => {
    const title = `Products with issues.`;
    const header = [
      'Product Code',
      'Product Name',
      'Product Type',
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Supplier']
        : []),
      'Category',
      'Brand',
      'Barcode',
      'Hs Code',
      'Primary UoM',
      'Secondary UoM',
      'Conversion Rate',
      'UoM per Pallet',
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Shelf Life (In Days)']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Product Life (In Days)']
        : []),
      'Height',
      'Length',
      'Breadth',
      'Volume',
      'Lead Time',
      'Weight',
      'Min Ordered Quantity',
      'Max Ordered Quantity',
      'Stock Handling Type',
      'Re Order Point',
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Cost Price']
        : []),
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Selling Price']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['SKU requires Expiry Tracking']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['MRP Enabled']
        : []),
      'Issues'
    ];

    const exportSortedHalls = bulkUploadResponse?.invalidProducts?.map(
      (value, i) => {
        return {
          'Product Code': `"${value?.name || ''}"`,
          'Product Name': `"${value?.description || ''}"`,
          'Product Type': `"${value?.productType || ''}"`,
          ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
            ? { Supplier: `"${value?.supplier || ''}"` }
            : {}),
          Category: `"${value?.category || ''}"`,
          Brand: `"${value?.brand || ''}"`,
          Barcode: `"${value?.barcode || ''}"`,
          'HS Code': `"${value?.hsCode || ''}"`,
          'Primary UoM': `"${value?.uom || ''}"`,
          'Secondary UoM': `"${value?.secondaryUom || ''}"`,
          'Conversion Rate': `"${value?.conversionFactor || ''}"`,
          'UoM per Pallet': `"${value?.perPallet || ''}"`,
          ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
            ? { 'Shelf Life (In Days)': `"${value?.shelfLife || ''}"` }
            : {}),
          ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
            ? { 'Product Life (In Days)': `"${value?.productLife || ''}"` }
            : {}),
          Height: `"${value?.height || ''}"`,
          Length: `"${value?.length || ''}"`,
          Breadth: `"${value?.breadth || ''}"`,
          'Lead Time': `"${value?.leadTime || ''}"`,
          Volume: `"${value?.dimensionsCBM || ''}"`,
          Weight: `"${value?.weight || ''}"`,
          'Min Ordered Quantity': `"${value?.minOrderedQuantity || ''}"`,
          'Max Ordered Quantity': `"${value?.maxOrderedQuantity || ''}"`,
          'Stock Handling Type': `"${value?.stockHandlingType || ''}"`,
          'Re Order Point': `"${value?.reOrderPoint || ''}"`,
          ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
            ? { 'Cost Price': `"${value?.costPrice || ''}"` }
            : {}),
          ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
            ? { 'Selling Price': `"${value?.sellingPrice || ''}"` }
            : {}),
          ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
            ? {
                'SKU requires Expiry Tracking': `"${value?.batchEnabled || ''}"`
              }
            : {}),
          ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
            ? {
                'MRP Enabled': `"${value?.MRPEnabled || ''}"`
              }
            : {}),
          'Additional Information': `"${value?.additionalInformation || ''}"`,
          Issues: `"${bulkUploadResponse?.validationErrors
            ?.filter((data) => {
              return data.includes(`Row ${i + 2} :`);
            })
            .join('; ')}"`
        };
      }
    );

    exportToCSV(header, exportSortedHalls, title);
  };

  return (
    <Popup
      open={bulkUploadPopup}
      setOpen={setBulkUploadPopup}
      title={`Bulk Upload For ${company ? company?.name : 'Products'}`}
      content={
        <div className="flex flex-col gap-2 h-[500px] w-[788px]">
          {bulkUploadResponse?.invalidProducts?.length > 0 && (
            <div className="display-flex justify-between bg-[#FFEBEB] px-4 py-3.5 border-[#D63232] border rounded mb-8 align-center">
              <div className="gap-2 display-flex flex-1 items-center">
                <img src={card_alert} alt="card-alert-icon" />
                <Paragraph variant={'md'}>
                  {bulkUploadResponse?.invalidProducts?.length} products are not
                  uploaded because of formatting issues in file.
                </Paragraph>
              </div>
              <button
                className="flex mr-8 self-end flex-0.1 gap-2 items-center"
                onClick={downloadbulkErrorFile}
              >
                <img src={downloadIcon} alt="download-icon" />
                <Title variant={'md'}>Download File</Title>
              </button>
            </div>
          )}
          {organizationType ===
            ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER && (
            <div className="mb-56 mt-4">
              <div className="display-flex bd-4">
                <div className="mr-8">
                  <p className={classes.nodeTitleText}>Customer Name</p>
                  <Paragraph className="text-pg-sm mt-2">
                    {company?.name}
                  </Paragraph>
                </div>
                <div className="mr-8">
                  <p className={classes.nodeTitleText}>Customer Type</p>
                  <Paragraph className="text-pg-sm mt-2">
                    {company?.relationType || '-'}
                  </Paragraph>
                </div>
                <div className="mr-8">
                  <p className={classes.nodeTitleText}>Customer Code</p>
                  <Paragraph className="text-pg-sm mt-2">
                    {company?.internalIdForBusiness || '-'}
                  </Paragraph>
                </div>
                <div>
                  <p className={classes.nodeTitleText}>Address</p>
                  <Paragraph className="text-pg-sm mt-2">
                    {company?.billingAddress || '-'}
                  </Paragraph>
                </div>
              </div>
            </div>
          )}
          <div className="display-flex mb-2 justify-between items-center w-100">
            <Title variant={'md'}>{productsArr?.length} items detected</Title>
            <div className={'w-86 mr-3'}>
              <ThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  variant="outlined"
                  color={'primary'}
                  value={search}
                  onChange={(e) => {
                    let search = e.target.value;
                    setSearch(search);
                    if (search) {
                      const products = data?.filter((value) => {
                        return (
                          value['Product Name']
                            ?.toLowerCase()
                            ?.includes(search.toLowerCase()) ||
                          value['Product Code']
                            ?.toLowerCase()
                            ?.includes(search.toLowerCase()) ||
                          `${value['Product Code']}-${value['Product Name']}`
                            ?.toLowerCase()
                            ?.includes(search.toLowerCase())
                        );
                      });
                      const catArr = categoriesArr?.filter((value) =>
                        value.toLowerCase().includes(search.toLowerCase())
                      );
                      const brandArr = brandsArr?.filter((value) =>
                        value.toLowerCase().includes(search.toLowerCase())
                      );
                      setCategoriesArr(catArr);
                      setBrandsArr(brandArr);
                      setProductsArr(products);
                    } else {
                      const aisleArr = data
                        ?.map((item) => item?.Category)
                        ?.filter(
                          (value, index, self) => self.indexOf(value) === index
                        );
                      const bayArr = data
                        ?.map((item) => item.Brand)
                        ?.filter(
                          (value, index, self) => self.indexOf(value) === index
                        );
                      setCategoriesArr(aisleArr);
                      setBrandsArr(bayArr);
                      setProductsArr(data);
                    }
                  }}
                  size="small"
                  inputProps={{
                    onFocus: () => search
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <Search
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px'
                          }}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </ThemeProvider>
            </div>
          </div>
          <div>
            {categoriesArr?.length > 0 && (
              <div>
                <div className="display-flex items-center mb-3">
                  <Label variant={'sm'} className={'mr-3'}>
                    CATEGORIES
                  </Label>
                  <Paragraph variant={'sm'}>
                    ({categoriesArr?.length})
                  </Paragraph>
                </div>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="mx-1 mb-10"
                >
                  {categoriesArr.map((value, index) => (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                      className="flex items-center justify-between bg-oasisPrimary100 px-4 py-4 bd-4 my-2 mr-4"
                    >
                      <div className={'flex items-center gap-4'}>
                        <Paragraph
                          className={`text-sm ${search && 'bg-[#FFF0C3]'}`}
                        >
                          {value}
                        </Paragraph>
                      </div>{' '}
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}

            {brandsArr?.length > 0 && (
              <div>
                <div className="display-flex items-center mb-3">
                  <Label variant={'sm'} className={'mr-3'}>
                    BRANDS
                  </Label>
                  <Paragraph variant={'sm'}>({brandsArr?.length})</Paragraph>
                </div>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="mx-1 mb-10"
                >
                  {brandsArr.map((value, index) => (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                      className="flex items-center justify-between bg-oasisPrimary100 px-4 py-4 bd-4 my-2 mr-4"
                    >
                      <div className={'flex items-center gap-4'}>
                        <Paragraph
                          className={`text-sm ${search && 'bg-[#FFF0C3]'}`}
                        >
                          {value}
                        </Paragraph>
                      </div>{' '}
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
            {productsArr?.length > 0 && (
              <div>
                <div className="display-flex items-center mb-3">
                  <Label variant={'sm'} className={'mr-3'}>
                    PRODUCTS
                  </Label>
                  <Paragraph variant={'sm'}>({productsArr?.length})</Paragraph>
                </div>
                {productsArr?.map((value, index) => (
                  <div
                    key={index}
                    className="items-center justify-between bg-oasisPrimary100 px-4 py-4 bd-4 my-2 mr-4"
                  >
                    <div className="flex gap-2">
                      <div
                        className={`h-3 w-3 mt-2 rounded-full border-[#1AAE2E] bg-[#1AAE2E]`}
                      />
                      <Title
                        variant={'sm'}
                        className={`${search && 'bg-[#FFF0C3]'}`}
                      >
                        {value['Product Code']}-{value['Product Name']}
                      </Title>
                    </div>
                    <div className="ml-[19px] mt-2 flex">
                      {value['Primary UoM'] && (
                        <div className="px-2 py-0.5 bg-oasisPrimary300 rounded mr-2">
                          <Paragraph className="text-[10px] font-medium text-oasisPrimary600">
                            UoM: {value['Primary UoM']}
                          </Paragraph>
                        </div>
                      )}
                      {value['Category'] && (
                        <div className="px-2 py-0.5 bg-oasisPrimary300 rounded mr-2">
                          <Paragraph className="text-[10px] font-medium text-oasisPrimary600">
                            CATEGORY: {value['Category']}
                          </Paragraph>
                        </div>
                      )}
                      {value['Brand'] && (
                        <div className="px-2 py-0.5 bg-oasisPrimary300 rounded mr-2">
                          <Paragraph className="text-[10px] font-medium text-oasisPrimary600">
                            BRAND: {value['Brand']}
                          </Paragraph>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      }
      onClose={() => {
        setBulkUploadPopup(false);
      }}
      actions={
        <div className="flex justify-end">
          <Button
            label="Cancel"
            variant="transparent"
            onClick={() => {
              setBulkUploadPopup(false);
            }}
          />
          <Button
            className="ml-4"
            label={`Add ${data?.length} Item${data?.length > 0 ? 's' : ''} `}
            variant="primary"
            onClick={createBulkUpload}
            disabled={data?.length === 0}
          />
        </div>
      }
    />
  );
};

export default BulkUpload;

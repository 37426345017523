import API from '../../libs/axios';

export const fetchShipmentStats = async ({ from, to, nodeId, moveType }) => {
  return await API.get(`reporting/shipments/stats`, {
    params: { from, to, nodeId, moveType }
  });
};

export const fetchNodes = async () => {
  return await API.get(`reporting/warehouses`);
};

export const fetchMapData = async ({ from, to, nodeType, search }) => {
  return await API.get(`reporting/nodes`, {
    params: { ...(from && { from }), ...(to && { to }), nodeType, search }
  });
};

export const fetchExceptions = async (limit, page) => {
  return await API.get(`reporting/exceptions`, {
    params: { limit, page }
  });
};

export const fetchShipments = async ({
  from,
  to,
  nodeType,
  nodeId,
  status,
  page,
  search
}) => {
  return await API.get(`reporting/shipments`, {
    params: {
      ...(from && { from }),
      ...(to && { to }),
      nodeType,
      nodeId,
      status,
      page,
      search,
      limit: 10
    }
  });
};

export const fetchCalendarShipments = async ({
  date,
  nodeType,
  nodeId,
  active,
  status,
  orderStatus,
  moveType,
  page
}) => {
  return await API.get(`reporting/weekly-shipments`, {
    params: {
      ...(date && { date }),
      nodeType,
      nodeId,
      active,
      status,
      orderStatus,
      moveType,
      page,
      limit: 10
    }
  });
};

export const fetchOrdersCount = async () => {
  return await API.get(`reporting/active-orders-count`);
};

export const fetchShipmentsCount = async () => {
  return await API.get(`reporting/active-shipments-count`);
};

export const fetchSalesRevenue = async () => {
  return await API.get(`reporting/sales-revenue`);
};

export const fetchTrackingDetails = async (shipmentId) => {
  return await API.get(`reporting/shipments/${shipmentId}/tracking`);
};

export const fetchOTIFRate = async () => {
  return await API.get(`reporting/otif-rate`);
};

export const favoriteShipment = async ({ shipmentId, isFavorite }) => {
  return await API.put(`reporting/shipments/favorite/${shipmentId}`, {
    isFavorite
  });
};

export const fetchShipment = async (shipmentId) => {
  return await API.get(`reporting/shipments/${shipmentId}`);
};

/* eslint-disable */
import { ORGANIZATION_TYPES } from '../../constants';

export const constants = {
  ORDER_TYPES: {
    INWARD: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
    LOGISTIC: 'LOGISTIC',
    TRANSFER: 'TRANSFER',
    PURCHASE: 'PURCHASE',
    SALES: 'SALES',
    SALES_RETURN: 'SALES_RETURN'
  },

  MANUFACTURER_ORDER_TYPES: {
    PURCHASE: 'PURCHASE',
    SALES: 'SALES',
    SALES_RETURN: 'SALES_RETURN',
    TRANSFER: 'TRANSFER'
  },

  PL3_ORDER_TYPES: {
    INWARD: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
    LOGISTIC: 'LOGISTIC',
    TRANSFER: 'TRANSFER'
  },

  ORDER_STATUS: {
    DRAFT: 'Draft',
    ASSIGNED: 'Route Assigned',
    ON_THE_WAY_DESTINATION: 'On The Way To Destination',
    ARRIVED_DESTINATION: 'Arrived At Destination',
    UNLOADING_IN_PROGRESS: 'Unloading In Progress',
    UNLOADING_COMPLETE: 'Unloading Complete',
    PARTIALLY_COMPLETED_CLOSED: 'Closed',
    PARTIALLY_COMPLETE: 'Partially Complete',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    PENDING: 'Pending',
    PUTAWAY_IN_PROGRESS: 'Put Away In Progress',
    PICKING_IN_PROGRESS: 'Picking In Progress',
    LOAD_DISPATCHED: 'Load Dispatched',
    LOADING_COMPLETE: 'Loading Complete',
    LOADING_IN_PROGRESS: 'Loading In Progress'
  },

  O360_ORDER_STATUSES: {
    DRAFT: 'Draft',
    PARTIALLY_COMPLETED_CLOSED: 'Closed',
    PARTIALLY_COMPLETE: 'Partially Complete',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    PENDING: 'Pending'
  },

  STOCK_360_ORDER_STATUSES: {
    PUTAWAY_IN_PROGRESS: 'Put Away In Progress',
    PICKING_IN_PROGRESS: 'Picking In Progress'
  },

  TRANSIT_360_ORDER_STATUSES: {
    ASSIGNED: 'Route Assigned',
    ON_THE_WAY_DESTINATION: 'On The Way To Destination',
    ARRIVED_DESTINATION: 'Arrived At Destination',
    UNLOADING_IN_PROGRESS: 'Unloading In Progress',
    UNLOADING_COMPLETE: 'Unloading Complete',
    LOAD_DISPATCHED: 'Load Dispatched',
    LOADING_COMPLETE: 'Loading Complete',
    LOADING_IN_PROGRESS: 'Loading In Progress'
  }
};

export const AnimationType = {
  LOTTIE: 'LOTTIE',
  GIF: 'GIF'
};

export const OrderTypeMap = {
  INWARD: 'INWARD',
  OUTBOUND: 'OUTBOUND',
  TRANSFER: 'TRANSFER',
  LOGISTIC: 'LOGISTIC',
  PURCHASE: 'INWARD',
  SALES: 'OUTBOUND',
  SALES_RETURN: 'INWARD'
};

export const OrderSubTypesMap = {
  PURCHASE: 'INWARD',
  SALES: 'OUTBOUND',
  SALES_RETURN: 'SALES_RETURN'
};

export const OrderSubTypes = ['PURCHASE', 'SALES', 'SALES_RETURN'];

export const OrderTypeRowText = {
  Inward: 'Select Order type & Customer',
  Outbound: 'Select Order type & Customer',
  Transfer: 'Select Order type',
  Logistic: 'Select Order type',
  Purchase: 'Select Order type & Supplier',
  Sales: 'Select Order type & Customer',
  'Sales Return': 'Select Order type & Order ID'
};

export const OrderTypeRowTextMobile = {
  Inward: 'Select Customer',
  Outbound: 'Select Customer',
  Purchase: 'Select Supplier',
  Sales: 'Select Customer',
  'Sales Return': 'Select Order ID'
};

export const mapStatuses = (tms, stockEnabled) => {
  if (!tms && !stockEnabled) {
    return constants.O360_ORDER_STATUSES;
  } else if (tms && !stockEnabled) {
    return {
      ...constants.O360_ORDER_STATUSES,
      ...constants.TRANSIT_360_ORDER_STATUSES
    };
  } else if (!tms && stockEnabled) {
    return {
      ...constants.O360_ORDER_STATUSES,
      ...constants.STOCK_360_ORDER_STATUSES
    };
  }

  return {
    ...constants.O360_ORDER_STATUSES,
    ...constants.STOCK_360_ORDER_STATUSES,
    ...constants.TRANSIT_360_ORDER_STATUSES
  };
};

export const ShipmentMode = {
  LAND: 'LAND',
  AIR: 'AIR',
  SEA: 'SEA'
};

export const getColumns = (orgType) => ({
  0: {
    fixed: ['Order ID', 'Origin & Destination', 'Order Type', 'Order Status'],
    variable: [
      orgType == ORGANIZATION_TYPES.MANUFACTURER
        ? 'Order Value & Items'
        : 'Order Items',
      'Creation Date'
    ]
  },
  1: {
    fixed: [
      'Order ID',
      'Origin & Destination',
      'Order Type',
      'Order Status',
      'Shipment Status'
    ],
    variable: [
      orgType == ORGANIZATION_TYPES.MANUFACTURER
        ? 'Order Value & Items'
        : 'Order Items',
      'Creation Date'
    ]
  },
  2: {
    fixed: [
      'Order ID',
      'Origin & Destination',
      'Order Type',
      'Creation Progress'
    ],
    variable: [
      orgType == ORGANIZATION_TYPES.MANUFACTURER
        ? 'Order Value & Items'
        : 'Order Items'
    ]
  },
  3: {
    fixed: [
      'Order ID',
      'Origin & Destination',
      'Order Type',
      'Order Status',
      'Shipment Status'
    ],
    variable: [
      orgType == ORGANIZATION_TYPES.MANUFACTURER
        ? 'Order Value & Items'
        : 'Order Items',
      'Creation Date'
    ]
  },
  4: {
    fixed: ['Order ID', 'Origin & Destination', 'Order Type', 'Closing Date'],
    variable: [
      orgType == ORGANIZATION_TYPES.MANUFACTURER
        ? 'Order Value & Items'
        : 'Order Items',
      'Creation Date'
    ]
  },
  5: {
    fixed: ['Order ID', 'Origin & Destination', 'Order Type', 'Order Status'],
    variable: ['Creation Date', 'Reason Of Cancelling']
  }
});

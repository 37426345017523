import API from '../../libs/axios';

export const updateGoodStockCondition = async ({
  inventoryDetailId,
  availableQuantity,
  damageRecoverable: recoverableDamageQuantity,
  damageUnsellable: unsellableDamageQuantity
}) => {
  return await API.put(`inventories/stock-condition/${inventoryDetailId}`, {
    source: {
      availableQuantity
    },
    recoverableDamageQuantity,
    unsellableDamageQuantity,
    hostUrl: window.location.href
  });
};

export const updateDamagedStockCondition = async ({
  inventoryDetailId,
  recoverableQuantity: recoverableDamageQuantity,
  unsellableQuantity: unsellableDamageQuantity,
  goodQuantity
}) => {
  return await API.put(`inventories/stock-condition/${inventoryDetailId}`, {
    source: {
      recoverableDamageQuantity
    },
    goodQuantity,
    unsellableDamageQuantity,
    hostUrl: window.location.href
  });
};

export const getAllCompanies = async () => {
  return await API.get(`/companies`);
};

export const getInventories = async ({
  page,
  searchKeyword,
  filters,
  columns,
  order,
  advanceFilters,
  excludeBatches
}) => {
  return await API.get(`inventories`, {
    params: {
      page,
      search: searchKeyword,
      filters,
      columns,
      order,
      advanceFilters,
      excludeBatches
    }
  });
};

export const getInventoriesStats = async () => {
  return await API.get(`inventories/stats/all`);
};

export const getInventoriesWeight = async () => {
  return await API.get(`inventories/stats/weight`);
};

export const exportInventories = async ({
  offset,
  search,
  filters,
  columns
}) => {
  const response = await API.get(`inventories/export`, {
    responseType: 'blob',
    params: { offset, search, filters, columns }
  });
  return response;
};

export const getInventory = async ({
  id,
  searchKeyword,
  filters,
  columns,
  advanceFilters
}) => {
  return await API.get(`inventories/${id}`, {
    params: {
      search: searchKeyword,
      filters: filters,
      columns: columns,
      advanceFilters: advanceFilters
    }
  });
};

export const holdInventory = async (params) => {
  return await API.put(`inventories/hold`, null, { params });
};

export const removeHoldFromInventory = async (params) => {
  return await API.put(`inventories/hold/remove`, params);
};

export const getInventoryOrdersInTransit = async ({ id, page }) =>
  await API.get(`inventories/in-transit/${id}`, {
    params: {
      page: page,
      limit: 20
    }
  });

export const getInventoryOrdersReserved = async ({ id, page }) =>
  await API.get(`inventories/reserved/${id}`, {
    params: {
      page: page,
      limit: 20
    }
  });

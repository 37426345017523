import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import useStyles from './palletStyles';
import '../../../assets/css/style.css';
import { QRCode } from 'react-qrcode-logo';
import { dividerDateFormatForFilter } from '../../../utils/common';

export default function PalletsQR(props) {
  const classes = useStyles();

  return (
    <Paper>
      <Grid item container>
        {props?.pallets?.length &&
          props?.pallets?.map((pallet) => {
            return (
              <Grid
                item
                container
                key={pallet?.businessWarehouseCode}
                className="mt-2 palletQR"
              >
                <Grid item xs={6}>
                  <QRCode
                    value={JSON.stringify({
                      id: pallet?.id,
                      palletId: pallet?.palletId
                    })}
                    {...{
                      ecLevel: 'M',
                      enableCORS: false,
                      size: 160,
                      quietZone: 10,
                      bgColor: '#FFFFFF',
                      fgColor: '#000000',
                      qrStyle: 'squares'
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid item container>
                    <Grid item xs={12}>
                      <p className={classes.qrText}>
                        {pallet?.Warehouse?.businessWarehouseCode}
                      </p>
                    </Grid>

                    <Grid item xs={12}>
                      <p className={classes.qrText}>{pallet?.palletId}</p>
                    </Grid>

                    <Grid item xs={12}>
                      <p className={classes.qrText}>
                        {dividerDateFormatForFilter(pallet?.creationDate)}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Paper>
  );
}

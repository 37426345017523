import { featureFlag } from '../config/featureFlag';
import { getOrgSubdomain } from './common';

export const FeatureFlag = (featureName) => {
  const currentOrg = getOrgSubdomain(window.location.host);
  if (!currentOrg || !featureName) {
    return false;
  }

  const orgFeatureFlags = featureFlag[currentOrg];
  if (!orgFeatureFlags) return true;

  return orgFeatureFlags[featureName] === 'true';
};

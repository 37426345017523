import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useNavigate } from 'react-router';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Typography,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputAdornment
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { toaster } from '../../../utils/toaster';
import { debounce } from 'lodash';
import * as Yup from 'yup';
import classNames from 'classnames/bind';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormik } from 'formik';

import API from '../../../libs/axios';
import ScanPallets from './ScanPallets';
import {
  dividerDateFormatForFilter,
  dateFormatSlashDivider,
  productLabelFormat,
  SharedContext
} from '../../../utils/common';
import ScannerIcon from '../../../assets/icons/scanner.svg';
import { DEBOUNCE_CONST } from '../../../Config';
import useStyles from './palletizeStyles';
import DetailText from '../../../atomicComponents/DetailText';
import Dialogue from '../../../atomicComponents/Dialogue';
import SectionHeading from '../../../atomicComponents/SectionHeading';
import { onError } from '../../../libs/errorLib';

export default function AddPalletizeStock() {
  const inputRef = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [warehouses, setWarehouses] = useState('');
  const [orderSearch, setOrderSearch] = useState('');
  const [warehouseSearch, setWarehouseSearch] = useState('');
  const [companySearch, setCompanySearch] = useState('');
  const [companies, setCompanies] = useState([]);
  const [batches, setBatches] = useState([]);
  const [currStockId, setCurrStockId] = useState('');
  const [details, setDetails] = useState({});
  const [scanning, setScanning] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [disable, setDisable] = useState(false);
  const [scanProducts, setScanProducts] = useState(false);
  const [scanPallets, setScanPallets] = useState(false);
  const [palletizeStockList, setPalletizeStockList] = useState([]);
  const [pallets, setPallets] = useState([]);

  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);

  const [formikInitVals, setInitVals] = useState({
    order: {},
    warehouse: {},
    company: {},
    product: {},
    batch: {},
    availableQty: '',
    UPP: '',
    MRP: '',
    palletQty: '',
    pallet: {}
  });
  const formik = useFormik({
    initialValues: formikInitVals,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      warehouse: Yup.object().shape({
        id: Yup.number().required('Warehouse is required')
      }),
      company: Yup.object().shape({
        id: Yup.number().required('Company is required')
      }),
      product: Yup.object().shape({
        id: Yup.number().required('Product is required')
      }),
      batch: Yup.object().shape({
        id: Yup.number().required('Batch is required')
      }),
      pallet: Yup.object().shape({
        id: Yup.number().required('Pallet is required')
      }),
      palletQty: Yup.string().required('Pallet QTY is required')
    }),
    onSubmit: () => {
      addStock();
    }
  });

  const enableSKUScanning = () => {
    return (
      Object.keys(formik?.values?.warehouse)?.length !== 0 &&
      Object.keys(formik?.values?.company)?.length !== 0
    );
  };

  const scanningMode = classNames({
    'cursor-pointer': enableSKUScanning(),
    'o-4': !enableSKUScanning()
  });

  const getOrdersCallback = useCallback(
    debounce((search) => {
      getGRNOrders(search ? Number(search) : null);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getWarehousesCallback(warehouseSearch);
  }, [warehouseSearch]);

  useEffect(() => {
    if (Object.keys(details)?.length !== 0) {
      setConfirmation(true);
    }
  }, [details]);

  useEffect(() => {
    getOrdersCallback(orderSearch);
  }, [orderSearch]);

  useEffect(() => {
    let title = 'Palletize Stock';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  const getWarehousesCallback = useCallback(
    debounce((search) => {
      getWarehouses(search);
    }, DEBOUNCE_CONST),
    []
  );

  const getDelayedCompanies = useCallback(
    debounce((search, warehouseId) => {
      getCompanies(search, warehouseId);
    }, DEBOUNCE_CONST),
    []
  );

  const getWarehouses = async (search) => {
    try {
      setAPILoader(true);
      const columns = ['businessWarehouseCode', 'name'];
      const warehouses = await API.get(`warehouses`, {
        params: { columns, search }
      });
      setWarehouses(warehouses?.data);
    } catch (error) {
      let errors = error?.response?.data?.error?.errors;
      errors?.map((data) => {
        toaster('warning', data.message);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const getCompanies = async (search, warehouseId) => {
    try {
      setAPILoader(true);
      let colVal = { isActive: true };
      const filters = { colVal };
      let params = {};
      if (search) {
        params.search = search;
      }
      params.filters = filters;
      const companies = await API.get(`warehouses/${warehouseId}/companies`, {
        params
      });
      setCompanies(companies?.data?.rows);
      if (companies?.data?.rows?.length === 1) {
        formik.setFieldValue('company', companies?.data?.rows[0]);
      }
    } catch (error) {
      let errors = error?.response?.data?.error?.errors;
      errors?.map((data) => {
        toaster('warning', data.message);
      });
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (formik?.values?.warehouse) {
      getDelayedCompanies(companySearch, formik?.values?.warehouse?.id);
      getPallets('', formik?.values);
    }
  }, [formik?.values?.warehouse, companySearch]);

  useEffect(() => {
    if (!scanning) {
      inputRef.current.focus();
    }
  }, [scanning]);

  useEffect(() => {
    if (
      formik?.values?.warehouse &&
      Object.keys(formik?.values?.warehouse)?.length !== 0 &&
      formik?.values?.company &&
      Object.keys(formik?.values?.company)?.length !== 0 &&
      (!formik?.values?.order ||
        Object.keys(formik?.values?.order)?.length === 0)
    ) {
      getGRNOrders();
    }
  }, [
    formik?.values?.warehouse,
    formik?.values?.company,
    formik?.values?.product
  ]);

  const getBatches = async (warehouseId, companyId, productId) => {
    try {
      setAPILoader(true);
      let params = {};
      let batches = [];
      if (Object.keys(formik?.values?.order)?.length !== 0) {
        params = {
          params: {
            orderId: formik?.values?.order?.id,
            warehouseId: formik?.values?.warehouse?.id
          }
        };
        batches = await API.get(
          `warehouses/${formik?.values?.warehouse?.id}/orders/${formik?.values?.order?.customId}/grns`,
          params
        );
        const getFilterBatches = getFilteredBatches(
          batches?.data[0],
          productId
        );
        setBatches(getFilterBatches);
      } else {
        params = {
          params: {
            warehouseId: warehouseId,
            companyId: companyId,
            productId: productId
          }
        };
        batches = await API.get(`inventories/inventories`, params);
        setBatches(batches?.data[0]?.InventoryDetail);
      }
    } catch (error) {
      let errors = error?.response?.data?.error?.errors;
      errors?.map((data) => {
        toaster('warning', data.message);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const getFilteredBatches = (GRNGroups, productId) => {
    const grnGroup = GRNGroups?.GRNGroup?.find(
      (GRNGroup) => GRNGroup?.productId === productId
    );
    const batches = grnGroup?.GRNGroupBatches?.map((batch) => {
      return {
        id: batch?.InventoryDetail?.id,
        MRP: batch?.InventoryDetail?.MRP,
        expiryDate: batch?.InventoryDetail?.expiryDate,
        batchNumber: batch?.InventoryDetail?.batchNumber,
        PalletStocks: batch?.InventoryDetail?.PalletStocks,
        goodQuantity: batch?.InventoryDetail?.goodQuantity,
        hallId: batch?.InventoryDetail?.WarehouseHall?.id,
        manufacturingDate: batch?.InventoryDetail?.manufacturingDate,
        availableQuantity: batch?.InventoryDetail?.availableQuantity
      };
    });
    return batches;
  };

  const getGRNOrders = async (search) => {
    try {
      setAPILoader(true);
      const page = 1;
      const limit = 500;
      const colVal = {};
      colVal['$Order.warehouseDropOff.id$'] = formik?.values?.warehouse?.id;
      colVal['$Order.Company.id$'] = formik?.values?.company?.id;
      const columns = [
        '$Order.id$',
        '$Order.warehouseDropOff.id$',
        '$Order.Company.id$',
        '$GRNGroup.Product.id$'
      ];
      const filters = getfilters(colVal);
      const orders = await API.get(`pallets/grn-orders`, {
        params: { filters, columns, search, page, limit }
      });
      if (
        Object.keys(formik?.values?.warehouse)?.length !== 0 &&
        Object.keys(formik?.values?.company)?.length !== 0 &&
        (typeof formik?.values?.order === 'undefined' ||
          Object.keys(formik?.values?.order)?.length === 0)
      ) {
        getProducts(orders);
      } else {
        setOrders(orders?.data);
      }
    } catch (error) {
      let errors = error?.response?.data?.error?.errors;
      errors?.map((data) => {
        toaster('warning', data.message);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const valProdAndBat = async (detail) => {
    try {
      setAPILoader(true);
      const validate = await API.get(`inventories/inventories`, {
        params: {
          warehouseId: formik?.values?.warehouse?.id,
          companyId: formik?.values?.company?.id,
          productId: detail?.PID
        }
      });
      if (Object.keys(formik?.values?.order)?.length !== 0 && scanProducts) {
        validateProductAndBatch(detail);
      } else if (scanProducts) {
        fetchProductAndBatch(validate?.data, detail);
      }
    } catch (error) {
      let errors = error?.response?.data?.error?.errors;
      errors?.map((data) => {
        toaster('warning', data.message);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const validateProductAndBatch = (detail) => {
    if (
      detail &&
      formik?.values?.palletQty &&
      detail?.batch !== formik?.values?.batch?.batchNumber
    ) {
      setScanning(false);
      setDetails({ orders, detail });
    } else {
      let flag = false;
      formik?.values?.GRNGroup?.forEach((product) => {
        if (product?.Product?.id === detail?.PID) {
          formik.setFieldValue('product', product?.Product);
          formik.setFieldValue('UPP', product?.Product?.perPallet);
          flag = true;
        }
        product?.GRNGroupBatches.forEach((batch) => {
          if (
            batch?.batchNumber === detail?.batch &&
            detail?.exp === dateFormatSlashDivider(batch?.expiryDate)
          ) {
            formik.setFieldValue('batch', batch);
            formik.setFieldValue('MRP', batch?.MRP);
            formik.setFieldValue(
              'availableQty',
              batch?.InventoryDetail?.availableQuantity
            );
            validatePalleteQty(
              !formik?.values?.palletQty || Object.keys(details)?.length !== 0
                ? 1
                : Number(formik?.values?.palletQty) + 1
            );
          }
        });
      });
      if (!flag) {
        toaster('warning', 'No Product found');
      } else {
        setScanning(false);
      }
    }
  };

  const getfilters = (cols) => {
    if (
      !formik?.values?.order ||
      Object.keys(formik?.values?.order)?.length === 0
    ) {
      return cols;
    }
  };

  const getProducts = (orders) => {
    let filterProducts = [];
    orders?.data?.forEach((order) => {
      order?.GRNGroup?.forEach((product) => {
        const check = filterProducts?.find(
          (duplicateProd) => duplicateProd?.id === product?.Product?.id
        );
        if (!check) {
          filterProducts.push(product?.Product);
        }
      });
    });
    setProducts(filterProducts);
  };

  const fetchDetails = (data, detail) => {
    setConfirmation(false);
    data?.forEach((inventory) => {
      if (inventory?.Product?.id === detail?.PID) {
        if (
          Object.keys(formik?.values?.product)?.length === 0 ||
          formik?.values?.product?.id !== inventory?.Product?.id
        ) {
          formik.setFieldValue('product', inventory?.Product);
          formik.setFieldValue('UPP', inventory?.Product?.perPallet);
        }
        inventory?.InventoryDetail?.find((batch) => {
          if (
            batch?.batchNumber === detail?.batch &&
            dateFormatSlashDivider(batch?.expiryDate) === detail?.exp
          ) {
            formik.setFieldValue('batch', batch);
            formik.setFieldValue('MRP', batch?.MRP);
            formik.setFieldValue('availableQty', batch?.availableQuantity);
            validatePalleteQty(
              !formik?.values?.palletQty || Object.keys(details)?.length !== 0
                ? 1
                : Number(formik?.values?.palletQty) + 1
            );
          }
        });
      }
    });
    setDetails({});
    setScanning(false);
  };

  const fetchProductAndBatch = (data, detail) => {
    if (data?.length) {
      if (
        detail &&
        formik?.values?.palletQty &&
        detail?.batch !== formik?.values?.batch?.batchNumber
      ) {
        setScanning(false);
        setDetails({ data, detail });
      } else {
        fetchDetails(data, detail);
      }
    } else {
      toaster('warning', 'No Product found');
    }
  };

  const GRNorders = (newValue) => {
    setDisabled(true);
    formik.values.order = newValue?.Order;
    formik.values.GRNGroup = newValue?.GRNGroup;
    formik.values.warehouse = newValue?.Order?.warehouseDropOff;
    formik.values.company = newValue?.Order?.Company;
    formik.values.product = {};
    formik.values.batch = {};
    formik.values.MRP = '';
    formik.values.availableQty = '';
    let products = newValue?.GRNGroup?.map((product) => {
      return product?.Product;
    });
    setProducts(products);
  };

  const resetStates = () => {
    setCurrStockId('');
    formik.values.order = {};
    formik.values.warehouse = {};
    formik.values.company = {};
    formik.values.product = {};
    formik.values.batch = {};
    formik.values.pallet = {};
    formik.values.availableQty = '';
    formik.values.palletQty = '';
    formik.values.MRP = '';
    formik.values.UPP = '';
    setDisabled(false);
    setProducts([]);
    setBatches([]);
  };

  const resetProductLevelInfo = () => {
    setScanProducts(enableSKUScanning);
    setScanning(enableSKUScanning);
  };

  const handleSubmit = async () => {
    try {
      if (!palletizeStockList?.length) {
        return;
      }
      setDisable(true);
      setAPILoader(true);
      const palletStock = palletizeStockList?.map((stock) => {
        return {
          orderId:
            Object.keys(stock?.order)?.length !== 0 ? stock?.order?.id : null,
          customerId: stock?.company?.id,
          palletId: Number(stock?.pallet?.id),
          quantity: Number(stock?.palletQty),
          warehouseId: stock?.warehouse?.id,
          hallId: stock?.batch?.hallId,
          inventoryDetailId:
            stock?.batch?.InventoryDetail?.id || stock?.batch?.id
        };
      });
      await API.post(`pallets/assignPalletToStock`, {
        palletStock
      });
      setDisabledBtn(true);
      navigate('/operations/palletizer');
      toaster('success', 'Stock Palletize successfully');
    } catch (err) {
      if (err?.response?.data?.error?.errors) {
        let errors = err?.response?.data?.error?.errors;
        errors?.map((data) => {
          if (data?.message?.includes('PalletStocks.unique_PalletId')) {
            err.response.data.message = 'Pallet ID must be unique';
            onError(err);
          } else {
            onError(data);
          }
        });
      } else {
        onError(err);
      }
    } finally {
      setDisable(false);
      setAPILoader(false);
    }
  };

  const setBatchesAndProducts = (newValue) => {
    formik.setFieldValue('product', newValue);
    formik.setFieldValue('UPP', newValue?.perPallet);
    getBatches(
      formik?.values?.warehouse?.id,
      formik?.values?.company?.id,
      newValue?.id
    );
  };

  const validatePalleteQty = (value) => {
    if (value > formik?.values?.availableQty) {
      formik.setFieldValue('palletQty', formik?.values?.availableQty);
    } else {
      formik.setFieldValue('palletQty', value);
    }
    checkPalletQtyByUPP(value, formik?.values?.UPP);
  };

  const checkPalletQtyByUPP = useCallback(
    debounce((value, UPP) => {
      if (Number(value) > Number(UPP)) {
        toaster('info', 'Pallet Quantity increased by UPP');
      }
    }, DEBOUNCE_CONST),
    []
  );

  const handleClose = () => {
    setScanning(false);
    setScanProducts(false);
    setScanPallets(false);
  };

  const settleBatch = (newValue) => {
    formik.setFieldValue('batch', newValue);
    formik.setFieldValue('MRP', newValue?.MRP);
    let availableQty = '';
    if (newValue?.InventoryDetail?.availableQuantity) {
      availableQty = newValue?.InventoryDetail?.availableQuantity;
    } else {
      availableQty = newValue?.availableQuantity;
    }
    let calAvailableQty = Number(availableQty);
    let reduceQty = '';
    if (palletizeStockList?.length) {
      reduceQty = palletizeStockList?.reduce((prev, currValue) => {
        if (
          currValue?.warehouse?.id === formik?.values?.warehouse?.id &&
          currValue?.company?.id === formik?.values?.company?.id &&
          currValue?.product?.id === formik?.values?.product?.id &&
          currValue?.batch?.id === newValue?.id
        ) {
          return (prev || Number(availableQty)) - Number(currValue?.palletQty);
        }
      }, 0);
    }
    formik.setFieldValue('availableQty', reduceQty || calAvailableQty);
  };

  const unsetBatchLevelDetails = () => {
    formik.setFieldValue('batch', {});
    formik.setFieldValue('MRP', '');
    formik.setFieldValue('UPP', '');
    formik.setFieldValue('availableQty', '');
  };

  const addStock = () => {
    const flag = validatePalletId();
    const palletizeStock = {
      pallet: formik?.values?.pallet,
      warehouse: formik?.values?.warehouse,
      order: formik?.values?.order,
      company: formik?.values?.company,
      product: formik?.values?.product,
      batch: formik?.values?.batch,
      palletQty: formik?.values?.palletQty,
      availableQty: formik?.values?.availableQty,
      UPP: formik?.values?.UPP,
      MRP: formik?.values?.MRP
    };
    if (currStockId && flag) {
      const index = palletizeStockList?.findIndex(
        (stock) => stock?.pallet?.palletId === currStockId
      );
      palletizeStockList[index] = palletizeStock;
      setCurrStockId('');
      resetStates();
    } else {
      if (flag) {
        setPalletizeStockList([...palletizeStockList, palletizeStock]);
        resetStates();
      }
    }
  };

  const getDelayedPallets = useCallback(
    debounce((search, formik) => {
      getPallets(search, formik);
    }, DEBOUNCE_CONST),
    []
  );

  const getPallets = async (search, formik) => {
    try {
      setAPILoader(true);
      const page = 1;
      const columns = [
        'palletId',
        'creationDate',
        '$Warehouse.businessWarehouseCode$'
      ];
      let colVal = {};
      if (search) {
        colVal.palletId = search;
      }
      if (formik?.warehouse?.businessWarehouseCode) {
        colVal['$Warehouse.businessWarehouseCode$'] =
          formik?.warehouse?.businessWarehouseCode;
      }
      const filters = { colVal };
      const pallets = await API.get(`pallets`, {
        params: { filters, columns, page, usedDate: true }
      });
      setPallets(pallets.data);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const validatePalletId = () => {
    const pallet = palletizeStockList?.find(
      (stock) =>
        stock?.pallet?.palletId === formik?.values?.pallet?.palletId &&
        stock?.pallet?.palletId !== currStockId
    );
    if (pallet?.pallet?.id) {
      toaster('warning', 'Pallet ID could not duplicate');
      return false;
    }
    return true;
  };

  const removePalletizeStock = (i) => {
    setPalletizeStockList(palletizeStockList?.filter((stock, j) => j !== i));
  };

  const editPalletizeStock = (stock) => {
    const palletizeStockObj = {
      order: stock?.order,
      warehouse: stock?.warehouse,
      company: stock?.company,
      product: stock?.product,
      batch: stock?.batch,
      MRP: stock?.MRP,
      UPP: stock?.UPP,
      availableQty: stock?.availableQty,
      palletQty: stock?.palletQty,
      pallet: stock?.pallet
    };
    getBatches(stock?.warehouse?.id, stock?.company?.id, stock?.product?.id);
    setInitVals({ ...palletizeStockObj });
    setCurrStockId(stock?.pallet?.palletId);
  };

  return (
    <Grid container className={classes.parentContainer} spacing={3}>
      {scanning ? (
        <ScanPallets
          formik={formik}
          open={scanning}
          scanProducts={scanProducts}
          warehouse={formik?.values?.warehouse?.id}
          validateProducts={(detail) => valProdAndBat(detail)}
          scanPallets={scanPallets}
          handleClose={() => handleClose()}
        />
      ) : (
        ''
      )}
      {confirmation ? (
        <Dialogue
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          submitHandler={() => fetchDetails(details?.data, details?.detail)}
          dialogueText="Are you sure you want to change batch details"
        />
      ) : (
        ''
      )}
      <Grid item container>
        <Grid item xs={7}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid item container>
              <Grid item xs={12} sm={12} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="orderId"
                    key="orderId"
                    options={[]}
                    getOptionLabel={(order) =>
                      order?.Order?.customId || order?.customId || ''
                    }
                    onKeyUp={(e) => setOrderSearch(e.target.value)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        GRNorders(newValue);
                        setOrderSearch('');
                      } else {
                        setOrderSearch('');
                        resetStates();
                      }
                    }}
                    value={formik?.values?.order || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Order ID (Optional)"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="warehouseId"
                    key="warehouses"
                    options={warehouses || []}
                    value={formik?.values?.warehouse || ''}
                    disabled={warehouses ? disabled : true}
                    onKeyUp={(e) => setWarehouseSearch(e.target.value)}
                    getOptionLabel={(warehouse) =>
                      warehouse?.businessWarehouseCode
                        ? warehouse?.businessWarehouseCode
                        : ''
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setCompanySearch('');
                        setWarehouseSearch('');
                        formik.setFieldValue('warehouse', newValue);
                        formik.setFieldValue('company', {});
                        formik.setFieldValue('product', {});
                        formik.setFieldValue('batch', {});
                        formik.setFieldValue('availableQty', '');
                        formik.setFieldValue('palletQty', '');
                        formik.setFieldValue('MRP', '');
                        formik.setFieldValue('UPP', '');
                        setBatches([]);
                        setProducts([]);
                      } else {
                        setCompanySearch('');
                        setWarehouseSearch('');
                        formik.setFieldValue('warehouse', {});
                        formik.setFieldValue('company', {});
                        formik.setFieldValue('product', {});
                        formik.setFieldValue('batch', {});
                        formik.setFieldValue('availableQty', '');
                        formik.setFieldValue('palletQty', '');
                        formik.setFieldValue('MRP', '');
                        formik.setFieldValue('UPP', '');
                        setBatches([]);
                        setProducts([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Warehouse"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                {formik?.errors?.warehouse && formik?.touched?.warehouse && (
                  <Typography color="error">
                    {formik.errors.warehouse?.id}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="companyId"
                    key="companyId"
                    value={formik?.values?.company || ''}
                    disabled={disabled}
                    options={companies}
                    getOptionLabel={(company) => company.name || ''}
                    onKeyUp={(e) => setCompanySearch(e.target.value)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setCompanySearch('');
                        formik.setFieldValue('company', newValue);
                      } else {
                        setCompanySearch('');
                        formik.setFieldValue('company', '');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                {formik?.errors?.company && formik?.touched?.company && (
                  <Typography color="error">
                    {formik.errors.company?.id}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={12} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="skuId"
                    key="skuId"
                    options={products}
                    getOptionLabel={(product) => productLabelFormat(product)}
                    onChange={(event, newValue) => {
                      unsetBatchLevelDetails();
                      if (newValue) {
                        setBatchesAndProducts(newValue);
                      } else {
                        setBatches([]);
                        formik.setFieldValue('product', {});
                      }
                    }}
                    value={formik?.values?.product || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="SKU Code & Name"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={ScannerIcon}
                                alt="Scanner Icon"
                                className={scanningMode}
                                onClick={() => {
                                  resetProductLevelInfo();
                                }}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
                {formik?.errors?.product && formik?.touched?.product && (
                  <Typography color="error">
                    {formik.errors.product?.id}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="batchId"
                    key="batchId"
                    options={batches || []}
                    getOptionLabel={(batch) =>
                      batch?.batchNumber && batch?.expiryDate
                        ? `${batch.batchNumber} - ${dividerDateFormatForFilter(
                            batch?.expiryDate
                          )}`
                        : ''
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        settleBatch(newValue);
                      } else {
                        unsetBatchLevelDetails();
                      }
                    }}
                    value={formik?.values?.batch || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Batch #/Exp. Date"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                {formik?.errors?.batch && formik?.touched?.batch && (
                  <Typography color="error">
                    {formik.errors.batch?.id}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    className="inputForm p-0 w-100"
                    variant="outlined"
                    id="MRP"
                    name="MRP"
                    disabled={true}
                    value={formik?.values?.MRP || ''}
                    label="MRP"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <DetailText text="Available Quantity" />
                <DetailText text={formik?.values?.availableQty} />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <DetailText text="UPP" />
                <DetailText text={formik?.values?.UPP} />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    className="inputForm p-0 w-100"
                    variant="outlined"
                    id="palletQty"
                    onChange={(e) => {
                      if (e.target.value) {
                        validatePalleteQty(e.target.value);
                      } else {
                        formik.setFieldValue('palletQty', '');
                      }
                    }}
                    value={formik?.values?.palletQty || ''}
                    label="Pallet Quantity"
                  />
                </FormControl>
                {formik?.errors?.palletQty && formik?.touched?.palletQty && (
                  <Typography color="error">
                    {formik.errors.palletQty}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className={classes.padding12}>
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="palletId"
                    key="palletId"
                    options={pallets}
                    getOptionLabel={(pallet) => pallet?.palletId || ''}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        formik.setFieldValue('pallet', newValue);
                      } else {
                        formik.setFieldValue('pallet', {});
                      }
                    }}
                    disabled={!Object.keys(formik?.values?.warehouse)?.length}
                    onKeyUp={(e) =>
                      getDelayedPallets(e.target.value, formik?.values)
                    }
                    value={formik?.values?.pallet || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pallet ID"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={ScannerIcon}
                                alt="Scanner Icon"
                                className={scanningMode}
                                onClick={() => {
                                  setScanPallets(true);
                                  setScanning(true);
                                }}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
                {formik?.errors?.pallet?.pallet?.id &&
                  formik?.touched?.pallet?.pallet?.id && (
                    <Typography color="error">
                      {formik.errors.pallet?.id}
                    </Typography>
                  )}
              </Grid>

              <Grid
                item
                className="mt-3"
                container
                xs={12}
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    className={classes.cancelButton}
                    onClick={() => resetStates()}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    ref={inputRef}
                    className={classes.createButton}
                    type="submit"
                    fullWidth
                  >
                    PALLETIZE
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={4} sm={12} lg={4}>
          <SectionHeading title="Serials" className="coming-soon-title" />

          <div className="comming-soon-box text-center">
            <h2>Coming Soon!</h2>
          </div>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={12}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Pallet ID</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>SKU Code-Description</TableCell>
                <TableCell>Batch No.</TableCell>
                <TableCell>Expiry. Date</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody key="index">
              {palletizeStockList?.map((palletizeStock, i) => {
                return (
                  <TableRow key={palletizeStock?.company?.id}>
                    <TableCell key={palletizeStock?.pallet?.palletId}>
                      {palletizeStock?.pallet?.palletId}
                    </TableCell>

                    <TableCell key={palletizeStock?.order?.id}>
                      {palletizeStock?.order?.id}
                    </TableCell>

                    <TableCell key={palletizeStock?.company?.name}>
                      {palletizeStock?.company?.name}
                    </TableCell>

                    <TableCell key={palletizeStock?.product?.description}>
                      {`${palletizeStock?.product?.name} - ${palletizeStock?.product?.description}`}
                    </TableCell>

                    <TableCell key={palletizeStock?.batch?.batchNumber}>
                      {palletizeStock?.batch?.batchNumber}
                    </TableCell>

                    <TableCell key={palletizeStock?.batch?.expiryDate}>
                      {dividerDateFormatForFilter(
                        palletizeStock?.batch?.expiryDate
                      )}
                    </TableCell>

                    <TableCell key={palletizeStock?.palletQty}>
                      {palletizeStock?.palletQty}
                    </TableCell>

                    <TableCell key={palletizeStock?.pallet?.id}>
                      <EditIcon
                        color="primary"
                        className="cursor-pointer"
                        onClick={() => editPalletizeStock(palletizeStock)}
                      />
                      <DeleteIcon
                        color="error"
                        onClick={() => removePalletizeStock(i)}
                        className="cursor-pointer"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12} className="mt-5">
          <button
            className={`submitBtns float-right ${
              (palletizeStockList?.length && !disabledBtn) || 'no-drop o-4'
            }`}
            disabled={disable}
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            FINALIZE
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

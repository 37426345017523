import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { Grid, Button, Tooltip } from '@material-ui/core';

import FormContext from './OrganizationFormContext';
import FormikInputField from '../../../atomicComponents/FormikInputField';
import CancelButton from '../../../components/CancelButton';
import { organizationBankDetailsValidationSchema } from './formikFields';
import useStyles from './OrganizationFormStyles';

const OrganizationBankDetailsForm = () => {
  const {
    organizationBankDetails,
    setOrganizationBankDetails,
    next,
    prev,
    handleClose,
    reset
  } = useContext(FormContext);

  const formik = useFormik({
    initialValues: organizationBankDetails,
    onSubmit: async () => {
      const errs = await formik.validateForm();
      if (Object.keys(errs).length === 0) {
        next();
      }
    },
    validationSchema: organizationBankDetailsValidationSchema
  });

  const classes = useStyles();

  useEffect(() => {
    setOrganizationBankDetails(formik.values);
  }, [formik.values]);

  return (
    <Grid container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="ntn"
              type="text"
              formik={formik}
              title="Tax ID"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="bankName"
              type="text"
              formik={formik}
              title="Bank Name"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="bankBranch"
              type="text"
              formik={formik}
              title="Bank Branch"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="accountTitle"
              type="text"
              formik={formik}
              title="Account Title"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="accountNumber"
              type="text"
              formik={formik}
              title="Account Number"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="iban"
              type="text"
              formik={formik}
              title="IBAN"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="swiftCode"
              type="text"
              formik={formik}
              title="Swift Code"
            />
          </Grid>
          <div className={classes.btnContainer}>
            <CancelButton
              handleClose={handleClose}
              setValidation={() => {}}
              resetStates={reset}
            />
            <Tooltip>
              <Button color="primary" variant="contained" onClick={prev}>
                Back
              </Button>
            </Tooltip>
            <Tooltip>
              <Button color="primary" variant="contained" type="submit">
                Next
              </Button>
            </Tooltip>
          </div>
        </Grid>
      </form>
    </Grid>
  );
};
export default OrganizationBankDetailsForm;

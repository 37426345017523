/*eslint-disable*/
import * as Yup from 'yup';

export const initialValues = {
  manufacturingDate: null,
  expiryDate: null,
  actualDispatchedQuantity: 0,
  recoverableDamageQuantity: 0,
  unsellableDamageQuantity: 0,
  promoName: '',
  promoQuantity: 0
};

export const validationSchema = Yup.object().shape({
  actualDispatchedQuantity: Yup.number()
    .required('Dispatched Qty is required')
    .min(0, 'Received Qty must be a positive number')
    .test(
      'actualDispatchedQuantity',
      'Dispatched Qty must be greater than sum of Recoverable and Unsellable ',
      function (value) {
        const {
          recoverableDamageQuantity,
          unsellableDamageQuantity,
          actualDispatchedQuantity
        } = this.parent;
        return (
          (recoverableDamageQuantity || 0) + (unsellableDamageQuantity || 0) <=
          actualDispatchedQuantity
        );
      }
    ),
  recoverableDamageQuantity: Yup.number().min(
    0,
    'Recoverable must be a positive number'
  ),
  unsellableDamageQuantity: Yup.number().min(
    0,
    'Unsellable must be a positive number'
  )
});

import React, { useEffect, useRef } from 'react';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { MdCancel } from 'react-icons/md';
import '../assets/css/style.css';

// export default function Filter({  open, handleClose, selectedEntity, title,columns }) {
export default function Filter(props) {
  let ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.onClickOutside && props.onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  if (!props.show) return null;

  return (
    <div ref={ref} style={{ display: 'inline' }}>
      <Tooltip title="Close Filter">
        <button
          onClick={props.handleClose}
          type="button"
          className="closeModalBtn"
        >
          <MdCancel />
        </button>
      </Tooltip>
      <Grid container>
        <div className="filterHeader">
          <Typography>Columns</Typography>
          <Typography>Value</Typography>
        </div>
        {props?.columnsNew?.map((column, idx) => {
          return (
            <div key={idx}>
              <div className="filterBody">
                <span>
                  <b>{column.label}</b>
                </span>
                <span>
                  <input
                    type={column.type}
                    placeholder="Filter value"
                    value={column.value}
                    className={column.className}
                    name={column.name}
                    onChange={(e) => column.setNameVal(e.target.value)}
                  />
                </span>
              </div>
              <hr className="lineBreak" />
            </div>
          );
        })}
      </Grid>
    </div>
  );
}

/* eslint-disable no-unused-vars */
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ArrowForward } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState, useContext } from 'react';
import * as Yup from 'yup';
import API from '../../../libs/axios';
import { REPORTS } from '../../../utils/enums/report';
import AddUsersForm from './AddUsersForm';
import UsersTable from './UsersTable';
import { SharedContext } from '../../../utils/common';
import { ORGANIZATION_TYPES } from '../../../constants';

const Notify = styled(Switch)(({ theme }) => ({
  padding: 8,
  '&$checked': {
    color: '#46c6ef'
  },
  '&$checked + $track': {
    backgroundColor: '#46c6ef'
  },

  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },

    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

const valSchema = Yup.object().shape({
  companyId: Yup.number().required('Please select a company'),
  notify: Yup.bool(),
  reportId: Yup.number().required('Please select report type'),
  config: Yup.array().of(
    Yup.object().shape({
      userEmail: Yup.string()
        .email('Invalid Email Address')
        .test('Validate Email', 'Invalid Email Address', (value) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(value).toLowerCase());
        })
    })
  ),
  emailFrequency: Yup.string().required('Please select email frequency'),
  emailTime: Yup.string().required('Please select email time')
});

const valSchemaManufacturer = Yup.object().shape({
  notify: Yup.bool(),
  reportId: Yup.number().required('Please select report type'),
  config: Yup.array().of(
    Yup.object().shape({
      userEmail: Yup.string()
        .email('Invalid Email Address')
        .test('Validate Email', 'Invalid Email Address', (value) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(value).toLowerCase());
        })
    })
  ),
  emailFrequency: Yup.string().required('Please select email frequency'),
  emailTime: Yup.string().required('Please select email time')
});

const AddNotificationUsersForm = (props) => {
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [disabled, setDisabled] = useState(false);
  const { organizationType } = useContext(SharedContext);

  const {
    notificationSubscription,
    notificationState: { notify, setNotify },
    searchState: { setSearchText },
    defaultUserState: { defaultUser },
    usersState: { setUsers },
    userEmailsState: { userEmails, setUserEmails },
    addUsersHandler,
    notificationUsersId,
    usersTableProps: { resUsers, deleteAction, classes }
  } = props;

  const [reports, setReports] = useState([{ name: '' }]);
  const [flag, setFlag] = useState(true);
  const [singleCompany, setSingleCompany] = useState(null);
  const emailFrequencies = [
    { type: 'Daily' },
    { type: 'Weekly' },
    { type: 'Monthly' }
  ];

  let customerReports = Object.keys(REPORTS).map(function (type) {
    return { id: type, name: REPORTS[type] };
  });

  const formik = useFormik({
    initialValues: notificationSubscription,
    validationSchema:
      organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? valSchemaManufacturer
        : valSchema,
    enableReinitialize: true,
    onSubmit: (values) => addUsersHandler(values)
  });

  useEffect(() => {
    if (formik?.values?.reportId && formik?.values?.reportId == 1) {
      formik.values.emailTime = '03:00:00';
      formik.values.emailFrequency = 'Daily';
      setDisabled(true);
    }
  }, [formik.values.reportId]);

  useEffect(() => {
    setReports(customerReports);
    if (notificationSubscription?.companyId)
      getCompany(notificationSubscription.companyId);
  }, [notificationSubscription]);

  useEffect(() => {
    getCompanies(searchCompanyValue);
  }, [searchCompanyValue]);

  const getCompany = (id) => {
    API.get(`companies/${id}`).then((res) => {
      if (res.company) {
        setCompany(res.company);
        setSingleCompany(res.company);
        notificationSubscription.company = res.company;
      }
    });
  };

  const getCompanies = (searchCompanyValue) => {
    let filters = {
      colVal: {
        name: searchCompanyValue,
        isActive: 1
      },
      sortable: ''
    };

    API.get('companies', {
      params: { filters }
    }).then((res) => {
      if (res.data) {
        setCompanies(res.data.rows);
        !notificationUsersId &&
          !searchCompanyValue &&
          res.data.rows?.length === 1 &&
          setSingleCompany(res.data.rows[0]);
      }
    });
  };
  return (
    <Grid item xs={12}>
      <form>
        <Grid container spacing={2}>
          {organizationType ===
            ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER && (
            <Grid item xs={6}>
              <FormControl fullWidth={true} variant="outlined">
                {((notificationUsersId && company && company.id) ||
                  !notificationUsersId) && (
                  <Autocomplete
                    id="company"
                    key="companyId"
                    className="notificationField"
                    defaultValue={
                      company
                        ? {
                            name: company.name,
                            id: company.id
                          }
                        : {}
                    }
                    value={singleCompany}
                    options={companies}
                    getOptionLabel={(company) => company.name || ''}
                    onChange={(e, val) => {
                      formik?.setFieldValue(
                        'companyId',
                        val !== null ? val.id : null
                      );
                      setSingleCompany(val !== null ? val : null);
                    }}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    onInputChange={(event, newValue) => {
                      setSearchCompanyValue(newValue);
                    }}
                    onFocus={() => {
                      setSearchCompanyValue('');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company"
                        variant="outlined"
                        required
                      />
                    )}
                    onBlur={formik?.handleBlur}
                  />
                )}
                {formik?.errors?.companyId && formik?.touched?.companyId ? (
                  <div>
                    <Typography color="error">
                      {formik?.errors?.companyId}
                    </Typography>
                  </div>
                ) : null}
              </FormControl>
            </Grid>
          )}
          <Grid
            item
            xs={organizationType === ORGANIZATION_TYPES.MANUFACTURER ? 12 : 6}
          >
            <FormControl fullWidth={true} variant="outlined">
              <Autocomplete
                id="reportId"
                className="notificationField"
                value={{
                  name:
                    reports?.find((el) => el.id == formik?.values?.reportId)
                      ?.name || ''
                }}
                options={reports}
                getOptionLabel={(report) => report.name || ''}
                onBlur={formik?.handleBlur}
                onChange={(e, val) => {
                  formik?.setFieldValue(
                    'reportId',
                    val !== null
                      ? parseInt(val.id)
                      : notificationSubscription.reportId
                  );
                  setFlag(val && val.id == 1 ? true : false);
                }}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField {...params} label="Report" variant="outlined" />
                )}
              ></Autocomplete>
              {formik?.errors?.reportId && formik?.touched?.reportId ? (
                <div>
                  <Typography color="error">
                    {formik?.errors?.reportId}
                  </Typography>
                </div>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth={true} variant="outlined">
              <Autocomplete
                id="emailFrequency"
                key={emailFrequencies}
                options={emailFrequencies}
                value={{
                  type: formik?.values?.emailFrequency
                }}
                className="notificationField"
                disabled={disabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Email Frequency"
                    variant="outlined"
                  />
                )}
                getOptionLabel={(frequency) => frequency.type}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                onBlur={formik?.handleBlur}
                onChange={(e, val) =>
                  formik?.setFieldValue(
                    'emailFrequency',
                    val !== null
                      ? val.type
                      : notificationSubscription.emailFrequency
                  )
                }
              />
              {formik?.errors?.emailFrequency &&
              formik?.touched?.emailFrequency ? (
                <div>
                  <Typography color="error">
                    {formik?.errors?.emailFrequency}
                  </Typography>
                </div>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth={true} variant="outlined">
              <TextField
                fullWidth={true}
                id="emailTime"
                label="Email Time"
                InputLabelProps={{
                  shrink: true
                }}
                className="notificationField"
                placeholder="Email Time"
                type="time"
                variant="outlined"
                value={formik?.values?.emailTime}
                onBlur={formik?.handleBlur}
                disabled={disabled}
                onChange={(e) =>
                  formik?.setFieldValue(
                    'emailTime',
                    e.target.value !== null
                      ? e.target.value
                      : notificationSubscription.emailTime
                  )
                }
              />
              {formik?.errors?.emailTime && formik?.touched?.emailTime ? (
                <div>
                  <Typography color="error">
                    {formik?.errors?.emailTime}
                  </Typography>
                </div>
              ) : null}
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Grid container className="textAreaForm">
              <Grid item xs={12} className="formTitle">
                <Typography>Add User Emails</Typography>
              </Grid>

              <Grid item xs={11} className="parentTextArea">
                <AddUsersForm
                  defaultUser={defaultUser}
                  setFieldValue={formik.setFieldValue}
                  handleBlur={formik.handleBlur}
                  setUsers={setUsers}
                  value={formik?.values?.config}
                  validation={{
                    errors: formik?.errors?.config,
                    touched: formik?.touched?.config
                  }}
                  setUserEmails={setUserEmails}
                  userEmails={userEmails}
                />
              </Grid>

              <Grid item container className="formFooter">
                <Grid item xs={6}>
                  <FormGroup className="switch">
                    <FormControlLabel
                      control={<Notify checked={formik?.values?.notify} />}
                      value={formik?.values?.notify}
                      label="Notify"
                      onChange={(event, newValue) => {
                        formik?.setFieldValue('notify', newValue);
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} className="text-right">
                  <Tooltip
                    title={
                      notificationUsersId
                        ? 'Update User Email'
                        : 'Submit User Email'
                    }
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      disabled={formik?.isSubmitting}
                      className="notificationBtn"
                      onClick={formik.handleSubmit}
                    >
                      {notificationUsersId ? 'Update' : 'Submit'}
                      &emsp;
                      <ArrowForward fontSize="small" />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <UsersTable
              resUsers={resUsers}
              classes={classes}
              deleteAction={deleteAction}
            />
          </Grid>
          <Grid></Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddNotificationUsersForm;

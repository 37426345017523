/*eslint-disable*/
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  SvgIcon,
  StepConnector
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { stepConnectorClasses } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  stepConnector: {
    marginBottom: theme.spacing(1)
  },
  dot: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
  },
  incompleteDot: {
    backgroundColor: '#fff',
    border: '1px solid #ccc'
  }
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    //   top: 10
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeftColor: '#0B1940',
      borderLeftStyle: 'solid', // Adjust the margin based on your preference
      borderLeftWidth: '1px',
      minHeight: '40px'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderLeftColor: '#6A7289',
    borderLeftWidth: '1px',
    borderLeftStyle: 'dashed',
    height: '1px',
    position: 'relative',
    top: 11,
    marginLeft: '-8px',
    minHeight: '40px'
  }
}));

const CustomStepper = () => {
  const classes = useStyles();
  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];

  return (
    <div>
      <Stepper
        activeStep={2} // Set the active step based on your current step
        orientation="vertical"
        className={classes.stepper}
        connector={<CustomConnector className={classes.stepConnector} />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={({ completed }) => (
                <div
                  className={`${classes.dot} ${
                    completed ? '' : classes.incompleteDot
                  }`}
                ></div>
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomStepper;

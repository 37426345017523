/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../core-components/atoms/Button';
import FixedLayout from '../../../core-components/molecules/FixedLayout';
import { Modal } from '@mui/material';
import { CloseOutlined } from '@material-ui/icons';

import { useFormik } from 'formik';
import CustomerForm from '../AddCustomerForm';
import { companyTypes } from '../constants';
import { useNavigate, useParams } from 'react-router';
import { SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import { customerSchema } from '../validationSchemas/customerSchema';
import { convertToTitleCase } from '../../revampedOrder/AddOrder';

export const nodeInitialValues = {
  name: '',
  nodeType: {},
  nodeClass: 'EXTERNAL',
  adhocFlag: true,
  isActive: true,
  description: '',
  pocContact: '',
  pocName: '',
  latLng: {
    lat: '',
    lng: ''
  },
  code: '',
  capacity: 0,
  pallets: 0,
  companyId: null,
  cityId: null,
  IMS: true,
  WMS: true,
  savedNodes: [],
  entity: {}
};

export const getSuffix = (n) => {
  if (n == 2) {
    return 'nd';
  } else if (n == 3) {
    return 'rd';
  } else return 'th';
};

function ViewCustomer({ edit }) {
  const [customer, setCustomer] = useState({});

  const { id } = useParams();

  const customerFormik = useFormik({
    initialValues: {
      supportingDocuments: [],
      name: '',
      code: '',
      type: '',
      ntn: '',
      categoryName: null,
      category: {},
      paymentTerm: {},
      strn: '',
      pocName: '',
      pocId: '',
      pocEmail: '',
      pocContact: '',
      additionalInfo: '',
      paymentTermTitle: '',
      creditLimit: null,
      shippingAddress: '',
      billingAddress: '',
      sameAsBilling: false,
      email: '',
      isSupplier: false
    },
    validationSchema: customerSchema,
    onSubmit: async (values) => {},
    validateOnMount: true
  });

  const getCompany = async (id) => {
    try {
      const company = await API.get(`companies/${id}`);
      setCustomer(company.company);
      customerFormik.setValues({ ...company.company });
      customerFormik.setFieldValue('customerType', {
        label: convertToTitleCase(company.company.type),
        value: company.company.type
      });
      customerFormik.setFieldValue(
        'category',
        company.company.CustomerCategory
      );
      customerFormik.setFieldValue('paymentTerm', company.company.PaymentTerm);
      if (company.company.billingAddress == company.company.shippingAddress) {
        customerFormik.setFieldValue('sameAsBilling', true);
      }
      if (company.company.supportingDocuments?.length > 0) {
        customerFormik.setFieldValue(
          'supportingDocuments',
          company.company.files
        );
      } else {
        customerFormik.setFieldValue('supportingDocuments', []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { organizationType } = useContext(SharedContext);

  const orgCompanyTypes = organizationType
    ? organizationType == 'THIRD_PARTY_SERVICE_PROVIDER'
      ? Object.keys(companyTypes['3PL']).map((key) => ({
          value: key,
          label: companyTypes['3PL'][key]
        }))
      : Object.keys(companyTypes.MANUFACTURER).map((key) => ({
          value: key,
          label: companyTypes.MANUFACTURER[key]
        }))
    : [];

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getCompany(id);
    }
  }, [id]);

  const Header = (
    <div className="flex justify-between w-full items-center">
      <div className="flex gap-2">
        <p className="font-bold text-2xl">{`View ${
          customer
            ? customer.relationType == 'SUPPLIER'
              ? 'Supplier'
              : 'Customer'
            : ''
        }`}</p>
      </div>
      <div>
        <CloseOutlined
          onClick={() => {
            navigate('/administration/supply-chain-network');
          }}
          className="cursor-pointer"
        />
      </div>
    </div>
  );

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <form>
          <FixedLayout
            header={Header}
            content={
              <div className="m-auto w-[60%] mt-10">
                <CustomerForm
                  formik={customerFormik}
                  edit={false}
                  customerTypes={orgCompanyTypes}
                />
              </div>
            }
            footer={
              <div className="flex justify-between w-full">
                <Button
                  label="Back"
                  variant="tertiary"
                  onClick={() =>
                    navigate('/administration/supply-chain-network')
                  }
                />
              </div>
            }
          />
        </form>
      </div>
    </Modal>
  );
}

export default ViewCustomer;

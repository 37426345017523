import React, { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../../Config';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { Box, Grid } from '@mui/material';
import addIcon from '../../../../assets/icons/addCard.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import AisleListComponent from '../components/AisleListComponent';
import { makeStyles, Tooltip } from '@material-ui/core';
import AddAislesAndBaysView from '../components/AddAislesAndBaysView';
import { SharedContext } from '../../../../utils/common';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

const AislesView = ({ warehouse, searchValue, aisles, setAisles }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const toolTipClasses = useStylesBootstrap();
  const [open, setOpen] = React.useState(null);
  const [selectedAisle, setSelectedAisle] = useState();
  const { setAPILoader } = useContext(SharedContext);

  const getWarehouseAisles = async (page, searchValue, oldData = []) => {
    setAPILoader(true);
    try {
      const cols = ['name'];
      const res = await API.get(`warehouses/${warehouse?.id}/aisles`, {
        params: {
          page: page,
          limit: limit,
          search: searchValue,
          columns: cols
        }
      });
      const newArr = res?.data;
      setAisles([...oldData, ...newArr]);
      setPageCount(res?.pages);
      setAPILoader(false);
    } catch (error) {
      onError(error);
      setAPILoader(false);
    }
  };

  const getDelayedWarehouseAisles = useCallback(
    debounce(
      async (page, searchValue, oldData) => {
        getWarehouseAisles(page, searchValue, oldData);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedWarehouseAisles(page, searchValue, []);
  }, [searchValue]);

  const handleClick = (event) => {
    setOpen(open ? null : event.currentTarget);
  };

  return searchValue && aisles.length <= 0 ? (
    <></>
  ) : (
    <Grid
      item
      flex={1}
      className="bg-dark200 h-full rounded"
      style={{ boxSizing: 'border-box' }}
    >
      <Box>
        <AddAislesAndBaysView
          type="Aisle"
          route="aisles"
          handleClose={() => {
            setOpen(null);
          }}
          aisleOrBays={selectedAisle}
          warehouseId={warehouse?.id}
          anchorEl={open}
          getWarehouseAislesOrBays={getWarehouseAisles}
        />
        <div className="mx-4 mt-4 mb-2 items-center">
          <div className="flex justify-between">
            <p>AISLES</p>
            {isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
              <div className="flex">
                <Tooltip
                  arrow
                  title="Add Aisles"
                  aria-label="add"
                  placement="top"
                  classes={toolTipClasses}
                >
                  <button
                    className="flex"
                    onClick={(event) => {
                      setSelectedAisle();
                      handleClick(event);
                    }}
                  >
                    <img src={addIcon} alt="add-icon" />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div
          id={'scrollableDiv'}
          className="items-center overflow-auto h-[60vh] no-scrollbar"
        >
          <InfiniteScroll
            dataLength={aisles?.length} //This is important field to render the next data
            next={() => {
              setPage(page + 1);
              getDelayedWarehouseAisles(page + 1, searchValue, aisles);
            }}
            hasMore={page < pageCount}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>Loading</b>
              </p>
            }
            refreshFunction={() => {
              setPage(1);
              setAisles([]);
              getDelayedWarehouseAisles(1, searchValue, []);
            }}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8595; Pull down to refresh
              </h3>
            }
            scrollableTarget="scrollableDiv"
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {aisles?.length > 0 &&
              aisles.map((aisle, id) => {
                return (
                  <AisleListComponent
                    warehouse={warehouse}
                    data={aisle}
                    key={id}
                    searchValue={searchValue}
                    getWarehouseAisles={getWarehouseAisles}
                    onClick={(event) => {
                      setSelectedAisle(aisle);
                      if (isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)) {
                        handleClick(event);
                      }
                    }}
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </Box>
    </Grid>
  );
};

export default AislesView;

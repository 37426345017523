/*eslint-disable*/
import React from 'react';
import { Grid } from '@mui/material';
import PrintStyles from './PrintStyles';
import { decimalDisplay } from '../../../utils/common';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const BasicDetail = ({
  productDetails,
  isManufacturer,
  startDate,
  endDate
}) => {
  const useStyles = makeStyles((theme) => ({
    basicDetailsContainer: {
      [theme.breakpoints.down('xs')]: {
        '& > .MuiGrid-item': {
          flexBasis: '20%',
          maxWidth: '20%'
        }
      }
    }
  }));
  const classes = { ...PrintStyles(), ...useStyles() };

  const infoFields = [
    {
      id: 'openingStock',
      label: 'OPENING STOCK',
      format: (value) => (value ? value : '-')
    },
    ...(isManufacturer
      ? [
          {
            id: 'purchaseQuantity',
            label: 'PURCHASES',
            format: (value) => (value ? value : '-')
          }
        ]
      : []),
    ...(isManufacturer
      ? [
          {
            id: 'salesQuantity',
            label: 'SALES',
            format: (value) => (value ? value : '-')
          }
        ]
      : []),
    ...(isManufacturer
      ? [
          {
            id: 'salesReturnQuantity',
            label: 'SALES',
            format: (value) => (value ? value : '-')
          }
        ]
      : []),
    ...(!isManufacturer
      ? [
          {
            id: 'inboundQuantity',
            label: 'INBOUND',
            format: (value) => (value ? value : '-')
          }
        ]
      : []),
    ...(!isManufacturer
      ? [
          {
            id: 'outboundQuantity',
            label: 'OUTBOUND',
            format: (value) => (value ? value : '-')
          }
        ]
      : []),
    {
      id: 'transferQty',
      label: 'TRANSFER',
      format: (value) => (value ? value : '-')
    },

    {
      id: 'adjustments',
      label: 'ADJUSTMENTS',
      format: (value) => (value ? value : '-')
    },
    {
      id: 'gdnReversalQuantity',
      label: 'GDN REVERSAL',
      format: (value) => (value ? value : '-')
    },
    {
      id: 'grnReversalQuantity',
      label: 'GRN REVERSAL',
      format: (value) => (value ? value : '-')
    },
    {
      id: 'closingStock',
      label: 'CLOSING STOCK',
      format: (value) => (value ? value : '-')
    },
    {
      id: 'activityDate',
      label: `REPORT PERIOD`,
      format: () => ''
    }
  ];

  return (
    <Grid
      container
      style={{ maxWidth: '100%', marginLeft: '-10px' }}
      spacing={1}
      className={classes.basicDetailsContainer}
    >
      {infoFields.map(({ id, label, format }) => (
        <Grid item md={3} xs={3} key={id} space={1} className="px-1">
          <Grid item xs={12} className={classes.label}>
            {`${label}:`}
          </Grid>
          <Grid item xs={12} className={classes.basicFieldValue}>
            {id === 'activityDate'
              ? `${startDate ? moment(startDate).format('DD/MM/YYYY') : ''} - ${
                  endDate ? moment(endDate).format('DD/MM/YYYY') : ''
                }`
              : id === 'transferQty'
              ? format
                ? decimalDisplay(
                    productDetails?.addTransferQuantity -
                      productDetails?.subtractTransferQuantity
                  )
                : '-'
              : format
              ? decimalDisplay(format(productDetails?.[id]))
              : '-'}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default BasicDetail;

import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import delIcon from '../../../assets/icons/del.svg';
import { useContext } from 'react';
import { SharedContext } from '../../../utils/common';
import { checkPermission } from '../../../utils/auth';

export default function InvoiceTitles({ formik, edit }) {
  const useStyles = makeStyles(() => ({
    deleteIcon: {
      cursor: 'pointer'
    },
    row: {
      borderBottom: '1px dashed #C4C6CB'
    },
    addBtn: {
      textTransform: 'none',
      color: '#0C71D7',
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      marginTop: 20
    }
  }));

  const { currentUser } = useContext(SharedContext);

  const classes = useStyles();

  const disabled = !edit || !checkPermission(currentUser, 'OPS_TITLES_UPDATE');

  return (
    <Grid item container className="mt-10">
      <Grid item xs={12}>
        <DetailPageTitle text="Invoicing Titles" />
      </Grid>
      <Grid container className="mt-10">
        {formik.values.titles?.length > 0 &&
          formik.values.titles.map(
            (title, id) =>
              !title.isDeleted && (
                <Grid key={id} item container spacing={2} className="mt-5">
                  <Grid item xs={2} className="mt-5">
                    <Typography>Title {id + 1}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      value={title.name}
                      disabled={disabled}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `titles.${id}.name`,
                          e.target.value
                        );
                      }}
                      variant="outlined"
                    />
                    {formik.errors.titles?.[id] && (
                      <Typography color="error">
                        {formik.errors.titles?.[id].name}
                      </Typography>
                    )}
                  </Grid>
                  {edit &&
                    checkPermission(currentUser, 'OPS_TITLES_DELETE') && (
                      <>
                        <Grid item xs={2} className="mt-5">
                          <img
                            src={delIcon}
                            onClick={() => {
                              if (title.isNew) {
                                let titles = [...formik.values.titles];
                                titles = titles.filter((t, i) => i != id);
                                formik.setFieldValue('titles', titles);
                              } else {
                                formik.setFieldValue(`titles.${id}`, {
                                  ...formik.values.titles[id],
                                  isDeleted: true
                                });
                              }
                            }}
                            className={classes.deleteIcon}
                          />
                        </Grid>
                      </>
                    )}

                  <Grid item xs={2} />
                  <Grid item xs={6}>
                    <hr className="dashedLine" />
                  </Grid>
                </Grid>
              )
          )}
      </Grid>
      {edit && checkPermission(currentUser, 'OPS_TITLES_CREATE') && (
        <Button
          color="primary"
          className={classes.addBtn}
          onClick={() => {
            formik.setFieldValue('titles', [
              ...formik.values.titles,
              { name: '', isNew: true }
            ]);
          }}
        >
          + Add new title
        </Button>
      )}
    </Grid>
  );
}

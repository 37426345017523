import { Grid, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import CustomerIcon from '../../../assets/icons/account-outline.svg';
import TypeIcon from '../../../assets/icons/button-pointer.svg';
import ContractIcon from '../../../assets/icons/file-sign.svg';
import StatusIcon from '../../../assets/icons/list-status.svg';
import WarehouseIcon from '../../../assets/icons/warehouseFilter.svg';
import API from '../../../libs/axios';
import { SharedContext, exportToCSV } from '../../../utils/common';
import { InvoiceFiltersInitialValue } from '../expenseManagement/ExpenseManagement';
import CustomPagination from './CustomPagination';
import NestedMenu from './FiltersMenu';
import InvoiceTable from './InvoiceTable';
import { constants } from './constants';
import { onError } from '../../../libs/errorLib';

export default function InvoicesTab({
  tab,
  billing,
  filterStateInvoices,
  setFilterStateInvoices,
  getStats
}) {
  const useStyles = makeStyles(() => ({
    container: {
      width: '95%',
      margin: 'auto'
    },
    borderBottom: {
      borderBottom: '1px solid #dcdcdc',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '1.5rem'
    },
    tabsContainer: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#0C71D7',
        height: '3px'
      },
      '& .MuiTabs-root': {
        minHeight: '100%'
      }
    },
    addIcon: {
      fontSize: '1.5rem',
      marginRight: '4px'
    },
    invoiceBtn: {
      marginBottom: '5px'
    },
    active: {
      color: '#0C71D7'
    },
    filters: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    filterBox: {
      background: '#DFF1FF',
      padding: '8px 6px 8px 12px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      '& svg': {
        width: '1rem',
        height: '1rem',
        color: '#77A1CC'
      },
      borderRadius: '6px'
    },
    filterContainer: {
      display: 'flex',
      gap: '0.5rem'
    },
    halfCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: 'linear-gradient( 90deg, white, white 50%, #1AAE2E 50%)',
      border: '2px solid #1AAE2E'
    },
    yellowCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: '#FFC000'
    },
    greenCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: '#1AAE2E'
    },
    redCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: '#FF3B3B'
    },
    resetFilter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderLeft: '1px solid #D9D9D9',
      padding: '0 8px',
      color: '#0C71D7',
      cursor: 'pointer',
      marginLeft: '8px'
    },
    title: {
      fontSize: '20px',
      fontWeight: '600'
    }
  }));
  const classes = useStyles();

  const [invoices, setInvoices] = useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(filterStateInvoices);
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [order, setOrder] = useState('desc');

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { setAPILoader } = useContext(SharedContext);

  const getInvoices = async () => {
    try {
      setAPILoader(true);
      if (filterLength) {
        const res = await API.get(`invoices`, {
          params: {
            billing,
            filters,
            page,
            order: order.toUpperCase(),
            orderBy
          }
        });
        setInvoices(res);
        setPage(1);
      } else {
        const res = await API.get(`invoices`, {
          params: { billing, page, order, orderBy }
        });
        setInvoices(res);
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const getCompanies = async (search) => {
    try {
      let companyData;
      if (!billing) {
        if (search) {
          companyData = await API.get(`companies`, { params: { search } });
        } else {
          companyData = await API.get(`companies`, {});
        }
      } else {
        if (search) {
          companyData = await API.get(`companies`, {
            params: { type: 'VENDOR', search }
          });
        } else {
          companyData = await API.get(`companies`, {
            params: { type: 'VENDOR' }
          });
        }
      }
      setCompanies(companyData?.data?.rows);
    } catch (err) {
      onError(err);
    }
  };

  const getWarehouses = async (search) => {
    try {
      let warehouseData;
      if (search) {
        warehouseData = await API.get(`warehouses`, { params: { search } });
      } else {
        warehouseData = await API.get(`warehouses`);
      }

      setWarehouses(warehouseData?.data);
    } catch (err) {
      onError(err);
    }
  };

  const statusIcons = {
    PENDING: classes.yellowCircle,
    PAID: classes.greenCircle,
    PARTIALLY_PAID: classes.halfCircle,
    OVERDUE: classes.redCircle
  };

  let filterOptions = [
    {
      label: 'Status',
      options: Object.keys(constants.STATUSES)
        .filter((key) => key != 'CANCELLED')
        .map((key, id) => ({
          value: key,
          label: constants.STATUSES[key],
          id,
          icon: statusIcons[key]
        })),
      icon: StatusIcon
    },
    {
      label: 'Type',
      options: Object.keys(constants.TYPES).map((key, id) => ({
        value: key,
        label: constants.TYPES[key],
        id
      })),
      icon: TypeIcon
    },
    {
      label: billing ? 'Vendor' : 'Customer',
      options: companies?.map((company, id) => ({
        value: company.id,
        label: company.name,
        id
      })),
      icon: CustomerIcon
    },
    {
      label: 'Contract',
      options: Object.keys(constants.CONTRACT_TYPES).map((key, id) => ({
        value: key,
        label: constants.CONTRACT_TYPES[key],
        id
      })),
      icon: ContractIcon
    },
    {
      label: 'Warehouse',
      options: warehouses?.map((warehouse, id) => ({
        value: warehouse.id,
        label:
          warehouse.businessWarehouseCode || warehouse.name
            ? `${warehouse.name} ${
                warehouse?.businessWarehouseCode
                  ? `- ${warehouse.businessWarehouseCode}`
                  : ''
              }`
            : '',
        id
      })),
      icon: WarehouseIcon
    }
  ];

  const setFilter = (key, value, type) => {
    let currentFilters = { ...filters };
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    setFilters(currentFilters);
    setFilterStateInvoices(currentFilters);
  };

  const reset = () => {
    setFilters(InvoiceFiltersInitialValue);
    setFilterStateInvoices(InvoiceFiltersInitialValue);
  };

  let filterLength = Object.keys(filters).reduce(
    (a, b) => filters[b].length + a,
    0
  );

  const exportToExcel = () => {
    API.get(`invoices/export`, {
      params: {
        filterStateInvoices,
        billing
      }
    }).then((response) => {
      const invoices = response?.invoices.map((data) => {
        const {
          createdAt,
          Company,
          type,
          contractType,
          dueDate,
          total,
          paidAmount,
          Warehouse,
          status
        } = data;

        return {
          createdAt: createdAt
            ? moment(createdAt).format('DD/MM/yy HH:mm')
            : '-',
          company: Company.name,
          warehouse: Warehouse?.name || '-',
          type,
          contractType,
          dueDate,
          total: total,
          paidAmount: paidAmount,
          status
        };
      });
      let title = `Invoices ${moment().format('DD-MM-yyyy')}`;
      const header = [
        'Date of Invoice',
        billing ? 'Vendor' : 'Customer',
        'Warehouse',
        'Invoice Type',
        'Contract Type',
        'Due Date',
        'Total Amount',
        billing ? 'Paid Amount' : 'Received Amount',
        'Status'
      ];
      exportToCSV(header, invoices, title);
    });
  };

  useEffect(() => {
    getCompanies();
    getWarehouses();
  }, []);

  useEffect(() => {
    getInvoices();
  }, [filters, page, order, orderBy]);

  return (
    <Grid item container className="mt-10">
      <Grid item xs={12}>
        <div className={classes.filters}>
          <Typography className={classes.title}>
            {billing ? 'All Bills' : 'All Invoices'}
          </Typography>
          <NestedMenu
            options={filterOptions}
            getCompanies={(search) => getCompanies(search)}
            getWarehouses={(search) => getWarehouses(search)}
            setFilter={setFilter}
            filters={filters}
            number={filterLength}
          />
        </div>
        {filterLength > 0 && (
          <div className={classes.filterContainer}>
            {Object.keys(filters).map((key) => {
              return filters[key].map((filter) => (
                <div key={filter.label} className={classes.filterBox}>
                  <span>{filter.label}</span>
                  <CloseIcon
                    fontSize="small"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setFilter(key, filter)}
                  />
                </div>
              ));
            })}
            <div className={classes.resetFilter} onClick={reset}>
              <Typography>Clear All</Typography>
            </div>
          </div>
        )}
      </Grid>

      <InvoiceTable
        invoices={invoices?.invoices || []}
        getInvoices={getInvoices}
        tab={tab}
        type={billing}
        order={order}
        orderBy={orderBy}
        handleSort={handleSort}
        getStats={getStats}
        url={''}
        exportInvoices={exportToExcel}
      />

      <CustomPagination page={page} setPage={setPage} pages={invoices.pages} />
    </Grid>
  );
}

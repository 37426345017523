/*eslint-disable*/
import Popup from '../../../../core-components/atoms/Popup';
import Button from '../../../../core-components/atoms/Button';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { SharedContext } from '../../../../utils/common';
import VehicleTypeForm from './vehicleTypeForm';

const AddVehicleTypePopup = ({
  open,
  setOpen,
  organizationType,
  selectedVehicleType,
  setSelectedVehicleType,
  refetch
}) => {
  const { setAPILoader } = useContext(SharedContext);

  const addSubmitHandler = async (values) => {
    const payload = {
      makeId: values.makeId,
      modelId: values.modelId,
      weight: values.weight,
      volume: values.volume,
      categoryId: values.categoryId,
      subCategoryId: values.subCategoryId,
      fuelType: values.fuelType,
      payloadWeight: values.payloadWeight,
      horsepower: values.horsepower,
      isActive: values.isActive,
      name: values.name
    };

    try {
      setAPILoader(true);
      if (!selectedVehicleType) await API.post(`vehicles/types`, payload);
      else await API.put(`vehicles/types/${selectedVehicleType.id}`, payload);
      toaster(
        'success',
        !selectedVehicleType
          ? 'Vehicle Type has been created.'
          : 'Vehicle Type updated successfully.'
      );
      await refetch();
      setOpen(false);
      formik.resetForm();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const handleClose = async () => {
    formik.resetForm();
    setOpen(false);
    setSelectedVehicleType && setSelectedVehicleType(null);
  };

  let valSchema;
  if (organizationType === 'MANUFACTURER') {
    valSchema = Yup.object().shape({
      uomId: Yup.string().required('UoM is required'),
      description: Yup.string().required('Name is Required')
    });
  } else {
    valSchema = Yup.object().shape({
      uomId: Yup.string().required('UoM is required'),
      description: Yup.string().required('Name is Required'),
      companyId: Yup.string().required('Entity is required')
    });
  }

  const initialValues = {
    make: null,
    model: null,
    category: null,
    subCategory: null
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: valSchema,
    onSubmit: addSubmitHandler
  });

  useEffect(() => {
    if (selectedVehicleType) {
      formik.setValues({
        makeId: selectedVehicleType?.CarMake?.id,
        make: selectedVehicleType?.CarMake,
        modelId: selectedVehicleType?.CarModel?.id,
        model: selectedVehicleType?.CarModel,
        weight: selectedVehicleType?.VehicleType?.weight,
        volume: selectedVehicleType?.volume,
        categoryId: selectedVehicleType?.Category?.id,
        category: selectedVehicleType?.Category,
        subCategoryId: selectedVehicleType?.SubCategory?.id,
        subCategory: selectedVehicleType?.SubCategory,
        fuelType: selectedVehicleType?.VehicleType?.fuelType,
        payloadWeight: selectedVehicleType?.VehicleType?.payloadWeight,
        horsepower: selectedVehicleType?.VehicleType?.horsepower,
        volume: selectedVehicleType?.VehicleType?.volume,
        isActive: selectedVehicleType?.isActive,
        name: selectedVehicleType?.name
      });
    }
  }, [selectedVehicleType]);

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      content={
        <VehicleTypeForm
          open
          openPopup={open}
          organizationType={organizationType}
          formik={formik}
          edit={!!selectedVehicleType}
        />
      }
      title={selectedVehicleType ? `Edit Vehicle Type` : `Add Vehicle Type`}
      headerClass="px-8 pt-8 pb-4"
      titleClass="text-primaryBlue text-lg font-semibold"
      noPadding={true}
      closeIconSize={24}
      paperProps={{
        style: {
          borderRadius: '8px',
          height: '793px',
          maxWidth: '780px',
          width: '780px'
        }
      }}
      dialogContentClasses={'dark-scrollbar'}
      onClose={() => {
        handleClose();
      }}
      actions={
        <div className="flex justify-end gap-2 pt-3 pb-6 px-6">
          <Button label="Cancel" onClick={handleClose} variant="text" />
          <Button
            label={
              selectedVehicleType ? 'Update Vehicle Type' : 'Add Vehicle Type'
            }
            className="rounded"
            onClick={async () => {
              await addSubmitHandler(formik.values);
            }}
            variant="primary"
            size="large"
            disabled={!formik.values.name}
          />
        </div>
      }
    />
  );
};

export default AddVehicleTypePopup;

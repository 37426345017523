import React, { useState } from 'react';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { Paragraph, Title } from '../../../../core-components/atoms/Text';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';

const BinListComponent = ({
  warehouse,
  data,
  searchValue,
  _getBins,
  onClick
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const removeBin = async () => {
    try {
      await API.delete(`warehouses/${warehouse?.id}/halls/${data.id}`);
      toaster('success', `Bin deleted successfully.`);
      _getBins();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_${'Bin'}s_warehouseId`,
        `$ already exists it must be unique`
      );
    }
  };

  return (
    <div
      className="flex items-center node-card justify-between bg-white cursor-pointer shadow-elevation-1 mx-4 px-4 py-4 bd-4 my-2"
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={onClick}
    >
      <div className="flex gap-4">
        <div
          className={`h-3 w-3 mt-2 rounded-full ${
            data.isActive
              ? 'border-[#1AAE2E] bg-[#1AAE2E]'
              : 'border border-solid border-[#9DA3B3]'
          }`}
        />
        <div>
          <div className="flex items-center">
            <Paragraph className={`text-sm ${searchValue && 'bg-[#FFF0C3]'}`}>
              {data.name}
            </Paragraph>
            <Paragraph className="text-xs ml-4 text-[#696F80]">•</Paragraph>
            <Paragraph className="text-xs ml-4 text-[#696F80]">
              {data.sectionType}
            </Paragraph>
          </div>
          <div className="flex items-center mt-3 gap-2">
            <Title className="flex text-xs text-['#0B1940]">
              Sq. ft.:
              <Paragraph className="text-xs ml-1 text-['#0B1940]">
                {data.capacity}
              </Paragraph>
            </Title>

            <Title className="flex text-xs text-['#0B1940]">
              Pallet:
              <Paragraph className="text-xs ml-1 text-['#0B1940]">
                {data.pallets}
              </Paragraph>
            </Title>
          </div>
        </div>
      </div>
      {isHovering && isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
        <button
          onClick={async (event) => {
            event.stopPropagation();
            await removeBin();
          }}
        >
          <img src={deleteIcon} alt="delete-icon" />
        </button>
      )}
    </div>
  );
};

export default BinListComponent;

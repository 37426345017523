import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';

export default function BulkUploadTabs(props) {
  const [tabClasses, setClasses] = useState({
    tab1: 'selected-tab',
    tab2: '',
    tab3: ''
  });

  useEffect(() => {
    if (props?.verifiedList?.length) {
      changeForm('tab2');
    }
  }, [props?.verifiedList]);

  useEffect(() => {
    if (props?.correctUploadFlag) {
      changeForm('tab3');
    }
  }, [props?.correctUploadFlag]);

  const changeForm = (form) => {
    if (props?.verifyFlag && props?.selectedFile) {
      let formstate = { ...props?.openForm };
      let classnames = { ...tabClasses };
      Object.keys(formstate).forEach((key) => {
        if (key === form) {
          formstate[key] = true;
          classnames[key] = 'selected-tab';
        } else {
          formstate[key] = false;
          classnames[key] = '';
        }
      });
      props?.setOpenForm(formstate);
      setClasses(classnames);
    }
  };

  return (
    <div className="position-relative mt-10">
      <div className="border">
        <Tooltip title="Bulk Aisle Upload">
          <div
            className={`order-form-tabs first-tab ${tabClasses.tab1}`}
            onClick={() => changeForm('tab1')}
          ></div>
        </Tooltip>
        <Tooltip title="Validation Screen">
          <div
            className={`order-form-tabs second-tab ${tabClasses.tab2}`}
            onClick={() => changeForm('tab2')}
          ></div>
        </Tooltip>
        <Tooltip title="Summary of Upload">
          <div
            className={`order-form-tabs third-tab ${tabClasses.tab3}`}
            onClick={() => changeForm('tab3')}
          ></div>
        </Tooltip>
      </div>
    </div>
  );
}

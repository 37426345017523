import { useEffect, useContext } from 'react';
import { SharedContext } from '../../../utils/common';

export default function Dashboard() {
  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'Home';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  // return <h1 style={{display:'flex',alignItems:'center',justifyContent:'center'}}>Welcome to Oware</h1>

  return (
    <>
      <iframe
        title="Dashboard"
        src="https://oware-technologies.metabaseapp.com/public/dashboard/aad9a84c-0326-4612-9afb-bc71775a8e53"
        frameBorder="0"
        width="100%"
        height="100%"
        // eslint-disable-next-line react/no-unknown-property
        allowtransparency
      ></iframe>
    </>
  );
}

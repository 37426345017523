import React from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Tooltip } from '@material-ui/core';

const CustomToolbar = ({ props, searchKeyword, openFilterView }) => {
  return (
    <GridToolbarContainer
      {...props}
      className="toolbarContainer userToolbarContainer"
    >
      {/* <GridToolbarExport /> */}
      <Tooltip title="Filter">
        <button
          className="mainFilter"
          id="search"
          label="Search"
          // eslint-disable-next-line react/no-unknown-property
          variant="outlined"
          value={searchKeyword}
          key={3}
          onClick={() => openFilterView()}
        >
          FILTER
        </button>
      </Tooltip>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;

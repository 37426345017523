/*eslint-disable*/
import clsx from 'clsx';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import TriangleDown from '../../assets/icons/unifiedDashboard/TriangleDown.svg';
import TriangleUp from '../../assets/icons/unifiedDashboard/TriangleUp.svg';
import Button from '../../core-components/atoms/Button';
import StatItem from './StatItem';
import {
  MetricStatus,
  SymbolType,
  TopBarMetric,
  TopBarMetricCondensed
} from './constants';

const StatsDropDown = ({
  className,
  title,
  status,
  value,
  symbol,
  specificStats = [],
  showBreaker = true,
  showOnRight = false
}) => {
  const [clicked, setClicked] = useState(false);
  const [position, setPosition] = useState({ left: 0, right: 0 });
  const componentRef = useRef(null);
  const dropdownRef = useRef(null);

  useLayoutEffect(() => {
    const updatePosition = () => {
      if (componentRef.current) {
        const { left, right } = componentRef.current.getBoundingClientRect();
        setPosition({ left, right });
      }
    };

    const handleScroll = () => {
      updatePosition();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    updatePosition();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        clicked
      ) {
        setClicked(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [clicked]);

  return (
    <div className="block transition-all" ref={componentRef}>
      <div
        onMouseOver={() => {
          setClicked(true);
        }}
        className={clsx(
          'h-full border-0 rounded-none px-[0px] pt-2 pb-3 bg-blackPurple hover:bg-yankeesBlue',
          className
        )}
      >
        <div
          className={clsx(
            'flex justify-between w-100 items-center px-6 border-deepWater box-border whitespace-nowrap',
            !clicked && showBreaker && 'border-l'
          )}
        >
          {status !== MetricStatus.NONE && (
            <img
              src={getStatusIcon(status)}
              alt={status}
              className={clsx('mr-1 hidden min-[1440px]:block')}
            />
          )}

          <p className="text-white text-base font-semibold mr-1">
            {symbol === SymbolType.RUPPEE ? symbol + ' ' : ''}
            {value}
            {symbol === SymbolType.PERCENTAGE ? symbol : ''}
          </p>
          <p className="text-soltitude text-sm font-normal hidden 2xl:block">
            {TopBarMetric[title]}
          </p>
          <p className="text-soltitude text-sm font-normal block 2xl:hidden">
            {TopBarMetricCondensed[title]}
          </p>
        </div>
      </div>
      {specificStats.length > 0 ? (
        <div
          ref={dropdownRef}
          className={clsx(
            'bg-blackPurple w-[237px] py-2 rounded fixed top-[110px] transition-all overflow-hidden',
            clicked ? 'max-h-[264px]' : 'h-0 p-0'
          )}
          style={
            showOnRight ? { left: `${Math.round(position.right) - 237}px` } : {}
          }
        >
          <div className="max-h-[248px] overflow-auto statDropDown mr-[3px] overflow-x-hidden">
            {specificStats.map((stat) => (
              <StatItem
                key={stat.title}
                title={stat.title}
                status={stat.status}
                value={stat.value}
                symbol={symbol}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StatsDropDown;

const getStatusIcon = (status) => {
  let iconSrc;
  switch (status) {
    case MetricStatus.DOWN:
      iconSrc = TriangleDown;
      break;
    case MetricStatus.UP:
      iconSrc = TriangleUp;
      break;
    case MetricStatus.NONE:
      iconSrc = TriangleUp;
      break;
  }
  return iconSrc;
};

/* eslint-disable */
export const constants = {
  TASK_STATUS: {
    COMPLETED: 'Completed',
    IN_PROGRESS: 'In Progress',
    NOT_ASSIGNED: 'Not Assigned',
    ASSIGNED: 'Assigned',
    CANCELLED: 'Cancelled',
    PENDING: 'Pending'
  },
  TASK_TYPE: {
    PICKING: 'PICKING',
    PUTAWAY: 'PUTAWAY',
    CYCLE_COUNT: 'CYCLE_COUNT',
    RELOCATION: 'RELOCATION'
  }
};

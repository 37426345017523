import React from 'react';
import { Button, Tooltip } from '@material-ui/core';

const CancelButton = ({
  setValidation,
  resetStates,
  handleClose,
  className
}) => {
  return (
    <Tooltip title="Cancel">
      <Button
        className={className}
        onClick={() => {
          setValidation({});
          resetStates();
          handleClose();
        }}
        color="default"
        variant="contained"
      >
        Cancel
      </Button>
    </Tooltip>
  );
};

export default CancelButton;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { DEBOUNCE_CONST } from '../../../Config';
import StockHandlingSection from '../../../components/StockHandlingSection';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { isRequired } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  skuWidth: {
    width: '98%',
    '@media (max-width: 768px)': {
      marginLeft: '0px',
      width: '100%'
    }
  },
  skuSeparator: {
    width: '100%',
    height: '100%',
    'font-weight': 'bolder',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center'
  },
  pl10: {
    'padding-left': '10px',
    '@media (max-width: 768px)': {
      'padding-left': '0px'
    }
  },
  alignCenter: {
    'align-items': 'center',
    display: 'flex'
  }
}));

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#cecece',
    '&$checked': {
      color: 'blue'
    },
    '&$checked + $track': {
      backgroundColor: 'blue'
    }
  },
  checked: {},
  track: {}
})(Switch);

export default function AddProductView({
  addProduct,
  open,
  handleClose,
  selectedProduct,
  // brands,
  uoms,
  categories,
  formErrors,
  setCategory,
  setBrand,
  company,
  companyId
}) {
  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [uomVal, setUomVal] = useState('');
  const [dimensionsCBM, setDimensionsCBM] = useState('');
  const [height, setHeight] = useState('');
  const [length, setLength] = useState('');
  const [breadth, setBreadth] = useState('');
  const [weight, setWeight] = useState('');
  const [perPallet, setPerPallet] = useState('');
  const [shelfLife, setShelfLife] = useState('');
  const [productLife, setProductLife] = useState('');
  const [barcode, setBarCode] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [uomId, setUomId] = useState('');
  const [isActive, setActive] = useState(true);
  const classes = useStyles();
  const [batchEnabled, setBatchEnabled] = useState(false);
  const [MRPEnabled, setMRPEnabled] = useState(false);
  const [brandOptions, setBrandOptions] = useState(true);
  const [brands, setBrands] = useState([]);
  const [uom, setUom] = useState([]);
  const [stockHandlingType, setStockHandlingType] = useState('FIFO');

  const resetStates = () => {
    setValidation({});
    setName('');
    setDescription('');
    setDimensionsCBM('');
    setHeight('');
    setLength('');
    setBreadth('');
    setWeight('');
    setPerPallet('');
    setCategoryId();
    setBrandId();
    setUomId();
    setBarCode('');
    setShelfLife('');
    setProductLife('');
    setActive(true);
    setBatchEnabled(false);
    setMRPEnabled(false);
  };

  const getBrands = async (id) => {
    setCategory(id);
    setCategoryId(id);
    let colVal = {
      categoryId: id
    };
    let filters = {
      colVal
    };
    try {
      const brands = await API.get(`companies/${companyId}/brands`, {
        params: { filters }
      });
      setBrands(brands.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      setName(selectedProduct.name || '');
      setDescription(selectedProduct.description || '');
      setDimensionsCBM(selectedProduct.dimensionsCBM || '');
      setHeight(selectedProduct.height || '');
      setLength(selectedProduct.length || '');
      setBreadth(selectedProduct.breadth || '');
      setWeight(selectedProduct.weight || '');
      setPerPallet(selectedProduct.perPallet || '');
      setBarCode(selectedProduct.barcode || '');
      setShelfLife(selectedProduct.shelfLife || '');
      setProductLife(selectedProduct.productLife || '');
      setCategoryId(selectedProduct.Brand.Category.id || '');
      setBrandId(selectedProduct.Brand.id || '');
      setUomId(selectedProduct.UOM.id || '');
      setStockHandlingType(selectedProduct.stockHandlingType || '');
      setActive(!!selectedProduct.isActive);
      setBatchEnabled(!!selectedProduct.batchEnabled || false);
      setMRPEnabled(!!selectedProduct.MRPEnabled || false);
      setUom(uoms);
    } else {
      setName('');
      setDescription('');
      setDimensionsCBM('');
      setLength('');
      setHeight('');
      setBreadth('');
      setWeight('');
      setPerPallet('');
      setBarCode('');
      setShelfLife('');
      setProductLife('');
      setCategoryId('');
      setBrandId('');
      setStockHandlingType('');
      setUomId('');
      setActive(true);
      setBatchEnabled(false);
      setMRPEnabled(false);
    }
  }, [selectedProduct]);

  useEffect(() => {
    getDelayedUoms(uomVal);
  }, [uomVal]);

  const getDelayedUoms = useCallback(
    debounce((value) => {
      getUom(value).then((newUoms) => {
        setUom([...uom, ...newUoms.data]);
      });
    }, DEBOUNCE_CONST),
    []
  );

  const handleSubmit = () => {
    const newProduct = {
      name,
      description,
      dimensionsCBM: dimensionsCBM ? dimensionsCBM : length * height * breadth,
      length,
      height,
      breadth,
      weight,
      perPallet,
      shelfLife,
      productLife,
      barcode,
      categoryId,
      stockHandlingType:
        stockHandlingType.length != 0 ? stockHandlingType : null,
      brandId,
      uomId,
      isActive,
      batchEnabled,
      MRPEnabled
    };

    setValidation({
      name: true,
      description: true,
      // dimensionsCBM: true,
      length: true,
      height: true,
      breadth: true,
      weight: true,
      categoryId: true,
      brandId: true,
      uomId: true
    });
    if (
      isRequired(name) &&
      isRequired(description) &&
      isRequired(length) &&
      isRequired(height) &&
      isRequired(breadth) &&
      isRequired(weight) &&
      isRequired(categoryId) &&
      isRequired(brandId) &&
      isRequired(uomId)
    ) {
      setBrandOptions(true);
      newProduct['perPallet'] = perPallet === '' ? 0 : perPallet;
      newProduct['dimensionsCBM'] =
        newProduct['dimensionsCBM'] == ''
          ? length * breadth * height
          : dimensionsCBM;
      if (newProduct['shelfLife'] == '' || !newProduct['shelfLife'])
        delete newProduct['shelfLife'];
      if (newProduct['productLife'] == '' || !newProduct['productLife'])
        delete newProduct['productLife'];
      if (newProduct['barcode'] == '' || !newProduct['barcode'])
        delete newProduct['barcode'];

      addProduct(newProduct);
      resetStates();
    }
  };

  const getUom = async (value) => {
    try {
      let colVal = { isActive: 1 };
      colVal.name = value;
      let filters = { colVal };
      let uom = await API.get(`uoms`, { params: { filters } });
      return uom;
    } catch (err) {
      onError(err);
    }
  };

  const toggleChecked = (event) => {
    setBatchEnabled(event.target.checked);
    setMRPEnabled(event.target.checked);
  };

  const toggleMRPChecked = (event) => {
    setMRPEnabled(event.target.checked);
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}
          onBackdropClick={() => {
            resetStates();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle disableTypography className="title">
            {!selectedProduct ? 'Add Product' : 'Edit Product'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  inputProps={{ className: classes.textBox }}
                  className={`${classes.labelBox} ${classes.skuWidth}`}
                  margin="dense"
                  id="name"
                  label="Customer SKU Code*"
                  type="text"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => setValidation({ ...validation, name: true })}
                />
                {validation.name && !isRequired(name) ? (
                  <Typography color="error">
                    Customer SKU Code is required!
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                  margin="dense"
                  id="description"
                  label="SKU Name*"
                  type="text"
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onBlur={() =>
                    setValidation({ ...validation, description: true })
                  }
                />
                {validation.description && !isRequired(description) ? (
                  <Typography color="error">SKU Name is required!</Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth={true}
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                  margin="dense"
                  id="barcode"
                  label="SKU Barcode"
                  type="text"
                  variant="outlined"
                  value={barcode}
                  onChange={(e) => {
                    setBarCode(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4} className={classes.pl10}>
                <FormControl margin="dense" fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="categoryId"
                    key={categories}
                    options={categories}
                    defaultValue={
                      selectedProduct
                        ? {
                            name: selectedProduct.Brand.Category.name,
                            id: selectedProduct.Brand.Category.id
                          }
                        : ''
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="SKU Category*"
                        variant="outlined"
                      />
                    )}
                    getOptionLabel={(category) => category.name || ''}
                    onBlur={() =>
                      setValidation({ ...validation, categoryId: true })
                    }
                    onChange={(event, newValue) => {
                      setBrandOptions(false);
                      setBrand('');
                      setBrandId('');
                      if (newValue) {
                        // setBrandOpt(newValue)
                        getBrands(newValue.id);
                      }
                    }}
                  />
                  {validation.categoryId && !isRequired(categoryId) ? (
                    <Typography color="error">
                      SKU Category is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className={classes.pl10}>
                <FormControl margin="dense" fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="brandId"
                    key={brands ? brands : ''}
                    options={brands ? brands : ''}
                    defaultValue={
                      !!selectedProduct && brandOptions
                        ? {
                            name: selectedProduct.Brand.name
                              ? selectedProduct.Brand.name
                              : '',
                            id: selectedProduct.Brand.id
                              ? selectedProduct.Brand.id
                              : ''
                          }
                        : ''
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="SKU Brand*"
                        variant="outlined"
                      />
                    )}
                    getOptionLabel={(brand) => brand.name || ''}
                    onBlur={() =>
                      setValidation({ ...validation, brandId: true })
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setBrand(newValue.id);
                        setBrandId(newValue.id);
                      }
                    }}
                  />
                  {validation.brandId && !isRequired(brandId) ? (
                    <Typography color="error">
                      SKU Brand is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="shelfLife"
                    label="Shelf Life (In Days)"
                    type="number"
                    variant="outlined"
                    value={shelfLife}
                    onChange={(e) => {
                      if (e.target.value > -1) setShelfLife(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="productLife"
                    label="Product Life (In Days)"
                    type="number"
                    variant="outlined"
                    value={productLife}
                    onChange={(e) => {
                      if (e.target.value > -1) setProductLife(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} className={classes.alignCenter}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PurpleSwitch
                        checked={batchEnabled}
                        onChange={toggleChecked}
                      />
                    }
                    label="Enable Batch / Expiry"
                  />
                  <FormControlLabel
                    disabled={!batchEnabled}
                    control={
                      <PurpleSwitch
                        checked={MRPEnabled}
                        onChange={toggleMRPChecked}
                      />
                    }
                    label="Enable MRP"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={8} className={classes.pl10}>
                <FormControl margin="dense" fullWidth={true} variant="outlined">
                  <StockHandlingSection
                    setStockHandlingType={setStockHandlingType}
                    stockHandlingType={
                      stockHandlingType
                        ? stockHandlingType
                        : company.stockHandlingType
                    }
                  ></StockHandlingSection>
                </FormControl>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="height"
                    label="Height*"
                    type="number"
                    variant="outlined"
                    value={height}
                    onChange={(e) => {
                      if (e.target.value > -1) {
                        setHeight(e.target.value);
                        setDimensionsCBM(length * breadth * e.target.value);
                      }
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, height: true })
                    }
                  />
                  {validation.height && !isRequired(height) ? (
                    <Typography color="error">Height is required!</Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="length"
                    label="Length*"
                    type="number"
                    variant="outlined"
                    value={length}
                    onChange={(e) => {
                      if (e.target.value > -1) {
                        setLength(e.target.value);
                        setDimensionsCBM(e.target.value * breadth * height);
                      }
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, length: true })
                    }
                  />
                  {validation.length && !isRequired(length) ? (
                    <Typography color="error">Length is required!</Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="breadth"
                    label="Breadth*"
                    type="number"
                    variant="outlined"
                    value={breadth}
                    onChange={(e) => {
                      if (e.target.value > -1) {
                        setBreadth(e.target.value);
                        setDimensionsCBM(length * e.target.value * height);
                      }
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, breadth: true })
                    }
                  />
                  {validation.breadth && !isRequired(breadth) ? (
                    <Typography color="error">Breadth is required!</Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    disabled
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="dimensionsCBM"
                    label="Volume cm3*"
                    type="number"
                    variant="outlined"
                    value={length * breadth * height || dimensionsCBM}
                    onChange={(e) => {
                      if (e.target.value > -1) setDimensionsCBM(e.target.value);
                    }}
                    // onBlur={(e) =>
                    //   setValidation({ ...validation, dimensionsCBM: true })
                    // }
                  />
                  {/* {validation.dimensionsCBM && !isRequired(dimensionsCBM) ? (
                    <Typography color="error">Volume is required!</Typography>
                  ) : (
                    ""
                  )} */}
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="weight"
                    label="Pack size in kgs or liter*"
                    type="number"
                    variant="outlined"
                    value={weight}
                    onChange={(e) => {
                      if (e.target.value > -1) setWeight(e.target.value);
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, weight: true })
                    }
                  />
                  {validation.weight && !isRequired(weight) ? (
                    <Typography color="error">
                      Pack size in kgs or liter is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="uomId"
                      key={uoms}
                      options={uom}
                      defaultValue={
                        selectedProduct
                          ? {
                              name: selectedProduct?.UOM?.name,
                              id: selectedProduct?.UOM?.id
                            }
                          : ''
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="UoM*"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(uom) => uom.name || ''}
                      onBlur={() =>
                        setValidation({ ...validation, categoryId: true })
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setUomId(newValue.id);
                        }
                      }}
                      onKeyUp={(event) => {
                        if (event.target.value) {
                          setUomVal(event.target.value);
                        }
                      }}
                    />
                    {validation.uomId && !isRequired(uomId) ? (
                      <Typography color="error">UoM is required!</Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="perPallet"
                    label="UoM per Pallet*"
                    type="number"
                    variant="outlined"
                    value={perPallet}
                    onChange={(e) => {
                      if (e.target.value > -1) setPerPallet(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className={classes.alignCenter}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PurpleSwitch
                        checked={isActive}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  resetStates();
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip
              title={!selectedProduct ? 'Add Product' : 'Update Product'}
            >
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                {!selectedProduct ? 'Add Product' : 'Update Product'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import React from 'react';
import TruckIcon from '../../assets/icons/truck.svg';
import TransferIcon from '../../assets/icons/transfer.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import UploadIcon from '../../assets/icons/uploads.svg';

function MoveTypeIcon(props) {
  const getIcon = (type) => {
    let icon = '';
    switch (type) {
      case 'LOGISTIC':
        icon = TruckIcon;
        break;
      case 'INWARD':
        icon = DownloadIcon;
        break;
      case 'OUTBOUND':
        icon = UploadIcon;
        break;
      case 'TRANSFER':
        icon = TransferIcon;
        break;
      default:
        icon = TruckIcon;
        break;
    }
    return icon;
  };

  return (
    <img src={getIcon(props?.type)} className="mr-3" alt="Logistic Icon" />
  );
}

export default MoveTypeIcon;

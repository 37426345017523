/*eslint-disable*/
import { FormControl } from '@material-ui/core';
import { React, useContext, useEffect, useState } from 'react';
import Autocomplete from '../../../../core-components/atoms/Autocomplete';
import Switch from '../../../../core-components/atoms/Switch';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { SharedContext, toSuperscript } from '../../../../utils/common';
import TextField from '../../../../core-components/atoms/TextField';
import { Typography } from '@mui/material';
import { FUEL_TYPES } from '../constants';
import { PRIMARY } from '../../../../constants/colors';
import AddModalView from '../../../logistics/vehicletype/AddPopupModal';
import { toaster } from '../../../../utils/toaster';

const VehicleTypeForm = ({ formik, edit }) => {
  const { setAPILoader, settings } = useContext(SharedContext);

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [makeSearchKeyword, setMakeSearchKeyword] = useState('');
  const [modelSearchKeyword, setModelSearchKeyword] = useState('');
  const [subCategoryKeyword, setSubCategoryKeyword] = useState('');
  const [categoryKeyword, setCategoryKeyword] = useState('');

  const [addMakeViewOpen, setAddMakeViewOpen] = useState(false);
  const [addModelViewOpen, setAddModelViewOpen] = useState(false);
  const [addCategoryViewOpen, setAddCategoryViewOpen] = useState(false);
  const [addSubCategoryViewOpen, setAddSubCategoryViewOpen] = useState(false);

  useEffect(() => {
    getMakes(makeSearchKeyword);
  }, [makeSearchKeyword]);

  useEffect(() => {
    getModels(modelSearchKeyword);
  }, [modelSearchKeyword]);

  useEffect(() => {
    getVehicleSubCategory(subCategoryKeyword);
  }, [subCategoryKeyword]);

  useEffect(() => {
    getVehicleCategory(categoryKeyword);
  }, [categoryKeyword]);

  const getMakes = async (searchKeyword) => {
    try {
      setAPILoader(true);
      const makes = await API.get('vehicles/makers', {
        params: { search: searchKeyword }
      });
      setMakes(makes.data?.filter((m) => m.isActive === true) || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const getModels = async (searchKeyword) => {
    try {
      setAPILoader(true);
      const models = await API.get('vehicles/models', {
        params: { search: searchKeyword }
      });
      setModels(models.data?.filter((m) => m.isActive === true) || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const getVehicleSubCategory = async (search) => {
    try {
      const vehicleSubCategories = await API.get(`vehicles/categories`, {
        params: { isSubCategory: true, search: search || '' }
      });
      setSubCategories(
        vehicleSubCategories?.data.filter(
          (category) => category.isActive === true
        )
      );
    } catch (err) {
      onError(err);
    }
  };

  const getVehicleCategory = async (search) => {
    try {
      const vehicleCategories = await API.get(`vehicles/categories`, {
        params: { search: search || '' }
      });
      setCategories(
        vehicleCategories?.data.filter((category) => category.isActive === true)
      );
    } catch (err) {
      onError(err);
    }
  };

  const addMake = async (data) => {
    try {
      setAPILoader(true);
      setAddMakeViewOpen(false);
      await API.post(`vehicles/makers`, data);
      setAPILoader(false);
      toaster('success', 'Vehicle Make Created Successfully');
      await getMakes();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  const addModel = async (data) => {
    try {
      setAPILoader(true);
      setAddModelViewOpen(false);
      await API.post(`vehicles/models`, data);
      setAPILoader(false);
      toaster('success', 'Model Created Successfully');
      getModels();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  const addSubCategory = async (data) => {
    try {
      setAPILoader(true);
      setAddSubCategoryViewOpen(false);
      await API.post(`vehicles/categories`, { ...data, isSubCategory: true });
      setAPILoader(false);
      toaster('success', 'Vehicle Sub Category Created Successfully!');
      getVehicleSubCategory();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  const addCategory = async (data) => {
    try {
      setAPILoader(true);
      setAddCategoryViewOpen(false);
      await API.post(`vehicles/categories`, { ...data });
      setAPILoader(false);
      toaster('success', 'Vehicle Category Created Successfully!');
      getVehicleCategory();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  const listBoxProps = {
    fontSize: 14
  };

  const inputProps = {
    classes: {
      input: 'text-sm h-7',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11.6px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  return (
    <div className="px-8 pt-4 pb-5 number-input-container">
      <AddModalView
        handleClose={() => {
          setAddMakeViewOpen(false);
        }}
        open={addMakeViewOpen}
        postData={addMake}
        type={'Make'}
      />
      <AddModalView
        handleClose={() => {
          setAddModelViewOpen(false);
        }}
        open={addModelViewOpen}
        postData={addModel}
        type={'Model'}
      />
      <AddModalView
        handleClose={() => {
          setAddCategoryViewOpen(false);
        }}
        open={addCategoryViewOpen}
        postData={addCategory}
        type={'Category'}
      />
      <AddModalView
        handleClose={() => {
          setAddSubCategoryViewOpen(false);
        }}
        open={addSubCategoryViewOpen}
        type={'Sub Category'}
        postData={addSubCategory}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Vehicle Type <span className="text-error">*</span>
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="name"
                  placeholder="Enter Vehicle Type"
                  value={formik.values?.name || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Vehicle Make & Model
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={[
                    ...makes,
                    {
                      id: '',
                      name: 'addMake',
                      isActive: true
                    }
                  ]}
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return option?.name === 'addMake' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={() => {
                          setAddMakeViewOpen(true);
                        }}
                      >
                        + {'Add New Make'}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option">
                        <span>{option.name}</span>
                      </li>
                    );
                  }}
                  value={formik.values?.make}
                  inputValue={makeSearchKeyword}
                  getOptionLabel={(make) => make?.name || ''}
                  placeholder="Select Make"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('makeId', newValue.id);
                    formik.setFieldValue('make', newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setMakeSearchKeyword(newValue);
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={[
                    ...models,
                    { id: '', name: 'addModel', isActive: true }
                  ]}
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return option?.name === 'addModel' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={() => {
                          setAddModelViewOpen(true);
                        }}
                      >
                        + {'Add New Model'}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option">
                        <span>{option.name}</span>
                      </li>
                    );
                  }}
                  inputValue={modelSearchKeyword}
                  value={formik.values?.model}
                  getOptionLabel={(model) => model.name || ''}
                  placeholder="Select Model"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('modelId', newValue.id);
                    formik.setFieldValue('model', newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setModelSearchKeyword(newValue);
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Category & Sub-Category
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={[
                    ...categories,
                    { id: '', name: 'addCategory', isActive: true }
                  ]}
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return option?.name === 'addCategory' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={() => {
                          setAddCategoryViewOpen(true);
                        }}
                      >
                        + {'Add New Category'}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option">
                        <span>{option.name}</span>
                      </li>
                    );
                  }}
                  value={formik.values?.category}
                  inputValue={categoryKeyword}
                  getOptionLabel={(category) => category?.name || ''}
                  placeholder="Select Category"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('categoryId', newValue.id);
                    formik.setFieldValue('category', newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setCategoryKeyword(newValue);
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={[
                    ...subCategories,
                    { id: '', name: 'addSubCategory', isActive: true }
                  ]}
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return option?.name === 'addSubCategory' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={() => {
                          setAddSubCategoryViewOpen(true);
                        }}
                      >
                        + {'Add New Sub Category'}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option">
                        <span>{option.name}</span>
                      </li>
                    );
                  }}
                  value={formik.values?.subCategory}
                  inputValue={subCategoryKeyword}
                  getOptionLabel={(subCategory) => subCategory?.name || ''}
                  placeholder="Sub-Category"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('subCategoryId', newValue.id);
                    formik.setFieldValue('subCategory', newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    setSubCategoryKeyword(newValue);
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Payload & Vehicle Weight
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  placeholder="Enter Payload Weight"
                  type="number"
                  value={formik.values?.payloadWeight || ''}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'payloadWeight',
                      e.target.value ? e.target.value : ''
                    );
                  }}
                  size="small"
                  sxProps={sxProps}
                  InputProps={{
                    endAdornment: settings?.weight?.symbol || 'Kg'
                  }}
                />
              </FormControl>
            </div>
            <div className="w-full input-number-container">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  placeholder="Enter Weight"
                  type="number"
                  value={formik.values?.weight || ''}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'weight',
                      e.target.value ? e.target.value : ''
                    );
                  }}
                  size="small"
                  sxProps={sxProps}
                  InputProps={{
                    endAdornment: settings?.weight?.symbol || 'Kg'
                  }}
                />
                {formik.errors?.description && formik.touched?.description ? (
                  <Typography color="error">Name is required!</Typography>
                ) : (
                  ''
                )}
              </FormControl>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Maximum Vehicle Gross Weight
            </p>
          </div>
          <div className="flex w-[479px] justify-between input-number-container">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="maxWeight"
                  placeholder="Max Payload + Vehicle Weight"
                  value={
                    Number(formik.values?.weight || 0) +
                      Number(formik.values?.payloadWeight || 0) || ''
                  }
                  size="small"
                  sxProps={sxProps}
                  disabled
                  InputProps={{
                    endAdornment: settings?.weight?.symbol || 'Kg'
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Capacity in Volume & Fuel Type
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  placeholder="Enter Volume"
                  type="number"
                  value={formik.values?.volume || ''}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'volume',
                      e.target.value ? e.target.value : ''
                    );
                  }}
                  size="small"
                  sxProps={sxProps}
                  InputProps={{
                    endAdornment: (
                      <span className="text-[10px]">m{toSuperscript(3)}</span>
                    )
                  }}
                />
              </FormControl>
            </div>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  options={FUEL_TYPES || []}
                  value={formik.values?.fuelType}
                  getOptionLabel={(type) => type || ''}
                  placeholder="Select Fuel Type"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('fuelType', newValue);
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">
              Vehicle Horsepower
            </p>
          </div>
          <div className="flex w-[479px] justify-between">
            <div className="w-full mr-2">
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  name="horsepower"
                  placeholder="Enter Vehicle Horsepower"
                  value={formik.values?.horsepower || ''}
                  size="small"
                  sxProps={sxProps}
                  onChange={(e) =>
                    formik.setFieldValue('horsepower', e.target.value)
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between pt-4">
          <div className="flex items-center">
            <p className="font-medium text-primaryBlue text-sm">Status</p>
          </div>
          <div className="w-[479px]">
            <FormControl fullWidth={true} variant="outlined">
              <Switch
                checked={formik.values?.isActive}
                onChange={(newValue) => {
                  formik.setFieldValue('isActive', newValue);
                }}
                label="Active"
              ></Switch>
            </FormControl>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VehicleTypeForm;

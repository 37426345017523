import React from 'react';
import TextField from '../atoms/TextField';

const FormikTextField = ({ formik, name, ...rest }) => {
  return (
    <div className="flex flex-col gap-2">
      <TextField
        value={formik.values[name]}
        onChange={(e) => formik.setFieldValue(name, e.target.value)}
        onBlur={() => formik.setFieldTouched(name, true)}
        error={formik.touched[name] && formik.errors[name]}
        {...rest}
      />
      {formik.touched[name] && formik.errors[name] && (
        <span className="text-error text-xs">{formik.errors[name]}</span>
      )}
    </div>
  );
};

export default FormikTextField;

import React from 'react';
import Popup from '../../../core-components/atoms/Popup';
import CustomButton from '../../../core-components/atoms/Button';
import ExpenseForm from './ExpenseForm';
import { Label } from '../../../core-components/atoms/Text';
import { Grid } from '@material-ui/core';

const ExpenseAddListing = ({
  expenses,
  setExpenses,
  open,
  setOpen,
  order,
  postExpense,
  shipment
}) => {
  const removeExpense = (e) => {
    let array = expenses?.filter((_, index) => index !== e);
    setExpenses(array);
  };

  const isAllValid = expenses?.every((item) => {
    return (
      item &&
      typeof item.name === 'string' &&
      item.name.trim() !== '' &&
      (typeof item.cost === 'string' || typeof item.cost === 'number') &&
      String(item.cost).trim() !== '' &&
      Number(item.cost) !== 0
    );
  });

  return (
    <div style={{ display: 'inline' }}>
      <Popup
        open={open}
        setOpen={setOpen}
        title={'Add Expense'}
        content={
          <div>
            <Grid container spacing={2}>
              <Grid item xs={!shipment ? 3 : 4}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Expense Name
                </Label>
              </Grid>
              {!shipment && (
                <Grid item xs={3}>
                  <Label variant={'xs'} className={'text-dark500'}>
                    type
                  </Label>
                </Grid>
              )}
              <Grid item xs={!shipment ? 3 : 4}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Cost
                </Label>
              </Grid>
              <Grid item xs={!shipment ? 2 : 3}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Documents
                </Label>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {expenses?.map((value, index) => {
              return (
                <div key={index}>
                  <ExpenseForm
                    expense={value}
                    setExpenses={setExpenses}
                    expenses={expenses}
                    removeExpense={removeExpense}
                    index={index}
                    order={order}
                    shipment={shipment}
                    deletePermission={true}
                  />
                </div>
              );
            })}
            <CustomButton
              label="+ Add More"
              onClick={() =>
                setExpenses([
                  ...expenses,
                  ...[
                    {
                      name: '',
                      cost: 0,
                      shipmentId: null,
                      fileIds: null
                    }
                  ]
                ])
              }
              className={'mt-2'}
              variant="text"
              size="large"
              overrideSize={true}
              labelClass="font-medium text-xs"
            />
          </div>
        }
        onClose={() => {
          setOpen(false);
        }}
        dialogContentClasses={shipment ? 'w-[40vw]' : 'w-[60vw]'}
        actions={
          <div className="flex gap-2">
            <CustomButton
              label="Cancel"
              className="py-2 px-3 ml-2 h-8 border-solid border "
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={() => {
                setOpen(false);
              }}
              variant="tertiary"
            />
            <CustomButton
              disabled={!isAllValid}
              className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"
              overrideSize={true}
              labelClass="font-medium text-xs"
              label={'Add Expense'}
              onClick={async () => {
                await postExpense();
              }}
              variant="primary"
            />
          </div>
        }
      />
    </div>
  );
};

export default ExpenseAddListing;

import { makeStyles } from '@material-ui/core';

const warehouseBookingStyles = makeStyles((theme) => ({
  root: {
    width: '95%',
    margin: '32px auto 0px auto',
    borderRadius: '8px'
  },
  searchBarContainer: {
    width: '100%',
    minHeight: 'fit-content',
    overflow: 'hidden'
  },
  addNewBooking: {
    width: '168px',
    height: '32px',
    background: '#4B89C8',
    boxShadow: '4px 4px 5px rgba(75, 137, 200, 0.12)',
    borderRadius: '8px',
    border: '1px solid #4B89C8',
    color: '#FFFFFF',
    fontSize: '16px',
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      margin: '10px 0px 0px 0px'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '286px !important'
    }
  },
  dataGridContainer: {
    marginTop: '26px'
  },
  paginationContainer: {
    marginTop: '20px'
  },
  menuItems: {
    fontWeight: '500',
    fontSize: '16px'
  },
  backdropGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  submitBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '95%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #4B89C8',
    background: '#4B89C8',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#FFFFFF',
    float: 'right',
    '@media (max-width: 767px)': {
      float: 'none',
      margin: '10px 0px 0px 0px'
    }
  },
  cancelBtn: {
    gap: '10px',
    width: '95%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #D9DFE5',
    background: '#D9DFE5',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#979B9F',
    fontSize: '13.5px'
  },
  itemContainer: {
    marginTop: '32px',
    padding: '0px 5px'
  },
  sectionSeparator: {
    marginTop: '32px',
    width: '100% !important'
  },
  noBorder: {
    border: 'none'
  },
  capacityText: {
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '14px'
  },
  disabled: {
    color: '#A4A49F !important'
  },
  heading: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: '#000000',
    width: '100%',
    '@media (max-width: 998px)': {
      fontSize: '12px'
    }
  },
  minimumChargeableContainer: {
    width: '80% !important',
    marginLeft: '8px',
    display: 'flex',
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      width: '100% !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '100% !important'
    }
  },
  actionBtnContainer: {
    marginTop: '32px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  popupFooter: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    marginRight: '3%'
  },
  popupBtn: {
    height: '32px',
    width: '183px',
    borderRadius: '8px',
    padding: '8px, 20px, 8px, 20px'
  },
  searchBar: {
    width: '318px !important',
    height: '32px',
    border: '1px solid #87A2BD',
    borderRadius: '8px',
    paddingLeft: '12px',
    '@media (max-width: 767px)': {
      width: '100% !important'
    }
  },
  floatContainer: {
    float: 'left',
    '@media (max-width: 767px)': {
      float: 'none',
      width: '100% !important'
    }
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold'
  }
}));

export default warehouseBookingStyles;

import React from 'react';
import { Title } from '../../../core-components/atoms/Text';

const NoDataFloorOperator = () => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-gray-100 rounded-lg relative overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full flex flex-col space-y-2">
        {/* Blurred bars */}
        <div className="h-4 w-3/4 bg-gray-300 rounded-full filter blur-lg"></div>
        <div className="h-4 w-2/3 bg-gray-300 rounded-full filter blur-lg"></div>
        <div className="h-4 w-1/2 bg-gray-300 rounded-full filter blur-lg"></div>
      </div>
      <Title variant={'md'}>{'No data to display'}</Title>
    </div>
  );
};

export default NoDataFloorOperator;

import * as Yup from 'yup';

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value == null || list.some((f) => value[f] != null)
  });
});

export const initialValues = (values) => {
  return {
    name: values?.name || '',
    businessWarehouseCode: values?.businessWarehouseCode || '',
    address: values?.address || '',
    cityId: values?.City?.id || '',
    locationLatlng: values?.locationLatlng || {},
    capacity: values?.capacity || '',
    pallets: values?.pallets || '',
    managerId: values?.Manager?.id || '',
    humidity: values?.humidity || '',
    height: values?.height || '',
    ...(values?.hallId && { hallId: values?.hallId }),
    configurations: {
      elevatedDocks: values?.configurations?.elevatedDocks || 0,
      racking: values?.configurations?.racking || 0,
      officeSpace: values?.configurations?.officeSpace || 0,
      temperature: values?.configurations?.temperature || [],
      typeOfProds: {
        dangerous: values?.configurations?.typeOfProds?.dangerous || false,
        fmcg: values?.configurations?.typeOfProds?.fmcg || false,
        manufacturing:
          values?.configurations?.typeOfProds?.manufacturing || false
      },
      structure: values?.configurations?.structure || '',
      transformerVoltAmps: values?.configurations?.transformerVoltAmps || '',
      electricPhase: values?.configurations?.electricPhase || '',
      contractDate: {
        startDate: values?.configurations?.contractDate?.startDate || null,
        endDate: values?.configurations?.contractDate?.endDate || null
      },
      rent: values?.configurations?.rent || '',
      operations: {
        followHeight: values?.configurations?.operations?.followHeight || false,
        ventilation: values?.configurations?.operations?.ventilation || false,
        canopy: values?.configurations?.operations?.canopy || false,
        adequateLighting:
          values?.configurations?.operations?.adequateLighting || false,
        forkliftAvail:
          values?.configurations?.operations?.forkliftAvail || false
      },
      buildingAndInfrastructure: {
        officeAreaAvail:
          values?.configurations?.buildingAndInfrastructure?.officeAreaAvail ||
          false,
        powerBackup:
          values?.configurations?.buildingAndInfrastructure?.powerBackup ||
          false,
        internet:
          values?.configurations?.buildingAndInfrastructure?.internet || false,
        concealedWiring:
          values?.configurations?.buildingAndInfrastructure?.concealedWiring ||
          false,
        noWaterLeakage:
          values?.configurations?.buildingAndInfrastructure?.noWaterLeakage ||
          false,
        noCeilingHolesAndWraps:
          values?.configurations?.buildingAndInfrastructure
            ?.noCeilingHolesAndWraps || false
      },
      housekeeping: {
        garbageCollection:
          values?.configurations?.housekeeping?.garbageCollection || false,
        pestControl: values?.configurations?.housekeeping?.pestControl || false,
        dailyCleaning:
          values?.configurations?.housekeeping?.dailyCleaning || false
      },
      safety: {
        fireSafety: values?.configurations?.safety?.fireSafety || false,
        guards: values?.configurations?.safety?.guards || false,
        cctv: values?.configurations?.safety?.cctv || false
      },
      licAndCert: {
        fa: values?.configurations?.licAndCert?.fa || false,
        iso: values?.configurations?.licAndCert?.iso || false,
        drap: values?.configurations?.licAndCert?.drap || false,
        epa: values?.configurations?.licAndCert?.epa || false
      }
    },
    additionalInstructions: values?.additionalInstructions || '',
    images: values?.files || [],
    supportingDocuments: values?.supportingDocuments || [],
    isActive: values?.isActive || false,
    ...(values?.Manager && { manager: values?.Manager }),
    ...(values?.City && { city: values?.City })
  };
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  businessWarehouseCode: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  cityId: Yup.number().required('Required'),
  capacity: Yup.number().required('Required'),
  pallets: Yup.number().required('Required'),
  managerId: Yup.number().required('Required'),
  humidity: Yup.number(),
  height: Yup.number().required('Required'),
  locationLatlng: Yup.object()
    .shape({
      lat: Yup.number(),
      lng: Yup.number()
    })
    .test('locationLatlng', 'invalid location', (values) => {
      if (!values.lat || !values.lng) {
        return new Yup.ValidationError('Required', null, 'locationLatlng');
      }
      return true;
    }),
  configurations: Yup.object().shape({
    elevatedDocks: Yup.number(),
    racking: Yup.number().required('Required'),
    officeSpace: Yup.number().required('Required'),
    temperature: Yup.array()
      .min(1)
      .of(Yup.string().required('At least one option is required'))
      .required('At least one option is required'),
    typeOfProds: Yup.object().shape({
      dangerous: Yup.bool(),
      fmcg: Yup.bool(),
      manufacturing: Yup.bool()
    }),
    structure: Yup.string().required('Required'),
    transformerVoltAmps: Yup.string(),
    electricPhase: Yup.string(),
    contractDate: Yup.object()
      .shape({
        startDate: Yup.date().required('Required'),
        endDate: Yup.date().required('Required')
      })
      .required('Required'),
    rent: Yup.number().required('Required'),
    operations: Yup.object().shape({
      followHeight: Yup.bool(),
      ventilation: Yup.bool(),
      canopy: Yup.bool(),
      adequateLighting: Yup.bool(),
      forkliftAvail: Yup.bool()
    }),
    buildingAndInfrastructure: Yup.object().shape({
      officeAreaAvail: Yup.bool(),
      powerBackup: Yup.bool(),
      internet: Yup.bool(),
      concealedWiring: Yup.bool(),
      noWaterLeakage: Yup.bool(),
      noCeilingHolesAndWraps: Yup.bool()
    }),
    housekeeping: Yup.object().shape({
      garbageCollection: Yup.bool(),
      pestControl: Yup.bool(),
      dailyCleaning: Yup.bool()
    }),
    safety: Yup.object().shape({
      fireSafety: Yup.bool(),
      guards: Yup.bool(),
      cctv: Yup.bool()
    }),
    licAndCert: Yup.object().shape({
      fda: Yup.bool(),
      iso: Yup.bool(),
      drap: Yup.bool(),
      epa: Yup.bool()
    })
  }),
  additionalInstructions: Yup.string(),
  isActive: Yup.bool()
});

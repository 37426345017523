import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  detailText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: '#000000',
    width: '100%',
    marginBottom: '16px',
    fontFamily: 'Roboto',
    '@media only screen and  (max-width: 1400px) and (min-width:1200px)': {
      fontSize: '14px !important',
      fontWeight: 'bold'
    },
    '@media only screen and (max-width: 1200px) and (min-width:992px)': {
      fontSize: '12px !important',
      fontWeight: 'bold'
    },
    '@media only screen and (max-width: 992px) and (min-width:769px)': {
      fontWeight: 'bold !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      fontWeight: 'bold !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      fontWeight: 'bold !important',
      fontSize: '10px !important'
    },
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      fontWeight: 'bold !important',
      fontSize: '14px !important',
      paddingTop: '15px !important'
    }
  }
}));

export default function DetailTitle({ text, style, className, ...props }) {
  const classes = useStyles();
  const classNames = classes.detailText + ` ${className}`;

  return (
    <Typography className={classNames} style={style} {...props}>
      {text}
    </Typography>
  );
}

import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip
} from '@material-ui/core';
import { useParams } from 'react-router';
import { MdOutlineAdjust } from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';
import { Pagination } from '@material-ui/lab';
import { debounce } from 'lodash';

import addIcon from '../../../assets/icons/addIcon.svg';
import SearchBar from '../../../atomicComponents/SearchBar';
import { DEBOUNCE_CONST } from '../../../Config';
import { checkValidationErr } from '../../../services/BulkUploadServices';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import darkEditIcon from '../../../assets/icons/darkEditIcon.svg';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../../src/utils/toaster';
import AddHallDialog from './AddHallDialog';
import warehouseStyles from './warehouseMakeStyles';
import { exportToCSV, capitalize, SharedContext } from '../../../utils/common';
import useMediaQuery from '@mui/material/useMediaQuery';

const limit = 5;

const SectionManagement = ({ selectedWarehouse }) => {
  const classes = warehouseStyles();
  const { uid } = useParams();

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [halls, setHalls] = useState([]);
  const [openAddHall, setOpenAddHall] = useState(false);
  const [updateHallValues, setUpdateHallValues] = useState(false);
  const [selectedHall, setSelectedHall] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [capacity, setCapacity] = useState({});
  const [warehouseCapacity] = useState({
    capacity: selectedWarehouse?.capacity,
    pallets: selectedWarehouse?.pallets
  });

  const { setAPILoader } = useContext(SharedContext);

  const getHalls = useCallback(
    debounce((page, searchValue) => {
      let filters = {};

      let columns = ['name'];
      _getHalls(page, searchValue, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getHalls(page, searchValue);
  }, [page, searchValue, updateHallValues]);

  const isPhone = useMediaQuery('(max-width:768px)');
  const columns = [
    {
      field: 'isActive',
      headerName: '',
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      type: 'boolean',
      className: (value) => (value ? classes.active : ''),
      renderHeader: () => <DetailTitle text="" />,
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Section name',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      renderHeader: () => <DetailTitle text="Section Name" />
    },
    {
      field: 'pallets',
      headerName: 'Pallets',
      disableColumnMenu: true,
      flex: isPhone ? null : 1,
      width: isPhone ? 180 : null,
      sortable: false,
      type: 'number',
      headerClassName: 'headerCenter',
      cellClassName: 'justify-content-center',
      renderHeader: () => <DetailTitle text="Capacity(Pallets)" />,
      renderCell: (value) => (value && value.value > 0 ? value.value : 0)
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      disableColumnMenu: true,
      flex: isPhone ? null : 1,
      width: isPhone ? 180 : null,
      sortable: false,
      type: 'number',
      headerClassName: 'headerCenter',
      cellClassName: 'justify-content-center',
      renderHeader: () => <DetailTitle text="Capacity(Sq. Ft.)" />,
      renderCell: (value) => (value && value.value > 0 ? value.value : 0)
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      width: 180,
      sortable: false,
      headerClassName: 'headerCenter',
      cellClassName: 'justify-content-center',
      renderHeader: () => <DetailTitle text="Actions" />,
      renderCell: (params) => (
        <Tooltip title="Edit">
          <img
            className={classes.sectionsEditBtn}
            src={darkEditIcon}
            alt="edit"
            onClick={() => {
              handleHallEditView(params.row);
            }}
          />
        </Tooltip>
      )
    }
  ];

  const handleHallClose = () => {
    setOpenAddHall(false);
    setSelectedHall(null);
  };

  const _getHalls = async (page, searchValue, filters, columns) => {
    try {
      const halls = await API.get(`warehouses/${uid}/halls`, {
        params: {
          page,
          limit,
          search: searchValue,
          filters,
          columns
        }
      });
      setPageCount(halls.pages);
      const finalHalls = [...halls.data];
      const finalHallMapper = finalHalls.map((d, index) => ({
        ...d,
        orderId: index
      }));
      const { totalPalletCapacity, totalSqCapacity } =
        finalHallMapper?.reduce((total, singleValue) => {
          if (!total.totalPalletCapacity) total.totalPalletCapacity = 0;
          if (!total.totalSqCapacity) total.totalSqCapacity = 0;

          total.totalPalletCapacity =
            singleValue?.sectionType === 'RACKED' ||
            singleValue?.sectionType === 'GROUNDED'
              ? total?.totalPalletCapacity + singleValue?.pallets
              : total.totalPalletCapacity;

          total.totalSqCapacity =
            singleValue?.sectionType === 'RACKED' ||
            singleValue?.sectionType === 'GROUNDED'
              ? total?.totalSqCapacity + singleValue?.capacity
              : total.totalSqCapacity;
          return total;
        }, {}) || {};
      setCapacity({ pallets: totalPalletCapacity, capacity: totalSqCapacity });
      setHalls(finalHallMapper);
    } catch (err) {
      onError(err);
    }
  };

  const handleHallEditView = (selectedHall) => {
    setOpenAddHall(true);
    setSelectedHall(selectedHall);
    const palletsCapacity = capacity?.pallets - selectedHall?.pallets;
    const updatedCapacity = capacity?.capacity - selectedHall?.capacity;
    setCapacity({
      capacity: updatedCapacity,
      pallets: palletsCapacity
    });
  };

  const addHall = async (data) => {
    try {
      setAPILoader(true);
      if (!selectedHall) {
        await API.post(`warehouses/${uid}/halls`, data);
      } else {
        await API.put(`warehouses/${uid}/halls/${selectedHall.id}`, data);
      }
      toaster(
        'success',
        !selectedHall
          ? 'New Section has been created.'
          : 'Section updated successfully.'
      );
      closeAddHallView();
      _getHalls();
      setUpdateHallValues(!updateHallValues);
    } catch (err) {
      checkValidationErr(
        err,
        'WarehouseHalls.unique_hall_warehouseId must be unique',
        `Section Hall already exists it must be unique`
      );
    } finally {
      setAPILoader(false);
    }
  };

  const closeAddHallView = () => {
    setSelectedHall(null);
    setOpenAddHall(false);
  };

  const handleExportSection = async () => {
    const halls = await API.get(`warehouses/${uid}/halls/export`);
    const hallsData = [...halls.data];

    const sortedHalls = getSortedHalls(hallsData);

    const exportSortedHalls = sortedHalls.map((hall) => {
      return {
        name: hall?.name,
        sectionType: capitalize(hall?.sectionType),
        capacity: hall?.capacity,
        pallets: hall?.pallets,
        isActive: hall?.isActive,
        classifications: hall?.classifications
      };
    });

    const title = `${selectedWarehouse.name} Sections`;
    const header = [
      'SECTION NAME',
      'SECTION TYPE',
      'CAPACITY (SQ. FT)',
      'CAPACITY (PALLETS)',
      'IS ACTIVE',
      'CLASSIFICATION'
    ];

    exportToCSV(header, exportSortedHalls, title);
  };

  const getSortedHalls = (halls) => {
    const sortOrder = {
      id: 1,
      warehouseName: 2,
      name: 3,
      capacity: 4,
      pallets: 5,
      isActive: 6
    };

    halls.map((hall) => {
      delete hall['memo'];
      delete hall['createdAt'];
      delete hall['createdBy'];
      delete hall['deletedAt'];
      delete hall['updatedAt'];
      delete hall['warehouseId'];
      hall['warehouseName'] = selectedWarehouse.name;
    });

    const sortedHalls = halls.map((hall) =>
      Object.assign(
        {},
        ...Object.keys(hall)
          .sort((a, b) => sortOrder[a] - sortOrder[b])
          .map((x) => {
            return { [x]: hall[x] };
          })
      )
    );

    return sortedHalls;
  };

  return (
    <>
      <TableContainer className={classes.sectionContainer}>
        <Table aria-label="sticky table" className="warehouseTable">
          <TableHead>
            <div className={classes.sectionsDataGridHead}>
              <SearchBar
                setSearchValue={(e) => {
                  setPage(1);
                  setSearchValue(e);
                }}
                placeholder="Search section name"
              />
              <div className="display-flex dockWarehouseBtns">
                <Tooltip title="Export as csv">
                  <button
                    id="exportBtn"
                    className={classes.sectionsBtn}
                    onClick={handleExportSection}
                  >
                    <p>Export</p>
                  </button>
                </Tooltip>
                <Tooltip title="Add Section">
                  <button
                    className={classes.sectionsBtn}
                    onClick={() => setOpenAddHall(true)}
                  >
                    <img src={addIcon} alt="add-icon" />
                    <p>ADD</p>
                  </button>
                </Tooltip>
              </div>
            </div>
          </TableHead>
          <TableBody>
            <div
              className={
                classes.sectionsDataGridParent + ' ' + classes.sectionBox
              }
            >
              <DataGrid
                columns={columns}
                rows={halls}
                pageSize={limit}
                rowsPerPageOptions={[limit]}
              />
            </div>
          </TableBody>
          <div className={classes.sectionBox}>
            <Pagination
              component="div"
              shape="rounded"
              count={pageCount}
              color="primary"
              page={page}
              className={classes.pagination}
              onChange={(e, newPage) => setPage(newPage)}
            />
          </div>
        </Table>
      </TableContainer>
      {openAddHall && (
        <AddHallDialog
          open
          handleClose={handleHallClose}
          addHall={addHall}
          selectedHall={selectedHall}
          capacity={capacity}
          warehouseCapacity={warehouseCapacity}
        />
      )}
    </>
  );
};

export default SectionManagement;

import React, { useState, useRef, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import delIcon from '../../../assets/icons/del.svg';
import ExcelIcon from '../../../assets/icons/excel.svg';
import { Grid } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ImagePreview = ({ file }) => {
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (file?.location) {
      setPreview(file.location);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }, []);

  return (
    <div className="h-12 w-full border border-[#e0e0e0] relative">
      {preview && preview?.includes('pdf') ? (
        <embed className="image-preview br-3 w-12 h-12" src={preview} />
      ) : preview?.includes(
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml'
        ) || preview?.includes('.xlsx') ? (
        <img className="image-preview br-3 w-12 h-12" src={ExcelIcon} />
      ) : (
        <img className="image-preview br-3 w-12 h-12" src={preview} />
      )}
      {
        <div
          className="image-preview-doc"
          onClick={async () => {
            let fileUrl;
            if (!file?.location) fileUrl = URL.createObjectURL(file);
            else {
              fileUrl = file.location;
            }
            const a = document.createElement('a');
            a.href = fileUrl;
            a.target = '_blank';
            a.click();
          }}
        >
          <VisibilityIcon />
        </div>
      }
    </div>
  );
};

export const ImagesList = ({ formik, disableUpload = false }) => {
  const inputRef = useRef(null);
  const [imagesArr, setImagesArr] = useState(formik?.values?.images || []);

  useEffect(() => {
    formik?.values?.images?.length && setImagesArr(formik?.values?.images);
  }, [formik?.values?.images]);

  const validateImage = async (event) => {
    const checkFile = event.target.files[0];
    if (checkFile) {
      if (!checkFile.name.toLowerCase().match(/\.(jpg|jpeg|png|pdf|xlsx)$/)) {
        alert('Only images or PDF file allowed!');
        return false;
      }
      const isLt2M = checkFile.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        alert('File must be smaller than 1MB!');
        return false;
      }

      const imgs = [...imagesArr, checkFile];
      setImagesArr(imgs);
      formik?.setFieldValue('images', imgs);
    }
  };

  return (
    <Grid container spacing={2} style={{ width: '300px' }}>
      {imagesArr?.length
        ? imagesArr?.map((file, id) => (
            <Grid key={id} item xs={3} style={{ position: 'relative' }}>
              <ImagePreview file={file} />
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  right: '-2%',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  let imgs = [...imagesArr];
                  imgs = imgs.slice(0, id).concat(imgs.slice(id + 1));
                  setImagesArr(imgs);
                  formik.setFieldValue('images', imgs);

                  if (file.id) {
                    let docs = formik.values.supportingDocument || [];
                    docs = docs.filter((doc) => doc !== file.id);
                    formik.setFieldValue('supportingDocument', docs);
                  }
                }}
              >
                <img src={delIcon} />
              </div>
            </Grid>
          ))
        : null}
      <Grid xs={3} item>
        <div className="">
          <button
            type="button"
            className="flex justify-center items-center h-12 w-12 border-primary border text-white"
            onClick={() => inputRef.current.click()}
            disabled={disableUpload}
          >
            <AddIcon style={{ color: '#0C70D7' }} fontSize="medium" />
          </button>
          <input
            ref={inputRef}
            hidden
            type="file"
            onChange={(e) => validateImage(e)}
            accept=".jpg,.png,.jpeg,.pdf,.xlsx"
          />
        </div>
      </Grid>
    </Grid>
  );
};

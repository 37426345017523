import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Bar from '../components/Bar';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { Label, Title } from '../../../core-components/atoms/Text';
import BottomBarSkeleton from '../components/BottomBarSkeleton';
import NoDataFloorOperator from '../components/NoDataFloorOperator';
const Legend = () => (
  <div className="flex justify-center items-center mt-4">
    <div className="flex items-center mr-4">
      <span className="inline-block w-3 h-3 bg-primaryBlue mr-2" />
      <Label variant={'xs'} className={''}>
        PICKING
      </Label>
    </div>
    <div className="flex items-center mr-4">
      <span className="inline-block w-3 h-3 bg-navyBlue mr-2" />
      <Label variant={'xs'} className={''}>
        PUTAWAY
      </Label>
    </div>
    <div className="flex items-center">
      <span className="inline-block w-3 h-3 bg-oasisPrimary300 mr-2" />
      <Label variant={'xs'} className={''}>
        CYCLE COUNT
      </Label>
    </div>
  </div>
);

const BestFloorOperators = ({ nodeIds, endDate, startDate }) => {
  const [floorOperator, setFloorOperator] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);

  useEffect(() => {
    setIsLoading(true);
    getDelayedFloorOperator(startDate, endDate, nodeIdsMemo);
  }, [startDate, endDate, nodeIdsMemo]);

  const getDelayedFloorOperator = useCallback(
    debounce(
      async (startDate, endDate, nodeIds) => {
        getFloorOperators(startDate, endDate, nodeIds);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getFloorOperators = async (startDate, endDate, nodeIds) => {
    try {
      const data = await API.get(`intelligence/best-floor-operators`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          nodeIds: nodeIds
        }
      });
      setFloorOperator(data);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return (
      <div className="p-4 bg-white">
        <Title variant={'md'} className={'mb-4'}>
          Best Floor Operators
        </Title>
        <BottomBarSkeleton />
        <Legend />
      </div>
    );
  }

  return (
    <div className="p-4 bg-white">
      <Title variant={'md'} className={'mb-4'}>
        Best Floor Operators
      </Title>
      {floorOperator?.length > 0 ? (
        floorOperator?.map((operator, index) => (
          <Bar key={index} data={operator} index={index} />
        ))
      ) : (
        <NoDataFloorOperator />
      )}
      <Legend />
    </div>
  );
};

export default BestFloorOperators;

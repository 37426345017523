import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  headerBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box'
  },
  topHeader: {
    boxSizing: 'border-box',
    paddingRight: 5
  },
  heading: {
    fontWeight: 'bolder',
    boxSizing: 'border-box',
    padding: 20
  },
  subHeading: {
    fontWeight: 'normal',
    boxSizing: 'border-box',
    padding: 20,
    fontSize: 18
  },
  systemAlert: {
    marginBottom: 10
  },
  uploadDetails: {
    boxSizing: 'border-box',
    padding: 20,
    height: '10%'
  },
  downloadTempBtn: {
    marginRight: 5
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  subHeadingGuideline: {
    fontWeight: 'normal',
    boxSizing: 'border-box',
    padding: 20,
    fontSize: 18,
    paddingBottom: 0
  },
  guidelines: {
    boxSizing: 'border-box',
    padding: 20,
    '@media (max-width: 767px)': {
      padding: 0
    }
  },
  guideLine: {
    marginTop: 5
  },
  gridContainer: {
    width: '88%',
    margin: 'auto',
    '@media only screen and (max-width: 1200px) and (min-width: 993px)': {
      width: '100%'
    }
  },
  mainContent: {
    width: '80%',
    margin: 'auto'
  },
  submitBtn: {
    paddingTop: '5px',
    paddingBottom: '5px',
    background: '#4B89C8',
    border: '2px solid #4B89C8',
    borderRadius: '8px',
    color: '#FFFFFF',
    width: '100%',
    height: '32px',
    cursor: 'pointer'
  },
  cancelBtn: {
    paddingTop: '5px',
    paddingBottom: '5px',
    background: '#FCFDFF',
    border: '2px solid #4B89C8',
    borderRadius: '8px',
    color: '#4B89C8',
    width: '100%',
    height: '32px',
    cursor: 'pointer !important',
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      border: '2px solid rgba(0, 0, 0, 0.26) !important',
      cursor: 'no-drop !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      fontSize: '11px !important'
    }
  },
  addNodeBtn: {
    height: '56px',
    width: '56px',
    borderRadius: '8px',
    border: '2px solid #4B89C8',
    background: '#4B89C8',
    cursor: 'pointer !important',
    marginLeft: '5px',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '30px',
      height: '44px'
    },
    '@media (min-width: 993px) and (max-width:1024px)': {
      width: '35px',
      height: '54px'
    }
  },
  textArea: {
    marginTop: '10px',
    borderRadius: '8px',
    padding: '5px',
    border: '1px solid #EDF3FA',
    background: '#EDF3FA'
  },
  timePicker: {
    marginTop: '10px',
    background: '#EDF3FA',
    borderRadius: '8px',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  dataGridContainer: {
    height: '320px !important',
    width: '100%'
  },
  gridFooter: {
    position: 'absolute !important',
    bottom: '104px !important'
  },
  footerGrid: {
    width: '100%',
    height: '112px',
    marginTop: '30px',
    background: '#FAFAFC'
  },
  tableProductFields: {
    width: '80%'
  },
  footerGridItem: {
    textAlign: 'right !important',
    margin: 'auto'
  },
  footerDownloadBtn: {
    border: '2px solid #4B89C8',
    borderRadius: '8px',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    marginTop: '5px'
  },
  footerUploadBtn: {
    border: '2px solid #4B89C8',
    borderRadius: '8px',
    minWidth: '32px !important',
    height: '32px',
    cursor: 'pointer',
    marginTop: '5px',
    padding: '0px'
  },
  footerAddBtn: {
    border: '2px solid #4B89C8',
    borderRadius: '8px',
    background: '#4B89C8',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    marginTop: '5px'
  },
  dialogContainer: {
    maxWidth: '100% !important'
  },
  locationDetail: {
    marginTop: '25px'
  },
  secAddress: {
    marginLeft: '10px',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      paddingTop: '20px',
      marginLeft: '0px'
    },
    '@media only screen and (max-width: 768px) and (min-width: 578px)': {
      marginLeft: '0px',
      marginTop: '20px'
    }
  },
  addressDetail: {
    marginTop: '15px',
    maxWidth: '1200px',
    boxSizing: 'border-box'
  },
  calenderIcon: {
    marginRight: '10px'
  },
  detailInstructions: {
    margin: '30px 0 5px 0'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  backdropGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: '6px 0px'
    }
  },
  cancelDockBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '100%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #D9DFE5',
    background: '#D9DFE5',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#979B9F',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '90%',
      marginLeft: '6px'
    }
  },
  submitDockBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '100%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #4B89C8',
    background: '#4B89C8',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#FFFFFF',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '90%',
      marginLeft: '5px'
    }
  },
  buttonGrid: {
    justifyContent: 'space-evenly'
  },

  detailText: {
    margin: '10px 0',
    'line-height': 1.1
  },
  opactiy5: {
    opacity: 0.5
  },
  paddingTop12: {
    paddingTop: 12
  },
  createButton: {
    color: 'white',
    backgroundColor: '#4B89C8',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    }
  },
  cancelButton: {
    border: '1px solid #4B89C8',
    color: '#4B89C8',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    }
  },
  margin20tb: {
    margin: '20px 0'
  },
  faulty: {
    color: 'red',
    marginLeft: 5
  },
  viewButton: {
    background: 'transparent',
    border: 'none',
    fontSize: '1rem',
    fontWeight: 400,
    color: 'inherit',
    cursor: 'pointer'
  },
  printHeaders: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: '#000000',
    marginRight: '5px'
  },
  pageHeading: {
    fontWeight: 600,
    fontSize: '16px'
  },
  baseColor: {
    color: '#4B89C8'
  },
  head: {
    background: '#EDF3FA',
    width: '100%'
  },
  margin0: {
    margin: '0 5px 0 0 !important'
  },
  padding5mm: {
    padding: '5mm 5mm 0mm 5mm'
  },
  nodeBulkButtons: {
    '@media (max-width: 767px)': {
      marginBottom: '4px !important'
    }
  }
}));

export default useStyles;

import MoveTypeIcon from '../../orders/moveTypeIcon';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import React from 'react';
import moment from 'moment/moment';
import clsx from 'clsx';
import { constants } from '../../revampedOrder/constants';
import tlds from 'tlds';

const Chat = ({ chat, newConversation, onClick, search }) => {
  const renderContentWithMentions = (comment) => {
    const content = comment?.content ? comment?.content : 'No comment yet';
    const lines = content?.split('\n') || [];

    const emojiRegex =
      /\p{Emoji}|\p{Emoji_Presentation}|\p{Emoji_Modifier_Base}/gu;
    const currencyRegex =
      /^(?:[A-Z]{2,3}|[$€¥£₹₽₱₩₪₨៛₮₴₣₡₣₢₦¤]|R$|R\$)?\s?\d+(?:[.,]\d+)?$/i;
    const urlRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)/i;
    return lines.map((line, lineIndex) => {
      const words = line.split(' ');
      return (
        <React.Fragment key={lineIndex}>
          {words.map((word, wordIndex) => {
            if (word.startsWith('@')) {
              const mentionedUser = comment?.MentionedUsers?.find(
                (val) => val?.User?.username === word.replace(/@/g, '')
              );
              if (mentionedUser) {
                return (
                  <span
                    key={wordIndex}
                    style={{
                      color: '#0C6BD7',
                      fontWeight: '500',
                      fontSize: '12px'
                    }}
                  >
                    {word}{' '}
                  </span>
                );
              }
            } else if (currencyRegex.test(word)) {
              // Check if word is a number (optionally preceded by currency) followed by a dot
              return (
                <span
                  key={wordIndex}
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {word}{' '}
                </span>
              );
            } else if (emojiRegex.test(word)) {
              return (
                <span
                  key={wordIndex}
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {word}{' '}
                </span>
              );
            } else if (urlRegex.test(word)) {
              const domain = extractDomain(word);
              if (isValidDomain(domain)) {
                // Prepend "https://" to the URL if no protocol is provided
                const url = word.startsWith('http') ? word : `https://${word}`;
                return (
                  <a
                    key={wordIndex}
                    href={url}
                    style={{
                      color: 'blue',
                      borderBottom: '1px solid blue'
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {word}{' '}
                  </a>
                );
              }
            }
            return <React.Fragment key={wordIndex}>{word} </React.Fragment>;
          })}
          <br /> {/* Add a line break after each line */}
        </React.Fragment>
      );
    });
  };

  function extractDomain(url) {
    // Regular expression to extract the domain part from a URL
    const domainRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)/i;
    const match = url.match(domainRegex);
    return match ? match[1] : null;
  }

  function isValidDomain(domain) {
    const parts = domain.split('.');
    const tld = parts[parts.length - 1]; // Extract the TLD

    // Check if the TLD is in the list of valid TLDs
    return tlds.includes(tld);
  }

  const orderStatus = constants.ORDER_STATUS[chat?.status];

  return (
    <div
      className={'border-b p-4 flex-1 flex'}
      onClick={() => {
        onClick(chat);
      }}
    >
      {!newConversation ? (
        <>
          <MoveTypeIcon type={chat?.moveType} />
          <div className={'flex-1'}>
            <div className={'flex flex-1 justify-between'}>
              <div className={'flex items-center'}>
                <Title variant={'sm'} className={'text-primary mr-2 font-bold'}>
                  {chat?.customId}
                </Title>
                <Paragraph variant={'xs'}>{orderStatus}</Paragraph>
              </div>
              {!newConversation && chat?.UnreadComments?.length > 0 && (
                <Label
                  variant={'xs'}
                  className={
                    'bg-primary text-white py-1 px-2 text-center rounded-full'
                  }
                >
                  {chat?.UnreadComments?.length}
                </Label>
              )}
            </div>
            <div className={'flex flex-1 justify-between items-center'}>
              <Title
                variant={'sm'}
                className={clsx(
                  'truncate max-w-[250px]',
                  chat?.UnreadComments?.length > 0
                    ? 'font-medium'
                    : 'font-normal',
                  chat?.lastComment?.content
                    .toLowerCase()
                    .includes(search?.toLowerCase()) &&
                    (search ? 'bg-[#fff0c3]' : '')
                )}
              >
                {renderContentWithMentions(chat?.lastComment)}
              </Title>
              <Paragraph variant={'xs'} className={'text-[#696F80]'}>
                {chat?.lastComment?.updatedAt
                  ? moment(chat?.lastComment?.updatedAt)?.fromNow()
                  : ''}
              </Paragraph>
            </div>
          </div>
        </>
      ) : (
        <div className={'flex items-center'}>
          <Title variant={'sm'} className={'mr-2 font-bold'}>
            {chat?.customId}
          </Title>
          <Title variant={'sm'} className={'mr-2'}>
            •
          </Title>
          <Paragraph variant={'xs'}>{orderStatus}</Paragraph>
        </div>
      )}
    </div>
  );
};

export default Chat;

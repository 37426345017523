/* eslint-disable no-unused-vars */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import API from '../../../libs/axios';
import { isRequired } from '../../../utils/validators';
export default function EditGroupAccessView({
  open,
  handleClose,
  selectedUser,
  addPermissionToGroupAccess,
  removePermissionToGroupAccess,
  formErrors,
  createdPermission,
  updatedPermission
}) {
  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [roleId, setRoleId] = useState('');
  const [userId, setUserId] = useState('');
  const [allPermissions, setAllPermissions] = useState({});
  const [permissionMapperOld, setPermissionMapperOld] = useState({});
  const [permissionMapper, setPermissionMapper] = useState({});
  const [changedPermissions, setChangedPermissions] = useState([]);
  const [updatePermission, setUpdatePermission] = useState(false);
  const disableProps = ['Super', 'Admin', 'GroupAccess', 'Permission'];

  useEffect(() => {
    if (selectedUser) {
      setName(selectedUser.name || '');
      setRoleId(selectedUser.roleId || '');
      setUserId(selectedUser.id || '');
      setAllPermissions({});
      setPermissionMapperOld({});
      setPermissionMapper({});
      setChangedPermissions([]);
      setUpdatePermission(
        createdPermission || updatedPermission ? true : false
      );
      getAllPermissions();
    }
  }, [selectedUser]);

  const handleChange = (id, key, value) => {
    const mapped = { ...permissionMapper };
    mapped[key][id] = value;
    delete mapped[key][key];
    const isAll = Object.values(mapped[key]).every((val) => !!val);
    mapped[key][key] = isAll;
    setPermissionMapper(mapped);
    setChangedPermissions((changedPermissions) => [...changedPermissions, key]);
  };

  const handleChangeAll = (key, value) => {
    const mapped = { ...permissionMapper };
    Object.keys(mapped[key]).map((d) => {
      mapped[key][d] = value;
    });
    setPermissionMapper(mapped);
    setChangedPermissions((changedPermissions) => [...changedPermissions, key]);
  };

  const handleSubmit = () => {
    const newPermissions = [...new Set(changedPermissions)];

    const bindPermissions = [];
    const unBindPermissions = [];
    newPermissions.map((d) => {
      delete permissionMapper[d][d];
      Object.keys(permissionMapper[d]).map((data) => {
        if (permissionMapper[d][data] && !permissionMapperOld[d][data])
          bindPermissions.push(data);
        if (!permissionMapper[d][data] && permissionMapperOld[d][data])
          unBindPermissions.push(data);
      });
    });

    const newPermissionGroupAccess = {
      userId,
      permissionIds: bindPermissions.map((permission) => parseInt(permission))
    };

    const removePermissionGroupAccess = {
      userId,
      permissionIds: unBindPermissions.map((permission) => parseInt(permission))
    };

    if (
      isRequired(userId) &&
      isRequired(bindPermissions) &&
      bindPermissions.length
    ) {
      addPermissionToGroupAccess(newPermissionGroupAccess);
    }
    if (
      isRequired(userId) &&
      isRequired(unBindPermissions) &&
      unBindPermissions.length
    ) {
      removePermissionToGroupAccess(removePermissionGroupAccess);
    }
    handleClose();
  };

  const getAllPermissions = async () => {
    const unAttached = (await getUnAttchedPermissions()) || [];
    const attached = (await getAttchedPermissions()) || [];
    const allPermissions = unAttached.concat(attached);
    const permissionGroup = {};
    allPermissions.map((d) => {
      if (!d) {
        return;
      }

      if (d.name.indexOf('Vendor') !== -1) {
        return;
      }
      const name = d.name.split(' ')[0];
      if (permissionGroup[name]) {
        permissionGroup[name].push(d);
      } else {
        if (!disableProps.find((namee) => namee == name)) {
          permissionGroup[name] = [d];
        }
        // permissionGroup[name] = [d]
      }
    });
    setAllPermissions(permissionGroup);
    const objectPermissionMapper = {};

    Object.keys(permissionGroup).map((data) => {
      objectPermissionMapper[data] = {};
      permissionGroup[data].map((d) => {
        objectPermissionMapper[data][d.id] = d.checked;
      });
      const parent = permissionGroup[data].find((d) => d.checked === false);
      objectPermissionMapper[data][data] = !parent || ![parent].length;
    });
    const old = { ...objectPermissionMapper, old: true };
    setPermissionMapperOld(JSON.parse(JSON.stringify(old)));
    setPermissionMapper(objectPermissionMapper);
  };

  const getAttchedPermissions = async () => {
    if (!selectedUser || !selectedUser.id) return;
    const { groupAccess } = await API.get(
      `group-accesses?userId=${selectedUser.id}`
    );
    const attached = groupAccess.length
      ? groupAccess.map((d) => {
          if (d && d.Permission) return { ...d.Permission, checked: true };
        })
      : [];
    return attached;
  };

  const getUnAttchedPermissions = async () => {
    if (!selectedUser || !selectedUser.id) return;
    const { permissionAccess } = await API.get(
      `group-accesses/un-attached?userId=${selectedUser.id}&roleId=${selectedUser.roleId}`
    );
    const unAttached = permissionAccess.length
      ? permissionAccess.map((d) => {
          if (d && d.Permission && !d.Permission.deletedAt)
            return { ...d.Permission, checked: false };
        })
      : [];

    return unAttached;
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          onBackdropClick={() => {
            setValidation('');
          }}
        >
          <DialogTitle>
            <h1 style={{ textAlign: 'center' }}> {'User Access'} </h1>
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Typography>
                {Object.keys(allPermissions).length
                  ? Object.keys(allPermissions).map((data, index) => {
                      return (
                        <div key={index}>
                          <div style={{ fontWeight: 'bold' }}>
                            <FormControlLabel
                              key={data}
                              control={
                                <Checkbox
                                  disabled={!updatePermission}
                                  checked={
                                    permissionMapper[data]
                                      ? permissionMapper[data][data]
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleChangeAll(data, e.target.checked)
                                  }
                                  name={data}
                                />
                              }
                              label={
                                <p style={{ fontWeight: 'bold' }}>{data}</p>
                              }
                            />
                          </div>
                          <div style={{ marginLeft: '30px' }}>
                            {allPermissions[data].map((d) => {
                              return (
                                <FormControlLabel
                                  key={d.id}
                                  control={
                                    <Checkbox
                                      disabled={!updatePermission}
                                      checked={
                                        permissionMapper[data]
                                          ? permissionMapper[data][d.id]
                                          : false
                                      }
                                      onClick={(e) =>
                                        handleChange(
                                          d.id,
                                          data,
                                          e.target.checked
                                        )
                                      }
                                      name={d.id}
                                    />
                                  }
                                  label={d.name}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </Typography>
              {/* </AccordionDetails>
              </Accordion> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  // setExplicitReRender(!explicitReRender);
                  setValidation('');
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title="Update">
              <Button
                disabled={!updatePermission}
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                {'Update'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

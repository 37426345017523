import React, { useEffect, useState } from 'react';
import { Paragraph } from '../../../core-components/atoms/Text';
import { makeStyles, Paper, Popper } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClickAwayListener } from '@mui/base';
import clsx from 'clsx';
// import { debounce } from 'lodash';
// import { DEBOUNCE_CONST } from '../../../Config';
import {
  getInventoryOrdersInTransit,
  getInventoryOrdersReserved
} from '../../../apis/wms';
import { onError } from '../../../libs/errorLib';

const useStyles = makeStyles(() => ({
  popper: {
    '& ::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      height: '4px'
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#9DA0A6'
    },
    zIndex: 1300
  },
  paper: {
    pointerEvents: 'auto',
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    marginTop: '2px',
    boxShadow:
      '0px 6.400000095367432px 14.399999618530273px 0px #00000021,0px 1.2000000476837158px 3.5999999046325684px 0px #0000001A',
    overflowY: 'auto',
    '& ::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      height: '4px'
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#9DA0A6'
    },
    '& input': {
      fontSize: '12px !important'
    }
  }
}));

const OrderList = ({
  open,
  setOpen,
  anchorRef,
  isReserved,
  inventoryDetail
}) => {
  const [ordersList, setOrderList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const classes = useStyles();

  function openFileInNewTab(url) {
    const newTab = window.open(`${window.location.origin}${url}`, '_blank');
    newTab.focus();
  }

  useEffect(() => {
    if (open) {
      getOrdersList(inventoryDetail?.id, page, []);
    }
  }, [page, inventoryDetail, open]);

  const getOrdersList = async (inventoryId, page, oldData) => {
    try {
      const data = isReserved
        ? await getInventoryOrdersReserved({ id: inventoryId, page })
        : await getInventoryOrdersInTransit({ id: inventoryId, page });
      if (data) {
        const newArr = data?.data;
        setOrderList([...newArr, ...oldData]);
        setPageCount(data?.pages);
      }
    } catch (error) {
      onError(error);
    }
  };

  return (
    <div style={{ display: 'inline' }}>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={'bottom-end'}
        disablePortal
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Paper className={classes.paper} id={'scrollableDiv'}>
            <div className={'w-[200px] max-h-[400px] p-4'}>
              <div
                className={clsx(
                  'px-1.5 py-3 flex justify-between items-center',
                  ' bg-white'
                )}
              >
                {isReserved && (
                  <Paragraph variant={'xs'} className={'text-dark500'}>
                    Picking ID
                  </Paragraph>
                )}
                <Paragraph variant={'xs'} className={'text-dark500'}>
                  Order ID
                </Paragraph>
                <Paragraph variant={'xs'} className={'text-dark500'}>
                  Qty
                </Paragraph>
              </div>
              <InfiniteScroll
                dataLength={ordersList.length}
                next={async () => {
                  setPage(page + 1);
                  await getOrdersList(inventoryDetail?.id, page, ordersList);
                }}
                style={{
                  overflow: 'hidden'
                }}
                hasMore={page < pageCount}
                pullDownToRefresh={false}
                loader={
                  <div
                    className={
                      'flex flex-1 overflow-hidden pb-4 justify-center'
                    }
                  >
                    <CircularProgress size={20} />
                  </div>
                }
                scrollThreshold={0.1}
                scrollableTarget="scrollableDiv"
              >
                {ordersList?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={clsx(
                        'px-1.5 py-3 flex justify-between items-center',
                        index % 2 === 0 ? ' bg-white' : ' bg-gray-200'
                      )}
                    >
                      {isReserved && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            openFileInNewTab(
                              `/routing/picking/view/${value?.taskId}`
                            );
                          }}
                        >
                          <Paragraph
                            variant={'xs'}
                            className={'text-oasisPrimary500 select-none'}
                          >
                            {value?.taskId}
                          </Paragraph>
                        </button>
                      )}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openFileInNewTab(
                            `/routing/view-order/${
                              isReserved
                                ? value?.orderId
                                : value?.Order?.customId
                            }`
                          );
                        }}
                      >
                        <Paragraph
                          variant={'xs'}
                          className={'text-oasisPrimary500 select-none'}
                        >
                          {isReserved ? value?.orderId : value?.Order?.customId}
                        </Paragraph>
                      </button>
                      <Paragraph variant={'sm'}>{value?.quantity}</Paragraph>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default OrderList;

/*eslint-disable*/

import React from 'react';
import { Paragraph, Title } from '../../core-components/atoms/Text';
import moment from 'moment';
import clsx from 'clsx';
import tlds from 'tlds';
import ChatAILoaderIcon from '../../assets/icons/chat_ai_loader.svg';
import OAI from '../../assets/icons/OAi.png';

const Comment = ({ comment, loader, comments }) => {
  const renderContentWithMentions = () => {
    const lines = comment?.message?.split('\n') || [];
    const emojiRegex =
      /\p{Emoji}|\p{Emoji_Presentation}|\p{Emoji_Modifier_Base}/gu;
    const currencyRegex =
      /^(?:[A-Z]{2,3}|[$€¥£₹₽₱₩₪₨៛₮₴₣₡₣₢₦¤]|R$|R\$)?\s?\d+(?:[.,]\d+)?$/i;
    const urlRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)/i;

    return lines.map((line, lineIndex) => {
      const words = line.split(' ');
      return (
        <React.Fragment key={lineIndex}>
          {words.map((word, wordIndex) => {
            if (currencyRegex.test(word)) {
              return (
                <span
                  key={wordIndex}
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {word}{' '}
                </span>
              );
            } else if (emojiRegex.test(word)) {
              return (
                <span
                  key={wordIndex}
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {word}{' '}
                </span>
              );
            } else if (urlRegex.test(word)) {
              const domain = extractDomain(word);
              if (isValidDomain(domain)) {
                // Prepend "https://" to the URL if no protocol is provided
                const url = word.startsWith('http') ? word : `https://${word}`;
                return (
                  <a
                    key={wordIndex}
                    href={url}
                    style={{
                      color: 'blue',
                      borderBottom: '1px solid blue'
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {word}{' '}
                  </a>
                );
              }
            }
            return <React.Fragment key={wordIndex}>{word} </React.Fragment>;
          })}
          <br />
        </React.Fragment>
      );
    });
  };

  function extractDomain(url) {
    const domainRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)/i;
    const match = url.match(domainRegex);
    return match ? match[1] : null;
  }

  function isValidDomain(domain) {
    const parts = domain.split('.');
    const tld = parts[parts.length - 1];
    return tlds.includes(tld);
  }

  return (
    <div className={'flex gap-4'}>
      <div
        className={clsx(
          'grid rounded-full h-8 w-8 place-items-center',
          'Oai' === comment?.name ? 'bg-[#0B1940]' : 'bg-[#E1E5F0]'
        )}
      >
        {'Oai' === comment?.name ? (
          <img src={OAI} alt="oai" className="rounded-full" />
        ) : (
          <Paragraph
            variant={'sm'}
            className={clsx(
              'Oai' === comment?.name
                ? 'text-white uppercase'
                : 'text-[#0B1940] uppercase'
            )}
          >
            {comment?.name
              ?.split(' ')
              ?.filter(Boolean)
              ?.map((word, index, array) =>
                index === 0 || index === array.length - 1 ? word.charAt(0) : ''
              )
              .join('')}
          </Paragraph>
        )}
      </div>
      <div className={'flex-1 items-center'}>
        <div className={'flex justify-between items-center overflow-hidden'}>
          <div className={'flex gap-2 items-center'}>
            <Title variant={'sm'}>{comment?.name}</Title>
            <Paragraph variant={'xs'} className={'text-[#696F80]'}>
              {moment(comment?.updatedAt)?.fromNow()}
            </Paragraph>
          </div>
        </div>
        {loader && comments?.[0]?.id === comment.id ? (
          <Paragraph variant={'sm'} className={'mt-1.5'}>
            <img
              alt="empty-conversation"
              className={'h-5 w-20'}
              src={ChatAILoaderIcon}
            />
          </Paragraph>
        ) : (
          <Paragraph variant={'sm'} className={'mt-1.5'}>
            {renderContentWithMentions()}
          </Paragraph>
        )}
      </div>
    </div>
  );
};

export default Comment;

import { Tooltip } from '@mui/material';
import { MdOutlineAdjust } from 'react-icons/md';

const bayVerificationColumns = [
  {
    field: 'isActive',
    headerName: '',
    disableColumnMenu: true,
    sortable: false,
    cellClassName: 'justify-content-center',
    headerClassName: 'headerCenter',
    width: 50,
    renderCell: (params) => (
      <Tooltip title={params?.row?.message ?? 'verified bay'}>
        <span className="table-cell-trucate">
          <MdOutlineAdjust
            className={
              params?.row?.errorLevel === 'Correct' ? 'online' : 'offline'
            }
          />
        </span>
      </Tooltip>
    )
  },
  {
    field: 'name',
    width: 150,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Name</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.name}`}>
        <span className="table-cell-trucate">{params?.row?.name}</span>
      </Tooltip>
    )
  },
  {
    field: 'rowLevel',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Row Level</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.message}`}>
        <span className="table-cell-trucate">{params?.row?.message}</span>
      </Tooltip>
    )
  },
  {
    field: 'Bay',
    width: 150,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Bay</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.errorLevel}`}>
        <span className="table-cell-trucate">{params?.row?.errorLevel}</span>
      </Tooltip>
    )
  }
];

export default bayVerificationColumns;

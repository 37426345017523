import { Box, Tab } from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import OrderCard from '../../atomicComponents/OrderCard';
import FloatingActionButton from '../../atomicComponents/FloatingActionButton';

const OrderManagementMobile = ({
  orders,
  onCreateOrder,
  orderCreatable,
  handleTabChange,
  orderStats,
  tabValue,
  organization,
  closeOrder,
  ...props
}) => {
  return (
    <div className="p-1">
      {orderCreatable && <FloatingActionButton onClick={onCreateOrder} />}
      <div className="w-full md:w-auto">
        <TabContext value={tabValue}>
          <Box>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                className="min-w-fit"
                label={
                  <span className="flex">All ({orderStats?.[0]?.value})</span>
                }
                value={0}
              />
              <Tab
                className="min-w-fit"
                label={
                  <span className="flex">
                    Active ({orderStats?.[5]?.value})
                  </span>
                }
                value={1}
              />
              <Tab
                className="min-w-fit"
                label={
                  <span className="flex">Draft ({orderStats?.[4]?.value})</span>
                }
                value={2}
              />
              <Tab
                className="min-w-fit"
                label={
                  <span className="flex">
                    Completed ({orderStats?.[1]?.value})
                  </span>
                }
                value={3}
              />
              <Tab
                className="min-w-fit"
                label={
                  <span className="flex">
                    Closed ({orderStats?.[2]?.value})
                  </span>
                }
                value={4}
              />
              <Tab
                className="min-w-fit"
                label={
                  <span className="flex">
                    Cancelled ({orderStats?.[3]?.value})
                  </span>
                }
                value={5}
              />
            </TabList>
          </Box>
        </TabContext>
      </div>

      <div className="mt-4 space-y-4">
        {orders.length === 0 ? (
          <div
            style={{
              textAlign: 'center',
              color: 'black',
              fontSize: '14px',
              alignItems: 'center'
            }}
            className="empty-box"
          >
            <p>No Orders Available</p>
          </div>
        ) : (
          orders.map((order) => (
            <OrderCard
              key={order.id}
              order={order}
              closeOrder={closeOrder}
              organization={organization}
              {...props}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default OrderManagementMobile;

/*eslint-disable*/
import Popup from '../../../../core-components/atoms/Popup';
import Button from '../../../../core-components/atoms/Button';
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { SharedContext } from '../../../../utils/common';
import DriverTypeForm from './driverForm';
import { upload } from '../../../../utils/upload';

const AddDriverPopup = ({
  open,
  setOpen,
  organizationType,
  selectedDriver,
  refetch,
  selectedCarrier
}) => {
  const { setAPILoader, organization } = useContext(SharedContext);

  const addSubmitHandler = async (values) => {
    let cnicId;
    if (values?.cnic && !values?.cnic?.id) {
      [cnicId] = await upload(values?.cnic, 'driver');
    }

    let drivingLicenseId;
    if (values?.DrivingLicense && !values?.DrivingLicense?.id) {
      [drivingLicenseId] = await upload(values?.DrivingLicense, 'driver');
    }
    const payload = {
      name: values.name,
      code: values.code,
      companyId: values.carrierId,
      cnicNumber: values.cnicNumber,
      drivingLicenseExpiryDate: values.drivingLicenseExpiryDate,
      drivingLicenseNumber: values.drivingLicenseNumber,
      phone: values.phone,
      ...(cnicId && { cnicId }),
      ...(drivingLicenseId && { drivingLicenseId })
    };

    try {
      setAPILoader(true);
      if (!selectedDriver) await API.post(`drivers`, payload);
      else await API.put(`drivers/${selectedDriver.id}`, payload);
      toaster(
        'success',
        !selectedDriver
          ? 'Driver has been created.'
          : 'Driver updated successfully.'
      );
      await refetch();
      setOpen(false);
      formik.resetForm();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const handleClose = async () => {
    formik.resetForm();
    setOpen(false);
  };

  let valSchema;

  const initialValues = {};

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: valSchema,
    onSubmit: addSubmitHandler
  });

  useEffect(() => {
    if (selectedDriver) {
      formik.setValues({
        carrierId: selectedDriver?.Vendor?.id,
        carrier: selectedDriver?.Vendor,
        name: selectedDriver?.name,
        code: selectedDriver?.code,
        phone: selectedDriver?.phone,
        drivingLicenseNumber: selectedDriver?.drivingLicenseNumber,
        drivingLicenseExpiryDate: selectedDriver?.drivingLicenseExpiryDate,
        cnicNumber: selectedDriver?.cnicNumber,
        cnicId: selectedDriver?.cnicId,
        cnic: selectedDriver?.Cnic,
        drivingLicenseId: selectedDriver?.drivingLicenseId,
        drivingLicense: selectedDriver?.DrivingLicense
      });
    }
  }, [selectedDriver]);

  useEffect(() => {
    if (selectedCarrier && selectedCarrier?.id) {
      formik.setFieldValue('carrier', selectedCarrier);
      formik.setFieldValue('carrierId', selectedCarrier?.id);
    }
  }, [selectedCarrier]);

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      content={
        <DriverTypeForm
          open
          openPopup={open}
          organizationType={organizationType}
          formik={formik}
        />
      }
      title={selectedDriver ? `Edit Driver` : `Add Driver`}
      headerClass="px-8 pt-8 pb-4"
      titleClass="text-primaryBlue text-lg font-semibold"
      noPadding={true}
      closeIconSize={24}
      paperProps={{
        style: {
          borderRadius: '8px',
          height: '793px',
          maxWidth: '780px',
          width: '780px'
        }
      }}
      dialogContentClasses={'dark-scrollbar'}
      onClose={() => {
        formik.resetForm();
      }}
      actions={
        <div className="flex justify-end gap-2 pt-3 pb-6 px-6">
          <Button label="Cancel" onClick={handleClose} variant="text" />
          <Button
            label={selectedDriver ? 'Update Driver' : 'Add Driver'}
            className="rounded"
            onClick={async () => {
              await addSubmitHandler(formik.values);
            }}
            disabled={
              !formik.values.name ||
              !formik.values.carrierId ||
              !formik.values.phone
            }
            variant="primary"
            size="large"
          />
        </div>
      }
    />
  );
};

export default AddDriverPopup;

import React, { useContext, useEffect, useState } from 'react';
import API from '../../../libs/axios';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { onError } from '../../../libs/errorLib';
import { exportToCSV, SharedContext } from '../../../utils/common';
import useStyles from './../taskStyles';
import { ChevronLeft } from '@material-ui/icons';
import { icons } from '../statusIcons';
import { constants } from '../constants';
import { Heading, Title } from '../../../core-components/atoms/Text';
import { TaskDetailStats } from './TaskDetailStats';
import Button from '../../../core-components/atoms/Button';
import clsx from 'clsx';
import Export from '@mui/icons-material/DownloadOutlined';
import { OrderDetail } from './OrderDetail';
import { TaskDetail } from './TaskDetail';
import PrintTask from './PrintPickingTask';
import mixpanel from 'mixpanel-browser';

const ViewPickingDetails = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const tabSelectedClass =
    'border-b-[4px] border-solid border-t-0 border-r-0 border-l-0 border-primary text-primary';
  const tabClass = 'font-medium text-sm normal-case';
  const { uid } = useParams();
  const { setAPILoader, setCurrentPageTitle } = useContext(SharedContext);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedPicking, setSelectedPicking] = useState(null);
  const [, setWarehouseId] = useState('');

  useEffect(() => {
    if (selectedPicking?.id) {
      setCurrentPageTitle(
        <div className="flex gap-2 items-center">
          <div className="flex items-center">
            <ChevronLeft
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer"
            />
            <Heading variant={'section'}>
              {' '}
              {selectedPicking?.taskType.toTitleCase()} ID:{' '}
              {selectedPicking?.customId}
            </Heading>
          </div>
          <div className="text-sm font-normal flex gap-2 items-center border-[#AEC1D6] border-0 border-l-2 border-solid px-2">
            <div>
              <div className={icons[selectedPicking.taskStatus]} />
            </div>
            {constants.TASK_STATUS[selectedPicking.taskStatus]}
          </div>
        </div>
      );
    }
  }, [selectedPicking]);

  useEffect(() => {
    if (uid) getPickingDetails();
    mixpanel.track('Task View', { taskId: uid });
  }, []);

  const getPickingDetails = async () => {
    setAPILoader(true);
    try {
      const pickingDetail = await API.get(`tasks/${uid}`);
      setSelectedPicking(pickingDetail.warehouseTask);
      setWarehouseId(pickingDetail?.warehouseTask?.Warehouse?.id);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const exportToExcel = async () => {
    setAPILoader(true);
    try {
      const pickingDetail = await API.get(`tasks/${uid}/exports`);

      if (pickingDetail && pickingDetail.warehouseTask) {
        const data = pickingDetail.warehouseTask;

        const dataToExport = [];

        let totalPlanned = 0;
        let totalPicked = 0;

        data.WarehouseTaskProducts.forEach((warehouseProduct) => {
          warehouseProduct.WarehouseTaskProductDetails.map((detail) => {
            dataToExport.push({
              code: `${warehouseProduct?.Product?.name}`,
              name: `${warehouseProduct?.Product?.description}`,
              batchNumber: `${detail.InventoryDetail.batchNumber || 'N/A'}`,
              mfg: detail.InventoryDetail.manufacturingDate
                ? moment(detail.InventoryDetail.manufacturingDate).format(
                    'DD/MM/YYYY'
                  )
                : ' N/A',
              expiry: detail.InventoryDetail.expiryDate
                ? moment(detail.InventoryDetail.expiryDate).format('DD/MM/YYYY')
                : 'N/A ',
              locationDetails: `${
                detail.InventoryDetail.WarehouseHall?.name || 'N/A'
              }`,
              aisle: `${
                detail.InventoryDetail.WarehouseHall?.Aisle?.name || 'N/A'
              }`,
              bay: `${
                detail.InventoryDetail.WarehouseHall?.Bay?.name || 'N/A'
              }`,
              orderId: `${
                data?.OrdersTasks?.filter(
                  (val) => val.id === detail?.ordersTaskId
                )
                  .map((val, index) => (index ? ', ' : '') + val.Order.customId)
                  ?.join('') || 'N/A'
              }`,
              plannedQuantity: `${detail.cocQuantity}`,
              pickedQuantity: `${detail.physicalQuantity}`
            });
          });

          totalPlanned += warehouseProduct?.totalCOCQty;
          totalPicked += warehouseProduct?.totalPhysicalQty;
        });

        dataToExport.push({
          code: `TOTAL`,
          name: ``,
          batchNumber: ``,
          mfg: '',
          expiry: '',
          locationDetails: ``,
          aisle: ``,
          bay: ``,
          orderId: ``,
          plannedQuantity: `${totalPlanned}`,
          pickedQuantity: `${totalPicked}`
        });
        let title = `Picking ${data.customId} ${moment().format('DD-MM-yyyy')}`;

        const header = [
          'SKU Code',
          'SKU Name',
          'Batch Number',
          'Mfg',
          'Expiry',
          'Bin location',
          'Aisle',
          'Bay',
          'Order ID',
          'Planned Qty',
          'Picked Qty'
        ];

        exportToCSV(header, dataToExport, title);
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Paper className={clsx(classes.root, 'p-5')}>
      <div className={'flex items-center justify-between'}>
        <TaskDetailStats taskStats={selectedPicking} />
        <div className={'flex items-center'}>
          <Button
            color="default"
            variant="contained"
            className={'mr-2'}
            label={'Export'}
            icon={<Export fontSize={'small'} className={'mr-2'} />}
            onClick={() => exportToExcel()}
          />

          <PrintTask className="ml-10" data={selectedPicking} isIcon={false} />
        </div>
      </div>
      <div className="flex items-center justify-between border-b-[1px] border-solid border-t-0 border-r-0 border-l-0 border-[#E1E5F0] mt-5">
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={
              <div className="flex gap-2 items-center">
                <Title variant={'sm'}>Task Detail</Title>
              </div>
            }
            className={`${tabClass} && ${value === 0 && tabSelectedClass}`}
            active={value === 0}
          />
          {selectedPicking?.order?.length > 0 && (
            <Tab
              label={
                <div className="flex gap-2">
                  <Title variant={'sm'}>Order Detail</Title>
                </div>
              }
              className={`${tabClass} ${value === 1 && tabSelectedClass}`}
              active={value === 1}
            />
          )}
        </Tabs>
      </div>
      {value === 0 && <TaskDetail taskDetails={selectedPicking} />}
      {value === 1 && <OrderDetail orderDetail={selectedPicking} />}
    </Paper>
  );
};
export default ViewPickingDetails;

import {
  Button,
  Grid,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Pagination } from '@material-ui/lab';
import { DataGrid } from '@mui/x-data-grid';
import classNames from 'classnames/bind';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  MdCancel,
  MdFilterAlt,
  MdOutlineAdjust,
  MdOutlineNorth,
  MdOutlineSouth,
  MdSearch
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import { DEBOUNCE_CONST } from '../../../Config';
import Filter from '../../../components/Filter';
import MessageSnackbar from '../../../components/MessageSnackbar';
import TableHeader from '../../../components/TableHeader';
import FilterButton from '../../../components/stats/FilterButton';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { checkPermission } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import { REPORTS } from '../../../utils/enums/report';
import { ORGANIZATION_TYPES } from '../../../constants';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInput: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  textFieldRange: {
    padding: 0,
    marginRight: 5
  },
  formControl: {
    minWidth: 160,
    boxSizing: 'border-box',
    marginRight: 10
  },
  placeholderText: {
    color: '#CAC9C9',
    '& .MuiSelect-outlined': {
      paddingTop: '7px',
      paddingBottom: '6px'
    }
  },
  dropdownListItem: {
    fontSize: 14
  },
  inputAdronmentStyle: {
    '& .MuiInputAdornment-positionStart': {
      margin: '0',
      padding: '0',
      backgroundColor: 'green'
    },
    '& .MuiInputAdornment-root': {
      margin: '0',
      padding: '0',
      backgroundColor: 'green'
    }
  },
  addSubscriptionButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  }
}));

export default function NotificationConsoleView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { currentUser, organizationType } = useContext(SharedContext);

  const [subscriptionNameVal, setSubscriptionNameVal] = useState('');
  const [reportNameVal, setReportNameVal] = useState('');
  const [emailFrequencyVal, setEmailFrequencyVal] = useState('');
  const [statusVal] = useState('');
  const [sortable, setSortable] = useState('');
  const [fetchState, setFetchState] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [showMessage, setShowMessage] = useState(null);
  const [filterView, setFilterView] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
  };

  const columnsNew = [
    {
      field: 'notify',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      width: 50,
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    ...(organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
      ? [
          {
            field: 'Company.name',
            headerName: 'Company',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => {
              let result = [];
              if (params.row && params.row['Company.name']) {
                result.push(params.row['Company.name']);
              }
              return result.join(', ');
            },
            renderHeader: () => {
              return (
                <p className="w-100 parentActions">
                  Company
                  {fetchState && subscriptionNameVal ? (
                    <>
                      <button
                        className="customHeaderIcon"
                        onClick={() => {
                          setSubscriptionNameVal('');
                        }}
                      >
                        <MdFilterAlt />
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      sortBy('Company.name');
                    }}
                    className={
                      sortable.col == 'name' && sortable.sorted == 1
                        ? 'customHeaderIcon'
                        : 'customHeaderIcon display-sortable'
                    }
                  >
                    {sortable.col == 'Company.name' && sortable.sorted == 0 ? (
                      <MdOutlineNorth />
                    ) : (
                      <MdOutlineSouth />
                    )}
                  </button>
                </p>
              );
            },
            renderCell: (params) => (
              <Tooltip title={params.row['Company.name']}>
                <span className="table-cell-trucate">
                  {params.row['Company.name']}
                </span>
              </Tooltip>
            )
          }
        ]
      : []),
    {
      field: 'reportName',
      headerName: 'Report',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row && params.row.reportName) {
          result.push(params.row.reportName);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Report
            {fetchState && reportNameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setReportNameVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('reportName');
              }}
              className={
                sortable.col == 'reportName' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'reportName' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params.row.reportName}>
          <span className="table-cell-trucate">{params.row.reportName}</span>
        </Tooltip>
      )
    },
    {
      field: 'emailFrequency',
      headerName: 'Frequency',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params.row && params.row.emailFrequency) {
          result.push(params.row.emailFrequency);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Frequency
            {fetchState && emailFrequencyVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setEmailFrequencyVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('emailFrequency');
              }}
              className={
                sortable.col == 'emailFrequency' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'emailFrequency' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params.row.emailFrequency}>
          <span className="table-cell-trucate">
            {params.row.emailFrequency}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'driverDatatableActions',
      cellClassName: 'justify-content-right',
      renderCell: (params) =>
        checkPermission(currentUser, 'OPS_NOTIFICATION_UPDATE') ? (
          <Tooltip title="Edit">
            <EditIcon
              key={params.row.id}
              onClick={() => navigate(`edit/${params.row.id}`)}
            />
          </Tooltip>
        ) : null
    }
  ];

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  function CustomToolbar() {
    return (
      <FilterButton searchKeyword openFilterView={() => openFilterView()} />
    );
  }

  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (subscriptionNameVal) {
      colVal = { '$Company.name$': subscriptionNameVal };
    }

    if (reportNameVal) {
      colVal = { $reportName$: reportNameVal };
    }

    if (emailFrequencyVal) {
      colVal = { $emailFrequency$: emailFrequencyVal };
    }

    if (statusVal) {
      colVal = { $status$: statusVal };
    }

    let filters = {
      colVal,
      sortable
    };

    let columns = [
      ...(organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
        ? ['$Company.name$']
        : []),
      '$reportName$',
      '$emailFrequency$',
      '$notify$'
    ];

    _getSubscriptions(page, searchKeyword, filters, columns);
  };
  const addSubscriptionButton = (
    <Tooltip title="Add Subscription">
      <Button
        key={2}
        id="removeHoverBtn"
        variant="contained"
        color="primary"
        className={classes.addSubscriptionButton}
        size="small"
        onClick={() => navigate(`create`)}
      >
        ADD Subscription
      </Button>
    </Tooltip>
  );

  const _getSubscriptions = async (page, searchKeyword, filters, columns) => {
    try {
      setMuiLoader(true);
      const subscriptions = await API.get(`notification-subscriptions`, {
        params: { page: page, search: searchKeyword, filters, columns }
      });

      setPageCount(subscriptions.pages);
      let subData = subscriptions.data.map((elem) => {
        let reportName = REPORTS[elem.reportId];
        return {
          ...elem,
          reportName
        };
      });
      setSubscriptions(subData);
    } catch (err) {
      onError(err);
    } finally {
      setMuiLoader(false);
    }
  };

  const getSubscriptions = useCallback(
    debounce((page, searchKeyword) => {
      let columns = ['$name$', '$emailFrequency$', '$notify$'];
      _getSubscriptions(page, searchKeyword, {}, columns);
    }, DEBOUNCE_CONST),
    []
  );

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'Notification Console';
    setCurrentPageTitle(title);
    mixpanel.track('Notification Console', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes();
  }, [reportNameVal, subscriptionNameVal, emailFrequencyVal, sortable]);

  useEffect(() => {
    getSubscriptions(page, searchKeyword);
  }, [page, searchKeyword]);

  const searchInput = (
    <div className="search-box">
      {searchKeyword ? (
        <span className="search-cancel" onClick={() => setSearchKeyword('')}>
          <MdCancel className="searchIcons" />
        </span>
      ) : null}
      <InputBase
        className={mainClass}
        id="search"
        label="Search"
        type="text"
        variant="outlined"
        value={searchKeyword}
        key={3}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          setPage(1);
        }}
      />
      <span className="search-btn">
        <MdSearch className="searchIcons" />
      </span>
    </div>
  );

  //Input Objects for filters
  let subscriptionNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Company',
    field: 'Company.name',
    value: subscriptionNameVal ? subscriptionNameVal : '',
    setNameVal: (e) => setNameVal(setSubscriptionNameVal, e)
  };

  let emailFrequencyObj = {
    type: 'text',
    className: 'textField',
    label: 'Frequency',
    field: 'emailFrequency',
    value: emailFrequencyVal ? emailFrequencyVal : '',
    setNameVal: (e) => setNameVal(setEmailFrequencyVal, e)
  };

  //Custom modal for filter....
  const filterCols = [
    ...(organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
      ? [subscriptionNameObj]
      : []),
    emailFrequencyObj
  ]; //reportNameObj
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        title={'Inventory'}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_NOTIFICATION_READ') ? searchInput : null,
    checkPermission(currentUser, 'OPS_NOTIFICATION_READ') ? filterModal : null,
    checkPermission(currentUser, 'OPS_NOTIFICATION_CREATE')
      ? addSubscriptionButton
      : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table">
          <TableBody>
            <div style={{ height: 750, width: '100%' }}>
              <DataGrid
                rows={subscriptions}
                hiddenColumnModel={['id']}
                columns={columnsNew}
                components={{ Toolbar: CustomToolbar }}
                pageSize={15}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
                loading={muiLoader}
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="space-between">
        <Grid item></Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            className={classes.pagination}
            onChange={(e, newPage) => setPage(newPage)}
          />
        </Grid>
      </Grid>
      <MessageSnackbar showMessage={showMessage} />
    </Paper>
  );
}

import React from 'react';
import { Link, NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: 'white',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: 0
  },
  title: {
    marginRight: 'auto'
    // color: theme.palette.secondary.light,
  },
  active: {
    color: 'white',
    fontWeight: 'bold',
    width: '100%',
    '& $title': {
      fontWeight: 500
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  titleActive: {
    color: 'white',
    fontWeight: '500',
    textTransform: 'none'
  }
}));

const NavItem = ({
  className,
  href,
  icon,
  title,
  activeRouteName,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  const currentPathName = location.pathname;
  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Link to={href} state={{ prevPath: location.pathname }}>
        <Button
          activeClassName="activeIcon"
          className={
            activeRouteName &&
            currentPathName.toLowerCase() === activeRouteName.toLowerCase()
              ? 'activeIcon'
              : classes.button
          }
          component={RouterLink}
          to={href}
        >
          <span
            className={
              activeRouteName &&
              currentPathName.toLowerCase() === activeRouteName.toLowerCase()
                ? 'activeIcon'
                : classes.title
            }
          >
            <span className="navChildTitle navTitleSpacing">{title}</span>
            <span className="navChildIcon">{icon}</span>
          </span>
        </Button>
      </Link>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  href: PropTypes.string,
  title: PropTypes.string
};

export default NavItem;

/*eslint-disable*/
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchMapData } from '../../../apis/dashboard';
import { NodeType as nodeOption } from '../constants';
import MapFilters from './MapFilters';
import SupplyChainMap from './ClusterMap/SupplyChainMap';
import { processNodeData } from './utils';
import { GOOGLE_MAP_API_KEY } from '../../../constants';
import {useLoadScript} from "@react-google-maps/api";
import SupplyChainMapHeat from "./HeatMap/SupplyChainMapHeat";
import {Divider, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import GrainIcon from "@mui/icons-material/Grain";
import {styled} from "@mui/material/styles";
import mixpanel from 'mixpanel-browser';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(1),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0
    }
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
      {
        marginLeft: -1,
        borderLeft: '1px solid transparent'
      }
}));

const SupplyChainView = () => {
  const [nodeData, setNodeData] = useState({});
  const [markers, setMarkers] = useState([]);
  const [clusterer, setClusterer] = useState(null);
  const [shipmentCount, setShipmentCount] = useState(0);
  const [nodeType, setNodeType] = useState('ALL_NODES');
  const [statuses, setStatuses] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [mapType, setMapType] = useState('heat');
  const [fromNode, setFromNode] = useState(null);
  const [toNode, setToNode] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [isReload, setIsReload] = useState(false);
  const [shipmentsModal, setShipmentsModal] = useState(false);


  const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string,
  ) => {
    newAlignment !== null ? setMapType(newAlignment) : setMapType(mapType);
  };

  const { refetch, isFetching } = useQuery(
    ['mapData', startDate, endDate, nodeType],
    () => {
      const from = startDate;
      const to = endDate;
      if (nodeOption[nodeType] === nodeOption.ALL_NODES) {
        return fetchMapData({ from, to, search: searchKeyword });
      }
      return fetchMapData({ from, to, nodeType: nodeOption[nodeType], search: searchKeyword});
    },
    {
      enabled: true,
      onSuccess: (data) => {
        let nodes = data.nodes;
        const statuses = data.statusesCount;
        const processedNodes = processNodeData(nodes);
        setShipmentCount(Object.keys(processedNodes).length || 0);
        setStatuses(statuses);
        setNodeData(processedNodes);
      }
    }
  );

  useEffect(() => {
    mixpanel.track('Control Tower', {});
  }, []);

  useEffect(() => {
    setNodeData([]);
    if (markers) {
      markers?.forEach((marker) => {
        marker.setMap(null);
      });
    }
    setMarkers([]);
    clusterer?.clearMarkers(false);
  }, [startDate, endDate, nodeType]);


  useEffect(() => {
    if (!shipmentsModal && searchKeyword !== null) {
      setNodeData([]);
      if (markers) {
        markers?.forEach((marker) => {
          marker.setMap(null);
        });
      }
      setMarkers([]);
      clusterer?.clearMarkers(false);
      refetch();
    }
  }, [startDate, endDate, nodeType, shipmentsModal, searchKeyword]);

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return (
          <Box className="w-100 flex justify-center items-center">
            <CircularProgress />
          </Box>
        );
      case Status.FAILURE:
        return <div>Error!</div>;
      case Status.SUCCESS:
        return (
          <SupplyChainMap
            nodeData={nodeData}
            isLoading={isFetching || isReload}
            startDate={startDate}
            endDate={endDate}
            markers={markers}
            setMarkers={setMarkers}
            clusterer={clusterer}
            setClusterer={setClusterer}
            statuses={statuses}
            nodeType={nodeType}
            fromNode={fromNode}
            toNode={toNode}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            setIsReload={setIsReload}
            shipmentsModal={shipmentsModal}
            setShipmentsModal={setShipmentsModal}
          />
        );
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ['marker', 'geometry', 'routes', 'visualization']
  });

  return (
    <div className="ctower-map-holder-height w-full mb-[20px]">
      <div className="w-full flex justify-between items-center mb-6 mt-4">
        <h1 className="text-xl font-bold text-primaryBlue">
          Supply Chain Network{' '}
          <span className="font-normal">({shipmentCount} Active Nodes)</span>
        </h1>
        <div>
          <MapFilters
            setEndDate={setEndDate}
            endDate={endDate}
            setStartDate={setStartDate}
            startDate={startDate}
            setNodeType={setNodeType}
            nodeType={nodeType}
            nodeData={nodeData}
            fromNode={fromNode}
            setFromNode={setFromNode}
            toNode={toNode}
            setToNode={setToNode}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            setIsReload={setIsReload}
          />
        </div>
      </div>
      <div className="w-100 ctower-map-height rounded border border-solid border-platinum shadow-[0_3px_8px_0px_rgba(0,0,0,0.16)] overflow-hidden">
        {mapType === 'heat' ? (
            <>
              {!isLoaded ? (
                  <Box className="w-100 flex justify-center items-center">
                    <CircularProgress />
                  </Box>
              ) : loadError ? (
                  <div>Error!</div>
              ) : isLoaded && (
                  <SupplyChainMapHeat
                      nodeData={nodeData}
                      isLoading={isFetching || isReload}
                      startDate={startDate}
                      endDate={endDate}
                      markers={markers}
                      setMarkers={setMarkers}
                      clusterer={clusterer}
                      setClusterer={setClusterer}
                      statuses={statuses}
                      nodeType={nodeType}
                      fromNode={fromNode}
                      toNode={toNode}
                      searchKeyword={searchKeyword}
                      setSearchKeyword={setSearchKeyword}
                      setIsReload={setIsReload}
                      shipmentsModal={shipmentsModal}
                      setShipmentsModal={setShipmentsModal}
                  />
              )}
            </>
        ) : mapType === 'map' && (
            <Wrapper
                apiKey={GOOGLE_MAP_API_KEY}
                version="beta"
                libraries={['marker', 'geometry', 'routes']}
                render={render}
            />
            )}
        <div className={'border bg-white relative ctower-bottom-right-position w-fit'}>
          <StyledToggleButtonGroup
              size="small"
              value={mapType}
              selected={mapType}
              orientation="vertical"
              exclusive
              onChange={handleChange}
              aria-label="map type"
          >
            <ToggleButton value="map" aria-label="map">
              <MapIcon />
            </ToggleButton>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButton value="heat" aria-label="heat">
              <GrainIcon />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </div>
      </div>
    </div>
  );
};
export default SupplyChainView;

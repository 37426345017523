/*eslint-disable*/
import {
  Heading,
  Label,
  Paragraph,
  Title
} from '../../../core-components/atoms/Text';
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip
} from '@material-ui/core';
import { InputAdornment } from '@mui/material';
import { Close, Search } from '@material-ui/icons';
import DateRangePickerSingleCalendar from '../DateRangePicker';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { createTheme } from '@material-ui/core/styles';
import { BACKDROP, OASIS_PRIMARY_500 } from '../../../constants/colors';
import moment from 'moment/moment';
import mixpanel from 'mixpanel-browser';
import { icons } from '../statusIcons';
import { constants } from '../constants';
import OptionsMenu from '../Options';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import CustomPagination from '../../invoiceandbilling/invoiceManagement/CustomPagination';
import SettingIcon from '../../../assets/icons/setting.svg';
import { toaster } from '../../../utils/toaster';
import Filters from './TaskComponent/Filters';
import TypeIcon from '../../../assets/icons/button-pointer.svg';
import StatusIcon from '../../../assets/icons/list-status.svg';
import ProductIcon from '../../../assets/icons/archiveNew.svg';
import WarehouseIcon from '../../../assets/icons/warehouseFilter.svg';
import Cancel from '@mui/icons-material/CancelOutlined';
import IconButton from '@mui/material/IconButton';
import RemoveRedEye from '@mui/icons-material/RemoveRedEyeOutlined';
import { confirmAlert } from 'react-confirm-alert';
import Button from '../../../core-components/atoms/Button';
import { checkPermission } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import PopupButton from '../../../core-components/molecules/PopupButton';
import AssignTask from './TaskComponent/AssignTask';
import clsx from 'clsx';
import PrintTask from '../PickingDetail/PrintPickingTask';

const filterInitialState = {
  Status: [],
  Type: [],
  Nodes: [],
  Product: []
};

const useStyle = makeStyles(() => ({
  customUI: {
    width: '500px',
    padding: '28px',
    background: '#fff',
    boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
    color: 'black',
    zIndex: 1500
  },
  customButton: {
    // width: '160px',
    padding: '8px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#0C71D7',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: '4px'
  },
  customButtonCancel: {
    padding: '8px 16px',
    border: '1px solid black',
    margin: '10px',
    cursor: 'pointer',
    color: '#1d1d1d',
    background: 'white',
    fontSize: '14px',
    borderRadius: '4px'
  },
  popupHeader: {
    paddingBottom: '14px',
    fontWeight: 600,
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const TasksList = ({ getTasksStats }) => {
  const navigate = useNavigate();
  const quickFilterTypes = [
    {
      key: 'PICKING',
      value: 'Picking'
    },
    {
      key: 'PUTAWAY',
      value: 'Putaway'
    },
    {
      key: 'CYCLE_COUNT',
      value: 'Cycle Count'
    },
    {
      key: 'IN_PROGRESS',
      value: 'In Progress'
    },
    {
      key: 'PENDING',
      value: 'Pending'
    },
    {
      key: 'CANCELLED',
      value: 'Cancelled'
    }
  ];
  const classes = useStyle();
  const [topFilters, setTopFilters] = useState(filterInitialState);
  const [page, setPage] = useState(1);
  const [taskData, setTaskData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [, setOptionsClick] = React.useState(false);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [pickingModal] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [products, setProducts] = useState([]);
  const { setAPILoader, currentUser } = useContext(SharedContext);
  const [entityTypeOpen, setEntityTypeOpen] = useState(null);

  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });
  useEffect(() => {
    getTaskData(page, {}, [], startDate, endDate, search);
    mixpanel.track('Task Management', {});
  }, []);

  const getProducts = async (search) => {
    try {
      const tasks = await API.get(`products`, {
        params: {
          search: search,
          limit: 20,
          columns: []
        }
      });
      setProducts(tasks.data);
    } catch (err) {
      onError(err);
    }
  };

  const getNodes = async (search) => {
    try {
      const tasks = await API.get(`nodes`, {
        params: {
          search: search,
          nodeClass: 'INTERNAL',
          manageInventory: true,
          limit: 20
        }
      });
      setNodes(tasks.data);
    } catch (err) {
      onError(err);
    }
  };

  const getTaskData = async (
    page,
    filters,
    columns,
    startDate,
    endDate,
    search
  ) => {
    setAPILoader(true);
    try {
      const tasks = await API.get(`tasks`, {
        params: {
          limit: 10,
          page,
          filters,
          columns,
          startTaskDate: startDate,
          endTaskDate: endDate,
          search: search
        }
      });
      setPageCount(tasks.pages);
      setTaskData(tasks.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const filterOptions = [
    {
      label: 'Status',
      options: Object.keys(constants.TASK_STATUS).map((key, id) => ({
        value: key,
        label: constants.TASK_STATUS[key],
        id
      })),
      icon: StatusIcon
    },
    {
      label: 'Type',
      options: Object.keys(constants.TASK_TYPE).map((key, id) => ({
        value: key,
        label: constants.TASK_TYPE[key],
        id
      })),
      icon: TypeIcon
    },
    {
      label: 'Product',
      options: products?.map((company, id) => ({
        value: company.id,
        label: company.name,
        id
      })),
      icon: ProductIcon
    },
    {
      label: 'Nodes',
      options: nodes?.map((node, id) => ({
        value: node.id,
        label:
          node.code || node.name
            ? `${node.name} ${node?.code ? `- ${node.code}` : ''}`
            : '',
        warehouseId: node.Warehouse.id,
        id
      })),
      icon: WarehouseIcon
    }
  ];

  useEffect(() => {
    getTasks({
      page,
      startDate,
      endDate,
      currentFilter,
      topFilters,
      search
    });
  }, [
    page,
    startDate,
    endDate,
    currentFilter,
    topFilters,
    pickingModal,
    search
  ]);

  const getTasks = useCallback(
    debounce(
      ({ page, startDate, endDate, currentFilter, topFilters, search }) => {
        fetchRes({
          page,
          startDate,
          endDate,
          currentFilter,
          topFilters,
          search
        });
      },
      DEBOUNCE_CONST
    ),
    []
  );

  const fetchRes = ({
    page,
    startDate,
    endDate,
    currentFilter,
    topFilters,
    search
  }) => {
    let colVal = {};
    if (currentFilter && currentFilter[0] === 'ALL') {
      colVal.taskStatus = '';
    } else if (currentFilter?.length === 0) {
      colVal.taskStatus = '';
    } else if (
      currentFilter?.some(
        (val) =>
          val === 'IN_PROGRESS' || val === 'PENDING' || val === 'CANCELLED'
      )
    ) {
      colVal.taskStatus = currentFilter?.filter(
        (val) =>
          val === 'IN_PROGRESS' || val === 'PENDING' || val === 'CANCELLED'
      );
    }
    if (
      currentFilter?.some(
        (val) => val === 'PICKING' || val === 'PUTAWAY' || val === 'CYCLE_COUNT'
      )
    ) {
      colVal['taskType'] = currentFilter?.filter(
        (val) => val === 'PICKING' || val === 'PUTAWAY' || val === 'CYCLE_COUNT'
      );
    }

    if (topFilters.Status?.length > 0) {
      colVal.taskStatus = topFilters.Status?.map((value) => value?.value);
    }

    if (topFilters.Type?.length > 0) {
      colVal['taskType'] = topFilters.Type?.map((value) => value?.value);
    }

    if (topFilters.Nodes?.length > 0) {
      colVal['$Warehouse.id$'] = topFilters.Nodes?.map(
        (value) => value?.warehouseId
      );
    }

    if (topFilters.Product?.length > 0) {
      colVal['$WarehouseTaskProduct.productId$'] = topFilters.Product?.map(
        (value) => value?.value
      );
    }

    let filters = {
      colVal
    };
    let columns = [
      'customId',
      '$Warehouse.id$',
      'taskType',
      '$WarehouseTaskProduct.productId$'
    ];

    getTaskData(page, filters, columns, startDate, endDate, search);
  };

  const handleClickOptions = (e, task) => {
    const onClickHandler =
      task.taskType === 'CYCLE_COUNT'
        ? () => navigate(`/routing/cycle-count/view/${task.customId}`)
        : task.taskType === 'PICKING'
        ? () => navigate(`/routing/picking/view/${task.customId}`)
        : task.taskType === 'PUTAWAY'
        ? () => handlePutaway(task)
        : () => navigate(`/routing/picking/view/${task.customId}`);
    onClickHandler();
  };

  const handlePutaway = (task) => {
    if (task.taskStatus === 'COMPLETED') {
      navigate(`/routing/putaway/view/${task.id}`);
    } else {
      toaster('info', 'Putaway not completed');
    }
  };

  const setFilter = (key, value, type, filterType) => {
    let currentFilters = !filterType ? { ...topFilters } : { ...currentFilter };
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    !filterType
      ? setTopFilters(currentFilters)
      : setCurrentFilter(currentFilters);
  };

  const cancelTicket = async (e, task) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUI}>
            <p className={classes.popupHeader}>
              <span>Are you sure ?</span>
              <Close style={{ fontSize: '16px' }} onClick={onClose} />
            </p>
            <Paragraph variant={'lg'} className={'mb-3'}>
              By doing this you will be cancelling task {task.customId}. Are you
              sure you want to continue?
            </Paragraph>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="No">
                <button
                  className={classes.customButtonCancel}
                  onClick={onClose}
                >
                  NO
                </button>
              </Tooltip>
              <Tooltip title="Cancel Task">
                <button
                  className={classes.customButton}
                  onClick={async () => {
                    setAPILoader(true);
                    try {
                      await API.put(`tasks/${task.customId}/cancel`);
                      toaster(
                        'success',
                        'Task has been cancelled successfully'
                      );
                      getTasks({
                        page,
                        startDate,
                        endDate,
                        currentFilter,
                        topFilters,
                        search
                      });
                      getTasksStats();
                    } catch (error) {
                      onError(error);
                    } finally {
                      setAPILoader(false);
                    }
                    onClose();
                  }}
                >
                  Cancel Task
                </button>
              </Tooltip>
            </div>
          </div>
        );
      }
    });
  };

  const confirmTicket = async (e, task) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUI}>
            <p className={classes.popupHeader}>
              <span>Are you sure ?</span>
              <Close style={{ fontSize: '16px' }} onClick={onClose} />
            </p>
            <Paragraph variant={'lg'} className={'mb-3'}>
              If you click yes, the batches & quantity that you have selected
              will be moved to the staging area and the respective task will not
              be performed on the App.
            </Paragraph>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="No">
                <button
                  className={classes.customButtonCancel}
                  onClick={onClose}
                >
                  NO
                </button>
              </Tooltip>
              <Tooltip title="Complete">
                <button
                  className={classes.customButton}
                  onClick={async () => {
                    setAPILoader(true);
                    try {
                      const taskDetail = await API.get(
                        `tasks/${task.customId}`
                      );
                      await API.put(`tasks/${task.customId}`, {
                        warehouseTask: {
                          ...taskDetail?.warehouseTask,
                          taskStatus: 'COMPLETED',
                          taskEndedAt: new Date()
                        }
                      });
                      getTasks({
                        page,
                        startDate,
                        endDate,
                        currentFilter,
                        topFilters,
                        search
                      });
                      getTasksStats();
                    } catch (error) {
                      onError(error);
                    } finally {
                      setAPILoader(false);
                    }
                    onClose();
                  }}
                >
                  Yes, Mark Complete
                </button>
              </Tooltip>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div>
      <div className="mt-18 flex justify-between items-center">
        <Heading variant={'section'}>All Tasks</Heading>
        <div className="flex gap-4">
          <div className="w-[204px]">
            <ThemeProvider theme={theme}>
              <TextField
                fullWidth
                placeholder="Search"
                variant="outlined"
                color={'primary'}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                size="small"
                inputProps={{
                  onFocus: () => search
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <Search
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '10px'
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {search && (
                        <Close
                          style={{
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setSearch('');
                          }}
                        />
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </ThemeProvider>
          </div>
          <DateRangePickerSingleCalendar
            startDate={startDate}
            endDate={endDate}
            updateRange={(input) => {
              setStartDate(input.selection.startDate);
              setEndDate(input.selection.endDate);
            }}
          />
          <Filters
            options={filterOptions}
            filters={topFilters}
            setFilter={setFilter}
            getNodes={getNodes}
            getProducts={getProducts}
          />
        </div>
      </div>
      <Grid className="box-border w-full border-[1px] mt-[8px] rounded-md border-solid border-dark300">
        <div className="px-2 py-4 w-full flex border-b  border-dark300 flex-wrap gap-3 items-center">
          <div className="border-solid border-0 border-r border-[#BDBDBD] inline-block text-sm text-[#6A7289] cursor-pointer px-2 py-1">
            <div className="flex items-center gap-2">
              <img src={SettingIcon} />
              <span>Quick Filters</span>
            </div>
          </div>
          {quickFilterTypes.map((filter, id) => {
            let isSelected = currentFilter.indexOf(filter.key) !== -1;
            let className = isSelected ? 'bg-[#274E77] text-white' : '';
            return (
              <div
                className={`px-3 py-2 border-solid cursor-pointer border border-[#67718C] inline-block ${className} rounded`}
                onClick={() => {
                  if (currentFilter.indexOf(filter.key) !== -1) {
                    setCurrentFilter(
                      currentFilter.filter((f) => f !== filter.key)
                    );
                  } else {
                    setCurrentFilter([...currentFilter, filter.key]);
                  }
                }}
                key={id}
              >
                <span className="text-sm">{filter.value}</span>
                <span
                  className={`ml-3 ${
                    isSelected ? 'text-white' : 'text-[#172B61E5]'
                  } font-bold`}
                >
                  +
                </span>
              </div>
            );
          })}
        </div>
        <Table aria-label="collapsible table" className="parent-DataTable">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ padding: '16px 6px 16px 10px' }}
                className={`w-[80px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  TASK ID
                </Label>
              </TableCell>
              <TableCell sx={{ padding: '16px 4px' }} className={`w-[120px]`}>
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  NODES
                </Label>
              </TableCell>
              <TableCell sx={{ padding: '16px 4px' }} className={`w-[120px]`}>
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  TYPE
                </Label>
              </TableCell>
              <TableCell sx={{ padding: '16px 4px' }} className={`w-[140px]`}>
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  CREATED BY
                </Label>
              </TableCell>
              <TableCell sx={{ padding: '16px 4px' }} className={`w-[120px]`}>
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  ASSIGNED TO
                </Label>
              </TableCell>
              <TableCell sx={{ padding: '16px 4px' }} className={`w-[140px]`}>
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  STATUS
                </Label>
              </TableCell>
              <TableCell sx={{ padding: '16px 4px' }} className={`w-[280px]`}>
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  ORDER NUMBER
                </Label>
              </TableCell>
              <TableCell
                sx={{ padding: '16px 4px' }}
                className={`w-[80px]`}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskData?.map((task) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow className="group hover:cursor-pointer">
                  <TableCell
                    sx={{ padding: '16px 4px' }}
                    className={`w-[80px]`}
                  >
                    <Title
                      className={clsx(
                        'sm',
                        `${
                          search &&
                          String(task?.id).includes(search) &&
                          'bg-[#FFF0C3] w-fit p-0.5'
                        }`
                      )}
                    >
                      {task?.customId}
                    </Title>
                  </TableCell>
                  <TableCell
                    sx={{ padding: '16px 4px' }}
                    className={`w-[120px]`}
                  >
                    <Paragraph variant={'sm'} className={'truncate'}>
                      {task.Warehouse.businessWarehouseCode.toUpperCase() ||
                        '-'}
                    </Paragraph>
                    <Label variant={'xs'} className={`text-[#4B5369]`}>
                      {task.Warehouse.Node?.nodeType || 'WAREHOUSE'}
                    </Label>
                  </TableCell>
                  <TableCell
                    sx={{ padding: '16px 4px' }}
                    className={`w-[120px]`}
                  >
                    <Paragraph variant={'sm'}>
                      {task?.taskType.toUpperCase()}
                    </Paragraph>
                    <Label variant={'xs'} className={`text-[#4B5369]`}>
                      {task?.generatedThrough === 'FEFO'
                        ? `${task?.generatedThrough} (AUTO)`
                        : 'CUSTOM' || ''}
                    </Label>
                  </TableCell>
                  <TableCell
                    sx={{ padding: '16px 4px' }}
                    className={`w-[140px]`}
                  >
                    <Paragraph
                      variant={'sm'}
                      className={clsx(
                        'truncate',
                        'overflow-hidden',
                        'w-[108px]',
                        `${
                          search &&
                          String(
                            `${task?.CreatedBy?.firstName} ${task?.CreatedBy?.lastName}`
                          ).includes(search) &&
                          'bg-[#FFF0C3] w-fit p-0.5'
                        }`
                      )}
                    >
                      {task?.CreatedBy?.firstName} {task?.CreatedBy?.lastName}
                    </Paragraph>
                    <Label variant={'xs'} className={`text-[#4B5369]`}>
                      {moment(task?.createdAt).format('DD/MM/YY')}
                    </Label>
                  </TableCell>
                  <TableCell
                    className={`w-[120px]`}
                    sx={{ padding: '16px 4px' }}
                  >
                    <Paragraph
                      variant={'sm'}
                      className={clsx(
                        `${
                          search &&
                          String(
                            `${task?.AssignedTo?.firstName} ${task?.AssignedTo?.lastName}`
                          ).includes(search) &&
                          'bg-[#FFF0C3] w-fit p-0.5'
                        }`
                      )}
                    >
                      {task?.AssignedTo?.firstName} {task?.AssignedTo?.lastName}
                    </Paragraph>
                  </TableCell>
                  <TableCell
                    sx={{ padding: '16px 4px' }}
                    className={`w-[140px]`}
                  >
                    <div className="flex gap-2 items-center">
                      <div className={icons[task.taskStatus]} />
                      <Paragraph variant={'sm'}>
                        {constants.TASK_STATUS[task.taskStatus]}
                      </Paragraph>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{ padding: '16px 4px' }}
                    className={`w-[280px]`}
                  >
                    <Paragraph
                      variant={'sm'}
                      className={clsx(
                        `${
                          search &&
                          String(
                            [
                              ...new Set(
                                task.OrdersTasks.map((o) => o.Order.customId)
                              )
                            ].join(',')
                          )?.includes(search) &&
                          'bg-[#FFF0C3] w-fit p-0.5'
                        }`,
                        'flex flex-row'
                      )}
                    >
                      {task?.OrdersTasks?.length > 0
                        ? [
                            ...new Set(
                              task.OrdersTasks.slice(0, 3).map(
                                (o) => o.Order.customId
                              )
                            )
                          ].join(', ')
                        : '-'}
                      {task?.OrdersTasks?.length > 2 && (
                        <>
                          {', \b'}
                          <p className="font-roboto text-normal font-medium text-xs text-oasisPrimary500">
                            +{task?.OrdersTasks?.length - 2}
                          </p>
                        </>
                      )}
                    </Paragraph>
                  </TableCell>
                  <TableCell
                    sx={{ padding: '16px 4px' }}
                    className={`w-[80px]`}
                  >
                    <div className={'flex items-center justify-end'}>
                      <div className={'flex items-center justify-end'}>
                        {task?.taskType.toUpperCase() === 'PICKING' &&
                          (task.taskStatus === 'NOT_ASSIGNED' ||
                            task.taskStatus === 'ASSIGNED') && (
                            <PopupButton
                              open={entityTypeOpen === task?.id}
                              setOpen={() => {
                                setEntityTypeOpen(task?.id);
                              }}
                              btn={
                                <div>
                                  <Button
                                    className={clsx(
                                      'group-hover:visible',
                                      entityTypeOpen === task?.id
                                        ? 'visible'
                                        : 'invisible'
                                    )}
                                    label="Assign"
                                    variant="outlined"
                                    onClick={() => {
                                      setEntityTypeOpen(task?.id);
                                    }}
                                  />
                                </div>
                              }
                              content={
                                <AssignTask
                                  task={task}
                                  setEntityTypeOpen={setEntityTypeOpen}
                                  getTaskData={() => {
                                    getTasks({
                                      page,
                                      startDate,
                                      endDate,
                                      currentFilter,
                                      topFilters,
                                      search
                                    });
                                  }}
                                />
                              }
                            />
                          )}
                        {task?.taskType.toUpperCase() === 'PICKING' &&
                          (task.taskStatus === 'PENDING' ||
                            task.taskStatus === 'IN_PROGRESS' ||
                            task.taskStatus === 'ASSIGNED' ||
                            task.taskStatus === 'NOT_ASSIGNED') &&
                          checkPermission(
                            currentUser,
                            'OPS_PICKING_COMPLETE'
                          ) && (
                            <Button
                              label="Mark as complete"
                              className={'mr-1 group-hover:visible invisible'}
                              variant="tertiary"
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmTicket(e, task);
                              }}
                            />
                          )}
                        {!(
                          task?.taskType.toUpperCase() === 'PUTAWAY' &&
                          task.taskStatus !== 'COMPLETED'
                        ) && (
                          <div
                            className={
                              'flex items-center mr-6 group-hover:visible invisible'
                            }
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickOptions(e, task);
                              }}
                            >
                              <RemoveRedEye style={{ color: BACKDROP }} />
                            </IconButton>
                          </div>
                        )}
                      </div>
                      {!(
                        task?.taskType.toUpperCase() === 'PUTAWAY' &&
                        task.taskStatus !== 'COMPLETED'
                      ) && (
                        <div onClick={(e) => e.stopPropagation()}>
                          {task.taskStatus !== 'CANCELLED' &&
                          task.taskStatus !== 'COMPLETED' ? (
                            <OptionsMenu
                              options={[
                                {
                                  label: (
                                    <div>
                                      <PrintTask
                                        className="ml-10"
                                        data={task}
                                        isIcon={true}
                                      />
                                    </div>
                                  )
                                },
                                {
                                  label: (
                                    <div className="flex gap-3 items-center">
                                      <Cancel color={'disabled'} />
                                      <Paragraph variant={'sm'}>
                                        Cancel Task
                                      </Paragraph>
                                    </div>
                                  ),
                                  onClick: (e) => {
                                    cancelTicket(e, task);
                                  }
                                }
                              ]}
                              closeCallback={() => setOptionsClick(false)}
                              enterCallBack={() => setOptionsClick(true)}
                            />
                          ) : (
                            <OptionsMenu
                              options={[
                                {
                                  label: (
                                    <div>
                                      <PrintTask
                                        className="ml-10"
                                        data={task}
                                        isIcon={true}
                                      />
                                    </div>
                                  )
                                }
                              ]}
                              closeCallback={() => setOptionsClick(false)}
                              enterCallBack={() => setOptionsClick(true)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item />
        <Grid item>
          <CustomPagination page={page} pages={pageCount} setPage={setPage} />
        </Grid>
      </Grid>
    </div>
  );
};

import TextField from '@mui/material/TextField';

const NumberInput = ({ onChange, ...props }) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue < 0) {
      event.target.value = 0;
      onChange(event);
    }
    const regex = /^-?\d*\.?\d{0,3}$/;

    if (regex.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(event);
    }
  };

  const handleSpecialCharacters = (e) => {
    if (e?.key === '-' || e?.key === '+') {
      e.preventDefault();
    }
  };

  return (
    <TextField
      {...props}
      type="number"
      onChange={handleChange}
      inputProps={{ step: 0.001 }}
      onKeyPress={handleSpecialCharacters}
    />
  );
};

export default NumberInput;

import { Button, Grid, Typography, Tooltip } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';

import useStyles from './makeStyles';
import ProductsCsvReader from '../../components/ProductsCsvReader';
import {
  validateDuplication,
  validateCols,
  capitalize,
  SharedContext
} from '../../utils/common';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import downloadData from '../../assets/files/NodesBulkUploadTemplate.xlsx';

function NodeBulkUpload() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [fileUploaded, setfileUploaded] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorAlerts, setErrorAlerts] = useState([]);
  const [successAlerts, setSuccessAlerts] = useState([]);
  const { setAPILoader } = useContext(SharedContext);

  const { company_id } = useParams();

  const bulkUpload = async (data) => {
    setfileUploaded(true);
    setAPILoader(true);
    const sanitizationArray = dataSanitization(data.nodes);
    if (sanitizationArray.length > 0) {
      setErrorAlerts(sanitizationArray);
      return;
    }

    let temp = [];
    let errorsArray = [];
    let count = 2;
    for (let node of data.nodes) {
      validateDuplication(
        node['Location Name'],
        'Location Name',
        count,
        'Duplicate Location Name.',
        temp,
        errorsArray
      );
      validateDuplication(
        node['Location Code'],
        'Location Code',
        count,
        'Duplicate Location Code.',
        temp,
        errorsArray
      );
      if (
        temp.find(
          (prevNode) =>
            prevNode['Latitude'] === node['Latitude'] &&
            prevNode['Longitude'] === node['Longitude']
        )
      ) {
        checkDuplicate(errorsArray, count, 'Duplicate LatLng.');
      }

      if (
        node['Location Name']?.toLowerCase() ===
        node['Node Address']?.toLowerCase()
      ) {
        checkDuplicate(
          errorsArray,
          count,
          'Location Name can not be same as Node address.'
        );
      }

      if (
        !['internal nodes', 'external nodes'].includes(
          node['Node Class']?.toLowerCase()
        )
      ) {
        checkDuplicate(errorsArray, count, 'Node Class is invalid.');
      }

      if (
        ![
          'company plant / warehouse',
          'dark store',
          'distributor',
          'retailer',
          'other'
        ].includes(node['Node Type']?.toLowerCase()) ||
        (node['Node Class'].toLowerCase() === 'internal nodes' &&
          !['company plant / warehouse', 'dark store'].includes(
            node['Node Type']?.toLowerCase()
          )) ||
        (node['Node Class'].toLowerCase() === 'external nodes' &&
          !['distributor', 'retailer', 'other'].includes(
            node['Node Type']?.toLowerCase()
          ))
      ) {
        checkDuplicate(errorsArray, count, 'Node Type is invalid.');
      }
      temp.push(node);
      count++;
    }
    if (errorsArray?.length > 0) {
      setSelectedFile(null);
      setErrorAlerts([...errorsArray]);
    }

    let node = finalizeNodesData(data.nodes);

    if (errorsArray.length === 0) {
      try {
        await API.post(`orders/nodes/bulk`, node);
        setSelectedFile(null);
        setSuccessAlerts(['File Successfully Uploaded']);
        return;
      } catch (err) {
        setSelectedFile(null);
        setSuccessAlerts([]);
        if (Array.isArray(err?.response?.data)) {
          for (const error of err?.response?.data || []) {
            errorsArray = [...errorsArray, error];
          }
          setErrorAlerts([...errorsArray]);
        } else {
          onError(err);
        }
      } finally {
        setAPILoader(false);
      }
    }
    setAPILoader(false);
  };

  const checkDuplicate = (errArr, count, message) => {
    setSelectedFile(null);
    setSuccessAlerts([]);
    errArr.push({
      row: count,
      message: `Row ${count} : ${message}`
    });
  };

  const finalizeNodesData = (nodes) => {
    for (let node of nodes) {
      switch (node['Node Type']?.toLowerCase()) {
        // For node type company plant node type ID is 1
        case 'company plant / warehouse':
          node.nodeTypeId = 1;
          break;
        // For node type dark store node type ID is 2
        case 'dark store':
          node.nodeTypeId = 2;
          break;
        // For node type distributor node type ID is 3
        case 'distributor':
          node.nodeTypeId = 3;
          break;
        // For node type retailer node type ID is 4
        case 'retailer':
          node.nodeTypeId = 4;
          break;
        // For node type other node type ID is 5
        case 'other':
          node.nodeTypeId = 5;
          break;
        default:
          node.nodeTypeId = 1;
          break;
      }

      switch (node['Node Class']?.toLowerCase()) {
        // For node class internal node class ID is 1
        case 'internal nodes':
          node.nodeClassId = 1;
          break;
        // For node class external node class ID is 2
        case 'external nodes':
          node.nodeClassId = 2;
          break;
        default:
          node.nodeClassId = 1;
          break;
      }
      node.code = node['Location Code'];
      node.name = node['Location Name'];
      node.description = node['Node Description'];
      node.address = node['Node Address'];
      node.city = node['City'];
      node.latitude = node['Latitude'];
      node.longitude = node['Longitude'];
      node.nodeClass = capitalize(node['Node Class']);
      if (node['POC Name']) node.pocName = node['POC Name'];
      if (node['POC Contact']) node.pocContact = node['POC Contact'];
      node.nodeType = capitalize(node['Node Type']);

      delete node['Location Name'];
      delete node['Location Code'];
      delete node['Node Description'];
      delete node['Node Address'];
      delete node['City'];
      delete node['Latitude'];
      delete node['Longitude'];
      delete node['Node Class'];
      delete node['Node Type'];
      delete node['POC Contact'];
      delete node['POC Name'];
    }
    return nodes;
  };

  const dataSanitization = (data) => {
    let count = 2;
    let sanitizationArray = [];
    for (let node of data) {
      validateCols(
        node['Location Code'],
        count,
        sanitizationArray,
        'Location Code is missing'
      );
      validateCols(
        node['Location Name'],
        count,
        sanitizationArray,
        'Location Name is missing'
      );
      validateCols(
        node['Node Description'],
        count,
        sanitizationArray,
        'Node Description is missing'
      );
      validateCols(
        node['Node Address'],
        count,
        sanitizationArray,
        'Node Address is missing'
      );
      validateCols(node['City'], count, sanitizationArray, 'City is missing');
      validateCols(
        node['Latitude'],
        count,
        sanitizationArray,
        'Latitude is missing'
      );
      validateCols(
        node['Longitude'],
        count,
        sanitizationArray,
        'Longitude is missing'
      );
      validateCols(
        node['Node Class'],
        count,
        sanitizationArray,
        'Node Class is missing'
      );
      validateCols(
        node['Node Type'],
        count,
        sanitizationArray,
        'Node Type is missing'
      );
      count++;
    }
    return sanitizationArray;
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          className={classes.topHeader}
        >
          <Grid item xs={12} sm={10}>
            <Typography
              component="div"
              variant="h4"
              className={classes.heading}
            >
              Nodes Bulk Upload
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.backBtn}>
            <Tooltip title="Back To Company List">
              <Button
                variant="contained"
                className={classes.nodeBulkButtons}
                color="primary"
                onClick={() =>
                  navigate(`/administration/company-details/${company_id}`)
                }
              >
                Back
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          className={classes.headerBtns}
        >
          <Grid item xs={12} sm={5} md={3} className={classes.downloadTempBtn}>
            <Tooltip title="Download Bulk Node Template">
              <a href={downloadData} className={classes.nodeBulkButtons}>
                <Button
                  variant="contained"
                  className={classes.nodeBulkButtons}
                  color="primary"
                  fullWidth
                >
                  Download Template
                </Button>
              </a>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ProductsCsvReader
              bulkUpload={bulkUpload}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              type="nodes"
            />
          </Grid>
        </Grid>
        {fileUploaded ? (
          <>
            <Grid item xs={12} alignItems="center">
              <Typography component="div" className={classes.subHeading}>
                Bulk Upload Details
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.uploadDetails}>
              {errorAlerts?.map((alert, index) => {
                return (
                  <Alert
                    key={index}
                    severity="error"
                    className={classes.systemAlert}
                  >
                    {' '}
                    {alert?.message ? alert?.message : alert}{' '}
                  </Alert>
                );
              })}
              {successAlerts?.map((alert, index) => {
                return (
                  <Alert
                    key={index}
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    className={classes.systemAlert}
                  >
                    {' '}
                    {alert}{' '}
                  </Alert>
                );
              })}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} alignItems="center">
              <Typography
                component="div"
                className={classes.subHeadingGuideline}
              >
                Bulk Upload Guidelines
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              alignItems="center"
              className={classes.guidelines}
            >
              <Alert severity="info" className={classes.guideLine}>
                Bulk upload can only happen for 1 customer at a time.
              </Alert>
              <Alert severity="info" className={classes.guideLine}>
                Please load only Customer (Non Oware) locations in Nodes. Oware
                Warehouses need to be defined separately in the Warehouses
                Screen.
              </Alert>
              <Alert severity="info" className={classes.guideLine}>
                The template contains sample value for node rows which must be
                replaced with actual values before upload.
              </Alert>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default NodeBulkUpload;

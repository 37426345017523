import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  Button,
  Box
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useContext, useEffect, useState } from 'react';
import { SharedContext } from '../../utils/common';
import {
  checkPermission,
  getUserToken,
  setUser,
  setUserToken
} from '../../utils/auth';
import OwareLogo from '../../assets/icons/O360DarkLogo.svg';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import ForgotPasswordPopup from './ForgotPasswordPopup';
import PasswordField from './PasswordField';
import { toaster } from '../../utils/toaster';
import { useCapcha } from '../../hooks/useCapcha';
import mixpanel from 'mixpanel-browser';
import Loader from '../multiModalShipment/comments/components/Loader';

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    marginBottom: '20px',
    padding: 5,
    height: '55vh',
    maxWidth: '350px',
    minWidth: 'auto',
    margin: '10% auto'
  },
  children: {
    margin: '5px auto'
  },
  forgotPassword: {
    color: theme.palette.primary.main
  }
}));

export default function LoginView() {
  let [searchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [formErrors, setFormErrors] = useState(null);
  const { subdomain } = useContext(SharedContext);
  const [validateCapcha, onChangeCapcha, CAPCHA_SITE_KEY] = useCapcha();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const {
    setAuthToken,
    setCurrentUser,
    currentUser,
    setOrganization,
    setOrganizationType,
    setAPILoader
  } = useContext(SharedContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const setToken = (token) => {
    setUserToken(token);
    return setAuthToken(token);
  };

  const saveAuthToken = async () => {
    try {
      let token = searchParams.get('verificationToken');
      const response = await API.post('auth/user', {
        verificationToken: token
      });

      if (!response.token) return;

      setUserToken(response.token);
      const resTwo = await API.get(`me`);
      if (resTwo) {
        setUser(resTwo.user);
        setCurrentUser(resTwo.user);
      }

      mixpanel.track('Trial started', {
        organizationId: resTwo.user.Role.organizationId,
        email: resTwo.user.email
      });
      navigate('/');
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    searchParams.get('verificationToken') && saveAuthToken();
    if (
      currentUser &&
      checkPermission(currentUser, 'OPS_CONTROL_TOWER_VIEW') &&
      !isMobile
    ) {
      navigate(`/control-tower`);
    } else if (currentUser) {
      navigate(`/routing/order-management`);
    }
  }, [currentUser]);

  useEffect(async () => {
    if (getUserToken()) {
      try {
        const resTwo = await API.get(`me`);
        if (resTwo) {
          setUser(resTwo.user);
          setCurrentUser(resTwo.user);
        }
      } catch (err) {
        onError(err);
      }
    }
  }, [getUserToken()]);

  const handleSubmit = async (values) => {
    values.preventDefault();

    if (!validateCapcha()) {
      toaster('error', 'Invalid Capcha!');
      return;
    }

    try {
      setAPILoader(true);
      const body = {
        username,
        password
      };
      const auth = await API.post(`auth/login`, body);
      const orgs = auth.organizations;
      let userOrg = {};

      if (auth.user.isCustomerUser) {
        throw new Error('User is not authorized to access this application');
      }

      if (orgs.length == 0) {
        throw new Error("User doesn't belong to organization");
      } else {
        let userBelongToOrganization = false;
        for (let i = 0; i < orgs.length; i++) {
          if (subdomain === orgs[i].Organization.subdomain) {
            userBelongToOrganization = true;
            setOrganization(orgs[i].Organization);
            userOrg = orgs[i].Organization;
            setOrganizationType(orgs[i].Organization.type);
            break;
          }
        }
        if (!userBelongToOrganization) {
          throw new Error("User doesn't belong to organization");
        }
      }
      if (auth && !auth.error) {
        mixpanel.identify(auth.user.id);
        mixpanel.people.set({
          ...auth.user,
          ...userOrg,
          $orgName: userOrg?.name,
          $name: `${auth.user.firstName || ''} ${
            auth.user.lastName || ''
          }`.trim(),
          $email: auth.user.email
        });
        mixpanel.track('Login', {
          loginType: 'Conventional',
          subdomain
        });
        setToken(auth.token);
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handlePopupClose = () => {
    setShowForgotPassword(false);
  };

  return !searchParams.get('verificationToken') ? (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Paper elevation={0} className={classes.paperStyle}>
          <Grid align="center">
            <img src={OwareLogo} alt="Logo" width="auto" height="34px" />
          </Grid>
          {formErrors}
          <Box mt={4}>
            <TextField
              label="Username or Email"
              variant="outlined"
              placeholder="name@example.com"
              fullWidth
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Box>
          <Box mt={3}>
            <PasswordField
              name="Password"
              label="Password"
              placeholder="*****"
              fullWidth
              required
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
            />
          </Box>
          <Box mt={2}>
            <Link
              to=""
              onClick={handleForgotPassword}
              className={classes.forgotPassword}
            >
              Forgot Password?
            </Link>
            {showForgotPassword && (
              <ForgotPasswordPopup onClose={handlePopupClose} />
            )}
          </Box>
          <ReCAPTCHA
            sitekey={CAPCHA_SITE_KEY}
            onChange={onChangeCapcha}
            className="my-2"
          />
          <Box mt={2}>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Login
            </Button>
          </Box>
        </Paper>
      </Grid>
    </form>
  ) : (
    <Loader loader className="w-full" />
  );
}

import { Tooltip } from '@mui/material';
import { MdOutlineAdjust } from 'react-icons/md';

import { dateFormatSlashDivider } from '../../../utils/common';

const ordersVerificationColumns = [
  {
    field: 'isActive',
    headerName: '',
    disableColumnMenu: true,
    sortable: false,
    cellClassName: 'justify-content-center',
    headerClassName: 'headerCenter',
    width: 50,
    renderCell: (params) => (
      <Tooltip title={params?.row?.recordLevel}>
        <span className="table-cell-trucate">
          <MdOutlineAdjust
            className={
              params?.row?.recordLevel == 'Correct' ? 'online' : 'offline'
            }
          />
        </span>
      </Tooltip>
    )
  },
  {
    field: 'sourceSection',
    width: 150,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Source Section</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.sourceSection}`}>
        <span className="table-cell-trucate">{params?.row?.sourceSection}</span>
      </Tooltip>
    )
  },
  {
    field: 'destinationSection',
    width: 200,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader">Destination Section</p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.destinationSection}`}>
        <span className="table-cell-trucate">
          {params?.row?.destinationSection}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'product',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">SKU Code</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.product}`}>
        <span className="table-cell-trucate">{params?.row?.product}</span>
      </Tooltip>
    )
  },
  {
    field: 'palletId',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Pallet ID</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.palletId || '-'}`}>
        <span className="table-cell-trucate">
          {params?.row?.palletId || '-'}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'batch',
    width: 100,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Batch</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.batch}`}>
        <span className="table-cell-trucate">
          {params?.row?.batch ? params?.row?.batch : '-'}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'uom',
    width: 80,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">UoM</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.uom}`}>
        <span className="table-cell-trucate">{params?.row?.uom}</span>
      </Tooltip>
    )
  },
  {
    field: 'quantity',
    width: 100,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Quantity</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.quantity}`}>
        <span className="table-cell-trucate">{params?.row?.quantity}</span>
      </Tooltip>
    )
  },
  {
    field: 'manufacturingDate',
    width: 180,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader">Manufacturing Date</p>
      );
    },
    renderCell: (params) => (
      <Tooltip
        title={`${dateFormatSlashDivider(params?.row?.manufacturingDate)}`}
      >
        <span className="table-cell-trucate">
          {dateFormatSlashDivider(params?.row?.manufacturingDate)}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'expiryDate',
    width: 180,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Expiry Date</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${dateFormatSlashDivider(params?.row?.expiryDate)}`}>
        <span className="table-cell-trucate">
          {dateFormatSlashDivider(params?.row?.expiryDate)}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'recordLevel',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Row Level</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.warning || params?.row?.recordLevel}`}>
        <span className="table-cell-trucate">
          {params?.row?.warning || params?.row?.recordLevel}
        </span>
      </Tooltip>
    )
  }
];

export default ordersVerificationColumns;

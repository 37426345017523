/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { checkPermission } from '../../../utils/auth';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import classNames from 'classnames/bind';
import { DataGrid } from '@mui/x-data-grid';

import {
  makeStyles,
  Paper,
  Button,
  Table,
  TableBody,
  Tooltip,
  TableContainer
} from '@material-ui/core';
import TableHeader from '../../../components/TableHeader';
import Filter from '../../../components/Filter';

import { SharedContext } from '../../../utils/common';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import PaginationGrid from '../../../components/PaginationGrid';
import CustomToolbar from '../../../components/CustomToolbar';
import '../../../assets/css/style.css';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddRegionView from './AddRegionView';
import EditIcon from '@material-ui/icons/EditOutlined';
import { toaster } from '../../../utils/toaster';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px'
  },
  container: {
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInputRegion: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  addRegionButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  }
}));

export default function RegionView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { country_id } = useParams();

  const columnsNew = [
    {
      field: 'isActive',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Region name',
      flex: 1,
      disableColumnMenu: true,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      sortable: false,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Region Name
            {fetchState && nameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          <Tooltip title="View">
            <VisibilityIcon
              key={params.row.id}
              onClick={() => navigate(`${params.row.id}/cities`)}
            />
          </Tooltip>
          {checkPermission(currentUser, 'OPS_REGION_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon key={params} onClick={() => openEditView(params.row)} />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [regions, setRegions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterView, setFilterView] = useState(false);
  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  const [nameVal, setName] = useState('');
  const [focus, setFocus] = useState(false);
  const [fetchState, setFetchState] = useState(false);
  const [sortable, setSortable] = useState('');
  const [addRegionViewOpen, setAddRegionViewOpen] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);

  useEffect(() => {
    setCurrentPageTitle('Region');
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes();
  }, [nameVal, sortable]);

  const { currentUser } = useContext(SharedContext);

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (nameVal) {
      colVal = { name: nameVal };
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = ['name'];

    _getRegions(page, searchKeyword, filters, columns);
  };

  const _getRegions = async (page, searchKeyword, filters, columns) => {
    try {
      const regions = await API.get(`countries/${country_id}/regions`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setPageCount(regions.pages);
      setRegions(regions.data);
    } catch (err) {
      onError(err);
    }
  };

  const openEditView = (region) => {
    setSelectedRegion(region);
    setAddRegionViewOpen(true);
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
  };

  const getRegions = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        name: searchKeyword ? searchKeyword : '',
        sortable
      };

      let columns = ['name'];
      _getRegions(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  const addRegion = async (data) => {
    setFormErrors('');
    try {
      setAPILoader(false);
      if (!selectedRegion) await API.post(`regions`, data);
      else await API.put(`regions/${selectedRegion.id}`, data);
    } catch (err) {
      onError(err);
    } finally {
      toaster(
        'success',
        !selectedRegion
          ? 'New Region has been created.'
          : 'Region updated successfully.'
      );
      closeAddRegionView();
      getRegions(page, nameVal);
      setAPILoader(false);
    }
  };

  const closeAddRegionView = () => {
    setSelectedRegion(null);
    setAddRegionViewOpen(false);
  };

  const addRegionModal = (
    <AddRegionView
      key={4}
      formErrors={formErrors}
      selectedRegion={selectedRegion}
      open={addRegionViewOpen}
      addRegion={addRegion}
      country_id={country_id}
      handleClose={() => closeAddRegionView()}
    />
  );

  useEffect(() => {
    getRegions(page, searchKeyword);
  }, [page, searchKeyword]);

  let nameObj = {
    type: 'text',
    className: 'textField',
    label: 'Region Name',
    field: 'name',
    value: nameVal ? nameVal : '',
    setNameVal: (e) => setNameVal(setName, e)
  };

  const filterCols = [nameObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'User'}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const addRegionButton = (
    <Tooltip title="Add Region">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addRegionButton}
        // color="primary"
        size="small"
        onClick={() => setAddRegionViewOpen(true)}
      >
        ADD Region
      </Button>
    </Tooltip>
  );
  const backButton = (
    <Tooltip title="Back To Country">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addRegionButton}
        // color="primary"
        size="small"
        onClick={() => navigate(`/administration/country`)}
      >
        Back
      </Button>
    </Tooltip>
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_REGION_CREATE') ? addRegionButton : null,
    backButton,
    // checkPermission(currentUser, "OPS_REGION_READ") ? searchInputRegion : null,
    checkPermission(currentUser, 'OPS_REGION_CREATE') ||
    checkPermission(currentUser, 'OPS_REGION_UPDATE')
      ? addRegionModal
      : null,
    checkPermission(currentUser, 'OPS_REGION_READ') ? filterModal : null
  ];

  // Custom toolbar
  function CustomRegionToolbar(props) {
    return (
      <CustomToolbar
        props={props}
        searchKeyword={searchKeyword}
        openFilterView={openFilterView}
      />
    );
  }
  // Custom toolbar
  const visibleColumns = { name: true };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table" className="tableDiv">
          <TableBody>
            <div style={{ height: 750, width: '100%' }}>
              <DataGrid
                rows={regions}
                hiddenColumnModel={['id']}
                visibleColumns={visibleColumns}
                columns={columnsNew}
                components={{ Toolbar: CustomRegionToolbar }}
                pageSize={15}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { StyledEngineProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import config from './config/';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

Sentry.init({
  dsn: `${config?.REACT_APP_SENTRY_DSN}`,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    })
  ]
});

mixpanel.init(`${config.REACT_APP_MIXPANEL_TOKEN}`, {
  // debug: true,
  // track_pageview: 'url-with-path',
  persistence: 'localStorage'
});

const host = window.location.hostname;
const [subdomain] = host.split('.');

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY,
    context: {
      key: config.REACT_APP_LAUNCH_DARKLY_USER_KEY,
      custom: {
        organization: subdomain
      }
    }
  });

  ReactDOM.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <LDProvider>
            <App />
          </LDProvider>
        </BrowserRouter>
      </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

/*eslint-disable*/
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EDIT_ICON from '../../../assets/icons/edit-row.svg';
import DELETE_ICON from '../../../assets/icons/delete-row.svg';
import AddBatch from './AddDefaultGdnBatch';
import Popup from '../../../core-components/atoms/Popup';

const ProductSubTable = ({
  batches,
  enableActions,
  deleteBatch,
  GDNview,
  row,
  addBatch,
  editBatch,
  parentIndex,
  remainingQty,
  inventoryBatches,
  getBatches,
  Order,
  pickingTask,
  pickedProducts,
  uom
}) => {
  const [childIndex, setChildIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const dateFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  return (
    <>
      <Table
        size="small"
        className="bg-light border-2 border-dashed rounded-md py-3"
      >
        <TableHead className="border-none">
          <TableCell className="py-2 text-xs border-none">
            Batch Number
          </TableCell>
          <TableCell className="py-2 text-xs border-none">
            Dispatched Qty
          </TableCell>
          <TableCell className="py-2 text-xs border-none">
            MRP (Per Pack) Rs
          </TableCell>
          {GDNview ||
            (enableActions && <TableCell className="py-2 text-xs"></TableCell>)}
        </TableHead>
        <TableBody className="border-none">
          {batches.map((batch, index) => {
            return (
              <>
                {+batch.actualDispatchedQuantity === +batch.promoQuantity || (
                  <TableRow className="group">
                    <TableCell className="py-2 border-none">
                      <p className="text-sm">{batch.batchNumber}</p>
                      <p className="text-xs">
                        {batch.manufacturingDate && (
                          <span className="mr-2">
                            Mfg:{' '}
                            {new Date(batch.manufacturingDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                        {batch.expiryDate && (
                          <span>
                            Exp:{' '}
                            {new Date(batch.expiryDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                      </p>
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      {(batch.promoQuantity
                        ? Number(batch.actualDispatchedQuantity) -
                          Number(batch.promoQuantity)
                        : Number(batch.actualDispatchedQuantity)) /
                        (uom?.conversionFactor || 1)}
                    </TableCell>
                    <TableCell className="py-2 text-sm text-warning border-none">
                      {batch.MRP}
                    </TableCell>
                    {GDNview ||
                      (enableActions && (
                        <TableCell className="w-24 p-0 border-none">
                          <span className="flex inset-0 opacity-0 group-hover:opacity-100 transition-opacity">
                            <img
                              src={EDIT_ICON}
                              alt="Edit Icon"
                              className="mx-2"
                              onClick={() => {
                                getBatches(
                                  Order?.pickupId,
                                  Order?.companyId,
                                  row.Product.id
                                );
                                setChildIndex(index);
                                setOpen(true);
                              }}
                            />
                            <img
                              src={DELETE_ICON}
                              alt="Delete Icon"
                              className="mx-2"
                              onClick={() => deleteBatch(index)}
                            />
                          </span>
                        </TableCell>
                      ))}
                  </TableRow>
                )}
                {(batch.promoName || !!batch.promoQuantity) && (
                  <TableRow className="group">
                    <TableCell className="py-2 text-sm text-darkPrimary border-none">
                      <div>
                        <span className="text-black">{batch.batchNumber}</span>
                        <pre className="font-bold inline"> · </pre>
                        {batch.promoName}
                      </div>
                      <p className="text-xs">
                        {batch.manufacturingDate && (
                          <span className="mr-2">
                            Mfg:{' '}
                            {new Date(batch.manufacturingDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                        {batch.expiryDate && (
                          <span>
                            Exp:{' '}
                            {new Date(batch.expiryDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                      </p>
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      {Number(batch.promoQuantity)}
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      -
                    </TableCell>
                    {GDNview ||
                      (enableActions && (
                        <TableCell className="w-24 p-0 border-none">
                          <span className="flex inset-0 opacity-0 group-hover:opacity-100 transition-opacity">
                            <img
                              src={EDIT_ICON}
                              alt="Edit Icon"
                              className="mx-2"
                              onClick={() => {
                                getBatches(
                                  Order?.pickupId,
                                  Order?.companyId,
                                  row.Product.id
                                );
                                setChildIndex(index);
                                setOpen(true);
                              }}
                            />
                            <img
                              src={DELETE_ICON}
                              alt="Delete Icon"
                              className="mx-2"
                              onClick={() => deleteBatch(index, true)}
                            />
                          </span>
                        </TableCell>
                      ))}
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>
      <Popup
        title={`Edit Batch Details for ${row?.Product?.name}-${row?.Product?.description}`}
        open={open}
        setOpen={setOpen}
        content={
          <AddBatch
            addBatch={addBatch}
            editBatch={editBatch}
            parentIndex={parentIndex}
            remainingQty={remainingQty}
            setOpen={setOpen}
            product={row?.Product}
            edit={true}
            childIndex={childIndex}
            batch={batches[childIndex]}
            inventoryBatches={inventoryBatches}
            row={row}
            pickingTask={pickingTask}
            pickedProducts={pickedProducts}
            pickedValue={pickedProducts?.[childIndex]}
            uom={uom}
          />
        }
      />
    </>
  );
};

export default ProductSubTable;

import { useEffect, useState, useCallback, useContext } from 'react';
import {
  makeStyles,
  Paper,
  InputBase,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Tooltip,
  TableHead,
  TableRow
} from '@material-ui/core';
import TableHeader from '../../../components/TableHeader';
import axios from 'axios';
import { getURL, SharedContext } from '../../../utils/common';
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/EditOutlined';
import ConfirmDelete from '../../../components/ConfirmDelete';
import AddCategoryView from './AddCategoryView';
import PaginationGrid from '../../../components/PaginationGrid';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { checkPermission } from '../../../utils/auth';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    // maxHeight: 450,
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInputCategory: {
    border: '1px solid grey',
    borderRadius: 4,
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    height: 30
  }
}));

export default function CategoryView() {
  const classes = useStyles();

  const columnsCategory = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 'auto',
      className: ''
    },
    {
      id: 'isActive',
      label: 'Status',
      minWidth: 'auto',
      className: (value) => (value ? classes.active : ''),
      format: (value) => (value ? 'Active' : 'In-Active')
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 'auto',
      className: '',
      format: (value, entity) => [
        checkPermission(currentUser, 'OPS_CATEGORY_UPDATE') ? (
          <Tooltip title="Edit">
            <EditIcon key="edit" onClick={() => openEditView(entity)} />
          </Tooltip>
        ) : (
          ''
        )
        // <DeleteIcon color="error" key="delete" onClick={() => openDeleteView(entity)} />
      ]
    }
  ];
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formErrors, setFormErrors] = useState('');
  const [addCategoryViewOpen, setAddCategoryViewOpen] = useState(false);
  const [deleteCategoryViewOpen, setDeleteCategoryViewOpen] = useState(false);
  const { currentUser } = useContext(SharedContext);

  const addCategory = (data) => {
    let apiPromise = null;
    if (!selectedCategory) apiPromise = axios.post(getURL('category'), data);
    else
      apiPromise = axios.put(getURL(`category/${selectedCategory.id}`), data);
    apiPromise.then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      toaster(
        'success',
        !selectedCategory
          ? 'New category has been created.'
          : 'Category has been updated.'
      );
      closeAddCategoryView();
      getCategories();
    });
  };

  const deleteCategory = () => {
    axios.delete(getURL(`category/${selectedCategory.id}`)).then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      closeDeleteCategoryView();
      getCategories();
    });
  };

  const openEditView = (category) => {
    setSelectedCategory(category);
    setAddCategoryViewOpen(true);
  };

  const closeAddCategoryView = () => {
    setSelectedCategory(null);
    setAddCategoryViewOpen(false);
  };

  const closeDeleteCategoryView = () => {
    setSelectedCategory(null);
    setDeleteCategoryViewOpen(false);
  };

  // const _getCategories = (page, searchKeyword) => {
  //   axios.get(getURL('category'), { params: { page, search: searchKeyword } })
  //     .then(res => {
  //       setPageCount(res.data.pages);
  //       setCategories(res.data.data);
  //     });
  // }

  const _getCategories = async (page, searchKeyword, filters, columns) => {
    try {
      const categories = await API.get(`categories`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setPageCount(categories.pages);
      setCategories(categories.data);
    } catch (err) {
      onError(err);
    }
  };

  const getCategories = useCallback(
    debounce((page, searchKeyword) => {
      _getCategories(page, searchKeyword);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getCategories(page, searchKeyword);
  }, [page, searchKeyword]);

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'Category';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  const searchInputCategory = (
    <InputBase
      placeholder="Search"
      className={classes.searchInputCategory}
      id="search"
      label="Search"
      type="text"
      variant="outlined"
      value={searchKeyword}
      key={1}
      onChange={(e) => setSearchKeyword(e.target.value)}
    />
  );
  const addCategoryButton = (
    <Button
      key={2}
      variant="contained"
      color="primary"
      size="small"
      onClick={() => setAddCategoryViewOpen(true)}
    >
      ADD CATEGORY
    </Button>
  );
  const addCategoryModal = (
    <AddCategoryView
      key={3}
      formErrors={formErrors}
      selectedCategory={selectedCategory}
      open={addCategoryViewOpen}
      addCategory={addCategory}
      handleClose={() => closeAddCategoryView()}
    />
  );
  const deleteCategoryModal = (
    <ConfirmDelete
      key={4}
      confirmDelete={deleteCategory}
      open={deleteCategoryViewOpen}
      handleClose={closeDeleteCategoryView}
      selectedEntity={selectedCategory && selectedCategory.name}
      title={'Category'}
    />
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_CATEGORY_READ')
      ? searchInputCategory
      : null,
    checkPermission(currentUser, 'OPS_CATEGORY_CREATE')
      ? addCategoryButton
      : null,
    checkPermission(currentUser, 'OPS_CATEGORY_CREATE') ||
    checkPermission(currentUser, 'OPS_CATEGORY_UPDATE')
      ? addCategoryModal
      : null,
    checkPermission(currentUser, 'OPS_CATEGORY_DELETE')
      ? deleteCategoryModal
      : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnsCategory.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={category.id}>
                  {columnsCategory.map((column) => {
                    const value = category[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={
                          column.className &&
                          typeof column.className === 'function'
                            ? column.className(value)
                            : column.className
                        }
                      >
                        {column.format ? column.format(value, category) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}

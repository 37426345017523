import React, { useEffect, useRef, useState } from 'react';
import API from '../libs/axios';
import { Tooltip } from '@material-ui/core';
import { CSVLink } from 'react-csv';

const ExportButton = ({ api, style, cropText }) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [fileName, setFileName] = useState('Data_Export');
  const [loading, setLoading] = useState(false);
  const csvInstance = useRef();
  const title = cropText ? 'Export' : 'Export To Excel';

  const exportToExcel = async () => {
    if (api && !loading) {
      setLoading(true);
      const { apiUrl, options } = api;
      let result;
      result = await API.get(apiUrl, { ...options });
      if (result.data && result.headers) {
        result.filename && setFileName(result.filename);
        setData(result.data);
        setHeaders(result.headers);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      csvInstance.current &&
      csvInstance.current.link &&
      data.length &&
      headers.length
    ) {
      csvInstance.current.link.click();
    }
  }, [data, headers]);
  return (
    <>
      <Tooltip title={title}>
        <button
          className={`exportBtn ${style}`}
          id="export"
          // eslint-disable-next-line react/no-unknown-property
          variant="outlined"
          key={3}
          onClick={() => exportToExcel()}
        >
          <b>{loading ? 'Downloading...' : title}</b>
        </button>
      </Tooltip>
      {data?.length && headers?.length && loading ? (
        <CSVLink
          style={{ color: 'white' }}
          data={data}
          headers={headers}
          filename={fileName}
          ref={csvInstance}
          onClick={() => exportToExcel()}
        />
      ) : undefined}
    </>
  );
};

export default ExportButton;

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { isRequired } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  }
}));

export default function AddBrandView({
  addBrand,
  open,
  handleClose,
  selectedBrand,
  formErrors,
  categories,
  selectCategory
}) {
  const classes = useStyles();

  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');
  const [isActive, setActive] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryName, setSelectedCategoryName] = useState('');

  const resetStates = () => {
    setValidation({});
    setName('');
    setManufacturerName('');
    setSelectedCategory('');
    setSelectedCategoryName('');
    setActive(true);
  };

  useEffect(() => {
    if (selectedBrand) {
      setName(selectedBrand.name || '');
      setManufacturerName(selectedBrand.manufacturerName || '');
      setSelectedCategoryName(selectedBrand.Category.name || '');
      setSelectedCategory(selectedBrand.Category.id || '');
      setActive(!!selectedBrand.isActive);
    } else {
      setName('');
      setManufacturerName('');
      setSelectedCategoryName('');
      setActive(true);
    }
  }, [selectedBrand]);
  const handleSubmit = () => {
    const newBrand = {
      name,
      manufacturerName,
      categoryId: selectedCategory,
      isActive
    };
    setValidation({
      name: true,
      selectedCategory: true
    });
    if (isRequired(name) && isRequired(selectedCategory)) {
      addBrand(newBrand);
    }
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          onBackdropClick={() => {
            resetStates();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle disableTypography className="title">
            {!selectedBrand ? 'Add Brand' : 'Edit Brand'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  variant="outlined"
                  value={name}
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => setValidation({ ...validation, name: true })}
                />
                {validation.name && !isRequired(name) ? (
                  <Typography color="error">Name is required!</Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  margin="dense"
                  id="manufacturerName"
                  label="Manufacturer Name"
                  type="text"
                  variant="outlined"
                  value={manufacturerName}
                  onChange={(e) => setManufacturerName(e.target.value)}
                  onBlur={() =>
                    setValidation({ ...validation, manufacturerName: true })
                  }
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                />
                {validation.manufacturerName &&
                !isRequired(manufacturerName) ? (
                  <Typography color="error">
                    Manufacturer name is required!
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>

              <Grid item xs={12}>
                {/* <FormControl fullWidth id="selectCategory">
                  <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      label="Select Category"
                      onChange={(e)=>{
                        setSelectedCategory(e.target.value)
                        selectCategory(e.target.value)
                      }}
                      onBlur={e => setValidation({ ...validation, selectedCategory: true })}
                    >
                      {categories.map(category => <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)}
                  </Select>
              </FormControl> */}

                <FormControl margin="dense" fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="categoryId"
                    key={categories}
                    options={categories}
                    defaultValue={
                      selectedCategoryName
                        ? {
                            name: selectedCategoryName,
                            id: categories.id
                          }
                        : ''
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category*"
                        variant="outlined"
                      />
                    )}
                    getOptionLabel={(category) => category.name || ''}
                    onBlur={() =>
                      setValidation({ ...validation, selectedCategory: true })
                    }
                    onChange={(event, newValue) => {
                      setSelectedCategory(newValue.id);
                      selectCategory(newValue.id);
                    }}
                  />
                  {validation.selectedCategory &&
                  !isRequired(selectedCategory) ? (
                    <Typography color="error">
                      Categories is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>

              <Grid item sm={12}>
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setActive(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Active
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  resetStates();
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title={!selectedBrand ? 'Add Brand' : 'Update Brand'}>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                {!selectedBrand ? 'Add Brand' : 'Update Brand'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

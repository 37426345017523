import React from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { PaginationItem } from '@mui/material';

export default function CustomPagination({ page, setPage, pages }) {
  const useStyles = makeStyles(() => ({
    ul: {
      '& .MuiPaginationItem-root': {
        backgroundColor: '#fff',
        color: '#333',
        '&:hover': {
          backgroundColor: '#fff'
        },
        border: '1px solid #9DA0A6',
        borderRadius: '0.5rem',
        fontWeight: 600,
        fontSize: '0.8rem'
      },
      '& .Mui-selected': {
        backgroundColor: '#0C71D7 !important',
        color: 'white',
        '&:hover': {
          backgroundColor: '#0C71D7 !important'
        },
        border: '1px solid transparent'
      }
    },
    pageIconBtns: {
      fontWeight: 600,
      color: '#333333',
      fontSize: '0.9rem',
      '&:hover': {
        background: '#fff'
      }
    },
    disabled: {
      fontWeight: 600,
      color: '#CCCCCC',
      fontSize: '0.9rem',
      '&:hover': {
        background: '#fff'
      }
    }
  }));
  const classes = useStyles();

  return (
    <Grid item container className="mt-5" justifyContent="space-between">
      <Grid item></Grid>
      <Grid item>
        <Pagination
          component="div"
          shape="rounded"
          count={pages}
          classes={{ ul: classes.ul }}
          page={page}
          onChange={(e, page) => {
            setPage(page);
          }}
          renderItem={(item) => {
            if (item.type === 'previous') {
              return (
                <IconButton
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                  aria-label="previous"
                  className={
                    page === 1 || pages == 0
                      ? classes.disabled
                      : classes.pageIconBtns
                  }
                >
                  Prev
                </IconButton>
              );
            }
            if (item.type === 'next') {
              return (
                <IconButton
                  onClick={() => setPage(page + 1)}
                  disabled={page === pages}
                  aria-label="next"
                  className={
                    page === pages || pages == 0
                      ? classes.disabled
                      : classes.pageIconBtns
                  }
                >
                  Next
                </IconButton>
              );
            }
            return (
              <PaginationItem
                component="li"
                {...item}
                className={classes.activeItem}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

import React, { useState } from 'react';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { Paragraph } from '../../../../core-components/atoms/Text';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import { checkPermission } from '../../../../utils/auth';
import editIcon from '../../../../assets/icons/editIconDark.svg';
import viewIcon from '../../../../assets/icons/viewIcon.svg';

const BrandListComponent = ({
  data,
  searchValue,
  onClick,
  getBrands,
  currentUser,
  editOpen,
  onClickView,
  viewOpen
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    if (!viewOpen && !editOpen) {
      setIsHovering(false);
    } else {
      setIsHovering(true);
    }
  };

  const removeBrand = async () => {
    try {
      await API.delete(`brands/${data.id}`);
      toaster('success', `Brand deleted successfully.`);

      getBrands();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_category_id`,
        `$ already exists it must be unique`
      );
    }
  };

  return (
    <>
      <div
        className="flex items-center justify-between bg-white cursor-pointer shadow-elevation-1 px-4 py-4 border-b"
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <div className={'max-w-[200px] min-w-[187px]'}>
          <Paragraph
            variant={'sm'}
            className={`${searchValue && 'bg-[#FFF0C3]'}flex truncate`}
          >
            {data.name}
          </Paragraph>
          <Paragraph className={'color-[#0B1940]'} variant={'xs'}>
            Product(s): {data.productsCount ? data.productsCount : '-'}
          </Paragraph>
        </div>
        <Paragraph
          variant={'sm'}
          className={`${
            searchValue && 'bg-[#FFF0C3]'
          }  min-w-[187px] max-w-[200px] flex truncate`}
        >
          {data.manufacturerName}
        </Paragraph>

        <div className={'flex h-6 w-20 justify-end gap-2'}>
          {isHovering && (
            <button onClick={onClickView}>
              <img src={viewIcon} alt="view-icon" className={'h-6 w-6'} />
            </button>
          )}
          {isHovering && (
            <button onClick={onClick}>
              <img src={editIcon} alt="edit-icon" className={'h-6 w-6'} />
            </button>
          )}
          {isHovering && checkPermission(currentUser, 'OPS_BRAND_DELETE') && (
            <button
              onClick={async (event) => {
                event.stopPropagation();
                await removeBrand();
              }}
            >
              <img src={deleteIcon} alt="delete-icon" className={'h-6 w-6'} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default BrandListComponent;

import React from 'react';

const BottomBarSkeleton = () => {
  const numberOfSkeletons = 3;
  return (
    <div className="space-y-4">
      {Array.from({ length: numberOfSkeletons }, (_, index) => (
        <div key={index} className="animate-pulse flex items-center space-x-4">
          <div className="w-24 h-6 bg-gray-300 rounded"></div>
          <div className="flex flex-1 space-x-2">
            <div className="w-1/3 h-6 bg-gray-300 rounded"></div>
            <div className="w-1/4 h-6 bg-gray-300 rounded"></div>
            <div className="w-1/6 h-6 bg-gray-300 rounded"></div>
          </div>
          <div className="w-12 h-6 bg-gray-300 rounded"></div>
        </div>
      ))}
    </div>
  );
};

export default BottomBarSkeleton;

import * as Yup from 'yup';

const validationSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (@, !, ?, $, *, %, &)'
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        'New password and confirm password do not match.'
      )
      .required('Confirm password is required.')
  });

export default validationSchema;

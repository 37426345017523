import { Tooltip } from '@mui/material';
import { MdOutlineAdjust } from 'react-icons/md';

import { dateFormatSlashDivider } from '../../utils/common';

const ordersVerificationColumns = [
  {
    field: 'isActive',
    headerName: '',
    disableColumnMenu: true,
    sortable: false,
    cellClassName: 'justify-content-center',
    headerClassName: 'headerCenter',
    width: 50,
    renderCell: (params) => (
      <Tooltip title={params?.row?.recordLevel}>
        <span className="table-cell-trucate">
          <MdOutlineAdjust
            className={
              params?.row?.orderLevel == 'Correct' ? 'online' : 'offline'
            }
          />
        </span>
      </Tooltip>
    )
  },
  {
    field: 'dateOfOrder',
    width: 180,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader">Pickup & Dropoff Date</p>
      );
    },
    renderCell: (params) => (
      <Tooltip
        title={`${dateFormatSlashDivider(
          params?.row?.pickUpDate
        )} - ${dateFormatSlashDivider(params?.row?.dropOffDate)}`}
      >
        <span className="table-cell-trucate">
          {dateFormatSlashDivider(params?.row?.pickUpDate) +
            ' - ' +
            dateFormatSlashDivider(params?.row?.dropOffDate)}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'pickup',
    width: 150,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Origin</p>;
    },
    renderCell: (params) => (
      <Tooltip
        title={`${params?.row?.pickUpCode} - ${params?.row?.pickUpLocation}`}
      >
        <span className="table-cell-trucate">
          {params?.row?.pickUpCode} - {params?.row?.pickUpLocation}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'dropoff',
    width: 180,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Dropoff</p>;
    },
    renderCell: (params) => (
      <Tooltip
        title={`${params?.row?.dropOffCode} - ${params?.row?.dropOffLocation}`}
      >
        <span className="table-cell-trucate">
          {params?.row?.dropOffCode} - {params?.row?.dropOffLocation}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'referenceID',
    width: 200,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader">
          Supporting Document ID
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={params?.row?.referenceID}>
        <span className="table-cell-trucate">{params?.row?.referenceID}</span>
      </Tooltip>
    )
  },
  {
    field: 'product',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">SKU Code</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.product}`}>
        <span className="table-cell-trucate">{params?.row?.product}</span>
      </Tooltip>
    )
  },
  {
    field: 'uom',
    width: 80,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">UoM</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.uom}`}>
        <span className="table-cell-trucate">{params?.row?.uom}</span>
      </Tooltip>
    )
  },
  {
    field: 'quantity',
    width: 100,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Quantity</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.quantity}`}>
        <span className="table-cell-trucate">{params?.row?.quantity}</span>
      </Tooltip>
    )
  },
  {
    field: 'logisticBy',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Logistic By</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.logisticBy}`}>
        <span className="table-cell-trucate">{params?.row?.logisticBy}</span>
      </Tooltip>
    )
  },
  {
    field: 'vehicleType',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Vehicle Type</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.vehicleType}`}>
        <span className="table-cell-trucate">{params?.row?.vehicleType}</span>
      </Tooltip>
    )
  },
  {
    field: 'weight',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Weight</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.weight}`}>
        <span className="table-cell-trucate">{params?.row?.weight}</span>
      </Tooltip>
    )
  },
  {
    field: 'pallet',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader">
          Palletized / Non-Palletized
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.pallet}`}>
        <span className="table-cell-trucate">{params?.row?.pallet}</span>
      </Tooltip>
    )
  },
  {
    field: 'additionalInfo',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader">Aditional Information</p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.additionalInfo}`}>
        <span className="table-cell-trucate">
          {params?.row?.additionalInfo}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'recordLevel',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Row Level</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.recordLevel}`}>
        <span className="table-cell-trucate">{params?.row?.recordLevel}</span>
      </Tooltip>
    )
  },
  {
    field: 'orderLevel',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Order Level</p>;
    },
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.orderLevel}`}>
        <span className="table-cell-trucate">{params?.row?.orderLevel}</span>
      </Tooltip>
    )
  }
];

export default ordersVerificationColumns;

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CircularProgressWithLabel({ value }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        width: 68,
        height: 68
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        thickness={5}
        sx={{
          color: '#EDF1F6'
        }}
        size={68}
      />
      <CircularProgress
        variant="determinate"
        value={Math.round(value > 100 ? 100 : value)}
        thickness={5}
        className="absolute text-primary"
        size={68}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          className="text-sm text-primary"
        >
          {`${Math.round(value > 100 ? 100 : value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

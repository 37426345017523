/*eslint-disable*/
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import TextField from '../../../core-components/atoms/TextField';
import { useFormik } from 'formik';
import Button from '../../../core-components/atoms/Button';
import { initialValues, validationSchema } from './validationSchema/AddBatch';
import ProductSubTable from './ProductSubTable';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import moment from 'moment';
import { decimalDisplay } from '../../../utils/common';
import { NumericFormat } from 'react-number-format';

const AddBatch = ({
  addBatch,
  parentIndex,
  childIndex,
  inventoryBatches,
  setOpen,
  editBatch,
  product,
  batch,
  edit = false,
  remainingQty,
  pickingTask,
  pickedProducts,
  row,
  uom
}) => {
  const [batches, setBatches] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState('');
  const [closeModal, setCloseModal] = useState(false);

  const totalAvailable =
    inventoryBatches?.reduce((acc, curr) => acc + curr.availableQuantity, 0) /
    (uom?.conversionFactor || 1);

  const formik = useFormik({
    initialValues: edit
      ? {
          ...batch,
          actualDispatchedQuantity:
            batch.actualDispatchedQuantity / (uom?.conversionFactor || 1)
        }
      : initialValues,
    validationSchema,
    enableReinitialize: false,
    onSubmit: async (values, actions) => {
      if (product.batchNumber && !values.batchNumber) {
        setError('Batch Number is mandatory');
        return;
      }

      setError('');

      if (product.batchEnabled) {
        const batchExist = batches.findIndex((b) => {
          if (values.promoName) {
            return (
              b.batchNumber == values.batchNumber &&
              moment(b.expiryDate).isSame(moment(values.expiryDate)) &&
              b.promoName == values.promoName
            );
          } else
            return (
              b.batchNumber == values.batchNumber &&
              moment(b.expiryDate).isSame(moment(values.expiryDate)) &&
              !b.promoName
            );
        });
        if (batchExist >= 0) {
          let curr = [...batches];
          curr[batchExist].actualDispatchedQuantity +=
            values.actualDispatchedQuantity;
          curr[batchExist].promoQuantity += values.actualDispatchedQuantity;
          setBatches([...curr]);
        } else
          setBatches([
            ...batches,
            { ...values, promoQuantity: values.actualDispatchedQuantity }
          ]);
      } else {
        if (batches.length > 0) {
          const curr = [...batches];
          curr[0].actualDispatchedQuantity += values.actualDispatchedQuantity;
          setBatches(curr);
        } else {
          setBatches([...batches, { ...values }]);
        }
      }

      if (!closeModal || edit) setShowTable(true);
      if (edit) {
        await editBatch(parentIndex, childIndex, values);
        setOpen(false);
      } else {
        await addBatch(parentIndex, values);
        let currentAvailable =
          formik.values.availableQuantity - values.actualDispatchedQuantity;
        if (closeModal) setOpen(false);
        else await actions.resetForm();
        await formik.setFieldValue('availableQuantity', currentAvailable);
      }
      setShowTable(true);
    }
  });

  const calculateTotalAvailable = () => {
    let total = totalAvailable || 0;
    if (
      pickingTask &&
      pickedProducts?.[parentIndex]?.GDNGroupBatches?.length > 0
    ) {
      return row?.GDNGroupBatches?.filter((b) => b.InventoryDetail).reduce(
        (acc, curr) =>
          acc + (curr.actualQuantity - curr.actualDispatchedQuantity),
        0
      );
    } else
      return (
        total -
        (row?.GDNGroupBatches?.filter((b) => !b.InventoryDetail).reduce(
          (acc, curr) => acc + curr.actualDispatchedQuantity,
          0
        ) || 0)
      );
  };

  useEffect(() => {
    if (!product.batchEnabled) {
      if (
        !pickingTask ||
        pickedProducts?.[parentIndex]?.GDNGroupBatches?.length == 0
      )
        formik.setFieldValue(
          'availableQuantity',
          inventoryBatches?.reduce(
            (acc, curr) => acc + curr.availableQuantity,
            0
          ) || 0
        );
    } else {
      if (
        pickingTask &&
        pickedProducts?.[parentIndex]?.GDNGroupBatches?.length == 0
      ) {
        formik.setFieldValue(
          'availableQuantity',
          row.GDNGroupBatches.reduce(
            (acc, curr) =>
              acc + (curr.actualQuantity - curr.actualDispatchedQuantity),
            0
          )
        );
      }
    }
  }, [row]);

  let maxValue = product.batchEnabled
    ? pickingTask &&
      pickedProducts?.[parentIndex].GDNGroupBatches?.length > 0 &&
      batch
      ? pickedProducts?.[parentIndex].GDNGroupBatches?.find(
          (b) =>
            b.batchNumber == batch.batchNumber &&
            (batch.expiryDate
              ? moment(b.expiryDate).isSame(batch.expiryDate)
              : true)
        )
      : null
    : pickingTask && pickedProducts?.[parentIndex].actualQuantity && batch
    ? pickedProducts?.[parentIndex]
    : null;

  let pickingTaskBatches =
    pickingTask && pickedProducts?.[parentIndex].GDNGroupBatches?.length > 0
      ? inventoryBatches?.filter((b) => {
          const findBatch = row.GDNGroupBatches.find(
            (gb) =>
              gb.batchNumber == b.batchNumber &&
              moment(gb.expiryDate).isSame(b.expiryDate)
          );
          return findBatch
            ? b.reservedQuantity - findBatch.actualDispatchedQuantity > 0
            : false;
        })
      : inventoryBatches?.filter((b) => {
          const findBatch = row?.GDNGroupBatches.find((gb) => {
            if (b.promoName) {
              return (
                gb.batchNumber == b.batchNumber &&
                moment(gb.expiryDate).isSame(b.expiryDate) &&
                b.promoName == gb.promoName
              );
            } else {
              return (
                gb.batchNumber == b.batchNumber &&
                moment(gb.expiryDate).isSame(b.expiryDate) &&
                !gb.promoName
              );
            }
          });
          return findBatch
            ? b.availableQuantity - findBatch.actualDispatchedQuantity > 0
            : true;
        });

  return (
    <div>
      <div className="flex gap-2">
        <span className="text-sm">
          Required QTY:{' '}
          {decimalDisplay(remainingQty / (uom?.conversionFactor || 1))}
        </span>
        <span className="text-normal ml-[2px] mr-[2px] font-medium">
          &#183;
        </span>
        <span className="text-sm">
          Available QTY : {decimalDisplay(calculateTotalAvailable()) || 0}
        </span>
      </div>
      {showTable && (
        <Box sx={{ marginBottom: 2, marginTop: 4 }}>
          <ProductSubTable batches={batches} />
        </Box>
      )}
      <form
        onSubmit={formik.handleSubmit}
        className="number-input-container mt-10"
      >
        <p className="text-error mb-4">{error}</p>
        {(remainingQty > 0 || edit) && (
          <Grid container spacing={2}>
            {product.batchEnabled && !edit && (
              <>
                <Grid item xs={10}>
                  <Autocomplete
                    label="Batch / Promo / Expiry Date"
                    variant="outlined"
                    size="small"
                    fullWidth
                    options={pickingTaskBatches || []}
                    renderOption={(props, option) => {
                      const { className, ...rest } = props;
                      return (
                        <li {...rest} className="auto-complete-option">
                          <p>
                            <span>{option.batchNumber}</span>
                            {option.promoName && (
                              <span className="text-primary ml-1">
                                &#183; {option.promoName}
                              </span>
                            )}
                          </p>
                          <p>
                            {option.manufacturingDate &&
                              option.manufacturingDate !== '-' && (
                                <span>
                                  {moment(option.manufacturingDate).format(
                                    'DD/MM/YYYY'
                                  )}{' '}
                                  -{' '}
                                </span>
                              )}
                            <span>
                              {moment(option.expiryDate).format('DD/MM/YYY')}
                            </span>
                          </p>
                        </li>
                      );
                    }}
                    value={
                      inventoryBatches.find((batch) => {
                        if (!formik.values.promoName) {
                          return (
                            batch.id == formik.values.id && !batch.promoName
                          );
                        } else if (formik.values.promoName) {
                          return (
                            batch.id == formik.values.id &&
                            batch.promoName == formik.values.promoName
                          );
                        }
                      }) || null
                    }
                    getOptionLabel={(batch) => {
                      return formik.values.expiryDate
                        ? `${
                            batch?.batchNumber ? batch.batchNumber + ' / ' : ''
                          }` +
                            `${batch.promoName ? batch.promoName + ' /' : ''}` +
                            ` ${moment(batch.expiryDate).format('DD/MM/YYYY')}`
                        : '';
                    }}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, val) => {
                      formik.setFieldValue('batchNumber', val.batchNumber);
                      const findBatch = row?.GDNGroupBatches.find((gb) => {
                        if (val.promoName) {
                          return (
                            gb.batchNumber == val.batchNumber &&
                            moment(gb.expiryDate).isSame(val.expiryDate) &&
                            gb.promoName == val.promoName
                          );
                        } else {
                          if (val.batchNumber)
                            return (
                              gb.batchNumber == val.batchNumber &&
                              moment(gb.expiryDate).isSame(val.expiryDate) &&
                              !gb.promoName
                            );
                          else
                            return (
                              moment(gb.expiryDate).isSame(val.expiryDate) &&
                              !gb.promoName
                            );
                        }
                      });
                      if (
                        pickingTask &&
                        pickedProducts?.[parentIndex].GDNGroupBatches?.length >
                          0
                      ) {
                        formik.setFieldValue(
                          'availableQuantity',
                          findBatch
                            ? val.reservedQuantity -
                                findBatch.actualDispatchedQuantity
                            : val.reservedQuantity
                        );
                      } else {
                        formik.setFieldValue(
                          'availableQuantity',
                          findBatch
                            ? val.availableQuantity -
                                findBatch.actualDispatchedQuantity
                            : val.availableQuantity
                        );
                      }
                      val.manufacturingDate &&
                        val.manufacturingDate !== '-' &&
                        formik.setFieldValue(
                          'manufacturingDate',
                          val.manufacturingDate
                        );
                      formik.setFieldValue('id', val.id);
                      formik.setFieldValue('expiryDate', val.expiryDate);
                      formik.setFieldValue('actualDispatchedQuantity', 0);
                      if (val.MRP) formik.setFieldValue('MRP', val.MRP);
                      if (val.promoName) {
                        formik.setFieldValue('promoName', val.promoName);
                      } else {
                        formik.setFieldValue('promoName', null);
                        formik.setFieldValue('promoQuantity', 0);
                      }
                    }}
                  />
                  <span className="text-error">
                    {formik.touched.batchNumber && formik.errors.batchNumber}
                  </span>
                </Grid>
              </>
            )}
            {product.batchEnabled || <Grid item xs={9}></Grid>}

            <Grid item xs={pickingTask ? 6 : 4}>
              <NumericFormat
                customInput={TextField}
                name="actualDispatchedQuantity"
                label="Dispatch Qty"
                variant="outlined"
                size="small"
                decimalScale={3}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.actualDispatchedQuantity}
                disabled={formik.values.availableQuantity === 0 && !edit}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  if (!pickingTask || !pickedProducts) {
                    if (
                      remainingQty / (uom?.conversionFactor || 1) -
                        floatValue >=
                        0 ||
                      floatValue == undefined
                    ) {
                      return true;
                    }
                    return false;
                  }
                  if (
                    (pickingTask &&
                      (maxValue
                        ? maxValue?.actualQuantity /
                            (uom?.conversionFactor || 1) >=
                          floatValue
                        : true)) ||
                    floatValue == undefined
                  ) {
                    return true;
                  }
                  return false;
                }}
                onValueChange={(e) => {
                  e.value = parseFloat(e.value);
                  if (edit) {
                    if (pickingTask) {
                      formik.setFieldValue('actualDispatchedQuantity', e.value);
                    } else if (parseFloat(e.value || 0) >= 0) {
                      if (
                        (remainingQty === 0 &&
                          parseFloat(e.value) <=
                            parseFloat(batch.actualDispatchedQuantity)) ||
                        e.value === '' ||
                        e.value === 0
                      ) {
                        formik.setFieldValue(
                          'actualDispatchedQuantity',
                          e.value
                        );
                      } else if (
                        remainingQty !== 0 &&
                        (e.value === 0 ||
                          e.value === '' ||
                          parseFloat(
                            remainingQty / (uom?.conversionFactor || 1)
                          ) +
                            parseFloat(batch.actualDispatchedQuantity || 0) -
                            parseFloat(e.value || 0) >=
                            0)
                      ) {
                        formik.setFieldValue(
                          'actualDispatchedQuantity',
                          e.value
                        );
                      } else {
                        formik.setFieldValue(
                          'actualDispatchedQuantity',
                          Math.min(
                            parseFloat(
                              formik.values.availableQuantity /
                                (uom?.conversionFactor || 1)
                            ),
                            parseFloat(
                              remainingQty / (uom?.conversionFactor || 1)
                            ) + parseFloat(batch.actualDispatchedQuantity)
                          )
                        );
                      }
                    }
                  } else {
                    if (e.value >= 0) {
                      if (
                        parseFloat(e.value || 0) <= parseFloat(remainingQty) &&
                        parseFloat(e.value || 0) <=
                          parseFloat(formik.values.availableQuantity)
                      ) {
                        formik.setFieldValue(
                          'actualDispatchedQuantity',
                          e.value
                        );
                      } else {
                        formik.setFieldValue(
                          'actualDispatchedQuantity',
                          Math.min(
                            parseFloat(formik.values.availableQuantity),
                            parseFloat(remainingQty)
                          )
                        );
                      }
                    }
                  }
                }}
                thousandSeparator=","
                decimalSeparator="."
              />
              <span className="text-error text-xs">
                {formik.touched.actualDispatchedQuantity &&
                  formik.errors.actualDispatchedQuantity}
              </span>
            </Grid>

            {(((formik.values.expiryDate || !product.batchEnabled) &&
              !edit &&
              pickingTask) ||
              !pickingTask) && (
              <Grid item xs={4}>
                <TextField
                  label="Available Qty"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    (formik.values.availableQuantity -
                      (formik.values.actualDispatchedQuantity || 0)) /
                      (uom?.conversionFactor || 1) || 0
                  }
                  type="number"
                  disabled
                />
              </Grid>
            )}

            {formik.values.batchNumber && formik.values.MRP && (
              <Grid item xs={4}>
                <TextField
                  label="MRP"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formik.values.MRP}
                  type="number"
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} className="flex justify-end">
              {edit || (
                <Button
                  size="large"
                  label="Save & Add New Batch"
                  type="submit"
                  variant="secondary"
                  className="w-48 mr-2"
                />
              )}
              {edit ? (
                <Button
                  size="large"
                  label="Save"
                  type="submit"
                  variant="primary"
                />
              ) : (
                <Button
                  size="large"
                  label="Save Changes"
                  onClick={() => {
                    if (!formik.values.batchNumber) {
                      setOpen(false);
                    }
                    setCloseModal(true);
                  }}
                  type="submit"
                  variant="primary"
                />
              )}
            </Grid>
          </Grid>
        )}{' '}
      </form>
    </div>
  );
};

export default AddBatch;

import * as React from 'react';
import { Paragraph } from '../../../../core-components/atoms/Text';
import CustomButton from '../../../../core-components/atoms/Button';
import Popup from '../../../../core-components/atoms/Popup';

export default function DeleteConfirmation({
  open,
  setOpen,
  handleClose,
  confirmDelete,
  message
}) {
  return (
    <Popup
      open={open}
      setOpen={setOpen}
      title={'Are you sure?'}
      content={
        <div>
          <Paragraph variant={'lg'}>{message}</Paragraph>
        </div>
      }
      onClose={handleClose}
      dialogContentClasses="w-[40vw]"
      actions={
        <div className="flex gap-2">
          <CustomButton
            label="Cancel"
            className="py-2 px-3 ml-2 h-8 border-solid border "
            overrideSize={true}
            labelClass="font-medium text-xs"
            onClick={handleClose}
            variant="tertiary"
          />
          <CustomButton
            className="py-2 px-3 ml-1 h-8 border-solid border border-persianRed text-persianRed hover:bg-persianRed"
            overrideSize={true}
            labelClass="font-medium text-xs text-persianRed hover:text-white"
            label={'Yes, Proceed'}
            variant={'transparent'}
            onClick={confirmDelete}
          />
        </div>
      }
    />
  );
}

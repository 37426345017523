import React, { useRef } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';

import { dividerDateFormat } from '../utils/common';
import CancelArrow from '../assets/icons/cancel.svg';
import DownloadIcon from '../assets/icons/download.svg';
import TruckIcon from '../assets/icons/truck.svg';
import TransferIcon from '../assets/icons/transfer.svg';
import UploadIcon from '../assets/icons/uploads.svg';

function DragItems(props) {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'card',
    item: { id: props?.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: () => {
      props?.setEnd(true);
    }
  }));

  const [spec, dropRef] = useDrop({
    accept: 'card',
    hover: (item, monitor) => {
      props?.setEnd(false);
      const dragIndex = item.id;
      const hoverIndex = props?.index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
      props?.moveListItem(dragIndex, hoverIndex);
      item.id = hoverIndex;
    }
  });

  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));
  const opacity = isDragging ? 0 : 1;
  let source = '';
  switch (props?.data?.moveType) {
    case 'LOGISTIC':
      source = TruckIcon;
      break;
    case 'INWARD':
      source = DownloadIcon;
      break;
    case 'OUTBOUND':
      source = UploadIcon;
      break;
    case 'TRANSFER':
      source = TransferIcon;
      break;
    default:
      source = TruckIcon;
      break;
  }
  return (
    <>
      <TableRow
        className="cursor-move"
        key={props?.index}
        ref={dragDropRef}
        style={{ opacity }}
      >
        <TableCell key={spec}>
          {props?.data?.moveType === 'OUTBOUND'
            ? props?.data?.warehousePickUp?.name
            : props?.data?.pickupNode?.name}
        </TableCell>
        <TableCell>
          <img src={source} />
          {props?.data?.id}
        </TableCell>
        <TableCell>{dividerDateFormat(props?.data?.createdAt)}</TableCell>
        <TableCell>
          {props?.data?.moveType == 'INWARD'
            ? props?.data?.warehouseDropOff?.name
            : props?.data?.dropOffNode?.name}
        </TableCell>
        <TableCell>{props?.data?.weight}</TableCell>
        <TableCell>
          <img
            src={CancelArrow}
            alt="Cancel Arrow"
            id="orders"
            className="cursor-pointer"
            onClick={() => {
              props?.settingOrders(props?.data);
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
}
export default DragItems;

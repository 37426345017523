/*eslint-disable*/
import React, { useContext, useState } from 'react';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import moment from 'moment';
import { SharedContext } from '../../../utils/common';
import clsx from 'clsx';
import OptionsMenu from './components/Options';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import tlds from 'tlds';

const Comment = ({
  comment,
  editComment,
  deleteComment,
  customClassText,
  selectedSearch
}) => {
  const { currentUser } = useContext(SharedContext);
  const [selectedComment, setSelectedComment] = useState();
  const [, setOptionsClick] = useState(false);

  const renderContentWithMentions = () => {
    const lines = comment?.content?.split('\n') || [];
    const emojiRegex =
      /\p{Emoji}|\p{Emoji_Presentation}|\p{Emoji_Modifier_Base}/gu;
    const currencyRegex =
      /^(?:[A-Z]{2,3}|[$€¥£₹₽₱₩₪₨៛₮₴₣₡₣₢₦¤]|R$|R\$)?\s?\d+(?:[.,]\d+)?$/i;
    const urlRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)/i;

    return lines.map((line, lineIndex) => {
      const words = line.split(' ');
      return (
        <React.Fragment key={lineIndex}>
          {words.map((word, wordIndex) => {
            if (word.startsWith('@')) {
              const mentionedUser = comment?.MentionedUsers?.find(
                (val) => val?.User?.username === word.replace(/@/g, '')
              );
              if (mentionedUser) {
                return (
                  <span
                    key={wordIndex}
                    style={{
                      color: '#0C6BD7',
                      fontWeight: '500',
                      fontSize: '12px'
                    }}
                  >
                    {word}{' '}
                  </span>
                );
              }
            } else if (currencyRegex.test(word)) {
              return (
                <span
                  key={wordIndex}
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {word}{' '}
                </span>
              );
            } else if (emojiRegex.test(word)) {
              return (
                <span
                  key={wordIndex}
                  style={{
                    fontSize: '12px'
                  }}
                >
                  {word}{' '}
                </span>
              );
            } else if (urlRegex.test(word)) {
              const domain = extractDomain(word);
              if (isValidDomain(domain)) {
                // Prepend "https://" to the URL if no protocol is provided
                const url = word.startsWith('http') ? word : `https://${word}`;
                return (
                  <a
                    key={wordIndex}
                    href={url}
                    style={{
                      color: 'blue',
                      borderBottom: '1px solid blue'
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {word}{' '}
                  </a>
                );
              }
            }
            return <React.Fragment key={wordIndex}>{word} </React.Fragment>;
          })}
          <br /> {/* Add a line break after each line */}
        </React.Fragment>
      );
    });
  };

  function extractDomain(url) {
    // Regular expression to extract the domain part from a URL
    const domainRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)/i;
    const match = url.match(domainRegex);
    return match ? match[1] : null;
  }

  function isValidDomain(domain) {
    const parts = domain.split('.');
    const tld = parts[parts.length - 1]; // Extract the TLD

    // Check if the TLD is in the list of valid TLDs
    return tlds.includes(tld);
  }

  const commentOptions = [
    {
      label: (
        <div className="flex items-center">
          <EditIcon color={'inherit'} fontSize="small" />
          <Title variant={'xs'} className={'ml-3'}>
            Edit Comment
          </Title>
        </div>
      ),
      onClick: () => editComment(selectedComment)
    },
    {
      label: (
        <div className="flex items-center">
          <DeleteIcon color={'error'} fontSize="small" />
          <Title variant={'xs'} className={'text-persianRed ml-3'}>
            Delete Comment
          </Title>
        </div>
      ),
      onClick: () => deleteComment(selectedComment)
    }
  ];

  function getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
  }

  function bytesToSize(bytes) {
    const mb = bytes / (1024 * 1024);
    const gb = bytes / (1024 * 1024 * 1024);

    if (gb >= 1) {
      return gb.toFixed(2) + ' GB';
    } else {
      return mb.toFixed(2) + ' MB';
    }
  }

  function openFileInNewTab(url) {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }

  return (
    <div className={'flex gap-4 group'}>
      <div
        className={clsx(
          'grid rounded-full h-8 w-8 place-items-center',
          currentUser?.id === comment?.createdBy?.id
            ? 'bg-[#0B1940]'
            : 'bg-[#E1E5F0]'
        )}
      >
        <Paragraph
          variant={'sm'}
          className={clsx(
            currentUser?.id === comment?.createdBy?.id
              ? 'text-white uppercase'
              : 'text-[#0B1940] uppercase'
          )}
        >
          {(comment?.createdBy?.firstName + ' ' + comment?.createdBy?.lastName)
            ?.split(' ')
            ?.filter(Boolean) // Remove any empty strings from the split result
            ?.map((word, index, array) =>
              index === 0 || index === array.length - 1 ? word.charAt(0) : ''
            )
            .join('')}
        </Paragraph>
      </div>
      <div>
        <div className={'flex justify-between items-center overflow-hidden'}>
          <div className={'flex gap-2 items-center'}>
            <Title variant={'sm'}>
              {comment?.createdBy?.firstName +
                ' ' +
                comment?.createdBy?.lastName}
            </Title>
            <Paragraph variant={'xs'} className={'text-[#696F80]'}>
              {moment(comment?.updatedAt)?.fromNow()}
            </Paragraph>
          </div>
          {currentUser?.id === comment?.createdBy?.id &&
            comment?.Expenses?.length <= 0 &&
            comment?.Files?.length <= 0 && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="group-hover:visible invisible"
              >
                <OptionsMenu
                  key={comment?.id}
                  options={commentOptions}
                  closeCallback={() => setOptionsClick(false)}
                  enterCallBack={() => {
                    setSelectedComment(comment);
                    setOptionsClick(true);
                  }}
                />
              </div>
            )}
        </div>
        <Paragraph
          variant={'sm'}
          className={clsx(
            'mt-1.5 w-[300px]',
            customClassText,
            selectedSearch &&
              selectedSearch === comment?.content &&
              'bg-[#fff0c3]'
          )}
        >
          {renderContentWithMentions()}
        </Paragraph>
        <div>
          {comment?.Files?.map((val, index) => {
            const fileType = getFileExtension(val?.originalName);
            return (
              <div key={index}>
                {fileType === 'png' ||
                fileType === 'jpg' ||
                fileType === 'jpeg' ? (
                  <div>
                    <Paragraph variant={'sm'} className={'truncate w-56'}>
                      {val?.originalName}
                    </Paragraph>
                    <div
                      className={
                        'border border-dark300 my-2 rounded w-[246px] overflow-hidden'
                      }
                    >
                      <img
                        src={val?.location}
                        className={'w-full h-auto'}
                        onClick={() => {
                          openFileInNewTab(val?.location);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      'flex border border-dark300 rounded h-[46px] my-2 p-2  w-[246px]'
                    }
                    onClick={() => {
                      openFileInNewTab(val?.location);
                    }}
                  >
                    <div
                      className={clsx(
                        'flex items-center justify-center rounded h-[30px] w-[30px]',
                        fileType === 'pdf'
                          ? 'bg-[#CE375C]'
                          : fileType === 'csv' ||
                            fileType === 'xlsx' ||
                            fileType === 'xls'
                          ? 'bg-[#4DA954]'
                          : fileType === 'doc' ||
                            fileType === 'docx' ||
                            fileType === 'txt'
                          ? 'bg-[#5187ED]'
                          : 'bg-black'
                      )}
                    >
                      <Label variant={'xs'} className={'text-white'}>
                        {fileType}
                      </Label>
                    </div>
                    <div className={'ml-2 overflow-hidden w-48'}>
                      <Paragraph variant={'sm'} className={'truncate'}>
                        {val?.originalName}
                      </Paragraph>
                      <Label variant={'xs'} className={'truncate text-dark500'}>
                        {bytesToSize(val?.size)}
                      </Label>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div>
          {comment?.Expenses?.map((item) => {
            return item.Files?.map((val, index) => {
              const fileType = getFileExtension(val?.originalName);
              return (
                <div key={index}>
                  {fileType === 'png' ||
                  fileType === 'jpg' ||
                  fileType === 'jpeg' ? (
                    <div>
                      <Paragraph variant={'sm'} className={'truncate w-56'}>
                        {val?.originalName}
                      </Paragraph>
                      <div
                        className={
                          'border border-dark300 my-2 rounded w-[246px] overflow-hidden'
                        }
                      >
                        <img
                          src={val?.location}
                          className={'w-full h-auto'}
                          onClick={() => {
                            openFileInNewTab(val?.location);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        'flex border border-dark300 rounded h-[46px] my-2 p-2  w-[246px]'
                      }
                      onClick={() => {
                        openFileInNewTab(val?.location);
                      }}
                    >
                      <div
                        className={clsx(
                          'flex items-center justify-center rounded h-[30px] w-[30px]',
                          fileType === 'pdf'
                            ? 'bg-[#CE375C]'
                            : fileType === 'csv' ||
                              fileType === 'xlsx' ||
                              fileType === 'xls'
                            ? 'bg-[#4DA954]'
                            : fileType === 'doc' ||
                              fileType === 'docx' ||
                              fileType === 'txt'
                            ? 'bg-[#5187ED]'
                            : 'bg-black'
                        )}
                      >
                        <Label variant={'xs'} className={'text-white'}>
                          {fileType}
                        </Label>
                      </div>
                      <div className={'ml-2 overflow-hidden w-48'}>
                        <Paragraph variant={'sm'} className={'truncate'}>
                          {val?.originalName}
                        </Paragraph>
                        <Label
                          variant={'xs'}
                          className={'truncate text-dark500'}
                        >
                          {bytesToSize(val?.size)}
                        </Label>
                      </div>
                    </div>
                  )}
                </div>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default Comment;

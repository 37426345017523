import {
  InputAdornment,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { toaster } from '../utils/toaster';
import { CustomNumberInput } from '../views/invoiceandbilling/settings/Taxes';

const InvoiceDropdown = ({
  fieldText,
  defaultText,
  options,
  type,
  onSubmit,
  onSelect,
  addFieldText,
  id,
  addText,
  onDelete,
  fetchOptions
}) => {
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setAdd(false);
  };

  const useStyles = makeStyles(() => ({
    formContainer: {
      boxSizing: 'border-box',
      padding: '21px 16px 21px 16px'
    },
    backIcon: {
      cursor: 'pointer',
      display: 'block',
      '& .MuiSvgIcon-root': {
        width: '10px !important',
        height: '10px !important'
      }
    },
    actionBtns: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
      marginRight: '5px',
      gap: '5px'
    },
    addBtn: {
      background: '#0C71D7 !important',
      textTransform: 'none',
      padding: '4px 24px 4px 24px'
    },
    formControl: {
      marginTop: '1.5rem'
    },
    backIconContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    addNewBtn: {
      color: '#0C71D7',
      padding: '4px 16px 4px 16px',
      marginTop: '1rem',
      cursor: 'pointer',
      fontSize: '1rem',
      '&:hover': {
        background: '#F1F8FF'
      }
    },
    option: {
      fontSize: '1rem',
      padding: '4px 16px 4px 16px',
      '&:hover': {
        background: '#F1F8FF',
        cursor: 'pointer'
      },
      display: 'flex',
      justifyContent: 'space-between',
      '& span': {
        color: 'rgba(0, 0, 0, 0.87)'
      }
    },
    paper: {
      width: '200px',
      boxShadow: '0px 4px 13px 0px #0B3E722E'
    }
  }));

  const classes = useStyles();

  const formik = useFormik({
    initialValues: { name: '', tax: null },
    onSubmit: async (values) => {
      if (type !== 'tax') {
        if (!values.name) {
          toaster('warning', 'Name is required');
          return;
        }
      } else {
        if (!values.name) {
          toaster('warning', 'Name is required');
        }
        if (!values.tax) {
          toaster('warning', 'Rate is required');
        }

        if (!values.name || !values.tax) {
          return;
        }
      }

      if (!values.tax) {
        onSubmit({ name: values.name });
      } else {
        onSubmit(values);
      }
      setAdd(false);
      setOpen(true);
    }
  });

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} className={classes.paper} />;
  };

  return (
    <>
      <div id={id} className="invoice-dropdown">
        <Autocomplete
          options={options}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          getOptionLabel={(option) => option?.name || ''}
          value={fieldText}
          renderInput={(params) => {
            return (
              <div
                ref={params.InputProps.ref}
                className={`invoice-autocomplete-input`}
              >
                <input
                  {...params.inputProps}
                  style={{ padding: '0.5rem' }}
                  onChange={(e) => {
                    params.inputProps.onChange(e);
                    fetchOptions(e.target.value);
                  }}
                  placeholder={defaultText}
                  type="text"
                  id={`${open || add ? 'border-visible' : null}`}
                />
                <div
                  className={`invoice-end-adornment ${
                    open || add ? 'visible' : null
                  }`}
                >
                  {!open ? <KeyboardArrowDownIcon /> : <ExpandLessIcon />}
                </div>
              </div>
            );
          }}
          onChange={(event, newValue) => {
            onSelect(newValue);
          }}
          sx={{
            display: 'inline-block',
            '& input': {
              bgcolor: 'background.paper',
              width: '100%'
            }
          }}
          renderOption={(props, option) => {
            return option.name !== 'add' ? (
              <li {...props} className={classes.option} key={option.id}>
                <Typography>{option.name}</Typography>
                {option.isDeleteable && (
                  <DeleteIcon onClick={() => onDelete(option.id)} />
                )}
              </li>
            ) : (
              <li
                {...props}
                onClick={() => {
                  setAdd(true);
                }}
                className={classes.addNewBtn}
                key={option.id}
              >
                <Typography>{addFieldText}</Typography>
              </li>
            );
          }}
          PaperComponent={CustomPaper}
        />
        <Menu
          anchorEl={add ? document.getElementById(id) : null}
          open={add}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 270,
              padding: '0.5rem 0',
              boxShadow: '0px 4px 13px 0px #0B3E722E'
            }
          }}
        >
          <div className={classes.formContainer}>
            <div className={classes.backIconContainer}>
              <ArrowBackIosIcon
                onClick={() => {
                  setAdd(false);
                  setOpen(true);
                }}
                className={classes.backIcon}
                fontSize="small"
              />
              <Typography>{addText}</Typography>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className={classes.formControl}>
                <TextField
                  variant="outlined"
                  placeholder="name"
                  value={formik.name}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  size="small"
                />
              </div>

              {type === 'tax' && (
                <div className={classes.formControl}>
                  <CustomNumberInput
                    inputMode="numeric"
                    variant="outlined"
                    placeholder="tax"
                    type="number"
                    size="small"
                    value={formik.values.tax}
                    onChange={(event) => {
                      if (
                        Number(event.target.value) < 0 ||
                        Number(event.target.value) > 100
                      ) {
                        event.preventDefault();
                      } else {
                        formik.setFieldValue('tax', event.target.value);
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                        max: '100',
                        maxLength: 2
                      }
                    }}
                    inputProps={{
                      min: 0,
                      max: 100
                    }}
                  />
                </div>
              )}
              <div className={classes.actionBtns}>
                <Button
                  onClick={() => {
                    setAdd(false);
                    setOpen(true);
                  }}
                  style={{ textTransform: 'none' }}
                  variant="text"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.addBtn}
                  style={{ textTransform: 'none' }}
                >
                  Add
                </Button>
              </div>
            </form>
          </div>
        </Menu>
      </div>
    </>
  );
};

export default InvoiceDropdown;

import React from 'react';
import PasswordResetForm from '../auth/ForceUpdatePassword';

function ProfileSettingsView() {
  return (
    <div>
      <PasswordResetForm />
    </div>
  );
}

export default ProfileSettingsView;

import clsx from 'clsx';
import Button from '../atoms/Button';

const PillButton = ({
  children,
  className,
  variant = 'primary',
  size,
  onClick,
  label
}) => {
  return (
    <Button
      className={clsx('rounded-l-full rounded-r-full', className)}
      variant={variant}
      size={size}
      onClick={onClick}
      label={label}
    >
      {children}
    </Button>
  );
};

export default PillButton;

/*eslint-disable*/
import { FormControl } from '@material-ui/core';
import { React, useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import TextField from '../../../../core-components/atoms/TextField';
import vendorTypes from '../../../../utils/enums/vendorTypes';
import FormikTextField from '../../../../core-components/molecules/FormikTextField';
import { SharedContext } from '../../../../utils/common';

const CarrierForm = ({ formik, edit, shipmentOnlyScreen }) => {
  const { organization } = useContext(SharedContext);
  const [types, setTypes] = useState({});
  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11.6px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  useEffect(() => {
    setTypes(formik.values.type);
  }, [formik.values.type]);

  if (edit && !formik.values.type) {
    return <></>;
  }

  let filteredVendorTypes = shipmentOnlyScreen
    ? vendorTypes?.filter(
        (v) => v.label !== 'Warehousing' && v.label !== 'Technology'
      )
    : vendorTypes;

  return (
    <div className="px-8 pt-4 pb-5">
      <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
        <div className="flex items-center">
          <p className="font-medium text-primaryBlue text-sm">
            Name <span className="text-error ml-1">*</span>
          </p>
        </div>
        <div className="flex w-[479px] justify-between">
          <div className="w-full ">
            <FormControl fullWidth={true} variant="outlined">
              <FormikTextField
                name="name"
                placeholder="Enter Name"
                size="small"
                sxProps={sxProps}
                formik={formik}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
        <div className="flex items-center">
          <p className="font-medium text-primaryBlue text-sm">Contact Person</p>
        </div>
        <div className="flex w-[479px] justify-between">
          <div className="w-full ">
            <FormControl fullWidth={true} variant="outlined">
              <FormikTextField
                name="contactName"
                placeholder="Enter Contact Name"
                size="small"
                sxProps={sxProps}
                formik={formik}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
        <div className="flex items-center">
          <p className="font-medium text-primaryBlue text-sm">Email</p>
        </div>
        <div className="flex w-[479px] justify-between">
          <div className="w-full ">
            <FormControl fullWidth={true} variant="outlined">
              <FormikTextField
                name="email"
                placeholder="Enter Contact Email"
                size="small"
                sxProps={sxProps}
                formik={formik}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
        <div className="flex items-center">
          <p className="font-medium text-primaryBlue text-sm">Carrier Tax ID</p>
        </div>
        <div className="flex w-[479px] justify-between">
          <div className="w-full ">
            <FormControl fullWidth={true} variant="outlined">
              <TextField
                name="ntn"
                placeholder="Enter Carrier Tax ID"
                value={formik.values?.ntn || ''}
                size="small"
                sxProps={sxProps}
                onChange={(e) => formik.setFieldValue('ntn', e.target.value)}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 py-4">
        <div className="flex items-center">
          <p className="font-medium text-primaryBlue text-sm">
            Type
            <span className="text-error ml-1">*</span>
          </p>
        </div>
        <div className="flex w-[479px] justify-between">
          <div className="w-full ">
            <FormGroup row id="vendorType" name="vendorType">
              {filteredVendorTypes?.map((type, index) => {
                return (
                  <div key={index} className="display-block mr-10">
                    <FormControlLabel
                      className="mr-10"
                      control={
                        <Checkbox
                          onChange={(e) =>
                            formik.setFieldValue('type', {
                              ...formik.values.type,
                              [type.label]: e.target.checked
                            })
                          }
                          checked={formik.values?.type?.[type?.label]}
                        />
                      }
                      label={type?.label}
                    />
                  </div>
                );
              })}
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between border-t-0 border-r-0 border-l-0 py-4">
        <div className="flex items-center">
          <p className="font-medium text-primaryBlue text-sm">Notes</p>
        </div>
        <div className="flex w-[479px] justify-between">
          <div className="w-full ">
            <FormControl fullWidth={true} variant="outlined" multiline rows={4}>
              <TextField
                name="notes"
                placeholder="Enter Notes"
                value={formik.values?.notes || ''}
                size="small"
                sxProps={sxProps}
                onChange={(e) => formik.setFieldValue('notes', e.target.value)}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierForm;

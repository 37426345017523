/* eslint-disable no-unused-vars */
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import ProductsCsvReader from '../../../components/ProductsCsvReader';
import { SharedContext, isEmptyObject } from '../../../utils/common';
import CheckIcon from '@material-ui/icons/Check';
import { useNavigate } from 'react-router';
import fileDownload from 'js-file-download';
import moment from 'moment';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { useParams } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    padding: 20
  },
  headerBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box'
  },
  topHeader: {
    boxSizing: 'border-box',
    paddingRight: 5
  },
  heading: {
    fontWeight: 'bolder',
    boxSizing: 'border-box',
    padding: 20
  },
  subHeading: {
    fontWeight: 'normal',
    boxSizing: 'border-box',
    padding: 20,
    fontSize: 18
  },
  systemAlert: {
    marginBottom: 10
  },
  uploadDetails: {
    boxSizing: 'border-box',
    padding: 20,
    height: '10%'
  },
  downloadTempBtn: {
    marginRight: 5
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  subHeadingGuideline: {
    fontWeight: 'normal',
    boxSizing: 'border-box',
    padding: 20,
    fontSize: 18,
    paddingBottom: 0
  },
  guidelines: {
    boxSizing: 'border-box',
    padding: 20,
    '@media (max-width: 767px)': {
      padding: '0px'
    }
  },
  guideLine: {
    marginTop: 5
  },
  button: {
    '@media (max-width: 767px)': {
      marginBottom: 4
    }
  }
}));
function BulkUpload() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [fileUploaded, setfileUploaded] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorAlerts, setErrorAlerts] = useState([]);
  const [successAlerts, setSuccessAlerts] = useState([]);

  const { setAPILoader } = useContext(SharedContext);

  const { company_id } = useParams();

  const bulkUpload = async (data) => {
    setfileUploaded(true);
    setAPILoader(true);

    const sanitizationArray = dataSanitization(data.products);

    if (sanitizationArray.length > 0) {
      setErrorAlerts(sanitizationArray);
      return;
    }

    let temp = [];
    let tempTwo = []; // for same product in same order number.
    let errorsArray = [];
    let count = 2; // to keep index count of loop.
    for (let product of data.products) {
      if (temp.includes(product['Customer SKU Code'])) {
        setSelectedFile(null);
        setSuccessAlerts([]);
        setErrorAlerts(['Can not upload file having duplicate products.']);
        return;
      }
      temp.push(product['Customer SKU Code']);
      // error check fo r Same Brand having same Barcode
      if (
        tempTwo.find(
          (el) =>
            el['SKU Brand'] === product['SKU Brand'] &&
            el['SKU Barcode'] === product['SKU Barcode']
        )
      ) {
        setSelectedFile(null);
        setSuccessAlerts([]);
        errorsArray = [
          ...errorsArray,
          {
            row: count,
            message: `Row ${count} : Can not upload file having same SKU Barcode in same Brand.`
          }
        ];
      }

      errorsArray.length > 0
        ? setErrorAlerts(errorsArray)
        : tempTwo.push(product);

      count++;
    }

    for (let product of data.products) {
      product.name = product['Customer SKU Code'];
      product.batchEnabled = product['SKU requires Expiry Tracking'];
      product.brand = product['SKU Brand'];
      product.category = product['SKU Category'];
      product.description = product['SKU Name'];
      if (product['SKU Barcode']) product.barcode = product['SKU Barcode'];
      if (product['Shelf Life (In Months)'])
        product.shelfLife = product['Shelf Life (In Months)'];
      product.isActive = product.IsActive || 'TRUE';
      product.uom = product['UoM'];
      product.perPallet = product['UoM per Pallet']
        ? product['UoM per Pallet']
        : 0;
      product.length = product['Length'];
      product.breadth = product['Breadth'];
      product.height = product['Height'];
      product.weight = product['Weight per UoM in Kgs'];
      product.volume =
        parseFloat(product['Length'] || 0) *
        parseFloat(product['breadth'] || 0) *
        parseFloat(product['height'] || 0);

      delete product['Customer SKU Code'];
      delete product['SKU requires Expiry Tracking'];
      delete product['SKU Brand'];
      delete product['SKU Category'];
      delete product['SKU Name'];
      delete product['SKU Barcode'];
      delete product['Shelf Life (In Months)'];
      delete product.IsActive;
      delete product['UoM per Pallet'];
      delete product['UoM'];
      delete product['Volume in cm3'];
      delete product['Length'];
      delete product['Breadth'];
      delete product['Height'];
      delete product['Weight per UoM in Kgs'];

      if (!(Array.isArray(data.products) && data.products.length > 0)) {
        setSelectedFile(null);
        setSuccessAlerts([]);
        setErrorAlerts(['Can not upload file having zero products.']);
        return;
      }
    }

    if (errorsArray.length === 0) {
      try {
        await API.post(`companies/${company_id}/products/bulk`, data);
        setSelectedFile(null);
        setSuccessAlerts(['File Successfully Uploaded']);
        return;
      } catch (err) {
        setSelectedFile(null);
        setSuccessAlerts([]);
        if (Array.isArray(err.response.data)) {
          for (const error of err.response.data) {
            errorsArray = [...errorsArray, error];
          }
          setErrorAlerts([...errorsArray]);
        } else if (Array.isArray(err.response.data.error.errors)) {
          for (const errorArray of err.response.data.error.errors) {
            if (
              errorArray.message ==
                'products.unique_product_barcode must be unique' ||
              errorArray.message ==
                'Products.unique_product_barcode must be unique'
            ) {
              errorArray.message = 'SKU Barcode must be unique';
            } else if (
              errorArray.message ==
                'products.unique_product_brandId must be unique' ||
              errorArray.message ==
                'Products.unique_product_brandId must be unique'
            ) {
              errorArray.message = 'Product must be unique';
            }
            errorsArray = [...errorsArray, errorArray];
          }
          setErrorAlerts([...errorsArray]);
        } else {
          setErrorAlerts([
            ...errorsArray,
            isEmptyObject(err.response.data.errors)
              ? 'Failed to upload bulk products.'
              : err.response.data.errors
          ]);
        }
        onError(err);
      } finally {
        setAPILoader(false);
      }
    }
  };

  const dataSanitization = (data) => {
    let count = 2;
    let sanitizationArray = [];
    for (let product of data) {
      if (!product['Customer SKU Code']) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : Customer SKU Code cannot be empty.`
          }
        ];
      }

      if (!product['SKU Brand']) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : SKU Brand cannot be empty.`
          }
        ];
      }
      if (!product['SKU Category']) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : SKU Category cannot be empty.`
          }
        ];
      }
      if (!product['SKU Name']) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : SKU Name cannot be empty.`
          }
        ];
      }
      if (!product['SKU requires Expiry Tracking']) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : SKU requires Expiry Tracking cannot be empty.`
          }
        ];
      }
      if (!product['UoM']) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : UoM cannot be empty.`
          }
        ];
      }
      if (!product['Length'] || !parseFloat(product['Length'])) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : Length cannot be empty and its must greater than 0`
          }
        ];
      }
      if (!product['Breadth'] || !parseFloat(product['Breadth'])) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : Breadth cannot be empty and its must greater than 0.`
          }
        ];
      }
      if (!product['Height'] || !parseFloat(product['Height'])) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : Height cannot be empty and its must greater than 0.`
          }
        ];
      }
      if (!product['Weight per UoM in Kgs']) {
        sanitizationArray = [
          ...sanitizationArray,
          {
            row: count,
            message: `Row ${count} : Weight per UoM in Kgs cannot be empty.`
          }
        ];
      }

      count++;
    }
    return sanitizationArray;
  };

  const downloadTemplate = async () => {
    const products = await API.get(`products/bulk-template`, {
      responseType: 'blob'
    });
    fileDownload(products, `Products ${moment().format('DD-MM-yyyy')}.xlsx`);
  };
  return (
    <>
      <Grid container className={classes.root}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          className={classes.topHeader}
        >
          <Grid item xs={12} sm={10}>
            <Typography
              component="div"
              variant="h4"
              className={classes.heading}
            >
              Products Bulk Upload
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.backBtn}>
            <Tooltip title="Back To Company List">
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/administration/company-details/${company_id}`)
                }
              >
                Back
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          className={classes.headerBtns}
        >
          <Grid item xs={12} sm={5} md={3} className={classes.downloadTempBtn}>
            <Tooltip title="Download Bulk Product Template">
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                fullWidth
                onClick={downloadTemplate}
              >
                Download Template
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ProductsCsvReader
              bulkUpload={bulkUpload}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </Grid>
        </Grid>
        {fileUploaded ? (
          <>
            <Grid item xs={12} alignItems="center">
              <Typography component="div" className={classes.subHeading}>
                Bulk Upload Details
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.uploadDetails}>
              {errorAlerts?.map((alert, index) => {
                return (
                  <Alert
                    key={index}
                    severity="error"
                    className={classes.systemAlert}
                  >
                    {' '}
                    {alert.message ? alert.message : alert}{' '}
                  </Alert>
                );
              })}
              {successAlerts?.map((alert, index) => {
                return (
                  <Alert
                    key={index}
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    className={classes.systemAlert}
                  >
                    {' '}
                    {alert}{' '}
                  </Alert>
                );
              })}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} alignItems="center">
              <Typography
                component="div"
                className={classes.subHeadingGuideline}
              >
                Bulk Upload Guidelines
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              alignItems="center"
              className={classes.guidelines}
            >
              <Alert severity="info" className={classes.guideLine}>
                Maximum of 1000 products are allowed to be included for upload
                in a single file.
              </Alert>
              <Alert severity="info" className={classes.guideLine}>
                The Brand, Category & UoM values must exist in the system before
                being used in bulk upload.
              </Alert>
              <Alert severity="info" className={classes.guideLine}>
                The template contains sample value for product rows which must
                be replaced with actual values before upload.
              </Alert>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default BulkUpload;

import React from 'react';
import { FaPlus } from 'react-icons/fa';

const FloatingActionButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: 'skyblue' }}
      className="fixed bottom-4 right-4 text-white rounded-full p-4 shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-transform transform hover:scale-110 z-50 flex items-center justify-center"
    >
      <FaPlus className="text-white text-2xl" />
    </button>
  );
};

export default FloatingActionButton;

import React, { useContext, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Paragraph, Title } from '../../core-components/atoms/Text';
import Comment from './Comment';
import { SharedContext } from '../../utils/common';
import { ORGANIZATION_TYPES } from '../../constants';

const CommentList = ({ chatList = [], loader, setMessage }) => {
  const groupedChatList = {};
  const today = new Date();
  const scrollRef = useRef([]);
  const { organizationType } = useContext(SharedContext);
  const prompts =
    organizationType === ORGANIZATION_TYPES.MANUFACTURER
      ? [
          ('Can you provide the status of order #12345?',
          'How many sales orders are currently pending?',
          'What is the current inventory level of product XYZ?',
          'How many purchase orders were created this month?',
          'How many sales return orders have been processed this week?',
          'What are the top 5 products with the highest sales returns?',
          'What is the status of the latest purchase order from supplier GHI Industries?',
          'How many pending orders do we have with supplier JKL Corp?',
          "What is the status of customer XYZ's latest order?")
        ]
      : organizationType ===
          ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER && [
          'Can you track the status of shipment #7890?',
          'When is the expected delivery date for order #12345?',
          'What is the current capacity utilisation of our main warehouse?',
          'Do we have any items that are out of stock?',
          'Which product has the highest inventory available?',
          'Which user has passed the most stock adjustment entries?',
          "What is the status of customer XYZ's latest order?",
          'What is the current inventory level of Product XYZ?',
          'Can you provide the status of order #12345?'
        ];

  chatList.forEach((comment) => {
    const commentDate = new Date(comment.updatedAt);
    const diffInDays = Math.floor(
      (today - commentDate) / (1000 * 60 * 60 * 24)
    );
    let dateLabel;
    if (diffInDays === 0) {
      dateLabel = 'Today';
    } else {
      dateLabel = commentDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    }
    if (!groupedChatList[dateLabel]) {
      groupedChatList[dateLabel] = [];
    }
    groupedChatList[dateLabel].push(comment);
  });

  return (
    <div
      id={'scrollableDiv'}
      style={{
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
        flex: 1
      }}
      className={'select-none no-scrollbar py-6'}
    >
      <InfiniteScroll
        dataLength={chatList.length}
        inverse={true}
        hasMore={false}
        pullDownToRefresh={false}
        scrollThreshold={0.1}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column-reverse'
        }}
        scrollableTarget="scrollableDiv"
      >
        {chatList?.length > 0 ? (
          Object.entries(groupedChatList).map(([date, comments], index) => (
            <React.Fragment key={index}>
              {comments.map((comment, id) => (
                <div
                  key={id}
                  className={'pb-6 flex-1'}
                  ref={(ref) => (scrollRef.current[comment?.content] = ref)}
                >
                  <Comment
                    comment={comment}
                    loader={loader}
                    comments={comments}
                  />
                </div>
              ))}
              <div className={'flex flex-1 mb-8 justify-center items-center'}>
                <div className={'border-t w-100'} />
                <div>
                  <Paragraph
                    className={'w-[200px] text-center border rounded-full'}
                    variant={'sm'}
                  >
                    {date}
                  </Paragraph>
                </div>
                <div className={'border-t w-100'} />
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className={'flex flex-col'}>
            <Title variant={'xs'} className={'text-oasisPrimary600'}>
              You may also ask
            </Title>
            {prompts.map((value, index) => (
              <div
                key={index}
                className={
                  'flex border border-oasisPrimary500 bg-oasisPrimary100 my-1 w-100 p-2 rounded'
                }
                onClick={() => {
                  setMessage(value);
                }}
              >
                <Title variant={'xs'} className={'text-oasisPrimary600'}>
                  {value}
                </Title>
              </div>
            ))}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default CommentList;

/*eslint-disable*/
import { Typography } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DateRange from 'react-date-range/dist/components/DateRangePicker';
import calenderSvg from '../assets/icons/calenderIcon.svg';
import { dateFormatSlashDivider, dateWithYearFirst } from '../utils/common';
import { Clear } from '@material-ui/icons';

import '../assets/css/style.css';
import { useState } from 'react';

export default function DatePicker(props) {
  const [cancel, setCancel] = useState(false);
  return (
    <div className="datePickerContainer">
      <div
        className={
          props?.class && props?.class == 'customWidthForDate'
            ? 'customWidthForDate dateFilter'
            : 'dateFilter'
        }
      >
        <div
          className="display-flex w-100"
          onClick={() => {
            if (props.dateFilter) {
              props.setDateFilter(false);
            } else {
              props.setDateFilter(true);
              setCancel(false);
            }
          }}
        >
          <Typography className="dateFont">
            {props?.startDate && props?.endDate
              ? dateFormatSlashDivider(props?.startDate) +
                ' - ' +
                dateFormatSlashDivider(props?.endDate)
              : 'dd/mm/yyyy - dd/mm/yyyy'}
          </Typography>
          <img
            className="calenderIconDate"
            src={calenderSvg}
            alt="Calender Icon"
          />
        </div>
        <div>
          <Clear
            onClick={() => {
              props.setStartDate(null);
              props.setEndDate(null);
              setCancel(true);
            }}
          />
        </div>
      </div>

      {props.dateFilter && !cancel ? (
        <div
          className={
            props.smallContainer
              ? 'position-fixed z-index'
              : 'position-relative'
          }
        >
          <DateRange
            editableDateInputs={false}
            className={
              props.ledgerContainer
                ? 'customLedgerDatePicker'
                : props.bookingContainer
                ? 'customBookingDatePicker'
                : 'customDateFilter'
            }
            onChange={(item) => {
              props.setRange([item.selection]);
              props.setStartDate(dateWithYearFirst(item?.selection?.startDate));
              if (item?.selection?.startDate !== item?.selection?.endDate) {
                props.setEndDate(dateWithYearFirst(item?.selection?.endDate));
                setCancel(true);
              }
            }}
            moveRangeOnFirstSelection={false}
            ranges={props.range}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

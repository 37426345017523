import React, { useContext } from 'react';
import clsx from 'clsx';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter
} from '@mui/material';
import PrintStyles from './PrintStyles';
import {
  decimalDisplay,
  getCurrencySymbol,
  SharedContext
} from '../../../../utils/common';
import { checkPermission } from '../../../../utils/auth';

const ProductDetail = ({ order, orderSummary, watermark, expenseTotal }) => {
  const { organization, settings } = useContext(SharedContext);
  const classes = PrintStyles();
  const { currentUser } = useContext(SharedContext);

  const hasCostPricePermission = checkPermission(
    currentUser,
    'OPS_COST_PRICE_VISIBILITY'
  );
  const hasSellPricePermission = checkPermission(
    currentUser,
    'OPS_SELLING_PRICE_VISIBILITY'
  );
  const isPurchaseOrder = ['PURCHASE'].includes(order?.moveSubType);
  const isSalesOrder = ['SALES'].includes(order?.moveSubType);

  const topColumnsGeneric = [];

  if (
    ((isPurchaseOrder && hasCostPricePermission) ||
      (isSalesOrder && hasSellPricePermission)) &&
    !watermark
  ) {
    topColumnsGeneric.push(
      {
        id: 'skuCode',
        label: 'SKU Code & Name',
        minWidth: 'auto',
        align: 'left',
        width: '300px',
        className: classes.topColumn,
        style: classes.value,
        format: (order) =>
          `${order?.Product?.name || '-'} - ${
            order?.Product?.description || '-'
          }`
      },
      {
        id: 'uom',
        label: 'UoM',
        minWidth: 'auto',
        align: 'left',
        width: '130px',
        className: classes.topColumn,
        style: classes.value,
        format: (order) => order.Product.UOM?.name || '-'
      },
      {
        id: 'orderedQuantity',
        label: 'Ordered Qty',
        minWidth: 'auto',
        width: '130px',
        align: 'center',
        className: clsx(classes.topColumn, classes.centerText),
        style: classes.value,
        format: (order) => decimalDisplay(order?.originalOrderedQuantity) ?? '-'
      },
      {
        id: 'costPrice',
        label: 'Price',
        minWidth: 'auto',
        align: 'left',
        width: '130px',
        className: classes.topColumn,
        style: classes.value,
        format: (order) =>
          order?.moveSubType === 'SALES'
            ? `${getCurrencySymbol(
                organization?.defaultCurrency
              )} ${decimalDisplay(order?.sellingPrice)}` || '-'
            : `${getCurrencySymbol(
                organization?.defaultCurrency
              )} ${decimalDisplay(order?.costPrice)}` || '-'
      },
      {
        id: 'totalCost',
        label: `Total Amount (${getCurrencySymbol(
          organization?.defaultCurrency
        )})`,
        minWidth: 'auto',
        align: 'left',
        width: '200px',
        className: classes.topColumn,
        style: classes.value,
        format: (order) =>
          getCurrencySymbol(organization?.defaultCurrency) +
            order.moveSubType ===
          'SALES'
            ? `${getCurrencySymbol(
                organization?.defaultCurrency
              )} ${decimalDisplay(
                order?.sellingPrice * order?.originalOrderedQuantity
              )}` || '-'
            : `${getCurrencySymbol(
                organization?.defaultCurrency
              )} ${decimalDisplay(
                order.costPrice * order?.originalOrderedQuantity
              )}` || '-'
      }
    );
  } else {
    topColumnsGeneric.push(
      {
        id: 'skuCode',
        label: 'SKU Code & Name',
        minWidth: 'auto',
        align: 'left',
        width: '400px',
        className: classes.topColumnCh,
        style: classes.value,
        format: (order) =>
          `${order?.Product?.name || '-'} - ${
            order?.Product?.description || '-'
          }`
      },
      {
        id: 'uom',
        label: 'UoM',
        minWidth: 'auto',
        align: 'center',
        width: '240px',
        className: clsx(classes.topColumnCh, classes.centerText),
        style: classes.value,
        format: (order) => order.Product.UOM?.name || '-'
      },
      {
        id: 'orderedQuantity',
        label: 'Ordered Qty',
        minWidth: 'auto',
        width: '240px',
        align: 'center',
        className: clsx(classes.topColumnCh, classes.centerText),
        style: classes.value,
        format: (order) => decimalDisplay(order?.originalOrderedQuantity) ?? '-'
      }
    );
  }

  function sumOriginalOrderedQuantities(data) {
    return data?.reduce(
      (total, item) => total + item.originalOrderedQuantity,
      0
    );
  }

  function sumTax(data) {
    return Object.values(data).reduce(
      (total, val) =>
        total +
        (val?.[order?.moveSubType === 'SALES' ? 'totalRevenue' : 'totalCost'] ||
          0) -
        (val?.[order?.moveSubType === 'SALES' ? 'sellingPrice' : 'costPrice'] ||
          0) *
          (val?.total ?? 0),
      0
    );
  }

  function totalSale(orderSummary) {
    return Object.values(orderSummary).reduce(
      (total, val) =>
        total +
        (val?.[order?.moveSubType === 'SALES' ? 'sellingPrice' : 'costPrice'] ||
          0) *
          (val?.total ?? 0),
      0
    );
  }

  return (
    <Box className={classes.productDetailSectionContainer}>
      <Box className={classes.productDetailSectionHeading}>
        Product Detail(s)
      </Box>
      <Box displayPrint="block">
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ borderCollapse: 'collapse' }}
          >
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRow}>
                {topColumnsGeneric.map((column, idx) => {
                  return (
                    <TableCell
                      key={idx}
                      className={column.className}
                      align={column.center}
                      style={{
                        pageBreakInside: 'avoid',
                        minWidth: column.minWidth,
                        width: column.width
                      }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody style={{ borderBottom: 'none' }}>
              {order?.OrderProductGroups?.map((product, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow className={classes.tableRow} key={index}>
                      {topColumnsGeneric.map((column, idx) => {
                        const value = product[column.id];
                        return (
                          <TableCell
                            key={idx}
                            className={column.style}
                            align={column.align}
                            style={{
                              pageBreakInside: 'avoid',
                              borderRadius: 0
                            }}
                          >
                            {column.format
                              ? column.format(product)
                              : value || '-'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          {order?.OrderProductGroups?.length > 1 && (
            <TableFooter className={classes.tableHead}>
              <TableRow className={classes.tableMidContainer}>
                {!watermark ? (
                  <>
                    <TableCell
                      className={classes.topColumnWrapper}
                      style={{
                        minWidth: 'auto',
                        width: '32%'
                      }}
                    >
                      <div className={classes.totalRow}>
                        <span>Total Sku(s):</span>
                        <span className={classes.topColumnWrapperBold}>
                          {decimalDisplay(order?.OrderProductGroups?.length)}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.topColumnWrapper}
                      style={{ minWidth: 'auto', width: '2%' }}
                    >
                      |
                    </TableCell>
                    <TableCell
                      className={classes.topColumnWrapper}
                      style={{
                        minWidth: 'auto',
                        width: '32%'
                      }}
                    >
                      <div className={classes.totalRow}>
                        <span>Total Weight:</span>
                        <span className={classes.topColumnWrapperBold}>
                          {decimalDisplay(order?.calculatedWeight)}
                        </span>
                        <span className={classes.topColumnWrapperSimple}>
                          {`${settings?.weight?.symbol || 'Kg'}`}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.topColumnWrapper}
                      style={{ minWidth: 'auto', width: '2%' }}
                    >
                      |
                    </TableCell>
                    <TableCell
                      className={classes.topColumnWrapper}
                      style={{
                        minWidth: 'auto',
                        width: '32%'
                      }}
                    >
                      <div className={classes.totalRow}>
                        <span>Total Order Qty:</span>
                        <span className={classes.topColumnWrapperBold}>
                          {decimalDisplay(
                            sumOriginalOrderedQuantities(
                              order?.OrderProductGroups
                            )
                          )}
                        </span>
                      </div>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell
                      className={classes.topColumnWrapper}
                      align={'left'}
                      style={{
                        minWidth: 'auto',
                        width: '680px'
                      }}
                    />
                    <TableCell
                      colSpan={1}
                      className={classes.topColumnWrapper}
                      align={'right'}
                      style={{
                        minWidth: 'auto',
                        width: '200px'
                      }}
                    >
                      <div className={classes.totalRow}>
                        <span>{`|     Total Order Qty:`}</span>
                        <span className={classes.topColumnWrapperBold}>
                          {decimalDisplay(
                            sumOriginalOrderedQuantities(
                              order?.OrderProductGroups
                            )
                          )}{' '}
                          {`     |`}
                        </span>
                      </div>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableFooter>
          )}
        </TableContainer>
        {((isPurchaseOrder && hasCostPricePermission) ||
          (isSalesOrder && hasSellPricePermission)) &&
          !watermark && (
            <div className={classes.bottomColumn}>
              <div>
                <div className={classes.bottomColumnWrapper}>
                  <span className={classes.bottomColumnTopText}>
                    {'Order Amount'}
                  </span>
                  <span className={classes.bottomColumnText}>
                    {order.moveSubType === 'SALES'
                      ? `${
                          getCurrencySymbol(organization.defaultCurrency) +
                          ' ' +
                          decimalDisplay(totalSale(orderSummary))
                        }`
                      : `${
                          getCurrencySymbol(organization.defaultCurrency) +
                          ' ' +
                          decimalDisplay(totalSale(orderSummary))
                        }`}
                  </span>
                </div>
                <div className={classes.bottomColumnWrapper}>
                  <span className={classes.bottomColumnTopText}>Tax</span>
                  <span className={classes.bottomColumnText}>
                    {getCurrencySymbol(organization.defaultCurrency) +
                      ' ' +
                      decimalDisplay(sumTax(orderSummary))}
                  </span>
                </div>
                <div className={classes.bottomColumnWrapper}>
                  <span className={classes.bottomColumnTopText}>
                    Shipping Cost
                  </span>
                  <span className={classes.bottomColumnText}>
                    {getCurrencySymbol(organization.defaultCurrency) +
                      ' ' +
                      decimalDisplay(expenseTotal)}
                  </span>
                </div>
                <div className={classes.bottomColumnWrapper}>
                  <span className={classes.bottomColumnTopText}>
                    Total Amount
                  </span>
                  <span className={classes.bottomColumnText}>
                    {getCurrencySymbol(organization.defaultCurrency) +
                      ' ' +
                      decimalDisplay(
                        expenseTotal +
                          totalSale(orderSummary) +
                          sumTax(orderSummary)
                      )}
                  </span>
                </div>
              </div>
            </div>
          )}
      </Box>
    </Box>
  );
};

export default ProductDetail;

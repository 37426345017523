import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import AddDockView from './AddDockView';
import { Pagination } from '@material-ui/lab';
import { debounce } from 'lodash';
import { MdOutlineAdjust } from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';

import SearchBar from '../../../atomicComponents/SearchBar';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import { DEBOUNCE_CONST } from '../../../Config';
import darkEditIcon from '../../../assets/icons/darkEditIcon.svg';
import addIcon from '../../../assets/icons/addIcon.svg';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import DetailText from '../../../atomicComponents/DetailText';
import warehouseStyles from './warehouseMakeStyles';
import dockTypes from '../../../utils/enums/dockTypes';
import { useParams } from 'react-router';

const DockManagement = () => {
  const classes = warehouseStyles();
  const [docks, setDocks] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedDock, setSelectedDock] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const limit = 5;
  const { uid } = useParams();

  const columns = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      className: (value) => (value ? classes.active : ''),
      renderCell: (params) => (
        <MdOutlineAdjust
          className={params.row.isActive ? 'online' : 'offline'}
        />
      )
    },
    {
      field: 'dockName',
      headerName: 'Dock Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => <DetailTitle text="Dock Name" />,
      renderCell: (params) => <span>{params.row.name}</span>
    },
    {
      field: 'dockType',
      headerName: 'Dock Type',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => <DetailTitle text="Dock Type" />,
      renderCell: (params) => {
        const type = dockTypes?.find(
          (type) => type?.value === params?.row?.type
        )?.label;
        return <DetailText text={type} />;
      }
    },
    {
      field: 'action',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderHeader: () => <DetailTitle text="Action" />,
      renderCell: (params) => (
        <img
          className={classes.docksEditBtn}
          src={darkEditIcon}
          alt="edit"
          onClick={() => {
            setSelectedDock(params.row);
            setOpen(true);
          }}
        />
      )
    }
  ];

  const getWarehouseDocks = async (page, searchValue, limit) => {
    if (!uid) {
      return;
    }

    try {
      const cols = ['name', 'type'];
      const res = await API.get(`warehouses/${uid}/docks`, {
        params: {
          page: page,
          limit: limit,
          search: searchValue,
          columns: cols
        }
      });
      setDocks(res?.data);
      setPageCount(res?.pages);
    } catch (error) {
      onError(error);
    }
  };

  const getDelayedWarehouseDocks = useCallback(
    debounce(
      async (page, searchValue, limit) => {
        getWarehouseDocks(page, searchValue, limit);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedWarehouseDocks(page, searchValue, limit);
  }, [page, searchValue]);

  const handleClose = () => {
    setOpen(false);
    setSelectedDock(null);
  };

  return (
    <div className={classes.docksParentContainer}>
      {open ? (
        <AddDockView
          handleClose={handleClose}
          dock={selectedDock}
          warehouseId={uid}
          open={open}
          getWarehouseDocks={getWarehouseDocks}
        />
      ) : null}
      <Grid container spacing={1} className={classes.DockDataGridHead}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <SearchBar
            setSearchValue={(e) => {
              setPage(1);
              setSearchValue(e);
            }}
            placeholder="Search dock name"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <button className={classes.docksAddBtn} onClick={() => setOpen(true)}>
            <img src={addIcon} alt="add-icon" />
            <p>ADD</p>
          </button>
        </Grid>
        <Grid item xs={12} className="doctTable-responsive">
          <div className={classes.docksDataGridParent}>
            <DataGrid
              columns={columns}
              rows={docks}
              pageSize={limit}
              rowsPerPageOptions={[limit]}
            />
          </div>
        </Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            onChange={(e, page) => {
              setPage(page);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DockManagement;

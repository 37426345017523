import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import {
  FormHelperText,
  Popper,
  TextField,
  ThemeProvider
} from '@material-ui/core';
import { Title } from '../../../../core-components/atoms/Text';
import { OASIS_PRIMARY_500 } from '../../../../constants/colors';
import Switch from '../../../../core-components/atoms/Switch';
import { SharedContext } from '../../../../utils/common';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px #E8ECF1',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: '16px 16px',
    alignItems: ' flex-start',
    boxShadow:
      '0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.10)'
  },
  dockDialog: {
    // padding: '10px 0px 0px !important'
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  dockInputField: {
    width: '270px'
  },
  switchButton: {
    marginTop: 16
  },
  cancelBtn: {
    padding: '8px 12px',
    // gap: '6px',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#0B1940'
  },

  submitBtn: {
    padding: '8px 12px',
    background: '#0C6BD7',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:disabled': {
      background: 'rgba(12,107,215,0.5)'
    }
  }
}));

const AddAislesAndBaysView = ({
  anchorEl,
  route,
  aisleOrBays,
  handleClose,
  type,
  warehouseId,
  getWarehouseAislesOrBays
}) => {
  const classes = useStyles();
  const { setAPILoader } = useContext(SharedContext);

  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });
  const formik = useFormik({
    initialValues: {
      name: aisleOrBays?.name || '',
      isActive: aisleOrBays?.isActive
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(`${type} name is required`),
      isActive: Yup.bool().required(`${type} active status is required`)
    }),
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  const submitForm = async (values) => {
    try {
      setAPILoader(false);
      if (aisleOrBays && aisleOrBays.id) {
        await API.put(`warehouses/${warehouseId}/${route}/${aisleOrBays.id}`, {
          ...values
        });
      } else {
        await API.post(`warehouses/${warehouseId}/${route}`, {
          ...values
        });
      }
      toaster(
        'success',
        !(aisleOrBays && aisleOrBays.id)
          ? `${type} created successfully.`
          : `${type} updated successfully.`
      );

      getWarehouseAislesOrBays();
      formik.resetForm();
      handleClose();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_${type}s_warehouseId`,
        `${type} already exists it must be unique`
      );
    } finally {
      setAPILoader(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-end'}>
      <div className={classes.paper}>
        <div className="mb-24">
          <Title className={'text-base'}>
            {aisleOrBays?.id ? `Edit ${type}` : `Add A New ${type}`}
          </Title>
        </div>
        <div className={classes.dockDialog}>
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className={classes.dockInputField}
          >
            <ThemeProvider theme={theme}>
              <TextField
                id="name"
                label={`${type} Name`}
                value={formik.values.name || ''}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size={'small'}
                color={'primary'}
                fullWidth={true}
                error={formik.errors.name && formik.touched.name}
              />
            </ThemeProvider>
            {formik.errors.name && formik.touched.name && (
              <FormHelperText
                id="component-error-text"
                error={true}
                className={classes.dockInputField}
              >
                {formik.errors.name}
              </FormHelperText>
            )}

            <div className={'mt-4'}>
              <Switch
                checked={formik.values.isActive}
                onChange={() =>
                  formik.setFieldValue('isActive', !formik.values.isActive)
                }
                label="Active"
              />
            </div>

            <div className={classes.btnGroup}>
              <button
                className={classes.cancelBtn}
                type="button"
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}
              >
                Cancel
              </button>
              <button
                className={classes.submitBtn}
                type="submit"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {aisleOrBays?.id ? 'Update' : `Save`}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Popper>
  );
};

export default AddAislesAndBaysView;

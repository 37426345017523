import React, { useContext, useEffect } from 'react';
import { SharedContext } from '../../utils/common';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { CircularProgress } from '@mui/material';

function CustomerPortal() {
  const { setCurrentPageTitle, subdomain } = useContext(SharedContext);

  const redirectToStripeCustomerDashboard = async (subdomain) => {
    try {
      const response = await API.get(
        `organizations/${subdomain}/stripe/billing-session`
      );

      window.location.href = response.url;
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    setCurrentPageTitle(`Customer Portal`);
    redirectToStripeCustomerDashboard(subdomain);
  });

  return (
    <div className="flex justify-center items-center mt-10">
      <CircularProgress />
    </div>
  );
}

export default CustomerPortal;

import * as Yup from 'yup';
import { REQUIRED_MSG } from '../../Config';

export const initialValues = (values) => {
  return {
    cid: values?.cid || '',
    pid: values?.pid || '',
    batch: values?.batch || '',
    mfgd: values?.mfgd || null,
    expd: values?.expd || null,
    mrp: values?.mrp || 0,
    product: {},
    promo: values?.promo || ''
  };
};

export const validationSchema3PL = Yup.object().shape({
  cid: Yup.number().required(REQUIRED_MSG),
  pid: Yup.number().required(REQUIRED_MSG)
});

export const validationSchemaManufacturer = Yup.object().shape({
  pid: Yup.number().required(REQUIRED_MSG)
});

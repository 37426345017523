/*eslint-disable */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { debounce } from 'lodash';
import Button from '../../core-components/atoms/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import { dateFormatSlashDivider, SharedContext } from '../../utils/common';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { toaster } from '../../utils/toaster';
import useStyles from './pickingStyles';
import { isRequired } from '../../utils/validators';
import { DEBOUNCE_CONST } from '../../Config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PickingBatchesTable from './pickingBatchesTables';
import clsx from 'clsx';
import usePickingForm from '../../hooks/usePickingForm';
import Popup from '../../core-components/atoms/Popup';
import ConfirmationPreviewTable from './ConfirmationPreviewTable';
import { Popover } from '@mui/material';
import ActiveCheckbox from '../../atomicComponents/ActiveCheckbox';
import {
  dontAskForAutoPickingConfirmation,
  getDontAskForAutoPickingConfirmation
} from '../../utils/auth';
import { useNavigate } from 'react-router';
import filterLocationsIcon from '../../assets/icons/filter-locations.svg';
import filterBatchesIcon from '../../assets/icons/filter-batches.svg';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import BackArrowIcon from '../../assets/icons/chevronLeft.svg';
import CustomTextField from '../../core-components/atoms/TextField';
import { Close } from '@mui/icons-material';
import mixpanel from 'mixpanel-browser';

const SimplifiedPickingPreviewDetail = ({
  warehouse,
  company,
  ordersData,
  setValid,
  completeTask,
  setCompleteTask,
  selectedOrdersForPicking,
  handleCloseDialog,
  generateResult,
  setGenerateResult
}) => {
  const { setAPILoader, subdomain } = useContext(SharedContext);
  const [
    collection,
    updateSelectedIds,
    updateBatches,
    getSelectedIds,
    getBatches,
    updateRecord,
    changeOccur,
    setPickQuantity,
    generatePayload,
    getSelectedProductCount,
    refreshPickedCounts,
    totalOrderedCount,
    resetBatches,
    totallyAutoPicked,
    releaseHoldQuantity
  ] = usePickingForm(ordersData, setValid);
  const classes = useStyles();
  const [orderIds, setOrderIds] = useState('');
  const [orderData, setOrderData] = useState([]);
  const [currentSelectedProductId, setCurrentSelectedProductId] = useState('');
  const [selectedProductTitle, setSelectedProductTitle] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [searchUserValue, setSearchUserValue] = useState('');
  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultPayload, setResultPayload] = useState([]);
  const [selectedProductCount, setSelectedProductCount] = useState(0);
  const [pickedProductCounts, setPickedProductCounts] = useState({});
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [autoPickAnchorEl, setAutoPickAnchorEl] = useState(null);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [isBatchFilter, setIsBatchFilter] = useState(true);
  const [enteredValue, setEnteredValue] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    resetBatches();
  }, [filteredLocations, filteredBatches]);

  useEffect(() => {
    setPage(1);
    getNewBatches(1);
  }, [currentSelectedProductId, filteredLocations, filteredBatches]);

  useEffect(() => {
    if (generateResult) {
      const result = generatePayload(structuredClone(ordersData), orderData);
      setResultPayload(result);
      if (result?.length) setOpen(true);
      setGenerateResult(false);
    }
  }, [generateResult]);
  useEffect(() => {
    if (selectedOrdersForPicking?.length) {
      let orders = selectedOrdersForPicking.toString();
      setOrderIds(orders);
      getProducts(selectedOrdersForPicking);
    }
  }, [selectedOrdersForPicking]);

  useEffect(() => {
    if (isRequired(warehouse?.id)) {
      getUserCallback({
        warehouseId: warehouse?.Warehouse.id,
        searchUserValue
      });
    }
  }, [searchUserValue]);

  useEffect(() => {
    setSelectedProductCount(getSelectedProductCount());
    setPickedProductCounts(refreshPickedCounts());
  }, [changeOccur]);

  const getUserCallback = useCallback(
    debounce(({ warehouseId, searchUserValue }) => {
      getWarehouseUsers({
        warehouseId,
        search: searchUserValue,
        columns: ['firstName']
      });
    }, DEBOUNCE_CONST),
    []
  );

  const openAutoPickModalOpen = (event) => {
    setAutoPickAnchorEl(event.currentTarget);
  };

  const closeAutoPickModal = () => {
    setAutoPickAnchorEl(null);
  };

  const openFilterMenu = (event) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setFilterMenuAnchorEl(null);
  };

  const openFilter = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const closeFilter = () => {
    setFilterAnchorEl(null);
  };

  const handleUserSearch = (userId) => {
    setUserId(userId);
  };

  const getWarehouseUsers = async (params) => {
    try {
      let { warehouseId } = params;
      setAPILoader(true);
      setUsers([]);
      const response = await API.get(
        `organizations/${subdomain}/warehouses/${warehouseId}/users`,
        { params }
      );
      setUsers(response.data);
      setAPILoader(false);
    } catch (error) {
      onError(error);
      setAPILoader(false);
    }
  };

  const getNewBatches = async (page) => {
    try {
      if (currentSelectedProductId) {
        const response = await API.get(`inventory-details`, {
          params: {
            page,
            productId: currentSelectedProductId,
            rejectReservedOnly: true,
            warehouseId: warehouse.Warehouse.id,
            batchNumbers: [
              ...new Set(filteredBatches.join(',').split(','))
            ].join(','),
            locations: [
              ...new Set(filteredLocations.join(',').split(','))
            ].join(','),
            limit: 20
          }
        });
        setPageCount(response.pages);
        updateBatches(currentSelectedProductId, [
          ...getBatches(currentSelectedProductId),
          ...response?.data
        ]);
      }
    } catch (err) {
      onError(err);
    }
  };

  const getProducts = async (orders) => {
    try {
      const response = await API.get(
        `warehouses/${warehouse?.Warehouse?.id}/orders`,
        {
          params: {
            companyId: company?.id,
            orderIds: `[${orders.join(',')}]`,
            isGlobalTask: true,
            nodeId: warehouse?.id
          }
        }
      );
      const filteredPrintOrders =
        response?.printOrdersInfo?.printOrders?.filter(
          (p) => p.productQuantityToPick
        ) || [];
      setOrderData(filteredPrintOrders);
      setCurrentSelectedProductId(filteredPrintOrders?.[0]?.product?.id);
      setSelectedProductTitle(
        `${filteredPrintOrders?.[0]?.product?.name} - ${filteredPrintOrders?.[0]?.product?.description}`
      );
      setLoading(!filteredPrintOrders?.length);
      return filteredPrintOrders;
    } catch (err) {
      onError(err);
    }
  };

  const generateTicket = async (products) => {
    try {
      setLoading(true);
      setAPILoader(true);
      let payload = {
        companyId: company?.id,
        warehouseId: warehouse?.Warehouse.id,
        taskType: 'PICKING',
        assignedTo: userId ? userId : null,
        taskStatus: userId ? 'ASSIGNED' : 'NOT_ASSIGNED',
        products,
        generatedThrough: totallyAutoPicked ? 'FEFO' : 'CUSTOM'
      };

      const task = await API.post(`tasks/picking`, {
        ...payload,
        hostUrl: window.location.href
      });

      if (completeTask) {
        const taskDetail = await API.get(`tasks/${task.customId}`);
        if (taskDetail?.warehouseTask?.taskStatus !== 'COMPLETED') {
          await API.put(`tasks/${task.customId}`, {
            warehouseTask: {
              ...taskDetail?.warehouseTask,
              taskStatus: 'COMPLETED',
              taskEndedAt: new Date()
            },
            forceComplete: true
          });
        }
      }
      toaster('success', 'New Picking has been created successfully.');
      setCompleteTask(false);
      navigate(-1);
      handleCloseDialog();
      mixpanel.track('Picking Created', {});
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
      setAPILoader(false);
    }
  };

  const completeAutoPicking = async () => {
    const res = await getProducts(selectedOrdersForPicking);
    res.map((order) => {
      updateRecord(
        order?.product?.id,
        structuredClone(order?.batches) ?? [],
        order?.batches?.map((v) => v.id) ?? [],
        true
      );
    });
  };

  const AutoPickingConfimationPopOver = () => {
    return (
      <div className="p-3">
        <div className="pl-3">
          <p className="font-medium">Are you Sure?</p>
          <p className="text-xs mr-5 mb-2">
            All SKUs will automatically picked based on FIFO logic.
          </p>
        </div>
        <div className="flex justify-betweentext-md font-medium">
          <ActiveCheckbox
            isActive={dontAskAgain}
            setActive={setDontAskAgain}
            label="Don’t ask me again"
          />
          <div className="flex">
            <Button
              label="No"
              variant="text"
              className="mr-2"
              onClick={() => {
                if (dontAskAgain) {
                  dontAskForAutoPickingConfirmation();
                }
                closeAutoPickModal(false);
              }}
            />
            <Button
              label="Yes"
              variant="primary"
              onClick={() => {
                if (dontAskAgain) {
                  dontAskForAutoPickingConfirmation();
                }
                completeAutoPicking();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Grid container className={classes.parentContainer} spacing={3}>
        <Grid item sm={2} className={`${classes.padding12} w-full`}>
          <div>
            <Table
              aria-label="simple table"
              className="bg-dark200 border-solid rounded border-dark200 mb-8"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="flex justify-between text-xs text-dark500">
                      <div>PICK LIST</div>
                      <div>
                        <span className="text-primary">
                          {Math.round(
                            (Object.keys(pickedProductCounts).reduce(
                              (sum, key) =>
                                sum + (pickedProductCounts[key] ?? 0),
                              0
                            ) /
                              Object.values(collection).reduce(
                                (sum, currentValue) => {
                                  return (
                                    sum + (currentValue?.orderedCount || 0)
                                  );
                                },
                                0
                              )) *
                              100 ?? 0
                          )}
                          %
                        </span>{' '}
                        complete
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <div className="max-h-[650px] overflow-y-auto no-scrollbar">
                  {orderData.map((order) => (
                    <TableRow
                      key={order.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={clsx(
                          'w-full',
                          order?.product?.id === currentSelectedProductId
                            ? 'bg-oasisPrimary200'
                            : 'bg-dark200'
                        )}
                        onClick={() => {
                          setCurrentSelectedProductId(order?.product?.id);
                          setSelectedProductTitle(
                            `${order?.product?.name} - ${order?.product?.description}`
                          );
                        }}
                      >
                        <div className="flex justify-between items-center group">
                          <div className="flex items-center w-40">
                            <div
                              className={clsx(
                                'text-[28px] mr-4',
                                pickedProductCounts?.[order?.product?.id]
                                  ? 'text-jade700'
                                  : 'text-dark500'
                              )}
                            >
                              {pickedProductCounts?.[order?.product?.id] ===
                              collection[order?.product?.id]?.orderedCount
                                ? '•'
                                : '◦'}
                            </div>
                            <div className="block">
                              <div className="text-xs font-medium">
                                {order?.product?.description}
                              </div>
                              <div className="text-[10px]">
                                {pickedProductCounts?.[order?.product?.id] ?? 0}
                                /{collection[order?.product?.id]?.orderedCount}{' '}
                                Picked
                              </div>
                            </div>
                          </div>
                          <div
                            className="text-primary opacity-0 transition-opacity duration-300 group-hover:opacity-100 cursor-pointer pl-5"
                            onClick={() => {
                              updateRecord(
                                order?.product?.id,
                                structuredClone(order?.batches) ?? [],
                                order?.batches?.map((v) => v.id) ?? []
                              );
                            }}
                          >
                            Auto Pick
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </div>
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Grid item sm={10} className={classes.padding12}>
          <div className="flex flex-row w-full justify-between">
            <div>
              <div className="text-lg font-medium">{selectedProductTitle}</div>
              <div className="text-lg font-medium">
                {selectedProductCount}/{orderData.length} SKU's Picked
              </div>
              <div>
                Selected Orders: {ordersData.map((o) => o.customId).join(', ')}
              </div>
            </div>
            <div className="flex flex-row space-x-1">
              <Button
                variant="tertiary"
                icon={
                  <FilterListIcon
                    sx={{ marginRight: '8px' }}
                    fontSize="small"
                  />
                }
                onClick={openFilterMenu}
                label="Filters"
              />
              <Button
                variant="primary"
                label={
                  <>
                    <span className="text-xl mr-2">+</span> Auto Pick All
                  </>
                }
                size="large"
                onClick={(event) => {
                  if (Boolean(getDontAskForAutoPickingConfirmation())) {
                    completeAutoPicking();
                  } else {
                    openAutoPickModalOpen(event);
                  }
                }}
              />
            </div>
          </div>
          <div>
            {(Boolean(filteredBatches.length) ||
              Boolean(filteredLocations.length)) && (
              <div className="mt-5">
                Applied Filters:
                {Boolean(filteredBatches.length) && (
                  <span className="mx-2 bg-primary p-1 rounded-sm text-light">
                    Batches: {filteredBatches.join(',').slice(0, 20)}
                    {filteredBatches.join(',').length > 20 && (
                      <span className="mx-2 underline">
                        +{' '}
                        {
                          filteredBatches
                            .join(',')
                            .slice(20, filteredBatches.join(',').length - 20)
                            .split(',').length
                        }{' '}
                        more
                      </span>
                    )}
                    <Close onClick={() => setFilteredBatches([])} />
                  </span>
                )}
                {Boolean(filteredLocations.length) && (
                  <span className="mx-2 bg-primary p-1 rounded-sm text-light">
                    Locations: {filteredLocations.join(',')}
                    {filteredLocations.join(',').length > 20 && (
                      <span className="mx-2 underline">
                        +{' '}
                        {
                          filteredLocations
                            .join(',')
                            .slice(20, filteredLocations.join(',').length - 20)
                            .split(',').length
                        }{' '}
                        more
                      </span>
                    )}
                    <Close onClick={() => setFilteredLocations([])} />
                  </span>
                )}
              </div>
            )}
            <PickingBatchesTable
              getSelectedIds={getSelectedIds}
              getBatches={getBatches}
              updateSelectedIds={updateSelectedIds}
              changeOccur={changeOccur}
              productId={currentSelectedProductId}
              setPickQuantity={setPickQuantity}
              releaseHoldQuantity={releaseHoldQuantity}
              page={page}
              setPage={setPage}
              pageCount={pageCount}
              getNewBatches={getNewBatches}
              order={orderData.find(
                (order) => order?.product?.id === currentSelectedProductId
              )}
              updateRecord={updateRecord}
            />
          </div>
          <Menu
            id="filters-menu"
            anchorEl={filterMenuAnchorEl}
            keepMounted
            open={Boolean(filterMenuAnchorEl)}
            onClose={closeFilterMenu}
            className="p-3"
          >
            <MenuItem disabled>
              <small className="text-dark500">APPLY FILTERS TO THE TABLE</small>
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setIsBatchFilter(true);
                closeFilterMenu();
                openFilter(event);
              }}
            >
              <img
                src={filterLocationsIcon}
                alt="Filter Locations Icon"
                className="mr-2"
              />
              <span className="ml-2">Batches</span>
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setIsBatchFilter(false);
                closeFilterMenu();
                openFilter(event);
              }}
            >
              <img
                src={filterBatchesIcon}
                alt="Filter Batches Icon"
                className="mr-2"
              />
              <span className="ml-2">Locations</span>
            </MenuItem>
          </Menu>
          <Popover
            id="auto-picking-popover"
            anchorEl={autoPickAnchorEl}
            keepMounted
            open={Boolean(autoPickAnchorEl)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            onClose={closeAutoPickModal}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
          >
            <AutoPickingConfimationPopOver />
          </Popover>
          <Popover
            id="filter-popover"
            anchorEl={filterAnchorEl}
            keepMounted
            open={Boolean(filterAnchorEl)}
            onClose={closeFilter}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
          >
            <div className="p-3">
              <div className="flex items-center">
                <span
                  onClick={(event) => {
                    closeFilter();
                    openFilterMenu(event);
                  }}
                >
                  <img src={BackArrowIcon} alt="Back to filter menu" />
                </span>
                <p className="font-medium">
                  {isBatchFilter ? 'Batches' : 'Locations'}
                </p>
              </div>
              <div className="flex justify-center">
                <CustomTextField
                  id="enteredBatches"
                  variant="outlined"
                  size="small"
                  className="w-200 my-2"
                  placeholder={
                    isBatchFilter
                      ? "Filter one or more ID's"
                      : 'Filter Locations'
                  }
                  value={enteredValue}
                  onChange={(event) => {
                    const newValue = event.target.value.split(' ').join(',');;
                    setEnteredValue(newValue);
                  }}
                  sx={{
                    borderRadius: '8px',
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                      height: '7px'
                    }
                  }}
                />
              </div>
              <div className="flex justify-end ml-10">
                <Button
                  label="Cancel"
                  variant="text"
                  className="mr-2"
                  onClick={() => {
                    closeFilter();
                    setEnteredValue('');
                  }}
                />
                <Button
                  label={`Filter ${isBatchFilter ? 'Batches' : 'Locations'}`}
                  variant="primary"
                  onClick={() => {
                    if (isBatchFilter) {
                      setFilteredBatches((prev) => [...prev, enteredValue]);
                    } else {
                      setFilteredLocations((prev) => [...prev, enteredValue]);
                    }

                    closeFilter();
                    setEnteredValue('');
                  }}
                  disabled={!enteredValue}
                />
              </div>
            </div>
          </Popover>
          <Popup
            title={`Confirm Picking Task`}
            subTitle={`Creation Date: ${dateFormatSlashDivider(new Date())}`}
            open={open}
            setOpen={(val) => {
              setCompleteTask(false);
              setOpen(val);
            }}
            PaperProps={{
              style: { width: '80%' }
            }}
            content={
              <Grid container className={classes.parentContainer} spacing={3}>
                <Grid item xs={3} className="flex items-center">
                  <p className="font-medium">Picking List Detail</p>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="assignedTo"
                    options={users}
                    value={selectedUser}
                    getOptionLabel={(user) =>
                      Object.keys(user).length !== 0
                        ? `${user?.firstName} ${user?.lastName}`
                        : ''
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleUserSearch(newValue?.id);
                        setSelectedUser(newValue);
                      } else {
                        handleUserSearch('');
                        setSelectedUser({});
                      }
                    }}
                    onInputChange={(event, newValue) => {
                      setSearchUserValue(newValue);
                    }}
                    onFocus={() => {
                      setSearchUserValue('');
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        label="Assigned To"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ConfirmationPreviewTable
                    payload={resultPayload}
                    totalOrderedCount={totalOrderedCount}
                  />
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={3} className="flex justify-between pl-14">
                  <Button
                    label="Cancel"
                    variant="tertiary"
                    onClick={() => {
                      setCompleteTask(false);
                      setOpen(false);
                    }}
                  />
                  <Button
                    label="Confirm"
                    variant="primary"
                    onClick={() => {
                      setSubmitting(true);
                      generateTicket(resultPayload);
                    }}
                    disabled={submitting}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SimplifiedPickingPreviewDetail;

import React from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';

import useStyles from '../../orders/makeStyles';
import BulkFileAttacher from '../../../components/BulkFileAttacher';
import downloadIcon from '../../../assets/icons/downloadIcon.svg';
import aislesDownloadData from '../../../assets/files/BulkUploadAislesTemplate.xlsx';
import baysDownloadData from '../../../assets/files/BulkUploadBaysTemplate.xlsx';
import DetailTitle from '../../../atomicComponents/DetailTitle';

const BulkUploadScreen = ({
  bulkUpload,
  selectedFile,
  setSelectedFile,
  type
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <DetailTitle text="Download Template" style={{ margin: '10px 0' }} />
        <div className="w-100px">
          <Tooltip title="Download Bulk Template">
            <div>
              <a
                href={type === 'aisle' ? aislesDownloadData : baysDownloadData}
                className="upload-image-btn display-block"
                // onClick={downloadTemplate}
              >
                <img
                  src={downloadIcon}
                  className="download-image"
                  alt="download-icon"
                />
              </a>
            </div>
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography className={classes.detailText}>
          <b>Upload</b>
          <span className={classes.opactiy5}>(xlsx, CSV)</span>
        </Typography>
        <BulkFileAttacher
          bulkUpload={bulkUpload}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </Grid>
    </Grid>
  );
};

export default BulkUploadScreen;

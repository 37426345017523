import moment from 'moment';
import React, { useMemo, useState } from 'react';
import DateRangePickerSingleCalendar from '../../revampedOrder/DateRangePicker';
import { getNodeCategories } from './utils';

import {
  createStaticRanges,
  defineds
} from '../../revampedOrder/DateRangePicker';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import Dropdown from '../../../core-components/atoms/Dropdown';
import SearchBar from '../../../atomicComponents/SearchBar';
import { makeStyles } from '@material-ui/core';

const sxProps = {
  '& .MuiInputBase-root': {
    padding: '4.5px 16px !important',
    fontSize: '14px',
    width: '150px'
  },
  '& .MuiAutocomplete-input': {
    padding: '0px 4px 0px 6px !important'
  },
  '& input::placeholder': {
    fontSize: '14px'
  },
  '& textarea::placeholder': {
    fontSize: '14px'
  }
};

const listBoxProps = {
  fontSize: 14,
  width: '150px'
};

const inputProps = {
  classes: {
    input: 'text-sm',
    textarea: 'text-sm',
    'MuiInputBase-root': 'py-2 px-2 text-sm'
  }
};

const useStyles = makeStyles(() => ({
  searchBar: {
    maxWidth: '188px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    marginRight: '8px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));

const MapFilters = ({
  startDate,
  endDate,
  nodeType,
  setStartDate,
  setEndDate,
  setNodeType,
  fromNode,
  setFromNode,
  toNode,
  setToNode,
  nodeData,
  searchKeyword,
  setSearchKeyword,
  setIsReload
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [localStartDate, setLocalStartDate] = useState(
    !startDate ? null : moment(startDate)
  );
  const [localEndDate, setLocalEndDate] = useState(
    !endDate ? null : moment(endDate)
  );

  const nodesData = Object.values(nodeData);
  const classes = useStyles();

  const nodes = useMemo(() => {
    if (!nodesData) {
      return [];
    }
    return [
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...nodesData?.map((node) => {
        return {
          label: node?.name,
          value: node?.id
        };
      })
    ];
  }, [nodesData]);

  const updateRange = (input) => {
    if (!input.selection.startDate || !input.selection.endDate) {
      setStartDate(null);
      setEndDate(null);
      setLocalStartDate(null);
      setLocalEndDate(null);
    } else if (
      moment(input.selection.startDate).isSame(
        moment(input.selection.endDate),
        'day'
      )
    ) {
      setStartDate(
        moment(input.selection.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
      );
      setLocalStartDate(
        moment(input.selection.startDate).format('YYYY-MM-DD HH:mm:ss')
      );
      setEndDate(
        moment(input.selection.endDate)
          .utc()
          .add(1, 'day')
          .subtract(1, 'second')
          .format('YYYY-MM-DD HH:mm:ss')
      );
      setLocalEndDate(
        moment(input.selection.endDate)
          .add(1, 'day')
          .subtract(1, 'second')
          .format('YYYY-MM-DD HH:mm:ss')
      );
    } else {
      setStartDate(
        moment(input.selection.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
      );
      setEndDate(
        moment(input.selection.endDate).utc().format('YYYY-MM-DD HH:mm:ss')
      );
      setLocalStartDate(
        moment(input.selection.startDate).format('YYYY-MM-DD HH:mm:ss')
      );
      setLocalEndDate(
        moment(input.selection.endDate).format('YYYY-MM-DD HH:mm:ss')
      );
    }
  };

  return (
    <div className="flex flex-row justify-end">
      <div className={'flex mr-2 gap-2'}>
        <Autocomplete
          placeholder={'From'}
          value={nodes?.find((node) => node.value === fromNode)}
          options={nodes}
          getOptionLabel={(nodes) => nodes?.label || ''}
          onChange={(event, newValue) => {
            setFromNode(newValue?.value || null);
            setIsReload(true);
          }}
          onInputChange={(event, newValue) => {
            if (!newValue) {
              setFromNode(null);
              setIsReload(true);
            }
          }}
          disableClearable={false}
          sxProps={sxProps}
          customInputProps={inputProps}
          listBoxProps={listBoxProps}
        />
        <Autocomplete
          placeholder={'To'}
          value={nodes.find((node) => node.value === toNode)}
          options={nodes}
          getOptionLabel={(nodes) => nodes?.label || ''}
          onChange={(event, newValue) => {
            setToNode(newValue?.value || null);
            setIsReload(true);
          }}
          onInputChange={(event, newValue) => {
            if (!newValue) {
              setToNode(null);
              setIsReload(true);
            }
          }}
          disableClearable={false}
          sxProps={sxProps}
          customInputProps={inputProps}
          listBoxProps={listBoxProps}
        />
      </div>
      <SearchBar
        className={classes.searchBar}
        iconClass="h-4 w-4"
        setSearchValue={(e) => {
          setSearchKeyword(e);
        }}
        placeholder="Search"
        searchKeyword={searchKeyword}
      />
      <div
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Dropdown
          variant="outlined"
          size="small"
          value={nodeType}
          items={getNodeCategories()}
          onChange={(selection) => setNodeType(selection)}
          showIcon={true}
          iconClasses="mr-3 w-[20px] flex justify-center"
          selectClasses="h-[33px]"
        />
      </div>
      <DateRangePickerSingleCalendar
        anchorEl={anchorEl}
        showDatesInButton={startDate}
        startDate={localStartDate}
        endDate={localEndDate}
        buttonClasses="h-[38px]"
        customStaticRanges={customRanges}
        showMonthArrow={true}
        updateRange={updateRange}
        label="All Shipments"
      />
    </div>
  );
};

export default MapFilters;

const customRanges = createStaticRanges([
  {
    label: 'Next 7 Days',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.afterSevenDays
    })
  },
  {
    label: 'Next 30 Days',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.afterThirtyDays
    })
  }
]);

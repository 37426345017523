import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px',
    width: '100% !important',
    '@media (max-width: 767px)': {
      padding: '10px 10px'
    }
  },
  heading: {
    fontWeight: 'bolder'
  },
  head: {
    background: '#EDF3FA'
  },
  leftBorder: {
    'border-left': '2px solid #4B89C8'
  },
  visibilityNone: {
    visibility: 'hidden'
  },
  padding0: {
    padding: '0 0 0 16px !important'
  },
  margin0: {
    margin: '0 5px 0 0 !important'
  },
  root: {
    width: '100%',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      overflow: 'auto'
    }
  },
  container: {
    padding: 20,
    '@media (max-width: 767px)': {
      padding: 10,
      paddingTop: '10px !important'
    }
  },
  detailContainer: {
    width: '90%',
    margin: 'auto',
    marginTop: '30px'
  },
  taskManagementContainer: {
    margin: '40px 15px',
    '@media (max-width: 767px)': {
      margin: '10px 0px'
    }
  },
  pt1: {
    paddingTop: 1
  },

  completedStatus: {
    color: '#4EAD5B'
  },
  inProgressStatus: {
    color: '#3F6A26'
  },
  notAssignedStatus: {
    color: '#DF8244'
  },
  exportButton: {
    padding: '10px',
    borderTop: '1px solid lightgrey'
  },
  createButton: {
    width: '96%',
    margin: '0 3px 0 12px',
    color: 'white',
    backgroundColor: '#4B89C8',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    }
  },
  cancelButton: {
    width: '96%',
    margin: '0 3px 0 12px',
    border: '1px solid #4B89C8',
    color: '#4B89C8',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    }
  },
  paddingTop0: {
    paddingTop: 0
  },
  paddingTop12: {
    paddingTop: 12
  },
  padding12: {
    padding: '12px 12px 0px !important'
  },
  hidden: {
    visibility: 'hidden'
  },
  marginRight8: {
    marginRight: 8
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  backdropGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  popupFooter: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    marginRight: '3%'
  },
  cancelBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '90%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #D9DFE5',
    background: '#D9DFE5',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#979B9F',
    marginLeft: '24px !important',
    '@media (max-width: 767px)': {
      width: '70%'
    }
  },
  submitBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '92%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #4B89C8',
    background: '#4B89C8',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#FFFFFF',
    '@media (max-width: 767px)': {
      width: '70%'
    }
  },
  editIcon: {
    cursor: 'pointer',
    marginRight: '8px'
  },
  assignTO: {
    justifyContent: 'space-between',
    width: '100%',
    display: 'flex'
  },
  floatRight: {
    float: 'right'
  },
  pagination: {
    marginTop: 10
  },
  dataGrid: {
    height: 650,
    width: '100%'
  },
  taskIdStyles: {
    color: '#4B89C8',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row !important'
  },
  parentWidth: {
    width: '100%',
    'padding-right': 5
  },
  marginBottom: {
    '@media (max-width: 767px)': {
      marginBottom: '10px'
    }
  },
  createCycleButton: {
    '@media (min-width: 769px) and (max-width: 992px)': {
      fontSize: '8px !important'
    }
  },
  iconClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterView: {
    '@media (max-width: 767px)': {
      left: '0px !important'
    }
  },
  bdGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  btnYes: {
    marginLeft: 10,
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green'
    }
  },
  scanningOverlay: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    left: '0',
    top: '0',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 11199999,
    overflow: 'hidden'
  },
  childLoader: {
    position: 'fixed',
    left: '50%',
    top: '50%'
  },
  scanImgOverlay: {
    width: '60%'
  },
  tableHeadText: {
    fontWeight: 'bolder'
  }
}));

export default useStyles;

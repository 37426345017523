import React, { useEffect, useRef } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  TextField
} from '@material-ui/core';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MdCancel } from 'react-icons/md';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import '../assets/css/style.css';

export default function CustomFilter(props) {
  let ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.onClickOutside && props.onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div ref={ref} style={{ display: 'inline' }}>
      <Tooltip title="Close Filter">
        <button
          onClick={props.handleClose}
          type="button"
          className="closeModalBtn"
        >
          <MdCancel />
        </button>
      </Tooltip>
      <div className="customFilterParent">
        <Grid item container>
          {props?.columns?.map((column) => {
            switch (column?.type) {
              case 'text':
                return (
                  <Grid className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label className="display-block orderFilter-heading">
                      <b>{column.label}</b>
                    </label>
                    <input
                      type={column.type}
                      placeholder="Type here"
                      value={column.value}
                      className="customFilterField mt-3"
                      onChange={(e) => column.setNameVal(e.target.value)}
                    />
                  </Grid>
                );

              case 'radio':
                return (
                  <Grid className="mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label className="display-block">
                      <b>{column.label}</b>
                    </label>

                    <RadioGroup
                      row
                      id={column?.name}
                      className="customRadio"
                      value={column?.value}
                      name={column?.field}
                      onChange={(e) => column.setNameVal(e.target.value)}
                    >
                      {column?.options?.length
                        ? column?.options.map((option) => (
                            <FormControlLabel
                              className="mr-10"
                              key={option.value}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                            />
                          ))
                        : null}
                    </RadioGroup>
                  </Grid>
                );

              case 'datePicker':
                return (
                  <Grid
                    className="mt-3 customDateFilterField"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                  >
                    <label className="display-block">
                      <b>{column.label}</b>
                    </label>
                    <div className="display-flex">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          mask="__/__/____"
                          maxDate={new Date()}
                          inputFormat="dd/MM/yyyy"
                          value={column?.value}
                          name={column?.field}
                          onChange={(e) => {
                            column.setNameVal(e);
                            props?.handleOpen();
                          }}
                          onClose={() => props?.handleOpen()}
                          onAccept={() => props?.handleOpen()}
                          onMonthChange={() => props?.handleOpen()}
                          onYearChange={() => props?.handleOpen()}
                          onViewChange={() => props?.handleOpen()}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              id="DateFilter"
                              className="generatedField"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                );
            }
          })}

          <Grid xs="12" className="justify-content-right mt-5 display-flex">
            <Tooltip title="CANCEL">
              <button
                className="cancelBtn"
                onClick={props.clearFilter}
                type="button"
              >
                CLEAR FILTER
              </button>
            </Tooltip>

            <Tooltip title="APPLY FILTER">
              <button
                className="submitBtns ml-3"
                onClick={() => props?.applyFilter()}
                type="submit"
              >
                <b>APPLY FILTER</b>
              </button>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton
} from '@mui/material';

const TableSkeleton = ({ numberOfRows = 3, numberOfColumns = 4 }) => {
  return (
    <TableContainer
      component={Paper}
      className="border border-dark300 rounded overflow-hidden mt-4"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="border-b border-dark300 m-0">
            {Array.from({ length: numberOfColumns }).map((_, index) => (
              <TableCell
                key={index}
                className="border-r border-dark300 py-1 bg-dark200"
              >
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
            <TableRow key={rowIndex} className="border-b border-dark300">
              {Array.from({ length: numberOfColumns }).map((_, colIndex) => (
                <TableCell
                  key={colIndex}
                  className="border-r border-dark300 py-2"
                >
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;

/* eslint-disable */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import API from '../../libs/axios';
import {
  FormControl,
  InputAdornment,
  List,
  ListItem,
  Popper
} from '@mui/material';
import { debounce } from 'lodash';
import { onError } from '../../libs/errorLib';
import { DEBOUNCE_CONST } from '../../Config';
import DetailIcon from '../../assets/icons/detail.svg';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Close, Search } from '@material-ui/icons';
import { makeStyles, ThemeProvider, TextField } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { OASIS_PRIMARY_500 } from '../../constants/colors';

const OrderSearchBar = ({
  setCompanyNameFilter,
  companyFiterName,
  setCustomerId,
  onClear
}) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [companies, setOrders] = useState([]);
  const [selection, setSelection] = useState(false);
  const anchorRef = useRef(null);

  const useStyles = makeStyles(() => ({
    popper: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      }
    },
    paper: {
      pointerEvents: 'auto',
      width: '300px',
      border: '1px solid #E7E6E6',
      marginTop: '2px',
      maxHeight: '220px',
      boxShadow: '0px 8px 12px 0px #0000001F',
      overflowY: 'auto',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& input': {
        fontSize: '12px !important'
      }
    },
    heading: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#67718C',
      padding: '16px 8px',
      marginLeft: '16px'
    },
    option: {
      display: 'flex',
      gap: '16px',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer'
    }
  }));

  const handleClose = (e) => {
    if (
      document
        .getElementsByClassName('container-search-order')[0]
        .contains(e.target)
    ) {
      e.preventDefault();
    } else {
      setOpen(false);
      if (companyFiterName) setSelection(true);
    }
  };

  const getCompanies = async (params) => {
    try {
      let res = await API.get('companies', {
        params,
        columns: []
      });
      setOrders(res.data);
    } catch (err) {
      onError(err);
    }
  };

  const _getCompanies = useCallback(
    debounce(async (search) => {
      if (search) {
        await getCompanies({ search });
      } else {
        await getCompanies();
      }
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    if (search) {
      _getCompanies(search);
      setOpen(true);
    }
  }, [search]);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });

  return (
    <div className="w-[300px] container-search-order">
      <FormControl fullWidth ref={anchorRef}>
        <ThemeProvider theme={theme}>
          <TextField
            fullWidth
            placeholder="Search"
            variant="outlined"
            color={'primary'}
            value={search}
            size="small"
            onChange={(e) => {
              setSearch(e.target.value);
              setCompanyNameFilter('');
            }}
            inputProps={{
              onFocus: () => search && setOpen(true)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px'
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {search && (
                    <Close
                      style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setSearch('');
                        setSelection(false);
                        onClear();
                      }}
                    />
                  )}
                </InputAdornment>
              )
            }}
          />
        </ThemeProvider>
      </FormControl>
      {search && !selection && open && (
        <Popper
          open={open}
          anchorEl={
            anchorRef.current ||
            document.getElementsByClassName('container-search-order')[0]
          }
          placement="bottom-start"
          disablePortal
          className={classes.popper}
          modifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={classes.paper}>
              <List>
                <p className={classes.heading}>SEARCH RESULTS</p>
                {search &&
                  companies?.rows?.map((result) => (
                    <ListItem
                      key={result}
                      className={classes.option}
                      onClick={() => {
                        setSearch(result.name);
                        setSelection(true);
                        setCompanyNameFilter(result.name);
                        setCustomerId(result.id);
                      }}
                    >
                      <img src={DetailIcon} />
                      <span className="text-sm">{result.name}</span>
                    </ListItem>
                  ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

export default OrderSearchBar;

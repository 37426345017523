/*eslint-disable*/

import Stepper from './Stepper';

const ShipmentTimeline = () => {
  return (
    <div className={'py-8 px-4'}>
      <Stepper
        orientation="vertical"
        steps={[
          'created',
          'dock assigned',
          'GDN created',
          'Shipment completed'
        ]}
        activeStep={0}
      />
    </div>
  );
};

export default ShipmentTimeline;

/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import BasicDetail from './BasicDetail';
import PrintStyles from './PrintStyles';
import ProductDetail from './ProductDetail';
import owareLogo from '../../assets/icons/O360DarkLogo.svg';
import { OrderTypeMapping } from '../../constants/index';
import { SharedContext } from '../../utils/common';
import { useParams } from 'react-router';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';
import PrintMenu from '../PrintMenu';
import Button from '../../core-components/atoms/Button';
import EXPORT_ICON from '../../assets/icons/export.svg';

const PrintDetail = ({
  formik,
  orderType,
  vehicleType,
  tms,
  totalOrderedQuantity,
  totalDispatchedQuantity,
  totalReceivedQuantity
}) => {
  const classes = PrintStyles();
  const { sid } = useParams();
  const { currentUser } = useContext(SharedContext);
  const order = { ...formik.values.Order, ...formik.values.shipment };
  const shipment = formik.values.shipment;
  const { darkOrganizationLogoFile, settings } = useContext(SharedContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [watermark, setWatermark] = useState(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    documentTitle:
      orderType === 'grn' ? `GRN ${order.customId}` : `GDN ${order.customId}`
  });

  useEffect(() => {
    if (watermark) {
      handlePrint();
      handleClose();
    }
  }, [watermark]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
    setWatermark(null);
  }

  const header =
    orderType === 'grn' ? 'Good Received Note' : 'Good Dispatch Note';
  const vehicleDetail = vehicleType
    ? `${vehicleType?.CarMake?.name} ${vehicleType?.CarModel?.name} (${vehicleType?.Category?.name})`
    : null;

  return (
    <>
      {watermark === 'all' && (
        <Box display="none" displayPrint="block" ref={componentRef}>
          <Box
            display="none"
            displayPrint="block"
            className={clsx(classes.pageContainer, classes.root)}
          >
            <table>
              <thead>
                <tr>
                  <td>
                    <div className={classes.topTextContainer} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Box display="none" displayPrint="block">
                      <Grid container spacing={2} className="mb-4">
                        <Grid item xs={6}>
                          {darkOrganizationLogoFile ? (
                            <img
                              src={darkOrganizationLogoFile.location}
                              alt="Logo"
                              style={{
                                width: '100px',
                                height: 'auto',
                                maxHeight: '100px'
                              }}
                            />
                          ) : (
                            <img
                              style={{ width: 'auto', height: '18px' }}
                              src={owareLogo}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <div className={classes.copyTypeButton}>
                            {`customer copy`}
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <p className={classes.pageSubHeading}>{header}</p>
                          <div className={classes.totalRow}>
                            <p className={classes.shipmentIdText}>
                              {`Shipment ${sid}`}{' '}
                              <span className="mx-1 text-[12px] font-normal">
                                {'. '}
                              </span>
                            </p>
                            <p className={classes.shipmentIdDate}>
                              {moment(order?.createdAt)
                                .tz(settings?.timezone?.tzCode)
                                .format(`${settings?.dateFormat} hh:mm A`)}
                            </p>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <p className={classes.referenceIdText}>
                            {`${
                              order?.moveSubType
                                ? order?.moveSubType?.toLowerCase()
                                : OrderTypeMapping[order?.moveType] ||
                                  order?.moveType?.toLowerCase()
                            } - ${order?.Order?.customId}`}
                          </p>
                        </Grid>
                      </Grid>

                      <BasicDetail
                        vehicleDetail={vehicleDetail}
                        order={order}
                        orderType={orderType}
                        tms={tms}
                        shipment={shipment}
                      />
                      <Divider className={classes.dividerProperties} />
                      <Box
                        className={classes.additionalInstructionsContainer}
                        display="none"
                        displayPrint="block"
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            ORIGIN
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.pickupNode
                              ? order?.pickupNode?.name ??
                                order?.pickupNode?.code
                              : '-'}
                            {order?.pickupNode?.address
                              ? ` - ${order?.pickupNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            DESTINATION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.dropOffNode
                              ? order?.dropOffNode?.name ??
                                order?.dropOffNode?.code
                              : '-'}
                            {order?.dropOffNode?.address
                              ? ` - ${order?.dropOffNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            INSTRUCTION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.memo ?? '-'}
                          </p>
                        </div>
                      </Box>
                      <Divider className={classes.dividerProperties} />
                      <ProductDetail
                        order={order}
                        orderType={orderType}
                        totalOrderedQuantity={totalOrderedQuantity}
                        totalDispatchedQuantity={totalDispatchedQuantity}
                        totalReceivedQuantity={totalReceivedQuantity}
                      />
                    </Box>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div className={classes.footerSpace}>
                      <Box
                        className={classes.bottomTextContainer}
                        display="none"
                        displayPrint="block"
                      >
                        <div>
                          <p className={classes.bottomText}>
                            {`This is an electronically generated document and does not require a signature.`}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '7px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex'
                              }}
                            >
                              <p className={classes.bottomTextNormal}>
                                Printed By:
                              </p>
                              <p
                                className={classes.bottomText}
                              >{` ${currentUser?.firstName} ${currentUser.lastName}`}</p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '36px'
                              }}
                            >
                              <p className={classes.bottomTextNormal}>
                                Printed Date & Time:
                              </p>
                              <p className={classes.bottomText}>
                                {` ${moment()
                                  .tz(settings?.timezone?.tzCode)
                                  .format(`${settings?.dateFormat} hh:mm A`)}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Box>
          <Box
            display="none"
            displayPrint="block"
            className={clsx(classes.pageContainer, classes.root)}
          >
            <table>
              <thead>
                <tr>
                  <td>
                    <div className={classes.topTextContainer} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Box display="none" displayPrint="block">
                      <Grid container spacing={2} className="mb-4">
                        <Grid item xs={6}>
                          {darkOrganizationLogoFile ? (
                            <img
                              src={darkOrganizationLogoFile.location}
                              alt="Logo"
                              style={{
                                width: '100px',
                                height: 'auto',
                                maxHeight: '100px'
                              }}
                            />
                          ) : (
                            <img
                              style={{ width: 'auto', height: '18px' }}
                              src={owareLogo}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <div className={classes.copyTypeButton}>
                            {`gatepass copy`}
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <p className={classes.pageSubHeading}>{header}</p>
                          <div className={classes.totalRow}>
                            <p className={classes.shipmentIdText}>
                              {`Shipment ${sid}`}{' '}
                              <span className="mx-1 text-[12px] font-normal">
                                {'. '}
                              </span>
                            </p>
                            <p className={classes.shipmentIdDate}>
                              {moment(order?.createdAt)
                                .tz(settings?.timezone?.tzCode)
                                .format(`${settings?.dateFormat} hh:mm A`)}
                            </p>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <p className={classes.referenceIdText}>
                            {`${
                              order?.moveSubType
                                ? order?.moveSubType?.toLowerCase()
                                : OrderTypeMapping[order?.moveType] ||
                                  order?.moveType?.toLowerCase()
                            } - ${order?.Order?.customId}`}
                          </p>
                        </Grid>
                      </Grid>

                      <BasicDetail
                        vehicleDetail={vehicleDetail}
                        order={order}
                        orderType={orderType}
                        tms={tms}
                        shipment={shipment}
                      />
                      <Divider className={classes.dividerProperties} />
                      <Box
                        className={classes.additionalInstructionsContainer}
                        display="none"
                        displayPrint="block"
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            ORIGIN
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.pickupNode
                              ? order?.pickupNode?.name ??
                                order?.pickupNode?.code
                              : '-'}
                            {order?.pickupNode?.address
                              ? ` - ${order?.pickupNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            DESTINATION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.dropOffNode
                              ? order?.dropOffNode?.name ??
                                order?.dropOffNode?.code
                              : '-'}
                            {order?.dropOffNode?.address
                              ? ` - ${order?.dropOffNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            INSTRUCTION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.memo ?? '-'}
                          </p>
                        </div>
                      </Box>
                      <Divider className={classes.dividerProperties} />
                      <ProductDetail
                        order={order}
                        orderType={orderType}
                        totalOrderedQuantity={totalOrderedQuantity}
                        totalDispatchedQuantity={totalDispatchedQuantity}
                        totalReceivedQuantity={totalReceivedQuantity}
                      />
                    </Box>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div className={classes.footerSpace}>
                      <Box
                        className={classes.bottomTextContainer}
                        display="none"
                        displayPrint="block"
                      >
                        <div>
                          <p className={classes.bottomText}>
                            {`This is an electronically generated document and does not require a signature.`}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '7px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex'
                              }}
                            >
                              <p className={classes.bottomTextNormal}>
                                Printed By:
                              </p>
                              <p
                                className={classes.bottomText}
                              >{` ${currentUser?.firstName} ${currentUser.lastName}`}</p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '36px'
                              }}
                            >
                              <p className={classes.bottomTextNormal}>
                                Printed Date & Time:
                              </p>
                              <p className={classes.bottomText}>
                                {` ${moment()
                                  .tz(settings?.timezone?.tzCode)
                                  .format(`${settings?.dateFormat} hh:mm A`)}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Box>
          <Box
            display="none"
            displayPrint="block"
            className={clsx(classes.pageContainer, classes.root)}
          >
            <table>
              <thead>
                <tr>
                  <td>
                    <div className={classes.topTextContainer} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Box display="none" displayPrint="block">
                      <Grid container spacing={2} className="mb-4">
                        <Grid item xs={6}>
                          {darkOrganizationLogoFile ? (
                            <img
                              src={darkOrganizationLogoFile.location}
                              alt="Logo"
                              style={{
                                width: '100px',
                                height: 'auto',
                                maxHeight: '100px'
                              }}
                            />
                          ) : (
                            <img
                              style={{ width: 'auto', height: '18px' }}
                              src={owareLogo}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <div
                            className={classes.copyTypeButton}
                          >{`vendor copy`}</div>
                        </Grid>{' '}
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <p className={classes.pageSubHeading}>{header}</p>
                          <div className={classes.totalRow}>
                            <p className={classes.shipmentIdText}>
                              {`Shipment ${sid}`}{' '}
                              <span className="mx-1 text-[12px] font-normal">
                                {'. '}
                              </span>
                            </p>
                            <p className={classes.shipmentIdDate}>
                              {moment(order?.createdAt)
                                .tz(settings?.timezone?.tzCode)
                                .format(`${settings?.dateFormat} hh:mm A`)}
                            </p>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <p className={classes.referenceIdText}>
                            {`${
                              order?.moveSubType
                                ? order?.moveSubType?.toLowerCase()
                                : OrderTypeMapping[order?.moveType] ||
                                  order?.moveType?.toLowerCase()
                            } - ${order?.Order?.customId}`}
                          </p>
                        </Grid>
                      </Grid>

                      <BasicDetail
                        vehicleDetail={vehicleDetail}
                        order={order}
                        orderType={orderType}
                        tms={tms}
                        shipment={shipment}
                      />
                      <Divider className={classes.dividerProperties} />
                      <Box
                        className={classes.additionalInstructionsContainer}
                        display="none"
                        displayPrint="block"
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            ORIGIN
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.pickupNode
                              ? order?.pickupNode?.name ??
                                order?.pickupNode?.code
                              : '-'}
                            {order?.pickupNode?.address
                              ? ` - ${order?.pickupNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            DESTINATION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.dropOffNode
                              ? order?.dropOffNode?.name ??
                                order?.dropOffNode?.code
                              : '-'}
                            {order?.dropOffNode?.address
                              ? ` - ${order?.dropOffNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            INSTRUCTION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.memo ?? '-'}
                          </p>
                        </div>
                      </Box>
                      <Divider className={classes.dividerProperties} />
                      <ProductDetail
                        order={order}
                        orderType={orderType}
                        totalOrderedQuantity={totalOrderedQuantity}
                        totalDispatchedQuantity={totalDispatchedQuantity}
                        totalReceivedQuantity={totalReceivedQuantity}
                      />
                    </Box>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div className={classes.footerSpace}>
                      <Box
                        className={classes.bottomTextContainer}
                        display="none"
                        displayPrint="block"
                      >
                        <div>
                          <p className={classes.bottomText}>
                            {`This is an electronically generated document and does not require a signature.`}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '7px'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex'
                              }}
                            >
                              <p className={classes.bottomTextNormal}>
                                Printed By:
                              </p>
                              <p
                                className={classes.bottomText}
                              >{` ${currentUser?.firstName} ${currentUser.lastName}`}</p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '36px'
                              }}
                            >
                              <p className={classes.bottomTextNormal}>
                                Printed Date & Time:
                              </p>
                              <p className={classes.bottomText}>
                                {` ${moment()
                                  .tz(settings?.timezone?.tzCode)
                                  .format(`${settings?.dateFormat} hh:mm A`)}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Box>
        </Box>
      )}
      {watermark && watermark !== 'all' && (
        <Box
          display="none"
          displayPrint="block"
          className={clsx(classes.pageContainer, classes.root)}
          ref={componentRef}
        >
          <table>
            <thead>
              <tr>
                <td>
                  <div className={classes.topTextContainer} />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box display="none" displayPrint="block">
                    <Grid container spacing={2} className="mb-4">
                      <Grid item xs={6}>
                        {darkOrganizationLogoFile ? (
                          <img
                            src={darkOrganizationLogoFile.location}
                            alt="Logo"
                            style={{
                              width: '100px',
                              height: 'auto',
                              maxHeight: '100px'
                            }}
                          />
                        ) : (
                          <img
                            style={{ width: 'auto', height: '18px' }}
                            src={owareLogo}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.gridItem}
                        justifyContent="flex-end"
                      >
                        <div className={classes.copyTypeButton}>
                          {`${watermark} copy`}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <p className={classes.pageSubHeading}>{header}</p>
                        <div className={classes.totalRow}>
                          <p className={classes.shipmentIdText}>
                            {`Shipment ${sid}`}{' '}
                            <span className="mx-1 text-[12px] font-normal">
                              {'. '}
                            </span>
                          </p>
                          <p className={classes.shipmentIdDate}>
                            {moment(order?.createdAt)
                              .tz(settings?.timezone?.tzCode)
                              .format(`${settings?.dateFormat} hh:mm A`)}
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.gridItem}
                        justifyContent="flex-end"
                      >
                        <p className={classes.referenceIdText}>
                          {`${
                            order?.moveSubType
                              ? order?.moveSubType?.toLowerCase()
                              : OrderTypeMapping[order?.moveType] ||
                                order?.moveType?.toLowerCase()
                          } - ${order?.Order?.customId}`}
                        </p>
                      </Grid>
                    </Grid>

                    <BasicDetail
                      vehicleDetail={vehicleDetail}
                      order={order}
                      orderType={orderType}
                      tms={tms}
                      shipment={shipment}
                    />
                    <Divider className={classes.dividerProperties} />
                    <Box
                      className={classes.additionalInstructionsContainer}
                      display="none"
                      displayPrint="block"
                    >
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '16px'
                        }}
                      >
                        <p className={classes.additionalInstructionsHeading}>
                          ORIGIN
                        </p>
                        <p className={classes.additionalInstructionsBody}>
                          {order?.pickupNode
                            ? order?.pickupNode?.name ?? order?.pickupNode?.code
                            : '-'}
                          {order?.pickupNode?.address
                            ? ` - ${order?.pickupNode?.address || ''}`
                            : ''}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '16px'
                        }}
                      >
                        <p className={classes.additionalInstructionsHeading}>
                          DESTINATION
                        </p>
                        <p className={classes.additionalInstructionsBody}>
                          {order?.dropOffNode
                            ? order?.dropOffNode?.name ??
                              order?.dropOffNode?.code
                            : '-'}
                          {order?.dropOffNode?.address
                            ? ` - ${order?.dropOffNode?.address || ''}`
                            : ''}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '16px'
                        }}
                      >
                        <p className={classes.additionalInstructionsHeading}>
                          INSTRUCTION
                        </p>
                        <p className={classes.additionalInstructionsBody}>
                          {order?.memo ?? '-'}
                        </p>
                      </div>
                    </Box>
                    <Divider className={classes.dividerProperties} />
                    <ProductDetail
                      order={order}
                      orderType={orderType}
                      totalOrderedQuantity={totalOrderedQuantity}
                      totalDispatchedQuantity={totalDispatchedQuantity}
                      totalReceivedQuantity={totalReceivedQuantity}
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <div className={classes.footerSpace}>
                  <Box
                    className={classes.bottomTextContainer}
                    display="none"
                    displayPrint="block"
                  >
                    <div>
                      <p className={classes.bottomText}>
                        {`This is an electronically generated document and does not require a signature.`}
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '7px'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex'
                          }}
                        >
                          <p className={classes.bottomTextNormal}>
                            Printed By:
                          </p>
                          <p
                            className={classes.bottomText}
                          >{` ${currentUser?.firstName} ${currentUser.lastName}`}</p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: '36px'
                          }}
                        >
                          <p className={classes.bottomTextNormal}>
                            Printed Date & Time:
                          </p>
                          <p className={classes.bottomText}>
                            {` ${moment()
                              .tz(settings?.timezone?.tzCode)
                              .format(`${settings?.dateFormat} hh:mm A`)}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </tr>
            </tfoot>
          </table>
        </Box>
      )}
      <PrintMenu
        anchorEl={anchorEl}
        setWatermark={setWatermark}
        handleClose={handleClose}
      />
      <Button
        label="Print"
        icon={<img src={EXPORT_ICON} alt="Export Icon" className="mr-2" />}
        className="ml-1"
        onClick={handleClick}
        onClose={handleClose}
      />
    </>
  );
};

export default PrintDetail;

/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useFormik } from 'formik';
import FormikTextField from '../../../core-components/molecules/FormikTextField';
import FormikAutocomplete from '../../../core-components/molecules/FormikAutocomplete';
import addIcon from '../../../assets/icons/add-icon-light.svg';
import placement from '../../../assets/icons/img_placement.png';
import { upload } from '../../../utils/upload';
import { toaster } from '../../../utils/toaster';
import { onError } from '../../../libs/errorLib';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { NumericFormat } from 'react-number-format';
import TextField from './CustomTextField';
import { Label } from '../../../core-components/atoms/Text';
import { Close } from '@material-ui/icons';

const ExpenseForm = ({
  expense,
  removeExpense,
  expenses,
  setExpenses,
  order,
  index,
  shipment,
  deletePermission
}) => {
  const uploadRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [files, setFiles] = useState([]);
  const [types, setTypes] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: expense?.name,
      cost: expense?.cost,
      fileIds: []
    }
  });
  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  useEffect(() => {
    if (types.length <= 0) {
      const shipments =
        order?.Shipments?.map((val) => {
          return { name: 'Shipment ' + val?.customId, id: val?.id };
        }) || [];
      setTypes([{ name: 'Miscellaneous', id: 0 }, ...shipments]);
    }
  }, [types]);

  useEffect(() => {
    if (selectedFile) handleFileUpload(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    if (formik?.values) {
      formik.setFieldValue('fileIds', files);
      const newArr = expenses.map((item, idx) => {
        if (idx === index) {
          const updatedItem = { ...item };
          Object.keys(formik.values).forEach((key) => {
            updatedItem[key] = formik.values[key];
          });
          return updatedItem;
        }
        return item;
      });
      setExpenses(newArr);
    }
  }, [formik?.values, files]);

  const handleFileUpload = async (e) => {
    setFileLoading(true);
    const files = e.target.files;
    const filesArr = [];
    for (let file of files) {
      filesArr.push(file);
    }
    try {
      let uploadedData = await upload(
        filesArr.filter((file) => !file?.id),
        'orders'
      );
      if (uploadedData) {
        setFiles((prevFiles) => [...prevFiles, ...uploadedData]);
      } else {
        toaster('error', 'File not uploaded');
      }
    } catch (err) {
      onError(err);
    } finally {
      setFileLoading(false);
    }
  };

  function getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
  }

  function openFileInNewTab(url) {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className={'display-flex mt-5 justify-between'}
      >
        <Grid container spacing={2}>
          <Grid item xs={!shipment ? 3 : 4}>
            <FormikTextField
              name="name"
              size="small"
              disabled={expense?.shipmentLegId}
              formik={formik}
              placeholder="Add Expense"
              type="text"
              sxProps={sxProps}
            />
          </Grid>
          {!shipment && (
            <Grid item xs={3}>
              <FormikAutocomplete
                name="shipment"
                formik={formik}
                options={types}
                onChange={async (e, value) => {
                  formik.setFieldValue('shipmentId', value);
                }}
                value={
                  formik.values?.shipmentId
                    ? formik.values?.shipmentId
                    : {
                        id:
                          expense?.shipmentId === null ||
                          expenses?.shipmentId === 0 ||
                          expenses?.shipmentId === '0' ||
                          expenses?.shipmentId === ''
                            ? null
                            : expenses?.shipmentId,
                        name:
                          expense?.shipmentId === null ||
                          expenses?.shipmentId === 0 ||
                          expenses?.shipmentId === '0' ||
                          expenses?.shipmentId === ''
                            ? 'Miscellaneous'
                            : `Shipment ${expense?.Shipment?.customId}`
                      }
                }
                placeholder="Select Type"
                getOptionLabel={(option) => option.name || ''}
              />
            </Grid>
          )}
          <Grid item xs={!shipment ? 3 : 4}>
            <NumericFormat
              value={formik?.values?.cost}
              customInput={TextField}
              onValueChange={(e) => {
                if (parseFloat(e.value) >= 0) {
                  formik.setFieldValue('cost', parseFloat(e.value));
                }
              }}
              thousandSeparator=","
              decimalSeparator="."
              placeholder="-"
            />
          </Grid>
          <Grid item xs={!shipment ? 2 : 3}>
            <div className={'flex'}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  uploadRef.current.click();
                }}
                className={
                  'flex border rounded border-dotted min-h-[40px] min-w-[40px] items-center justify-center'
                }
              >
                <img src={addIcon} alt="add-icon" />
              </div>
              {fileLoading && (
                <div
                  className={
                    'flex border rounded border-dotted min-h-[40px] min-w-[40px] items-center justify-center'
                  }
                >
                  <CircularProgress size={20} />
                </div>
              )}
              {expense.Files?.map((val, i) => {
                const fileType = getFileExtension(val?.originalName);
                return (
                  <div
                    key={i}
                    onClick={() => {
                      openFileInNewTab(val?.location);
                    }}
                    className={clsx(
                      'h-[40px] w-[40px] rounded items-center justify-center flex ml-2',
                      fileType === 'pdf'
                        ? 'bg-[#CE375C]'
                        : fileType === 'csv' ||
                          fileType === 'xlsx' ||
                          fileType === 'xls'
                        ? 'bg-[#4DA954]'
                        : fileType === 'doc' ||
                          fileType === 'docx' ||
                          fileType === 'txt'
                        ? 'bg-[#5187ED]'
                        : 'bg-dusk500'
                    )}
                  >
                    <Label variant={'xs'} className={'text-white'}>
                      {fileType}
                    </Label>
                  </div>
                );
              })}
              {files?.map((value, i) => {
                return (
                  <div className={'relative'} key={i}>
                    <Close
                      onClick={() => {
                        const filesArray = files.filter(
                          (fileId) => fileId !== value
                        );
                        setFiles(filesArray);
                      }}
                      className={clsx(
                        ` w-[15px] h-[15px] text-white  absolute h-[15px] w-[15px] rounded-full top-[-5px] right-[-5px] bg-primaryBlue`
                      )}
                    />
                    <div
                      className={clsx(
                        'flex border rounded border-dotted h-[40px] w-[40px] items-center justify-center ml-2'
                      )}
                    >
                      <img src={placement} alt="add-icon" />
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={1}>
            {(expense?.id || index !== 0) &&
              !expense?.shipmentLegId &&
              deletePermission && (
                <DeleteIcon
                  fontSize="small"
                  onClick={() => {
                    removeExpense(index);
                  }}
                />
              )}
          </Grid>
        </Grid>
      </form>
      <input
        ref={uploadRef}
        hidden
        type="file"
        multiple
        onClick={(e) => {
          setSelectedFile(null);
          e.target.value = null;
        }}
        onChange={(e) => {
          setSelectedFile(e);
        }}
        accept=".jpg,.png,.jpeg,.pdf,.csv,.xlsx,.xls,.doc,.docx,.txt"
      />
    </div>
  );
};

export default ExpenseForm;

/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import {
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { SharedContext } from '../../utils/common';
import useStyles from './makeStyles';
import BulkFileAttacher from '../../components/BulkFileAttacher';
import downloadIcon from '../../assets/icons/downloadIcon.svg';
import downloadData3PL from '../../assets/files/BulkUploadOrdersTemplate3PL..xlsx';
import downloadDataManufacturer from '../../assets/files/BulkUploadOrdersTemplateMFG.xlsx';
import DetailTitle from '../../atomicComponents/DetailTitle';
import { ORGANIZATION_TYPES } from '../../constants';

const BulkUploadScreen = ({
  moveType,
  moveTypes,
  company,
  setCompany,
  companies,
  setMoveType,
  setCompanyID,
  searchCompanyState: { searchCompanyValue, setSearchCompanyValue },
  bulkUpload,
  selectedFile,
  setSelectedFile
}) => {
  const classes = useStyles();
  const [singleCompany, setSingleCompany] = useState({});
  const { organizationType } = useContext(SharedContext);

  useEffect(() => {
    if (companies?.length === 1 && !searchCompanyValue.length) {
      setSingleCompany(companies?.[0]);
      setCompany(companies?.[0]);
      setCompanyID(companies?.[0]?.id);
    }
  }, [companies]);

  return (
    <Grid container spacing={2}>
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={
          !(
            organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
            moveType === 'TRANSFER'
          )
            ? 6
            : 12
        }
        lg={
          !(
            organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
            moveType === 'TRANSFER'
          )
            ? 6
            : 12
        }
      >
        <FormControl fullWidth={true} variant="outlined">
          <Autocomplete
            className="autoCompleteField"
            id="moveType"
            value={
              moveTypes.length && moveType
                ? {
                    id: moveTypes?.find((el) => el.id == moveType)?.id || '',
                    type: moveTypes?.find((el) => el.id == moveType)?.type || ''
                  }
                : ''
            }
            options={moveTypes}
            getOptionLabel={(type) => type.type || ''}
            onChange={(e, newValue) => {
              if (newValue) {
                setMoveType(newValue.id);
              } else {
                setMoveType(null);
              }
            }}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Move Type"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </Grid> */}

      {/* {!(
        organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
        moveType === 'TRANSFER'
      ) && (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth={true} variant="outlined">
            <Autocomplete
              className="autoCompleteField"
              id="customer"
              options={companies}
              getOptionLabel={(company) => company.name || ''}
              value={
                companies.length && company
                  ? companies?.find((el) => el.id == company.id) || null
                  : singleCompany || ''
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setCompanyID(newValue.id);
                  setCompany(newValue);
                  setSingleCompany(newValue);
                } else {
                  setCompanyID(null);
                  setCompany(null);
                  setSingleCompany({});
                }
              }}
              onInputChange={(event, newValue) => {
                setSearchCompanyValue(newValue);
              }}
              onFocus={() => {
                setSearchCompanyValue('');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Customer"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
      )} */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <DetailTitle text="Download Template" style={{ margin: '10px 0' }} />
        <div className="w-100px">
          <Tooltip title="Download Bulk Template">
            <div>
              <a
                href={
                  organizationType ===
                  ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
                    ? downloadData3PL
                    : downloadDataManufacturer
                }
                className="upload-image-btn display-block"
              >
                <img
                  src={downloadIcon}
                  className="download-image"
                  alt="download-icon"
                />
              </a>
            </div>
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography className={classes.detailText}>
          <b>Upload</b>
          <span className={classes.opactiy5}>(xlsx, CSV)</span>
        </Typography>
        <BulkFileAttacher
          bulkUpload={bulkUpload}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </Grid>
    </Grid>
  );
};

export default BulkUploadScreen;

/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as XLSX from 'xlsx';
import { Grid } from '@mui/material';
import moment from 'moment';
import { toaster } from '../../../utils/toaster';
import GrnProductsTableRow from './GrnProductsTableRow';
import { decimalDisplay } from '../../../utils/common';
import GRNBulkUpload from './GrnBulkUpload';
import SORT_ICON from '../../../assets/icons/sort.svg';
import DEC_SORT_ICON from '../../../assets/icons/up-arrow.svg';
import ASC_SORT_ICON from '../../../assets/icons/down-arrow.svg';
import Button from '../../../core-components/atoms/Button';
import AddProduct from './AddProduct';
import Popup from '../../../core-components/atoms/Popup';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import WarningIcon from '../../../assets/icons/warning.svg';
import O360GrnProductsTableRow from './O360GrnProductsTableRow';

const GrnProductsTable = ({
  formik,
  GRNview,
  reversed,
  totalOrderedQuantity,
  setTotalOrderedQuantity,
  totalReceivedQuantity,
  setTotalReceivedQuantity,
  reverseFlag,
  returnBatches,
  totalDispatchedQuantity,
  vehicleType,
  batchExpiry
}) => {
  const [rows, setRows] = useState(formik?.values?.Order?.products);
  const [sortOption, setSortOption] = useState(2);
  const [open, setOpen] = useState(false);
  const [reInitialize, setReinitialize] = useState(false);

  let initialValidation = {
    productId: false,
    batchNumber: false,
    expiryDate: false,
    receivedQuantity: false,
    MRP: false,
    recoverableDamageQuantity: false,
    unsellableDamageQuantity: false,
    promoName: false,
    promoQuantity: false
  };

  const [, setValidation] = useState(initialValidation);

  let intitialProductValues = {
    id: '',
    Product: {},
    orderedQuantity: 0,
    batchNumber: '',
    manufacturingDate: null,
    expiryDate: null,
    receivedQuantity: '',
    MRP: null,
    recoverableDamageQuantity: '',
    unsellableDamageQuantity: '',
    goodQuantity: '',
    promoName: '',
    promoQuantity: 0,
    lossIntransitQuantity: 0
  };
  const [productValues, setProductValues] = useState(intitialProductValues);
  const [, setRemainingQty] = useState(productValues?.orderedQuantity || 0);
  const [, setParentIndex] = useState(null);
  const [, setErrorDialog] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (reverseFlag) {
      setRows(formik.values.Order.products);
      setTotalOrderedQuantity(
        formik.values.Order.products
          .reduce((acc, val) => acc + (+val.orderedQuantity || 0), 0)
          .toFixed(3)
      );
      setTotalReceivedQuantity(
        formik.values.Order.products
          .reduce((acc, val) => {
            return (
              acc +
              val.GRNGroupBatches?.reduce((a, obj) => {
                return a + (Number(obj?.receivedQuantity) || 0);
              }, 0)
            );
          }, 0)
          .toFixed(3)
      );
    } else {
      formik.setFieldValue('Order.products', rows);
      setTotalOrderedQuantity(
        rows
          .reduce((acc, val) => acc + (+val.orderedQuantity || 0), 0)
          .toFixed(3)
      );
      setTotalReceivedQuantity(
        rows
          .reduce((acc, val) => {
            return (
              acc +
              val.GRNGroupBatches?.reduce((a, obj) => {
                return a + (Number(obj?.receivedQuantity) || 0);
              }, 0)
            );
          }, 0)
          .toFixed(3)
      );
    }
  }, [rows, formik.values]);

  const resetProductSection = () => {
    setProductValues(intitialProductValues);
    setParentIndex(null);
    setValidation(initialValidation);
  };

  const insertChildRowHandler = (parentIndex, rowData) => {
    setProductValues({
      ...productValues,
      ...{
        Product: { ...rowData.Product },
        ...{ orderedQuantity: rowData.orderedQuantity }
      }
    });
    setParentIndex(parentIndex);
  };

  const addBatch = (parentIndex, batch) => {
    let toggleData = rows;
    if (!toggleData[parentIndex].Product.batchEnabled) {
      if (toggleData[parentIndex].GRNGroupBatches?.length > 0) {
        toggleData[parentIndex].GRNGroupBatches[0].receivedQuantity +=
          batch.receivedQuantity;
        toggleData[parentIndex].GRNGroupBatches[0].recoverableDamageQuantity +=
          batch.recoverableDamageQuantity;
        toggleData[parentIndex].GRNGroupBatches[0].unsellableDamageQuantity +=
          batch.unsellableDamageQuantity;
        toggleData[parentIndex].GRNGroupBatches[0].actualWeight += Number(
          batch.actualWeight
        );
      } else {
        toggleData[parentIndex].GRNGroupBatches = [
          ...(toggleData[parentIndex]?.GRNGroupBatches || []),
          { ...batch, actualWeight: Number(batch.actualWeight) }
        ];
      }
    } else {
      const batchIndex = toggleData[parentIndex].GRNGroupBatches?.findIndex(
        (tb) => {
          if (
            tb.batchNumber !== batch.batchNumber ||
            moment(tb.expiryDate).format('DD/MM/YYYY') !==
              moment(batch.expiryDate).format('DD/MM/YYYY')
          ) {
            return false;
          }
          return (
            tb.batchNumber == batch.batchNumber &&
            moment(batch.expiryDate).format('DD/MM/YYYY') ===
              moment(batch.expiryDate).format('DD/MM/YYYY')
          );
        }
      );
      if (batchIndex >= 0) {
        toggleData[parentIndex].GRNGroupBatches[batchIndex].receivedQuantity +=
          batch.receivedQuantity;
        toggleData[parentIndex].GRNGroupBatches[
          batchIndex
        ].recoverableDamageQuantity += batch.recoverableDamageQuantity;
        toggleData[parentIndex].GRNGroupBatches[
          batchIndex
        ].unsellableDamageQuantity += batch.unsellableDamageQuantity;
        toggleData[parentIndex].GRNGroupBatches[batchIndex].actualWeight =
          Number(
            toggleData[parentIndex].GRNGroupBatches[batchIndex].actualWeight
          ) + Number(batch.actualWeight);
      } else {
        toggleData[parentIndex].GRNGroupBatches = [
          ...(toggleData[parentIndex]?.GRNGroupBatches || []),
          { ...batch, actualWeight: Number(batch.actualWeight) }
        ];
      }
    }

    setRows([...toggleData]);
  };

  const addNewProduct = (newRow, batch) => {
    let index = -1;
    const row = rows.find((row, i) => {
      const condition = row.Product.name === newRow.Product.name;
      if (condition) index = i;
      return condition;
    });

    if (row) {
      addBatch(index, batch);
      toaster('warning', 'Product already exists so only batch added!');
      return;
    }

    newRow.GRNGroupBatches = [batch];
    newRow.receivedQuantity = batch.receivedQuantity;
    newRow.secondaryUomId = null;
    newRow.secondaryUomQuantity = null;
    setRows((prev) => [...prev, newRow]);
  };

  const getValidDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  };

  useEffect(() => {
    const currentProduct = rows?.find(
      (product) => product?.Product?.id === productValues?.Product?.id
    );
    let actualQuantity = currentProduct?.GRNGroupBatches?.reduce(function (
      acc,
      obj
    ) {
      return Number(acc) + (Number(obj?.receivedQuantity) || 0);
    }, 0);
    const remainingQty = productValues?.orderedQuantity
      ? productValues?.orderedQuantity - actualQuantity
      : 0;
    if (productValues?.receivedQuantity) {
      setRemainingQty(
        remainingQty - Number(productValues?.receivedQuantity) < 0
          ? 0
          : remainingQty - productValues?.receivedQuantity
      );
    } else {
      setRemainingQty(remainingQty < 0 ? 0 : remainingQty);
    }
  }, [productValues]);

  const sortProductsByName = () => {
    setRows((prev) => [
      ...prev.sort((a, b) => a.Product?.name.localeCompare(b?.Product?.name))
    ]);
    setSortOption(1);
  };

  const reverseProducts = () => {
    setRows((prev) => [...prev.reverse()]);
    setSortOption((prev) => +!+prev);
  };

  const exportToXlsx = () => {
    const incorrect = data?.filter((p, i) =>
      errorData.find((e) => e?.row == i + 2)
    );
    const messages = errorData
      .filter((e) => data.find((p, i) => e.row == i + 2))
      .map((e) => e.message);
    let rows = incorrect?.map((row, i) => [
      row?.name,
      row?.batchNumber,
      row?.manufacturingDate,
      row?.expiryDate,
      row?.receivedQuantity,
      row?.recoverableDamageQuantity,
      row?.unsellableDamageQuantity,
      row?.promoName,
      row?.promoQuantity,
      row?.MRP,
      messages[i]
    ]);
    const exportData = [
      [
        'SKU Name/SKU Code',
        'Batch #',
        'Manufacturing Date',
        'Expiry Date',
        'Received Qty',
        'Damaged Qty Recoverable',
        'Damaged Qty Unsellable',
        'Promo Code',
        'Promo Qty',
        'MRP (Per Pack) Rs',
        'Reason'
      ],
      ...rows
    ];

    const ws = XLSX.utils.aoa_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Incorrect rows');

    XLSX.writeFile(wb, 'incorrect_rows.xlsx');
  };

  return (
    <>
      <div className="flex justify-between">
        {(GRNview && (
          <p className="text-xl font-semibold">
            GRN Details
            {reversed && (
              <span className="text-error">
                <pre className="font-bold inline"> · </pre>REVERSED
              </span>
            )}
          </p>
        )) || (
          <p className="text-xl font-semibold">
            Fill in The Inventory Details <span className="text-error">*</span>
          </p>
        )}
        <GRNBulkUpload
          setErrorData={setErrorData}
          setErrorDialog={setErrorDialog}
          formik={formik}
          rows={rows}
          setRows={setRows}
          GRNview={GRNview}
          reversed={reversed}
          getValidDate={getValidDate}
          errorData={errorData}
          setData={setData}
          vehicleType={vehicleType}
          totalOrderedQuantity={totalOrderedQuantity}
          totalDispatchedQuantity={totalDispatchedQuantity}
          totalReceivedQuantity={totalReceivedQuantity}
          setReinitialize={setReinitialize}
        />
      </div>
      {reversed && <p className="mt-2">{formik.values.reversalReason}</p>}
      {errorData?.length > 0 && (
        <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
          <div className="flex gap-2">
            <img src={WarningIcon} />
            <p className="text-sm">
              File not uploaded correctly because of issues in file.
            </p>
          </div>
          <div
            onClick={async () => {
              await exportToXlsx();
            }}
            className="flex gap-2 cursor-pointer"
          >
            <img src={downloadFileIcon} />
            <p className="text-sm">Download File</p>
          </div>
        </div>
      )}

      <TableContainer
        component={Paper}
        className="mt-10"
        sx={{
          borderRadius: '16px',
          overflow: 'hidden',
          border: '1px solid rgba(75, 137, 200, 0.2) !important',
          boxShadow: '4px 4px 16px rgb(75 137 200 / 12%) !important',
          '& .MuiTable-root': {
            borderCollapse: 'unset'
          }
        }}
      >
        <Grid className="scroll-table">
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className="w-1/5 text-sm text-[#9DA3B3]">
                  <div className="flex items-center">
                    <span>Sku Code & Name</span>
                    {(sortOption === 2 && (
                      <img
                        src={SORT_ICON}
                        alt="Sort Icon"
                        className="ml-3 cursor-pointer"
                        onClick={sortProductsByName}
                      />
                    )) ||
                      (sortOption === 1 && (
                        <img
                          src={DEC_SORT_ICON}
                          alt="Sort Icon"
                          className="ml-3 cursor-pointer"
                          onClick={reverseProducts}
                        />
                      )) || (
                        <img
                          src={ASC_SORT_ICON}
                          alt="Sort Icon"
                          className="ml-3 cursor-pointer"
                          onClick={reverseProducts}
                        />
                      )}
                  </div>
                </TableCell>
                <TableCell className="w-1/5 text-sm text-[#9DA3B3]">
                  UoM
                </TableCell>
                <TableCell className="text-sm text-[#9DA3B3]">
                  Required
                </TableCell>
                <TableCell className="w-[15%] text-sm text-[#9DA3B3]">
                  Received
                </TableCell>

                {batchExpiry ? (
                  <TableCell className="text-sm w-1/10 text-[#9DA3B3]">
                    Remaining
                  </TableCell>
                ) : (
                  <TableCell className="w-[15%] text-sm text-[#9DA3B3]">
                    Damaged
                  </TableCell>
                )}
                {batchExpiry && <TableCell colSpan={2}></TableCell>}
                {!batchExpiry && (
                  <TableCell className="w-[15%] text-sm text-[#9DA3B3]">
                    Remaining
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, parentIndex) =>
                batchExpiry ? (
                  <GrnProductsTableRow
                    formik={formik}
                    key={parentIndex}
                    row={row}
                    parentIndex={parentIndex}
                    settleRemainingQty={(value) => setRemainingQty(value)}
                    rows={rows}
                    setRows={setRows}
                    batchInfo={productValues}
                    GRNview={GRNview}
                    addBatch={addBatch}
                    insertChildRowHandler={() =>
                      insertChildRowHandler(parentIndex, row)
                    }
                    resetProductSection={() => resetProductSection()}
                    returnBatches={returnBatches}
                  />
                ) : (
                  <O360GrnProductsTableRow
                    formik={formik}
                    key={parentIndex}
                    row={row}
                    parentIndex={parentIndex}
                    settleRemainingQty={(value) => setRemainingQty(value)}
                    rows={rows}
                    setRows={setRows}
                    batchInfo={productValues}
                    GRNview={GRNview}
                    addBatch={addBatch}
                    insertChildRowHandler={() =>
                      insertChildRowHandler(parentIndex, row)
                    }
                    resetProductSection={() => resetProductSection()}
                    returnBatches={returnBatches}
                    enableActions={
                      formik?.values?.Order?.moveType === 'TRANSFER'
                    }
                    reInitialize={reInitialize}
                    setReinitialize={setReinitialize}
                  />
                )
              )}
            </TableBody>
          </Table>
          <Table
            aria-label="collapsible table"
            className="bg-light w-full rounded-b-lg"
          >
            <TableBody>
              <TableRow className="flex justify-between items-center p-5">
                <div>
                  {GRNview ||
                    formik?.values?.Order?.moveType === 'TRANSFER' || (
                      <>
                        <Button
                          variant="secondary"
                          size="large"
                          label="+ New Product"
                          onClick={() => setOpen(true)}
                        />
                        <Popup
                          title={`Add a New Product`}
                          subTitle={`Received Qty: 0 · Good Qty: 0`}
                          open={open}
                          setOpen={setOpen}
                          content={
                            <AddProduct
                              addProduct={addNewProduct}
                              setOpen={setOpen}
                              companyId={formik?.values?.Order?.companyId}
                            />
                          }
                        />
                      </>
                    )}
                </div>
                <div className="flex">
                  <p className="text-xs mx-2">
                    Total Ordered:{' '}
                    <span className="text-base">
                      {decimalDisplay(totalOrderedQuantity)}
                    </span>
                    <span className="text-xs ml-1">units</span>
                  </p>
                  <p className="text-xs mx-2">
                    Total Received:{' '}
                    <span className="text-base">
                      {decimalDisplay(totalReceivedQuantity)}
                    </span>
                    <span className="text-xs ml-1">units</span>
                  </p>
                  <p className="text-xs mx-2">
                    Total Remaining:{' '}
                    <span className="text-base">
                      {totalOrderedQuantity - totalReceivedQuantity < 0
                        ? 0
                        : decimalDisplay(
                            totalOrderedQuantity - totalReceivedQuantity
                          )}
                    </span>
                    <span className="text-xs ml-1">units</span>
                  </p>
                </div>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </TableContainer>
    </>
  );
};

export default GrnProductsTable;

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import TextField from '../atoms/TextField';
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  List,
  ListItem,
  Paper,
  Popper
} from '@mui/material';
import { Search } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import MarkerIcon from '../../assets/icons/mapMarker.svg';
import { GoogleApiWrapper } from 'google-maps-react';
import Geocode from 'react-geocode';
import FormikTextField from './FormikTextField';
import { GOOGLE_MAP_API_KEY } from '../../constants';

const AddressTextField = ({
  onSelect,
  value,
  isFormik,
  name,
  formik,
  search,
  setSearch,
  disabled
}) => {
  const [open, setOpen] = useState(false);

  const searchOptions = {
    // componentRestrictions: { country: ['pk'] }
  };

  const anchorRef = useRef(null);

  const useStyles = makeStyles(() => ({
    popper: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      zIndex: 1300
    },
    paper: {
      pointerEvents: 'auto',
      width: '100%',
      border: '1px solid #E7E6E6',
      marginTop: '2px',
      maxHeight: '220px',
      boxShadow: '0px 8px 12px 0px #0000001F',
      overflowY: 'auto',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& input': {
        fontSize: '12px !important'
      }
    },
    heading: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#67718C',
      padding: '16px 8px',
      marginLeft: '16px'
    },
    option: {
      display: 'flex',
      gap: '16px',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer'
    }
  }));

  const classes = useStyles();

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setSearch(selectedAddress);
      onSelect(selectedAddress, latLng);
    } catch (error) {
      console.error('Error selecting place:', error);
    }
  };

  useEffect(() => {
    if (value) {
      setSearch(value);
    }
  }, [value]);

  const Pin = () => {
    return (
      <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.0013 2.33464C7.60964 2.33464 10.168 3.5013 10.168 6.6263C10.168 8.4263 8.73464 10.518 6.0013 12.7263C3.26797 10.518 1.83464 8.41797 1.83464 6.6263C1.83464 3.5013 4.39297 2.33464 6.0013 2.33464ZM6.0013 0.667969C3.2763 0.667969 0.167969 2.71797 0.167969 6.6263C0.167969 9.2263 2.10964 11.968 6.0013 14.8346C9.89297 11.968 11.8346 9.2263 11.8346 6.6263C11.8346 2.71797 8.7263 0.667969 6.0013 0.667969Z"
          fill="#0C6BD7"
        />
        <path
          d="M6.0013 4.83464C5.08464 4.83464 4.33464 5.58464 4.33464 6.5013C4.33464 7.41797 5.08464 8.16797 6.0013 8.16797C6.44333 8.16797 6.86725 7.99237 7.17981 7.67981C7.49237 7.36725 7.66797 6.94333 7.66797 6.5013C7.66797 6.05927 7.49237 5.63535 7.17981 5.32279C6.86725 5.01023 6.44333 4.83464 6.0013 4.83464ZM0.167969 15.668H11.8346V17.3346H0.167969V15.668Z"
          fill="#0C6BD7"
        />
      </svg>
    );
  };

  const reverseGeocoding = async (lat, lng) => {
    if (lat && lng) {
      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;
      return address;
    }
    return null;
  };
  const fetchUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const address = await reverseGeocoding(latitude, longitude);
          setSearch(address);
          onSelect(address, { lat: latitude, lng: longitude });
        },
        (error) => console.log(error)
      );
    } else {
      console.log('Geolocation not supported');
    }
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={search}
      onChange={(value) => {
        setSearch(value);
        setOpen(true);
      }}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <FormControl fullWidth ref={anchorRef}>
            {isFormik ? (
              <FormikTextField
                {...getInputProps({
                  placeholder: 'Enter here',
                  className: 'bg-white'
                })}
                name={name}
                formik={formik}
                InputProps={{
                  startAdornment: (
                    <IconButton onClick={() => {}}>
                      <Search className="mr-2" />
                    </IconButton>
                  ),
                  endAdornment: (
                    <IconButton
                      onClick={async () => {
                        await fetchUserLocation();
                      }}
                      className="ml-2"
                    >
                      {Pin()}
                    </IconButton>
                  )
                }}
                autocomplete="on"
                disabled={disabled}
              />
            ) : (
              <TextField
                {...getInputProps({
                  placeholder: 'Enter here',
                  className: 'bg-white'
                })}
                InputProps={{
                  startAdornment: (
                    <IconButton onClick={() => {}}>
                      <Search className="mr-2" />
                    </IconButton>
                  ),
                  endAdornment: (
                    <IconButton
                      onClick={async () => {
                        await fetchUserLocation();
                      }}
                      className="ml-2"
                    >
                      {Pin()}
                    </IconButton>
                  )
                }}
                autocomplete="on"
                disabled={disabled}
              />
            )}
          </FormControl>
          {suggestions && suggestions.length > 0 && (
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom-start"
              disablePortal
              className={classes.popper}
              modifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: true,
                  boundariesElement: 'viewport'
                }
              }}
            >
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Paper className={classes.paper}>
                  <List>
                    {suggestions.map((suggestion) => {
                      const style = {
                        cursor: 'pointer',
                        display: 'flex',
                        gap: '4px',
                        backgroundColor: suggestion.active
                          ? '#F1F8FF'
                          : '#ffffff'
                      };

                      return (
                        <ListItem
                          {...getSuggestionItemProps(suggestion, {
                            style
                          })}
                          key={suggestion.placeId}
                        >
                          <img src={MarkerIcon} />
                          {suggestion.description}
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY
})(AddressTextField);

import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function AddBtn(props) {
  const navigate = useNavigate();
  let className = 'listingAddBtn cursor-pointer';
  if (props.classes) {
    className += ' ' + props.classes;
  }
  const buttonClick = () => {
    if (props?.url) {
      navigate(props.url);
    } else {
      props?.handleClick();
    }
  };

  return (
    <button className={className} onClick={buttonClick}>
      <div className={props.iconClass}>
        {props.icon}
        <div className="icon-align-center">
          {props.image ? <img src={props.image} /> : null}
          {props.text}
        </div>
      </div>
    </button>
  );
}

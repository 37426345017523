import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  subQuarters,
  startOfQuarter,
  endOfQuarter
} from 'date-fns';
import { Popover, makeStyles } from '@material-ui/core';
import CalendarIcon from '../../assets/icons/calendarIcon.svg';
import moment from 'moment';
import Button from '../../core-components/atoms/Button';
// import clsx from 'clsx';

export const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  lastQuarterStartDate: startOfQuarter(subQuarters(new Date(), 1)),
  lastQuarterEndDate: endOfQuarter(subQuarters(new Date(), 1)),
  afterSevenDays: endOfDay(addDays(new Date(), 6)),
  afterThirtyDays: endOfDay(addDays(new Date(), 29))
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  }
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Last Week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek
    })
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth
    })
  },
  {
    label: 'Last Quarter',
    range: () => ({
      startDate: defineds.lastQuarterStartDate,
      endDate: defineds.lastQuarterEndDate
    })
  }
]);

export const shipmentRanges = createStaticRanges([
  {
    label: 'Next 7 Days',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.end
    })
  },
  {
    label: 'Next 30 Days',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth
    })
  },
  {
    label: 'Last Quarter',
    range: () => ({
      startDate: defineds.lastQuarterStartDate,
      endDate: defineds.lastQuarterEndDate
    })
  }
]);

const DateRangePickerSingleCalendar = ({
  startDate,
  endDate,
  initialStartDate = null,
  initialEndDate = null,
  updateRange,
  customStaticRanges = defaultStaticRanges,
  showMonthArrow = false,
  showDatesInButton = false,
  label = 'Date Range'
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
    reset();
  };

  const useStyles = makeStyles({
    dateRangeContainer: {
      boxShadow: '1px 4px 13px 0px #0B3E722E',
      border: '1px solid transparent',
      fontSize: '0.9rem',
      fontFamily: 'Roboto',
      borderRadius: '8px',
      padding: '8px',
      marginTop: '16px',
      '& .rdrDateRangePickerWrapper': {
        width: '100%',
        fontSize: '1rem',
        fontFamily: 'inherit',
        '& .rdrDefinedRangesWrapper': {
          borderRight: '1px dashed #C4C6CB',
          '& .rdrStaticRange': {
            borderBottom: 'none',
            fontSize: '0.9rem',
            fontFamily: 'Roboto'
          }
        },
        '& .rdrCalendarWrapper': {
          '& .rdrMonthAndYearPickers': {
            '& span select': {
              fontSize: '1rem !important',
              fontFamily: 'Roboto !important',
              fontWeight: 600
            }
          },
          '& .rdrWeekDays': {
            fontSize: '1rem !important',
            fontFamily: 'Roboto !important',
            fontWeight: 500,
            '& span': {
              color: '#202021'
            },
            '& .rdrWeekDay': {
              height: '45px !important',
              width: '45px !important'
              // flexBasis: 'unset'
            }
          },
          '& .rdrDays': {
            '& button': {
              color: '#202021 !important',
              margin: '0.3rem 0',
              // height: '40px',
              // width: '40px',
              '& span': {
                fontSize: '0.9rem',
                fontFamily: 'Roboto',
                fontWeight: 400
              },
              '&:has(.rdrInRange)': {
                backgroundColor: '#EBF5FF'
              },
              '&:has(.rdrEndEdge)': {
                backgroundColor: '#0C71D7',
                borderRadius: '50%',
                color: '#fff !important'
              },
              '&:has(.rdrStartEdge)': {
                backgroundColor: '#0C71D7',
                borderRadius: '50%',
                color: '#fff !important'
              }
            },
            '& .rdrDay': {
              // height: '45px',
              // width: '45px',
              color: 'inherit',
              '& .rdrEndEdge': {
                display: 'none',
                '& + .rdrDayNumber': {
                  '& span': {
                    color: 'inherit'
                  }
                }
              },
              '& .rdrStartEdge': {
                display: 'none',
                '& + .rdrDayNumber': {
                  '& span': {
                    color: 'inherit'
                  }
                }
              },
              '& .rdrInRange': {
                display: 'none'
              },
              '& .rdrDayNumber': {
                color: 'inherit',
                '& span': {
                  color: 'inherit',
                  '&:after': {
                    display: 'none'
                  }
                }
              }
            }
          }
        }
      },
      width: '500px'
    },
    applyButton: {
      textTransform: 'none',
      backgroundColor: '#0C71D7',
      padding: '4px 24px 4px 24px',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#0C71D7',
        color: '#fff'
      }
    },
    cancelBtn: {
      color: '#0C71D7',
      padding: '4px 24px 4px 24px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  });

  useEffect(() => {
    if (startDate && endDate) {
      setState({
        selection: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key: 'selection'
        }
      });
    } else if (startDate) {
      setState({
        selection: {
          startDate: new Date(startDate),
          endDate: null,
          key: 'selection'
        }
      });
    } else if (endDate) {
      setState({
        selection: {
          startDate: null,
          endDate: new Date(endDate),
          key: 'selection'
        }
      });
    } else {
      setState({
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      });
    }
  }, [startDate, endDate]);

  const reset = () => {
    if (startDate && endDate) {
      setState({
        selection: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key: 'selection'
        }
      });
    } else {
      setState({
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      });
    }
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        icon={<img src={CalendarIcon} className="mr-[6px]" />}
        className={'p-2 ml-2 h-8 rounded border-solid'}
        iconClass="h-[14px] w-[14px] mr-1"
        variant="transparent"
        overrideSize={true}
        labelClass="font-medium text-xs"
        label={
          showDatesInButton && startDate && endDate
            ? `${moment(startDate).format('DD-MM-YYYY')} - ${moment(
                endDate
              ).format('DD-MM-YYYY')}`
            : label
        }
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsCalendarOpen(true);
        }}
      />
      <Popover
        open={isCalendarOpen}
        anchorEl={anchorEl}
        onClose={handleCalendarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          horizontal: 'left'
        }}
        style={{
          boxShadow: '0px 4px 13px 0px #0B3E722E'
        }}
        PaperProps={{
          style: {
            boxShadow: '0px 4px 13px 0px #0B3E722E'
          }
        }}
        disablePortal
      >
        <div
          className={classes.dateRangeContainer}
          onMouseEnter={(e) => e.preventDefault()}
        >
          <DateRangePicker
            onChange={(item) => {
              setState({ ...state, ...item });
            }}
            months={1}
            minDate={new Date('2021-01-01')}
            maxDate={new Date('2030-12-31')}
            ranges={[state.selection]}
            staticRanges={customStaticRanges}
            inputRanges={[]}
            showDateDisplay={false}
            editableDateInputs={false}
            showMonthArrow={showMonthArrow}
            rangeColors={['#EBF5FF']}
          />
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
          >
            <Button
              variant="text"
              className={classes.cancelBtn}
              onClick={handleCalendarClose}
              label="Close"
              size="medium"
            />
            <Button
              variant="tertiary"
              onClick={() => {
                updateRange({
                  selection: {
                    endDate: initialEndDate,
                    startDate: initialStartDate
                  }
                });
                reset();
                setIsCalendarOpen(false);
              }}
              label="Reset"
              size="medium"
            />
            <Button
              variant="primary"
              size="large"
              label="Apply"
              onClick={() => {
                updateRange({
                  selection: {
                    endDate: state.selection.endDate,
                    startDate: state.selection.startDate
                  }
                });
                reset();
                setIsCalendarOpen(false);
              }}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default DateRangePickerSingleCalendar;

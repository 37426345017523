/*eslint-disable*/
import React, { useContext } from 'react';
import clsx from 'clsx';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter
} from '@mui/material';
import {
  dateFormatSlashDivider,
  decimalDisplay,
  SharedContext
} from '../../utils/common';
import PrintStyles from './PrintStyles';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';

const ProductDetail = ({
  order,
  orderType,
  totalOrderedQuantity,
  totalDispatchedQuantity,
  totalReceivedQuantity
}) => {
  const classes = PrintStyles();
  const { settings } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const topColumnsGeneric = [
    {
      id: 'skuCode',
      label: 'Sku Code & Name',
      minWidth: 'auto',
      align: 'left',
      width: '250px',
      className: classes.topColumn,
      style: classes.value,
      format: (product) =>
        `${product?.Product?.name || '-'} - ${
          product?.Product?.description || '-'
        }`
    }
  ];

  if (isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)) {
    topColumnsGeneric.push(
      {
        id: 'uom',
        label: 'UoM',
        minWidth: 'auto',
        align: 'left',
        width: '160px',
        className: classes.topColumn,
        style: classes.value,
        format: (product) =>
          product?.SecondaryUOM?.name || product?.Product?.UOM?.name || '-'
      },
      {
        id: 'batchNumber',
        label: 'Batch',
        minWidth: 'auto',
        align: 'left',
        className: classes.topColumn,
        width: '100px',
        style: classes.value,
        format: (product, batch) => batch?.batchNumber || '-'
      },
      {
        id: 'manufacturingDate',
        label: 'Mfg. & EXP. Date',
        minWidth: 'auto',
        align: 'left',
        width: '220px',
        className: classes.topColumn,
        style: classes.value,
        format: (product, batch) =>
          `${
            batch?.manufacturingDate
              ? dateFormatSlashDivider(batch?.manufacturingDate)
              : '-'
          } - ${
            batch?.expiryDate ? dateFormatSlashDivider(batch?.expiryDate) : '-'
          }`
      },
      {
        id: 'weight',
        label: `Weight (${settings?.weight?.symbol || 'Kg'})`,
        minWidth: 'auto',
        align: 'center',
        width: '160px',
        className: classes.topColumn,
        style: classes.value,
        format: (product, batch) => {
          if (batch?.GRNGroupId) {
            return batch?.receivedQuantity
              ? decimalDisplay(
                  batch?.receivedQuantity * (product?.Product?.weight || 0)
                )
              : '-';
          } else {
            return batch?.quantity
              ? decimalDisplay(
                  batch?.quantity * (product?.Product?.weight || 0)
                )
              : '-';
          }
        }
      },
      {
        id: 'orderedQuantity',
        label: 'Ordered Qty',
        minWidth: 'auto',
        width: '160px',
        align: 'center',
        className: clsx(classes.topColumn, classes.centerText),
        style: classes.value,
        format: (product, batch, x, GroupBatches, conversionFactor) =>
          GroupBatches?.length <= 1
            ? decimalDisplay(product?.orderedQuantity / conversionFactor) ?? '-'
            : decimalDisplay(batch?.orderedQuantity / conversionFactor) ?? '-'
      }
    );
  } else {
    topColumnsGeneric.push(
      {
        id: 'uom',
        label: 'UoM',
        minWidth: 'auto',
        align: 'left',
        width: '160px',
        className: classes.topColumn,
        style: classes.value,
        format: (product) =>
          product?.SecondaryUOM?.name || product?.Product?.UOM?.name || '-'
      },
      {
        id: 'weight',
        label: `Weight (${settings?.weight?.symbol || 'Kg'})`,
        minWidth: 'auto',
        align: 'center',
        width: '160px',
        className: classes.topColumn,
        style: classes.value,
        format: (product, batch) => {
          if (batch?.GRNGroupId) {
            return batch?.receivedQuantity
              ? decimalDisplay(
                  batch?.receivedQuantity * (product?.Product?.weight || 0)
                )
              : '-';
          } else {
            return batch?.quantity
              ? decimalDisplay(
                  batch?.quantity * (product?.Product?.weight || 0)
                )
              : '-';
          }
        }
      },
      {
        id: 'orderedQuantity',
        label: 'Ordered Qty',
        minWidth: 'auto',
        width: '160px',
        align: 'center',
        className: clsx(classes.topColumn, classes.centerText),
        style: classes.value,
        format: (product, batch, x, GroupBatches, conversionFactor) =>
          GroupBatches?.length <= 1
            ? decimalDisplay(product?.orderedQuantity / conversionFactor) ?? '-'
            : decimalDisplay(batch?.orderedQuantity / conversionFactor) ?? '-'
      }
    );
  }

  const topColumnsGDN = [
    {
      id: 'actualQuantity',
      label: 'Dispatched Qty',
      minWidth: 'auto',
      width: '160px',
      align: 'center',
      className: clsx(classes.topColumn, classes.centerText),
      style: classes.value,
      format: (product, batch, og, groupBatches, conversionFactor) =>
        decimalDisplay(batch?.actualDispatchedQuantity / conversionFactor) ??
        '-'
    }
  ];

  const topColumnsGRN = [
    {
      id: 'receivedQuantity',
      label: 'Received Qty',
      minWidth: 'auto',
      align: 'center',
      width: '160px',
      className: clsx(classes.topColumn, classes.centerText),
      style: classes.value,
      format: (product, batch, og, groupBatches, conversionFactor) =>
        decimalDisplay(batch?.receivedQuantity / conversionFactor) ?? 0
    },
    {
      id: 'damagedQuantity',
      label: 'Damaged Qty',
      minWidth: 'auto',
      align: 'center',
      width: '160px',
      className: clsx(classes.topColumn, classes.centerText),
      style: classes.value,
      format: (product, batch, og, groupBatches, conversionFactor) =>
        decimalDisplay(batch?.recoverableDamageQuantity / conversionFactor) ?? 0
    }
  ];

  const bottomColumnsGeneric = [
    {
      id: 'skuCode',
      label: 'Sku Code & Name',
      minWidth: 'auto',
      align: 'left',
      width: '250px',
      className: classes.topColumn,
      style: classes.value,
      format: () => ''
    },
    {
      id: 'uom',
      label: 'Uom',
      minWidth: 'auto',
      align: 'left',
      width: '160px',
      className: classes.topColumn,
      style: classes.value,
      format: () => ''
    }
  ];
  if (isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)) {
    bottomColumnsGeneric.push(
      {
        id: 'batchNumber',
        label: 'Batch',
        minWidth: 'auto',
        align: 'left',
        className: classes.topColumn,
        width: '100px',
        style: classes.value,
        format: () => ''
      },
      {
        id: 'manufacturingDate',
        label: 'Mfg. & EXP. Date',
        minWidth: 'auto',
        align: 'left',
        width: '220px',
        className: classes.topColumn,
        style: classes.value,
        format: () => ''
      },
      {
        id: 'weight',
        label: `Weight (${settings?.weight?.symbol || 'Kg'})`,
        minWidth: 'auto',
        align: 'center',
        width: '160px',
        className: clsx(classes.topColumn, classes.centerText),
        style: classes.valueMid,
        format: () => 'Total Count'
      },
      {
        id: 'orderedQuantity',
        label: 'Ordered Qty',
        minWidth: 'auto',
        width: '160px',
        align: 'center',
        className: clsx(classes.topColumn, classes.centerText),
        style: classes.value,
        format: (product, og, conversionFactor) =>
          decimalDisplay(product?.orderedQuantity / conversionFactor) ?? '-'
      }
    );
  } else {
    bottomColumnsGeneric.push(
      {
        id: 'weight',
        label: `Weight (${settings?.weight?.symbol || 'Kg'})`,
        minWidth: 'auto',
        align: 'center',
        width: '160px',
        className: clsx(classes.topColumn, classes.centerText),
        style: classes.valueMid,
        format: () => 'Total Count'
      },
      {
        id: 'orderedQuantity',
        label: 'Ordered Qty',
        minWidth: 'auto',
        width: '160px',
        align: 'center',
        className: clsx(classes.topColumn, classes.centerText),
        style: classes.value,
        format: (product) => decimalDisplay(product?.orderedQuantity) ?? '-'
      }
    );
  }

  const bottomColumnsGDN = [
    {
      id: 'actualQuantity',
      label: 'Dispatched Qty',
      minWidth: 'auto',
      width: '160px',
      align: 'center',
      className: clsx(classes.topColumn, classes.centerText),
      style: classes.value,
      format: (product) =>
        decimalDisplay(
          product?.GDNGroupBatches?.reduce((acc, obj) => {
            return +acc + (+obj?.actualDispatchedQuantity || 0);
          }, 0) ?? '-'
        )
    }
  ];

  const bottomColumnsGRN = [
    {
      id: 'receivedQuantity',
      label: 'Received Qty',
      minWidth: 'auto',
      align: 'center',
      width: '160px',
      className: clsx(classes.topColumn, classes.centerText),
      style: classes.value,
      format: (product) =>
        product ? decimalDisplay(calculateTotalReceivedQuantity(product)) : 0
    },
    {
      id: 'damagedQuantity',
      label: 'Damaged Qty',
      minWidth: 'auto',
      align: 'center',
      width: '160px',
      className: clsx(classes.topColumn, classes.centerText),
      style: classes.value,
      format: (product) =>
        product ? decimalDisplay(calculateTotalDamagedQuantity(product)) : 0
    }
  ];

  const topColumns = topColumnsGeneric.concat(
    orderType === 'grn' ? topColumnsGRN : topColumnsGDN
  );

  const bottomColumn = bottomColumnsGeneric.concat(
    orderType === 'grn' ? bottomColumnsGRN : bottomColumnsGDN
  );

  const calculateTotalReceivedQuantity = (product) => {
    let totalReceivedQuantity = 0;
    product?.GRNGroupBatches?.map((batch) => {
      totalReceivedQuantity += batch.receivedQuantity;
    });
    return totalReceivedQuantity;
  };

  const calculateTotalDamagedQuantity = (product) => {
    let totalReceivedQuantity = 0;
    product?.GRNGroupBatches?.map((batch) => {
      totalReceivedQuantity += batch.recoverableDamageQuantity;
    });
    return totalReceivedQuantity;
  };

  const getTotalWeight = (products) => {
    return products?.reduce((totalWeight, product) => {
      const gdnBatch = product?.GRNGroupBatches;
      const grnBatch = product?.GDNGroupBatches;
      const GroupBatches =
        gdnBatch?.length > 0
          ? gdnBatch
          : grnBatch?.length > 0
          ? grnBatch
          : null;
      if (GroupBatches) {
        return (
          totalWeight +
          GroupBatches.reduce((batchWeight, batch) => {
            if (batch?.GRNGroupId) {
              return (
                batchWeight + batch?.receivedQuantity * product?.Product?.weight
              );
            } else {
              return batchWeight + batch?.quantity * product?.Product?.weight;
            }
          }, 0)
        );
      } else {
        return totalWeight;
      }
    }, 0);
  };

  return (
    <Box className={classes.productDetailSectionContainer}>
      <Box className={classes.productDetailSectionHeading}>
        Product Detail(s)
      </Box>
      <Box displayPrint="block">
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.tableClass}
          >
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRow}>
                {topColumns.map((column, idx) => {
                  return (
                    <TableCell
                      key={idx}
                      className={column.className}
                      align={column.center}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width
                      }}
                    >
                      {column.id === 'receivedQuantity' ? (
                        <div>Received Qty</div>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {order?.products?.map((product, productIndex) => {
                const gdnBatch = product?.GRNGroupBatches || [];
                const grnBatch = product?.GDNGroupBatches || [];
                const GroupBatches = gdnBatch?.length > 0 ? gdnBatch : grnBatch;
                const orderProductGroup = order?.OrderProductGroups
                  ? order?.OrderProductGroups[productIndex]
                  : null;

                const conversionFactor =
                  product?.SecondaryUOM?.id || product?.secondaryUomId
                    ? product?.Product?.ProductUOMs?.find(
                        (u) =>
                          u.uomId ==
                          (product?.SecondaryUOM?.id || product?.secondaryUomId)
                      ).conversionFactor
                    : 1;

                const rowsForProduct = GroupBatches.map((batch, batchIndex) => {
                  return (
                    <React.Fragment key={`${productIndex}-${batchIndex}`}>
                      <TableRow
                        className={classes.tableRow}
                        key={`${productIndex}-${batchIndex}`}
                      >
                        {topColumns.map((column, columnIndex) => (
                          <TableCell
                            key={columnIndex}
                            className={column.style}
                            align={column.align}
                            style={{
                              borderRadius: 0
                            }}
                          >
                            {column.format
                              ? column.format(
                                  product,
                                  batch,
                                  orderProductGroup,
                                  GroupBatches,
                                  conversionFactor
                                )
                              : product[column.id] || '-'}
                          </TableCell>
                        ))}
                      </TableRow>
                    </React.Fragment>
                  );
                });

                GroupBatches?.length > 1 &&
                  rowsForProduct.push(
                    <TableRow
                      className={classes.tableRow}
                      key={`bottom-${productIndex}`}
                    >
                      {bottomColumn.map((column, columnIndex) => {
                        return (
                          <TableCell
                            key={columnIndex}
                            className={column.style}
                            align={column.align}
                            style={{
                              borderRadius: 0
                            }}
                          >
                            {column.format
                              ? column.format(
                                  product,
                                  orderProductGroup,
                                  conversionFactor
                                )
                              : product[column.id] || '-'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );

                return (
                  <React.Fragment key={productIndex}>
                    {rowsForProduct}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          {order?.products?.length > 1 && (
            <TableFooter>
              <TableRow
                className={classes.tableMidContainer}
                style={{
                  minWidth: '100%',
                  overflow: 'hidden'
                }}
              >
                <TableCell
                  className={classes.topColumnWrapper}
                  style={{
                    minWidth: 'auto',
                    width: '20%'
                  }}
                >
                  <div className={classes.totalRow}>
                    <span>Total Unique Sku:</span>
                    <span className={classes.topColumnWrapperBold}>
                      {decimalDisplay(order?.products?.length)}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  className={classes.topColumnWrapper}
                  style={{ minWidth: 'auto', width: '1%' }}
                >
                  |
                </TableCell>
                <TableCell
                  className={classes.topColumnWrapper}
                  style={{ minWidth: 'auto', width: '27%' }}
                >
                  <div className={classes.totalRow}>
                    <span>Total Order Qty:</span>
                    <span className={classes.topColumnWrapperBold}>
                      {decimalDisplay(totalOrderedQuantity)}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  className={classes.topColumnWrapper}
                  style={{ minWidth: 'auto', width: '1%' }}
                >
                  |
                </TableCell>
                <TableCell
                  className={classes.topColumnWrapper}
                  style={{ minWidth: 'auto', width: '20%' }}
                >
                  <div className={classes.totalRow}>
                    <span>Total Weight:</span>
                    <span className={classes.topColumnWrapperBold}>
                      {decimalDisplay(getTotalWeight(order?.products))}
                    </span>
                    <span className={classes.topColumnWrapperSimple}>
                      {settings?.weight?.symbol || 'Kg'}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  className={classes.topColumnWrapper}
                  style={{ minWidth: 'auto', width: '1%' }}
                >
                  |
                </TableCell>
                <TableCell
                  className={classes.topColumnWrapper}
                  style={{
                    minWidth: 'auto',
                    width: '30%'
                  }}
                >
                  <div className={classes.totalRow}>
                    <span>
                      Total{' '}
                      {orderType === 'grn'
                        ? `Received Qty: `
                        : `Dispatched Qty: `}
                    </span>
                    <span className={classes.topColumnWrapperBold}>
                      {orderType === 'grn'
                        ? decimalDisplay(totalReceivedQuantity)
                        : decimalDisplay(totalDispatchedQuantity)}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ProductDetail;

import { Box, Grid, List, ListItem, Tab, Tabs } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { Heading, Title } from '../../core-components/atoms/Text';
import { makeStyles, Tooltip } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import CategoryListView from './ListViews/Category/CategoryListView';
import BrandListView from './ListViews/Brand/BrandListView';
import ProductListView from './ListViews/Product/ProductListView';
import { useLocation, useParams } from 'react-router-dom';
import { exportToCSV, SharedContext } from '../../utils/common';
import BullkUpload from './ListViews/components/BullkUpload';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { ORGANIZATION_TYPES } from '../../constants';
import PopupButton from '../../core-components/molecules/PopupButton';
import DownloadIcon from '../../assets/icons/downloadIconNew.svg';
import DownloadBulkIcon from '../../assets/icons/download-template-light.svg';
import EditColumnIcon from '../../assets/icons/build_circle.svg';
import BulkFileAttacher from './ListViews/components/BulkFileAttacher';
import Button from '../../core-components/atoms/Button';
import { toaster } from '../../utils/toaster';
import BackIcon from '../../assets/icons/chevronLeft.svg';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import { checkPermission } from '../../utils/auth';
import SearchBar from '../../atomicComponents/SearchBar';
import * as XLSX from 'xlsx';
import FLAGS from '../../constants/featureFlags';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const useStyles = makeStyles(() => ({
  nodeTitleText: {
    fontFamily: 'Roboto !important',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.048px',
    textTransform: 'uppercase',
    color: '#696F80'
  },
  searchBar: {
    maxWidth: '252px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

const ProductsView = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { isFeatureEnabled } = useFeatureFlags();
  const {
    setCurrentPageTitle,
    organizationType,
    sidebar,
    setAPILoader,
    currentUser
  } = useContext(SharedContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [editColumnOpen, setEditColumnOpen] = useState(false);
  const [bulkUploadPopup, setBulkUploadPopup] = useState(false);
  const [bulkUploadData, setBulkUploadData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [isManufacturer, setIsManufacturer] = useState(null);

  const { uid } = useParams();
  const [search, setSearch] = useState('');
  const [companyId] = useState(uid || null);
  const [company, setCompany] = useState(null);
  const [bulkUploadResponse, setBulkUploadResponse] = useState(null);
  const [checkValidations, setCheckValidations] = useState(true);
  const [correctBulkUploadData, setCorrectBulkUploadData] = useState(null);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [value, setValue] = React.useState('products');
  const [editColumns, setEditColumns] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toolTipClasses = useStylesBootstrap();

  useEffect(() => {
    if (organizationType) {
      setIsManufacturer(organizationType === ORGANIZATION_TYPES.MANUFACTURER);
    }
  }, [organizationType]);

  useEffect(() => {
    setCurrentPageTitle('Products');
  }, [isManufacturer]);

  useEffect(() => {
    if (companyId) {
      getCompany();
    }
  }, [companyId, sidebar]);

  const getCompany = async () => {
    const companyData = await API.get(`companies/${companyId}`);
    setCompany(companyData?.company);
    setCurrentPageTitle(
      <div className={clsx('flex items-center gap-2')}>
        <img
          src={BackIcon}
          alt="back"
          onClick={() => {
            navigate('/administration/products/');
          }}
          className="mr-2"
        />
        <Heading variant={'card'}>{companyData?.company?.name}</Heading>
      </div>
    );
  };

  useEffect(() => {
    setCurrentPageTitle(
      <div className="flex gap-2 items-center">
        <p className="flex items-center">
          {location.state?.code
            ? `${location.state?.code} - ${location.state?.name}`
            : location.state?.name}
        </p>
      </div>
    );
  }, []);

  const handleDownloadTemplate = async () => {
    const title = `Template for categories. brands and products`;
    const header = [
      'Product Code',
      'Product Name',
      'Product Type',
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Supplier']
        : []),
      'Category',
      'Brand',
      'Barcode',
      'HS Code',
      'Primary UoM',
      'Secondary UoM',
      'Conversion Rate',
      'UoM per Pallet',
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Shelf Life (In Days)']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Product Life (In Days)']
        : []),
      'Height',
      'Length',
      'Breadth',
      'Volume',
      'Lead Time',
      'Weight',
      'Min Ordered Quantity',
      'Max Ordered Quantity',
      'Stock Handling Type',
      'Re Order Point',
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Cost Price']
        : []),
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Selling Price']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['SKU requires Expiry Tracking']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['MRP Enabled']
        : []),
      'Additional Information'
    ];

    const exportSortedHalls = [
      {
        name: 'COKE ZERO',
        description: 'product of Coco Cola(sample product)',
        productType: 'Raw Material',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { supplier: 'GT Store' }
          : {}),
        category: 'Drinks',
        brand: 'CocaCola',
        barcode: 'XXXXX XXXX',
        hsCode: 'XXXXX XXXX',
        uom: 'Bottle',
        secondaryUom: 'Can',
        conversionFactor: 2,
        perPallet: '10',
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { shelfLife: '2' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { productLife: '2' }
          : {}),
        height: '1.4',
        length: '1.4',
        breadth: '1.4',
        dimensionsCBM: '4.2',
        leadTime: '2',
        weight: '2',
        minOrderedQuantity: '10',
        maxOrderedQuantity: '200',
        stockHandlingType: 'FIFO',
        reOrderPoint: '1',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { costPrice: '10' }
          : {}),
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { sellingPrice: '200' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { batchEnabled: 'FALSE' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { MRPEnabled: 'FALSE' }
          : {}),
        additonalInformation: ''
      },
      {
        name: 'COKE ZERO',
        description: 'product of Coco Cola(sample product)',
        productType: 'Raw Material',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { supplier: 'GT Store' }
          : {}),
        category: 'Drinks',
        brand: 'CocaCola',
        barcode: 'XXXXX XXXX',
        hsCode: 'XXXXX XXXX',
        uom: 'Bottle',
        secondaryUom: 'Can',
        conversionFactor: 2,
        perPallet: '10',
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { shelfLife: '2' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { productLife: '2' }
          : {}),
        height: '1.4',
        length: '1.4',
        breadth: '1.4',
        dimensionsCBM: '4.2',
        leadTime: '2',
        weight: '2',
        minOrderedQuantity: '10',
        maxOrderedQuantity: '200',
        stockHandlingType: 'FIFO',
        reOrderPoint: '1',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { costPrice: '10' }
          : {}),
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { sellingPrice: '200' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { batchEnabled: 'FALSE' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { MRPEnabled: 'FALSE' }
          : {}),
        additonalInformation: ''
      }
    ];
    exportToCSV(header, exportSortedHalls, title);
  };

  const bulkUpload = (data) => {
    setBulkUploadData(data);
    setBulkUploadPopup(true);
    setBulkUploadOpen(false);
    setSelectedFile(null);
  };

  const createBulkUpload = async () => {
    if (checkValidations) {
      try {
        setAPILoader(true);
        let response;
        const arr = [];
        bulkUploadData?.map((value) => {
          let data = {
            name: value['Product Code'] ? value['Product Code'] : ' ',
            description: value['Product Name'] ? value['Product Name'] : ' ',
            productType: value['Product Type'] ? value['Product Type'] : ' ',
            ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
              ? { supplier: value['Supplier'] ? value['Supplier'] : ' ' }
              : {}),
            category: value['Category'] ? value['Category'] : ' ',
            brand: value['Brand'] ? value['Brand'] : ' ',
            barcode: value['Barcode'] ? value['Barcode'] : ' ',
            hsCode: value['HS Code'] ? value['HS Code'] : ' ',
            uom: value['Primary UoM'] ? value['Primary UoM'] : ' ',
            secondaryUom: value['Secondary UoM'] ? value['Secondary UoM'] : '',
            conversionFactor: value['Conversion Rate']
              ? value['Conversion Rate']
              : ' ',
            perPallet: value['UoM per Pallet'] ? value['UoM per Pallet'] : ' ',
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  shelfLife: value['Shelf Life (In Days)']
                    ? value['Shelf Life (In Days)']
                    : ' '
                }
              : { shelfLife: '' }),
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  productLife: value['Product Life (In Days)']
                    ? value['Product Life (In Days)']
                    : ' '
                }
              : { productLife: '' }),
            height: value['Height'] ? value['Height'] : ' ',
            length: value['Length'] ? value['Length'] : ' ',
            breadth: value['Breadth'] ? value['Breadth'] : ' ',
            dimensionsCBM: value['Volume'] ? value['Volume'] : 0,
            leadTime: value['Lead Time'] ? value['Lead Time'] : ' ',
            weight: value['Weight'] ? value['Weight'] : ' ',
            minOrderedQuantity: value['Min Ordered Quantity']
              ? value['Min Ordered Quantity']
              : ' ',
            maxOrderedQuantity: value['Max Ordered Quantity']
              ? value['Max Ordered Quantity']
              : ' ',
            stockHandlingType: value['Stock Handling Type']
              ? value['Stock Handling Type']
              : ' ',
            reOrderPoint: value['Re Order Point']
              ? value['Re Order Point']
              : ' ',
            ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
              ? { costPrice: value['Cost Price'] ? value['Cost Price'] : ' ' }
              : { costPrice: ' ' }),
            ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
              ? {
                  sellingPrice: value['Selling Price']
                    ? value['Selling Price']
                    : ' '
                }
              : { sellingPrice: ' ' }),
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  batchEnabled: value['SKU requires Expiry Tracking']
                    ? value['SKU requires Expiry Tracking']
                    : ' '
                }
              : { batchEnabled: 'FALSE' }),
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  MRPEnabled: value['MRP Enabled'] ? value['MRP Enabled'] : ' '
                }
              : { MRPEnabled: 'FALSE' }),
            additonalInformation: value['Additional Information']
              ? value['Additional Information']
              : ' '
          };
          arr.push(data);
        });
        let url = `products/validate-bulk`;
        if (!isManufacturer) {
          url = `products/validate-bulk?companyId=${companyId}`;
        }
        response = await API.post(url, { products: arr });
        await setBulkUploadResponse(response);
        if (response?.validationErrors?.length <= 0) {
          await dataBulkUpload(arr);
        } else {
          let newArrData = [];
          let newArr = [];
          response?.validProducts?.map((value) => {
            let data = {
              ['Product Code']: value?.name,
              ['Product Name']: value?.description,
              ['Product Type']: value?.productType,
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['Supplier']: value?.supplier }
                : {}),
              ['Category']: value?.category,
              ['Brand']: value?.brand,
              ['Barcode']: value?.barcode,
              ['HS Code']: value?.hsCode,
              ['Primary UoM']: value?.uom,
              ['Secondary UoM']: value?.secondaryUom,
              ['Conversion Rate']: value?.conversionFactor,
              ['UoM per Pallet']: value?.perPallet,
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { ['Shelf Life (In Days)']: value?.shelfLife }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { ['Product Life (In Days)']: value?.productLife }
                : {}),
              ['Height']: value?.height,
              ['Length']: value?.length,
              ['Breadth']: value?.breadth,
              ['Volume']: value?.dimensionsCBM,
              ['Lead Time']: value?.leadTime,
              ['Weight']: value?.weight,
              ['Min Ordered Quantity']: value?.minOrderedQuantity,
              ['Max Ordered Quantity']: value?.maxOrderedQuantity,
              ['Stock Handling Type']: value?.stockHandlingType,
              ['Re Order Point']: value?.reOrderPoint,
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['Cost Price']: value?.costPrice }
                : {}),
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['Selling Price']: value?.sellingPrice }
                : {}),
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['SKU requires Expiry Tracking']: value?.batchEnabled }
                : {}),
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['MRP Enabled']: value?.MRPEnabled }
                : {}),
              ['Additional Information']: value?.additonalInformation
            };
            const newData = {
              name: value?.name ? value?.name : ' ',
              description: value?.description ? value?.description : ' ',
              productType: value?.productType ? value?.productType : ' ',
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { supplier: value?.supplier ? value?.supplier : ' ' }
                : {}),
              category: value?.category ? value?.category : ' ',
              brand: value?.brand ? value?.brand : ' ',
              barcode: value?.barcode ? value?.barcode : ' ',
              hsCode: value?.hsCode ? value?.hsCode : ' ',
              uom: value?.uom ? value?.uom : ' ',
              secondaryUom: value?.secondaryUom ? value?.secondaryUom : ' ',
              conversionFactor: value?.conversionFactor
                ? value?.conversionFactor
                : ' ',
              perPallet: value?.perPallet ? value?.perPallet : ' ',
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { shelfLife: value?.shelfLife ? value?.shelfLife : ' ' }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { productLife: value?.productLife ? value?.productLife : ' ' }
                : {}),
              height: value?.height ? value?.height : ' ',
              length: value?.length ? value?.length : ' ',
              breadth: value?.breadth ? value?.breadth : ' ',
              dimensionsCBM: value?.dimensionsCBM ? value?.dimensionsCBM : ' ',
              leadTime: value?.leadTime ? value?.leadTime : ' ',
              weight: value?.weight ? value?.weight : ' ',
              minOrderedQuantity: value?.minOrderedQuantity
                ? value?.minOrderedQuantity
                : ' ',
              maxOrderedQuantity: value?.maxOrderedQuantity
                ? value?.maxOrderedQuantity
                : ' ',
              stockHandlingType: value?.stockHandlingType
                ? value?.stockHandlingType
                : ' ',
              reOrderPoint: value?.reOrderPoint ? value?.reOrderPoint : ' ',
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { costPrice: value?.costPrice ? value?.costPrice : ' ' }
                : {}),
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? {
                    sellingPrice: value?.sellingPrice
                      ? value?.sellingPrice
                      : ' '
                  }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? {
                    batchEnabled: value?.batchEnabled
                      ? value?.batchEnabled
                      : ' '
                  }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? {
                    MRPEnabled: value?.MRPEnabled ? value?.MRPEnabled : ' '
                  }
                : {}),
              additonalInformation: value?.additonalInformation
                ? value?.additonalInformation
                : ' '
            };
            newArr.push(data);
            newArrData.push(newData);
          });
          setBulkUploadData(newArr);
          setCorrectBulkUploadData(newArrData);
          setCheckValidations(false);
        }
      } catch (error) {
        onError(error.message);
      } finally {
        setAPILoader(false);
      }
    } else {
      await dataBulkUpload(correctBulkUploadData);
    }
  };

  const dataBulkUpload = async (newArrData) => {
    setAPILoader(true);
    let uploadUrl = `products/bulk`;
    if (!isManufacturer) {
      uploadUrl = `products/bulk?companyId=${companyId}`;
    }
    const res = await API.post(uploadUrl, {
      products: newArrData
    });
    if (res) {
      setBulkUploadResponse(null);
      setBulkUploadData(null);
      setBulkUploadPopup(false);
      setCheckValidations(true);
      setCorrectBulkUploadData(null);
      await getCategories();
      await getBrands();
      await getProducts();
      toaster('success', `Bulk upload created successfully.`);
    }
    setAPILoader(false);
  };

  const getCategories = async () => {
    try {
      const cols = ['name'];
      const res = await API.get('categories', {
        params: {
          page: 1,
          limit: 10,
          search: '',
          columns: cols,
          company_id: companyId
        }
      });
      const newArr = res?.data;
      setCategories([...newArr]);
    } catch (error) {
      onError(error);
    }
  };

  const getBrands = async () => {
    try {
      const cols = ['name'];
      const res = await API.get(`brands`, {
        params: {
          page: 1,
          limit: 10,
          search: '',
          columns: cols,
          company_id: companyId
        }
      });
      const newArr = res?.data;
      setBrands([...newArr]);
    } catch (error) {
      onError(error);
    }
  };

  const getProducts = async () => {
    try {
      const res = await API.get(`products`, {
        params: {
          page: 1,
          limit: 10,
          search: '',
          companyId
        }
      });
      const newArr = res?.data;
      setProducts([...newArr]);
    } catch (error) {
      onError(error);
    }
  };

  const handleExportSection = async () => {
    let url = `products/export`;
    if (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER) {
      url = `products/export?companyId=${companyId}`;
    }
    const halls = await API.get(url);
    const sortedHalls = [...halls.data];
    const exportSortedHalls = sortedHalls.map((products) => [
      products?.name || ' ',
      products?.description || ' ',
      products?.productType || ' ',
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? [products?.productType || ' ']
        : []),
      products?.Category?.name || ' ',
      products?.Brand?.name || ' ',
      products?.barcode || ' ',
      products?.hsCode || ' ',
      products?.UOM?.name || ' ',
      products?.height || ' ',
      products?.length || ' ',
      products?.breadth || ' ',
      products?.weight || ' ',
      products?.dimensionsCBM || ' ',
      products?.minOrderedQuantity || ' ',
      products?.maxOrderedQuantity || ' ',
      products?.leadTime || ' ',
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? [products?.shelfLife || ' ']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? [products?.productLife || ' ']
        : []),
      products?.stockHandlingType,
      products?.reOrderPoint,
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? [products?.costPrice]
        : []),
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? [products?.sellingPrice]
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? [products?.batchEnabled]
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? [products?.MRPEnabled]
        : []),
      products?.isActive,
      products?.additionalInformation
    ]);

    const exportSortedUoMs = sortedHalls.map((products) => [
      products?.name || ' ',
      products?.description || ' ',
      products?.ProductUOMs.map((val) => val?.UOM?.name) || ' ',
      products?.ProductUOMs.map((val) => val?.conversionFactor) || ' '
    ]);
    const exportDataUoMs = [
      ['Product Code', 'Product Name', 'Secondary UoM(s)', 'Conversion Rate'],
      ...exportSortedUoMs
    ];

    const exportData = [
      [
        'Product Code',
        'Product Name',
        'Product Type',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? ['Supplier']
          : []),
        'Category',
        'Brand',
        'Barcode',
        'HS Code',
        'Primary UoM',
        'Height',
        'Length',
        'Breadth',
        'Weight',
        'Volume',
        'Min Ordered Quantity',
        'Max Ordered Quantity',
        'Lead Time',
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? ['Shelf Life (In Days)']
          : []),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? ['Product Life (In Days)']
          : []),
        'Stock Handling Type',
        'Re Order Point',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? ['Cost Price']
          : []),
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? ['Selling Price']
          : []),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? ['SKU requires Expiry Tracking']
          : []),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? ['MRP Enabled']
          : []),
        'Active/Inactive',
        'Additional Information'
      ],
      ...exportSortedHalls
    ];
    const ws = XLSX.utils.aoa_to_sheet(exportData);
    const wsUoM = XLSX.utils.aoa_to_sheet(exportDataUoMs);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Product(s)');
    XLSX.utils.book_append_sheet(wb, wsUoM, 'UoM(s)');
    XLSX.writeFile(wb, `${halls?.filename}.xlsx`);
  };

  useEffect(() => {
    if (editColumns?.length <= 0) {
      getEditColumns();
    }
  }, []);

  const getEditColumns = async () => {
    try {
      const res = await API.get(`user-column-preferences/Product`);
      if (!res) {
        let data = [
          { name: 'Weight & Volume', status: false },
          { name: 'MoQ(s)', status: false }
        ];
        organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
          data.push({ name: 'Cost & Selling', status: false });
        setEditColumns(data);
      } else {
        let data = [
          { name: 'Weight & Volume', status: true },
          { name: 'MoQ(s)', status: true }
        ];
        organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
          data.push({ name: 'Cost & Selling', status: true });
        let columns = res?.payload?.columns;
        data.forEach((item) => {
          item.status = columns?.includes(item.name);
        });
        setEditColumns(data);
      }
    } catch (error) {
      onError(error);
    }
  };

  async function onEditColumn(value, name) {
    setEditColumnOpen(false);
    let newArr = editColumns.map((x) =>
      x.name === name ? { ...x, status: value } : x
    );
    setEditColumns(newArr);
    const columns = newArr.reduce((acc, x) => {
      if (x.status) {
        acc.push(x.name);
      }
      return acc;
    }, []);
    setAPILoader(true);
    let uploadUrl = `user-column-preferences/Product`;
    await API.put(uploadUrl, { payload: { columns: columns } });
    toaster('success', 'User Preference Updated Successfully!');
    setAPILoader(false);
  }

  return (
    <>
      <BullkUpload
        bulkUploadResponse={bulkUploadResponse}
        company={company}
        data={bulkUploadData}
        setBulkUploadResponse={setBulkUploadResponse}
        setCheckValidations={setCheckValidations}
        bulkUploadPopup={bulkUploadPopup}
        setBulkUploadPopup={setBulkUploadPopup}
        createBulkUpload={createBulkUpload}
      />
      {(!uid || company) && (
        <div>
          <Box
            sx={{ width: '100%' }}
            className={'bg-dark200 border-dark300 border-b px-4'}
          >
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where selection follows focus"
              selectionFollowsFocus
            >
              <Tab
                className="capitalize p-4"
                label={<Title variant={'sm'}>{`Products`}</Title>}
                value={'products'}
              />
              <Tab
                className="capitalize p-4"
                label={<Title variant={'sm'}>{`Categories & Brand`}</Title>}
                value={'categories'}
              />
            </Tabs>
          </Box>
          <div className="scroll-my-3 mr-5 mx-10">
            <BullkUpload
              bulkUploadResponse={bulkUploadResponse}
              company={company}
              data={bulkUploadData}
              setBulkUploadResponse={setBulkUploadResponse}
              setCheckValidations={setCheckValidations}
              bulkUploadPopup={bulkUploadPopup}
              setBulkUploadPopup={setBulkUploadPopup}
              createBulkUpload={createBulkUpload}
            />
            <div className="display-flex mb-10 mt-4 mr-[20px] justify-between items-center">
              <Title variant={'lg'}>
                {value === 'products' ? 'Products' : 'Categories & Brand Info'}
              </Title>
              <div className={'display-flex flex items-center'}>
                <div className={'w-64'}>
                  <SearchBar
                    className={classes.searchBar}
                    iconClass="h-4 w-4"
                    setSearchValue={setSearch}
                    placeholder="Search"
                    searchKeyword={search}
                  />
                </div>
                {value === 'products' && (
                  <PopupButton
                    open={editColumnOpen}
                    setOpen={setEditColumnOpen}
                    btn={
                      <Button
                        label="Edit Columns"
                        className={
                          'py-2 px-2 ml-2 h-8 rounded border-solid border-primaryBlue'
                        }
                        variant="transparent"
                        overrideSize={true}
                        icon={
                          <img src={EditColumnIcon} className={`mr-[2px]`} />
                        }
                        labelClass="font-medium text-xs"
                        iconClass="h-[14px] w-[14px] mr-1"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditColumnOpen(!editColumnOpen);
                        }}
                      />
                    }
                    content={
                      <List>
                        <ListItem className="flex items-center">
                          <div className={'mx-2 border-b pr-12'}>
                            <span
                              className={
                                'text-[10px]/[14px] font-normal text-dark400'
                              }
                            >
                              Fixed Columns
                            </span>
                            <Title variant={'sm'} className={'py-3'}>
                              SKU Code & Name
                            </Title>
                            <Title variant={'sm'} className={'py-3'}>
                              Brand & Category
                            </Title>
                            <Title variant={'sm'} className={'py-3'}>
                              UoM(s)
                            </Title>
                          </div>
                        </ListItem>
                        {editColumns?.filter((value) => value.status)?.length >
                          0 && (
                          <ListItem className="flex items-center">
                            <div className={'mx-2 border-b pr-12'}>
                              <span
                                className={
                                  'text-[10px]/[14px] font-normal text-dark400'
                                }
                              >
                                Active Columns
                              </span>
                              {editColumns
                                ?.filter((value) => value.status)
                                ?.map((value, key) => {
                                  return (
                                    <div
                                      className={'flex items-center'}
                                      key={key}
                                    >
                                      <Checkbox
                                        checked={value.status}
                                        onChange={(event, checked) => {
                                          onEditColumn(checked, value.name);
                                        }}
                                        color="primary"
                                        inputProps={{
                                          'aria-label': 'secondary checkbox'
                                        }}
                                      />
                                      <Title
                                        variant={'sm'}
                                        className={'py-3 ml-1'}
                                      >
                                        {value.name}
                                      </Title>
                                    </div>
                                  );
                                })}
                            </div>
                          </ListItem>
                        )}
                        {editColumns?.filter((value) => !value.status)?.length >
                          0 && (
                          <ListItem className="flex items-center">
                            <div className={'mx-2 border-b pr-12'}>
                              <span
                                className={
                                  'text-[10px]/[14px] font-normal text-dark400'
                                }
                              >
                                Available Columns
                              </span>
                              {editColumns
                                ?.filter((value) => !value.status)
                                ?.map((value, key) => {
                                  return (
                                    <div
                                      className={'flex items-center'}
                                      key={key}
                                    >
                                      <Checkbox
                                        checked={value.status}
                                        onChange={(event, checked) => {
                                          onEditColumn(checked, value.name);
                                        }}
                                        color="primary"
                                        inputProps={{
                                          'aria-label': 'secondary checkbox'
                                        }}
                                      />
                                      <Title
                                        variant={'sm'}
                                        className={'py-3 ml-1'}
                                      >
                                        {value.name}
                                      </Title>
                                    </div>
                                  );
                                })}
                            </div>
                          </ListItem>
                        )}
                      </List>
                    }
                  />
                )}
                {checkPermission(currentUser, 'OPS_PRODUCT_EXPORT') &&
                  value === 'products' && (
                    <Tooltip
                      arrow
                      title="Download Products"
                      aria-label="add"
                      placement="top"
                      classes={toolTipClasses}
                    >
                      <Button
                        label="Products"
                        className={
                          'py-2 px-2 ml-2 h-8 rounded border-solid border-primaryBlue'
                        }
                        variant="transparent"
                        overrideSize={true}
                        icon={<img src={DownloadIcon} className={`mr-[2px]`} />}
                        labelClass="font-medium text-xs"
                        iconClass="h-[14px] w-[14px] mr-1"
                        onClick={async (e) => {
                          e.preventDefault();
                          await handleExportSection();
                          setBulkUploadOpen(false);
                        }}
                      />
                    </Tooltip>
                  )}
                {checkPermission(currentUser, 'OPS_PRODUCT_BULK_UPLOAD') && (
                  <PopupButton
                    open={bulkUploadOpen}
                    setOpen={setBulkUploadOpen}
                    btn={
                      <Button
                        label="Bulk Upload"
                        className={
                          'py-2 px-2 ml-2 h-8 rounded border-solid border-oasisPrimary500'
                        }
                        variant="transparent"
                        overrideSize={true}
                        labelClass="font-medium text-xs text-oasisPrimary500"
                        onClick={(e) => {
                          e.preventDefault();
                          setBulkUploadOpen(!bulkUploadOpen);
                        }}
                      />
                    }
                    content={
                      <List className="py-4">
                        <ListItem
                          className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                          onClick={async (e) => {
                            e.preventDefault();
                            await handleDownloadTemplate();
                            setBulkUploadOpen(false);
                          }}
                        >
                          <img src={DownloadBulkIcon} className={`mr-[2px]`} />
                          <span>Download Template</span>
                        </ListItem>
                        <BulkFileAttacher
                          bulkUpload={bulkUpload}
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                        />
                      </List>
                    }
                  />
                )}
                {checkPermission(currentUser, 'OPS_PRODUCT_CREATE') &&
                  value === 'products' && (
                    <Tooltip
                      arrow
                      title="Add Products"
                      aria-label="add"
                      placement="top"
                      classes={toolTipClasses}
                    >
                      <Button
                        label="Add Products"
                        className={
                          'py-2 px-2 ml-2 h-8 rounded border-solid border-oasisPrimary500'
                        }
                        variant="primary"
                        overrideSize={true}
                        labelClass="font-medium text-xs text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenAddProduct(true);
                        }}
                      />
                    </Tooltip>
                  )}
              </div>
            </div>
            {value !== 'products' ? (
              <div className="display-flex gap-4 mr-[20px]">
                <Grid container className="max-w-[50%]">
                  <CategoryListView
                    searchValue={search}
                    categories={categories}
                    setCategories={setCategories}
                    companyId={companyId}
                  />
                </Grid>
                <Grid container className="max-w-[50%]">
                  <BrandListView
                    searchValue={search}
                    brands={brands}
                    setBrands={setBrands}
                    companyId={companyId}
                  />
                </Grid>
              </div>
            ) : (
              <div className="display-flex gap-4 mr-[20px]">
                <Grid container>
                  <ProductListView
                    searchValue={search}
                    products={products}
                    setProducts={setProducts}
                    companyId={companyId}
                    companyName={company?.name}
                    openAddProduct={openAddProduct}
                    editColumns={editColumns}
                    setOpenAddProduct={setOpenAddProduct}
                  />
                </Grid>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsView;

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

const ProductGroupSubTable = ({ orderProducts }) => {
  const HEADER_CELLS = [
    'SKU NAME',
    'SKU CODE',
    'UOM',
    'ORDERED QTY',
    'AVAILABLE QTY',
    'HOLD QTY'
  ];

  return (
    <Table
      size="small"
      className="bg-light border border-dashed border-black rounded-md py-3"
    >
      <TableHead className="border-none rounded-lg">
        {HEADER_CELLS.map((content) => (
          <TableCell key={content} className="py-2 text-xs border-none">
            {content}
          </TableCell>
        ))}
      </TableHead>
      <TableBody className="border-none">
        {orderProducts.map((row) => (
          <TableRow className="group" key={row.id}>
            <TableCell className="py-2  border-none">
              {row?.Product?.description}
            </TableCell>
            <TableCell className="py-2  border-none">
              {row?.Product?.name}
            </TableCell>
            <TableCell className="py-2  border-none">
              {row?.Product?.UOM?.name}
            </TableCell>
            <TableCell className="py-2  border-none">{row?.quantity}</TableCell>
            <TableCell className="py-2  border-none">
              {row?.Product?.Inventories?.reduce(
                (acc, i) => acc + i?.availableQuantity,
                0
              )}
            </TableCell>
            <TableCell className="py-2  border-none">
              {row?.Product?.Inventories?.reduce(
                (acc, i) => acc + i?.holdQuantity,
                0
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProductGroupSubTable;

import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  detailText: {
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '0.9rem',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: '#000000',
    overflow: 'auto',
    wordWrap: 'break-word',
    fontFamily: 'Roboto',
    '@media only screen and (max-width: 1200px) and (min-width:992px)': {
      fontSize: '12px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      fontSize: '10px !important'
    }
  }
}));

export default function DetailText({ text, style, active }) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.detailText}
      style={{ ...style, color: active && '#0C71D7' }}
    >
      {text}
    </Typography>
  );
}

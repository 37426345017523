import React from 'react';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainContainer: {
    background: '#FEF3D7',
    borderRadius: '8px',
    padding: '10px 20px',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: '10px 4px'
    }
  },
  heading: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '12px',
    letterSpacing: '0.0025em',
    color: '#BC7100'
  },
  text: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    letterSpacing: '0.0025em',
    color: '#BC7100'
  },
  pocDetail: {
    marginTop: '10px'
  }
}));

export default function Address(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid item container className={classes.mainContainer}>
        <Grid item container>
          <Grid item xs={4}>
            <Typography className={classes.heading}>Address</Typography>
          </Grid>
          <Grid item xs={8}>
            <Tooltip title={props.pickupLocation}>
              <Typography className={classes.text}>
                {props?.pickupLocation?.length > 40
                  ? `${props?.pickupLocation?.substring(0, 40)}...`
                  : `${props?.pickupLocation}`}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item container className={classes.pocDetail}>
          <Grid item xs={4}>
            <Typography className={classes.heading}>POC</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.text}>
              {props.name} - {props.pickupLocationPhone}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

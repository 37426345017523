import { useContext, useEffect, useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Pagination, Grid } from '@mui/material';
import { debounce } from 'lodash';
import { SharedContext } from '../../../utils/common';
import { onboardingColumns } from './onboardingColumns';
import onboardingStyles from './onboardingStyles';
import API from '../../../libs/axios';
import CustomerOnboardingActionMenu from './CustomerOnboardingActionMenu';
import { DEBOUNCE_CONST, RECORD_LIMIT } from '../../../Config';
import SearchBar from '../../../atomicComponents/SearchBar';
import { onError } from '../../../libs/errorLib';

const CustomerOnboarding = () => {
  const { currentPageTitle, setCurrentPageTitle, setAPILoader, apiLoader } =
    useContext(SharedContext);
  const classes = onboardingStyles();
  const [onboardingRequests, setOnboardingRequests] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPagesCount] = useState(0);

  const columns = [
    ...onboardingColumns,
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <p className="w-100 parentActions detailText">Actions</p>
      ),
      renderCell: (params) => {
        return (
          <CustomerOnboardingActionMenu
            onboardingRequest={params?.row}
            getRequests={getOnboardingRequests}
          />
        );
      }
    }
  ];

  useEffect(() => {
    let title = 'O-Nexus Customer Onboarding';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  const getOnboardingRequests = async (page, searchValue, columns) => {
    try {
      setAPILoader(true);
      const res = await API.get('/customer-onboardings', {
        params: {
          page: page,
          search: searchValue,
          limit: RECORD_LIMIT,
          columns
        }
      });
      setOnboardingRequests(res?.data);
      setPagesCount(res?.pages);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const getDelayedRequests = useCallback(
    debounce(
      (page, searchValue) => {
        let columns = ['firstname', 'lastname', 'phone', 'email'];
        getOnboardingRequests(page, searchValue, columns);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedRequests(page, searchValue);
  }, [page, searchValue]);

  useEffect(() => {
    getOnboardingRequests();
  }, []);

  return (
    <div className={classes.root}>
      <SearchBar
        width={classes.searchBar}
        placeholder="Search here"
        setSearchValue={(e) => {
          setPage(1);
          setSearchValue(e);
        }}
      />
      <div className={classes.dataGridContainer}>
        <Grid className="oNexus-customerOnboarding">
          <DataGrid
            className="onexus-Grid"
            autoHeight={true}
            rows={onboardingRequests}
            columns={columns}
            loading={apiLoader}
            sx={{
              '.MuiDataGrid-columnSeparator': {
                display: 'none'
              },
              '&.MuiDataGrid-root': {
                border: 'none'
              }
            }}
          />
        </Grid>
      </div>
      <div className={classes.paginationContainer}>
        <Pagination
          component="div"
          shape="rounded"
          count={pageCount}
          color="primary"
          page={page}
          onChange={(e, page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default CustomerOnboarding;

import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import useStyles from '../views/orders/makeStyles';

export default function BulkUploadCancelBtn(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} className={classes.paddingTop12}>
      <Button
        variant="contained"
        className={classes.cancelButton}
        onClick={() => navigate(props?.path)}
        fullWidth
      >
        {props?.text}
      </Button>
    </Grid>
  );
}

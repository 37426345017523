import * as Yup from 'yup';

const AddShipmentValidation = Yup.object().shape({
  pickupDatetime: Yup.date()
    .required('Pickup date and time is required')
    .typeError('Pickup date and time must be a valid date')
    .test(
      'is-before-dropoff',
      'Pickup date and time must be before dropoff date and time',
      function (value) {
        const { dropoffDatetime } = this.parent;
        return value < dropoffDatetime;
      }
    ),
  dropoffDatetime: Yup.date()
    .required('Dropoff date and time is required')
    .typeError('Dropoff date and time must be a valid date')
    .test(
      'is-after-pickup',
      'Dropoff date and time must be after pickup date and time',
      function (value) {
        const { pickupDatetime } = this.parent;
        return value > pickupDatetime;
      }
    )
});

export default AddShipmentValidation;

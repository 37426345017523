import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Paragraph, Title } from '../../../core-components/atoms/Text';
import {
  decimalDisplay,
  getCurrencySymbol,
  SharedContext
} from '../../../utils/common';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import BarSkelton from '../components/BarSkelton';
import EmptyBarComponent from '../components/EmptyBarComponent';

const TopCustomers = ({ nodeIds, endDate, startDate }) => {
  const { organization } = useContext(SharedContext);
  const [isLoading, setIsLoading] = useState(false);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getDelayedCustomers(startDate, endDate, nodeIdsMemo);
  }, [startDate, endDate, nodeIdsMemo]);

  const getDelayedCustomers = useCallback(
    debounce(
      async (startDate, endDate, nodeIds) => {
        getCustomers(startDate, endDate, nodeIds);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getCustomers = async (startDate, endDate, nodeIds) => {
    try {
      const data = await API.get(`intelligence/top-companies`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          nodeIds: nodeIds,
          type: 'CUSTOMER'
        }
      });
      setCustomers(data);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getColor = (index) =>
    index % 2 === 0 ? 'bg-[#0B1940]' : 'bg-[#0C6BD7]';
  const maxValue = Math.max(...customers.map((c) => c?.amount));

  return (
    <div className="p-4">
      <Title variant={'md'}>Top Customers</Title>
      <ul className={'mt-4'}>
        {isLoading ? (
          <BarSkelton />
        ) : customers.length <= 0 ? (
          <EmptyBarComponent />
        ) : (
          customers.map((customer, index) => (
            <li key={index} className="mb-4">
              <div className={'flex justify-between'}>
                <Title variant={'sm'}>
                  {index + 1}. {customer?.['Order.Company.name']}
                </Title>
                <Paragraph variant={'sm'} className={'text-oasisPrimary500'}>
                  {`${getCurrencySymbol(
                    organization?.defaultCurrency
                  )} ${decimalDisplay(customer?.['amount'])}`}
                </Paragraph>
              </div>
              <div className="flex-grow bg-dark200 rounded-sm mt-1">
                <div
                  className={clsx(
                    ` text-xs leading-none h-[10px] text-center text-white rounded`,
                    `${getColor(index)}`
                  )}
                  style={{ width: `${(customer?.amount / maxValue) * 100}%` }}
                />
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default TopCustomers;

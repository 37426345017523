import { useNavigate } from 'react-router-dom';
import useStyles from '../taskStyles';
import { TaskStats } from './TaskStats';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Tab, TableContainer, Tabs } from '@material-ui/core';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';
import { ActiveIcon, DraftIcon } from '../icons';
import { checkPermission } from '../../../utils/auth';
import Button from '../../../core-components/atoms/Button';
import { MdAdd } from 'react-icons/md';
import { Title } from '../../../core-components/atoms/Text';
import { TasksList } from './TasksList';
import { PendingOrders } from './PendingOrders';
import clsx from 'clsx';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

export default function TaskManagementView() {
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const classes = useStyles();
  const tabSelectedClass =
    'border-b-[4px] border-solid border-t-0 border-r-0 border-l-0 border-primary text-primary';
  const tabClass = 'font-medium text-sm normal-case';

  const { currentPageTitle, setCurrentPageTitle, currentUser } =
    useContext(SharedContext);

  const [taskStats, setTasksStat] = useState([]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let title = 'Task Management';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    getTasksStats();
  }, []);

  const getTasksStats = async () => {
    try {
      let url = `tasks/stats`;
      let res = await API.get(url);
      setTasksStat(res?.data);
    } catch (err) {
      onError(err);
    }
  };

  return (
    <Paper className={classes.root}>
      <TaskStats taskStats={taskStats || []} />
      <Grid className={classes.taskManagementContainer}>
        <TableContainer className={classes.container}>
          <div className="flex items-center justify-between  border-b-[1px] border-solid border-t-0 border-r-0 border-l-0 border-[#E1E5F0]">
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
            >
              <Tab
                label={
                  <div className="flex gap-2 items-center">
                    <ActiveIcon fill={value === 0 ? '#0C71D7' : '#4B5369'} />
                    <Title variant={'sm'}>Task</Title>
                  </div>
                }
                className={clsx(
                  `${tabClass} && ${value === 0 && tabSelectedClass}`
                )}
                active={value === 0}
              />
              <Tab
                label={
                  <div className="flex gap-2">
                    <DraftIcon fill={value === 1 && '#0C71D7'} />
                    <Title variant={'sm'}>Pending Orders</Title>
                  </div>
                }
                className={`${tabClass} ${value === 1 && tabSelectedClass}`}
                active={value === 1}
              />
            </Tabs>
            <div className="flex gap-3">
              {isFeatureEnabled(FLAGS.CYCLE_COUNT) &&
              checkPermission(currentUser, 'OPS_ORDER_CREATE') ? (
                <Button
                  variant="secondary"
                  label="Create Cycle Count"
                  size="large"
                  icon={<MdAdd size={20} className="mr-2" />}
                  onClick={() => navigate('/routing/cycle-count')}
                />
              ) : null}
              {isFeatureEnabled(FLAGS.PICKING) && (
                <Button
                  variant="primary"
                  label={
                    <>
                      <span className="text-xs"></span> Generate Picking
                    </>
                  }
                  size="large"
                  onClick={() => navigate('/routing/custom-picking')}
                ></Button>
              )}
            </div>
          </div>
          <div className="mt-8">
            {value === 0 && <TasksList getTasksStats={getTasksStats} />}
            {value === 1 && <PendingOrders />}
          </div>
        </TableContainer>
      </Grid>
    </Paper>
  );
}

import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Grid, Paper, Box, IconButton } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Close } from '@mui/icons-material';
import DetailPageTitle from '../../atomicComponents/DetailPageTitle';
import popupStyles from './popupStyles';

const PasswordResetSuccessPopup = (props) => {
  const classes = popupStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const handleSubmit = () => {
    navigate('/login');
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '25%' } }}
    >
      <Grid
        container
        className={classes.backdropGrid}
        justifyContent="flex-start"
      >
        <DialogTitle>
          <DetailPageTitle text="Password Reset" />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.close}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper elevation={0} className={classes.paperStyleSmall}>
            <Box mt={3}>
              <label>Your password has been reset successfully.</label>
            </Box>
            <Box mt={2} className={classes.btn}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Log In
              </Button>
            </Box>
          </Paper>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default PasswordResetSuccessPopup;

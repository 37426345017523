/*eslint-disable*/
import { useParams } from 'react-router';
import CircularProgressWithLabel from '../../../core-components/atoms/CircularProgressWithLabel';
import LinearProgressBar from '../../../core-components/atoms/LinerProgressBar';
import { decimalDisplay, SharedContext } from '../../../utils/common';
import { useContext } from 'react';

const ProgressDetails = ({
  occupied,
  total,
  extraQuantity = 0,
  type,
  products,
  dockName,
  formik,
  view
}) => {
  const { oid, sid } = useParams();

  const [recoverableQuantity, unsellableQuantity] = products?.reduce(
    (acc, product) => {
      const [recoverable, unsellable] = product[`${type}GroupBatches`]?.reduce(
        (a, v) => [
          a[0] + (+v?.recoverableDamageQuantity || 0),
          a[1] + (+v?.unsellableDamageQuantity || 0)
        ],
        [0, 0]
      ) || [0, 0];

      const fixedRecoverable = parseFloat(recoverable.toFixed(3));
      const fixedUnsellable = parseFloat(unsellable.toFixed(3));

      return [acc[0] + fixedRecoverable, acc[1] + fixedUnsellable];
    },
    [0, 0]
  ) || [0, 0];

  const { settings } = useContext(SharedContext);

  return (
    <div className="p-7 text-base font-medium shadow-md border-l-2 border-solid border-[#E8ECF1] border-y-0 border-r-0 h-full">
      <p>{type} Details</p>
      <div className="flex my-6">
        <CircularProgressWithLabel
          value={Math.floor((+occupied / (total || 100)) * 100)}
        />
        <div className="flex items-center ml-5">
          <div>
            <p className="text-xs text-headerCell">
              {type === 'GRN' ? 'RECEIVED' : 'DISPATCHED'} QTY
            </p>
            <p className="text-base">
              {decimalDisplay(occupied - extraQuantity)} /{' '}
              {decimalDisplay(total)}
            </p>
          </div>
        </div>
      </div>
      <div className="py-5 border-2 border-solid border-[#E8ECF1] border-x-0">
        <div className="flex justify-between">
          <p className="text-xs font-semibold">Order ID</p>
          <p className="text-xs font-normal">{oid}</p>
        </div>
        <div className="flex justify-between my-5">
          <p className="text-xs font-semibold">Shipment ID</p>
          <p className="text-xs font-normal">{sid}</p>
        </div>
        {dockName && (
          <div className="flex justify-between">
            <p className="text-xs font-semibold">Dock</p>
            <p className="text-xs font-normal">{dockName}</p>
          </div>
        )}
      </div>
      <p className="text-xs font-semibold mt-5">SKU Details</p>
      {products?.map((product) => {
        const occQty = (
          product[`${type}GroupBatches`]?.reduce(
            (acc, obj) =>
              acc +
              (+obj?.[
                type === 'GRN' ? 'receivedQuantity' : 'actualDispatchedQuantity'
              ] || 0),
            0
          ) || 0
        ).toFixed(3);

        return (
          <div className="py-3 border-b-2 border-dashed border-[#E8ECF1] border-x-0 border-t-0">
            <p className="text-xs mb-3 font-normal">{product.Product.name}</p>
            <div className="flex justify-between items-center">
              <LinearProgressBar
                value={(occQty * 100) / (product.orderedQuantity || 1)}
              />
              <p className="text-xs font-normal ml-2 whitespace-nowrap">
                {product.orderedQuantity - occQty < 0
                  ? 0
                  : decimalDisplay(product.orderedQuantity - occQty)}{' '}
                Left
              </p>
            </div>
          </div>
        );
      })}
      <div className="py-5 border-b-2 border-solid border-[rgb(232,236,241)] border-x-0 border-t-0">
        <div className="flex justify-between">
          <p className="text-xs font-normal">
            Total {type === 'GRN' ? 'Received' : 'Dispatched'} Quantity
          </p>
          <p className="text-xs font-normal">{decimalDisplay(occupied)}</p>
        </div>
        <div className="flex justify-between my-5">
          <p className="text-xs font-normal">Total Damaged</p>
          <p className="text-xs font-normal text-warning">
            {decimalDisplay(recoverableQuantity)}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-xs font-normal">Total Good</p>
          <p className="text-xs font-normal">
            {decimalDisplay(
              occupied - recoverableQuantity - unsellableQuantity
            )}
          </p>
        </div>
      </div>
      <div className="py-5 border-b-2 border-solid border-[#E8ECF1] border-x-0 border-t-0">
        <div className="flex justify-between">
          <p className="text-xs">Total Left</p>
          <p className="text-xs font-normal">
            {total - occupied + extraQuantity < 0
              ? 0
              : decimalDisplay(total - occupied + extraQuantity)}
          </p>
        </div>
      </div>
      {view && (
        <>
          <div className="flex justify-between my-5">
            <p className="text-xs">Vehicle and Driver Details</p>
          </div>
          <div className="flex justify-between my-5">
            <p className="text-xs font-normal">Driver Name</p>
            <p className="text-xs font-normal">
              {formik.values?.shipment?.driverName ||
                formik.values.shipment?.ShipmentLegs?.[0]?.Driver?.name}
            </p>
          </div>
          <div className="flex justify-between my-5">
            <p className="text-xs font-normal">Driver Number</p>
            <p className="text-xs font-normal">
              {formik.values?.shipment?.driverPhone ||
                formik.values.shipment?.ShipmentLegs?.[0]?.Driver?.phone}
            </p>
          </div>
          {type === 'GRN' ? (
            <div className="flex justify-between my-5">
              <p className="text-xs font-normal">Vehicle Type</p>
              <p className="text-xs font-normal">
                {formik.values?.shipment?.vehicleType?.CarMake?.name ||
                  formik.values.shipment?.ShipmentLegs?.[0]?.Car?.CarMake
                    ?.name}{' '}
                {formik.values?.shipment?.vehicleType?.CarModel?.name ||
                  formik.values.shipment?.ShipmentLegs?.[0]?.Car?.CarModel
                    ?.name}
              </p>
            </div>
          ) : (
            <div className="flex justify-between my-5">
              <p className="text-xs font-normal">Seal Number</p>
              <p className="text-xs font-normal">
                {formik.values.shipment?.sealNumber}
              </p>
            </div>
          )}
          <div className="flex justify-between my-5">
            <p className="text-xs font-normal">Vehicle Number</p>
            <p className="text-xs font-normal">
              {formik.values.shipment?.vehicleNumber ||
                formik.values.shipment?.ShipmentLegs?.[0]?.Vehicle
                  ?.registrationNumber}
            </p>
          </div>
          <div className="flex justify-between my-5">
            <p className="text-xs font-normal">Weight</p>
            <p className="text-xs font-normal">
              {formik.values.shipment?.weight || 0}{' '}
              {` ${settings?.weight?.symbol || 'Kg'}`}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default ProgressDetails;

/*eslint-disable*/
import { CloseOutlined } from '@material-ui/icons';
import { Modal, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { DEBOUNCE_CONST } from '../../Config';
import InwardsAnimation from '../../assets/animations/Inwards.json';
import LogisticsAnimation from '../../assets/animations/Logistics.gif';
import OutboundAnimation from '../../assets/animations/Outbound.json';
import ReturnAnimation from '../../assets/animations/Return.json';
import TransferAnimation from '../../assets/animations/Transfer.json';
import BackIcon from '../../assets/icons/chevronLeft.svg';
import PAPERCLIP_ICON from '../../assets/icons/paperclip.svg';
import Button from '../../core-components/atoms/Button';
import Popup from '../../core-components/atoms/Popup';
import { DialogueConfirmationOpen } from '../../core-components/molecules/DialogueConfirmation';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { SharedContext } from '../../utils/common';
import { toaster } from '../../utils/toaster';
import { upload } from '../../utils/upload';
import OrderFormBody from './OrderFormBody';
import OrderTypeBox from './OrderTypeBox';
import ProductTable from './ProductsTable';
import { AnimationType, OrderSubTypes, OrderTypeMap } from './constants';
import { ORGANIZATION_TYPES } from '../../constants';
import NodeForm from '../supplyChainNetwork/AddNodeForm';
import { nodeTypes } from '../supplyChainNetwork/constants';
import { nodeSchema } from '../supplyChainNetwork/validationSchemas/nodeSchema';
import { checkPermission } from '../../utils/auth';
import ProductTableMobile from './ProductsTableMobile';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import { makeStyles } from '@material-ui/styles';
import mixpanel from 'mixpanel-browser';

const {
  INBOUND_ORDER_CREATION,
  OUTBOUND_ORDER_CREATION,
  TRANSFER_ORDER_CREATION,
  LOGISTICS_ORDER_CREATION,
  PURCHASE_ORDER_CREATION,
  SALES_ORDER_CREATION,
  SALES_RETURN_ORDER_CREATION
} = FLAGS;

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start'
  },
  topPaperScrollBody: {
    verticalAlign: 'top'
  }
});

function AddOrder() {
  const { oid } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const [orderTypeSelectionView, setOrderTypeSelectionView] = useState(!oid);
  const [companies, setCompanies] = useState([]);
  const [internalNodes, setInternalNodes] = useState([]);
  const [secondaryInternalNodes, setSecondaryInternalNodes] = useState([]);
  const [externalNodes, setExternalNodes] = useState([]);
  const [secondaryExternalNodes, setSecondaryExternalNodes] = useState([]);
  const [timer, setTimer] = useState(null);
  const [orderSummaryOpen, setOrderSummaryOpen] = useState(false);
  const [addNodeView, setAddNodeView] = useState(false);
  const [companySearch, setCompanySearch] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [nodeSearch, setNodeSearch] = useState('');
  const [salesOrders, setSalesOrders] = useState([]);
  const [selectedReferenceOrder, setSelectedReferenceOrder] = useState(null);
  const [orderTypeIcons, setOrderTypeIcons] = useState([]);
  const [isInventoryLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [createOrder, setCreateOrder] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [prefillLastOrderDetails, setPrefillLastOrderDetails] = useState(false);
  const {
    setAPILoader,
    setCurrentPageTitle,
    organizationType,
    currentUser,
    subdomain,
    organization,
    settings
  } = useContext(SharedContext);

  const handleClick = () => {
    clearTimeout(timer);
    let newTimer;
    newTimer = setTimeout(() => {
      setOrderTypeSelectionView(false);
    }, 1500);
    setTimer(newTimer);
  };

  useEffect(() => {
    if (organizationType) {
      const typeData =
        organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
          ? [
              {
                animation: InwardsAnimation,
                val: 'Inward',
                label: 'Inbound',
                type: AnimationType.LOTTIE,
                segment: [22, 58],
                disabled: !isFeatureEnabled(INBOUND_ORDER_CREATION),
                description:
                  'Streamline the receipt of goods into your supply chain. Effectively manage the process of receiving and recording items from customers.'
              },
              {
                animation: OutboundAnimation,
                val: 'Outbound',
                label: 'Outbound',
                type: AnimationType.LOTTIE,
                segment: [0, 34],
                disabled: !isFeatureEnabled(OUTBOUND_ORDER_CREATION),
                description:
                  'Efficiently manage the shipping and distribution of products to customers. Ensure timely deliveries and customer satisfaction.'
              },
              {
                animation: TransferAnimation,
                val: 'Transfer',
                label: 'Transfer',
                type: AnimationType.LOTTIE,
                segment: [0, 36],
                disabled: !isFeatureEnabled(TRANSFER_ORDER_CREATION),
                description:
                  'Optimize the movement of goods between different warehouse locations. Maintain accurate stock levels and distribution within your supply chain network.'
              }
            ]
          : [
              {
                animation: InwardsAnimation,
                val: 'Purchase',
                label: 'Purchase',
                type: AnimationType.LOTTIE,
                segment: [22, 58],
                disabled: !isFeatureEnabled(PURCHASE_ORDER_CREATION),
                description:
                  'Initiate procurement with precision. Create and manage orders to suppliers, ensuring your inventory is always at the right level.'
              },
              {
                animation: OutboundAnimation,
                val: 'Sales',
                label: 'Sales',
                type: AnimationType.LOTTIE,
                segment: [0, 34],
                disabled: !isFeatureEnabled(SALES_ORDER_CREATION),
                description:
                  'Process customer orders seamlessly. Ensure every sale is tracked and fulfilled to keep your customers happy and coming back.'
              },
              {
                animation: TransferAnimation,
                val: 'Transfer',
                label: 'Transfer',
                type: AnimationType.LOTTIE,
                segment: [0, 36],
                disabled: !isFeatureEnabled(TRANSFER_ORDER_CREATION),
                description:
                  'Streamline internal logistics. Manage the movement of stock between warehouses to balance inventory and improve efficiency.'
              },
              {
                animation: ReturnAnimation,
                val: 'Sales Return',
                label: 'Sales Return',
                type: AnimationType.LOTTIE,
                segment: [0, 60],
                disabled: !isFeatureEnabled(SALES_RETURN_ORDER_CREATION),
                description:
                  'Handle returns smoothly. Manage the return of goods, processing refunds or exchanges with accuracy and customer care.'
              }
            ];
      if (
        organizationType !== 'MANUFACTURER' &&
        (isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ||
          isFeatureEnabled(FLAGS.TMS))
      ) {
        typeData.push({
          animation: LogisticsAnimation,
          val: 'Logistic',
          label: 'Logistic',
          type: AnimationType.GIF,
          segment: [],
          disabled: !isFeatureEnabled(LOGISTICS_ORDER_CREATION),
          description:
            'Track the movement of goods between two locations in your supply chain with precision and ease.'
        });
      }
      setOrderTypeIcons(typeData);
    }
  }, [organizationType, organization]);

  const formik = useFormik({
    initialValues: {
      orderType: {},
      company: {},
      pickupNode: {},
      dropOffNode: {},
      pickupDateTime: moment(),
      dropoffDateTime: moment().add(2, 'hours'),
      logisticBy: 'CUSTOMER',
      pallete: 'Palletized',
      weight: 0,
      vehicleType: {},
      vehicleNumber: null,
      driverName: '',
      driverNumber: '',
      additionalInstructions: '',
      referenceId: '',
      PODId: null,
      POD: null,
      supportingDocument: [],
      existingDocs: [],
      products: [],
      isDraft: false,
      warehousePickUp: {},
      warehouseDropOff: {},
      dockId: '',
      paymentTerm: {},
      paymentTermTitle: ''
    },
    onSubmit: (values) => {
      addOrder(values, false);
    }
  });

  const nodeFormik = useFormik({
    initialValues: {
      name: '',
      nodeType: {},
      nodeClass: 'EXTERNAL',
      adhocFlag: true,
      isActive: true,
      description: '',
      pocName: '',
      pocContact: '',
      latLng: {
        lat: '',
        lng: ''
      },
      code: '',
      capacity: 0,
      pallets: 0,
      companyId: null,
      cityId: null,
      entity: {},
      isEntity: false,
      IMS: true,
      WMS: true,
      savedNodes: []
    },
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const getOrder = async () => {
    const { data } = await API.get(`orders/order/${oid}`, {
      params: {
        includeAvailableQuantities: true
      }
    });

    const moveTypeTitle = convertToTitleCase(
      data?.moveSubType
        ? data?.moveSubType
        : data.moveType == 'INWARD'
        ? 'Inbound'
        : data.moveType
    );

    let warehouseId;
    if (
      ['Inbound', 'Purchase', 'Sales Return'].includes(moveTypeTitle) &&
      data.dropOffNode?.Warehouse?.id
    ) {
      warehouseId = data.dropOffNode?.Warehouse?.id;
    } else if (
      ['Transfer', 'Outbound', 'Sales'].includes(moveTypeTitle) &&
      data.pickupNode?.Warehouse?.id
    ) {
      warehouseId = data.pickupNode?.Warehouse?.id;
    }

    const productArray = data?.OrderProductGroups?.map((prod) => {
      const Inventories =
        prod.Product?.Inventories?.filter(
          (i) => i.warehouseId === warehouseId
        ) ?? [];
      const uom = prod?.SecondaryUOM
        ? {
            ...prod?.SecondaryUOM,
            conversionFactor: prod?.Product?.ProductUOMs?.find(
              (uom) => uom.uomId == prod?.SecondaryUOM?.id
            )?.conversionFactor,
            primary: false
          }
        : {
            ...prod?.Product?.UOM,
            primary: true,
            conversionFactor: 1
          };
      return {
        id: prod.Product.id,
        orderedQuantity: prod.quantity / uom.conversionFactor,
        product: {
          ...prod.Product,
          Inventories: warehouseId ? Inventories : []
        },
        uom,
        tax: prod.tax,
        costPrice: prod.costPrice,
        sellingPrice: prod.sellingPrice,
        totalCost: prod.totalCost,
        totalRevenue: prod.totalRevenue,
        previousOrderQuantity: prod.quantity / uom.conversionFactor
      };
    });

    const orderObj = {
      orderType: {
        label: convertToTitleCase(
          data?.moveSubType
            ? data?.moveSubType
            : data.moveType == 'INWARD'
            ? 'Inbound'
            : data.moveType
        ),
        value: convertToTitleCase(
          data?.moveSubType ? data?.moveSubType : data.moveType
        )
      },
      company: {
        ...data.Company
      },
      ...(data.calculatedWeight && { calculatedWeight: data.calculatedWeight }),
      ...(data.pickupId && { pickupId: data.pickupId }),
      ...(data.pickupNode && { pickupNode: data.pickupNode }),
      ...(data.warehousePickUpId && {
        warehousePickUpId: data.warehousePickUpId
      }),
      ...(data.warehousePickUp && { warehousePickUp: data.warehousePickUp }),
      ...(data.supportingDocument
        ? { supportingDocument: data?.files }
        : { supportingDocument: [] }),
      ...(data.dropOffId && { dropOffId: data.dropOffId }),
      ...(data.dropOffNode && { dropOffNode: data.dropOffNode }),
      ...(data.warehouseDropOffId && {
        warehouseDropOffId: data.warehouseDropOffId
      }),
      ...(data?.PaymentTerm?.title && {
        paymentTermTitle: data.PaymentTerm.title
      }),
      ...(data?.PaymentTerm && { paymentTerm: data.PaymentTerm }),
      ...(data.warehouseDropOff && { warehouseDropOff: data.warehouseDropOff }),
      ...(data.pickupDateTime && { pickupDateTime: data.pickupDateTime }),
      ...(data.dropoffDateTime && { dropoffDateTime: data.dropoffDateTime }),
      ...(data.logisticBy && { logisticBy: data.logisticBy }),
      pallete: data.palletize ? 'Palletized' : 'Non Palletized',
      ...(data.weight && { weight: data.weight }),
      ...(data.vehicleType && { vehicleType: data.vehicleType }),
      ...(data.vehicleTypeId && { vehicleTypeId: data.vehicleTypeId }),
      ...(data.vehicleNumber && { vehicleNumber: data.vehicleNumber }),
      ...(data.driverName && { driverName: data.driverName }),
      ...(data.driverPhone && { driverPhone: data.driverPhone }),
      ...(data.PODId && { PODId: data.PODId }),
      ...(data.memo && { additionalInstructions: data.memo || '' }),
      ...(productArray?.length && { products: productArray }),
      ...(data.referenceId && { referenceID: data.referenceId }),
      ...(data.expectedDelivery && { expectedDelivery: data.expectedDelivery }),
      ...(data.totalCost && { totalCost: data.totalCost }),
      ...(data.totalRevenue && { totalRevenue: data.totalRevenue }),
      ...(data.files && { images: data.files }),
      isDraft: data.isDraft,
      pickupNode: data.pickupNode,
      status: data.status
    };
    formik.setValues({ ...orderObj });
  };

  const addOrder = async (values, isDraft) => {
    if (!values.company?.id && values.orderType.value !== 'Transfer') {
      return;
    }

    const checkValidProducts = values.products.reduce(
      (acc, prod) => acc && (prod?.product?.name || prod?.product?.description),
      true
    );

    const checkValidQty = values.products.reduce(
      (acc, prod) => acc && prod?.orderedQuantity && prod?.orderedQuantity > 0,
      true
    );
    if (!checkValidProducts) {
      toaster('warning', 'Please make sure that product names are not missing');
      return;
    }

    if (!checkValidQty) {
      toaster(
        'warning',
        'Please make sure that ordered quantity is not missing or greater than zero'
      );
      return;
    }
    setAPILoader(true);
    setCreateOrder(true);
    const productsArr = values.products?.map((prod) => {
      return {
        sku: prod.product.name,
        ...{ id: prod.product.id },
        quantity: prod.orderedQuantity * (prod.uom?.conversionFactor || 1),
        costPrice: prod.costPrice || 0,
        sellingPrice: prod.sellingPrice || 0,
        tax: prod.tax || 0,
        totalCost: prod.totalCost,
        totalRevenue: prod.totalRevenue,
        uomType: prod?.uom?.primary ? 'PRIMARY' : 'SECONDARY',
        secondaryUomId: prod?.uom?.primary ? null : prod?.uom?.uomId,
        secondaryUomQuantity: prod?.uom?.primary ? null : prod.orderedQuantity,
        ...(prod.previousOrderQuantity !== prod.orderedQuantity && {
          previousQuantity: prod.previousOrderQuantity
        })
      };
    });

    const orderType = values.orderType.value.toUpperCase().replace(' ', '_');
    let moveSubType;
    if (OrderSubTypes.includes(orderType)) {
      moveSubType = orderType;
    } else {
      moveSubType = null;
    }

    let order = {
      orderDetails: {
        moveType: OrderTypeMap[orderType],
        moveSubType,
        calculatedWeight: values?.calculatedWeight,
        expectedDelivery: values?.expectedDelivery,
        referenceOrderId: values?.referenceOrderId,
        ...(['PURCHASE', 'SALES'].includes(orderType)
          ? { paymentTermTitle: values?.paymentTermTitle }
          : {}),
        totalCost:
          values?.products.reduce((a, b) => +a + (+b?.totalCost || 0), 0) || 0,
        totalRevenue:
          values?.products.reduce((a, b) => +a + (+b?.totalRevenue || 0), 0) ||
          0,
        ...(values.pickupNode && { pickupId: values.pickupNode.id }),
        ...(values.warehousePickUp && {
          warehousePickUpId: values.warehousePickUp.id
        }),
        ...(values.company?.id && { companyId: values.company.id }),
        ...(values.dropOffNode && { dropOffId: values.dropOffNode.id }),
        ...(values.pickupDateTime && { pickupDate: values.pickupDateTime }),
        ...(values.dropoffDateTime && { dropoffDate: values.dropoffDateTime }),
        ...(values.warehouseDropOff && {
          warehouseDropOffId: values.warehouseDropOff.id
        }),

        ...(productsArr?.length && { products: productsArr }),
        ...(values.additionalInstructions && {
          memo: values.additionalInstructions
        }),
        ...(values.referenceID && { referenceId: values.referenceID }),
        ...(values.PODId && { PODId: values.PODId }),
        isDraft,
        status: isDraft
          ? 'DRAFT'
          : values?.status !== 'PENDING' && orderType !== 'LOGISTIC'
          ? 'IN_PROGRESS'
          : 'PENDING'
      },
      shipmentDetails: {
        ...(values.logisticBy && { logisticBy: values.logisticBy }),
        palletize: values.pallete === 'Palletized' ? true : false,
        ...(values.weight && { weight: values.weight }),
        ...(values.vehicleTypeId && { vehicleTypeId: values.vehicleType.id }),
        ...(values.vehicleNumber && { vehicleNumber: values.vehicleNumber }),
        ...(values.driverName && { driverName: values.driverName }),
        ...(values.driverPhone && { driverPhone: values.driverPhone }),
        ...(values.pickupDateTime && { pickupDateTime: values.pickupDateTime }),
        ...(values.dropoffDateTime && {
          dropoffDateTime: values.dropoffDateTime
        })
      }
    };

    let fileIds = [];
    if (values?.supportingDocument?.length) {
      fileIds = await upload(
        values?.supportingDocument?.filter((file) => !file?.id),
        'orders'
      );
      order.orderDetails.supportingDocument = [
        ...((values && values.supportingDocument) || [])
          .filter((file) => file.id)
          .map((file) => file.id),
        ...fileIds
      ];
    }

    try {
      if (oid) {
        if (
          !formik.values.isDraft &&
          order?.orderDetails?.status == 'IN_PROGRESS'
        ) {
          await API.post(`orders/${oid}/shipments`, {
            ...order.shipmentDetails,
            hostUrl: window.location.href
          });
        }
        await API.put(`orders/order/${oid}`, {
          ...order,
          hostUrl: window.location.href
        });
        mixpanel.track('Order Updated', {
          orderId: oid
        });
        toaster('success', 'Order has been Updated');
      } else {
        await API.post(`orders`, {
          ...order,
          hostUrl: window.location.href
        });
        mixpanel.track('Order Created', {});
        toaster('success', 'Order has been Created');
      }

      setAPILoader(false);
      navigate('/routing/order-management');
    } catch (error) {
      onError(error);
      setCreateOrder(false);
    }
    setAPILoader(false);
  };

  const getCompanies = async (search) => {
    try {
      let companyData;
      if (search) {
        companyData = await API.get(`companies`, {
          params: { search, type: companyType, excludeExtraInclusions: true }
        });
      } else {
        companyData = await API.get(`companies`, {
          params: { type: companyType, excludeExtraInclusions: true }
        });
      }

      setCompanies(companyData?.data?.rows);

      if (companyData?.data?.rows?.length == 1) {
        formik.setFieldValue('company', companyData?.data?.rows?.[0]);
      }
    } catch (err) {
      onError(err);
    }
  };

  const getPaymentTerms = async (search = '') => {
    try {
      setAPILoader(true);
      const { data } = await API.get('paymentTerms', {
        params: { search, columns: ['title'] }
      });

      setPaymentTerms(data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getNodes = async ({
    id,
    externalSearchKeyword = '',
    internalSearchKeyword = '',
    secondaryInternalSearchKeyword = '',
    secondaryExternalSearchKeyword = ''
  }) => {
    try {
      setAPILoader(true);
      if (formik.values.orderType?.value === 'Transfer') {
        const resSecondary = await API.get(`nodes`, {
          params: {
            nodeClass: 'INTERNAL',
            search: secondaryInternalSearchKeyword,
            ignoreExtraQueries: true
          }
        });
        resSecondary?.data?.length
          ? setSecondaryInternalNodes(resSecondary.data)
          : setSecondaryInternalNodes([]);
      }
      if (formik.values.orderType?.value === 'Logistic') {
        const resSecondary = await API.get(`nodes`, {
          params: {
            companyId: formik?.values?.company?.id,
            nodeClass: 'EXTERNAL',
            search: secondaryExternalSearchKeyword,
            ignoreExtraQueries: true
          }
        });
        resSecondary?.data?.length
          ? setSecondaryExternalNodes(resSecondary.data)
          : setSecondaryExternalNodes([]);
      }

      const resInternal = await API.get(`nodes`, {
        params: {
          nodeClass: 'INTERNAL',
          search: internalSearchKeyword,
          ignoreExtraQueries: true
        }
      });
      resInternal?.data?.length
        ? setInternalNodes(resInternal.data)
        : setInternalNodes([]);
      if (id) {
        const resExternal = await API.get(`nodes`, {
          params: {
            companyId: id,
            search: externalSearchKeyword,
            nodeClass: 'EXTERNAL',
            ignoreExtraQueries: true
          }
        });
        const transitNodes = await API.get('nodes', {
          params: {
            search: externalSearchKeyword,
            nodeClass: 'TRANSIT',
            ignoreExtraQueries: true
          }
        });
        const externalNodes = [
          ...resExternal.data,
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...transitNodes?.data?.map((n) => ({ ...n, isActive: true }))
        ];
        setExternalNodes(externalNodes);
      }
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const _getExternalNodes = useCallback(
    debounce((externalSearchKeyword) => {
      const id = formik?.values?.company?.id;
      if (id) {
        getNodes({ id, externalSearchKeyword });
      }
    }, DEBOUNCE_CONST),
    [formik.values.company]
  );

  const _getSecondaryExternalNodes = useCallback(
    debounce((secondaryExternalSearchKeyword) => {
      const id = formik?.values?.company?.id;
      if (id) {
        getNodes({ id, secondaryExternalSearchKeyword });
      }
    }, DEBOUNCE_CONST),
    [formik.values.company]
  );

  const _getInternalNodes = useCallback(
    debounce((internalSearchKeyword) => {
      getNodes({ id: formik.values.company?.id, internalSearchKeyword });
    }, DEBOUNCE_CONST),
    [formik.values.orderType.value, formik.values.company]
  );

  const _getSecondaryInternalNodes = useCallback(
    debounce((secondaryInternalSearchKeyword) => {
      getNodes({
        id: formik.values.company?.id,
        secondaryInternalSearchKeyword
      });
    }, DEBOUNCE_CONST),
    [formik.values.orderType.value, formik.values.company]
  );

  const getSalesOrders = async (search = '') => {
    try {
      setAPILoader(true);
      const orders = await API.get(`orders`, {
        params: {
          moveSubType: 'SALES',
          statuses: ['COMPLETED', 'PARTIALLY_COMPLETE'],
          search,
          columns: ['customId']
        }
      });
      setSalesOrders(orders?.data ? orders?.data : []);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
    setAPILoader(false);
  };

  const getSalesPurchaseOrders = async (company, orderType) => {
    const moveType =
      orderType === 'Inbound' ? 'INWARD' : orderType.toUpperCase();
    try {
      setAPILoader(true);
      const orders = await API.get(`orders`, {
        params: {
          search: '',
          columns: ['id'],
          company: company?.name,
          ...(['OUTBOUND', 'TRANSFER', 'LOGISTIC', 'INWARD'].includes(
            moveType
          ) && { moveType: moveType }),
          ...(['PURCHASE', 'SALES', 'SALES_RETURN'].includes(moveType) && {
            moveSubType: moveType
          }),
          limit: 1
        }
      });
      const filteredOrder = orders?.data?.filter(
        (value) => value?.Company?.id === company?.id
      );
      const lastOrder = filteredOrder[0];
      formik.setFieldValue(
        'pickupId',
        lastOrder?.pickupNode !== null && lastOrder?.pickupNode?.id
      );
      formik.setFieldValue(
        'pickupNode',
        lastOrder?.pickupNode !== null && lastOrder?.pickupNode
      );
      formik.setFieldValue(
        'dropOffId',
        lastOrder?.dropOffNode !== null && lastOrder?.dropOffNode?.id
      );
      formik.setFieldValue(
        'dropOffNode',
        lastOrder?.dropOffNode !== null && lastOrder?.dropOffNode
      );
      formik.setFieldValue(
        'expectedDelivery',
        lastOrder?.expectedDelivery || new Date()
      );
      if (!formik?.values?.paymentTermTitle) {
        formik.setFieldValue('paymentTermTitle', lastOrder?.PaymentTerm?.title);
        formik.setFieldValue('paymentTerm', lastOrder?.PaymentTerm);
      }
      const lastOrderProducts = lastOrder?.OrderProductGroups?.map((item) => {
        return {
          orderId: item?.orderId,
          id: item?.id,
          productId: item?.ProductId,
          quantity: item?.quantity,
          originalOrderedQuantity: item?.originalOrderedQuantity,
          costPrice: item?.costPrice,
          sellingPrice: item?.sellingPrice,
          tax: item?.tax,
          totalCost: item?.totalCost,
          totalRevenue: item?.totalRevenue,
          product: {
            ...item?.Product
          },
          uom: { ...item?.Product?.UOM, conversionFactor: 1 }
        };
      });
      formik.setFieldValue(`products`, lastOrderProducts);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
    setAPILoader(false);
  };

  const getUsers = async (value) => {
    try {
      let columns = [
        'firstName',
        'lastName',
        'username',
        'email',
        'phone',
        '$UserRoles.Role.name$'
      ];

      let colVal = {
        '$UserRoles.Role.allowedApps$': 'OPERATIONS',
        isActive: '1'
      };

      let filters = {
        colVal
      };

      const { data } = await API.get(`organizations/${subdomain}/users`, {
        params: { search: value || '', filters, columns }
      });

      setUsers(data);
    } catch (err) {
      onError(err);
    }
  };

  const fetchRes = (orderType, dropOffNode, pickupNode, company) => {
    let colVal = {};
    colVal['$Company.name$'] = company.name;
    if (
      (orderType?.label === 'Inbound' ||
        orderType?.label === 'Purchase' ||
        orderType?.label === 'Sales Return') &&
      dropOffNode?.Warehouse?.id
    ) {
      colVal['$Warehouse.id$'] = dropOffNode?.Warehouse?.id;
    } else if (
      (orderType?.label === 'Transfer' ||
        orderType?.label === 'Outbound' ||
        orderType?.label === 'Sales') &&
      pickupNode?.Warehouse?.id
    ) {
      colVal['$Warehouse.id$'] = pickupNode?.Warehouse?.id;
    }
    if (organizationType === ORGANIZATION_TYPES.MANUFACTURER) {
      delete colVal['$Company.name$'];
    }

    getWarehouse(orderType, dropOffNode, pickupNode);
  };

  const getWarehouse = useCallback(
    (orderType, dropOffNode, pickupNode) => {
      if (
        (orderType.label === 'Inbound' ||
          orderType.label === 'Purchase' ||
          orderType.label === 'Sales Return') &&
        dropOffNode?.Warehouse?.id
      ) {
        setSelectedWarehouseId(dropOffNode?.Warehouse?.id);
      } else if (
        (orderType.label === 'Transfer' ||
          orderType.label === 'Outbound' ||
          orderType.label === 'Sales') &&
        pickupNode?.Warehouse?.id
      ) {
        setSelectedWarehouseId(pickupNode?.Warehouse?.id);
      }
    },
    [formik.values.products]
  );

  useEffect(() => {
    if (formik?.values?.company?.id) {
      getNodes({
        id: formik.values.company.id,
        internalSearchKeyword: '',
        externalSearchKeyword: ''
      });
    } else if (formik.values.orderType.value === 'Transfer') {
      getNodes({
        secondaryExternalSearchKeyword: '',
        externalSearchKeyword: ''
      });
    }
  }, [formik.values.company, formik.values.orderType.value]);

  useEffect(() => {
    const shouldFetch = () => {
      const { company, orderType, pickupId, dropOffId } = formik.values;
      if (
        (company.id &&
          dropOffId &&
          ['Inbound', 'Purchase', 'Sales Return'].includes(orderType.label)) ||
        (company.id &&
          pickupId &&
          ['Outbound', 'Sales'].includes(orderType.label)) ||
        (orderType.label === 'Transfer' && pickupId)
      ) {
        return true;
      }
      return false;
    };

    if (shouldFetch()) {
      const { orderType, dropOffNode, pickupNode, company } = formik.values;
      fetchRes(orderType, dropOffNode, pickupNode, company);
    }
  }, [formik.values]);

  useEffect(() => {
    if (oid) {
      getOrder();
    }
    setCurrentPageTitle('Create Order');
  }, []);

  useEffect(() => {
    getCompanies();
  }, [companyType]);

  const saveToDraft = async () => {
    formik.setFieldValue('isDraft', true);
    formik.setFieldValue('status', 'DRAFT');

    addOrder(formik.values, true);
  };

  useEffect(() => {
    if (selectedReferenceOrder && !oid) {
      const selectedOrder = salesOrders.find(
        (obj) => obj.id === selectedReferenceOrder
      );

      if (!selectedOrder) {
        return;
      }

      formik.setFieldValue('company', selectedOrder?.Company);
      formik.setFieldValue('dropOffId', selectedOrder?.pickupId);
      formik.setFieldValue('dropOffNode', selectedOrder?.pickupNode);
      formik.setFieldValue('pickupId', selectedOrder?.dropOffId);
      formik.setFieldValue('pickupNode', selectedOrder?.dropOffNode);
      formik.setFieldValue('referenceID', selectedOrder?.referenceId);

      const productIdTotals = {};
      selectedOrder?.OrderProductGroups?.forEach((orderedProduct) => {
        if (productIdTotals[orderedProduct?.ProductId] === undefined) {
          productIdTotals[orderedProduct?.ProductId] =
            +orderedProduct?.originalOrderedQuantity -
            +orderedProduct?.quantity;
        } else {
          productIdTotals[orderedProduct?.ProductId] +=
            +orderedProduct?.originalOrderedQuantity -
            +orderedProduct?.quantity;
        }
      });

      const outputList = Object.entries(productIdTotals).map(
        ([productId, total]) => ({
          productId: parseInt(productId, 10),
          total
        })
      );
      const productsList = [];
      outputList.forEach((prod) => {
        const orderedProduct = selectedOrder.OrderProductGroups.find(
          (obj) => obj.ProductId === prod.productId
        );
        if (orderedProduct) {
          orderedProduct.product = orderedProduct.Product;
          delete orderedProduct.Product;
          orderedProduct.uom = {
            ...orderedProduct.product?.UOM,
            conversionFactor: 1,
            primary: true
          };

          productsList.push({
            ...orderedProduct,
            prevOrderedQuantity: prod.total,
            availableQuantity: prod.total,
            orderedQuantity: 0
          });
        }
      });
      formik.setFieldValue('products', productsList);
      fetchRes(
        selectedOrder.moveSubType || selectedOrder.moveType,
        selectedOrder.dropOffNode,
        selectedOrder.pickupNode,
        selectedOrder.Company
      );
    } else {
      formik.setFieldValue('products', []);
      formik.setFieldValue('calculatedWeight', 0);
    }
  }, [selectedReferenceOrder]);

  const navigate = useNavigate();

  const orderType = formik.values.orderType?.label;

  const calculateOrderProgress = () => {
    const values = formik.values;
    let progress = 0;
    if (values.orderType?.label) {
      progress += 16;
      const orderType = values.orderType?.label;
      if (orderType == 'Inbound') {
        if (values.dropOffNode?.id) progress += 16;
        if (values.pickupNode?.id) progress += 16;
      } else if (orderType == 'Outbound') {
        if (values.pickupNode?.id) progress += 16;
        if (values.dropOffNode?.id) progress += 16;
      } else if (orderType == 'Transfer') {
        if (values.dropOffNode?.id) progress += 24;
        if (values.pickupNode?.id) progress += 24;
      } else {
        if (values.dropOffNode?.id) progress += 16;
        if (values.pickupNode?.id) progress += 16;
      }
    }
    if (values.company?.id) progress += 16;
    if (values.expectedDelivery) progress += 16;
    if (verifyProducts()) progress += 20;

    return progress;
  };

  const verifyProducts = () => {
    if (formik.values.products?.length > 0) {
      const products = formik.values.products;
      let flag = true;
      for (let i = 0; i < products?.length; i++) {
        if (
          !products[i]?.product?.id ||
          !products[i]?.orderedQuantity ||
          products[i]?.orderedQuantity <= 0 ||
          (formik.values?.orderType.value === 'Purchase' &&
            checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY') &&
            !products[i]?.costPrice) ||
          products[i]?.costPrice < 0 ||
          ((formik.values?.orderType.value === 'Sales' ||
            formik.values?.orderType.value === 'Sales Return') &&
            checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY') &&
            !products[i]?.sellingPrice) ||
          products[i]?.sellingPrice < 0
        ) {
          flag = false;
          break;
        }
      }
      return flag;
    }
    return formik.values.orderType?.value == 'Logistic' ? true : false;
  };

  const nodeTypeOptions = Object.keys(nodeTypes.EXTERNAL).map((key) => ({
    value: key,
    label: nodeTypes.EXTERNAL[key]
  }));

  const resetNodeValues = () => {
    nodeFormik.setValues({
      name: '',
      nodeType: {},
      nodeClass: 'EXTERNAL',
      adhocFlag: true,
      isActive: true,
      description: '',
      pocName: '',
      pocContact: '',
      latLng: {
        lat: '',
        lng: ''
      },
      code: '',
      capacity: '',
      pallets: '',
      companyId: null,
      cityId: null,
      entity: {},
      isEntity: false,
      IMS: true,
      WMS: true,
      savedNodes: []
    });
  };

  const addNode = async () => {
    let data = [
      {
        ...nodeFormik.values,
        nodeType: nodeFormik.values.nodeType?.value,
        locationLatLng: nodeFormik.values.latLng,
        managerId: nodeFormik.values.manager?.id,
        pallets: Number(nodeFormik.values.pallets) || 0,
        capacity: Number(nodeFormik.values.capacity) || 0
      }
    ];

    setAPILoader(true);
    try {
      if (nodeFormik.values.nodeClass == 'INTERNAL')
        await API.post(`nodes/bulk`, data);
      else
        await API.post(
          `nodes/bulk?companyId=${Number(formik.values.company?.id)}`,
          data
        );
      toaster('success', 'Node has been created.');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const classes = useStyles();

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open={true}
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <form onSubmit={formik.handleSubmit}>
          <FixedLayout
            showFooter={!orderTypeSelectionView}
            header={
              <>
                <div className="flex justify-between w-full items-center px-6">
                  <div className="flex items-center">
                    <img
                      src={BackIcon}
                      alt="back"
                      onClick={() => {
                        navigate('/routing/order-management');
                      }}
                      className="mr-1"
                    />
                    <p className="font-semibold text-lg text-primaryBlue">
                      {orderTypeSelectionView
                        ? 'Create an Order'
                        : `${
                            orderType == 'Inbound' || orderType == 'Outbound'
                              ? oid
                                ? `Update Order ${oid}`
                                : `Create an ${orderType} Order`
                              : oid
                              ? `Update Order ${oid}`
                              : `Create a ${orderType} Order`
                          }`}
                    </p>
                  </div>
                  <div>
                    <CloseOutlined
                      onClick={() => {
                        navigate('/routing/order-management');
                      }}
                      className="cursor-pointer text-[20px] text-dark400"
                    />
                  </div>
                  <div
                    className={`absolute -bottom-[14px] left-0 border-solid border-0 border-b-[6px] border-b-primary`}
                    style={{
                      width: `${calculateOrderProgress()}%`
                    }}
                  />
                </div>
              </>
            }
            content={
              orderTypeSelectionView && !oid ? (
                <div className="flex flex-col items-center mt-32 px-4 sm:px-6 lg:px-8">
                  <p className="font-bold text-2xl sm:text-3xl md:text-4xl text-primaryBlue mb-3 text-center">
                    What type of order you want to create?
                  </p>
                  <p className="text-center text-primaryBlue text-base mb-[72px] sm:text-lg md:text-xl">
                    Choose from one of the options below
                  </p>
                  <div
                    className={clsx(
                      `grid gap-4 ${
                        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ||
                        isFeatureEnabled(FLAGS.TMS) ||
                        organizationType == 'MANUFACTURER'
                          ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4'
                          : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3'
                      }`
                    )}
                  >
                    {orderTypeIcons.map((orderType) => (
                      <OrderTypeBox
                        key={orderType.label}
                        animation={orderType.animation}
                        label={orderType.label}
                        value={orderType.val}
                        animationType={orderType.type}
                        segment={orderType.segment}
                        selected={formik.values.orderType?.label}
                        description={orderType.description}
                        disabled={orderType.disabled}
                        handleClick={() => {
                          if (orderType.disabled) return;

                          formik.setFieldValue('orderType', {
                            label: orderType.label,
                            value: orderType.val
                          });
                          if (orderType.val === 'Purchase') {
                            setCompanyType('SUPPLIER');
                          }
                          if (orderType.val === 'Sales') {
                            setCompanyType('CUSTOMER');
                          }
                          handleClick();
                        }}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <OrderFormBody
                  edit={!!oid}
                  companies={companies}
                  setCompanyType={setCompanyType}
                  formik={formik}
                  getExternalNodes={_getExternalNodes}
                  getInternalNodes={_getInternalNodes}
                  getSecondaryInternalNodes={_getSecondaryInternalNodes}
                  getSecondaryExternalNodes={_getSecondaryExternalNodes}
                  internalNodes={internalNodes}
                  secondaryInternalNodes={secondaryInternalNodes}
                  secondaryExternalNodes={secondaryExternalNodes}
                  externalNodes={externalNodes}
                  setAddNodeView={setAddNodeView}
                  companySearch={companySearch}
                  setCompanySearch={setCompanySearch}
                  nodeSearch={nodeSearch}
                  setNodeSearch={setNodeSearch}
                  organizationType={organizationType}
                  salesOrders={salesOrders}
                  selectedReferenceOrder={selectedReferenceOrder}
                  setSelectedReferenceOrder={setSelectedReferenceOrder}
                  inventoryLoading={isInventoryLoading}
                  moveType={orderType}
                  nodeFormik={nodeFormik}
                  getSalesOrders={getSalesOrders}
                  getSalesPurchaseOrders={getSalesPurchaseOrders}
                  paymentTerms={paymentTerms}
                  getUsers={getUsers}
                  getCompanies={getCompanies}
                  getPaymentTerms={getPaymentTerms}
                  warehouseId={selectedWarehouseId}
                  setPrefillLastOrderDetails={setPrefillLastOrderDetails}
                />
              )
            }
            footer={
              !orderTypeSelectionView && (
                <>
                  <div className="flex justify-between items-center w-full">
                    <Button
                      label="Cancel"
                      variant="transparent"
                      className={'p-2 ml-2 h-8 rounded border-solid'}
                      overrideSize={true}
                      labelClass="font-medium text-xs "
                      onClick={() => navigate('/routing/order-management')}
                    />

                    <div className="flex justify-between ">
                      {!oid ? (
                        <Button
                          label="Save Draft"
                          variant="transparent"
                          className={'p-2 ml-2 h-8 rounded border-solid'}
                          overrideSize={true}
                          labelClass="font-medium text-xs "
                          onClick={() => {
                            return DialogueConfirmationOpen(
                              formik.values,
                              false,
                              saveToDraft,
                              '',
                              'This will save order to draft and return you to order management, continue ?',
                              'Yes, Save Draft'
                            );
                          }}
                        />
                      ) : (
                        formik.values.isDraft && (
                          <Button
                            label="Save Draft"
                            variant="transparent"
                            className={'p-2 ml-2 h-8 rounded border-solid'}
                            overrideSize={true}
                            labelClass="font-medium text-xs "
                            onClick={saveToDraft}
                            disabled={
                              organizationType ==
                                ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER &&
                              !formik.values.company?.id
                            }
                          />
                        )
                      )}
                      <Button
                        label={oid ? 'Update Order' : 'Create Order'}
                        className={
                          'py-2 px-3 ml-[7px] h-8 border-0 rounded w-[115px]'
                        }
                        variant="primary"
                        overrideSize={true}
                        labelClass="font-medium text-xs"
                        onClick={() => setOrderSummaryOpen(true)}
                        disabled={
                          !formik.values?.orderType?.label ||
                          (!formik.values?.warehousePickUp?.id &&
                            !formik.values?.pickupNode?.id) ||
                          (!formik.values?.warehouseDropOff?.id &&
                            !formik.values?.dropOffNode?.id) ||
                          (formik.values?.products?.length === 0 &&
                            formik.values?.orderType?.value !== 'Logistic') ||
                          // !formik.values?.expectedDelivery ||
                          // (['Purchase', 'Sales'].includes(
                          //   formik.values?.orderType?.label
                          // ) &&
                          //   !formik.values?.paymentTermTitle) ||
                          !verifyProducts()
                        }
                      />
                    </div>
                  </div>
                </>
              )
            }
          />
        </form>
        <Popup
          open={prefillLastOrderDetails}
          setOpen={setPrefillLastOrderDetails}
          onClose={() => {
            setPrefillLastOrderDetails(false);
          }}
          title={'Order Detail'}
          scroll="paper"
          classes={{
            scrollPaper: classes.topScrollPaper,
            paperScrollBody: classes.topPaperScrollBody
          }}
          content={
            <div className="flex flex-col md:flex-row w-full max-w-[700px] items-center justify-between p-4">
              <span className="text-sm font-medium mb-4 md:mb-0">
                Would you like to use details from your previous order?
              </span>
              <div className="flex gap-2 items-center w-full md:w-auto justify-end">
                <Button
                  label={'No'}
                  variant="transparent"
                  className="w-full md:w-auto"
                  onClick={() => setPrefillLastOrderDetails(false)}
                />
                <Button
                  label={'Yes'}
                  variant="primary"
                  className="w-full md:w-auto"
                  onClick={async () => {
                    await getSalesPurchaseOrders(
                      formik.values?.company,
                      formik.values?.orderType?.label
                    );
                    setPrefillLastOrderDetails(false);
                  }}
                />
              </div>
            </div>
          }
        />
        <Popup
          open={orderSummaryOpen}
          setOpen={setOrderSummaryOpen}
          fullScreen={isFullScreen || isMediumScreen}
          maxWidth={isFullScreen || isMediumScreen ? 'xl' : 'sm'}
          content={
            <OrderSummary
              formik={formik}
              organizationType={organizationType}
              isLogistic={orderType == 'LOGISTIC' || orderType == 'Logistic'}
              warehouseId={selectedWarehouseId}
            />
          }
          title={oid ? 'Update Order' : 'Create Order'}
          actions={
            <div className="flex gap-2 pb-6 px-4 sm:px-4 lg:px-8 pt-6">
              <Button
                label="Cancel"
                variant="tertiary"
                onClick={() => setOrderSummaryOpen(false)}
              />
              <Button
                label={oid ? 'Update Order' : 'Create Order'}
                variant="primary"
                disabled={createOrder}
                onClick={() => formik.handleSubmit()}
              />
            </div>
          }
          // dialogContentClasses="dark-scrollbar p-0 overflow-x-hidden"
          headerClass="px-4 sm:px-4 lg:px-8 pt-8 pb-4"
          closeIconSize={24}
          titleClass="text-primaryBlue text-xl font-semibold"
          noLineBreak={true}
          paperProps={
            isFullScreen ||
            (isMediumScreen && {
              style: {
                borderRadius: '8px',
                maxHeight: '60vh',
                minWidth: '742px',
                width: '100%'
              }
            })
          }
        />
        <Popup
          open={addNodeView}
          setOpen={setAddNodeView}
          fullScreen={isFullScreen || isMediumScreen}
          maxWidth={isFullScreen || isMediumScreen ? 'xl' : 'sm'}
          content={
            <NodeForm
              formik={nodeFormik}
              nodeTypes={nodeTypeOptions}
              users={users}
              isEntity={false}
              edit={true}
              search={nodeSearch}
              setSearch={setNodeSearch}
              savedNodes={[]}
            />
          }
          title={
            <div>
              <p>Add Node</p>
            </div>
          }
          onClose={() => {
            resetNodeValues();
          }}
          actions={
            <div className="flex justify-between gap-2">
              <Button
                label="Cancel"
                variant="tertiary"
                onClick={() => {
                  setAddNodeView(false);
                  resetNodeValues();
                }}
              />

              <Button
                label="Create Node"
                variant="primary"
                disabled={!nodeFormik.isValid}
                onClick={async () => {
                  await addNode();
                  await getNodes({
                    id: formik.values.company?.id,
                    externalSearchKeyword: '',
                    internalSearchKeyword: ''
                  });
                  setAddNodeView(false);
                  resetNodeValues();
                }}
              />
            </div>
          }
        />
      </div>
    </Modal>
  );
}

const OrderSummary = ({ formik, isLogistic, warehouseId }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 pt-6 pb-0">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6">
        <div>
          <p className="text-[10px] text-[#67718C] font-medium">
            REFERENCE NO.
          </p>
          <p className="font-medium text-sm">
            {formik.values.referenceID || '-'}
          </p>
        </div>
        <div>
          <p className="text-[10px] text-[#67718C] font-medium">ORDER TYPE</p>
          <p className="text-sm font-medium">
            {formik.values.orderType.value == 'Inward'
              ? 'Inbound'
              : formik.values.orderType.value}
          </p>
        </div>
        <div>
          <p className="text-[10px] text-[#67718C] font-medium">
            {formik.values.orderType.value === 'Purchase' ||
            formik.values.orderType.value === 'Sales Return'
              ? 'SUPPLIER'
              : 'CUSTOMER'}
          </p>
          <p className="text-sm font-medium">{formik.values.company.name}</p>
        </div>
        <div>
          <p className="text-[10px] text-[#67718C] font-medium">PALLET</p>
          <p className="text-sm font-medium">{formik.values.pallete}</p>
        </div>
      </div>
      <div className="mt-10">
        {isMobile ? (
          <ProductTableMobile
            formik={formik}
            summary={true}
            isLogistic={isLogistic}
            warehouseId={warehouseId}
          />
        ) : (
          <ProductTable
            formik={formik}
            summary={true}
            isLogistic={isLogistic}
            warehouseId={warehouseId}
          />
        )}
      </div>
    </div>
  );
};

export const SupportingDocumentUpload = ({ formik, propertyName }) => {
  const inputRef = useRef(null);
  const propertyNames = propertyName?.split('.') || 0;

  const uploadFiles = async (event) => {
    const newFiles = event.target.files;
    formik?.setFieldValue(propertyName, [
      ...(propertyNames.length === 1
        ? formik.values[propertyName]
        : formik.values[propertyNames[0]][propertyNames[1]]),
      ...newFiles
    ]);
  };

  return (
    <>
      <Button
        variant="tertiary"
        size="medium"
        label="Attach"
        className="text-xs h-8"
        onClick={() => inputRef.current.click()}
        icon={<img src={PAPERCLIP_ICON} alt="Paperclip Icon" />}
      />
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={(e) => uploadFiles(e)}
        accept=".jpg,.png,.jpeg,.pdf,.xlsx"
        multiple
      />
    </>
  );
};

export const formatFileName = (file) => {
  if (file.id) {
    let ext = file.originalName?.split('.')?.pop();
    return `${file.originalName?.substring(0, 6)}...${ext}`;
  } else {
    let ext = file.name?.split('.')?.pop();
    return `${file.name?.substring(0, 6)}...${ext}`;
  }
};

export const convertToTitleCase = (inputString) => {
  inputString = inputString.replace('_', ' ');
  return (
    inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
  );
};

export const capitalizeWords = (str) => {
  str = str.replace('_', ' ');
  const words = str.split(' ');
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const capitalizedString = capitalizedWords.join(' ');
  return capitalizedString;
};

export default AddOrder;

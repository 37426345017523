export const SOURCE = Object.freeze({
  CUSTOMER_PORTAL: 'Customer',
  COC: 'Oware'
});

export const BOOKING_STATUS = Object.freeze({
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  CONFIRMATION_REQUIRED: 'Need Confirmation',
  CANCELLED: 'Cancelled'
});

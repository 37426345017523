import React, { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../../Config';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { Box, Grid } from '@mui/material';
import addIcon from '../../../../assets/icons/addCard.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import BayListComponent from '../components/BayListComponent';
import { makeStyles, Tooltip } from '@material-ui/core';
import AddAislesAndBaysView from '../components/AddAislesAndBaysView';
import { SharedContext } from '../../../../utils/common';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

const BaysView = ({ warehouse, searchValue, bays, setBays }) => {
  const limit = 10;
  const { isFeatureEnabled } = useFeatureFlags();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const toolTipClasses = useStylesBootstrap();
  const [open, setOpen] = React.useState(null);
  const [selectedBay, setSelectedBay] = useState();
  const { setAPILoader } = useContext(SharedContext);

  const getWarehouseBays = async (page, searchValue, oldData = []) => {
    setAPILoader(true);
    try {
      const cols = ['name'];
      const res = await API.get(`warehouses/${warehouse?.id}/bays`, {
        params: {
          page: page,
          limit: limit,
          search: searchValue,
          columns: cols
        }
      });
      const newArr = res?.data;
      setBays([...oldData, ...newArr]);
      setPageCount(res?.pages);
      setAPILoader(false);
    } catch (error) {
      onError(error);
      setAPILoader(false);
    }
  };

  const getDelayedWarehouseBays = useCallback(
    debounce(
      async (page, searchValue, oldData) => {
        getWarehouseBays(page, searchValue, oldData);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedWarehouseBays(page, searchValue, []);
  }, [searchValue]);

  const handleClick = (event) => {
    setOpen(open ? null : event.currentTarget);
  };

  return searchValue && bays.length <= 0 ? (
    <></>
  ) : (
    <Grid
      item
      flex={1}
      className="bg-dark200 h-full rounded"
      style={{ boxSizing: 'border-box' }}
    >
      <Box>
        <AddAislesAndBaysView
          type="Bay"
          route="bays"
          handleClose={() => {
            setOpen(null);
          }}
          aisleOrBays={selectedBay}
          warehouseId={warehouse?.id}
          anchorEl={open}
          getWarehouseAislesOrBays={getWarehouseBays}
        />
        <div className="mx-4 mt-4 mb-2 items-center">
          <div className="flex justify-between">
            <p>BAYS</p>
            {isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
              <div className="flex">
                <Tooltip
                  arrow
                  title="Add Bays"
                  aria-label="add"
                  placement="top"
                  classes={toolTipClasses}
                >
                  <button
                    className="flex"
                    onClick={(event) => {
                      setSelectedBay();
                      handleClick(event);
                    }}
                  >
                    <img src={addIcon} alt="add-icon" />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div
          id={'scrollableDivBays'}
          className="items-center overflow-auto h-[60vh] no-scrollbar"
        >
          <InfiniteScroll
            dataLength={bays?.length} //This is important field to render the next data
            next={() => {
              setPage(page + 1);
              getDelayedWarehouseBays(page + 1, searchValue, bays);
            }}
            hasMore={page < pageCount}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>Loading</b>
              </p>
            }
            refreshFunction={() => {
              setPage(1);
              setBays([]);
              getDelayedWarehouseBays(1, searchValue, []);
            }}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8595; Pull down to refresh
              </h3>
            }
            scrollableTarget="scrollableDivBays"
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {bays?.length > 0 &&
              bays.map((bays, id) => {
                return (
                  <BayListComponent
                    warehouse={warehouse}
                    data={bays}
                    key={id}
                    searchValue={searchValue}
                    onClick={(event) => {
                      setSelectedBay(bays);
                      if (isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)) {
                        handleClick(event);
                      }
                    }}
                    getWarehouseBays={getWarehouseBays}
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </Box>
    </Grid>
  );
};

export default BaysView;

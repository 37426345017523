/*eslint-disable*/
import { CircularProgress } from '@mui/material';
import React from 'react';
import StatCard from './StatCard';
import { decimalDisplay, formatNumberToKAndM } from '../../utils/common';

export const OrderStats = ({ orderStats, orderTypeStats }) => {
  return (
    <div className="w-full h-auto bg-dark200 px-4 py-4 md:h-[90px] md:px-6 md:py-2 flex flex-wrap md:flex-nowrap shadow-sm items-center">
      {orderStats ? (
        <>
          <StatCard>
            <div className="mb-4 md:mb-0 md:mr-6">
              <p className="text-xs text-dark600">TOTAL ORDERS</p>
              <p className="text-lg md:text-xl text-primaryBlue font-semibold">
                {orderStats?.[0]?.value < 1000
                  ? decimalDisplay(orderStats?.[0]?.value || 0)
                  : formatNumberToKAndM(orderStats?.[0]?.value || 0)}
              </p>
            </div>
          </StatCard>
          <StatCard>
            <div className="flex flex-col md:flex-row">
              {orderTypeStats?.length > 0 &&
                orderTypeStats?.map((typeStat, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-4 md:mb-0 md:mr-6 w-full md:w-auto"
                    >
                      <p className="text-xs text-dark600 uppercase">
                        {typeStat?.label || typeStat} ORDERS
                      </p>
                      <p className="text-lg md:text-xl text-primaryBlue font-semibold">
                        {typeStat?.value < 1000
                          ? decimalDisplay(typeStat?.value || 0)
                          : formatNumberToKAndM(typeStat?.value || 0)}
                      </p>
                    </div>
                  );
                })}
            </div>
          </StatCard>
          <StatCard>
            <div className="flex flex-col md:flex-row">
              <div className="mb-4 md:mb-0 md:mr-6">
                <p className="text-xs text-dark600">CLOSED ORDERS</p>
                <p className="text-lg md:text-xl text-primaryBlue font-semibold">
                  {orderStats?.[2]?.value < 1000
                    ? decimalDisplay(orderStats?.[2]?.value || 0)
                    : formatNumberToKAndM(orderStats?.[2]?.value || 0)}
                </p>
              </div>
              <div className="mb-4 md:mb-0 md:mr-6">
                <p className="text-xs text-dark600">CANCELLED ORDERS</p>
                <p className="text-lg md:text-xl text-primaryBlue font-semibold">
                  {orderStats?.[3]?.value < 1000
                    ? decimalDisplay(orderStats?.[3]?.value || 0)
                    : formatNumberToKAndM(orderStats?.[3]?.value || 0)}
                </p>
              </div>
            </div>
          </StatCard>
        </>
      ) : (
        <div className="h-full w-full flex items-center justify-center">
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
};

import { useEffect, useState, useContext, useCallback } from 'react';
import {
  makeStyles,
  Paper,
  InputBase,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import TableHeader from '../../../components/TableHeader';
import axios from 'axios';
import { getURL, SharedContext } from '../../../utils/common';
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/EditOutlined';
import ConfirmDelete from '../../../components/ConfirmDelete';
import AddBrandView from './AddBrandView';
import PaginationGrid from '../../../components/PaginationGrid';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { checkPermission } from '../../../utils/auth';
import { toaster } from '../../../utils/toaster';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    // maxHeight: 450,
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInputBrand: {
    border: '1px solid grey',
    borderRadius: 4,
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    height: 30
  }
}));

export default function BrandView() {
  const classes = useStyles();
  const columnsBrand = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 'auto',
      className: ''
    },
    {
      id: 'manufacturerName',
      label: 'Manufacturer Name',
      minWidth: 'auto',
      className: ''
    },
    {
      id: 'isActive',
      label: 'Status',
      minWidth: 'auto',
      className: (value) => (value ? classes.active : ''),
      format: (value) => (value ? 'Active' : 'In-Active')
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 'auto',
      className: '',
      format: (value, entity) => [
        checkPermission(currentUser, 'OPS_BRAND_UPDATE') ? (
          <Tooltip title="Edit">
            <EditIcon key="edit" onClick={() => openEditView(entity)} />
          </Tooltip>
        ) : (
          ''
        )
        // <DeleteIcon color="error" key="delete" onClick={() => openDeleteView(entity)} />
      ]
    }
  ];
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [formErrors, setFormErrors] = useState('');
  const [addBrandViewOpen, setAddBrandViewOpen] = useState(false);
  const [deleteBrandViewOpen, setDeleteBrandViewOpen] = useState(false);
  const { currentUser } = useContext(SharedContext);

  const addBrand = (data) => {
    let apiPromise = null;
    if (!selectedBrand) apiPromise = axios.post(getURL(`brands`), data);
    else apiPromise = axios.put(getURL(`brands/${selectedBrand.id}`), data);
    apiPromise.then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      toaster(
        'success',
        !selectedBrand
          ? 'New Brand has been created.'
          : 'Brand updated successfully.'
      );
      closeAddBrandView(false);
      getBrands();
    });
  };

  const deleteBrand = () => {
    axios.delete(getURL(`brand/${selectedBrand.id}`)).then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      closeDeleteBrandView();
      getBrands();
    });
  };

  const openEditView = (brand) => {
    setSelectedBrand(brand);
    setAddBrandViewOpen(true);
  };

  const closeAddBrandView = () => {
    setSelectedBrand(null);
    setAddBrandViewOpen(false);
  };

  const closeDeleteBrandView = () => {
    setSelectedBrand(null);
    setDeleteBrandViewOpen(false);
  };

  const _getBrands = (page, searchKeyword) => {
    axios
      .get(getURL('brand'), { params: { page, search: searchKeyword } })
      .then((res) => {
        setPageCount(res.data.pages);
        setBrands(res.data.data);
      });
  };

  const getBrands = useCallback(
    debounce((page, searchKeyword) => {
      _getBrands(page, searchKeyword);
    }, DEBOUNCE_CONST),
    []
  );

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);

  useEffect(() => {
    let title = 'Brand';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    getBrands(page, searchKeyword);
  }, [page, searchKeyword]);

  const searchInputBrand = (
    <InputBase
      placeholder="Search"
      className={classes.searchInputBrand}
      id="search"
      label="Search"
      type="text"
      variant="outlined"
      value={searchKeyword}
      key={1}
      onChange={(e) => setSearchKeyword(e.target.value)}
    />
  );
  const addBrandButton = (
    <Button
      key={2}
      variant="contained"
      color="primary"
      size="small"
      onClick={() => setAddBrandViewOpen(true)}
    >
      ADD BRAND
    </Button>
  );
  const addBrandModal = (
    <AddBrandView
      key={3}
      formErrors={formErrors}
      selectedBrand={selectedBrand}
      open={addBrandViewOpen}
      addBrand={addBrand}
      handleClose={() => closeAddBrandView()}
    />
  );
  const deleteBrandModal = (
    <ConfirmDelete
      key={4}
      confirmDelete={deleteBrand}
      open={deleteBrandViewOpen}
      handleClose={closeDeleteBrandView}
      selectedEntity={selectedBrand && selectedBrand.name}
      title={'Brand'}
    />
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_BRAND_READ') ? searchInputBrand : null,
    checkPermission(currentUser, 'OPS_BRAND_CREATE') ? addBrandButton : null,
    checkPermission(currentUser, 'OPS_BRAND_CREATE') ||
    checkPermission(currentUser, 'OPS_BRAND_UPDATE')
      ? addBrandModal
      : null,
    checkPermission(currentUser, 'OPS_BRAND_DELETE') ? deleteBrandModal : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnsBrand.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {brands.map((brand) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={brand.id}>
                  {columnsBrand.map((column) => {
                    const value = brand[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={
                          column.className &&
                          typeof column.className === 'function'
                            ? column.className(value)
                            : column.className
                        }
                      >
                        {column.format ? column.format(value, brand) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}

/*eslint-disable*/
import axios from 'axios';
import config from '../config/';
import { getUserToken, setUserToken } from '../utils/auth';
const baseInstance = axios.create({
  baseURL: config.REACT_APP_API_PATH
});

baseInstance.defaults.headers.common['Accept'] = 'application/json';
baseInstance.defaults.headers.common['Content-Type'] = 'application/json';
baseInstance.defaults.withCredentials = true;

baseInstance.interceptors.request.use(async function (config) {
  const url = getDynamicURL(config.url);
  config.url = `${config.baseURL}/${url}`;

  const token = getUserToken();
  if (config.method !== 'get') {
    const secretToken = await baseInstance.get(`auth/csrftoken`);
    config.headers['X-CSRF-TOKEN'] = secretToken?.csrfToken?.trim();
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

baseInstance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    throw err;
  }
);

baseInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/login' && err.response) {
      if (
        err?.response?.data?.error?.name === 'TokenExpiredError' &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        try {
          const rs = await baseInstance.post(
            `${config.REACT_APP_API_PATH}/auth/refresh`
          );
          if (rs && !rs.error) {
            setUserToken(rs.token);
            originalConfig.headers.Authorization = `Bearer ${rs.token}`;
          }

          return baseInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

const getDynamicURL = (url) => {
  const host = window.location.host;
  let subdomain = host.split('.')[0];
  const excludeUrls = [
    'auth',
    'organizations',
    'uploads',
    'users',
    'embedded-reports',
    'stripe'
  ];
  let organizationPrefix = true;

  excludeUrls.forEach((endpoint) => {
    if (url.indexOf(endpoint) !== -1) {
      organizationPrefix = false;
    }
  });

  if (subdomain && organizationPrefix) {
    if (url[0] === '/') {
      return `organizations/${subdomain}/${url.substring(1)}`;
    }
    return `organizations/${subdomain}/${url}`;
  } else {
    return url;
  }
};

export default baseInstance;

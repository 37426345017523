import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Tab, Tabs, makeStyles } from '@material-ui/core';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import DetailText from '../../../atomicComponents/DetailText';
import OrganizationDetails from './OrganizationDetails';
import { onError } from '../../../libs/errorLib';
import { useFormik } from 'formik';
import InvoiceTitles from './InvoiceTitles';
import { toaster } from '../../../utils/toaster';
import { settingsFormSchema } from './formikFields';
import { upload } from '../../../utils/upload';
import Taxes from './Taxes';
import mixpanel from 'mixpanel-browser';

export default function Settings() {
  const useStyles = makeStyles(() => ({
    container: {
      width: '95%',
      margin: 'auto',
      height: '100%',
      marginBottom: '1rem',
      marginTop: '1.5rem',
      '& ::-webkit-scrollbar': {
        width: '0'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& .Mui-disabled': {
        background: '#F5F7FA !important',
        color: '#0B1940'
      }
    },
    borderBottom: {
      borderBottom: '1px solid #dcdcdc',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '1.5rem'
    },
    tabsContainer: {
      '& .MuiTabs-indicator': {
        backgroundColor: '#0C71D7',
        height: '4px'
      },
      '& .MuiTabs-root': {
        minHeight: '100%'
      }
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '0.2rem',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: '-1rem',
      marginRight: '1rem',
      padding: '10px',
      boxSizing: 'border-box',
      borderTop: '1px solid #CCCCCC',
      zIndex: 100,
      height: '70px',
      '& button': {
        marginRight: '0.3rem'
      }
    },
    editBtn: {
      background: '#0C71D7',
      padding: '8px 40px 8px 40px',
      width: '240px',
      fontWeight: 700,
      textTransform: 'none',
      fontSize: '16px',
      '&:hover': {
        background: '#0C71D7',
        opacity: '50%'
      }
    },
    cancelBtn: {
      padding: '8px 40px 8px 40px',
      width: '240px',
      fontWeight: 700,
      textTransform: 'none',
      fontSize: '16px'
    }
  }));
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [organization, setOrganization] = useState({});
  const [invoiceTitles, setInvoiceTitles] = useState([]);
  const [resetStates, setResetStates] = useState({});
  const [edit, setEdit] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [taxes, setTaxes] = useState([]);

  const { setCurrentPageTitle, setAPILoader } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: {
      organization: {
        name: '',
        ntn: '',
        email: '',
        address: '',
        bankName: '',
        bankBranch: '',
        accountTitle: '',
        accountNumber: '',
        iban: '',
        swiftCode: '',
        logo: ''
      }
    },
    enableReinitialize: true,
    validationSchema: settingsFormSchema,
    validateOnChange: firstSubmit,
    onSubmit: async (values) => {
      let payload = { organization: values.organization };
      payload.deleteTitles = values.titles.filter((title) => title.isDeleted);
      payload.updateTitles = values.titles.filter((title) => !title.isNew);
      payload.newTitles = values.titles.filter((title) => title.isNew);
      payload.deleteTaxes = values.taxes.filter((tax) => tax.isDeleted);
      payload.updateTaxes = values.taxes.filter((tax) => !tax.isNew);
      payload.newTaxes = values.taxes.filter((tax) => tax.isNew);
      try {
        setAPILoader(true);
        if (payload.organization.logo instanceof File) {
          let file = await upload([payload.organization.logo], 'organization');
          payload.organization.logo = file[0];
        }
        await API.post('update-business-information', payload);
        setEdit(false);
        setFirstSubmit(false);
        await getOrganization();
        await getInvoiceTitles();
        await getTaxes();
        toaster('success', 'Information saved successfully');
      } catch (e) {
        onError(e);
      } finally {
        setAPILoader(false);
      }
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getOrganization = async () => {
    try {
      setAPILoader(true);
      const organization = await API.get('check');
      setOrganization(organization.organization);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getInvoiceTitles = async () => {
    try {
      setAPILoader(true);
      const titles = await API.get('invoice-titles');
      setInvoiceTitles(titles.invoiceTitles);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };
  const getTaxes = async () => {
    try {
      setAPILoader(true);
      const taxes = await API.get('taxes');
      setTaxes(taxes.taxes);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    setCurrentPageTitle('Settings');
    getOrganization();
    getInvoiceTitles();
    getTaxes();
    mixpanel.track('Billing and Invoices Settings', {});
  }, []);

  useEffect(() => {
    if (organization) {
      formik.setValues({
        organization: {
          name: organization.name,
          ntn: organization.ntn,
          address: organization.address,
          email: organization.email,
          accountTitle: organization.accountTitle,
          accountNumber: organization.accountNumber,
          bankName: organization.bankName,
          bankBranch: organization.bankBranch,
          iban: organization.iban,
          swiftCode: organization.swiftCode,
          logo: organization.logo
        },
        taxes: formik.values.taxes,
        titles: formik.values.titles
      });
    }
  }, [organization]);

  useEffect(() => {
    if (organization)
      formik.setValues({
        organization: formik.values.organization,
        titles: invoiceTitles.map((title) => ({
          name: title.name,
          id: title.id
        })),
        taxes: formik.values.taxes
      });
  }, [invoiceTitles]);

  useEffect(() => {
    if (organization && invoiceTitles) {
      formik.setValues({
        organization: formik.values.organization,
        titles: invoiceTitles.map((title) => ({
          name: title.name,
          id: title.id
        })),
        taxes: taxes.map((tax) => ({
          name: tax.name,
          rate: tax.rate,
          id: tax.id
        }))
      });
      setResetStates({
        organization: formik.values.organization,
        titles: invoiceTitles.map((title) => ({
          name: title.name,
          id: title.id
        })),
        taxes: taxes.map((tax) => ({
          name: tax.name,
          rate: tax.rate,
          id: tax.id
        }))
      });
    }
  }, [taxes]);

  useEffect(() => {
    if (organization) {
      formik.setValues({
        organization: formik.values.organization,
        titles: invoiceTitles.map((title) => ({
          name: title.name,
          id: title.id
        }))
      });
    }
  }, [invoiceTitles]);

  const reset = () => {
    setEdit(false);
    setFirstSubmit(false);
    formik.setValues(resetStates);
  };

  return (
    <Grid item container className={classes.container}>
      <Box
        style={{
          height: 'calc(100% - 70px - 2rem)',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        <Box className={classes.borderBottom}>
          <Box className={classes.tabsContainer}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={
                  <DetailText
                    text="Business information"
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                    active={value == 0}
                  />
                }
              />
              <Tab
                label={
                  <DetailText
                    text="Invoice titles"
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                    active={value == 1}
                  />
                }
              />
              <Tab
                label={
                  <DetailText
                    text="Tax details"
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                    active={value == 2}
                  />
                }
              />
            </Tabs>
          </Box>
        </Box>
        {value == 0 && (
          <OrganizationDetails
            organization={organization || {}}
            formik={formik}
            edit={edit}
          />
        )}
        {value == 1 && <InvoiceTitles formik={formik} edit={edit} />}
        {value == 2 && <Taxes formik={formik} edit={edit} />}
      </Box>
      <Box className={classes.buttons}>
        {edit ? (
          <>
            <Button
              variant="outlined"
              onClick={() => reset()}
              className={classes.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.editBtn}
              color="primary"
              onClick={() => {
                setFirstSubmit(true);
                formik.handleSubmit();
              }}
            >
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEdit(true)}
            className={classes.editBtn}
          >
            Edit Settings
          </Button>
        )}
      </Box>
    </Grid>
  );
}

import React, { useContext } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../../components/Page';
import { SharedContext } from '../../utils/common';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();
  const { subdomain } = useContext(SharedContext);

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          {subdomain && (
            <Typography variant="h1" align="center">
              <Button
                size="medium"
                to={'/control-tower'}
                color="secondary"
                variant="contained"
                component={RouterLink}
              >
                Go to home
              </Button>
            </Typography>
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;

/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Grid, InputAdornment } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RemoveRedEye from '@mui/icons-material/RemoveRedEyeOutlined';
import EyeIcon from '../../assets/icons/eye.svg';
import Button from '../../core-components/atoms/Button';
import Map from '../revampedOrder/Map';
import API from '../../libs/axios';
import { useNavigate } from 'react-router';
import OptionsMenu from '../revampedOrder/Options';
import Popup from '../../core-components/atoms/Popup';
import CustomPagination from '../invoiceandbilling/invoiceManagement/CustomPagination';
import { convertToTitleCase } from '../revampedOrder/AddOrder';
import { onError } from '../../libs/errorLib';
import { SharedContext, getCurrencySymbol } from '../../utils/common';
import BulkUpload from './NodeBulkUpload';
import NodeBulkUploadTemplate from '../../assets/files/NodesBulkUploadTemplate.xlsx';
import { toaster } from '../../utils/toaster';
import { Title } from '../../core-components/atoms/Text';
import clsx from 'clsx';
import {
  makeStyles,
  TextField,
  ThemeProvider,
  Tooltip
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { createTheme } from '@material-ui/core/styles';
import { BACKDROP, OASIS_PRIMARY_500 } from '../../constants/colors';
import { nodeTypes } from './constants';
import { checkPermission } from '../../utils/auth';
import EditOrganization from './EditOrganization';

const NodeRow = ({ node, listView, refetch }) => {
  const [mapOpen, setMapOpen] = useState(false);
  const navigate = useNavigate();

  const { setAPILoader, currentUser } = useContext(SharedContext);

  const deleteNode = async () => {
    try {
      setAPILoader(true);
      await API.delete(`nodes/${node.id}`);
      await refetch();
      toaster('success', 'Node deleted successfully');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Grid
      container
      className={`mt-2 mr-2 my-4 rounded shadow ${
        !listView ? 'w-[92%] ml-4' : 'w-[100%] ml-1'
      } bg-[#F0F7FF] px-4 py-1 group`}
      spacing={2}
    >
      <Grid item xs={3}>
        <div className="flex h-full gap-2 items-center">
          <div
            className={clsx(
              `h-3 w-3 rounded-full border border-solid border-[#9DA3B3]`,
              node.isActive && 'border-[#1AAE2E] bg-[#1AAE2E]'
            )}
          />
          <Grid className={'overflow-hidden'}>
            <Title variant="sm" className={'truncate w-40'}>
              {node.code ? `${node.code} - ${node.name}` : node.name}
            </Title>
            <p className="text-[10px] text-[#4B5369] mt-2">
              {nodeTypes['3PL_INTERNAL'][node.nodeType.toUpperCase()]}
            </p>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={4} className="py-5">
        <span className="text-xs">{node.address}</span>
      </Grid>
      <Grid item xs={2} className="py-4">
        {node.nodeClass == 'INTERNAL' ? (
          <>
            <span className="text-xs">{node.Warehouse?.capacity} Sq. Ft.</span>
            <p className="text-xs mt-1">
              <span className="font-medium">Pallets : </span>
              <span>{node.Warehouse?.pallets}</span>
            </p>
          </>
        ) : (
          <>
            <span className="text-xs">{node.pocName || '-'}</span>
            {node.pocContact ? (
              <p className="text-xs mt-1">
                <span>{node.pocContact}</span>
              </p>
            ) : (
              <p>-</p>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={3}>
        <div className="flex justify-end items-center gap-2 h-full">
          <div className="group-hover:visible invisible">
            {node.nodeClass == 'INTERNAL' && (
              <Button
                label="View"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/administration/node/${node.id}/view`, {
                    state: node
                  });
                }}
                variant="transparent"
                icon={<img src={EyeIcon} className="mr-[2px]" />}
              />
            )}
          </div>
          <OptionsMenu
            options={[
              checkPermission(currentUser, 'OPS_NODE_UPDATE') && {
                label: (
                  <div className="flex gap-3 items-center">
                    <ModeEditOutlineTwoToneIcon color={'disabled'} />
                    <span>Edit</span>
                  </div>
                ),
                onClick: () => {
                  navigate(`/administration/node/${node.id}/edit`);
                }
              },
              checkPermission(currentUser, 'OPS_NODE_DELETE') && {
                label: (
                  <div className="flex gap-3 items-center">
                    <DeleteOutlineIcon color={'disabled'} />
                    <span>Delete</span>
                  </div>
                ),
                onClick: () => {
                  deleteNode();
                }
              }
            ]}
          />
        </div>
      </Grid>
      <Popup
        open={mapOpen}
        setOpen={setMapOpen}
        content={
          <div>
            <Grid container spacing={2} className="mb-2 w-[800px]">
              <Grid item xs={2}>
                <p className="text-[#67718C] font-medium text-xs mb-1">
                  NODE TYPE
                </p>
                <p className="text-sm">{convertToTitleCase(node?.nodeType)}</p>
              </Grid>
              <Grid item xs={4}>
                <p className="text-[#67718C] font-medium text-xs mb-1">
                  {node.nodeClass != 'INTERNAL' ? 'POC' : 'CAPACITY'}
                </p>
                <p className="text-sm">
                  {node.nodeClass != 'INTERNAL'
                    ? node.pocName || '-'
                    : !node?.Warehouse?.capacity && !node?.Warehouse?.pallets
                    ? `-`
                    : `${
                        node.Warehouse?.capacity &&
                        node.Warehouse?.capacity + ' Sq. ft. - '
                      } ${
                        node.Warehouse?.pallets &&
                        node.Warehouse?.pallets + ' Pallets'
                      }`}
                </p>
              </Grid>
              <Grid item xs={5}>
                <p className="text-[#67718C] font-medium text-xs mb-1">
                  ADDRESS
                </p>
                <p className="text-sm">
                  {node.address ? convertToTitleCase(node?.address) : '-'}
                </p>
              </Grid>
            </Grid>
            <div className="w-[100%] h-[400px] relative">
              <Map
                singleLocationLatlng={node.locationLatLng}
                editable={false}
              />
            </div>
          </div>
        }
        onClose={() => {
          setMapOpen(false);
        }}
        actions={
          <div className="flex gap-2 text-white" style={{ color: 'white' }}>
            <Button
              label="Close"
              variant="primary"
              onClick={async () => {
                setMapOpen(false);
              }}
            />
          </div>
        }
        title={`${node.code ? node.code + ' - ' : ''}${node.name}`}
        dialogContentClasses=""
        disablePortal={false}
      />
    </Grid>
  );
};

// const NodeCard = ({ node, refetch }) => {
//   return (
//     <TableRow
//       sx={{
//         '& > *': { borderBottom: 'unset' }
//       }}
//     >
//       <TableCell colSpan={7} style={{ padding: 0 }}>
//         <NodeRow node={node} refetch={refetch} />
//       </TableCell>
//     </TableRow>
//   );
// };

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

function Row({
  row,
  showHighlight,
  setNodeAddOpen,
  setEntityInfo,
  refetch,
  setOpen,
  open
}) {
  const [nodeListOpen, setNodeListOpen] = useState(false);
  const [nodePage, setNodePage] = useState(1);
  const [nodes, setNodes] = useState([]);
  const [nodePages, setNodePages] = useState(0);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [file, setFile] = useState(null);
  const ref = useRef();
  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });
  const toolTipClasses = useStylesBootstrap();
  const [search, setSearch] = useState('');

  const { setAPILoader, currentUser } = useContext(SharedContext);

  const deleteEntity = async () => {
    try {
      setAPILoader(true);
      if (row.type !== 'Organization') {
        await API.delete(`companies/${row.id}`);
        await refetch();
        toaster(
          'success',
          row.type == 'Customer'
            ? 'Customer deleted successfully'
            : 'Supplier deleted successfully'
        );
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getCustomerNodes = async (search) => {
    try {
      let response = {};
      setAPILoader(true);
      if (row.type !== 'Organization') {
        response = await API.get(`nodes`, {
          params: {
            page: Number(nodePage),
            companyId: row.id,
            limit: 5,
            search: search
          }
        });
      } else {
        response = await API.get(`nodes`, {
          params: {
            page: Number(nodePage),
            limit: 5,
            search: search
          }
        });
      }

      setNodes(response.data);
      setNodePages(response.pages);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const onBulkUpload = async (nodes) => {
    try {
      setAPILoader(true);
      await API.post(
        row?.type == 'Organization'
          ? `nodes/bulk`
          : `nodes/bulk?companyId=${row.id}`,
        nodes
      );
      setBulkUploadOpen(false);
      await refetch();
      toaster('success', `Nodes have been uploaded`);
    } catch (err) {
      console.log(err);
    } finally {
      setAPILoader(false);
    }
  };

  const navigate = useNavigate();
  const { organization } = useContext(SharedContext);

  useEffect(() => {
    if (nodes.length > 0) {
      getCustomerNodes(search);
    }
  }, [nodePage, search]);

  useEffect(() => {
    if (search) getCustomerNodes(search);
  }, [search]);

  return (
    <>
      <input
        type="file"
        ref={ref}
        hidden
        onChange={(e) => {
          setBulkUploadOpen(true);
          setFile(e.target.files[0]);
        }}
        onClick={(e) => {
          setFile(null);
          e.target.value = null;
        }}
        accept=".csv,.xlsx,.xls"
      />

      <BulkUpload
        open={bulkUploadOpen}
        file={file}
        setOpen={setBulkUploadOpen}
        entity={{
          name: row?.name,
          entityType: row?.type,
          type: row?.entityType ? convertToTitleCase(row.entityType) : '',
          code: row?.code,
          address: row?.billingAddress,
          id: row?.id
        }}
        refetch={refetch}
        onSubmit={onBulkUpload}
      />
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: '1px solid #E7E6E6'
          }
        }}
        className="group hover:cursor-pointer"
      >
        <TableCell style={{ width: '25', padding: 0, textAlign: 'left' }}>
          <div className="min-h-[80px] p-4 h-full">
            <p
              className={`text-sm font-medium ${
                showHighlight && 'bg-highlight'
              }`}
            >
              {row.name}
            </p>
            <p className="text-xs mt-1">Code: {row.code || '-'}</p>
          </div>
        </TableCell>
        <TableCell style={{ width: '10%', padding: 0 }}>
          <div className="min-h-[80px] py-4 h-full">
            <p className="text-xs">{row.type}</p>
            <p className="text-xs mt-1">
              <span className="font-medium">Class : </span>
              <span>
                {row.type == 'Organization' ? 'Internal' : 'External'}
              </span>
            </p>
          </div>
        </TableCell>
        <TableCell style={{ width: '10%', padding: 0, textAlign: 'left' }}>
          <div className="min-h-[80px] py-4 h-full">
            {!row.creditLimit && !row.paymentTerms ? (
              <span>-</span>
            ) : (
              <div className="flex flex-col gap-2">
                {row.paymentTerms && (
                  <p className="text-xs">{row.paymentTerms?.title}</p>
                )}
                {row.creditLimit !== 0 && row.creditLimit && (
                  <p className="text-xs font-medium">
                    Credit Limit:{' '}
                    <span className="font-normal">
                      {getCurrencySymbol(organization?.defaultCurrency)}{' '}
                      {row.creditLimit}
                    </span>
                  </p>
                )}
              </div>
            )}
          </div>
        </TableCell>
        <TableCell style={{ width: '15%', padding: 0 }}>
          <div className="min-h-[80px] py-4 h-full">
            <span className="text-xs">{row.ntn || '-'}</span>
          </div>
        </TableCell>
        <TableCell style={{ width: '20%', padding: 0 }}>
          <div className="min-h-[80px] py-4 h-full">
            <span className="text-xs">{row.billingAddress}</span>
          </div>
        </TableCell>
        <TableCell style={{ width: '10%', padding: 0 }}>
          <div className="min-h-[80px] py-4 h-full pl-4">
            <span className="text-xs">{row.activeNodes}</span>
          </div>
        </TableCell>
        <TableCell style={{ width: '10%', padding: 0, paddingRight: '10px' }}>
          <div className="min-h-[80px] py-4 h-full">
            <div className="flex gap-3 items-center justify-end">
              {row.type !== 'Organization' && (
                <div
                  className={`group-hover:visible ${
                    open === row?.id ? 'visible' : 'invisible'
                  }`}
                >
                  <Tooltip
                    arrow
                    title="View Entity"
                    aria-label="add"
                    placement="top"
                    classes={toolTipClasses}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        row.type == 'Customer'
                          ? navigate(`/administration/customer/${row.id}/view`)
                          : navigate(`/administration/supplier/${row.id}/view`);
                      }}
                    >
                      <RemoveRedEye style={{ color: BACKDROP }} />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {row.type !== 'Organization' ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <OptionsMenu
                    styleIcon={{ color: BACKDROP }}
                    options={[
                      checkPermission(currentUser, 'OPS_COMPANY_UPDATE') && {
                        label: (
                          <div className="flex items-center">
                            <ModeEditOutlineTwoToneIcon color={'disabled'} />
                            <span className="ml-2">Edit Entity</span>
                          </div>
                        ),
                        onClick: () => {
                          row.type == 'Customer'
                            ? navigate(`/administration/company/${row.id}/edit`)
                            : navigate(
                                `/administration/supplier/${row.id}/edit`
                              );
                        }
                      },
                      checkPermission(currentUser, 'OPS_NODE_CREATE') && {
                        label: (
                          <div className="flex items-center">
                            <AddIcon color={'disabled'} />
                            <span className="ml-2">Add Node</span>
                          </div>
                        ),
                        onClick: () => {
                          setEntityInfo({
                            nodeClass:
                              row?.type == 'Organization'
                                ? 'Internal'
                                : 'External',
                            entity: {
                              address: row?.billingAddress,
                              code: null,
                              entityType: row?.type,
                              label: row?.name,
                              name: row?.name,
                              type: row?.entityType,
                              value: row?.id
                            }
                          });
                          setNodeAddOpen(true);
                        }
                      },
                      checkPermission(currentUser, 'OPS_NODES_BULK_UPLOAD') && {
                        label: (
                          <a href={NodeBulkUploadTemplate}>
                            <div className="flex items-center">
                              <FileDownloadIcon color={'disabled'} />
                              <span className="ml-2">Download Template</span>
                            </div>
                          </a>
                        ),
                        onClick: () => {}
                      },
                      checkPermission(currentUser, 'OPS_NODES_BULK_UPLOAD') && {
                        label: (
                          <div className="flex items-center">
                            <FileUploadIcon color={'disabled'} />
                            <span className="ml-3">Bulk Upload</span>
                          </div>
                        ),
                        onClick: () => {
                          ref.current.click();
                        }
                      },
                      checkPermission(currentUser, 'OPS_COMPANY_DELETE') && {
                        label: (
                          <div className="flex items-center">
                            <DeleteOutlineIcon color={'disabled'} />
                            <span className="ml-2">Delete</span>
                          </div>
                        ),
                        onClick: () => {
                          deleteEntity();
                        }
                      }
                    ]}
                  />
                </div>
              ) : (
                <div onClick={(e) => e.stopPropagation()}>
                  <OptionsMenu
                    styleIcon={{ color: BACKDROP }}
                    options={[
                      {
                        label: (
                          <div className="flex items-center">
                            <ModeEditOutlineTwoToneIcon color={'disabled'} />
                            <span className="ml-2">Edit Entity</span>
                          </div>
                        ),
                        onClick: () => {
                          navigate(`/administration/organization/edit`);
                        }
                      },
                      checkPermission(currentUser, 'OPS_NODE_CREATE') && {
                        label: (
                          <div className="flex items-center">
                            <AddIcon color={'disabled'} />
                            <span className="ml-2">Add Node</span>
                          </div>
                        ),
                        onClick: () => {
                          setEntityInfo({
                            nodeClass:
                              row?.type == 'Organization'
                                ? 'Internal'
                                : 'External',
                            entity: {
                              entityType: 'Organization',
                              label: row?.name,
                              name: row?.name,
                              type: row?.type,
                              value: row?.id
                            }
                          });
                          setNodeAddOpen(true);
                        }
                      },
                      checkPermission(currentUser, 'OPS_NODES_BULK_UPLOAD') && {
                        label: (
                          <a href={NodeBulkUploadTemplate}>
                            <div className="flex items-center">
                              <FileDownloadIcon color={'disabled'} />
                              <span className="ml-2">Download Template</span>
                            </div>
                          </a>
                        ),
                        onClick: () => {}
                      },
                      checkPermission(currentUser, 'OPS_NODES_BULK_UPLOAD') && {
                        label: (
                          <div className="flex items-center">
                            <FileUploadIcon color={'disabled'} />
                            <span className="ml-3">Bulk Upload</span>
                          </div>
                        ),
                        onClick: () => {
                          ref.current.click();
                        }
                      }
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
      <Popup
        open={nodeListOpen}
        setOpen={setNodeListOpen}
        title={row?.name}
        content={
          <div className="flex flex-col gap-2 h-[500px] w-[825px]">
            <p className="text-xs">List of All Nodes for {row?.name}</p>
            <div className="display-flex mt-4 justify-between items-center">
              <div className="flex justify-between">
                <p className="font-medium">{row?.numberOfNodes} Nodes</p>
              </div>
              <div className={'display-flex'}>
                <div className={'w-64 mr-3'}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      fullWidth
                      placeholder="Search"
                      variant="outlined"
                      color={'primary'}
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      size="small"
                      inputProps={{
                        onFocus: () => search
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment>
                            <Search
                              style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '10px'
                              }}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {search && (
                              <Close
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  setSearch('');
                                }}
                              />
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </div>
        }
        onClose={() => {
          setNodePage(1);
        }}
        actions={
          <div className="flex justify-end">
            <CustomPagination
              pages={nodePages}
              page={nodePage}
              setPage={setNodePage}
            />
            {/*<Button*/}
            {/*  label="Close"*/}
            {/*  variant="primary"*/}
            {/*  onClick={() => {*/}
            {/*    setNodeListOpen(false);*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
        }
      />
    </>
  );
}

const ExpandableTable = ({
  staticRow,
  rows,
  page,
  showHighlight,
  setNodeAddOpen,
  setEntityInfo,
  refetch
}) => {
  const [organizationRowEdit, setOrganizationRowEdit] = useState(false);
  const ref = useRef();

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="text-headerCell font-medium py-2">
                Entity
              </TableCell>
              <TableCell className="text-headerCell font-medium p-0 py-2">
                Type
              </TableCell>
              <TableCell className="text-headerCell font-medium p-0 py-2">
                Payment Terms
              </TableCell>
              <TableCell className="text-headerCell font-medium p-0 py-2">
                Tax Identification Number (TIN)
              </TableCell>
              <TableCell className="text-headerCell font-medium p-0 py-2">
                Billing Address
              </TableCell>
              <TableCell
                className="text-headerCell font-medium py-2 pl-4"
                style={{ textAlign: 'left' }}
              >
                Active Nodes
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {page == 1 && Object.keys(staticRow).length > 0 && (
              <Row
                row={staticRow}
                tableRef={ref}
                refetch={refetch}
                setNodeAddOpen={() => {
                  setNodeAddOpen(true);
                }}
                setEntityInfo={setEntityInfo}
                open={open}
              />
            )}
            {rows.map((row) => {
              let entity = {
                name: row?.name,
                type: row.relationType == 'SUPPLIER' ? 'Supplier' : 'Customer',
                paymentTerms: row.PaymentTerm,
                creditLimit: row.creditLimit,
                ntn: row?.ntn,
                billingAddress: row?.billingAddress,
                nodes: row?.Nodes || [],
                code: row?.code,
                id: row?.id,
                activeNodes: row?.activeNodeCount,
                numberOfNodes: row?.nodeCount,
                entityType: row?.type
              };
              return (
                <Row
                  row={entity}
                  key={row?.id}
                  showHighlight={showHighlight}
                  refetch={refetch}
                  setNodeAddOpen={() => {
                    setNodeAddOpen(true);
                  }}
                  setEntityInfo={setEntityInfo}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {organizationRowEdit && (
        <EditOrganization
          edit={true}
          open={organizationRowEdit}
          setOpen={setOrganizationRowEdit}
        />
      )}
    </>
  );
};

export default ExpandableTable;

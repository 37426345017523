export default Object.freeze([
  {
    label: 'Logistics',
    value: 'Logistics'
  },
  {
    label: 'Warehousing',
    value: 'Warehousing'
  },
  {
    label: 'Technology',
    value: 'Technology'
  },
  {
    label: 'Land',
    value: 'Land'
  },
  {
    label: 'Air',
    value: 'Air'
  },
  {
    label: 'Sea',
    value: 'Sea'
  }
]);

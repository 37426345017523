/*eslint-disable*/
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useContext, useEffect, useState } from 'react';
import TextField from '../../core-components/atoms/TextField';
import API from '../../libs/axios';
import { dividerDateFormat12Hrs } from '../../utils/common';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import { onError } from '../../libs/errorLib';
import moment from 'moment';
import ShipmentMap from './ShipmentMap';
import { PRIMARY } from '../../constants/colors';
import AddVehicleTypeView from '../logistics/vehicletype/AddVehicleTypeView';
import { toaster } from '../../utils/toaster';
import AddVehicleView from '../logistics/vehicle/AddVehicleView';
import AddDriverView from '../logistics/driver/AddDriverView';
import { getUser } from '../../utils/auth';
import { SharedContext } from '../../utils/common';
import AddCompanyView from '../administration/customer/AddCompanyView';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import AddDriverPopup from '../logisticsRevamped/driver/components/driverPopup';
import AddcarrierPopup from '../logisticsRevamped/carrier/components/carrierPopup';
import AddVehicleTypePopup from '../logisticsRevamped/vehicleType/components/vehicleTypePopup';
import VehiclePopup from '../logisticsRevamped/vehicle/VehiclePopup';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';

const AddShipment = ({ order, view, shipment, formik, vehicleView }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [cars, setCars] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState({});
  const [selectedDriver, setSelectedDriver] = useState({});
  const [selectedCar, setSelectedCar] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState({});

  const [driverSearch, setDriverSearch] = useState('');
  const [carrierSearch, setCarrierSearch] = useState('');
  const [vehicleTypeSearch, setVehicleTypeSearch] = useState('');
  const [vehicleSearch, setVehicleSearch] = useState('');

  const [addVehicleTypeViewOpen, setAddVehicleTypeViewOpen] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  const [attemptedAlready, setAttemptedAlready] = useState(false);

  const [attemptedAlreadyVehicle, setAttemptedAlreadyVehicle] = useState(false);
  const [addVehicleView, setAddVehicleView] = useState(false);

  const [addDriverView, setAddDriverView] = useState(false);
  const [attemptedAlreadyDriver, setAttemptedAlreadyDriver] = useState(false);

  const [addCompanyViewOpen, setAddCompanyViewOpen] = useState(false);
  const [attemptedAlreadyCarrier, setAttemptedAlreadyCarrier] = useState(false);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [users, setUsers] = useState([]);

  const { subdomain, organization, settings } = useContext(SharedContext);

  const getCars = async (search) => {
    try {
      const vehicles = await API.get(`vehicles/types`, {
        params: { search: search || '', isActive: true }
      });
      setCars(
        vehicles?.data
          ? vehicles?.data.filter(
              (vehicleType) => vehicleType.isActive === true
            )
          : []
      );
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getCarriers = async (search) => {
    try {
      const carriers = await API.get(`companies`, {
        params: {
          search: search || '',
          type: 'VENDOR',
          filters: { colVal: { isActive: '1' }, sortable: '' },
          columns: ['name']
        }
      });
      setCarriers(carriers?.data?.rows ? carriers?.data?.rows : []);
      setCustomerTypes(carriers?.data?.types || []);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const addVehicle = async (data) => {
    setFormErrors('');
    try {
      setAttemptedAlready(true);
      await API.post(`vehicles`, data);
      toaster('success', 'New vehicle has been created.');
      closeAddVehicleViewModal(false);
      getVehicle();
    } catch (err) {
      onError(err);
    } finally {
      setAttemptedAlready(false);
    }
  };

  const getDrivers = async (search) => {
    try {
      if (selectedCarrier?.id) {
        const drivers = await API.get(`drivers`, {
          params: { search: search || '', companyId: selectedCarrier?.id }
        });
        setDrivers(drivers?.data ? drivers?.data : []);
      }
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const addVehicleType = async (data) => {
    setFormErrors('');
    try {
      setAttemptedAlready(true);
      await API.post(`vehicles/types`, data);
      toaster('success', 'Vehicle Type has been created.');
      closeAddVehicleTypeView();
      getCars();
    } catch (err) {
      onError(err);
    } finally {
      setAttemptedAlready(false);
    }
  };

  const closeAddVehicleTypeView = () => {
    setAddVehicleTypeViewOpen(false);
  };

  const getVehicle = async (search) => {
    try {
      if (selectedCarrier?.id && selectedCar?.id) {
        const drivers = await API.get(`vehicles`, {
          params: {
            search: search || '',
            companyId: selectedCarrier?.id,
            carId: selectedCar?.id
          }
        });
        setVehicles(drivers?.data ? drivers?.data : []);
      }
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const addDriver = async (data) => {
    if (attemptedAlready) return;
    setFormErrors('');
    try {
      setAttemptedAlready(true);
      await API.post(`drivers`, data);
      toaster('success', 'New driver has been created.');
      closeaddDriverViewModal();
      getDrivers();
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAttemptedAlready(false);
    }
  };

  const addCompany = async (data) => {
    setFormErrors('');
    setAttemptedAlready(true);
    try {
      await API.post(`companies`, data);
      toaster('success', 'Carrier has been created.');
      closeAddCompanyView();
      getCarriers();
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAttemptedAlready(false);
    }
  };

  const _getUsers = async (page, searchKeyword, filters, columns) => {
    try {
      const users = await API.get(`organizations/${subdomain}/users`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setUsers(users?.data?.filter((user) => user.isActive) || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const closeAddCompanyView = () => {
    setAddCompanyViewOpen(false);
  };

  const closeaddDriverViewModal = () => {
    setAddDriverView(false);
    setAttemptedAlreadyDriver(false);
  };
  const closeAddVehicleViewModal = () => {
    setAddVehicleView(false);
  };
  useEffect(() => {
    getVehicle();
  }, [selectedCarrier, selectedCar]);

  useEffect(() => {
    getCars();
    getCarriers();
    _getUsers();
  }, []);

  useEffect(() => {
    getDrivers(driverSearch);
  }, [driverSearch, selectedCarrier]);

  useEffect(() => {
    getCars(vehicleTypeSearch);
  }, [vehicleTypeSearch]);

  useEffect(() => {
    getVehicle(vehicleSearch);
  }, [vehicleSearch]);

  useEffect(() => {
    getCarriers(carrierSearch);
  }, [carrierSearch]);

  useEffect(() => {
    if (shipment) {
      formik.setValues({
        pickupDateTime: shipment.pickupDateTime,
        dropoffDateTime: shipment.dropoffDateTime,
        pallete: shipment?.palletize ? 'Palletized' : 'Non-Palletized',
        weight: shipment?.weight || 0,
        vehicleType: shipment.Car || {},
        vehicleTypeId: shipment.vehicleTypeId,
        vehicleNumber: shipment?.vehicleNumber || '',
        driverName: shipment?.driverName || '',
        driverPhone: shipment?.driverPhone || selectedDriver.phone,
        sealNumber: shipment?.sealNumber || '',
        carrier: shipment?.Carrier || {},
        carrierId: shipment?.carrierId,
        driver: shipment?.Driver || {},
        driverId: shipment?.driverId,
        vehicle: shipment?.Vehicle || {},
        vehicleId: shipment?.vehicleId,
        carrierName: shipment?.carrierName || '',
        vehicleTypeName: shipment?.vehicleTypeName || ''
      });
    }
    setSelectedCarrier(shipment?.Carrier);
    setSelectedCar(shipment?.Car);
    setSelectedDriver(shipment?.Driver);
    setSelectedVehicle(shipment?.Vehicle);
  }, [shipment]);

  return (
    <>
      {/* <AddCompanyView
        key={3}
        formErrors={formErrors}
        users={users}
        relationType={'VENDOR'}
        customerTypes={customerTypes}
        open={addCompanyViewOpen}
        attemptedAlready={attemptedAlreadyCarrier}
        setAttemptedAlready={setAttemptedAlreadyCarrier}
        addCompany={addCompany}
        handleClose={() => closeAddCompanyView()}
        removeLogoId={() => removeCurrentLogoId()}
      /> */}
      {/* <AddDriverView
        key={3}
        companies={carriers}
        addDriver={addDriver}
        formErrors={formErrors}
        open={addDriverView}
        attemptedAlready={attemptedAlreadyDriver}
        setAttemptedAlready={setAttemptedAlreadyDriver}
        handleClose={closeaddDriverViewModal}
      /> */}
      {/* <AddVehicleTypeView
        key={4}
        formErrors={formErrors}
        open={AddVehicleTypeViewOpen}
        addVehicleType={addVehicleType}
        attemptedAlready={attemptedAlready}
        setAttemptedAlready={setAttemptedAlready}
        handleClose={() => closeAddVehicleTypeView()}
      /> */}
      {/* <AddVehicleView
        key={3}
        vehicles={vehicles}
        companies={carriers}
        drivers={drivers}
        cars={cars}
        setCars={setCars}
        formErrors={formErrors}
        addVehicle={addVehicle}
        open={addVehicleView}
        attemptedAlready={attemptedAlreadyVehicle}
        setAttemptedAlready={setAttemptedAlreadyVehicle}
        handleClose={() => closeAddVehicleViewModal()}
      /> */}
      <VehiclePopup
        open={addVehicleView}
        setOpen={setAddVehicleView}
        refetch={getVehicle}
        organizationType={organization?.type}
      />
      <AddVehicleTypePopup
        open={addVehicleTypeViewOpen}
        setOpen={setAddVehicleTypeViewOpen}
        organizationType={organization?.type}
        refetch={getCars}
      />
      <AddcarrierPopup
        open={addCompanyViewOpen}
        setOpen={setAddCompanyViewOpen}
        organizationType={organization?.type}
        refetch={getCarriers}
      />
      <AddDriverPopup
        open={addDriverView}
        setOpen={setAddDriverView}
        refetch={getDrivers}
        organizationType={organization?.type}
      />
      <Grid container spacing={2} className="mt-4 w-[800px]">
        <Grid item xs={4} className="flex items-center">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={formik.handleBlur}
                  size="small"
                />
              )}
              inputFormat="dd/MM/yyyy hh:mm a"
              value={
                dividerDateFormat12Hrs(formik?.values?.pickupDateTime) || null
              }
              onChange={(newValue) => {
                formik.setFieldValue('pickupDateTime', newValue);
              }}
              disabled={view}
              maxDate={formik.values.dropoffDateTime}
              maxDateTime={formik?.values?.dropoffDateTime}
              label="Pickup date"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} className="flex items-center">
          <FormControl outlined={true}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={formik.handleBlur}
                    size="small"
                  />
                )}
                inputFormat="dd/MM/yyyy hh:mm a"
                value={
                  dividerDateFormat12Hrs(formik?.values?.dropoffDateTime) ||
                  null
                }
                onChange={(newValue) => {
                  formik.setFieldValue('dropoffDateTime', newValue);
                }}
                disabled={view}
                minDate={formik.values.pickupDateTime}
                minDateTime={formik?.values?.pickupDateTime}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            {isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
              <Autocomplete
                value={formik?.values?.carrier || selectedCarrier}
                options={[
                  ...carriers,
                  {
                    id: '',
                    name: 'addCarrier'
                  }
                ]}
                renderOption={(props, option) => {
                  const { className, ...rest } = props;
                  return option?.name === 'addCarrier' ? (
                    <li
                      className="auto-complete-option"
                      style={{
                        color: PRIMARY,
                        fontSize: '16px',
                        fontWeight: 500
                      }}
                      onClick={() => {
                        setAddCompanyViewOpen(true);
                      }}
                    >
                      + {'Add New Carrier'}
                    </li>
                  ) : (
                    <li {...rest} className="auto-complete-option">
                      <span>{option && option.name ? option?.name : ''}</span>
                    </li>
                  );
                }}
                getOptionLabel={(carrier) => {
                  return carrier && carrier?.name ? carrier?.name : '';
                }}
                onInputChange={(event, newValue) => {
                  setCarrierSearch(newValue);
                }}
                onBlur={formik.handleBlur}
                onChange={(e, val) => {
                  formik.setFieldValue('carrier', val !== null && val);
                  formik.setFieldValue(
                    'carrierId',
                    val && val.id ? val.id : ''
                  );
                  formik.setFieldValue(
                    'carrierName',
                    val && val.name ? val.name : ''
                  );
                  setSelectedCarrier(val);
                  setSelectedDriver({});
                  setSelectedVehicle({});
                  formik.setFieldValue('driver', {});
                  formik.setFieldValue('driverPhone', '');
                  formik.setFieldValue('vehicle', {});
                }}
                placeholder="Carrier"
                disabled={view}
              />
            ) : (
              <TextField
                value={formik.values?.carrierName}
                onChange={(e) =>
                  formik.setFieldValue('carrierName', e.target.value)
                }
                size="small"
                disabled={view}
                className="w-full"
                label={'Carrier Name'}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            {!isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
              <TextField
                value={formik.values.vehicleTypeName}
                onChange={(e) =>
                  formik.setFieldValue('vehicleTypeName', e.target.value)
                }
                label="Vehicle Type"
                size="small"
                disabled={view}
              />
            ) : (
              <Autocomplete
                value={formik?.values?.vehicleType}
                options={[
                  ...cars,
                  {
                    id: '',
                    name: 'addCar'
                  }
                ]}
                renderOption={(props, option) => {
                  const { className, ...rest } = props;
                  return option?.name === 'addCar' ? (
                    <li
                      className="auto-complete-option"
                      style={{
                        color: PRIMARY,
                        fontSize: '16px',
                        fontWeight: 500
                      }}
                      onClick={() => {
                        setAddVehicleTypeViewOpen(true);
                      }}
                    >
                      + {'Add New Vehicle Type'}
                    </li>
                  ) : (
                    <li {...rest} className="auto-complete-option">
                      <span>
                        {option && option.CarMake && option.CarModel
                          ? `${option?.CarMake?.name}-${option?.CarModel?.name} ( ${option?.Category?.name} )`
                          : ''}
                      </span>
                    </li>
                  );
                }}
                getOptionLabel={(car) => {
                  return car && car.CarMake && car.CarModel
                    ? `${car?.CarMake?.name}-${car?.CarModel?.name} ( ${car?.Category?.name} )`
                    : '';
                }}
                onBlur={formik.handleBlur}
                onChange={(e, val) => {
                  formik.setFieldValue('vehicleType', val !== null && val);
                  formik.setFieldValue(
                    'vehicleTypeId',
                    val && val.id ? val.id : ''
                  );
                  setSelectedCar(val);
                  formik.setFieldValue(
                    'vehicleTypeName',
                    `${val?.CarMake?.name}-${val?.CarModel?.name} ( ${val?.Category?.name} )`
                  );
                }}
                onInputChange={(event, newValue) => {
                  setVehicleTypeSearch(newValue);
                }}
                label="Vehicle Type"
                disabled={view}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4} className="flex items-center">
          <FormControl fullWidth>
            <TextField
              value={formik.values.vehicleNumber}
              onChange={(e) =>
                formik.setFieldValue('vehicleNumber', e.target.value)
              }
              label="Vehicle Number"
              size="small"
              disabled={
                view || isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                  ? !formik.values.carrier?.id || !formik.values.vehicleType?.id
                  : false
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} className="flex items-center">
          <FormControl fullWidth>
            <TextField
              value={formik.values?.driverName}
              onChange={(e) =>
                formik.setFieldValue('driverName', e.target.value)
              }
              label="Driver Name"
              size="small"
              disabled={
                view ||
                (isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                  ? !formik.values.carrier?.id
                  : false)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} className="flex items-center number-input-container">
          <FormControl fullWidth>
            <PhoneInput
              country={'pk'}
              value={formik.values.driverPhone || selectedDriver?.phone}
              onChange={(phone) => {
                formik.setFieldValue('driverPhone', phone);
              }}
              disableDropdown={view}
              disabled={view}
              inputStyle={{ width: '100%', height: '40px' }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} className="flex items-center number-input-container">
          <FormControl fullWidth>
            <TextField
              value={formik.values.weight}
              onChange={(e) => {
                let value = e.target.value;
                if (value.length > 5) {
                  value = value.slice(0, 5);
                }
                formik.setFieldValue('weight', value);
              }}
              label={`Weight (${settings?.weight?.symbol || 'Kg'})`}
              size="small"
              disabled={view}
              type="number"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} className="flex items-center number-input-container">
          <FormControl fullWidth>
            <TextField
              value={formik.values.volume}
              onChange={(e) => {
                let value = e.target.value;
                formik.setFieldValue('volume', value);
              }}
              size="small"
              disabled={view}
              type="number"
              label={`Total Volume (${settings?.volume?.symbol || 'cm³'})`}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} className="flex items-center number-input-container">
          <FormControl fullWidth>
            <TextField
              value={formik.values.sealNumber}
              onChange={(e) => {
                formik.setFieldValue('sealNumber', e.target.value);
              }}
              label="Seal Number"
              size="small"
              disabled={view}
              type="text"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default AddShipment;

import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { Grid, Button, Typography, FormControl } from '@material-ui/core';

import FormContext from './OrganizationFormContext';
import FormikInputField from '../../../atomicComponents/FormikInputField';
import CancelButton from '../../../components/CancelButton';
import useStyles from './OrganizationFormStyles';
import { ownerDetailsValidationSchema } from './formikFields';
import FormikCheckbox from '../../../atomicComponents/FormikCheckbox';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const OwnerDetailsForm = ({ onSubmit, edit }) => {
  const { ownerDetails, setOwnerDetails, prev, handleClose, reset } =
    useContext(FormContext);

  const formik = useFormik({
    initialValues: { ...ownerDetails, edit },
    validationSchema: ownerDetailsValidationSchema,
    onSubmit: async (values) => {
      onSubmit({ ...values, phone: values.phone.replace('-', '') });
    }
  });

  const classes = useStyles();

  useEffect(() => {
    setOwnerDetails(formik.values);
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikInputField
              name="username"
              type="text"
              formik={formik}
              title="Username"
              disabled={edit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikInputField
              name="firstName"
              type="text"
              formik={formik}
              title="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikInputField
              name="lastName"
              type="text"
              formik={formik}
              title="LastName"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="email"
              type="email"
              formik={formik}
              title="Email"
              disabled={edit}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <PhoneInput
                country={'pk'}
                value={formik?.values?.phone || ''}
                onChange={(phone) => {
                  formik.setFieldValue('phone', phone);
                }}
                variant="outlined"
                inputStyle={{ width: '100%', height: '56px' }}
              />
              {formik?.errors?.phone && formik?.touched?.phone ? (
                <Typography color="error">{formik.errors.phone}</Typography>
              ) : (
                ''
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="password"
              type="password"
              formik={formik}
              title="Password"
              autoComplete="off"
            />
          </Grid>

          <Grid container spacing={2} style={{ marginLeft: 10 }}>
            <Grid item xs={6} lg={6}>
              <FormikCheckbox
                formik={formik}
                label="Active status"
                name="active"
              />
            </Grid>
          </Grid>

          <div className={classes.btnContainer}>
            <CancelButton
              handleClose={handleClose}
              setValidation={() => {}}
              resetStates={reset}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOwnerDetails(formik.values);
                prev();
              }}
            >
              Back
            </Button>
            <Button color="primary" variant="contained" type="submit">
              {!edit ? 'Add Organization' : 'Update Organization'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
export default OwnerDetailsForm;

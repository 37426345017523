/* eslint-disable */
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useContext, useEffect, useState } from 'react';
import TextField from '../../../core-components/atoms/TextField';
import { useFormik } from 'formik';
import Button from '../../../core-components/atoms/Button';
import { initialValues, validationSchema } from './validationSchema/AddBatch';
import ProductSubTable from './ProductSubTable';
import moment from 'moment';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { NumericFormat } from 'react-number-format';
import { SharedContext } from '../../../utils/common';

const AddBatch = ({
  addBatch,
  parentIndex,
  childIndex,
  setOpen,
  editBatch,
  product,
  batch,
  existingBatches = [],
  edit = false,
  returnBatches = [],
  enableActions,
  uom
}) => {
  const [batches, setBatches] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState('');
  const [closeModal, setCloseModal] = useState(false);
  const [batchMaxQuantity, setBatchMaxQuantity] = useState(
    enableActions ? batch.receivedQuantity : 0
  );
  const [selectedReturnBatch, setSelectedReturnBatch] = useState({});

  const { settings } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: edit
      ? {
          ...batch,
          recoverableDamageQuantity:
            batch.recoverableDamageQuantity / (uom?.conversionFactor || 1),
          receivedQuantity:
            batch.receivedQuantity / (uom?.conversionFactor || 1)
        }
      : initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (
        product.batchEnabled &&
        (!values.manufacturingDate || !values.expiryDate)
      ) {
        setError('Dates are mandatory');
        return;
      } else if (
        product.batchEnabled &&
        new Date(values.manufacturingDate) > new Date(values.expiryDate)
      ) {
        setError('Manufacturing Date must be lesser than expiry');
        return;
      }

      if (
        (values.promoName && !values.promoQuantity) ||
        (!values.promoName && values.promoQuantity)
      ) {
        setError(`Required both Promo Name and Promo Quantity or none`);
        return;
      }

      setError('');
      setBatches([values]);
      if (!closeModal || edit) setShowTable(true);
      if (edit) {
        editBatch(parentIndex, childIndex, {
          ...values,
          recoverableDamageQuantity:
            values.recoverableDamageQuantity * (uom?.conversionFactor || 1),
          receivedQuantity:
            values.receivedQuantity * (uom?.conversionFactor || 1)
        });
        setOpen(false);
      } else {
        addBatch(parentIndex, {
          ...values,
          recoverableDamageQuantity:
            values.recoverableDamageQuantity * (uom?.conversionFactor || 1),
          receivedQuantity:
            values.receivedQuantity * (uom?.conversionFactor || 1)
        });
        if (closeModal) setOpen(false);
        else actions.resetForm();
        formik.setFieldValue('batchNumber', '');
      }
    }
  });

  useEffect(() => {
    if (edit && returnBatches?.length > 0) {
      const currentBatch = returnBatches.find(
        (batch) => batch?.batchNumber === formik.values.batchNumber
      );
      if (currentBatch) {
        setBatchMaxQuantity(currentBatch?.quantity || 0);
      }
    }
  }, [edit]);

  const autoPopulateExpiry = (date) => {
    if (date && product?.productLife) {
      const expDate = new Date(date);
      formik.setFieldValue(
        'expiryDate',
        moment(expDate, 'dd/MM/yyyy').add(product?.productLife, 'days')
      );
    }
  };

  return (
    <div className="mt-2">
      {showTable && (
        <Box sx={{ marginBottom: 2 }}>
          <ProductSubTable
            batches={batches}
            returnBatches={returnBatches}
            enableActions={enableActions}
            uom={uom}
          />
        </Box>
      )}
      <form onSubmit={formik.handleSubmit} className="number-input-container">
        <p className="text-error mb-4">{error}</p>
        <Grid container spacing={2}>
          {product.batchEnabled && returnBatches?.length > 0 && !edit && (
            <>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  label="Batch / Expiry Date / UoM / Returnable Qty"
                  variant="outlined"
                  size="small"
                  fullWidth
                  options={
                    returnBatches?.filter((batch) => {
                      const existingBatch = existingBatches.find(
                        (existingBatch) =>
                          existingBatch.batchNumber === batch.batchNumber
                      );
                      if (existingBatch) {
                        const updatedQuantity =
                          batch.quantity - existingBatch.receivedQuantity;
                        return updatedQuantity > 0;
                      }
                      return true;
                    }) || []
                  }
                  renderOption={(props, option) => {
                    const { className, ...rest } = props;
                    return (
                      <li {...rest} className="auto-complete-option">
                        <p>
                          <span>{option?.batchNumber}</span>
                        </p>
                        <p>
                          {option?.manufacturingDate &&
                            option?.manufacturingDate !== '-' && (
                              <span>
                                {moment(option?.manufacturingDate).format(
                                  'DD/MM/YY'
                                )}{' '}
                                -{' '}
                              </span>
                            )}
                          <span>
                            {moment(option?.expiryDate).format('DD/MM/YY')}
                          </span>
                        </p>
                        <p>{product?.UOM?.name}</p>
                        <p style={{ fontWeight: 500 }}>
                          {option?.quantity -
                            (existingBatches.length > 0
                              ? existingBatches.find(
                                  (batch) =>
                                    batch.batchNumber === option.batchNumber
                                )?.receivedQuantity || 0
                              : 0)}{' '}
                        </p>
                      </li>
                    );
                  }}
                  value={selectedReturnBatch}
                  getOptionLabel={(batch) => {
                    return formik.values.expiryDate
                      ? `${
                          batch?.batchNumber ? batch.batchNumber + ' / ' : ''
                        }` +
                          `${moment(batch.expiryDate).format('DD/MM/YYYY')}` +
                          ` / ${
                            batch?.quantity -
                            (existingBatches.length > 0
                              ? existingBatches.find(
                                  (b) => b.batchNumber === batch.batchNumber
                                )?.receivedQuantity || 0
                              : 0)
                          } ${product?.UOM?.name}`
                      : '';
                  }}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(e, val) => {
                    formik.setFieldValue('batchNumber', val.batchNumber);
                    val.manufacturingDate &&
                      val.manufacturingDate !== '-' &&
                      formik.setFieldValue(
                        'manufacturingDate',
                        val.manufacturingDate
                      );
                    formik.setFieldValue('expiryDate', val.expiryDate);
                    if (val.MRP) formik.setFieldValue('MRP', val.MRP);
                    setBatchMaxQuantity(
                      val.quantity -
                        (existingBatches.length > 0
                          ? existingBatches.find(
                              (b) => b.batchNumber === val.batchNumber
                            )?.receivedQuantity || 0
                          : 0)
                    );
                    setSelectedReturnBatch(val);
                    formik.setFieldValue(
                      'receivedQuantity',
                      val.quantity -
                        (existingBatches.length > 0
                          ? existingBatches.find(
                              (b) => b.batchNumber === val.batchNumber
                            )?.receivedQuantity || 0
                          : 0)
                    );
                  }}
                />
                <span className="text-error">
                  {formik.touched.batchNumber && formik.errors.batchNumber}
                </span>
              </Grid>
            </>
          )}

          {product.batchEnabled && (
            <>
              <Grid item xs={12} sm={3}>
                <TextField
                  name="batchNumber"
                  label="Batch #"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formik.values.batchNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={returnBatches?.length > 0 || enableActions}
                />
                <span className="text-error">
                  {formik.touched.batchNumber && formik.errors.batchNumber}
                </span>
              </Grid>
              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="manufacturingDate"
                    label="Mfg. Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.manufacturingDate}
                    onChange={(date) => {
                      formik.setFieldValue('manufacturingDate', date);
                      autoPopulateExpiry(date);
                    }}
                    disabled={returnBatches?.length > 0 || enableActions}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled
                        onKeyDown={(e) => e.preventDefault()}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                <span className="text-error">
                  {formik.touched.manufacturingDate &&
                    formik.errors.manufacturingDate}
                </span>
              </Grid>
              <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="expiryDate"
                    label="Exp. Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.expiryDate}
                    onChange={(date) =>
                      formik.setFieldValue('expiryDate', date)
                    }
                    disabled={returnBatches?.length > 0 || enableActions}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled
                        onKeyDown={(e) => e.preventDefault()}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                <span className="text-error">
                  {formik.touched.expiryDate && formik.errors.expiryDate}
                </span>
              </Grid>
            </>
          )}

          {product.MRPEnabled && (
            <Grid item xs={12} sm={3}>
              <TextField
                name="MRP"
                label="MRP per Pack (Rs)"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.MRP}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={returnBatches?.length > 0}
              />
              <span className="text-error">
                {formik.touched.MRP && formik.errors.MRP}
              </span>
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <NumericFormat
              customInput={TextField}
              name="actualWeight"
              label="Actual Weight"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.actualWeight}
              onValueChange={(e) =>
                formik.setFieldValue('actualWeight', parseFloat(e.value))
              }
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: settings?.weight?.symbol || 'kg'
              }}
              thousandSeparator=","
              decimalSeparator="."
            />
            <span className="text-error">
              {formik.touched.actualWeight && formik.errors.actualWeight}
            </span>
          </Grid>

          <Grid item xs={12} sm={3}>
            <NumericFormat
              customInput={TextField}
              name="receivedQuantity"
              label="Received Qty"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.receivedQuantity || ''}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (!enableActions) {
                  return true;
                }
                if (
                  (enableActions && batchMaxQuantity >= floatValue) ||
                  floatValue === undefined
                ) {
                  return true;
                }
                return false;
              }}
              onValueChange={(e) => {
                if (batchMaxQuantity > 0 && returnBatches?.length > 0) {
                  if (
                    parseFloat(e.value) >= 0 &&
                    parseFloat(e.value) <= batchMaxQuantity
                  ) {
                    formik.setFieldValue(
                      'receivedQuantity',
                      parseFloat(e.value)
                    );
                  }
                } else if (e.value >= 0) {
                  formik.setFieldValue('receivedQuantity', parseFloat(e.value));
                }

                if (enableActions) {
                  formik.setFieldValue(
                    'lossInTransitQuantity',
                    batchMaxQuantity - parseFloat(e.value)
                  );
                }
              }}
              thousandSeparator=","
              decimalSeparator="."
            />
            <span className="text-error">
              {formik.touched.receivedQuantity &&
                formik.errors.receivedQuantity}
            </span>
          </Grid>

          <Grid item xs={12} sm={3}>
            <NumericFormat
              customInput={TextField}
              name="recoverableDamageQuantity"
              label="Damaged"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.recoverableDamageQuantity || ''}
              onValueChange={(e) => {
                if (e.value >= 0) {
                  formik.setFieldValue(
                    'recoverableDamageQuantity',
                    parseFloat(e.value)
                  );
                }
              }}
              onBlur={formik.handleBlur}
              thousandSeparator=","
              decimalSeparator="."
            />
            <span className="text-error">
              {formik.touched.recoverableDamageQuantity &&
                formik.errors.recoverableDamageQuantity}
            </span>
          </Grid>

          {product.batchEnabled && (
            <>
              <Grid item xs={12} sm={3}>
                <TextField
                  name="promoName"
                  label="Promo Code"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formik.values.promoName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="text-error">
                  {formik.touched.promoName && formik.errors.promoName}
                </span>
              </Grid>
              <Grid item xs={12} sm={3}>
                <NumericFormat
                  name="promoQuantity"
                  customInput={TextField}
                  label="Promo Qty"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formik.values.promoQuantity || 0}
                  onValueChange={(e) => {
                    if (e.value >= 0) {
                      formik.setFieldValue(
                        'promoQuantity',
                        parseFloat(e.value)
                      );
                    }
                  }}
                  onBlur={formik.handleBlur}
                  thousandSeparator=","
                  decimalSeparator="."
                />
                <span className="text-error">
                  {formik.touched.promoQuantity && formik.errors.promoQuantity}
                </span>
              </Grid>
            </>
          )}

          <Grid item xs={12} className="flex flex-col sm:flex-row justify-end">
            {edit || (
              <Button
                size="large"
                label="Save & Add New Batch"
                type="submit"
                onClick={() => {
                  setCloseModal(false);
                }}
                variant="secondary"
                className="w-full sm:w-48 mb-2 sm:mb-0 sm:mr-2"
              />
            )}
            {edit ? (
              <Button
                size="large"
                label="Save"
                type="submit"
                variant="primary"
                className="w-full sm:w-48"
              />
            ) : (
              <Button
                size="large"
                label="Save Changes"
                onClick={() => {
                  setCloseModal(true);
                }}
                type="submit"
                variant="primary"
                className="w-full sm:w-48"
              />
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddBatch;

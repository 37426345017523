import { Grid } from '@mui/material';
import SMSIcon from '../../assets/icons/sms-search.svg';
import LocationIcon from '../../assets/icons/location-yellow.svg';
import PhoneIcon from '../../assets/icons/call.svg';

const OrganizationSetupDetails = () => {
  return (
    <div className="text-white w-full max-w-[952px] me-8 flex justify-center md:justify-start web-app-default-font">
      <div>
        <p className="font-bold text-white mb-8 text-3xl sm:text-4xl md:text-5xl">
          Oware Technologies Inc
        </p>
        <p className="text-2xl text-white mb-8 text-2xl sm:text-3xl md:text-4xl">
          (For 14 Days Free Trial)
        </p>
        <Grid container spacing={4} className="mt-5">
          <Grid item xs={12} sm={6}>
            <ContactCard
              title="Call Us"
              icon={PhoneIcon}
              contacts={['+1 (325) 9998161']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ContactCard
              title="Email Us"
              icon={SMSIcon}
              contacts={['info@oware.co']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ContactCard
              title="Visit Us"
              icon={LocationIcon}
              contacts={['www.o360cloud.com']}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const ContactCard = ({ title, icon, contacts }) => {
  return (
    <div className="mb-4">
      <img src={icon} alt={title} />
      <p className="font-bold mb-2 text-xl text-white">{title}</p>
      {contacts.map((contact) => (
        <p key={contact}>{contact}</p>
      ))}
    </div>
  );
};

export default OrganizationSetupDetails;

/*eslint-disable*/
import { useContext, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import {
  SharedContext,
  validateCols,
  validateDuplication
} from '../../utils/common';
import Popup from '../../core-components/atoms/Popup';
import WarningIcon from '../../assets/icons/warning.svg';
import downloadFileIcon from '../../assets/icons/templateDownload.svg';
import { Grid } from '@mui/material';
import { nodeTypes } from './constants';
import Map from '../revampedOrder/Map';
import API from '../../libs/axios';
import Button from '../../core-components/atoms/Button';

const BulkUpload = ({ file, open, setOpen, entity, onSubmit }) => {
  const [data, setData] = useState([]);
  const [verifiedResponse, setVerifiedResponse] = useState({
    incorrect: [],
    correct: [],
    showPopup: false
  });

  const { setAPILoader } = useContext(SharedContext);

  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    setData(list);
  };

  const bulkUpload = async (data) => {
    const [sanitizationArray, errs] = dataSanitization(data);

    let temp = [];
    let incorrect = [];
    let errorsArray = [];
    let count = 0;
    for (let node of sanitizationArray) {
      validateDuplication(
        node['Node Name'],
        'Node Name',
        count,
        'Duplicate Node Name.',
        temp,
        errorsArray
      );
      if (
        !['Warehouse', 'Company/Plant', 'DarkStore'].includes(
          node['Type'].replace(/ /g, '')
        )
      ) {
        errorsArray.push({ row: count, message: 'Node Type is in valid' });
      }
      if (!errorsArray.find((er) => er.row == count)) {
        temp.push(node);
      } else {
        incorrect.push(node);
      }

      count++;
    }

    let node = finalizeNodesData(temp);
    let backendValidation;

    try {
      backendValidation = await API.post(
        entity?.entityType == 'Organization'
          ? 'nodes/bulk?validateOnly=true'
          : `nodes/bulk?validateOnly=true&companyId=${entity.id}`,
        node
      );
    } catch (err) {
      console.log(err);
    } finally {
      let allCorrectNodes = node.filter((_, id) => {
        return !(
          backendValidation?.[id + 2] && backendValidation[id + 2]?.length > 0
        );
      });

      let allInCorrectNodes = node.filter((_, id) => {
        return (
          backendValidation?.[id + 2] && backendValidation[id + 2]?.length > 0
        );
      });

      setVerifiedResponse({
        correct: allCorrectNodes,
        incorrect: [
          ...verifiedResponse.incorrect,
          ...incorrect,
          ...errs,
          ...allInCorrectNodes
        ],
        showPopup: true
      });

      setAPILoader(false);
    }
  };

  const finalizeNodesData = (nodes) => {
    for (let node of nodes) {
      node.code = node['Node Code'];
      node.name = node['Node Name'];
      node.description = node['Additional Detail'];
      node.address = node['Address'];
      node.latitude = node['Latitude'];
      node.longitude = node['Longitude'];
      node.locationLatLng = {
        lat: Number(node['Latitude']),
        lng: Number(node['Longitude'])
      };
      node.nodeClass =
        entity?.entityType == 'Organization' ? 'INTERNAL' : 'EXTERNAL';
      if (node['POC Name']) node.pocName = node['POC Name'];
      if (node['POC Contact']) node.pocContact = node['POC Contact'];
      node.nodeType =
        node['Type'].replace(/ /g, '') == 'Warehouse'
          ? 'WAREHOUSE'
          : node['Type'].replace(/ /g, '') == 'Company/Plant'
          ? 'PLANT'
          : 'DARKSTORE';

      delete node['Node Name'];
      delete node['Node Code'];
      delete node['Additional Detail'];
      delete node['Address'];
      delete node['Latitude'];
      delete node['Longitude'];
      delete node['Type'];
      delete node['POC Contact'];
      delete node['POC Name'];
    }

    return nodes;
  };

  const exportToXlsx = () => {
    let nodes = verifiedResponse?.incorrect?.map((node) => [
      node?.name || node['Node Name'],
      node?.code || node['Node Code'],
      node?.latitude || node['Latitude'],
      node?.longitude || node['Longitude'],
      node?.address || node['Address'],
      node?.additionalDetail || node['Additional Detail'],
      node?.nodeType
        ? node?.nodeType == 'WAREHOUSE'
          ? 'Warehouse'
          : node.nodeType == 'DARKSTORE'
          ? 'Dark Store'
          : 'Company/Plant'
        : node['Type'],
      node.pocContact || node['POC Contact'],
      node?.pocName || node['POC Name']
    ]);
    const data = [
      [
        'Node Code',
        'Node Name',
        'Additional Detail',
        'Address',
        'Latitude',
        'Longitude',
        'Type',
        'POC Name',
        'POC Contact'
      ],
      ...nodes
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Incorrect nodes');

    // Save the workbook as an XLSX file
    XLSX.writeFile(wb, 'incorrect_nodes.xlsx');
  };

  const dataSanitization = (data) => {
    let count = 2;
    let sanitizationArray = [];
    let temp = [];
    let incorrect = [];
    let regex = /^[a-zA-Z]+$/;
    let numberRegex = /^-?\d+$/;

    for (let node of data) {
      validateCols(
        node['Node Name'],
        count,
        sanitizationArray,
        'Node Name is missing'
      );
      validateCols(
        node['Address'],
        count,
        sanitizationArray,
        'Node Address is missing'
      );
      validateCols(
        node['Type'],
        count,
        sanitizationArray,
        'Node Type is missing'
      );
      if (node['POC Name'] && !regex.test(node['POC Name'].replace(/ /g, ''))) {
        incorrect.push(node);
      } else if (
        node['POC Contact'] &&
        !numberRegex.test(node['POC Contact'].replace(/ /g, ''))
      ) {
        incorrect.push(node);
      } else if (!sanitizationArray.find((er) => er.row == count)) {
        temp.push(node);
      } else {
        incorrect.push(node);
      }
      count++;
    }

    setVerifiedResponse({
      correct: [...temp],
      incorrect: [...verifiedResponse.incorrect, ...incorrect]
    });
    return [temp, incorrect];
  };

  // handle file upload
  const handleFileUpload = (file) => {
    setAPILoader(true);
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (file) {
      handleFileUpload(file);
    }
  }, [file]);

  useEffect(() => {
    if (data && data.length > 0) bulkUpload(data);
  }, [data]);

  return (
    <div>
      {verifiedResponse?.showPopup && (
        <Popup
          open={open}
          setOpen={setOpen}
          title={`Add Nodes for ${entity?.name} ?`}
          dialogContentClasses={`w-[700px]`}
          actions={
            <div className="flex gap-2">
              <Button
                label="Cancel"
                onClick={() => setOpen(false)}
                variant="secondary"
              />
              <Button
                label={
                  verifiedResponse?.correct?.length > 0
                    ? `Create ${verifiedResponse?.correct?.length} ${
                        verifiedResponse?.correct?.length > 1 ? 'Nodes' : 'Node'
                      }`
                    : 'Create'
                }
                onClick={async () => {
                  await onSubmit(verifiedResponse.correct);
                }}
                disabled={verifiedResponse?.correct?.length == 0}
                variant="primary"
              />
            </div>
          }
          content={
            <div>
              {verifiedResponse?.incorrect?.length > 0 && (
                <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
                  <div className="flex gap-2">
                    <img src={WarningIcon} />
                    <p className="text-sm">
                      {verifiedResponse?.incorrect?.length}{' '}
                      {verifiedResponse?.incorrect?.length > 1
                        ? 'nodes are '
                        : 'node is '}
                      not uploaded because of formatting issues in file.
                    </p>
                  </div>
                  <div
                    onClick={async () => {
                      await exportToXlsx();
                    }}
                    className="flex gap-2 cursor-pointer"
                  >
                    <img src={downloadFileIcon} />
                    <p className="text-sm">Download File</p>
                  </div>
                </div>
              )}
              {verifiedResponse?.correct?.length > 0 && (
                <>
                  <div className="flex justify-between mt-4">
                    <p className="font-medium">
                      {verifiedResponse?.correct?.length}{' '}
                      {verifiedResponse?.correct?.length > 1 ? 'nodes' : 'node'}{' '}
                      Detected
                    </p>
                  </div>
                  {verifiedResponse?.correct?.map((node, id) => (
                    <Grid
                      className="p-4 bg-[#F0F7FF] mb-3 min-h-[100px] w-full mt-4"
                      container
                      spacing={2}
                      key={id}
                    >
                      <Grid item xs={1} className="flex items-center">
                        <div
                          className={`h-3 w-3 rounded-full border border-solid border-[#1AAE2E] bg-[#1AAE2E]`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div className="w-[100%] h-full relative">
                          <Map
                            singleLocationLatlng={{
                              lat: node.latitude,
                              lng: node.longitude
                            }}
                            editable={false}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div className="flex flex-col gap-1 pt-2">
                          <span className="font-medium text-xs break-words">
                            {node.code ? `${node.code} - ` : ''} {node.name}
                          </span>
                          <span className="text-[10px]">
                            {nodeTypes.EXTERNAL[node.nodeType]}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div className="flex flex-col gap-1 pt-2 whitespace-normal">
                          <span className="font-medium text-xs break-words">
                            {node.pocName || '-'}
                          </span>
                          <span className="text-[10px] inline-block">
                            {node.pocContact || '-'}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <span className="text-xs">{node.address}</span>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default BulkUpload;

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { dateFormatSlashDivider } from '../../utils/common';

const ConfirmationPreviewSubTable = ({ batches }) => {
  const HEADER_CELLS = [
    'LOCATION',
    'BATCH',
    'ORDER ID',
    'MFG. & EXP. DATE',
    'PALLET ID',
    'MRP',
    'AVAILABLE QTY',
    'PICKED QTY'
  ];

  return (
    <Table
      size="small"
      className="bg-light border border-dashed border-black rounded-md py-3"
    >
      <TableHead className="border-none rounded-lg">
        {HEADER_CELLS.map((content) => (
          <TableCell
            key={content}
            className="py-2 text-[10px] border-none text-stormGray"
          >
            {content}
          </TableCell>
        ))}
      </TableHead>
      <TableBody className="border-none">
        {batches.map((batch) => (
          <TableRow className="group" key={batch.id}>
            <TableCell className="py-2  border-none">
              <p className="mb-1">{batch?.WarehouseHall?.name}</p>
              <div className="flex justify-between text-xs">
                <span className="font-medium">Aisle:</span>{' '}
                {batch?.WarehouseHall?.Aisle?.name}
                <span className="font-medium">Bay:</span>{' '}
                {batch?.WarehouseHall?.Bay?.name}
              </div>
            </TableCell>
            <TableCell className="py-2  border-none text-xs">
              {batch?.batchNumber}
            </TableCell>
            <TableCell className="py-2  border-none w-[94px] text-xs">
              {batch?.customId}
            </TableCell>
            <TableCell className="py-2  border-none w-[192px] text-xs">
              {batch?.manufacturingDate &&
                dateFormatSlashDivider(batch?.manufacturingDate)}
              -{batch?.expiryDate && dateFormatSlashDivider(batch?.expiryDate)}
            </TableCell>
            <TableCell className="py-2  border-none w-[98px]">
              {batch?.palletId}
            </TableCell>
            <TableCell className="py-2  border-none text-xs">
              {batch?.MRP}
            </TableCell>
            <TableCell className="py-2  border-none text-xs">
              {batch.availableQuantity}
            </TableCell>
            <TableCell className="py-2  border-none text-xs">
              {batch.pickQuantity}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ConfirmationPreviewSubTable;

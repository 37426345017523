import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Grid, Tooltip, Button } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';

import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import {
  SharedContext,
  bulkDateFormator,
  checkDates,
  exportToCSV,
  dateFormatSlashDivider
} from '../../../utils/common';
import { DEBOUNCE_CONST } from '../../../Config';
import { isRequired } from '../../../utils/validators';
import BulkUploadScreen from './BulkUploadScreen';
import BulkValidationScreen from './BulkValidationScreen';
import TemplateLoader from '../../../atomicComponents/TemplateLoader';
import useStyles from './sectionRelocationStyles';
import { ORGANIZATION_TYPES } from '../../../constants';
import Popup from '../../../core-components/atoms/Popup';
import CustomButton from '../../../core-components/atoms/Button';
import mixpanel from 'mixpanel-browser';

const AddBulkSectionRelocation = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    currentPageTitle,
    setCurrentPageTitle,
    setAPILoader,
    organizationType
  } = useContext(SharedContext);
  const IS_MANUFACTURER = organizationType === ORGANIZATION_TYPES.MANUFACTURER;
  const [openForm, setOpenForm] = useState({
    tab1: true,
    tab2: false,
    tab3: false
  });
  const [tabClasses, setClasses] = useState({
    tab1: 'selected-tab',
    tab2: '',
    tab3: ''
  });
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseId, setWarehouseId] = useState('');
  const [searchWarehouseValue, setSearchWarehouseValue] = useState('');
  const [companies, setCompanies] = useState([]);
  const [relocationPayload, setRelocationPayload] = useState([]);
  const [verifiedRelocations, setVerfiedRelocations] = useState([]);
  const [relocationsWithIssues, setRelocationsWithIssues] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [correctUploadFlag, setCorrectUploadFlag] = useState(false);
  const [dataloaded, setDataLoaded] = useState(true);
  const [correctRelocations, setCorrectRelocations] = useState(0);
  const [inCorrectRelocations, setInCorrectRelocations] = useState(0);
  const [singleCompany, setSingleCompany] = useState({});
  const [open, setOpen] = useState(false);

  const header = [
    'Source Section',
    'Destination Section',
    'SKU Code/Description',
    'Pallet ID',
    'Batch',
    'Manufacturing Date',
    'Expiry Date',
    'UoM',
    'Quantity',
    'Record Level'
  ];

  useEffect(() => {
    let title = 'Bulk Section Relocation Upload';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    setVerifyFlag(false);
  }, [warehouseId, selectedCompany, selectedFile]);

  useEffect(() => {
    if (verifiedRelocations?.length) {
      changeForm('tab2');
    }
  }, [verifiedRelocations]);

  useEffect(() => {
    if (correctUploadFlag) {
      changeForm('tab3');
    }
  }, [correctUploadFlag]);

  useEffect(() => {
    getWarehouseCallback({ searchWarehouseValue });
  }, [searchWarehouseValue]);

  const getWarehouseCallback = useCallback(
    debounce(({ searchWarehouseValue }) => {
      getWarehouses({ searchKeyword: searchWarehouseValue });
    }, DEBOUNCE_CONST),
    []
  );

  const getWarehouses = async ({ searchKeyword }) => {
    try {
      setAPILoader(true);
      const response = await API.get(`warehouses`, {
        params: { search: searchKeyword }
      });
      setWarehouses(response.data);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (!warehouseId) return;
    getCompanies({ warehouseId });
  }, [warehouseId]);

  useEffect(() => {
    if (isRequired(warehouseId)) {
      getCompaniesCallback({ warehouseId, searchCompanyValue });
    }
  }, [searchCompanyValue]);

  const getCompaniesCallback = useCallback(
    debounce(({ warehouseId, searchCompanyValue }) => {
      getCompanies({ warehouseId, searchCompanyValue });
    }, DEBOUNCE_CONST),
    []
  );

  const getCompanies = async (params) => {
    try {
      setAPILoader(true);
      let { warehouseId: warehouseId, searchCompanyValue: searchKeyword } =
        params;
      let colVal = { isActive: 1 };
      const response = await API.get(`warehouses/${warehouseId}/companies`, {
        params: { search: searchKeyword, filters: { colVal } }
      });
      if (response.data.rows?.length === 1 && !IS_MANUFACTURER) {
        setSingleCompany(response.data.rows?.[0]);
        setSelectedCompany(response.data.rows?.[0]);
      }
      setCompanies(response.data.rows);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const validateDatesAndData = (relocations) => {
    try {
      setAPILoader(true);
      const newRelocations = relocations.map((newRelocation) => {
        let manufacturingDate = newRelocation['Manufacturing Date']
          ?.toString()
          ?.replaceAll('-', '/');
        let expiryDate = newRelocation['Expiry Date']
          ?.toString()
          ?.replaceAll('-', '/');

        if (
          (manufacturingDate && !checkDates(manufacturingDate)) ||
          (expiryDate && !checkDates(expiryDate))
        ) {
          throw new Error(
            'Dates are not following a consistent format, all dates should either be DD/MM/YYYY or MM/DD/YYYY'
          );
        }

        return {
          manufacturingDate: manufacturingDate
            ? bulkDateFormator(manufacturingDate)
            : '',
          expiryDate: expiryDate ? bulkDateFormator(expiryDate) : '',
          sourceSection: newRelocation['Source Section'],
          destinationSection: newRelocation['Destination Section'],
          product: newRelocation['SKU Code/Description'],
          palletId: newRelocation['Pallet ID'] || null,
          batch: newRelocation['Batch'],
          quantity: +newRelocation['Quantity']
        };
      });
      setRelocationPayload(newRelocations);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const bulkUpload = (relocations) => {
    if (relocations?.length) {
      validateDatesAndData(relocations);
    }
  };

  const handleSubmit = async () => {
    try {
      setDataLoaded(false);
      setAPILoader(true);
      const payload = {
        warehouseId,
        relocations: [...relocationPayload],
        ...(selectedCompany && selectedCompany.id)
      };

      let url = `warehouses/${warehouseId}/relocations/bulk-validation`;
      if (selectedCompany) {
        url = `warehouses/${warehouseId}/relocations/bulk-validation?company_id=${selectedCompany.id}`;
      }
      const response = await API.post(url, payload);
      if (response.relocations.length) {
        setVerifyFlag(true);
        setVerfiedRelocations(response.relocations);
      }
      setCorrectRelocations(response.correctRelocations);
      setInCorrectRelocations(response.inCorrectRelocations);

      setDataLoaded(true);
    } catch (error) {
      setDataLoaded(true);
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const handleCreation = async () => {
    try {
      setDataLoaded(false);
      setAPILoader(true);
      const correctRelocations = [];
      const faultyRelocations = [];

      verifiedRelocations.forEach((relocation) => {
        delete relocation.uom;
        if (relocation.recordLevel != 'Correct') {
          faultyRelocations.push(relocation);
        } else {
          correctRelocations.push(relocation);
        }
      });

      setRelocationsWithIssues(faultyRelocations);

      if (!correctRelocations.length) {
        throw new Error('No Correct Relocations');
      }

      const payload = {
        warehouseId,
        companyId: selectedCompany?.id,
        relocations: [...correctRelocations]
      };

      selectedCompany?.id
        ? await API.post(
            `warehouses/${warehouseId}/relocations/bulk?companyId=${selectedCompany.id}`,
            payload
          )
        : await API.post(
            `warehouses/${warehouseId}/relocations/bulk?`,
            payload
          ),
        setCorrectUploadFlag(true);
      setDataLoaded(true);
      mixpanel.track('Bulk Stock Movement', {
        count: correctRelocations?.length
      });
    } catch (error) {
      setDataLoaded(true);
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const dataMaker = (relocations) => {
    return relocations.map((data) => {
      return {
        sourceSection: `"${data?.sourceSection}"`,
        destinationSection: `"${data?.destinationSection}"`,
        product: `"${data?.product}"`,
        palletId: `"${data?.palletId || ''}"`,
        batch: `"${data?.batch}"`,
        manufacturingDate: `"${dateFormatSlashDivider(
          data?.manufacturingDate
        )}"`,
        expiryDate: `"${dateFormatSlashDivider(data?.expiryDate)}"`,
        uom: `"${data?.uom}"`,
        quantity: `"${data?.quantity}"`,
        recordLevel: `"${data?.recordLevel}"`
      };
    });
  };

  const extractFaultyData = () => {
    setDataLoaded(false);
    if (
      relocationsWithIssues &&
      relocationsWithIssues?.length &&
      verifyFlag &&
      warehouseId &&
      selectedCompany &&
      selectedFile
    ) {
      let title = `Faulty Relocations`;
      const dataToExport = dataMaker(relocationsWithIssues);

      exportToCSV(header, dataToExport, title);
    }
    setDataLoaded(true);
  };

  const exportToExcel = () => {
    try {
      if (verifyFlag && warehouseId && selectedCompany && selectedFile) {
        let title = `Verified Relocations`;
        const dataToExport = dataMaker(verifiedRelocations);

        exportToCSV(header, dataToExport, title);
      }
    } catch (error) {
      onError(error);
    }
  };

  const changeForm = (form) => {
    if (
      verifyFlag &&
      warehouseId &&
      (selectedCompany || IS_MANUFACTURER) &&
      selectedFile
    ) {
      let formstate = { ...openForm };
      let classnames = { ...tabClasses };
      Object.keys(formstate).forEach((key) => {
        if (key === form) {
          formstate[key] = true;
          classnames[key] = 'selected-tab';
        } else {
          formstate[key] = false;
          classnames[key] = '';
        }
      });
      setOpenForm(formstate);
      setClasses(classnames);
    }
  };

  const nextButtons = () => {
    if (!verifyFlag) {
      return (
        <Button
          disabled={
            warehouseId && (IS_MANUFACTURER || selectedCompany) && selectedFile
              ? false
              : true
          }
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            handleSubmit();
          }}
          fullWidth
        >
          VERIFY SECTION RELOCATIONS
        </Button>
      );
    }

    if (verifyFlag && !correctUploadFlag) {
      return (
        <>
          <Button
            disabled={
              warehouseId &&
              (IS_MANUFACTURER || selectedCompany) &&
              selectedFile
                ? false
                : true
            }
            variant="contained"
            className={classes.createButton}
            onClick={() => {
              const containWarning = verifiedRelocations.find(
                (r) => r?.warning
              );
              if (!containWarning) {
                handleCreation();
              } else {
                setOpen(true);
              }
            }}
            fullWidth
          >
            UPLOAD CORRECT RELOCATIONS
          </Button>
          <Popup
            title="Are you sure?"
            open={open}
            setOpen={setOpen}
            content={
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <p>
                      You are trying to inbound in a section which already{' '}
                      contains units.
                    </p>
                    <p>If you would still like to proceed, please confirm:</p>
                  </Grid>
                  <Grid item xs={9}></Grid>
                  <Grid item xs={3} className="flex justify-between">
                    <CustomButton
                      label="No"
                      variant="tertiary"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                    <CustomButton
                      label="Yes"
                      variant="primary"
                      onClick={handleCreation}
                    />
                  </Grid>
                </Grid>
              </>
            }
          />
        </>
      );
    }

    return (
      <Button
        disabled={
          warehouseId && (IS_MANUFACTURER || selectedCompany) && selectedFile
            ? false
            : true
        }
        variant="contained"
        className={classes.createButton}
        onClick={() => {
          extractFaultyData();
        }}
        fullWidth
      >
        EXTRACT RELOCATIONS WITH ISSUES
      </Button>
    );
  };

  return (
    <Grid className={classes.gridContainer}>
      <div className={!dataloaded || 'display-none'}>
        <TemplateLoader />
      </div>
      <div className="position-relative mt-10">
        <div className="border">
          <Tooltip title="Bulk Relocation Upload">
            <div
              className={`order-form-tabs first-tab ${tabClasses.tab1}`}
              onClick={() => changeForm('tab1')}
            ></div>
          </Tooltip>
          <Tooltip title="Validation Screen">
            <div
              className={`order-form-tabs second-tab ${tabClasses.tab2}`}
              onClick={() => changeForm('tab2')}
            ></div>
          </Tooltip>
          <Tooltip title="Summary of Upload">
            <div
              className={`order-form-tabs third-tab ${tabClasses.tab3}`}
              onClick={() => changeForm('tab3')}
            ></div>
          </Tooltip>
        </div>
      </div>

      <div className={`${classes.mainContent} mt-10`}>
        {openForm.tab1 && (
          <BulkUploadScreen
            searchCompanyState={{ searchCompanyValue, setSearchCompanyValue }}
            bulkUpload={bulkUpload}
            setCompanies={setCompanies}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            companies={companies}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            setSelectedWarehouse={setSelectedWarehouse}
            selectedWarehouse={selectedWarehouse}
            warehouses={warehouses}
            setWarehouseId={setWarehouseId}
            setSearchWarehouseValue={setSearchWarehouseValue}
            setSingleCompany={setSingleCompany}
            singleCompany={singleCompany}
            IS_MANUFACTURER={IS_MANUFACTURER}
          />
        )}
        {verifyFlag &&
          warehouseId &&
          (IS_MANUFACTURER || selectedCompany) &&
          selectedFile &&
          openForm.tab2 && (
            <>
              <BulkValidationScreen relocations={verifiedRelocations} />
              <Grid container item xs={12} alignItems="center" spacing={1}>
                <Grid item xs={6}>
                  <div className={classes.exportButton}>
                    <button className="exportCustomBtn" onClick={exportToExcel}>
                      Export
                    </button>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        {correctUploadFlag &&
          verifyFlag &&
          warehouseId &&
          (IS_MANUFACTURER || selectedCompany) &&
          selectedFile &&
          openForm.tab3 && (
            <>
              <h3 className={classes.margin20tb}>
                {correctRelocations} Relocations have been created
              </h3>
              <h4 className={classes.margin20tb}>
                Remaining Data with issues
                <span
                  className={classes.faulty}
                >{`(${inCorrectRelocations})`}</span>
              </h4>
              <BulkValidationScreen
                tableHeight={680}
                relocations={relocationsWithIssues}
              />
            </>
          )}
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={2}
          className="relocationButtons"
        >
          <Grid item xs={12} md={6} className={classes.paddingTop12}>
            <Button
              variant="contained"
              className={classes.cancelButton}
              onClick={() => navigate('/operations/section-relocation')}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {nextButtons()}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default AddBulkSectionRelocation;

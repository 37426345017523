import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect
} from 'react';
import {
  Grid,
  TextField,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  FormControl,
  Typography,
  Tooltip
} from '@material-ui/core';
import { debounce } from 'lodash';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { withScriptjs, withGoogleMap } from 'react-google-maps';
import { useFormik } from 'formik';
import Geocode from 'react-geocode';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { toaster } from '../../utils/toaster';
import SearchBar from '../../atomicComponents/SearchBar';
import InfoModal from '../../components/InfoModal';
import LoaderOverlay from '../../layouts/DashboardLayout/LoaderOverlay';
import ListingTitle from '../../atomicComponents/ListingTitle';
import { dividerDateFormat, SharedContext } from '../../utils/common';
import DragItems from '../../components/DragItems';
import { DEBOUNCE_CONST } from '../../Config';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import OrderManagementStats from '../../components/stats/OrderManagementStats';
import DetailTitle from '../../atomicComponents/DetailTitle';
import TabsBtn from '../../atomicComponents/TabsBtn';
import RouteMaps from '../../components/RouteMaps.js';
import StatusArrow from '../../assets/icons/statusArrow.svg';
import '../../assets/css/style.css';
import DownloadIcon from '../../assets/icons/download.svg';
import TruckIcon from '../../assets/icons/truck.svg';
import TransferIcon from '../../assets/icons/transfer.svg';
import UploadIcon from '../../assets/icons/uploads.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { GOOGLE_MAP_API_KEY } from '../../constants/index.js';

const useStyles = makeStyles(() => ({
  addRouteContainer: {
    padding: '0 30px',
    margin: '0 0 30px 0'
  },
  routeAutocomplete: {
    width: '90%'
  },
  driverField: {
    marginTop: '-6px'
  }
}));

export default function AddRoute() {
  const navigate = useNavigate();
  const { companyId, routeId } = useParams();
  const classes = useStyles();
  const WrappedMap = withScriptjs(withGoogleMap(RouteMaps));

  const [currentFilter, setCurrentFilter] = useState(!routeId ? 'ALL' : '');
  const [searchValue, setSearchValue] = useState('');
  const [moveType, setMoveType] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [ordersInRouteCount, setOrdersInRouteCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [page, setPage] = useState(1);
  const [infoDetailView, setInfoDetailView] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [goHead, setGoHead] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [singleOrder, setSingleOrder] = useState('');
  const [customerKey, setCustomerKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [end, setEnd] = useState(false);
  const [orderStats, setOrderStats] = useState('');
  const [tab, setTab] = useState('');
  const [pickupNodeCall, setPickupNodeCall] = useState('');
  const [orders, setOrders] = useState([]);
  const [ordersInRoute, setOrdersInRoute] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorKey, setVendorKey] = useState('');
  const [vehicleKey, setVehicleKey] = useState('');
  const [vehicleTypeKey, setVehicleTypeKey] = useState('');

  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  useEffect(() => {
    let title = 'Route Planning';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  const [formikInitVals, setInitVals] = useState({
    companyId: null,
    vehicleTypeId: null,
    vehicleId: null,
    driverId: null,
    driverPhone: null,
    vendorId: null,
    costPrice: null,
    sellingPrice: null,
    orders: []
  });

  const formik = useFormik({
    initialValues: formikInitVals,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      companyId: Yup.object().nullable().required('Company is required')
    }),
    onSubmit: (values) => {
      setClicked(true);
      values.companyId = values?.companyId?.id;
      values.vehicleTypeId = values?.vehicleTypeId?.id;
      values.vehicleId = values?.vehicleId?.id;
      values.driverId = values?.driverId?.id;
      values.vendorId = values?.vendorId?.id;
      values.driverPhone = values?.driverPhone || null;
      values.orders = ordersInRoute?.map((order) => {
        return {
          orderId: order?.id,
          moveType: order?.moveType,
          sequenceNumber: order?.sequenceNumber
        };
      });

      addRoute(values);
    }
  });

  function removeVendorDuplicate(vendorTemp) {
    const prevAdded = [];
    const newArray = [];

    vendorTemp.forEach((item) => {
      if (!prevAdded.includes(item.Vendor.id)) {
        newArray.push(item);
        prevAdded.push(item.Vendor.id);
      }
    });

    return newArray;
  }

  const getRoute = async () => {
    try {
      setAPILoader(true);
      setLoading(true);
      let res = await API.get(`routes/company/${companyId}/route/${routeId}`);

      setPickupNodeCall(
        res?.data?.RouteOrderGroups[0]?.Order?.pickupNode?.name
      );
      const routeObj = {
        companyId: {
          ...res?.data?.Company
        },
        ...(res?.data?.Vehicle && { vehicleId: res?.data?.Vehicle }),
        ...(res?.data?.VehicleType && {
          vehicleTypeId: res?.data?.VehicleType
        }),
        ...(res?.data?.Vendor && { vendorId: res?.data?.Vendor }),
        ...(res?.data?.Driver && { driverId: res?.data?.Driver }),
        ...(res?.data?.driverPhone && { driverPhone: res?.data?.driverPhone }),
        ...(res?.data?.costPrice && { costPrice: res?.data?.costPrice }),
        ...(res?.data?.sellingPrice && {
          sellingPrice: res?.data?.sellingPrice
        })
      };
      res?.data?.RouteOrderGroups?.map((order) => {
        setOrdersInRoute((prev) => [
          ...prev,
          {
            id: order?.Order?.id,
            moveType: order?.Order?.moveType,
            createdAt: order?.Order?.createdAt,
            weight: order?.Order?.weight,
            pickupNode: {
              id: order?.Order?.pickupNode?.id,
              name: order?.Order?.pickupNode?.name,
              address: order?.Order?.pickupNode?.address
            },
            warehouseDropOff: {
              id: order?.Order?.warehouseDropOff?.id,
              name: order?.Order?.warehouseDropOff?.name,
              address: order?.Order?.warehouseDropOff?.address
            },
            dropOffNode: {
              id: order?.Order?.dropOffNode?.id,
              name: order?.Order?.dropOffNode?.name,
              address: order?.Order?.dropOffNode?.address
            },
            warehousePickUp: {
              id: order?.Order?.warehousePickUp?.id,
              name: order?.Order?.warehousePickUp?.name,
              address: order?.Order?.warehousePickUp?.address
            }
          }
        ]);
      });
      setInitVals({ ...routeObj });
      setAPILoader(false);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
      setLoading(false);
    }
  };

  const addRoute = async (values) => {
    try {
      setAPILoader(true);
      if (routeId) {
        await API.put(`routes/company/${companyId}/route/${routeId}`, {
          ...values
        });
      } else {
        await API.post(`routes`, { ...values });
      }
      navigate('/routing/route-management');
      const message = routeId
        ? 'Route has been updated successfully'
        : 'Route has been created successfully';

      toaster('success', message);
    } catch (error) {
      setOrders([]);
      setOrdersInRoute([]);
      onError(error);
    } finally {
      setAPILoader(false);
      setClicked(false);
    }
  };

  const getRouteStats = async () => {
    try {
      setAPILoader(true);
      const res = await API.get('orders/stats?isDraft=false');
      setOrderStats(res?.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getCustomers = async (value) => {
    try {
      setAPILoader(true);
      const colVal = {
        '$Company.name$': value,
        '$Company.isActive$': true
      };
      const filters = colVal;
      const params = { filters, orderStatus: 'PENDING' };

      if (currentFilter && currentFilter !== 'ALL') {
        params['moveType'] = currentFilter;
      }
      setCustomers([]);
      const companies = await API.get(`companies`, {
        params
      });
      setCustomers(companies?.data?.rows);
      if (companies?.data?.rows?.length === 1 && !customerKey.length) {
        formik.setFieldValue('companyId', companies?.data?.rows[0]);
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getVendors = async () => {
    try {
      setAPILoader(true);
      const vendors = await API.get(`companies`, {
        params: { type: 'VENDOR' }
      });
      setVendors(vendors?.data?.rows);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getOrders = async () => {
    try {
      setLoading(true);
      setAPILoader(true);
      let colVal = {};
      if (formik.values.companyId) {
        colVal['$Company.id$'] = formik?.values?.companyId?.id;
      }

      if (moveType) {
        colVal['moveType'] = moveType;
      }

      if (pickupNodeCall) {
        colVal['$pickupNode.name$'] = pickupNodeCall;
      }

      if (currentFilter && currentFilter !== 'ALL') {
        colVal['status'] = currentFilter;
        colVal['moveType'] = currentFilter;
      }

      colVal['isDraft'] = false;
      colVal['status'] = 'PENDING';
      colVal['logisticBy'] = 'OWARE';

      let selectedOrders = [];
      ordersInRoute?.map((order) => {
        selectedOrders.push(order?.id);
        colVal['moveType'] = order.moveType;
        if (order.moveType == 'INWARD') {
          colVal['$warehouseDropOff.name$'] = order?.warehouseDropOff?.name;
        } else if (order.moveType == 'OUTBOUND') {
          colVal['$warehousePickUp.name$'] = order?.warehousePickUp?.name;
        }
      });
      let filters = { colVal };
      let columns = [
        '$Company.id$',
        '$pickupNode.name$',
        '$dropOffNode.name$',
        '$Company.name$',
        'id'
      ];
      let res = await API.get('orders', {
        params: {
          page,
          search: searchValue,
          selectedOrders,
          limit: 3,
          filters,
          columns,
          exact: 1
        }
      });
      setOrdersCount(res?.count);
      setPageCount(res.pages);
      setOrders(res?.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
      setLoading(false);
    }
  };

  const getNewCars = async (value) => {
    try {
      setAPILoader(true);
      let columns = [
        '$CarMake.name$',
        '$CarModel.name$',
        '$Category.name$',
        '$VehicleType.mileage$',
        '$VehicleType.weight$',
        '$VehicleType.volume$'
      ];
      let vehicleTypes = await API.get(`vehicles/types`, {
        params: { search: value, columns }
      });
      setVehicleTypes(
        vehicleTypes?.data.filter(
          (vehicleType) => vehicleType.isActive === true
        )
      );
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getVehicles = async (value) => {
    try {
      setAPILoader(true);
      let columns = [
        'registrationNumber',
        '$Vendor.name$',
        '$`Car->CarMake`.name$'
      ];
      let colVal = {};
      colVal.isActive = 1;
      colVal['$Car.id$'] = formik.values.vehicleTypeId?.id;
      let filters = { colVal };
      const vehicles = await API.get(`vehicles`, {
        params: { page: 1, search: value, columns, filters }
      });
      setVehicles(vehicles.data);
      setVendors(removeVendorDuplicate(vehicles.data));
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getDrivers = async () => {
    try {
      setAPILoader(true);
      let colVal = {};
      colVal.companyId = formik.values.vendorId.id;
      let filters = { colVal };
      const drivers = await API.get(`drivers`, { params: { filters } });
      setDrivers(drivers.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getDelayedCustomers = useCallback(
    debounce((value) => {
      setCustomers([]);
      getCustomers(value);
    }, DEBOUNCE_CONST),
    []
  );

  const getDelayedVehicleTypes = useCallback(
    debounce((value) => {
      setVehicleTypes([]);
      getNewCars(value);
    }, DEBOUNCE_CONST),
    []
  );

  const getDelayedVehicles = useCallback(
    debounce((value) => {
      setVehicles([]);
      getVehicles(value);
    }, DEBOUNCE_CONST),
    []
  );

  const getDelayedVendors = useCallback(
    debounce((value) => {
      setVendors([]);
      getVendors(value);
    }, DEBOUNCE_CONST),
    []
  );

  const map = useMemo(
    () => (
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${GOOGLE_MAP_API_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: '80vh' }} />}
        mapElement={<div style={{ height: `100%` }} />}
        wayPoints={ordersInRoute}
      />
    ),
    [ordersInRoute]
  );

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = ordersInRoute[dragIndex];
      const hoverItem = ordersInRoute[hoverIndex];
      setOrdersInRoute((ordersInRoute) => {
        const updatedOrders = [...ordersInRoute];
        updatedOrders[dragIndex] = hoverItem;
        updatedOrders[hoverIndex] = dragItem;
        return updatedOrders;
      });
    },
    [ordersInRoute]
  );

  const setGeoCodeLatLng = async (address, latLng) => {
    if (address) {
      const response = await Geocode.fromAddress(address);
      const latLong = response.results[0].geometry.location;
      if (latLng == 'lat') {
        return latLong?.lat;
      } else {
        return latLong?.lng;
      }
    }
  };

  const settingOrders = (order) => {
    setSingleOrder(order);
    setInfoDetailView(true);
  };

  const updateOrdersState = () => {
    setGoHead(false);
    setOrdersInRoute((currentOrder) =>
      currentOrder.filter((ordersInRoute) => {
        return ordersInRoute?.id !== singleOrder?.id;
      })
    );
    setOrders((prev) => [
      ...prev,
      {
        id: singleOrder?.id,
        moveType: singleOrder?.moveType,
        pickupNode: {
          id: singleOrder?.pickupNode?.id,
          name: singleOrder?.pickupNode?.name,
          address: singleOrder?.pickupNode?.address
        },
        dropOffNode: {
          id: singleOrder?.dropOffNode?.id,
          name: singleOrder?.dropOffNode?.name,
          address: singleOrder?.dropOffNode?.address
        },
        warehouseDropOff: {
          id: singleOrder?.warehouseDropOff?.id,
          name: singleOrder?.warehouseDropOff?.name,
          address: singleOrder?.warehouseDropOff?.address
        },
        warehousePickUp: {
          id: singleOrder?.warehousePickUp?.id,
          name: singleOrder?.warehousePickUp?.name,
          address: singleOrder?.warehousePickUp?.address
        },
        createdAt: singleOrder?.createdAt,
        weight: singleOrder?.weight
      }
    ]);
    setOrdersCount(ordersCount + 1);
  };

  const updateOrders = (orderId) => {
    setOrders((current) =>
      current.filter((order) => {
        return order.id !== orderId;
      })
    );
    setOrdersCount(ordersCount - 1);
  };

  const timer = () => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(async () => {
    if (currentFilter) {
      await formik.setFieldValue('companyId', null);
      setTimeout(() => {
        clearStates();
        getCustomers();
      });
    }
  }, [currentFilter]);

  useEffect(() => {
    if (customerKey) {
      getDelayedCustomers(customerKey);
    }
  }, [customerKey]);

  useEffect(() => {
    if (vehicleTypeKey) {
      getDelayedVehicleTypes(vehicleTypeKey);
    }
  }, [vehicleTypeKey]);

  useEffect(() => {
    if (vehicleKey) {
      getDelayedVehicles(vehicleKey);
    }
  }, [vehicleKey]);

  useEffect(() => {
    if (vendorKey) {
      getDelayedVendors(vendorKey);
    }
  }, [vendorKey]);

  useEffect(() => {
    getRouteStats();
    getCustomers();
    getVehicles();
    getNewCars();
    if (companyId && routeId) {
      setTab('orderInRoute');
      getRoute();
    }
  }, []);

  useEffect(() => {
    if (
      formik?.values?.companyId ||
      page > 1 ||
      searchValue ||
      pickupNodeCall
    ) {
      getOrders();
    } else {
      setOrders([]);
    }
  }, [
    formik?.values?.companyId,
    page,
    currentFilter,
    searchValue,
    pickupNodeCall
  ]);

  useEffect(() => {
    if (goHead) {
      updateOrdersState();
    }
  }, [goHead]);

  useEffect(() => {
    if (formik?.values?.vehicleTypeId) {
      getVehicles();
    }
    if (formik?.values?.vendorId) {
      getDrivers();
    }
  }, [formik?.values?.vehicleTypeId, formik?.values?.vendorId]);

  useEffect(() => {
    if (ordersInRoute) {
      setOrdersInRouteCount(`Orders In Route (${ordersInRoute.length})`);
      ordersInRoute.map((order, i) => {
        order.sequenceNumber = i;
      });

      formik?.values?.orders?.map((currentOrder, index) => {
        currentOrder.sequenceNumber = index;
      });
    } else {
      getOrders();
    }
  }, [ordersInRoute]);

  const clearStates = () => {
    formik.setFieldValue('orders', []);
    setOrders([]);
    setPickupNodeCall('');
    setOrdersCount(0);
    setOrdersInRouteCount(0);
    setPageCount(0);
    setOrdersInRoute([]);
    setTab('pendingOrders');
  };

  return (
    <Grid item container className={classes.addRouteContainer}>
      <InfoModal
        setGoHead={(value) => setGoHead(value)}
        goHead={goHead}
        open={infoDetailView}
        handleClose={(value) => setInfoDetailView(value)}
      />
      {loading ? <LoaderOverlay /> : ''}
      <form
        noValidate
        onSubmit={formik.handleSubmit}
        className="w-100 formWidth"
      >
        <Grid item container>
          <Grid item xs={12}>
            <Grid className="orderManagementStats">
              <ListingTitle text="Order Type :" />
              <OrderManagementStats
                stats={orderStats}
                setCurrentFilter={(value) =>
                  routeId ? '' : setCurrentFilter(value)
                }
                currentFilter={currentFilter}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth={true} variant="outlined">
              <Autocomplete
                id="companyId"
                key="customer"
                disabled={routeId}
                onBlur={formik.handleBlur}
                options={customers ? customers : []}
                value={
                  formik?.values?.companyId
                    ? {
                        id: formik?.values?.companyId?.id,
                        name: formik?.values?.companyId?.name
                      }
                    : null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Customer" variant="outlined" />
                )}
                renderOption={(props) => {
                  return (
                    <span {...props} style={{ width: '100%' }}>
                      <p>
                        {props?.name}
                        <span
                          style={{
                            background: '#F8D7D7',
                            color: 'black',
                            'box-sizing': 'border-box',
                            float: 'right',
                            padding: '0px 12px 1px 12px',
                            'border-radius': '48px'
                          }}
                        >
                          {props.pendingOrders}
                        </span>
                      </p>
                    </span>
                  );
                }}
                getOptionLabel={(customers) => {
                  return customers?.name || '';
                }}
                onChange={(e, val) => {
                  formik.setFieldValue('companyId', val !== null && val);
                  clearStates();
                }}
                onKeyPress={(e) => setCustomerKey(e.target.value)}
              />
              {formik.errors.companyId && formik.touched.companyId ? (
                <Typography className="error">
                  {formik.errors.companyId}
                </Typography>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container className="mt-5">
          <Grid item xs={12}>
            <DetailTitle text="Routes" />
          </Grid>
          <Grid item container className="mt-3 display-flex">
            <div>
              <TabsBtn
                text={`Pending Orders (${ordersCount})`}
                tab="pendingOrders"
                color={tab && tab == 'pendingOrders' ? 'active' : ''}
                setTab={(value) =>
                  tab && tab == value ? setTab('') : setTab(value)
                }
              />
            </div>

            <div className="ml-3 routeOrder">
              <TabsBtn
                text={ordersInRouteCount}
                tab="orderInRoute"
                color={tab && tab == 'orderInRoute' ? 'active' : ''}
                setTab={(value) =>
                  tab && tab == value ? setTab('') : setTab(value)
                }
              />
            </div>

            {tab == 'pendingOrders' ? (
              <Grid item container className="mt-3 mb-3">
                <SearchBar
                  setSearchValue={(e) => {
                    setPage(1);
                    setSearchValue(e);
                  }}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item container className={tab ? '' : 'display-none'}>
              <Grid className="scroll-Routetable">
                <Table className="detailTable">
                  <TableHead className="tableHeader">
                    <TableCell id="originColumn">Origin</TableCell>
                    <TableCell>Move ID</TableCell>
                    <TableCell>Date & Time</TableCell>
                    <TableCell>Drop-off</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>
                      {tab && tab == 'pendingOrders'
                        ? 'Move to Route'
                        : 'Action'}
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {tab && tab == 'pendingOrders' ? (
                      orders?.length ? (
                        orders?.map((order, index) => {
                          let source = '';
                          let pickup = '';
                          let dropoff = '';
                          switch (order?.moveType) {
                            case 'LOGISTIC':
                              dropoff = order?.dropOffNode?.name;
                              pickup = order?.pickupNode?.name;
                              source = TruckIcon;
                              break;
                            case 'INWARD':
                              dropoff = order?.warehouseDropOff?.name;
                              pickup = order?.pickupNode?.name;
                              source = DownloadIcon;
                              break;
                            case 'OUTBOUND':
                              dropoff = order?.dropOffNode?.name;
                              pickup = order?.warehousePickUp?.name;
                              source = UploadIcon;
                              break;
                            case 'TRANSFER':
                              dropoff = order?.warehouseDropOff?.name;
                              pickup = order?.warehousePickUp?.name;
                              source = TransferIcon;
                              break;
                            default:
                              source = TruckIcon;
                              break;
                          }
                          return (
                            <TableRow key={index}>
                              <TableCell>{pickup}</TableCell>
                              <TableCell>
                                <img src={source} /> {order?.id}
                              </TableCell>
                              <TableCell>
                                {dividerDateFormat(order?.createdAt)}
                              </TableCell>
                              <TableCell>{dropoff}</TableCell>
                              <TableCell>{order?.weight}</TableCell>
                              <TableCell>
                                <img
                                  src={StatusArrow}
                                  alt="Status Arrow"
                                  id="orders"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setLoading(true);
                                    toaster(
                                      'success',
                                      'Order is assigned to the route'
                                    );
                                    timer();
                                    if (
                                      pickupNodeCall !== order?.pickupNode?.name
                                    ) {
                                      setPickupNodeCall(
                                        order?.pickupNode?.name
                                      );
                                    }
                                    if (!moveType) {
                                      setMoveType(order?.moveType);
                                    }
                                    setPage(1);
                                    updateOrders(order?.id);
                                    setOrdersInRoute((prev) => [
                                      ...prev,
                                      {
                                        pickupNode: {
                                          id: order?.pickupNode?.id,
                                          name: order?.pickupNode?.name,
                                          address: order?.pickupNode?.address
                                        },
                                        id: order?.id,
                                        moveType: order?.moveType,
                                        createdAt: order?.createdAt,
                                        dropOffNode: {
                                          id: order?.dropOffNode?.id,
                                          name: order?.dropOffNode?.name,
                                          address: order?.dropOffNode?.address
                                        },
                                        warehouseDropOff: {
                                          id: order?.warehouseDropOff?.id,
                                          name: order?.warehouseDropOff?.name,
                                          address:
                                            order?.warehouseDropOff?.address
                                        },
                                        warehousePickUp: {
                                          id: order?.warehousePickUp?.id,
                                          name: order?.warehousePickUp?.name,
                                          address:
                                            order?.warehousePickUp?.address
                                        },
                                        weight: order?.weight,
                                        pickupLatLng: {
                                          lat: setGeoCodeLatLng(
                                            order?.pickupNode?.address,
                                            'lat'
                                          ),
                                          lng: setGeoCodeLatLng(
                                            order?.pickupNode?.address,
                                            'lng'
                                          )
                                        },
                                        dropOffLatLng: {
                                          lat: setGeoCodeLatLng(
                                            order?.dropOffNode?.address,
                                            'lat'
                                          ),
                                          lng: setGeoCodeLatLng(
                                            order?.dropOffNode?.address
                                          )
                                        }
                                      }
                                    ]);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow className="routeTableBorder">
                          <TableCell className="messageAlert">
                            {loading
                              ? ''
                              : "There's no pending orders for this customer"}
                          </TableCell>
                        </TableRow>
                      )
                    ) : null}
                    {tab && tab == 'orderInRoute' ? (
                      ordersInRoute && ordersInRoute.length ? (
                        ordersInRoute?.map((order, i) => {
                          return (
                            <DragItems
                              data={order}
                              goHead={goHead}
                              setInfoDetailView={(value) =>
                                setInfoDetailView(value)
                              }
                              setEnd={(flag) => setEnd(flag)}
                              index={i}
                              end={end}
                              key={order?.id}
                              moveListItem={moveItem}
                              settingOrders={settingOrders}
                              setOrdersInRoute={(orders) =>
                                setOrdersInRoute(orders)
                              }
                            />
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell>
                            {loading ? '' : "There's no orders in Route"}
                          </TableCell>
                        </TableRow>
                      )
                    ) : null}
                    {tab && tab == 'pendingOrders' ? (
                      <TableRow>
                        <TableCell>
                          <Pagination
                            component="div"
                            shape="rounded"
                            count={pageCount}
                            color="primary"
                            page={page}
                            className={classes.pagination}
                            onChange={(e, newPage) => setPage(newPage)}
                          />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <div className="addRouteMapsDesign">
              <Grid item container className="mt-3">
                <Grid item xs={12} key={ordersInRoute.length}>
                  {map}
                </Grid>
              </Grid>

              <Grid item container className="mt-5">
                <DetailTitle text="Route Details" />
              </Grid>
              <Grid item container className="mt-5">
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="vehicleTypeId"
                      key="vehicleTypeId"
                      className={classes.routeAutocomplete}
                      options={vehicleTypes ? vehicleTypes : []}
                      value={
                        formik.values && formik?.values?.vehicleTypeId
                          ? {
                              id: formik?.values?.vehicleTypeId?.id,
                              CarMake: {
                                id: formik.values?.vehicleTypeId?.CarMake?.id,
                                name: formik.values?.vehicleTypeId?.CarMake
                                  ?.name
                              },
                              CarModel: {
                                id: formik.values?.vehicleTypeId?.CarModel?.id,
                                name: formik.values?.vehicleTypeId?.CarModel
                                  ?.name
                              },
                              VehicleType: {
                                id: formik.values?.vehicleTypeId?.VehicleType
                                  ?.id,
                                category:
                                  formik.values?.vehicleTypeId?.VehicleType
                                    ?.category
                              }
                            }
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vehicle Types"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(vehicles) => {
                        return `${
                          typeof vehicles?.CarMake?.name == 'undefined'
                            ? ''
                            : vehicles?.CarMake?.name
                        } ${
                          typeof vehicles?.CarModel?.name == 'undefined'
                            ? ''
                            : vehicles?.CarModel?.name
                        } ${
                          typeof vehicles?.Category?.name == 'undefined'
                            ? ''
                            : vehicles?.Category?.name
                        }`;
                      }}
                      onChange={(e, val) => {
                        formik.setFieldValue(
                          'vehicleTypeId',
                          val !== null && val
                        );
                        formik.setFieldValue('vehicleId', null);
                        formik.setFieldValue('vendorId', null);
                        formik.setFieldValue('driverId', null);
                      }}
                      onKeyPress={(e) => setVehicleTypeKey(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="vendorField">
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="vendorId"
                      key="vendorId"
                      onBlur={formik.handleBlur}
                      className={classes.routeAutocomplete}
                      options={vendors ? vendors : []}
                      value={
                        formik?.values?.vendorId
                          ? {
                              id: formik?.values?.vendorId?.id,
                              name: formik?.values?.vendorId?.name
                            }
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Carrier"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(vendor) => {
                        if (vendor?.name) {
                          return vendor.name;
                        }

                        return vendor?.Vendor ? vendor?.Vendor?.name : '';
                      }}
                      onChange={(e, val) => {
                        formik.setFieldValue(
                          'vendorId',
                          val !== null && val.Vendor
                        );
                        formik.setFieldValue('vehicleId', null);
                        formik.setFieldValue('driverId', null);
                      }}
                      onKeyPress={(e) => setVendorKey(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="vehicleId">
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="vehicleId"
                      key="vehicleId"
                      onBlur={formik.handleBlur}
                      className={classes.routeAutocomplete}
                      options={vehicles ? vehicles : []}
                      value={
                        formik?.values?.vehicleId
                          ? {
                              id: formik?.values?.vehicleId?.id,
                              registrationNumber:
                                formik?.values?.vehicleId?.registrationNumber
                            }
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vehicle Number"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(vehicle) => {
                        return vehicle.registrationNumber || '';
                      }}
                      onChange={(e, val) => {
                        formik.setFieldValue('vehicleId', val !== null && val);
                      }}
                      onKeyPress={(e) => setVehicleKey(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="driverId">
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="driverId"
                      key="driverId"
                      onBlur={formik.handleBlur}
                      className={classes.routeAutocomplete}
                      options={drivers ? drivers : []}
                      value={
                        formik?.values?.driverId
                          ? {
                              id: formik?.values?.driverId?.id,
                              name: formik?.values?.driverId?.name
                            }
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Driver Name"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(drivers) => {
                        return drivers?.name || '';
                      }}
                      onChange={(e, val) => {
                        formik.setFieldValue('driverId', val !== null && val);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container className="mt-6">
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth={true} variant="outlined">
                    <PhoneInput
                      country={'pk'}
                      value={formik.values.driverPhone || null}
                      onChange={(phone) => {
                        formik.setFieldValue('driverPhone', phone);
                      }}
                      inputStyle={{ width: '255px', height: '56px' }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="vehicleNumber">
                  <FormControl fullWidth={true} variant="outlined">
                    <TextField
                      id="costPrice"
                      key="costPrice"
                      name="costPrice"
                      type="number"
                      fullWidth={true}
                      placeholder="Cost Price"
                      onBlur={formik.handleBlur}
                      autoFocus={formik?.values?.costPrice ? true : false}
                      value={formik.values.costPrice || ''}
                      onChange={formik.handleChange}
                      variant="outlined"
                      label="Cost Price"
                      className={classes.routeAutocomplete}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} className="driverId">
                  <FormControl fullWidth={true} variant="outlined">
                    <TextField
                      id="sellingPrice"
                      key="sellingPrice"
                      name="sellingPrice"
                      type="number"
                      value={formik.values.sellingPrice || ''}
                      autoFocus
                      onChange={formik.handleChange}
                      className={classes.routeAutocomplete}
                      variant="outlined"
                      label="Selling Price"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container className="mt-5">
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Tooltip title="Cancel">
                    <button
                      className="cancelRouteBtn"
                      onClick={() => navigate(`/routing/route-management`)}
                    >
                      Cancel
                    </button>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  className="text-right routeUpdateButton"
                >
                  <Tooltip
                    title={
                      companyId && routeId ? 'UPDATE THE ROUTE' : 'CREATE ROUTE'
                    }
                  >
                    <button
                      className={clicked ? 'submitBtn no-drop' : 'submitBtn'}
                      type="submit"
                      disabled={clicked}
                    >
                      {companyId && routeId
                        ? 'UPDATE THE ROUTE'
                        : 'CREATE ROUTE'}
                    </button>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import NodesOverlay from './NodesOverlay';
import { GoogleMap, HeatmapLayer } from '@react-google-maps/api';
import {
  convertLatLng,
  getLinks,
  getMapLegend,
  getNodeDirection
} from '../utils';
import { ShipmentsDirection } from '../../constants';
import {
  PRIMARY,
  PRIMARY_BLUE,
  PRIMARY_DARK,
  PRIMARY_LIGHT
} from '../../../../constants/colors';

const SupplyChainMap = ({
  nodeData,
  isLoading,
  startDate,
  endDate,
  markers,
  setMarkers,
  clusterer,
  setClusterer,
  statuses,
  nodeType,
  fromNode,
  toNode,
  searchKeyword,
  setSearchKeyword,
  setIsReload,
  shipmentsModal,
  setShipmentsModal
}) => {
  const [map, setMap] = useState();
  const [source, setSource] = useState([]);
  const [highlight, setHighlight] = useState();
  const [clusterNodes, setClusterNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const [linkPolyline, setLinkPolyline] = useState([]);
  const [nodeListing, setNodeListing] = useState(nodeData);

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  };

  const createHeatmapDataFromObject = (dataObject) => {
    return Object.values(dataObject).map((item) => ({
      location: new window.google.maps.LatLng(
        item.position.lat,
        item.position.lng
      ),
      weight: Object.values(dataObject).length
    }));
  };
  const heatmapData = createHeatmapDataFromObject(nodeListing);

  useEffect(() => {
    if (map) {
      linkPolyline.forEach((polyline) => {
        polyline?.setMap(null);
      });
      const objects = [];
      links.forEach((nodeLink) => {
        const direction = getNodeDirection(
          nodeLink.link,
          nodeListing[highlight].directions
        );
        if (direction === ShipmentsDirection.NONE) {
          return;
        }
        // eslint-disable-next-line no-undef
        const dashes = new google.maps.Polyline({
          path: [
            { lat: source[1], lng: source[0] },
            { lat: nodeLink.location[1], lng: nodeLink.location[0] }
          ],
          strokeColor: '#020b1f',
          strokeOpacity: 0,
          geodesic: true,
          icons: [
            {
              icon: {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                scale: 2
              },
              offset: '0',
              repeat: '10px'
            }
          ],
          map: map
        });
        if (direction === ShipmentsDirection.BOTH) {
          // eslint-disable-next-line no-undef
          const inboundArrows = new google.maps.Polyline({
            path: [
              { lat: source[1], lng: source[0] },
              { lat: nodeLink.location[1], lng: nodeLink.location[0] }
            ],
            strokeOpacity: 0,
            geodesic: true,
            icons: [
              {
                icon: {
                  path: 'M -11 -13 H 11 L 0 -32 L -11 -13 Z Z Z',
                  strokeColor: '#020b1f',
                  fillColor: '#020b1f',
                  fillOpacity: 1,
                  scale: 0.5,
                  strokeWeight: 1,
                  rotation: 180
                },
                offset: '50%',
                path: []
              }
            ],
            map: map
          });
          // eslint-disable-next-line no-undef
          const outboundArrows = new google.maps.Polyline({
            path: [
              { lat: source[1], lng: source[0] },
              { lat: nodeLink.location[1], lng: nodeLink.location[0] }
            ],
            strokeOpacity: 0,
            geodesic: true,
            icons: [
              {
                icon: {
                  path: 'M -11 -13 H 11 L 0 -32 L -11 -13 Z Z Z',
                  strokeColor: '#020b1f',
                  fillColor: '#020b1f',
                  fillOpacity: 1,
                  scale: 0.5,
                  strokeWeight: 1,
                  rotation: 0
                },
                offset: '50%',
                path: []
              }
            ],
            map: map
          });
          objects.push(inboundArrows);
          objects.push(outboundArrows);
        } else {
          // eslint-disable-next-line no-undef
          const arrows = new google.maps.Polyline({
            path: [
              { lat: source[1], lng: source[0] },
              { lat: nodeLink.location[1], lng: nodeLink.location[0] }
            ],
            strokeOpacity: 0,
            geodesic: true,
            icons: [
              {
                icon: {
                  path: 'M -11 -13 H 11 L 0 -32 L -11 -13 Z Z Z',
                  strokeColor: '#020b1f',
                  fillColor: '#020b1f',
                  fillOpacity: 1,
                  scale: 0.5,
                  strokeWeight: 1,
                  rotation: direction === ShipmentsDirection.INWARD ? 180 : 0
                },
                offset: '50%',
                path: []
              }
            ],
            map: map
          });
          objects.push(arrows);
        }
        objects.push(dashes);
      });
      setLinkPolyline(objects);
    }
  }, [map, links]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    map?.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
      getMapLegend()
    );
  }, [map]);

  useEffect(() => {
    if (fromNode && toNode) {
      let filteredData = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(nodeData).filter(([key, value]) => {
          return value.id === fromNode || value.id === toNode;
        })
      );
      let matchedKey =
        Object.entries(filteredData).find(
          // eslint-disable-next-line no-unused-vars
          ([key, value]) => value.id === fromNode
        )?.[0] || null; // Default to null if no match

      if (markers) {
        markers?.forEach((marker) => {
          marker.setMap(null);
        });
      }
      setMarkers([]);
      clusterer?.clearMarkers(false);
      setHighlight(matchedKey);
      setSource(
        convertLatLng(filteredData[matchedKey].position || { lat: 0, lng: 0 })
      );
      setLinks(getLinks(matchedKey, filteredData));
      setNodeListing(filteredData);
      setIsReload(false);
    } else {
      if (markers) {
        markers?.forEach((marker) => {
          marker.setMap(null);
        });
      }
      setMarkers([]);
      clusterer?.clearMarkers(false);
      setNodeListing(nodeData);
      setHighlight(null);
      setSource([]);
      setLinks([]);
      setIsReload(false);
    }
  }, [fromNode, toNode, nodeData]);

  return (
    <>
      <div
        className={clsx(
          'w-full h-full transition-all',
          (isLoading || Object.keys(nodeListing).length === 0) && 'blur'
        )}
      >
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          // center={{ lat: 29.600087, lng: 70.008634 }}
          zoom={5}
          options={mapOptions}
          onLoad={onLoad}
        >
          <HeatmapLayer
            data={heatmapData}
            options={{
              radius: 50,
              opacity: 0.8,
              gradient: [
                'rgba(0, 255, 255, 0)',
                PRIMARY_LIGHT,
                PRIMARY,
                PRIMARY_DARK,
                PRIMARY_BLUE
              ]
            }}
          />
        </GoogleMap>
        {map && !isLoading > 0 && Object.keys(nodeListing).length > 0 && (
          <NodesOverlay
            nodeData={nodeListing}
            map={map}
            setSource={setSource}
            setLinks={setLinks}
            startDate={startDate}
            endDate={endDate}
            markers={markers}
            setMarkers={setMarkers}
            clusterer={clusterer}
            setClusterer={setClusterer}
            statuses={statuses}
            nodeType={nodeType}
            links={links}
            source={source}
            highlight={highlight}
            setHighlight={setHighlight}
            clusterNodes={clusterNodes}
            setClusterNodes={setClusterNodes}
            fromNode={fromNode}
            toNode={toNode}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            shipmentsModal={shipmentsModal}
            setShipmentsModal={setShipmentsModal}
          />
        )}
      </div>
      {isLoading && (
        <div className="w-100 h-full relative ctower-bottom-position flex items-center justify-center transition-all">
          <CircularProgress />
        </div>
      )}
      {!isLoading && Object.keys(nodeListing).length === 0 && (
        <div className="w-100 h-full relative ctower-bottom-position flex items-center justify-center transition-all">
          <p>No results found within the specified date range</p>
        </div>
      )}
    </>
  );
};

export default SupplyChainMap;

const mapOptions = {
  mapId: 'f821a93ab9a91eb9',
  center: { lat: 29.600087, lng: 70.008634 },
  zoom: 5,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  scrollwheel: false,
  minZoom: 3
};

import React, { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Box, makeStyles, Button } from '@material-ui/core';
import { SharedContext } from '../../utils/common';
import '../../assets/css/style.css';
import { removeAuth } from '../../utils/auth';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)'
  },
  avatar: {
    width: 60,
    height: 60
  },
  userLink: {
    color: theme.palette.primary.dark,
    fontWeight: 'bolder',
    cursor: 'pointer',
    '@media only screen and (max-width: 1200px) and (min-width: 992px)': {
      marginLeft: '9.5% !important',
      transition: '0.2s'
    }
  },
  logout: {
    color: 'black',
    width: '100%',
    justifyContent: 'left',
    padding: '10px 25px'
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    color: '#0B1940'
  }
}));

const UserProfilePic = () => {
  const classes = useStyles();
  const { currentUser, setCurrentUser } = useContext(SharedContext);

  const navigate = useNavigate();

  const logout = () => {
    setCurrentUser(null);
    removeAuth();
    mixpanel.track('Logout', {});
    navigate('/login');
  };

  const handleProfile = () => {
    navigate('/profile/settings');
  };

  const location = useLocation();

  const [menu, setMenu] = useState(false);

  return (
    <Box>
      <div>
        <label>
          <div
            size="medium"
            className={clsx(
              'w-8 h-8 rounded-full flex items-center justify-center cursor-pointer',
              {
                'bg-white text-primaryBlue':
                  location.pathname.split('/').pop() === 'control-tower'
              },
              {
                'bg-primaryBlue text-white':
                  location.pathname.split('/').pop() !== 'control-tower'
              },
              {
                'bg-darkPrimary text-white':
                  location.pathname.split('/').pop() === 'intelligence'
              }
            )}
            onMouseOver={() => setMenu(true)}
            onMouseLeave={() => setMenu(false)}
          >
            <span className="text-sm">
              {currentUser?.firstName[0]?.toUpperCase()}
              {currentUser?.lastName[0]?.toUpperCase()}
            </span>
          </div>
        </label>
        {/* shadow-elevation-2 rounded-[8px] p-4 bg-white */}
        <ul className={`profileDrop ${menu ? 'profileDropVisible' : ''}`}>
          <li>
            <Button
              size="medium"
              onClick={handleProfile}
              className={classes.logout}
            >
              <b>{currentUser.firstName}</b>
            </Button>
          </li>
          <li>
            <Button
              size="medium"
              type="submit"
              onClick={logout}
              to="/login"
              component={RouterLink}
              className={classes.logout}
            >
              <b>Logout</b>
            </Button>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default UserProfilePic;

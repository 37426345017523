import React, { useContext, useState } from 'react';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { Paragraph } from '../../../../core-components/atoms/Text';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import { checkPermission } from '../../../../utils/auth';
import editIcon from '../../../../assets/icons/editIconDark.svg';
import { FileUploadMain } from './FileUploadMain';
import { SharedContext } from '../../../../utils/common';
import { TableCell, TableRow } from '@mui/material';
import { ORGANIZATION_TYPES } from '../../../../constants';

const ProductListComponent = ({
  data,
  searchValue,
  onClick,
  getProducts,
  currentUser,
  editColumns
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { organizationType } = useContext(SharedContext);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const removeCategory = async () => {
    try {
      await API.delete(`products/${data.id}`);
      toaster('success', `Product deleted successfully.`);

      getProducts();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_product_id`,
        `$ already exists it must be unique`
      );
    }
  };

  const { settings } = useContext(SharedContext);

  return (
    <TableRow
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      className="items-center justify-between bg-white cursor-pointer px-4 py-4 hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out h-[48px]"
    >
      <TableCell className="rounded-none py-4 px-6 ">
        {data?.file ? (
          <img
            src={data?.file?.location}
            className={'w-[28px] rounded'}
            alt={'product-img'}
          />
        ) : (
          <FileUploadMain data={data} getProducts={getProducts} />
        )}
      </TableCell>
      <TableCell className="rounded-none py-4 px-6 ">
        <div className={'flex items-center gap-2'}>
          <div
            className={`h-[6px] w-[6px] rounded-full ${
              data.isActive
                ? 'border-[#1AAE2E] bg-[#1AAE2E]'
                : 'border border-solid border-[#9DA3B3]'
            }`}
          />
          <Paragraph
            className={` ${searchValue && 'bg-[#FFF0C3]'}`}
            variant={'sm'}
          >
            {data.description ? data.description : '-'}
          </Paragraph>
        </div>
        <Paragraph
          className={` ${searchValue && 'bg-[#FFF0C3]'}`}
          variant={'xs'}
        >
          Item Code: {data.name ? data.name : '-'}
        </Paragraph>
      </TableCell>
      <TableCell className="rounded-none py-4 px-6">
        <div className={'flex items-center'}>
          <Paragraph
            className={` ${searchValue && 'bg-[#FFF0C3]'} truncate`}
            variant={'sm'}
          >
            {data.Brand?.name}
          </Paragraph>
        </div>
        <Paragraph
          className={` ${searchValue && 'bg-[#FFF0C3]'} truncate`}
          variant={'xs'}
        >
          {data.Category?.name}
        </Paragraph>
      </TableCell>
      <TableCell className="rounded-none py-4 px-6">
        <div className={'flex items-center'}>
          <Paragraph
            className={` ${searchValue && 'bg-[#FFF0C3]'}`}
            variant={'sm'}
          >
            {data.UOM.name}
          </Paragraph>
        </div>
        <Paragraph className={`text-dark500 truncate`} variant={'xs'}>
          {data?.ProductUOMs?.length > 0
            ? data?.ProductUOMs?.slice(0, 2)
                ?.map((product) => product.UOM?.name)
                ?.join(', ')
            : '-'}
          {data?.ProductUOMs.length > 2 ? (
            <div className="text-[10px] font-medium text-dark800">
              +{data?.ProductUOMs.length - 2} More
            </div>
          ) : (
            ''
          )}
        </Paragraph>
      </TableCell>
      <TableCell className="rounded-none py-4 px-6">
        {editColumns.find((val) => val.name === 'Weight & Volume')?.status && (
          <>
            <div className={'flex items-center'}>
              <Paragraph
                className={` ${searchValue && 'bg-[#FFF0C3]'}`}
                variant={'sm'}
              >
                {data?.weight
                  ? `${data?.weight} ${settings?.weight?.symbol || 'Kg'}(s)`
                  : '-'}
              </Paragraph>
            </div>
            <Paragraph className={`text-dark500`} variant={'xs'}>
              {data?.dimensionsCBM ? `${data?.dimensionsCBM} cm³` : '-'}
            </Paragraph>
          </>
        )}
      </TableCell>
      <TableCell className="rounded-none py-4 px-6">
        {editColumns.find((val) => val.name === 'MoQ(s)')?.status && (
          <>
            <div className={'flex items-center'}>
              <Paragraph
                className={` ${searchValue && 'bg-[#FFF0C3]'}`}
                variant={'sm'}
              >
                Min: {data?.minOrderedQuantity || '-'}
              </Paragraph>
            </div>
            <Paragraph className={`text-dark500`} variant={'xs'}>
              Max: {data?.maxOrderedQuantity || '-'}
            </Paragraph>
          </>
        )}
      </TableCell>
      <TableCell className="rounded-none py-4 px-6">
        {organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
          editColumns.find((val) => val.name === 'Cost & Selling')?.status && (
            <>
              {' '}
              <div className={'flex items-center'}>
                <Paragraph
                  className={` ${searchValue && 'bg-[#FFF0C3]'}`}
                  variant={'sm'}
                >
                  Cost Price: {data?.costPrice || '-'}
                </Paragraph>
              </div>
              <Paragraph className={`text-dark500`} variant={'xs'}>
                Selling Price: {data?.sellingPrice || '-'}
              </Paragraph>
            </>
          )}
      </TableCell>

      <TableCell className="rounded-none py-4 px-6">
        <div className={'flex justify-end'}>
          {isHovering && (
            <button onClick={onClick}>
              <img src={editIcon} alt="edit-icon" className={'h-6 w-6'} />
            </button>
          )}
          {isHovering && checkPermission(currentUser, 'OPS_PRODUCT_DELETE') && (
            <button
              onClick={async (event) => {
                event.stopPropagation();
                await removeCategory();
              }}
            >
              <img src={deleteIcon} alt="delete-icon" />
            </button>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ProductListComponent;

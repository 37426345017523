import React, { useContext, useEffect, useState } from 'react';
import { Paper, Tooltip } from '@material-ui/core';
import '../../../assets/css/style.css';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { styled } from '@mui/system';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import LazyLoad from 'react-lazyload';
import DockManagement from './DockManagement';
import AislesView from './AislesView';
import SectionManagement from './SectionManagement';
import BaysView from './BaysView';
import WarehouseCompanyData from './WarehouseCompanyData';
import { useParams } from 'react-router';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#646f81',
  500: '#2b3a53',
  600: '#2b3a53',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75'
};

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 120px;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  width: 400px;
  margin-top: 30px;
`;

const TabStyled = styled(TabsUnstyled)`
  width: 95%;
  margin: auto;
`;

export default function WarehouseHallView() {
  const [tabSelected, setTabSelected] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);

  useEffect(() => {
    if (!selectedWarehouse) {
      setCurrentPageTitle('Warehouse');
    } else {
      setCurrentPageTitle(selectedWarehouse.name);
    }
  }, [currentPageTitle, setCurrentPageTitle, selectedWarehouse]);

  const { uid } = useParams();

  useEffect(() => {
    getWarehouses();
  }, [uid]);

  const getWarehouses = () => {
    API.get(`warehouses/${uid}`).then((res) => {
      if (res.data) {
        setSelectedWarehouse(res.data);
      }
    });
  };

  return (
    <Paper>
      <WarehouseCompanyData selectedWarehouse={selectedWarehouse} />
      <TabStyled id="tabs" defaultValue={tabSelected}>
        <TabsList id="tab-list" className="aislesTab">
          <Tooltip title="Aisles">
            <Tab id="tab-option-docks" onClick={() => setTabSelected(0)}>
              Aisles
            </Tab>
          </Tooltip>
          <Tooltip title="bays">
            <Tab id="tab-option-docks" onClick={() => setTabSelected(1)}>
              Bays
            </Tab>
          </Tooltip>
          <Tooltip title="Sections">
            <Tab id="tab-option-sections" onClick={() => setTabSelected(2)}>
              Sections
            </Tab>
          </Tooltip>
          <Tooltip title="Docks">
            <Tab id="tab-option-docks" onClick={() => setTabSelected(3)}>
              Docks
            </Tab>
          </Tooltip>
        </TabsList>
        <LazyLoad id="dock-lazyload">
          <TabPanel id="dock-tabpanel" value={0}>
            <AislesView warehouseId={uid} />
          </TabPanel>
        </LazyLoad>
        <LazyLoad id="dock-lazyload">
          <TabPanel id="dock-tabpanel" value={1}>
            <BaysView warehouseId={uid} />
          </TabPanel>
        </LazyLoad>
        <LazyLoad id="section-lazyload">
          <TabPanel id="section-tabpanel" value={2}>
            <SectionManagement selectedWarehouse={selectedWarehouse} />
          </TabPanel>
        </LazyLoad>
        <LazyLoad id="dock-lazyload">
          <TabPanel id="dock-tabpanel" value={3}>
            <DockManagement warehouseId={uid} />
          </TabPanel>
        </LazyLoad>
      </TabStyled>
    </Paper>
  );
}

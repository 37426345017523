import React, { useState } from 'react';
import { FaShippingFast } from 'react-icons/fa';
import PartiallyCompleteIcon from '../assets/icons/partial_complete.svg';
import { icons } from '../views/inventoryRevamped/components/statusIcons';
import { statuses } from '../constants/orderStatuses';
import InboundIcon from '../assets/icons/inboundNew.svg';
import OutboundIcon from '../assets/icons/outboundNew.svg';
import TransferIcon from '../assets/icons/transferNew.svg';
import LogisticsIcon from '../assets/icons/shipment.svg';
import ReturnIcon from '../assets/icons/returnOrder.svg';
import { useNavigate } from 'react-router';
import useFeatureFlags from '../hooks/useFeatureFlags';
import FLAGS from '../constants/featureFlags';
import { DialogueConfirmationOpen } from './DialogueConfirmation';
import { InboundTMSShipmentTrackingMobile } from '../views/revampedOrder/components/tracking/InboundTMSShipmentTrackingMobile';
import { OutboundTMSShipmentTrackingMobile } from '../views/revampedOrder/components/tracking/OutboundTMSShipmentTrackingMobile';
import { TransferTMSShipmentTrackingMobile } from '../views/revampedOrder/components/tracking/TransferTMSShipmentTrackingMobile';
import { LogisticTMSShipmentTrackingMobile } from '../views/revampedOrder/components/tracking/LogisticTMSShipmentTrackingMobile';
import Drawer from './Drawer';
import { ExpandedRowMobile2 } from '../views/revampedOrder/OrderTable';
import OptionsMenu from '../views/revampedOrder/Options';
import { ORGANIZATION_TYPES } from '../constants';
import { decimalDisplay, getCurrencySymbol } from '../utils/common';
import { capitalizeWords } from '../views/revampedOrder/AddOrder';

const OrderCard = ({ order, organization, closeOrder, ...restProps }) => {
  const [expanded, setExpanded] = useState(false);
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const shipmentContent = () => (
    <>
      {expanded &&
        order.Shipments?.length > 0 &&
        (isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
          order.moveType == 'OUTBOUND' ? (
            <OutboundTMSShipmentTrackingMobile
              order={order}
              expanded={expanded}
              setActiveShipment={restProps.setActiveShipment}
              setActiveShipmentOrder={restProps.setActiveShipmentOrder}
              shipmentIdFilter={restProps.shipmentIdFilter}
              activeShipment={restProps.activeShipment}
              setCancelPopUp={restProps.setCancelPopUp}
              refetch={restProps.refetch}
              // setAssignVehiclePopup={restProps.setAssignVehiclePopup}
              // setActiveShipmentPopup={restProps.setActiveShipmentPopup}
            />
          ) : order.moveType == 'INWARD' ? (
            <InboundTMSShipmentTrackingMobile
              expanded={expanded}
              order={order}
              setActiveShipment={restProps.setActiveShipment}
              setActiveShipmentOrder={restProps.setActiveShipmentOrder}
              shipmentIdFilter={restProps.shipmentIdFilter}
              activeShipment={restProps.activeShipment}
              setCancelPopUp={restProps.setCancelPopUp}
              refetch={restProps.refetch}
            />
          ) : order.moveType == 'TRANSFER' ? (
            <TransferTMSShipmentTrackingMobile
              expanded={expanded}
              order={order}
              setActiveShipment={restProps.setActiveShipment}
              setActiveShipmentOrder={restProps.setActiveShipmentOrder}
              shipmentIdFilter={restProps.shipmentIdFilter}
              activeShipment={restProps.activeShipment}
              setCancelPopUp={restProps.setCancelPopUp}
              refetch={restProps.refetch}
            />
          ) : (
            <LogisticTMSShipmentTrackingMobile
              expanded={expanded}
              order={order}
              setActiveShipment={restProps.setActiveShipment}
              setActiveShipmentOrder={restProps.setActiveShipmentOrder}
              shipmentIdFilter={restProps.shipmentIdFilter}
              activeShipment={restProps.activeShipment}
              setCancelPopUp={restProps.setCancelPopUp}
              refetch={restProps.refetch}
            />
          )
        ) : (
          <ExpandedRowMobile2
            order={order}
            expanded={expanded}
            setActiveShipment={restProps.setActiveShipment}
            setActiveShipmentOrder={restProps.setActiveShipmentOrder}
            shipmentIdFilter={restProps.shipmentIdFilter}
            activeShipment={restProps.activeShipment}
            setCancelPopUp={restProps.setCancelPopUp}
            refetch={restProps.refetch}
            setView={restProps.setView}
            setActiveShipmentPopup={restProps.setActiveShipmentPopup}
            setAssignVehiclePopup={restProps.setAssignVehiclePopup}
          />
        ))}
    </>
  );
  const toggleDrawer = () => {
    setExpanded((prev) => !prev);
  };

  const totalCost = order?.OrderProductGroups?.reduce(
    (acc, curr) =>
      acc +
      (curr.originalOrderedQuantity || 0) *
        (curr.sellingPrice || curr.costPrice || 0),
    0
  );

  const orderOptions = [];
  if (
    order.status == 'IN_PROGRESS' &&
    (order.moveType !== 'OUTBOUND' || order.S) &&
    isFeatureEnabled(FLAGS.ORDER_CANCELLATION)
  ) {
    orderOptions.push({
      label: (
        <div className="flex gap-3">
          <span>Cancel Order</span>
        </div>
      ),
      onClick: () => {
        restProps.setCancelPopUp(true, 'order');
        restProps.setActiveShipmentOrder(order);
      }
    });
  }
  if (order.status == 'PENDING') {
    !order.OrdersTasks?.length &&
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <span>Edit Order</span>
          </div>
        ),
        onClick: () => {
          navigate(`/routing/edit-order/${order.customId}`);
        }
      });
    if (isFeatureEnabled(FLAGS.ORDER_CANCELLATION)) {
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <span>Cancel Order</span>
          </div>
        ),
        onClick: () => {
          restProps.setActiveShipmentOrder(order);
          restProps.setCancelPopUp(true, 'order');
        }
      });
    }
  } else if (
    order.status == 'PARTIALLY_COMPLETE' ||
    order.status == 'PUTAWAY_IN_PROGRESS'
  ) {
    const closeable = order.Shipments.reduce(
      (acc, curr) =>
        acc &&
        (curr.status.key == 'COMPLETED' || curr.status.key == 'CANCELLED'),
      true
    );

    closeable &&
      isFeatureEnabled(FLAGS.CLOSE_ORDER) &&
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <span>Close Order</span>
          </div>
        ),
        onClick: (e) => {
          e.stopPropagation();
          return DialogueConfirmationOpen(
            order,
            false,
            closeOrder,
            '',
            'Are you sure you want to Close this Order'
          );
        }
      });
  }

  const getStatusContent = () => {
    if (order.status === 'PARTIALLY_COMPLETE') {
      return (
        <div className="flex items-center space-x-2">
          <div className="flex items-center justify-center">
            <img
              src={PartiallyCompleteIcon}
              alt="Partially Complete"
              className="w-3 h-3"
            />
          </div>
          <span className="text-sm text-gray-600">
            {statuses[order.status]}
          </span>
        </div>
      );
    }

    return (
      <div className="flex items-center space-x-2">
        <div
          className={`w-3 h-3 rounded-full ${
            icons[order.status]
          } flex items-center justify-center`}
          style={{ width: '12px', height: '12px' }}
        ></div>
        <span className="text-sm text-gray-600">{statuses[order.status]}</span>
      </div>
    );
  };

  return (
    <div className="max-w-full p-4 m-3 bg-white shadow-md rounded-lg relative">
      <div
        onClick={() => {
          navigate(`/routing/view-order/${order.customId}`);
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            {order.moveType == 'INWARD' &&
            order.moveSubType === 'SALES_RETURN' ? (
              <img src={ReturnIcon} className="w-4 h-4" />
            ) : order.moveType == 'INWARD' ? (
              <img src={InboundIcon} className="w-4 h-4" />
            ) : order.moveType == 'OUTBOUND' ? (
              <img src={OutboundIcon} className="w-4 h-4" />
            ) : order.moveType == 'TRANSFER' ? (
              <img src={TransferIcon} className="w-4 h-4" />
            ) : (
              <img src={LogisticsIcon} className="w-4 h-4" />
            )}
            <h2 className="text-lg font-bold">{order.customId}</h2>
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex items-center">{getStatusContent()}</div>
            {orderOptions.length > 0 && (
              <div onClick={(e) => e.stopPropagation()}>
                <OptionsMenu
                  options={orderOptions}
                  // closeCallback={() => setOptionsClick(false)}
                  // enterCallBack={() => setOptionsClick(true)}
                />
              </div>
            )}
          </div>
        </div>

        <div className="mb-4">
          <p className="text-sm mt-2">
            <strong>Origin:</strong> {order?.pickupNode?.name}
          </p>
          <p className="text-sm mt-2">
            <strong>Destination:</strong> {order?.dropOffNode?.name}
          </p>
          <p className="text-sm mt-2">
            <strong>Order Type:</strong>{' '}
            {capitalizeWords(order?.moveSubType ?? order?.moveType)}
          </p>
          {organization?.type == ORGANIZATION_TYPES.MANUFACTURER && (
            <p className="text-sm mt-2">
              <strong>Total Cost: </strong>
              <span className="text-[10px] mr-1">
                {getCurrencySymbol(organization?.defaultCurrency)}
              </span>
              <span>{decimalDisplay(totalCost)}</span>
            </p>
          )}
        </div>
      </div>
      {(isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ||
        order.moveType !== 'LOGISTIC') &&
        order.Shipments?.length > 0 && (
          <button
            onClick={toggleDrawer}
            className="absolute bottom-4 right-4 text-blue-500 hover:text-blue-700"
          >
            <FaShippingFast />
          </button>
        )}

      <Drawer open={expanded} onClose={() => setExpanded(false)}>
        {shipmentContent()}
      </Drawer>
    </div>
  );
};

export default OrderCard;

import { Button, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DetailText from '../../atomicComponents/DetailText';
import DetailTitle from '../../atomicComponents/DetailTitle';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { dividerDateFormatForFilter, exportToCSV } from '../../utils/common';
import CycleCountTableView from './cycleCountTableView';
import PrintTask from './printTask';
import useStyles from './taskStyles';

const ViewCycleCountDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { uid } = useParams();

  const [selectedCycleCount, setSelectedCycleCount] = useState(null);

  useEffect(() => {
    if (uid) getCycleCountDetails();
  }, [uid]);

  const getCycleCountDetails = async () => {
    try {
      const cycleCountDetail = await API.get(`tasks/${uid}`);
      setSelectedCycleCount(cycleCountDetail.warehouseTask);
    } catch (err) {
      onError(err);
    }
  };

  const exportToExcel = async () => {
    try {
      const cycleCountDetail = await API.get(`tasks/${uid}/exports`);

      if (cycleCountDetail && cycleCountDetail.warehouseTask) {
        const data = cycleCountDetail.warehouseTask;

        const dataToExport = [];

        let totalCOC = 0;
        let totalPhysical = 0;

        data.WarehouseTaskProducts.forEach((warehouseProduct) => {
          warehouseProduct.WarehouseTaskProductDetails.map((detail) => {
            dataToExport.push({
              product: `${warehouseProduct?.Product?.name}`,
              description: `${warehouseProduct?.Product?.description}`,
              batchNumber: `${detail.InventoryDetail.batchNumber}`,
              cocQuantity: `${detail.cocQuantity}`,
              cycleCountQuantity: `${detail.physicalQuantity}`,
              difference: `${detail.physicalQuantity - detail.cocQuantity}`
            });
          });

          totalCOC += warehouseProduct?.totalCOCQty;
          totalPhysical += warehouseProduct?.totalPhysicalQty;
        });

        dataToExport.push({
          product: ' ',
          description: ' ',
          batchNumber: 'TOTAL',
          cocQuantity: `${totalCOC}`,
          cycleCountQuantity: `${totalPhysical}`,
          difference: `${totalPhysical - totalCOC}`
        });
        let title = `Cycle Count ${data.id} ${moment().format('DD-MM-yyyy')}`;

        const header = [
          'SKU CODE',
          'SKU DESCRIPTION',
          'BATCH NUMBER',
          'QUANTITY IN COC',
          'CYCLE COUNT QTY',
          'DIFFERENCE'
        ];

        exportToCSV(header, dataToExport, title);
      }
    } catch (err) {
      onError(err);
    }
  };

  return (
    <Grid
      container
      className={classes.parentContainer + ' ' + classes.parentWidth}
      spacing={3}
    >
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={12} sm={11}>
          <Typography variant="h3" className={classes.heading}>
            Task Parent ID: {selectedCycleCount?.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/routing/task-management')}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={11}>
          <Typography variant="h3" className={classes.heading}>
            Cycle Count Note:
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <DetailText text="Warehouse:" />
            <DetailTitle
              text={selectedCycleCount?.Warehouse?.businessWarehouseCode}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <DetailText text="Customer:" />
            <DetailTitle text={selectedCycleCount?.Company?.name} />
          </Grid>
          <Grid item xs={6} sm={2}>
            <DetailText text="Created By:" />
            <DetailTitle
              text={
                selectedCycleCount?.CreatedBy?.firstName +
                ' ' +
                selectedCycleCount?.CreatedBy?.lastName
              }
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <DetailText text="Assigned To:" />
            <DetailTitle
              text={
                selectedCycleCount?.AssignedTo?.firstName
                  ? selectedCycleCount?.AssignedTo?.firstName +
                    ' ' +
                    selectedCycleCount?.AssignedTo?.lastName
                  : '-'
              }
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <DetailText text="Cycle Count Date:" />
            <DetailTitle
              text={dividerDateFormatForFilter(
                selectedCycleCount?.scheduledDate
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} className="mt-5">
          <Grid item xs={6} sm={3}>
            <DetailText text="Cycle Count Estimated Qty Weight:" />
            <DetailTitle
              text={selectedCycleCount?.totalEstimatedWeight + ' Kgs'}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DetailText text="Cycle Count Actual Qty Weight:" />
            <DetailTitle
              text={selectedCycleCount?.totalActualWeight + ' Kgs'}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className="mt-10">
          <CycleCountTableView values={selectedCycleCount} />
        </Grid>

        <Grid container item xs={12} alignItems="center" spacing={1}>
          <Grid item xs={6}>
            {selectedCycleCount?.taskStatus == 'COMPLETED' ? (
              <div className={classes.exportButton}>
                <button className="exportCustomBtn" onClick={exportToExcel}>
                  Export
                </button>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <PrintTask data={selectedCycleCount} isIcon={false} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ViewCycleCountDetails;

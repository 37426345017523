import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useParams, useNavigate } from 'react-router';

import DetailTitle from '../../atomicComponents/DetailTitle';
import DetailText from '../../atomicComponents/DetailText';
import onexusStyles from './onexusMakeStyles';
import DetailPageTitle from '../../atomicComponents/DetailPageTitle';
import areaIcon from '../../assets/icons/areaIcon.svg';
import mapIcon from '../../assets/icons/mapIcon.svg';
import stackingIcon from '../../assets/icons/stackingIcon.svg';
import ventilationIcon from '../../assets/icons/ventilationIcon.svg';
import canopyIcon from '../../assets/icons/canopyIcon.svg';
import lightingIcon from '../../assets/icons/lightingIcon.svg';
import forkliftIcon from '../../assets/icons/forkliftIcon.svg';
import officeAreaIcon from '../../assets/icons/officeAreaIcon.svg';
import generatorIcon from '../../assets/icons/generatorIcon.svg';
import internetIcon from '../../assets/icons/internetIcon.svg';
import wiresIcon from '../../assets/icons/wiresIcon.svg';
import waterLeakageIcon from '../../assets/icons/waterLeakageIcon.svg';
import ceilingIcon from '../../assets/icons/ceilingIcon.svg';
import garbageIcon from '../../assets/icons/garbageIcon.svg';
import pestControlIcon from '../../assets/icons/pestControlIcon.svg';
import cleaningIcon from '../../assets/icons/cleaningIcon.svg';
import fireSafetyIcon from '../../assets/icons/fireSafetyIcon.svg';
import cctvIcon from '../../assets/icons/cctvIcon.svg';
import guardsIcon from '../../assets/icons/guardsIcon.svg';
import foodAuthorityIcon from '../../assets/icons/foodAuthorityIcon.svg';
import ISOIcon from '../../assets/icons/ISOIcon.svg';
import drapIcon from '../../assets/icons/drapIcon.svg';
import EPAIcon from '../../assets/icons/EPAIcon.svg';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import GoogleMap from '../../components/GoogleMap';
import { TRUTHY } from '../../utils/enums/truthy';
import { TYPE } from '../../utils/enums/typeOfProducts';
import { TEMPERATURE } from '../../utils/enums/temperatures';
import { PHASE } from '../../utils/enums/electricArrangements';
import AddWarehouseBooking from './warehouseBooking/AddWarehouseBooking';
import PlaceholderImg from '../../assets/icons/placeholderImg.svg';
import onexusDetailMarker from '../../assets/icons/onexusDetailMarker.svg';

// eslint-disable-next-line no-unused-vars
const OnexusDetailPage = ({ triggerBack }) => {
  const classes = onexusStyles();
  const navigate = useNavigate();
  const [typeOfProds, setTypeOfProds] = useState([]);
  const { uid } = useParams();
  const [warehouse, setWarehouse] = useState(null);
  const [totalDocks, setTotalDocks] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (uid) {
      getWarehouse();
    }
  }, [uid]);

  const getWarehouse = async () => {
    try {
      const res = await API.get(`warehouses/${uid}`);

      setWarehouse(res?.data);
      setTotalDocks(res?.totalDocks);
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    if (warehouse?.configurations?.typeOfProds) {
      const vals = Object.keys(warehouse.configurations.typeOfProds)?.filter(
        (key) => warehouse.configurations.typeOfProds[key] && key
      );
      setTypeOfProds(vals);
    }
  }, [warehouse]);

  return (
    <div className={classes.root}>
      <div className={classes.listing}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.imageContainer}>
              <img
                className={classes.imgPreview}
                src={warehouse?.files?.[0]?.location || PlaceholderImg}
              />
              <ArrowBackOutlinedIcon
                className={classes.backClick}
                onClick={() => navigate('/onexus/listing')}
              />
            </div>
          </Grid>
          <Grid item xs={10}>
            <DetailPageTitle text={warehouse?.name} />
            <div className={`${classes.codeAndActiveContainer} mt-3`}>
              <p className={classes.businessCodeText}>
                {warehouse?.businessWarehouseCode}
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <button
              className={classes.bookspaceBtn}
              onClick={() => setOpenModal(true)}
            >
              BOOK SPACE
            </button>
            {openModal ? (
              <AddWarehouseBooking
                open={openModal}
                handleClose={() => setOpenModal(false)}
                booking={{ Warehouse: warehouse }}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <DetailTitle text="Overview" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <p className={classes.detailSectionText}>Address Details</p>
              </Grid>
              <Grid item xs={3}>
                <img src={mapIcon} alt="map" />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <DetailText text="Address:" />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.smallDetailText}>
                      {warehouse?.address}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <DetailText text="Lat, Long:" />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.smallDetailText}>
                      {warehouse?.locationLatlng?.lat}
                      {', '}
                      {warehouse?.locationLatlng?.lng}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <DetailText text="City:" />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.smallDetailText}>
                      {warehouse?.City?.name || 'N/A'}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <hr className="lineDashed" />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.detailGrid}>
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <p className={classes.detailSectionText}>Total Capacity</p>
              </Grid>
              <Grid item xs={3}>
                <img src={areaIcon} alt="area" />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <DetailText text="Sq. Ft.:" />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.smallDetailText}>{`${
                      warehouse?.capacity
                        ? `${warehouse?.capacity} Sq. Ft.`
                        : 'N/A'
                    }`}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <DetailText text="Pallets:" />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.smallDetailText}>
                      {warehouse?.pallets || 'N/A'}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <hr className="lineSpaceWarehouse" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.detailSectionText}>Photos</p>
              </Grid>
              <Grid item xs={12}>
                <div className={`${classes.imgGalleryContainer} ov-x`}>
                  {warehouse?.files && warehouse?.files?.length ? (
                    <>
                      {warehouse?.files.map((file, index) => (
                        <img
                          key={index}
                          className={`${classes.imgGalleryItem} mr-3`}
                          src={file?.location || PlaceholderImg}
                          alt="warehouse-img"
                        />
                      ))}
                    </>
                  ) : (
                    <img
                      className={`${classes.imgGalleryItem} mr-3`}
                      src={PlaceholderImg}
                      alt="placeholder-img"
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <hr className="lineSpaceWarehouse" />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.detailGrid}>
              <Grid item xs={12}>
                <DetailTitle text="Warehousing Details" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.detailSectionText}>All info</p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Racking Availability:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>
                  {TRUTHY[warehouse?.configurations?.racking]}
                </p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Number of Docks:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>{totalDocks}</p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Elevated Docks:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>
                  {TRUTHY[warehouse?.configurations?.elevatedDocks]}
                </p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Temp:" />
              </Grid>
              <Grid item xs={6}>
                <ul>
                  {warehouse?.configurations?.temperature?.length ? (
                    warehouse?.configurations?.temperature.map(
                      (temperature, idx) => (
                        <li key={idx} className={classes.smallDetailText}>
                          <p className={classes.smallDetailText}>
                            {TEMPERATURE[temperature] || null}
                          </p>
                        </li>
                      )
                    )
                  ) : (
                    <p className={classes.smallDetailText}>{'N/A'}</p>
                  )}
                </ul>
                <p className={classes.smallDetailText}></p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Humidity:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>{`${
                  warehouse?.humidity || '0'
                }%`}</p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Type of Products Stored:" />
              </Grid>
              <Grid item xs={6}>
                {typeOfProds?.length ? (
                  typeOfProds.map((typeOfProd, idx) => (
                    <p key={idx} className={classes.smallDetailText}>
                      {TYPE[typeOfProd] || null}
                    </p>
                  ))
                ) : (
                  <p className={classes.smallDetailText}>N/A</p>
                )}
                <p className={classes.smallDetailText}></p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Structure:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>{`${
                  warehouse?.configurations?.structure || 'N/A'
                }`}</p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Electricity Arrangement:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>{`${
                  warehouse?.configurations?.electricPhase
                    ? PHASE[warehouse?.configurations?.electricPhase]
                    : `N/A`
                } `}</p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Transformer:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>{`${
                  warehouse?.configurations?.transformerVoltAmps
                    ? `${warehouse?.configurations?.transformerVoltAmps} KVA`
                    : `N/A`
                }`}</p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Height of Warehouse:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>{`${
                  warehouse?.height || '0'
                } Ft`}</p>
              </Grid>
              <Grid item xs={6}>
                <DetailText text="Office Space for Clients:" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.smallDetailText}>
                  {TRUTHY[warehouse?.configurations?.officeSpace]}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr className="lineSpaceWarehouse" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DetailTitle text="Further Details" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.detailSectionText}>Operations</p>
              </Grid>
              {warehouse?.configurations?.operations?.followHeight ? (
                <Grid item xs={3}>
                  <img src={stackingIcon} alt="stacking-height" />
                  <p className={classes.smallDetailText}>
                    Maximum Stacking Height
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.operations?.ventilation ? (
                <Grid item xs={3}>
                  <img src={ventilationIcon} alt="ventilation-icon" />
                  <p className={classes.smallDetailText}>
                    Ventilation Availability
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.operations?.canopy ? (
                <Grid item xs={3}>
                  <img src={canopyIcon} alt="ventilation-icon" />
                  <p className={classes.smallDetailText}>Canopy Available</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.operations?.adequateLighting ? (
                <Grid item xs={3}>
                  <img src={lightingIcon} alt="lighting-icon" />
                  <p className={classes.smallDetailText}>Adequate Lighting</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.operations?.forkliftAvail ? (
                <Grid item xs={3}>
                  <img src={forkliftIcon} alt="forklift-icon" />
                  <p className={classes.smallDetailText}>Forklift Available</p>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <hr className="lineDashed" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.detailSectionText}>
                  Building & Infrastructure
                </p>
              </Grid>
              {warehouse?.configurations?.buildingAndInfrastructure
                ?.officeAreaAvail ? (
                <Grid item xs={3}>
                  <img src={officeAreaIcon} alt="office-icon" />
                  <p className={classes.smallDetailText}>
                    Office Area is Available
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.buildingAndInfrastructure
                ?.powerBackup ? (
                <Grid item xs={3}>
                  <img src={generatorIcon} alt="generator-icon" />
                  <p className={classes.smallDetailText}>
                    Power Backup Available
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.buildingAndInfrastructure
                ?.internet ? (
                <Grid item xs={3}>
                  <img src={internetIcon} alt="internet-icon" />
                  <p className={classes.smallDetailText}>Internet Connection</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.buildingAndInfrastructure
                ?.concealedWiring ? (
                <Grid item xs={3}>
                  <img src={wiresIcon} alt="wires-icon" />
                  <p className={classes.smallDetailText}>
                    Concealer Electric Wires
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.buildingAndInfrastructure
                ?.noWaterLeakage ? (
                <Grid item xs={3}>
                  <img src={waterLeakageIcon} alt="water-leakage-icon" />
                  <p className={classes.smallDetailText}>No Water Leakages</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.buildingAndInfrastructure
                ?.noCeilingHolesAndWraps ? (
                <Grid item xs={3}>
                  <img src={ceilingIcon} alt="water-leakage-icon" />
                  <p className={classes.smallDetailText}>
                    Ceiling is Free Of Holes and Wraps
                  </p>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <hr className="lineDashed" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.detailSectionText}>House Keeping</p>
              </Grid>
              {warehouse?.configurations?.housekeeping?.garbageCollection ? (
                <Grid item xs={3}>
                  <img src={garbageIcon} alt="garbage-icon" />
                  <p className={classes.smallDetailText}>
                    Garbage Collection Availability
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.housekeeping?.pestControl ? (
                <Grid item xs={3}>
                  <img src={pestControlIcon} alt="pest-control-icon" />
                  <p className={classes.smallDetailText}>Pest Control</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.housekeeping?.dailyCleaning ? (
                <Grid item xs={3}>
                  <img src={cleaningIcon} alt="cleaning-icon" />
                  <p className={classes.smallDetailText}>Daily Cleaning</p>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <hr className="lineDashed" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.detailSectionText}>Safety & Security</p>
              </Grid>
              {warehouse?.configurations?.safety?.fireSafety ? (
                <Grid item xs={3}>
                  <img src={fireSafetyIcon} alt="fire-icon" />
                  <p className={classes.smallDetailText}>Fire & Safety</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.safety?.cctv ? (
                <Grid item xs={3}>
                  <img src={cctvIcon} alt="cctv-icon" />
                  <p className={classes.smallDetailText}>
                    CCTV Camera Installed
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.safety?.guards ? (
                <Grid item xs={3}>
                  <img src={guardsIcon} alt="cctv-icon" />
                  <p className={classes.smallDetailText}>Guards</p>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <hr className="lineDashed" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.detailSectionText}>
                  Licenses and Certifications
                </p>
              </Grid>
              {warehouse?.configurations?.licAndCert?.fa ? (
                <Grid item xs={3}>
                  <img src={foodAuthorityIcon} alt="FA-icon" />
                  <p className={classes.smallDetailText}>
                    Food Authority License (FA)
                  </p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.licAndCert?.iso ? (
                <Grid item xs={3}>
                  <img src={ISOIcon} alt="ISO-icon" />
                  <p className={classes.smallDetailText}>ISO Certified</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.licAndCert?.drap ? (
                <Grid item xs={3}>
                  <img src={drapIcon} alt="DRAP-icon" />
                  <p className={classes.smallDetailText}>DRAP Certified</p>
                </Grid>
              ) : null}
              {warehouse?.configurations?.licAndCert?.epa ? (
                <Grid item xs={3}>
                  <img src={EPAIcon} alt="EPA-icon" />
                  <p className={classes.smallDetailText}>
                    Environmental Protection Authority (EPA)
                  </p>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.map}>
        {warehouse?.locationLatlng ? (
          <GoogleMap
            showSingleSearchField={false}
            editable={false}
            singleLocationLatlng={warehouse?.locationLatlng}
            icon={onexusDetailMarker}
            markerTitle={`Warehouse: ${warehouse?.name} \nAddress: ${warehouse?.address}`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default OnexusDetailPage;

import React, { useEffect, useContext, useState } from 'react';
import { SharedContext } from '../../../utils/common';
import '../../../assets/css/style.css';
import API from '../../../libs/axios';
import { useParams } from 'react-router';

const WarehouseCompanyData = () => {
  const { uid } = useParams();
  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  useEffect(() => {
    getWarehouses();
  }, [uid]);

  const getWarehouses = () => {
    API.get(`warehouses/${uid}`).then((res) => {
      if (res.data) {
        setSelectedWarehouse(res.data);
      }
    });
  };

  useEffect(() => {
    if (!selectedWarehouse) {
      setCurrentPageTitle('Warehouse');
    } else {
      setCurrentPageTitle(selectedWarehouse.name);
    }
  }, [currentPageTitle, setCurrentPageTitle, selectedWarehouse]);

  return (
    <>
      {selectedWarehouse ? (
        <div className="companyDetails warehouseCompanyDetails">
          <div className="label">
            <b>Warehouse Name:</b>
            <b>Business Warehouse Code:</b>
            <b>Capacity (Square feet):</b>
            <b>Capacity (Pallets):</b>
            <b>City:</b>
            <b>Status:</b>
            <b>Address:</b>
          </div>
          <div className="renderData">
            <div>
              <b>{selectedWarehouse.name || '-'}</b>
            </div>

            <div>
              <b>{selectedWarehouse.businessWarehouseCode || '-'}</b>
            </div>

            <div>
              <b>{selectedWarehouse?.capacity || '-'}</b>
            </div>

            <div>
              <b>{selectedWarehouse?.pallets || '-'}</b>
            </div>

            <div>
              <b>{selectedWarehouse?.City?.name || '-'}</b>
            </div>

            <div>
              <b>{selectedWarehouse.isActive ? 'Active' : 'In-active'}</b>
            </div>
            <div>
              <b>{selectedWarehouse.address || '-'}</b>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WarehouseCompanyData;

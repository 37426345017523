/*eslint-disable*/
import clsx from 'clsx';
import PillButton from '../../../core-components/molecules/PillButton';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { FormControl } from '@material-ui/core';

const FiltersCTA = ({
  shipmentTypes,
  selectedNode,
  nodes,
  setSelectedShipmentType,
  setSelectedNode
}) => {
  const sxProps = {
    '& .MuiInputBase-root': {
      padding: '8px 16px !important',
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  const listBoxProps = {
    fontSize: 14,
    width: '150px'
  };

  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };
  return (
    <div className="flex flex-row justify-between ml-4 mt-4">
      <div className="flex">
        <div className="flex flex-row w-fit">
          {shipmentTypes.map((shipmentType, index) => {
            const { label, isActive } = shipmentType;

            return (
              <PillButton
                key={label}
                {...(!isActive ? { variant: 'transparent-text' } : {})}
                className={clsx('p-0 text-xs', { 'ml-1': index > 0 })}
                size="small"
                onClick={() => setSelectedShipmentType(label.replace(' ', '_'))}
                label={label}
              ></PillButton>
            );
          })}
        </div>
      </div>

      <div>
        <FormControl className="w-100 min-w-[150px]">
          <Autocomplete
            value={nodes.find((node) => node.value === selectedNode)}
            options={nodes}
            getOptionLabel={(nodes) => nodes?.label || ''}
            onChange={(event, newValue) => {
              setSelectedNode(newValue?.value || null);
            }}
            sxProps={sxProps}
            customInputProps={inputProps}
            listBoxProps={listBoxProps}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default FiltersCTA;

import Button from '../../../core-components/atoms/Button';
import IconButton from '../../../core-components/atoms/IconButton';

const WeekNavigationCTA = ({
  BackIcon,
  NextIcon,
  week,
  setCurrentWeek,
  updateWeek,
  getPreviousWeek,
  getNextWeek
}) => {
  return (
    <div className="flex flex-row">
      <Button variant="transparent-text" onClick={setCurrentWeek}>
        This Week
      </Button>

      <div className="ml-1">
        <IconButton onClick={() => updateWeek(getPreviousWeek, week[0])}>
          <img src={BackIcon} className="w-3 h-3" />
        </IconButton>

        <IconButton
          onClick={() => updateWeek(getNextWeek, week[week.length - 1])}
        >
          <img src={NextIcon} className="w-3 h-3" />
        </IconButton>
      </div>
    </div>
  );
};

export default WeekNavigationCTA;

// import { getURL } from "../utils/common";
import API from '../libs/axios';

export const upload = (images, folders) => {
  if (!(images instanceof Array)) {
    images = [images];
  }
  if (!(folders instanceof Array)) {
    folders = images.map(() => folders);
  }
  return Promise.all(
    images.map((image, i) => {
      let formData = new FormData();
      formData.append('image', image);
      return API.post(`uploads/${folders[i]}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          return response.file.id;
        })
        .catch((err) => {
          console.info(err);
        });
    })
  );
};

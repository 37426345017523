import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const LinearProgressBar = ({ value, sx }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={Math.round(value > 100 ? 100 : value)}
      color="secondary"
      className={`bg-white rounded-full ${!sx && 'w-full'}`}
      sx={sx || { border: '1px solid black' }}
    />
  );
};

export default LinearProgressBar;

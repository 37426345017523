import { colors } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';
import typography from './typography';
import { checkboxClasses } from '@mui/material';

const rootElement = document.getElementById('root');

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiModal: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#0C71D7',
          [`&.${checkboxClasses.checked}`]: {
            color: '#0C71D7'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #9DA3B3'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0B1940 !important'
          }
        }
      }
    }
  },

  palette: {
    primary: {
      main: '#0B1940',
      light: colors.common.white,
      dark: colors.common.black
    },
    secondary: {
      main: '#2b3a53',
      light: '#8b96a8'
    },
    success: {
      main: '#8bbb2a',
      light: '#8b96a8'
    },
    error: {
      main: '#de6868',
      light: '#8b96a8'
    }
  },
  props: {
    MuiButton: {
      // disableRipple: true,
    }
  },
  shadows: ['none'],
  typography
});

export default theme;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Pagination } from '@material-ui/lab';
import { debounce } from 'lodash';
import { MdOutlineAdjust } from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';
import classNames from 'classnames/bind';

import SearchBar from '../../../atomicComponents/SearchBar';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import { DEBOUNCE_CONST } from '../../../Config';
import darkEditIcon from '../../../assets/icons/darkEditIcon.svg';
import addIcon from '../../../assets/icons/addIcon.svg';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import warehouseStyles from './warehouseMakeStyles';
import { useNavigate } from 'react-router-dom';
import { checkPermission } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import AddAislesAndBaysView from './AddAislesAndBaysView';
import { useParams } from 'react-router';

const BaysView = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const classes = warehouseStyles();
  const [bays, setBays] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedBay, setSelectedBay] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const { currentUser } = useContext(SharedContext);
  const limit = 5;

  const columns = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      className: (value) => (value ? classes.active : ''),
      renderCell: (params) => (
        <MdOutlineAdjust
          className={params.row.isActive ? 'online' : 'offline'}
        />
      )
    },
    {
      field: 'bayName',
      headerName: 'Bay Name',
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <DetailTitle className="w-100 parentActions" text="Bay Name" />
      ),
      renderCell: (params) => (
        <span className="text-center">{params.row.name}</span>
      )
    },
    {
      field: 'action',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderHeader: () => <DetailTitle text="Action" />,
      renderCell: (params) => (
        <img
          className={
            checkPermission(currentUser, 'OPS_BAY_CREATE')
              ? classes.docksEditBtn
              : 'display-none'
          }
          src={darkEditIcon}
          alt="edit"
          onClick={() => {
            setSelectedBay(params.row);
            setOpen(true);
          }}
        />
      )
    }
  ];

  const bulkUploadClass = classNames({
    'mr-3': true,
    [classes.docksAddBtn]: true
  });

  const getWarehouseBays = async (page, searchValue, limit) => {
    if (!uid) {
      return;
    }

    try {
      const cols = ['name'];
      const res = await API.get(`warehouses/${uid}/bays`, {
        params: {
          page: page,
          limit: limit,
          search: searchValue,
          columns: cols
        }
      });
      setBays(res?.data);
      setPageCount(res?.pages);
    } catch (error) {
      onError(error);
    }
  };

  const getDelayedWarehouseBays = useCallback(
    debounce(
      async (page, searchValue, limit) => {
        getWarehouseBays(page, searchValue, limit);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedWarehouseBays(page, searchValue, limit);
  }, [page, searchValue]);

  const handleClose = () => {
    setOpen(false);
    setSelectedBay(null);
  };

  return (
    <div className={classes.docksParentContainer}>
      <div className={open || 'display-none'}>
        <AddAislesAndBaysView
          type="Bay"
          route="bays"
          handleClose={handleClose}
          aisleOrBays={selectedBay}
          warehouseId={uid}
          open={open}
          getWarehouseAislesOrBays={getWarehouseBays}
        />
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <SearchBar
            setSearchValue={(e) => {
              setPage(1);
              setSearchValue(e);
            }}
            placeholder="Search dock name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <button
            className={
              checkPermission(currentUser, 'OPS_BAY_CREATE')
                ? classes.docksAddBtn
                : 'display-none'
            }
            onClick={() => setOpen(true)}
          >
            <img src={addIcon} alt="add-icon" />
            <p>ADD</p>
          </button>
          <button
            className={
              checkPermission(currentUser, 'OPS_BAY_BULK_UPLOAD')
                ? bulkUploadClass
                : 'display-none'
            }
            onClick={() =>
              navigate(`/administration/warehouse/bulk-create-bay/${uid}`)
            }
          >
            <p>BULK UPLOAD</p>
          </button>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.docksDataGridParent}>
            <DataGrid
              columns={columns}
              rows={bays}
              pageSize={limit}
              rowsPerPageOptions={[limit]}
            />
          </div>
        </Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            onChange={(e, page) => {
              setPage(page);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BaysView;

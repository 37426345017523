/*eslint-disable*/
import { FormControl, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TextField from '../../core-components/atoms/TextField';
import API from '../../libs/axios';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import { onError } from '../../libs/errorLib';

const AssignVehicle = ({ order, view, shipment, formik, vehicleView }) => {
  const [cars, setCars] = useState([]);

  const getCars = async () => {
    try {
      const vehicles = await API.get(`vehicles/types`);
      setCars(
        vehicles?.data
          ? vehicles?.data.filter(
              (vehicleType) => vehicleType.isActive === true
            )
          : []
      );
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  useEffect(() => {
    getCars();
  }, []);

  useEffect(() => {
    if (shipment) {
      formik.setValues({
        vehicleType: shipment.Car || {},
        vehicleTypeId: shipment.vehicleTypeId,
        vehicleNumber: shipment?.vehicleNumber || '',
        driverName: shipment?.driverName || '',
        driverPhone: shipment?.driverPhone || ''
      });
    }
  }, [shipment]);

  const dashedBorderClassName =
    'border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pt-3 pb-3 mb-3';

  return (
    <>
      <Grid container className={dashedBorderClassName} spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Autocomplete
              value={formik?.values?.vehicleType}
              options={cars}
              getOptionLabel={(car) => {
                return car && car.CarMake && car.CarModel
                  ? `${car?.CarMake?.name}-${car?.CarModel?.name} ( ${car?.Category?.name} )`
                  : '';
              }}
              onBlur={formik.handleBlur}
              onChange={(e, val) => {
                formik.setFieldValue('vehicleType', val !== null && val);
                formik.setFieldValue(
                  'vehicleTypeId',
                  val && val.id ? val.id : ''
                );
              }}
              placeholder="Vehicle Type"
              disabled={view}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} className="flex items-center">
          <FormControl fullWidth>
            <TextField
              value={formik.values.vehicleNumber}
              onChange={(e) =>
                formik.setFieldValue('vehicleNumber', e.target.value)
              }
              placeholder="Vehicle No"
              size="small"
              disabled={view}
              maxLength={10}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} className="flex items-center">
          <FormControl fullWidth>
            <TextField
              value={formik.values.driverName}
              onChange={(e) =>
                formik.setFieldValue('driverName', e.target.value)
              }
              placeholder="Driver Name"
              size="small"
              disabled={view}
              maxLength={30}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} className="flex items-center number-input-container">
          <FormControl fullWidth>
            <TextField
              value={formik.values.driverPhone}
              onChange={(e) => {
                if (e.target.value < 10000000000000)
                  formik.setFieldValue('driverPhone', e.target.value);
              }}
              placeholder="Phone"
              size="small"
              disabled={view}
              type="number"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default AssignVehicle;

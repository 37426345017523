import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Pagination, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

import columns from './WarehouseListingColumns';
import warehouseStyles from '../warehouseMakeStyles';
import API from '../../../../libs/axios';
import { DEBOUNCE_CONST } from '../../../../Config';
import { onError } from '../../../../libs/errorLib';
import darkEditIcon from '../../../../assets/icons/darkEditIcon.svg';
import eye from '../../../../assets/icons/eye.svg';
import DetailTitle from '../../../../atomicComponents/DetailTitle';
import SearchBar from '../../../../atomicComponents/SearchBar';
import addIcon from '../../../../assets/icons/addIcon.svg';
import { SharedContext } from '../../../../utils/common';
import { MdOutlineNorth, MdOutlineSouth } from 'react-icons/md';
import clsx from 'clsx';

const WarehouseListing = () => {
  const classes = warehouseStyles();
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortable, setSortable] = useState('');
  const [pageCount, setPagesCount] = useState(0);
  const [muiLoader, setMuiLoader] = useState(false);
  const recordLimit = 10;

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);

  useEffect(() => {
    let title = 'Warehouse';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  const warehouseColumns = [
    {
      field: 'warehouseName',
      headerName: 'Warehouse Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          Warehouse Name
          <button
            type="button"
            onClick={() => {
              sortBy('name');
            }}
            className={
              sortable.col == 'name' && sortable.sorted == 1
                ? 'customHeaderIcon'
                : 'customHeaderIcon display-sortable'
            }
          >
            {sortable.col == 'name' && sortable.sorted == 0 ? (
              <MdOutlineNorth />
            ) : (
              <MdOutlineSouth />
            )}
          </button>
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.name}`}>
          <p
            className="warehouseColumnName"
            onClick={() =>
              navigate(`/administration/warehouse/${params?.row?.id}`)
            }
          >
            {params.row?.name}
          </p>
        </Tooltip>
      )
    },
    {
      field: 'businessWarehouseCode',
      headerName: 'Business Warehouse Code',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          Business Warehouse Code
          <button
            type="button"
            onClick={() => {
              sortBy('businessWarehouseCode');
            }}
            className={
              sortable.col == 'businessWarehouseCode' && sortable.sorted == 1
                ? 'customHeaderIcon'
                : 'customHeaderIcon display-sortable'
            }
          >
            {sortable.col == 'businessWarehouseCode' && sortable.sorted == 0 ? (
              <MdOutlineNorth />
            ) : (
              <MdOutlineSouth />
            )}
          </button>
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.businessWarehouseCode}`}>
          <span>{params.row?.businessWarehouseCode}</span>
        </Tooltip>
      )
    },
    {
      field: 'pallets',
      headerName: 'Warehouse Capacity(Pallets)',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          Warehouse Capacity(Pallets)
          <button
            type="button"
            onClick={() => {
              sortBy('pallets');
            }}
            className={clsx('customHeaderIcon', {
              'display-sortable':
                sortable.col !== 'pallets' || sortable.sorted === 1
            })}
          >
            {sortable.col == 'pallets' && sortable.sorted == 0 ? (
              <MdOutlineNorth />
            ) : (
              <MdOutlineSouth />
            )}
          </button>
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.pallets}`}>
          <span>{params.row?.pallets}</span>
        </Tooltip>
      )
    },
    {
      field: 'capacity',
      headerName: 'Warehouse Capacity(Sq. Ft)',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          Warehouse Capacity(Sq. Ft)
          <button
            type="button"
            onClick={() => {
              sortBy('capacity');
            }}
            className={clsx('customHeaderIcon', {
              'display-sortable':
                sortable.col !== 'capacity' || sortable.sorted === 1
            })}
          >
            {sortable.col == 'capacity' && sortable.sorted == 0 ? (
              <MdOutlineNorth />
            ) : (
              <MdOutlineSouth />
            )}
          </button>
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.capacity}`}>
          <span>{params.row?.capacity}</span>
        </Tooltip>
      )
    },
    {
      field: 'managerName',
      headerName: 'Warehouse Manager',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          Warehouse Manager
          <button
            type="button"
            onClick={() => {
              sortBy('managerName');
            }}
            className={clsx('customHeaderIcon', {
              'display-sortable':
                sortable.col !== 'managerName' || sortable.sorted === 1
            })}
          >
            {sortable.col == 'managerName' && sortable.sorted == 0 ? (
              <MdOutlineNorth />
            ) : (
              <MdOutlineSouth />
            )}
          </button>
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.managerName}`}>
          <span>{params.row?.managerName}</span>
        </Tooltip>
      )
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          Address
          <button
            type="button"
            onClick={() => {
              sortBy('address');
            }}
            className={
              sortable.col == 'address' && sortable.sorted == 1
                ? 'customHeaderIcon'
                : 'customHeaderIcon display-sortable'
            }
          >
            {sortable.col == 'address' && sortable.sorted == 0 ? (
              <MdOutlineNorth />
            ) : (
              <MdOutlineSouth />
            )}
          </button>
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.address}`}>
          <span>{params.row?.address}</span>
        </Tooltip>
      )
    },
    ...columns,
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <DetailTitle text="Actions" />,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="view">
              <img
                className={classes.pointer}
                src={eye}
                alt="view-icon"
                onClick={() =>
                  navigate(`/administration/warehouse/${params.row.id}`)
                }
              />
            </Tooltip>
            <Tooltip title="edit">
              <img
                className={`${classes.pointer} ml-3`}
                src={darkEditIcon}
                alt="edit-icon"
                onClick={() => navigate(`edit/${params?.row?.id}`)}
              />
            </Tooltip>
          </>
        );
      }
    }
  ];

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const getWarehouses = async (page, searchValue, filters, columns) => {
    try {
      setMuiLoader(true);
      const res = await API.get(`warehouses`, {
        params: {
          page: page,
          search: searchValue,
          limit: recordLimit,
          filters,
          columns
        }
      });
      setWarehouses(res?.data);
      setPagesCount(res?.pages);
    } catch (error) {
      onError(error);
    } finally {
      setMuiLoader(false);
    }
  };

  const getDelayedWarehouses = useCallback(
    debounce(
      (page, searchValue, sortable) => {
        let filters = {
          sortable
        };
        let columns = ['name', '$City.name$'];
        getWarehouses(page, searchValue, filters, columns);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedWarehouses(page, searchValue, sortable);
  }, [page, searchValue, sortable]);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={classes.warehouseSearchField}
        >
          <SearchBar
            setSearchValue={(e) => {
              setPage(1);
              setSearchValue(e);
            }}
            placeholder="Search warehouse here"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <button
            className={classes.warehouseAddBtn}
            onClick={() => navigate('create')}
          >
            <img src={addIcon} alt="add-icon" />
            <p>ADD WAREHOUSE</p>
          </button>
        </Grid>
        <Grid item xs={12}>
          <div className={`responsive-table ${classes.listGridContainer}`}>
            <DataGrid
              rows={warehouses}
              columns={warehouseColumns}
              sx={{
                '.MuiDataGrid-columnSeparator': {
                  display: 'none'
                },
                '&.MuiDataGrid-root': {
                  border: 'none'
                }
              }}
              loading={muiLoader}
            />
          </div>
        </Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            onChange={(e, page) => {
              setPage(page);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default WarehouseListing;

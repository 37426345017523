import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { withGoogleMap, withScriptjs } from 'react-google-maps';

import '../../assets/css/style.css';
import RouteMaps from '../../components/RouteMaps.js';
import LoaderOverlay from '../../layouts/DashboardLayout/LoaderOverlay';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { GOOGLE_MAP_API_KEY } from '../../constants/index.js';

const useStyles = makeStyles(() => ({
  routeTableHead: {
    backgroundColor: '#FAFAFC',
    '& th:first-child': {
      borderRadius: '1em 0 0 0'
    },
    '& th:last-child': {
      borderRadius: '0 1em 0 0'
    }
  },
  routeDetailTable: {
    background: '#FFFFFF',
    boxShadow: '4px 4px 16px rgb(75 137 200 / 12%)',
    borderRadius: '16px'
  }
}));

const RoutesMap = (props) => {
  const classes = useStyles();
  const WrappedMap = withScriptjs(withGoogleMap(RouteMaps));
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState([]);

  const getRoute = async () => {
    try {
      setLoading(true);
      let res = await API.get(
        `routes/company/${props?.companyId}/route/${props?.routeId}`
      );
      let orders = [];
      res?.data?.RouteOrderGroups?.map((order) => {
        orders.push(order?.Order);
      });
      setRoutes(orders);
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  const map = useMemo(
    () => (
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${GOOGLE_MAP_API_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: '80vh' }} />}
        mapElement={<div style={{ height: `100%` }} />}
        wayPoints={routes}
      />
    ),
    [routes]
  );

  useEffect(() => {
    if (props?.companyId && props?.routeId) {
      getRoute();
    }
  }, [props?.routeId]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      className="dialogParent routeDetailView"
      aria-labelledby="form-dialog-title"
    >
      {loading ? <LoaderOverlay type="detailPage" /> : null}
      <Grid className="parentRouteMaps mt-5 mb-5">
        <DialogContent>
          <Grid item container className={classes.detailTitleSec}>
            <Grid item xs={12}>
              <div className="mapSec">{map}</div>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default RoutesMap;

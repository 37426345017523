export const ActiveIcon = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8334 1.6665V3.33317C14.4917 3.78317 17.0834 7.10817 16.6334 10.7665C16.25 13.7998 13.8667 16.1915 10.8334 16.5665V18.2332C15.4167 17.7332 18.7084 13.6165 18.2084 9.0415C17.775 5.15817 14.7167 2.08317 10.8334 1.6665ZM9.16671 1.6665C7.53337 1.8165 5.99171 2.45817 4.72504 3.49984L5.91671 4.78317C6.85004 4.03317 7.97504 3.54984 9.16671 3.38317V1.6665ZM3.55004 4.72484C2.50004 5.9915 1.86671 7.53317 1.70837 9.1665H3.37504C3.53337 7.98317 4.00004 6.85817 4.74171 5.9165L3.55004 4.72484ZM1.71671 10.8332C1.88337 12.4665 2.52504 14.0082 3.55837 15.2748L4.74171 14.0832C4.00837 13.1415 3.53337 12.0165 3.38337 10.8332H1.71671ZM5.88337 15.3082L4.72504 16.4498C5.98337 17.4998 7.53337 18.1582 9.16671 18.3332V16.6665C7.98337 16.5165 6.85837 16.0415 5.91671 15.3082H5.88337Z"
        fill={fill || '#0C71D7'}
      />
    </svg>
  );
};

export const CompletedIcon = ({ fill }) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75003 12.3152L0.0925293 7.65766L2.21503 5.53516L4.75003 8.07766L12.16 0.660156L14.2825 2.78266L4.75003 12.3152Z"
        fill={fill || '#0B1940'}
      />
    </svg>
  );
};

export const CancelledIcon = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83337 0.691895V2.37523C13.4917 2.82523 16.0834 6.15023 15.6334 9.80856C15.25 12.8419 12.8667 15.2502 9.83337 15.6086V17.2752C14.4167 16.8169 17.75 12.7502 17.2917 8.16689C16.9167 4.20856 13.775 1.08356 9.83337 0.691895ZM8.16671 0.716895C6.54171 0.875228 4.99171 1.50023 3.72504 2.55023L4.91671 3.78356C5.85004 3.03356 6.97504 2.55023 8.16671 2.38356V0.716895ZM2.55004 3.72523C1.50004 4.99189 0.875041 6.53356 0.708374 8.16689H2.37504C2.53337 6.98356 3.00004 5.85856 3.74171 4.91689L2.55004 3.72523ZM0.716707 9.83356C0.883374 11.4669 1.52504 13.0086 2.55837 14.2752L3.74171 13.0836C3.00837 12.1419 2.53337 11.0169 2.38337 9.83356H0.716707ZM4.91671 14.3086L3.72504 15.4502C4.98337 16.5002 6.53337 17.1586 8.16671 17.3336V15.6669C6.98337 15.5169 5.85837 15.0419 4.91671 14.3086ZM11.1584 5.66689L9.00004 7.82523L6.84171 5.66689L5.66671 6.8419L7.82504 9.00023L5.66671 11.1586L6.84171 12.3336L9.00004 10.1752L11.1584 12.3336L12.3334 11.1586L10.175 9.00023L12.3334 6.8419L11.1584 5.66689Z"
        fill={fill || '#0B1940'}
      />
    </svg>
  );
};

export const DraftIcon = ({ fill }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 8.10833V6.33333H14.6667V6.58333C14.4833 6.68333 14.3083 6.8 14.1583 6.95L13 8.10833ZM5.91667 7.16667C5.68333 7.16667 5.5 7.35 5.5 7.58333V8.83333H10.5V7.58333C10.5 7.35 10.3167 7.16667 10.0833 7.16667H5.91667ZM3 6.33333H1.33333V15.5H7.16667V13.9417L7.275 13.8333H3V6.33333ZM15.5 5.5H0.5V0.5H15.5V5.5ZM13.8333 2.16667H2.16667V3.83333H13.8333V2.16667ZM8.83333 14.6333V16.3333H10.5333L15.6417 11.2333L13.9417 9.525L8.83333 14.6333ZM17.0417 9.225L15.9417 8.125C15.775 7.95833 15.5 7.95833 15.3417 8.125L14.525 8.94167L16.225 10.6417L17.0417 9.825C17.2083 9.66667 17.2083 9.39167 17.0417 9.225Z"
        fill={fill || '#0B1940'}
      />
    </svg>
  );
};

export const ClosedIcon = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83337 0.691895V0.708561V2.37523C13.4917 2.82523 16.0834 6.15023 15.6334 9.80856C15.25 12.8419 12.8667 15.2502 9.83337 15.6086V17.2752C14.4167 16.8169 17.75 12.7502 17.2917 8.16689C16.9167 4.20856 13.775 1.08356 9.83337 0.691895ZM8.16671 0.716895C6.54171 0.875228 4.99171 1.50023 3.72504 2.55023L4.91671 3.78356C5.85004 3.03356 6.97504 2.55023 8.16671 2.38356V0.716895ZM2.55004 3.72523C1.50004 4.99189 0.875041 6.53356 0.708374 8.16689H2.37504C2.53337 6.98356 3.00004 5.85856 3.74171 4.91689L2.55004 3.72523ZM11.9167 6.08356L7.85004 10.1502L6.08337 8.38356L5.20004 9.2669L7.85004 11.9169L12.8 6.9669L11.9167 6.08356ZM0.716707 9.83356C0.883374 11.4669 1.52504 13.0086 2.55837 14.2752L3.74171 13.0836C3.00837 12.1419 2.53337 11.0169 2.38337 9.83356H0.716707ZM4.91671 14.3086L3.72504 15.4502C4.98337 16.5002 6.53337 17.1586 8.16671 17.3336V15.6669C6.98337 15.5169 5.85837 15.0419 4.91671 14.3086Z"
        fill={fill || '#0B1940'}
      />
    </svg>
  );
};

export const OrderDetail = (fill) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00008 8.83333C2.15841 8.83333 0.666748 10.325 0.666748 12.1667C0.666748 14.0083 2.15841 15.5 4.00008 15.5C5.84175 15.5 7.33341 14.0083 7.33341 12.1667C7.33341 10.325 5.84175 8.83333 4.00008 8.83333ZM4.00008 13.8333C3.08341 13.8333 2.33341 13.0833 2.33341 12.1667C2.33341 11.25 3.08341 10.5 4.00008 10.5C4.91675 10.5 5.66675 11.25 5.66675 12.1667C5.66675 13.0833 4.91675 13.8333 4.00008 13.8333ZM4.00008 0.5C2.15841 0.5 0.666748 1.99167 0.666748 3.83333C0.666748 5.675 2.15841 7.16667 4.00008 7.16667C5.84175 7.16667 7.33341 5.675 7.33341 3.83333C7.33341 1.99167 5.84175 0.5 4.00008 0.5ZM9.00008 2.16667H17.3334V3.83333H9.00008V2.16667ZM9.00008 13.8333V12.1667H17.3334V13.8333H9.00008ZM9.00008 7.16667H17.3334V8.83333H9.00008V7.16667Z"
        fill={fill || '#0B1940'}
      />
    </svg>
  );
};

export const ActivityLogs = (fill) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 4.66667H10V8.83333L13.5667 10.95L14.1667 9.94167L11.25 8.20833V4.66667ZM10.8334 0.5C8.84425 0.5 6.9366 1.29018 5.53007 2.6967C4.12355 4.10322 3.33337 6.01088 3.33337 8H0.833374L4.13337 11.3583L7.50004 8H5.00004C5.00004 6.4529 5.61462 4.96917 6.70858 3.87521C7.80255 2.78125 9.28628 2.16667 10.8334 2.16667C12.3805 2.16667 13.8642 2.78125 14.9582 3.87521C16.0521 4.96917 16.6667 6.4529 16.6667 8C16.6667 9.5471 16.0521 11.0308 14.9582 12.1248C13.8642 13.2188 12.3805 13.8333 10.8334 13.8333C9.22504 13.8333 7.76671 13.175 6.71671 12.1167L5.53337 13.3C6.89171 14.6667 8.75004 15.5 10.8334 15.5C12.8225 15.5 14.7302 14.7098 16.1367 13.3033C17.5432 11.8968 18.3334 9.98912 18.3334 8C18.3334 6.01088 17.5432 4.10322 16.1367 2.6967C14.7302 1.29018 12.8225 0.5 10.8334 0.5Z"
        fill={fill || '#0B1940'}
      />
    </svg>
  );
};

export const ShipmentTab = (fill) => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5527 1H1.73438V10.4545H12.5527V1Z"
        stroke={fill || '#0B1940'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5547 4.63281H15.4396L17.6032 6.81463V10.451H12.5547V4.63281Z"
        stroke={fill || '#0B1940'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97492 14.0895C5.97072 14.0895 6.77797 13.2755 6.77797 12.2713C6.77797 11.2672 5.97072 10.4531 4.97492 10.4531C3.97913 10.4531 3.17188 11.2672 3.17188 12.2713C3.17188 13.2755 3.97913 14.0895 4.97492 14.0895Z"
        stroke={fill || '#0B1940'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3577 14.0895C15.3535 14.0895 16.1608 13.2755 16.1608 12.2713C16.1608 11.2672 15.3535 10.4531 14.3577 10.4531C13.3619 10.4531 12.5547 11.2672 12.5547 12.2713C12.5547 13.2755 13.3619 14.0895 14.3577 14.0895Z"
        stroke={fill || '#0B1940'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ViewIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0.500977H2.16667C1.72464 0.500977 1.30072 0.676571 0.988155 0.989132C0.675595 1.30169 0.5 1.72562 0.5 2.16764V13.8343C0.5 14.2763 0.675595 14.7003 0.988155 15.0128C1.30072 15.3254 1.72464 15.501 2.16667 15.501H13.8333C14.2754 15.501 14.6993 15.3254 15.0118 15.0128C15.3244 14.7003 15.5 14.2763 15.5 13.8343V5.50098L10.5 0.500977ZM13.8333 13.8343H2.16667V2.16764H9.66667V6.33431H13.8333M12.1667 9.66764H3.83333V8.00098H12.1667M9.66667 12.1676H3.83333V10.501H9.66667"
        fill="#0B1940"
      />
    </svg>
  );
};

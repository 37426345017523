/*eslint-disable*/
import * as Yup from 'yup';

export const nodeInitialValues = {
  name: '',
  nodeType: {},
  nodeClass: 'EXTERNAL',
  adhocFlag: true,
  isActive: true,
  description: '',
  pocName: '',
  pocContact: '',
  latLng: {
    lat: '',
    lng: ''
  },
  code: '',
  capacity: null,
  pallets: null,
  companyId: null,
  cityId: null,
  entity: {},
  IMS: true,
  WMS: true,
  savedNodes: []
};

export const nodeSchema = Yup.object().shape({
  isEntity: Yup.boolean(),
  name: Yup.string().required('Name is Required'),
  nodeType: Yup.object().shape({
    value: Yup.string().required('Node type is Required')
  }),
  // address: Yup.string().required('Address is Required'),
  // entity: Yup.object().when('isEntity', {
  //   is: true,
  //   then: Yup.object().shape({
  //     value: Yup.string().required('Entity is Required')
  //   })
  // }),
  // otherwise: Yup.object().shape({
  //   value: Yup.string()
  // }),
  // nodeClass: Yup.string(),
  capacity: Yup.number().when('nodeClass', {
    is: 'INTERNAL',
    then: Yup.number().nullable().min(32, 'Minimum capacity should be 32 sq ft')
  }),
  pallets: Yup.number().when('nodeClass', {
    is: 'INTERNAL',
    then: Yup.number().min(2, 'Minimum pallets should be 2').nullable()
  })
});

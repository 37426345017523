import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  timer: {
    color: theme.palette.primary.main
  }
}));

function Timer(props) {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(props.seconds || 0);
  const [minutes, setMinutes] = useState(props.minutes || 0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(interval);
        props.onTimerFinish(); // Call the onTimerFinish prop when the timer finishes counting down
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  useEffect(() => {
    if (props.isReset) {
      setMinutes(props.minutes || 0);
      setSeconds(props.seconds || 0);
      props.onReset();
    }
  }, [props.isReset, props.minutes, props.seconds, props.onReset]);

  return (
    <div className={classes.timer}>
      {minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </div>
  );
}

export default Timer;

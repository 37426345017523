/*eslint-disable*/
import React, { useContext } from 'react';
import { SharedContext } from '../../utils/common';
import { ORGANIZATION_TYPES } from '../../constants';
import { Grid, makeStyles } from '@material-ui/core';
import PrintStyles from './PrintStyles';
import moment from 'moment/moment';
import FLAGS from '../../constants/featureFlags';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const BasicDetail = ({ order, orderType, tms, shipment }) => {
  const { organizationType, settings } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();
  const useStyles = makeStyles((theme) => ({
    basicDetailsContainer: {
      [theme.breakpoints.down('xs')]: {
        '& > .MuiGrid-item': {
          flexBasis: '20%',
          maxWidth: '20%'
        }
      }
    }
  }));

  const classes = { ...PrintStyles(), ...useStyles() };

  const infoFields = [
    {
      id: 'Company',
      label:
        organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
        orderType === 'grn'
          ? 'SUPPLIER'
          : 'COMPANY',
      format: (value) => (value ? value?.name : '-')
    },
    {
      id: 'referenceId',
      label: 'REFERENCE ID',
      format: (value) => (value ? value : '-')
    },
    {
      id: 'pickupDate',
      label: 'ORDER CREATED',
      format: (value) =>
        value
          ? moment(value)
              .tz(settings?.timezone?.tzCode)
              .format(`${settings?.dateFormat} hh:mm A`)
          : '-'
    },
    {
      id: 'Carrier',
      label: 'CARRIER NAME',
      format: (value) => (value?.name ? value?.name : value || '-')
    },
    {
      id: 'vehicleType',
      label: 'VEHICLE TYPE',
      format: (value) =>
        value?.CarModel?.name ? value?.CarModel?.name : value || '-'
    },
    {
      id: 'Vehicle',
      label: 'VEHICLE #',
      format: (value) =>
        value?.registrationNumber ? value?.registrationNumber : value || '-'
    },
    {
      id: 'Driver',
      label: 'DRIVER NAME',
      format: (value) => (value?.name ? value?.name : value || '-')
    },

    {
      id: 'driverPhone',
      label: 'Driver #',
      format: (value) => (value ? value : '-')
    }
  ];

  if (isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) {
    infoFields.push(
      {
        id: 'WarehouseDock',
        label: 'DOCK',
        format: (value) => (value ? value?.name : '-')
      },
      {
        id: 'sealNumber',
        label: 'SEAL NUMBER',
        format: (value) => (value ? value : '-')
      },
      {
        id: 'user',
        label: 'CREATED BY',
        format: (value) =>
          value ? `${value?.firstName} ${value?.lastName}` : '-'
      },
      {
        id: 'activityDate',
        label: `${orderType?.toUpperCase()} CREATION DATE & TIME`,
        format: (value) =>
          value
            ? `${moment(value)
                .tz(settings?.timezone?.tzCode)
                .format(`${settings?.dateFormat} hh:mm A`)}`
            : '-'
      }
    );
  } else {
    infoFields.push(
      {
        id: 'sealNumber',
        label: 'SEAL NUMBER',
        format: (value) => (value ? value : '-')
      },
      {
        id: 'user',
        label: 'CREATED BY',
        format: (value) =>
          value ? `${value?.firstName} ${value?.lastName}` : '-'
      },
      {
        id: 'activityDate',
        label: `${orderType?.toUpperCase()} CREATION DATE & TIME`,
        format: (value) =>
          value
            ? `${moment(value)
                .tz(settings?.timezone?.tzCode)
                .format(`${settings?.dateFormat} hh:mm A`)}`
            : '-'
      }
    );
  }

  return (
    <Grid
      container
      style={{ maxWidth: '100%', marginLeft: '-10px', marginTop: '20px' }}
      spacing={1}
      className={classes.basicDetailsContainer}
    >
      {infoFields.map(({ id, label, format }) => (
        <Grid item md={2} xs={2} key={id} space={1} className="px-1">
          <Grid item xs={12} className={classes.label}>
            {`${label}:`}
          </Grid>

          {tms ? (
            <Grid item xs={12} className={classes.basicFieldValue}>
              {id == 'Driver'
                ? format(order?.ShipmentLegs?.[0]?.Driver?.name)
                : id == 'driverPhone'
                ? format(order?.ShipmentLegs?.[0]?.Driver?.phone)
                : id == 'Vehicle'
                ? format(order?.ShipmentLegs?.[0]?.Vehicle)
                : id == 'vehicleType'
                ? format(order?.ShipmentLegs?.[0]?.Car?.CarModel?.name)
                : id == 'Carrier'
                ? format(order?.ShipmentLegs?.[0]?.Company)
                : format(order?.[id])}
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.basicFieldValue}>
              {id == 'Driver'
                ? order?.[id]?.name
                  ? format(order?.[id])
                  : format(shipment?.driverName)
                : id == 'Vehicle'
                ? order?.[id]?.registrationNumber
                  ? format(order?.[id])
                  : format(shipment?.vehicleNumber)
                : id == 'vehicleType'
                ? order?.[id]?.name
                  ? format(order?.[id])
                  : format(shipment?.vehicleTypeName)
                : id == 'Carrier'
                ? order?.[id]?.name
                  ? format(order?.[id])
                  : format(shipment?.carrierName)
                : format
                ? format(order?.[id])
                : '-'}
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default BasicDetail;

import React, { useState, useContext, useEffect } from 'react';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';

import EllipsesIcon from '../../../assets/icons/ellipsesIcon.svg';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import warehouseBookingStyles from './onboardingStyles';
import { SharedContext } from '../../../utils/common';
import onboardingEnums from '../../../utils/enums/onboardingEnums';

const CustomerOnboardingActionMenu = ({ onboardingRequest, getRequests }) => {
  const { id, status } = onboardingRequest;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const open = Boolean(anchorEl);
  const classes = warehouseBookingStyles();
  const { setAPILoader } = useContext(SharedContext);

  useEffect(() => {
    setMenuItems(
      Object.keys(onboardingEnums.CUSTOMER_ONBOARDING_STATUS).filter(
        (value) => value !== status
      )
    );
  }, [onboardingRequest]);

  const updateRequest = async (status) => {
    try {
      setAPILoader(true);
      await API.put(`customer-onboardings/${id}`, {
        status
      });
      setAnchorEl(null);
      getRequests();
    } catch (error) {
      onError(error);
    }
  };
  return (
    <>
      <img
        id="basic-button"
        style={{ marginLeft: '5px', cursor: 'pointer' }}
        src={EllipsesIcon}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      {menuItems?.length ? (
        <Menu
          elevation={0}
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={() => setAnchorEl(null)}
        >
          {menuItems?.length
            ? menuItems?.map((menuItem) => {
                const menuOption =
                  onboardingEnums.CUSTOMER_ONBOARDING_STATUS[menuItem];
                return (
                  <Tooltip key={menuItem} title={menuOption}>
                    <MenuItem
                      className={classes.menuItems}
                      onClick={() => {
                        setAnchorEl(null);
                        updateRequest(menuItem);
                      }}
                    >
                      {`Move To ${menuOption}`}
                    </MenuItem>
                  </Tooltip>
                );
              })
            : null}
        </Menu>
      ) : null}
    </>
  );
};

export default CustomerOnboardingActionMenu;

/*eslint-disable*/
import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import moment from 'moment';

import FileIcon from '../../../assets/icons/fileIcon.svg';
import { SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { formatPrice } from '../invoices/InvoiceForm';
import ExcelIcon from '../../../assets/icons/excel.svg';
import ImagePreview from '../../../atomicComponents/ImagePreview';

export default function PaymentListing({
  payments,
  invoiceNumber,
  billing,
  invoice
}) {
  const [supportingDocumentView, setSupportDocumentView] = useState(false);
  const [invoiceSupportingDocView, setInvioceSupportingDocumentView] =
    useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const { setAPILoader } = useContext(SharedContext);
  const [activeImage, setActiveImage] = useState(null);
  const useStyles = makeStyles((theme) => ({
    table: {
      borderRadius: '10px',
      border: '1px solid #dcdcdc',
      width: 'auto',
      margin: '1rem 0'
    },
    title: {
      fontWeight: 'bold',
      fontSize: '1.3rem',
      marginBottom: '1.5rem'
    },
    img: {
      borderRadius: '10px',
      border: '1px solid transparent',
      '&:hover': {
        border: '2px solid #0C71D7'
      },
      height: '80px'
    },
    imageContainer: {
      position: 'relative',
      display: 'inline-block',
      '&:hover div': {
        opacity: 1
      },
      cursor: 'pointer'
    },

    iconOverlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: 'opacity 0.3s'
    },
    cancelBtn: {
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
      color: '#0C71D7',
      '&:hover': {}
    }
  }));

  const onPreview = async (payment) => {
    if (payment.supportingDocument) {
      try {
        setAPILoader(true);
        const res = await API.post(`invoices/bulk-preview`, {
          fileIds: payment.supportingDocument
        });
        setPreviewImages(res.files);
        setSupportDocumentView(true);
      } catch (err) {
        onError(err);
      } finally {
        setAPILoader(false);
      }
    }
  };

  const getInvoiceDocs = async () => {
    if (invoice.supportingDocument) {
      try {
        setAPILoader(true);
        const res = await API.post(`invoices/bulk-preview`, {
          fileIds: invoice.supportingDocument
        });
        setPreviewImages(res.files);
        setSupportDocumentView(true);
      } catch (err) {
        onError(err);
      } finally {
        setAPILoader(false);
      }
    }
  };

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <div className={classes.title}>
        {supportingDocumentView ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ChevronLeftIcon
                onClick={() => {
                  setSupportDocumentView(false);
                  setInvioceSupportingDocumentView(false);
                }}
                style={{ cursor: 'pointer' }}
                fontSize="large"
              />
              Supporting Documents
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
            <div>{`Payment Details of "${invoiceNumber}" `}</div>
            {invoice?.supportingDocument?.length && (
              <div
                className="position-relative cursor-pointer"
                onClick={() => {
                  getInvoiceDocs();
                }}
              >
                <img
                  src={FileIcon}
                  style={{ cursor: 'pointer' }}
                  className={
                    invoice?.supportingDocument?.length
                      ? 'podUploaded'
                      : 'podNotUploaded'
                  }
                />
                <p className="podSize">{invoice?.supportingDocument?.length}</p>
              </div>
            )}
          </div>
        )}
      </div>
      {!supportingDocumentView ? (
        <div className={classes.table}>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell className="first-cell">
                  <DetailTitle text="Payment Date" />
                </TableCell>
                <TableCell className="table-cell">
                  <DetailTitle text="Payment Amount" />
                </TableCell>
                <TableCell className="table-cell">
                  <DetailTitle
                    text={billing ? 'Paid Amount' : 'Received Amount'}
                  />
                </TableCell>
                <TableCell className="table-cell">
                  <DetailTitle text="Outstanding Amount" />
                </TableCell>
                <TableCell className="last-cell">
                  <DetailTitle text="Supporting Documents" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.length > 0 &&
                payments
                  .sort((a, b) => a.id - b.id)
                  .map((payment) => (
                    <TableRow style={{ backgroundColor: '#fff' }}>
                      <TableCell>
                        {moment(payment.date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {formatPrice(Number(payment.payableAmount))}
                      </TableCell>
                      <TableCell>
                        {formatPrice(Number(payment.amount))}
                      </TableCell>
                      <TableCell>
                        {formatPrice(Number(payment.outstandingAmount))}
                      </TableCell>
                      <TableCell>
                        <div
                          className="position-relative cursor-pointer"
                          onClick={() => {
                            onPreview(payment);
                          }}
                        >
                          <img
                            src={FileIcon}
                            style={{ cursor: 'pointer' }}
                            className={
                              payment?.supportingDocument?.length
                                ? 'podUploaded'
                                : 'podNotUploaded'
                            }
                          />
                          <p className="podSize">
                            {payment?.supportingDocument?.length}
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <>
          <div>
            <Grid container style={{ width: '600px' }} spacing={5}>
              {previewImages.map((img, id) => (
                <Grid item xs={3} className={classes.imageContainer}>
                  <ImagePreview file={img} />
                </Grid>
              ))}
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="text"
                className={classes.cancelBtn}
                onClick={() => {
                  setSupportDocumentView(false);
                  setActiveImage(null);
                }}
              >
                Cancel
              </Button>
            </div>

            <Dialog
              open={!!activeImage}
              onClose={() => {
                setActiveImage(null);
              }}
            >
              <DialogContent style={{ padding: 0 }}>
                <img
                  src={activeImage?.location}
                  alt="Preview"
                  style={{ width: '100%' }}
                />
              </DialogContent>
            </Dialog>
          </div>
        </>
      )}
    </Grid>
  );
}

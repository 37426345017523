/*eslint-disable*/
import React, { useCallback, useEffect, useState, useRef } from 'react';
import API from '../../libs/axios';
import TextField from '../../core-components/atoms/TextField';
import {
  Checkbox,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  Popper
} from '@mui/material';
import { debounce } from 'lodash';
import { onError } from '../../libs/errorLib';
import { DEBOUNCE_CONST } from '../../Config';
import DetailIcon from '../../assets/icons/detail.svg';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Close, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const OrderSearchBar = ({
  setOrderIdFilter,
  setReferenceIdFilter,
  setCompanyNameFilter,
  setCurrentTab,
  tabValue,
  companyFiterName,
  orderFilterId,
  referenceFilterId,
  shipmentIdFilter,
  setShipmentIdFilter,
  params
}) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [referencedOrders, setReferencedOrders] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [selection, setSelection] = useState(false);
  const [companyOrders, setCompanyOrders] = useState({});
  const [showAll, setShowAll] = useState(false);

  const anchorRef = useRef(null);

  const useStyles = makeStyles(() => ({
    popper: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      }
    },
    paper: {
      pointerEvents: 'auto',
      width: '300px',
      border: '1px solid #E7E6E6',
      marginTop: '2px',
      maxHeight: '220px',
      boxShadow: '0px 8px 12px 0px #0000001F',
      overflowY: 'auto',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& input': {
        fontSize: '12px !important'
      }
    },
    heading: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#67718C',
      padding: '16px 8px',
      marginLeft: '12px'
    },
    option: {
      display: 'flex',
      gap: '16px',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer'
    }
  }));

  const handleClose = (e) => {
    if (
      document
        .getElementsByClassName('container-search-order')?.[0]
        ?.contains(e.target)
    ) {
      e.preventDefault();
    } else {
      setOpen(false);
      if (
        orderFilterId ||
        shipmentIdFilter ||
        referenceFilterId ||
        companyFiterName
      )
        setSelection(true);
    }
  };

  const getOrders = async (params) => {
    try {
      let res = await API.get('orders', {
        params,
        columns: []
      });
      setOrders(res.data);
    } catch (err) {
      onError(err);
    }
  };

  const getReferencedOrders = async (params) => {
    try {
      let res = await API.get('orders', {
        params,
        columns: []
      });
      setReferencedOrders(res.data);
    } catch (err) {
      onError(err);
    }
  };

  const getShipments = async (params) => {
    try {
      let res = await API.get('orders/shipments', {
        params,
        columns: []
      });
      setShipments(res.data);
    } catch (err) {
      onError(err);
    }
  };

  const getOrderStats = async (search) => {
    try {
      let url = `orders/stats`;
      let res = await API.get(url, {
        params: { companyId: search, ...params }
      });
      setCompanyOrders(res?.data);
    } catch (err) {
      onError(err);
    }
  };

  const _getOrders = useCallback(
    debounce(async (search) => {
      if (search) {
        await getOrders({
          orderId: search,
          ...params,
          limit: 5
        });
      }
    }, DEBOUNCE_CONST),
    []
  );

  const _getReferencedOrders = useCallback(
    debounce(async (search) => {
      if (search) {
        await getReferencedOrders({
          referenceId: search,
          ...params,
          limit: 5
        });
      }
    }, DEBOUNCE_CONST),
    []
  );

  const _getShipments = useCallback(
    debounce(async (search) => {
      await getShipments({ shipmentId: search, ...params, limit: 5 });
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    if (search) {
      _getOrders(search);
      _getReferencedOrders(search);
      _getShipments(search);
      setOpen(true);
    }
  }, [search]);

  const classes = useStyles();

  const tab =
    tabValue == 0
      ? 'active'
      : tabValue == 1
      ? 'draft'
      : tabValue == 2
      ? 'completed'
      : tabValue == 3
      ? 'closed'
      : 'cancelled';

  return (
    <div className="w-[188px] h-8">
      {selection ? (
        <div
          className="flex gap-1 text-xs w-[188px] h-8 text-black border border-solid border-black py-1 px-4 items-center justify-between rounded"
          onClick={() => setSelection(false)}
        >
          <div className="flex items-center gap-3 overflow-hidden whitespace-nowrap overflow-ellipsis">
            <Search
              style={{ width: '20px', height: '20px', cursor: 'pointer' }}
            />
            {orderFilterId && shipmentIdFilter && referenceFilterId ? (
              <span>
                {'Search'} :{' '}
                {orderFilterId
                  ? orderFilterId
                  : referenceFilterId
                  ? referenceFilterId
                  : shipmentIdFilter.id}
              </span>
            ) : orderFilterId || shipmentIdFilter || referenceFilterId ? (
              <span>
                {shipmentIdFilter
                  ? 'Shipment'
                  : referenceFilterId
                  ? 'Reference'
                  : 'Order'}{' '}
                ID:{' '}
                {orderFilterId
                  ? orderFilterId
                  : referenceFilterId
                  ? referenceFilterId
                  : shipmentIdFilter.id}
              </span>
            ) : (
              <span>
                &quot;{companyFiterName}&quot; in {tab} orders
              </span>
            )}
          </div>
          <Close
            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
            onClick={() => {
              setOrderIdFilter('');
              setReferenceIdFilter('');
              setShipmentIdFilter('');
              setCompanyNameFilter('');
              setSearch('');
              setCompanyOrders([]);
            }}
          />
        </div>
      ) : (
        <FormControl fullWidth ref={anchorRef}>
          <TextField
            placeholder="Search table"
            variant="outlined"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setOrderIdFilter('');
              setReferenceIdFilter('');
              setShipmentIdFilter('');
              setCompanyNameFilter('');
            }}
            // size="medium"
            fullWidth
            inputProps={{
              onFocus: () => search && setOpen(true)
            }}
            sxProps={{
              '& .MuiInputBase-root': {
                padding: '4px 16px !important',
                fontSize: '12px',
                height: 32
              },
              '& input::placeholder': {
                fontSize: '12px'
              },
              '& input': {
                padding: '0px !important'
              },
              '& textarea::placeholder': {
                fontSize: '12px'
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '8px'
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      )}
      {search && !selection && open && (
        <Popper
          open={open}
          anchorEl={
            anchorRef.current ||
            document.getElementsByClassName('container-search-order')[0]
          }
          placement="bottom-start"
          disablePortal
          className={classes.popper}
          modifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={classes.paper}>
              <List>
                <p className={classes.heading}>SEARCH RESULTS</p>
                {true ? (
                  <>
                    {orders?.map((result) => (
                      <ListItem
                        key={result}
                        className={classes.option}
                        onClick={() => {
                          setOrderIdFilter(result.id);
                          setSearch(result.id);
                          setSelection(true);
                        }}
                      >
                        <img src={DetailIcon} />
                        <span className="text-sm">
                          Order ID: {result.customId}
                        </span>
                      </ListItem>
                    ))}
                    {referencedOrders?.map((result) => (
                      <ListItem
                        key={result}
                        className={classes.option}
                        onClick={() => {
                          setReferenceIdFilter(result.referenceId);
                          setSearch(result.referenceId);
                          setSelection(true);
                        }}
                      >
                        <img src={DetailIcon} />
                        <span className="text-sm">
                          Reference ID: {result.referenceId}
                        </span>
                      </ListItem>
                    ))}
                    {shipments?.map((result) => (
                      <ListItem
                        key={result}
                        className={classes.option}
                        onClick={() => {
                          setShipmentIdFilter(result);
                          setSearch(result.id);
                          setSelection(true);
                        }}
                      >
                        <img src={DetailIcon} />
                        <span className="text-sm">
                          Shipment ID: {result.customId}
                        </span>
                      </ListItem>
                    ))}
                    {orders?.length > 0 ||
                      referencedOrders?.length > 0 ||
                      (shipments?.length > 0 && (
                        <ListItem className={classes.option}>
                          <Checkbox
                            checked={showAll}
                            onChange={(e, checked) => {
                              if (checked) {
                                setOrderIdFilter(search);
                                setReferenceIdFilter(search);
                                setShipmentIdFilter(search);
                                setSelection(true);
                              } else {
                                setOrderIdFilter(null);
                                setReferenceIdFilter(null);
                                setShipmentIdFilter(null);
                                setSelection(false);
                              }
                            }}
                          />
                          <span>Show All</span>
                        </ListItem>
                      ))}
                  </>
                ) : (
                  !!companyOrders &&
                  Object.keys(companyOrders).length > 0 && (
                    <>
                      {companyOrders[5].value > 0 && (
                        <ListItem
                          key={1}
                          className={classes.option}
                          onClick={() => {
                            setCompanyNameFilter(search);
                            setSelection(true);
                            setCurrentTab(0);
                          }}
                        >
                          <img src={DetailIcon} />
                          <span className="text-sm">
                            {companyOrders[5].value} Active orders
                          </span>
                        </ListItem>
                      )}

                      {companyOrders[4].value > 0 && (
                        <ListItem
                          key={2}
                          className={classes.option}
                          onClick={() => {
                            setCompanyNameFilter(search);
                            setSelection(true);
                            setCurrentTab(1);
                          }}
                        >
                          <img src={DetailIcon} />
                          <span className="text-sm">
                            {companyOrders[4].value} Draft Orders
                          </span>
                        </ListItem>
                      )}
                      {companyOrders[1].value > 0 && (
                        <ListItem
                          key={3}
                          className={classes.option}
                          onClick={() => {
                            setCompanyNameFilter(search);
                            setSelection(true);
                            setCurrentTab(2);
                          }}
                        >
                          <img src={DetailIcon} />
                          <span className="text-sm">
                            {companyOrders[1].value} Completed Orders
                          </span>
                        </ListItem>
                      )}
                      {companyOrders[2].value > 0 && (
                        <ListItem
                          key={4}
                          className={classes.option}
                          onClick={() => {
                            setCompanyNameFilter(search);
                            setSelection(true);
                            setCurrentTab(3);
                          }}
                        >
                          <img src={DetailIcon} />
                          <span className="text-sm">
                            {companyOrders[2].value} Closed Orders
                          </span>
                        </ListItem>
                      )}
                      {companyOrders[3].value > 0 && (
                        <ListItem
                          key={5}
                          className={classes.option}
                          onClick={() => {
                            setCompanyNameFilter(search);
                            setSelection(true);
                            setCurrentTab(4);
                          }}
                        >
                          <img src={DetailIcon} />
                          <span className="text-sm">
                            {companyOrders[3].value} Cancelled orders
                          </span>
                        </ListItem>
                      )}
                    </>
                  )
                )}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

export default OrderSearchBar;

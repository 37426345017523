import OwareLogo from '../../assets/icons/O360LightLogo.svg';
import OrganizationSetupBackground from '../../assets/icons/organization-setup-bg.png';
import OrganizationSetupDetails from './details';
import OrganizationSetupForm from './form';

const OrganizationSetup = () => {
  return (
    <div
      className="min-h-screen w-full px-8 py-8 md:px-20 lg:px-40"
      style={{
        fontFamily: 'Sause Pro',
        backgroundImage: `url(${OrganizationSetupBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center'
      }}
    >
      <div className="flex justify-center md:justify-start">
        <img src={OwareLogo} alt="Oware Logo" className="h-12 w-auto" />
      </div>
      <div className="mt-20 flex flex-col md:flex-row justify-between items-center md:items-start">
        <OrganizationSetupDetails className="w-full md:w-1/2 mb-8 md:mb-0" />
        <OrganizationSetupForm className="w-full md:w-1/2" />
      </div>
    </div>
  );
};

export default OrganizationSetup;

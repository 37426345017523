import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const SectionHeading = ({ title, className }) => {
  const classNames = `section-heading mb-10 ${className}`;

  return (
    <Typography className={classNames} variant="h2">
      {title}
    </Typography>
  );
};

export default SectionHeading;

SectionHeading.propTypes = {
  title: PropTypes.string
};

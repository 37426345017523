import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import moment from 'moment';
import React from 'react';
import { constants } from '../../revampedOrder/constants';

export const OrderDetail = ({ orderDetail }) => {
  return (
    <div>
      <Grid className="box-border w-full border-[1px] mt-[8px] rounded-md border-solid border-dark300 mt-16">
        <Table aria-label="collapsible table" className="parent-DataTable">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ width: '15%', padding: '16px 6px 16px 10px' }}
                className={`w-[240px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Order ID
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '18%', padding: '16px 4px' }}
                className={`w-[240px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Origin & Destination
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '15%', padding: '16px 4px' }}
                className={`w-[200px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  DROP OF DATE
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '13%', padding: '16px 4px' }}
                className={`w-[150px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  SUPPORTING DOC ID
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '13%', padding: '16px 4px' }}
                className={`w-[150px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Status
                </Label>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetail?.order?.map((order) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow>
                  <TableCell
                    sx={{ width: '10%', padding: '16px 6px 16px 10px' }}
                  >
                    <Title className={'sm'}>{order?.customId}</Title>
                  </TableCell>
                  <TableCell sx={{ width: '18%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {order?.pickupNode?.name} <span>➜</span>{' '}
                      {order?.dropOffNode?.name}
                    </Paragraph>
                  </TableCell>
                  <TableCell sx={{ width: '15%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {moment(order.dropoffDate).format('DD/MM/YY')}
                    </Paragraph>
                  </TableCell>
                  <TableCell sx={{ width: '13%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {order?.referenceId || '-'}
                    </Paragraph>
                  </TableCell>
                  <TableCell sx={{ width: '20%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {constants.ORDER_STATUS[order?.status]}
                    </Paragraph>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </div>
  );
};

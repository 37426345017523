import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import classNames from 'classnames/bind';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

const UsersTable = (props) => {
  const { resUsers, classes, deleteAction } = props;

  var tableClasses = classNames({
    [classes.parentContainer]: true,
    customTableClass: true
  });

  const dialogueOpen = (email) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteAction(email)
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  };

  return (
    <TableContainer className={tableClasses}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow className={classes.shadedTableHeader}>
            <TableCell className={classes.tableHeadText}>Email</TableCell>
            <TableCell className={classes.tableHeadText}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resUsers.map((user, idx) => {
            return (
              <TableRow hover role="checkbox" key={idx}>
                <TableCell>{user.userEmail}</TableCell>
                <TableCell>
                  <Tooltip title="Delete Email">
                    <DeleteIcon
                      color="error"
                      key="delete"
                      onClick={() => {
                        dialogueOpen(user.userEmail);
                      }}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;

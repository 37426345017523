/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';

import API from '../../libs/axios';
import { SharedContext } from '../../utils/common';
import Switch from '../../core-components/atoms/Switch';
import DeleteIcon from '../../assets/icons/deleteIcon.svg';
import EditIcon from '../../assets/icons/edit-row.svg';
import ViewIcon from '../../assets/icons/viewIcon.svg';
import UploadIcon from '../../assets/icons/bulk-upload.svg';
import DownloadIcon from '../../assets/icons/downloadFileIcon.svg';
import AddIcon from '../../assets/icons/addCard.svg';
import Popup from '../../core-components/atoms/Popup';
import { nodeSchema } from './validationSchemas/nodeSchema';
import NodeForm from './AddNodeForm';
import { nodeTypes } from './constants';
import Button from '../../core-components/atoms/Button';
import { toaster } from '../../utils/toaster';
import CustomTextField from '../../core-components/atoms/TextField';
import { Close, Search } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import CreateNode from './AddEntities/CreateNode';
import PopupButton from '../../core-components/molecules/PopupButton';
import BulkUpload from './NodeBulkUpload';
import { ref } from 'yup';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import { useLocation, useNavigate } from 'react-router';
import CustomPagination from '../invoiceandbilling/invoiceManagement/CustomPagination';
import { Paragraph, Title } from '../../core-components/atoms/Text';
import NodeBulkUploadTemplate from "../../assets/files/NodesBulkUploadTemplate.xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const NodeCard = ({
  node,
  refetch,
  users,
  userSearch,
  setUserSearch,
  deleteNode
}) => {
  const [edit, setEdit] = useState(false);
  const [nodeSearch, setNodeSearch] = useState('');
  const [view, setView] = useState(false);

  const { setAPILoader, organization } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();

  const updateNode = async (id, payload) => {
    setAPILoader(true);
    try {
      await API.put(`nodes/${id}`, payload);
      await refetch();
      toaster('success', 'Node Updated Successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setAPILoader(false);
    }
  };

  const nodeFormik = useFormik({
    initialValues: {
      name: '',
      nodeType: {},
      nodeClass: 'EXTERNAL',
      adhocFlag: true,
      isActive: true,
      description: '',
      pocName: '',
      pocContact: '',
      latLng: {
        lat: '',
        lng: ''
      },
      code: '',
      capacity: 0,
      pallets: 0,
      companyId: null,
      cityId: null,
      entity: {},
      isEntity: false,
      IMS: true,
      WMS: true,
      savedNodes: []
    },
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const nodeTypeOptions = Object.keys(nodeTypes.EXTERNAL).map((key) => ({
    value: key,
    label: nodeTypes.EXTERNAL[key]
  }));

  const resetNodeValues = () => {
    nodeFormik.setValues({
      name: '',
      nodeType: {},
      nodeClass: 'EXTERNAL',
      adhocFlag: true,
      address: '',
      isActive: true,
      description: '',
      pocName: '',
      pocContact: '',
      latLng: {
        lat: '',
        lng: ''
      },
      code: '',
      capacity: '',
      pallets: '',
      companyId: null,
      cityId: null,
      entity: {},
      isEntity: false,
      IMS: true,
      WMS: true,
      savedNodes: []
    });
  };

  useEffect(() => {
    nodeFormik.setValues({
      name: node?.name,
      nodeClass: node?.nodeClass,
      code: node.code,
      nodeType: {
        value: node?.nodeType,
        label: nodeTypes.EXTERNAL[node?.nodeType]
      },
      address: node?.address,
      latLng: node?.locationLatLng,
      pocName: node?.pocName,
      pocContact: node?.pocContact,
      description: node?.description,
      isActive: node?.isActive,
      capacity: node.Warehouse?.capacity,
      pallets: node.Warehouse?.pallets,
      IMS: true,
      WMS: node.Warehouse?.WMS,
      manager: node.Warehouse?.Manager
    });
  }, [node]);

  return (
    <>
      <div className="flex bg-[#F0F7FF] px-4 py-4 group justify-between items-center mt-6">
        <div className="flex flex-1 gap-8 items-center">
          <div className={'flex gap-4 flex-1 items-center'}>
            <div
              className={clsx(
                `h-3 w-3 rounded-full border border-solid border-[#9DA3B3]`,
                node?.isActive && 'border-[#1AAE2E] bg-[#1AAE2E]'
              )}
            />
            <div>
              <Title variant={'sm'}>{node?.name}</Title>
              <Paragraph variant={'xs'} className="text-[#4B5369] mt-1">
                {node?.code}
              </Paragraph>
            </div>
          </div>
          <Paragraph variant={'sm'} className={'flex-1'}>
            {node?.address}
          </Paragraph>
          <div className="flex items-center flex-initial w-32 items-center">
            {node?.Warehouse?.capacity && (
              <div>
                <Paragraph variant={'sm'}>
                  {node?.Warehouse?.capacity} Sq.Ft.
                </Paragraph>
                <Paragraph variant={'xs'} className="text-[#4B5369] mt-1">
                  {node?.Warehouse?.pallets || '-'} pallets
                </Paragraph>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-8">
          <div className="group-hover:visible invisible flex gap-4 items-center">
            <img
              src={DeleteIcon}
              className="cursor-pointer"
              onClick={async () => await deleteNode(node?.id)}
            />
            <img
              src={EditIcon}
              className="cursor-pointer"
              onClick={() => setEdit(true)}
            />
            <img
              src={ViewIcon}
              className="cursor-pointer"
              onClick={() => {
                if (node.nodeClass == 'EXTERNAL') {
                  setView(true);
                } else {
                  if (isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)) {
                    navigate(`/administration/node/${node.id}/view`, {
                      state: node
                    });
                  } else {
                    setView(true);
                  }
                }
              }}
            />
          </div>
          <Switch
            checked={node?.isActive}
            onChange={async (checked) => {
              await updateNode(node?.id, { isActive: checked });
            }}
            label={node?.isActive ? 'Active' : 'Inactive'}
          />
        </div>
      </div>
      <Popup
        open={edit}
        setOpen={setEdit}
        content={
          <NodeForm
            formik={nodeFormik}
            nodeTypes={nodeTypeOptions}
            users={users}
            isEntity={false}
            edit={true}
            search={nodeSearch}
            setSearch={setNodeSearch}
            savedNodes={[]}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            organization={organization}
          />
        }
        title={
          <div>
            <p>Edit Node</p>
          </div>
        }
        onClose={() => {
          nodeFormik.setValues({
            name: node?.name,
            nodeClass: node?.nodeClass,
            code: node.code,
            nodeType: {
              value: node?.nodeType,
              label: nodeTypes.EXTERNAL[node?.nodeType]
            },
            address: node?.address,
            latLng: node?.locationLatLng,
            pocName: node?.pocName,
            pocContact: node?.pocContact,
            description: node?.description,
            isActive: node?.isActive,
            capacity: node.Warehouse?.capacity,
            pallets: node.Warehouse?.pallets,
            IMS: true,
            WMS: node.Warehouse?.WMS,
            manager: node.Warehouse?.Manager
          });
        }}
        actions={
          <div className="flex justify-between gap-2">
            <Button
              label="Cancel"
              variant="tertiary"
              onClick={() => {
                setEdit(false);
                nodeFormik.setValues({
                  name: node?.name,
                  nodeClass: node?.nodeClass,
                  code: node.code,
                  nodeType: {
                    value: node?.nodeType,
                    label: nodeTypes.EXTERNAL[node?.nodeType]
                  },
                  address: node?.address,
                  latLng: node?.locationLatLng,
                  pocName: node?.pocName,
                  pocContact: node?.pocContact,
                  description: node?.description,
                  isActive: node?.isActive,
                  capacity: node.Warehouse?.capacity,
                  pallets: node.Warehouse?.pallets,
                  IMS: true,
                  WMS: node.Warehouse?.WMS,
                  manager: node.Warehouse?.Manager
                });
              }}
            />

            <Button
              label="Edit Node"
              variant="primary"
              disabled={!nodeFormik.isValid}
              onClick={async () => {
                const payload = {
                  ...nodeFormik.values,
                  nodeType: nodeFormik.values.nodeType.value,
                  locationLatLng: nodeFormik.values.latLng,
                  managerId: nodeFormik.values.manager?.id
                };
                await updateNode(node?.id, payload);
                await refetch();
                setEdit(false);
                // resetNodeValues();
              }}
            />
          </div>
        }
      />
      <Popup
        open={view}
        setOpen={setView}
        content={
          <NodeForm
            formik={nodeFormik}
            nodeTypes={nodeTypeOptions}
            users={users}
            isEntity={false}
            edit={false}
            search={nodeSearch}
            setSearch={setNodeSearch}
            savedNodes={[]}
            viewOnly={true}
          />
        }
        title={
          <div>
            <p>{node.name}</p>
          </div>
        }
        onClose={() => {
          // resetNodeValues();
        }}
        actions={
          <div className="flex justify-between gap-2">
            <Button
              label="Cancel"
              variant="tertiary"
              onClick={() => {
                setView(false);
                resetNodeValues();
              }}
            />

            {/* <Button
                            label="Edit Node"
                            variant="primary"
                            disabled={!nodeFormik.isValid}
                            onClick={async () => {
                                const payload = {
                                    ...nodeFormik.values,
                                    nodeType: nodeFormik.values.nodeType.value,
                                    locationLatLng: nodeFormik.values.latLng,
                                    managerId: nodeFormik.values.manager?.id,
                                }
                                await updateNode(node?.id, payload)
                                await refetch()
                                setEdit(false)
                                // resetNodeValues();
                            }}
                        /> */}
          </div>
        }
      />
    </>
  );
};

const NodeList = ({
  nodes,
  refetch,
  search,
  setSearch,
  customer,
  page,
  pages,
  setPage
}) => {
  const { setAPILoader, subdomain } = useContext(SharedContext);
  const [view, setView] = useState(false);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [file, setFile] = useState(null);
  const [users, setUsers] = useState([]);
  const [addNode, setAddNode] = useState(false);
  const ref = useRef();
  const location = useLocation();

  const deleteNode = async (id) => {
    try {
      setAPILoader(true);
      await API.delete(`nodes/${id}`);
      await refetch();
      toaster('success', 'Node deleted successfully');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getUsers = async (value) => {
    try {
      let columns = [
        'firstName',
        'lastName',
        'username',
        'email',
        'phone',
        '$UserRoles.Role.name$'
      ];

      let colVal = {
        '$UserRoles.Role.allowedApps$': 'OPERATIONS',
        isActive: '1'
      };

      let filters = {
        colVal
      };

      const { data } = await API.get(`organizations/${subdomain}/users`, {
        params: { search: value || '', filters, columns }
      });

      setUsers(data);
    } catch (err) {
      onError(err);
    }
  };

  const onBulkUpload = async (nodes) => {
    try {
      setAPILoader(true);
      await API.post(
        customer?.id ? `nodes/bulk?companyId=${customer.id}` : `nodes/bulk`,
        nodes
      );
      setBulkUploadOpen(false);
      await refetch();
      toaster('success', `Nodes have been uploaded`);
    } catch (err) {
      console.log(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (userSearch) {
      getUsers(userSearch);
    } else {
      getUsers();
    }
  }, [userSearch]);

  useEffect(() => {
    if (location.state) {
      setSearch(location.state?.nodeName);
    }
  }, [location]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-[20px]">Nodes Details</p>
        <div className="flex gap-4 items-center">
          <CustomTextField
            fullWidth
            placeholder="Search Nodes"
            variant="outlined"
            color={'primary'}
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            inputProps={{
              onFocus: () => search
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px'
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {search && (
                    <Close
                      style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setSearch('');
                      }}
                    />
                  )}
                </InputAdornment>
              )
            }}
          />

          <img
            src={UploadIcon}
            className="h-5 w-5 cursor-pointer"
            onClick={() => ref.current.click()}
          />
          <a href={NodeBulkUploadTemplate}>
            <div>
              <img src={DownloadIcon} className="h-8 w-8 cursor-pointer" />
            </div>
          </a>
          <PopupButton
            open={addNode}
            setOpen={setAddNode}
            btn={
              <img
                src={AddIcon}
                className="h-5 w-5 cursor-pointer"
                onClick={() => setAddNode(true)}
              />
            }
            content={
              <CreateNode
                setOpen={setAddNode}
                entityInfo={{
                  entity: { ...customer },
                  nodeClass: customer.nodeClass
                }}
                getNodes={refetch}
              />
            }
          />
        </div>
      </div>
      {nodes.map((node) => (
        <NodeCard
          node={node}
          refetch={refetch}
          users={users}
          userSearch={userSearch}
          setUserSearch={setUserSearch}
          deleteNode={deleteNode}
        />
      ))}

      <CustomPagination page={page} setPage={setPage} pages={pages} />

      <BulkUpload
        open={bulkUploadOpen}
        file={file}
        setOpen={setBulkUploadOpen}
        entity={{
          name: customer?.name,
          entityType: customer?.entityType,
          type: customer?.type,
          id: customer?.id
        }}
        refetch={refetch}
        onSubmit={onBulkUpload}
      />

      <input
        type="file"
        ref={ref}
        hidden
        onChange={(e) => {
          setFile(e.target.files[0]);
          setBulkUploadOpen(true);
        }}
        onClick={() => {
          setFile(null);
        }}
        accept=".csv,.xlsx,.xls"
      />
    </div>
  );
};

export default NodeList;

export const ActionType = {
  HOLD: 'HOLD',
  REMOVE_HOLD: 'REMOVE_HOLD'
};

export const Source = {
  INVENTORY: 'INVENTORY',
  BATCHES: 'BATCHES'
};

export const PopoverOrigin = {
  FOOTER: 'FOOTER',
  BATCH: 'BATCH',
  DROPDOWN: 'DROPDOWN',
  BATCH_DROPDOWN: 'BATCH_DROPDOWN'
};

export const PromoScreen = {
  APPLY: 'APPLY',
  REMOVE: 'REMOVE'
};

import React, { useEffect, useState, useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useParams } from 'react-router';

import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext, exportToCSV } from '../../../utils/common';
import BulkUploadScreen from './BulkUploadScreen';
import BulkUploadTabs from '../../../atomicComponents/BulkUploadTabs';
import { exportToExcel } from '../../../services/BulkUploadServices';
import BulkValidationScreen from '../../orders/BulkValidationScreen';
import TemplateLoader from '../../../atomicComponents/TemplateLoader';
import useStyles from '../../orders/makeStyles';
import BulkUploadSuccessTitle from '../../../atomicComponents/BulkUploadSuccessTitle';
import BulkUploadCancelBtn from '../../../atomicComponents/BulkUploadCancelBtn';
import { toaster } from '../../../utils/toaster';

const UploadAisleView = () => {
  const { uid } = useParams();
  const type = 'Aisles';
  const classes = useStyles();
  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  const [openForm, setOpenForm] = useState({
    tab1: true,
    tab2: false,
    tab3: false
  });
  const [aislePayload, setAislePayload] = useState([]);
  const [verifiedAisles, setVerfiedAisles] = useState([]);
  const [aislesWithIssues, setAislesWithIssues] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [correctUploadFlag, setCorrectUploadFlag] = useState(false);
  const [dataloaded, setDataLoaded] = useState(true);
  const [correctAisles, setCorrectAisles] = useState(0);
  const [inCorrectAisles, setInCorrectAisles] = useState(0);
  const [exportVerifiedAisles, setExportVerifiedAisles] = useState([]);

  const header = ['Name', 'IsActive', 'Record Level', 'Message'];

  useEffect(() => {
    let title = 'Bulk Aisle Upload';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    setVerifyFlag(false);
  }, [selectedFile]);

  const validateDatesAndData = (aisles) => {
    try {
      const newAisles = aisles.map((aisle) => {
        const newAisle = {
          name: aisle['Name'],
          isActive: true
        };
        return newAisle;
      });
      setAislePayload(newAisles);
    } catch (error) {
      onError(error);
    }
  };

  const bulkUpload = (aisles) => {
    if (aisles?.length) {
      validateDatesAndData(aisles);
    }
  };

  useEffect(() => {
    if (verifiedAisles.length === 0) return;
    const aislesToExport = verifiedAisles.map((aisle) => {
      return {
        name: aisle?.name,
        isActive: aisle?.isActive,
        errorLevel: aisle?.errorLevel,
        message: aisle?.message
      };
    });
    setExportVerifiedAisles(aislesToExport);
  }, [verifiedAisles]);

  const handleCreation = async () => {
    try {
      const correctAislesArray = [];
      const faultyAislesArray = [];
      verifiedAisles.forEach((aisle) => {
        if (aisle?.errorLevel === 'Incorrect') {
          delete aisle['createdBy'];
          delete aisle['warehouseId'];
          faultyAislesArray.push(aisle);
        } else {
          correctAislesArray.push({
            name: aisle?.name,
            isActive: aisle?.isActive
          });
        }
      });
      setAislesWithIssues(faultyAislesArray);
      if (!correctAislesArray.length) {
        throw new Error('No Correct aisles');
      }
      setAPILoader(true);
      const payload = {
        aisles: correctAislesArray
      };
      await API.post(`warehouses/${uid}/aisles/bulk`, payload);
      setDataLoaded(true);
      setCorrectUploadFlag(true);
    } catch (error) {
      onError(error);
    } finally {
      setDataLoaded(true);
      setAPILoader(false);
    }
  };

  const extractFaultyData = () => {
    setDataLoaded(false);
    if (aislesWithIssues?.length && verifyFlag && selectedFile) {
      let title = `Faulty Aisles`;
      let arr = [...aislesWithIssues];
      arr.unshift(header);
      exportToCSV(null, arr, title);
    }
    setDataLoaded(true);
  };

  const handleSubmit = async () => {
    try {
      if (aislePayload.length === 0) {
        toaster('warning', 'Uploaded file is empty');
        return;
      }
      setDataLoaded(false);
      setAPILoader(true);
      const response = await API.post(`warehouses/${uid}/aisles/verify`, {
        aisles: [...aislePayload]
      });
      if (response.aisles.length) {
        setVerifyFlag(true);
        setVerfiedAisles(response.aisles);
      }
      setCorrectAisles(response.correctAisles);
      setInCorrectAisles(response.inCorrectAisles);
      setDataLoaded(true);
    } catch (error) {
      setDataLoaded(true);
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const nextButtons = () => {
    if (!verifyFlag) {
      return (
        <Button
          disabled={selectedFile ? false : true}
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            handleSubmit();
          }}
          fullWidth
        >
          VERIFY AISLES
        </Button>
      );
    }

    if (verifyFlag && !correctUploadFlag) {
      return (
        <Button
          disabled={selectedFile ? false : true}
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            handleCreation();
          }}
          fullWidth
        >
          UPLOAD CORRECT AISLES
        </Button>
      );
    }

    return (
      <Button
        disabled={selectedFile ? false : true}
        variant="contained"
        className={classes.createButton}
        onClick={() => {
          extractFaultyData();
        }}
        fullWidth
      >
        EXTRACT AISLES WITH ISSUES
      </Button>
    );
  };

  return (
    <Grid className={classes.gridContainer}>
      <div className={!dataloaded || 'display-none'}>
        <TemplateLoader />
      </div>
      <BulkUploadTabs
        verifiedList={verifiedAisles}
        correctUploadFlag={correctUploadFlag}
        verifyFlag={verifyFlag}
        setOpenForm={(value) => setOpenForm(value)}
        openForm={openForm}
        selectedFile={selectedFile}
      />

      <div className={`${classes.mainContent} mt-10`}>
        {openForm.tab1 && (
          <BulkUploadScreen
            bulkUpload={bulkUpload}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            type={'aisle'}
          />
        )}
        {verifyFlag && selectedFile && openForm.tab2 && (
          <>
            <BulkValidationScreen aisles={verifiedAisles} status="aisles" />
            <Grid container item xs={12} alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <div className={classes.exportButton}>
                  <button
                    className="exportCustomBtn"
                    onClick={() =>
                      exportToExcel(
                        verifyFlag,
                        selectedFile,
                        exportVerifiedAisles,
                        header,
                        type
                      )
                    }
                  >
                    Export
                  </button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
        {correctUploadFlag && selectedFile && openForm.tab3 && (
          <>
            <BulkUploadSuccessTitle
              text={`${correctAisles} Aisles have been created`}
            />
            <BulkUploadSuccessTitle
              text={`Remaining Data with issues ${inCorrectAisles}`}
            />
            <BulkValidationScreen
              tableHeight={680}
              aisles={aislesWithIssues}
              status="aisles"
            />
          </>
        )}
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={2}
          className="aisleBtn"
        >
          <BulkUploadCancelBtn
            path={`/administration/warehouse/${uid}`}
            text="Cancel"
          />
          <Grid item xs={12} sm={6}>
            {nextButtons()}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default UploadAisleView;

import React, { useCallback, useEffect, useState, useContext } from 'react';
import { FormControl, Grid, Container } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { debounce } from 'lodash';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import { SharedContext } from '../../utils/common';
import { DEBOUNCE_CONST } from '../../Config';
import API from '../../libs/axios';
import { isRequired } from '../../utils/validators';
import { onError } from '../../libs/errorLib';
import useStyles from './pickingStyles';
import PickingOrderTable from './PickingOrderTable';
import MoveTypes from '../../utils/enums/moveTypes';
import { ORGANIZATION_TYPES } from '../../constants';
import { useFormik } from 'formik';
import { InputAdornment } from '@mui/material';
import { Close, Search } from '@material-ui/icons';
import { TextField } from '@material-ui/core';

const PickingGenerationForm = ({
  orderId,
  isGlobalPickingModal,
  selectedOrdersForPicking,
  setSelectedOrdersForPicking,
  setEnableStartPicking,
  setOrdersData,
  setWarehouse,
  setCompany,
  warehouse,
  company
}) => {
  const classes = useStyles();
  const { setAPILoader, organization } = useContext(SharedContext);
  const [warehouses, setWarehouses] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [searchWarehouseValue, setSearchWarehouseValue] = useState('');
  const [singleCustomer, setSingleCustomer] = useState(null);
  const [selectedOrderPayload, setSelectedOrdersPayload] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [searchIds, setSearchIds] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const IS_MANUFACTURER =
    organization?.type === ORGANIZATION_TYPES.MANUFACTURER;

  useEffect(() => {
    if (!isGlobalPickingModal) {
      getPickingDetails();
    }
    if (isGlobalPickingModal && (IS_MANUFACTURER || company) && warehouse) {
      getAllGDN();
    }
  }, []);

  useEffect(() => {
    setSelectedOrdersForPicking(selectedOrders);
    setOrdersData(orders.filter((o) => selectedOrders.includes(o.id)));
  }, [selectedOrders]);

  useEffect(() => {
    if (orders.length > 0) setShowSearch(true);
  }, [orders]);

  const applyFilter = () => {
    let colVal = {};

    if (!IS_MANUFACTURER) {
      colVal['$Company.isActive$'] = true;
      colVal['$Company.id$'] = selectedCustomer?.id || company?.id;
    }

    colVal.moveType = [MoveTypes.OUTBOUND, MoveTypes.TRANSFER];
    colVal.pickupId = selectedWarehouse?.id;
    return colVal;
  };

  const initialValues = {
    selectedCustomer: {},
    selectednode: {}
  };

  const formik = useFormik({
    initialValues: initialValues
  });

  const sxProps = {
    '& .MuiInputBase-root': {
      padding: '8px 16px !important',
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  const listBoxProps = {
    fontSize: 14
  };
  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const getPickingDetails = async () => {
    try {
      if (company?.id && orderId) {
        const orderDetails = await API.get(`orders/order/${orderId}`);
        setSelectedOrdersPayload([orderDetails?.data]);
        let orders = [];
        if (selectedOrdersForPicking.length) {
          orders = [...selectedOrdersForPicking];
        } else {
          orders.push(orderDetails?.data?.customId);
        }
        setSelectedOrders(orders);
        setSelectedCustomer(orderDetails?.data?.Company);
        setSelectedWarehouse(orderDetails?.data?.warehousePickUp);
        setSearchCompanyValue(orderDetails?.data?.Company?.name);
        setSearchWarehouseValue(orderDetails?.data?.warehousePickUp?.name);
      }
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    if (isRequired(selectedWarehouse)) {
      setWarehouse(selectedWarehouse);
      IS_MANUFACTURER ||
        (isRequired(selectedCustomer) && setCompany(selectedCustomer));
      getAllGDN();
    } else {
      setSelectedOrders([]);
      setOrders([]);
    }
  }, [selectedWarehouse, selectedCustomer, page, searchIds]);

  useEffect(() => {
    if (warehouses.length == 1 && !searchWarehouseValue) {
      formik.setFieldValue('selectedNode', warehouses[0]);
      setSelectedWarehouse(warehouses[0]);
    }
    if (customers?.length == 1 && !searchCompanyValue) {
      formik.setFieldValue('selectedCustomer', customers[0]);
      setSelectedCustomer(customers[0]);
    }
  }, [warehouses, customers]);

  const getAllGDN = async () => {
    try {
      let colVal = applyFilter();
      let filters = { colVal };
      const orders = await API.get(`orders`, {
        params: {
          ...(searchIds ? { searchIds } : {}),
          filters,
          page: page,
          isPicking: true,
          statuses: ['IN_PROGRESS', 'PARTIALLY_COMPLETE', 'PENDING']
        }
      });
      let seletedOrder = [];
      if (selectedOrdersForPicking.length) {
        seletedOrder = [...selectedOrdersForPicking];
      }
      setSelectedOrders(seletedOrder);
      const newOrders = orders.data.filter(
        (element) => !selectedOrderPayload.find((el) => el?.id == element?.id)
      );
      let arr = [...selectedOrderPayload, ...newOrders];
      setPageCount(orders?.pages);
      setOrders(arr);
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    if (!IS_MANUFACTURER) getCompaniesCallback(searchCompanyValue);
  }, [searchCompanyValue]);

  const getCompaniesCallback = useCallback(
    debounce((searchCompanyValue) => {
      getCompanies(searchCompanyValue);
    }, DEBOUNCE_CONST),
    []
  );

  const getCompanies = async (search) => {
    setAPILoader(true);
    let colVal = { isActive: 1 };
    const response = await API.get(`companies`, {
      params: { search, filters: { colVal }, type: 'CUSTOMER' }
    });
    setCustomers(response.data.rows);
    setSingleCustomer(null);
    setAPILoader(false);
  };

  useEffect(() => {
    getWarehouseCallback({ searchWarehouseValue });
  }, [searchWarehouseValue]);

  const getWarehouseCallback = useCallback(
    debounce(({ searchWarehouseValue }) => {
      getWarehouses({ searchWarehouseValue });
    }, DEBOUNCE_CONST),
    []
  );

  const getWarehouses = async ({ searchWarehouseValue }) => {
    setAPILoader(true);
    const response = await API.get(`nodes`, {
      params: {
        manageInventory: true,
        search: searchWarehouseValue,
        nodeClass: 'INTERNAL'
      }
    });
    setWarehouses(response.data);
    setAPILoader(false);
  };

  useEffect(() => {
    if (IS_MANUFACTURER) {
      getWarehouses({});
    } else if (customers?.length === 1 && !searchCompanyValue.length) {
      setSingleCustomer(customers?.[0]);
      setSelectedCustomer(customers?.[0]);
      getWarehouses({ customerId: customers?.[0]?.id });
    }
  }, [customers]);

  return (
    <Container>
      <Grid container className={classes.parentContainer} spacing={3}>
        <Grid item className="text-xl font-semibold flex items-center" xs={4}>
          1. Select the order for Picking
        </Grid>
        <Grid item xs={4}>
          {showSearch && (
            <TextField
              fullWidth
              placeholder="Search Orders"
              variant="outlined"
              color={'primary'}
              value={searchIds}
              onChange={(e) => {
                setSearchIds(e.target.value);
                setPage(1);
              }}
              size="small"
              inputProps={{
                onFocus: () => searchIds
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Search
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '10px'
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchIds && (
                      <Close
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setSearchIds('');
                        }}
                      />
                    )}
                  </InputAdornment>
                )
              }}
            />
          )}
        </Grid>
        <Grid item sm={2}>
          <FormControl fullWidth={true} variant="outlined">
            <Autocomplete
              options={warehouses}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedWarehouse(newValue);
                  setWarehouse(newValue);
                  setSelectedOrdersPayload([]);
                  setSelectedOrders([]);
                  setSelectedOrdersForPicking([]);
                } else {
                  setWarehouse(null);
                  setSelectedWarehouse(null);
                }
              }}
              onInputChange={(event, newValue) => {
                setSearchWarehouseValue(newValue);
              }}
              placeholder={'Select Node'}
              getOptionLabel={(warehouse) =>
                warehouse.code || warehouse.name
                  ? `${warehouse.name} ${
                      warehouse?.code ? `- ${warehouse.code}` : ''
                    }`
                  : ''
              }
              value={selectedWarehouse || warehouse || null}
              formik={formik}
              sxProps={sxProps}
              customInputProps={inputProps}
              listBoxProps={listBoxProps}
              disableClearable={false}
            />
          </FormControl>
        </Grid>
        {IS_MANUFACTURER || (
          <Grid item xs={2}>
            <FormControl fullWidth={true} variant="outlined">
              <Autocomplete
                options={customers}
                onChange={(event, newValue) => {
                  formik.setFieldValue('selectedCustomer', newValue);
                  setSelectedCustomer(newValue);
                }}
                onInputChange={(event, newValue) => {
                  if (!newValue) {
                    setSelectedCustomer(null);
                    setSingleCustomer(null);
                  }
                  setSearchCompanyValue(newValue);
                }}
                value={
                  customers.length && selectedCustomer
                    ? customers?.find((e) => e.id == selectedCustomer.id) ||
                      null
                    : singleCustomer || ''
                }
                placeholder={'Select Customer'}
                getOptionLabel={(option) => option.name || ''}
                sxProps={sxProps}
                customInputProps={inputProps}
                listBoxProps={listBoxProps}
                disableClearable={false}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item sm={12}>
          <div className="mt-10">
            <PickingOrderTable
              orders={orders}
              selectedOrders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
              setSelectedOrdersPayload={setSelectedOrdersPayload}
              setEnableStartPicking={setEnableStartPicking}
              tableHeight={isGlobalPickingModal ? '50vh' : '49vh'}
              isGlobalPickingModal={isGlobalPickingModal}
            />
          </div>
        </Grid>
        <Grid container justify="space-between">
          <Grid item></Grid>
          <Grid>
            <Pagination
              component="div"
              shape="rounded"
              count={pageCount}
              color="primary"
              page={page}
              className={classes.pagination}
              onChange={(e, page) => {
                setPage(page);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PickingGenerationForm;

/*eslint-disable*/
import * as React from 'react';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

export default function OptionsMenu({ options, closeCallback, enterCallBack }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    enterCallBack && enterCallBack();
  };
  const handleClose = () => {
    setAnchorEl(null);
    closeCallback && closeCallback();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton onClick={(e) => handleClick(e)} size="small" sx={{ ml: 2 }}>
          <MoreVertIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.12))',
            mt: 2
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={0}
      >
        {options?.map((option) => (
          <MenuItem
            className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
            onClick={(e) => {
              if (option.onClick) {
                e.stopPropagation();
                option.onClick(e);
                handleClose();
              }
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import {
  calculateDateRangeFromDates,
  SharedContext
} from '../../../utils/common';
import IntelligenceDateRangePicker from './IntelligenceDateRangePicker';
import Filters from './Filters';

const SubTopIntBar = () => {
  const {
    sidebar,
    startDateIntelligence,
    setStartDateIntelligence,
    endDateIntelligence,
    setEndDateIntelligence,
    nodeIdsIntelligence,
    setNodeIdsIntelligence
  } = useContext(SharedContext);
  const [timeFilter, setTimeFilter] = useState('This Month');

  return (
    <div
      className={clsx(
        'w-screen fixed top-[44px] h-[44px] bg-blackPurple pr-6 z-[200] transition-all',
        sidebar ? 'max-[1366px]:pl-[340px] pl-[calc(16vw+18px)]' : 'pl-[87px]'
      )}
    >
      <div className="flex justify-start w-100 h-[44px] justify-between">
        <div className={'flex items-center'}>
          <IntelligenceDateRangePicker
            label={timeFilter}
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            updateRange={(input) => {
              setStartDateIntelligence(input.selection.startDate);
              setEndDateIntelligence(input.selection.endDate);
              const result = calculateDateRangeFromDates(
                input.selection.startDate,
                input.selection.endDate
              );
              setTimeFilter(result);
            }}
          />
          <Filters
            nodeIdsIntelligence={nodeIdsIntelligence}
            setNodeIdsIntelligence={setNodeIdsIntelligence}
            getOrgNodes={() => {}}
            height={'200px'}
            isPrimary={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SubTopIntBar;

import { makeStyles } from '@material-ui/core';

const popupStyles = makeStyles((theme) => ({
  paperStyle: {
    marginBottom: '5px',
    padding: 5,
    height: '200px',
    maxWidth: 'auto',
    minWidth: 'auto',
    margin: '10px'
  },
  children: {
    margin: '5px auto'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  backdropGrid: {
    backgroundColor: 'white',
    padding: '15px 15px'
  },
  email: {
    color: theme.palette.primary.main
  },
  btn: {
    display: 'flex',
    justifyContent: 'center'
  },
  paperStyleSmall: {
    padding: 5,
    height: '75px',
    maxWidth: 'auto',
    minWidth: 'auto',
    margin: '10px'
  },
  inline: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px'
  },
  label: {
    color: 'grey'
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 10,
    color: 'grey',
    fontSize: 'small'
  }
}));

export default popupStyles;

/*eslint-disable*/

import { useContext, useEffect } from 'react';
import QuickBooksIntegration from './Quickbooks';
import { SharedContext } from '../../utils/common';

const Integrations = () => {
  const { setCurrentPageTitle } = useContext(SharedContext);

  useEffect(() => {
    setCurrentPageTitle('Integrations');
  }, []);
  return (
    <div className="mt-10 w-[70%] ml-10">
      <div className="mb-4">
        <p className="font-bold text-[20px]">Built By Oware</p>
        <hr className="mt-4" />
      </div>
      <QuickBooksIntegration />
    </div>
  );
};

export default Integrations;

/*eslint-disable*/
import { ArcLayer, LineLayer } from 'deck.gl';
import ReactDOM from 'react-dom';
import DiamondGray from '../../../assets/icons/unifiedDashboard/diamondGray.svg';
import DiamondWhite from '../../../assets/icons/unifiedDashboard/diamond.svg';
import FactoryIcon from '../../../assets/icons/unifiedDashboard/factory.svg';
import FactoryGrayIcon from '../../../assets/icons/unifiedDashboard/factoryGray.svg';
import OtherIcon from '../../../assets/icons/unifiedDashboard/other.svg';
import OtherGrayIcon from '../../../assets/icons/unifiedDashboard/otherGray.svg';
import StoreIcon from '../../../assets/icons/unifiedDashboard/store.svg';
import StoreGrayIcon from '../../../assets/icons/unifiedDashboard/storeGray.svg';
import UpDownArrows from '../../../assets/icons/unifiedDashboard/thinArrowBoth.svg';
import DownArrow from '../../../assets/icons/unifiedDashboard/thinArrowDown.svg';
import UpArrow from '../../../assets/icons/unifiedDashboard/thinArrowUp.svg';
import WarehouseIcon from '../../../assets/icons/unifiedDashboard/warehouse.svg';
import WarehouseGrayIcon from '../../../assets/icons/unifiedDashboard/warehouseGray.svg';
import {
  ActivityLevel,
  DateFilterOptions,
  NodeType,
  NodeTypeOptions,
  ShipmentStatus,
  ShipmentsDirection,
  StatusFilterOptions
} from '../constants';
import NodesCluster from './NodesCluster';

export const getNodeActivityColor = (activity) => {
  let nodeColor;
  switch (activity) {
    case ActivityLevel.HIGH:
      nodeColor = 'bg-error';
      break;
    case ActivityLevel.MEDIUM:
      nodeColor = 'bg-inProgress';
      break;
    case ActivityLevel.LOW:
      nodeColor = 'bg-pending';
      break;
    default:
      nodeColor = 'bg-black';
      break;
  }
  return nodeColor;
};

export const getNodeIcon = (type) => {
  const lowercaseType = type?.toLowerCase();
  let iconSrc;
  switch (lowercaseType) {
    case NodeType.PLANT?.toLowerCase():
      iconSrc = FactoryIcon;
      break;
    case NodeType.WAREHOUSE?.toLowerCase():
      iconSrc = WarehouseIcon;
      break;
    case NodeType.STORE?.toLowerCase():
      iconSrc = StoreIcon;
      break;
    case NodeType.OTHER?.toLowerCase():
      iconSrc = OtherIcon;
      break;
    case NodeType.MULTI?.toLowerCase():
      iconSrc = DiamondWhite;
      break;
    default:
      iconSrc = OtherIcon;
      break;
  }
  return iconSrc;
};

export const getNodeOptionIcon = (type) => {
  let iconSrc;
  switch (type) {
    case NodeTypeOptions.ALL_NODES:
      iconSrc = DiamondGray;
      break;
    case NodeTypeOptions.PLANT:
      iconSrc = FactoryGrayIcon;
      break;
    case NodeTypeOptions.WAREHOUSE:
      iconSrc = WarehouseGrayIcon;
      break;
    case NodeTypeOptions.STORE:
      iconSrc = StoreGrayIcon;
      break;
    case NodeTypeOptions.OTHER:
      iconSrc = OtherGrayIcon;
      break;
    default:
      iconSrc = OtherGrayIcon;
      break;
  }
  return iconSrc;
};

export const getArcLayer = (source, links) => {
  return new ArcLayer({
    id: 'arc-layer',
    data: links,
    numSegments: 300,
    getSourcePosition: () => source,
    getTargetPosition: (f) => f,
    getSourceColor: [11, 25, 64],
    getTargetColor: [11, 25, 64],
    getWidth: 3
  });
};

export const getLineLayer = (source, links) => {
  return new LineLayer({
    id: 'line-layer',
    data: links,
    numSegments: 100,
    getSourcePosition: () => source,
    getTargetPosition: (f) => f,
    getSourceColor: [0, 128, 200],
    getTargetColor: [0, 128, 200],
    getWidth: 3
  });
};

export const convertLatLng = (input) => {
  if (typeof input === 'object' && 'lat' in input && 'lng' in input) {
    const { lat, lng } = input;
    return [lng, lat];
  } else {
    throw new Error('Input is not in the expected format');
  }
};

export const generateClusterMarker = (
  activeShipments,
  clusterType,
  statusFilter,
  nodeTypes
) => {
  const outerDiv = document.createElement('div');
  outerDiv.className =
    'flex w-fit justify-between py-1 px-2 rounded-full shadow-[0_4px_4px_0px_rgba(0,0,0,0.16)] bg-white items-center transition-all cursor-pointer';

  if (nodeTypes.includes(statusFilter)) {
    outerDiv.className += ' opacity-20';
  }
  const innerDiv1 = document.createElement('div');
  innerDiv1.className = 'flex';

  const subInnerDiv1 = document.createElement('div');
  subInnerDiv1.className =
    'flex w-8 h-[27px] box-border bg-richBlack rounded-full border border-white overflow-hidden justify-center items-center relative left-0 z-10';

  const img = document.createElement('img');
  img.src = getNodeIcon(clusterType);
  img.alt = 'icon';

  subInnerDiv1.appendChild(img);

  const subInnerDiv2 = document.createElement('div');
  subInnerDiv2.className =
    'flex w-8 h-[27px] box-border bg-richBlack rounded-full mr-2 border border-white overflow-hidden justify-center items-center opacity-20 relative -ml-5';

  innerDiv1.appendChild(subInnerDiv1);
  innerDiv1.appendChild(subInnerDiv2);

  const innerDiv2 = document.createElement('div');

  const paragraph = document.createElement('p');
  paragraph.className =
    'text-primaryBlue text-xs h-fit self-center font-medium transition-all';
  paragraph.textContent = activeShipments;

  innerDiv2.appendChild(paragraph);

  outerDiv.appendChild(innerDiv1);
  outerDiv.appendChild(innerDiv2);

  return outerDiv;
};

export const getClusterData = (markerElements) => {
  const nodeTypes = [];
  const nodeStatuses = [];
  let clusterType = NodeType.MULTI;
  let markerKeys = [];
  let activeShipments = 0;
  for (const element of markerElements) {
    const childElementWithData = element.querySelector('[data-node-key]');
    if (childElementWithData) {
      nodeTypes.push(childElementWithData.getAttribute('data-node-type'));
      nodeStatuses.push(
        JSON.parse(childElementWithData.getAttribute('data-node-statuses'))
      );
      markerKeys.push(childElementWithData.getAttribute('data-node-key'));
      const dataActiveShipments = childElementWithData.getAttribute(
        'data-active-shipments'
      );
      const shipmentValue = parseInt(dataActiveShipments);
      if (!isNaN(shipmentValue)) {
        activeShipments += shipmentValue;
      }
    }
  }
  let nodeTypesSet = new Set(nodeTypes);
  if (nodeTypesSet.size === 1) {
    [clusterType] = nodeTypesSet;
  }
  return { clusterType, markerKeys, nodeStatuses, activeShipments };
};

export const MapLegend = () => {
  return (
    <div className="bg-white flex justify-between py-3 px-4 shadow-[0_1px_3px_0px_rgba(0,0,0,0.20)] w-fit rounded-lg">
      <p className="text-primaryBlue text-sm font-medium">Activity</p>
      <p className="text-indigo text-sm ml-4">
        <span className="inline-block bg-normalGreen w-[9px] h-[9px] rounded-full mr-2"></span>
        High
      </p>
      <p className="text-indigo text-sm ml-4">
        <span className="inline-block bg-navyBlue w-[9px] h-[9px] rounded-full mr-2"></span>
        Medium
      </p>
      <p className="text-indigo text-sm ml-4">
        <span className="inline-block bg-persianRed w-[9px] h-[9px] rounded-full mr-2"></span>
        Low
      </p>
    </div>
  );
};

export const getMapLegend = () => {
  const outerDiv = document.createElement('div');
  outerDiv.className =
    'bg-white flex justify-between py-3 px-4 shadow-[0_1px_3px_0px_rgba(0,0,0,0.20)] w-fit rounded-lg mb-5';

  const paragraph1 = document.createElement('p');
  paragraph1.className = 'text-primaryBlue text-sm font-medium';
  paragraph1.textContent = 'Activity';

  const paragraph2 = document.createElement('p');
  paragraph2.className = 'text-indigo text-sm ml-4';

  const spanHigh = document.createElement('span');
  spanHigh.className =
    'inline-block bg-error w-[9px] h-[9px] rounded-full mr-2';

  paragraph2.appendChild(spanHigh);

  paragraph2.appendChild(document.createTextNode('High'));

  const paragraph3 = document.createElement('p');
  paragraph3.className = 'text-indigo text-sm ml-4';

  const spanMedium = document.createElement('span');
  spanMedium.className =
    'inline-block bg-inProgress w-[9px] h-[9px] rounded-full mr-2';
  paragraph3.appendChild(spanMedium);
  paragraph3.appendChild(document.createTextNode('Medium'));

  const paragraph4 = document.createElement('p');
  paragraph4.className = 'text-indigo text-sm ml-4';

  const spanLow = document.createElement('span');
  spanLow.className =
    'inline-block bg-pending w-[9px] h-[9px] rounded-full mr-2';
  paragraph4.appendChild(spanLow);
  paragraph4.appendChild(document.createTextNode('Low'));

  outerDiv.appendChild(paragraph1);
  outerDiv.appendChild(paragraph2);
  outerDiv.appendChild(paragraph3);
  outerDiv.appendChild(paragraph4);

  return outerDiv;
};

export const getShipmentStatusCounts = (data) => {
  const statusSum = {};

  for (const nodeKey in data) {
    if (Object.prototype.hasOwnProperty.call(data, nodeKey)) {
      const node = data[nodeKey];
      const statuses = node.statuses;

      for (const statusKey in statuses) {
        if (Object.prototype.hasOwnProperty.call(statuses, statusKey)) {
          if (statusSum[statusKey] === undefined) {
            statusSum[statusKey] = 0;
          }
          statusSum[statusKey] += statuses[statusKey];
        }
      }
    }
  }

  return statusSum;
};

export const isClusterFiltered = (nodeStatuses, statusFilter) => {
  if (statusFilter === ShipmentStatus.ALL) {
    return false;
  }
  return nodeStatuses.some((nodeStatus) => nodeStatus[statusFilter] === 0);
};

export const addClusterClass = (markerElement, className) => {
  const clusterElement = markerElement.querySelector('[data-is-cluster]');
  if (clusterElement) {
    if (clusterElement.classList) {
      clusterElement.classList.add(className);
    } else {
      clusterElement.className += ' ' + className;
    }
  }
};

export const removeClusterClass = (markerElement, className) => {
  const clusterElement = markerElement.querySelector('[data-is-cluster]');
  if (clusterElement) {
    if (clusterElement.classList) {
      clusterElement.classList.remove(className);
    } else {
      const currentClasses = clusterElement.className.split(' ');
      const index = currentClasses.indexOf(className);
      if (index !== -1) {
        currentClasses.splice(index, 1);
        clusterElement.className = currentClasses.join(' ');
      }
    }
  }
};

export const filterClusters = (clusters, statusFilter, links) => {
  clusters.forEach((cluster) => {
    if (statusFilter === ShipmentStatus.ALL && links.length === 0) {
      removeClusterClass(cluster.marker.targetElement, 'opacity-20');
      return;
    }
    if (cluster?.markers?.length > 1) {
      const markerElements = cluster.markers.map(
        (marker) => marker.targetElement
      );
      const { nodeStatuses, markerKeys } = getClusterData(markerElements);
      const filtered = nodeStatuses.some(
        (nodeStatus) => nodeStatus[statusFilter] === 0
      );
      const filteredLinks = markerKeys.some((key) => links.includes(key));

      if (filtered || !filteredLinks) {
        addClusterClass(cluster.marker.targetElement, 'opacity-20');
      } else {
        removeClusterClass(cluster.marker.targetElement, 'opacity-20');
      }
    }
  });
};

export const getNodeDirection = (key, directions) => {
  const directionMap = {
    [ShipmentsDirection.INWARD]: directions.INWARD || [],
    [ShipmentsDirection.OUTBOUND]: directions.OUTBOUND || [],
    [ShipmentsDirection.BOTH]: directions.BOTH || []
  };

  for (const direction in directionMap) {
    if (directionMap[direction].includes(key)) {
      return direction;
    }
  }

  return ShipmentsDirection.NONE;
};

export const getDirectionIcon = (type) => {
  let iconSrc;
  switch (type) {
    case ShipmentsDirection.INWARD:
      iconSrc = UpArrow;
      break;
    case ShipmentsDirection.OUTBOUND:
      iconSrc = DownArrow;
      break;
    case ShipmentsDirection.BOTH:
      iconSrc = UpDownArrows;
      break;
  }
  return iconSrc;
};

export const getLinks = (currentNode, allNodes) => {
  const links = [];
  allNodes[currentNode].links.forEach((link) => {
    if (allNodes[link]) {
      links.push({ location: allNodes[link]?.currentLocation, link });
    }
  });
  return links;
};

export const updateClusters = (
  clusterer,
  resetCurrentLocations,
  nodeData,
  setClusterNodes
) => {
  if (!clusterer || !clusterer.clusters) return;
  resetCurrentLocations();
  const currentMarkers = [];

  clusterer.clusters.forEach((cluster) => {
    if (cluster.markers.length <= 1) return;
    const markerElements = cluster.markers.map(
      (marker) => marker.targetElement
    );
    const { markerKeys } = getClusterData(markerElements);
    currentMarkers.push(...markerKeys);
    markerKeys.forEach((key) => {
      if (nodeData[key]) {
        nodeData[key].currentLocation = [
          cluster.position.lng(),
          cluster.position.lat()
        ];
      }
    });
  });
  const newClusterNodes = Array.from(new Set(currentMarkers));
  setClusterNodes(newClusterNodes);
};

export const getRenderer = () => {
  return {
    render(cluster, stats, map) {
      const markerElements = cluster.markers.map(
        (marker) => marker.targetElement
      );
      const { clusterType, activeShipments } = getClusterData(markerElements);
      stats = {};
      const element = document.createElement('div');
      const google = window.google;
      const clusterMarker = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: cluster.position,
        content: element
      });
      ReactDOM.render(
        <NodesCluster
          activeShipments={activeShipments}
          nodesInCluster={cluster.count}
          clusterType={clusterType}
        />,
        element
      );
      return clusterMarker;
    }
  };
};

export const onClusterClickHandler = (_, cluster, map) => {
  const google = window.google;
  map.fitBounds(cluster.bounds);
  google.maps.event.addListenerOnce(map, 'idle', function () {
    map.setZoom(map.getZoom());
  });
};

export const processNodeData = (nodes) => {
  Object.keys(nodes).forEach((key) => {
    if (!nodes[key]?.position) {
      nodes[key].position = {
        lat: 0,
        lng: 0
      };
    }
    if (nodes[key]?.position.lat === '' || nodes[key]?.position.lng === '') {
      nodes[key].position.lat = 0;
      nodes[key].position.lng = 0;
    }
    if (typeof nodes[key].position.lat === 'string') {
      nodes[key].position.lat = parseFloat(nodes[key].position.lat);
    }
    if (typeof nodes[key].position.lng === 'string') {
      nodes[key].position.lng = parseFloat(nodes[key].position.lng);
    }
    nodes[key].currentLocation = convertLatLng(nodes[key].position);
    nodes[key].uniqueID = Math.random().toString();
  });
  return nodes;
};

export const getNodeCategories = () => {
  const objectsArray = [];

  for (const key in NodeTypeOptions) {
    if (Object.prototype.hasOwnProperty.call(NodeTypeOptions, key)) {
      objectsArray.push({
        value: key,
        label: NodeTypeOptions[key],
        icon: getNodeOptionIcon(NodeTypeOptions[key])
      });
    }
  }
  return objectsArray;
};

export const getStatusFilters = () =>
  Object.keys(StatusFilterOptions).map((key) => ({
    value: key,
    label: StatusFilterOptions[key]
  }));

export const getDateFilters = () => {
  const objectsArray = [];

  for (const key in DateFilterOptions) {
    if (Object.prototype.hasOwnProperty.call(DateFilterOptions, key)) {
      const itemValue = DateFilterOptions[key];
      const itemLabel = DateFilterOptions[key];
      objectsArray.push({ value: itemValue, label: itemLabel });
    }
  }
  return objectsArray;
};

export const favouriteSort = (a, b) => {
  const isFavoriteA = a?.ShipmentFavorites[0]?.isFavorite || false;
  const isFavoriteB = b?.ShipmentFavorites[0]?.isFavorite || false;

  if (isFavoriteA && !isFavoriteB) {
    return -1;
  } else if (!isFavoriteA && isFavoriteB) {
    return 1;
  } else {
    return 0;
  }
};

export const getNodeShipmentsDirection = (input) => {
  const { INWARD, OUTBOUND, BOTH } = input;

  if (INWARD.length > 0 && OUTBOUND.length === 0 && BOTH.length === 0) {
    return 'OUTBOUND';
  } else if (OUTBOUND.length > 0 && INWARD.length === 0 && BOTH.length === 0) {
    return 'INWARD';
  } else if (BOTH.length > 0 || (INWARD.length > 0 && OUTBOUND.length > 0)) {
    return 'BOTH';
  } else {
    return 'NONE';
  }
};

export const setMapBounds = (map, nodeData, padding = 0) => {
  const google = window.google;
  let minLat = Infinity;
  let minLng = Infinity;
  let maxLat = -Infinity;
  let maxLng = -Infinity;

  for (const key in nodeData) {
    if (Object.prototype.hasOwnProperty.call(nodeData, key)) {
      const position = nodeData[key].position;
      if (
        position &&
        position.lat !== undefined &&
        position.lng !== undefined
      ) {
        minLat = Math.min(minLat, position.lat);
        minLng = Math.min(minLng, position.lng);
        maxLat = Math.max(maxLat, position.lat);
        maxLng = Math.max(maxLng, position.lng);
      }
    }
  }

  if (
    minLat === Infinity &&
    minLng === Infinity &&
    maxLat === -Infinity &&
    maxLng === -Infinity
  ) {
    const defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(-1, -1),
      new google.maps.LatLng(1, 1)
    );
    map.fitBounds(defaultBounds);
    return;
  }

  const latPadding = (maxLat - minLat) * padding;
  const lngPadding = (maxLng - minLng) * padding;

  minLat -= latPadding;
  maxLat += latPadding;
  minLng -= lngPadding;
  maxLng += lngPadding;

  const bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(minLat, minLng),
    new google.maps.LatLng(maxLat, maxLng)
  );

  map.fitBounds(bounds);
};

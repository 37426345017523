import { Table } from '@material-ui/core';
import {
  Box,
  Collapse,
  TableBody,
  TableContainer,
  TableFooter
} from '@mui/material';
import { DataGrid, GridRow } from '@mui/x-data-grid';
import confirmationTableHeader from './confirmationTableHeader';
import ConfirmationPreviewSubTable from './ConfirmationPreviewSubTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useStyles from './pickingStyles';
import { memo, useEffect, useState } from 'react';

const ConfirmationPreviewTable = ({ payload, totalOrderedCount }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const classes = useStyles();
  const [pickedQty, setPickedQty] = useState(0);

  useEffect(() => {
    setPickedQty(
      payload.reduce((sum, v) => {
        return (
          sum +
          v.batches.reduce((s, b) => {
            return s + (b.pickQuantity ?? 0);
          }, 0)
        );
      }, 0)
    );
  }, []);

  const MemoizedRows = (props) => {
    return (
      <>
        <GridRow {...props} />
        <Collapse in={expandedRows.includes(props?.row?.product?.id)}>
          <Box sx={{ margin: 2 }}>
            <ConfirmationPreviewSubTable batches={props?.row?.batches} />
          </Box>
        </Collapse>
      </>
    );
  };

  return (
    <TableContainer className={classes.container}>
      <Table aria-label="sticky table">
        <TableBody>
          <DataGrid
            rows={payload}
            columns={[
              ...confirmationTableHeader,
              {
                field: 'actions',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
                renderHeader: () => {
                  return <p className="w-100 parentActions routeheader"></p>;
                },
                renderCell: (params) => (
                  <span className="table-cell-trucate">
                    {expandedRows.includes(params.id) ? (
                      <KeyboardArrowUpIcon
                        className="cursor-pointer"
                        onClick={() =>
                          setExpandedRows((prev) =>
                            prev.filter((id) => id !== params.id)
                          )
                        }
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() =>
                          setExpandedRows((prev) => [...prev, params.id])
                        }
                      />
                    )}
                  </span>
                )
              }
            ]}
            getRowId={(row) => row?.product?.id}
            className="parentDataTable customDataGrid mr-[20px] border-0"
            hideFooter
            style={{ height: '45vh', width: '100%' }}
            sx={{
              '.MuiDataGrid-columnSeparator': {
                display: 'none'
              },
              '&.MuiDataGrid-root': {
                border: 'none'
              },
              '.MuiDataGrid-columnHeadersInner': {
                background: '#F6F8FB !importants',
                fontWeight: '500'
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'white',
                border: 'none',
                '& div': {
                  visibility: 'visible !important'
                }
              },
              '& .MuiDataGrid-cell': {
                border: 'none',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                '&:focus': {
                  border: 'none',
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  outline: 'none'
                }
              }
            }}
            components={{
              Row: memo(MemoizedRows)
            }}
          />
        </TableBody>
        <TableFooter className="bg-dark300 border mx-2 text-xs">
          <div className="flex justify-end p-5">
            <div className="mx-2 text-md">
              {"Total SKU's: "}
              <span className="mx-2 text-lg">{payload.length ?? 0}</span>
            </div>
            <div className="mx-2 text-md">
              Total Qty:
              <span className="mx-2 text-lg">
                {+(pickedQty ?? 0).toFixed(3)}/
                {+(totalOrderedCount ?? 0).toFixed(3)}
              </span>
            </div>
          </div>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ConfirmationPreviewTable;

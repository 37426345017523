import React, { useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core';

import SectionHeading from '../atomicComponents/SectionHeading';

export default function Dialogue(props) {
  const { open, handleClose, submitHandler, dialogueText } = props;
  const [disable, setDisable] = useState(false);
  return (
    <div style={{ display: 'inline' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container justifyContent="flex-start">
          <Grid item xs={12} className="text-center">
            <DialogTitle>
              <SectionHeading title="Confirmation" />
            </DialogTitle>
          </Grid>
          <Grid item xs={12} className="text-center">
            <DialogContent>
              <Grid container spacing={2} className="text-center">
                <SectionHeading className="w-100" title={dialogueText} />
              </Grid>
            </DialogContent>
          </Grid>
          <DialogActions style={{ justifyContent: 'center' }} className="w-100">
            <Grid item xs={12} className="text-center">
              <Tooltip title="No">
                <Button
                  onClick={handleClose}
                  color="#D9DFE5"
                  variant="contained"
                >
                  No
                </Button>
              </Tooltip>
              <Tooltip title="Yes" className="ml-2">
                <Button
                  type="button"
                  className={disable && 'cursor-not-allowed'}
                  disabled={disable}
                  onClick={() => {
                    setDisable(true);
                    submitHandler();
                  }}
                  variant="contained"
                >
                  Yes
                </Button>
              </Tooltip>
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
}

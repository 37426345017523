/*eslint-disable*/
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
// Icons
import InventoryProductTableRow from './InventoryTableRow';

const InventoryProductsTable = ({ data }) => {
  return (
    <>
      <TableContainer
        component={Paper}
        className="mt-10"
        sx={{
          borderRadius: '16px',
          overflow: 'hidden',
          border: '1px solid rgba(75, 137, 200, 0.2) !important',
          boxShadow: '4px 4px 16px rgb(75 137 200 / 12%) !important',
          '& .MuiTable-root': {
            borderCollapse: 'unset'
          }
        }}
      >
        <Grid className="scroll-table">
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className="w-1/3 text-xs">
                  <div className="flex items-center text-[#696F80]">
                    <span>SKU CODE AND NAME</span>
                  </div>
                </TableCell>
                <TableCell className="text-xs text-[#696F80]">NODES</TableCell>
                <TableCell className="text-xs text-[#696F80]">
                  NUMBER OF BATCHES
                </TableCell>
                <TableCell className="text-xs text-[#696F80]">
                  TOTAL QTY
                </TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, id) => (
                <InventoryProductTableRow key={id} row={row} />
              ))}
            </TableBody>
          </Table>
        </Grid>
      </TableContainer>
    </>
  );
};

export default InventoryProductsTable;

/* eslint-disable */
import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { withScriptjs, withGoogleMap } from 'react-google-maps';
import RouteMaps from '../../components/RouteMaps';
import { GOOGLE_MAP_API_KEY } from '../../constants';

const ShipmentMap = ({ order, zoom }) => {
  const WrappedMap = withScriptjs(withGoogleMap(RouteMaps));

  const map = useMemo(
    () => (
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${GOOGLE_MAP_API_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: `100%` }} />}
        wayPoints={[order]}
        options={{
          disableDefaultUI: true,
          streetViewControl: false
        }}
        zoom={zoom}
        order={order}
      />
    ),
    [order]
  );

  return (
    <Grid className="h-full w-full">
      <Grid item xs={12} className="h-full">
        <div className="h-full w-full">{map}</div>
      </Grid>
    </Grid>
  );
};

export default ShipmentMap;

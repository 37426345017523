/*eslint-disable*/
import React from 'react';
import { Grid } from '@mui/material';
import PrintStyles from './PrintStyles';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { Paragraph, Title } from '../../../../core-components/atoms/Text';

const BasicDetail = ({ order, watermark }) => {
  const useStyles = makeStyles((theme) => ({
    basicDetailsContainer: {
      [theme.breakpoints.down('xs')]: {
        '& > .MuiGrid-item': {
          flexBasis: '20%',
          maxWidth: '20%'
        }
      }
    }
  }));
  const classes = { ...PrintStyles(), ...useStyles() };
  const infoFields = [
    {
      id: 'orderId',
      label: 'ORDER ID',
      format: (value) => (value?.customId ? value?.customId : '-')
    },
    {
      id: 'OrderType',
      label: 'ORDER TYPE',
      format: (value) =>
        value
          ? value?.moveSubType
            ? value?.moveSubType
            : value.moveType === 'INWARD'
            ? 'INBOUND'
            : value?.moveType
          : '-'
    },
    {
      id: 'supplier',
      label:
        order?.moveSubType === 'PURCHASE' ||
        order?.moveSubType === 'SALES_RETURN'
          ? 'SUPPLIER'
          : 'CUSTOMER',
      format: (value) => (value ? value?.Company?.name : '-')
    },
    {
      id: 'expectedDeliveryTime',
      label: 'EXPECTED DELIVERY DATE',
      format: (value) =>
        value?.expectedDelivery
          ? moment(value?.expectedDelivery).format('DD-MM-YYYY hh:mm:ss')
          : '-'
    },
    {
      id: 'referenceId',
      label: 'REFERENCE ID',
      format: (value) =>
        value.referenceId
          ? value.referenceId.length > 13
            ? value.referenceId.substring(0, 13) + '...'
            : value.referenceId
          : '-'
    },
    {
      id: 'createdBy',
      label: 'CREATED BY',
      format: (value) =>
        value?.User ? value?.User?.firstName + ' ' + value?.User?.lastName : '-'
    },

    {
      id: 'creationDate',
      label: 'CREATION DATE AND TIME',
      format: (value) =>
        value?.createdAt
          ? moment(value?.createdAt).format('DD-MM-YYYY hh:mm:ss')
          : '-'
    }
  ];

  if (!watermark && ['SALES', 'PURCHASE'].includes(order?.moveSubType)) {
    infoFields.push({
      id: 'paymentTerm',
      label: 'PAYMENT TERMS',
      format: (value) => (value?.PaymentTerm ? value?.PaymentTerm?.title : '-')
    });
  }

  return (
    <Grid
      container
      style={{ maxWidth: '100%', marginLeft: '-10px', marginTop: '20px' }}
      spacing={1}
      className={classes.basicDetailsContainer}
    >
      {infoFields.map(({ id, label, format }) => (
        <Grid item md={2} xs={2} key={id} space={1} className="px-1">
          <Paragraph variant={'xs'} className={classes.label}>
            {`${label}:`}
          </Paragraph>
          <Title variant={'xs'} className={classes.basicFieldValue}>
            {order ? format(order) : '-'}
          </Title>
        </Grid>
      ))}
    </Grid>
  );
};

export default BasicDetail;

import { useEffect, useState, useContext, useCallback } from 'react';
import {
  Paper,
  Grid,
  InputBase,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Button
} from '@material-ui/core';
import classNames from 'classnames/bind';
import { Pagination } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/EditOutlined';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';

import API from '../../../libs/axios';
import Filter from '../../../components/Filter';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';
import AddReportView from './AddReportView';
import reportingStyles from './report.makeStyles';
import { SharedContext } from '../../../utils/common';
import TableHeader from '../../../components/TableHeader';
import MessageSnackbar from '../../../components/MessageSnackbar';
import { MdOutlineAdjust, MdSearch, MdCancel } from 'react-icons/md';
import { checkPermission } from '../../../utils/auth';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';

export default function EmbeddedReportView() {
  const classes = reportingStyles();

  const [page, setPage] = useState(1);
  const [showMessage] = useState(null);
  const [urlVal, setUrlVal] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [reportings, setReportings] = useState([]);
  const [nameVal, setReportingNameVal] = useState('');
  const [filterView, setFilterView] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedReport, setSelectedReport] = useState(null);
  const [addReportingViewOpen, setAddReportingViewOpen] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);
  const {
    currentUser,
    currentPageTitle,
    setCurrentPageTitle,
    subdomain,
    setAPILoader
  } = useContext(SharedContext);

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const columnsNew = [
    {
      field: 'isActive',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      width: 50,
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return <p className="w-100 parentActions">Name</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params.row.name}>
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      )
    },
    {
      field: 'URL',
      headerName: 'URL',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return <p className="w-100 parentActions">URL</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.URL}>
          <span className="table-cell-trucate">{params?.row?.URL}</span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'driverDatatableActions',
      cellClassName: 'justify-content-right',
      renderCell: (params) => (
        <div className={params?.row?.URL || 'display-none'}>
          <Tooltip
            title="Edit"
            className={
              checkPermission(currentUser, 'OPS_REPORT_UPDATE') ||
              'display-none'
            }
          >
            <EditIcon key={params} onClick={() => openEditView(params.row)} />
          </Tooltip>
        </div>
      )
    }
  ];

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer
        {...props}
        className="toolbarContainer userToolbarContainer"
      >
        <Tooltip title="Filter">
          <button
            className="mainFilter"
            id="search"
            label="Search"
            value={searchKeyword}
            key={3}
            onClick={() => openFilterView()}
          >
            FILTER
          </button>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  const closeFilter = () => {
    setFilterView(false);
  };

  const openEditView = (user) => {
    setSelectedReport(user);
    setAddReportingViewOpen(true);
  };

  const fetchRes = () => {
    let colVal = {};
    if (nameVal) {
      colVal['name'] = nameVal;
    }
    if (urlVal) {
      colVal['URL'] = urlVal;
    }
    let filters = {
      colVal
    };
    let columns = ['name', 'URL', 'id'];
    getReports(page, searchKeyword, filters, columns);
  };

  const getReports = useCallback(
    debounce(async (page, searchKeyword, filters, columns) => {
      try {
        setMuiLoader(true);
        const reportings = await API.get(
          `organizations/${subdomain}/embedded-reports`,
          {
            params: { page: page, search: searchKeyword, filters, columns }
          }
        );
        setPageCount(reportings.pages);
        setReportings(reportings.data);
      } catch (err) {
        onError(err);
      } finally {
        setMuiLoader(false);
      }
    }, DEBOUNCE_CONST),
    []
  );

  const addReport = async (data) => {
    try {
      setAPILoader(true);
      if (!selectedReport) {
        await API.post(`organizations/${subdomain}/embedded-reports`, data);
      } else {
        await API.put(
          `organizations/${subdomain}/embedded-reports/${selectedReport?.id}`,
          data
        );
      }
      toaster(
        'success',
        `Reporting has been ${
          selectedReport ? 'Updated' : 'Created'
        } successfully`
      );
      getReports();
      closeAddReportingView();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const addReportingModal = (
    <AddReportView
      key={4}
      selectedReport={selectedReport}
      addReport={addReport}
      open={addReportingViewOpen}
      handleClose={() => closeAddReportingView()}
    />
  );

  const closeAddReportingView = () => {
    setSelectedReport(null);
    setAddReportingViewOpen(false);
  };

  useEffect(() => {
    let title = 'Embedded Reports';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes();
  }, [page, searchKeyword, nameVal, urlVal]);

  const searchInput = (
    <div className="search-box">
      {searchKeyword ? (
        <span className="search-cancel" onClick={() => setSearchKeyword('')}>
          <MdCancel className="searchIcons" />
        </span>
      ) : null}
      <InputBase
        className={mainClass}
        id="search"
        label="Search"
        type="text"
        variant="outlined"
        value={searchKeyword}
        key={3}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          setPage(1);
        }}
      />
      <span className="search-btn">
        <MdSearch className="searchIcons" />
      </span>
    </div>
  );

  const addReporting = (
    <Tooltip title="Add Embedded Report">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className="float-right"
        color="secondary"
        size="small"
        onClick={() => {
          setAddReportingViewOpen(true);
          setSearchKeyword('');
        }}
      >
        Add Embedded Report
      </Button>
    </Tooltip>
  );

  //Input Objects for filters
  let nameObj = {
    type: 'text',
    className: 'textField',
    label: 'Name',
    field: 'name',
    value: nameVal ? nameVal : '',
    setNameVal: (e) => setNameVal(setReportingNameVal, e)
  };

  let urlObj = {
    type: 'text',
    className: 'textField',
    label: 'URL',
    field: 'URL',
    value: urlVal ? urlVal : '',
    setNameVal: (e) => setNameVal(setUrlVal, e)
  };

  //Custom modal for filter....
  const filterCols = [nameObj, urlObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        title={'Inventory'}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_REPORT_READ') ? searchInput : null,
    checkPermission(currentUser, 'OPS_REPORT_READ') ? filterModal : null,
    checkPermission(currentUser, 'OPS_REPORT_CREATE') ? addReporting : null,
    checkPermission(currentUser, 'OPS_REPORT_CREATE') ? addReportingModal : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Grid className="embeddedScroll-table">
          <Table aria-label="sticky table">
            <TableBody>
              <div className="grid-styles">
                <DataGrid
                  rows={reportings}
                  hiddenColumnModel={['id']}
                  columns={columnsNew}
                  components={{ Toolbar: CustomToolbar }}
                  pageSize={20}
                  rowsPerPageOptions={[6]}
                  className="parentDataTable"
                  loading={muiLoader}
                />
              </div>
            </TableBody>
          </Table>
        </Grid>
      </TableContainer>
      <Grid container justify="space-between" className="justify-content-right">
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            onChange={(newPage) => setPage(newPage)}
          />
        </Grid>
      </Grid>
      <MessageSnackbar showMessage={showMessage} />
    </Paper>
  );
}

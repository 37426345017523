import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import API from '../../../libs/axios';
import { isRequired } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  labelPadding: {
    paddingTop: 5
  },
  selectBox: {
    height: 55
  }
}));

export default function EditRoleView({
  open,
  handleClose,
  addRole,
  selectedUser,
  addPermissionToRole,
  removePermissionToRole,
  formErrors,
  currentUser
}) {
  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [roleId, setRoleId] = useState('');
  const [type, setType] = useState('');
  const [portal, setPortal] = useState('');
  const [allPermissions, setAllPermissions] = useState({});
  const [permissionMapperOld, setPermissionMapperOld] = useState({});
  const [permissionMapper, setPermissionMapper] = useState({});
  const [changedPermissions, setChangedPermissions] = useState([]);
  const classes = useStyles();
  const disableProps = ['Super', 'Admin', 'Permission'];

  useEffect(() => {
    if (selectedUser) {
      setName(selectedUser.name || '');
      setRoleId(selectedUser.roleId || '');
      setType(selectedUser.type || '');
      setPortal(selectedUser.portal || '');
      setAllPermissions({});
      setPermissionMapperOld({});
      setPermissionMapper({});
      setChangedPermissions([]);
      getAllPermissions();
    } else {
      setName('');
      setRoleId('');
      setType('');
      setPortal('');
      setAllPermissions({});
      setPermissionMapperOld({});
      setPermissionMapper({});
      setChangedPermissions([]);
      getAllPermissions();
    }
  }, [selectedUser]);

  const handleChange = (id, key, value) => {
    const mapped = { ...permissionMapper };
    mapped[key][id] = value;
    delete mapped[key][key];
    const isAll = Object.values(mapped[key]).every((val) => !!val);
    mapped[key][key] = isAll;
    setPermissionMapper(mapped);
    setChangedPermissions((changedPermissions) => [...changedPermissions, key]);
  };

  const handleChangeAll = (key, value) => {
    const mapped = { ...permissionMapper };
    Object.keys(mapped[key]).map((d) => {
      mapped[key][d] = value;
    });
    setPermissionMapper(mapped);
    setChangedPermissions((changedPermissions) => [...changedPermissions, key]);
  };

  const handleSubmit = () => {
    const newPermissions = [...new Set(changedPermissions)];

    const bindPermissions = [];
    const unBindPermissions = [];
    newPermissions.map((d) => {
      delete permissionMapper[d][d];
      Object.keys(permissionMapper[d]).map((data) => {
        if (permissionMapper[d][data] && !permissionMapperOld[d][data])
          bindPermissions.push(data);
        if (!permissionMapper[d][data] && permissionMapperOld[d][data])
          unBindPermissions.push(data);
      });
    });

    const newPermissionRole = {
      roleId,
      permissionIds: bindPermissions.map((permission) => parseInt(permission))
    };
    const removePermissionRole = {
      roleId,
      permissionIds: unBindPermissions.map((permission) => parseInt(permission))
    };

    const newRole = {
      roleId,
      name,
      type,
      allowedApps: portal
    };

    if (
      isRequired(name) &&
      isRequired(portal) &&
      isRequired(type) &&
      selectedUser.name != name
    ) {
      addRole(newRole);
    }
    if (
      isRequired(roleId) &&
      isRequired(bindPermissions) &&
      bindPermissions.length
    ) {
      addPermissionToRole(newPermissionRole);
    }
    if (
      isRequired(roleId) &&
      isRequired(unBindPermissions) &&
      unBindPermissions.length
    ) {
      removePermissionToRole(removePermissionRole);
    }
    handleClose();
  };

  const getAllPermissions = async () => {
    const attached = (await getAttchedPermissions()) || [];
    const unAttached = (await getUnAttchedPermissions()) || [];
    const allPermissions = attached.concat(unAttached);
    const permissionGroup = {};
    allPermissions.map((d) => {
      if (!d) {
        return;
      }

      if (d.name.indexOf('Vendor') !== -1) {
        return;
      }
      const name = d.name.split(' ')[0];
      if (permissionGroup[name]) {
        permissionGroup[name].push(d);
      } else {
        if (
          (currentUser?.Role?.name !== 'Super Admin' ||
            currentUser?.Role?.type !== 'SUPER_ADMIN') &&
          !disableProps.find((namee) => namee == name)
        ) {
          permissionGroup[name] = [d];
          // name
        } else if (
          currentUser?.Role?.name == 'Super Admin' ||
          currentUser?.Role?.type == 'SUPER_ADMIN'
        ) {
          permissionGroup[name] = [d];
        }
      }
    });
    setAllPermissions(permissionGroup);
    const objectPermissionMapper = {};

    Object.keys(permissionGroup).map((data) => {
      objectPermissionMapper[data] = {};
      permissionGroup[data].map((d) => {
        objectPermissionMapper[data][d.id] = d.checked;
      });
      const parent = permissionGroup[data].find((d) => d.checked === false);
      objectPermissionMapper[data][data] = !parent || ![parent].length;
    });
    const old = { ...objectPermissionMapper, old: true };
    setPermissionMapperOld(JSON.parse(JSON.stringify(old)));
    setPermissionMapper(objectPermissionMapper);
  };

  const getAttchedPermissions = async () => {
    if (!selectedUser || !selectedUser.roleId) return;
    const { permissionAccess } = await API.get(
      `permission-accesses/attached?roleId=${selectedUser.roleId}`
    );
    const attached = permissionAccess.length
      ? permissionAccess.map((d) => {
          if (d && d.Permission) return { ...d.Permission, checked: true };
        })
      : [];
    return attached;
  };

  const getUnAttchedPermissions = async () => {
    if (!selectedUser || !selectedUser.roleId) return;
    const { permissionAccess } = await API.get(
      `permission-accesses/un-attached?roleId=${selectedUser.roleId}`
    );
    const unAttached = permissionAccess.length
      ? permissionAccess.map((d) => {
          if (d && d.Permission && !d.Permission.deletedAt)
            return { ...d.Permission, checked: false };
        })
      : [];

    return unAttached;
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          onBackdropClick={() => {
            setValidation('');
          }}
        >
          <DialogTitle disableTypography className="title">
            {'Edit Role'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid
                container
                md={5}
                justify="space-around"
                style={{
                  position: 'fixed',
                  zIndex: '100',
                  background: 'white'
                }}
                className="roleContainer"
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onBlur={() => setValidation({ ...validation, name: true })}
                  />
                  {validation.name && !isRequired(name) ? (
                    <Typography color="error">Name is required!</Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Typography style={{ marginTop: '100px' }}>
                {Object.keys(allPermissions).length
                  ? Object.keys(allPermissions).map((data, index) => {
                      return (
                        <div key={index}>
                          <div style={{ fontWeight: 'bold' }}>
                            <FormControlLabel
                              key={data}
                              control={
                                <Checkbox
                                  disabled={disableProps.find(
                                    (namee) => namee == data
                                  )}
                                  checked={
                                    permissionMapper[data]
                                      ? permissionMapper[data][data]
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleChangeAll(data, e.target.checked)
                                  }
                                  name={data}
                                />
                              }
                              label={
                                <p style={{ fontWeight: 'bold' }}>{data}</p>
                              }
                            />
                          </div>
                          <div style={{ marginLeft: '30px' }}>
                            {allPermissions[data].map((d) => {
                              return (
                                <FormControlLabel
                                  key={d.id}
                                  control={
                                    <Checkbox
                                      disabled={disableProps.find(
                                        (namee) => namee == data
                                      )}
                                      checked={
                                        permissionMapper[data]
                                          ? permissionMapper[data][d.id]
                                          : false
                                      }
                                      onClick={(e) =>
                                        handleChange(
                                          d.id,
                                          data,
                                          e.target.checked
                                        )
                                      }
                                      name={d.id}
                                    />
                                  }
                                  label={d.name}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions className="updateBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  // setExplicitReRender(!explicitReRender);
                  setValidation('');
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>

            <Tooltip title="Update Role">
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                {'Update'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import {
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

import downloadData from '../../../assets/files/BulkSectionRelocationTemplate.xlsx';
import downloadIcon from '../../../assets/icons/downloadIcon.svg';
import BulkFileAttacher from '../../../components/BulkFileAttacher';
import useStyles from './sectionRelocationStyles';

const BulkUploadScreen = ({
  selectedCompany,
  setSelectedCompany,
  companies,
  searchCompanyState: { setSearchCompanyValue },
  bulkUpload,
  selectedFile,
  setSelectedFile,
  warehouses,
  setSelectedWarehouse,
  setWarehouseId,
  setSearchWarehouseValue,
  singleCompany,
  setSingleCompany,
  IS_MANUFACTURER
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={!IS_MANUFACTURER ? 6 : 12}
        lg={!IS_MANUFACTURER ? 6 : 12}
      >
        <FormControl fullWidth={true} variant="outlined">
          <Autocomplete
            id="selectedWarehouse"
            className="autoCompleteField"
            key={'warehouses'}
            options={warehouses}
            getOptionLabel={(selectedWarehouse) =>
              selectedWarehouse.businessWarehouseCode || selectedWarehouse.name
                ? `${selectedWarehouse.name} ${
                    selectedWarehouse?.businessWarehouseCode
                      ? `- ${selectedWarehouse.businessWarehouseCode}`
                      : ''
                  }`
                : ''
            }
            onChange={(event, newValue) => {
              if (newValue) {
                setWarehouseId(newValue.id);
                setSelectedWarehouse(newValue);
              } else {
                setWarehouseId(null);
                setSelectedWarehouse(null);
              }
            }}
            onInputChange={(event, newValue) => {
              setSearchWarehouseValue(newValue);
            }}
            onFocus={() => {
              setSearchWarehouseValue('');
            }}
            renderInput={(params) => (
              <TextField {...params} label="Warehouse" variant="outlined" />
            )}
          />
        </FormControl>
      </Grid>
      {IS_MANUFACTURER || (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth={true} variant="outlined">
            <Autocomplete
              className="autoCompleteField"
              id="selectedCompany"
              options={companies}
              getOptionLabel={(selectedCompany) => selectedCompany.name || ''}
              value={
                companies.length && selectedCompany
                  ? companies?.find((el) => el.id == selectedCompany.id) || null
                  : singleCompany || ''
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedCompany(newValue);
                  setSingleCompany(newValue);
                } else {
                  setSelectedCompany(null);
                  setSingleCompany({});
                }
              }}
              onInputChange={(event, newValue) => {
                setSearchCompanyValue(newValue);
              }}
              onFocus={() => {
                setSearchCompanyValue('');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <Typography className={classes.detailText}>
          <b>Download Template</b>
        </Typography>
        <div className="w-100px">
          <Tooltip title="Download Bulk Template">
            <div>
              <a href={downloadData} className="upload-image-btn display-block">
                <img
                  src={downloadIcon}
                  className="download-image"
                  alt="download-icon"
                />
              </a>
            </div>
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography className={classes.detailText}>
          <b>Upload</b>
          <span className={classes.opactiy5}>(xlsx, CSV)</span>
        </Typography>
        <BulkFileAttacher
          bulkUpload={bulkUpload}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </Grid>
    </Grid>
  );
};

export default BulkUploadScreen;

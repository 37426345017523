import React, { useContext } from 'react';
import { SharedContext } from '../../utils/common';

const FixedLayout = ({
  content,
  footer,
  header,
  modal,
  showFooter = true,
  showHeader = true
}) => {
  const { sidebar } = useContext(SharedContext);
  return (
    <div className="flex flex-col h-screen">
      {showHeader && (
        <header className="fixed top-0 left-0 right-0 h-11 border-solid border-0 border-b-[1px] border-platinum w-full bg-white flex items-center">
          <div className="relative w-full">{header}</div>
        </header>
      )}
      <main className="flex-grow overflow-y-auto bg-red fixed-layout-content mt-12 mb-12">
        {content}
      </main>
      {showFooter && (
        <footer
          className={`fixed flex items-center bottom-0 left-0 h-[60px] w-full pl-[40px] pr-[24px] bg-white border-solid border-0 border-t-[1px] border-platinum ${
            modal ? (!sidebar ? 'pl-[100px]' : 'pl-[16vw]') : ''
          }`}
        >
          {footer}
        </footer>
      )}
    </div>
  );
};

export default FixedLayout;

import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Tooltip
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import API from '../../../libs/axios';
import { removeDuplicateObjects } from '../../../utils/removeDuplicateObjects';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';
import AddNotificationUsersForm from './AddNotificationUsersForm';
import { SharedContext } from '../../../utils/common';

const useStyles = makeStyles(() => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px'
  },
  pageHeading: {
    fontWeight: 600
  },
  pageSubHeading: {
    fontWeight: 300
  },
  heading: {
    fontWeight: 'bolder'
  },
  shadedTableHeader: {
    backgroundColor: '#f0fbff'
  },
  tableHeadText: {
    background: '#f0fbff',
    fontWeight: 'bolder',
    fontSize: '12px'
  },
  subContainer: {
    boxSizing: 'border-box',
    padding: '20px 14px'
  }
}));

const AddViewNotificationUsers = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [notify, setNotify] = useState('');
  const [defaultUser, setDefaultUser] = useState(null);
  const { notificationUsersId } = useParams();
  const [users, setUsers] = useState([]);
  const [resUsers, setResUsers] = useState([]);
  const [notificationSubscription, setNotificationSubscription] = useState({
    companyId: null,
    notify: false,
    reportId: '',
    config: '',
    emailFrequency: '',
    emailTime: '',
    id: ''
  });
  const [userEmails, setUserEmails] = useState('');

  const { setAPILoader } = useContext(SharedContext);

  const getReport = async () => {
    if (notificationUsersId) {
      const response = await API.get(
        `notification-subscriptions/${notificationUsersId}`
      );

      if (response.data) {
        const { data } = response;
        data.config = JSON.parse(data.config);
        const {
          companyId,
          notify,
          reportId,
          config,
          emailFrequency,
          emailTime,
          id
        } = data;
        if (config) {
          setResUsers([...config]);
        }
        let subData = {
          companyId,
          notify,
          reportId,
          config,
          emailFrequency,
          emailTime,
          id
        };
        setNotify(notify);
        return setNotificationSubscription(subData);
      }
    }
  };

  useEffect(() => {
    getReport();
  }, [notificationUsersId]);

  useEffect(() => {
    getCompanies(searchText);
  }, [searchText]);

  const getCompanies = async (searchText) => {
    let filters = {
      colVal: {
        name: searchText
      },
      sortable: ''
    };

    const response = await API.get('companies', { params: { filters } });
    setCompanies(response.data.rows);
  };

  const addUsersHandler = async (values, formikProps, email, event) => {
    event && event.preventDefault();
    let totalUsers = [...resUsers, ...users];
    totalUsers = removeDuplicateObjects(totalUsers);

    if (email) {
      totalUsers = totalUsers.filter((user) => user.userEmail != email);
    }
    values = values ?? notificationSubscription;
    values.config = JSON.stringify(totalUsers);
    // const { companyId } = values;

    try {
      if (!notificationUsersId) {
        setAPILoader(true);
        var response = await API.post('notification-subscriptions', values);
        toaster('success', 'Subscription created successfully');
      } else {
        response = await API.put(
          `notification-subscriptions/${notificationUsersId}`,
          values
        );
        toaster('success', 'Subscription updated successfully');
      }
      totalUsers.length !== resUsers.length && setResUsers(totalUsers);
      setUsers([]);
      setUserEmails('');
      !notificationUsersId && navigate('/reporting/notification-console');
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }

    if (response.data) {
      setDefaultUser(null);
      setUsers([]);
      // getSubscriptionUsers(companyId, response.data.reportId);
    }
  };

  const deleteAction = async (email) => {
    //await setResUsers(resUsers.filter((user) => user.userEmail != email));
    await addUsersHandler(null, null, email, null);
  };

  return (
    <>
      <Grid container className={classes.parentContainer} spacing={3}>
        <Grid item xs={11}>
          <Typography variant="h3" className={classes.heading}>
            {notificationUsersId
              ? 'View Notification Users'
              : 'Add Notification Users'}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Cancel">
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/reporting/notification-console')}
            >
              Cancel
            </Button>
          </Tooltip>
        </Grid>
        {/* </Grid> */}
        <AddNotificationUsersForm
          notificationSubscription={notificationSubscription}
          companies={companies}
          notificationState={{ notify, setNotify }}
          searchState={{ setSearchText }}
          defaultUserState={{ defaultUser }}
          usersState={{ setUsers }}
          userEmailsState={{ userEmails, setUserEmails }}
          addUsersHandler={addUsersHandler}
          notificationUsersId={notificationUsersId}
          resUsers={resUsers}
          usersTableProps={{
            resUsers,
            deleteAction,
            classes
          }}
        />
      </Grid>
      {/* {resUsers?.length && notificationUsersId ? (
                <Grid item xs={12}>
                    <UsersTable
                        resUsers={resUsers}
                        classes={classes}
                        deleteAction={deleteAction}
                    />
                </Grid>
            ) : null} */}
    </>
  );
};

export default AddViewNotificationUsers;

/*eslint-disable*/
import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Checkbox from '@mui/material/Checkbox';
import API from '../../libs/axios';
import FileDownload from 'js-file-download';
import clsx from 'clsx';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router';
import { DEBOUNCE_CONST } from '../../Config';
import {
  getInventory,
  holdInventory,
  removeHoldFromInventory
} from '../../apis/wms';
import SearchBar from '../../atomicComponents/SearchBar';
import {
  InventoryManagementTabsMapping,
  ORGANIZATION_TYPES,
  OriginCell
} from '../../constants';
import Button from '../../core-components/atoms/Button';
import {
  SharedContext,
  decimalDisplay,
  formatNumberToKAndM,
  productLabelFormat
} from '../../utils/common';
import {
  getConditionUpdateSummary,
  getInventoryAlertMessage,
  getUpdatedStockCondition,
  isBatchUpdated,
  isDamagedBatch,
  isUsableBatch,
  removeBatchOfId
} from '../../utils/inventoryDetails';
import InventoryDetailStats from './components/InventoryDetailStats';
import useStyles from './inventoryStyles';
import DownloadIcon from '../../assets/icons/downloadOutlineIcon.svg';
import AlertIcon from '../../assets/icons/error.svg';
import WarningIcon from '../../assets/icons/errorOutlinedIcon.svg';
import PromoPopup from './components/PromoPopup';
import Popup from '../../core-components/atoms/Popup';
import ConditionConfirmation from './components/ConditionConfirm';
import { advancedToaster, toaster } from '../../utils/toaster';
import { checkPermission } from '../../utils/auth';
import Filters from './components/Filters';
import StatusIcon from '../../assets/icons/list-status.svg';
import Batch from '../../assets/icons/Batch.svg';
import Location from '../../assets/icons/location.svg';
import { onError } from '../../libs/errorLib';
import { ActionType, PopoverOrigin, Source } from './constants';
import { ArrowDropDown, ChevronLeft } from '@material-ui/icons';
import PromoConfirmation from './components/RemovePromoConfirm';
import OptionsMenu from './components/Options';
import StockAdjustmentPopover from './stockAdjustment/StockAdjustmentPopover';
import StockAdjustment from './stockAdjustment';
import StockCondition from './stockCondition';
import SectionRelocation from './sectionRelocation';
import SectionRelocationPopover from './sectionRelocation/SectionRelocationPopover';
import ReleaseInventoryPopover from './release/ReleaseInventoryPopover';
import ReleaseInventory from './release';
import HoldInventory from './hold';
import StockConditionPopover from './stockCondition/stockConditionPopover';
import HoldInventoryPopover from './hold/HoldInventoryPopover';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import InventoryHistory from './InventoryHistoryView';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import CustomTextField from '../../core-components/atoms/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import EditBatch from './editBatch';

const InventoryDetailsView = () => {
  const { uid } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const {
    setCurrentPageTitle,
    currentUser,
    organizationType,
    setAPILoader,
    settings
  } = useContext(SharedContext);
  const [searchKeyword] = useState('');
  const [sortable] = useState('');
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [usableBatches, setUsableBatches] = useState([]);
  const [damagedBatches, setDamagedBatches] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [showPromoPopup, setShowPromoPopup] = useState(false);
  const [recoverableQuantity, setRecoverableQuantity] = useState(null);
  const [unsellableQuantity, setUnsellableQuantity] = useState(null);
  const [usableQuantity, setUsableQuantity] = useState(null);
  const [uom, setUom] = useState()
  const [uoms, setUoms] = useState([])
  const [showReleaseHoldPopup, setShowReleaseHoldPopup] = useState(false);
  const [showStockAdjustmentPopup, setShowStockAdjustmentPopup] =
    useState(false);
  const [lastConditionChange, setLastConditionChange] = useState(null);
  const [areAnyFilterActive, setAreAnyFilterActive] = useState(false);
  const [removeQuantity, setRemoveQuantity] = useState(0);
  const [, setOptionsClick] = useState(false);
  const [showRemovePromoPopup, setShowRemovePromoPopup] = useState(false);
  const [stockAdjusmtentViewOpen, setStockAdjustmentViewOpen] = useState(false);
  const [stockConditionViewOpen, setStockConditionViewOpen] = useState(false);
  const [holdInventoryViewOpen, setHoldInventoryViewOpen] = useState(false);
  const [releaseInventoryViewOpen, setReleaseInventoryViewOpen] =
    useState(false);

  const [inventoryUpdates, setInventoryUpdates] = useState({
    usable: false,
    damaged: false
  });
  useEffect(() => {
    const hasPromoQuantity = usableBatches?.some(
      (batch) => batch.promoQuantity > 0
    );
    setPromotQuantityColumn(hasPromoQuantity);
  }, [usableBatches]);
  const [showConditionConfirmPopup, setShowConditionConfirmPopup] =
    useState(false);
  const [selectedInventoryDetails, setSelectedInventoryDetails] =
    useState(null);
  const [tabValue, setTabValue] = useState(
    InventoryManagementTabsMapping.USABLE
  );
  const [promoQuantityColumn, setPromotQuantityColumn] = useState('');

  const filterInitialState = {
    Status: [],
    Batches: [],
    Locations: []
  };
  const [advancedfilters, setFilters] = useState(filterInitialState);

  const inventoryStatus = {
    DAMAGED: 'Damaged',
    AVAILABLE: 'Available',
    HOLD: 'Hold',
    RESERVED: 'Reserved',
    NEAR_EXPIRY: 'Near Expiry',
    EXPIRED: 'Expired'
  };
  let filterOptions = [
    {
      label: 'Status',
      options: Object.keys(inventoryStatus).map((key, id) => ({
        value: key,
        label: inventoryStatus[key],
        id
      })),
      icon: StatusIcon
    },
    ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
      ? [
        {
          label: 'Batch(es)',
          icon: Batch
        }
      ]
      : []),
    {
      label: 'Location(s)',
      icon: Location
    }
  ];

  const setFilter = (key, value, type, filterType) => {
    let currentFilters;
    if (!filterType) {
      currentFilters = { ...advancedfilters };
    }
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    if (!filterType) {
      setFilters(currentFilters);
    }
  };
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [selectedBatchesForHold, setSelectedBatchesForHold] = useState([]);
  const [selectedBatchesForRelease, setSelectedBatchesForRelease] = useState(
    []
  );
  const [, setSelectedHoldQuantity] = useState(0);
  const [, setSelectedReleaseQuantity] = useState(0);
  const [allBatchesSelected, setAllBatchesSelection] = useState(false);
  const [sectionRelocationViewOpen, setSectionRelocationViewOpen] =
    useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const usableColumnNames = [
    ...[...(isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) ? ['Location'] : [])],
    ...[
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Batch No, MFG & Exp.']
        : [])
    ],
    'Weight',
    'mrp (pack)',
    'Total qty',
    'Available',
    'On Hold',
    'Reserved',
    'Promo QTY & Code'
  ];

  const damagedColumnNames = [
    ...[...(isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) ? ['Location'] : [])],
    ...[
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Batch No, MFG & Exp.']
        : [])
    ],
    'Damaged'
  ];

  const tableCellClass = 'py-2 h-12 pl-0 pr-2';
  const cellValue = 'table-cell-trucate  text-normal';
  const cellSubValue = clsx(cellValue, 'text-[10px]');
  const topValue = clsx(cellValue, 'mb-[2px]', 'text-xs');
  const digitValue = clsx(cellValue, 'mt-[2px]', 'text-xs');

  useEffect(() => {
    let title = (
      <div className="flex gap-1 items-center ">
        {selectedInventory?.Product && (
          <ChevronLeft
            style={{ color: '#9DA3B3' }}
            onClick={() => navigate('/routing/inventory')}
          />
        )}
        <span className="text-lg font-bold">
          {selectedInventory?.Product
            ? productLabelFormat(selectedInventory.Product)
            : '-'}
        </span>
        <span className="text-xs text-dark600 font-normal">
          {selectedInventory?.Product?.Brand?.name
            ? `| ${selectedInventory?.Product?.Brand?.name}`
            : ''}
        </span>
      </div>
    );
    setCurrentPageTitle(title);
  }, [selectedInventory]);

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const { refetch, isFetching } = useQuery(
    ['inventories', uid, searchKeyword, advancedfilters],
    () => {
      let colVal = {};
      let filters = {
        colVal,
        sortable
      };

      let columns = [
        '$InventoryDetail.WarehouseHall.name$',
        '$InventoryDetail.promoName$',
        '$InventoryDetail.batchNumber$',
        '$InventoryDetail.manufacturingDate$',
        '$InventoryDetail.expiryDate$',
        '$InventoryDetail.Pallet.palletId$'
      ];
      let advanceFilters = Object.entries(advancedfilters).map(
        ([key, values]) => ({
          [key]: values.flat().map((item) => item.value || item)
        })
      );
      return getInventory({
        id: uid,
        searchKeyword,
        filters,
        columns,
        advanceFilters
      });
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data?.data) {
          resetSelections();
          setSelectedInventory(data?.data);
          if (!uom) setUom({ ...data.data?.Product?.UOM, conversionFactor: 1, primary: true })
          setUoms([{ ...data.data?.Product?.UOM, conversionFactor: 1, primary: true }, ...(data.data?.Product?.ProductUOMs?.map(u => ({ name: u?.UOM?.name, conversionFactor: u.conversionFactor, primary: false })) || [])])
          setUsableBatches(
            data?.data?.InventoryDetail?.filter((invDetail) => {
              return isUsableBatch(invDetail);
            })
          );
          setDamagedBatches(
            data?.data?.InventoryDetail.filter((invDetail) => {
              return isDamagedBatch(invDetail);
            })
          );
        } else {
          // setSelectedInventory([]);
          setUsableBatches([]);
          setDamagedBatches([]);
        }
      }
    }
  );

  const debouncedRefetch = useCallback(
    debounce(async () => {
      refetch();
    }, DEBOUNCE_CONST),
    [refetch]
  );

  useEffect(() => {
    return () => {
      debouncedRefetch.cancel();
    };
  }, [debouncedRefetch]);

  const handleDebouncedRefetch = () => {
    debouncedRefetch();
  };

  const ToastBody = ({ text, closeToast, toastProps }) => (
    <div className="flex w-full items-center justify-between">
      <p className="text-sm text-white">{text}</p>
      <Button
        className="p-2 ml-2 h-8 bg-transparent text-white hover:bg-jade500"
        overrideSize={true}
        labelClass="font-medium text-xs text-white"
        variant="transparent-text"
        label="Got it"
        onClick={closeToast}
      />
    </div>
  );

  const submitStockCondition = (
    updatedBatch1,
    updatedBatch2,
    inventoryDetailId
  ) => {
    const shouldRemoveBatch = !updatedBatch1 || !updatedBatch2;
    const inventoryDetail = shouldRemoveBatch
      ? removeBatchOfId({
        id: inventoryDetailId,
        inventoryBatches: selectedInventory.InventoryDetail
      })
      : selectedInventory.InventoryDetail;
    const updatedInventoryOldBatch = updatedBatch1
      ? getUpdatedStockCondition({
        batch: updatedBatch1,
        inventoryBatches: inventoryDetail,
        isBatchRemoved: !updatedBatch2
      })
      : inventoryDetail;
    const updatedInventory = updatedBatch2
      ? getUpdatedStockCondition({
        batch: updatedBatch2,
        inventoryBatches: updatedInventoryOldBatch,
        isBatchRemoved: !updatedBatch1
      })
      : updatedInventoryOldBatch;

    const statsUpdatedInventory = recalculateInventoryStats({
      ...selectedInventory,
      InventoryDetail: updatedInventory
    });

    setSelectedInventory(statsUpdatedInventory);
    setUsableBatches(
      statsUpdatedInventory?.InventoryDetail?.filter((invDetail) => {
        return isUsableBatch(invDetail);
      })
    );
    setDamagedBatches(
      statsUpdatedInventory?.InventoryDetail.filter((invDetail) => {
        return isDamagedBatch(invDetail);
      })
    );
    setLastConditionChange(statsUpdatedInventory);
    setShowConditionConfirmPopup(false);
    const message = getConditionUpdateSummary({
      type: tabValue,
      usableQuantity,
      recoverableQuantity,
      unsellableQuantity,
      inventoryDetail: selectedInventoryDetails
    });
    setUsableQuantity();
    setUnsellableQuantity();
    setRecoverableQuantity();
    advancedToaster('success', <ToastBody text={message} />, {
      position: 'bottom-center',
      theme: 'colored',
      closeButton: false,
      className: 'bg-jade700 w-[344px]'
    });

    setTimeout(() => {
      setLastConditionChange(null);
    }, 5000);
  };

  const recalculateInventoryStats = (inventory) => {
    const stats = inventory.InventoryDetail.reduce(
      (acc, detail) => {
        return {
          ...acc,
          recoverableDamageQuantity: parseFloat(
            (
              acc.recoverableDamageQuantity + detail.recoverableDamageQuantity
            ).toFixed(3)
          ),
          unsellableDamageQuantity: parseFloat(
            (
              acc.unsellableDamageQuantity + detail.unsellableDamageQuantity
            ).toFixed(3)
          ),
          goodQuantity: parseFloat(
            (acc.goodQuantity + detail.goodQuantity).toFixed(3)
          ),
          availableQuantity: parseFloat(
            (acc.availableQuantity + detail.availableQuantity).toFixed(3)
          )
        };
      },
      {
        ...inventory,
        recoverableDamageQuantity: 0,
        unsellableDamageQuantity: 0,
        goodQuantity: 0,
        availableQuantity: 0
      }
    );

    // Returning the calculated statistics
    return stats;
  };
  useEffect(() => {
    const res = Object.values(advancedfilters).some(
      (filter) => filter.length > 0
    );
    setAreAnyFilterActive(res);
  }, [advancedfilters]);

  useEffect(() => {
    if (!selectedInventory || !selectedInventory.InventoryDetail) {
      return inventoryUpdates;
    }

    const updatedInventory = selectedInventory.InventoryDetail.reduce(
      (acc, invDetail) => {
        const hasUsableUpdates =
          isUsableBatch(invDetail) && invDetail.updatedFrom;
        const hasDamagedUpdates =
          isDamagedBatch(invDetail) && invDetail.updatedFrom;

        return {
          usable:
            tabValue === InventoryManagementTabsMapping.DAMAGED
              ? acc.usable || hasUsableUpdates
              : false,
          damaged:
            tabValue === InventoryManagementTabsMapping.USABLE
              ? acc.damaged || hasDamagedUpdates
              : false
        };
      },
      { ...inventoryUpdates }
    );
    setInventoryUpdates(updatedInventory);
  }, [selectedInventory]);

  useEffect(() => {
    setInventoryUpdates({ usable: false, damaged: false });
    resetSelections();
  }, [tabValue]);

  const handleCheckboxChange = (id, batch) => {
    const selectedIndex = selectedBatches.indexOf(id);
    let newSelected = [...selectedBatches];
    let newSelectedForHold = [...selectedBatchesForHold];
    let newSelectedForRelease = [...selectedBatchesForRelease];

    if (selectedIndex === -1) {
      if (batch?.availableQuantity > 0) {
        newSelectedForHold = [...newSelectedForHold, id];
      }
      if (batch?.holdQuantity > 0) {
        newSelectedForRelease = [...newSelectedForRelease, id];
      }
      newSelected = [...newSelected, id];
    } else {
      newSelected.splice(selectedIndex, 1);
      if (batch?.availableQuantity > 0) {
        const indexForHold = newSelectedForHold.indexOf(id);
        if (indexForHold !== -1) {
          newSelectedForHold.splice(indexForHold, 1);
        }
      }
      if (batch?.holdQuantity > 0) {
        const indexForRelease = newSelectedForRelease.indexOf(id);
        if (indexForRelease !== -1) {
          newSelectedForRelease.splice(indexForRelease, 1);
        }
      }
    }
    setSelectedBatches(newSelected);
    setSelectedBatchesForHold(newSelectedForHold);
    setSelectedBatchesForRelease(newSelectedForRelease);
    calculateSelectedQuantities(newSelectedForHold, newSelectedForRelease);
  };

  useEffect(() => {
    if (!allBatchesSelected) {
      setSelectedBatches([]);
      setSelectedBatchesForHold([]);
      setSelectedBatchesForRelease([]);
      calculateSelectedQuantities([], []);
    } else {
      if (tabValue === InventoryManagementTabsMapping.USABLE) {
        const batchesForHold = usableBatches
          .filter((batch) => batch?.availableQuantity > 0)
          .map((batch) => batch?.id);
        const batchesForRelease = usableBatches
          .filter((batch) => batch?.holdQuantity > 0)
          .map((batch) => batch?.id);
        setSelectedBatches(usableBatches.map((batch) => batch?.id));
        setSelectedBatchesForHold(batchesForHold);
        setSelectedBatchesForRelease(batchesForRelease);
        calculateSelectedQuantities(batchesForHold, batchesForRelease);
      } else if (tabValue === InventoryManagementTabsMapping.DAMAGED) {
        const batchesForHold = damagedBatches
          .filter((batch) => batch?.availableQuantity > 0)
          .map((batch) => batch?.id);
        const batchesForRelease = damagedBatches
          .filter((batch) => batch?.holdQuantity > 0)
          .map((batch) => batch?.id);
        setSelectedBatches(damagedBatches.map((batch) => batch?.id));
        setSelectedBatchesForHold(batchesForHold);
        setSelectedBatchesForRelease(batchesForRelease);
        calculateSelectedQuantities(batchesForHold, batchesForRelease);
      }
    }
  }, [allBatchesSelected, tabValue]);

  const calculateSelectedQuantities = (
    newSelectedForHold,
    newSelectedForRelease
  ) => {
    let totalAvailable = 0;
    let totaHold = 0;

    newSelectedForHold.forEach((id) => {
      const inventory = selectedInventory?.InventoryDetail?.find(
        (item) => item.id === id
      );
      if (inventory) {
        totalAvailable += inventory?.availableQuantity;
      }
    });
    newSelectedForRelease.forEach((id) => {
      const inventory = selectedInventory?.InventoryDetail?.find(
        (item) => item.id === id
      );
      if (inventory) {
        totaHold += inventory?.holdQuantity;
      }
    });

    setSelectedHoldQuantity(totalAvailable);
    setSelectedReleaseQuantity(totaHold);
  };

  const { isLoading: loadingHoldInventory, mutate: mutateHoldInventory } =
    useMutation(holdInventory, {
      onSuccess: () => {
        refetch();
        setAllBatchesSelection(false);
        advancedToaster(
          'success',
          <ToastBody text={'Inventory put on hold'} />,
          {
            position: 'bottom-center',
            theme: 'colored',
            closeButton: false,
            className: 'bg-jade700 w-[344px]'
          }
        );
      },
      onError: (error) => {
        onError({ response: error.response });
      }
    });

  const holdSelectedBatches = () => {
    if (selectedBatches.length > 0) {
      mutateHoldInventory({
        inventoryDetailIds: selectedBatchesForHold
      });
    }
  };

  const {
    isLoading: loadingRemoveHoldInventory,
    mutate: mutateRemoveHoldInventory
  } = useMutation(removeHoldFromInventory, {
    onSuccess: () => {
      refetch();
      setAllBatchesSelection(false);
      advancedToaster(
        'success',
        <ToastBody text={'Hold removed from Inventories'} />,
        {
          position: 'bottom-center',
          theme: 'colored',
          closeButton: false,
          className: 'bg-jade700 w-[344px]'
        }
      );
    },
    onError: (error) => {
      onError({ response: error.response });
    }
  });

  const removeHoldFromSelectedBatches = () => {
    if (selectedBatches.length > 0) {
      mutateRemoveHoldInventory({
        inventoryDetailIds: selectedBatchesForRelease
      });
    }
  };

  const resetSelections = () => {
    setSelectedBatches([]);
    setSelectedBatchesForHold([]);
    setSelectedBatchesForRelease([]);
    setSelectedHoldQuantity(0);
    setSelectedReleaseQuantity(0);
  };

  const exportToExcel = async () => {
    let columns = [
      '$Product.name$',
      '$Product.description$',
      ...(organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
        ? ['$Company.name$']
        : []),
      '$Warehouse.name$',
      '$InventoryDetail.WarehouseHall.name$',
      '$InventoryDetail.WarehouseHall.Aisle.name$',
      '$InventoryDetail.WarehouseHall.Bay.name$',
      '$Product.isActive$',
      '$InventoryDetail.availableQuantity$',
      '$InventoryDetail.recoverableDamageQuantity$',
      '$InventoryDetail.holdQuantity$',
      '$InventoryDetail.reservedQuantity$',
      '$InventoryDetail.batchNumber$',
      '$InventoryDetail.manufacturingDate$',
      '$InventoryDetail.expiryDate$',
      '$InventoryDetail.promoName$',
      '$InventoryDetail.promoQuantity$',
      '$InventoryDetail.goodQuantity$'
    ];
    let colVal = {};

    let filters = {
      colVal,
      sortable
    };
    let inventoryType = tabValue === '1' ? 'GOOD' : 'DAMAGED';
    let advanceFilters = Object.entries(advancedfilters).map(
      ([key, values]) => ({
        [key]: values.flat().map((item) => item.value || item)
      })
    );
    try {
      const inventories = await API.get(`inventories/export/${uid}`, {
        responseType: 'blob',
        params: {
          search: searchKeyword,
          filters,
          columns,
          inventoryType,
          advanceFilters
        }
      });
      FileDownload(
        inventories,
        `Inventory ${uid} (${inventoryType.charAt(0) + inventoryType.slice(1).toLowerCase()
        }) ${moment().format('DD-MM-yyyy')}.xlsx`
      );
    } catch (err) {
      onError(err);
    }
  };

  const createStockAdjustment = async (batches) => {
    setAPILoader(true);
    const payload = [];
    for (let i = 0; i < batches.length; i++) {
      let index = payload.findIndex(
        (b) => b.inventoryId == batches[i].inventoryId
      );
      if (batches[i].quantity == 0) {
        continue;
      }
      if (index !== -1) {
        payload[index].batches.push({
          stockCondition: batches[i].stockCondition,
          quantity: batches[i].quantity,
          id: batches[i].id,
          type: batches[i].type?.id
        });
      } else {
        payload.push({
          batches: [
            {
              stockCondition: batches[i].stockCondition,
              quantity: batches[i].quantity,
              id: batches[i].id,
              type: batches[i].type?.id
            }
          ],
          inventoryId: batches[i].inventoryId
        });
      }
    }

    const url = window.location.href.replace(
      'create',
      'view/stockAdjustmentId'
    );
    await API.post(`stock-Adjustments`, {
      products: payload,
      hostUrl: url
    });
    await refetch();
    toaster('success', 'Stock Adjustment Performed Successfully');
    setAPILoader(false);
  };

  const stockRelocation = async (batches) => {
    setAPILoader(true);
    try {
      let payload = [];
      for (let i = 0; i < batches.length; i++) {
        if (batches[i].qty && batches[i].destinationSectionId)
          payload.push({
            sourceSectionId: selectedInventory?.InventoryDetail?.[0]?.hallId,
            destinationSectionId: batches[i]?.destinationSectionId,
            relocateQuantity: batches[i].qty,
            batchName: selectedInventory?.InventoryDetail?.[0]?.batchName,
            inventoryId: selectedInventory?.id,
            warehouseId: selectedInventory?.Warehouse?.id,
            productId: selectedInventory?.Product?.id,
            inventoryDetailId: selectedInventoryDetails.id,
            availableQuantity: selectedInventoryDetails.availableQuantity
          });
      }
      await API.post(`warehouses/relocations`, payload);
      await refetch();
      toaster('success', 'Stock Relocation Performed Successfully!');
    } catch (e) {
      console.error(e);
    } finally {
      setAPILoader(false);
    }
  };

  const removePromo = async () => {
    const promo = {
      promoName: selectedInventoryDetails?.promoName,
      promoQuantity: selectedInventoryDetails?.promoQuantity,
      removeQuantity: removeQuantity
    };
    try {
      setAPILoader(true);
      await API.post(`promo/remove/${selectedInventoryDetails?.id}`, promo);
      advancedToaster(
        'success',
        <ToastBody text={'Promo has been removed'} />,
        {
          position: 'bottom-center',
          theme: 'colored',
          closeButton: false,
          className: 'bg-jade700 w-[344px]'
        }
      );
      refetch();
      setShowRemovePromoPopup(false);
      setRemoveQuantity();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const applyRelease = async (batches) => {
    setAPILoader(true);
    try {
      await API.put(`inventories/hold/remove`, batches);
      await refetch();
      toaster('success', 'Inventory Released Successfully');
    } catch (e) {
      console.error(e);
    } finally {
      setAPILoader(false);
    }
  };

  const applyHold = async (batches) => {
    setAPILoader(true);
    try {
      await API.put(`inventories/hold`, batches);
      await refetch();
      toaster('success', 'Hold Applied Successfully');
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const applyStockConditioning = async (payload) => {
    try {
      await API.put('inventories/update-stock-condition', payload);
      await refetch();
      toaster('success', 'Stock Conditioning Performed Successfully');
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const batchOptions = [
    ...(checkPermission(currentUser, 'OPS_BATCH_UPDATE') &&
    selectedInventory?.Product?.batchEnabled &&
    tabValue === InventoryManagementTabsMapping.USABLE &&
    selectedInventoryDetails?.reservedQuantity === 0
      ? [
          {
            label: (
              <EditBatch
                selectedInventoryDetails={selectedInventoryDetails}
                currentUser={currentUser}
                product={selectedInventory?.Product}
                refetch={refetch}
                closeCallback={() => {
                  setOptionsClick(false);
                }}
              />
            )
          }
        ]
      : []),
    ...(checkPermission(currentUser, 'OPS_HOLD_APPLY') &&
      isFeatureEnabled(FLAGS.INVENTORY_HOLD) &&
      selectedInventoryDetails?.availableQuantity > 0 &&
      tabValue == InventoryManagementTabsMapping.USABLE
      ? [
        {
          label: (
            <HoldInventoryPopover
              available={selectedInventoryDetails?.availableQuantity}
              uoms={[
                {
                  ...selectedInventory?.Product?.UOM,
                  primary: true,
                  conversionFactor: 1
                },
                ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                  name: uom?.UOM?.name,
                  conversionFactor: uom?.UOM?.conversionFactor,
                  primary: false
                }))
              ]}
              actionCallback={async (holdQty, comment) => {
                let payload = [
                  {
                    inventoryId: selectedInventory?.id,
                    inventoryDetailId: selectedInventoryDetails?.id,
                    quantity: holdQty,
                    comment
                  }
                ];
                await applyHold(payload);
              }}
            />
          )
        }
      ]
      : []),
    ...((checkPermission(currentUser, 'OPS_HOLD_APPLY') &&
      isFeatureEnabled(FLAGS.STOCK_CONDITION_MANAGEMENT) &&
      selectedInventoryDetails?.availableQuantity > 0 &&
      InventoryManagementTabsMapping.USABLE == tabValue) ||
      (selectedInventoryDetails?.recoverableDamageQuantity > 0 &&
        tabValue == InventoryManagementTabsMapping.DAMAGED)
      ? [
        {
          label: (
            <StockConditionPopover
              available={selectedInventoryDetails?.availableQuantity}
              damaged={selectedInventoryDetails?.recoverableDamageQuantity}
              stockCondition={
                InventoryManagementTabsMapping.USABLE == tabValue
                  ? 'GOOD'
                  : 'RECOVERABLE'
              }
              uoms={[
                {
                  ...selectedInventory?.Product?.UOM,
                  primary: true,
                  conversionFactor: 1
                },
                ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                  id: uom?.UOM?.id,
                  name: uom?.UOM?.name,
                  conversionFactor: uom?.conversionFactor,
                  primary: false
                }))
              ]}
              actionCallback={async (
                stockCondition,
                goodQuantity,
                damagedQuantity
              ) => {
                let payload = [];
                if (stockCondition == 'RECOVERABLE') {
                  payload.push({
                    inventoryId: selectedInventory.id,
                    inventoryDetailId: selectedInventoryDetails?.id,
                    goodQuantity: Number(goodQuantity),
                    source: {
                      damageQuantity: Number(damagedQuantity)
                    }
                  });
                } else {
                  payload.push({
                    inventoryId: selectedInventory.id,
                    inventoryDetailId:
                      selectedInventory?.InventoryDetail?.[0]?.id,
                    damageQuantity: Number(damagedQuantity),
                    source: {
                      goodQuantity: Number(goodQuantity)
                    }
                  });
                }
                await applyStockConditioning(payload);
              }}
            />
          )
        }
      ]
      : []),
    ...((checkPermission(currentUser, 'OPS_HOLD_APPLY') &&
      isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) &&
      selectedInventoryDetails?.availableQuantity > 0 &&
      InventoryManagementTabsMapping.USABLE == tabValue) ||
      (selectedInventoryDetails?.recoverableDamageQuantity > 0 &&
        tabValue == InventoryManagementTabsMapping.DAMAGED)
      ? [
        {
          label: (
            <StockAdjustmentPopover
              origin={PopoverOrigin.DROPDOWN}
              available={selectedInventoryDetails?.availableQuantity}
              damaged={selectedInventoryDetails?.recoverableDamageQuantity}
              stockCondition={
                tabValue == InventoryManagementTabsMapping.DAMAGED
                  ? 'RECOVERABLE'
                  : 'GOOD'
              }
              uoms={[
                {
                  ...selectedInventory?.Product?.UOM,
                  primary: true,
                  conversionFactor: 1
                },
                ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                  id: uom?.UOM?.id,
                  name: uom?.UOM?.name,
                  conversionFactor: uom?.conversionFactor,
                  primary: false
                }))
              ]}
              actionCallback={async (reason, qty, qtyType) => {
                let payload = [
                  {
                    inventoryId: selectedInventoryDetails?.inventoryId,
                    id: selectedInventoryDetails?.id,
                    stockCondition: qtyType,
                    type: reason,
                    quantity: qty
                  }
                ];
                await createStockAdjustment(payload);
              }}
            />
          )
        }
      ]
      : []),
    ...(checkPermission(currentUser, 'OPS_HOLD_APPLY') &&
      isFeatureEnabled(FLAGS.STOCK_RELOCATION) &&
      selectedInventoryDetails?.availableQuantity > 0 &&
      tabValue !== InventoryManagementTabsMapping.DAMAGED
      ? [
        {
          label: (
            <SectionRelocationPopover
              availableQuantity={selectedInventoryDetails?.availableQuantity}
              warehouseId={selectedInventory?.Warehouse?.id}
              sourceLocation={selectedInventoryDetails?.hallId}
              uoms={[
                {
                  ...selectedInventory?.Product?.UOM,
                  primary: true,
                  conversionFactor: 1
                },
                ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                  id: uom?.UOM?.id,
                  name: uom?.UOM?.name,
                  conversionFactor: uom?.conversionFactor,
                  primary: false
                }))
              ]}
              actionCallback={async (batches) => {
                await stockRelocation(batches);
              }}
            />
          )
        }
      ]
      : []),
    ...(checkPermission(currentUser, 'OPS_HOLD_APPLY') &&
      selectedInventoryDetails?.holdQuantity > 0 &&
      tabValue !== InventoryManagementTabsMapping.DAMAGED
      ? [
        {
          label: (
            <ReleaseInventoryPopover
              holdQuantity={selectedInventoryDetails?.holdQuantity}
              uoms={[
                {
                  ...selectedInventory?.Product?.UOM,
                  primary: true,
                  conversionFactor: 1
                },
                ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                  id: uom?.UOM?.id,
                  name: uom?.UOM?.name,
                  conversionFactor: uom?.conversionFactor,
                  primary: false
                }))
              ]}
              actionCallback={async (qty, comment) => {
                let payload = [
                  {
                    inventoryId: selectedInventoryDetails?.inventoryId,
                    inventoryDetailId: selectedInventoryDetails?.id,
                    quantity: qty,
                    comment
                  }
                ];
                await applyRelease(payload);
              }}
            />
          )
        }
      ]
      : []),
    ...(checkPermission(
      currentUser,
      selectedInventoryDetails?.promoName
        ? 'OPS_PROMO_UPDATE'
        : 'OPS_PROMO_CREATE'
    ) && tabValue !== InventoryManagementTabsMapping.DAMAGED
      ? [
        {
          label: (
            <PromoPopup
              open={showPromoPopup}
              selectedInventoryDetails={selectedInventoryDetails}
              refetch={refetch}
              closeCallback={() => setShowPromoPopup(false)}
              enterCallBack={() => {
                setSelectedInventoryDetails(selectedInventoryDetails);
                setShowPromoPopup(true);
              }}
              removeQuantity={removeQuantity}
              setRemoveQuantity={setRemoveQuantity}
              setConfirmationPopup={setShowRemovePromoPopup}
              currentUser={currentUser}
              editMode={Boolean(selectedInventoryDetails?.promoName)}
            />
          )
        }
      ]
      : [])
  ];

  return (
    <>
      <Popup
        open={showConditionConfirmPopup}
        setOpen={setShowConditionConfirmPopup}
        content={
          <ConditionConfirmation
            type={tabValue}
            recoverableQuantity={recoverableQuantity}
            unsellableQuantity={unsellableQuantity}
            usableQuantity={usableQuantity}
            selectedInventoryDetails={selectedInventoryDetails}
            setOpen={setShowConditionConfirmPopup}
            handleSubmit={(updatedBatch1, updatedBatch2) =>
              submitStockCondition(
                updatedBatch1,
                updatedBatch2,
                selectedInventory?.id
              )
            }
          />
        }
        title="Are You Sure?"
        noLineBreak={true}
        headerClass="p-8 pb-4"
        closeIconSize={24}
        titleClass="text-primaryBlue font-semibold"
        paperProps={{
          style: {
            borderRadius: '8px',
            height: '276px',
            maxWidth: '613px',
            width: '613px'
          }
        }}
        noPadding={true}
      />
      <Popup
        open={showRemovePromoPopup}
        setOpen={setShowRemovePromoPopup}
        content={
          <PromoConfirmation
            selectedInventoryDetails={selectedInventoryDetails}
            setOpen={setShowRemovePromoPopup}
            removeQuantity={removeQuantity}
            handleSubmit={removePromo}
          />
        }
        title="Are You Sure?"
        noLineBreak={true}
        headerClass="p-8 pb-4"
        closeIconSize={24}
        titleClass="text-primaryBlue font-semibold"
        paperProps={{
          style: {
            borderRadius: '8px',
            height: '276px',
            maxWidth: '613px',
            width: '613px'
          }
        }}
        noPadding={true}
      />
      {selectedInventory && (
        <div className="no-scrollbar">
          <InventoryDetailStats inventory={selectedInventory} />
          <div className="pl-8 pr-6">
            <TabContext value={tabValue}>
              <Box className={classes.tabContainer}>
                <TabList onChange={handleTabChange}>
                  <Tab
                    label={
                      <span className="flex">
                        {`Total Usable`}
                        {Boolean(
                          tabValue === InventoryManagementTabsMapping.DAMAGED &&
                          inventoryUpdates.usable
                        ) && <div className={classes.blueDot} />}
                      </span>
                    }
                    value={InventoryManagementTabsMapping.USABLE}
                  />
                  <Tab
                    label={
                      <span className="flex">
                        {`Total Damaged`}
                        {Boolean(
                          tabValue === InventoryManagementTabsMapping.USABLE &&
                          inventoryUpdates.damaged
                        ) && <div className={classes.blueDot} />}
                      </span>
                    }
                    value={InventoryManagementTabsMapping.DAMAGED}
                  />
                  <Tab
                    label={<span className="flex">Inventory History</span>}
                    value={InventoryManagementTabsMapping.HISTORY}
                  />
                </TabList>
                {tabValue !== InventoryManagementTabsMapping.HISTORY && (
                  <div className="flex gap-4">
                    <div className='w-40'>
                      <Autocomplete
                        value={uom}
                        onChange={(e, value) => {
                          setUom(value)
                        }}
                        options={uoms}
                        getOptionLabel={(uom) => uom?.name || ''}
                        renderOption={(props, value) => (
                          <li className="auto-complete-option" {...props}>
                            {value.name} {value.primary ? ' (primary)' : ''}
                          </li>
                        )}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            placeholder="UOM"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                fontSize: '12px'
                              },
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  {...params.endAdornment}
                                >
                                  <IconButton>
                                    <ArrowDropDown />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </div>
                    <SearchBar
                      className={classes.searchBar}
                      iconClass="h-4 w-4"
                      setSearchValue={() => { }}
                      placeholder="Search Inventory"
                    />
                    <Filters
                      options={filterOptions}
                      filters={advancedfilters}
                      setFilter={setFilter}
                      isPrimary={false}
                    />
                  </div>
                )}
              </Box>
              <TabPanel
                sx={{ padding: 0 }}
                value={InventoryManagementTabsMapping.USABLE}
              >
                <div className="mb-5">
                  {areAnyFilterActive && (
                    <div className="w-full flex pt-[20px] text-dark500 text-sm font-medium">
                      <div className="pt-1">Applied Filters</div>
                      <div className="ml-6 flex">
                        {advancedfilters?.Status?.map((t) => {
                          let isSelected =
                            advancedfilters?.Status?.findIndex(
                              (type) => type.value == t.value
                            ) != -1;
                          let className = isSelected
                            ? 'bg-[#274E77] text-white'
                            : '';
                          return (
                            <div
                              key={t.value}
                              className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block ${className} rounded`}
                              onClick={() =>
                                setFilter(
                                  'Status',
                                  t,
                                  isSelected ? 'remove' : 'add'
                                )
                              }
                            >
                              <span className="text-sm">{t.label}</span>
                              <span
                                className={clsx('ml-3 font-bold', {
                                  'text-white': isSelected,
                                  'text-indigo': !isSelected
                                })}
                              >
                                x
                              </span>
                            </div>
                          );
                        })}

                        {advancedfilters?.Batches?.length > 0 && (
                          <div
                            className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                            onClick={() =>
                              setFilters((advancedfilters) => ({
                                ...advancedfilters,
                                Batches: []
                              }))
                            }
                          >
                            <span className="text-sm">Batches:</span>
                            <span className="text-sm">
                              {advancedfilters?.Batches[0]?.[0]?.value}
                            </span>
                            {advancedfilters?.Batches?.[0]?.length > 1 && (
                              <span className="text-sm ml-1">
                                +
                                {advancedfilters?.Batches.reduce(
                                  (acc, batch) => acc + batch.length,
                                  0
                                ) - 1}{' '}
                                more
                              </span>
                            )}
                            <span
                              className={clsx('ml-3 font-bold', 'text-white')}
                            >
                              x
                            </span>
                          </div>
                        )}

                        {advancedfilters?.Locations?.length > 0 && (
                          <div
                            className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                            onClick={() =>
                              setFilters((advancedfilters) => ({
                                ...advancedfilters,
                                Locations: []
                              }))
                            }
                          >
                            <span className="text-sm">Locations:</span>
                            <span className="text-sm">
                              {advancedfilters?.Locations[0]?.[0]?.value}
                            </span>
                            {advancedfilters?.Locations?.[0]?.length > 1 && (
                              <span className="text-sm ml-1">
                                +
                                {advancedfilters?.Locations.reduce(
                                  (acc, location) => acc + location.length,
                                  0
                                ) - 1}{' '}
                                more
                              </span>
                            )}
                            <span
                              className={clsx('ml-3 font-bold', 'text-white')}
                            >
                              x
                            </span>
                          </div>
                        )}

                        {(advancedfilters?.Batches.length > 0 ||
                          advancedfilters?.Locations.length > 0 ||
                          advancedfilters?.Status.length > 0) && (
                            <div
                              className="px-3 pt-1 cursor-pointer border-[#BDBDBD] border-solid border-0 border-l"
                              onClick={(e) => {
                                e.preventDefault();
                                setFilters({
                                  Status: [],
                                  Batches: [],
                                  Locations: []
                                });
                              }}
                            >
                              <span className="text-primary font-medium">
                                Clear All
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                  <Paper className="no-scrollbar rounded-t-lg shadow-none">
                    <TableContainer
                      style={{ overflow: 'auto' }}
                      className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              key={'checkbox'}
                              className="bg-white text-dark300 py-2 px-6 "
                            >
                              <Checkbox
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 16
                                  },
                                  '& .Mui-checked.MuiSvgIcon-root': {
                                    color: '#0C6BD7 !important'
                                  }
                                }}
                                checked={allBatchesSelected}
                                onChange={() => {
                                  setAllBatchesSelection(!allBatchesSelected);
                                }}
                              />
                            </TableCell>
                            {usableColumnNames.map((columnName, index) =>
                              columnName === 'Promo QTY & Code' &&
                                !promoQuantityColumn ? null : (
                                <TableCell
                                  key={index}
                                  className="text-[10px] text-dark500 bg-white py-2 pl-0 pr-2 font-normal"
                                >
                                  {columnName.toUpperCase()}
                                </TableCell>
                              )
                            )}
                            <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {usableBatches?.map((row, index) => (
                            <TableRow
                              key={index}
                              onMouseEnter={() => {
                                setHoveredRow(index);
                              }}
                              onMouseLeave={() => {
                                setHoveredRow(-1);
                              }}
                              className={clsx(
                                {
                                  'bg-snow text-auroMetal group':
                                    row?.availableQuantity === 0 &&
                                    row?.holdQuantity > 0
                                },
                                'hover:shadow-elevation-1 hover:z-20 hover:relative'
                              )}
                              onClick={() => handleCheckboxChange(row?.id, row)}
                            >
                              <TableCell className="rounded-none text-dark300 py-4 px-6 ">
                                <Checkbox
                                  sx={{
                                    '& .MuiSvgIcon-root': {
                                      fontSize: 16
                                    },
                                    '& .Mui-checked.MuiSvgIcon-root': {
                                      color: '#0C6BD7 !important'
                                    }
                                  }}
                                  checked={selectedBatches.includes(row?.id)}
                                  onChange={() =>
                                    handleCheckboxChange(row?.id, row)
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className={`${tableCellClass} group-hover:text-primaryBlue`}
                              >
                                <Tooltip title={row?.WarehouseHall?.name}>
                                  <p
                                    className={clsx(topValue, {
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    {(row?.WarehouseHall?.name).length > 20
                                      ? `${(row?.WarehouseHall?.name).substring(
                                        0,
                                        20
                                      )}...`
                                      : `${row?.WarehouseHall?.name}`}
                                  </p>
                                </Tooltip>

                                <p
                                  className={clsx(cellSubValue, {
                                    'text-primaryBlue':
                                      row?.holdQuantity === 0 &&
                                      row?.holdQuantity > 0,
                                    'text-auroMetal': row?.holdQuantity > 0
                                  })}
                                >
                                  <span
                                    className={clsx('mr-2', {
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    Aisle: {row?.WarehouseHall?.Aisle?.name}
                                  </span>{' '}
                                  <span
                                    className={clsx({
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    Bay: {row?.WarehouseHall?.Bay?.name}
                                  </span>
                                </p>
                              </TableCell>
                              {isFeatureEnabled(
                                FLAGS.BATCH_AND_EXPIRY_TRACKING
                              ) && (
                                  <TableCell
                                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                                  >
                                    <p
                                      className={clsx(topValue, {
                                        'text-primaryBlue':
                                          row?.holdQuantity === 0 &&
                                          row?.holdQuantity > 0,
                                        'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                          row?.holdQuantity > 0
                                      })}
                                    >
                                      {row?.batchNumber}
                                    </p>
                                    <div className="flex items-start">
                                      <p
                                        className={clsx(
                                          cellSubValue,
                                          {
                                            'text-primaryBlue':
                                              row?.holdQuantity === 0 &&
                                              row?.holdQuantity > 0,
                                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                              row?.holdQuantity > 0
                                          },
                                          'mr-1'
                                        )}
                                      >
                                        <span
                                          className={clsx({
                                            'text-dusk600':
                                              row?.expiredQuantity > 0
                                          })}
                                        >
                                          {row?.manufacturingDate
                                            ? moment(
                                              row?.manufacturingDate
                                            ).format('DD/MM/YY') + ' - '
                                            : ''}
                                        </span>
                                        <span
                                          className={clsx({
                                            'text-dusk600':
                                              row?.expiredQuantity > 0 ||
                                              row?.nearExpiryQuantity > 0
                                          })}
                                        >
                                          {row?.expiryDate &&
                                            row?.expiredQuantity === 0
                                            ? moment(row?.expiryDate).format(
                                              'DD/MM/YY'
                                            )
                                            : row?.expiredQuantity > 0
                                              ? `${moment(row?.expiryDate).format(
                                                'DD/MM/YY'
                                              )}`
                                              : ''}
                                        </span>
                                      </p>
                                      {(row?.expiredQuantity > 0 ||
                                        row?.nearExpiryQuantity > 0) && (
                                          <Tooltip
                                            title={getInventoryAlertMessage({
                                              inventory: row,
                                              detailView: true,
                                              damages: false
                                            })}
                                            placement="top"
                                            classes={{
                                              tooltip: classes.customTooltip,
                                              arrow: classes.arrow
                                            }}
                                            arrow
                                          >
                                            <span>
                                              <img
                                                src={
                                                  row?.expiredQuantity > 0
                                                    ? AlertIcon
                                                    : WarningIcon
                                                }
                                                alt="alert"
                                              />
                                            </span>
                                          </Tooltip>
                                        )}
                                    </div>
                                  </TableCell>
                                )}
                              <TableCell
                                className={`${tableCellClass} group-hover:text-primaryBlue`}
                              >
                                <div className="flex items-start h-full">
                                  <p
                                    className={clsx(digitValue, {
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    {decimalDisplay(
                                      row?.weight
                                        ? row?.weight
                                        : (selectedInventory?.Product?.weight ||
                                          0) * row?.goodQuantity || 0
                                    )}{' '}
                                    <span className="ml-1">
                                      {settings?.weight?.symbol}
                                    </span>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell
                                className={`${tableCellClass} group-hover:text-primaryBlue`}
                              >
                                <div className="flex items-start h-full">
                                  <p
                                    className={clsx(digitValue, {
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    {decimalDisplay(row?.MRP)}
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell
                                className={`${tableCellClass} group-hover:text-primaryBlue`}
                              >
                                <div className="flex items-start h-full">
                                  <p
                                    className={clsx(digitValue, {
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    {decimalDisplay(
                                      (row?.availableQuantity / uom?.conversionFactor) +
                                      (row?.holdQuantity / uom?.conversionFactor) +
                                      (row?.reservedQuantity / uom?.conversionFactor) || 0
                                    )}
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell
                                className={`${tableCellClass} group-hover:text-primaryBlue`}
                              >
                                <div className="flex items-start h-full">
                                  <p
                                    className={clsx(
                                      clsx(digitValue, {
                                        'text-primaryBlue':
                                          row?.holdQuantity === 0 &&
                                          row?.holdQuantity > 0,
                                        'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                          row?.holdQuantity > 0
                                      }),
                                      'flex'
                                    )}
                                  >
                                    {decimalDisplay(row?.availableQuantity / uom?.conversionFactor)}
                                    {isBatchUpdated({
                                      id: row?.id,
                                      lastConditionChange: lastConditionChange,
                                      origin: OriginCell.AVAILABLE
                                    }) && <div className={classes.blueDot} />}
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell
                                className={`${tableCellClass} group-hover:text-primaryBlue`}
                              >
                                <div className="flex items-start h-full">
                                  <p
                                    className={clsx(digitValue, {
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    {decimalDisplay((row?.holdQuantity / uom?.conversionFactor) || 0)}
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell
                                className={`${tableCellClass} group-hover:text-primaryBlue`}
                              >
                                <div className="flex items-start h-full">
                                  <p
                                    className={clsx(digitValue, {
                                      'text-primaryBlue':
                                        row?.holdQuantity === 0 &&
                                        row?.holdQuantity > 0,
                                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                        row?.holdQuantity > 0
                                    })}
                                  >
                                    {decimalDisplay((row?.reservedQuantity / uom?.conversionFactor) || 0)}
                                  </p>
                                </div>
                              </TableCell>
                              {promoQuantityColumn && (
                                <TableCell
                                  className={`${tableCellClass} group-hover:text-primaryBlue`}
                                >
                                  <div className="flex items-start h-full">
                                    <p
                                      className={clsx(digitValue, {
                                        'text-primaryBlue':
                                          row?.holdQuantity === 0 &&
                                          row?.holdQuantity > 0,
                                        'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                          row?.holdQuantity > 0
                                      })}
                                    >
                                      {row?.promoName
                                        ? `${decimalDisplay(
                                          row?.promoQuantity || 0
                                        )} (${row?.promoName})`
                                        : '-'}
                                    </p>
                                  </div>
                                </TableCell>
                              )}

                              <TableCell className="rounded-none">
                                <div
                                  className={clsx('flex h-full items-center', {
                                    'invisible ': hoveredRow !== index
                                  })}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {((checkPermission(
                                    currentUser,
                                    'OPS_HOLD_APPLY'
                                  ) &&
                                    selectedInventoryDetails?.availableQuantity >
                                    0) ||
                                    (checkPermission(
                                      currentUser,
                                      'OPS_HOLD_REMOVE'
                                    ) &&
                                      selectedInventoryDetails?.holdQuantity >
                                      0) ||
                                    (checkPermission(
                                      currentUser,
                                      'OPS_STOCK_CONDITION_UPDATE'
                                    ) &&
                                      selectedInventoryDetails?.availableQuantity >
                                      0) ||
                                    checkPermission(
                                      currentUser,
                                      selectedInventoryDetails?.promoName
                                        ? 'OPS_PROMO_UPDATE'
                                        : 'OPS_PROMO_CREATE'
                                    )) && (
                                      <OptionsMenu
                                        key={row?.id}
                                        options={batchOptions}
                                        closeCallback={() =>
                                          setOptionsClick(false)
                                        }
                                        enterCallBack={() => {
                                          setSelectedInventoryDetails(row);
                                          setOptionsClick(true);
                                        }}
                                        forceClose={
                                          stockAdjusmtentViewOpen ||
                                          sectionRelocationViewOpen ||
                                          stockConditionViewOpen ||
                                          holdInventoryViewOpen ||
                                          releaseInventoryViewOpen
                                        }
                                      />
                                    )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <div className="w-full flex justify-between items-center bg-dark200 rounded-b-lg h-14 border-solid border border-dark300 border-t-0 py-3 px-4">
                    <div className="flex">
                      <Button
                        variant="transparent"
                        icon={<img src={DownloadIcon} className={`mr-2`} />}
                        label="Export"
                        className="py-2 px-3 ml-2 h-8 border-solid border "
                        overrideSize={true}
                        labelClass="font-medium text-xs"
                        iconClass="h-[14px] w-[14px] mr-1"
                        onClick={() => exportToExcel()}
                      />
                      {(selectedBatches?.length &&
                        isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) > 0 && (
                          <StockAdjustment
                            batchIds={selectedBatches}
                            refetch={refetch}
                            stock360Enabled={isFeatureEnabled(
                              FLAGS.LOCATION_HIERARCHY
                            )}
                            batchExpiryEnabled={isFeatureEnabled(
                              FLAGS.BATCH_AND_EXPIRY_TRACKING
                            )}
                            open={stockAdjusmtentViewOpen}
                            setOpen={setStockAdjustmentViewOpen}
                            warehouseIds={[selectedInventory?.Warehouse?.id]}
                          />
                        )) ||
                        null}
                      {(selectedBatches?.length &&
                        isFeatureEnabled(FLAGS.STOCK_CONDITION_MANAGEMENT) && (
                          <StockCondition
                            batchIds={selectedBatches}
                            refetch={refetch}
                            stock360Enabled={isFeatureEnabled(
                              FLAGS.LOCATION_HIERARCHY
                            )}
                            batchExpiryEnabled={isFeatureEnabled(
                              FLAGS.BATCH_AND_EXPIRY_TRACKING
                            )}
                            open={stockConditionViewOpen}
                            setOpen={setStockConditionViewOpen}
                            warehouseIds={[selectedInventory?.Warehouse?.id]}
                          />
                        )) ||
                        null}
                      {(selectedBatches?.length &&
                        isFeatureEnabled(FLAGS.STOCK_RELOCATION) > 0 && (
                          <SectionRelocation
                            batchIds={selectedBatches}
                            refetch={refetch}
                            stock360Enabled={isFeatureEnabled(
                              FLAGS.LOCATION_HIERARCHY
                            )}
                            batchExpiryEnabled={isFeatureEnabled(
                              FLAGS.BATCH_AND_EXPIRY_TRACKING
                            )}
                            open={sectionRelocationViewOpen}
                            setOpen={setSectionRelocationViewOpen}
                            warehouseIds={[selectedInventory?.Warehouse?.id]}
                          />
                        )) ||
                        null}
                      {selectedBatches?.length > 0 &&
                        isFeatureEnabled(FLAGS.INVENTORY_HOLD) && (
                          <ReleaseInventory
                            batchIds={selectedBatches}
                            refetch={refetch}
                            stock360Enabled={isFeatureEnabled(
                              FLAGS.LOCATION_HIERARCHY
                            )}
                            batchExpiryEnabled={isFeatureEnabled(
                              FLAGS.BATCH_AND_EXPIRY_TRACKING
                            )}
                            open={releaseInventoryViewOpen}
                            setOpen={setReleaseInventoryViewOpen}
                            warehouseIds={[selectedInventory?.Warehouse?.id]}
                          />
                        )}
                      {(selectedBatches?.length > 0 &&
                        isFeatureEnabled(FLAGS.INVENTORY_HOLD) && (
                          <HoldInventory
                            batchIds={selectedBatches}
                            refetch={refetch}
                            stock360Enabled={isFeatureEnabled(
                              FLAGS.LOCATION_HIERARCHY
                            )}
                            batchExpiryEnabled={isFeatureEnabled(
                              FLAGS.BATCH_AND_EXPIRY_TRACKING
                            )}
                            open={holdInventoryViewOpen}
                            setOpen={setHoldInventoryViewOpen}
                            warehouseIds={[selectedInventory?.Warehouse?.id]}
                          />
                        )) ||
                        null}
                    </div>
                    <div className="flex">
                      <p className="text-xs text-primaryBlue flex items-center mr-8">
                        Total Locations:{' '}
                        <span className="text-sm font-medium ml-1">
                          {selectedBatches?.length > 0
                            ? selectedBatches.length
                            : usableBatches?.length || 0}
                        </span>
                      </p>
                      <p className="text-xs text-primaryBlue flex items-center mr-8">
                        Total Weight:{' '}
                        <span className="text-sm font-medium ml-1">
                          {decimalDisplay(
                            formatNumberToKAndM(
                              selectedBatches.length > 0
                                ? (selectedInventory?.Product?.weight || 0) *
                                usableBatches
                                  ?.filter((invDetail) =>
                                    selectedBatches.includes(invDetail.id)
                                  )
                                  .map((invDetail) => {
                                    return (
                                      invDetail.goodQuantity +
                                      invDetail.recoverableDamageQuantity
                                    );
                                  })
                                  .reduce((acc, curr) => acc + curr, 0)
                                : (selectedInventory?.Product?.weight || 0) *
                                usableBatches
                                  ?.map((invDetail) => {
                                    return (
                                      invDetail.goodQuantity +
                                      invDetail.recoverableDamageQuantity
                                    );
                                  })
                                  .reduce((acc, curr) => acc + curr, 0)
                            )
                          )}
                        </span>{' '}
                        <span className="text-[10px] ml-0.5">
                          {settings?.weight?.symbol}
                        </span>
                      </p>
                      {organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
                        checkPermission(
                          currentUser,
                          'OPS_COST_PRICE_VISIBILITY'
                        ) && (
                          <p className="text-xs text-primaryBlue flex items-center mr-8">
                            Total Stock Value:{' '}
                            <span className="text-sm font-medium ml-1">
                              Rs.
                              {decimalDisplay(
                                selectedBatches.length > 0
                                  ? (selectedInventory?.averageStockPrice ||
                                    0) *
                                  usableBatches
                                    ?.filter((invDetail) =>
                                      selectedBatches.includes(invDetail.id)
                                    )
                                    .map((invDetail) => {
                                      return (
                                        invDetail.goodQuantity +
                                        invDetail.recoverableDamageQuantity
                                      );
                                    })
                                    .reduce((acc, curr) => acc + curr, 0)
                                  : (selectedInventory?.averageStockPrice ||
                                    0) *
                                  usableBatches
                                    ?.map((invDetail) => {
                                      return (
                                        invDetail.goodQuantity +
                                        invDetail.recoverableDamageQuantity
                                      );
                                    })
                                    .reduce((acc, curr) => acc + curr, 0)
                              )}
                            </span>{' '}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                sx={{ padding: 0 }}
                value={InventoryManagementTabsMapping.DAMAGED}
              >
                <div className="mb-5">
                  <Paper className="no-scrollbar rounded-t-lg shadow-none">
                    <TableContainer
                      style={{ overflow: 'auto' }}
                      className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              key={'checkbox'}
                              className="bg-white text-dark300 py-2 px-6 "
                            >
                              <Checkbox
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 16
                                  },
                                  '& .Mui-checked.MuiSvgIcon-root': {
                                    color: '#0C6BD7 !important'
                                  }
                                }}
                              />
                            </TableCell>
                            {damagedColumnNames.map((columnName, index) => (
                              <TableCell
                                key={index}
                                className="text-[10px] text-dark500 bg-white py-2 pl-0 pr-2 font-normal"
                              >
                                {columnName.toUpperCase()}
                              </TableCell>
                            ))}
                            <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {damagedBatches
                            ?.filter((b) => b.recoverableDamageQuantity > 0)
                            .map((row, index) => (
                              <TableRow
                                key={index}
                                onMouseEnter={() => {
                                  setHoveredRow(index);
                                }}
                                onMouseLeave={() => {
                                  setHoveredRow(-1);
                                }}
                                className={clsx(
                                  {
                                    'bg-snow text-auroMetal group':
                                      row?.holdQuantity === 0 &&
                                      row?.holdQuantity > 0
                                  },
                                  'hover:shadow-elevation-1 hover:z-20 hover:relative'
                                )}
                                onClick={() =>
                                  handleCheckboxChange(row?.id, row)
                                }
                              >
                                <TableCell className="rounded-none text-dark300 py-4 px-6 ">
                                  <Checkbox
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        fontSize: 16
                                      },
                                      '& .Mui-checked.MuiSvgIcon-root': {
                                        color: '#0C6BD7 !important'
                                      }
                                    }}
                                    checked={selectedBatches.includes(row?.id)}
                                    onChange={() =>
                                      handleCheckboxChange(row?.id, row)
                                    }
                                  />
                                </TableCell>
                                {isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
                                  <TableCell
                                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                                  >
                                    <Tooltip title={row?.WarehouseHall?.name}>
                                      <p
                                        className={clsx(topValue, {
                                          'text-primaryBlue':
                                            row?.holdQuantity === 0 &&
                                            row?.holdQuantity > 0,
                                          'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                            row?.holdQuantity > 0
                                        })}
                                      >
                                        {(row?.WarehouseHall?.name).length > 20
                                          ? `${(row?.WarehouseHall?.name).substring(
                                            0,
                                            20
                                          )}...`
                                          : `${row?.WarehouseHall?.name}`}
                                      </p>
                                    </Tooltip>

                                    <p
                                      className={clsx(cellSubValue, {
                                        'text-primaryBlue':
                                          row?.holdQuantity === 0 &&
                                          row?.holdQuantity > 0,
                                        'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                          row?.holdQuantity > 0
                                      })}
                                    >
                                      <span
                                        className={clsx('mr-2', {
                                          'text-primaryBlue':
                                            row?.holdQuantity === 0 &&
                                            row?.holdQuantity > 0,
                                          'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                            row?.holdQuantity > 0
                                        })}
                                      >
                                        Aisle: {row?.WarehouseHall?.Aisle?.name}
                                      </span>{' '}
                                      <span
                                        className={clsx({
                                          'text-primaryBlue':
                                            row?.holdQuantity === 0 &&
                                            row?.holdQuantity > 0,
                                          'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                            row?.holdQuantity > 0
                                        })}
                                      >
                                        Bay: {row?.WarehouseHall?.Bay?.name}
                                      </span>
                                    </p>
                                  </TableCell>
                                )}
                                {isFeatureEnabled(
                                  FLAGS.BATCH_AND_EXPIRY_TRACKING
                                ) && (
                                    <TableCell
                                      className={`${tableCellClass} group-hover:text-primaryBlue`}
                                    >
                                      <p
                                        className={clsx(topValue, {
                                          'text-primaryBlue':
                                            row?.holdQuantity === 0 &&
                                            row?.holdQuantity > 0,
                                          'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                            row?.holdQuantity > 0
                                        })}
                                      >
                                        {row?.batchNumber}
                                      </p>
                                      <div className="flex items-start">
                                        <p
                                          className={clsx(
                                            cellSubValue,
                                            {
                                              'text-primaryBlue':
                                                row?.holdQuantity === 0 &&
                                                row?.holdQuantity > 0,
                                              'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                                row?.holdQuantity > 0
                                            },
                                            'mr-1'
                                          )}
                                        >
                                          <span
                                            className={clsx({
                                              'text-dusk600':
                                                row?.expiredQuantity > 0
                                            })}
                                          >
                                            {row?.manufacturingDate
                                              ? moment(
                                                row?.manufacturingDate
                                              ).format('DD/MM/YY') + ' - '
                                              : ''}
                                          </span>

                                          <span
                                            className={clsx({
                                              'text-dusk600':
                                                row?.expiredQuantity > 0 ||
                                                row?.nearExpiryQuantity > 0
                                            })}
                                          >
                                            {row?.expiryDate &&
                                              row?.expiredQuantity === 0
                                              ? moment(row?.expiryDate).format(
                                                'DD/MM/YY'
                                              )
                                              : row?.expiredQuantity > 0
                                                ? `${moment(row?.expiryDate).format(
                                                  'DD/MM/YY'
                                                )}`
                                                : ''}
                                          </span>
                                        </p>
                                        {(row?.expiredQuantity > 0 ||
                                          row?.nearExpiryQuantity > 0) && (
                                            <Tooltip
                                              title={getInventoryAlertMessage({
                                                inventory: row,
                                                detailView: true,
                                                damages: true
                                              })}
                                              placement="top"
                                              classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.arrow
                                              }}
                                              arrow
                                            >
                                              <span>
                                                <img
                                                  src={
                                                    row?.expiredQuantity > 0
                                                      ? AlertIcon
                                                      : WarningIcon
                                                  }
                                                  alt="alert"
                                                />
                                              </span>
                                            </Tooltip>
                                          )}
                                      </div>
                                    </TableCell>
                                  )}
                                <TableCell
                                  className={`${tableCellClass} group-hover:text-primaryBlue`}
                                >
                                  <div className="flex items-center h-full">
                                    <p
                                      className={clsx(digitValue, {
                                        'text-primaryBlue':
                                          row?.holdQuantity === 0 &&
                                          row?.holdQuantity > 0,
                                        'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                          row?.holdQuantity > 0
                                      })}
                                    >
                                      {decimalDisplay(
                                        row?.recoverableDamageQuantity || 0
                                      )}
                                    </p>
                                  </div>
                                </TableCell>

                                <TableCell className="rounded-none">
                                  <div
                                    className={clsx(
                                      'flex h-full items-center',
                                      {
                                        'invisible ': hoveredRow !== index
                                      }
                                    )}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <OptionsMenu
                                      key={row?.id}
                                      options={batchOptions}
                                      closeCallback={() =>
                                        setOptionsClick(false)
                                      }
                                      enterCallBack={() => {
                                        setSelectedInventoryDetails(row);
                                        setOptionsClick(true);
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <div className="w-full flex justify-between items-center bg-dark200 rounded-b-lg h-14 border-solid border border-dark300 border-t-0 py-3 px-4">
                    <div className="flex">
                      <Button
                        variant="transparent"
                        icon={<img src={DownloadIcon} className={`mr-2`} />}
                        label="Export"
                        className="py-2 px-3 ml-2 h-8 border-solid border "
                        overrideSize={true}
                        labelClass="font-medium text-xs"
                        iconClass="h-[14px] w-[14px] mr-1"
                      />
                      {selectedBatches?.length > 0 && (
                        <StockAdjustment
                          batchIds={selectedBatches}
                          refetch={refetch}
                          stock360Enabled={true}
                          batchExpiryEnabled={true}
                          open={stockAdjusmtentViewOpen}
                          setOpen={setStockAdjustmentViewOpen}
                          stockCondition="RECOVERABLE"
                        />
                      )}
                      {selectedBatches?.length > 0 && (
                        <StockCondition
                          batchIds={selectedBatches}
                          refetch={refetch}
                          stock360Enabled={true}
                          batchExpiryEnabled={true}
                          open={stockConditionViewOpen}
                          setOpen={setStockConditionViewOpen}
                          stockCondition="RECOVERABLE"
                        />
                      )}
                    </div>
                    <div className="flex">
                      <p className="text-xs text-primaryBlue flex items-center mr-8">
                        Total Locations:{' '}
                        <span className="text-sm font-medium ml-1">
                          {selectedBatches?.length ||
                            damagedBatches?.length ||
                            0}
                        </span>
                      </p>
                      <p className="text-xs text-primaryBlue flex items-center mr-8">
                        Total Weight:{' '}
                        <span className="text-sm font-medium ml-1">
                          {decimalDisplay(
                            formatNumberToKAndM(
                              (selectedInventory?.Product?.weight || 0) *
                              damagedBatches
                                ?.map((invDetail) => {
                                  return invDetail.recoverableDamageQuantity;
                                })
                                .reduce((acc, curr) => acc + curr, 0)
                            )
                          )}
                        </span>{' '}
                        <span className="text-[10px] ml-0.5">
                          {settings?.weight?.symbol}
                        </span>
                      </p>
                      {organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
                        checkPermission(
                          currentUser,
                          'OPS_COST_PRICE_VISIBILITY'
                        ) && (
                          <p className="text-xs text-primaryBlue flex items-center mr-8">
                            Total Damage Value:{' '}
                            <span className="text-sm font-medium ml-1">
                              Rs.
                              {decimalDisplay(
                                (selectedInventory?.Product?.MRP || 0) *
                                damagedBatches
                                  ?.map((invDetail) => {
                                    return invDetail.recoverableDamageQuantity;
                                  })
                                  .reduce((acc, curr) => acc + curr, 0)
                              )}
                            </span>{' '}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                sx={{ padding: 0 }}
                value={InventoryManagementTabsMapping.HISTORY}
              >
                <InventoryHistory inventoryId={selectedInventory?.id} />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      )}
    </>
  );
};

export default InventoryDetailsView;

/*eslint-disable*/
import { makeStyles } from '@material-ui/core';

const qrCodeStyles = makeStyles(() => ({
  root: {
    margin: '32px 24px 32px 40px',
    '@media print': {
      visibility: 'hidden'
    },
    '@media (max-width: 1280px)': {
      margin: '10px 0px 0px 0px'
    }
  },
  inputField: {
    margin: '32px 0px'
  },
  qrContainer: {
    height: 'auto',
    padding: '16px 56px 25px 56px',
    borderRadius: '8px',
    border: '1.5px solid #C1D6EC',
    background: '#F4FAFF',
    boxShadow: '0px 4px 8px 0px rgba(28, 22, 103, 0.06)',
    minHeight: '320px'
  },
  qrPreview: {
    width: '100%',
    minHeight: '192px',
    height: 'auto',
    borderRadius: '8px',
    background: '#FFFFFF',
    border: '1px solid #D9ECFF',
    padding: 8,
    boxShadow: '0px 4px 8px rgba(28, 22, 103, 0.06)'
  },
  printBtn: {
    width: '100%',
    margin: 'auto',
    background: '#4B89C8 !important',
    border: '1px solid #4B89C8 !important',
    borderRadius: '8px !important',
    height: '32px !important',
    color: '#FFFFFF !important',
    cursor: 'pointer'
  },
  clearBtn: {
    width: '100%',
    margin: 'auto',
    background: '#D9DFE5 !important',
    border: '1px solid #D9DFE5 !important',
    borderRadius: '8px !important',
    height: '32px !important',
    color: '#979B9F !important',
    cursor: 'pointer'
  },
  timePicker: {
    marginTop: '10px',
    background: '#EDF3FA',
    borderRadius: '8px',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '100%'
  },
  qrText: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '8px'
  },
  dateField: {
    width: '100%',
    margin: '-3px'
  },
  container: {
    padding: '2px',
    '@media (min-width: 460px)': {
      padding: '20px'
    }
  },
  inputBox: {
    display: 'flex',
    margin: '40px 0px',
    justifyContent: 'space-between'
  },
  textField: {
    '& .MuiAutocomplete-inputRoot': {
      marginTop: '0px',
      marginLeft: '0px',
      color: '#323338',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      height: '40px',
      width: '288px',
      paddingLeft: '24px !important'
    },
    '& .MuiFormLabel-root': {
      color: '#0B1940',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0.15px',
      marginTop: '-8px',
      marginLeft: '10px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0C70D7',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500
    },
    '& .MuiInputLabel-shrink': {
      marginTop: '0px !important',
      marginLeft: '0px !important',
      color: '#0B1940',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400
    },
    '& input': {
      fontWeight: '500'
    },
    '& .Mui-focused input': {
      color: '#0C70D7'
    },

    '& .Mui-focused fieldset': {
      border: '1px solid #0C70D7 !important'
    },
    '& fieldset': {
      border: '1px solid #0B1940 !important'
    },

    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      color: '#676879'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: '0px !important'
      },
    '& .MuiInputBase-fullWidth': {
      width: '100%'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px 10px 24px !important'
    }
  },
  clearIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: 'path("M4.59167 3.84024L4.16602 3.45455L3.74036 3.84024C3.97544 4.05325 4.35659 4.05325 4.59167 3.84024ZM4.16602 2.68316L1.38115 0.15976C1.14606 -0.0532536 0.764915 -0.0532536 0.52983 0.15976C0.294744 0.372773 0.294744 0.718136 0.52983 0.931149L3.74036 3.84024L4.16602 3.45455L4.59167 3.84024L7.8022 0.931149C8.03729 0.718136 8.03729 0.372773 7.8022 0.15976C7.56712 -0.0532536 7.18597 -0.0532536 6.95088 0.15976L4.16602 2.68316Z") '
        }
      }
    }
  },
  textInput: {
    borderRadius: '4px !important',
    background: '#FFF !important',
    color: '#0B1940 !important',
    fontSize: '16px !important',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: '400  !important',
    letterSpacing: '0.15px !important',
    height: '40px !important',
    padding: '0px'
  },
  longInput: {
    width: '366px',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
      {
        right: '33px'
      }
  },
  qrInputBox: {
    marginBottom: '16px'
  },
  qtTitle: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: 12
  },
  skuName: {
    fontSize: '20px',
    fontWeight: 600,
    marginTop: 8
  },
  promoName: {
    fontSize: '10px',
    fontWeight: 400,
    marginBottom: 16
  },
  productText: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: 8
  },
  dateTitle: {
    fontSize: '12px',
    fontWeight: 700
  },
  dateText: {
    fontSize: '12px',
    fontWeight: 400
  },
  dateBox: {
    display: 'flex',
    minWidth: '150px',
    justifyContent: 'space-between'
  },
  neutralBtn: {
    color: '#0C71D7 !important',
    background: 'white !important',
    border: '2px solid #0C71D7 !important',
    boxSizing: 'border-box !important'
  },
  successBtn: {
    background: '#0C71D7 !important',
    color: 'white !important',
    border: '0px !important'
  },
  rejectBtn: {
    background: '#C5C7D0 !important',
    color: 'black !important',
    marginRight: '24px !important',
    border: '0px !important'
  },
  lgBtn: {
    display: 'flex',
    height: '40px',
    width: '100%',
    padding: '8px 40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'uppercase'
  },
  print: {
    marginTop: '16px !important',
    '& img': {
      marginRight: 8
    }
  },
  inputBox: {
    display: 'flex',
    margin: '40px 0px',
    justifyContent: 'space-between'
  },
  textField: {
    '& .MuiAutocomplete-inputRoot': {
      marginTop: '0px',
      marginLeft: '0px',
      color: '#323338',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      height: '40px',
      width: '288px',
      paddingLeft: '24px !important'
    },
    '& .MuiFormLabel-root': {
      color: '#0B1940',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0.15px',
      marginTop: '-8px',
      marginLeft: '10px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0C70D7',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500
    },
    '& .MuiInputLabel-shrink': {
      marginTop: '0px !important',
      marginLeft: '0px !important',
      color: '#0B1940',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400
    },
    '& input': {
      fontWeight: '500'
    },
    '& .Mui-focused input': {
      color: '#0C70D7'
    },

    '& .Mui-focused fieldset': {
      border: '1px solid #0C70D7 !important'
    },
    '& fieldset': {
      border: '1px solid #0B1940 !important'
    },

    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      color: '#676879'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: '0px !important'
      },
    '& .MuiInputBase-fullWidth': {
      width: '100%'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px 10px 24px !important'
    }
  },
  clearIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: 'path("M4.59167 3.84024L4.16602 3.45455L3.74036 3.84024C3.97544 4.05325 4.35659 4.05325 4.59167 3.84024ZM4.16602 2.68316L1.38115 0.15976C1.14606 -0.0532536 0.764915 -0.0532536 0.52983 0.15976C0.294744 0.372773 0.294744 0.718136 0.52983 0.931149L3.74036 3.84024L4.16602 3.45455L4.59167 3.84024L7.8022 0.931149C8.03729 0.718136 8.03729 0.372773 7.8022 0.15976C7.56712 -0.0532536 7.18597 -0.0532536 6.95088 0.15976L4.16602 2.68316Z") '
        }
      }
    }
  },
  textInput: {
    borderRadius: '4px !important',
    background: '#FFF !important',
    color: '#0B1940 !important',
    fontSize: '16px !important',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: '400  !important',
    letterSpacing: '0.15px !important',
    height: '40px !important',
    padding: '0px'
  },
  longInput: {
    width: '366px',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
      {
        right: '33px'
      }
  },
  qrInputBox: {
    marginBottom: '16px'
  },
  qtTitle: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: 12
  },
  skuName: {
    fontSize: '20px',
    fontWeight: 600,
    marginTop: 8
  },
  promoName: {
    fontSize: '10px',
    fontWeight: 400,
    marginBottom: 16
  },
  productText: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: 8
  },
  dateTitle: {
    fontSize: '12px',
    fontWeight: 700
  },
  dateText: {
    fontSize: '12px',
    fontWeight: 400
  },
  dateBox: {
    display: 'flex',
    minWidth: '150px',
    justifyContent: 'space-between'
  },
  neutralBtn: {
    color: '#0C71D7 !important',
    background: 'white !important',
    border: '2px solid #0C71D7 !important',
    boxSizing: 'border-box !important'
  },
  successBtn: {
    background: '#0C71D7 !important',
    color: 'white !important',
    border: '0px !important'
  },
  rejectBtn: {
    background: '#C5C7D0 !important',
    color: 'black !important',
    marginRight: '24px !important',
    border: '0px !important'
  },
  lgBtn: {
    display: 'flex',
    height: '40px',
    width: '100%',
    padding: '8px 40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'uppercase'
  },
  print: {
    marginTop: '16px !important',
    '& img': {
      marginRight: 8
    }
  },
  qrBody: {
    display: 'flex'
  },
  qtDetails: {
    overflow: 'hidden',
    wordWrap: 'break-word',
    maxWidth: '100vw'
  },
  qrCode: {
    margin: '10px'
  }
}));

export default qrCodeStyles;

/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@mui/material';
import { Title } from '../../../core-components/atoms/Text';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import { nodeTypes, organizationTypes } from '../constants';
import MultiSelect from '../MultiSelect';
import { customerIcon, organizationIcon, supplierIcon } from '../icons';
import { ChevronRight } from '@material-ui/icons';
import { onError } from '../../../libs/errorLib';
import Switch from '../../../core-components/atoms/Switch';
import { toaster } from '../../../utils/toaster';
import FormikTextField from '../../../core-components/molecules/FormikTextField';
import clsx from 'clsx';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px #E8ECF1',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: '16px 16px',
    alignItems: ' flex-start',
    boxShadow:
      '0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.10)'
  },
  dockDialog: {
    // padding: '10px 0px 0px !important'
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  dockInputField: {
    width: '300px'
  },
  switchButton: {
    marginTop: 16
  },
  cancelBtn: {
    padding: '8px 12px',
    // gap: '6px',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#0B1940'
  },

  submitBtn: {
    padding: '8px 12px',
    background: '#0C6BD7',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:disabled': {
      background: 'rgba(12,107,215,0.5)'
    }
  }
}));

const CreateNode = ({ setOpen, getCustomer, getNodes, entityInfo }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [nodeTypeOptions, setNodeTypeOptions] = useState([]);
  const { organization, setAPILoader } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: {
      name: '',
      nodeType: null,
      isActive: true,
      entity: entityInfo ? entityInfo?.entity : null,
      isEntity: false,
      nodeClass: entityInfo ? entityInfo?.nodeClass?.toUpperCase() : null,
      IMS: true,
      WMS: true
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(`Entity name is required`),
      nodeType: Yup.object().required(`Node type is required`),
      entity: Yup.object().required(`Entity type is required`)
    }),
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  const submitForm = async (values) => {
    const { ...rest } = values;
    let payload = {
      ...rest,
      nodeType: rest.nodeType.value,
      nodeClass: (entityInfo?.nodeClass || values?.nodeClass)?.toUpperCase()
    };

    return await addNode([payload]);
  };

  const addNode = async (data) => {
    let node = {};
    setAPILoader(true);
    try {
      if (formik.values.entity.entityType == 'Organization')
        await API.post(`nodes/bulk`, data);
      else
        await API.post(
          `nodes/bulk?companyId=${formik.values.entity.value}`,
          data
        );
      setOpen(false);
      getCustomer && getCustomer();
      getNodes && getNodes();
      toaster('success', 'New Node has been created.');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }

    return node;
  };

  const getCustomers = async (value) => {
    try {
      const response = await API.get(`companies`, {
        params: { search: value, type: 'CUSTOMER' }
      });

      setCustomers(response.data.rows);
    } catch (err) {
      onError(err);
    }
  };

  const getSuppliers = async (value) => {
    try {
      const response = await API.get(`companies`, {
        params: { search: value, type: 'SUPPLIER' }
      });

      setSuppliers(response.data.rows);
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (organization?.type == 'MANUFACTURER') {
      getSuppliers();
    }
  }, [organization]);

  let nestedOptions = [
    {
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            {customerIcon()}
            <spam>Customer</spam>
          </div>
          <ChevronRight />
        </div>
      ),
      options: customers?.map((customer) => ({
        label: customer.name,
        value: customer.id,
        address: customer.billingAddress,
        code: customer.code,
        type: customer.type,
        name: customer.name
      })),
      entityType: 'Customer'
    }
  ];

  if (organization?.type == 'MANUFACTURER') {
    nestedOptions.push({
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            {supplierIcon()}
            <spam>Supplier</spam>
          </div>
          <ChevronRight />
        </div>
      ),
      options: suppliers?.map((supplier) => ({
        label: supplier.name,
        value: supplier.id,
        address: supplier.billingAddress,
        code: supplier.code,
        type: supplier.type,
        name: supplier.name
      })),
      entityType: 'Supplier'
    });
  }

  if (isFeatureEnabled(FLAGS.WAREHOUSE_CREATION)) {
    nestedOptions.push({
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            {organizationIcon()}
            <spam>Organization</spam>
          </div>
        </div>
      ),
      value: organization?.id,
      address: organization?.address,
      code: '-',
      type: organizationTypes[organization?.type],
      name: organization?.name,
      entityType: 'Organization'
    });
  }

  useEffect(() => {
    let options;
    if (formik.values.entity) {
      if (formik.values.entity.label == 'Organization') {
        if (organization.type == 'MANUFACTURER') {
          options = Object.keys(nodeTypes.MANUFACTURER_INTERNAL).map((key) => ({
            value: key,
            label: nodeTypes.MANUFACTURER_INTERNAL[key]
          }));
          setNodeTypeOptions(options);
        } else {
          options = Object.keys(nodeTypes['3PL_INTERNAL']).map((key) => ({
            value: key,
            label: nodeTypes['3PL_INTERNAL'][key]
          }));
          setNodeTypeOptions(options);
        }
      } else {
        options = Object.keys(nodeTypes.EXTERNAL).map((key) => ({
          value: key,
          label: nodeTypes.EXTERNAL[key]
        }));
        setNodeTypeOptions(options);
      }
    }
  }, [formik.values.entity, organization]);

  return (
    <div className={classes.paper}>
      <div className="mb-4">
        <Title className={'text-base'}>Create New Node</Title>
      </div>
      <div className={classes.dockDialog}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
          autoComplete="off"
          className={classes.dockInputField}
        >
          <FormControl fullWidth>
            <MultiSelect
              className={clsx(
                'py-[5.5px] px-[8.5px]',
                entityInfo && 'pointer-events-none'
              )}
              options={nestedOptions}
              value={formik.values.entity || {}}
              formik={formik}
              name="entity"
              setValue={(value) => {
                formik.setFieldValue('entity', value);
                if (value.entityType == 'Organization') {
                  formik.setFieldValue('nodeClass', 'INTERNAL');
                } else {
                  formik.setFieldValue('nodeClass', 'EXTERNAL');
                }
              }}
              size={'small'}
              getOptionLabel={(option) => option.name}
              placeholder="Entity"
            />
          </FormControl>

          <FormControl fullWidth>
            <FormikTextField
              className="mt-5"
              name="name"
              formik={formik}
              size={'large'}
              placeholder="Enter Name"
              sxProps={{
                '& .MuiInputBase-root': {
                  fontSize: '14px',
                  height: 36
                },
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                }
              }}
            />
          </FormControl>

          <Autocomplete
            className="mt-5"
            id="nodeType"
            options={nodeTypeOptions}
            onChange={async (e, value) => {
              if (value) {
                formik.setFieldValue('nodeType', value);
              } else {
                formik.setFieldValue('nodeType', '');
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values?.nodeType || ''}
            getOptionLabel={(type) => type?.label || ''}
            placeholder="Node Type"
          />
          {formik.touched?.nodeType && formik.errors?.nodeType?.value && (
            <span className="text-error text-xs">
              {formik.errors?.nodeType?.value}
            </span>
          )}

          <div className={'mt-5'}>
            <Switch
              checked={formik.values.isActive}
              onChange={() =>
                formik.setFieldValue('isActive', !formik.values.isActive)
              }
              label="Active"
            ></Switch>
          </div>

          <div className={classes.btnGroup}>
            <button
              className={classes.cancelBtn}
              type="button"
              onClick={() => setOpen(null)}
            >
              Cancel
            </button>
            <button
              className={classes.submitBtn}
              type="button"
              onClick={async () => {
                await submitForm(formik.values);
              }}
              disabled={!(formik.isValid && formik.dirty)}
            >
              {`Create`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNode;

import React, { useState, useRef, useCallback, useContext } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  makeStyles,
  Dialog,
  TableRow,
  FormControlLabel,
  FormGroup,
  Switch,
  withStyles,
  CircularProgress
} from '@material-ui/core';

import { SharedContext, decryptDate } from '../../utils/common';
import Scanning from '../../assets/icons/scanning.gif';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../Config';
import API from '../../libs/axios';
import { toaster } from '../../utils/toaster';
import moment from 'moment';
import classNames from 'classnames/bind';
import { ORGANIZATION_TYPES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  bdGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  btnYes: {
    marginLeft: 10,
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green'
    }
  },
  container: {
    padding: 20
  },
  scanningOverlay: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    left: '0',
    top: '0',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 11199999,
    overflow: 'hidden'
  },
  childLoader: {
    position: 'fixed',
    left: '50%',
    top: '50%'
  },
  scanImgOverlay: {
    width: '60%'
  },
  tableHeadText: {
    fontWeight: 'bolder'
  }
}));
const PurpleSwitch = withStyles({
  switchBase: {
    color: '#cecece',
    '&$checked': {
      color: 'blue'
    },
    '&$checked + $track': {
      backgroundColor: 'blue'
    }
  },
  checked: {},
  track: {}
})(Switch);

const ScanView = ({
  open,
  handleClose,
  setScannedProducts,
  formik,
  tableHeaders,
  source
}) => {
  const inputRef = useRef(null);
  const inputRefQuantity = useRef(null);
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [scanQr, setScanQr] = useState('');
  const [loading, setLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(true);

  const { organizationType } = useContext(SharedContext);

  const scanProductHandler = useCallback(
    debounce((val) => {
      keydownHandler(val);
    }, DEBOUNCE_CONST),
    []
  );

  const getValidDate = (date) => {
    return date ? moment(date, 'DD/MM/YYYY') : null;
  };

  const keydownHandler = async (product) => {
    if (!product.includes('}')) {
      product += '}'; //sometimes onchange does not change receive }, this line validates } at end.
    }

    try {
      product = JSON.parse(product);
    } catch (err) {
      setScanQr('');
      return false;
    } finally {
      setLoading(false);
    }
    product.mfgd = getValidDate(product?.mfgd).format('DD/MM/YYYY');
    product.expd = getValidDate(decryptDate(product?.expd)).format(
      'DD/MM/YYYY'
    );

    const companyId = formik.values.Order.companyId;
    const productId = product.pid;
    const warehouseId = formik.values.Order.warehousePickUpId;

    const columns = ['id'];
    const dbProduct = await API.get(`companies/${companyId}/products`, {
      params: {
        filters: {
          colVal: {
            id: product.pid
          }
        },
        columns
      }
    });

    const batches =
      source === 'GDN' &&
      (await API.get(`inventories/batches`, {
        params: {
          warehouseId,
          ...(organizationType ===
          ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
            ? { companyId }
            : {}),
          productId,
          orderId: formik?.values?.Order?.id
        }
      }));

    if (batches.data) {
      batches?.data[0]?.InventoryDetail?.map((batch) => {
        if (
          batch.batchNumber === product.batch &&
          moment(batch.expiryDate).format('DD/MM/YYYY') == product.expd
        ) {
          product.availableQuantity = batch.availableQuantity;
        }
      });
      if (!product.availableQuantity) {
        toaster('warning', 'Batch does not exist');
        setScanQr('');
        setLoading(false);
        return false;
      }
    }

    if (dbProduct?.data?.length) {
      product.name = dbProduct?.data[0]?.description;
      product['Product'] = dbProduct?.data[0];
      if (updateIfProductExists(product) === false) {
        if (source === 'GRN') {
          product.quantity = 1;
        } else if (source === 'GDN') {
          product.dispatchQuantity = 1;
        }

        let productsScanned = products;
        productsScanned.push(product);
        setProducts(productsScanned);
      }
    } else {
      toaster('warning', 'This product does not exist in current company');
    }

    setScanQr('');
    setTimeout(() => {
      setLoading(false);
    }, 10);
  };

  const updateIfProductExists = (product) => {
    let found = false;
    let scannedProducts = products;

    scannedProducts = scannedProducts.map((scannedProduct) => {
      if (
        scannedProduct.pid === product.pid &&
        scannedProduct.batch === product.batch &&
        scannedProduct.expd == product.expd
      ) {
        found = true;
        if (source === 'GRN') {
          ++scannedProduct.quantity;
        } else if (source === 'GDN') {
          ++scannedProduct.dispatchQuantity;
        }
      }

      return scannedProduct;
    });

    if (scannedProducts.length) {
      setProducts([...scannedProducts]);
    }

    return found;
  };

  const handleProductSubmit = () => {
    setScannedProducts(products);
  };

  const handleChangeQuantity = (value, product) => {
    inputRefQuantity.current.focus();
    setProducts((current) =>
      current.map((scannedProduct) => {
        if (
          scannedProduct.pid === product.pid &&
          scannedProduct.batch === product.batch &&
          scannedProduct.expd == product.expd
        ) {
          if (source === 'GRN') {
            setGRNQuantity(value, scannedProduct);
          } else if (source === 'GDN') {
            setGDNQuantity(value, product, scannedProduct);
          }
        }

        return scannedProduct;
      })
    );
  };

  const setGRNQuantity = (value, scannedProduct) => {
    scannedProduct.quantity = value;
  };

  const setGDNQuantity = (value, product, scannedProduct) => {
    if (value && Number(value) <= product.availableQuantity) {
      scannedProduct.dispatchQuantity = value;
    } else {
      if (value) {
        scannedProduct.dispatchQuantity = product.availableQuantity;
      } else {
        scannedProduct.dispatchQuantity = 0;
      }
    }
  };
  const scannerBtnClass = classNames({
    'display-flex': true,
    'justify-content-center': true,
    scanButtonResponsive: true
  });

  return (
    <>
      {loading ? (
        <div className={classes.scanningOverlay}>
          <CircularProgress className={classes.childLoader} />
        </div>
      ) : (
        ''
      )}
      {open && (
        <Dialog className={classes.backdrop} open={open}>
          {products.length > 0 ? (
            <TableContainer className={classes.container}>
              <div className="scanScrollTable">
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((header) => {
                        return (
                          <TableCell
                            key={tableHeaders.id}
                            className={classes.tableHeadText}
                          >
                            {header.value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => {
                      return (
                        <TableRow key={product.id}>
                          {tableHeaders.map((header) => {
                            return (
                              <>
                                {header.key === 'pid' ||
                                header.key === 'Product' ? (
                                  ''
                                ) : (
                                  <TableCell>
                                    {header.key === 'quantity' ||
                                    header.key === 'dispatchQuantity' ? (
                                      <input
                                        ref={inputRefQuantity}
                                        min={0}
                                        type="number"
                                        value={product[header.key]}
                                        onBlur={() => inputRef.current.focus()}
                                        onChange={(e) => {
                                          handleChangeQuantity(
                                            e.target.value,
                                            product
                                          );
                                        }}
                                      />
                                    ) : (
                                      <>{product[header.key]}</>
                                    )}
                                  </TableCell>
                                )}
                              </>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          ) : (
            <img src={Scanning} />
          )}

          <input
            ref={inputRef}
            style={{ border: 'none', color: 'white' }}
            type="text"
            value={scanQr}
            onChange={(e) => {
              setLoading(true);
              setScanQr(e.target.value);
              scanProductHandler(e.target.value);
            }}
            onBlur={() => {
              setIsScanning(false);
            }}
            autoFocus
          />
          <div className={scannerBtnClass}>
            <div className="mt-5 mb-5 ml-auto">
              <Button
                className="responsiveScannerBtn"
                variant="contained"
                onClick={() => {
                  setProducts([]);
                  setScannedProducts(null);
                  handleClose(false);
                }}
              >
                CANCEL
              </Button>
              {products.length > 0 ? (
                <Button
                  variant="contained"
                  className={`${classes.btnYes} ml-5 resposiveScanner`}
                  onClick={() => {
                    handleProductSubmit();
                  }}
                >
                  SUBMIT
                </Button>
              ) : null}
            </div>
            <div className="mt-5 mb-5 ml-auto">
              <FormGroup>
                <FormControlLabel
                  control={
                    <PurpleSwitch
                      checked={isScanning}
                      onChange={(e) => {
                        setIsScanning(e.target.checked);
                        inputRef.current.focus();
                      }}
                    />
                  }
                  label="Scanning"
                />
              </FormGroup>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ScanView;

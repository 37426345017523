const Drawer = ({ open, onClose, children }) => {
  return (
    <div>
      <div
        className={`fixed inset-0 bg-black-solid bg-opacity-10 transition-opacity ${
          open ? 'opacity-30' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
        style={{
          transition: 'opacity 0.3s ease-in-out'
        }}
      ></div>

      <div
        className={`fixed left-0 bottom-0 w-full bg-white rounded-t-2xl shadow-lg z-10 ${
          open ? 'translate-y-0' : 'translate-y-full'
        }`}
        style={{
          height: '85vh',
          transition: 'transform 0.3s ease-in-out',
          transform: open ? 'translateY(0)' : 'translateY(100%)',
          boxShadow: '0px -2px 10px rgba(0, 0, 255, 0.1)' // Subtle bluish shadow
        }}
      >
        {/* top Action Pill */}
        <div
          onClick={onClose}
          style={{
            width: '40px',
            height: '5px',
            backgroundColor: '#ccc', // Greyish color
            borderRadius: '5px',
            margin: '10px auto' // Center the pill
          }}
        ></div>

        {/* Drawer Content */}
        <div style={{ padding: '1rem' }}>{children}</div>
      </div>
    </div>
  );
};

export default Drawer;

/*eslint-disable*/
import React from 'react';
import {
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepLabel,
  styled,
  stepLabelClasses,
  stepConnectorClasses
} from '@mui/material';
import { Check } from '@material-ui/icons';

const StyledStepLabel = styled(StepLabel)(({ theme, center }) => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: center && 'column'
    // position: 'relative'
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(200%)',
    whiteSpace: 'nowrap'
  }
}));

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0B1940',
      borderStyle: 'solid',
      marginLeft: '12px', // Adjust the margin based on your preference
      borderWidth: '1px'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#6A7289',
    borderWidth: '1px',
    borderStyle: 'dashed',
    height: '1px',
    position: 'relative',
    top: 11
    // left: "-20px",
    // width: "calc(100% + 64px)"
  }
}));

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .StepIcon-completedIcon': {
    color: 'white',
    backgroundColor: 'black',
    borderRadius: '50%',
    zIndex: 1,
    fontSize: 18,
    width: 16,
    height: 16
  },
  '& .StepIcon-circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'white',
    border: '1px solid #6A7289'
  }
}));

function StepIcon(props) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="StepIcon-completedIcon" />
      ) : (
        <div className="StepIcon-circle" />
      )}
    </StepIconRoot>
  );
}

export default function Stepper({
  steps,
  activeStep,
  orientation = 'horizontal'
}) {
  return (
    <MuiStepper
      activeStep={activeStep}
      sx={{
        alignItems: 'flex-start',
        position: 'relative',
        /**
         * since the label doesn't account for width,
         * you'll need to apply a margin bottom
         **/
        mb: 4
      }}
      connector={<Connector />}
      orientation={orientation}
    >
      {steps.map((label, id) => (
        <Step key={label}>
          <StyledStepLabel
            StepIconComponent={StepIcon}
            center={id != 0 && id != steps.length - 1}
          >
            {label}
          </StyledStepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
}

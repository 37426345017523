import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

const FlashyBanner = ({ tenants, currentSubdomain }) => {
  const LOCKDAY = 5;
  const [tenantData, setTenantData] = useState(null);
  const location = useLocation();

  const isLoginPage = location.pathname === '/login';

  useEffect(() => {
    const tenant = tenants.find((t) => t.subdomain === currentSubdomain);

    if (tenant) {
      const today = new Date();
      const startDate = new Date(tenant.startDate);
      const remainingDays =
        LOCKDAY - Math.ceil((today - startDate) / (1000 * 3600 * 24));

      setTenantData({
        ...tenant,
        remainingDays: remainingDays > 0 ? remainingDays : 0
      });
    }
  }, [tenants, currentSubdomain]);

  if (
    tenantData &&
    tenantData.remainingDays >= 0 &&
    !isLoginPage &&
    tenantData.remainingDays <= LOCKDAY
  ) {
    const daysText =
      tenantData.remainingDays === 0
        ? 'Today'
        : `${tenantData.remainingDays} day${
            tenantData.remainingDays > 1 ? 's' : ''
          }`;

    const message = (
      <>
        <strong>Important:</strong> Your account will be locked{' '}
        <strong>{daysText}</strong> if payment is not processed.
        <br />
        Please take action to avoid any interruptions.
      </>
    );

    const bannerStyle = {
      position: 'relative',
      top: '50px',
      left: '25px',
      width: '100%',
      backgroundColor: '#f4f186',
      padding: '15px',
      textAlign: 'center',
      zIndex: '1000',
      fontSize: '20px'
    };

    return (
      <>
        <div className="banner" style={bannerStyle}>
          <p>{message}</p>
        </div>
      </>
    );
  }

  return null;
};

export default FlashyBanner;

export const addCardIcon = (fill) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0002 1.69922C9.0002 1.14693 8.55248 0.699219 8.0002 0.699219C7.44791 0.699219 7.0002 1.14693 7.0002 1.69922V6.99922H1.7002C1.14791 6.99922 0.700195 7.44693 0.700195 7.99922C0.700195 8.5515 1.14791 8.99922 1.7002 8.99922H7.0002V14.2992C7.0002 14.8515 7.44791 15.2992 8.0002 15.2992C8.55248 15.2992 9.0002 14.8515 9.0002 14.2992V8.99922H14.3002C14.8525 8.99922 15.3002 8.5515 15.3002 7.99922C15.3002 7.44693 14.8525 6.99922 14.3002 6.99922H9.0002V1.69922Z"
        fill={fill || '#0B1940'}
      />
    </svg>
  );
};

export const customerIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12.5C4.175 12.5 3.5075 13.175 3.5075 14C3.5075 14.825 4.175 15.5 5 15.5C5.825 15.5 6.5 14.825 6.5 14C6.5 13.175 5.825 12.5 5 12.5ZM12.5 12.5C11.675 12.5 11.0075 13.175 11.0075 14C11.0075 14.825 11.675 15.5 12.5 15.5C13.325 15.5 14 14.825 14 14C14 13.175 13.325 12.5 12.5 12.5ZM5.825 8.75H11.4125C11.975 8.75 12.47 8.4425 12.725 7.9775L15.5 2.72L14.1875 2L11.4125 7.25H6.1475L2.9525 0.5H0.5V2H2L4.7 7.6925L3.6875 9.5225C3.14 10.5275 3.86 11.75 5 11.75H14V10.25H5L5.825 8.75ZM8.75 0.5L11.75 3.5L8.75 6.5L7.6925 5.4425L8.8775 4.25H5.75V2.75H8.8775L7.685 1.5575L8.75 0.5Z"
        fill="#696F80"
      />
    </svg>
  );
};

export const supplierIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12.5C4.175 12.5 3.5075 13.175 3.5075 14C3.5075 14.825 4.175 15.5 5 15.5C5.825 15.5 6.5 14.825 6.5 14C6.5 13.175 5.825 12.5 5 12.5ZM12.5 12.5C11.675 12.5 11.0075 13.175 11.0075 14C11.0075 14.825 11.675 15.5 12.5 15.5C13.325 15.5 14 14.825 14 14C14 13.175 13.325 12.5 12.5 12.5ZM5.825 8.75H11.4125C11.975 8.75 12.47 8.4425 12.725 7.9775L15.5 2.72L14.1875 2L11.4125 7.25H6.1475L2.9525 0.5H0.5V2H2L4.7 7.6925L3.6875 9.5225C3.14 10.5275 3.86 11.75 5 11.75H14V10.25H5L5.825 8.75ZM8.75 0.5L11.75 3.5L8.75 6.5L7.6925 5.4425L8.8775 4.25H5.75V2.75H8.8775L7.685 1.5575L8.75 0.5Z"
        fill="#696F80"
      />
    </svg>
  );
};

export const organizationIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4.2625V12.25H12.5V6.25H3.5V12.25H2V4.2625L8 1.8625L14 4.2625ZM15.5 13.75V3.25L8 0.25L0.5 3.25V13.75H5V7.75H11V13.75H15.5ZM7.25 12.25H5.75V13.75H7.25V12.25ZM8.75 10H7.25V11.5H8.75V10ZM10.25 12.25H8.75V13.75H10.25V12.25Z"
        fill="#696F80"
      />
    </svg>
  );
};

export const Pin = () => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.0013 2.33464C7.60964 2.33464 10.168 3.5013 10.168 6.6263C10.168 8.4263 8.73464 10.518 6.0013 12.7263C3.26797 10.518 1.83464 8.41797 1.83464 6.6263C1.83464 3.5013 4.39297 2.33464 6.0013 2.33464ZM6.0013 0.667969C3.2763 0.667969 0.167969 2.71797 0.167969 6.6263C0.167969 9.2263 2.10964 11.968 6.0013 14.8346C9.89297 11.968 11.8346 9.2263 11.8346 6.6263C11.8346 2.71797 8.7263 0.667969 6.0013 0.667969Z"
        fill="#0C6BD7"
      />
      <path
        d="M6.0013 4.83464C5.08464 4.83464 4.33464 5.58464 4.33464 6.5013C4.33464 7.41797 5.08464 8.16797 6.0013 8.16797C6.44333 8.16797 6.86725 7.99237 7.17981 7.67981C7.49237 7.36725 7.66797 6.94333 7.66797 6.5013C7.66797 6.05927 7.49237 5.63535 7.17981 5.32279C6.86725 5.01023 6.44333 4.83464 6.0013 4.83464ZM0.167969 15.668H11.8346V17.3346H0.167969V15.668Z"
        fill="#0C6BD7"
      />
    </svg>
  );
};

import React from 'react';
import { Title } from '../../../core-components/atoms/Text';

// Dummy data for the styling of the bars
const dummyBars = [
  { color: 'bg-[#0B1940]' },
  { color: 'bg-[#0C6BD7]' },
  { color: 'bg-[#0B1940]' },
  { color: 'bg-[#0C6BD7]' }
];

const EmptyBarComponent = () => {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gray-100 blur-lg">
        <ul>
          {dummyBars.map((bar, index) => (
            <li key={index} className="mb-4">
              <div className={`h-6 rounded ${bar.color} opacity-30`}></div>
            </li>
          ))}
        </ul>
      </div>
      <div className="relative flex items-center justify-center h-32">
        <Title variant={'md'}>{'No data to display'}</Title>
      </div>
    </div>
  );
};

export default EmptyBarComponent;

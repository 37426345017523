/*eslint-disable*/
/* eslint-disable no-unused-vars */
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate, useParams } from 'react-router';
import { DEBOUNCE_CONST } from '../../../Config';
import NumberInput from '../../../atomicComponents/NumberInput';
import MessageSnackbar from '../../../components/MessageSnackbar';
import { ORGANIZATION_TYPES } from '../../../constants';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import {
  SharedContext,
  checkForMatchInArray,
  dividerDateFormatForFilter,
  productLabelFormat,
  reasonLabelFormat
} from '../../../utils/common';
import { toaster } from '../../../utils/toaster';
import { isRequired } from '../../../utils/validators';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 'bolder'
  },
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px',
    width: '100% !important',
    margin: '0px',
    '@media (max-width: 998px)': {
      padding: '10px 2px'
    }
  },
  pageHeading: {
    fontWeight: 600
  },
  customButtonSM: {
    width: '160px',
    padding: '14px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#005cfd',
    color: '#fff',
    fontSize: '14px',
    borderRadius: '4px'
  },
  pageSubHeading: {
    fontWeight: 300
  },
  customUISM: {
    textAlign: 'center',
    width: '500px',
    padding: '40px',
    background: '#fff',
    boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
    color: 'black'
  },
  customButtonCancel: {
    width: '160px',
    padding: '14px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#e0e0e0',
    color: '#1d1d1d',
    fontSize: '14px',
    borderRadius: '4px'
  }
}));

export default function AddStockManagement() {
  const { setAPILoader, organization } = useContext(SharedContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const { uid } = useParams();
  const IS_MANUFACTURER =
    organization?.type === ORGANIZATION_TYPES.MANUFACTURER;

  const [selectedInventoryWastages, setSelectedInventoryWastages] =
      useState(null),
    [warehouses, setWarehouses] = useState([]),
    [products, setProducts] = useState([]),
    [validation, setValidation] = useState({}),
    [availableQuantity, setAvailableQuantity] = useState(0),
    [recoverableDamageQuantity, setRecoverableDamageQuantity] = useState(0),
    [unsellableDamageQuantity, setUnsellableDamageQuantity] = useState(0),
    [availableInventoryQuantity, setAvailableInventoryQuantity] = useState(0),
    [recoverableInventoryQuantity, setRecoverableInventoryQuantity] =
      useState(0),
    [unsellableInventoryQuantity, setUnsellableInventoryQuantity] = useState(0),
    [uom, setUom] = useState(''),
    [warehouseId, setWarehouseId] = useState(''),
    [productId, setProductId] = useState(''),
    [selectedInventory, setSelectedInventory] = useState({}),
    [formErrors, setFormErrors] = useState([]),
    [showMessage, setShowMessage] = useState(null),
    [messageType, setMessageType] = useState(null),
    [fieldDisableByType, setfieldDiableByType] = useState(false);
  const [productLabel, setProductLabel] = useState('');
  const [reasons, setReasons] = useState([]); // to be displayed on dropdown
  const [conditions, setConditions] = useState([]); // to be displayed on dropdown
  const [quantity, setQuantity] = useState(0); // adjusted quantity
  const [remainingQuantity, setRemainingQuantity] = useState(0); // adjusted quantity
  const [reasonType, setReasonType] = useState(''); // selcted reason id
  const [stockCondition, setStockCondition] = useState(''); // selcted reason id
  const [comment, setComment] = useState(''); // optional comment
  const [adjustments, setAdjustments] = useState([]); // contains products along with adjusted quantities, will not be displayed at the bottom table
  const [adjustmentsSecondaryArray, setAdjustmentsSecondaryArray] = useState(
    []
  ); // contains more details of added products to be displayed at the bottom table
  const [warehouse, setWarehouse] = useState(null);
  const [reasonTypeLabel, setReasonTypeLabel] = useState(''); // selcted reason label for adding

  const [
    selectedInventoryWastageInventories,
    setSelectedInventoryWastageInventories
  ] = useState([]); // only for edit, to list all the inventories for edition
  const [existingBatches, setExistingBatches] = useState([]);
  const [batchId, setBatchId] = useState(null);
  const [hallId, setHallId] = useState(null);
  const [palletId, setPalletId] = useState(null);

  const [searchWarehouseValue, setSearchWarehouseValue] = useState('');
  const [searchProductValue, setSearchProductValue] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customers, setCustomers] = useState([]);
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [singleCustomer, setSingleCustomer] = useState(null);

  // If uid exists than fetch details of the selecteInventoryWastages
  useEffect(() => {
    if (uid) _getInventoryWastage(); // only in case of edit
  }, [uid]);

  useEffect(() => {
    getCompanies(searchCompanyValue);
  }, [searchCompanyValue]);

  useEffect(() => {
    getReasonsType();
  }, []);

  useEffect(() => {
    getConditionsType();
    setProductId('');
    setBatchId(null);
  }, [reasonTypeLabel]);

  const getWarehouseCallback = useCallback(
    debounce(({ searchWarehouseValue }) => {
      getWarehouses({ searchKeyword: searchWarehouseValue });
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getWarehouseCallback({ searchWarehouseValue });
  }, [searchWarehouseValue]);

  useEffect(() => {
    resetLocalStates();

    if (selectedInventoryWastages) {
      setWarehouseId(selectedInventoryWastages.Inventory.warehouseId);
    } else {
      getWarehouses({});
    }
  }, []);

  useEffect(() => {
    setProducts([]);
    setReasons([]);
    setProductLabel('');
    setProductId('');
    setHallId('');
    getReasonsType();
    setReasonTypeLabel('');
    setReasonType('');
    setComment('');
    setBatchId(null);
    setHallId(null);
    setPalletId(null);
    setAvailableInventoryQuantity(0);
    setRecoverableDamageQuantity(0);
    setUnsellableDamageQuantity(0);
    setQuantity(0);
    setStockCondition('');
  }, [warehouseId]);

  useEffect(() => {
    if (isRequired(warehouseId)) {
      getProducts({
        companyId: customerId,
        warehouseId,
        search: searchProductValue,
        columns: ['name'],
        stockCondition,
        reasonType: reasonTypeLabel
      }).then((aproducts) => {
        if (aproducts?.length) {
          setProducts([products, ...aproducts]);
        }
      });
    }
  }, [searchProductValue]);

  useEffect(() => {
    if (customers?.length === 1 && !searchCompanyValue.length) {
      setSingleCustomer(customers?.[0]);
      setCustomerId(customers?.[0].id);
    }
  }, [customers]);

  const getCompanies = (searchCompanyValue) => {
    let filters = {
      colVal: {
        name: searchCompanyValue,
        isActive: 1
      },
      sortable: ''
    };

    API.get(`companies`, {
      params: { filters }
    })
      .then((res) => {
        setCustomers(res.data.rows);
        setSingleCustomer(null);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    setProducts([]);
    setProductId('');
    setProductLabel('');
    setBatchId(null);
    setHallId(null);
    setAvailableInventoryQuantity(0);
    setRecoverableDamageQuantity(0);
    setUnsellableDamageQuantity(0);
    if (!warehouseId) return;
    if (selectedInventoryWastages) {
      setProducts([selectedInventoryWastages.Inventory.Product]);
    } else {
      // const warehouse = warehouses.find((element) => warehouseId == element.id)
      getProducts({
        companyId: customerId,
        warehouseId,
        stockCondition,
        reasonType: reasonTypeLabel
      }).then((products) => {
        return setProducts(products);
      }); // INPROGRESS: products with 0 available qty are also comming.
    }
  }, [stockCondition]);

  useEffect(() => {
    setUom('');
    setQuantity('');
    setAvailableQuantity(0);
    setBatchId(null);
    setHallId(null);
    setAvailableInventoryQuantity(0);
    setExistingBatches([]);
    // setHallId('')
    if (warehouseId && productId) {
      const product = products.find((product) => product.id == productId);
      setUom(product.UOM?.name);
      getInventory({ warehouseId, productId, stockCondition }).then(
        (inventory) => {
          if (inventory) {
            setSelectedInventory(inventory);
            checkInventoryAddedQuantityAndUpdate(inventory);
            setUnsellableInventoryQuantity(inventory.unsellableDamageQuantity);
            setRecoverableInventoryQuantity(
              inventory.recoverableDamageQuantity
            );
            setAvailableInventoryQuantity(inventory.availableQuantity);
            getBatches();
          }
        }
      );
    }
  }, [productId]);

  const dialogueUpdateConfirmationOpen = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUISM}>
            <h1 style={{ paddingBottom: '10px' }}>Confirm</h1>
            <p
              style={{
                fontSize: '20px',
                fontWeight: 600,
                paddingBottom: '14px'
              }}
            >
              Are you sure you want to Update Stock Adjustment?
            </p>
            <Tooltip title="No">
              <button className={classes.customButtonCancel} onClick={onClose}>
                NO
              </button>
            </Tooltip>
            <Tooltip title="Yes">
              <button
                className={classes.customButtonSM}
                onClick={() => {
                  handleUpdate();
                  onClose();
                }}
              >
                YES
              </button>
            </Tooltip>
          </div>
        );
      },
      overlayClassName: {}
    });
  };

  const dialogueOpen = (inventoryId, batchId) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteAction(inventoryId, batchId)
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  };

  const dialogueConfirmationOpen = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUISM}>
            <h1 style={{ paddingBottom: '10px' }}>Confirm</h1>
            <p
              style={{
                fontSize: '20px',
                fontWeight: 600,
                paddingBottom: '14px'
              }}
            >
              Are you sure you want to Add Stock Adjustment?
            </p>
            <Tooltip title="No">
              <button className={classes.customButtonCancel} onClick={onClose}>
                NO
              </button>
            </Tooltip>
            <Tooltip title="Yes">
              <button
                className={classes.customButtonSM}
                onClick={() => {
                  handleSubmit();
                  onClose();
                }}
              >
                YES
              </button>
            </Tooltip>
          </div>
        );
      },
      overlayClassName: {}
    });
  };

  const dialogueAddOpen = (adjustmentsSecondaryArray, idx) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteAddAction(adjustmentsSecondaryArray, idx)
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  };

  const deleteAddAction = (adjustmentsSecondaryArray, idx) => {
    let deletedItem = adjustmentsSecondaryArray.find(
      (adjustmentsSecondaryArray, _idx) => _idx == idx
    );
    let deletedQty = deletedItem ? deletedItem.quantity : 0;
    setAvailableInventoryQuantity(
      (availableInventoryQuantity + deletedQty).toFixed(3)
    );
    setRecoverableDamageQuantity(
      (recoverableDamageQuantity + deletedQty).toFixed(3)
    );
    setUnsellableDamageQuantity(
      (unsellableDamageQuantity + deletedQty).toFixed(3)
    );

    setAdjustments(
      adjustments
        .map((adjustment) => {
          adjustment.batches = adjustment.batches.filter(
            (batch) =>
              batch.id !== deletedItem.batchId ||
              batch.type !== deletedItem.reasonTypeId
          );
          if (adjustment.batches.length) return adjustment;
        })
        .filter((adjustment) => adjustment != undefined || null)
    );
    setAdjustmentsSecondaryArray(
      adjustmentsSecondaryArray.filter(
        (adjustmentsSecondaryArray, _idx) => _idx != idx
      )
    );
  };

  const handleCustomerSearch = (customerId) => {
    setCustomerId(customerId);
  };

  const deleteAction = (inventoryId, batchId) => {
    removeFromAdjustmentArrayForEdit(inventoryId, batchId);
  };

  function checkInventoryAddedQuantityAndUpdate(inventory) {
    let inventoryAddedQuantity = 0;
    for (const record of adjustmentsSecondaryArray) {
      if (record.batch.Inventory.id === inventory.id) {
        if (record.reasonType == 'NEW_FOUND') {
          inventoryAddedQuantity -= record.quantity;
        } else {
          inventoryAddedQuantity += record.quantity;
        }
      }
    }

    setAvailableInventoryQuantity(
      (inventory.availableQuantity - inventoryAddedQuantity).toFixed(3)
    );
    setRecoverableDamageQuantity(
      (inventory.recoverableDamageQuantity - inventoryAddedQuantity).toFixed(3)
    );
    setUnsellableDamageQuantity(
      (inventory.unsellableDamageQuantity - inventoryAddedQuantity).toFixed(3)
    );
  }

  useEffect(() => {
    const product = products.find((product) => product.id == productId);
    if (isRequired(batchId) && product) {
      var availableQty = existingBatches.find(
        (batch) => batch.id == batchId
      )?.availableQuantity;

      var recoverableQty = existingBatches.find(
        (batch) => batch.id == batchId
      )?.recoverableDamageQuantity;

      var unsellableQty = existingBatches.find(
        (batch) => batch.id == batchId
      )?.unsellableDamageQuantity;

      setAvailableQuantity(parseFloat(availableQty).toFixed(3));
      setRecoverableDamageQuantity(parseFloat(recoverableQty).toFixed(3));
      setUnsellableDamageQuantity(parseFloat(unsellableQty).toFixed(3));

      setRemainingQuantity(
        parseFloat(
          stockCondition === 'GOOD'
            ? availableQty
            : stockCondition === 'RECOVERABLE'
            ? recoverableQty
            : stockCondition === 'UNSELLABLE'
            ? unsellableQty
            : 0
        )
      );
    } else {
      setAvailableQuantity(parseFloat(0));
      setRecoverableDamageQuantity(parseFloat(0));
      setUnsellableDamageQuantity(parseFloat(0));
      setRemainingQuantity(parseFloat(0));
    }
  }, [batchId]);

  const getBatches = () => {
    API.get(`warehouses/${warehouseId}/inwards/existing-batches`, {
      params: {
        productId: productId,
        stockCondition,
        reasonType: reasonTypeLabel,
        companyId: null
      }
    }).then((response) => {
      if (response.batches?.length) {
        // filter batches with 0 available quantity
        let filteredBatches = response.batches;
        if (reasonTypeLabel === 'NEW_FOUND') {
          filteredBatches = response.batches.filter(
            (batch) => batch.availableQuantity >= 0 && !batch?.Pallet
          );
        }
        // if (
        //   filteredBatches.length &&
        //   filteredBatches[0].batchName.includes('default')
        // ) {
        //   setBatchId(filteredBatches[0].id);
        // }
        setExistingBatches(filteredBatches);
      }
    });
  };

  // will be called for
  const resetLocalStates = () => {
    setValidation({});
    setReasons([]);
    setWarehouses([]);
    setWarehouseId('');
    setProducts([]);
    setProductLabel('');
    setProductId('');
    setHallId('');
    getReasonsType();
    setReasonType('');
    setComment('');
    setBatchId(null);
    setHallId(null);
    setAvailableInventoryQuantity(0);
    setRecoverableDamageQuantity(0);
    setUnsellableDamageQuantity(0);
    setQuantity(0);
    setReasonTypeLabel('');
  };

  const getReasonsType = () => {
    API.get(`stock-adjustments/wastage-types`)
      .then((res) => {
        setReasons(res.data);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  const getConditionsType = () => {
    if (reasonTypeLabel !== 'NEW_FOUND' && reasonTypeLabel !== '') {
      setConditions(['Good', 'Recoverable', 'Unsellable']);
    } else if (reasonTypeLabel === 'NEW_FOUND') {
      setConditions(['Good']);
    } else {
      setConditions(['']);
    }
  };

  const _getInventoryWastage = () => {
    API.get(`stock-adjustments/${uid}`)
      .then((res) => {
        if (res.stockAdjustment) {
          setSelectedInventoryWastages(res.stockAdjustment);
          const modifiedInventories =
            res.stockAdjustment.AdjustmentInventory.map((inventory) => {
              let initialEditedQuantity = 0;
              inventory.StockAdjustmentBatch.map((batch) => {
                // shifting to adjustment inventory
                // batch.InventoryDetail.availableQuantity + batch.quantity
                initialEditedQuantity += batch.quantity;
                batch['actualAvailableQuantity'] =
                  batch.InventoryDetail.availableQuantity + batch.quantity; // saving qty before adjustment
                batch['dirtyData'] = false;
                batch['editedQty'] = batch.quantity;
                return batch;
              });
              inventory['initialEditedQuantity'] = initialEditedQuantity;
              return inventory;
            });
          setSelectedInventoryWastageInventories(modifiedInventories || []);
        }
      })
      .catch((error) => {
        console.info(error);
      });
  };

  const getInventory = (params) => {
    let { warehouseId, productId } = params;
    if (warehouseId && productId)
      return API.get(
        `dispatch-orders/warehouses/${warehouseId}/products/${productId}/inventories`,
        { companyId: customerId }
      ).then((res) => res.inventory);
  };

  const getWarehouses = async ({ searchKeyword }) => {
    setAPILoader(true);
    const response = await API.get('dispatch-orders/warehouses', {
      params: {
        search: searchKeyword,
        companyId: customerId,
        columns: ['businessWarehouseCode', 'name']
      }
    });
    setWarehouses(response.data);
    setAPILoader(false);
  };

  const addAdjustments = (data) => {
    let apiPromise = null;
    setAPILoader(true);
    if (!selectedInventoryWastages) {
      const url = window.location.href.replace(
        'create',
        'view/stockAdjustmentId'
      );
      apiPromise = API.post(`stock-Adjustments`, {
        ...data,
        hostUrl: url
      });
    } else {
      apiPromise = API.put(
        `stock-Adjustments/${selectedInventoryWastages.id}`,
        data
      );
    }
    apiPromise
      .then(() => {
        if (selectedInventoryWastages) {
          toaster(
            'success',
            'Stock Adjustments have been updated successfully.'
          );
          mixpanel.track('Stock Adjustment Updation', {
            stockId: selectedInventoryWastages?.id
          });
        } else {
          toaster('success', 'New Adjustments have been created.');
          mixpanel.track('Stock Adjustment Created', {});
        }
        setTimeout(() => {
          navigate('/operations/stock-adjustment');
        }, 2000);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        setAPILoader(false);
      });
    setAPILoader(false);
  };

  const getProducts = (params) => {
    let { warehouseId } = params;

    return API.get(`dispatch-orders/warehouses/${warehouseId}/products`, {
      params
    }).then((res) => {
      return res.data;
    });
  };

  const updateAdjustmentsTable = () => {
    if (
      isRequired(warehouseId) &&
      isRequired(productId) &&
      isRequired(quantity) &&
      isRequired(reasonType) &&
      isRequired(batchId)
    ) {
      // checking if particular product is already added once with a specific reason type
      // if yes
      if (
        checkForMatchInArray(
          adjustmentsSecondaryArray,
          'reasonType',
          reasonTypeLabel
        ) &&
        checkForMatchInArray(adjustments, 'warehouseId', warehouseId) &&
        checkForMatchInArray(adjustments, 'productId', productId) &&
        checkForMatchInArray(adjustmentsSecondaryArray, 'batchId', batchId) &&
        checkForMatchInArray(
          adjustmentsSecondaryArray,
          'uniqueFlag',
          `${batchId || ''}-${reasonType}`
        ) &&
        checkForMatchInArray(
          adjustmentsSecondaryArray,
          'conditionType',
          stockCondition
        )
      ) {
        toaster(
          'warning',
          'This product is already added from this warehouse, please choose a different reason type.'
        );
      }
      // if no
      else {
        let inventoryAddedQuantity = 0;
        for (const record of adjustmentsSecondaryArray) {
          if (record.batch.Inventory.id === selectedInventory.id) {
            inventoryAddedQuantity += record.quantity;
          }
        }
        if (reasonTypeLabel == 'NEW_FOUND') {
          setAvailableInventoryQuantity(
            selectedInventory.availableQuantity?.toFixed(3)
          );
          setAvailableQuantity((+availableQuantity + +quantity)?.toFixed(3));
        } else {
          setAvailableInventoryQuantity(
            selectedInventory.availableQuantity?.toFixed(3)
          );
          setAvailableQuantity((+availableQuantity - +quantity).toFixed(3));
          setRecoverableInventoryQuantity(
            selectedInventory.recoverableDamageQuantity?.toFixed(3)
          );
          setRecoverableDamageQuantity(
            (+recoverableDamageQuantity - +quantity).toFixed(3)
          );
          setUnsellableInventoryQuantity(
            selectedInventory.unsellableDamageQuantity.toFixed(3)
          );
          setUnsellableDamageQuantity(
            (+unsellableDamageQuantity - +quantity).toFixed(3)
          );
        }

        // checkInventoryAddedQuantityAndUpdate(selectedInventory)
        // setRemainingQuantity(remainingQuantity - quantity) // change remaining qty
        // double check if adjustment quantity is less than available quantity
        var productAdjustments = adjustments.filter(
          (adjustment) =>
            adjustment.productId == productId &&
            adjustment.warehouseId == warehouseId &&
            adjustments.batchId == batchId
        );
        var totalAdjustedQuantity = quantity;
        if (productAdjustments.length) {
          for (let adjustment of productAdjustments) {
            totalAdjustedQuantity += parseFloat(adjustment.quantity);
          }
          if (totalAdjustedQuantity > availableQuantity) {
            toaster(
              'warning',
              'The adjustment quantity can not be greater than available quantity.'
            );
            setRemainingQuantity(availableQuantity);
            return 0;
          }
        }
        setMessageType('green');
        var prevBatches = adjustments.find(
          (adjustment) =>
            adjustment.productId == productId &&
            adjustment.warehouseId == warehouseId
        )
          ? adjustments.find((adjustment) => adjustment.productId == productId)
              .batches
          : [];
        if (
          adjustments.find(
            (adjustment) =>
              adjustment.productId == productId &&
              adjustment.warehouseId == warehouseId
          )
        ) {
          setAdjustments([
            ...adjustments.filter(
              (adjustment) =>
                adjustment.productId != productId ||
                adjustment.warehouseId != warehouseId
            ),
            {
              ...adjustments.find(
                (adjustment) =>
                  adjustment.productId == productId &&
                  adjustment.warehouseId == warehouseId
              ),
              batches: [
                ...prevBatches,
                {
                  id: batchId,
                  type: reasonType,
                  quantity,
                  hallId,
                  comment,
                  stockCondition
                }
              ]
            }
          ]);
        } else {
          setAdjustments([
            ...adjustments,
            {
              productId: productId,
              warehouseId,
              warehouse: warehouse,
              batches: [
                {
                  id: batchId,
                  type: reasonType,
                  hallId,
                  palletId: palletId,
                  quantity,
                  comment,
                  stockCondition
                }
              ]
            }
          ]);
        }

        // will be sent to the backend
        setAdjustmentsSecondaryArray([
          ...adjustmentsSecondaryArray,
          {
            product: products.find((_product) => _product.id == productId),
            warehouse: warehouses.find(
              (warehouse) => warehouse.id == warehouseId
            ),
            availableQuantity,
            reasonType: reasonTypeLabel,
            reasonTypeId: reasonType,
            comment,
            quantity,
            batchId,
            hallId,
            batch: existingBatches.find((batch) => batch.id == batchId),
            uniqueFlag: `${batchId || ''}-${reasonType}`,
            stockCondition
          }
        ]);
        // resetting
        setValidation({});
        setReasons([]);
        setConditions([]);
        setQuantity(0);
        setReasonType('');
        setComment('');
        setBatchId(null);
        setHallId(null);
        setPalletId(null);
        setAvailableInventoryQuantity(0);
        setRecoverableDamageQuantity(0);
        setUnsellableDamageQuantity(0);
        setProductLabel('');
        getReasonsType('');
        getConditionsType();
        setStockCondition('');
        setReasonTypeLabel('');
      }
    } else {
      setUom('');
      setQuantity('');
      setValidation({
        warehouseId: true,
        productId: true,
        quantity: true,
        reasonType: true,
        batchId: true
      });
    }
  };

  // Done: uncomment dispatch orderId when DO is created
  const handleSubmit = () => {
    setMessageType('green');
    const adjustmentsObject = {
      // customerId,
      // warehouseId,
      adjustment_products: adjustments
    };

    setValidation({
      warehouseId: true
    });
    addAdjustments(adjustmentsObject);
    getConditionsType();
  };

  const verifyEditedAdjustmentQty = () => {
    return new Promise((resolve, reject) => {
      for (const inventory of selectedInventoryWastageInventories) {
        for (const batch of inventory.StockAdjustmentBatch) {
          // verify if qty is 0 and not soft deleted
          if (!isRequired(batch.quantity) && !batch['softDelete'])
            return reject(false);
        }
        return resolve(true);
      }
    });
  };

  const handleUpdate = async () => {
    const adjustment_products = [];
    setMessageType('green');

    verifyEditedAdjustmentQty()
      .then(() => {
        setMessageType('green');
        selectedInventoryWastageInventories.forEach((inventory) => {
          for (const _batch of inventory.StockAdjustmentBatch) {
            const { comment, quantity } = _batch;

            const alreadyAddedProduct = adjustment_products.find(
              (product) => product.inventoryId === inventory.Inventory.id
            );

            if (alreadyAddedProduct) {
              const alreadyAddedBatchWithSameReason =
                alreadyAddedProduct.batches.find(
                  (batch) =>
                    batch.id == _batch.InventoryDetail.id &&
                    batch.type == _batch.WastagesType.id
                );
              if (alreadyAddedBatchWithSameReason) {
                throw new Error(
                  'This product is already added from this warehouse, please choose a different reason type.'
                );
              }

              alreadyAddedProduct.batches.push({
                id: _batch.InventoryDetail.id,
                type: _batch.WastagesType.id,
                hallId: _batch.WarehouseHall.id,
                comment,
                quantity
              });
            } else
              adjustment_products.push({
                inventoryId: inventory.Inventory.id,
                batches: [
                  {
                    id: _batch.InventoryDetail.id,
                    type: _batch.WastagesType.id,
                    hallId: _batch.WarehouseHall.id,
                    comment,
                    quantity
                  }
                ]
              });
          }
        });

        const adjustmentsObject = {
          adjustment_products
        };

        addAdjustments(adjustmentsObject);
      })
      .catch((err) => {
        if (err.message) {
          toaster('warning', err.message);
        } else
          toaster(
            'warning',
            'Please make sure you have entered valid adjustment quantity.'
          );
      });
  };

  // For edit only
  // For udpating the values of individual adjustment
  const handleEditForBatch = (
    value,
    IdOfAdjustmentToBeAltered,
    batchId,
    name
  ) => {
    if (isNaN(value) && name === 'quantity') {
      value = 0;
    }
    setSelectedInventoryWastageInventories(() => {
      // Explain : We'll have to add another property in each inventory to track/calculate the remaining qty on real time
      return [
        ...selectedInventoryWastageInventories.map((inventory) => {
          if (inventory.Inventory.id === IdOfAdjustmentToBeAltered) {
            let totalEditedQty = 0;

            const _batch = inventory.StockAdjustmentBatch.find(
              (batch) => batch.id === batchId
            );

            if (name === 'reason') {
              _batch.WastagesType.id = value;
              // _batch[name] = value
            } else _batch[name] = value;
            name === 'quantity'
              ? (_batch['dirtyData'] = true)
              : (_batch['dirtyData'] = false);

            //update total edited quantity
            inventory.StockAdjustmentBatch.forEach((batch) => {
              totalEditedQty += batch.quantity;
              batch['totalEditedQty'] = totalEditedQty;
            });

            if (_batch.WastagesType.id == 5) {
              inventory.Inventory['remainingQuantity'] =
                inventory.Inventory.availableQuantity -
                inventory.initialEditedQuantity +
                totalEditedQty;
            } else {
              inventory.Inventory['remainingQuantity'] =
                inventory.Inventory.availableQuantity -
                inventory.initialEditedQuantity -
                totalEditedQty;
            }
            inventory.Inventory['totalEditedQty'] = totalEditedQty;
          }
          return inventory;
        })
      ];
    });
  };

  const calcTotalEditedQuantity = (inventory, batchId, currentValue) => {
    let totalEditedQuantity = 0;
    for (const batch of inventory.StockAdjustmentBatch) {
      if (batch.id === batchId) {
        totalEditedQuantity += parseFloat(currentValue);
      } else totalEditedQuantity += parseFloat(batch.quantity);
    }
    inventory['totalEditedQuantity'] = totalEditedQuantity;
    return inventory;
  };

  const removeFromAdjustmentArrayForEdit = (
    IdOfAdjustmentToBeAltered,
    batchId
  ) => {
    for (let inventory of selectedInventoryWastageInventories) {
      if (inventory.Inventory.id == IdOfAdjustmentToBeAltered) {
        let filteredBatches = inventory.StockAdjustmentBatch.filter(
          (batch) => batch.id != batchId
        );
        inventory.StockAdjustmentBatch = filteredBatches;
        setSelectedInventoryWastageInventories((prevState) => {
          return [
            ...prevState.filter(
              (inv) => inv.Inventory.id != IdOfAdjustmentToBeAltered
            ),
            inventory
          ];
        });
      }
    }
  };

  return (
    <>
      {formErrors}
      {!selectedInventoryWastages ? (
        <Grid container className={classes.parentContainer} spacing={1}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={8} sm={10} md={11}>
              <Typography variant="h3" className={classes.heading}>
                {' '}
                {selectedInventoryWastages
                  ? 'Edit Stock Adjustment'
                  : 'Add Stock Adjustment'}{' '}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} md={1}>
              <Tooltip title="Cancel">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/operations/stock-adjustment')}
                >
                  Cancel
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            {IS_MANUFACTURER || (
              <Grid item xs={12} sm={6}>
                {
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="customerId"
                      options={customers}
                      value={singleCustomer}
                      // defaultValue={selectedInventoryWastages ? { name: selectedInventoryWastages.Inventory.Company.name, id: selectedInventoryWastages.Inventory.Company.id } : <Typography color="error"></Typography>}
                      getOptionLabel={(customer) => customer.name || ''}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleCustomerSearch(
                            newValue.id,
                            newValue.name || ''
                          );
                          setSingleCustomer(newValue);
                        } else {
                          setCustomerId(null);
                          setSingleCustomer(null);
                        }
                      }}
                      onKeyUp={(event) => {
                        setSearchCompanyValue(event.target.value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Company (Optional)"
                          variant="outlined"
                        />
                      )}
                      disabled={
                        fieldDisableByType || !!selectedInventoryWastages
                      }
                    />
                  </FormControl>
                }
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={!IS_MANUFACTURER ? 6 : 12}
              lg={!IS_MANUFACTURER ? 6 : 12}
            >
              <FormControl margin="dense" fullWidth={true} variant="outlined">
                <Autocomplete
                  id="warehouse"
                  key={'warehouses'}
                  options={warehouses}
                  getOptionLabel={(warehouse) =>
                    warehouse.businessWarehouseCode || warehouse.name
                      ? `${warehouse.name} ${
                          warehouse?.businessWarehouseCode
                            ? `- ${warehouse.businessWarehouseCode}`
                            : ''
                        }`
                      : ''
                  }
                  value={warehouse}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setWarehouseId(newValue.id);
                      setWarehouse(newValue);
                      setSearchWarehouseValue('');
                    } else {
                      setWarehouseId(null);
                      setWarehouse(null);
                      setSearchWarehouseValue('');
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setSearchWarehouseValue(newValue);
                  }}
                  onFocus={() => {
                    setSearchWarehouseValue('');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Warehouse"
                      variant="outlined"
                    />
                  )}
                  onBlur={() =>
                    setValidation({ ...validation, warehouseId: true })
                  }
                  disabled={!!selectedInventoryWastages}
                />
                {validation.warehouseId && !isRequired(warehouseId) ? (
                  <Typography color="error">Warehouse is required!</Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {/* Product Details */}
          <Grid container item xs={12} alignItems="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="reasonType"
                  key={reasons}
                  options={reasons}
                  renderOption={(props) => {
                    return (
                      <span {...props} className="full-width">
                        <p>{reasonLabelFormat(props)}</p>
                      </span>
                    );
                  }}
                  getOptionLabel={(reasons) => {
                    if (reasons?.name == 'NEW_FOUND') {
                      return 'Additional Stock Identified';
                    } else {
                      return (
                        reasons.name.charAt(0).toUpperCase() +
                          reasons.name.slice(1).toLowerCase() || ''
                      );
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setReasonType(newValue.id);

                      if (newValue?.name == 'NEW_FOUND') {
                        setReasonTypeLabel('NEW_FOUND');
                      } else {
                        setReasonTypeLabel(
                          newValue.name.charAt(0).toUpperCase() +
                            newValue.name.slice(1).toLowerCase()
                        );
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reason Type"
                      variant="outlined"
                    />
                  )}
                  onBlur={() =>
                    setValidation({ ...validation, reasonType: true })
                  }
                  disabled={!!selectedInventoryWastages}
                />
                {validation.reasonType && !isRequired(reasonType) ? (
                  <Typography color="error">
                    Reason type is required!
                  </Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="conditionType"
                  key={conditions}
                  options={conditions}
                  getOptionLabel={(condition) => condition || ''}
                  onChange={(event, newValue) => {
                    setStockCondition(newValue.toUpperCase());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Condition Type"
                      variant="outlined"
                    />
                  )}
                />
                {validation.reasonType && !isRequired(reasonType) ? (
                  <Typography color="error">
                    Condition type is required!
                  </Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              // margin="dense"
              fullWidth={true}
              variant="outlined"
            >
              <Autocomplete
                id="product"
                key={'products'}
                options={products}
                inputValue={productLabel}
                getOptionLabel={(product) => productLabelFormat(product)}
                filterOptions={(options) => options}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setProductId(newValue.id);
                    setProductLabel(newValue.name);
                  } else {
                    setProductId(null);
                    setProductLabel('');
                  }
                }}
                onInputChange={(event, newValue) => {
                  setProductLabel(newValue);
                  setSearchProductValue(newValue);
                }}
                onFocus={() => {
                  setProductLabel('');
                  setSearchProductValue('');
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Product" variant="outlined" />
                )}
                onBlur={() => setValidation({ ...validation, productId: true })}
                disabled={!!selectedInventoryWastages}
              />
              {validation.productId && !isRequired(productId) ? (
                <Typography color="error">Product is required!</Typography>
              ) : (
                <Typography color="error" className="display-sortable">
                  Dummy
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth={true}
              InputProps={{ inputProps: { maxLength: 300 } }}
              id="quantity"
              label="Comment"
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={!!selectedInventoryWastages}
            />
            {validation.reasonType && !isRequired(reasonType) ? (
              <Typography color="error" className="display-sortable">
                Reason type is required!
              </Typography>
            ) : (
              <Typography color="error" className="display-sortable">
                Dummy
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth={true}>
              {products.find((product) => product.id == productId) ? (
                <Autocomplete
                  id="batch"
                  key={existingBatches}
                  options={existingBatches}
                  getOptionLabel={(batch) => {
                    return (
                      `${batch.batchNumber || ''}${
                        batch.batchNumber ? ',' : ''
                      }${dividerDateFormatForFilter(batch.expiryDate)} ${
                        batch?.WarehouseHall?.name
                      } ${batch?.Pallet?.palletId ? '-' : ''} ${
                        batch?.Pallet?.palletId || ''
                      }` || ''
                    );
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setBatchId(newValue.id);
                      setHallId(newValue?.WarehouseHall?.id);
                      setPalletId(newValue?.Pallet?.id);
                    } else {
                      setBatchId(null);
                      setHallId(null);
                      setPalletId(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Batch + Expiry + Section + Pallet"
                      variant="outlined"
                    />
                  )}
                  onBlur={() => setValidation({ ...validation, batchId: true })}
                  disabled={
                    products.find((product) => product.id == productId)
                      ? false
                      : true
                  }
                />
              ) : (
                <TextField
                  fullWidth={true}
                  id="Batch + Expiry + Section + Pallet"
                  label="Batch + Expiry + Section + Pallet"
                  type="text"
                  variant="filled"
                  disabled
                />
              )}
              {validation.batchId && !isRequired(batchId) ? (
                <Typography color="error">
                  Batch + Expiry + Section is required.
                </Typography>
              ) : (
                <Typography color="error" className="display-sortable">
                  Dummy
                </Typography>
              )}
            </FormControl>
          </Grid>
          {stockCondition === 'GOOD' ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <NumberInput
                  fullWidth={true}
                  id="quantityAdjust"
                  label="Quantity to adjust"
                  variant="outlined"
                  value={quantity}
                  disabled={!!selectedInventoryWastages}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value == '') {
                      value = 0;
                    } else {
                      value = parseFloat(e.target.value);
                    }
                    if (
                      value > availableQuantity &&
                      reasonTypeLabel !== 'NEW_FOUND'
                    ) {
                      value = availableQuantity;
                    }
                    setQuantity(value);
                  }}
                />
                {validation.quantity && !isRequired(quantity) ? (
                  <Typography color="error">Quantity is required!</Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <NumberInput
                  fullWidth={true}
                  id="availableQuantity"
                  label={`Inventory Available Quantity`}
                  variant="filled"
                  value={availableInventoryQuantity}
                  disabled
                />
                {validation.reasonType && !isRequired(reasonType) ? (
                  <Typography color="error" className="display-sortable">
                    DUMMY
                  </Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </Grid>
            </>
          ) : null}
          {stockCondition === 'RECOVERABLE' ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <NumberInput
                  fullWidth={true}
                  id="quantityAdjust"
                  label="Quantity to adjust"
                  variant="outlined"
                  value={quantity}
                  disabled={!!selectedInventoryWastages}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value == '') {
                      value = 0;
                    } else {
                      value = parseFloat(e.target.value);
                    }
                    if (value > recoverableDamageQuantity) {
                      value = recoverableDamageQuantity;
                    }
                    setQuantity(value);
                  }}
                  // onBlur={e => setValidation({ ...validation, quantity: true })}
                />
                {validation.quantity && !isRequired(quantity) ? (
                  <Typography color="error">Quantity is required!</Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <NumberInput
                  fullWidth={true}
                  id="recoverableDamageQuantity"
                  label={`Inventory Damaged Quantity`}
                  variant="filled"
                  value={(
                    unsellableInventoryQuantity + recoverableInventoryQuantity
                  ).toFixed(3)}
                  disabled
                />
                {validation.reasonType && !isRequired(reasonType) ? (
                  <Typography color="error" className="display-sortable">
                    DUMMY
                  </Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </Grid>
            </>
          ) : null}
          {stockCondition === 'UNSELLABLE' ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <NumberInput
                  fullWidth={true}
                  id="quantityAdjust"
                  label="Quantity to adjust"
                  variant="outlined"
                  value={quantity}
                  disabled={!!selectedInventoryWastages}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value == '') {
                      value = 0;
                    } else {
                      value = parseFloat(e.target.value);
                    }
                    if (value > unsellableDamageQuantity) {
                      value = unsellableDamageQuantity;
                    }
                    setQuantity(value);
                  }}
                  // onBlur={e => setValidation({ ...validation, quantity: true })}
                />
                {validation.quantity && !isRequired(quantity) ? (
                  <Typography color="error">Quantity is required!</Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <NumberInput
                  fullWidth={true}
                  id="unsellableDamageQuantity"
                  label={`Inventory Damaged Quantity`}
                  variant="filled"
                  value={(
                    unsellableInventoryQuantity + recoverableInventoryQuantity
                  ).toFixed(3)}
                  disabled
                />
                {validation.reasonType && !isRequired(reasonType) ? (
                  <Typography color="error" className="display-sortable">
                    DUMMY
                  </Typography>
                ) : (
                  <Typography color="error" className="display-sortable">
                    Dummy
                  </Typography>
                )}
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth={true}
              // margin="normal"
              id="batchQuantity"
              label="Batch & Section Quantity"
              type="number"
              variant="filled"
              value={
                stockCondition === 'GOOD'
                  ? availableQuantity
                  : stockCondition === 'RECOVERABLE'
                  ? recoverableDamageQuantity
                  : stockCondition === 'UNSELLABLE'
                  ? unsellableDamageQuantity
                  : '-'
              }
              disabled
            />
            {validation.reasonType && !isRequired(reasonType) ? (
              <Typography color="error" className="display-sortable">
                DUMMY
              </Typography>
            ) : (
              <Typography color="error" className="display-sortable">
                Dummy
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth={true}
              // margin="normal"
              id="uom"
              label="UoM"
              type="text"
              variant="filled"
              value={uom}
              disabled
            />
            {validation.reasonType && !isRequired(reasonType) ? (
              <Typography color="error" className="display-sortable">
                DUMMY
              </Typography>
            ) : (
              <Typography color="error" className="display-sortable">
                Dummy
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip title="Add Entry in Table">
              <Button
                variant="contained"
                onClick={updateAdjustmentsTable}
                color="primary"
                fullWidth
                disabled={!!selectedInventoryWastages}
              >
                Add
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.parentContainer} spacing={3} xs={12}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={8} sm={10} md={11}>
              <Typography variant="h3" className={classes.heading}>
                {' '}
                Edit Stock Adjustment{' '}
              </Typography>
            </Grid>
            <Grid item md={1} sm={2} xs={4}>
              <Tooltip title="Cancel">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/operations/stock-adjustment')}
                >
                  Cancel
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!selectedInventoryWastages ? (
        <TableContainer className={classes.parentContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  WAREHOUSE
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  PRODUCT
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  ADJUSTED QUANTITY
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  UoM
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  BATCH + SECTION + PALLET
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  EXPIRY DATE
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  REASON
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  COMMENT
                </TableCell>
                <TableCell>ACTION</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {adjustmentsSecondaryArray.map((adjustment, idx) => {
                return (
                  <TableRow hover role="checkbox" key={idx}>
                    <TableCell>
                      {adjustment?.warehouse?.businessWarehouseCode ||
                        adjustment?.warehouse?.name ||
                        ''}
                    </TableCell>
                    <TableCell>
                      {productLabelFormat(adjustment?.product)}
                    </TableCell>
                    <TableCell>{adjustment.quantity}</TableCell>
                    <TableCell>{adjustment.product.UOM.name}</TableCell>
                    <TableCell>
                      {`${adjustment.batch.batchNumber || ''}-${
                        adjustment.batch?.WarehouseHall?.name
                      } ${adjustment?.batch?.Pallet?.palletId ? '-' : ''} ${
                        adjustment.batch?.Pallet?.palletId || ''
                      }` || '-'}
                    </TableCell>
                    <TableCell>
                      {adjustment.product.batchEnabled
                        ? dividerDateFormatForFilter(
                            adjustment.batch.expiryDate
                          ) || '-'
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {reasonLabelFormat({ name: adjustment.reasonType })}
                    </TableCell>
                    <TableCell>{adjustment.comment}</TableCell>
                    <TableCell>
                      <Tooltip title="Delete Entry From Table">
                        <DeleteIcon
                          color="error"
                          key="delete"
                          onClick={() => {
                            dialogueAddOpen(adjustmentsSecondaryArray, idx);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer className={classes.parentContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  WAREHOUSE
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  PRODUCT
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  INVENTORY AVAILABLE QTY
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  BATCH QUANTITY
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                  size="small"
                >
                  ADJUSTED QTY
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                  size="small"
                >
                  BATCH NAME
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                  size="small"
                >
                  BATCH + EXPIRY + SECTION
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px'
                  }}
                >
                  REASON
                </TableCell>
                <TableCell
                  style={{
                    background: 'transparent',
                    fontWeight: 'bolder',
                    fontSize: '12px',
                    minWidth: 150
                  }}
                >
                  COMMENT
                </TableCell>
                <TableCell>ACTION</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {selectedInventoryWastageInventories.map((inventory, idx) => {
                return inventory.StockAdjustmentBatch.map((batch) => {
                  return (
                    <TableRow hover role="checkbox" key={idx}>
                      <TableCell>
                        {inventory.Inventory.Warehouse?.businessWarehouseCode ||
                          '-'}
                      </TableCell>
                      <TableCell>
                        {productLabelFormat(inventory?.Inventory?.Product)}
                      </TableCell>
                      <TableCell>
                        {inventory.Inventory.remainingQuantity >= 0
                          ? inventory.Inventory.remainingQuantity
                          : inventory.Inventory.availableQuantity}
                        {batch['dirtyData'] ? (
                          <PriorityHighOutlinedIcon
                            style={{
                              transform: 'translateY(5px)translateX(0px)',
                              color: 'red'
                            }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {!batch.InventoryDetail.batchName.includes('default')
                          ? batch.InventoryDetail.availableQuantity
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <NumberInput
                          id="editAdjustmentQty"
                          label="Quantity"
                          variant="outlined"
                          value={batch.quantity || 0}
                          InputLabelProps={{
                            style: { fontSize: 14 }
                          }}
                          onChange={(e) => {
                            inventory = calcTotalEditedQuantity(
                              inventory,
                              batch.id,
                              e.target.value
                            );
                            return handleEditForBatch(
                              inventory.remainingQuantity <= 0
                                ? batch.editedQty
                                : inventory.totalEditedQuantity >
                                  inventory.initialEditedQuantity +
                                    batch.InventoryDetail.availableQuantity
                                ? batch.editedQty +
                                  batch.InventoryDetail.availableQuantity
                                : e.target.value > batch.actualAvailableQuantity
                                ? batch.actualAvailableQuantity
                                : parseFloat(e.target.value),
                              inventory.Inventory.id,
                              batch.id,
                              'quantity'
                            );
                          }}
                          onBlur={() => {
                            setValidation({
                              ...validation,
                              editAdjustmentQuantity: true
                            });
                          }}
                          size="small"
                        />
                        {/* {validation.editAdjustmentQuantity && !isRequired(inventory.AdjustmentInventory.quantity) ? <Typography color="error">Company is required!</Typography> : ''} */}
                      </TableCell>
                      <TableCell>
                        {!batch.InventoryDetail.batchName.includes('default')
                          ? batch.InventoryDetail.batchName
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {/* {batch.InventoryDetail.batchNumber
                            ? batch.InventoryDetail.batchNumber +
                            ' + ' +
                            dividerDateWithoutTimeFormat(
                              batch.InventoryDetail.expiryDate
                            ) || '-'
                            : '-'} */}
                        {`${batch.InventoryDetail.batchNumber || ''}${
                          batch.InventoryDetail.batchNumber ? ',' : ''
                        }${dividerDateFormatForFilter(
                          batch.InventoryDetail.expiryDate
                        )} ${batch?.WarehouseHall?.name}` || ''}
                      </TableCell>

                      <TableCell>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="reasons">Reason Types</InputLabel>
                          <Select
                            labelId="reasons"
                            id="reasons"
                            value={batch.WastagesType.id || '0'}
                            onChange={(e) => {
                              handleEditForBatch(
                                e.target.value,
                                inventory.Inventory.id,
                                batch.id,
                                'reason'
                              );
                            }}
                            label="Reason Types"
                          >
                            {reasons.map((reason, index) => {
                              return (
                                <MenuItem key={index} value={reason?.id}>
                                  {reason?.name == 'NEW_FOUND'
                                    ? 'Additional Stock Identified'
                                    : reason.name.charAt(0).toUpperCase() +
                                      reason.name.slice(1).toLowerCase()}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth={true}
                          id="comment"
                          label="Comment"
                          variant="outlined"
                          InputProps={{ inputProps: { maxLength: 300 } }}
                          value={batch.comment || ''}
                          onChange={(e) =>
                            handleEditForBatch(
                              e.target.value,
                              inventory.Inventory.id,
                              batch.id,
                              'comment'
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Delete Entry">
                          <DeleteIcon
                            color="error"
                            key="delete"
                            onClick={() => {
                              dialogueOpen(inventory.Inventory.id, batch.id);
                              // removeFromAdjustmentArrayForEdit(
                              //   inventory.Inventory.id,
                              //   batch.id
                              // );
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {adjustmentsSecondaryArray.length > 0 ||
      selectedInventoryWastageInventories.length > 0 ? (
        <Grid container className={classes.parentContainer} xs={12} spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl margin="dense" fullWidth={true} variant="outlined">
              <Tooltip
                title={
                  !selectedInventoryWastages
                    ? 'Create Stock Adjustment'
                    : 'Update Stock Adjustment'
                }
              >
                <Button
                  onClick={
                    !selectedInventoryWastages
                      ? dialogueConfirmationOpen
                      : dialogueUpdateConfirmationOpen
                  }
                  color="primary"
                  variant="contained"
                >
                  {!selectedInventoryWastages
                    ? 'Create Stock Adjustment'
                    : 'Update Stock Adjustment'}
                </Button>
              </Tooltip>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        ''
      )}

      <MessageSnackbar showMessage={showMessage} type={messageType} />
    </>
  );
}

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { isRequired } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  }
}));

export default function AddCategoryView({
  addCategory,
  open,
  handleClose,
  selectedCategory,
  formErrors
}) {
  const classes = useStyles();

  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [isActive, setActive] = useState(true);

  const resetStates = () => {
    setValidation({});
    setName('');
    setActive(true);
  };

  useEffect(() => {
    if (selectedCategory) {
      setName(selectedCategory.name || '');
      setActive(!!selectedCategory.isActive);
    } else {
      setName('');
      setActive(true);
    }
  }, [selectedCategory]);
  const handleSubmit = () => {
    const newCategory = {
      name,
      isActive
    };

    setValidation({
      name: true
    });
    if (isRequired(name)) {
      addCategory(newCategory);
      resetStates();
    }
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}
          onBackdropClick={() => {
            resetStates();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle disableTypography className="title">
            {!selectedCategory ? 'Add Category' : 'Edit Category'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  variant="outlined"
                  value={name}
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => setValidation({ ...validation, name: true })}
                />
                {validation.name && !isRequired(name) ? (
                  <Typography color="error">Name is required!</Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item sm={12}>
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setActive(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Active
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  resetStates();
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>

            <Tooltip
              title={!selectedCategory ? 'Add Category' : 'Update Category'}
            >
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                {!selectedCategory ? 'Add Category' : 'Update Category'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import CURRENCIES from '../fixtures/currencies.json';
import COUNTRIES from '../fixtures/countries.json';

export const InventoryManagementTabsMapping = {
  USABLE: '1',
  DAMAGED: '2',
  HISTORY: '3'
};

export const OriginCell = {
  AVAILABLE: 'AVAILABLE',
  UNSELLABLE: 'UNSELLABLE',
  RECOVERABLE: 'RECOVERABLE'
};
export const OrderTypeMapping = {
  INWARD: 'Inbound'
};

export { CURRENCIES };

export { COUNTRIES };

export const ORGANIZATION_TYPES = {
  MANUFACTURER: 'MANUFACTURER',
  THIRD_PARTY_SERVICE_PROVIDER: 'THIRD_PARTY_SERVICE_PROVIDER'
};

export const GOOD_TO_DAMAGED_STOCK_CONDITION_MESSAGE =
  'These items were recently changed from good to damaged';
export const DAMAGED_TO_GOOD_STOCK_CONDITION_MESSAGE =
  'These items were recently changed from damaged to good';

export const shipmentsTabsMapping = {
  TRACKING: '1',
  COMMENTS: '2',
  DETAILS: '3'
};

export const SHIPMENT_ACTIONS_ORDER = {
  GRN_CREATE: 'GRN_CREATE',
  DOCK_ASSIGN: 'DOCK_ASSIGN',
  VEHICLE_ASSIGN: 'VEHICLE_ASSIGN',
  CREATE_SHIPMENT: 'CREATE_SHIPMENT',
  CREATE: 'CREATE'
};

export const TASKS_STATUS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  NOT_ASSIGNED: 'Not Assigned',
  ASSIGNED: 'Assigned',
  CANCELLED: 'Cancelled'
};

export const GOOGLE_MAP_API_KEY = 'AIzaSyC6sHJ40oRDivgDUhcr0rLOJ8vO059J6mQ';

export const LOCKABLE_TENANTS = [
  // { subdomain: 'quickbookTest', startDate: '2024-09-02' }
];

import React from 'react';
import { Title } from '../../../core-components/atoms/Text';

const Bar = ({ data, index }) => {
  const maxValue = data?.count;
  const scale = (value) => (value / maxValue) * 100;
  return (
    <div className="flex items-center space-x-2 mb-2">
      <Title variant={'sm'} className={'w-24'}>
        {index + 1}.{' '}
        {`${data?.['AssignedTo.firstName']} ${data?.['AssignedTo.lastName']}`}
      </Title>
      <div className="flex-1 flex space-x-1">
        <div
          style={{
            width: `${scale(
              data?.taskCount?.find((value) => value?.taskType === 'PICKING')
                ?.count
            )}%`
          }}
          className="bg-primaryBlue text-xs flex justify-end items-center text-white px-2 h-6 rounded-l"
        >
          {
            data?.taskCount?.find((value) => value?.taskType === 'PICKING')
              ?.count
          }
        </div>
        <div
          style={{
            width: `${scale(
              data?.taskCount?.find((value) => value?.taskType === 'PUTAWAY')
                ?.count
            )}%`
          }}
          className="bg-navyBlue text-xs flex justify-end items-center text-white px-2 h-6"
        >
          {
            data?.taskCount?.find((value) => value?.taskType === 'PUTAWAY')
              ?.count
          }
        </div>
        <div
          style={{
            width: `${scale(
              data?.taskCount?.find(
                (value) => value?.taskType === 'CYCLE COUNT'
              )?.count
            )}%`
          }}
          className="bg-oasisPrimary300 text-xs flex justify-end items-center text-white px-2 h-6 rounded-r"
        >
          {
            data?.taskCount?.find((value) => value?.taskType === 'CYCLE COUNT')
              ?.count
          }
        </div>
      </div>
      <div className="w-12 text-sm font-medium">{data?.count}</div>
    </div>
  );
};
export default Bar;

import { useParams } from 'react-router';
import API from '../../libs/axios';
import React, { useEffect, useState } from 'react';
import { onError } from '../../libs/errorLib';

const ScriptsDetailsView = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  const { scriptName } = useParams();
  const [scriptState, setScriptState] = useState(
    `Running the Script ${scriptName}`
  );
  const getScriptResponse = async () => {
    try {
      let res = await API.get(`scripts/${scriptName}/${subdomain}`);
      if (res) {
        setScriptState(res.message);
      }
    } catch (err) {
      onError(err);
    }
  };
  useEffect(() => {
    if (scriptName) {
      getScriptResponse();
    }
  }, [scriptName]);

  return <h3>{scriptState}</h3>;
};

export default ScriptsDetailsView;

import clsx from 'clsx';
import TriangleDown from '../../assets/icons/unifiedDashboard/TriangleDown.svg';
import TriangleUp from '../../assets/icons/unifiedDashboard/TriangleUp.svg';
import { MetricStatus, SymbolType } from './constants';

const StatItem = ({ title, status, value, symbol }) => {
  return (
    <div className="block">
      <div className="flex justify-between w-100 items-center py-1 pl-4 pr-3 hover:bg-yankeesBlue">
        <p className="text-white text-sm font-normal break-words">{title}</p>
        <div className="flex items-center">
          {status !== MetricStatus.NONE && (
            <img
              src={getStatusIcon(status)}
              alt={status}
              className={clsx('mr-1 w-4 h-4')}
            />
          )}
          <p className="text-white text-base font-semibold">
            {symbol === SymbolType.RUPPEE ? symbol + ' ' : ''}
            {value}
            {symbol === SymbolType.PERCENTAGE ? symbol : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatItem;

const getStatusIcon = (status) => {
  let iconSrc;
  switch (status) {
    case MetricStatus.DOWN:
      iconSrc = TriangleDown;
      break;
    case MetricStatus.UP:
      iconSrc = TriangleUp;
      break;
    case MetricStatus.NONE:
      iconSrc = TriangleUp;
      break;
  }
  return iconSrc;
};

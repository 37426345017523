import Button from '../../../core-components/atoms/Button';
import SearchBar from '../../../atomicComponents/SearchBar';
import { makeStyles } from '@material-ui/core';
// import API from '../../../libs/axios';
import React from 'react';

const useStyles = makeStyles(() => ({
  searchBar: {
    maxWidth: '188px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));

const Header = ({
  searchKeyword,
  setSearchKeyword,
  headerText,
  addBtnLabel,
  onClick,
  searchPlaceHolder,
  filterComponent
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className="w-full flex justify-between">
        <div className="flex items-center gap-2">
          <p className="text-lg text-primaryBlue font-semibold">{headerText}</p>
        </div>
        <div className="flex items-center">
          <SearchBar
            className={classes.searchBar}
            iconClass="h-4 w-4"
            setSearchValue={setSearchKeyword}
            searchKeyword={searchKeyword}
            placeholder={searchPlaceHolder || 'Search'}
          />
          {filterComponent}
          <Button
            label={addBtnLabel}
            className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
            variant="primary"
            overrideSize={true}
            labelClass="font-medium text-xs "
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;

import { useRef } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

const BulkUploadMenu = ({
  anchorEl,
  handleClose,
  setSelectedProductFile,
  template
}) => {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <a href={template} className="text-decoration-none text-current">
        <MenuItem>Download Template</MenuItem>
      </a>
      <MenuItem onClick={handleUploadClick}>
        Upload Document
        <input
          type="file"
          ref={fileInputRef}
          hidden
          onClick={(e) => {
            setSelectedProductFile(null);
            e.target.value = null;
          }}
          onChange={(e) => {
            setSelectedProductFile(e);
          }}
          accept=".xls,.csv,.xlsx,"
        />
      </MenuItem>
    </Menu>
  );
};

export default BulkUploadMenu;

import React from 'react';
import TextField from '@mui/material/TextField';

const CustomTextField = ({
  value,
  onChange = () => {},
  onInput = () => {},
  label,
  placeholder,
  type = 'text',
  maxLength,
  step = 0.001,
  hover,
  sxProps = {},
  error,
  ...rest
}) => {
  const handleChange = (event) => {
    if (type === 'number') {
      const inputValue = event.target.value;
      if (inputValue < 0) {
        event.target.value = 0;
        onChange(event);
      }
      const regex = /^-?\d*\.?\d{0,3}$/;

      if (regex.test(inputValue) || inputValue === '' || inputValue === '-') {
        onChange(event);
      }
    } else {
      onChange(event);
    }
  };

  return (
    <TextField
      value={value || ''}
      onChange={handleChange}
      label={label}
      placeholder={placeholder}
      type={type}
      size={'small'}
      className={`hover:mt-0`}
      inputProps={{ maxLength, step }}
      autoComplete="off"
      error={error}
      onInput={onInput}
      sx={{
        ...sxProps,
        ...(value && Object.keys(value)?.length > 0
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #0B1940 !important'
              }
            }
          : []),
        ...(hover && {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0'
          }
        })
      }}
      variant="outlined"
      {...rest}
    />
  );
};

export default CustomTextField;

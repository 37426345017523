import React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paperStyle: {
    marginBottom: '20px',
    padding: 5,
    height: '55vh',
    maxWidth: '350px',
    minWidth: 'auto',
    margin: '10% auto'
  },
  errors: {
    color: 'red'
  }
}));

const PasswordField = ({
  name,
  label,
  placeholder,
  fullWidth,
  required,
  type,
  value,
  onChange,
  onBlur,
  showPassword,
  handleShowPassword,
  error
}) => {
  const classes = useStyles();

  return (
    <Box mt={3}>
      <TextField
        name={name}
        label={label}
        variant="outlined"
        placeholder={placeholder}
        fullWidth={fullWidth}
        required={required}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {error && <div className={classes.errors}>{error}</div>}
    </Box>
  );
};

export default PasswordField;

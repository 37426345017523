import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  heading: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: '#000000',
    padding: '20px 0'
  },
  pos: {
    marginBottom: 12
  },
  successIcon: {
    color: '#85C830'
  },
  errorIcon: {
    color: 'red'
  },
  carIcon: {
    color: '#000000',
    paddingRight: 4
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    textDecorationLine: 'underline',
    color: '#4B89C8',
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  },
  activityGrid: {
    marginTop: '47px'
  },
  rideActivityGrid: {
    marginTop: '8px'
  },
  gridContainer: {
    boxSizing: 'border-box'
  },
  externalHeader: {
    marginTop: '37px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  calendarDate: {
    '@media (max-width: 1400px)': {
      fontSize: '8px !important',
      width: 'auto !important'
    }
  }
}));

export default useStyles;

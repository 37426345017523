import { Grid } from '@mui/material';
import Button from '../../../core-components/atoms/Button';

const DeleteBatch = ({ setOpen, productTitle, deleteNewProduct }) => {
  return (
    <div>
      <Grid item xs={12} className="mt-2 mb-10 pr-20">
        This will delete “{productTitle}” from the table. Are you sure you want
        to continue?
      </Grid>
      <Grid item xs={12} className="flex justify-end mb-2">
        <Button
          size="large"
          label="Cancel"
          variant="text"
          onClick={() => setOpen(false)}
          className="mr-2"
        />
        <Button
          size="large"
          label="Delete"
          variant="primary"
          onClick={deleteNewProduct}
        />
      </Grid>
    </div>
  );
};

export default DeleteBatch;

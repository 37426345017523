import React, { useState } from 'react';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';

import EllipsesIcon from '../../../assets/icons/ellipsesIcon.svg';
import warehouseBookingStyles from './warehouseBookingStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import CancelBookingPopup from './CancelBookingPopup';

const BookingActionMenu = ({
  booking,
  getBookings,
  openModal,
  setSelectedBooking
}) => {
  const { status, Warehouse, bookingStartDate, bookingEndDate, id } = booking;
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const open = Boolean(anchorEl);
  const classes = warehouseBookingStyles();

  const updateBooking = async (status) => {
    try {
      await API.put(`warehouses/${Warehouse?.id}/bookings/${id}`, {
        bookingStartDate,
        bookingEndDate,
        customerId: booking?.Customer?.id,
        status
      });
      getBookings();
      setConfirmationPopup(false);
      setAnchorEl(null);
    } catch (error) {
      onError(error);
    }
  };
  return (
    <>
      <CancelBookingPopup
        openState={{ confirmationPopup, setConfirmationPopup }}
        confirmCancel={() => updateBooking('CANCELLED')}
      />
      <img
        id="basic-button"
        style={{ marginLeft: '5px', cursor: 'pointer' }}
        src={EllipsesIcon}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Menu
        elevation={0}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => setAnchorEl(null)}
      >
        {status !== 'CANCELLED' ? (
          <Tooltip title="Cancel">
            <MenuItem
              className={classes.menuItems}
              onClick={() => {
                setConfirmationPopup(true);
                setAnchorEl(null);
              }}
            >
              Cancel
            </MenuItem>
          </Tooltip>
        ) : null}

        <Tooltip title="Edit">
          <MenuItem
            className={classes.menuItems}
            onClick={() => {
              setAnchorEl(null);
              setSelectedBooking(booking);
              openModal(true);
            }}
          >
            Edit
          </MenuItem>
        </Tooltip>
        {status === 'CONFIRMATION_REQUIRED' ? (
          <Tooltip title="Confirm">
            <MenuItem
              className={classes.menuItems}
              onClick={() => {
                updateBooking('ACTIVE');
              }}
            >
              Confirm
            </MenuItem>
          </Tooltip>
        ) : null}
      </Menu>
    </>
  );
};

export default BookingActionMenu;

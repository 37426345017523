import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paddingTop12: {
    paddingTop: 12
  },
  createButton: {
    color: 'white',
    backgroundColor: '#4B89C8',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    }
  },
  detailText: {
    margin: '10px 0',
    'line-height': 1.1
  },
  gridContainer: {
    width: '88%',
    margin: 'auto',
    '@media (max-width: 767px)': {
      width: '100%'
    }
  },
  mainContent: {
    width: '80%',
    margin: 'auto'
  },
  cancelButton: {
    border: '1px solid #4B89C8',
    color: '#4B89C8',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    },
    '@media (max-width: 767px)': {
      marginTop: '10px'
    }
  },
  margin20tb: {
    margin: '20px 0'
  },
  faulty: {
    color: 'red',
    marginLeft: 5
  }
}));

export default useStyles;

import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  MdFindInPage,
  MdHouseSiding,
  MdToday,
  MdListAlt,
  MdOutlineAssignment,
  MdNotifications
} from 'react-icons/md';
import { useFormik } from 'formik';

export default function AddReportingView({
  open,
  handleClose,
  selectedReport,
  addReport
}) {
  const [formikInitVals, setInitVals] = useState({
    name: '',
    isActive: false,
    URL: '',
    icon: null
  });
  const formik = useFormik({
    initialValues: formikInitVals,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      URL: Yup.string().required('URL is required')
    }),
    onSubmit: (values) => {
      addReport(values, false);
    }
  });

  const PurpleSwitch = withStyles({
    switchBase: {
      color: '#cecece',
      '&$checked': {
        color: 'blue'
      },
      '&$checked + $track': {
        backgroundColor: 'blue'
      }
    },
    checked: {},
    track: {}
  })(Switch);

  const resetStates = () => {
    formik.setFieldValue('name', '');
    formik.setFieldValue('URL', '');
    formik.setFieldValue('icon', null);
    formik.setFieldValue('isActive', false);
  };

  useEffect(() => {
    let reportingObj = {
      name: selectedReport?.name,
      URL: selectedReport?.URL,
      icon: selectedReport?.icon,
      isActive: selectedReport?.isActive
    };
    setInitVals({ ...reportingObj });
  }, [selectedReport]);

  return (
    <div className="display-inline">
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          open={open}
          onClose={() => {
            resetStates();
            handleClose();
          }}
          aria-labelledby="form-dialog-title"
          className="dialogSettings"
        >
          <DialogTitle disableTypography className="title">
            {!selectedReport ? 'Add Embedded Report' : 'Edit Embedded Report'}
          </DialogTitle>
          <DialogContent>
            <Grid item container>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <FormControl className="w-90 iconWidth">
                  <InputLabel className="select-input-label">Icon</InputLabel>
                  <Select
                    variant="outlined"
                    value={formik?.values?.icon || ''}
                    key="select"
                    className="text-left"
                    onChange={(e) => {
                      formik.setFieldValue('icon', e.target.value);
                    }}
                  >
                    <MenuItem value={1} key={1}>
                      <ListItemIcon>
                        <MdFindInPage />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem value={2} key={2}>
                      <ListItemIcon>
                        <MdHouseSiding />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem value={3} key={3}>
                      <ListItemIcon>
                        <MdToday />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem value={4} key={4}>
                      <ListItemIcon>
                        <MdListAlt />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem value={5} key={5}>
                      <ListItemIcon>
                        <MdOutlineAssignment />
                      </ListItemIcon>
                    </MenuItem>

                    <MenuItem value={6} key={6}>
                      <ListItemIcon>
                        <MdNotifications />
                      </ListItemIcon>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                className="nameField"
              >
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    variant="outlined"
                    name="name"
                    value={formik?.values?.name}
                    onChange={(e) =>
                      formik.setFieldValue('name', e.target.value)
                    }
                    label="Name"
                  />
                  {formik?.errors?.name && formik?.touched?.name && (
                    <Typography color="error">{formik.errors.name}</Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container className="mt-5">
              <Grid item xs="12">
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    className="inputWeight p-0 w-100"
                    variant="outlined"
                    name="URL"
                    value={formik?.values?.URL}
                    onChange={(e) =>
                      formik.setFieldValue('URL', e.target.value)
                    }
                    label="URL"
                  />
                  {formik?.errors?.URL && formik?.touched?.URL && (
                    <Typography color="error">{formik.errors.URL}</Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container className="mt-5">
              <Grid item sm={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PurpleSwitch
                        checked={formik?.values?.isActive || ''}
                        onChange={(e) => {
                          formik.setFieldValue('isActive', e.target.checked);
                        }}
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="embededBtns">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  resetStates();
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip
              title={
                !selectedReport
                  ? 'Add Embedded Report'
                  : 'Update Embedded Report'
              }
            >
              <Button
                color="secondary"
                type="submit"
                variant="contained"
                onClick={formik.handleSubmit}
              >
                {!selectedReport
                  ? 'Add Embedded Report'
                  : 'Update Embedded Report'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

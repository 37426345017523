/*eslint-disable*/
export const dateFormats = [
  'DD/MM/YYYY',
  'YYYY/DD/MM',
  'YYYY/MM/DD',
  'MM/DD/YYYY',
  'DD/MM/YY'
];

export const dateFormatMuiMapping = {
  'DD/MM/YYYY': 'dd/MM/yyyy',
  'YYYY/DD/MM': 'yyyy/dd/MM',
  'YYYY/MM/DD': 'yyyy/MM,dd',
  'MM/DD/YYYY': 'MM/dd/yyyy'
};

export const weights = [
  {
    value: 'GRAM',
    symbol: 'g',
    label: 'Gram (g)',
    conversion: 1000
  },
  {
    value: 'KILOGRAM',
    symbol: 'Kg',
    label: 'Kilogram (Kg)',
    conversion: 1
  },
  {
    value: 'MILLIGRAM',
    symbol: 'mg',
    label: 'MilliGram (mg)',
    conversion: 0.000001
  },
  {
    value: 'POUND',
    symbol: 'lb',
    label: 'Pound (lb)',
    conversion: 2.20462
  },
  {
    value: 'TON',
    symbol: 'ton',
    label: 'Ton',
    conversion: 0.001
  },
  {
    value: 'QUINTAL',
    symbol: 'q',
    label: 'Quintal (q)',
    conversion: 0.01
  }
];

export const volumes = [
  {
    value: 'CUBIC_METER',
    symbol: 'm³',
    label: 'Cubic Meter (m³)'
  },
  {
    value: 'LITER',
    symbol: 'L',
    label: 'Litre (L)'
  },
  {
    value: 'CUBIC_CENTIMETER',
    symbol: 'cm³',
    label: 'Cubic Centimeter (cm³)'
  },
  {
    value: 'CUBIC_INCH',
    symbol: 'in³',
    label: 'Cubic Inch (in³)'
  }
];

export const sizes = [
  {
    value: 'CENTIMETER',
    symbol: 'cm',
    label: 'Centimeter (cm)'
  },
  {
    value: 'METER',
    symbol: 'm',
    label: 'Meter (m)'
  },
  {
    value: 'INCH',
    symbol: 'In',
    label: 'Inch (In)'
  },

  {
    value: 'FOOT',
    symbol: 'ft',
    label: 'Foot (ft)'
  }
];

export const distances = [
  {
    value: 'KILOMETER',
    symbol: 'Km',
    label: 'Kilometer (Km)'
  },
  {
    value: 'MILE',
    symbol: 'ml',
    label: 'Mile (ml)'
  },

  {
    value: 'NAUTICAL_MILE',
    symbol: 'nm',
    label: 'Nautical Mile (nm)'
  }
];

/*eslint-disable*/
import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';

import RightArrowIcon from '../../../../assets/icons/rightArrow.svg';
import CancelledIcon from '../../../../assets/icons/cancelled.svg';
import EditIcon from '../../../../assets/icons/edit-row.svg';
import LinearProgressBar from '../../../../core-components/atoms/LinerProgressBar';
import OptionsMenu from '../../Options';
import { useContext, useState } from 'react';
import AddShipmentTMS from '../../AddShipmentTMS';
import clsx from 'clsx';
import { CRIMSON_500, JADE_700, PRIMARY } from '../../../../constants/colors';
import ShipmentIcon from '../../../../assets/icons/shipment.svg';
import truckIcon from '../../../../assets/icons/TruckIcon.svg';
import moment from 'moment';
import { ActionBtns } from '../tracking/InboundTMSShipmentTracking';
import CalendarIcon from '../../../../assets/icons/calendarIcon.svg';
import ShipmentTab from '../../../../assets/icons/shipmentTab.svg';
import { toaster } from '../../../../utils/toaster';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import ShipIcon from '../../../../assets/icons/ShipIcon.svg';
import AirplaneIcon from '../../../../assets/icons/AirplaneIcon.svg';
import DarkShipIcon from '../../../../assets/icons/darkShip.svg';
import DarkAeroplaneIcon from '../../../../assets/icons/darkAeroPlane.svg';
import DarkTruckIcon from '../../../../assets/icons/shipment.svg';
import BlueTruckIcon from '../../../../assets/icons/TruckIcon.svg';
import GreenTruckIcon from '../../../../assets/icons/greentTruckIcon.svg';
import GreenShipIcon from '../../../../assets/icons/greenShip.svg';
import GreenAeroPlaneIcon from '../../../../assets/icons/greenAeroplane.svg';
import RedTruckIcon from '../../../../assets/icons/redTruckIcon.svg';
import RedShipIcon from '../../../../assets/icons/redShip.svg';
import RedAeroplaneIcon from '../../../../assets/icons//redAeroPlane.svg';
import { SharedContext } from '../../../../utils/common';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';

export const InBoundShipmentTracking = ({ shipment, order, refetch }) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('edit');
  const { isFeatureEnabled } = useFeatureFlags();

  const { organization } = useContext(SharedContext);

  let cases = {
    AWAITING_CARRIER:
      shipment?.status?.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'AWAITING_CARRIER',
    CARRIER_ASSIGNED:
      shipment?.status?.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'CARRIER_ASSIGNED',
    OUT_FOR_DELIVERY:
      shipment?.status?.key == 'IN_TRANSIT' && !shipment?.subStatus?.key,
    ARRIVED_AT_DESTINATION:
      shipment?.status?.key == 'IN_TRANSIT' &&
      shipment?.subStatus?.key == 'ARRIVED_AT_DESTINATION',
    DOCK_ASSIGNED:
      shipment?.status?.key == 'IN_TRANSIT' &&
      shipment?.subStatus?.key == 'DOCK_ASSIGNED',
    COMPLETED: shipment?.status?.key == 'COMPLETED',
    CANCELLED: shipment?.status?.key == 'CANCELLED'
  };

  const bgStyle =
    cases.ARRIVED_AT_DESTINATION || cases.DOCK_ASSIGNED
      ? 'border-jade700 text-jade700 border'
      : cases.COMPLETED
      ? 'bg-jade700 border-jade700'
      : cases.CANCELLED
      ? 'bg-[#870000] border-none'
      : 'bg-[#000] text-[#fff] border';

  const textStyle =
    cases.ARRIVED_AT_DESTINATION || cases.DOCK_ASSIGNED
      ? 'text-jade700 font-medium'
      : cases.COMPLETED
      ? 'text-[#fff] font-medium'
      : cases.CANCELLED
      ? 'text-[#fff] font-medium'
      : 'text-[#fff] font-medium';

  const assignDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'DOCK_ASSIGNED',
          dockId
        }
      });
      toaster('success', 'Dock assigned successfully');
      refetch();
    } catch (err) {
      toaster('error', 'Failed to assign dock');
      onError(err);
    } finally {
    }
  };

  const mode = shipment?.ShipmentLegs?.[0]?.mode;

  return (
    <>
      <div
        className={clsx(
          'mt-4 mb-4 p-4 w-full border border-transparent border-collapse rounded',
          {
            'bg-dark100 border-dark400':
              !cases.COMPLETED &&
              !cases.CANCELLED &&
              !cases.ARRIVED_AT_DESTINATION &&
              !cases.DOCK_ASSIGNED,
            'bg-jade100':
              cases.COMPLETED ||
              cases.ARRIVED_AT_DESTINATION ||
              cases.DOCK_ASSIGNED,
            'bg-crimson100': cases.CANCELLED
          }
        )}
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <img
              src={
                mode == 'LAND'
                  ? cases.ARRIVED_AT_DESTINATION || cases.DOCK_ASSIGNED
                    ? GreenTruckIcon
                    : cases.CANCELLED
                    ? RedTruckIcon
                    : cases.COMPLETED
                    ? DarkTruckIcon
                    : BlueTruckIcon
                  : mode == 'SEA'
                  ? cases.ARRIVED_AT_DESTINATION || cases.DOCK_ASSIGNED
                    ? GreenShipIcon
                    : cases.CANCELLED
                    ? RedShipIcon
                    : cases.COMPLETED
                    ? DarkShipIcon
                    : ShipIcon
                  : cases.ARRIVED_AT_DESTINATION || cases.DOCK_ASSIGNED
                  ? GreenAeroPlaneIcon
                  : cases.CANCELLED
                  ? RedAeroplaneIcon
                  : cases.COMPLETED
                  ? DarkAeroplaneIcon
                  : AirplaneIcon
              }
              className="w-4"
            />
            <p className="flex gap-2 justify-center items-center">
              <span className="text-xs text-primaryBlue flex-shrink-0 text-right">
                {order.pickupNode?.name || '-'}
              </span>
              {order.dropOffNode?.name && (
                <img
                  src={RightArrowIcon}
                  alt="arrow"
                  className="flex-shrink-0 w-[20px]"
                />
              )}
              <span className="text-xs text-primaryBlue flex-shrink-0 text-left">
                {order.dropOffNode?.name}
              </span>
            </p>
            <div className={`${bgStyle} px-2 py-1 flex items-center rounded`}>
              {cases.AWAITING_CARRIER ? (
                <span className={`text-xs ${textStyle}`}>
                  Awaiting Carrier Details
                </span>
              ) : cases.CARRIER_ASSIGNED ? (
                <span className={`text-xs ${textStyle}`}>Carrier Assigned</span>
              ) : cases.OUT_FOR_DELIVERY ? (
                <span className={`text-xs ${textStyle}`}>Out For Delivery</span>
              ) : cases.DOCK_ASSIGNED ? (
                <span className={`text-xs ${textStyle}`}>Unloaded</span>
              ) : cases.ARRIVED_AT_DESTINATION ? (
                <span className={`text-xs ${textStyle}`}>Reached</span>
              ) : cases.CANCELLED ? (
                <span className={`text-xs ${textStyle}`}>Cancelled</span>
              ) : (
                <span className={`text-xs ${textStyle}`}>Completed</span>
              )}
            </div>
          </div>
          <div>
            <ActionBtns
              shipment={shipment}
              assignDock={assignDock}
              order={order}
              refetch={refetch}
              onAssignCarrier={() => {
                setOpen(true);
                setAction('assign');
              }}
            />
          </div>
        </div>
        <div className="w-full h-4 border-b border-dashed mt-3 mb-3 border-[#9DA3B3]"></div>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <div className="flex gap-2 items-center">
              <img src={CalendarIcon} />
              <span className="text-xs">
                {shipment.pickupDateTime
                  ? moment(shipment.pickupDateTime).format('D MMMM YYYY')
                  : 'NA'}
              </span>
              <span>-</span>
              <span className="text-xs">
                {shipment.dropoffDateTime
                  ? moment(shipment.dropoffDateTime).format('D MMMM YYYY')
                  : 'NA'}
              </span>
            </div>
            <div className="flex items-center gap-2 ml-4">
              <img
                src={
                  shipment.ShipmentLegs?.[0]?.mode == 'LAND'
                    ? ShipmentTab
                    : shipment.ShipmentLegs?.[0]?.mode == 'SEA'
                    ? DarkShipIcon
                    : DarkAeroplaneIcon
                }
                className="w-4"
              />
              <span className="text-xs">
                {shipment.ShipmentLegs?.[0]?.Company?.name || '-'}
              </span>
            </div>
            <div className="flex items-center gap-2 ml-4">
              <span className="text-xs">
                {shipment.ShipmentLegs?.[0]?.mode == 'LAND'
                  ? !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                    ? shipment.ShipmentLegs?.[0]?.Vehicle?.registrationNumber ||
                      '-'
                    : shipment.ShipmentLegs?.[0]?.vehicleNumber || '-'
                  : shipment.ShipmentLegs?.[0]?.mblNumber ||
                    shipment.ShipmentLegs?.[0]?.hblNumber ||
                    '-'}
              </span>
            </div>
          </div>
          <div className="flex gap-3">
            {shipment.ShipmentLegs?.[0]?.Vehicle?.trackingLink &&
              shipment?.status?.key !== 'COMPLETED' && (
                <div>
                  <span className="text-primary text-xs">
                    <a
                      href={shipment.ShipmentLegs?.[0]?.Vehicle?.trackingLink}
                      target="_blank"
                    >
                      View Tracking
                    </a>
                  </span>
                </div>
              )}
            <div>
              <span
                className="text-primary text-xs cursor-pointer"
                onClick={() => {
                  setOpen(true);
                  setAction('assign');
                }}
              >
                {shipment?.ShipmentLegs?.[0]?.carrierId
                  ? 'Carrier Details'
                  : 'Add Carrier'}
              </span>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <AddShipmentTMS
          shipment={shipment}
          open={open}
          setOpen={setOpen}
          selectedOrder={order}
          edit={true}
          refetch={refetch}
          action={action}
          onClose={() => setAction('edit')}
          editable={
            !(
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment?.subStatus?.key == 'ARRIVED_AT_DESTINATION') ||
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment?.subStatus?.key == 'DOCK_ASSIGNED') ||
              shipment?.status?.key == 'COMPLETED' ||
              shipment?.status?.key == 'CANCELLED'
            )
          }
        />
      )}
    </>
  );
};

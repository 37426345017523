import React from 'react';
import { Paper } from '@mui/material';
import { Title } from '../../../core-components/atoms/Text';

const EmptyChart = () => {
  return (
    <Paper
      className="flex justify-center items-center overflow-hidden h-32 flex-1"
      elevation={0}
    >
      <Title variant={'md'}>{'No data to display'}</Title>
    </Paper>
  );
};

export default EmptyChart;

/*eslint-disable*/
/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableContainer,
  InputBase,
  Button,
  Box,
  Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdSearch,
  MdCancel,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import { makeStyles } from '@material-ui/styles';
import TableHeader from '../../../components/TableHeader';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useEffect, useState, useContext } from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import MessageSnackbar from '../../../components/MessageSnackbar';
import Filter from '../../../components/Filter';
import { SharedContext } from '../../../utils/common';
import { checkPermission } from '../../../utils/auth';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { useParams } from 'react-router';
import { Pagination } from '@material-ui/lab';
import AddCategoryView from '../category/AddCategoryView';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { useNavigate } from 'react-router';
import AddBrandView from '../brand/AddBrandView';
import AddProductView from '../product/AddProductView';
import AddOrderNodeView from '../../orders/orderNodes/AddOrderNodeView';
import classNames from 'classnames/bind';
import '../../../assets/css/style.css';
import ExportButton from '../../../components/ExportButton';
import LazyLoad from 'react-lazyload';
import { toaster } from '../../../../src/utils/toaster';
import FilterButton from '../../../components/stats/FilterButton';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    padding: 20
  },
  CircularProgress: {
    height: 'calc(100% - 60px)',
    position: 'fixed',
    left: '100%',
    width: 'calc(100% - 256px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3051
  },
  active: {
    color: theme.palette?.success?.main
  },
  searchInput: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  exportBtn: {
    marginLeft: '12px !important',
    fontSize: '12px !important',
    top: '2px !important',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      marginLeft: '35% !important',
      top: '1px !important'
    }
  },
  placeholderText: {
    color: '#CAC9C9',
    '& .MuiSelect-outlined': {
      paddingTop: '6px',
      paddingBottom: '6px'
    },
    marginRight: 5
  },
  addButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold',
    '@media (max-width: 767px)': {
      fontsSize: '.5rem !important',
      padding: '2.5px 7px !important',
      marginLeft: '-18%',
      position: 'absolute',
      marginTop: '2px'
    },
    '@media (min-width: 578px) and (max-width:767px)': {
      padding: '2px 7px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      marginTop: '0px'
      // marginLeft: "-15%",
    }
  },
  companyDetails: {
    '@media (max-width: 767px)': {
      fontSize: '12px',
      width: '40%'
    }
  },
  companyDetailsText: {
    '@media (max-width: 767px)': {
      width: '100% !important'
    }
  },
  exportButton: {
    '@media (max-width: 767px)': {
      fontSize: '9.6px !important',
      padding: '5.5px 7px !important',
      marginLeft: '32%',
      marginRight: '85px !important',
      position: 'absolute !important',
      border: 'none',
      marginTop: '1px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '@media (min-width: 578px) and (max-width:767px)': {
      fontSize: '13px !important',
      marginLeft: '260px'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      marginTop: '-1px',
      marginLeft: '35%'
    }
  },
  uploadButton: {
    '@media (max-width: 767px)': {
      fontSize: '9.6px !important',
      padding: '5.5px 7px !important',
      position: 'absolute',
      marginLeft: '-48%',
      marginTop: '2px'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      marginTop: '0px'
    }
  },
  uploadButtonText: {
    fontSize: '13px',
    '@media (max-width: 767px)': {
      fontSize: '9.6px !important'
    },
    '@media (min-width: 578px) and (max-width:767px)': {
      fontSize: '13px !important'
    }
  },
  filterButton: {
    '@media (max-width: 767px)': {
      fontSize: '9.6px !important',
      padding: '5.5px 7px !important'
    },
    '@media (min-width: 578px) and (max-width:767px)': {
      fontSize: '13px !important'
    }
  },
  Tab: {
    '@media (min-width: 1024px)': {
      width: '40%'
    }
  }
}));

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#646f81',
  500: '#2b3a53',
  600: '#2b3a53',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75'
};

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 12px 0px;
    margin: 6px 0px;
    width: 112px;
  }
  @media (max-width: 360px) {
    font-size: 10px;
    padding: 12px 0px;
    margin: 6px 0px;
    width: 70px;
  }

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 100%;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 70% !important;
  }
  @media (min-width: 1446px) {
    width: 40% !important;
  }
  @media (max-width: 360px) {
    width: 100% !important;
  }
`;

const TabStyled = styled(TabsUnstyled)`
  width: 95%;
  margin: auto;
`;

export default function CompanyDetails() {
  const classes = useStyles();
  const navigate = useNavigate();

  //Category states start...
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);

  const [pageCountBrands, setPageCountBrands] = useState(1);
  const [pageCountNodes, setPageCountNodes] = useState(1);
  const [pageBrands, setPageBrands] = useState(1);

  const [pageCountProducts, setPageCountProducts] = useState(1);
  const [pageProducts, setPageProducts] = useState(1);

  const [loading, setLoading] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showMessage, setShowMessage] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const { currentUser } = useContext(SharedContext);
  const [filterView, setFilterView] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addViewOpen, setAddViewOpen] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  const [sortable, setSortable] = useState('');
  const [fetchState, setFetchState] = useState('');
  const [brandName, setBrandName] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');
  const [companies, setCompanies] = useState('');
  const [brands, setBrands] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [type, setType] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [productName, setProductName] = useState('');
  const [uoms, setUoms] = useState('');
  const [products, setProducts] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productBarcode, setProductBarcode] = useState('');
  const [productVol, setProductVol] = useState('');
  const [productWeight, setProductWeight] = useState('');
  const [productCatName, setProductCatName] = useState('');
  const [productBrandName, setProductBrandName] = useState('');
  const [productUomName, setProductUomName] = useState('');
  const [productPerPallet, setProductPerPallet] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [uomName, setUomNme] = useState('');
  const [focus, setFocus] = useState(false);
  const [brandCategoryName, setBrandCategoryName] = useState('');
  const [companyLogoImage, setCompanyLogoImage] = useState('');
  const [nodeName, setNodeName] = useState('');
  const [nodeCode, setNodeCode] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [nodeClass, setNodeClass] = useState('');
  const [nodeType, setNodeType] = useState('');
  const [muiLoader, setMuiLoader] = useState(false);

  //Category states end...
  const { uid } = useParams();
  const initialValues = {
    companyId: uid,
    address: '',
    cityId: '',
    cityName: '',
    nodeType: '',
    nodeTypeId: '',
    nodeClass: '',
    nodeClassId: '',
    pocName: '',
    pocContact: '',
    locationLatLng: {
      lat: '',
      lng: ''
    },
    name: '',
    description: '',
    adhocFlag: 0,
    isActive: true
  };
  const [nodeInitialValues, setNodeInitialValues] = useState(initialValues);
  const isPhone = useMediaQuery('(max-width:360px)');

  //Category columns configuration start...
  const categoryColumns = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      flex: isPhone ? null : 4,
      width: isPhone ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Name
            {fetchState && categoryName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setCategoryName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_CATEGORY_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={params}
                onClick={() => openEditView(setSelectedCategory, params.row)}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];
  //Category columns configuration end....

  //Brands columns configuration start....
  const isDesktop = useMediaQuery('(max-width:768px)');

  const brandColumns = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },

    {
      field: 'name',
      flex: isDesktop ? null : 2,
      width: isDesktop ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Name
            {fetchState && brandName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setBrandName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'manufacturerName',
      flex: isDesktop ? null : 2,
      width: isDesktop ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Manufacturer Name
            {fetchState && manufacturerName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setBrandName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('manufacturerName');
              }}
              className={
                sortable.col == 'manufacturerName' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'manufacturerName' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'Category Name',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Category Name
            {fetchState && brandCategoryName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setBrandName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Category->name');
              }}
              className={
                sortable.col == 'Category->name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Category->name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row.Category) {
          result.push(params.row.Category.name);
        }
        return result.join(', ');
      }
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_BRAND_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={params}
                onClick={() => _getBrand(params.row.id, params.row.categoryId)}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];
  //Brands columns configuration end....

  //Products columns configuration start....
  const productColumns = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },

    {
      field: 'name',
      flex: isPhone ? null : 2,
      width: isPhone ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        const titleToolSKUCode = 'Customer SKU Code';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolSKUCode}`}>
              <span className="table-cell-trucate">{titleToolSKUCode}</span>
            </Tooltip>
            {fetchState && productName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.name}`}>
          <span className="table-cell-trucate">
            {params.row.name.length > 20
              ? `${params.row.name.substring(0, 20)}...`
              : `${params.row.name}`}
          </span>
        </Tooltip>
      )
    },

    {
      field: 'description',
      flex: isPhone ? null : 1.5,
      width: isPhone ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        const titleToolSKUName = 'SKU Name';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolSKUName}`}>
              <span className="table-cell-trucate">{titleToolSKUName}</span>
            </Tooltip>
            {fetchState && productDescription ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductDescription('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('description');
              }}
              className={
                sortable.col == 'description' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'description' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.description}`}>
          <span className="table-cell-trucate">
            {params.row.description.length > 20
              ? `${params.row.description.substring(0, 20)}...`
              : `${params.row.description}`}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'barcode',
      flex: isPhone ? null : 2,
      width: isPhone ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        const titleToolSKUBarcode = 'SKU Barcode';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolSKUBarcode}`}>
              <span className="table-cell-trucate">{titleToolSKUBarcode}</span>
            </Tooltip>
            {fetchState && productBarcode ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductBarcode('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('barcode');
              }}
              className={
                sortable.col == 'barcode' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'barcode' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.barcode}`}>
          <span className="table-cell-trucate">
            {params.row.barcode && params.row.barcode.length > 20
              ? `${params.row.barcode.substring(0, 20)}...`
              : `${params.row.barcode ? params.row.barcode : ''}`}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'UoM',
      flex: isPhone ? null : 0.8,
      width: isPhone ? 120 : null,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.UOM) {
          result.push(params.row.UOM.name);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            UoM
            {fetchState && productUomName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductUomName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('UOM.name');
              }}
              className={
                sortable.col == 'UOM.name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'UOM.name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'dimensionsCBM',
      flex: isPhone ? null : 2,
      width: isPhone ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      valueGetter: (params) => {
        let result = [];
        if (params.row.dimensionsCBM) {
          result.push(params.row.dimensionsCBM);
        } else {
          result.push(
            params?.row?.length * params?.row?.breadth * params?.row?.height
          );
        }
        return result.join(', ');
      },
      renderHeader: () => {
        const titleToolUOM = 'Volume per UoM in cm3';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolUOM}`}>
              <span className="table-cell-trucate">{titleToolUOM}</span>
            </Tooltip>
            {fetchState && productVol ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductVol('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('dimensionsCBM');
              }}
              className={
                sortable.col == 'dimensionsCBM' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'dimensionsCBM' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'weight',
      flex: isPhone ? null : 2,
      width: isPhone ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      renderHeader: () => {
        const titleToolWeight = 'Weight per UoM in Kgs';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolWeight}`}>
              <span className="table-cell-trucate">{titleToolWeight}</span>
            </Tooltip>
            {fetchState && productWeight ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductWeight('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('weight');
              }}
              className={
                sortable.col == 'weight' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'weight' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'Category',
      flex: isPhone ? null : 1.5,
      width: isPhone ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.Brand) {
          result.push(params.row.Brand.Category.name);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        const titleToolSKUCategory = 'SKU Category';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolSKUCategory}`}>
              <span className="table-cell-trucate">{titleToolSKUCategory}</span>
            </Tooltip>
            {fetchState && productCatName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductCatName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Brand->Category->name');
              }}
              className={
                sortable.col == 'Brand->Category->name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Brand->Category->name' &&
              sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'Brand',
      flex: isPhone ? null : 1.5,
      width: isPhone ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.Brand) {
          result.push(params.row.Brand.name);
        }
        return result.join(', ');
      },
      renderHeader: () => {
        const titleToolSKUBrand = 'SKU Brand';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolSKUBrand}`}>
              <span className="table-cell-trucate">{titleToolSKUBrand}</span>
            </Tooltip>
            {fetchState && productBrandName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductBrandName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Brand.name');
              }}
              className={
                sortable.col == 'Brand.name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Brand.name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'perPallet',
      flex: isPhone ? null : 1.5,
      width: isPhone ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      renderHeader: () => {
        const titleToolUOMPerPallet = 'UoM per Pallet';
        return (
          <p className="w-100 parentActions">
            <Tooltip title={`${titleToolUOMPerPallet}`}>
              <span className="table-cell-trucate">
                {titleToolUOMPerPallet}
              </span>
            </Tooltip>
            {fetchState && productPerPallet ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setProductPerPallet('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('perPallet');
              }}
              className={
                sortable.col == 'perPallet' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'perPallet' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_PRODUCT_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={params}
                onClick={() =>
                  _getProduct(
                    params.row.id,
                    params.row.Brand.id,
                    params.row.Brand.Category.id
                  )
                }
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];
  //products columns configuration end....

  //Nodes columns configuration start....

  const isLaptop = useMediaQuery('(max-width:1024px)');
  const nodeColumns = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'nodeClass',
      flex: isLaptop ? null : 1,
      width: isLaptop ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Node Class
            {fetchState && nodeClass ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setNodeClass('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('nodeClass');
              }}
              className={
                sortable.col == 'nodeClass' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'nodeClass' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'nodeType',
      flex: isLaptop ? null : 2,
      width: isLaptop ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Node Type
            {fetchState && nodeType ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setNodeType('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('nodeType');
              }}
              className={
                sortable.col == 'nodeType' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'nodeType' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'code',
      flex: isLaptop ? null : 2,
      width: isLaptop ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Location Code
            {fetchState && nodeCode ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setNodeCode('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('code');
              }}
              className={
                sortable.col == 'code' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'code' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.code ? params?.row?.code : '-'}`}>
          <span className="table-cell-trucate">
            {params?.row?.code ? params?.row?.code : '-'}
          </span>
        </Tooltip>
      )
    },

    {
      field: 'name',
      flex: isLaptop ? null : 2,
      width: isLaptop ? 160 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Location Name
            {fetchState && nodeName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setNodeName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },

    {
      field: 'address',
      flex: isLaptop ? null : 2,
      width: isLaptop ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Address
            {fetchState && address ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setAddress('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('address');
              }}
              className={
                sortable.col == 'address' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'address' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.address}`}>
          <span className="table-cell-trucate">
            {params.row.address && params.row.address.length > 30
              ? `${params.row.address.substring(0, 30)}...`
              : `${params.row.address}`}
          </span>
        </Tooltip>
      )
    },

    {
      field: 'cityName',
      flex: isLaptop ? null : 1,
      width: isLaptop ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            City
            {fetchState && city ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setCity('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('cityName');
              }}
              className={
                sortable.col == 'cityName' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'cityName' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'adhocFlag',
      headerName: 'Adhoc',
      flex: isLaptop ? null : 1,
      width: isLaptop ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return value.value == 0 ? 'No' : 'Yes';
      }
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_BRAND_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={params}
                onClick={() => _getNode(params.row.customId)}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];
  //Nodes columns configuration end....

  //Category functions.....
  //Add Category API start.....

  const addCategory = async (data) => {
    let categories = null;
    try {
      setAPILoader(true);
      if (!selectedCategory)
        categories = await API.post(`companies/${uid}/categories`, data);
      else
        categories = await API.put(
          `companies/${uid}/categories/${selectedCategory.id}`,
          data
        );
      toaster(
        'success',
        !selectedCategory
          ? 'New category has been created.'
          : 'Category updated successfully.'
      );
      closeAddCategoryView();
      getCategories(page);
    } catch (err) {
      if (err.response.data.error) {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          onError(data);
        });
      } else {
        onError(err);
      }
      return false;
    } finally {
      setAPILoader(true);
    }
  };
  //Add Category API end.....

  //Add Brand API start.....
  const addBrand = async (data, params) => {
    let brand = null;
    let catId = selectedCategoryId ? selectedCategoryId : '';
    try {
      setAPILoader(false);
      if (!selectedBrand)
        brand = await API.post(
          `companies/${uid}/categories/${catId}/brands`,
          data
        );
      else
        brand = await API.put(
          `companies/${uid}/categories/${catId}/brands/${selectedBrand.id}`,
          data
        );
      toaster(
        'success',
        !selectedBrand
          ? 'New brand has been created.'
          : 'Brand updated successfully.'
      );
      closeAddBrandView();
      _getBrands(page);
    } catch (err) {
      if (err.response.data.error) {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          onError(data);
        });
      } else {
        let theObject = err.response;
        var result = null;
        if (theObject instanceof Array) {
          for (var i = 0; i < theObject.length; i++) {
            result = theObject[i];
            if (result) {
              break;
            }
          }
        } else {
          for (var prop in theObject) {
            if (prop == 'msg') {
              if (theObject[prop] == 1) {
                return theObject;
              }
            }
            if (
              theObject[prop] instanceof Object ||
              theObject[prop] instanceof Array
            ) {
              result = theObject[prop];
              if (result) {
                break;
              }
            }
          }
        }
        return result;
      }
    } finally {
      setAPILoader(false);
    }
  };
  //Add Brand API end.....

  const addProduct = async (data, params) => {
    let product = null;
    let catId = selectedProduct
      ? selectedProduct.Brand.Category.id
      : selectedCategoryId;
    let brandId = selectedProduct ? selectedProduct.Brand.id : selectedBrandId;
    try {
      setAPILoader(true);
      if (!selectedProduct)
        product = await API.post(
          `companies/${uid}/categories/${catId}/brands/${brandId}/products`,
          data
        );
      else
        product = await API.put(
          `companies/${uid}/categories/${catId}/brands/${brandId}/products/${selectedProduct.id}`,
          data
        );
      toaster(
        'success',
        !selectedProduct
          ? 'New product has been created.'
          : 'Product updated successfully.'
      );
      closeAddProductView();
      _getProducts(page);
    } catch (err) {
      if (err.response.data.error) {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          if (
            data.message == 'products.unique_product_brandId must be unique'
          ) {
            toaster('warning', 'Product must be unique');
          } else if (
            data.message == 'products.unique_product_barcode must be unique'
          ) {
            toaster('warning', 'SKU Barcode must be unique');
          } else {
            onError(data);
          }
        });
      } else {
        onError(err);
      }
    } finally {
      setAPILoader(false);
    }
  };
  //Add Product API end.....

  //Add Node Start
  const addNode = () => {
    closeAddNodeView();

    getNodes();
  };

  const getNodes = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        name: nodeName,
        sortable
      };
      let columns = ['name', 'code'];
      _getNodes(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  const getCategories = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        name: categoryName,
        sortable
      };
      let columns = ['name'];
      _getCategories(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  const setNameVal = (StateVal, e) => {
    StateVal(e);
  };

  const closeAddCategoryView = () => {
    setSelectedCategory(null);
    setAddViewOpen(false);
  };

  const closeAddBrandView = () => {
    setSelectedBrand(null);
    setAddViewOpen(false);
  };

  const focusInput = () => {
    setSearchKeyword('');
    let checkFocusVal = focus ? false : true;
    setFocus(checkFocusVal);
  };

  const closeAddProductView = () => {
    setSelectedProduct(null);
    setAddViewOpen(false);
  };

  const closeAddNodeView = () => {
    setAddViewOpen(false);
    setNodeInitialValues(initialValues);
  };

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const openEditView = (stateType, value) => {
    stateType(value);
    setAddViewOpen(true);
  };

  const selectCategory = (category) => {
    setSelectedCategoryId(category);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (categoryName) {
      colVal = { name: categoryName };
    }
    let filters = {
      colVal,
      sortable
    };
    let columns = ['name'];
    _getCategories(page, searchKeyword, filters, columns);
  };

  const fetchBrandRes = () => {
    setFetchState(true);
    let colVal = {};
    if (brandName) {
      colVal.name = brandName;
    }
    if (brandCategoryName) {
      colVal['$Category.name$'] = brandCategoryName;
    }
    if (manufacturerName) {
      colVal.manufacturerName = manufacturerName;
    }
    let filters = {
      colVal,
      sortable: sortable
    };
    let columns = ['name', 'manufacturerName'];
    _getBrands(page, searchKeyword, filters, columns);
  };

  const fetchUomRes = () => {
    setFetchState(true);
    let colVal = {};
    if (uomName) {
      colVal = { name: uomName };
    }
    let columns = ['name'];
    _getUoms(page, searchKeyword, columns);
  };

  const fetchProductRes = () => {
    setFetchState(true);
    let colVal = {};
    if (productName) {
      colVal.name = productName;
    }
    if (productDescription) {
      colVal.description = productDescription;
    }
    if (productBarcode) {
      colVal.barcode = productBarcode;
    }
    if (productVol) {
      colVal.dimensionsCBM = productVol;
    }
    if (productWeight) {
      colVal.weight = productWeight;
    }
    if (productCatName) {
      colVal['$Brand.Category.name$'] = productCatName;
    }
    if (productBrandName) {
      colVal['$Brand.name$'] = productBrandName;
    }
    if (productUomName) {
      colVal['$UOM.name$'] = productUomName;
    }
    if (productPerPallet) {
      colVal.perPallet = parseInt(productPerPallet);
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = [
      'name',
      'description',
      'barcode',
      'dimensionsCBM',
      'weight',
      '$Brand.Category.name$',
      '$Brand.name$',
      '$UOM.name$',
      'perPallet'
    ];
    _getProducts(page, searchKeyword, filters, columns);
  };

  const fetchNodesRes = () => {
    setFetchState(true);
    let colVal = {};
    if (nodeName) {
      colVal.name = nodeName;
    }
    if (address) {
      colVal.address = address;
    }
    if (city) {
      colVal.cityName = city;
    }
    if (nodeCode) {
      colVal.code = nodeCode;
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = ['name', 'code', 'address', 'cityName', 'description'];
    _getNodes(page, searchKeyword, filters, columns);
  };

  const changeType = (type) => {
    setPage(1);
    setPageBrands(1);
    setPageProducts(1);
    setType(type);
    setBrands([]);
    setSortable('');
    setProducts('');
    setPageCount('');
    setPageCountBrands('');
    setPageCountNodes('');
    setPageCountProducts('');
    setSearchKeyword('');
    setFilterView(false);
    setAddViewOpen(false);
    if (type == 0) fetchRes();
    else if (type == 1) fetchBrandRes();
    else if (type == 2) {
      fetchUomRes();
      fetchProductRes();
    } else if (type == 3) {
      //Nodes tab is active...
      fetchNodesRes();
    }
  };

  const _getCategories = useCallback(
    debounce(async (page, searchKeyword, filters, columns) => {
      try {
        setMuiLoader(true);
        if (searchKeyword) {
          setPage(1);
        }
        const categories = await API.get(`companies/${uid}/categories`, {
          params: {
            page: page,
            company_id: uid,
            search: searchKeyword,
            filters,
            columns
          }
        });
        setPageCount(categories.pages);
        setCategories(categories.data);
      } catch (err) {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          onError(data);
        });
      } finally {
        setLoading(false);
        setMuiLoader(false);
      }
    }, DEBOUNCE_CONST),
    []
  );

  const _getBrands = useCallback(
    debounce(async (page, searchKeyword, filters, columns) => {
      setMuiLoader(true);
      setLoading(true);
      try {
        if (searchKeyword) {
          setPage(1);
        }
        const brands = await API.get(`companies/${uid}/brands`, {
          params: { page: page, search: searchKeyword, filters, columns }
        });
        setPageCountBrands(brands.pages);
        setBrands(brands.data);
      } catch (err) {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          onError(data);
        });
      } finally {
        setLoading(false);
        setMuiLoader(false);
      }
    }, DEBOUNCE_CONST),
    []
  );

  const _getBrandOpt = async (value) => {
    setLoading(true);
    try {
      const brandOpt = await API.get(
        `companies/${uid}/categories/${value.id}/brands`
      );
      setBrands(brandOpt.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setLoading(false);
    }
  };

  const _getBrand = async (id, catId) => {
    setLoading(true);
    try {
      const brands = await API.get(
        `companies/${uid}/categories/${catId}/brands/${id}`
      );
      setSelectedCategoryId(catId);
      openEditView(setSelectedBrand, brands.brand);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setLoading(false);
    }
  };

  const _getUoms = async (page, searchKeyword, columns) => {
    setLoading(true);
    try {
      const uoms = await API.get(`uoms/`, {
        params: { page: page, search: searchKeyword, columns }
      });
      setPageCount(uoms.pages);
      setUoms(uoms.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setLoading(false);
    }
  };

  const _getNodes = async (page, searchKeyword, filters, columns) => {
    try {
      setMuiLoader(true);
      if (searchKeyword) {
        setPage(1);
      }
      const nodes = await API.get(`nodes`, {
        params: {
          page: page,
          companyId: uid,
          search: searchKeyword,
          filters,
          columns
        }
      });
      setPageCountNodes(nodes.pages);
      setNodes(nodes.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setLoading(false);
      setMuiLoader(false);
    }
  };

  //Classes for styles....

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const searchClasses = classNames({
    categorySearchIcon: true,
    'display-none': focus ? true : false
  });

  const filterClasses = classNames({
    filterBtn: true,
    filterFocused: focus ? true : false
  });

  //Classes for styles....

  const _getProducts = useCallback(
    debounce(async (page, searchKeyword, filters, columns) => {
      try {
        setMuiLoader(true);
        if (searchKeyword) {
          setPage(1);
        }
        const products = await API.get(`companies/${uid}/products`, {
          params: { page: page, search: searchKeyword, filters, columns }
        });
        setPageCountProducts(products.pages);
        setProducts(products.data);
      } catch (err) {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          onError(data);
        });
      } finally {
        setMuiLoader(false);
      }
    }, DEBOUNCE_CONST),
    []
  );

  const bulkUpload = async (data) => {
    try {
      const uploads = await API.post(`companies/${uid}/products/bulk`, data);
      toaster('success', 'New products has been created');
      closeAddProductView(false);
      _getProducts();
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getProduct = async (id, brandId, catId) => {
    try {
      const product = await API.get(
        `companies/${uid}/categories/${catId}/brands/${brandId}/products/${id}`
      );
      openEditView(setSelectedProduct, product.product);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getNode = async (id) => {
    try {
      const node = await API.get(`orders/${id}/node`);
      setNodeInitialValues(node.node);
      setAddViewOpen(true);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getCompany = async () => {
    try {
      const company = await API.get(`companies/${uid}`);
      setCompanies(company);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  useEffect(() => {
    _getCompany();
  }, [type]);

  useEffect(async () => {
    const companyLogo =
      companies && companies.company.logoId
        ? await API.get(`/rides/loads/preview/${companies.company.logoId}`)
        : '';
    setCompanyLogoImage(companyLogo.preview);
  }, [companies]);

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  useEffect(() => {
    if (type == 0) fetchRes();
    else if (type == 1) fetchBrandRes();
    else if (type == 2) fetchProductRes();
    else fetchNodesRes();
  }, [
    categoryName,
    brandName,
    manufacturerName,
    brandCategoryName,
    page,
    searchKeyword,
    sortable,
    productName,
    productDescription,
    productBarcode,
    productVol,
    productWeight,
    productCatName,
    productBrandName,
    productUomName,
    productPerPallet,
    nodeName,
    nodeCode,
    address,
    city
  ]);

  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  useEffect(() => {
    let title = 'Company';
    setCurrentPageTitle(title);
    fetchRes();
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    if (type) {
      changeType(type);
    }
  }, [type]);

  //Datatables actions function start...
  function CustomToolbar(props) {
    return (
      <FilterButton
        classes={classes.filterButton}
        searchKeyword
        openFilterView={() => openFilterView()}
      />
    );
  }
  //Datatables actions function ends...

  //Category Header button actions start....
  let title = '';
  if (type == 0) {
    title = 'ADD CATEGORY';
  } else if (type == 1) {
    title = 'ADD BRAND';
  } else if (type == 2) {
    title = 'ADD PRODUCT';
  } else {
    title = 'ADD NODE';
  }
  const addCategoryButton = (
    <Tooltip
      title={
        type == 0
          ? 'Add Category'
          : type == 1
          ? 'Add Brand'
          : type == 2
          ? 'Add Product'
          : 'Add Node'
      }
    >
      <Button
        key={2}
        id="removeHoverBtn"
        variant="contained"
        color="primary"
        size="small"
        className={classes.addButton}
        onClick={() => setAddViewOpen(true)}
      >
        {title}
      </Button>
    </Tooltip>
  );

  const addCategoryModal = (
    <AddCategoryView
      key={3}
      open={addViewOpen}
      formErrors={formErrors}
      addCategory={addCategory}
      selectedCategory={selectedCategory}
      handleClose={() => closeAddCategoryView()}
    />
  );

  const searchInput = (
    <div className={`search-box ${classes.searchBox}`}>
      {searchKeyword ? (
        <span className="search-cancel" onClick={() => setSearchKeyword('')}>
          <MdCancel className="searchIcons" />
        </span>
      ) : null}
      <InputBase
        className={mainClass}
        id="search"
        label="Search"
        type="text"
        variant="outlined"
        value={searchKeyword}
        key={3}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
      <span className="search-btn">
        <MdSearch className="searchIcons" />
      </span>
    </div>
  );

  //Category Header button actions end....

  //Brand Header button actions start

  const addBrandModal = (
    <AddBrandView
      key={4}
      formErrors={formErrors}
      selectedBrand={selectedBrand}
      open={addViewOpen}
      addBrand={(params) => addBrand(params)}
      handleClose={() => closeAddBrandView()}
      categories={categories}
      selectCategory={(category) => selectCategory(category)}
    />
  );
  //Brand Header button actions end.....

  //Product actions start....
  const addProductModal = (
    <AddProductView
      formErrors={formErrors}
      key={3}
      // brands={brands}
      uoms={uoms}
      companyId={uid}
      company={companies.company}
      categories={categories}
      selectedProduct={selectedProduct}
      open={addViewOpen}
      addProduct={addProduct}
      handleClose={() => closeAddProductView()}
      setBrand={(brandId) => {
        setSelectedBrandId(brandId);
      }}
      setBrandOpt={(value) => _getBrandOpt(value)}
      setCategory={(categoryId) => setSelectedCategoryId(categoryId)}
    />
  );
  //Product actions end....

  //Node actions start...
  const addOrderNodeModal = (
    <AddOrderNodeView
      key={5}
      addNode={addNode}
      open={addViewOpen}
      handleClose={() => closeAddNodeView()}
      dialogTitle={'Add Company Node'}
      initialValues={nodeInitialValues}
    />
  );

  //Category Filter columns start...
  let categoryNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Name',
    field: 'name',
    value: categoryName,
    setNameVal: (e) => setNameVal(setCategoryName, e)
  };
  //Category Filter columns end...

  //Brand Filter column Obj start...
  let brandNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Name',
    field: 'name',
    value: brandName,
    setNameVal: (e) => setNameVal(setBrandName, e)
  };

  let brandCatNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Category Name',
    field: 'Category.name',
    value: brandCategoryName,
    setNameVal: (e) => setNameVal(setBrandCategoryName, e)
  };

  //Product FIlter objects start...
  let productNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Customer SKU Code',
    field: 'name',
    value: productName,
    setNameVal: (e) => setNameVal(setProductName, e)
  };

  let productDescObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Name',
    field: 'description',
    value: productDescription,
    setNameVal: (e) => setNameVal(setProductDescription, e)
  };

  let productBarObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Barcode',
    field: 'barcode',
    value: productBarcode,
    setNameVal: (e) => setNameVal(setProductBarcode, e)
  };

  let productVolObj = {
    type: 'text',
    className: 'textField',
    label: 'Volume cm3',
    field: 'dimensionsCBM',
    value: productVol,
    setNameVal: (e) => setNameVal(setProductVol, e)
  };

  let productWeightObj = {
    type: 'text',
    className: 'textField',
    label: 'Weight per UoM in Kgs',
    field: 'weight',
    value: productWeight,
    setNameVal: (e) => setNameVal(setProductWeight, e)
  };

  let productCatObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Category',
    field: 'Brand.Category.name',
    value: productCatName,
    setNameVal: (e) => setNameVal(setProductCatName, e)
  };

  let productBrandObj = {
    type: 'text',
    className: 'textField',
    label: 'SKU Brand',
    field: 'Brand.name',
    value: productBrandName,
    setNameVal: (e) => setNameVal(setProductBrandName, e)
  };

  let productUomObj = {
    type: 'text',
    className: 'textField',
    label: 'UoM',
    field: 'UOM.name',
    value: productUomName,
    setNameVal: (e) => setNameVal(setProductUomName, e)
  };

  let productPalletObj = {
    type: 'text',
    className: 'textField',
    label: 'UOM per Pallet',
    field: 'perPallet',
    value: productPerPallet,
    setNameVal: (e) => setNameVal(setProductPerPallet, e)
  };

  let manufacturerNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Manufacturer Name',
    field: 'manufacturerName',
    value: manufacturerName,
    setNameVal: (e) => setNameVal(setManufacturerName, e)
  };
  //Brand Filter column Obj end...

  //Node Filter column Obj Start
  let nodeNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Name',
    field: 'name',
    value: nodeName,
    setNameVal: (e) => setNameVal(setNodeName, e)
  };
  let nodeCodeObj = {
    type: 'text',
    className: 'textField',
    label: 'Code',
    field: 'code',
    value: nodeCode,
    setNameVal: (e) => setNameVal(setNodeCode, e)
  };
  let addressObj = {
    type: 'text',
    className: 'textField',
    label: 'Address',
    field: 'address',
    value: address,
    setNameVal: (e) => setNameVal(setAddress, e)
  };
  let cityObj = {
    type: 'text',
    className: 'textField',
    label: 'City',
    field: 'cityName',
    value: city,
    setNameVal: (e) => setNameVal(setCity, e)
  };

  //Custom modal for filters start...
  let filterCols = [];
  if (type == 0) {
    filterCols = [categoryNameObj];
  } else if (type == 1) {
    filterCols = [brandNameObj, brandCatNameObj, manufacturerNameObj];
  } else if (type == 2) {
    filterCols = [
      productNameObj,
      productDescObj,
      productBarObj,
      productVolObj,
      productWeightObj,
      productCatObj,
      productBrandObj,
      productUomObj,
      productPalletObj
    ];
  } else if (type == 3) {
    //for type 3 node tab is active
    filterCols = [nodeNameObj, nodeCodeObj, addressObj, cityObj];
  }

  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'Category'}
        handleClose={() => closeFilter()}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );
  //Custom modal for filters end...

  const getAddModal = () => {
    let addModal = '';
    if (type == 0) {
      return (addModal = addCategoryModal);
    } else if (type == 1) {
      return (addModal = addBrandModal);
    } else if (type == 2) {
      return (addModal = addProductModal);
    } else {
      return (addModal = addOrderNodeModal);
    }
  };

  const addBulkProductsButton = (
    <Tooltip title="Upload Bulk Products">
      <button
        className={`templateBtn ${classes.uploadButton}`}
        onClick={() => navigate(`/administration/product/bulk-upload/${uid}`)}
      >
        <b className={classes.uploadButtonText}>UPLOAD</b>
      </button>
    </Tooltip>
  );
  const addBulkNodesButton = (
    <Tooltip title="Upload Bulk Nodes">
      <button
        className={`templateBtn ${classes.uploadButton}`}
        onClick={() => navigate(`/administration/node/bulk-upload/${uid}`)}
      >
        <b className={classes.uploadButtonText}>UPLOAD</b>
      </button>
    </Tooltip>
  );
  //Header buttons start...
  const headerButtons = [
    (checkPermission(currentUser, 'OPS_CATEGORY_READ') && type == 0) ||
    (checkPermission(currentUser, 'OPS_BRAND_READ') && type == 1) ||
    (checkPermission(currentUser, 'OPS_PRODUCT_READ') &&
      type != 0 &&
      type !== 1 &&
      type != 3)
      ? searchInput
      : null,

    (checkPermission(currentUser, 'OPS_CATEGORY_CREATE') && type == 0) ||
    (checkPermission(currentUser, 'OPS_BRAND_CREATE') && type == 1) ||
    (checkPermission(currentUser, 'OPS_PRODUCT_CREATE') &&
      type != 0 &&
      type !== 1 &&
      type != 3) ||
    (checkPermission(currentUser, 'OPS_PRODUCT_CREATE') && type == 3)
      ? addCategoryButton
      : null,

    checkPermission(currentUser, 'OPS_CATEGORY_CREATE') ||
    (checkPermission(currentUser, 'OPS_CATEGORY_UPDATE') && type == 0) ||
    checkPermission(currentUser, 'OPS_BRAND_CREATE') ||
    (checkPermission(currentUser, 'OPS_BRAND_UPDATE') && type == 1) ||
    checkPermission(currentUser, 'OPS_PRODUCT_CREATE') ||
    (checkPermission(currentUser, 'OPS_PRODUCT_UPDATE') &&
      type != 0 &&
      type !== 1 &&
      type != 3)
      ? getAddModal()
      : null,

    (checkPermission(currentUser, 'OPS_CATEGORY_READ') && type == 0) ||
    (checkPermission(currentUser, 'OPS_BRAND_READ') && type == 1) ||
    (checkPermission(currentUser, 'OPS_PRODUCT_READ') &&
      type != 0 &&
      type !== 1 &&
      type != 3) ||
    (checkPermission(currentUser, 'OPS_PRODUCT_READ') && type == 3)
      ? filterModal
      : null
  ];

  if (type == '2') {
    headerButtons.push(
      checkPermission(currentUser, 'OPS_PRODUCT_BULK_UPLOAD')
        ? addBulkProductsButton
        : null
    );
  } else if (type == '3') {
    //if nodes tab is active add nodes bulk upload button to header buttons
    headerButtons.push(
      checkPermission(currentUser, 'OPS_NODES_BULK_UPLOAD')
        ? addBulkNodesButton
        : null
    );
  }

  //Header buttons end...
  return (
    <>
      <div className="companyDetails">
        <div className={`label ${classes.companyDetails}`}>
          <b>ID:</b>
          <b>Company:</b>
          <b>Company Type:</b>
          <b>Contact Name:</b>
          <b>Contact Email:</b>
          <b>Status:</b>
        </div>
        <div className={`renderData ${classes.companyDetails}`}>
          <div>
            <b className={classes.companyDetailsText}>
              {companies ? companies.company.internalIdForBusiness : ''}
            </b>
          </div>

          <div>
            <b className={classes.companyDetailsText}>
              {companies ? companies.company.name : ''}
            </b>
          </div>

          <div>
            <b className={classes.companyDetailsText}>
              {companies ? companies.company.type : ''}
            </b>
          </div>

          <div>
            <b className={classes.companyDetailsText}>
              {companies
                ? companies.company.Contact.firstName +
                  ' ' +
                  companies.company.Contact.lastName
                : ''}
            </b>
          </div>

          <div>
            <b className={classes.companyDetailsText}>
              {companies ? companies.company.Contact.email : ''}
            </b>
          </div>

          <div>
            <b className={classes.companyDetailsText}>
              {companies
                ? companies.company.isActive
                  ? 'Active'
                  : 'In-active'
                : ''}
            </b>
          </div>
        </div>
        {/* Company Logo Image */}
        {companyLogoImage ? (
          <Box sx={{ marginLeft: 30 }}>
            <img
              src={companyLogoImage}
              width="140px"
              height="auto"
              style={{ objectFit: 'cover' }}
            />
          </Box>
        ) : (
          ''
        )}
      </div>
      <TabStyled defaultValue={0}>
        <TabsList className="onboardingTab">
          <Tooltip title="Categories">
            <Tab onClick={() => changeType(0)}>Categories</Tab>
          </Tooltip>
          <Tooltip title="Brands">
            <Tab onClick={() => changeType(1)}>Brands</Tab>
          </Tooltip>
          <Tooltip title="Products">
            <Tab onClick={() => changeType(2)}>Products</Tab>
          </Tooltip>
          <Tooltip title="Nodes">
            <Tab onClick={() => changeType(3)}>Nodes</Tab>
          </Tooltip>
        </TabsList>

        <LazyLoad>
          <TabPanel value={0}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <TableHeader buttons={[...headerButtons]} />
                <Table aria-label="sticky table">
                  <TableBody>
                    <div
                      className={classes.tableBody}
                      style={{ height: 750, width: '100%' }}
                    >
                      <DataGrid
                        rows={
                          checkPermission(currentUser, 'OPS_CATEGORY_READ')
                            ? categories
                            : []
                        }
                        hiddenColumnModel={['id']}
                        columns={
                          checkPermission(currentUser, 'OPS_CATEGORY_READ')
                            ? categoryColumns
                            : []
                        }
                        components={{ Toolbar: CustomToolbar }}
                        pageSize={15}
                        rowsPerPageOptions={[6]}
                        className="parentDataTable"
                        loading={muiLoader}
                      />
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justify="space-between">
                <Grid item></Grid>
                <Grid item>
                  <Pagination
                    component="div"
                    shape="rounded"
                    count={pageCount}
                    color="primary"
                    page={page}
                    className={classes.pagination}
                    onChange={(e, newPage) => setPage(newPage)}
                  />
                </Grid>
              </Grid>
              <MessageSnackbar showMessage={showMessage} />
            </Paper>
          </TabPanel>
        </LazyLoad>

        <LazyLoad>
          <TabPanel value={1}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <TableHeader buttons={[...headerButtons]} />
                <Table aria-label="sticky table">
                  <TableBody>
                    <div style={{ height: 750, width: '100%' }}>
                      <DataGrid
                        rows={
                          checkPermission(currentUser, 'OPS_BRAND_READ')
                            ? brands
                            : []
                        }
                        hiddenColumnModel={['id']}
                        columns={
                          checkPermission(currentUser, 'OPS_BRAND_READ')
                            ? brandColumns
                            : []
                        }
                        components={{ Toolbar: CustomToolbar }}
                        pageSize={15}
                        rowsPerPageOptions={[6]}
                        className="parentDataTable"
                        loading={muiLoader}
                      />
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justify="space-between">
                <Grid item></Grid>
                <Grid item>
                  <Pagination
                    component="div"
                    shape="rounded"
                    count={pageCountBrands}
                    color="primary"
                    page={page}
                    className={classes.pagination}
                    onChange={(e, newPage) => setPage(newPage)}
                  />
                </Grid>
              </Grid>
              <MessageSnackbar showMessage={showMessage} />
            </Paper>
          </TabPanel>
        </LazyLoad>

        <LazyLoad>
          <TabPanel value={2}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <TableHeader
                  buttons={[
                    checkPermission(currentUser, 'OPS_CATEGORY_READ') ? (
                      <ExportButton
                        style={`${classes.exportButton} ${classes.exportBtn}`}
                        cropText={true}
                        api={{ apiUrl: `companies/${uid}/products/export` }}
                      />
                    ) : null,
                    ...headerButtons
                  ]}
                />
                <Table aria-label="sticky table">
                  <TableBody>
                    <div style={{ height: 750, width: '100%' }}>
                      <DataGrid
                        rows={products}
                        hiddenColumnModel={['id']}
                        columns={productColumns}
                        components={{ Toolbar: CustomToolbar }}
                        pageSize={15}
                        rowsPerPageOptions={[6]}
                        className="parentDataTable"
                        loading={muiLoader}
                      />
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justify="space-between">
                <Grid item></Grid>
                <Grid item>
                  <Pagination
                    component="div"
                    shape="rounded"
                    count={pageCountProducts}
                    color="primary"
                    page={page}
                    className={classes.pagination}
                    onChange={(e, newPage) => setPage(newPage)}
                  />
                </Grid>
              </Grid>
              <MessageSnackbar showMessage={showMessage} />
            </Paper>
          </TabPanel>
        </LazyLoad>

        <LazyLoad>
          <TabPanel value={3}>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <TableHeader buttons={[...headerButtons]} />
                <Table aria-label="sticky table">
                  <TableBody>
                    <div style={{ height: 750, width: '100%' }}>
                      <DataGrid
                        rows={
                          checkPermission(currentUser, 'OPS_BRAND_READ')
                            ? nodes
                            : []
                        }
                        hiddenColumnModel={['id']}
                        columns={
                          checkPermission(currentUser, 'OPS_BRAND_READ')
                            ? nodeColumns
                            : []
                        }
                        components={{ Toolbar: CustomToolbar }}
                        pageSize={20}
                        rowsPerPageOptions={[6]}
                        className="parentDataTable"
                        loading={muiLoader}
                      />
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justify="space-between">
                <Grid item></Grid>
                <Grid item>
                  <Pagination
                    component="div"
                    shape="rounded"
                    count={pageCountNodes}
                    color="primary"
                    page={page}
                    className={classes.pagination}
                    onChange={(e, newPage) => setPage(newPage)}
                  />
                </Grid>
              </Grid>
              <MessageSnackbar showMessage={showMessage} />
            </Paper>
          </TabPanel>
        </LazyLoad>
      </TabStyled>
    </>
  );
}

import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router';

import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';

export const generateEmbeddedReportUrl = (url, orgId) => {
  let ret = '';
  if (!url) return url;
  if (url.indexOf('?') !== -1) {
    ret =
      url.slice(0, url.indexOf('?') + 1) +
      `org_id=${orgId}&` +
      url.slice(url.indexOf('?') + 1);
  } else if (url.indexOf('#') !== -1) {
    ret =
      url.slice(0, url.indexOf('#')) +
      `?org_id=${orgId}` +
      url.slice(url.indexOf('#'));
  } else {
    ret = url + `?org_id=${orgId}`;
  }
  return ret;
};

export default function ShowDynamicReport() {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState('');
  const { setCurrentPageTitle, organizationId, subdomain } =
    useContext(SharedContext);

  useEffect(() => {
    setCurrentPageTitle(report?.name || 'Embedded Report');
  }, [report]);

  useEffect(() => {
    subdomain && getReport();
  }, [uid]);

  const getReport = async () => {
    try {
      const reports = await API.get(
        `organizations/${subdomain}/embedded-reports/${uid}`
      );
      if (reports?.data) {
        setReport(reports?.data);
      } else {
        navigate('/404');
      }
    } catch (err) {
      onError(err);
    }
  };

  return (
    <iframe
      title={report?.name}
      src={generateEmbeddedReportUrl(report?.URL, organizationId)}
      className="embeddReports"
      frameBorder="0"
    ></iframe>
  );
}

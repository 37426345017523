import { Tooltip } from '@material-ui/core';

export default function CustomTabs(props) {
  return (
    <div className="invoiceBorder">
      <Tooltip title="Information">
        <button
          className={`invoice-form-tabs first-tab ${
            props?.activeTab === 'info' && 'outlined-tab'
          } ${props?.activeTab === 'info' && 'activeTab'} ${
            (props.activeTab === 'invoiceForm' ||
              props.activeTab === 'preview') &&
            'filled-tab'
          }`}
          onClick={() => {
            props.activeTab != 'info' && props.changeTab('info');
          }}
        >
          1. Information
        </button>
      </Tooltip>
      <Tooltip title="Invoice Form">
        <button
          className={`invoice-form-tabs second-tab ${
            props?.activeTab === 'invoiceForm' && 'outlined-tab'
          } ${props?.activeTab === 'invoiceForm' && 'activeTab'} ${
            props.activeTab === 'preview' && 'filled-tab'
          }`}
          onClick={() => {
            props.activeTab !== 'invoiceForm' &&
              props.activeTab !== 'info' &&
              props.changeTab('invoiceForm');
          }}
        >
          2. Invoice Form
        </button>
      </Tooltip>
      <Tooltip title="Preview">
        <button
          className={`invoice-form-tabs third-tab ${
            props?.activeTab === 'preview' && 'outlined-tab'
          } ${props?.activeTab === 'preview' && 'activeTab'}`}
          onClick={() => {
            props.activeTab != 'info' &&
              props.activeTab !== 'invoiceForm' &&
              props.changeTab('info');
          }}
        >
          3. Preview
        </button>
      </Tooltip>
    </div>
  );
}

/*eslint-disable*/

import { useContext, useState } from 'react';
import Popup from '../../../core-components/atoms/Popup';
import UomConversion from './UomConversion';
import { SharedContext } from '../../../utils/common';
import Button from '../../../core-components/atoms/Button';
import API from '../../../libs/axios';

const UomPopup = ({
  open,
  setOpen,
  refetech,
  primaryUom,
  setPrimaryUom,
  secondaryUoms,
  setSecondaryUoms,
  value
}) => {
  const [secondaryUomsData, setSecondaryUomsData] = useState(secondaryUoms);
  const { setAPILoader } = useContext(SharedContext);

  const handleUomCreation = async (uomId, uoms) => {
    setAPILoader(true);
    try {
      await API.put(`products/${value.id}`, {
        uoms,
        isActive: value.isActive,
        batchEnabled: value.batchEnabled
      });
      setSecondaryUoms([...uoms]);
      setOpen(false);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      title={'Unit of Measure'}
      content={
        <div className="mt-4">
          <UomConversion
            primaryUom={primaryUom}
            secondaryUoms={secondaryUomsData}
            setPrimaryUom={setPrimaryUom}
            setSecondaryUoms={setSecondaryUomsData}
          />
        </div>
      }
      actions={
        <div className="flex gap-2">
          <Button
            label="Cancel"
            variant="transparent"
            onClick={() => {
              setOpen(false);
              setSecondaryUomsData(secondaryUoms);
            }}
          />
          <Button
            label="Save"
            variant="primary"
            onClick={async () => {
              await handleUomCreation(primaryUom?.id, secondaryUomsData);
              await refetech();
            }}
          />
        </div>
      }
    />
  );
};

export default UomPopup;

import * as Yup from 'yup';
import { Box, Button, Container, FormControl, Grid } from '@mui/material';
import TextField from '../../core-components/atoms/TextField';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import API from '../../libs/axios';
import { toaster } from '../../utils/toaster';
import { useSearchParams } from 'react-router-dom';
import { onError } from '../../libs/errorLib';
import mixpanel from 'mixpanel-browser';

const organizationTypes = [
  { label: '3PL', value: 'THIRD_PARTY_SERVICE_PROVIDER' },
  { label: 'Trader', value: 'THIRD_PARTY_SERVICE_PROVIDER' },
  { label: 'Manufacturer', value: 'MANUFACTURER' }
];

const industries = [
  'Retail',
  'Manufacturing',
  'Healthcare',
  'Automotive',
  'Food and Beverage',
  'Consumer Packaged Goods (CPG)',
  'E-commerce',
  'Pharmaceuticals',
  'Logistics and Transportation',
  'Apparel and Footwear',
  'Electronics and High-Tech',
  'Aerospace and Defense',
  'Oil and Gas',
  'Construction',
  'Wholesale and Distribution',
  'Chemicals',
  'Agriculture',
  'Telecommunications',
  'Energy and Utilities',
  '3PL (Third-Party Logistics) Providers'
];

const numberOfEmployees = [
  '1-10 employees',
  '11-50 employees',
  '51-100 employees',
  '101-200 employees',
  '201-500 employees',
  '501-1,000 employees',
  '1,001-5,000 employees',
  '5000+ employees'
];

const validationSchema = Yup.object({
  name: Yup.string().required('Organization Name is required'),
  type: Yup.string().required('Organization Type is required'),
  industry: Yup.string().required('Industry is required'),
  employeeCount: Yup.string().required('Number of Employees is required'),
  address: Yup.string()
});

const OrganizationSetupForm = () => {
  let [searchParams] = useSearchParams();
  let [authToken, setAuthToken] = useState('');

  const createOrganization = async (values) => {
    let token = searchParams.get('verificationToken');
    try {
      const response = await API.post(
        'organizations/onboard',
        {
          organizationData: values,
          verificationToken: token,
          trial: true
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );

      return response.organization;
    } catch (e) {
      onError(e);
    }
  };

  const createCheckoutSession = async (subdomain) => {
    let subscriptionPlanKey = searchParams.get('subscriptionPlanKey') || 'SEED';

    try {
      const response = await API.post(
        `organizations/${subdomain}/stripe/checkout-session`,
        {
          subscriptionPlanKey,
          trial: true,
          saveToken: true
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );

      return response.checkoutUrl;
    } catch (e) {
      onError(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      industry: '',
      employeeCount: '',
      address: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      const organization = await createOrganization(values);
      const checkoutUrl = await createCheckoutSession(organization.subdomain);

      if (organization) {
        mixpanel.track('Organization Onboarded', values);
        toaster('success', 'Organization successfully created');
        window.location.href = checkoutUrl;
      }
    }
  });

  const getToken = async () => {
    try {
      let token = searchParams.get('verificationToken');
      const response = await API.post('auth/user', {
        verificationToken: token
      });

      setAuthToken(response.token);
      formik.setFieldValue('name', response.companyName);
      mixpanel.track('Organization Onboarding', {
        companyName: response.companyName
      });
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  const sxProps = {
    '& .MuiInputBase-root': {
      padding: '8px 16px !important',
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  const listBoxProps = {
    fontSize: '14px !important'
  };

  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  return (
    <Container className="bg-white p-8 rounded-lg shadow-lg max-w-xl web-app-default-font">
      <div className="mt-2">
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">
            Organization Name
          </p>
          <span className="text-error mt-1 ml-1">*</span>
        </div>
        <div className="flex w-full max-w-[532px]">
          <FormControl fullWidth variant="outlined">
            <TextField
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              placeholder="Enter Organization Name"
              sxProps={{
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                }
              }}
              size="small"
            />
          </FormControl>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">
            Organization Type
          </p>
          <span className="text-error mt-1 ml-1">*</span>
        </div>
        <div className="flex w-full max-w-[532px]">
          <div className="w-full max-w-[532px]">
            <Autocomplete
              options={organizationTypes}
              onChange={(e, o) => {
                formik.setFieldValue('type', o.value);
              }}
              isOptionEqualToValue={(option, obj) => option.value === obj.value}
              value={
                organizationTypes.find(
                  (option) => option.value === formik.values.type
                ) || ''
              }
              placeholder="Select Organization Type"
              getOptionLabel={(option) => option.label || ''}
              sxProps={sxProps}
              customInputProps={inputProps}
              listBoxProps={listBoxProps}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">Industry</p>
          <span className="text-error mt-1 ml-1">*</span>
        </div>
        <div className="flex w-full max-w-[532px]">
          <div className="w-full max-w-[532px]">
            <Autocomplete
              options={industries}
              onChange={(_, value) => {
                formik.setFieldValue('industry', value);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              value={formik.values.industry}
              placeholder="Select Industry"
              sxProps={sxProps}
              customInputProps={inputProps}
              listBoxProps={listBoxProps}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">
            Number of Emplyees
          </p>
          <span className="text-error mt-1 ml-1">*</span>
        </div>
        <div className="flex w-full max-w-[532px]">
          <div className="w-full max-w-[532px]">
            <Autocomplete
              options={numberOfEmployees}
              onChange={(_, value) => {
                formik.setFieldValue('employeeCount', value);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              value={formik.values.employeeCount}
              placeholder="Select Number of Emplyees"
              sxProps={sxProps}
              customInputProps={inputProps}
              listBoxProps={listBoxProps}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">Address</p>
        </div>
        <div className="flex w-full max-w-[532px]">
          <FormControl fullWidth variant="outlined">
            <TextField
              value={formik.values.address}
              onChange={(e) => formik.setFieldValue('address', e.target.value)}
              placeholder="Enter Address"
              sxProps={{
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                }
              }}
              size="small"
              multiline
              rows={2}
            />
          </FormControl>
        </div>
      </div>
      <Grid item xs={12}>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="success"
            size="large"
            className="bg-green-500 hover:bg-green-600 text-white mt-5"
            sx={{ borderRadius: '50px' }}
            fullWidth
            onClick={formik.handleSubmit}
          >
            Create Organization
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};

export default OrganizationSetupForm;

import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { SharedContext } from '../utils/common';

const ControlTowerBanner = () => {
  const { organization } = React.useContext(SharedContext);
  const token = localStorage.getItem('token');
  const location = useLocation();
  const [showBanner, setShowBanner] = React.useState(true);

  const bannerStyle = {
    position: 'relative',
    top: location.pathname === '/control-tower' ? '85px' : '50px',
    left: '25px',
    width: '100%',
    padding: '15px',
    textAlign: 'center',
    zIndex: '1000',
    fontSize: '20px',
    boxShadow:
      location.pathname === '/control-tower' ? '1px 1px 15px -10px' : ''
  };

  return showBanner && token && organization?.selfCreated ? (
    <div style={bannerStyle} className="flex justify-between banner">
      <div></div>
      <Box className="flex items-center">
        <Box>
          <Typography variant="body2" className="text-sm text-gray-600">
            <b>Note:</b> Currently displaying the demo data. You can reset it
            from the settings.
          </Typography>
        </Box>
      </Box>
      <IconButton
        size="small"
        className="text-gray-600"
        onClick={() => {
          setShowBanner(false);
        }}
        style={{ marginRight: '25px' }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  ) : null;
};

export default ControlTowerBanner;

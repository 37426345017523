import React from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import DetailTitle from './DetailTitle';
import { getPropByString } from '../utils/common';

const FormikRadioGroup = ({ name, formik, options, title }) => {
  const value = getPropByString(formik.values, name);
  const errors = getPropByString(formik.errors, name);
  const touched = getPropByString(formik.touched, name);

  return (
    <>
      <DetailTitle text={title} />
      <RadioGroup
        row
        id={name}
        name={name}
        value={value}
        onChange={formik?.handleChange}
      >
        {options?.length
          ? options.map((option) => (
              <FormControlLabel
                className="mr-10"
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))
          : null}
      </RadioGroup>
      {errors && touched && <Typography color="error">{errors}</Typography>}
    </>
  );
};

export default FormikRadioGroup;

/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { checkPermission } from '../../../utils/auth';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  makeStyles,
  Paper,
  Button,
  Table,
  TableBody,
  Tooltip,
  TableContainer
} from '@material-ui/core';
import TableHeader from '../../../components/TableHeader';
import Filter from '../../../components/Filter';
import EditIcon from '@material-ui/icons/EditOutlined';
import PaginationGrid from '../../../components/PaginationGrid';

import { SharedContext } from '../../../utils/common';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import '../../../assets/css/style.css';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { useNavigate } from 'react-router';
import AddCountryView from './AddCountryView';
import { toaster } from '../../../utils/toaster';
import FilterButton from '../../../components/stats/FilterButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px'
  },
  container: {
    padding: 20
  },
  searchInputCountry: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  active: {
    color: theme.palette.success.main
  },
  addCountryButton: {
    marginLeft: '0px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  }
}));

export default function CountryView() {
  const classes = useStyles();
  const navigate = useNavigate();

  const columnsNew = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Country name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions columnCountry">
            Country Name
            {fetchState && nameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.name}`}>
          <span className="table-cell-trucate">{params.row.name}</span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          <Tooltip title="View">
            <VisibilityIcon
              key={params.row.id}
              onClick={() => navigate(`${params.row.id}/regions`)}
            />
          </Tooltip>
          {checkPermission(currentUser, 'OPS_COUNTRY_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon key={params} onClick={() => openEditView(params.row)} />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterView, setFilterView] = useState(false);
  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  const [nameVal, setName] = useState('');

  const [fetchState, setFetchState] = useState(false);
  const [sortable, setSortable] = useState('');
  const [addCountryViewOpen, setAddCountryViewOpen] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [clickedAlready, setClickedAlready] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);

  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    setCurrentPageTitle('Countries');
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes();
  }, [nameVal, sortable]);
  const { currentUser } = useContext(SharedContext);

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const openEditView = (county) => {
    setSelectedCountry(county);
    setAddCountryViewOpen(true);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (nameVal) {
      colVal = { name: nameVal };
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = ['name'];

    _getCountries(page, searchKeyword, filters, columns);
  };

  const _getCountries = async (page, searchKeyword, filters, columns) => {
    try {
      setMuiLoader(true);
      const countries = await API.get(`countries`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setPageCount(countries.pages);
      setCountries(countries.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setMuiLoader(false);
    }
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const getCountries = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        name: searchKeyword ? searchKeyword : '',
        sortable
      };
      let columns = ['name'];
      _getCountries(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  const addCountry = async (data) => {
    setFormErrors('');
    try {
      setClickedAlready(true);
      setAPILoader(true);
      if (!selectedCountry) await API.post(`countries`, data);
      else await API.put(`countries/${selectedCountry.id}`, data);
      toaster(
        'success',
        !selectedCountry
          ? 'New Country has been created.'
          : 'Country updated successfully.'
      );
      closeAddCountryView();
      getCountries(page, nameVal);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setClickedAlready(false);
      setAPILoader(false);
    }
  };

  const closeAddCountryView = () => {
    setSelectedCountry(null);
    setAddCountryViewOpen(false);
  };

  const addCountryModal = (
    <AddCountryView
      key={4}
      formErrors={formErrors}
      selectedCountry={selectedCountry}
      open={addCountryViewOpen}
      addCountry={addCountry}
      clickedAlready={clickedAlready}
      setClickedAlready={setClickedAlready}
      handleClose={() => closeAddCountryView()}
    />
  );

  useEffect(() => {
    getCountries(page, searchKeyword);
  }, [page, searchKeyword]);

  let nameObj = {
    type: 'text',
    className: 'textField',
    label: 'Country Name',
    field: 'name',
    value: nameVal ? nameVal : '',
    setNameVal: (e) => setNameVal(setName, e)
  };

  const filterCols = [nameObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'User'}
        handleClose={() => closeFilter()}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const addCountryButton = (
    <Tooltip title="Add Country">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addCountryButton}
        size="small"
        onClick={() => setAddCountryViewOpen(true)}
      >
        ADD Country
      </Button>
    </Tooltip>
  );
  const headerButtons = [
    checkPermission(currentUser, 'OPS_COUNTRY_CREATE')
      ? addCountryButton
      : null,
    // checkPermission(currentUser, "OPS_COUNTRY_READ") ? searchInput : null,
    checkPermission(currentUser, 'OPS_COUNTRY_CREATE') ||
    checkPermission(currentUser, 'OPS_COUNTRY_UPDATE')
      ? addCountryModal
      : null,
    checkPermission(currentUser, 'OPS_COUNTRY_READ') ? filterModal : null
  ];

  // Custom toolbar
  function CustomCountryToolbar() {
    return (
      <FilterButton searchKeyword openFilterView={() => openFilterView()} />
    );
  }
  // Custom toolbar
  const visibleColumns = { name: true };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table" className="tableDiv">
          <TableBody>
            <div style={{ height: 1100, width: '100%' }}>
              <DataGrid
                rows={countries}
                hiddenColumnModel={['id']}
                visibleColumns={visibleColumns}
                columns={columnsNew}
                components={{ Toolbar: CustomCountryToolbar }}
                pageSize={15}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
                loading={muiLoader}
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}

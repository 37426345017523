import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const classes = {
  customUI: {
    textAlign: 'center',
    width: '500px',
    padding: '40px',
    background: '#fff',
    boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
    color: 'black',
    zIndex: 1500
  },
  customButton: {
    width: '160px',
    padding: '14px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#005cfd',
    color: '#fff',
    fontSize: '14px',
    borderRadius: '4px'
  },
  customButtonCancel: {
    width: '160px',
    padding: '14px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#e0e0e0',
    color: '#1d1d1d',
    fontSize: '14px',
    borderRadius: '4px'
  },
  popupHeader: {
    paddingBottom: '14px',
    fontWeight: 600,
    fontSize: '24px'
  },
  popupMessage: {
    fontSize: '16px',
    fontWeight: 400,
    paddingBottom: '14px'
  }
};

export function DialogueConfirmationOpen(
  values,
  isDraft,
  submitHandler,
  type,
  dialogueText
) {
  const dialogue = dialogueText ? dialogueText : 'Are you sure?';

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div style={classes.customUI}>
          <p style={classes.popupHeader}>Confirmation</p>
          <p style={classes.popupMessage}>
            {isDraft
              ? `Are you sure you would like to save to draft ${type}?`
              : dialogue}
          </p>
          <button style={classes.customButtonCancel} onClick={onClose}>
            NO
          </button>
          <button
            style={classes.customButton}
            onClick={() => {
              submitHandler(values, isDraft);
              onClose();
            }}
          >
            YES
          </button>
        </div>
      );
    }
  });
}

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { decimalDisplay } from '../../utils/common';
import InventoryProductsSubTable from './InventoryProductsSubTable';

const InventoryProductTableRow = (props) => {
  const { row } = props;
  const [openSubTable, setOpenSubTable] = useState(false);

  return (
    <>
      <TableRow
        className="group"
        sx={{
          '& td,th': {
            borderBottom: 0,
            paddingTop: 3,
            paddingBottom: 3
          }
        }}
      >
        <TableCell component="th" scope="row">
          <span>
            <Tooltip title={`${row?.product?.name}`}>
              <p className="text-base font-medium ">
                {row?.product.name}-{row?.product.description}
              </p>
            </Tooltip>
          </span>
          <div className="font-xs truncate w-[180px]">
            {row?.product?.UOM?.name && (
              <span>UoM: {row?.product?.UOM?.name}</span>
            )}
          </div>
        </TableCell>
        <TableCell className="text-base">{row.nodeName}</TableCell>
        <TableCell className="text-base">{row.batches.length}</TableCell>
        <TableCell className="text-base">{row.quantity}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenSubTable(!openSubTable)}
          >
            {openSubTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {!!row?.batches?.length && (
        <TableRow
          sx={
            openSubTable
              ? {
                  '& th,td': {
                    borderBottom: '0'
                  },
                  '& .childRow td': {
                    borderBottom: 'inset'
                  }
                }
              : null
          }
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={12}
            className="subRow"
          >
            <Collapse in={openSubTable} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }}>
                <div className="GDNScroll">
                  <InventoryProductsSubTable
                    batches={row.batches}
                    batchEnabled={row.product?.batchEnabled}
                  />
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default InventoryProductTableRow;

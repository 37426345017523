import { makeStyles } from '@material-ui/core';

const onexusStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '98%',
    margin: '40px auto 0px auto',
    maxHeight: '812px !important',
    height: '812px !important',
    border: '2px solid #4B89C8',
    borderRadius: '8px',
    '@media (max-width: 768px)': {
      flexDirection: 'column-reverse',
      margin: '10px auto 0px auto',
      height: '98% !important'
    }
  },
  listing: {
    padding: '5px',
    boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.2)',
    borderRadius: '6px',
    width: '28% !important',
    height: '99% !important',
    overflow: 'auto !important',
    '@media (max-width: 1024px)': {
      width: '100% !important'
    }
  },
  map: {
    margin: '12px',
    boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.2)',
    borderRadius: '6px',
    width: '72% !important',
    position: 'relative',
    minHeight: 300,
    '@media (max-width: 1024px)': {
      width: '100% !important'
    },
    '@media (max-width: 768px)': {
      margin: '0px',
      overflow: 'hidden',
      minHeight: 'auto',
      height: '100%'
    },
    '@media (max-width: 612px)': {
      height: '100%'
    }
  },
  searchBar: {
    margin: '32px 0px 0px 0px',
    width: '100% !important',
    height: '32px',
    border: '1px solid #87A2BD',
    borderRadius: '8px',
    paddingLeft: '12px',
    '@media (max-width: 1024px)': {
      margin: '18px 0px 0px 0px'
    }
  },
  listingItemContainer: {
    width: '95% !important',
    margin: '20px auto 0px auto'
  },
  icon: {
    color: '#4B89C8 !important'
  },
  smallText: {
    fontWeight: '400 !important',
    fontSize: '12px !important'
  },
  businessCodeText: {
    color: '#909090',
    fontWeight: '400',
    fontSize: '12px !important',
    margin: '8px 0px !important'
  },
  smallDetailText: {
    color: '#909090',
    fontWeight: '400',
    fontSize: '12px !important',
    lineHeight: '20px !important'
  },
  viewButton: {
    background: '#4B89C8',
    border: '1px solid #4B89C8',
    borderRadius: '8px',
    width: '40px',
    height: '40px',
    color: 'white',
    cursor: 'pointer'
  },
  detailGrid: {
    marginTop: '20px !important'
  },
  detailSectionText: {
    color: '#4B89C8',
    fontWeight: '600',
    fontSize: '12px'
  },
  backClick: {
    cursor: 'pointer',
    position: 'absolute',
    top: '12px',
    left: '12px'
  },
  backClickNoImg: {
    cursor: 'pointer'
  },
  codeAndActiveContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  simpleText: {
    fontWeight: '400',
    fontSize: '12px'
  },
  activeStatusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  bookspaceBtn: {
    cursor: 'pointer',
    background: '#4B89C8',
    color: '#FFFFFF',
    border: '1px solid #4B89C8',
    borderRadius: '8px',
    width: '100%',
    height: '32px',
    margin: 'auto',
    fontWeight: '600',
    fontSize: '16px'
  },
  imageContainer: {
    position: 'relative',
    maxWidth: '100%',
    height: '200px'
  },
  imgPreview: {
    width: '100%',
    height: '200px'
  },
  imgGalleryContainer: {
    maxWidth: '100%',
    height: '175px',
    display: 'flex'
  },
  imgGalleryItem: {
    width: '96px !important',
    height: '136px !important',
    borderRadius: '8px !important',
    objectFit: 'cover'
  },
  arrowButton: {
    '@media (max-width: 1024px)': {
      padding: '0px !important'
    }
  }
}));

export default onexusStyles;

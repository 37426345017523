/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { FormControl, Grid, List, ListItem } from '@mui/material';

import Button from '../../core-components/atoms/Button';
import {
  SharedContext,
  bulkDateFormator,
  checkDates,
  exportToCSV
} from '../../utils/common';
import PopupButton from '../../core-components/molecules/PopupButton';
import { onError } from '../../libs/errorLib';
import BulkIcon from '../../assets/icons/blue-bulk-upload.svg';
import DownloadIcon from '../../assets/icons/download-template-light.svg';
import BulkFileAttacher from '../productMaster/ListViews/components/BulkFileAttacher';
import Popup from '../../core-components/atoms/Popup';
import WarningIcon from '../../assets/icons/warning.svg';
import downloadFileIcon from '../../assets/icons/templateDownload.svg';
import downloadData3PL from '../../assets/files/BulkUploadOrdersTemplate3PL..xlsx';
import downloadDataManufacturer from '../../assets/files/BulkUploadOrdersTemplateMFG.xlsx';
import { toaster } from '../../utils/toaster';
import API from '../../libs/axios';
import mixpanel from 'mixpanel-browser';

const BulkUpload = ({ refetch }) => {
  const [orders, setOrders] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkVerification, setBulkVerification] = useState(false);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [verifiedOrders, setVerifiedOrders] = useState([]);
  const [correctOrders, setCorrectOrders] = useState(0);
  const [inCorrectOrders, setInCorrectOrders] = useState(0);
  const [bulkBtnOpen, setBulkBtnOpen] = useState(false);

  const header = [
    'Order Type',
    'Customer ID/Name',
    'Pickup Location Code/Name',
    'Dropoff Location Code/Name',
    'Supporting Document ID',
    'SKU Code/Description',
    'Quantity',
    'Record Level',
    'Order Level'
  ];

  const { organizationType, setAPILoader } = useContext(SharedContext);

  const validateDatesAndData = (orders) => {
    try {
      const newOrders = orders.map((order) => {
        const dropOfDate = order['Dropoff Date']
          .toString()
          .replaceAll(/\D/g, '/');
        const pickUpDate = order['Pickup Date']
          .toString()
          .replaceAll(/\D/g, '/');

        if (!checkDates(dropOfDate) || !checkDates(pickUpDate)) {
          throw new Error(
            'Dates are not following a consistent format, all dates should either be DD/MM/YYYY or MM/DD/YYYY'
          );
        }

        const newOrder = {
          pickUpDate: bulkDateFormator(pickUpDate),
          dropOffDate: bulkDateFormator(dropOfDate),
          pickUpLocation: order['Pickup Location Code/Name'],
          dropOffLocation: order['Dropoff Location Code/Name'],
          moveType: order['Order Type'],
          referenceID: order['Supporting Document ID'],
          product: order['SKU Code/Description'],
          company: order['Customer ID/Name'],
          calculatedWeight: order['Weight'] ? order['Weight'] : 0,
          costPrice: order['Order Type'] == 'Purchase' ? order['Price'] : null,
          sellingPrice: order['Order Type'] == 'Sales' ? order['Price'] : null,
          tax: order['Tax %'],
          uom: order['UOM'],
          quantity: +order['Quantity'],
          logisticBy: order['Logistics By']
            ? order['Logistics By']
            : 'Customer',
          pallet: order['Palletized/Non-Palletized']
            ? order['Palletized/Non-Palletized']
            : 'Non-Palletized',
          weight: order['Weight'] ? order['Weight'] : 0,
          additionalInfo: order['Addditional Info'],
          paymentTermTitle: order['Payment Terms']
        };
        return newOrder;
      });
      setOrders(newOrders);
      setBulkVerification(true);
    } catch (error) {
      onError(error);
    }
  };

  const handleVerification = async () => {
    try {
      setAPILoader(true);
      const payload = {
        orders
      };

      const response = await API.post(`orders/bulk-upload-validation`, payload);
      if (response.orders.length) {
        setVerifiedOrders(response.orders);
      }
      setCorrectOrders(response.correctOrders);
      setInCorrectOrders(response.inCorrectOrders);
      setBulkUploadOpen(true);
      setBulkVerification(false);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const extractFaultyData = () => {
    if (inCorrectOrders > 0 && selectedFile) {
      let title = `Faulty Orders`;
      let arr = [
        ...verifiedOrders
          .filter(
            (order) =>
              order.rowLevel !== 'Correct' || order.orderLevel !== 'Correct'
          )
          .map((order) => ({
            moveType: order.moveType,
            company: order.company,
            pickUpLocation: order.pickUpLocation,
            dropOffLocation: order.dropOffLocation,
            referenceID: order.referenceID,
            product: order.product,
            quantity: +order.quantity,
            orderLevel: order.orderLevel,
            recordLeve: order.recordLevel
          }))
      ];
      arr.unshift(header);
      exportToCSV(null, arr, title);
    }
  };

  const resetStates = () => {
    setSelectedFile(null);
    setOrders([]);
    setVerifiedOrders([]);
    setCorrectOrders(0);
    setInCorrectOrders(0);
    setBulkUploadOpen(false);
    setBulkVerification(false);
  };

  const handleCreation = async () => {
    try {
      setAPILoader(true);
      const correctOrders = [];
      const faultyOrders = [];

      verifiedOrders.forEach((order) => {
        if (order.orderLevel != 'Correct') {
          faultyOrders.push(order);
        } else {
          correctOrders.push(order);
        }
      });

      if (!correctOrders.length) {
        throw new Error('No Correct orders');
      }

      const payload = {
        orders: [...correctOrders]
      };

      await API.post(`orders/bulk-upload`, payload);
      await refetch();
      toaster('success', 'Orders Uploaded Successfully!');
      resetStates();
      mixpanel.track('Bulk Order Creation', {
        ordersCount: correctOrders?.length
      });
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between mr-4">
        <div className="flex gap-2">
          <PopupButton
            open={bulkBtnOpen}
            setOpen={setBulkBtnOpen}
            btn={
              <Button
                label="Bulk Upload"
                className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
                onClick={(e) => {
                  e.preventDefault();
                  setBulkBtnOpen(!bulkBtnOpen);
                }}
                variant="transparent"
                icon={<img src={BulkIcon} className={`mr-[2px]`} />}
              />
            }
            content={
              <List className="py-4">
                <ListItem className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer">
                  <a
                    href={
                      organizationType == 'MANUFACTURER'
                        ? downloadDataManufacturer
                        : downloadData3PL
                    }
                    className="flex gap-2"
                  >
                    <img src={DownloadIcon} className={`mr-[2px]`} />
                    <span>Download Template</span>
                  </a>
                </ListItem>
                <BulkFileAttacher
                  bulkUpload={(vals) => validateDatesAndData(vals)}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                />
              </List>
            }
          />
        </div>
      </div>
      <Popup
        open={bulkVerification}
        setOpen={setBulkVerification}
        title={'Verify Bulk Upload'}
        dialogContentClasses={`w-[800px]`}
        onClose={resetStates}
        content={
          <div>
            <div className="mt-8">
              <div className="mt-[32px]">
                <div className="grid grid-cols-5 p-4">
                  <p className="text-normal font-bold text-[#4B5369]">
                    Movetype
                  </p>

                  <p className="text-normal font-bold text-[#4B5369]">
                    Customer/Supplier
                  </p>
                  <p className="text-normal font-bold text-[#4B5369]">
                    Product
                  </p>

                  <p className="text-normal font-bold text-[#4B5369]">
                    Quantity
                  </p>
                  <p className="text-normal font-bold text-[#4B5369]">
                    ReferenceID
                  </p>
                </div>
                <div>
                  {orders?.map((order) => {
                    return (
                      <div className="p-4 bg-[#F0F7FF] mt-4">
                        <div className="grid grid-cols-5">
                          <div>
                            <p className="text-sm text-[#4B5369]">
                              {order.moveType}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-[#4B5369]">
                              {order.company || '-'}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-[#4B5369]">
                              {order.product}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-[#4B5369]">
                              {order.quantity}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-[#4B5369]">
                              {order.referenceID}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        }
        actions={
          <div className="flex gap-2">
            <Button label="Cancel" onClick={resetStates} variant="secondary" />
            <Button
              label={'Verify'}
              onClick={async () => {
                await handleVerification();
              }}
              variant="primary"
            />
          </div>
        }
      />

      <Popup
        open={bulkUploadOpen}
        setOpen={setBulkUploadOpen}
        title={'Bulk Upload'}
        dialogContentClasses={`w-[800px]`}
        onClose={resetStates}
        content={
          <div>
            <div className="mt-8">
              {inCorrectOrders > 0 ? (
                <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
                  <div className="flex gap-2">
                    <img src={WarningIcon} />
                    <p className="text-sm">
                      {inCorrectOrders}{' '}
                      {inCorrectOrders > 1 ? 'orders are ' : 'order is '}
                      not uploaded because of formatting issues in file.
                    </p>
                  </div>
                  <div
                    onClick={async () => {
                      await extractFaultyData();
                    }}
                    className="flex gap-2 cursor-pointer"
                  >
                    <img src={downloadFileIcon} />
                    <p className="text-sm">Download File</p>
                  </div>
                </div>
              ) : null}
              <div className="mt-8">
                <div className="mt-[32px]">
                  <div className="grid grid-cols-5 p-4">
                    <p className="text-normal font-bold text-[#4B5369]">
                      Movetype
                    </p>

                    <p className="text-normal font-bold text-[#4B5369]">
                      Customer/Supplier
                    </p>
                    <p className="text-normal font-bold text-[#4B5369]">
                      Product
                    </p>

                    <p className="text-normal font-bold text-[#4B5369]">
                      Quantity
                    </p>
                    <p className="text-normal font-bold text-[#4B5369]">
                      ReferenceID
                    </p>
                  </div>
                  <div>
                    {verifiedOrders
                      ?.filter((order) => order.orderLevel == 'Correct')
                      ?.map((order) => {
                        return (
                          <div className="p-4 bg-[#F0F7FF] mt-4">
                            <div className="grid grid-cols-5">
                              <div>
                                <p className="text-sm text-[#4B5369]">
                                  {order.moveType}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-[#4B5369]">
                                  {order.company || '-'}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-[#4B5369]">
                                  {order.product}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-[#4B5369]">
                                  {order.quantity}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-[#4B5369]">
                                  {order.referenceID}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        actions={
          <div className="flex gap-2">
            <Button label="Cancel" onClick={resetStates} variant="secondary" />
            <Button
              label={'Upload Correct Orders'}
              onClick={async () => {
                await handleCreation();
              }}
              disabled={correctOrders == 0}
              variant="primary"
            />
          </div>
        }
      />
    </div>
  );
};

export default BulkUpload;

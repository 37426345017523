import { makeStyles, Tooltip } from '@material-ui/core';
import { Input, InputAdornment } from '@mui/material';
import '../assets/css/style.css';
import FilterSvg from '../assets/icons/filterIcon.svg';
import Magnify from '../assets/icons/magnify.svg';

const useStyles = makeStyles(() => ({
  searchBar: {
    color: '#0B1940',
    maxWidth: '252px',
    height: '40px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    padding: '8px 16px',
    width: '100%',
    overflow: 'hidden'
  },
  searchBarFilter: {
    color: '#0B1940',
    maxWidth: '292px',
    height: '40px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    padding: '8px 0 8px 16px',
    width: '100%',
    overflow: 'hidden'
  }
}));

export default function NewSearchBar(props) {
  const classes = useStyles();

  return (
    <Input
      type="text"
      className={
        props?.showFilter ? classes.searchBarFilter : classes.searchBar
      }
      placeholder={props.placeholder || 'Search here'}
      onChange={(e) => props.setSearchValue(e.target.value)}
      sx={{
        ':before': { borderBottomColor: '#0C71D6' },
        ':after': { borderBottomColor: '#0C71D6' }
      }}
      startAdornment={
        <InputAdornment position="start">
          <img src={Magnify} alt="search" width={20} height={20} />
        </InputAdornment>
      }
      endAdornment={
        props?.showFilter ? (
          <InputAdornment position="end">
            <Tooltip title="Filter Results">
              <img
                src={FilterSvg}
                className={
                  props?.filter
                    ? 'filterApplied cursor-pointer'
                    : 'cursor-pointer newFilterIcon'
                }
                onClick={() => props?.setFilterView()}
                alt="Filter SVG"
              />
            </Tooltip>
          </InputAdornment>
        ) : (
          ''
        )
      }
    />
  );
}

import React from 'react';
import { Tooltip } from '@material-ui/core';
import { GridToolbarContainer } from '@mui/x-data-grid';

const FilterButton = (props) => {
  return (
    <GridToolbarContainer
      {...props}
      className="toolbarContainer userToolbarContainer"
    >
      <Tooltip title="Filter">
        <button
          className={`mainFilter ${props.classes}`}
          id="search"
          label="Search"
          // eslint-disable-next-line react/no-unknown-property
          variant="outlined"
          value={props.searchKeyword}
          key={3}
          onClick={props.openFilterView}
        >
          FILTER
        </button>
      </Tooltip>
    </GridToolbarContainer>
  );
};

export default FilterButton;

import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import {
  SharedContext,
  decimalDisplay,
  productLabelFormat
} from '../../utils/common';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../Config';
import TextField from '../../core-components/atoms/TextField';
import Button from '../../core-components/atoms/Button';
import Delete from '../../assets/icons/delete.svg';
import { useEffect } from 'react';
import { useRef } from 'react';
import { checkPermission } from '../../utils/auth';
import { useContext } from 'react';
import { ORGANIZATION_TYPES } from '../../constants';
import { Card, CardContent, Grid } from '@mui/material';
import { Paragraph, Title } from '../../core-components/atoms/Text';
import { PRIMARY } from '../../constants/colors';
import UomConversion from './components/UomConversion';
import Popup from '../../core-components/atoms/Popup';

const ProductSelect = ({
  companyId,
  productIds,
  warehouseId,
  onChange,
  value,
  refetch,
  setRefetch
}) => {
  const [products, setProducts] = React.useState([]);
  const { setAPILoader, organizationType } = React.useContext(SharedContext);

  const getProducts = async (search = '') => {
    try {
      setAPILoader(true);
      const columns = ['name', 'description', 'barcode', 'perPallet'];

      const filters = {
        colVal: {
          isActive: '1'
        }
      };
      let company_id = companyId;

      if (organizationType === ORGANIZATION_TYPES.MANUFACTURER) {
        company_id = null;
      }

      const res = await API.get(`products`, {
        params: {
          search,
          companyId: company_id,
          warehouseId,
          filters,
          columns,
          excludeExtraInclusions: true
        }
      });
      setProducts(res?.data || []);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const _getProducts = React.useCallback(
    debounce((productSearchKeyword) => {
      getProducts(productSearchKeyword);
    }, DEBOUNCE_CONST),
    [companyId, warehouseId]
  );

  React.useEffect(() => {
    if (companyId || organizationType == 'MANUFACTURER') {
      getProducts();
    }
  }, [companyId, warehouseId]);

  useEffect(() => {
    if (refetch) {
      getProducts();
      setRefetch(false);
    }
  }, [refetch]);

  const options = products.filter((p) => productIds.indexOf(p.id) === -1);

  return (
    <Autocomplete
      options={options}
      onChange={onChange}
      value={value}
      placeholder="Select Product"
      isOptionEqualToValue={(option, value) =>
        option.product?.id === value.product?.id
      }
      onInputChange={(_, newValue, reason) => {
        if (
          reason === 'input' &&
          (companyId || organizationType === ORGANIZATION_TYPES.MANUFACTURER)
        ) {
          if (newValue === '-') {
            _getProducts('');
          } else {
            _getProducts(newValue);
          }
        }
      }}
      getOptionLabel={(option) => productLabelFormat(option)}
      sxProps={{
        '& input::placeholder': {
          fontSize: '14px'
        },
        '& input': {
          fontSize: '14px',
          paddingTop: '10px',
          paddingBottom: '10px',
          height: '20px'
        }
      }}
    />
  );
};

const ProductCard = ({
  availableQuantity,
  productIds,
  // currentUser,
  value,
  companyId,
  // formik,
  onChange,
  uom,
  prevOrderedQuantity,
  orderedQuantity,
  updateOrderedQuantity,
  summary,
  removeProduct,
  costPrice,
  updateCostPrice,
  sellingPrice,
  updateSellingPrice,
  tax,
  updateTaxAmount,
  totalCost,
  updateTotalCost,
  totalRevenue,
  updateTotalRevenue,
  showCostPrice,
  orderType,
  showSellingPrice,
  inventoryLoading,
  isLogistic,
  warehouseId,
  uoms,
  onUoMChange,
  primaryUomValue
}) => {
  const [uomView, setUomView] = React.useState(false);
  const [secondaryUoms, setSecondaryUoms] = React.useState(
    uoms?.filter((uom) => !uom.primary) || []
  );
  const [refetch, setRefetch] = React.useState(false);

  const { setAPILoader } = useContext(SharedContext);

  useEffect(() => {
    if (uoms?.length && secondaryUoms?.length == 0) {
      setSecondaryUoms(uoms?.filter((uom) => !uom.primary));
    }
  }, [uoms]);

  const handleUomCreation = async (uomId, uoms) => {
    setAPILoader(true);
    try {
      await API.put(`products/${value.id}`, {
        uoms,
        isActive: value?.isActive,
        batchEnabled: value?.batchEnabled
      });
      setRefetch(true);
      setUomView(false);
    } catch (e) {
      console.log(e);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={2} columnGap={4}>
          <Grid item xs={12}>
            <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
              SKU CODE & NAME
            </Title>
            {summary || orderType === 'Sales Return' ? (
              <span className="truncate w-[180px] block">
                {productLabelFormat(value)}
              </span>
            ) : (
              <ProductSelect
                productIds={productIds}
                value={value}
                companyId={companyId}
                onChange={onChange}
                disabled={inventoryLoading}
                warehouseId={warehouseId}
                refetch={refetch}
                setRefetch={setRefetch}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
              UOM
            </Title>
            <Paragraph variant="md" className={'leading-8'}>
              {summary ? (
                uom.name
              ) : (
                <Autocomplete
                  options={
                    uoms.length > 0
                      ? [
                          ...uoms,
                          ...(!summary
                            ? [{ name: '+ Create UoM', type: 'add' }]
                            : [])
                        ]
                      : []
                  }
                  onChange={(e, value) => {
                    if (value.name == '+ Create Secondary UoM') {
                      //TODO
                    } else onUoMChange(value);
                  }}
                  value={uom}
                  placeholder="UoM"
                  getOptionLabel={(option) => option.name || ''}
                  hover
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px'
                    },
                    '& input': {
                      fontSize: '14px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '20px'
                    }
                  }}
                  renderOption={(props, option) => {
                    // eslint-disable-next-line no-unused-vars
                    const { className, ...rest } = props;
                    return option?.type == 'add' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={() => {
                          setUomView(true);
                        }}
                      >
                        {option.name}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option-small">
                        <span>
                          {option.name}{' '}
                          <span className="text-[10px] ml-1">
                            {option?.primary && '(primary)'}
                          </span>
                        </span>
                      </li>
                    );
                  }}
                />
              )}
            </Paragraph>
          </Grid>
          {orderType !== 'Sales Return' ? (
            <Grid item xs={6}>
              <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
                ORDER QTY
              </Title>
              {summary ? (
                orderedQuantity
              ) : (
                <NumericFormat
                  value={orderedQuantity}
                  error={!orderedQuantity || orderedQuantity < 0}
                  customInput={TextField}
                  onValueChange={(e) => {
                    if (e.value >= 0) {
                      updateOrderedQuantity(parseFloat(e.value));
                      if (orderType === 'Purchase' && costPrice) {
                        const cost = e.value * costPrice;
                        const newTotalCost = cost + cost * ((tax || 0) / 100);
                        updateTotalCost(newTotalCost);
                      }
                      if (orderType === 'Sales' && sellingPrice) {
                        const cost = e.value * sellingPrice;
                        const newTotalRevenue =
                          cost + cost * ((tax || 0) / 100);
                        updateTotalRevenue(newTotalRevenue);
                      }
                    }
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="-"
                />
              )}
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
                  ORDER QTY
                </Title>
                <NumericFormat
                  value={orderedQuantity}
                  customInput={TextField}
                  onValueChange={(e) => {
                    if (e.value >= 0 && e.value <= prevOrderedQuantity) {
                      updateOrderedQuantity(e.value);
                      const cost = e.value * sellingPrice;
                      const newTotalRevenue = cost + cost * ((tax || 0) / 100);
                      updateTotalRevenue(newTotalRevenue);
                    } else {
                      e.value = prevOrderedQuantity;
                    }
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="-"
                />
              </Grid>
            </>
          )}

          {!isLogistic && (
            <Grid item xs={4}>
              <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
                {orderType === 'Sales Return'
                  ? 'PREVIOUS QTY'
                  : 'AVAILABLE QTY'}
              </Title>
              <Paragraph
                variant="md"
                className={`leading-8 ${
                  uom && availableQuantity === 0 && 'text-error'
                }`}
              >
                {orderType === 'Sales Return'
                  ? decimalDisplay(prevOrderedQuantity) || '-'
                  : decimalDisplay(availableQuantity) || '-'}
                {}
              </Paragraph>
            </Grid>
          )}

          {(showCostPrice || showSellingPrice) && (
            <Grid item xs={6}>
              <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
                {'PRICE'}
              </Title>
              {showCostPrice && (
                <NumericFormat
                  value={costPrice}
                  error={!costPrice || costPrice < 0}
                  disabled={orderType === 'Sales Return'}
                  customInput={TextField}
                  onValueChange={(e) => {
                    if (e.value >= 0) {
                      updateCostPrice(e.value);
                      if (orderedQuantity) {
                        const cost = orderedQuantity * e.value;
                        const newTotalCost = cost + cost * ((tax || 0) / 100);
                        updateTotalCost(newTotalCost);
                      }
                    }
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="-"
                />
              )}
              {showSellingPrice && (
                <NumericFormat
                  value={sellingPrice}
                  error={!sellingPrice || sellingPrice < 0}
                  disabled={orderType === 'Sales Return'}
                  customInput={TextField}
                  onValueChange={(e) => {
                    if (e.value >= 0) {
                      updateSellingPrice(e.value);
                      if (orderedQuantity) {
                        const cost = orderedQuantity * e.value;
                        const newTotalRevenue =
                          cost + cost * ((tax || 0) / 100);
                        updateTotalRevenue(newTotalRevenue);
                      }
                    }
                  }}
                  thousandSeparator=","
                  decimalSeparator="."
                  placeholder="-"
                />
              )}
            </Grid>
          )}

          {(showCostPrice || showSellingPrice) && (
            <>
              <Grid item xs={4}>
                <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
                  TAX %
                </Title>
                <TextField
                  type="number"
                  value={tax}
                  disabled={orderType === 'Sales Return'}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      updateTaxAmount(e.target.value);
                      if (
                        orderType === 'Purchase' &&
                        orderedQuantity &&
                        costPrice
                      ) {
                        const cost = orderedQuantity * costPrice;
                        const newTotalCost =
                          cost + cost * (e.target.value / 100);
                        updateTotalCost(newTotalCost);
                      }
                      if (
                        orderType === 'Sales' &&
                        orderedQuantity &&
                        sellingPrice
                      ) {
                        const cost = orderedQuantity * sellingPrice;
                        const newTotalRevenue =
                          cost + cost * (e.target.value / 100);
                        updateTotalRevenue(newTotalRevenue);
                      }
                    }
                  }}
                  placeholder="-"
                />
              </Grid>
            </>
          )}

          {(showCostPrice || showSellingPrice) && (
            <Grid item xs={6}>
              <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
                {orderType === 'Purchase'
                  ? 'TOTAL COST'
                  : orderType === 'Sales'
                  ? 'TOTAL REVENUE'
                  : orderType === 'Sales Return' && 'TOTAL RETURN VALUE'}
              </Title>
              {showCostPrice && (
                <Paragraph variant="sm" className={'ml-1'}>
                  {decimalDisplay(totalCost) || '-'}
                </Paragraph>
              )}
              {showSellingPrice && (
                <Paragraph variant="sm" className={'ml-1'}>
                  {decimalDisplay(totalRevenue) || '-'}
                </Paragraph>
              )}
            </Grid>
          )}
          {!summary && (
            <Grid item xs={4}>
              <Title variant={'xs'} className={'text-[#67718C] mb-2'}>
                {' '}
              </Title>
              <img
                src={Delete}
                onClick={removeProduct}
                className="cursor-pointer inline-block"
                alt="delete"
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Popup
        open={uomView}
        setOpen={setUomView}
        title={'Unit of Measure'}
        content={
          <div className="mt-4">
            <UomConversion
              primaryUom={primaryUomValue}
              secondaryUoms={secondaryUoms}
              setSecondaryUoms={setSecondaryUoms}
            />
          </div>
        }
        actions={
          <div className="flex gap-2">
            <Button
              label="Cancel"
              variant="transparent"
              onClick={() => {
                setUomView(false);
                setSecondaryUoms(uoms?.filter((uom) => !uom.primary));
              }}
            />
            <Button
              label="Save"
              variant="primary"
              onClick={async () => {
                await handleUomCreation({}, secondaryUoms);
              }}
            />
          </div>
        }
      />
    </Card>
  );
};

export default function ProductTableMobile({
  formik,
  summary,
  inventoryLoading,
  isLogistic,
  warehouseId,
  _getProducts
}) {
  let productIds = formik.values?.products?.map((p) => p?.product?.id) || [];

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [showWeightPopup, setWeightPopup] = React.useState(false);
  const { currentUser, organizationType } = useContext(SharedContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        showWeightPopup
      ) {
        setWeightPopup(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showWeightPopup]);

  const totalTax =
    formik.values?.products?.length > 0
      ? decimalDisplay(
          formik.values?.products.reduce(
            (a, b) =>
              +a +
              ((+b?.tax *
                +b?.orderedQuantity *
                (+b?.sellingPrice || +b?.costPrice)) /
                100 || 0),
            0
          )
        )
      : 0;

  return (
    <div className="w-full flex flex-col">
      {formik.values?.products?.map((row, i) => {
        let availableQuantity = row?.product?.Inventories?.reduce(
          (a, b) => a + b.availableQuantity,
          0
        );
        return (
          <ProductCard
            key={i}
            productIds={productIds}
            value={formik.values?.products?.[i]?.product || {}}
            companyId={formik.values?.company?.id}
            refetch={_getProducts}
            onChange={(e, v) => {
              formik.setFieldValue(`products.${i}.product`, {
                ...v
              });
              if (formik.values?.products?.[i]?.uom?.id) {
                formik.setFieldValue(
                  `products.${i}.uom`,
                  formik.values?.products?.[i]?.uom
                );
              } else {
                formik.setFieldValue(`products.${i}.uom`, {
                  ...v?.UOM,
                  conversionFactor: 1,
                  primary: true
                });
              }
            }}
            uom={formik.values?.products?.[i]?.uom || []}
            onUoMChange={(v) => {
              let orderedQuantity =
                formik.values?.products?.[i]?.orderedQuantity;
              if (orderedQuantity) {
                orderedQuantity =
                  orderedQuantity *
                  (formik.values?.products?.[i].uom?.conversionFactor || 1);
                orderedQuantity = orderedQuantity / v.conversionFactor;
              }
              formik.setFieldValue(`products.${i}.uom`, v);
              if (orderedQuantity) {
                formik.setFieldValue(
                  `products.${i}.orderedQuantity`,
                  orderedQuantity
                );
              }
            }}
            primaryUomValue={
              {
                ...formik.values?.products?.[i]?.product?.UOM,
                primary: true,
                conversionFactor: 1
              } || {}
            }
            uoms={
              formik.values?.products?.[i]?.product?.id
                ? [
                    {
                      ...formik.values?.products?.[i]?.product?.UOM,
                      conversionFactor: 1,
                      primary: true
                    },
                    ...(formik.values?.products?.[i]?.product?.ProductUOMs?.map(
                      (uom) => ({
                        name: uom?.UOM?.name,
                        conversionFactor: uom?.conversionFactor,
                        primary: false,
                        uomId: uom?.uomId
                      })
                    ) || [])
                  ]
                : []
            }
            availableQuantity={
              availableQuantity /
              (formik.values?.products?.[i]?.uom?.conversionFactor || 1)
            }
            inventoryLoading={inventoryLoading}
            orderedQuantity={formik.values?.products?.[i]?.orderedQuantity}
            costPrice={formik.values?.products?.[i]?.costPrice}
            sellingPrice={formik.values?.products?.[i]?.sellingPrice}
            secondaryUomId={formik.values?.products?.[i]?.secondaryUomId}
            secondaryUomQuantity={
              formik.values?.products?.[i]?.secondaryUomQuantity
            }
            tax={formik.values?.products?.[i]?.tax}
            totalCost={formik.values?.products?.[i]?.totalCost}
            totalRevenue={formik.values?.products?.[i]?.totalRevenue}
            updateOrderedQuantity={(v) => {
              formik.setFieldValue(`products.${i}.orderedQuantity`, v);
            }}
            updateCostPrice={(v) => {
              formik.setFieldValue(`products.${i}.costPrice`, v);
            }}
            updateSellingPrice={(v) => {
              formik.setFieldValue(`products.${i}.sellingPrice`, v);
            }}
            updateTaxAmount={(v) => {
              formik.setFieldValue(`products.${i}.tax`, v);
            }}
            updateTotalCost={(v) => {
              formik.setFieldValue(`products.${i}.totalCost`, v);
            }}
            updateTotalRevenue={(v) => {
              formik.setFieldValue(`products.${i}.totalRevenue`, v);
            }}
            removeProduct={() =>
              formik.setFieldValue(
                'products',
                formik.values?.products?.filter((p, id) => id != i)
              )
            }
            summary={summary}
            showCostPrice={
              formik.values?.orderType.value === 'Purchase' &&
              checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY')
            }
            showSellingPrice={
              (formik.values?.orderType.value === 'Sales' ||
                formik.values?.orderType.value === 'Sales Return') &&
              checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY')
            }
            orderType={formik.values?.orderType.value}
            prevOrderedQuantity={
              formik.values?.products?.[i]?.prevOrderedQuantity
            }
            isLogistic={isLogistic}
            warehouseId={warehouseId}
          />
        );
      })}
      <div className="bg-light border justify-between py-2 items-center px-1 rounded mt-4">
        <div>
          {!summary && formik.values?.orderType.value !== 'Sales Return' && (
            <Button
              variant="transparent"
              className={'p-2 mr-2 h-8 w-full rounded border-solid'}
              overrideSize={true}
              labelClass="font-medium text-xs"
              label="+ New Product"
              onClick={() => {
                formik.setFieldValue('products', [
                  ...(formik.values?.products || []),
                  { product: {} }
                ]);
              }}
            />
          )}
        </div>
        <div className="flex items-center mt-2">
          <div className="flex items-center h-5 w-full">
            <Paragraph variant={'xs'} className="text-primaryBlue">
              Total Qty:
            </Paragraph>
            <Title variant={'xs'} className="ml-1 text-primaryBlue">
              {formik.values?.products?.length > 0
                ? decimalDisplay(
                    formik.values?.products.reduce(
                      (a, b) => +a + (+b?.orderedQuantity || 0),
                      0
                    )
                  )
                : 0}
            </Title>
          </div>
          {(checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY') ||
            checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY')) &&
            organizationType === ORGANIZATION_TYPES.MANUFACTURER && (
              <div className="flex items-center h-5 w-full">
                <Paragraph variant={'xs'} className=" text-primaryBlue">
                  Total Tax:
                </Paragraph>
                <Title variant={'xs'} className="ml-1 text-primaryBlue">
                  {totalTax}
                </Title>
              </div>
            )}
          {(checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY') ||
            checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY')) &&
            organizationType === ORGANIZATION_TYPES.MANUFACTURER && (
              <div className="flex items-center h-5 w-full">
                <Paragraph variant={'xs'} className="text-primaryBlue">
                  Grand Total:
                </Paragraph>
                <Title variant={'xs'} className=" ml-1 text-primaryBlue">
                  {formik.values?.products?.length > 0 &&
                  formik.values?.orderType.value === 'Purchase'
                    ? decimalDisplay(
                        formik.values?.products?.reduce(
                          (a, b) => +a + (+b?.totalCost || 0),
                          0
                        )
                      )
                    : formik.values?.products?.length > 0 &&
                      (formik.values?.orderType?.value === 'Sales' ||
                        formik.values?.orderType?.value === 'Sales Return')
                    ? decimalDisplay(
                        formik.values?.products?.reduce(
                          (a, b) => +a + (+b?.totalRevenue || 0),
                          0
                        )
                      )
                    : 0}
                </Title>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

/*eslint-disable*/
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

const InventoryProductsSubTable = ({ batches, batchEnabled }) => {
  return (
    <>
      <Table
        size="small"
        className="bg-dark border-2 border-dashed border-[#0B1940] rounded-md py-3"
      >
        <TableHead>
          <TableCell className="py-2 text-xs border-none text-[#696F80]">
            Batch
          </TableCell>
          <TableCell className="py-2 text-xs border-none text-[#696F80]">
            MFG & EXP Date
          </TableCell>
          <TableCell className="py-2 text-xs border-none text-[#696F80]">
            Bin Location
          </TableCell>
          <TableCell className="py-2 text-xs border-none text-[#696F80]">
            Quantity
          </TableCell>
        </TableHead>
        <TableBody>
          {batches.map((batch, index) => {
            return (
              <TableRow key={index}>
                <TableCell className="py-2 text-xs border-none">
                  {batch.batchNumber || '-'}
                </TableCell>
                <TableCell className="py-2 text-xs border-none">
                  {(moment(batch.manufacturingDate) && batchEnabled
                    ? moment(batch.manufacturingDate).format('DD/MM/YY')
                    : '') +
                    ' - ' +
                    (moment(batch.expiryDate) && batchEnabled
                      ? moment(batch.expiryDate).format('DD/MM/YY')
                      : '')}
                </TableCell>
                <TableCell className="py-2 text-xs border-none">
                  {batch.binLocation}
                </TableCell>
                <TableCell className="py-2 text-xs border-none">
                  {batch.quantity}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default InventoryProductsSubTable;

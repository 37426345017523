/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { ChevronLeft, CloseOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';

import NodeForm from './AddNodeForm';
import { nodeTypes } from './constants';
import Button from '../../core-components/atoms/Button';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import { SharedContext } from '../../utils/common';
import { onError } from '../../libs/errorLib';
import API from '../../libs/axios';
import { toaster } from '../../utils/toaster';
import { getSuffix, nodeInitialValues } from './AddCustomer';
import Popup from '../../core-components/atoms/Popup';
import NodeSummaryView from './NodeSummaryView';
import { nodeSchema } from './validationSchemas/nodeSchema';

export const nodeValidation = async (formik) => {
  await formik.setFieldTouched('name', true);
  await formik.setFieldTouched('nodeType', true);
  await formik.setFieldTouched('address', true);
  if (formik.values.isEntity == true) {
    await formik.setFieldTouched('entity', true);
  }
  if (formik.values.nodeClass == 'INTERNAL') {
    await formik.setFieldTouched('capacity', true);
    await formik.setFieldTouched('pallets', true);
  }
  const errs = await formik.validateForm();
  return errs;
};

export const resetNodeValidation = async (formik) => {
  await formik.setFieldTouched('name', false);
  await formik.setFieldTouched('nodeType', false);
  await formik.setFieldTouched('address', false);
  if (formik.values.nodeClass == 'INTERNAL') {
    await formik.setFieldTouched('capacity', false);
    await formik.setFieldTouched('pallets', false);
  }
};

function AddNode({ isEntity, edit }) {
  const [users, setUsers] = useState([]);
  const [node, setNode] = useState({});
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [summaryViewOpen, setSummaryViewOpen] = useState(false);
  const [nodeTypeOptions, setNodeTypeOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [nodeSearch, setNodeSearch] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [deletedNodes, setDeletedNodes] = useState([]);

  const { subdomain } = useContext(SharedContext);

  const { id } = useParams();

  const { setAPILoader, organization } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: { ...nodeInitialValues, isEntity: false },
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const editFormik = useFormik({
    initialValues: {
      name: '',
      nodeType: {},
      nodeClass: 'EXTERNAL',
      adhocFlag: true,
      isActive: true,
      description: '',
      pocName: '',
      pocContact: '',
      latLng: {
        lat: '',
        lng: ''
      },
      code: '',
      capacity: null,
      pallets: null,
      companyId: null,
      entity: null
    },
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const addNode = async (data) => {
    let node = {};
    setAPILoader(true);
    try {
      if (formik.values.entity.label == 'Organization')
        await API.post(`nodes/bulk`, data);
      else
        await API.post(
          `nodes/bulk?companyId=${formik.values.entity.value}`,
          data
        );
      toaster('success', 'New Node has been created.');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }

    return node;
  };

  const submitHandler = async (values) => {
    const { savedNodes, ...rest } = values;

    let payload = [
      ...savedNodes.map((node) => ({
        ...node,
        nodeType: node.nodeType.value,
        locationLatLng: node.latLng,
        managerId: node.manager?.id
      })),
      {
        ...rest,
        nodeType: rest.nodeType.value,
        locationLatLng: rest.latLng,
        managerId: rest.manager?.id
      }
    ];

    payload = payload.filter((n, idx) => deletedNodes.indexOf(idx) == -1);

    return await addNode(payload);
  };

  const getUsers = async (value) => {
    try {
      let columns = [
        'firstName',
        'lastName',
        'username',
        'email',
        'phone',
        '$UserRoles.Role.name$'
      ];

      let colVal = {
        '$UserRoles.Role.allowedApps$': 'OPERATIONS',
        isActive: '1'
      };

      let filters = {
        colVal
      };

      const { data } = await API.get(`organizations/${subdomain}/users`, {
        params: { search: value || '', filters, columns }
      });

      setUsers(data);
    } catch (err) {
      onError(err);
    }
  };

  const getCustomers = async (value) => {
    try {
      const response = await API.get(`companies`, {
        params: { search: value, type: 'CUSTOMER' }
      });

      setCustomers(response.data.rows);
    } catch (err) {
      onError(err);
    }
  };

  const getSuppliers = async (value) => {
    try {
      const response = await API.get(`companies`, {
        params: { search: value, type: 'SUPPLIER' }
      });

      setSuppliers(response.data.rows);
    } catch (err) {
      onError(err);
    }
  };

  const getNode = async (id) => {
    try {
      const node = await API.get(`nodes/${id}`);
      setNode(node.data);
      formik.setValues({
        ...node.data,
        capacity: node.data.Warehouse?.capacity,
        pallets: node.data.Warehouse?.pallets,
        nodeType: {
          label: nodeTypes['3PL_INTERNAL'][node.data.nodeType.toUpperCase()],
          value: node.data.nodeType.toUpperCase()
        },
        latLng: node.data.locationLatLng,
        manager: node.data.Warehouse?.Manager || null,
        IMS: !!node.data.Warehouse?.IMS,
        WMS: !!node.data.Warehouse?.WMS,
        savedNodes: []
      });
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
    getCustomers();
  }, []);

  useEffect(() => {
    if (id) {
      getNode(id);
    }
  }, [id]);

  useEffect(() => {
    if (organization?.type == 'MANUFACTURER') {
      getSuppliers();
    }
  }, [organization]);

  let currentNode = {
    ...formik.values,
    nodeType: formik.values.nodeType,
    locationLatLng: formik.values.latLng
  };

  useEffect(() => {
    let options;
    if (!isEntity) {
      options = Object.keys(nodeTypes.EXTERNAL).map((key) => ({
        value: key,
        label: nodeTypes.EXTERNAL[key]
      }));
      setNodeTypeOptions(options);
    } else {
      if (formik.values.entity) {
        if (formik.values.entity.label == 'Organization') {
          if (organization.type == 'MANUFACTURER') {
            options = Object.keys(nodeTypes.MANUFACTURER_INTERNAL).map(
              (key) => ({
                value: key,
                label: nodeTypes.MANUFACTURER_INTERNAL[key]
              })
            );
            setNodeTypeOptions(options);
          } else {
            options = Object.keys(nodeTypes['3PL_INTERNAL']).map((key) => ({
              value: key,
              label: nodeTypes['3PL_INTERNAL'][key]
            }));
            setNodeTypeOptions(options);
          }
        } else {
          options = Object.keys(nodeTypes.EXTERNAL).map((key) => ({
            value: key,
            label: nodeTypes.EXTERNAL[key]
          }));
          setNodeTypeOptions(options);
        }
      }
    }
    if (isEntity) {
      formik.setFieldValue('isEntity', true);
    }
  }, [formik.values.entity, organization]);

  const updateNode = async () => {
    let payload = {
      ...formik.values,
      nodeType: formik.values.nodeType?.value,
      managerId: formik.values.manager?.id,
      locationLatLng: formik.values.latLng
    };

    setAPILoader(true);
    try {
      await API.put(`nodes/${id}`, payload);
      toaster('success', 'Node has been updated.');
      navigate('/administration/supply-chain-network');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const savedNodes = formik.values.savedNodes;

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open
      BackdropProps={{ style: { backgroundColor: 'white' } }}
      onClose={() => {}}
    >
      <div>
        <form>
          <FixedLayout
            header={
              <>
                <div className="flex justify-between w-full items-center">
                  <div>
                    <p className="font-bold text-2xl">
                      {savedNodes.length == 0
                        ? edit
                          ? 'Edit this Node'
                          : 'Add Node'
                        : `Add ${
                            savedNodes?.length > 0
                              ? savedNodes.length +
                                1 +
                                getSuffix(savedNodes.length + 1)
                              : ''
                          } node for ${formik.values.entity?.name}`}
                    </p>
                  </div>
                  <div>
                    <CloseOutlined
                      onClick={() => {
                        navigate('/administration/supply-chain-network');
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </>
            }
            content={
              <div className="m-auto w-[60%] ">
                <NodeForm
                  formik={formik}
                  nodeTypes={nodeTypeOptions}
                  users={users}
                  isEntity={isEntity && !edit}
                  entity={formik.values.entity}
                  customers={customers}
                  suppliers={suppliers}
                  savedNodes={savedNodes}
                  organization={organization}
                  search={nodeSearch}
                  setSearch={setNodeSearch}
                />
                <Popup
                  open={summaryViewOpen}
                  setOpen={setSummaryViewOpen}
                  title={
                    activeIndex || activeIndex == 0 ? (
                      <div className="flex gap-2">
                        <ChevronLeft onClick={() => setActiveIndex(null)} />
                        <span>Edit This Node</span>
                      </div>
                    ) : savedNodes.length > 0 ? (
                      'Create Nodes?'
                    ) : (
                      'Create Node?'
                    )
                  }
                  content={
                    summaryViewOpen &&
                    (activeIndex || activeIndex == 0 ? (
                      <NodeForm
                        formik={editFormik}
                        nodeTypes={nodeTypeOptions}
                        users={users}
                        isEntity={false}
                        edit={true}
                        organization={organization}
                        search={search}
                        setSearch={setSearch}
                      />
                    ) : (
                      <NodeSummaryView
                        nodes={[...savedNodes, currentNode]}
                        entity={formik.values.entity}
                        setDeletedNodes={setDeletedNodes}
                        deletedNodes={deletedNodes}
                        onEdit={(id) => {
                          setActiveIndex(id);
                          if (id == [...savedNodes, currentNode].length - 1)
                            editFormik.setValues({
                              ...formik.values,
                              entity: formik.values.entity
                            });
                          else
                            editFormik.setValues({
                              ...savedNodes[id],
                              entity: formik.values.entity
                            });
                        }}
                      />
                    ))
                  }
                  onClose={() => {
                    setSummaryViewOpen(false);
                    setActiveIndex(null);
                    setDeletedNodes([]);
                  }}
                  dialogContentClasses="w-[60vw]"
                  actions={
                    activeIndex || activeIndex == 0 ? (
                      <div className="flex gap-2">
                        <Button
                          label="Cancel"
                          onClick={() => setActiveIndex(null)}
                          variant="tertiary"
                        />
                        <Button
                          label="Update Node"
                          onClick={async () => {
                            if (
                              activeIndex ==
                              [...savedNodes, currentNode].length - 1
                            ) {
                              formik.setValues({
                                ...editFormik.values,
                                savedNodes,
                                entity: formik.values.entity
                              });
                            } else
                              formik.setFieldValue(
                                `savedNodes[${activeIndex}]`,
                                { ...editFormik.values }
                              );
                            setActiveIndex(null);
                          }}
                          variant="primary"
                          disabled={!editFormik.isValid}
                        />
                      </div>
                    ) : (
                      <div className="flex gap-2">
                        <Button
                          label="Cancel"
                          onClick={() => setSummaryViewOpen(false)}
                          variant="tertiary"
                        />
                        <Button
                          label={
                            [...savedNodes, currentNode].filter(
                              (n, idx) => deletedNodes.indexOf(idx) == -1
                            ).length == 1 ||
                            [...savedNodes, currentNode].filter(
                              (n, idx) => deletedNodes.indexOf(idx) == -1
                            ).length == 0
                              ? 'Create Node'
                              : `Create ${
                                  [...savedNodes, currentNode].filter(
                                    (n, idx) => deletedNodes.indexOf(idx) == -1
                                  ).length
                                } Nodes`
                          }
                          onClick={async () => {
                            await submitHandler(formik.values);
                            setSummaryViewOpen(false);
                            navigate('/administration/supply-chain-network');
                          }}
                          variant="primary"
                          disabled={
                            [...savedNodes, currentNode].filter(
                              (n, idx) => deletedNodes.indexOf(idx) == -1
                            ).length == 0
                          }
                        />
                      </div>
                    )
                  }
                />
              </div>
            }
            footer={
              <div className="flex justify-between w-full">
                <Button
                  label="Cancel"
                  variant="tertiary"
                  onClick={() =>
                    navigate('/administration/supply-chain-network')
                  }
                />
                {edit ? (
                  <Button
                    label={'Update Node'}
                    variant="primary"
                    disabled={!formik.isValid}
                    onClick={async () => {
                      const errs = await nodeValidation(formik);
                      if (Object.keys(errs).length == 0) {
                        setSearch('');
                        await updateNode();
                      }
                    }}
                  />
                ) : (
                  <div className="flex justify-between mr-4 gap-4">
                    <Button
                      label={'Save & Add Another Node'}
                      variant="secondary"
                      disabled={!formik.isValid}
                      onClick={async () => {
                        const errs = await nodeValidation(formik);
                        if (Object.keys(errs).length == 0) {
                          setSearch('');
                          formik.setValues({
                            ...nodeInitialValues,
                            entity: formik.values.entity,
                            nodeClass: formik.values.nodeClass,
                            savedNodes: [
                              ...formik.values.savedNodes,
                              { ...formik.values }
                            ]
                          });
                          await resetNodeValidation(formik);
                        }
                      }}
                    />
                    <Button
                      label={'Create Node'}
                      disabled={!formik.isValid}
                      variant="primary"
                      onClick={async () => {
                        const errs = await nodeValidation(formik);
                        if (Object.keys(errs).length == 0)
                          setSummaryViewOpen(true);
                      }}
                    />
                  </div>
                )}{' '}
              </div>
            }
          />
        </form>
      </div>
    </Modal>
  );
}

export default AddNode;

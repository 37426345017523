/*eslint-disable*/
import Button from '../../../core-components/atoms/Button';
import SearchBar from '../../../atomicComponents/SearchBar';
import { makeStyles } from '@material-ui/core';
import { onError } from '../../../libs/errorLib';
import API from '../../../libs/axios';
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import Filters from './Filters';
import { SharedContext } from '../../../utils/common';
import { getVehicleTypes } from '../../../apis/tms';

const useStyles = makeStyles(() => ({
  searchBar: {
    maxWidth: '188px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));
const inventryStatus = {
  DAMAGED: 'Damaged',
  HOLD: 'Hold',
  RESERVED: 'Reserved',
  NEAR_EXPIRY: 'Near Expiry',
  AVAILABLE: 'Available',
  EXPIRED: 'Expired'
};
const VehicleHead = ({
  searchKeyword,
  setSearchKeyword,
  addVehicleView,
  setAddVehicleView,
  filters,
  setFilter,
  setFilters,
  loading
}) => {
  const [carriers, setCarriers] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  const { setAPILoader } = useContext(SharedContext);

  const getCarriers = async (searchKeyword) => {
    try {
      const carriers = await API.get('companies', {
        params: { search: searchKeyword, type: 'VENDOR' }
      });
      setCarriers(carriers.data?.rows || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
    }
  };

  const _getVehicleTypes = async (search) => {
    try {
      const types = await getVehicleTypes({ searchKeyword: search });
      setVehicleTypes(types.data);
    } catch (e) {}
  };

  useEffect(() => {
    getCarriers();
    _getVehicleTypes();
  }, []);

  const filterOptions = [
    {
      label: 'Carrier',
      options: carriers.map((key, id) => ({
        value: key.id,
        label: key.name,
        id
      }))
    },
    {
      label: 'Vehicle Type',
      options: vehicleTypes.map((key, id) => ({
        value: key.id,
        label: key?.name
          ? key.name
          : key?.CarMake
          ? `${key?.CarMake?.name || key?.CarMake?.carMake} ${
              key?.CarModel?.name
            } - ${key?.Category?.name}`
          : '',
        id
      }))
    },
    {
      label: 'Status',
      options: [
        {
          value: 'active',
          label: 'Active'
        },
        {
          value: 'inactive',
          label: 'Inactive'
        },
        {
          value: 'maintenance',
          label: 'Maintenance Required'
        }
      ]
    }
  ];

  const classes = useStyles();

  return (
    <div>
      <div className="w-full flex justify-between gap-2">
        <div className="flex items-center gap-2">
          <p className="text-lg text-primaryBlue font-semibold">All Vehicles</p>
          {loading && <CircularProgress size={16} />}
        </div>
        <div className="flex items-center">
          <SearchBar
            className={classes.searchBar}
            iconClass="h-4 w-4"
            setSearchValue={setSearchKeyword}
            placeholder="Search Vehicle"
            searchKeyword={searchKeyword}
          />

          <Filters
            options={filterOptions}
            getCarriers={getCarriers}
            getVehicleTypes={_getVehicleTypes}
            filters={filters}
            setFilter={setFilter}
          />

          <Button
            label="Add Vehicle"
            className={'py-2 ml-[7px] h-8 border-0 rounded'}
            variant="primary"
            overrideSize={true}
            labelClass="font-medium text-xs"
            iconClass="h-[14px] w-[14px] mr-1 self-center mt-1"
            onClick={() => {
              setAddVehicleView(!addVehicleView);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleHead;

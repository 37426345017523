import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar/index.js';
import TopBar from './TopBar';
import LoaderOverlay from './LoaderOverlay';
import { SharedContext } from '../../utils/common';
import classNames from 'classnames/bind';
import SubTopBar from '../../views/unifiedDashboard/SubTopBar.js';
import SubTopIntBar from '../../views/intelligence/components/SubTopIntBar';
import ChatBot from '../../views/chatBot';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    zIndex: 10
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.75
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    marginTop: 44,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '16vw'
    },
    '@media only screen and (max-width: 1200px) and (min-width:993px)': {
      paddingLeft: '16vw'
    },
    '@media (min-width: 993px) and (max-width:1024px)': {
      paddingLeft: '16vw'
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  dashboardWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    marginTop: 108,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '16vw'
    },
    '@media only screen and (max-width: 1200px) and (min-width:993px)': {
      paddingLeft: '16vw'
    },
    '@media (min-width: 993px) and (max-width:1024px)': {
      paddingLeft: '16vw'
    }
  }
}));

export default function DashboardLayout() {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { isLoading } = useContext(SharedContext);
  const { sidebar } = useContext(SharedContext);
  const location = useLocation();

  let content = classNames({
    [classes.content]: true,
    customStyleContent: true,
    'no-scrollbar': true
  });

  let parentContent = classNames({
    [classes.contentContainer]: true,
    customParentContent: true
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(false)} />
        {location.pathname.split('/').pop() === 'control-tower' && (
          <SubTopBar />
        )}

        {location.pathname.split('/').pop() === 'intelligence' && (
          <SubTopIntBar />
        )}

        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={false}
        />
      </div>
      <div
        className={
          location.pathname.split('/').pop() === 'control-tower' ||
          location.pathname.split('/').pop() === 'intelligence'
            ? classes.dashboardWrapper
            : classes.wrapper
        }
        id={sidebar ? '' : 'changeMainLayout'}
      >
        <div className={parentContent}>
          <div className={content}>
            {isLoading ? <LoaderOverlay /> : ''}
            <Outlet />
          </div>
        </div>
      </div>
      {!isMobile && !location.pathname.split('/').includes('reporting') && (
        <ChatBot />
      )}
    </div>
  );
}

/*eslint-disable*/
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import delIcon from '../../../assets/icons/del.svg';
import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import { useContext } from 'react';
import { SharedContext } from '../../../utils/common';
import { checkPermission } from '../../../utils/auth';

export const CustomNumberInput = withStyles({
  root: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0
      },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  }
})(TextField);

export default function Taxes({ formik, edit }) {
  const useStyles = makeStyles(() => ({
    deleteIcon: {
      cursor: 'pointer'
    },
    addBtn: {
      textTransform: 'none',
      color: '#0C71D7',
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      marginTop: 20
    }
  }));

  const { currentUser } = useContext(SharedContext);

  const classes = useStyles();

  const disabled = !edit || !checkPermission(currentUser, 'OPS_UPDATE_TAX');

  return (
    <Grid item container className="mt-10">
      <Grid item xs={12}>
        <DetailPageTitle text="Taxes" />
      </Grid>
      <Grid item container className="mt-10">
        {formik.values.taxes?.length > 0 &&
          formik.values.taxes.map(
            (tax, id) =>
              !tax.isDeleted && (
                <Grid key={id} item container spacing={2} className="mt-5">
                  <Grid item xs={1} className="mt-5">
                    <Typography>Tax {id + 1}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={tax.name}
                      disabled={disabled}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `taxes.${id}.name`,
                          e.target.value
                        );
                      }}
                      variant="outlined"
                    />
                    {formik.errors.taxes?.[id] && (
                      <Typography color="error">
                        {formik.errors.taxes?.[id].name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <CustomNumberInput
                      fullWidth
                      value={tax.rate}
                      disabled={disabled}
                      variant="outlined"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        )
                      }}
                      inputMode="numeric"
                      onChange={(event) => {
                        if (
                          Number(event.target.value) < 0 ||
                          Number(event.target.value) > 100
                        ) {
                          event.preventDefault();
                        } else {
                          formik.setFieldValue(
                            `taxes.${id}.rate`,
                            event.target.value
                          );
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {formik.errors.taxes?.[id] && (
                      <Typography color="error">
                        {formik.errors.taxes?.[id].rate}
                      </Typography>
                    )}
                  </Grid>
                  {edit && checkPermission(currentUser, 'OPS_DELETE_TAX') && (
                    <>
                      <Grid item xs={2} className="mt-5">
                        <img
                          src={delIcon}
                          onClick={() => {
                            if (tax.isNew) {
                              let taxes = [...formik.values.taxes];
                              taxes = taxes.filter((tax, i) => i != id);
                              formik.setFieldValue('taxes', taxes);
                            } else {
                              formik.setFieldValue(`taxes.${id}`, {
                                ...formik.values.taxes[id],
                                isDeleted: true
                              });
                            }
                          }}
                          className={classes.deleteIcon}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={edit ? 4 : 6} />

                  <Grid item xs={edit ? 7 : 6}>
                    <hr className="dashedLine" />
                  </Grid>
                </Grid>
              )
          )}
      </Grid>
      {edit && checkPermission(currentUser, 'OPS_CREATE_TAX') && (
        <Button
          className={classes.addBtn}
          onClick={() => {
            formik.setFieldValue('taxes', [
              ...formik.values.taxes,
              { name: '', rate: '', isNew: true }
            ]);
          }}
        >
          + Add new Tax
        </Button>
      )}
    </Grid>
  );
}

import React from 'react';
import PrintDetail from './PrintDetail';

const PrintOrder = ({
  formik,
  orderType,
  vehicleType,
  tms,
  totalOrderedQuantity,
  totalDispatchedQuantity,
  totalReceivedQuantity
}) => {
  return (
    <PrintDetail
      formik={formik}
      orderType={orderType}
      vehicleType={vehicleType}
      tms={tms}
      totalOrderedQuantity={totalOrderedQuantity}
      totalDispatchedQuantity={totalDispatchedQuantity}
      totalReceivedQuantity={totalReceivedQuantity}
    />
  );
};

export default PrintOrder;

import { Tooltip } from '@mui/material';
import onboardingEnums from '../../../utils/enums/onboardingEnums';

export const onboardingColumns = [
  {
    field: 'firstname',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">First Name</p>
    ),
    renderCell: (params) => (
      <Tooltip title={`${params.row?.firstname}`}>
        <p>{params.row?.firstname}</p>
      </Tooltip>
    )
  },
  {
    field: 'lastname',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Last Name</p>
    ),
    renderCell: (params) => (
      <Tooltip title={`${params.row?.lastname}`}>
        <p>{params.row?.lastname}</p>
      </Tooltip>
    )
  },
  {
    field: 'company_name',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Company Name</p>
    ),
    renderCell: (params) => (
      <Tooltip title={`${params.row?.company_name}`}>
        <p>{params.row?.company_name}</p>
      </Tooltip>
    )
  },
  {
    field: 'designation',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Designation</p>
    ),
    renderCell: (params) => (
      <Tooltip title={`${params.row?.designation}`}>
        <p>{params.row?.designation}</p>
      </Tooltip>
    )
  },
  {
    field: 'email',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => <p className="w-100 parentActions detailText">Email</p>,
    renderCell: (params) => (
      <Tooltip title={`${params.row?.email}`}>
        <p>{params.row?.email}</p>
      </Tooltip>
    )
  },
  {
    field: 'phone',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => <p className="w-100 parentActions detailText">Phone</p>,
    renderCell: (params) => (
      <Tooltip title={`${params.row?.phone}`}>
        <p>{params.row?.phone}</p>
      </Tooltip>
    )
  },
  {
    field: 'status',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Status</p>
    ),
    renderCell: (params) => {
      const status =
        onboardingEnums.CUSTOMER_ONBOARDING_STATUS[params?.row?.status] || '-';
      let color = '';
      switch (params?.row?.status) {
        case 'APPROVED':
          color = '#4EAD5B';
          break;
        case 'PENDING':
          color = '#FF3B3B';
          break;
        case 'IN_PROGRESS':
          color = '#DF8244';
          break;
        default:
          color = 'black';
      }
      return (
        <Tooltip title={status}>
          <span style={{ color: color }}>{status}</span>
        </Tooltip>
      );
    }
  }
];

import { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip
} from '@material-ui/core';
import { isRequired, isChar } from '../../../utils/validators';
import ActiveCheckbox from '../../../atomicComponents/ActiveCheckbox';
import CancelButton from '../../../components/CancelButton';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  labelPadding: {
    paddingTop: 5
  },
  selectBox: {
    height: 55
  }
}));

export default function AddCountryView({
  addCountry,
  open,
  handleClose,
  selectedCountry,
  formErrors,
  clickedAlready
}) {
  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [isActive, setActive] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (selectedCountry) {
      setName(selectedCountry.name);
      setActive(!!selectedCountry.isActive);
    }
  }, [selectedCountry]);

  const resetStates = () => {
    setName('');
    setActive(false);
  };

  const handleSubmit = () => {
    const newCountry = {
      name,
      isActive
    };

    setValidation({
      name: true
    });

    if (isRequired(name)) {
      addCountry(newCountry);
      setValidation({});
      resetStates();
    }
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={() => {
            setValidation({});
            resetStates();
            handleClose();
          }}
          aria-labelledby="form-dialog-title"
          onBackdropClick={() => {
            setValidation('');
          }}
        >
          <DialogTitle disableTypography className="title">
            {!selectedCountry ? 'Add Country' : 'Edit Country'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container spacing={2} justify="space-around">
                <Grid item xs={12} lg={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="name"
                    label="Country Name *"
                    type="text"
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z ]*$/;
                      if (regex.test(e.target.value)) setName(e.target.value);
                    }}
                    onBlur={() => setValidation({ ...validation, name: true })}
                  />
                  {validation.name && !isRequired(name) ? (
                    <Typography color="error">
                      Country name is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                  {validation.name && !isChar(name) ? (
                    <Typography color="error">
                      Country name is only characters!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <ActiveCheckbox isActive={isActive} setActive={setActive} />
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <CancelButton
              setValidation={setValidation}
              resetStates={resetStates}
              handleClose={handleClose}
            />
            <Tooltip
              title={!selectedCountry ? 'Add Country' : 'Update Country'}
            >
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={clickedAlready}
              >
                {!selectedCountry ? 'Add Country' : 'Update Country'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px',
    '@media print': {
      visibility: 'hidden'
    }
  },
  container: {
    padding: 20
  },
  pagination: {
    border: 'none',
    display: 'block',
    alignItems: 'right'
  },
  generatePalletBtn: {
    height: '38px',
    minWidth: '144px',
    background: '#4B89C8',
    cursor: 'pointer',
    backgroundColor: '#4B89C8',
    borderRadius: '8px',
    border: 'none',
    padding: '10px 20px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.0125em',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '100%'
    }
  },
  qrText: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '8px'
  },
  active: {
    color: theme.palette.success.main
  },
  searchInput: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  closeButton: {
    cursor: 'pointer',
    float: 'right',
    color: 'white',
    marginTop: '14px',
    fontSize: '28px',
    padding: '4px',
    marginRight: '20px',
    backgroundColor: 'black',
    borderRadius: '50%'
  },
  addUserButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold',
    '@media (max-width:1024px)': {
      marginRight: '10px !important'
    },
    '@media only screen and (max-width: 992px) and (min-width:769px)': {
      marginRight: '0px !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      marginRight: '0px !important'
    }
  },
  backdrop: {
    '@media print': {
      visibility: 'hidden'
    }
  },
  printQr: {
    visibility: 'hidden',
    position: 'fixed',
    width: '100%',
    top: '0',
    left: '0',
    padding: '40px',
    '@media print': {
      visibility: 'visible',
      padding: '2px'
    }
  }
}));

export default useStyles;

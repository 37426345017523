import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../../../core-components/atoms/Text';
import Autocomplete from '../../../../core-components/atoms/Autocomplete';
import { toaster } from '../../../../utils/toaster';
import API from '../../../../libs/axios';
import { SharedContext } from '../../../../utils/common';
import { onError } from '../../../../libs/errorLib';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../../Config';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px #E8ECF1',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: '16px 16px',
    alignItems: ' flex-start',
    boxShadow:
      '0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.10)'
  },
  dockDialog: {
    // padding: '10px 0px 0px !important'
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  dockInputField: {
    width: '270px'
  },
  switchButton: {
    marginTop: 16
  },
  cancelBtn: {
    padding: '8px 12px',
    // gap: '6px',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#0B1940'
  },

  submitBtn: {
    padding: '8px 12px',
    background: '#0C6BD7',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:disabled': {
      background: 'rgba(12,107,215,0.5)'
    }
  }
}));

const AssignTask = ({ task, getTaskData, setEntityTypeOpen }) => {
  const classes = useStyles();
  const { setAPILoader, subdomain } = useContext(SharedContext);
  const [users, setUsers] = useState([]);
  const formik = useFormik({
    initialValues: {
      userName: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // userName: Yup.string().required(`User name is required`)
    }),
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  useEffect(() => {
    getUserCallback();
  }, []);

  const getUserCallback = useCallback(
    debounce(() => {
      getWarehouseUsers();
    }, DEBOUNCE_CONST),
    []
  );

  const getWarehouseUsers = async () => {
    setAPILoader(true);
    try {
      const response = await API.get(
        `organizations/${subdomain}/warehouses/${task?.warehouseId}/users`
      );
      setUsers(response.data);
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const submitForm = async (values) => {
    const stringName = values?.userName?.label.split(' ');
    setAPILoader(true);
    let payload = {
      warehouseTask: {
        id: task?.id,
        assignedTo: values?.userName?.value,
        warehouseId: task?.warehouseId,
        AssignedTo: {
          id: values?.userName?.value,
          firstName: stringName[0],
          lastName: stringName[1]
        }
      }
    };
    try {
      await API.put(`tasks/${task?.customId}`, payload);
      toaster('success', `Task have been successfully assigned to task ID.`);
      formik.resetForm();
      setEntityTypeOpen(null);
      getTaskData();
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const usersList = users.map((key) => ({
    label: key.name,
    value: key.id
  }));

  return (
    <div className={classes.paper}>
      <div className="mb-24">
        <Title className={'text-base'}>Assign User</Title>
      </div>
      <div className={classes.dockDialog}>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className={classes.dockInputField}
        >
          <Autocomplete
            className="mt-5"
            id="assignuser"
            options={usersList}
            onChange={async (e, value) => {
              if (value) {
                formik.setFieldValue('userName', value);
              } else {
                formik.setFieldValue('userName', '');
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values?.userName || ''}
            getOptionLabel={(type) => type?.label || ''}
            placeholder="Assigned to"
          />
          {formik.touched?.userName && formik.errors?.userName?.value && (
            <span className="text-error text-xs">
              {formik.errors?.userName?.value}
            </span>
          )}

          <div className={classes.btnGroup}>
            <button
              className={classes.cancelBtn}
              type="button"
              onClick={() => {
                setEntityTypeOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className={classes.submitBtn}
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
            >
              {`Assign User`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignTask;

import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

function StockHandlingSection({ setStockHandlingType, stockHandlingType }) {
  const [stockHandlingMechanism] = useState(['FIFO', 'LIFO', 'FEFO']);

  return (
    <Autocomplete
      id="portal"
      key={stockHandlingMechanism}
      options={stockHandlingMechanism}
      defaultValue={stockHandlingType || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Stock Handling Mechanism"
          variant="outlined"
        />
      )}
      onChange={(event, newValue) => {
        if (newValue) {
          setStockHandlingType(newValue);
        }
      }}
    />
  );
}

export default StockHandlingSection;

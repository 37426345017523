import { Close } from '@material-ui/icons';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const classes = {
  customUI: {
    width: '500px',
    padding: '28px',
    background: '#fff',
    boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
    color: 'black',
    zIndex: 1500
  },
  customButton: {
    // width: '160px',
    padding: '8px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#0C71D7',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: '4px'
  },
  customButtonCancel: {
    padding: '8px 16px',
    border: '1px solid black',
    margin: '10px',
    cursor: 'pointer',
    color: '#1d1d1d',
    background: 'white',
    fontSize: '14px',
    borderRadius: '4px'
  },
  popupHeader: {
    paddingBottom: '14px',
    fontWeight: 600,
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  popupMessage: {
    fontSize: '14px',
    fontWeight: 400,
    paddingBottom: '14px',
    lineHeight: '24px'
  }
};

export function DialogueConfirmationOpen(
  values,
  isDraft,
  submitHandler,
  type,
  dialogueText,
  btnlabel
) {
  const dialogue = dialogueText ? dialogueText : 'Are You sure?';

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div style={classes.customUI}>
          <p style={classes.popupHeader}>
            <span>Are you sure ?</span>
            <Close style={{ fontSize: '16px' }} onClick={onClose} />
          </p>
          <p style={classes.popupMessage}>
            {isDraft
              ? `Are you sure you would like to save to draft ${type}?`
              : dialogue}
          </p>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button style={classes.customButtonCancel} onClick={onClose}>
              cancel
            </button>
            <button
              style={classes.customButton}
              onClick={() => {
                submitHandler(values, isDraft);
                onClose();
              }}
            >
              {btnlabel || 'YES'}
            </button>
          </div>
        </div>
      );
    }
  });
}

import { exportToCSV } from '../utils/common';
import { onError } from '../libs/errorLib';

export const exportToExcel = (
  verifyFlag,
  selectedFile,
  verifiedList,
  header,
  type
) => {
  try {
    if (verifyFlag && selectedFile) {
      let title = `Verified ${type}`;
      let arr = [...verifiedList];
      arr.unshift(header);
      exportToCSV(null, arr, title);
    }
  } catch (error) {
    onError(error);
  }
};

export const checkValidationErr = (error, check, message) => {
  if (error?.response?.data?.error?.errors) {
    let errors = error?.response?.data?.error?.errors;
    errors?.map((data) => {
      if (data?.message?.includes(check)) {
        error.response.data.message = message;
        onError(error);
      } else {
        onError(data);
      }
    });
  } else {
    onError(error);
  }
};

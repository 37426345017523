import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OptionsIcon from '../../../../assets/icons/moreOptionsIcon.svg';
import Button from '../../../../core-components/atoms/Button';

export default function OptionsMenu({ options, closeCallback, enterCallBack }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    enterCallBack && enterCallBack();
  };
  const handleClose = () => {
    setAnchorEl(null);
    closeCallback && closeCallback();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Button
          variant="tiny-transparent"
          icon={<img src={OptionsIcon} alt="release" />}
          className={'border-0 h-2 min-w-fit'}
          iconClass={'h-4 w-4'}
          overrideSize={true}
          labelClass="p-0"
          onClick={(e) => handleClick(e)}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.12))',
            mt: 2
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={0}
      >
        {options?.map((option, i) => (
          <MenuItem
            key={i}
            className="display-block pl-4 pr-4 pt-1 pb-1 hover:bg-[#F1F8FF]"
            onClick={(e) => {
              e.stopPropagation();
              option.onClick(e);
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

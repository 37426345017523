import React, { useCallback, useEffect, useState } from 'react';
import NewSearchBar from './Conversation/components/NewSearchBar';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  IconButton,
  makeStyles,
  ThemeProvider,
  Tooltip
} from '@material-ui/core';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../Config';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@mui/material';
import Chat from './Conversation/Chat';
import EmptyConversation from '../../assets/icons/user-interface-comments.svg';
import { Paragraph, Title } from '../../core-components/atoms/Text';
import Conversation from '../multiModalShipment/comments/Conversation';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import createTheme from '@mui/material/styles/createTheme';
import { PRIMARY_BLUE } from '../../constants/colors';
import moment from 'moment/moment';
import SearchIcon from '@mui/icons-material/Search';
import CommentSearch from '../multiModalShipment/comments/CommentSearch';
import mixpanel from 'mixpanel-browser';

const theme = createTheme({
  palette: {
    inherit: {
      main: PRIMARY_BLUE
    }
  }
});

const useStyles = makeStyles(() => ({
  customHoverFocus: {
    backgroundColor: '#E4EDF6',
    borderRadius: 4,
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#E4EDF6' }
  },
  customFocus: {
    backgroundColor: '#FFF',
    borderRadius: 4,
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#E4EDF6' }
  },
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

const CommentBoard = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const [chatList, setChatList] = useState([]);
  const [chatOpen, setChatOpen] = useState(null);
  const [sorted, setSorted] = useState(false);
  const [newCon, setNewCon] = useState(false);
  const [chatSearch, setChatSearch] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState('');
  const [openExpense, setOpenExpense] = useState(false);

  useEffect(() => {
    if (chatList.length <= 0 && !sorted && !search) {
      getDelayedUserCommentsList(1, [], search, newCon);
    }
  }, [chatList, search, newCon, sorted]);

  useEffect(() => {
    mixpanel.track('All conversations', {});
  }, []);

  const getDelayedUserCommentsList = useCallback(
    debounce(
      async (page, oldData, search, includeComments) => {
        getUserCommentsList(page, oldData, search, includeComments);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getUserCommentsList = async (
    page,
    oldData = [],
    search,
    includeComments
  ) => {
    try {
      const res = await API.get(`orders/comments`, {
        params: {
          page: page,
          limit: 50,
          search: search,
          ...(includeComments ? { inverse: true } : {}),
          ...(includeComments ? { columns: ['id'] } : {})
        }
      });
      const newArr = res?.data;

      const sortedChatList = [...oldData, ...newArr].sort(
        compareByLatestCommentTime
      );
      setChatList(sortedChatList);
      setPageCount(res?.pages);
      if (search) {
        const openedChat = chatOpen?.id?.toString();
        if (!openedChat?.includes(search)) {
          setChatOpen(null);
        }
      }
    } catch (error) {
      onError(error);
    }
  };

  const sortChaList = () => {
    if (!sorted) {
      let sortedList = chatList?.filter(
        (value) => value?.UnreadComments?.length > 0
      );
      const sortedChatList = sortedList?.sort(compareByLatestCommentTime);
      setChatList(sortedChatList);
      setSorted(true);
    } else {
      setSorted(false);
      getDelayedUserCommentsList(1, [], search, newCon);
    }
  };

  useEffect(() => {
    if (chatList.length > 0 && chatOpen) {
      chatList.forEach(async (chatItem) => {
        if (chatItem?.id === chatOpen?.id) {
          if (chatItem?.UnreadComments?.length > 0) {
            const unreadIds = chatItem?.UnreadComments?.map((val) => val?.id);
            const payload = {
              commentIds: unreadIds
            };
            try {
              await API.put(`comments/seen`, payload);
              await getDelayedUserCommentsList(1, [], search, newCon);
            } catch (e) {
              console.log(e);
            }
          }
        }
      });

      chatList.forEach((chatItem) => {
        if (chatItem.id === chatOpen.id) {
          const lastComment = chatItem.lastComment;
          if (chatOpen?.lastComment?.id !== lastComment?.id) {
            // If chatOpen exists and the IDs of lastComment don't match
            // Replace chatOpen's lastComment with the lastComment from chatItem
            setChatOpen((prevChatOpen) => ({
              ...prevChatOpen,
              lastComment: lastComment
            }));
          }
        }
      });
    }
  }, [chatList, chatOpen]);

  const compareByLatestCommentTime = (a, b) => {
    // Get the timestamps of the latest comments, or use a default date if there are no comments
    const latestCommentTimeA = a?.lastComment?.updatedAt
      ? new Date(a?.lastComment?.updatedAt)
      : new Date(0);
    const latestCommentTimeB = b?.lastComment?.updatedAt
      ? new Date(b?.lastComment?.updatedAt)
      : new Date(0);

    // Sort in descending order based on comment time
    return latestCommentTimeB - latestCommentTimeA;
  };

  return (
    <div className={'border border-soltitude rounded flex flex-1 h-[771px]'}>
      <div className={'border-r w-[401px]'}>
        <div className={'flex justify-between align-items py-4 px-2'}>
          {newCon && (
            <div className={'flex align-items mr-2'}>
              <ThemeProvider theme={theme}>
                <IconButton
                  aria-label="back"
                  size="small"
                  color={'inherit'}
                  className={classes.customFocus}
                  onClick={() => {
                    setNewCon(false);
                    setChatList([]);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </ThemeProvider>
            </div>
          )}
          <NewSearchBar
            showFilter={false}
            filter={false}
            setSearchValue={(e) => {
              setPage(1);
              getDelayedUserCommentsList(1, [], e, newCon);
              setSearch(e);
            }}
            placeholder="Search for a conversation here"
          />
          {!newCon && (
            <div className={'flex align-items ml-2'}>
              <ThemeProvider theme={theme}>
                <Tooltip
                  arrow
                  title="New Conversation"
                  aria-label="add"
                  placement="top"
                  classes={classes}
                >
                  <IconButton
                    aria-label="add"
                    size="small"
                    color={'inherit'}
                    className={classes.customFocus}
                    onClick={() => {
                      setNewCon(true);
                      setChatList([]);
                      setChatOpen(null);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  title="Unread"
                  aria-label="add"
                  placement="top"
                  classes={classes}
                >
                  <IconButton
                    aria-label="filter"
                    size="small"
                    disableRipple={true}
                    disableFocusRipple={true}
                    color={'inherit'}
                    className={
                      sorted ? classes.customHoverFocus : classes.customFocus
                    }
                    onClick={() => {
                      sortChaList();
                    }}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </ThemeProvider>
            </div>
          )}
        </div>
        <div
          id={'scrollableOrderList'}
          className="items-center overflow-auto h-[701px] no-scrollbar select-none"
        >
          {chatList?.length > 0 ? (
            <InfiniteScroll
              dataLength={chatList?.length}
              next={() => {
                getUserCommentsList(page + 1, chatList, search, newCon);
                setPage(page + 1);
              }}
              hasMore={page < pageCount}
              pullDownToRefresh={false}
              loader={
                <div className={'flex flex-1 pb-4 justify-center'}>
                  <CircularProgress size={20} />
                </div>
              }
              scrollableTarget="scrollableOrderList"
            >
              {chatList?.map((chat, id) => {
                return (
                  <div
                    key={id}
                    className={
                      chat?.id === chatOpen?.id
                        ? 'bg-darkSecondary'
                        : 'bg-white'
                    }
                  >
                    <Chat
                      chat={chat}
                      newConversation={newCon}
                      search={search}
                      onClick={(selectedChat) => {
                        setChatOpen(selectedChat);
                        if (newCon) {
                          setNewCon(false);
                          setChatList([]);
                        }
                      }}
                    />
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : (
            <div className={'flex flex-1 pb-4 justify-center'}>
              <CircularProgress size={20} />
            </div>
          )}
        </div>
      </div>
      <div className={'flex-1'}>
        {chatOpen ? (
          <div className={'overflow-auto'} key={chatOpen.id}>
            <div
              className={
                'border-b h-[61px] px-8 py-4 flex justify-between items-center'
              }
            >
              <div className={'flex items-center'}>
                <Title variant={'lg'} className={'text-xl mr-2'}>
                  Conversation
                </Title>
                {chatOpen?.lastComment?.updatedAt && (
                  <>
                    <Title variant={'md'} className={'mr-2 text-[#67718C]'}>
                      •
                    </Title>
                    <Title variant={'md'} className={'text-[#67718C]'}>
                      {`Last message ${moment(
                        chatOpen?.lastComment?.updatedAt
                      )?.fromNow()}`}
                    </Title>
                  </>
                )}
              </div>
              <div>
                {chatSearch ? (
                  <CommentSearch
                    order={chatOpen}
                    selectedSearch={selectedSearch}
                    setSelectedSearch={setSelectedSearch}
                    setChatSearch={setChatSearch}
                  />
                ) : (
                  <IconButton
                    aria-label="searchChat"
                    size="small"
                    disableRipple={true}
                    disableFocusRipple={true}
                    color={'inherit'}
                    className={classes.customFocus}
                    onClick={() => {
                      setChatSearch(!chatSearch);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                )}
              </div>
            </div>
            <Conversation
              order={chatOpen}
              className={'h-[720px] max-h-[720px]'}
              inputClassName={'mb-3 mx-3'}
              customClassText={'min-w-[912px]'}
              selectedSearch={selectedSearch}
              resetOrderFileListing={() => {}}
              openExpense={openExpense}
              setOpenExpense={setOpenExpense}
              conversationUpdate={() => {
                getDelayedUserCommentsList(1, [], search, newCon);
              }}
            />
          </div>
        ) : (
          <div className={'mt-32 grid justify-items-center'}>
            <img
              alt="empty-conversation"
              className={'w-[276px]'}
              src={EmptyConversation}
            />
            <Paragraph variant={'sm'} className={'text-[#9DA3B3] text-center'}>
              Select an order from the menu in order to see and reply to that
              conversation
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentBoard;

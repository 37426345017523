import { Box, Grid, Typography } from '@material-ui/core';
import React, { useContext, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import owareLogo from '../../assets/icons/O360DarkLogo.svg';
import printLogo from '../../assets/icons/print.svg';
import DetailText from '../../atomicComponents/DetailText';
import DetailTitle from '../../atomicComponents/DetailTitle';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { SharedContext, dividerDateFormatForFilter } from '../../utils/common';
import CycleCountTableView from './cycleCountTableView';
import useStyles from './taskStyles';

const PrintTask = ({ data, isIcon }) => {
  const classes = useStyles();
  const componentRef = useRef();
  const [selectedCycleCount, setSelectedCycleCount] = useState(null);

  const { darkOrganizationLogoFile } = useContext(SharedContext);

  const getCycleCountDetails = async () => {
    try {
      const cycleCountDetail = await API.get(`tasks/${data.customId}`);
      setSelectedCycleCount(cycleCountDetail.warehouseTask);
    } catch (err) {
      onError(err);
    }
  };

  const handlePrint = async () => {
    await getCycleCountDetails();
    handlePrintTaskDetails();
  };

  const handlePrintTaskDetails = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <Box display="none" displayPrint="block" ref={componentRef}>
        <Box
          display="none"
          displayPrint="block"
          style={{ padding: '5mm 5mm 0mm 5mm' }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{
                marginTop: -13
              }}
            ></Grid>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={6}
              style={{
                marginTop: -25,
                marginBottom: 20
              }}
            >
              {darkOrganizationLogoFile ? (
                <img
                  src={darkOrganizationLogoFile.location}
                  alt="Logo"
                  style={{ width: '100px', height: 'auto', maxHeight: '100px' }}
                />
              ) : (
                <img
                  style={{ width: 'auto', height: '18px' }}
                  src={owareLogo}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: -26 }}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.pageHeading}>
                Task Parent ID: {selectedCycleCount?.id}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          display="none"
          displayPrint="block"
          style={{ padding: '5mm 5mm 0mm 5mm' }}
        >
          <Grid container spacing={2} style={{ marginTop: -20 }}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.pageHeading}>
                Cycle Count Note:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <DetailText text="Warehouse:" />
                  <DetailTitle
                    text={selectedCycleCount?.Warehouse?.businessWarehouseCode}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DetailText text="Customer:" />
                  <DetailTitle text={selectedCycleCount?.Company?.name} />
                </Grid>
                <Grid item xs={2}>
                  <DetailText text="Created By:" />
                  <DetailTitle
                    text={
                      selectedCycleCount?.CreatedBy?.firstName +
                      ' ' +
                      selectedCycleCount?.CreatedBy?.lastName
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <DetailText text="Assigned To:" />
                  <DetailTitle
                    text={
                      selectedCycleCount?.AssignedTo?.firstName +
                      ' ' +
                      selectedCycleCount?.AssignedTo?.lastName
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <DetailText text="Cycle Count Date:" />
                  <DetailTitle
                    text={dividerDateFormatForFilter(
                      selectedCycleCount?.scheduledDate
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <DetailText text="Cycle Count Estimated Qty Weight:" />
                  <DetailTitle
                    text={selectedCycleCount?.totalEstimatedWeight + ' Kgs'}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DetailText text="Cycle Count Actual Qty Weight:" />
                  <DetailTitle
                    text={selectedCycleCount?.totalActualWeight + ' Kgs'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: -12 }}></Grid>
          </Grid>
          <Grid item xs={12} className="mt-10">
            <CycleCountTableView values={selectedCycleCount} isPrint={true} />
          </Grid>
        </Box>
      </Box>
      {isIcon ? (
        <img
          src={printLogo}
          style={{ cursor: 'pointer' }}
          onClick={handlePrint}
        />
      ) : (
        <div className={classes.exportButton + ' ' + classes.floatRight}>
          <button onClick={handlePrint} className="exportCustomBtn">
            Print
          </button>
        </div>
      )}
    </>
  );
};

export default PrintTask;

import React, { useEffect, useState } from 'react';
import Button from '../../../../core-components/atoms/Button';
import Popup from '../../../../core-components/atoms/Popup';
import { TextField, ThemeProvider } from '@material-ui/core';
import { Grid, InputAdornment } from '@mui/material';
import { Search } from '@material-ui/icons';
import { createTheme } from '@material-ui/core/styles';
import { OASIS_PRIMARY_500 } from '../../../../constants/colors';
import {
  Label,
  Paragraph,
  Title
} from '../../../../core-components/atoms/Text';
import card_alert from '../../../../assets/icons/sd_card_alert.svg';
import downloadIcon from '../../../../assets/icons/download-file.svg';
import { exportToCSV } from '../../../../utils/common';

const BulkUpload = ({
  bulkUploadResponse,
  bulkUploadPopup,
  setBulkUploadPopup,
  data,
  createBulkUpload = []
}) => {
  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });
  const [search, setSearch] = useState('');
  const [aisles, setAisles] = useState([]);
  const [bays, setBays] = useState([]);
  const [bins, setBins] = useState([]);

  useEffect(() => {
    const aisleArr = data
      ?.map((item) => item.AISLE)
      ?.filter((value, index, self) => self.indexOf(value) === index);
    const bayArr = data
      ?.map((item) => item.BAY)
      ?.filter((value, index, self) => self.indexOf(value) === index);
    const binArr = data?.filter((value) => value['BIN NAME']);
    setAisles(aisleArr);
    setBays(bayArr);
    setBins(binArr);
  }, [data]);

  const downloadbulkErrorFile = () => {
    const title = `Incorrect Aisles, Bay & Bins`;
    const header = [
      'BIN NAME',
      'BIN TYPE',
      'AISLE',
      'BAY',
      'CAPACITY (SQ. FT)',
      'CAPACITY (PALLETS)',
      'CLASSIFICATION (A B C)',
      'COORDINATES (x)',
      'COORDINATES (y)',
      'COORDINATES (z)',
      'Issues'
    ];

    const exportSortedHalls = bulkUploadResponse?.incorrect?.map((value) => {
      return {
        'BIN NAME': `"${value?.hallName || ''}"`,
        'BIN TYPE': `"${value?.sectionType || ''}"`,
        AISLE: `"${value?.aisleName || ''}"`,
        BAY: `"${value?.bayName || ''}"`,
        'CAPACITY (SQ. FT)': `"${value?.capacity || ''}"`,
        'CAPACITY (PALLETS)': `"${value?.pallets || ''}"`,
        'CLASSIFICATION (A B C)': `"${value?.classifications || ''}"`,
        'COORDINATES (x)': `"${value?.coordinates?.x || ''}"`,
        'COORDINATES (y)': `"${value?.coordinates?.y || ''}"`,
        'COORDINATES (z)': `"${value?.coordinates?.z || ''}"`,
        Issues: `"${value?.issues || ''}"`
      };
    });
    exportToCSV(header, exportSortedHalls, title);
  };

  return (
    <Popup
      open={bulkUploadPopup}
      setOpen={setBulkUploadPopup}
      title={'Bulk Upload'}
      content={
        <div className="flex flex-col gap-2 h-[500px] w-[788px]">
          {bulkUploadResponse?.incorrect?.length > 0 && (
            <div className="display-flex justify-between bg-[#FFEBEB] px-4 py-3.5 border-[#D63232] border rounded mb-8 align-center">
              <div className="gap-2 display-flex flex-1 items-center">
                <img src={card_alert} alt="card-alert-icon" />
                <Paragraph variant={'md'}>
                  {bulkUploadResponse?.incorrect?.length} items are not uploaded
                  because of issues in file.
                </Paragraph>
              </div>
              <button
                className="flex mr-8 self-end flex-0.1 gap-2 items-center"
                onClick={downloadbulkErrorFile}
              >
                <img src={downloadIcon} alt="download-icon" />
                <Title variant={'md'}>Download File</Title>
              </button>
            </div>
          )}
          <div className="display-flex mb-10 justify-between items-center w-100">
            <Title variant={'md'}>{data?.length} items detected</Title>
            <div className={'w-86 mr-3'}>
              <ThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  variant="outlined"
                  color={'primary'}
                  value={search}
                  onChange={(e) => {
                    let search = e.target.value;
                    setSearch(search);
                    if (search) {
                      const aisleArr = aisles.filter((value) =>
                        value.toLowerCase().includes(search.toLowerCase())
                      );
                      const bayArr = bays.filter((value) =>
                        value.toLowerCase().includes(search.toLowerCase())
                      );
                      const binArr = bins.filter((value) =>
                        value['BIN NAME']
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      );
                      setAisles(aisleArr);
                      setBays(bayArr);
                      setBins(binArr);
                    } else {
                      const aisleArr = data
                        ?.map((item) => item.AISLE)
                        ?.filter(
                          (value, index, self) => self.indexOf(value) === index
                        );
                      const bayArr = data
                        ?.map((item) => item.BAY)
                        ?.filter(
                          (value, index, self) => self.indexOf(value) === index
                        );
                      const binArr = data;
                      setAisles(aisleArr);
                      setBays(bayArr);
                      setBins(binArr);
                    }
                  }}
                  size="small"
                  inputProps={{
                    onFocus: () => search
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <Search
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px'
                          }}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </ThemeProvider>
            </div>
          </div>
          <div>
            {aisles?.length > 0 && (
              <div>
                <div className="display-flex items-center mb-3">
                  <Label variant={'sm'} className={'mr-3'}>
                    AISLES
                  </Label>
                  <Paragraph variant={'sm'}>{aisles?.length}</Paragraph>
                </div>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="mx-1 mb-10"
                >
                  {aisles.map((value, index) => (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                      className="flex items-center justify-between bg-oasisPrimary100 px-4 py-4 bd-4 my-2 mr-4"
                    >
                      <div className={'flex items-center gap-4'}>
                        <div
                          className={`h-3 w-3 rounded-full border-[#1AAE2E] bg-[#1AAE2E]`}
                        />
                        <Paragraph
                          className={`text-sm ${search && 'bg-[#FFF0C3]'}`}
                        >
                          {value}
                        </Paragraph>
                      </div>{' '}
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}

            {bays?.length > 0 && (
              <div>
                <div className="display-flex items-center mb-3">
                  <Label variant={'sm'} className={'mr-3'}>
                    BAYS
                  </Label>
                  <Paragraph variant={'sm'}>{bays?.length}</Paragraph>
                </div>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="mx-1 mb-10"
                >
                  {bays.map((value, index) => (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                      className="flex items-center justify-between bg-oasisPrimary100 px-4 py-4 bd-4 my-2 mr-4"
                    >
                      <div className={'flex items-center gap-4'}>
                        <div
                          className={`h-3 w-3 rounded-full border-[#1AAE2E] bg-[#1AAE2E]`}
                        />
                        <Paragraph
                          className={`text-sm ${search && 'bg-[#FFF0C3]'}`}
                        >
                          {value}
                        </Paragraph>
                      </div>{' '}
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}

            {bins?.length > 0 && (
              <div>
                <div className="display-flex items-center mb-3">
                  <Label variant={'sm'} className={'mr-3'}>
                    BINS
                  </Label>
                  <Paragraph variant={'sm'}>{bins?.length}</Paragraph>
                </div>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className="mx-1 mb-10"
                >
                  {bins.map((value, index) => (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={4}
                      key={index}
                      className="flex items-center justify-between bg-oasisPrimary100 px-4 py-4 bd-4 my-2 mr-4"
                    >
                      <div className="flex gap-4">
                        <div
                          className={`h-3 w-3 mt-2 rounded-full border-[#1AAE2E] bg-[#1AAE2E]`}
                        />
                        <div>
                          <div className="flex items-center">
                            <Paragraph
                              className={`text-sm ${search && 'bg-[#FFF0C3]'}`}
                            >
                              {value['BIN NAME']}
                            </Paragraph>
                          </div>
                          <div className="flex items-center mt-1">
                            <Paragraph className="text-xs text-[#696F80]">
                              {value['BIN TYPE']}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </div>
        </div>
      }
      onClose={() => {
        setBulkUploadPopup(false);
      }}
      actions={
        <div className="flex justify-end">
          <Button
            label="Cancel"
            variant="transparent"
            onClick={() => {
              setBulkUploadPopup(false);
            }}
          />
          <Button
            className="ml-4"
            label="Create All"
            variant="primary"
            disabled={data?.length === 0}
            onClick={createBulkUpload}
          />
        </div>
      }
    />
  );
};

export default BulkUpload;

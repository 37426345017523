import React from 'react';
import { FormControl, TextField, Typography } from '@material-ui/core';

const AddUsersForm = (props) => {
  const {
    setFieldValue,
    setUsers,
    setUserEmails,
    userEmails,
    validation: { errors, touched },
    handleBlur
  } = props;

  let fieldError = errors?.find((val) => val?.userEmail)?.userEmail;

  return (
    <FormControl fullWidth={true} variant="outlined">
      <TextField
        multiline
        fullWidth={true}
        margin="dense"
        rows={6}
        id="config"
        label="Users"
        type="text"
        variant="outlined"
        InputProps={{ inputProps: { maxLength: 1000 } }}
        // defaultValue={defaultUser ?? ''}
        value={userEmails}
        onBlur={handleBlur}
        onChange={(event) => {
          if (event.currentTarget.value) {
            let usersArray = event.currentTarget.value
              // eslint-disable-next-line no-useless-escape
              .replace(/[`~!#$%^&*()_|+\-=?;:'"<>\r\n\{\}\[\]\\\/]/gm, '')
              .split(',')
              .map((data) => {
                return {
                  userEmail: data.trim()
                };
              });
            setFieldValue('config', JSON.stringify(usersArray));
            setUsers(usersArray);
          }
          setUserEmails(event.target.value);
        }}
      />
      <Typography
        style={{
          color: '#1d1d1d',
          fontSize: 12
        }}
      >
        Max Length (1000 characters)
      </Typography>
      {fieldError && touched ? (
        <div>
          <Typography color="error">{fieldError}</Typography>
        </div>
      ) : null}
    </FormControl>
  );
};

export default AddUsersForm;

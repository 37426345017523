import React from 'react';
import {
  ShipmentFilterType,
  ShipmentStatus,
  NodeType as NodeCategory
} from '../constants';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const ShipmentFilters = ({
  statuses,
  setStatusFilter,
  setShipmentsModal,
  setSelectedNode,
  startDate,
  endDate,
  nodeType
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return (
    <div className="relative ctower-bottom-position w-fit">
      <div className="block pt-4 pl-4 w-fit">
        <p className="text-primaryBlue text-sm mb-2">Shipment Statuses</p>
        {Object.keys(statuses)
          .filter((value) =>
            isFeatureEnabled(FLAGS) ||
            isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
              ? value === 'AWAITING_CARRIER' ||
                value === 'CARRIER_ASSIGNED' ||
                value === 'IN_TRANSIT' ||
                value === 'CANCELLED' ||
                value === 'RECEIVED' ||
                value === 'DELIVERED'
              : value === 'CREATED' ||
                value === 'CANCELLED' ||
                value === 'RECEIVED' ||
                value === 'DELIVERED' ||
                value === 'DOCK_ASSIGNED'
          )
          .map((key, index) => (
            <div
              key={index}
              className="w-fit bg-white border border-solid border-primaryBlue shadow-[0_1px_3px_0px_rgba(0,0,0,0.20)] rounded h-16 px-3 py-2 mb-2 flex flex-col justify-center hover:bg-tertiary cursor-pointer"
              onMouseEnter={() => {
                setStatusFilter(key);
              }}
              onMouseLeave={() => {
                setStatusFilter(ShipmentStatus.ALL);
              }}
              onClick={() => {
                setSelectedNode({
                  from: startDate,
                  to: endDate,
                  name: ShipmentStatus[key],
                  status: key,
                  filterType: ShipmentFilterType.NODE_TYPE,
                  nodeType: NodeCategory[nodeType],
                  page: 1,
                  active: true
                });
                setShipmentsModal(true);
              }}
            >
              <p className="text-primaryBlue text-2xl font-semibold">
                {statuses[key]}
              </p>
              <p className="text-indigo text-sm">{ShipmentStatus[key]}</p>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ShipmentFilters;

export default Object.freeze([
  {
    id: 'all',
    name: 'Clear'
  },
  {
    id: 7,
    name: '7 days'
  },
  {
    id: 14,
    name: '14 days'
  },
  {
    id: 30,
    name: '30 days'
  },
  {
    id: 60,
    name: '60 days'
  },
  {
    id: 'cus',
    name: 'Custom'
  }
]);

import { Tooltip } from '@material-ui/core';

import { dateFormatSlashDivider } from '../../../utils/common';
const orderPickingColumns = [
  {
    field: 'id',
    width: 150,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return <p className="w-100 parentActions routeheader">Order Number</p>;
    },
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={`${params?.row?.id}`}>
            <span className="table-cell-trucate">{params?.row?.id}</span>
          </Tooltip>
        </>
      );
    }
  },
  {
    field: 'dateOfOrder',
    flex: 1,
    disableColumnMenu: true,
    headerClassName: 'justify-content-center datatableCenter',
    cellClassName: 'justify-content-center datatableCenter',
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader text-center">Date</p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={`${dateFormatSlashDivider(params?.row?.dropoffDate)}`}>
        <span className="table-cell-trucate text-center">
          {dateFormatSlashDivider(params?.row?.dropoffDate)}
        </span>
      </Tooltip>
    )
  },
  {
    field: 'referenceID',
    width: 250,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => {
      return (
        <p className="w-100 parentActions routeheader">
          Supporting Document ID
        </p>
      );
    },
    renderCell: (params) => (
      <Tooltip title={params?.row?.referenceId}>
        <span className="table-cell-trucate">{params?.row?.referenceId}</span>
      </Tooltip>
    )
  }
];

export default orderPickingColumns;

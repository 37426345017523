import { Tooltip } from '@mui/material';
import DetailTitle from '../../../../atomicComponents/DetailTitle';
import { dividerDateFormatForFilter } from '../../../../utils/common';

const columns = [
  {
    field: 'startEndDate',
    headerName: 'Start & End Date',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => <DetailTitle text="Start & End Date" />,
    renderCell: (params) => {
      const startDate = dividerDateFormatForFilter(
        params.row?.configurations?.contractDate?.startDate
      );
      const endDate = dividerDateFormatForFilter(
        params.row?.configurations?.contractDate?.endDate
      );
      return (
        <Tooltip title={`${startDate} - ${endDate}`}>
          <span>{`${startDate} - ${endDate}`}</span>
        </Tooltip>
      );
    }
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 0.5,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => <DetailTitle text="City" />,
    renderCell: (params) => (
      <Tooltip title={`${params.row?.City?.name}`}>
        <span>{params.row?.City?.name}</span>
      </Tooltip>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.5,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => <DetailTitle text="Status" />,
    renderCell: (params) => (
      <Tooltip title={`${params.row?.isActive ? 'Active' : 'Inactive'}`}>
        <span className={params?.row?.isActive ? 'active' : 'inactive'}>
          {params.row?.isActive ? 'Active' : 'Inactive'}
        </span>
      </Tooltip>
    )
  }
];

export default columns;

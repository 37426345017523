import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef
} from 'react';
import { debounce } from 'lodash';

import onexusMakeStyles from '../onexusMakeStyles';
import API from '../../../libs/axios';
import { DEBOUNCE_CONST } from '../../../Config';
import { onError } from '../../../libs/errorLib';
import SearchBar from '../../../atomicComponents/SearchBar';
import ListingItem from './ListingItem';
import MultipleMarkerMap from '../../../components/MultipleMarkerMap';
import { SharedContext } from '../../../utils/common';
import markerIcon from '../../../assets/icons/onexusDetailMarker.svg';

const OnexusListing = () => {
  const classes = onexusMakeStyles();
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseMarkers, setWarehouseMarkers] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [page, setPage] = useState(1);
  const [scroll, setScroll] = useState(false);
  const recordLimit = 10;

  const listInnerRef = useRef();

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);

  useEffect(() => {
    let title = 'O-Nexus';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    getWarehouses(page, searchVal, scroll);
  }, [searchVal, page]);

  const getWarehouses = useCallback(
    debounce(async (page, search, scroll) => {
      try {
        let columns = [
          '$City.name$',
          'address',
          'name',
          'businessWarehouseCode'
        ];
        const filters = {
          colVal: {
            isActive: 1
          }
        };
        const res = await API.get('warehouses', {
          params: {
            page: page,
            search: search,
            limit: recordLimit,
            filters,
            columns
          }
        });
        const markers = res?.data?.map((warehouse) => {
          return {
            id: warehouse.id,
            name: warehouse.name,
            latLng: warehouse.locationLatlng,
            markerTitle: `Warehouse: ${warehouse.name} \nAddress: ${warehouse.address}`
          };
        });

        if (scroll) {
          setWarehouses((prev) => [...(prev || []), ...(res?.data || [])]);
          setWarehouseMarkers((prev) => [...(prev || []), ...(markers || [])]);
        } else {
          setWarehouses(res?.data);
          setWarehouseMarkers(markers);
        }

        setScroll(false);
      } catch (error) {
        onError(error);
      }
    }, DEBOUNCE_CONST),
    []
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        Math.ceil(scrollTop) + Math.ceil(clientHeight) ===
        Math.ceil(scrollHeight)
      ) {
        setScroll(true);
        setPage((prev) => ++prev);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.listing} onScroll={onScroll} ref={listInnerRef}>
        <div>
          <SearchBar
            width={classes.searchBar}
            setSearchValue={(e) => {
              setPage(1);
              setSearchVal(e);
            }}
          />
        </div>
        {warehouses?.length
          ? warehouses.map((warehouse) => (
              <ListingItem key={warehouse.id} warehouse={warehouse} />
            ))
          : null}
      </div>
      <div className={classes.map}>
        {warehouseMarkers?.length ? (
          <MultipleMarkerMap markers={warehouseMarkers} icon={markerIcon} />
        ) : null}
      </div>
    </div>
  );
};

export default OnexusListing;

import React, { useEffect, useState, useContext } from 'react';
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid } from '@mui/x-data-grid';
import { Pagination } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';

import {
  SharedContext,
  dividerDateFormatForFilter,
  dividerDateFormatForYear
} from '../../../utils/common';
import { onError } from '../../../libs/errorLib';
import AddBtn from '../../../atomicComponents/AddBtn';
import API from '../../../libs/axios';
import EyeIcon from '../../../assets/icons/eye.svg';
import { checkPermission } from '../../../utils/auth';
import useStyles from './palletizeStyles';
import CustomFilter from '../../../components/CustomFilter';
import SearchBar from '../../../atomicComponents/SearchBar';

export default function PalletizeStock() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterView, setFilterView] = useState(false);
  const [filter, setFilter] = useState(false);
  const [palletizeStock, setPalletizeStock] = useState([]);
  const [palletIdVal, setPalletIdVal] = useState('');
  const [orderIdVal, setOrderIdVal] = useState('');
  const [companyVal, setCompanyVal] = useState('');
  const [productVal, setProductVal] = useState('');
  const [batchVal, setBatchVal] = useState('');
  const [expVal, setExpVal] = useState('');
  const [sectionVal, setSectionVal] = useState('');
  const [qtyVal, setQtyVal] = useState('');
  const { currentPageTitle, setCurrentPageTitle, currentUser, setAPILoader } =
    useContext(SharedContext);

  const isPhone = useMediaQuery('(max-width:915px)');

  const PalletizeColumns = [
    {
      field: 'palletId',
      headerName: 'Pallet ID',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.Pallet?.palletId}`}>
          <span>{params?.row?.Pallet?.palletId}</span>
        </Tooltip>
      )
    },
    {
      field: 'orderId',
      headerName: 'Order ID',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderHeader: () => {
        return <p className="w-100 parentActions">Order ID</p>;
      },
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.orderId}`}>
          <span>{params?.row?.orderId}</span>
        </Tooltip>
      )
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderHeader: () => {
        return <p className="w-100 parentActions">Company</p>;
      },
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.customer?.name}`}>
          <span>{params?.row?.customer?.name}</span>
        </Tooltip>
      )
    },
    {
      field: 'product',
      headerName: 'SKU Code-Description',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderHeader: () => {
        return <p className="w-100 parentActions">SKU Code-Description</p>;
      },
      renderCell: (params) => (
        <Tooltip
          title={`${params?.row?.InventoryDetail?.Inventory?.Product?.name} - ${params?.row?.InventoryDetail?.Inventory?.Product?.description}`}
        >
          <span>{`${params?.row?.InventoryDetail?.Inventory?.Product?.name} - ${params?.row?.InventoryDetail?.Inventory?.Product?.description}`}</span>
        </Tooltip>
      )
    },
    {
      field: 'batch',
      headerName: 'Batch No.',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderHeader: () => {
        return <p className="w-100 parentActions">Batch No.</p>;
      },
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.InventoryDetail?.batchNumber}`}>
          <span>{params?.row?.InventoryDetail?.batchNumber}</span>
        </Tooltip>
      )
    },
    {
      field: 'expDate',
      headerName: 'Exp. Date',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderHeader: () => {
        return <p className="w-100 parentActions">Exp. Date</p>;
      },
      renderCell: (params) => (
        <Tooltip
          title={`${dividerDateFormatForFilter(
            params?.row?.InventoryDetail?.expiryDate
          )}`}
        >
          <span>
            {dividerDateFormatForFilter(
              params?.row?.InventoryDetail?.expiryDate
            )}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'section',
      headerName: 'Section',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderHeader: () => {
        return <p className="w-100 parentActions">Section</p>;
      },
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.WarehouseHall?.name}`}>
          <span>{params?.row?.WarehouseHall?.name}</span>
        </Tooltip>
      )
    },
    {
      field: 'Qty',
      headerName: 'Qty',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      disableColumnMenu: true,
      renderHeader: () => {
        return <p className="w-100 parentActions">Qty</p>;
      },
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.quantity}`}>
          <span>{params?.row?.quantity}</span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      width: 100,
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_STOCK_PALLETIZATION_READ') ? (
            <Tooltip title="Detail">
              <img
                src={EyeIcon}
                alt="Visibility icon"
                className="cursor-pointer"
                onClick={() => {
                  navigate(`/operations/palletizer/${params?.row?.id}`);
                }}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const getPalletizeStockData = async (search, clearFilter) => {
    try {
      setAPILoader(true);
      closeFilter();
      const colVal = getFilters(clearFilter);
      const filters = { colVal };
      const columns = [
        '$Pallet.palletId$',
        'orderId',
        '$customer.name$',
        '$InventoryDetail.Inventory.Product.name$',
        '$InventoryDetail.batchNumber$',
        '$InventoryDetail.expiryDate$',
        '$WarehouseHall.name$'
      ];
      activeFilterView();
      const stocks = await API.get(`pallets/getPalletStock`, {
        params: { filters, columns, page, search }
      });
      setPageCount(stocks.pages);
      setPalletizeStock(stocks.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getFilters = (clearFilter) => {
    let colVal = {};
    if ((!clearFilter, palletIdVal)) {
      colVal['$Pallet.palletId$'] = palletIdVal;
    }
    if ((!clearFilter, orderIdVal)) {
      colVal.orderId = orderIdVal;
    }
    if ((!clearFilter, companyVal)) {
      colVal['$customer.name$'] = companyVal;
    }
    if ((!clearFilter, productVal)) {
      colVal['$InventoryDetail.Inventory.Product.name$'] = productVal;
    }
    if ((!clearFilter, batchVal)) {
      colVal['$InventoryDetail.batchNumber$'] = batchVal;
    }
    if ((!clearFilter, expVal)) {
      colVal['$InventoryDetail.expiryDate$'] = dividerDateFormatForYear(expVal);
    }
    if ((!clearFilter, sectionVal)) {
      colVal['$WarehouseHall.name$'] = sectionVal;
    }
    if ((!clearFilter, qtyVal)) {
      colVal.quantity = qtyVal;
    }
    if (
      !clearFilter &&
      (palletIdVal ||
        orderIdVal ||
        companyVal ||
        productVal ||
        batchVal ||
        expVal ||
        sectionVal ||
        qtyVal)
    ) {
      setFilter(true);
    } else {
      setFilter(false);
    }
    return colVal;
  };

  const activeFilterView = () => {
    if (
      palletIdVal ||
      orderIdVal ||
      companyVal ||
      productVal ||
      batchVal ||
      expVal ||
      sectionVal ||
      qtyVal
    ) {
      setFilter(true);
    } else {
      setFilter(false);
    }
  };

  const clearFilter = () => {
    setPalletIdVal('');
    setOrderIdVal('');
    setCompanyVal('');
    setProductVal('');
    setBatchVal('');
    setExpVal('');
    setSectionVal('');
    setQtyVal('');
    getPalletizeStockData(searchValue, true);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const palletId = {
    type: 'text',
    className: 'textField',
    label: 'Pallet ID',
    field: 'palletId',
    value: palletIdVal || '',
    setNameVal: (e) => setNameVal(setPalletIdVal, e)
  };

  const orderId = {
    type: 'text',
    className: 'textField',
    label: 'Order ID',
    field: 'orderId',
    value: orderIdVal || '',
    setNameVal: (e) => setNameVal(setOrderIdVal, e)
  };

  const company = {
    type: 'text',
    className: 'textField',
    label: 'Company',
    field: 'company',
    value: companyVal || '',
    setNameVal: (e) => setNameVal(setCompanyVal, e)
  };

  const product = {
    type: 'text',
    className: 'textField',
    label: 'SKU Code-Description',
    field: 'product',
    value: productVal || '',
    setNameVal: (e) => setNameVal(setProductVal, e)
  };

  const batch = {
    type: 'text',
    className: 'textField',
    label: 'Batch No.',
    field: 'batch',
    value: batchVal || '',
    setNameVal: (e) => setNameVal(setBatchVal, e)
  };

  const exp = {
    type: 'datePicker',
    className: 'textField',
    label: 'Exp. Date',
    field: 'creationDate',
    value: expVal || '',
    setNameVal: (e) => setNameVal(setExpVal, e)
  };

  const section = {
    type: 'text',
    className: 'textField',
    label: 'Section',
    field: 'section',
    value: sectionVal || '',
    setNameVal: (e) => setNameVal(setSectionVal, e)
  };

  const qty = {
    type: 'text',
    className: 'textField',
    label: 'Qty',
    field: 'qty',
    value: qtyVal || '',
    setNameVal: (e) => setNameVal(setQtyVal, e)
  };

  const filterCols = [
    palletId,
    orderId,
    company,
    product,
    batch,
    exp,
    section,
    qty
  ];

  const filterModal = (
    <div className={filterView ? 'customFilterViewSec' : 'display-none'}>
      <CustomFilter
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        clearFilter={clearFilter}
        applyFilter={getPalletizeStockData}
        handleClose={() => closeFilter()}
        handleOpen={() => openFilterView()}
        columns={filterCols}
        className="headerFilters"
      />
    </div>
  );

  useEffect(() => {
    let title = 'Palletize Stock';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    getPalletizeStockData(searchValue, false);
  }, [searchValue, page]);

  return (
    <Paper className={classes.root}>
      <Grid className={classes.taskManagementContainer}>
        <TableContainer className={classes.container}>
          <Grid className="mb-10 mt-3" item container>
            <Grid item xs={12} sm={6} lg={6} md={6} className="orderFilter">
              <SearchBar
                setSearchValue={(e) => {
                  setPage(1);
                  setSearchValue(e);
                }}
                filter={filter}
                setFilterView={() => openFilterView()}
                className="customPlletizeSearch"
              />
              <div className="position-relative">{filterModal}</div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="text-right"
              id="task-management-button"
            >
              {checkPermission(
                currentUser,
                'OPS_STOCK_PALLETIZATION_CREATE'
              ) ? (
                <AddBtn
                  iconClass="icon-align-center"
                  text="PALLETIZE"
                  url="/operations/palletize-stock"
                />
              ) : null}
            </Grid>
          </Grid>
          <Table aria-label="sticky table">
            <TableBody>
              <div className={classes.dataGrid}>
                <DataGrid
                  rows={palletizeStock}
                  hiddenColumnModel={['id']}
                  columns={PalletizeColumns}
                  pageSize={20}
                  rowsPerPageOptions={[6]}
                  className="parentDataTable"
                />
              </div>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent="space-between">
          <Grid />
          <Grid item className={classes.pagination}>
            <Pagination
              component="div"
              shape="rounded"
              count={pageCount}
              color="primary"
              page={page}
              onChange={(e, page) => {
                setPage(page);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem
} from '@mui/material';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import TableSkelton from '../components/TableSkelton';
import EmptyChart from '../components/EmptyChart';
import {
  decimalDisplay,
  getCurrencySymbol,
  SharedContext
} from '../../../utils/common';
import {
  ALICE_BLUE,
  OASIS_PRIMARY_500,
  PRIMARY_BLUE
} from '../../../constants/colors';

const CarrierPerformance = ({ nodeIds, endDate, startDate }) => {
  const { organization } = useContext(SharedContext);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);
  const [mode, setMode] = useState('LAND');

  useEffect(() => {
    setIsLoading(true);
    getDelayedCarrierPerformance(startDate, endDate, nodeIdsMemo, mode);
  }, [startDate, endDate, nodeIdsMemo, mode]);

  const getDelayedCarrierPerformance = useCallback(
    debounce(
      async (startDate, endDate, nodeIds, mode) => {
        getCarrierPerformance(startDate, endDate, nodeIds, mode);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getCarrierPerformance = async (startDate, endDate, nodeIds, mode) => {
    try {
      const data = await API.get(`intelligence/carrier-performance`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          nodeIds: nodeIds,
          mode: mode
        }
      });
      setProducts(data);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    setMode(event.target.value);
  };

  return (
    <div className="p-4">
      <div className={'flex justify-between items-center'}>
        <Title variant={'md'}>Carrier Performance</Title>
        <Select
          value={mode}
          onChange={handleChange}
          sx={{
            '&& .MuiSelect-select': {
              paddingTop: '2px',
              paddingBottom: '2px',
              fontSize: '12px',
              color: PRIMARY_BLUE
            }
          }}
          MenuProps={{
            sx: {
              '.MuiPaper-root': {
                minWidth: 200,
                maxHeight: 300
              },
              '.MuiMenu-list': {
                display: 'flex',
                flexDirection: 'column',
                padding: 0
              },
              '.MuiMenuItem-root': {
                justifyContent: 'flex-start',
                padding: '8px',
                fontSize: '14px',
                color: PRIMARY_BLUE
              }
            }
          }}
          className="bg-white focus:bg-oasisPrimary100"
        >
          <MenuItem
            value={'LAND'}
            sx={{
              '&.Mui-selected': {
                justifyContent: 'flex-start',
                padding: '8px',
                fontSize: '14px',
                color: OASIS_PRIMARY_500,
                backgroundColor: ALICE_BLUE,
                fontWeight: 'bold'
              }
            }}
          >
            Land
          </MenuItem>
          <MenuItem
            value={'SEA'}
            sx={{
              '&.Mui-selected': {
                justifyContent: 'flex-start',
                padding: '8px',
                fontSize: '14px',
                color: OASIS_PRIMARY_500,
                backgroundColor: ALICE_BLUE,
                fontWeight: 'bold'
              }
            }}
          >
            SEA
          </MenuItem>
          <MenuItem
            value={'AIR'}
            sx={{
              '&.Mui-selected': {
                justifyContent: 'flex-start',
                padding: '8px',
                fontSize: '14px',
                color: OASIS_PRIMARY_500,
                backgroundColor: ALICE_BLUE,
                fontWeight: 'bold'
              }
            }}
          >
            AIR
          </MenuItem>
        </Select>
      </div>
      {isLoading ? (
        <TableSkelton />
      ) : (
        <TableContainer
          component={Paper}
          className="border border-dark300 rounded overflow-hidden mt-4"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="border-b border-dark300 m-0">
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    Carrier
                  </Label>
                </TableCell>
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                  align="left"
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    On Time Delivery
                  </Label>
                </TableCell>
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                  align="left"
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    Landed Cost / unit
                  </Label>
                </TableCell>
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                  align="left"
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    Shipping Cost
                  </Label>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length <= 0 ? (
                <TableRow>
                  <TableCell colSpan={4} className="text-center p-0">
                    <div className="flex justify-center items-center w-full h-full">
                      <EmptyChart />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                products.map((row) => (
                  <TableRow
                    key={row.id}
                    className="border-b border-dark300"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      scope="row"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {row?.[`ShipmentLegs.Company.name`]}
                      </Paragraph>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {decimalDisplay(row?.onTimeDeliveryCount)}
                      </Paragraph>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {`${getCurrencySymbol(organization?.defaultCurrency)}
                          ${decimalDisplay(
                            row?.[`ShipmentLegs.costPerRound`]
                          )}`}
                      </Paragraph>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {`${getCurrencySymbol(
                          organization?.defaultCurrency
                        )} ${decimalDisplay(row?.totalCost)}`}
                      </Paragraph>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CarrierPerformance;

export default Object.freeze([
  {
    value: 'PRIMARY',
    label: 'Primary'
  },
  {
    value: 'SECONDARY',
    label: 'Secondary'
  }
]);

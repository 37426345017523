/*eslint-disable*/
import API from '../../../libs/axios';

export const getRelocations = async (page, search, columns, filters) => {
  return API.get(`warehouses/relocations`, {
    params: {
      page,
      search,
      columns,
      productIds: filters?.productIds,
      warehouseIds: filters?.warehouseIds
    }
  });
};

export const getProducts = async (search = '') => {
  const columns = ['name', 'description', 'barcode', 'perPallet', 'id'];

  const filters = {
    colVal: {
      isActive: '1'
    }
  };

  return API.get(`products`, {
    params: {
      search,
      excludeExtraInclusions: true,
      columns,
      filters
    }
  });
};

import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import {
  FormHelperText,
  Popper,
  TextField,
  ThemeProvider
} from '@material-ui/core';
import { Title } from '../../../../core-components/atoms/Text';
import { OASIS_PRIMARY_500 } from '../../../../constants/colors';
import Switch from '../../../../core-components/atoms/Switch';
import { SharedContext } from '../../../../utils/common';
import { checkPermission } from '../../../../utils/auth';
import Button from '../../../../core-components/atoms/Button';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px #E8ECF1',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: '16px 16px',
    alignItems: ' flex-start',
    boxShadow:
      '0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.10)'
  },
  dockDialog: {
    marginTop: 16
    // padding: '10px 0px 0px !important'
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  dockInputField: {
    width: '270px'
  },
  switchButton: {
    marginTop: 16
  },
  cancelBtn: {
    padding: '8px 12px',
    // gap: '6px',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#0B1940'
  },
  marginBottom: {
    marginTop: 16
  },
  submitBtn: {
    padding: '8px 12px',
    background: '#0C6BD7',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:disabled': {
      background: 'rgba(12,107,215,0.5)'
    }
  }
}));

const AddBrandView = ({
  anchorEl,
  route,
  item,
  handleClose,
  type,
  getBrands,
  companyId
}) => {
  const classes = useStyles();
  const { setAPILoader, currentUser } = useContext(SharedContext);
  const theme = createTheme({
    palette: {
      primary: { main: OASIS_PRIMARY_500 }
    }
  });
  const formik = useFormik({
    initialValues: {
      name: item?.name || '',
      isActive: item?.isActive ?? true,
      manufacturerName: item?.manufacturerName || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(`${type} name is required`),
      isActive: Yup.bool().required(`${type} active status is required`),
      manufacturerName: Yup.string().optional()
    }),
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  const submitForm = async (values) => {
    try {
      setAPILoader(true);
      if (item && item.id) {
        const updatedValues = { ...values };
        await API.put(`${route}/${item.id}`, {
          ...updatedValues,
          companyId: companyId
        });
      } else {
        const updatedValues = { ...values };
        if (values.manufacturerName === '') {
          delete updatedValues.manufacturerName;
        }
        await API.post(`${route}`, {
          ...updatedValues,
          companyId: companyId
        });
      }
      toaster(
        'success',
        !(item && item.id)
          ? `${type} created successfully.`
          : `${type} updated successfully.`
      );

      getBrands();
      formik.resetForm();
      handleClose();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_${type}s_warehouseId`,
        `${type} already exists it must be unique`
      );
    } finally {
      setAPILoader(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-end'}>
      <div className={classes.paper}>
        <div>
          <Title variant={'lg'}>
            {item?.id ? `Edit ${type}` : `Add A New ${type}`}
          </Title>
        </div>
        <div className={classes.dockDialog}>
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className={classes.dockInputField}
          >
            <ThemeProvider theme={theme}>
              <TextField
                id="name"
                label={`${type} Name`}
                value={formik.values.name || ''}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size={'small'}
                color={'primary'}
                fullWidth={true}
                error={formik.errors.name && formik.touched.name}
              />

              <TextField
                id="manufacturerName"
                label={`Manufacturer Name`}
                value={formik.values.manufacturerName || ''}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size={'small'}
                color={'primary'}
                className={classes.marginBottom}
                fullWidth={true}
              />
            </ThemeProvider>
            {formik.errors.name && formik.touched.name && (
              <FormHelperText
                id="component-error-text"
                error={true}
                className={classes.dockInputField}
              >
                {formik.errors.name}
              </FormHelperText>
            )}

            <div className={classes.marginBottom}>
              <Switch
                checked={formik.values.isActive}
                onChange={() =>
                  formik.setFieldValue('isActive', !formik.values.isActive)
                }
                label="Active"
              />
            </div>

            <div className={classes.btnGroup}>
              <Button
                label="Cancel"
                className={classes.cancelBtn}
                variant="transparent"
                overrideSize={true}
                labelClass="font-medium text-xs capitalize text-oasisPrimary500"
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}
              />
              {item?.id ? (
                checkPermission(currentUser, 'OPS_BRAND_UPDATE') && (
                  <Button
                    label="Update"
                    className={classes.submitBtn}
                    variant="transparent"
                    overrideSize={true}
                    type="submit"
                    labelClass="font-medium text-xs capitalize text-oasisPrimary500"
                    disabled={!(formik.isValid && formik.dirty)}
                  />
                )
              ) : (
                <Button
                  label="Save"
                  className={classes.submitBtn}
                  variant="transparent"
                  overrideSize={true}
                  type="submit"
                  labelClass="font-medium text-xs capitalize text-oasisPrimary500"
                  disabled={!(formik.isValid && formik.dirty)}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </Popper>
  );
};

export default AddBrandView;

import { makeStyles } from '@material-ui/core';

const grnStyles = makeStyles((theme) => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px',
    width: '100% !important',
    margin: '0px !important',
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      padding: '0px'
    },
    '& .MuiTabs-indicator': {
      background: '#0C71D7 !important',
      height: '4px !important'
    }
  },
  heading: {
    fontWeight: 'bolder'
  },
  textArea: {
    borderRadius: '8px',
    border: '1px solid #EDF3FA',
    background: '#EDF3FA',
    height: '115px !important'
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40
  },

  tableHeader: {
    width: '1211px',
    height: '69.28px',
    left: '509.66px',
    top: '882px',
    border: '1px solid rgba(75, 137, 200, 0.2)'
  },
  detailText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: '#000000',
    width: '100%',
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      fontSize: '12px'
    }
  },
  smallerText: {
    'font-size': '12px',
    color: '#979797',
    'padding-left': '5px'
  },
  /*  cancelBtn: {
         "background": "#D9DFE5",
         border: '2px solid #D9DFE5',
         cursor: "pointer",
         "color": "#979B9F",
         "border-radius": "8px",
         "width": "137px",
         "height": "32px",
         '@media only screen and (max-width: 768px) and (min-width:578px)': {
             width: '98% !important'
         },
         '@media only screen and (max-width: 578px) and (min-width: 360px)': {
             width: '100% !important',
             "margin-left": "-8px !important",
         },
         '@media only screen and (max-width: 360px) and (min-width: 320px)': {
             "width": "97%",
             "margin-right": "10px !important",
         }
     }, */
  generateBtn: {
    background: '#4B89C8',
    border: '2px solid #4B89C8',
    cursor: 'pointer',
    color: '#FFFFFF',
    'border-radius': '8px',
    width: '137px',
    height: '32px',
    'margin-left': '15px',
    'margin-right': '7px',
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      width: '98% !important',
      'margin-left': '0px !important',
      'margin-top': '15px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '100% !important',
      'margin-left': '-8px !important',
      'margin-top': '15px !important'
    },
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      width: '97%',
      'margin-left': '0px !important',
      'margin-top': '15px !important'
    }
  },
  draftBtn: {
    background: '#FFFFFF',
    border: '2px solid #4B89C8',
    cursor: 'pointer',
    color: '#4B89C8',
    'border-radius': '8px',
    width: '136px',
    height: '32px',
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      width: '98% !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '100% !important',
      'margin-left': '-6px !important'
    },
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      width: '97%'
    }
  },
  recoverableCell: {
    left: '1283px',
    top: '951px',
    fontWeight: '500 !important',
    color: 'rgba(223, 130, 68, 0.87) !important'
  },
  unsellableCell: {
    left: '1364px',
    top: '951px',
    fontWeight: '500 !important',
    color: 'rgba(255, 59, 59, 0.87) !important'
  },
  goodQtyCell: {
    width: '15%',
    left: '1283px',
    top: '951px',
    fontWeight: '500 !important',
    color: '#0B1940 !important'
  },
  w15: {
    width: '12%'
  },

  // calender input CSS

  calenderProduct: {
    background: '#EDF3FA !important',
    'border-radius': '5px !important',
    padding: '5px'
  },

  // GRN/GDN disable button

  buttonDisabled: {
    cursor: 'no-drop',
    background: '#4B89C8',
    border: '2px solid #4B89C8',
    color: '#FFFFFF',
    'border-radius': '8px',
    width: '150px',
    height: '32px',
    'margin-left': '15px'
  },
  tableFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid rgba(75, 137, 200, 0.20)',
    background: '#FAFAFC',
    height: '56px',
    padding: '12px 32px',
    '& a, & label': {
      padding: '0px !important',
      minWidth: '0px !important',
      marginRight: 0
    }
  },
  icon: {
    width: '30px',
    cursor: 'pointer'
  },

  //// NEW

  inputBox: {
    display: 'flex',
    margin: '40px 0px',
    justifyContent: 'space-between'
  },
  textField: {
    overflowX: 'clip',
    '& .MuiAutocomplete-inputRoot': {
      marginTop: '0px',
      marginLeft: '0px',
      color: '#323338',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      height: '40px',
      width: '288px',
      paddingLeft: '24px !important'
    },
    '& .MuiFormLabel-root': {
      color: '#0B1940',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0.15px',
      marginTop: '-8px',
      marginLeft: '10px'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0C70D7',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500
    },
    '& .MuiInputLabel-shrink': {
      marginTop: '0px !important',
      marginLeft: '0px !important',
      color: '#0B1940',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400
    },
    '& input': {
      fontWeight: '500'
    },
    '& .Mui-focused input': {
      color: '#0C70D7'
    },

    '& .Mui-focused fieldset': {
      border: '1px solid #0C70D7 !important'
    },
    '& fieldset': {
      border: '1px solid #0B1940 !important'
    },

    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      color: '#676879',
      visibility: 'visible'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: '0px !important'
      },
    // '& .MuiOutlinedInput-root': {
    //     paddingLeft: '24px !important'
    // },
    '& .MuiInputBase-fullWidth': {
      width: '100%'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px 10px 24px !important'
    }
  },
  clearIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: 'path("M4.59167 3.84024L4.16602 3.45455L3.74036 3.84024C3.97544 4.05325 4.35659 4.05325 4.59167 3.84024ZM4.16602 2.68316L1.38115 0.15976C1.14606 -0.0532536 0.764915 -0.0532536 0.52983 0.15976C0.294744 0.372773 0.294744 0.718136 0.52983 0.931149L3.74036 3.84024L4.16602 3.45455L4.59167 3.84024L7.8022 0.931149C8.03729 0.718136 8.03729 0.372773 7.8022 0.15976C7.56712 -0.0532536 7.18597 -0.0532536 6.95088 0.15976L4.16602 2.68316Z") '
        }
      }
    }
  },
  textInput: {
    borderRadius: '4px !important',
    background: '#FFF !important',
    height: '40px !important',
    color: '#0B1940 !important',
    fontSize: '16px !important',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: '400  !important',
    letterSpacing: '0.15px !important',
    padding: '0px'
  },
  longInput: {
    width: '366px',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
      {
        right: '33px'
      }
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.05px'
  },
  tableTitle: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.45px'
  },
  tabContainer: {
    '& .Mui-selected': {
      height: '56px',
      minWidth: '0px',
      borderBottom: '4px solid #0C71D7 !important',
      padding: '0px',
      color: '#0C70D7 !important',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary': {
      height: '56px',
      minWidth: '0px',
      borderBottom: '4px solid white',
      padding: '0px',
      color: 'black',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: 'capitalize'
    },
    '& .MuiTouchRipple-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left'
    },
    '& .MuiTab-root': {
      marginRight: '24px'
    }
  },
  validation: {
    fontSize: '12px !important',
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    marginTop: '6px !important',
    position: 'absolute',
    top: '38px'
  },
  btn: {
    display: 'flex !important',
    /*         width: "121px", */
    height: '40px !important',
    padding: '10px !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '4px !important',
    width: '100% !important'
  },
  cancelBtn: {
    background: '#C5C7D0'
  },
  acceptBtn: {
    background: '#0C71D7'
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  vCenter: {
    display: 'flex',
    alignItems: 'center'
    // marginTop: '16px !important'
  },
  subTable: {
    background: '#FAFAFC',
    borderRadius: '4px',
    border: '2px dashed #E0E0E2',
    boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
    '& th': {
      border: '0px',
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.17px',
      textAlign: 'left'
    },
    '& td': {
      height: '56px'
    }
  },
  lgBtn: {
    display: 'flex',
    width: '236px',
    padding: '8px 40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'uppercase'
  },
  neutralBtn: {
    color: '#0C71D7',
    background: 'white',
    border: '2px solid #0C71D7',
    boxSizing: 'border-box'
  },
  successBtn: {
    background: '#0C71D7',
    color: 'white',
    border: '0px'
  },
  rejectBtn: {
    background: '#C5C7D0',
    color: 'black',
    marginRight: '24px',
    border: '0px'
  },
  btnRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowPromoName: {
    color: '#0C70D7',
    marginTop: 8
  },
  rowPromoQuanitity: {
    color: '#0C70D7 !important',
    fontWeight: '400 !important',
    marginLeft: 10
  },
  promoIcon: {
    marginLeft: '8px'
  },
  selectOption: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.035px'
  },
  selectPromo: {
    color: '#0C70D7',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.035px',
    marginLeft: 8
  }
}));

export default grnStyles;

import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  systemAlert: {
    marginBottom: 10
  }
}));

function ErrorFileUploaded({ open, errorAlerts, handleClose }) {
  const classes = useStyles();

  return (
    <div style={{ display: 'inline' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Errors</DialogTitle>
        <DialogContent>
          <Grid item xs={12} className={classes.uploadDetails}>
            {errorAlerts?.map((alert, index) => {
              return (
                <Alert
                  key={index}
                  severity="error"
                  className={classes.systemAlert}
                >
                  {alert.message ? alert.message : alert}{' '}
                </Alert>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Close">
            <Button onClick={handleClose} color="primary" variant="contained">
              Close
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ErrorFileUploaded;

import React, { useState, useRef, useCallback } from 'react';
import {
  Button,
  Dialog,
  FormControlLabel,
  FormGroup,
  Switch,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import { debounce } from 'lodash';
import classNames from 'classnames/bind';

import Scanning from '../../../assets/icons/scanning.gif';
// eslint-disable-next-line no-unused-vars
import { SharedContext, decryptDate } from '../../../utils/common';
import { DEBOUNCE_CONST } from '../../../Config';
import useStyles from './palletizeStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#cecece',
    '&$checked': {
      color: 'blue'
    },
    '&$checked + $track': {
      backgroundColor: 'blue'
    }
  },
  checked: {},
  track: {}
})(Switch);

const ScanPallets = ({
  open,
  validateProducts,
  scanPallets,
  warehouse,
  handleClose,
  formik
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [scanVal, setScanVal] = useState('');
  const [isScanning, setIsScanning] = useState(true);

  const scanPalletHandler = useCallback(
    debounce((val) => {
      keydownHandler(val);
    }, DEBOUNCE_CONST),
    []
  );

  const keydownHandler = async (pallet) => {
    if (!pallet.includes('}')) {
      pallet += '}';
    }
    try {
      pallet = JSON.parse(pallet);
      if (scanPallets) {
        palletOperation(pallet);
      } else {
        productOperation(pallet);
      }
    } catch (err) {
      setScanVal('');
      toaster('warning', 'No pallet ID found');
      return false;
    } finally {
      setScanVal('');
      setLoading(false);
    }
  };

  const productOperation = (pallet) => {
    if (pallet?.pid) {
      const detail = {
        PID: pallet?.pid,
        batch: pallet?.batch,
        exp: decryptDate(pallet?.expd)
      };
      validateProducts(detail);
    } else {
      toaster('warning', 'No Product found');
    }
  };

  const palletOperation = async (pallet) => {
    const check = await verifyPallet(pallet);
    if (check?.length && pallet?.id && pallet?.palletId) {
      formik.setFieldValue('pallet', pallet);
      handleClose();
    } else {
      toaster('warning', 'Pallet ID does not belong to selected warehouse');
    }
  };

  const verifyPallet = async (pallet) => {
    try {
      const columns = [
        'id',
        'palletId',
        'creationDate',
        '$Warehouse.businessWarehouseCode$'
      ];
      let colVal = {
        id: pallet?.id,
        '$Warehouse.id$': warehouse
      };
      const page = 1;
      const filters = { colVal };
      const pallets = await API.get(`pallets/list`, {
        params: { filters, columns, page }
      });
      return pallets?.data;
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    }
  };

  const scannerBtnClass = classNames({
    'display-flex': true,
    'justify-content-center': true,
    scanButtonResponsive: true
  });

  return (
    <>
      {loading ? (
        <div className={classes.scanningOverlay}>
          <CircularProgress className={classes.childLoader} />
        </div>
      ) : (
        ''
      )}
      {open && (
        <Dialog className={classes.backdrop} open={open}>
          <img src={Scanning} />

          <input
            ref={inputRef}
            style={{ border: 'none', color: 'white' }}
            type="text"
            value={scanVal}
            onChange={(e) => {
              setScanVal(e.target.value);
              setLoading(true);
              scanPalletHandler(e.target.value);
            }}
            onBlur={() => {
              setIsScanning(false);
            }}
            autoFocus
          />
          <div className={scannerBtnClass}>
            <div className="mt-5 mb-5 ml-auto">
              <Button
                className="responsiveScannerBtn"
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                CANCEL
              </Button>
            </div>
            <div className="mt-5 mb-5 ml-auto">
              <FormGroup>
                <FormControlLabel
                  control={
                    <PurpleSwitch
                      checked={isScanning}
                      onChange={(e) => {
                        setIsScanning(e.target.checked);
                        inputRef.current.focus();
                      }}
                    />
                  }
                  label="Scanning"
                />
              </FormGroup>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ScanPallets;

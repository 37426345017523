/*eslint-disable*/
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  TableRow,
  TableCell,
  TableHead,
  CircularProgress
} from '@material-ui/core';
import clsx from 'clsx';
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef
} from 'react';
import TableHeader from '../../../components/TableHeader';
import Filter from '../../../components/Filter';
import AddVehicleView from './AddVehicleView';
import VehicleDetailsView from './VehicleDetailsView';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import EditIcon from '@material-ui/icons/EditOutlined';
import { SharedContext } from '../../../utils/common';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { checkPermission } from '../../../utils/auth';
import { MdFilterAlt, MdOutlineNorth, MdOutlineSouth } from 'react-icons/md';
import SearchInput from '../../../components/SearchInput';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import classNames from 'classnames/bind';
import { toaster } from '../../../utils/toaster';
import FilterButton from '../../../components/stats/FilterButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import VehicleHead from './VehicleHead';
import VehiclePopup from './VehiclePopup';
import NoImageIcon from '../../../assets/icons/noImageIcon.svg';
import AlertIcon from '../../../assets/icons/error.svg';
import WarningIcon from '../../../assets/icons/errorOutlinedIcon.svg';
import EditIcon from '../../../assets/icons/editIconDark.svg';
import PinDropIcon from '../../../assets/icons/pinDropIcon.svg';
import Button from '../../../core-components/atoms/Button';
import PopupButton from '../../../core-components/molecules/PopupButton';
import CustomTextField from '../../../core-components/atoms/TextField';
import Popup from '../../../core-components/atoms/Popup';
import RefuelingView from './RefuelingView';
import { RemoveRedEye } from '@material-ui/icons';
import VehicleLocation from './VehicleLocation';
import Geocode from 'react-geocode';
import { FormControl } from '@mui/material';
import Autocomplete from '../../../core-components/atoms/Autocomplete';

const filterInitialState = {
  Carrier: [],
  'Vehicle Type': [],
  Status: []
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    // maxHeight: 450,
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInputVehicle: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  addVehicleButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  cancelBtn: {
    padding: '8px 12px',
    // gap: '6px',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#0B1940'
  },

  submitBtn: {
    padding: '8px 12px',
    background: '#0C6BD7',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:disabled': {
      background: 'rgba(12,107,215,0.5)'
    }
  }
}));
function VehicleView() {
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const { currentUser, setAPILoader, organizationType, settings } =
    useContext(SharedContext);
  const tableContainerRef = useRef(null);

  const [vehicles, setVehicles] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [addVehicleView, setAddVehicleView] = useState(false);
  const [vehicleDetailsView, setVehicleDetailsView] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [fetchState, setFetchState] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [drivers, setDrivers] = useState([]);
  const [carriers, setcarriers] = useState([]);
  const [cars, setCars] = useState([]);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [sortable, setSortable] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [filterView, setFilterView] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [vehicleName, setVehicleName] = useState('');
  const [attemptedAlready, setAttemptedAlready] = useState(false);
  const [vehiclesLoading, setVehiclesLoading] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [maintainenceBtnOpen, setMaintainenceBtnOpen] = useState(false);
  const [lastMaintainence, setLastMaintainence] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [categories, setCategories] = useState([]);
  const [refuelingView, setRefuelingView] = useState(false);
  const [filters, setFilters] = useState(filterInitialState);
  const [vehicleLocationView, setVehicleLocationView] = useState(false);
  const [selectedVehicleLocation, setSelectedVehicleLocation] = useState('');

  useEffect(() => {
    getCategories('');
  }, []);

  const addVehicle = async (data) => {
    setFormErrors('');
    setAPILoader(true);
    try {
      setAttemptedAlready(true);
      if (!selectedVehicle) await API.post(`vehicles`, data);
      else await API.put(`vehicles/${selectedVehicle.id}`, data);
      toaster(
        'success',
        !selectedVehicle
          ? 'New vehicle has been created.'
          : 'Vehicle updated successfully.'
      );
      closeAddVehicleViewModal(false);
      setSearchKeyword('');
      getVehicles(page);
      getCategories('');
    } catch (err) {
      onError(err);
    } finally {
      setAttemptedAlready(false);
      setAPILoader(false);
    }
  };

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const getCategories = async (search = '') => {
    try {
      setAPILoader(true);
      const { data } = await API.get('vehicles/maintenance/categories', {
        params: { search, columns: ['name'] }
      });

      setCategories(data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  // Sorting function
  const fetchRes = () => {
    setFetchState(true);
    _getVehicles(page, searchKeyword);
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const closeFilter = () => {
    setFilterView(false);
  };
  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };
  function CustomVehicleToolbar() {
    return (
      <FilterButton searchKeyword openFilterView={() => openFilterView()} />
    );
  }
  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const isPhone = useMediaQuery('(max-width:360px)');
  //All columns...
  const columnsNew = [
    {
      field: 'Registration Number',
      flex: isPhone ? null : 1,
      width: isPhone ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          if (params.row.registrationNumber) {
            result.push(params.row.registrationNumber);
          }
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Registration Number
            {fetchState && registrationNumber ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setRegistrationNumber('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('registrationNumber');
              }}
              className={
                sortable.col == 'registrationNumber' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'registrationNumber' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'Vendor Name',
      flex: isPhone ? null : 1,
      width: isPhone ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.Vendor) {
          if (params.row.Vendor.name) {
            result.push(params.row.Vendor.name);
          }
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Vendor Name
            {fetchState && vendorName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setVendorName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Vendor.name');
              }}
              className={
                sortable.col == 'Vendor.name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Vendor.name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'Vehicle Type',
      flex: isPhone ? null : 1,
      width: isPhone ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        let result = [];
        if (params.row.Car) {
          if (params.row.Car.CarMake.name) {
            result.push(
              `${params?.row?.Car?.CarMake?.name} ${params?.row?.Car?.CarModel?.name} ${params?.row?.Car?.Category?.name}`
            );
          }
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Vehicle Type
            {fetchState && vehicleType ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setVehicleType('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('`Car->CarMake`.name');
              }}
              className={
                sortable.col == '`Car->CarMake`.name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == '`Car->CarMake`.name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_VEHICLE_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={params}
                onClick={() => _getVehicle(params.row.id, 'edit')}
              />
            </Tooltip>
          ) : (
            ''
          )}
          {checkPermission(currentUser, 'OPS_VEHICLE_READ') ? (
            <Tooltip title="View">
              <VisibilityIcon
                key={params}
                onClick={() => _getVehicle(params.row.id, 'detail')}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const openEditView = (vehicle) => {
    setSelectedVehicle(vehicle);
    setAddVehicleView(true);
  };
  const openViewDetails = (vehicle) => {
    setSelectedVehicle(vehicle);
    setVehicleDetailsView(true);
  };
  // close functions
  const closeAddVehicleViewModal = () => {
    setSelectedVehicle(null);
    setAddVehicleView(false);
  };
  const closeVehicleDetailsView = () => {
    setVehicleDetailsView(false);
    setSelectedVehicle(null);
  };
  // constants views
  const addVehicleButton = (
    <Tooltip title="Add Vehicle">
      <Button
        key={2}
        id="removeHoverBtn"
        variant="contained"
        color="primary"
        size="small"
        className={classes.addVehicleButton}
        onClick={() => {
          setAddVehicleView(true);
        }}
      >
        ADD VEHICLE
      </Button>
    </Tooltip>
  );

  const addVehicleViewModal = (
    <AddVehicleView
      key={3}
      selectedVehicle={addVehicleView ? selectedVehicle : null}
      vehicles={vehicles}
      companies={carriers}
      drivers={drivers}
      cars={cars}
      setCars={setCars}
      formErrors={formErrors}
      addVehicle={addVehicle}
      open={addVehicleView}
      attemptedAlready={attemptedAlready}
      setAttemptedAlready={setAttemptedAlready}
      handleClose={() => closeAddVehicleViewModal()}
    />
  );

  const vehicleDetailsViewModal = (
    <VehicleDetailsView
      selectedVehicle={selectedVehicle}
      open={vehicleDetailsView}
      handleClose={closeVehicleDetailsView}
    />
  );

  const _getVehicles = async (page, searchKeyword) => {
    setVehiclesLoading(true);
    try {
      const vehiclesResponse = await API.get(`vehicles`, {
        params: {
          page: page,
          search: searchKeyword,
          filters,
          previews: true
        }
      });
      setPageCount(vehiclesResponse?.pages);
      if (page === 1) {
        setVehicles(vehiclesResponse?.data);
      } else {
        setVehicles([...vehicles, ...vehiclesResponse?.data]);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        onError(err);
        return;
      }

      let errors = err?.response?.data?.error?.errors || [];

      errors.map((data) => {
        onError(data);
      });
    } finally {
      setVehiclesLoading(true);
    }
  };

  const _getCars = async () => {
    try {
      const vehicleTypes = await API.get(`vehicles/types`);
      setCars(
        vehicleTypes?.data
          ? vehicleTypes?.data.filter(
              (vehicleType) => vehicleType.isActive === true
            )
          : []
      );
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getcarriers = async () => {
    try {
      const carriers = await API.get(`companies`, {
        params: { type: 'VENDOR' }
      });
      setcarriers(carriers.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getVehicle = async (id, type) => {
    try {
      const vehicle = await API.get(`vehicles/${id}`);
      type == 'edit'
        ? openEditView(vehicle.vehicle)
        : openViewDetails(vehicle.vehicle);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getVehicles = useCallback(
    debounce((page, searchKeyword) => {
      _getVehicles(page, searchKeyword);
    }, DEBOUNCE_CONST),
    [filters]
  );

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'Vehicles';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    getVehicles(page, searchKeyword);
  }, [page, searchKeyword, filters]);

  useEffect(() => {
    _getcarriers();
    _getCars();
  }, []);

  useEffect(() => {
    fetchRes();
  }, [registrationNumber, vehicleName, vehicleType, vendorName, sortable]);

  const searchInputVehicle = (
    <SearchInput
      mainClass={mainClass}
      searchKeyword={searchKeyword}
      setSearchKeyword={setSearchKeyword}
      setPage={() => setPage(1)}
    />
  );

  //Input Objects for filters
  let regNumObj = {
    type: 'text',
    className: 'textField',
    label: 'Registration Number',
    field: 'registrationNumber',
    value: registrationNumber ? registrationNumber : '',
    setNameVal: (e) => setNameVal(setRegistrationNumber, e)
  };
  let vendorNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Vendor Name',
    field: 'Vendor.name',
    value: vendorName ? vendorName : '',
    setNameVal: (e) => setNameVal(setVendorName, e)
  };
  let vehicleTypeObj = {
    type: 'text',
    className: 'textField',
    label: 'Vehicle Type',
    field: 'Car.CarMake.name',
    value: vehicleType ? vehicleType : '',
    setNameVal: (e) => setNameVal(setVehicleType, e)
  };
  //Custom modal for filter....
  const filterCols = [regNumObj, vendorNameObj, vehicleTypeObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'Driver'}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );
  // const headerButtons = [searchInput, addVehicleButton, addVehicleViewModal, vehicleDetailsViewModal]
  const headerButtons = [
    checkPermission(currentUser, 'OPS_VEHICLE_CREATE')
      ? addVehicleButton
      : null,
    checkPermission(currentUser, 'OPS_VEHICLE_READ')
      ? searchInputVehicle
      : null,
    checkPermission(currentUser, 'OPS_VEHICLE_CREATE') ||
    checkPermission(currentUser, 'OPS_VEHICLE_UPDATE')
      ? addVehicleViewModal
      : null,
    checkPermission(currentUser, 'OPS_VEHICLE_READ')
      ? vehicleDetailsViewModal
      : null,
    checkPermission(currentUser, 'OPS_USER_READ') ? filterModal : null
  ];

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !vehiclesLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
    }
  };

  const columnNames = [
    'Registration #',
    'Carrier Name',
    'Vehicle Type',
    `Mileage (${settings?.distance?.symbol || 'Km'}/${
      settings?.volume?.symbol || 'cm³'
    })`,
    `Avg Speed (${settings?.distance?.symbol || 'Km'}/h)`,
    'Last Maintenance',
    `Vehicle ${settings?.distance?.symbol?.capitalize() || 'KM'}S`,
    `Max G.Weight (${settings?.weight?.symbol || 'Kg'})`
  ];

  const tableCellClass = 'py-2 h-12 pl-0 pr-2 text-xs';

  const getMaintainanceAlertMessage = (vehicle) => {
    if (
      vehicle?.distance >
      vehicle?.lastMaintainenceMileage + vehicle?.maintainenceMileage
    ) {
      return `Vehicle maintainence is overdue by ${
        vehicle?.distance -
        (vehicle?.lastMaintainenceMileage + vehicle?.maintainenceMileage)
      } kms.`;
    }
    if (
      vehicle?.distance >
      vehicle?.lastMaintainenceMileage + vehicle?.maintainenceMileage * 0.9
    ) {
      return `Vehicle maintainence is due in ${
        vehicle?.lastMaintainenceMileage +
        vehicle?.maintainenceMileage -
        vehicle?.distance
      } kms.`;
    }
  };

  const updateLastMaintainence = async (id) => {
    const payload = {
      mileage: lastMaintainence,
      categoryName,
      additionalInfo
    };

    try {
      setAPILoader(true);
      await API.post(`vehicles/${id}/maintenance-details`, payload);
      toaster('success', 'Vehicle Mileage updated successfully.');
      await _getVehicles(page, searchKeyword);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
      setMaintainenceBtnOpen(false);
    }
  };

  const setFilter = (key, value, type) => {
    let currentFilters = { ...filters };
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    setFilters(currentFilters);
  };

  return (
    <>
      {addVehicleView && (
        <VehiclePopup
          open={addVehicleView}
          setOpen={setAddVehicleView}
          organizationType={organizationType}
          selectedVehicle={selectedVehicle}
          refetch={getVehicles}
        />
      )}
      {refuelingView && (
        <Popup
          open={refuelingView}
          setOpen={setRefuelingView}
          onClose={() => setRefuelingView(false)}
          content={
            <div className="w-[800px]">
              <RefuelingView vehicle={selectedVehicle} />
            </div>
          }
          title={
            selectedVehicle?.Car?.name
              ? selectedVehicle?.Car?.name
              : `${selectedVehicle?.Car?.CarMake?.name || ''} ${
                  selectedVehicle?.Car?.CarModel?.name || ''
                } ${selectedVehicle?.Car?.Category?.name || ''}`
          }
        />
      )}
      {vehicleLocationView && (
        <Popup
          open={vehicleLocationView}
          setOpen={setVehicleLocationView}
          onClose={() => {
            setVehicleLocationView(false);
            setSelectedVehicle(null);
            setSelectedVehicleLocation('');
          }}
          content={
            <div className="min-w-[850px]">
              <VehicleLocation
                vehicle={selectedVehicle}
                vehicleLocation={selectedVehicleLocation}
              />
            </div>
          }
          title={`${selectedVehicle?.registrationNumber} - Live Location`}
        />
      )}
      <div className="no-scrollbar overflow-hidden">
        <div className="w-full mt-10 pl-8 pr-6">
          <VehicleHead
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            addVehicleView={addVehicleView}
            setAddVehicleView={setAddVehicleView}
            filters={filters}
            setFilter={setFilter}
            setFilters={() => {}}
            loading={false}
          />
          <div className="mb-5">
            <Paper className="no-scrollbar rounded-lg shadow-none">
              <TableContainer
                style={{ overflow: 'auto', height: 'calc(100vh - 164px)' }}
                ref={tableContainerRef}
                onScroll={handleTableScroll}
                className="mt-6 no-scrollbar rounded-lg border border-solid border-dark300"
              >
                <Table stickyHeader>
                  <TableHead className="z-40 relative">
                    <TableRow>
                      <TableCell className="w-8 bg-white"></TableCell>
                      {columnNames.map((columnName, index) => (
                        <TableCell
                          key={index}
                          className="text-[10px] text-dark500 bg-white py-2 pl-0 pr-2 font-normal h-[40px]"
                        >
                          {columnName}
                        </TableCell>
                      ))}
                      <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicles?.map((row, index) => (
                      <TableRow
                        key={index}
                        onMouseEnter={() => {
                          if (!maintainenceBtnOpen) {
                            setHoveredRow(index);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!maintainenceBtnOpen) {
                            setHoveredRow(-1);
                            setMaintainenceBtnOpen(false);
                            setLastMaintainence(null);
                            setCategoryName(null);
                            setAdditionalInfo(null);
                          }
                        }}
                        className={clsx({
                          'bg-snow text-auroMetal group':
                            row?.distance >
                            row?.lastMaintainenceMileage +
                              row?.maintainenceMileage
                        })}
                      >
                        <TableCell
                          className={clsx(tableCellClass, 'pl-3 pr-3')}
                        >
                          <div className="w-8 h-8 rounded overflow-hidden border-dark300 border flex justify-center items-center">
                            <img
                              src={
                                row?.photoPreview
                                  ? row?.photoPreview
                                  : NoImageIcon
                              }
                              alt={
                                row?.photoPreview ? 'Vehicle Image' : 'No Image'
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={tableCellClass}>
                          <div className="flex items-center h-full">
                            <p
                              className={clsx('mr-1', {
                                'text-primaryBlue':
                                  row?.distance <=
                                  row?.lastMaintainenceMileage +
                                    row?.maintainenceMileage,
                                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                  row?.distance >
                                  row?.lastMaintainenceMileage +
                                    row?.maintainenceMileage
                              })}
                            >
                              {row?.registrationNumber || '-'}
                            </p>
                            <div>
                              {(row?.distance >
                                row?.lastMaintainenceMileage +
                                  row?.maintainenceMileage ||
                                row?.distance >
                                  row?.lastMaintainenceMileage +
                                    row?.maintainenceMileage * 0.9) && (
                                <Tooltip
                                  title={getMaintainanceAlertMessage(row)}
                                  placement="top"
                                  classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.arrow
                                  }}
                                  arrow
                                >
                                  <span>
                                    <img
                                      src={
                                        row?.distance >
                                        row?.lastMaintainenceMileage +
                                          row?.maintainenceMileage
                                          ? AlertIcon
                                          : WarningIcon
                                      }
                                      alt="alert"
                                    />
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={clsx(tableCellClass, {
                            'text-primaryBlue':
                              row?.distance <=
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage,
                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                              row?.distance >
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage
                          })}
                        >
                          {row?.Vendor?.name || '-'}
                        </TableCell>
                        <TableCell
                          className={clsx(tableCellClass, {
                            'text-primaryBlue':
                              row?.distance <=
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage,
                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                              row?.distance >
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage
                          })}
                        >
                          {row?.Car?.name
                            ? row?.Car?.name
                            : row?.Car?.CarMake
                            ? `${
                                row?.Car?.CarMake?.name ||
                                row?.Car?.CarMake?.carMake
                              } ${row?.Car?.CarModel?.name} - ${
                                row?.Car?.Category?.name
                              }`
                            : ''}
                        </TableCell>
                        <TableCell
                          className={clsx(tableCellClass, {
                            'text-primaryBlue':
                              row?.distance <=
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage,
                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                              row?.distance >
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage
                          })}
                        >
                          {row?.mileage}
                        </TableCell>
                        <TableCell
                          className={clsx(tableCellClass, {
                            'text-primaryBlue':
                              row?.distance <=
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage,
                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                              row?.distance >
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage
                          })}
                        >
                          {row?.avgSpeed}
                        </TableCell>
                        <TableCell
                          className={clsx(tableCellClass, {
                            'text-primaryBlue':
                              row?.distance <=
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage,
                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                              row?.distance >
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage
                          })}
                        >
                          {row?.lastMaintainenceMileage}
                        </TableCell>{' '}
                        <TableCell
                          className={clsx(tableCellClass, {
                            'text-primaryBlue':
                              row?.distance <=
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage,
                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                              row?.distance >
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage
                          })}
                        >
                          {row?.distance}
                        </TableCell>
                        <TableCell
                          className={clsx(tableCellClass, {
                            'text-primaryBlue':
                              row?.distance <=
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage,
                            'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                              row?.distance >
                              row?.lastMaintainenceMileage +
                                row?.maintainenceMileage
                          })}
                        >
                          {row?.Car?.VehicleType?.weight +
                            row?.Car?.VehicleType?.payloadWeight}
                        </TableCell>
                        <TableCell className="rounded-none">
                          <div
                            className={clsx(
                              'flex gap-1 h-full items-center justify-end',
                              {
                                'invisible ': hoveredRow !== index
                              }
                            )}
                          >
                            {/* {checkPermission(
                              currentUser,
                              'OPS_VEHICLE_READ'
                            ) && (
                              <Button
                                variant="tiny-transparent"
                                icon={<img src={PinDropIcon} alt="View" />}
                                className={
                                  'p-1 border-0 h-7 min-w-fit rounded-none'
                                }
                                overrideSize={true}
                                labelClass="p-0"
                                // onClick={(e) => handleClick(e)}
                              />
                            )}
                            {checkPermission(
                              currentUser,
                              'OPS_VEHICLE_READ'
                            ) && (
                              <Button
                                variant="tiny-transparent"
                                icon={<img src={ViewIcon} alt="View" />}
                                className={
                                  'p-1 border-0 h-7 min-w-fit rounded-none'
                                }
                                overrideSize={true}
                                labelClass="p-0"
                                // onClick={(e) => handleClick(e)}
                              />
                            )} */}
                            <span className="mr-2">
                              <PopupButton
                                open={
                                  hoveredRow === index && maintainenceBtnOpen
                                }
                                setOpen={setMaintainenceBtnOpen}
                                btn={
                                  <Button
                                    label="Record Maintenance"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setMaintainenceBtnOpen(true);
                                    }}
                                    variant="transparent"
                                  />
                                }
                                content={
                                  <div className="min-w-[280px] p-4">
                                    <p className="font-medium">
                                      Record Maintenance Reading
                                    </p>
                                    <div className="mt-2 w-[280px]">
                                      <FormControl fullWidth className="my-1">
                                        <CustomTextField
                                          placeholder="Current Maintenance (kms)"
                                          value={lastMaintainence}
                                          onChange={(e) =>
                                            setLastMaintainence(e.target.value)
                                          }
                                          size="small"
                                          type="number"
                                        />
                                      </FormControl>
                                      <FormControl fullWidth className="my-1">
                                        <Autocomplete
                                          options={categories.map(
                                            ({ name }) => {
                                              return {
                                                label: name,
                                                value: name
                                              };
                                            }
                                          )}
                                          value={{
                                            label: categoryName,
                                            value: categoryName
                                          }}
                                          freeSolo
                                          name="category"
                                          placeholder="Category"
                                          size="small"
                                          getOptionLabel={(option) =>
                                            option.label || ''
                                          }
                                          onChange={(_, newValue) => {
                                            if (newValue)
                                              setCategoryName(newValue.value);
                                          }}
                                          onInputChange={(
                                            _,
                                            newValue,
                                            reason
                                          ) => {
                                            setCategoryName(newValue);
                                            if (reason === 'input')
                                              getCategories(newValue);
                                          }}
                                        />
                                      </FormControl>
                                      <FormControl fullWidth className="my-1">
                                        <CustomTextField
                                          placeholder="Additional Info"
                                          value={additionalInfo}
                                          onChange={(e) =>
                                            setAdditionalInfo(e.target.value)
                                          }
                                          multiline
                                          rows={2}
                                          size="small"
                                        />
                                      </FormControl>
                                      <div className={classes.btnGroup}>
                                        <button
                                          className={classes.cancelBtn}
                                          type="button"
                                          onClick={() => {
                                            setRefuelingView(false);
                                            setMaintainenceBtnOpen(false);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className={classes.submitBtn}
                                          disabled={!lastMaintainence}
                                          onClick={() =>
                                            updateLastMaintainence(row?.id)
                                          }
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                }
                              />
                            </span>
                            {row.trackingLink && (
                              <a href={row.trackingLink} target="_blank">
                                <img src={PinDropIcon} className="mr-1" />
                              </a>
                            )}
                            {row.VehicleLocations?.filter(
                              (vl) => !vl.deletedAt
                            )?.[0]?.vehicleId && (
                              <img
                                src={PinDropIcon}
                                className="mr-1"
                                onClick={() => {
                                  setSelectedVehicle(row);
                                  setVehicleLocationView(true);
                                  Geocode.fromLatLng(
                                    row?.VehicleLocations?.[0]?.latitude,
                                    row?.VehicleLocations?.[0]?.longitude
                                  )
                                    .then(
                                      (addresses) =>
                                        addresses.results[0].formatted_address
                                    )
                                    .then((result) => {
                                      setSelectedVehicleLocation(result);
                                    });
                                }}
                              />
                            )}
                            {
                              <RemoveRedEye
                                onClick={() => {
                                  setRefuelingView(true);
                                  setSelectedVehicle(row);
                                }}
                                className="cursor-pointer"
                              />
                            }
                            {checkPermission(
                              currentUser,
                              'OPS_VEHICLE_UPDATE'
                            ) && (
                              <Button
                                variant="tiny-transparent"
                                icon={<img src={EditIcon} alt="Edit" />}
                                className={
                                  'p-1 border-0 h-7 min-w-fit rounded-none'
                                }
                                overrideSize={true}
                                labelClass="p-0"
                                onClick={() => {
                                  setSelectedVehicle(row);
                                  setAddVehicleView(true);
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {page < pageCount && (
                      <TableRow>
                        <TableCell className="py-2 h-12" colSpan={12}>
                          <div className="flex w-full justify-center">
                            <CircularProgress size="14px" />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    {vehicles?.length === 0 && !vehiclesLoading && (
                      <TableRow>
                        <TableCell className="py-2 h-12" colSpan={12}>
                          <div className="flex w-full justify-center">
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
}

export default VehicleView;

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { MdFilterAlt, MdOutlineSouth } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Pagination } from '@material-ui/lab';
import { confirmAlert } from 'react-confirm-alert';
import EditSvg from '../../assets/icons/edit.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import Phyramid from '../../assets/icons/pyramid.svg';
import SafariSvg from '../../assets/icons/safari.svg';
import AddBtn from '../../atomicComponents/AddBtn';
import ListingTitle from '../../atomicComponents/ListingTitle';
import SearchBar from '../../atomicComponents/SearchBar';
import CustomFilter from '../../components/CustomFilter';
import OrderManagementStats from '../../components/stats/OrderManagementStats';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { checkPermission } from '../../utils/auth';
import {
  SharedContext,
  dividerDateFormatForFilter,
  setStatus
} from '../../utils/common';
import { toaster } from '../../utils/toaster';
import OrderManagementDetail from '../orders/OrderManagementDetail';
import MoveTypeIcon from '../orders/moveTypeIcon';
import RouteManagementDetail from './RouteManagementDetail';
import RoutesMap from './RoutesMap';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    padding: 20
  },
  orderManagementContainer: {
    margin: '40px 15px'
  },
  active: {
    color: theme.palette.success.main
  },
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px'
  },
  pageHeading: {
    fontWeight: 600
  },
  pageSubHeading: {
    fontWeight: 300
  },
  heading: {
    fontWeight: 'bolder'
  },
  customUI: {
    textAlign: 'center',
    width: '500px',
    padding: '40px',
    background: '#fff',
    boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
    color: 'black'
  },
  customButton: {
    width: '160px',
    padding: '14px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#005cfd',
    color: '#fff',
    fontSize: '14px',
    borderRadius: '4px'
  },
  customButtonCancel: {
    width: '160px',
    padding: '14px 16px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: '#e0e0e0',
    color: '#1d1d1d',
    fontSize: '14px',
    borderRadius: '4px'
  },
  formControl: {
    minWidth: 160,
    boxSizing: 'border-box',
    paddingLeft: '8px'
  },
  addRideButton: {
    marginLeft: '10px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  },
  scheduledStatusButtonStyling: {
    color: '#FFC001',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  },
  onTheWayStatusButtonStyling: {
    color: '#FFC001',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  },
  arrivedStatusButtonStyling: {
    color: '#FFC001',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  },
  loadingCompleteStatusButtonStyling: {
    color: '#21612E',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  },
  loadingInProgressStatusButtonStyling: {
    color: '#21612E',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  },
  journeyInProgressStatusButtonStyling: {
    color: '#21612E',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  },
  completedStatusButtonStyling: {
    color: '#00B050',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  },
  cancelledStatusButtonStyling: {
    color: '#8E8E90',
    textTransform: 'capitalize',
    pointerEvents: 'none'
  }
}));

export default function OrderManagement() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [muiLoader, setMuiLoader] = useState(false);
  const [routeView, setRouteView] = useState(false);
  const [filterView, setFilterView] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [filter, setFilter] = useState(false);
  const [routeId, setRouteId] = useState('');
  const [routeIdVal, setRouteIdVal] = useState('');
  const [companyVal, setCompanyVal] = useState('');
  const [originVal, setOriginVal] = useState('');
  const [dropOffVal, setDropOffVal] = useState('');
  const [registrationNumberVal, setRegistrationNumberVal] = useState('');
  const [orderId, setOrderId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [routeData, setRouteData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [routeStats, setRouteStats] = useState([]);
  const [detailViewOpen, setDetailViewOpen] = useState(false);
  const [orderDetailViewOpen, setOrderDetailViewOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('ALL');
  const { currentUser } = useContext(SharedContext);

  const routeStatus = {
    NOT_ASSIGNED: 'Not Assigned',
    SCHEDULED: 'Scheduled',
    ON_THE_WAY_PICKUP: 'On The Way To Pickup',
    ARRIVED_PICKUP: 'Arrived For Pickup',
    LOADING_IN_PROGRESS: 'Loading In Progress',
    LOADING_COMPLETE: 'Loading Complete',
    ON_THE_WAY_DESTINATION: 'On The Way To Destination',
    ARRIVED_DESTINATION: 'Arrived at destination',
    UNLOADING_IN_PROGRESS: 'Unloading in progress',
    UNLOADING_COMPLETED: 'Unloading complete',
    COMPLETED: 'Completed'
  };

  const routeStatus2 = {
    ARRIVED_DESTINATION: 'Arrived At Destination'
  };
  const orderStatus = {
    DRAFT: 'Draft',
    PENDING: 'Pending',
    ASSIGNED: 'Route Assigned',
    LOAD_DISPATCHED: 'Load Dispatched',
    ON_THE_WAY_DESTINATION: 'On the way to destination',
    LOADING_IN_PROGRESS: 'Loading in progress',
    LOADING_COMPLETE: 'Loading complete',
    ARRIVED_DESTINATION: 'Arrived at destination',
    UNLOADING_IN_PROGRESS: 'Unloading in progress',
    UNLOADING_COMPLETE: 'Unloading complete',
    COMPLETED: 'Completed',
    CANCELLED: 'Order Cancelled',
    FULLY_COMPLETE: 'Fully Complete',
    PARTIALLY_COMPLETED_CLOSED: 'Partially Complete Closed',
    DOCK_ASSIGNED: 'Dock Assigned'
  };

  const routeBillingStatus = {
    NOT_COMPLETED: 'Not Completed',
    DOCUMENT_REQUIRED: 'Documents Required',
    PENDING_BILLING: 'Pending Billing',
    INVOICED: 'Invoiced',
    PAID: 'Paid',
    CANCELLED: 'Cancelled'
  };

  const afterCompleteRouteBillingStatus = {
    DOCUMENT_REQUIRED: 'Documents Required',
    PENDING_BILLING: 'Pending Billing',
    INVOICED: 'Invoiced',
    PAID: 'Paid'
  };

  const dialogueConfirmationOpen = (nextStatus, routeId, orderId) => {
    let status = setStatus(nextStatus);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUI}>
            <img src={Phyramid} className="phyramid-img" />
            <p
              style={{
                fontSize: '20px',
                fontWeight: 600,
                paddingBottom: '14px'
              }}
            >
              Are you sure you want to change the status to{' '}
              {nextStatus
                ? status?.text
                : 'Completed or Partially completed closed'}
            </p>
            <button className={classes.customButtonCancel} onClick={onClose}>
              NO
            </button>
            <button
              className={classes.customButton}
              onClick={() => {
                updateStatus(nextStatus, routeId, orderId);
                onClose();
              }}
            >
              YES
            </button>
          </div>
        );
      },
      overlayClassName: {}
    });
  };

  const routeStatusArray = Object.keys(routeStatus);
  const orderstatusArray = Object.keys(orderStatus);

  const routeToolTipMessage = (route) => {
    if (!route) return;
    const { Driver, Vehicle, RouteOrderGroups, status } = route;
    const routeIndex = routeStatusArray?.indexOf(status);
    const sortedOrder = RouteOrderGroups?.sort(
      (a, b) => a?.sequenceNumber - b?.sequenceNumber
    ).map((order) => order.Order);
    const orderModified = sortedOrder.find(
      (order) =>
        !['COMPLETED', 'PARTIALLY_COMPLETED_CLOSED'].includes(order?.status)
    );

    const inwardOrderType = sortedOrder.find(
      (order) =>
        order?.moveType === 'INWARD' &&
        [
          'LOADING_COMPLETE',
          'COMPLETED',
          'DOCK_ASSIGNED',
          'PARTIALLY_COMPLETED_CLOSED'
        ].includes(order?.status)
    );

    if (inwardOrderType) {
      if (status === 'ON_THE_WAY_DESTINATION') {
        return {
          message: 'change the status of route to Arrived at Destination',
          nextStatus: 'ARRIVED_DESTINATION'
        };
      } else if (status === 'ARRIVED_DESTINATION') {
        return {
          message: 'change the status of route to Complete',
          nextStatus: 'COMPLETED'
        };
      }
    }
    if (status === 'CANCELLED')
      return {
        message: 'Rescheduled the status',
        nextStatus: 'SCHEDULED'
      };

    if (!Driver?.Name && !Vehicle?.registrationNumber)
      return {
        message: 'Click on edit route to assign Vehicle and Driver',
        nextStatus: 'SCHEDULED',
        disable: true
      };

    if (status && !orderStatus[status] && routeStatus[status]) {
      const nextStatus = routeStatusArray[routeIndex + 1];
      return {
        message: `Change status to ${routeStatus[nextStatus]}`,
        nextStatus
      };
    }

    if (routeIndex > 3) {
      if (
        status &&
        orderStatus[status] &&
        routeStatus[status] &&
        routeIndex < 6
      ) {
        const nextStatus = routeStatusArray[routeIndex + 1];
        return {
          message: `change route status to ${routeStatus[nextStatus]}`,
          nextStatus
        };
      }

      if (orderModified) {
        const nextStatus =
          orderstatusArray[orderstatusArray.indexOf(orderModified.status) + 1];
        const index = sortedOrder.findIndex(
          (order) => order?.id === orderModified?.id
        );
        return {
          message: `Change status of dropoff ${index + 1} to ${
            orderStatus[nextStatus]
          }`,
          nextStatus,
          orderId: orderModified?.id
        };
      }
    }

    return {};
  };

  const getOrderData = async () => {
    setRouteData([]);
    setMuiLoader(true);
    let colVal = applyFilter();
    let filters = { colVal };
    let columns = [
      'id',
      'status',
      '$Driver.name$',
      '$Company.name$',
      '$Vehicle.registrationNumber$',
      '$Vehicle->Car->CarMake.name$',
      '$Vehicle->Car->CarModel.name$'
    ];
    if (colVal['$RouteOrderGroups->Order->pickupNode.name$']) {
      columns.push(
        '$RouteOrderGroups->Order->pickupNode.name$',
        '$RouteOrderGroups->Order->warehousePickUp.businessWarehouseCode$'
      );
      setSearchValue(colVal['$RouteOrderGroups->Order->pickupNode.name$']);
      delete colVal['$RouteOrderGroups->Order->pickupNode.name$'];
      delete colVal[
        '$RouteOrderGroups->Order->warehousePickUp.businessWarehouseCode$'
      ];
    } else if (colVal['$RouteOrderGroups->Order->dropOffNode.name$']) {
      columns.push(
        '$RouteOrderGroups->Order->dropOffNode.name$',
        '$RouteOrderGroups->Order->warehouseDropOff.businessWarehouseCode$'
      );
      setSearchValue(colVal['$RouteOrderGroups->Order->dropOffNode.name$']);
      delete colVal['$RouteOrderGroups->Order->dropOffNode.name$'];
      delete colVal[
        '$RouteOrderGroups->Order->warehouseDropOff.businessWarehouseCode$'
      ];
    } else {
      columns.push(
        '$RouteOrderGroups->Order->pickupNode.name$',
        '$RouteOrderGroups->Order->dropOffNode.name$',
        '$RouteOrderGroups->Order->warehouseDropOff.businessWarehouseCode$',
        '$RouteOrderGroups->Order->warehousePickUp.businessWarehouseCode$'
      );
    }

    if (
      routeIdVal ||
      companyVal ||
      originVal ||
      dropOffVal ||
      registrationNumberVal
    ) {
      setFilter(true);
    } else {
      setFilter(false);
    }
    try {
      let res = await API.get('routes', {
        params: { page, filters, search: searchValue, columns }
      });
      setPageCount(res.pages);
      setRouteData(res?.data || []);
    } catch (err) {
      onError(err);
    } finally {
      setFetch(false);
      setMuiLoader(false);
    }
  };

  const routeToolTipMessageOutbound = (route) => {
    if (!route) return;
    const { Driver, Vehicle, RouteOrderGroups, status } = route;
    const sortedOrder = RouteOrderGroups?.sort(
      (a, b) => a?.sequenceNumber - b?.sequenceNumber
    ).map((order) => order.Order);
    const orderModified = sortedOrder.find(
      (order) =>
        !['COMPLETED', 'PARTIALLY_COMPLETED_CLOSED'].includes(order?.status)
    );

    const outboundOrderType = sortedOrder.find(
      (order) =>
        order?.moveType === 'OUTBOUND' &&
        !['COMPLETED', 'PARTIALLY_COMPLETED_CLOSED'].includes(order?.status)
    );

    if (status === 'ARRIVED_DESTINATION') {
      const checkCompletedOrders = sortedOrder.every((order) => {
        return ['COMPLETED', 'PARTIALLY_COMPLETED_CLOSED'].includes(
          order?.status
        );
      });
      if (checkCompletedOrders) {
        return {
          message: 'change the status of route to Complete',
          nextStatus: 'COMPLETED'
        };
      }
    }

    if (status === 'ARRIVED_PICKUP') {
      const arrivedToOnTheWay = sortedOrder.every((order) =>
        ['LOAD_DISPATCHED'].includes(order?.status)
      );
      if (arrivedToOnTheWay) {
        return {
          message: 'change the status of route to on the way to Destination',
          nextStatus: 'ON_THE_WAY_DESTINATION'
        };
      }
    }

    if (outboundOrderType) {
      if (status === 'ON_THE_WAY_DESTINATION') {
        return {
          message: 'change the status of route to Arrived at Destination',
          nextStatus: 'ARRIVED_DESTINATION'
        };
      }
    }

    if (status === 'CANCELLED')
      return {
        message: 'Rescheduled the status',
        nextStatus: 'SCHEDULED'
      };

    if (!Driver?.Name && !Vehicle?.registrationNumber)
      return {
        message: 'Click on edit route to assign Vehicle and Driver',
        nextStatus: 'SCHEDULED',
        disable: true
      };
    const currentRouteStatus = { ...routeStatus, ...routeStatus2 };
    const routeStatusArrayOutbound = Object.keys(currentRouteStatus);
    const routeIndex = routeStatusArrayOutbound?.indexOf(status);
    if (status && !orderStatus[status] && currentRouteStatus[status]) {
      const nextStatus = routeStatusArrayOutbound[routeIndex + 1];
      return {
        message: `Change status to ${currentRouteStatus[nextStatus]}`,
        nextStatus
      };
    }

    if (routeIndex > 3) {
      if (
        status &&
        orderStatus[status] &&
        currentRouteStatus[status] &&
        routeIndex < 6
      ) {
        const nextStatus = routeStatusArrayOutbound[routeIndex + 1];
        return {
          message: `change route status to ${currentRouteStatus[nextStatus]}`,
          nextStatus
        };
      }

      if (orderModified) {
        const nextStatus =
          orderstatusArray[
            orderstatusArray.indexOf(
              orderModified.status === 'LOAD_DISPATCHED'
                ? 'ARRIVED_DESTINATION'
                : orderModified.status
            ) + 1
          ];
        const index = sortedOrder.findIndex(
          (order) => order?.id === orderModified?.id
        );
        return {
          message: `Change status of dropoff ${index + 1} to ${
            orderStatus[nextStatus]
          }`,
          nextStatus,
          orderId: orderModified?.id
        };
      }
    }

    return {};
  };

  const applyFilter = () => {
    let colVal = {};
    if (currentFilter && currentFilter === 'ALL') {
      colVal.status = '';
    } else if (currentFilter) {
      colVal.status = currentFilter;
    }
    if (routeIdVal) {
      colVal.id = routeIdVal;
    }
    if (companyVal) {
      colVal['$Company.name$'] = companyVal;
    }
    if (originVal) {
      colVal['$RouteOrderGroups->Order->pickupNode.name$'] = originVal;
      colVal[
        '$RouteOrderGroups->Order->warehousePickUp.businessWarehouseCode$'
      ] = originVal;
    }
    if (dropOffVal) {
      colVal['$RouteOrderGroups->Order->dropOffNode.name$'] = dropOffVal;
      colVal[
        '$RouteOrderGroups->Order->warehouseDropOff.businessWarehouseCode$'
      ] = dropOffVal;
    }
    if (registrationNumberVal) {
      colVal['$Vehicle.registrationNumber$'] = registrationNumberVal;
    }
    return colVal;
  };

  const getRouteStats = async () => {
    try {
      let res = await API.get('routes/stats');
      setRouteStats(res?.data);
    } catch (err) {
      onError(err);
    }
  };

  const updateStatus = async (status, routeId, orderId) => {
    try {
      await API.put(
        `routes/route/${routeId}/order/${orderId}/status/${status}`
      );
      toaster('success', 'Status has been updated');
      getRouteStats();
      getOrderData();
    } catch (err) {
      onError(err);
    }
  };

  const copyToClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toaster('success', 'Driver number is copied to clipboard');
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    getOrderData();
  }, [currentFilter]);

  const updateBillingStatus = async (routeId, billingStatus) => {
    const billKey = Object.keys(routeBillingStatus).find(
      (key) => routeBillingStatus[key] === billingStatus
    );
    try {
      await API.put(`routes/${routeId}/billing-status/${billKey}`);
      toaster('success', 'Billing status updated');
      getOrderData();
    } catch (err) {
      onError(err);
    }
  };

  const RouteManagementColumns = [
    {
      field: 'id',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Route #" placement="center">
            <p className="w-100 routeheader">
              Route #
              {routeIdVal ? (
                <>
                  <button
                    className="customHeaderIcon float-right"
                    onClick={() => {
                      setRouteIdVal('');
                      setFetch(true);
                    }}
                  >
                    <MdFilterAlt />
                  </button>
                </>
              ) : (
                ''
              )}
            </p>
          </Tooltip>
        );
      },
      renderCell: (params) => {
        const order = params?.row?.RouteOrderGroups.find(
          (routeOrder) => routeOrder?.Order?.moveType
        );

        return (
          <button
            onClick={() => {
              setRouteId(params?.row?.id);
              setCompanyId(params?.row?.Company?.id);
              setDetailViewOpen(true);
            }}
            className="detailLink cursor-pointer"
          >
            <Tooltip title={`${params.row.id}`}>
              <span className="table-cell-trucate">
                <MoveTypeIcon type={order?.Order?.moveType} /> {params.row.id}
              </span>
            </Tooltip>
          </button>
        );
      }
    },
    {
      field: 'dateOfRoute',
      headerName: 'Date Of The Route',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Date Of The Route" placement="center">
            <p className="w-100 parentActions routeheader">Date Of The Route</p>
          </Tooltip>
        );
      },
      renderCell: (params) => (
        <Tooltip
          title={`${dividerDateFormatForFilter(params?.row?.createdAt)}`}
        >
          <span className="table-cell-trucate">
            {dividerDateFormatForFilter(params?.row?.createdAt)}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Company" placement="center">
            <p className="w-100 parentActions routeheader">
              Company
              {companyVal ? (
                <>
                  <button
                    className="customHeaderIcon float-right"
                    onClick={() => {
                      setCompanyVal('');
                      setFetch(true);
                    }}
                  >
                    <MdFilterAlt />
                  </button>
                </>
              ) : (
                ''
              )}
            </p>
          </Tooltip>
        );
      },
      renderCell: (params) => (
        <Tooltip title={`${params?.row?.Company?.name}`}>
          <span className="table-cell-trucate">
            {params?.row?.Company?.name}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Vehicle" placement="center">
            <p className="w-100 parentActions routeheader">Vehicle</p>
          </Tooltip>
        );
      },
      renderCell: (params) => (
        <Tooltip
          title={`${params?.row?.Vehicle?.Car?.CarMake?.name} ${params?.row?.Vehicle?.Car?.CarModel?.name}`}
        >
          <span className="table-cell-trucate">
            {params?.row?.Vehicle?.Car?.CarMake?.name}{' '}
            {params?.row?.Vehicle?.Car?.CarModel?.name}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'vehicleNumber',
      headerName: 'Vehicle Number',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Vehicle Number" placement="center">
            <p className="w-100 parentActions routeheader">
              Vehicle Number
              {registrationNumberVal ? (
                <>
                  <button
                    className="customHeaderIcon float-right"
                    onClick={() => {
                      setRegistrationNumberVal('');
                      setFetch(true);
                    }}
                  >
                    <MdFilterAlt />
                  </button>
                </>
              ) : (
                ''
              )}
            </p>
          </Tooltip>
        );
      },
      renderCell: (params) => (
        <Tooltip
          title={`${params?.row?.Vehicle?.registrationNumber} ${params?.row?.Vehicle?.Car?.CarModel?.name}`}
        >
          <span className="table-cell-trucate">
            {params?.row?.Vehicle?.registrationNumber}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'driver',
      headerName: 'Driver',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Driver" placement="center">
            <p className="w-100 parentActions routeheader">Driver</p>
          </Tooltip>
        );
      },
      renderCell: (params) => {
        return (
          <>
            {(params?.row?.driverPhone || params?.row?.Driver?.phone) && (
              <Tooltip
                title={params?.row?.driverPhone || params?.row?.Driver?.phone}
              >
                <a
                  href={`tel:${
                    params?.row?.driverPhone || params?.row?.Driver?.phone
                  }`}
                >
                  <img
                    src={PhoneIcon}
                    onClick={() =>
                      copyToClipBoard(
                        params?.row?.driverPhone || params?.row?.Driver?.phone
                      )
                    }
                    alt="Driver Icon"
                  />
                </a>
              </Tooltip>
            )}

            <Tooltip title={`${params?.row?.Driver?.name}`}>
              <span className="table-cell-trucate display-flex">
                <p className="ml-3">{params?.row?.Driver?.name}</p>
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'origin',
      headerName: 'Origin',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Origin" placement="center">
            <p className="w-100 parentActions routeheader">Origin</p>
          </Tooltip>
        );
      },
      renderCell: (params) => {
        const route = params?.row;
        const { RouteOrderGroups } = route;
        const findPickup = RouteOrderGroups?.sort(
          (a, b) => a?.sequenceNumber - b?.sequenceNumber
        )
          .map((order) => order.Order)
          .find((order) => order?.status !== 'COMPLETED');

        const nextPickup =
          findPickup?.pickupNode?.name ||
          findPickup?.warehousePickUp?.businessWarehouseCode ||
          RouteOrderGroups[RouteOrderGroups?.length - 1]?.Order?.pickupNode
            ?.name ||
          RouteOrderGroups[RouteOrderGroups?.length - 1]?.Order?.warehousePickUp
            ?.businessWarehouseCode ||
          '-';

        return (
          <Tooltip title={nextPickup}>
            <span className="table-cell-trucate">{nextPickup}</span>
          </Tooltip>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.9,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Status" placement="center">
            <p className="w-100 parentActions routeheader">
              Status
              <button
                type="button"
                className="customHeaderIcon display-sortable"
              >
                <MdOutlineSouth />
              </button>
            </p>
          </Tooltip>
        );
      },
      renderCell: (params) => {
        const outbound = params?.row?.RouteOrderGroups.find(
          (routeOrder) => routeOrder?.Order?.moveType === 'OUTBOUND'
        );

        const { message, nextStatus, orderId, disable } = outbound
          ? routeToolTipMessageOutbound(params?.row)
          : routeToolTipMessage(params?.row);
        let routeStatusText =
          routeStatus[params?.row?.status] || routeStatus2[params?.row?.status];
        let status = setStatus(params?.row?.status);
        return (
          <>
            <Tooltip
              title={params?.row?.RouteOrderGroups.sort(
                (a, b) => a.sequenceNumber - b.sequenceNumber
              )
                .map(
                  (routeOrder, idx) =>
                    `DropOff ${idx + 1} ${
                      orderStatus[routeOrder?.Order?.status]
                    }`
                )
                .join('\n')}
            >
              <div className="w-150px statusRoute">
                <p className={status?.color}>
                  <b>
                    {routeStatusText} {'  '}
                  </b>
                </p>
              </div>
            </Tooltip>
            {!['COMPLETED', 'PARTIALLY_COMPLETED_CLOSED'].includes(
              params?.row?.status
            ) &&
            (params?.row?.RouteOrderGroups.find(
              (routeOrder) => routeOrder?.Order?.moveType === 'LOGISTIC'
            ) ||
              params?.row?.RouteOrderGroups.find((routeOrder) => {
                if (routeOrder?.Order?.moveType === 'INWARD') {
                  if (params?.row?.status === 'ON_THE_WAY_DESTINATION') {
                    return true;
                  } else if (params?.row?.status === 'ARRIVED_DESTINATION') {
                    return params?.row?.RouteOrderGroups.every((order) =>
                      ['COMPLETED', 'PARTIALLY_COMPLETED_CLOSED'].includes(
                        order?.Order?.status
                      )
                    );
                  }

                  return (
                    ![
                      'ON_THE_WAY_DESTINATION',
                      'COMPLETED',
                      'PARTIALLY_COMPLETED_CLOSED',
                      'ARRIVED_DESTINATION'
                    ].includes(routeOrder?.Order?.status) &&
                    params?.row?.status !== 'ON_THE_WAY_DESTINATION'
                  );
                }
              }) ||
              params?.row?.RouteOrderGroups.find((routeOrder) => {
                if (routeOrder?.Order?.moveType === 'OUTBOUND') {
                  if (params?.row?.status === 'ARRIVED_PICKUP') {
                    return params?.row?.RouteOrderGroups.every(
                      (order) =>
                        ['LOAD_DISPATCHED'].includes(order?.Order?.status) &&
                        order?.Order?.moveType === 'OUTBOUND'
                    );
                  }
                  return !['COMPLETED'].includes(params?.row?.status);
                }
              })) ? (
              <Tooltip title={`${message}`}>
                <span className={status?.color}>
                  {'  '}{' '}
                  <p className="routeManagementStatus" id={status?.color}>
                    <AiOutlineRight
                      onClick={() =>
                        disable
                          ? ''
                          : dialogueConfirmationOpen(
                              nextStatus,
                              params?.row?.id,
                              orderId
                            )
                      }
                    />
                  </p>
                </span>
              </Tooltip>
            ) : (
              ''
            )}
          </>
        );
      }
    },
    {
      field: 'dropOff',
      headerName: 'Destination',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Destination" placement="center">
            <p className="w-100 parentActions routeheader">
              Destination
              {dropOffVal ? (
                <>
                  <button
                    className="customHeaderIcon float-right"
                    onClick={() => {
                      setDropOffVal('');
                      setFetch(true);
                    }}
                  >
                    <MdFilterAlt />
                  </button>
                </>
              ) : (
                ''
              )}
            </p>
          </Tooltip>
        );
      },
      renderCell: (params) => {
        const route = params?.row;
        const { RouteOrderGroups } = route;
        const findDestaination = RouteOrderGroups?.sort(
          (a, b) => a?.sequenceNumber - b?.sequenceNumber
        )
          .map((order) => order.Order)
          .find((order) => order?.status !== 'COMPLETED');

        const nextDestination =
          findDestaination?.dropOffNode?.name ||
          findDestaination?.warehouseDropOff?.businessWarehouseCode ||
          RouteOrderGroups[RouteOrderGroups?.length - 1]?.Order?.dropOffNode
            ?.name ||
          RouteOrderGroups[RouteOrderGroups?.length - 1]?.Order
            ?.warehouseDropOff?.businessWarehouseCode ||
          '-';

        return (
          <Tooltip title={`${nextDestination}`}>
            <span className="table-cell-trucate">{nextDestination}</span>
          </Tooltip>
        );
      }
    },
    {
      field: 'billingStatus',
      headerName: 'Billing Status',
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Billing Status" placement="center">
            <p className="w-100 parentActions routeheader">
              Billing Status
              <button
                type="button"
                className="customHeaderIcon display-sortable"
              >
                <MdOutlineSouth />
              </button>
            </p>
          </Tooltip>
        );
      },
      renderCell: (params) => {
        const route = params?.row;
        const { id, status, billingStatus } = route;
        return status === 'COMPLETED' ? (
          <FormControl className={classes.margin} id="customEditSelect">
            <InputLabel id="demo-simple-select-label">Select status</InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              variant="outlined"
              label="Select status"
              value={
                afterCompleteRouteBillingStatus[billingStatus] ||
                Object.values(afterCompleteRouteBillingStatus)[0]
              }
              onChange={async (e) => {
                e.stopPropagation();
                e?.target?.value
                  ? await updateBillingStatus(id, e.target.value)
                  : null;
              }}
            >
              {Object.values(afterCompleteRouteBillingStatus).map(
                (d, index) => (
                  <MenuItem key={index} value={d}>
                    {d}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        ) : (
          <Tooltip title={`${routeBillingStatus[billingStatus]}`}>
            <span className="table-cell-trucate orangeText">
              <b>{routeBillingStatus[billingStatus]}</b>
            </span>
          </Tooltip>
        );
      }
    }
  ];

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'Route Management';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    getOrderData();
    getRouteStats();
  }, [page, searchValue, fetch]);

  const openFilterView = () => {
    setFilterView(true);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const clearFilter = () => {
    setRouteIdVal('');
    setCompanyVal('');
    setOriginVal('');
    setDropOffVal('');
    setRegistrationNumberVal('');
    setFetch(true);
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const routeIDObj = {
    type: 'text',
    className: 'textField',
    label: 'Route ID',
    field: 'id',
    value: routeIdVal ? routeIdVal : '',
    setNameVal: (e) => setNameVal(setRouteIdVal, e)
  };

  const companyObj = {
    type: 'text',
    className: 'textField',
    label: 'Select Company',
    field: 'company.name',
    value: companyVal ? companyVal : '',
    setNameVal: (e) => setNameVal(setCompanyVal, e)
  };

  const originObj = {
    type: 'text',
    className: 'textField',
    label: 'Select Origin',
    field: 'pickupNode.name',
    value: originVal ? originVal : '',
    setNameVal: (e) => setNameVal(setOriginVal, e)
  };

  const dropOffObj = {
    type: 'text',
    className: 'textField',
    label: 'Select Drop Off',
    field: 'dropOppNode.name',
    value: dropOffVal ? dropOffVal : '',
    setNameVal: (e) => setNameVal(setDropOffVal, e)
  };

  const vehicleObj = {
    type: 'text',
    className: 'textField',
    label: 'Vehicle Number',
    field: 'vehicleNumber',
    value: registrationNumberVal ? registrationNumberVal : '',
    setNameVal: (e) => setNameVal(setRegistrationNumberVal, e)
  };

  const filterCols = [
    routeIDObj,
    companyObj,
    originObj,
    dropOffObj,
    vehicleObj
  ];

  const filterModal = (
    <div className={filterView ? 'customFilterViewSec' : 'display-none'}>
      <CustomFilter
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        clearFilter={clearFilter}
        applyFilter={getOrderData}
        handleClose={() => closeFilter()}
        columns={filterCols}
        className="headerFilters"
      />
    </div>
  );

  if (checkPermission(currentUser, 'OPS_ROUTE_UPDATE')) {
    RouteManagementColumns.push({
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'driverDatatableActions',
      cellClassName: 'justify-content-right',
      renderHeader: () => {
        return (
          <Tooltip title="Actions" placement="center">
            <p className="w-100 parentActions routeheader">Actions</p>
          </Tooltip>
        );
      },
      renderCell: (params) => (
        <>
          {params?.row?.status == 'NOT_ASSIGNED' ||
          params?.row?.status == 'SCHEDULED' ? (
            <img
              key={params}
              className="cursor-pointer mr-3"
              onClick={() =>
                navigate(
                  `/routing/add-route/${params?.row?.Company?.id}/${params?.row?.id}`
                )
              }
              src={EditSvg}
              alt="Edit icon"
            />
          ) : (
            ''
          )}
          <img
            key={params}
            className="cursor-pointer"
            src={SafariSvg}
            alt="Map icon"
            onClick={() => {
              setRouteId(params?.row?.id);
              setCompanyId(params?.row?.Company?.id);
              setRouteView(true);
            }}
          />
        </>
      )
    });
  }

  return (
    <Paper className={classes.root}>
      <Grid className={classes.orderManagementContainer}>
        <TableContainer className={classes.container}>
          <RouteManagementDetail
            routeId={routeId}
            companyId={companyId}
            open={detailViewOpen}
            setOrderId={(value) => setOrderId(value)}
            setOrderDetailViewOpen={(value) => setOrderDetailViewOpen(value)}
            handleClose={(value) => setDetailViewOpen(value)}
          />
          <OrderManagementDetail
            orderId={orderId}
            companyId={companyId}
            open={orderDetailViewOpen}
            handleClose={() => setOrderDetailViewOpen(false)}
          />
          <RoutesMap
            routeId={routeId}
            companyId={companyId}
            open={routeView}
            handleClose={() => setRouteView(false)}
          />

          <Grid item container className="routeSearch">
            <Grid item xs={12} sm={6} md={6} lg={6} className="text-left">
              <SearchBar
                setSearchValue={(e) => {
                  setPage(1);
                  setSearchValue(e);
                }}
                setFilterView={() => openFilterView()}
                filter={filter}
                className="searchWithFilter"
              />
              <div className="position-relative">{filterModal}</div>
            </Grid>
            {checkPermission(currentUser, 'OPS_ROUTE_CREATE') && (
              <Grid item xs={12} sm={6} md={6} lg={6} className="text-right">
                <AddBtn text="CREATE ROUTE" url="/routing/add-route" />
              </Grid>
            )}
          </Grid>

          <Grid className="orderManagementStatuses statuses ">
            <ListingTitle text="Statuses :" />
            <OrderManagementStats
              stats={routeStats}
              setCurrentFilter={(value) => setCurrentFilter(value)}
              currentFilter={currentFilter}
            />
          </Grid>
          <Grid className="scroll-RouteListingtable">
            <Table aria-label="sticky table">
              <TableBody>
                <div style={{ height: 1150, width: '100%' }}>
                  <DataGrid
                    rows={routeData}
                    hiddenColumnModel={['id']}
                    columns={RouteManagementColumns}
                    pageSize={20}
                    rowsPerPageOptions={[6]}
                    loading={muiLoader}
                    className="parentDataTable"
                  />
                </div>
              </TableBody>
            </Table>
          </Grid>
        </TableContainer>
        <Grid container justify="space-between">
          <Grid item></Grid>
          <Grid item>
            <Pagination
              component="div"
              shape="rounded"
              count={pageCount}
              color="primary"
              page={page}
              className={classes.pagination}
              onChange={(e, page) => {
                setPage(page);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

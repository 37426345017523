import { useRef } from 'react';
import Button from '../../../core-components/atoms/Button';
import PaperClipIcon from '../../../assets/icons/paperClipIcon.svg';
import CloudUploadIcon from '../../../assets/icons/cloudUploadIcon.svg';
import { UploadIconType } from './constants';

export const FileUploadButton = ({
  formik,
  propertyName,
  iconType = UploadIconType.ATTACHMENT,
  label = 'Attach'
}) => {
  const inputRef = useRef(null);

  const uploadFiles = async (event) => {
    const newFiles = event.target.files[0];
    formik?.setFieldValue(propertyName, newFiles);
  };

  return (
    <>
      <Button
        variant="transparent"
        size="medium"
        label={label}
        className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
        overrideSize={true}
        labelClass="font-medium text-xs "
        iconClass="h-[14px] w-[14px] mr-2"
        onClick={() => inputRef.current.click()}
        icon={
          <img
            className="mr-2"
            src={
              iconType === UploadIconType.ATTACHMENT
                ? PaperClipIcon
                : CloudUploadIcon
            }
            alt="Paperclip Icon"
          />
        }
      />
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={(e) => uploadFiles(e)}
        accept=".jpg,.png,.jpeg,.pdf,.xlsx"
        multiple
      />
    </>
  );
};

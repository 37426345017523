import React from 'react';

const GraphSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div
        className="relative mt-4 rounded overflow-hidden bg-gray-200"
        style={{ height: '240px' }}
      >
        <div className="absolute top-1/4 left-0 right-0 border-t border-gray-300"></div>
        <div className="absolute top-1/2 left-0 right-0 border-t border-gray-300"></div>
        <div className="absolute top-3/4 left-0 right-0 border-t border-gray-300"></div>

        <div className="absolute top-0 bottom-0 left-1/4 border-l border-gray-300"></div>
        <div className="absolute top-0 bottom-0 left-1/2 border-l border-gray-300"></div>

        <div className="absolute top-1/3 left-1/4 w-3 h-3 bg-oasisPrimary500 rounded-full"></div>
        <div className="absolute top-1/2 left-1/2 w-3 h-3 bg-oasisPrimary500 rounded-full"></div>
        <div className="absolute top-2/3 left-3/4 w-3 h-3 bg-oasisPrimary500 rounded-full"></div>
      </div>
    </div>
  );
};

export default GraphSkeleton;

import { makeStyles } from '@material-ui/core';

const reportingStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInput: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  textFieldRange: {
    padding: 0,
    marginRight: 5
  },
  formControl: {
    minWidth: 160,
    boxSizing: 'border-box',
    marginRight: 10
  },
  placeholderText: {
    color: '#CAC9C9',
    '& .MuiSelect-outlined': {
      paddingTop: '7px',
      paddingBottom: '6px'
    }
  },
  dropdownListItem: {
    fontSize: 14
  },
  inputAdronmentStyle: {
    '& .MuiInputAdornment-positionStart': {
      margin: '0',
      padding: '0',
      backgroundColor: 'green'
    },
    '& .MuiInputAdornment-root': {
      margin: '0',
      padding: '0',
      backgroundColor: 'green'
    }
  }
}));

export default reportingStyles;

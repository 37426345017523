import { useContext, useEffect } from 'react';
import { SharedContext } from '../../utils/common';
import ExceptionsView from './ExceptionsView';
import SupplyChainView from './SupplyChain/SupplyChainView';
import CalendarView from './calender';
import CommentBoard from './CommentBoard';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';

const getTimeOfDay = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
};

const UnifiedDashboardView = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { setCurrentPageTitle, currentUser, dashboardSelected } =
    useContext(SharedContext);

  useEffect(() => {
    setCurrentPageTitle(`Good ${getTimeOfDay()}, ${currentUser.firstName}`);
  });
  return (
    <div>
      {dashboardSelected === 1 ? (
        <div className="p-container">
          {isFeatureEnabled(FLAGS.SUPPLY_CHAIN_NETWORK_MAP) && (
            <div className="flex border-t border-l-0 border-r-0 border-b-0 border-solid border-primaryBlue">
              <SupplyChainView />
            </div>
          )}
          <div className="flex border-t border-l-0 border-r-0 border-b-0 border-solid border-primaryBlue">
            <ExceptionsView />
            <CalendarView />
          </div>
        </div>
      ) : (
        <div className={'mx-8 my-4'}>
          <CommentBoard />
        </div>
      )}
    </div>
  );
};

export default UnifiedDashboardView;

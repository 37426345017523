import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const BarSkeleton = () => {
  const skeletonRows = 3;
  return (
    <ul className={'mt-4'}>
      {Array.from(new Array(skeletonRows)).map((_, index) => (
        <li key={index} className="mb-4">
          <div className={'flex justify-between'}>
            <Skeleton variant="text" width={100} height={24} />
            <Skeleton variant="text" width={60} height={24} />
          </div>
          <Skeleton
            variant="rectangular"
            height={10}
            className="flex-grow bg-dark200 rounded-sm mt-1"
          />
        </li>
      ))}
    </ul>
  );
};

export default BarSkeleton;

import { makeStyles } from '@material-ui/core';

const warehouseStyles = makeStyles((theme) => ({
  root: {
    width: '95%',
    margin: '40px auto 20px auto',
    paddingTop: '20px',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      margin: '0px auto 20px auto'
    }
  },

  addWarehouseContainer: {
    marginTop: '20px !important',
    marginBottom: '40px !important'
  },

  googleMap: {
    position: 'relative',
    minHeight: 300
  },

  textArea: {
    marginTop: '10px',
    borderRadius: '8px',
    padding: '10px',
    border: '1px solid #EDF3FA',
    background: '#EDF3FA'
  },

  listGridContainer: {
    height: '589px !important',
    marginTop: '25px'
  },

  pointer: {
    cursor: 'pointer !important'
  },

  active: {
    color: '#66bb6a !important'
  },

  inactive: {
    color: '#f44336 !important'
  },

  warehouseAddBtn: {
    height: '32px',
    maxWidth: '291px',
    border: '1px solid #4B89C8 !important',
    borderRadius: '8px',
    padding: '7px, 32px, 7px, 26px',
    background: '#4B89C8',
    color: 'white',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    float: 'right',
    width: '100%',
    '@media only screen and (max-width: 1400px) and (min-width:1200px)': {
      marginRight: '14px !important'
    },
    '@media only screen and (max-width: 1200px) and (min-width:993px)': {
      width: '80% !important',
      marginRight: '14px !important'
    }
  },

  docksParentContainer: {
    width: '597px',
    height: 'fit-content',
    margin: 'auto',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '100%'
    }
  },

  docksAddBtn: {
    height: '32px',
    width: '137px',
    border: '1px solid #4B89C8 !important',
    borderRadius: '8px',
    padding: '7px, 32px, 7px, 26px',
    background: '#4B89C8',
    color: 'white',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    float: 'right',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      float: 'left',
      width: '100%',
      marginTop: '6px'
    }
  },

  docksDataGridParent: {
    width: '597px',
    justifyContent: 'center',
    margin: 'auto',
    height: '325px',
    border: '1px solid #C1D6EC !important',
    borderRadius: '8px',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '280px'
    }
  },

  DockDataGridHead: {
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '100% !important',
      margin: '10px 0px !important',
      display: 'flex !important',
      rowGap: '12px !important',
      flexDirection: 'column !important',
      justifyContent: 'space-between !important'
    }
  },
  docksEditBtn: {
    cursor: 'pointer',
    marginRight: '10px'
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },

  backdropGrid: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black',
    marginTop: '16px',
    marginBottom: '16px'
  },

  dockInputField: {
    marginTop: '14px',
    marginBottom: '14px',
    width: '100%',
    height: '45px',
    background: '#FFFFFF',
    border: '1.5px solid #C1D6EC',
    borderRadius: '8px',
    padding: '0px 10px'
  },

  cancelBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '95%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #D9DFE5',
    background: '#D9DFE5',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#979B9F'
  },

  submitBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '95%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #4B89C8',
    background: '#4B89C8',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#FFFFFF',
    float: 'right',
    '&:hover': {
      background: '#D9DFE5'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      float: 'left !important'
    }
  },

  sectionContainer: {
    padding: 20,
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: 0
    }
  },

  pagination: {
    border: 'none',
    display: 'block',
    alignItems: 'right',
    margin: '5px'
  },

  sectionsDataGridParent: {
    height: '325px',
    border: '1px solid #C1D6EC !important',
    borderRadius: '8px'
  },

  sectionsDataGridHead: {
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px auto',
    '@media only screen and (max-width: 1200px) and (min-width:992px)': {
      width: '100% !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:575px)': {
      width: '100% !important'
    },
    '@media only screen and (max-width: 575px) and (min-width:360px)': {
      width: '100% !important',
      margin: '10px 0px !important',
      display: 'flex !important',
      justifyContent: 'space-between !important',
      flexDirection: 'column !important',
      rowGap: '14px !important'
    }
  },

  sectionBox: {
    width: '70%',
    justifyContent: 'center',
    margin: 'auto',
    '@media only screen and (max-width: 1200px) and (min-width:992px)': {
      width: '100% !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:575px)': {
      width: '100% !important'
    },
    '@media only screen and (max-width: 575px) and (min-width:360px)': {
      width: '100% !important'
    }
  },

  sectionsBtn: {
    height: '32px',
    width: '137px',
    marginLeft: '10px',
    border: '1px solid #4B89C8 !important',
    borderRadius: '8px',
    padding: '7px, 32px, 7px, 26px',
    background: '#4B89C8',
    color: 'white',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    float: 'right',
    '@media only screen and (max-width: 575px) and (min-width:360px)': {
      marginLeft: '4px !important',
      width: '100%'
    }
  },

  sectionsEditBtn: {
    cursor: 'pointer',
    marginRight: '10px'
  },

  textBox: {
    disableUnderline: true,
    borderBottom: 'none'
  },

  title: {
    fontWeight: 600,
    fontSize: '24px'
  },

  uploadDetails: {
    boxSizing: 'border-box',
    padding: 20,
    height: '10%'
  },

  textFieldBox: {
    marginTop: '10px',
    border: '1px solid rgba(15, 68, 122, 0.5)',
    borderRadius: '8px',
    padding: '0 10px',
    disableUnderline: true,
    height: '45px'
  },
  hallCancelBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '95%',
    height: '32px',
    border: '1px solid #D9DFE5',
    background: '#D9DFE5',
    borderRadius: '8px'
  },

  hallSubmitBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '95%',
    height: '32px',
    border: '1px solid #4B89C8',
    background: '#4B89C8',
    borderRadius: '8px',
    color: '#FFFFFF'
  },
  dockDialog: {
    padding: '10px 18px 0px !important'
  },
  btnGroup: {
    width: '95%',
    margin: '0 auto'
  },
  sectionHeading: {
    padding: '8px 24px'
  },
  attachBtn: {
    marginLeft: '10px'
  }
}));

export default warehouseStyles;

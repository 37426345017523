import React from 'react';
import { Label, Paragraph } from '../../../core-components/atoms/Text';
import moment from 'moment';

export const TaskDetailStats = ({ taskStats }) => {
  let statsBoxClass =
    'pt-6 pb-6 pr-8 pl-8 bg-white rounded border border-[#E1E5F0]';
  return (
    <div className="pt-4 pb-4 pr-10 pl-10 flex gap-2">
      <div className={statsBoxClass}>
        <Label variant={'xs'} className="text-[#6A7289] mb-4">
          CREATION DATE
        </Label>
        <Paragraph variant={'sm'}>
          {moment(taskStats?.createdAt).format('DD/MM/YY')}
        </Paragraph>
      </div>
      <div className={statsBoxClass}>
        <Label variant={'xs'} className="text-[#6A7289] mb-4">
          NODE
        </Label>
        <Paragraph variant={'sm'}>
          {taskStats?.Warehouse?.businessWarehouseCode}
        </Paragraph>
      </div>
      <div className={statsBoxClass}>
        <Label variant={'xs'} className="text-[#6A7289] mb-4">
          ASSIGN TO
        </Label>
        <Paragraph variant={'sm'}>
          {taskStats?.AssignedTo
            ? `${taskStats?.AssignedTo?.firstName} ${taskStats?.AssignedTo?.lastName}`
            : '-'}
        </Paragraph>
      </div>
      <div className={statsBoxClass}>
        <Label variant={'xs'} className="text-[#6A7289] mb-4">
          START / END DATE
        </Label>
        <Paragraph variant={'sm'}>
          {taskStats?.taskEndedAt
            ? moment(taskStats?.taskEndedAt).format('DD/MM/YY')
            : 'N/A'}{' '}
          -{' '}
          {taskStats?.taskStartedAt
            ? moment(taskStats?.taskStartedAt).format('DD/MM/YY')
            : 'N/A'}
        </Paragraph>
      </div>
      <div className={statsBoxClass}>
        <Label variant={'xs'} className="text-[#6A7289] mb-4">
          TOTAL QUANTITY
        </Label>
        <Paragraph variant={'sm'}>{taskStats?.totalQuantity}</Paragraph>
      </div>
    </div>
  );
};

import React from 'react';
import SendIcon from '../../assets/icons/send_icon.svg';
import clsx from 'clsx';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CommentInput = ({ message, setMessage, postComment, postLoader }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (message.length > 0 && !postLoader) {
        postComment();
      }
    }
  };

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              border: 'none' // Removes the border
            },
            '&:hover fieldset': {
              border: 'none' // Removes the border on hover
            },
            '&.Mui-focused fieldset': {
              border: 'none' // Removes the border on focus
            }
          },
          inputMultiline: {
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none' // for Webkit browsers
            },
            '-ms-overflow-style': 'none', // for IE and Edge
            'scrollbar-width': 'none' // for Firefox
          }
        }
      }
    }
  });

  return (
    <div className={'h-full flex items-end border border-[#0B1940] rounded'}>
      <ThemeProvider theme={theme}>
        <TextField
          variant="outlined"
          placeholder="Type your prompt here..."
          multiline
          fullWidth
          value={message}
          className={'overflow-auto max-h-[90px] text-[10px]'}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </ThemeProvider>
      <button
        type="button"
        disabled={message.length > 0 && postLoader}
        className={clsx(
          'h-10 w-10 mr-2 mb-2 rounded z-[100] ml-2',
          `${
            message.length > 0 && !postLoader ? 'bg-[#0C6BD7]' : 'bg-[#9DA3B3]'
          }`
        )}
        onClick={(e) => {
          e.stopPropagation();
          postComment();
        }}
      >
        <img src={SendIcon} className="p-3 h-10 w-10" />
      </button>
    </div>
  );
};
export default CommentInput;

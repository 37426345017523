import * as Yup from 'yup';

export const settingsFormSchema = Yup.object().shape({
  organization: Yup.object().shape({
    name: Yup.string().required('Organization name is required'),
    ntn: Yup.string().required('Ntn is required'),
    email: Yup.string()
      .email('Enter correct email')
      .required('Email is required'),
    address: Yup.string().required('Address is required'),
    bankName: Yup.string().required('Bank Name is required'),
    bankBranch: Yup.string().required('Bank Branch is required'),
    accountTitle: Yup.string().required('Account Title is required'),
    accountNumber: Yup.string().required('Account Number is required'),
    iban: Yup.string().required('IBAN is required'),
    swiftCode: Yup.string().nullable()
  }),
  titles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required')
    })
  ),
  taxes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Tax name is required'),
      rate: Yup.number().required('Tax rate is required')
    })
  )
});

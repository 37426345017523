import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../../Config';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid
} from '@mui/material';
import { Heading, Paragraph } from '../../../../core-components/atoms/Text';
import Autocomplete from '../../../../core-components/atoms/Autocomplete';
import sectionTypes from '../../../../utils/enums/sectionTypes';
import { capitalize } from '../../../../utils/common';
import TextField from '../../../../core-components/atoms/TextField';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider
} from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { OASIS_PRIMARY_500 } from '../../../../constants/colors';
import sectionClassifications from '../../../../utils/enums/sectionClassifications';
import { toaster } from '../../../../utils/toaster';
import Switch from '../../../../core-components/atoms/Switch';

const theme = createTheme({
  palette: {
    primary: { main: OASIS_PRIMARY_500 }
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontWeight: 400
        // padding: 0,
        // margin: 0
      }
    }
  }
});

const useStyles = makeStyles(() => ({
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  cancelBtn: {
    padding: '8px 12px',
    // gap: '6px',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#0B1940'
  },
  submitBtn: {
    padding: '8px 12px',
    background: '#0C6BD7',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:disabled': {
      background: 'rgba(12,107,215,0.5)'
    }
  }
}));

export default function AddBinView({
  open,
  handleClose,
  addHall,
  selectedHall,
  warehouse,
  capacity,
  warehouseCapacity
}) {
  const borderClass =
    'border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pt-3 pb-3 mt-3 number-input-container';
  const classes = useStyles();

  const sectionTypesCapitalized = sectionTypes
    .map((type) => capitalize(type))
    .filter((value) => {
      return (
        value.toLowerCase() !== 'staging' &&
        value.toLowerCase() !== 'quarantine'
      );
    });

  const [coordinates, setCoordinates] = useState({ x: '', y: '', z: '' });
  const [aisles, setAisles] = useState([]);
  const [bays, setBays] = useState([]);
  const [aisleSearch, setAisleSearch] = useState('');
  const [baySearch, setBaySearch] = useState('');

  const [formikInitVals, setInitVals] = useState({
    name: '',
    sectionType: 'GROUNDED',
    classifications: 'A',
    coordinates: {},
    pallets: null,
    aisleId: {},
    bayId: {},
    aisle: '',
    bay: '',
    capacity: null,
    isActive: true
  });
  const formik = useFormik({
    initialValues: formikInitVals,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      sectionType: Yup.string().required('Section Type is required'),
      aisle: Yup.object().required('Aisle is required'),
      bay: Yup.object().required('Bay is required'),
      pallets: Yup.number().required('Pallets is required'),
      capacity: Yup.number().required('Capacity is required')
    }),
    onSubmit: (values) => {
      submitHandler(values);
    }
  });

  const submitHandler = (values) => {
    formik.values.coordinates = {
      x: coordinates?.x || 0,
      y: coordinates?.y || 0,
      z: coordinates?.z || 0
    };
    const check = validateCapacity(values);
    if (check) {
      addHall(values);
    }
  };

  const validateCapacity = (value) => {
    if (
      (value?.sectionType?.toLowerCase() === 'racked' ||
        value?.sectionType?.toLowerCase() === 'grounded') &&
      Number(value.capacity) + Number(capacity?.capacity) >
        warehouseCapacity?.capacity
    ) {
      toaster(
        'warning',
        'Bins Capacity(Sq.Ft) cannot be greater than Warehouse Capacity(Sq.Ft)'
      );
      return false;
    } else if (
      (value?.sectionType?.toLowerCase() === 'racked' ||
        value?.sectionType?.toLowerCase() === 'grounded') &&
      Number(value.pallets) + Number(capacity?.pallets) >
        warehouseCapacity?.pallets
    ) {
      toaster(
        'warning',
        'Bins Capacity(Pallets) cannot be greater than Warehouse Capacity(Pallets)'
      );
      return false;
    } else {
      return true;
    }
  };

  const getAisles = async (search) => {
    if (!warehouse?.id) {
      return;
    }
    const colVal = {
      isActive: true
    };
    const filters = {
      colVal
    };
    try {
      const res = await API.get(`warehouses/${warehouse?.id}/aisles`, {
        params: { filters, search }
      });
      setAisles(res?.data);
    } catch (error) {
      onError(error);
    }
  };

  const getBays = async (search) => {
    if (!warehouse?.id) {
      return;
    }
    const colVal = {
      isActive: true
    };
    const filters = {
      colVal
    };
    try {
      const res = await API.get(`warehouses/${warehouse?.id}/bays`, {
        params: { filters, search }
      });
      setBays(res?.data);
    } catch (error) {
      onError(error);
    }
  };

  const getDelayedAisles = useCallback(
    debounce((value) => {
      setAisles([]);
      getAisles(value);
    }, DEBOUNCE_CONST),
    []
  );

  const getDelayedBays = useCallback(
    debounce((value) => {
      setBays([]);
      getBays(value);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    let hallObj = {
      name: selectedHall?.name,
      sectionType: selectedHall?.sectionType,
      classifications: selectedHall?.classifications,
      coordinates: selectedHall?.coordinates,
      pallets: selectedHall?.pallets,
      capacity: selectedHall?.capacity,
      isActive: selectedHall?.isActive,
      aisle: selectedHall?.Aisle,
      bay: selectedHall?.Bay
    };
    setCoordinates({
      x: selectedHall?.coordinates?.x,
      y: selectedHall?.coordinates?.y,
      z: selectedHall?.coordinates?.z
    });
    setInitVals({ ...hallObj });
  }, [selectedHall]);

  useEffect(() => {
    getDelayedAisles(aisleSearch);
  }, [aisleSearch]);

  useEffect(() => {
    getDelayedBays(baySearch);
  }, [baySearch]);

  return (
    <div style={{ display: 'inline' }} className="mt-10 mb-10">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle>
          <Heading variant={'section'}>
            {!selectedHall ? 'Add A New Bin' : 'Edit Bin'}
          </Heading>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} autoComplete={false}>
            <Grid container className={`${borderClass} mt-4`} spacing={1}>
              <Grid item xs={4} className="flex items-center">
                <p className="font-semibold">
                  Bin Name & Type
                  <span className="text-error ml-2">*</span>
                </p>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth size={'small'}>
                  <TextField
                    onChange={(e) =>
                      formik.setFieldValue('name', e.target.value)
                    }
                    filled={false}
                    id="name"
                    disabled={
                      formik?.values?.sectionType === 'STAGING' ||
                      formik?.values?.sectionType === 'QUARANTINE'
                    }
                    value={formik?.values?.name || ''}
                    type="text"
                    size="small"
                    placeholder="Section Name"
                    InputProps={{
                      style: {
                        height: 36
                      }
                    }}
                    inputProps={{
                      style: { WebkitBoxShadow: '0 0 0 1000px white inset' }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="sectionType"
                  options={sectionTypesCapitalized}
                  onChange={async (e, value) => {
                    if (value) {
                      formik.setFieldValue('sectionType', value);
                    } else {
                      formik.setFieldValue('sectionType', '');
                    }
                  }}
                  onBlur={formik.handleBlur}
                  disabled={
                    formik?.values?.sectionType === 'STAGING' ||
                    formik?.values?.sectionType === 'QUARANTINE'
                  }
                  value={formik.values?.sectionType || ''}
                  getOptionLabel={(type) => type || ''}
                  getOptionDisabled={(option) =>
                    option === 'Staging' || option === 'Quarantine'
                  }
                  placeholder="Select Type"
                />
              </Grid>
            </Grid>

            <Grid container className={`${borderClass} mt-4`} spacing={1}>
              <Grid item xs={4} className="flex items-center">
                <p className="font-semibold">
                  Aisle & Bay
                  <span className="text-error ml-2">*</span>
                </p>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="aisleId"
                  options={aisles}
                  onChange={async (e, value) => {
                    if (value) {
                      formik.setFieldValue('aisle', value);
                      formik.setFieldValue('aisleId', value?.id);
                      setAisleSearch('');
                    } else {
                      formik.setFieldValue('aisle', '');
                      formik.setFieldValue('aisleId', '');
                      setAisleSearch('');
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => setAisleSearch(e.target.value)}
                  value={formik.values?.aisle || ''}
                  getOptionLabel={(type) => type.name || ''}
                  placeholder="Select Aisle"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="bayId"
                  options={bays}
                  onChange={async (e, value) => {
                    if (value) {
                      formik.setFieldValue('bay', value);
                      formik.setFieldValue('bayId', value?.id);
                      setBaySearch('');
                    } else {
                      formik.setFieldValue('bay', '');
                      formik.setFieldValue('bayId', '');
                      setBaySearch('');
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => setBaySearch(e.target.value)}
                  value={formik.values?.bay || ''}
                  getOptionLabel={(type) => type.name || ''}
                  placeholder="Select Bay"
                />
              </Grid>
            </Grid>

            <Grid container className={`${borderClass} mt-4`} spacing={1}>
              <Grid item xs={4} className="flex items-center">
                <p className="font-semibold">
                  Capacity
                  <span className="text-error ml-2">*</span>
                </p>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth size={'small'}>
                  <TextField
                    id="capacity"
                    type="number"
                    value={
                      formik?.values?.capacity &&
                      Math.max(0, formik?.values?.capacity)
                    }
                    min="0"
                    onChange={(e) =>
                      formik.setFieldValue('capacity', e.target.value)
                    }
                    size="small"
                    placeholder="00"
                    InputProps={{
                      endAdornment: (
                        <Paragraph variant={'md'}>Sq.Ft.</Paragraph>
                      ),
                      style: {
                        height: 40
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth size={'small'}>
                  <TextField
                    id="pallets"
                    type="number"
                    value={
                      formik?.values?.pallets &&
                      Math.max(0, formik?.values?.pallets)
                    }
                    min="0"
                    onChange={(e) =>
                      formik.setFieldValue('pallets', e.target.value)
                    }
                    size="small"
                    placeholder="00"
                    InputProps={{
                      endAdornment: (
                        <Paragraph variant={'md'}>Pallets</Paragraph>
                      ),
                      style: {
                        height: 40
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className={`${borderClass} mt-4`} spacing={1}>
              <Grid item xs={4} className="flex items-center">
                <p className="font-semibold">Bin Classification</p>
              </Grid>
              <Grid item xs={8}>
                <ThemeProvider theme={theme}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-label="classifications"
                      name="classifications"
                      value={formik.values.classifications}
                      onChange={formik.handleChange}
                    >
                      {sectionClassifications?.map((value, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={value}
                            control={<Radio size={'small'} color={'primary'} />}
                            label={value}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </ThemeProvider>
              </Grid>
            </Grid>

            <Grid container className={`${borderClass} mt-4`} spacing={1}>
              <Grid item xs={4} className="flex items-center">
                <p className="font-semibold">Coordinates</p>
              </Grid>
              <Grid item xs={2.75}>
                <FormControl fullWidth>
                  <TextField
                    id="coordinates"
                    type="number"
                    value={coordinates?.x}
                    min="0"
                    onChange={(e) => {
                      if (Number(e.target.value) <= 0) {
                        setCoordinates({ ...coordinates, y: 0 });
                      } else {
                        setCoordinates({
                          ...coordinates,
                          x: e.target.value
                        });
                      }
                    }}
                    size="small"
                    placeholder="00"
                    InputProps={{
                      endAdornment: <Paragraph variant={'md'}>X</Paragraph>,
                      style: {
                        height: 40
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.5}>
                <FormControl fullWidth>
                  <TextField
                    id="coordinates"
                    type="number"
                    value={coordinates?.y}
                    min="0"
                    onChange={(e) => {
                      if (Number(e.target.value) <= 0) {
                        setCoordinates({ ...coordinates, y: 0 });
                      } else {
                        setCoordinates({
                          ...coordinates,
                          y: e.target.value
                        });
                      }
                    }}
                    size="small"
                    placeholder="00"
                    InputProps={{
                      endAdornment: <Paragraph variant={'md'}>Y</Paragraph>,
                      style: {
                        height: 40
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2.75}>
                <FormControl fullWidth>
                  <TextField
                    id="coordinates"
                    type="number"
                    value={coordinates?.z}
                    min="0"
                    onChange={(e) => {
                      if (Number(e.target.value) <= 0) {
                        setCoordinates({ ...coordinates, z: 0 });
                      } else {
                        setCoordinates({
                          ...coordinates,
                          z: e.target.value
                        });
                      }
                    }}
                    size="small"
                    placeholder="00"
                    InputProps={{
                      endAdornment: <Paragraph variant={'md'}>Z</Paragraph>,
                      style: {
                        height: 40
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className={`mt-4`} spacing={1}>
              <Grid item xs={4} className="flex items-center">
                <p className="font-semibold">Status</p>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={formik.values.isActive}
                  onChange={() =>
                    formik.setFieldValue('isActive', !formik.values.isActive)
                  }
                  label="Active"
                />
              </Grid>
            </Grid>

            <div className={classes.btnGroup}>
              <button
                className={classes.cancelBtn}
                type="button"
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}
              >
                Cancel
              </button>
              <button
                className={classes.submitBtn}
                type="submit"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {!selectedHall ? `Save` : 'Update'}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useContext, useState } from 'react';
import { Grid, Paper, Box, IconButton } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { toaster } from '../../utils/toaster';
import API from '../../libs/axios';
import { Close } from '@mui/icons-material';
import popupStyles from './popupStyles';
import DetailPageTitle from '../../atomicComponents/DetailPageTitle';
import Timer from './Timer';
import { SharedContext } from '../../utils/common';

const EmailSentPopup = (props) => {
  const classes = popupStyles();
  const [timerFinished, setTimerFinished] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [open, setOpen] = useState(true);

  const { setAPILoader } = useContext(SharedContext);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleTimerFinish = () => {
    setTimerFinished(true);
  };

  const handleResendClick = async () => {
    setIsReset(true);
    setTimerFinished(false);

    try {
      setAPILoader(true);
      await API.post('auth/forgot-pass', {
        email: props.email
      });
    } catch (err) {
      toaster('error', err?.response?.data?.message);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '25%' } }}
    >
      <Grid
        container
        className={classes.backdropGrid}
        justifyContent="flex-start"
      >
        <DialogTitle>
          <DetailPageTitle text="Email has been sent." />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.close}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper elevation={0} className={classes.paperStyle}>
            <Box mt={3}>
              <label className="email-label">
                If a matching account was found an email has been sent to{' '}
                <span className={classes.email}>{props.email}</span>. Do not
                forget to check your spam folder.
              </label>
            </Box>
            <br />
            <Box mt={2} className={classes.inline}>
              <label className={classes.label}>Did not receive an email?</label>
              <Timer
                minutes={0}
                seconds={60}
                isReset={isReset}
                onReset={() => setIsReset(false)}
                onTimerFinish={handleTimerFinish}
              />
            </Box>
            <Box mt={2} className={classes.btn}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleResendClick}
                disabled={!timerFinished}
              >
                Resend
              </Button>
            </Box>
          </Paper>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default EmailSentPopup;

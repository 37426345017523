import { Grid, Paper, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { SharedContext } from '../../src/utils/common';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '0px'
  },
  pageHeader: {
    padding: '16px 0px',
    display: 'flex'
  },
  heading: {
    fontWeight: 'bolder'
  }
}));

const TableHeader = ({ buttons }) => {
  const { currentPageTitle } = useContext(SharedContext);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper elevation={0} square className={classes.root}>
        <div className={classes.pageHeader}>
          <Grid
            container
            className={
              currentPageTitle == 'Product'
                ? 'full-cont customProductMargin'
                : 'full-cont'
            }
          >
            <Grid item className="full-cont text-center position-relative">
              {buttons.map((button, idx) => {
                return <span key={idx}>{button}</span>;
              })}
            </Grid>
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default TableHeader;

import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const useStyles = (customBtnColor) => {
  return makeStyles(() => ({
    pagination: {
      border: 'none',
      display: 'flex',
      justifyContent: 'flex-end',
      '& .Mui-selected': {
        background: customBtnColor
      }
    }
  }))();
};

const PaginationGrid = ({
  pageCount,
  setPage,
  page,
  customBtnColor = 'initial'
}) => {
  const classes = useStyles(customBtnColor);

  return (
    <Grid container justify="space-between">
      <Grid item></Grid>
      <Grid item>
        <Pagination
          component="div"
          shape="rounded"
          count={pageCount}
          color="primary"
          page={page}
          className={classes.pagination}
          onChange={(event, newPage) => setPage(newPage)}
        />
      </Grid>
    </Grid>
  );
};

export default PaginationGrid;

/*eslint-disable*/
import { useMutation } from 'react-query';
import { InventoryManagementTabsMapping } from '../../../constants';
import Button from '../../../core-components/atoms/Button';
import { decimalDisplay } from '../../../utils/common';
import {
  updateDamagedStockCondition,
  updateGoodStockCondition
} from '../../../apis/wms';
import { onError } from '../../../libs/errorLib';

const ConditionConfirmation = ({
  type,
  unsellableQuantity,
  recoverableQuantity,
  usableQuantity,
  selectedInventoryDetails,
  handleSubmit,
  setOpen
}) => {
  const handleChange = () => {
    if (type === InventoryManagementTabsMapping.USABLE) {
      mutateUsable({
        inventoryDetailId: selectedInventoryDetails?.id,
        damageRecoverable: +recoverableQuantity,
        damageUnsellable: +unsellableQuantity,
        availableQuantity: selectedInventoryDetails?.availableQuantity
      });
    }
    if (type === InventoryManagementTabsMapping.DAMAGED) {
      mutateDamaged({
        inventoryDetailId: selectedInventoryDetails?.id,
        unsellableQuantity: +unsellableQuantity,
        goodQuantity: usableQuantity,
        recoverableQuantity: selectedInventoryDetails?.recoverableDamageQuantity
      });
    }
  };

  const { isLoading: loadingUsable, mutate: mutateUsable } = useMutation(
    updateGoodStockCondition,
    {
      onSuccess: (response) => {
        const [update1 = null, update2 = null] = response?.updatedBatches || [];
        handleSubmit(update1, update2);
      },
      onError: (error) => {
        onError({ response: error.response });
      }
    }
  );

  const { isLoading: loadingDamaged, mutate: mutateDamaged } = useMutation(
    updateDamagedStockCondition,
    {
      onSuccess: (response) => {
        const [update1 = null, update2 = null] = response?.updatedBatches || [];
        handleSubmit(update1, update2);
      },
      onError: (error) => {
        onError({ response: error.response });
      }
    }
  );

  return (
    <div className="p-8 pt-0 flex flex-col justify-between h-full">
      {type === InventoryManagementTabsMapping.USABLE && (
        <div>
          {+recoverableQuantity > 0 && (
            <p className="text-primaryBlue mb-4">
              {decimalDisplay(recoverableQuantity)} item
              {+recoverableQuantity !== 1 ? 's' : ''} will be changed to “Damage
              Recoverable condition, which can be changed back.
            </p>
          )}
          {+unsellableQuantity > 0 && (
            <p className="text-primaryBlue mb-4">
              {decimalDisplay(unsellableQuantity)} item
              {+unsellableQuantity !== 1 ? 's' : ''} will be will be changed to
              “Damage Unsellable” condition, which can’t be changed back.
            </p>
          )}
        </div>
      )}
      {type === InventoryManagementTabsMapping.DAMAGED && (
        <div>
          {+unsellableQuantity > 0 && (
            <p className="text-primaryBlue mb-4">
              {decimalDisplay(unsellableQuantity)} item
              {+unsellableQuantity !== 1 ? 's' : ''} will be changed to “Damage
              Unsellable” condition, which can’t be changed back.
            </p>
          )}
          {+usableQuantity > 0 && (
            <p className="text-primaryBlue mb-4">
              {decimalDisplay(usableQuantity)} item
              {+usableQuantity !== 1 ? 's' : ''} will be changed to “Usable”
              condition, and moved to “Staging Area”
            </p>
          )}
        </div>
      )}
      <div className="w-full flex justify-end gap-4">
        <Button
          size="small"
          variant="transparent"
          label="Cancel"
          className="h-9"
          labelClass="font-medium text-sm"
          onClick={() => {
            setOpen(false);
          }}
        />
        <Button
          size="small"
          variant="primary"
          className="h-9"
          label="Change Condition"
          labelClass="font-medium text-sm"
          onClick={handleChange}
        />
      </div>
    </div>
  );
};

export default ConditionConfirmation;

/*eslint-disable*/
import { FormControl } from '@material-ui/core';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import _ from 'lodash';

import clsx from 'clsx';
import { useState, useEffect, useContext, useCallback } from 'react';
import QRCode from 'react-qr-code';
import { DEBOUNCE_CONST } from '../../Config';
import chevronUp from '../../assets/icons/chevronUpDark.svg';
import printIcon from '../../assets/icons/print-icon.svg';
import { QR_EXPIRY_DATE } from '../../constants/features';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { checkPermission } from '../../utils/auth';
import {
  SharedContext,
  dateFormatSlashDivider,
  encryptDate
} from '../../utils/common';
import { FeatureFlag } from '../../utils/featureFlag';
import { toaster } from '../../utils/toaster';
import qrCodeStyles from './qrCodeStyles';
import {
  initialValues,
  validationSchema3PL,
  validationSchemaManufacturer
} from './qrGenFormikFields';
import { ORGANIZATION_TYPES } from '../../constants';
import mixpanel from 'mixpanel-browser';

const QRCodeGeneration = () => {
  // styles
  const classes = qrCodeStyles();
  // states
  const [companies, setCompanies] = useState([]);
  const [companySearchVal, setCompanySearchVal] = useState('');
  const [singleCompany, setSingleCompany] = useState(null);
  const [products, setProducts] = useState([]);
  const [productSearchVal, setProductSearchVal] = useState('');
  const [qrCodeVals, setQRCodeVals] = useState({});
  const [initVals] = useState(initialValues());

  // context API
  const {
    currentPageTitle,
    setCurrentPageTitle,
    currentUser,
    setAPILoader,
    organizationType
  } = useContext(SharedContext);

  useEffect(() => {
    setCurrentPageTitle('QR Code Generation');
    mixpanel.track('QR Code Generation', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  const formik = useFormik({
    initialValues: initVals,
    validationSchema:
      organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? validationSchemaManufacturer
        : validationSchema3PL,
    enableReinitialize: true,
    onSubmit: (values) => generateQR(values)
  });

  useEffect(() => {
    if (!formik.values.cid) {
      formik.resetForm();
      setProducts([]);
    }
  }, [formik?.values?.cid]);

  const generateQR = (values) => {
    const { description, name } =
      products?.find((product) => product?.id === values?.pid) || {};
    const encryptedDate =
      encryptDate(dateFormatSlashDivider(values.expd)) || null;
    const qrCodeVals = {
      pid: values.pid,
      mfgd: values.mfgd ? dateFormatSlashDivider(values.mfgd) : null,
      expd: !FeatureFlag(QR_EXPIRY_DATE)
        ? encryptedDate
        : values.expd
        ? dateFormatSlashDivider(values.expd)
        : null,
      name: (description || '').trim(),
      sku: (name || '').trim(),
      mrp: values.mrp || '',
      batch: (values.batch || '').trim(),
      P: (values.promo || '').trim()
    };
    setQRCodeVals(qrCodeVals);
    mixpanel.track('QR Code Generated', {});
  };

  // fetching companies
  const getCompanies = async (searchVal) => {
    try {
      setAPILoader(true);
      const res = await API.get('companies', {
        params: {
          search: searchVal
        }
      });
      setCompanies(res?.data?.rows);
      setSingleCompany(null);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (companies?.length === 1 && !companySearchVal?.length) {
      formik.setFieldValue('cid', companies[0].id);
      setSingleCompany(companies[0]);
    }
  }, [companies]);

  const getDelayedCompanies = useCallback(
    _.debounce(
      async (companySearchVal) => {
        getCompanies(companySearchVal);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedCompanies(companySearchVal);
  }, [companySearchVal]);

  // fetching products
  const getProducts = async (searchVal, companyId) => {
    try {
      setAPILoader(true);
      if (
        companyId &&
        organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
      ) {
        const res = await API.get(`companies/${companyId}/products`, {
          params: {
            search: searchVal,
            columns: ['name', 'description', 'barcode']
          }
        });
        setProducts(res?.data);
      } else if (organizationType === ORGANIZATION_TYPES.MANUFACTURER) {
        const res = await API.get(`products`, {
          params: {
            search: searchVal,
            columns: ['name', 'description', 'barcode']
          }
        });
        setProducts(res?.data);
      }
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const getDelayedProducts = useCallback(
    _.debounce(
      async (productSearchVal, companyId) => {
        getProducts(productSearchVal, companyId);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    if (
      formik.values.cid ||
      organizationType === ORGANIZATION_TYPES.MANUFACTURER
    ) {
      getDelayedProducts(productSearchVal, formik?.values?.cid);
    }
  }, [productSearchVal, formik.values.cid]);

  useEffect(() => {
    if (organizationType === ORGANIZATION_TYPES.MANUFACTURER) {
      getDelayedProducts(productSearchVal, formik?.values?.cid);
    }
  }, [organizationType]);

  // fetching Batch
  const getBatch = async (batchNum, cid, pid) => {
    try {
      setAPILoader(true);
      if (cid && pid && batchNum) {
        const res = await API.get(
          `companies/${cid}/products/${pid}/batches/${batchNum}`
        );
        formik.setFieldValue(
          'mfgd',
          res?.batch?.manufacturingDate.replace(/-/g, '/').replace(/T.+/, '')
        );
        formik.setFieldValue(
          'expd',
          res?.batch?.expiryDate.replace(/-/g, '/').replace(/T.+/, '')
        );
        formik.setFieldValue('mrp', res?.batch?.MRP || '');
      } else if (organizationType === ORGANIZATION_TYPES.MANUFACTURER) {
        const res = await API.get(`products/${pid}/batches/${batchNum}`);
        formik.setFieldValue(
          'mfgd',
          res?.batch?.manufacturingDate.replace(/-/g, '/').replace(/T.+/, '')
        );
        formik.setFieldValue(
          'expd',
          res?.batch?.expiryDate.replace(/-/g, '/').replace(/T.+/, '')
        );
      }
    } catch (error) {
      toaster('info', error?.response?.data?.message);
    } finally {
      setAPILoader(false);
    }
  };

  const getDelayedBatch = useCallback(
    _.debounce(
      async (batchNum, cid, pid) => {
        getBatch(batchNum, cid, pid);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const handleClear = () => {
    formik.resetForm();
    setQRCodeVals({});
    setSingleCompany(null);
  };

  useEffect(() => {
    if (
      formik.values.batch &&
      (formik.values.cid ||
        organizationType === ORGANIZATION_TYPES.MANUFACTURER) &&
      formik.values.pid
    ) {
      getDelayedBatch(
        formik.values.batch,
        formik.values.cid,
        formik.values.pid
      );
      formik.setFieldValue('mfgd', null);
      formik.setFieldValue('expd', null);
      formik.setFieldValue('mrp', '');
      formik.setFieldValue('promo', '');
    }
  }, [formik.values.batch]);

  return (
    <div className={classes.root}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container item lg={10} md={10} xs={12} columnSpacing={4}>
          <Grid item xs={12} sm={5}>
            {organizationType ===
              ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER && (
              <Grid xs={12}>
                <FormControl
                  fullWidth={true}
                  variant="outlined"
                  className={classes.qrInputBox}
                >
                  <Autocomplete
                    id="cid"
                    options={companies}
                    getOptionLabel={(options) => {
                      return options?.name || '';
                    }}
                    value={singleCompany}
                    defaultValue={''}
                    onBlur={formik?.handleBlur}
                    onChange={(e, val) => {
                      if (!val) {
                        handleClear();
                      } else {
                        formik.resetForm();
                        setSingleCompany(val);
                        formik.setFieldValue('cid', val !== null && val.id);
                      }
                    }}
                    onKeyUp={(event) => {
                      setCompanySearchVal(event.target.value);
                    }}
                    popupIcon={
                      <img src={chevronUp} alt="" width={12.78} height={6} />
                    }
                    renderInput={(params) => (
                      <TextField
                        className={clsx(classes.textField, classes.longInput)}
                        InputProps={{
                          className: classes.textInput
                        }}
                        {...params}
                        variant="outlined"
                        label="Company"
                        placeholder="Select"
                      />
                    )}
                  />
                </FormControl>
                {formik?.errors['cid'] && formik?.touched['cid'] && (
                  <Typography color="error">Company is required!</Typography>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.qrInputBox}
              >
                <Autocomplete
                  id="pid"
                  options={products}
                  getOptionLabel={(options) => {
                    return options?.description && options?.name
                      ? `${options?.name || ''} - ${options?.description || ''}`
                      : '';
                  }}
                  value={formik.values.product}
                  defaultValue={''}
                  onBlur={formik?.handleBlur}
                  onChange={(e, val) => {
                    formik.setFieldValue('pid', val !== null && val.id);
                    formik.setFieldValue('product', val !== null && val);
                  }}
                  onKeyUp={(event) => {
                    setProductSearchVal(event.target.value);
                  }}
                  popupIcon={
                    <img src={chevronUp} alt="" width={12.78} height={6} />
                  }
                  renderInput={(params) => (
                    <TextField
                      className={clsx(classes.textField, classes.longInput)}
                      InputProps={{
                        className: classes.textInput
                      }}
                      {...params}
                      variant="outlined"
                      label="Product (SKU)"
                      placeholder="Select"
                    />
                  )}
                />
                {formik?.errors['pid'] && formik?.touched['pid'] && (
                  <Typography color="error">Product is required!</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.qrInputBox}
              >
                <TextField
                  formik={formik}
                  className={clsx(classes.textField)}
                  InputProps={{
                    className: classes.textInput
                  }}
                  name="batch"
                  label="Batch Number"
                  value={formik.values.batch}
                  onChange={(event) =>
                    formik.setFieldValue('batch', event.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.qrInputBox}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={formik?.values?.mfgd}
                  onChange={(newValue) => {
                    formik.setFieldValue('mfgd', newValue);
                    const productLife = products?.find(
                      (el) => el?.id === formik.values['pid']
                    )?.productLife;
                    if (productLife) {
                      let expiryDate = new Date(newValue);
                      expiryDate.setDate(expiryDate.getDate() + productLife);
                      formik.setFieldValue('expd', expiryDate);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className={clsx(classes.textField)}
                      InputProps={{
                        className: classes.textInput
                      }}
                      {...params}
                      label="Mfg Date"
                      id="mfgd"
                      onBlur={formik.handleBlur}
                      value={formik?.values?.mfgd}
                    />
                  )}
                />
              </LocalizationProvider>
              {formik?.errors?.mfgd && formik?.touched?.mfgd && (
                <Typography color="error">{formik.errors.mfgd}</Typography>
              )}
            </FormControl>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.qrInputBox}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={formik?.values?.expd}
                  minDate={formik?.values?.mfgd}
                  onChange={(newValue) => {
                    formik.setFieldValue('expd', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className={clsx(classes.textField)}
                      InputProps={{
                        className: classes.textInput
                      }}
                      {...params}
                      label="Exp Date"
                      id="expd"
                      onBlur={formik.handleBlur}
                      value={formik?.values?.expd}
                      minDate={formik?.values?.mfgd}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <Grid item xs={12}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.qrInputBox}
              >
                <TextField
                  formik={formik}
                  name="mrp"
                  type="number"
                  disabled={!formik?.values?.product?.MRPEnabled}
                  label="MRP (Per Pack) Rs"
                  className={clsx(classes.textField)}
                  InputProps={{
                    className: classes.textInput
                  }}
                  value={formik?.values?.mrp}
                  onChange={(event) =>
                    formik.setFieldValue('mrp', event.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.qrInputBox}
              >
                <TextField
                  formik={formik}
                  name="promo"
                  type="text"
                  label="Promo Name"
                  className={clsx(classes.textField)}
                  value={formik.values.promo}
                  InputProps={{
                    className: classes.textInput
                  }}
                  onChange={(event) =>
                    formik.setFieldValue('promo', event.target.value)
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.qrContainer}>
              <p className={classes.qtTitle}>QR Code Preview</p>
              <div className={classes.qrPreview}>
                <div className={classes.qrBody} id="printMe">
                  <div className={classes.qrCode}>
                    <QRCode
                      size={160}
                      level="Q"
                      value={JSON.stringify(_.omit(qrCodeVals, ['name']))}
                      viewBox={`0 0 160 160`}
                    />
                  </div>
                  <div className={classes.qtDetails}>
                    <p className={classes.skuName}>{qrCodeVals?.sku || '--'}</p>
                    <p className={classes.promoName}>
                      {qrCodeVals?.promo || ''}
                    </p>
                    <p className={classes.productText}>
                      {qrCodeVals?.batch || '--'}
                    </p>
                    <p className={classes.productText}>
                      {qrCodeVals?.name || '--'}
                    </p>
                    <div className={classes.dateBox}>
                      <div>
                        <p className={classes.dateTitle}>Mfg Date:</p>
                        <p className={classes.dateText}>
                          {qrCodeVals?.mfgd || '--'}
                        </p>
                      </div>
                      {FeatureFlag(QR_EXPIRY_DATE) ? (
                        <div>
                          <p className={classes.dateTitle}>Exp Date:</p>
                          <p className={classes.dateText}>
                            {qrCodeVals?.expd || '--'}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className={clsx(
                  classes.lgBtn,
                  classes.successBtn,
                  classes.print
                )}
                type="button"
                onClick={() => {
                  window.print();
                }}
              >
                <img src={printIcon} alt="print" />
                <span>PRINT</span>
              </Button>
            </div>
          </Grid>
          <Grid item xs={5} className="mt-16">
            <Button
              className={clsx(classes.lgBtn, classes.rejectBtn)}
              type="button"
              onClick={handleClear}
            >
              CLEAR
            </Button>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5} className="mt-16">
            {checkPermission(currentUser, 'OPS_QR_CREATE') ? (
              <Button
                className={clsx(classes.lgBtn, classes.successBtn)}
                type="submit"
              >
                Generate QR
              </Button>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default QRCodeGeneration;

import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  DialogTitle,
  Grid,
  Checkbox,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import warehouseStyles from './warehouseMakeStyles';
import API from '../../../libs/axios';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import { toaster } from '../../../utils/toaster';
import { checkValidationErr } from '../../../services/BulkUploadServices';
import { useParams } from 'react-router';
import { SharedContext } from '../../../utils/common';

const AddAislesAndBaysView = ({
  open,
  route,
  aisleOrBays,
  handleClose,
  type,
  getWarehouseAislesOrBays
}) => {
  const classes = warehouseStyles();

  const { uid } = useParams();
  const { setAPILoader } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: {
      name: aisleOrBays?.name || '',
      warehouseId: uid,
      isActive: !!aisleOrBays?.isActive
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(`${type} name is required`),
      isActive: Yup.bool().required(`${type} active status is required`)
    }),
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  const submitForm = async (values) => {
    try {
      setAPILoader(true);
      if (aisleOrBays && aisleOrBays.id) {
        await API.put(`warehouses/${uid}/${route}/${aisleOrBays.id}`, {
          ...values
        });
      } else {
        await API.post(`warehouses/${uid}/${route}`, {
          ...values
        });
      }
      toaster(
        'success',
        !(aisleOrBays && aisleOrBays.id)
          ? `${type} created successfully.`
          : `${type} updated successfully.`
      );

      getWarehouseAislesOrBays();
      formik.resetForm();
      handleClose();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_${type}s_warehouseId`,
        `${type} already exists it must be unique`
      );
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '448px' } }}
    >
      <Grid
        container
        className={classes.backdropGrid}
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <DialogTitle>
            <DetailPageTitle
              text={aisleOrBays?.id ? `Edit ${type}:` : `Add ${type}:`}
            />
          </DialogTitle>
        </Grid>
        <Grid item xs={12}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <DialogContent className={classes.dockDialog}>
              <DetailTitle text={`${type} Name`} />
              <input
                className={classes.dockInputField}
                id="name"
                placeholder="Type here"
                value={formik.values.name || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name && (
                <Typography color="error">{formik.errors.name}</Typography>
              )}
              <Grid item sm={12}>
                <Checkbox
                  checked={formik.values.isActive}
                  id="isActive"
                  name="isActive"
                  value={formik.values.isActive}
                  onChange={formik.handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Active
              </Grid>
            </DialogContent>
            <DialogActions
              className={classes.btnGroup}
              style={{ justifyContent: 'center' }}
            >
              <Tooltip title="Cancel">
                <button
                  className={classes.cancelBtn}
                  type="button"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Tooltip>
              <Tooltip title={`Add ${type}`}>
                <button className={classes.submitBtn} type="submit">
                  {aisleOrBays?.id ? 'SAVE EDIT' : `ADD ${type}`}
                </button>
              </Tooltip>
            </DialogActions>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddAislesAndBaysView;

import React from 'react';
import PrintDetail from './PrintDetail';

const PrintOrder = ({ order, expenseTotal, orderSummary, loading }) => {
  return (
    <PrintDetail
      order={order}
      expenseTotal={expenseTotal}
      orderSummary={orderSummary}
      loading={loading}
    />
  );
};

export default PrintOrder;

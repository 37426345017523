import { useContext } from 'react';
import { SharedContext } from '../../utils/common';
import CompaniesView from './CompaniesView';
import { ORGANIZATION_TYPES } from '../../constants';
import ProductsView from './ProductsView';

const ProductMaster = () => {
  const { organizationType, setCurrentPageTitle } = useContext(SharedContext);
  setCurrentPageTitle('Product(s)');

  return (
    <>
      {organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER ? (
        <CompaniesView />
      ) : (
        <ProductsView />
      )}
    </>
  );
};

export default ProductMaster;

import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { makeStyles } from '@material-ui/core';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const DraggableImage = ({
  item,
  index,
  imagesArr,
  setIsConfirm,
  setSelectedId,
  setImagePreview,
  setOpen,
  moveImage
}) => {
  const ref = useRef(null);
  const useStyles = makeStyles(() => ({
    closeIcon: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      zIndex: '1',
      cursor: 'pointer'
    }
  }));
  const classes = useStyles();
  const [source, setSource] = useState(item?.location);

  if (!source) {
    const reader = new FileReader();
    reader.readAsDataURL(item);
    reader.onload = () => {
      setSource(reader.result);
    };
  }
  const [{ handlerId }, drop] = useDrop({
    accept: 'image',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = imagesArr?.findIndex((i) => i.id === item.id);
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
    },
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = imagesArr?.findIndex((i) => i.id === item.id);
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="image-preview-container"
      data-handler-id={handlerId}
    >
      <div
        onClick={() => {
          setIsConfirm(true);
          setSelectedId(item.id);
        }}
        className={classes.closeIcon}
      >
        <CancelRoundedIcon sx={{ color: 'grey' }} />
      </div>
      <img
        onClick={() => {
          setImagePreview(source);
          setOpen(true);
        }}
        src={source}
        className="image-preview br-3"
        alt={`Image ${item?.id}`}
        style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
      />
    </div>
  );
};

export default DraggableImage;

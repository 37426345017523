/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';

import '../../assets/css/style.css';
import DetailIcon from '../../assets/icons/eye.svg';
import FileIcon from '../../assets/icons/file.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import UploadIcon from '../../assets/icons/upload.svg';
import DetailPageTitle from '../../atomicComponents/DetailPageTitle';
import DetailText from '../../atomicComponents/DetailText';
import DetailTitle from '../../atomicComponents/DetailTitle';
import LoaderOverlay from '../../layouts/DashboardLayout/LoaderOverlay';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { checkPermission } from '../../utils/auth';
import {
  SharedContext,
  dividerDateFormatForFilter,
  exportToCSV,
  setStatus
} from '../../utils/common';
import { toaster } from '../../utils/toaster';
import { upload } from '../../utils/upload';
import MoveTypeIcon from '../orders/moveTypeIcon';

const useStyles = makeStyles(() => ({
  dialogPadding: {
    padding: '20px 55px',
    '@media only screen and  (max-width: 578px) and (min-width:360px)': {
      padding: '20px !important'
    }
  },
  routeTableHead: {
    backgroundColor: '#FAFAFC',
    '& th:first-child': {
      borderRadius: '1em 0 0 0'
    },
    '& th:last-child': {
      borderRadius: '0 1em 0 0'
    }
  },
  routeDetailTable: {
    background: '#FFFFFF',
    boxShadow: '4px 4px 16px rgb(75 137 200 / 12%)',
    borderRadius: '16px'
  }
}));

const RouteManagementDetail = (props) => {
  const classes = useStyles();
  const { currentUser } = useContext(SharedContext);

  const [routeOrders, setRouteOrders] = useState([]);
  const [routeDetail, setRouteDetail] = useState('');
  const [statusText, setStatusText] = useState('');
  const [pickup, setPickup] = useState('');
  const [routeWeight, setRouteWeight] = useState('');
  const [statusColor, setStatusColor] = useState('');
  const [loading, setLoading] = useState(false);

  const getRoute = async () => {
    try {
      setLoading(true);
      let res = await API.get(
        `routes/company/${props?.companyId}/route/${props?.routeId}`
      );
      const status = setStatus(res?.data?.status);
      setStatusText(status?.text);
      setStatusColor(status?.color);
      setPickup(
        res?.data?.RouteOrderGroups[0]?.Order?.pickupNode?.name ||
          res?.data?.RouteOrderGroups[0]?.Order?.warehousePickUp
            ?.businessWarehouseCode
      );

      const weight = res?.data?.RouteOrderGroups.reduce((prev, object) => {
        return prev + object.Order.weight;
      }, 0);
      setRouteWeight(weight);
      let orders = [];
      res?.data?.RouteOrderGroups?.map((order) => {
        orders.push(order?.Order);
      });
      setRouteDetail(res?.data);
      setRouteOrders(orders);
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toaster('success', 'Driver number is copied to clipboard');
    } catch (err) {
      onError(err);
    }
  };

  const downloadPOD = async (file) => {
    try {
      const response = await fetch(encodeURIComponent(file.location));
      const blob = await response.blob();
      saveAs(blob, file.originalName);
    } catch (err) {
      onError(err);
    }
  };

  const validatePod = async (event, order) => {
    setLoading(true);
    const checkFile = event.target.files[0];
    if (!checkFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert('Proof of delivery image must be only image file!');
      return false;
    }
    const isLt2M = checkFile.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      alert('Proof of delivery image must smaller than 1MB!');
      return false;
    }
    uploadPod(checkFile, order);
  };

  const uploadPod = async (POD, order) => {
    try {
      let pod = await upload([POD], 'route');
      if (pod) {
        updateOrder(pod[0], order);
      } else {
        toaster('error', 'File not uploaded');
      }
    } catch (err) {
      onError(err);
    }
  };

  const updateOrder = async (pod, order) => {
    try {
      order.PODId = pod;
      await API.put(`orders/order/${order?.id}`, { orderDetails: order });
      toaster('success', 'POD uploaded successfully');
      await getRoute();
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props?.companyId && props?.routeId && props?.open) {
      getRoute();
    }
  }, [props?.open]);

  const exportToExcel = () => {
    let colVal = {};
    colVal['$Company.id$'] = props?.companyId;
    let filters = {
      colVal
    };
    let orders = [];
    routeOrders?.map((order) => {
      orders.push(order?.id);
    });
    API.get(`orders/export`, {
      params: {
        client_Tz: moment.tz.guess(),
        filters,
        orders
      }
    }).then((response) => {
      const orders = response?.data.map((data) => {
        const {
          createdAt,
          pickupNode,
          dropOffNode,
          Company,
          logisticBy,
          moveType,
          palletize,
          pickupDate,
          dropoffDate,
          weight,
          referenceId,
          memo
        } = data;
        return {
          createdAt: createdAt
            ? moment(createdAt).format('DD/MM/yy HH:mm')
            : '-',
          pickupNode: pickupNode?.name.includes(',')
            ? `"${pickupNode?.name}"`
            : pickupNode?.name,
          dropOffNode: dropOffNode?.name.includes(',')
            ? `"${dropOffNode?.name}"`
            : dropOffNode?.name,
          Company: Company?.name.includes(',')
            ? `"${Company?.name}"`
            : Company?.name,
          logisticBy: logisticBy,
          moveType: moveType,
          palletize: palletize ? 'Palletized' : 'Non-palletized',
          pickupDate: pickupDate
            ? moment(pickupDate).format('DD/MM/yy HH:mm')
            : '-',
          dropoffDate: dropoffDate
            ? moment(dropoffDate).format('DD/MM/yy HH:mm')
            : '-',
          weight: weight,
          referenceId: referenceId,
          memo: memo
        };
      });
      let title = `orders ${moment().format('DD-MM-yyyy')}`;
      const header = [
        'Date of Order',
        'Origin',
        'Destination',
        'Customer',
        'Logistic By',
        'Move Type',
        'Palletize',
        'Pickup Date',
        'Dropoff Date',
        'Weight',
        'Supporting Document ID',
        'Additional Instruction'
      ];
      exportToCSV(header, orders, title);
    });
  };
  return (
    <Dialog
      open={props.open}
      onClose={(event = 'backdropClick') => {
        props.handleClose();
      }}
      className="dialogParent routeDetailView"
      aria-labelledby="form-dialog-title"
    >
      {loading ? <LoaderOverlay type="detailPage" /> : null}
      <Grid className="mt-5 mb-5">
        <DialogContent className={classes.dialogPadding}>
          <Grid item container className={classes.detailTitleSec}>
            <Grid item container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <DetailPageTitle text={`Route ID: ${routeDetail?.id}`} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="text-right routeStatus"
              >
                <b className={`${statusColor} default-font`} id="statusFont">
                  {statusText}
                </b>
              </Grid>
            </Grid>

            <Grid item container className="mt-6">
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Customer:" />
                </div>
                <div className="display-block">
                  <DetailText text={routeDetail?.Company?.name} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Origin:" />
                </div>
                <div className="display-block">
                  <DetailText text={pickup} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Date:" />
                </div>
                <div className="display-block">
                  <DetailText
                    text={dividerDateFormatForFilter(routeDetail?.createdAt)}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid item container className="mt-5">
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Vendor Name: " />
                </div>
                <div className="display-block">
                  <DetailText text={routeDetail?.Vendor?.name} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Vehicle No:" />
                </div>
                <div className="display-block">
                  <DetailText text={routeDetail?.Vehicle?.registrationNumber} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Driver Name:" />
                </div>
                <div className="display-block">
                  <DetailText text={routeDetail?.Driver?.name} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Driver Number:" />
                </div>
                <div className="display-block">
                  {(routeDetail?.driverPhone || routeDetail?.Driver?.phone) && (
                    <Tooltip
                      title={
                        routeDetail?.driverPhone || routeDetail?.Driver?.phone
                      }
                    >
                      <a
                        className="phone#"
                        href={`tel:${
                          routeDetail?.driverPhone || routeDetail?.Driver?.phone
                        }`}
                      >
                        <img
                          src={PhoneIcon}
                          onClick={() =>
                            copyToClipBoard(
                              routeDetail?.driverPhone ||
                                routeDetail?.Driver?.phone
                            )
                          }
                          alt="Driver Icon"
                        />{' '}
                        {routeDetail?.driverPhone || routeDetail?.Driver?.phone}
                      </a>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid item container className="mt-5">
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Weight: " />
                </div>
                <div className="display-block">
                  <DetailText text={routeWeight} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Vehicle Type:" />
                </div>
                <div className="display-block">
                  <DetailText
                    text={
                      routeDetail?.VehicleType
                        ? `${routeDetail?.VehicleType?.CarMake?.name} ${' '} ${
                            routeDetail?.VehicleType?.CarModel?.name
                          }`
                        : ''
                    }
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Purchase Price:" />
                </div>
                <div className="display-block">
                  <DetailText text={routeDetail?.costPrice} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="display-block">
                  <DetailTitle text="Selling Price:" />
                </div>
                <div className="display-block">
                  <DetailText text={routeDetail?.sellingPrice} />
                </div>
              </Grid>
            </Grid>

            <Grid className="mt-5 w-100">
              <Grid className="scroll-RouteSummarytable">
                <Table className={classes.routeDetailTable}>
                  <TableHead className={classes.routeTableHead}>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Pickup Date</TableCell>
                    <TableCell className="documentID">
                      Supporting Document ID
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Destination</TableCell>
                    <TableCell>Action</TableCell>
                  </TableHead>
                  <TableBody>
                    {routeOrders?.map((order, index) => {
                      const { color, text } = setStatus(order?.status);

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <MoveTypeIcon type={order?.moveType} /> {order?.id}
                          </TableCell>
                          <TableCell>
                            {dividerDateFormatForFilter(order?.pickupDate)}
                          </TableCell>
                          <TableCell>{order?.referenceId}</TableCell>
                          <TableCell>
                            <p className={color} id="routeStyles">
                              <b>{text}</b>
                            </p>
                            <Button
                              variant="contained"
                              component="label"
                              className="generalBtn"
                              startIcon={
                                <img
                                  src={order?.POD ? FileIcon : UploadIcon}
                                  onClick={() =>
                                    order?.POD
                                      ? downloadPOD(routeDetail?.files?.[index])
                                      : ''
                                  }
                                  alt="Upload Icon"
                                />
                              }
                            >
                              {order?.POD ? (
                                ''
                              ) : (
                                <input
                                  type="file"
                                  hidden
                                  // value={(e) => e.target.value + 1}
                                  onChange={(e) => {
                                    validatePod(e, order);
                                  }}
                                  accept=".jpg,.png,.jpeg"
                                />
                              )}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {order?.moveType == 'INWARD'
                              ? order?.warehouseDropOff?.businessWarehouseCode
                              : order?.dropOffNode?.address}
                          </TableCell>
                          <TableCell>
                            <img
                              src={DetailIcon}
                              className="cursor-pointer"
                              onClick={() => {
                                props?.handleClose();
                                props?.setOrderDetailViewOpen(true);
                                props?.setOrderId(order?.id);
                              }}
                              alt="Detail Icon"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {checkPermission(currentUser, 'OPS_ROUTE_EXPORT') && (
                      <TableRow>
                        <TableCell className="bb-0">
                          <button
                            onClick={() => exportToExcel()}
                            className="exportCustomBtn cursor-pointer"
                          >
                            Export
                          </button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            className="routeSummaryBtn"
            onClick={() => {
              props.handleClose();
            }}
            color="default"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default RouteManagementDetail;

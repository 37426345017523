import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const AdvancedMarker = ({
  map,
  position,
  children,
  onClick,
  addMarkerRef,
  hovered,
  showInfoWindow,
  infoWindow
}) => {
  const google = window.google;
  const rootRef = useRef();
  const markerRef = useRef();
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (markerRef.current !== undefined) {
      addMarkerRef(markerRef.current);
    }
  }, [markerRef.current]);

  useEffect(() => {
    const loadAdvancedMarkerView = async () => {
      try {
        const { AdvancedMarkerElement } =
          await google.maps.importLibrary('marker');

        if (!rootRef.current && google) {
          const element = document.createElement('div');
          rootRef.current = element;
          setContainer(element);

          markerRef.current = new AdvancedMarkerElement({
            position,
            content: element
          });
        }
      } catch (error) {
        console.error('Error loading AdvancedMarkerView:', error);
      }
    };

    // Call the async function to load AdvancedMarkerView
    loadAdvancedMarkerView();
  }, []);

  useEffect(() => {
    if (container) {
      ReactDOM.render(children, container);
    }
    if (markerRef?.current) {
      markerRef.current.position = position;
      markerRef.current.map = map;

      let clickListener;

      if (showInfoWindow) {
        const info = new google.maps.InfoWindow({
          content: infoWindow
        });

        clickListener = markerRef.current.addListener('click', () => {
          info.open(map, markerRef.current);
        });
      } else {
        clickListener = markerRef.current.addListener('click', onClick);
      }

      return () => {
        clickListener.remove();
      };
    }
  }, [map, position, children, onClick, container]);

  useEffect(() => {
    if (markerRef?.current && hovered) {
      markerRef.current.zIndex = 999;
    }
    if (markerRef?.current && !hovered) {
      markerRef.current.zIndex = null;
    }
  }, [hovered]);

  return null;
};

export default AdvancedMarker;

// import React, { useContext, useState } from 'react';
// import CommentInput from './CommentInput';
// import CommentList from './CommentList';
// import API from '../../libs/axios';
// import moment from 'moment';
// import { SharedContext } from '../../utils/common';
//
// const ChatBot = () => {
//   const chatList = localStorage.getItem('chat');
//   const [chat, setChat] = useState(JSON.parse(chatList) || []);
//   const [message, setMessage] = useState('');
//   const [postLoader, setPostLoader] = useState(false);
//   const { currentUser } = useContext(SharedContext);
//
//   function generateUniqueId() {
//     return Math.random().toString(36).substring(2) + Date.now().toString(36);
//   }
//
//   const postComment = async () => {
//     setPostLoader(true);
//     let arr = [];
//     const uniqueId = generateUniqueId();
//     const uniqueId1 = generateUniqueId();
//     arr.push(
//       { id: uniqueId, name: 'O360 AI', message: '', updatedAt: moment() },
//       {
//         id: uniqueId1,
//         name: currentUser?.firstName + ' ' + currentUser?.lastName,
//         message: message,
//         updatedAt: moment()
//       }
//     );
//     setChat([...arr, ...chat]);
//     const index = arr.findIndex((element) => element.id === uniqueId);
//     try {
//       const response = await API.get(`chatbot/query`, {
//         params: {
//           query: `${message} and remove extra quotes from query as it's not giving correct results`
//         }
//       });
//       if (index !== -1) {
//         arr[index] = {
//           ...arr[index],
//           ...{ message: response?.response?.result }
//         };
//       }
//       setChat([...arr, ...chat]);
//       localStorage.setItem('chat', JSON.stringify([...arr, ...chat]));
//     } catch (e) {
//       console.log(e);
//     } finally {
//       setMessage('');
//       setPostLoader(false);
//     }
//   };
//
//   return (
//     <div className={'flex flex-col h-screen p-4'}>
//       <CommentList chatList={chat} loader={postLoader} />
//       <div className={'max-h-28 py-1 bg-white inset-x-4 mb-14'}>
//         <CommentInput
//           message={message}
//           setMessage={setMessage}
//           postComment={postComment}
//           postLoader={postLoader}
//         />
//       </div>
//     </div>
//   );
// };
//
// export default ChatBot;

import React, { useContext, useState } from 'react';
import { Fab, IconButton } from '@mui/material';
import OAI from '../../assets/icons/OAi.png';
import { Close } from '@mui/icons-material';
import CloseIcon from '@material-ui/icons/Remove';
import { Heading } from '../../core-components/atoms/Text';
import CommentInput from './CommentInput';
import CommentList from './CommentList';
import API from '../../libs/axios';
import moment from 'moment';
import { SharedContext } from '../../utils/common';

// const FloatingBox = styled(Box)`
//   position: fixed;
//   bottom: 110px;
//   right: 30px;
//   width: 400px;
//   height: 500px;
//   background-color: white;
//   border: 1px solid #e5e7eb; /* Tailwind's gray-200 */
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 16px;
// `;

const ChatBot = () => {
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const handleToggle = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  const chatList = localStorage.getItem('chat');
  const [chat, setChat] = useState(JSON.parse(chatList) || []);
  const [message, setMessage] = useState('');
  const [postLoader, setPostLoader] = useState(false);
  const { currentUser } = useContext(SharedContext);

  function generateUniqueId() {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  const postComment = async () => {
    setPostLoader(true);
    let arr = [];
    const uniqueId = generateUniqueId();
    const uniqueId1 = generateUniqueId();
    arr.push(
      { id: uniqueId, name: 'Oai', message: '', updatedAt: moment() },
      {
        id: uniqueId1,
        name: currentUser?.firstName + ' ' + currentUser?.lastName,
        message: message,
        updatedAt: moment()
      }
    );
    setChat([...arr, ...chat]);
    const index = arr.findIndex((element) => element.id === uniqueId);
    try {
      const response = await API.get(`chatbot/query`, {
        params: {
          query: `${message} and remove extra quotes from query as it's not giving correct results`
        }
      });
      if (index !== -1) {
        arr[index] = {
          ...arr[index],
          ...{ message: response?.response?.result }
        };
      }
      setChat([...arr, ...chat]);
      localStorage.setItem('chat', JSON.stringify([...arr, ...chat]));
    } catch (e) {
      console.log(e);
    } finally {
      setMessage('');
      setPostLoader(false);
    }
  };

  return (
    <>
      <div className="fixed bottom-8 right-8">
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleToggle}
          className={'rounded-full p-1 h-16 w-16 bg-primaryBlue'}
        >
          {isBoxVisible ? (
            <Close fontSize={'large'} sx={{ color: 'white' }} />
          ) : (
            <img src={OAI} alt="oai" className="rounded-full" />
          )}
        </Fab>
      </div>
      {isBoxVisible && (
        <div className="fixed bottom-28 right-8 bg-white rounded-lg shadow-lg border border-[#e5e7eb] w-[400px] h-[600px] z-[999]">
          <div className="bg-primaryBlue flex text-white items-center justify-between pl-4 rounded-t-lg">
            <Heading variant={'card'}>{'Welcome to Oai'}</Heading>
            <IconButton onClick={handleToggle} className="text-white">
              <CloseIcon />
            </IconButton>
          </div>
          <div className={'flex flex-col h-[570px] p-4'}>
            <CommentList
              chatList={chat}
              loader={postLoader}
              setMessage={setMessage}
            />
            <div className={'max-h-28 py-1 bg-white inset-x-4 mb-2'}>
              <CommentInput
                message={message}
                setMessage={setMessage}
                postComment={postComment}
                postLoader={postLoader}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBot;

import {
  Heading,
  Label,
  Paragraph,
  Title
} from '../../../core-components/atoms/Text';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import moment from 'moment/moment';
import CustomPagination from '../../invoiceandbilling/invoiceManagement/CustomPagination';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { SharedContext } from '../../../utils/common';
import mixpanel from 'mixpanel-browser';

export const PendingOrders = () => {
  const [page, setPage] = useState(1);
  const [taskData, setTaskData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const { setAPILoader } = useContext(SharedContext);
  useEffect(() => {
    getOrders();
    mixpanel.track('Tasks/Pending Orders Page', {});
  }, []);

  useEffect(() => {
    getTasks({
      page
    });
  }, [page]);

  const getTasks = useCallback(
    debounce(({ page }) => {
      fetchRes({
        page
      });
    }, DEBOUNCE_CONST),
    []
  );

  const fetchRes = ({ page }) => {
    getOrders(page);
  };

  const getOrders = async (page) => {
    setAPILoader(true);
    try {
      let params = {
        statuses: ['PENDING', 'PARTIALLY_COMPLETE', 'IN_PROGRESS'],
        page,
        limit: 10
      };
      let res = await API.get('orders', {
        params,
        columns: []
      });
      setTaskData(res.data);
      setPageCount(res.pages);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <div>
      <div className="mt-18 flex justify-between items-center">
        <Heading variant={'section'}>Pending Orders</Heading>
      </div>
      <Grid className="box-border w-full border-[1px] mt-[8px] rounded-md border-solid border-dark300">
        <Table aria-label="collapsible table" className="parent-DataTable">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ width: '15%', padding: '16px 6px 16px 10px' }}
                className={`w-[120px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Order Number
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '18%', padding: '16px 4px' }}
                className={`w-[240px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Origin & Destination
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '15%', padding: '16px 4px' }}
                className={`w-[120px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Customer
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '13%', padding: '16px 4px' }}
                className={`w-[140px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  SUPPORTING DOC ID
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '20%', padding: '16px 4px' }}
                className={`w-[120px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Date of Order
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '20%', padding: '16px 4px' }}
                className={`w-[120px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Picking ID
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '20%', padding: '16px 4px' }}
                className={`w-[120px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Order qty
                </Label>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskData?.map((order) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow>
                  <TableCell
                    sx={{ width: '10%', padding: '16px 6px 16px 10px' }}
                  >
                    <Title className={'sm'}>{order?.customId}</Title>
                  </TableCell>
                  <TableCell sx={{ width: '18%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {order?.pickupNode?.name} ➜ {order?.dropOffNode?.name}
                    </Paragraph>
                  </TableCell>
                  <TableCell sx={{ width: '15%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>{order?.Company?.name}</Paragraph>
                  </TableCell>
                  <TableCell sx={{ width: '13%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {order?.referenceId || '-'}
                    </Paragraph>
                  </TableCell>
                  <TableCell sx={{ width: '20%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {moment(order.createdAt).format('DD/MM/YY')}
                    </Paragraph>
                  </TableCell>
                  <TableCell sx={{ width: '20%', padding: '16px 4px' }}>
                    <Paragraph variant={'sm'}>
                      {order?.warehousePickUpId || '-'}
                    </Paragraph>
                  </TableCell>
                  <TableCell
                    sx={{ width: '20%', padding: '16px 4px' }}
                    className="font-roboto text-normal text-xs text-dark800"
                  >
                    {order?.OrderProductGroups?.map(
                      (val) => val.quantity
                    )?.reduce((total, value) => total + value)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item />
        <Grid item>
          <CustomPagination page={page} pages={pageCount} setPage={setPage} />
        </Grid>
      </Grid>
    </div>
  );
};

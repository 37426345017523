import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Paper, Popper } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClickAwayListener } from '@mui/base';
import clsx from 'clsx';
import { Label, Paragraph } from '../../../../core-components/atoms/Text';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../../Config';

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      zIndex: 1300
    },
    paper: {
      pointerEvents: 'auto',
      width: '100%',
      borderRadius: '4px',
      marginTop: '2px',
      boxShadow:
        '0px 6.400000095367432px 14.399999618530273px 0px #00000021,0px 1.2000000476837158px 3.5999999046325684px 0px #0000001A',
      overflowY: 'auto',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& input': {
        fontSize: '12px !important'
      },
      border: '1px #E8ECF1',
      backgroundColor: theme.palette.background.paper,
      alignItems: ' flex-start',
      maxHeight: '400px',
      overflow: 'auto'
    }
  };
});

const CategoryView = ({ anchorEl, handleClose, categoryDetail, isBrand }) => {
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setPage(1);
      getDelayedProducts(categoryDetail, 1, [], isBrand);
    }
  }, [categoryDetail, open, isBrand]);

  const getDelayedProducts = useCallback(
    debounce(
      async (categoryDetail, page, oldData, isBrand) => {
        getProudctsList(categoryDetail, page, oldData, isBrand);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getProudctsList = async (categoryDetail, page, oldData, isBrand) => {
    try {
      const data = await API.get(`products`, {
        params: {
          page: page,
          companyId: categoryDetail?.Company?.id,
          ...(isBrand
            ? { brandId: categoryDetail.id }
            : { categoryId: categoryDetail.id })
        }
      });
      const newArr = data?.data;
      setProductList([...oldData, ...newArr]);
      setPageCount(data?.pages);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement={'bottom-end'}
      disablePortal
      className={classes.popper}
      modifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: true,
          boundariesElement: 'viewport'
        }
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          handleClose();
        }}
      >
        <Paper className={classes.paper} id={'scrollableDiv'}>
          <div
            className={'w-[200px] max-h-[400px] p-4 overflow-auto no-scrollbar'}
            id={'scrollableDivProducts'}
          >
            <div
              className={clsx(
                'px-1.5 flex justify-between items-center',
                ' bg-white'
              )}
            >
              <Label variant={'sm'} className={'text-dark500'}>
                {categoryDetail?.name}
              </Label>
            </div>
            <InfiniteScroll
              dataLength={productList.length}
              next={async () => {
                if (page < pageCount) {
                  setPage(page + 1);
                  getDelayedProducts(
                    categoryDetail,
                    page + 1,
                    productList,
                    isBrand
                  );
                }
              }}
              scrollThreshold={0.1}
              style={{
                flex: 1
              }}
              hasMore={page < pageCount}
              pullDownToRefresh={false}
              loader={
                <div
                  className={'flex flex-1 overflow-hidden pb-4 justify-center'}
                >
                  <CircularProgress size={20} />
                </div>
              }
              scrollableTarget="scrollableDivProducts"
            >
              {productList?.map((value, index) => {
                return (
                  <div
                    key={index}
                    className={clsx(
                      'px-1.5 py-3 flex justify-between items-center',
                      index % 2 === 0 ? ' bg-white' : ' bg-gray-200'
                    )}
                  >
                    <Paragraph variant={'sm'}>
                      {value?.name} - {value?.description}
                    </Paragraph>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default CategoryView;

import * as React from 'react';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import CancelInvoice from './CancelInvoice';
import { SharedContext } from '../../../utils/common';
import { checkPermission } from '../../../utils/auth';

export default function OptionsMenu({
  invoice,
  onCancel,
  onViewPayments,
  onClickPdf,
  type,
  sendEmail
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cancelPopUp, setCancelPopUp] = React.useState(false);
  const { currentUser } = React.useContext(SharedContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: 'flex-start',
      marginTop: '100px'
    },
    topPaperScrollBody: {
      verticalAlign: 'top'
    }
  });

  const classes = useStyles();

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon sx={{ width: 32, height: 32 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            onClickPdf(invoice, true);
            handleClose();
          }}
          className="block px-4 py-1"
        >
          Download PDF
        </MenuItem>
        {!type && invoice.status !== 'PAID' && (
          <MenuItem
            onClick={async () => {
              await sendEmail(invoice);
              handleClose();
            }}
            className="block px-4 py-1"
          >
            Send Reminder Email
          </MenuItem>
        )}
        {invoice.status === 'PENDING' || invoice.status === 'OVERDUE' ? (
          (type
            ? checkPermission(currentUser, 'OPS_EXPENSE_CANCEL')
            : checkPermission(currentUser, 'OPS_INVOICE_CANCEL')) && (
            <MenuItem
              onClick={() => {
                setCancelPopUp(true);
                handleClose();
              }}
              className="block px-4 py-1"
            >
              Cancel
            </MenuItem>
          )
        ) : (
          <MenuItem
            onClick={() => {
              onViewPayments(invoice);
              handleClose();
            }}
            className="block px-4 py-1"
          >
            View Payment details
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={cancelPopUp}
        onClose={() => {
          setCancelPopUp(false);
        }}
        BackdropProps={{ style: { backgroundColor: '#0B1940' } }}
        scroll="paper"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setCancelPopUp(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent>
          <CancelInvoice
            onCancel={onCancel}
            invoice={invoice}
            closePopUp={() => setCancelPopUp(false)}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import { Popover, List, ListItem, Checkbox } from '@mui/material';
import { InputAdornment } from '@mui/material';
import Button from '../../../core-components/atoms/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import PIN_NODE from '../../../assets/icons/pin_nodes.svg';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { Paragraph } from '../../../core-components/atoms/Text';

const useStyles = makeStyles(() => ({
  popOver: {
    maxHeight: '350px',
    '& ::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      height: '4px'
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#9DA0A6'
    },
    pointerEvents: 'none'
  },
  search: {
    margin: '1rem auto',
    width: '90%',
    boxSizing: 'border-box',
    display: 'block'
  },
  subMenuPaper: {
    pointerEvents: 'auto',
    border: '1px solid #E7E6E6'
  }
}));

function Filters({ setNodeIdsIntelligence, nodeIdsIntelligence }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [organizationNodes, setOrganizationNodes] = useState([]);
  const secondaryRef = useRef();
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else {
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getOrgNodes();
  }, []);

  const getOrgNodes = async (search) => {
    try {
      const response = await API.get(`nodes`, {
        params: { search: search, nodeClass: 'INTERNAL' }
      });
      setOrganizationNodes(response?.data);
    } catch (err) {
      onError(err);
    }
  };

  const handleCheckboxChange = (event) => {
    const node = { name: event.target.name, id: event.target.value }; // value should be used to store the id

    if (event.target.checked) {
      setNodeIdsIntelligence([...nodeIdsIntelligence, node]);
    } else {
      setNodeIdsIntelligence(
        nodeIdsIntelligence.filter(
          (existingNode) => existingNode.id !== node.id
        )
      );
    }
  };

  return (
    <div>
      <Button
        label={
          nodeIdsIntelligence.length <= 0
            ? 'All Nodes'
            : nodeIdsIntelligence.length === 1
            ? nodeIdsIntelligence[0].name
            : 'Selected Nodes'
        }
        className={clsx('py-2 px-3 ml-2 h-8 mb-0 rounded-full border-0')}
        overrideSize={true}
        variant={'transparent'}
        labelClass="font-medium text-xs text-white"
        icon={<img src={PIN_NODE} alt="Export Icon" className="mr-2" />}
        iconClass="mr-0"
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={classes.popOver}
        classes={{
          paper: classes.subMenuPaper
        }}
        PaperProps={{
          onMouseLeave: handleClose
        }}
        ref={secondaryRef}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <div className="flex flex-col px-2 mt-4">
          <TextField
            variant="outlined"
            placeholder="Search"
            fullWidth
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              getOrgNodes(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            // className="border border-b-2 border-gray-300 pb-1"
          />
          <List
            className={'no-scrollbar overflow-y-auto'}
            sx={{
              overflow: 'auto',
              maxHeight: 235,
              width: 250
            }}
          >
            {organizationNodes.map((warehouse, i) => (
              <ListItem key={i} className="flex justify-content-left">
                <Checkbox
                  checked={nodeIdsIntelligence.some(
                    (node) => node.id == warehouse.id
                  )}
                  onChange={handleCheckboxChange}
                  name={warehouse.name}
                  value={warehouse.id}
                />
                <Paragraph
                  variant={'md'}
                  htmlFor={warehouse?.code}
                  className={'ml-2'}
                >
                  {warehouse?.code} - {warehouse?.name}
                </Paragraph>
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
}

export default Filters;

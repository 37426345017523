/*eslint-disable*/
import * as React from 'react';
import Box from '@mui/material/Box';
import PromoIcon from '../../../assets/icons/applyPromoIconFaded.svg';
import Button from '../../../core-components/atoms/Button';
import { FormControl, Popover } from '@mui/material';
import clsx from 'clsx';
import { SharedContext, decimalDisplay } from '../../../utils/common';
import TextField from '../../../core-components/atoms/TextField';
import { useState, useEffect } from 'react';
import { onError } from '../../../libs/errorLib';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import { PromoScreen } from '../constants';
import { ChevronLeft } from '@material-ui/icons';
import { checkPermission } from '../../../utils/auth';

export default function PromoPopup({
  open,
  closeCallback,
  enterCallBack,
  selectedInventoryDetails,
  refetch,
  setConfirmationPopup,
  removeQuantity,
  setRemoveQuantity,
  editMode,
  currentUser
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [promoName, setPromoName] = useState('');
  const [promoQuantity, setPromoQuantity] = useState();
  const [currentScreen, setCurrentScreen] = useState(PromoScreen.APPLY);
  const [formErrors, setFormErrors] = useState('');

  const { setAPILoader } = React.useContext(SharedContext);
  // const open = Boolean(anchorEl);

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '8px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& label': {
      fontSize: '14px'
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    enterCallBack && enterCallBack();
  };

  const handleClose = () => {
    setAnchorEl(null);
    closeCallback && closeCallback();
    setTimeout(function () {
      if (!selectedInventoryDetails?.promoName) {
        setPromoName('');
        setPromoQuantity(0);
        // setFormErrors('');
        // setRemoveQuantity(0);
        setCurrentScreen(PromoScreen.APPLY);
      }
    }, 500);
  };

  const applyPromo = async () => {
    const payload = {
      promoName,
      promoQuantity
    };
    try {
      setAPILoader(true);
      await API.post(
        `promo/${selectedInventoryDetails?.promoName ? 'edit/' : ''}${
          selectedInventoryDetails?.id
        }`,
        payload
      );
      handleClose();
      toaster('success', 'Promo has been applied');
      refetch();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (open) {
      setPromoName(selectedInventoryDetails?.promoName || '');
      setPromoQuantity(
        selectedInventoryDetails?.palletId
          ? selectedInventoryDetails?.availableQuantity
          : selectedInventoryDetails?.promoQuantity || 0
      );
      setRemoveQuantity(selectedInventoryDetails?.promoQuantity || 0);
      setCurrentScreen(PromoScreen.APPLY);
    }
  }, [selectedInventoryDetails, open]);

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div className="flex gap-[6px]" onClick={(e) => handleClick(e)}>
          <img src={PromoIcon} className="w-4" />
          <span className="text-xs text-primaryBlue">Promo</span>
        </div>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 0
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={0}
      >
        {currentScreen === PromoScreen.APPLY && (
          <div
            className={clsx(
              'w-[310px] p-4 rounded-[8px] bg-white z-10 transition-all shadow-elevation-2 overflow-hidden'
            )}
          >
            <p className="text-primaryBlue text-base font-medium mt-2">
              Apply Promo
            </p>
            <p className="text-sm text-cadetGray mb-6">
              Available Quantity:{' '}
              <span className="text-primaryBlue">
                {decimalDisplay(selectedInventoryDetails?.availableQuantity)}
              </span>
            </p>
            <FormControl className="mb-4" fullWidth variant="outlined">
              <TextField
                value={promoName}
                onChange={(e) => setPromoName(e.target.value)}
                type="text"
                label="Code"
                sxProps={sxProps}
                size="small"
                required
                // disabled={formik.values.orderType?.value === 'Sales Return'}
              />
            </FormControl>
            <FormControl className="mb-4" fullWidth variant="outlined">
              <TextField
                value={promoQuantity}
                type="number"
                label="Quantity"
                sxProps={sxProps}
                size="small"
                disabled={selectedInventoryDetails?.palletId}
                onChange={(e) => {
                  const enteredValue = parseFloat(e.target.value);
                  if (!isNaN(enteredValue)) {
                    e.target.value =
                      enteredValue > selectedInventoryDetails?.availableQuantity
                        ? selectedInventoryDetails?.availableQuantity
                        : enteredValue;
                  }
                  setPromoQuantity(e.target.value);
                }}
                // disabled={formik.values.orderType?.value === 'Sales Return'}
              />
            </FormControl>

            <div className="w-full flex justify-between">
              <div>
                {editMode &&
                  checkPermission(currentUser, 'OPS_PROMO_DELETE') && (
                    <Button
                      size="small"
                      variant="text"
                      label="Remove Promo"
                      className={'py-2 px-3 h-8'}
                      overrideSize={true}
                      labelClass="font-medium text-xs text-dusk600"
                      onClick={() => {
                        setCurrentScreen(PromoScreen.REMOVE);
                      }}
                    />
                  )}
              </div>
              <div className="flex">
                <Button
                  size="small"
                  variant="text"
                  label="Cancel"
                  className={'py-2 px-3 h-8'}
                  overrideSize={true}
                  labelClass="font-medium text-xs"
                  onClick={() => {
                    handleClose();
                  }}
                />
                <Button
                  size="small"
                  variant="primary"
                  label="Confirm"
                  className={
                    'py-2 px-3 ml-1 h-8 border-solid border border-Primary'
                  }
                  overrideSize={true}
                  labelClass="font-medium text-xs"
                  disabled={
                    promoName === '' ||
                    !promoQuantity ||
                    promoQuantity >
                      selectedInventoryDetails?.availableQuantity ||
                    parseFloat(promoQuantity) === 0.0
                  }
                  onClick={applyPromo}
                />
              </div>
            </div>
          </div>
        )}
        {currentScreen === PromoScreen.REMOVE && (
          <div
            className={clsx(
              'w-[310px] p-4 rounded-[8px] bg-white z-10 transition-all shadow-elevation-2 overflow-hidden'
            )}
          >
            <div className="flex gap-1 items-center mt-2">
              <ChevronLeft
                style={{ color: '#9DA3B3', cursor: 'pointer' }}
                onClick={() => setCurrentScreen(PromoScreen.APPLY)}
              />
              <p className="text-primaryBlue text-base font-medium ">
                Remove Promo
              </p>
            </div>

            {selectedInventoryDetails?.palletId ? (
              <p className="text-sm text-cadetGray mb-6">
                Pallet ID items will have promo applied to all available
                quantity
              </p>
            ) : (
              <p className="text-sm text-cadetGray mb-6">
                Quantity that can be removed:{' '}
                <span className="text-primaryBlue">
                  {selectedInventoryDetails?.promoQuantity}
                </span>
              </p>
            )}
            <FormControl className="mb-4" fullWidth variant="outlined">
              <TextField
                value={removeQuantity}
                type="number"
                label="Quantity"
                sxProps={sxProps}
                size="small"
                onChange={(e) => {
                  const enteredValue = parseFloat(e.target.value);
                  if (!isNaN(enteredValue)) {
                    e.target.value =
                      enteredValue > selectedInventoryDetails?.promoQuantity
                        ? selectedInventoryDetails?.promoQuantity
                        : enteredValue;
                  }
                  setRemoveQuantity(e.target.value);
                }}
              />
            </FormControl>

            <div className="w-full flex justify-end">
              <Button
                size="small"
                variant="primary"
                label="Remove Promo"
                className={
                  'py-2 px-3 ml-1 h-8 border-solid border border-Primary'
                }
                overrideSize={true}
                labelClass="font-medium text-xs"
                disabled={removeQuantity <= 0}
                onClick={() => {
                  setConfirmationPopup(true);
                  setAnchorEl(null);
                  closeCallback && closeCallback();
                }}
              />
            </div>
          </div>
        )}
      </Popover>
    </React.Fragment>
  );
}

/*eslint-disable*/
import {
  DAMAGED_TO_GOOD_STOCK_CONDITION_MESSAGE,
  GOOD_TO_DAMAGED_STOCK_CONDITION_MESSAGE,
  InventoryManagementTabsMapping,
  OriginCell
} from '../constants';
import { decimalDisplay } from './common';
export const isUsableBatch = (batch) => {
  return batch.goodQuantity > 0 || batch.holdQuantity > 0;
};
export const isDamagedBatch = (batch) => {
  return (
    batch.recoverableDamageQuantity > 0 || batch.unsellableDamageQuantity > 0
  );
};
export const getUpdatedStockCondition = ({
  batch,
  inventoryBatches,
  isBatchRemoved
}) => {
  const { id: batchId } = batch;
  const index = inventoryBatches?.findIndex(({ id }) => batchId === id);
  // Find if the batch already exists (i-e good/damaged inventory was updated)
  if (index !== -1) {
    return inventoryBatches.map((inventory, currentIndex) => {
      if (currentIndex === index) {
        const {
          recoverableDamageQuantity,
          unsellableDamageQuantity,
          availableQuantity,
          goodQuantity,
          promoName,
          promoQuantity
        } = batch;
        const message = isDamagedBatch(batch)
          ? GOOD_TO_DAMAGED_STOCK_CONDITION_MESSAGE
          : isUsableBatch(batch)
          ? DAMAGED_TO_GOOD_STOCK_CONDITION_MESSAGE
          : '';

        return {
          ...inventory,
          recoverableDamageQuantity,
          unsellableDamageQuantity,
          availableQuantity,
          goodQuantity,
          promoName,
          promoQuantity,
          updatedFrom: {
            message: isBatchRemoved ? message : null,
            recoverableDamageQuantity: inventory.recoverableDamageQuantity,
            unsellableDamageQuantity: inventory.unsellableDamageQuantity,
            availableQuantity: inventory.availableQuantity,
            goodQuantity: inventory.goodQuantity
          }
        };
      }
      return {
        ...inventory
      };
    });
  }
  // if no matching batch exists i-e Good quantity was marked as damaged and no damage was existing previously, and vice versa
  // add marker that its updated and append
  const message = isDamagedBatch(batch)
    ? GOOD_TO_DAMAGED_STOCK_CONDITION_MESSAGE
    : isUsableBatch(batch)
    ? DAMAGED_TO_GOOD_STOCK_CONDITION_MESSAGE
    : '';
  batch.updatedFrom = {
    message: isBatchRemoved ? message : null
  };
  return [...inventoryBatches, batch];
};
export const removeBatchOfId = ({ id, inventoryBatches }) => {
  return inventoryBatches.reduce((acc, batch) => {
    if (batch.id === id) {
      return [...acc];
    }
    return [
      ...acc,
      {
        ...batch
      }
    ];
  }, []);
};
export const getToolTipMessage = (
  id,
  lastConditionChange,
  fallbackMessage,
  origin
) => {
  if (!lastConditionChange) {
    return fallbackMessage;
  }
  let newMessage = fallbackMessage;
  lastConditionChange?.InventoryDetail?.forEach((invDetail) => {
    if (invDetail.id !== id) {
      return;
    }
    switch (origin) {
      case OriginCell.AVAILABLE:
        if (invDetail?.updatedFrom?.message) {
          newMessage = invDetail?.updatedFrom?.message;
        } else if (invDetail?.updatedFrom) {
          const difference =
            parseFloat(invDetail?.updatedFrom?.availableQuantity) -
            parseFloat(invDetail?.availableQuantity);
          newMessage = difference
            ? `Quantity updated to ${
                invDetail?.availableQuantity
              }. ${decimalDisplay(difference)} item${
                difference > 1 ? 's' : ''
              } moved ${difference < 0 ? 'from' : 'to'} Damaged`
            : fallbackMessage;
        }
        break;
      case OriginCell.UNSELLABLE:
        if (invDetail?.updatedFrom?.message) {
          newMessage = invDetail?.updatedFrom?.message;
        } else if (
          invDetail?.updatedFrom &&
          parseFloat(invDetail?.updatedFrom?.unsellableDamageQuantity) !==
            parseFloat(invDetail?.unsellableDamageQuantity)
        ) {
          const difference =
            parseFloat(invDetail?.updatedFrom?.unsellableDamageQuantity) -
            parseFloat(invDetail?.unsellableDamageQuantity);
          newMessage = difference
            ? `Unsellable quantity ${
                difference > 0 ? 'decreased' : 'increased'
              } to ${invDetail?.unsellableDamageQuantity}`
            : fallbackMessage;
        }
        break;
      case OriginCell.RECOVERABLE:
        if (invDetail?.updatedFrom?.message) {
          newMessage = invDetail?.updatedFrom?.message;
        } else if (
          invDetail?.updatedFrom &&
          parseFloat(invDetail?.updatedFrom?.recoverableDamageQuantity) !==
            parseFloat(invDetail?.recoverableDamageQuantity)
        ) {
          const difference =
            parseFloat(invDetail?.updatedFrom?.recoverableDamageQuantity) -
            parseFloat(invDetail?.recoverableDamageQuantity);
          newMessage = difference
            ? `Recoverable quantity ${
                difference > 0 ? 'decreased' : 'increased'
              } to ${invDetail?.recoverableDamageQuantity}`
            : fallbackMessage;
        }
        break;
    }
  });
  return newMessage;
};

export const isBatchUpdated = ({ id, lastConditionChange, origin }) => {
  if (!lastConditionChange) {
    return false;
  }
  let updated = false;
  lastConditionChange?.InventoryDetail?.forEach((invDetail) => {
    if (invDetail.id !== id) {
      return;
    }
    switch (origin) {
      case OriginCell.AVAILABLE:
        if (invDetail?.updatedFrom) {
          const difference =
            parseFloat(invDetail?.updatedFrom?.availableQuantity) -
            parseFloat(invDetail?.availableQuantity);
          updated = difference < 0;
        }
        break;
      case OriginCell.UNSELLABLE:
        if (
          invDetail?.updatedFrom &&
          parseFloat(invDetail?.updatedFrom?.unsellableDamageQuantity) !==
            parseFloat(invDetail?.unsellableDamageQuantity)
        ) {
          const difference =
            parseFloat(invDetail?.updatedFrom?.unsellableDamageQuantity) -
            parseFloat(invDetail?.unsellableDamageQuantity);
          updated = difference < 0;
        }
        break;
      case OriginCell.RECOVERABLE:
        if (
          invDetail?.updatedFrom &&
          parseFloat(invDetail?.updatedFrom?.recoverableDamageQuantity) !==
            parseFloat(invDetail?.recoverableDamageQuantity)
        ) {
          const difference =
            parseFloat(invDetail?.updatedFrom?.recoverableDamageQuantity) -
            parseFloat(invDetail?.recoverableDamageQuantity);
          updated = difference < 0;
        }
        break;
    }
  });
  return updated;
};

export const getConditionUpdateSummary = ({
  type,
  usableQuantity,
  recoverableQuantity,
  unsellableQuantity,
  inventoryDetail
}) => {
  if (type === InventoryManagementTabsMapping.DAMAGED) {
    return `${
      usableQuantity > 0 ? decimalDisplay(usableQuantity) + ' Usable ' : ''
    }${usableQuantity > 0 && unsellableQuantity > 0 ? ' & ' : ''}${
      unsellableQuantity > 0
        ? decimalDisplay(unsellableQuantity) + ' Unsellable '
        : ''
    } has been updated ${
      inventoryDetail?.batchNumber
        ? ' to Batch ' + inventoryDetail?.batchNumber
        : ''
    }`;
  }
  if (type === InventoryManagementTabsMapping.USABLE) {
    return `${
      recoverableQuantity > 0
        ? decimalDisplay(recoverableQuantity) + ' Recoverable '
        : ''
    }${recoverableQuantity > 0 && unsellableQuantity > 0 ? ' & ' : ''}${
      unsellableQuantity > 0
        ? decimalDisplay(unsellableQuantity) + ' Unsellable '
        : ''
    } has been updated ${
      inventoryDetail?.batchNumber
        ? ' to Batch ' + inventoryDetail?.batchNumber
        : ''
    }`;
  }
};
export const getInventoryAlertMessage = ({
  inventory,
  detailView = false,
  damages = false
}) => {
  let expiredQuantity = 0;
  let nearExpiryQuantity = 0;
  if (!detailView) {
    expiredQuantity = inventory?.expiredQuantity;
    nearExpiryQuantity = inventory?.nearExpiryQuantity;
  } else {
    if (damages) {
      inventory?.expiredQuantity > 0
        ? (expiredQuantity =
            inventory?.recoverableDamageQuantity +
            inventory?.unsellableDamageQuantity)
        : (nearExpiryQuantity =
            inventory?.recoverableDamageQuantity +
            inventory?.unsellableDamageQuantity);
    } else {
      inventory?.expiredQuantity > 0
        ? (expiredQuantity =
            inventory?.availableQuantity +
            inventory?.reservedQuantity +
            inventory?.holdQuantity)
        : (nearExpiryQuantity =
            inventory?.availableQuantity +
            inventory?.reservedQuantity +
            inventory?.holdQuantity);
    }
  }

  let result = '';

  if (expiredQuantity > 0) {
    result = `${decimalDisplay(expiredQuantity)} Expired.`;
    if (nearExpiryQuantity > 0) {
      result += ' ';
    }
  }

  if (nearExpiryQuantity > 0) {
    result += `${decimalDisplay(nearExpiryQuantity)} Near Expiry.`;
  }

  return result;
};

import React from 'react';

export default function TabsBtn(props) {
  return (
    <button
      className={
        props?.color && props?.color == 'active'
          ? 'activeColor tabsBtn'
          : 'tabsBtn'
      }
      type="button"
      onClick={() => props.setTab(props.tab)}
    >
      {props.text}
    </button>
  );
}

/*eslint-disable*/
import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { TableSortLabel } from '@mui/material';

import Payment from './Payment';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import { onError } from '../../../libs/errorLib';
import OptionsMenu from './OptionsMenu';
import PaymentListing from './PaymentListing';
import { constants } from './constants';
import { toaster } from '../../../utils/toaster';
import { checkPermission } from '../../../utils/auth';
import delIcon from '../../../assets/icons/del.svg';
import { formatPrice } from '../invoices/InvoiceForm';
import { PRIMARY } from '../../../constants/colors';
import DownloadIcon from '../../../assets/icons/downloadIcon.svg';

export default function InvoiceTable({
  invoices,
  getInvoices,
  tab,
  type,
  order,
  orderBy,
  handleSort,
  getStats,
  exportInvoices
}) {
  const useStyles = makeStyles(() => ({
    table: {
      borderRadius: '10px',
      border: '1px solid #dcdcdc',
      width: 'auto'
    },
    link: {
      textDecoration: 'underline',
      color: `${PRIMARY} !important`,
      padding: '2px',
      cursor: 'pointer'
    },
    invoiceTitle: {
      display: 'flex',
      flexDirection: 'column',
      '& span': {
        color: '#6A7289'
      }
    },
    statusDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      '& span': {
        color: '#0B1940'
      }
    },
    status: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block'
    },
    halfCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: 'linear-gradient( 90deg, white, white 50%, #1AAE2E 50%)',
      border: '2px solid #1AAE2E'
    },
    yellowCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: '#FFC000'
    },
    greenCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: '#1AAE2E'
    },
    redCircle: {
      borderRadius: '50%',
      width: '10px',
      height: '10px',
      display: 'inline-block',
      background: '#FF3B3B'
    },
    recordBtn: {
      color: ` ${PRIMARY} !important`,
      borderColor: `${PRIMARY}`,
      textTransform: 'none',
      '& span': {
        color: `${PRIMARY}`
      },
      '&:hover': {
        background: '#F1F8FF'
      }
    },
    tableHeader: {
      fontFamily: 'Poppins !important',
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '0.1px'
    },
    topScrollPaper: {
      alignItems: 'flex-start',
      marginTop: '100px'
    },
    topPaperScrollBody: {
      verticalAlign: 'top'
    },
    exportBtn: {
      padding: '8px 24px 8px 24px',
      border: '2px solid #0c71d7',
      color: '#0c71d7',
      textTransform: 'none',
      margin: '1rem',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: '#F1F8FF'
      }
    }
  }));

  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [active, setActive] = useState(null);
  const [paymentList, setPaymentsList] = useState(false);
  const { setAPILoader, currentUser } = useContext(SharedContext);

  const classes = useStyles();

  const addBillPayment = async (payload) => {
    try {
      setAPILoader(true);
      await API.post(`bill-payments`, payload);
      setPaymentPopUp(false);
      setActive(null);
      getInvoices();
      toaster('success', 'Payment added successfully');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const sendEmail = async (invoice) => {
    try {
      setAPILoader(true);
      await API.post(`invoices/email`, {
        invoiceId: invoice.id,
        companyId: invoice.companyId
      });
      toaster('success', 'Reminder email sent');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const onCancel = async (payload, id) => {
    try {
      setAPILoader(true);
      await API.put(`invoices/${id}`, {
        payload: { status: 'CANCELLED', ...payload }
      });
      getInvoices();
      getStats();
      toaster('success', 'Invoice cancelled successfully');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const onViewPayments = (invoice) => {
    setActive(invoice);
    setPaymentsList(true);
  };

  const onDeleteDraft = async (invoice) => {
    try {
      setAPILoader(true);
      await API.delete(`invoice-drafts/${invoice.id}`);
      navigate();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const openLink = async (invoice, download) => {
    try {
      setAPILoader(true);
      const link = document.createElement('a');
      const signedUrl = await API.post('invoices/pdf-preview', {
        key: invoice.number,
        download
      });
      link.href = signedUrl.preview.signedRequest;
      link.target = '_blank';
      link.click();
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const navigate = useNavigate();

  return (
    <Grid item xs={12} className="mt-10">
      <div className={classes.table}>
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="first-cell">
                <TableSortLabel
                  active={orderBy === 'number'}
                  direction={orderBy === 'number' ? order : 'asc'}
                  onClick={() => handleSort('number')}
                >
                  <Typography className={classes.tableHeader}>
                    {!type ? 'Invoice ID' : 'Bill ID'}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell">
                <TableSortLabel
                  active={orderBy === 'Company.name'}
                  direction={orderBy === 'Company.name' ? order : 'asc'}
                  onClick={() => handleSort('Company.name')}
                >
                  <Typography className={classes.tableHeader}>
                    {type ? 'Vendor' : 'Customer'}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell">
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={() => handleSort('type')}
                >
                  <Typography className={classes.tableHeader}>Type</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell">
                <Typography className={classes.tableHeader}>
                  Contract
                </Typography>
              </TableCell>
              <TableCell className="table-cell">
                <TableSortLabel
                  active={orderBy === 'dueDate'}
                  direction={orderBy === 'dueDate' ? order : 'asc'}
                  onClick={() => handleSort('dueDate')}
                >
                  <Typography className={classes.tableHeader}>
                    Due Date
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell">
                <Typography className={classes.tableHeader}>
                  Total Amount
                </Typography>
              </TableCell>
              {tab !== 2 ? (
                <>
                  {tab !== 1 && (
                    <TableCell className="table-cell">
                      <Typography className={classes.tableHeader}>
                        {type ? 'Paid' : 'Received'}
                      </Typography>
                    </TableCell>
                  )}

                  <TableCell className="table-cell">
                    <Typography className={classes.tableHeader}>
                      Status
                    </Typography>
                  </TableCell>

                  <TableCell className="table-cell" />
                </>
              ) : (
                <>
                  <TableCell className="table-cell">
                    <Typography className={classes.tableHeader}>
                      Remaining Amount
                    </Typography>
                  </TableCell>
                  <TableCell className="table-cell">
                    <Typography className={classes.tableHeader}>
                      Cancellation Reason
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.length > 0 &&
              invoices.map((invoice, index) => {
                let { status } = invoice;
                let statusClass =
                  status === 'PARTIALLY_PAID'
                    ? classes.halfCircle
                    : status === 'PENDING' || status === 'INCOMPLETE'
                    ? classes.yellowCircle
                    : status === 'OVERDUE'
                    ? classes.redCircle
                    : classes.greenCircle;

                return (
                  <TableRow key={index} style={{ backgroundColor: '#fff' }}>
                    <TableCell onClick={() => {}}>
                      <div className={classes.invoiceTitle}>
                        <span
                          className={classes.link}
                          onClick={() => invoice.pdf && openLink(invoice)}
                        >
                          {invoice.number}
                        </span>
                        <span>
                          <b>Created At:</b>{' '}
                          {moment(invoice.createdAt).format('DD/MM/YYYY')}
                        </span>
                        {invoice?.Warehouse && (
                          <span>
                            <b>Warehouse:</b> {invoice?.Warehouse?.name}
                          </span>
                        )}

                        {/* <embed src={invoice.pdf} width="100%" height="500px" type="application/pdf" /> */}
                      </div>
                    </TableCell>
                    <TableCell>{invoice?.Company?.name || '-'}</TableCell>
                    <TableCell>
                      {(invoice.type && constants.TYPES[invoice.type]) || '-'}
                    </TableCell>
                    <TableCell>{invoice?.contractType || '-'}</TableCell>
                    <TableCell>
                      {(invoice.dueDate &&
                        moment(invoice.dueDate).format('DD/MM/YYYY')) ||
                        '-'}
                    </TableCell>
                    <TableCell>
                      {invoice.total &&
                        `${constants.CURRENCY[invoice.currency]} ${
                          formatPrice(invoice.total) || '-'
                        }`}
                    </TableCell>
                    {tab != 2 ? (
                      <>
                        {tab != 1 && (
                          <TableCell>
                            {`${
                              constants.CURRENCY[invoice.currency]
                            } ${formatPrice(invoice.paidAmount)}`}
                          </TableCell>
                        )}

                        <TableCell>
                          <div className={classes.statusDiv}>
                            <span className={statusClass}></span>
                            <span>
                              {tab != 1
                                ? constants.STATUSES[invoice.status]
                                : constants.DRAFT_STATUSES[invoice.status]}
                            </span>
                          </div>
                        </TableCell>
                        {tab != 1 ? (
                          <>
                            <TableCell>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                {(type
                                  ? checkPermission(
                                      currentUser,
                                      'OPS_RECORD_PAYMENT_EXPENSE'
                                    )
                                  : checkPermission(
                                      currentUser,
                                      'OPS_RECORD_PAYMENT_INVOICE'
                                    )) &&
                                  invoice.paidAmount < invoice.total && (
                                    <Button
                                      variant="outlined"
                                      size="medium"
                                      className={classes.recordBtn}
                                      onClick={() => {
                                        setActive(invoice);
                                        setPaymentPopUp(true);
                                      }}
                                    >
                                      Record
                                    </Button>
                                  )}
                                <OptionsMenu
                                  invoice={invoice}
                                  onCancel={onCancel}
                                  onViewPayments={onViewPayments}
                                  onClickPdf={openLink}
                                  type={type}
                                  sendEmail={sendEmail}
                                />
                              </div>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '1rem',
                                  alignItems: 'center',
                                  marginLeft: '0.5rem',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                {invoice.status === 'INCOMPLETE' ? (
                                  (
                                    type
                                      ? checkPermission(
                                          currentUser,
                                          'OPS_EXPENSE_UPDATE'
                                        )
                                      : checkPermission(
                                          currentUser,
                                          'OPS_INVOICE_UPDATE'
                                        )
                                  ) ? (
                                    <ModeEditIcon
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        type
                                          ? navigate(
                                              `/billing-invoice/payables/edit/${invoice.id}`
                                            )
                                          : navigate(
                                              `/billing-invoice/receivables/edit/${invoice.id}`
                                            )
                                      }
                                    />
                                  ) : null
                                ) : (
                                    type
                                      ? checkPermission(
                                          currentUser,
                                          'OPS_EXPENSE_UPDATE'
                                        )
                                      : checkPermission(
                                          currentUser,
                                          'OPS_INVOICE_UPDATE'
                                        )
                                  ) ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      textTransform: 'none',
                                      background: '#0C71D7'
                                    }}
                                    onClick={() =>
                                      type
                                        ? navigate(
                                            `/billing-invoice/payables/edit/${invoice.id}`
                                          )
                                        : navigate(
                                            `/billing-invoice/receivables/edit/${invoice.id}`
                                          )
                                    }
                                  >
                                    <span style={{ color: '#fff' }}>
                                      Preview
                                    </span>
                                  </Button>
                                ) : null}
                                {checkPermission(
                                  currentUser,
                                  'OPS_INVOICE_DELETE'
                                ) && (
                                  <img
                                    src={delIcon}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteDraft(invoice);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <TableCell>{`Rs. ${invoice.total}`}</TableCell>
                        <TableCell>{`${invoice.cancellationReason}`}</TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Button
          onClick={exportInvoices}
          variant="outlined"
          className={classes.exportBtn}
        >
          <img src={DownloadIcon} className="mr-10" />
          Export
        </Button>
        {active && (
          <Dialog
            open={paymentPopUp}
            onClose={() => {
              setPaymentPopUp(false);
              setActive(null);
            }}
            BackdropProps={{ style: { backgroundColor: '#0B1940' } }}
            scroll="paper"
            classes={{
              scrollPaper: classes.topScrollPaper,
              paperScrollBody: classes.topPaperScrollBody
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setPaymentPopUp(false);
                  setActive(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <DialogContent>
              <Payment
                invoice={active}
                addBillPayment={addBillPayment}
                closePopUp={() => {
                  setPaymentPopUp(false);
                  setActive(null);
                }}
                billing={type}
              />
            </DialogContent>
          </Dialog>
        )}
        {active && (
          <Dialog
            open={paymentList}
            onClose={() => {
              setPaymentsList(false);
              setActive(null);
            }}
            BackdropProps={{ style: { backgroundColor: '#0B1940' } }}
            scroll="paper"
            classes={{
              scrollPaper: classes.topScrollPaper,
              paperScrollBody: classes.topPaperScrollBody
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setPaymentsList(false);
                  setActive(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <DialogContent>
              <PaymentListing
                payments={active.BillPayments}
                invoiceNumber={active.number}
                billing={type}
              />
            </DialogContent>
          </Dialog>
        )}
      </div>
    </Grid>
  );
}

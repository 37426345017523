/*eslint-disable*/

import React, { useContext, useEffect } from 'react';

import { SharedContext } from '../../utils/common';
import API from '../../libs/axios';
import quickbookicon from '../../assets/icons/quickbook.jpg';
import Button from '../../core-components/atoms/Button';
import { toaster } from '../../utils/toaster';
import config from '../../config/';

const QuickBooksIntegration = () => {
  const { organization, fetchOrganization, setAPILoader } =
    useContext(SharedContext);
  const fetchData = async () => {
    window.location.href = `${config.REACT_APP_API_PATH}/organizations/${organization.subdomain}/quickbooks/connect`;
  };

  const disconnect = async () => {
    setAPILoader(true);
    await API.post(
      `organizations/${organization.subdomain}/quickbooks/disconnect`,
      {}
    );
    await fetchOrganization();
    toaster('success', 'quickbooks disconnected successfully');
    setAPILoader(false);
  };

  const syncData = async () => {
    setAPILoader(true);
    await API.post(
      `organizations/${organization.subdomain}/quickbooks/sync`,
      {}
    );
    await fetchOrganization();
    toaster('success', 'Organization data synced successfully');
    setAPILoader(false);
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  return (
    <div className="flex justify-between items-center">
      <div>
        <img src={quickbookicon} className="w-[160px]" />
        {organization?.Integrations?.[0]?.accessToken && (
          <p className="text-sm italic">
            Connected with{' '}
            <span className="font-bold">
              {JSON.parse(organization?.Integrations?.[0]?.payload)?.email}
            </span>
          </p>
        )}
      </div>
      <div className="flex gap-2">
        {!organization?.Integrations?.[0]?.accessToken ? (
          <button
            onClick={fetchData}
            label={'Connect'}
            variant={'primary'}
            size={'large'}
            className="bg-[#2ca01c] hover:bg-[#2ca01c] text-white focus:opacity-80 px-4 py-2 text-sm"
          >
            Connect
          </button>
        ) : (
          <button
            onClick={disconnect}
            label={'Disconnect'}
            variant={'primary'}
            size={'large'}
            className="bg-[#2ca01c] hover:bg-[#2ca01c] text-white focus:opacity-80 px-4 py-2 text-sm"
          >
            Disconnect
          </button>
        )}
        <div>
          {organization?.Integrations?.[0]?.accessToken && (
            <button
              onClick={syncData}
              className="bg-[#2ca01c] hover:bg-[#2ca01c] text-white focus:opacity-80 px-4 py-2 text-sm"
            >
              Sync Data
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuickBooksIntegration;

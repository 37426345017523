/*eslint-disable*/
import {
  ADMINISTRATION_ORGANIZATION,
  ADMINISTRATION_VENDOR,
  INVOICE_AND_BILLING,
  QR_EXPIRY_DATE,
  T_M_ROUTE_MANAGEMENT
} from '../constants/features';
import { TPPL, TPPL_TEST, TCO, COC } from '../constants/subdomains';
const {
  REACT_APP_TPPL_INVOICE_BILLING,
  REACT_APP_TPPL_QR_EXPIRY_DATE,
  REACT_APP_TPPL_ADMINISTRATION_ORGANIZATION,
  REACT_APP_TPPL_ADMINISTRATION_VENDOR,
  REACT_APP_TPPL_T_M_ROUTE_MANAGEMENT,

  REACT_APP_TCO_INVOICE_BILLING,
  REACT_APP_TCO_QR_EXPIRY_DATE,
  REACT_APP_TCO_ADMINISTRATION_ORGANIZATION,
  REACT_APP_TCO_ADMINISTRATION_VENDOR,
  REACT_APP_TCO_T_M_ROUTE_MANAGEMENT,

  REACT_APP_COC_INVOICE_BILLING,
  REACT_APP_COC_QR_EXPIRY_DATE,
  REACT_APP_COC_ADMINISTRATION_ORGANIZATION,
  REACT_APP_COC_ADMINISTRATION_VENDOR,
  REACT_APP_COC_T_M_ROUTE_MANAGEMENT
} = process.env || {};

const tpplFeatureFlag = {
  [INVOICE_AND_BILLING]: REACT_APP_TPPL_INVOICE_BILLING,
  [QR_EXPIRY_DATE]: REACT_APP_TPPL_QR_EXPIRY_DATE,
  [ADMINISTRATION_ORGANIZATION]: REACT_APP_TPPL_ADMINISTRATION_ORGANIZATION,
  [ADMINISTRATION_VENDOR]: REACT_APP_TPPL_ADMINISTRATION_VENDOR,
  [T_M_ROUTE_MANAGEMENT]: REACT_APP_TPPL_T_M_ROUTE_MANAGEMENT
};

export const featureFlag = {
  [TCO]: {
    [INVOICE_AND_BILLING]: REACT_APP_TCO_INVOICE_BILLING,
    [QR_EXPIRY_DATE]: REACT_APP_TCO_QR_EXPIRY_DATE,
    [ADMINISTRATION_ORGANIZATION]: REACT_APP_TCO_ADMINISTRATION_ORGANIZATION,
    [ADMINISTRATION_VENDOR]: REACT_APP_TCO_ADMINISTRATION_VENDOR,
    [T_M_ROUTE_MANAGEMENT]: REACT_APP_TCO_T_M_ROUTE_MANAGEMENT
  },
  [COC]: {
    [INVOICE_AND_BILLING]: REACT_APP_COC_INVOICE_BILLING,
    [QR_EXPIRY_DATE]: REACT_APP_COC_QR_EXPIRY_DATE,
    [ADMINISTRATION_ORGANIZATION]: REACT_APP_COC_ADMINISTRATION_ORGANIZATION,
    [ADMINISTRATION_VENDOR]: REACT_APP_COC_ADMINISTRATION_VENDOR,
    [T_M_ROUTE_MANAGEMENT]: REACT_APP_COC_T_M_ROUTE_MANAGEMENT
  },
  [TPPL]: {
    ...tpplFeatureFlag
  },
  [TPPL_TEST]: {
    ...tpplFeatureFlag
  }
};

import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import '../assets/css/style.css';

const useStyles = makeStyles(() => ({
  root: {
    background: 'rgba(0, 0, 0, 0.5)',
    opacity: '0.5',
    height: '100%',
    position: 'fixed',
    width: '100%',
    top: '0',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    padding: '0'
  },
  detail: {
    opacity: '0.5',
    height: 'calc(100% - 550px)',
    position: 'fixed',
    width: 'calc(100% - 40%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    padding: '0'
  }
}));

const TemplateLoader = (props) => {
  const classes = useStyles();
  return (
    <div
      className={props?.type == 'detailPage' ? classes.detail : classes.root}
      id="loaderOverlay"
    >
      <CircularProgress />
    </div>
  );
};
export default TemplateLoader;

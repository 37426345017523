import { FormControl, Grid } from '@mui/material';
import React from 'react';
import TextField from '../../../core-components/atoms/TextField';
import { useFormik } from 'formik';
import Button from '../../../core-components/atoms/Button';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import { useNavigate } from 'react-router';
import { onError } from '../../../libs/errorLib';
import {
  initialValues,
  validationSchema
} from './validationSchema/ReversalForm';

const ReversalForm = ({ setOpen, type, id }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ reason }) => {
      try {
        await API.put(`${type.toLowerCase()}s/${id}/reverse`, {
          reason,
          hostUrl: window.location.href
        });
        toaster('success', `${type.toUpperCase()} order successfully reversed`);
        navigate('/routing/order-management');
      } catch (err) {
        onError(err);
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="w-[600px]">
      <span className="mt-2 text-error">
        {formik.touched.reason && formik.errors.reason}
      </span>
      <Grid item xs={12} className="mt-2 mb-10">
        <FormControl fullWidth>
          <TextField
            value={formik.values.reason}
            onChange={(e) => formik.setFieldValue('reason', e.target.value)}
            placeholder="Please mention a reason"
            minRows={3}
            multiline
            onBlur={formik.handleBlur}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className="flex justify-end">
        <Button
          size="large"
          label="Cancel"
          variant="text"
          onClick={() => setOpen(false)}
          className="mr-2"
        />
        <Button
          size="large"
          label={`Reverse ${type.toUpperCase()}`}
          type="submit"
          variant="primary"
          disabled={formik.isSubmitting || !formik.values.reason}
        />
      </Grid>
    </form>
  );
};

export default ReversalForm;

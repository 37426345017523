import React, { useEffect, useState, useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useParams } from 'react-router';

import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext, exportToCSV } from '../../../utils/common';
import BulkUploadScreen from './BulkUploadScreen';
import { exportToExcel } from '../../../services/BulkUploadServices';
import BulkValidationScreen from '../../orders/BulkValidationScreen';
import TemplateLoader from '../../../atomicComponents/TemplateLoader';
import useStyles from '../../orders/makeStyles';
import BulkUploadTabs from '../../../atomicComponents/BulkUploadTabs';
import BulkUploadSuccessTitle from '../../../atomicComponents/BulkUploadSuccessTitle';
import BulkUploadCancelBtn from '../../../atomicComponents/BulkUploadCancelBtn';
import { toaster } from '../../../utils/toaster';

const UploadBayView = () => {
  const { uid } = useParams();
  const title = 'Bays';
  const classes = useStyles();
  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  const [openForm, setOpenForm] = useState({
    tab1: true,
    tab2: false,
    tab3: false
  });
  const [bayPayload, setBayPayload] = useState([]);
  const [verifiedBays, setVerfiedBays] = useState([]);
  const [baysWithIssues, setBaysWithIssues] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [correctUploadFlag, setCorrectUploadFlag] = useState(false);
  const [dataloaded, setDataLoaded] = useState(true);
  const [correctBays, setCorrectBays] = useState(0);
  const [inCorrectBays, setInCorrectBays] = useState(0);
  const [exportVerifiedBays, setExportVerifiedBays] = useState([]);

  const header = ['Name', 'IsActive', 'Record Level', 'Message'];

  useEffect(() => {
    let title = 'Bulk Bay Upload';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    setVerifyFlag(false);
  }, [selectedFile]);

  const validateDatesAndData = (bays) => {
    try {
      const newBays = bays.map((bay) => {
        const newBay = {
          name: bay['Name'],
          isActive: true
        };
        return newBay;
      });
      setBayPayload(newBays);
    } catch (error) {
      onError(error);
    }
  };

  const bulkUpload = (bays) => {
    if (bays?.length) {
      validateDatesAndData(bays);
    }
  };

  const handleSubmit = async () => {
    try {
      if (bayPayload.length === 0) {
        toaster('warning', 'Uploaded file is empty');
        return;
      }
      setDataLoaded(false);
      setAPILoader(true);
      const payload = {
        bays: [...bayPayload]
      };

      const response = await API.post(`warehouses/${uid}/bays/verify`, payload);
      if (response.bays.length) {
        setVerifyFlag(true);
        setVerfiedBays(response.bays);
      }
      setCorrectBays(response.correctBays);
      setInCorrectBays(response.inCorrectBays);
      setDataLoaded(true);
    } catch (error) {
      setDataLoaded(true);
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (verifiedBays.length === 0) return;
    const aislesToExport = verifiedBays.map((aisle) => {
      return {
        name: aisle?.name,
        isActive: aisle?.isActive,
        errorLevel: aisle?.errorLevel,
        message: aisle?.message
      };
    });
    setExportVerifiedBays(aislesToExport);
  }, [verifiedBays]);

  const handleCreation = async () => {
    try {
      const correctBaysArray = [];
      const faultyBaysArray = [];
      verifiedBays.forEach((bay) => {
        if (bay?.errorLevel === 'Incorrect') {
          delete bay['createdBy'];
          delete bay['warehouseId'];
          faultyBaysArray.push(bay);
        } else {
          correctBaysArray.push({
            name: bay?.name,
            isActive: bay?.isActive
          });
        }
      });
      setBaysWithIssues(faultyBaysArray);
      if (!correctBaysArray.length) {
        throw new Error('No Correct bays');
      }
      const payload = {
        bays: correctBaysArray
      };
      setAPILoader(true);
      await API.post(`warehouses/${uid}/bays/bulk`, payload);
      setDataLoaded(true);
      setCorrectUploadFlag(true);
    } catch (error) {
      setDataLoaded(true);
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const extractFaultyData = () => {
    setDataLoaded(false);
    if (baysWithIssues?.length && verifyFlag && selectedFile) {
      let title = `Faulty Bays`;
      let arr = [...baysWithIssues];
      arr.unshift(header);
      exportToCSV(null, arr, title);
    }
    setDataLoaded(true);
  };

  const nextButtons = () => {
    if (!verifyFlag) {
      return (
        <Button
          disabled={selectedFile ? false : true}
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            handleSubmit();
          }}
          fullWidth
        >
          VERIFY BAYS
        </Button>
      );
    }

    if (verifyFlag && !correctUploadFlag) {
      return (
        <Button
          disabled={selectedFile ? false : true}
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            handleCreation();
          }}
          fullWidth
        >
          UPLOAD CORRECT BAYS
        </Button>
      );
    }

    return (
      <Button
        disabled={selectedFile ? false : true}
        variant="contained"
        className={classes.createButton}
        onClick={() => {
          extractFaultyData();
        }}
        fullWidth
      >
        EXTRACT BAYS WITH ISSUES
      </Button>
    );
  };

  return (
    <Grid className={classes.gridContainer}>
      <div className={!dataloaded || 'display-none'}>
        <TemplateLoader />
      </div>
      <BulkUploadTabs
        verifiedList={verifiedBays}
        correctUploadFlag={correctUploadFlag}
        verifyFlag={verifyFlag}
        setOpenForm={(value) => setOpenForm(value)}
        openForm={openForm}
        selectedFile={selectedFile}
      />

      <div className={`${classes.mainContent} mt-10`}>
        {openForm.tab1 && (
          <BulkUploadScreen
            bulkUpload={bulkUpload}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            type={'bay'}
          />
        )}
        {verifyFlag && selectedFile && openForm.tab2 && (
          <>
            <BulkValidationScreen bays={verifiedBays} status="bays" />
            <Grid container item xs={12} alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <div className={classes.exportButton}>
                  <button
                    className="exportCustomBtn"
                    onClick={() =>
                      exportToExcel(
                        verifyFlag,
                        selectedFile,
                        exportVerifiedBays,
                        header,
                        title
                      )
                    }
                  >
                    Export
                  </button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
        {correctUploadFlag && selectedFile && openForm.tab3 && (
          <>
            <BulkUploadSuccessTitle
              text={`${correctBays} Bays have been created`}
            />
            <BulkUploadSuccessTitle
              text={`Remaining Data with issues ${inCorrectBays}`}
            />
            <BulkValidationScreen
              tableHeight={680}
              bays={baysWithIssues}
              status="bays"
            />
          </>
        )}
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={2}
          className="aisleBtn"
        >
          <BulkUploadCancelBtn
            path={`/administration/warehouse/${uid}`}
            text="Cancel"
          />
          <Grid item xs={12} sm={6}>
            {nextButtons()}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default UploadBayView;

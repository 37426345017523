import React from 'react';
import DetailPageTitle from '../../atomicComponents/DetailPageTitle';
import DetailText from '../../atomicComponents/DetailText';
import { Grid, Button, makeStyles, Tooltip, Dialog } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  backdropGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  backdropTitle: {
    fontSize: 24,
    marginBottom: 18
  },
  backdropAgreeButton: {
    marginLeft: 10
  }
}));

const DeleteConfirmation = ({
  open,
  confirmDelete,
  itemToDelete = { name: '' },
  handleClose,
  loading = false
}) => {
  const classes = useStyles();

  return (
    <>
      {open && (
        <Dialog className={classes.backdrop} open={open}>
          <Grid
            container
            className={classes.backdropGrid}
            justifyContent="flex-start"
          >
            <DetailPageTitle text="Are you sure you want to delete?" />
            {itemToDelete.name.length ? (
              <DetailText text={itemToDelete.name} />
            ) : null}
            <div className="mt-10">
              <Tooltip title="Cancel">
                <Button
                  autoFocus
                  variant="contained"
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </Button>
              </Tooltip>
              <Tooltip title="Delete">
                <LoadingButton
                  variant="contained"
                  color="error"
                  className={`${classes.backdropAgreeButton} ml-5`}
                  onClick={confirmDelete}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<DeleteOutlineIcon />}
                >
                  Delete
                </LoadingButton>
              </Tooltip>
            </div>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default DeleteConfirmation;

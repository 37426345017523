import React, { useEffect, useState, useRef } from 'react';
import {
  DialogTitle,
  Grid,
  Dialog,
  Tooltip,
  TableContainer,
  Table,
  TableBody
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useReactToPrint } from 'react-to-print';

import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import palletStyles from './palletStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import {
  dividerDateFormatForFilter,
  dividerDateFormatForYear
} from '../../../utils/common';
import { DataGrid } from '@mui/x-data-grid';
import { Pagination } from '@material-ui/lab';
import { QRCode } from 'react-qrcode-logo';
import { MdClose } from 'react-icons/md';

const PalletLists = ({ open, handleClose, selectedPallet }) => {
  const classes = palletStyles();
  const isPhone = useMediaQuery('(max-width:768px)');

  const palletsListColumns = [
    {
      field: 'Warehouse.businessWarehouseCode',
      headerName: 'Warehouse',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Warehouse</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.Warehouse?.businessWarehouseCode}>
          <span className="table-cell-trucate">
            {params?.row?.Warehouse?.businessWarehouseCode}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'palletId',
      headerName: 'Pallet ID',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 2,
      width: isPhone ? 250 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Pallet ID</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.palletId}>
          <span className="table-cell-trucate">{params?.row?.palletId}</span>
        </Tooltip>
      )
    },
    {
      field: 'creationDate',
      headerName: 'Generated on',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Generated on</p>;
      },
      renderCell: (params) => (
        <Tooltip title={dividerDateFormatForFilter(params?.row?.creationDate)}>
          <span className="table-cell-trucate">
            {dividerDateFormatForFilter(params?.row?.creationDate)}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="Print">
            <div>
              <PrintIcon
                key={params}
                className="mr-3 cursor-pointer"
                onClick={() => {
                  printQr(params?.row);
                }}
              />
            </div>
          </Tooltip>
        </>
      )
    }
  ];

  const componentRef = useRef();
  const [pageCount, setPageCount] = useState(1);
  const [palletsList, setPalletsList] = useState([]);
  const [page, setPage] = useState(1);
  const [qrValues, setQrValues] = useState(null);

  const getPalletsList = async () => {
    try {
      const limit = 10;
      const colVal = {
        [`$creationDate$`]: `${dividerDateFormatForYear(
          selectedPallet?.creationDate
        )} 00:00:00`,
        warehouseId: selectedPallet?.warehouseId
      };
      const filters = {
        colVal
      };
      const palletsList = await API.get(`pallets`, {
        params: { filters, limit, page }
      });
      setPageCount(palletsList.pages);
      setPalletsList(palletsList.data);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    }
  };

  useEffect(() => {
    getPalletsList();
  }, [page]);

  const printQr = (pallet) => {
    handleReactPrint();
    setQrValues(pallet);
  };

  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    trigger: () => componentRef.current
  });

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '448px' } }}
      fullWidth
    >
      <Grid className={classes.printQr} ref={componentRef} container>
        <Grid item xs="6">
          <QRCode
            value={JSON.stringify({
              id: qrValues?.id,
              palletId: qrValues?.palletId
            })}
            {...{
              ecLevel: 'M',
              enableCORS: false,
              size: 180,
              quietZone: 10,
              bgColor: '#FFFFFF',
              fgColor: '#000000',
              qrStyle: 'squares'
            }}
          />
        </Grid>
        <Grid item xs="6">
          <Grid className="mt-8">
            <h2>{qrValues?.Warehouse?.businessWarehouseCode}</h2>
          </Grid>
          <Grid className="mt-8">
            <h2>{qrValues?.palletId}</h2>
          </Grid>
          <Grid className="mt-8">
            <h2>{dividerDateFormatForFilter(qrValues?.creationDate)}</h2>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.backdropGrid}
        justifyContent="flex-start"
      >
        <Grid item xs={8} sm={6}>
          <DialogTitle>
            <DetailPageTitle text="Pallets List" />
          </DialogTitle>
        </Grid>
        <Grid item xs={4} sm={6}>
          <MdClose className={classes.closeButton} onClick={handleClose} />
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.container}>
            <Table aria-label="sticky table" className="tableDiv">
              <TableBody>
                <div style={{ height: 650, width: '100%' }}>
                  <DataGrid
                    rows={palletsList}
                    hiddenColumnModel={['id']}
                    columns={palletsListColumns}
                    pageSize={20}
                    rowsPerPageOptions={[6]}
                    className="parentDataTable"
                  />
                </div>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="space-between">
            <Grid item></Grid>
            <Grid item>
              <Pagination
                component="div"
                shape="rounded"
                count={pageCount}
                color="primary"
                page={page}
                className={classes.pagination}
                onChange={(e, newPage) => setPage(newPage)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PalletLists;

/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  FormControl,
  MenuItem,
  Popover,
  Select,
  Box,
  InputAdornment,
  IconButton
} from '@mui/material';
import { ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';

import Button from '../../../core-components/atoms/Button';
import StockAdjustmentIcon from '../../../assets/icons/stockAdjustment.svg';
import API from '../../../libs/axios';
import CustomTextField from '../../../core-components/atoms/TextField';
import { decimalDisplay } from '../../../utils/common';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import DeleteIcon from '../../../assets/icons/deleteIcon.svg';
import SectionRelocationIcon from '../../../assets/icons/stockRelocationIcon.svg';

const SectionRelocationPopover = ({
  actionCallback,
  closeCallback,
  availableQuantity,
  warehouseId,
  sourceLocation,
  uoms
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [uom, setUom] = useState(uoms[0]);
  const [availableQty, setAvailable] = useState(availableQuantity);
  const [halls, setHalls] = useState([]);
  const [rows, setRows] = useState([]);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    if (closeCallback) closeCallback();
  };

  const handleUOMChange = (e) => {
    const modifiedRows = [...rows];
    for (let i = 0; i < modifiedRows.length; i++) {
      let value = modifiedRows[i].qty;
      value = value * uom?.conversionFactor;
      value = Number((value / e.target.value.conversionFactor).toFixed(3));
      modifiedRows[i].qty = value;
    }
    setRows(modifiedRows);
    setUom(e.target.value);
  };

  const handleConfirmClick = () => {
    const payload = rows?.map((row) => ({
      destinationSectionId: row?.destination?.id,
      qty: row?.qty * (uom?.conversionFactor || 1)
    }));
    actionCallback(payload, uom);
    handlePopoverClose();
  };

  const getHalls = async (warehouseId) => {
    const res = await API.get('warehouses/halls', {
      params: { warehouseIds: [warehouseId], fetchAll: true }
    });
    setHalls(res.data);
  };

  useEffect(() => {
    if (warehouseId) {
      getHalls(warehouseId);
    }
  }, [warehouseId]);

  useEffect(() => {
    if (availableQty > 0) {
      setRows([
        {
          destination: null,
          qty: null
        }
      ]);
    }
  }, []);

  const isValidSubmit =
    rows?.length == 0
      ? false
      : rows?.reduce(
          (acc, row) =>
            acc &&
            ((!row.destination && !row.qty) || (row.destination && row.qty)),
          true
        );

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div
          className="flex gap-[6px] items-center w-full"
          onClick={handlePopoverOpen}
        >
          <img src={SectionRelocationIcon} alt="Stock Relocation" />
          <span className="text-primaryBlue text-xs">Section Relocation</span>
        </div>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 0,
            boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.50)',
            width: '400px'
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div
          className={clsx(
            'w-[400px] p-4 rounded-[8px] bg-white z-10 transition-all shadow-elevation-2 overflow-hidden'
          )}
        >
          <p className="text-primaryBlue text-base font-medium my-2">
            Section Relocation
          </p>
          <div className="mt-4 flex justify-between">
            <div className="flex justify-between items-center w-full">
              <div className="flex gap-2">
                <span className="text-sm text-[#9DA3B3]">Total Quantity: </span>
                <span className="text-sm font-medium">
                  {decimalDisplay(
                    availableQuantity / (uom?.conversionFactor || 1)
                  )}
                </span>
              </div>
              <FormControl variant="standard">
                <Select
                  value={uom}
                  onChange={handleUOMChange}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5
                    }
                  }}
                  MenuProps={{
                    BackdropProps: { style: { backgroundColor: 'transparent' } }
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  renderValue={(val) => (
                    <span className="text-xs">{val?.name}</span>
                  )}
                >
                  {uoms.map((uom, index) => (
                    <MenuItem
                      key={index}
                      value={uom}
                      className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
                    >
                      {uom?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mt-4 mb-4">
            {rows?.map((row, id) => {
              return (
                <div className="flex gap-4 mt-4 items-center" key={id}>
                  <div className="w-[100%]">
                    <Autocomplete
                      value={row.destination}
                      onChange={(e, value) => {
                        const copiedArr = [...rows];
                        copiedArr[id].destination = value;
                        setRows([...copiedArr]);
                      }}
                      options={halls?.filter((h) => h.id !== sourceLocation)}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Destination"
                          InputProps={{
                            ...params.InputProps
                          }}
                        />
                      )}
                      getOptionLabel={(d) => d?.name || ''}
                    />
                  </div>
                  <NumericFormat
                    customInput={CustomTextField}
                    placeholder="Qty"
                    variant="outlined"
                    size="small"
                    decimalScale={3}
                    fullWidth
                    value={row.qty}
                    disabled={availableQty === 0}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      const totalQty = rows.reduce(
                        (acc, row, index) =>
                          acc + (index !== id ? parseFloat(row.qty) || 0 : 0),
                        0
                      );
                      return (
                        totalQty + (floatValue || 0) <=
                        Number(
                          (availableQty / (uom?.conversionFactor || 1)).toFixed(
                            3
                          )
                        )
                      );
                    }}
                    onValueChange={(e) => {
                      const copiedArr = [...rows];
                      copiedArr[id].qty = e.value;
                      setRows([...copiedArr]);
                    }}
                  />

                  <div>
                    <img
                      src={DeleteIcon}
                      onClick={() => {
                        const copiedArr = rows?.filter(
                          (row, index) => index !== id
                        );
                        setRows(copiedArr);
                      }}
                      className="w-10 h-10 cursor-pointer"
                    />
                  </div>
                </div>
              );
            })}
            <div className="mt-4">
              <Button
                onClick={() => {
                  setRows([...rows, { destination: null, qty: null }]);
                }}
                label="+ Add Relocation"
                variant="text"
              />
            </div>
          </div>
          <div className="w-full flex justify-end">
            <Button
              size="small"
              variant="text"
              label="Cancel"
              className="py-2 px-3 h-8"
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={handlePopoverClose}
            />
            <Button
              size="small"
              variant="primary"
              label="Confirm"
              className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"
              overrideSize={true}
              labelClass="font-medium text-xs"
              disabled={!isValidSubmit}
              onClick={handleConfirmClick}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default SectionRelocationPopover;

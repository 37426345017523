import { decimalDisplay, formatNumberToKAndM } from '../../../utils/common';
import DetailStatCard from './DetailStatCard';

const InventoryDetailStats = ({ inventory }) => {
  const damagedQty =
    (inventory?.recoverableDamageQuantity || 0) +
    (inventory?.unsellableDamageQuantity || 0);
  const availableQty = inventory?.availableQuantity || 0;
  const reservedQty = inventory?.reservedQuantity || 0;
  const holdQty = inventory?.holdQuantity || 0;
  const expiredQty = inventory?.expiredQuantity || 0;
  const nearExpiryQty = inventory?.nearExpiryQuantity || 0;
  const totalQty = availableQty + damagedQty + holdQty + reservedQty;

  return (
    <div className="w-full h-[84px] mb-10 bg-white pl-8 pr-6 mt-4 flex shadow-none justify-between items-end">
      <div className="flex">
        <DetailStatCard>
          <div>
            <p className="text-xs text-dark600">TOTAL QTY</p>
            <p className="text-lg text-primaryBlue ">
              {totalQty < 1000
                ? decimalDisplay(totalQty)
                : formatNumberToKAndM(totalQty)}
            </p>
          </div>
        </DetailStatCard>
        <DetailStatCard>
          <div className="mr-6">
            <p className="text-xs text-dark600">AVAILABLE</p>
            <p className="text-lg text-jade700 font-semibold">
              {availableQty < 1000
                ? decimalDisplay(availableQty)
                : formatNumberToKAndM(availableQty)}
            </p>
          </div>
          <div className="mr-6">
            <p className="text-xs text-dark600">ON HOLD</p>
            <p className="text-lg text-primaryBlue ">
              {holdQty < 1000
                ? decimalDisplay(holdQty)
                : formatNumberToKAndM(holdQty)}
            </p>
          </div>
          <div className="mr-6">
            <p className="text-xs text-dark600">RESERVED</p>
            <p className="text-lg text-primaryBlue">
              {reservedQty < 1000
                ? decimalDisplay(reservedQty)
                : formatNumberToKAndM(reservedQty)}
            </p>
          </div>
        </DetailStatCard>
        <DetailStatCard>
          <div className="mr-6">
            <p className="text-xs text-dark600">DAMAGED</p>
            <p className="text-lg text-persianRed ">
              {damagedQty < 1000
                ? decimalDisplay(damagedQty)
                : formatNumberToKAndM(damagedQty)}
            </p>
          </div>
          <div className="mr-6">
            <p className="text-xs text-dark600">EXPIRED</p>
            <p className="text-lg text-primaryBlue ">
              {expiredQty < 1000
                ? decimalDisplay(expiredQty)
                : formatNumberToKAndM(expiredQty)}
            </p>
          </div>
          <div className="mr-6">
            <p className="text-xs text-dark600">NEAR EXPIRY</p>
            <p className="text-lg text-primaryBlue ">
              {nearExpiryQty < 1000
                ? decimalDisplay(nearExpiryQty)
                : formatNumberToKAndM(nearExpiryQty)}
            </p>
          </div>
        </DetailStatCard>
      </div>
      <div className="flex items-end">
        <p className="text-[10px] text-dark500 mr-1">WAREHOUSE:</p>
        <p className="text-xs text-primaryBlue mr-2">
          {inventory?.Warehouse?.businessWarehouseCode}
        </p>
        <div className="w-[1px] h-[10px] bg-dark500 mr-2"></div>
        <p className="text-[10px] text-dark500 mr-1">PALLETS OCCUPIED:</p>
        <p className="text-xs text-primaryBlue mr-2">
          {totalQty && inventory.Product.perPallet
            ? decimalDisplay(Math.ceil(totalQty / inventory.Product.perPallet))
            : 0}
        </p>
        <div className="w-[1px] h-[10px] bg-dark500 mr-2"></div>
        <p className="text-[10px] text-dark500 mr-1">UoM:</p>
        <p className="text-xs text-primaryBlue mr-2">
          {inventory?.Product?.UOM?.name}
        </p>
      </div>
    </div>
  );
};

export default InventoryDetailStats;

import React from 'react';
import { Heading, Label } from '../../../core-components/atoms/Text';

export const TaskStats = ({ taskStats }) => {
  let statsBoxClass =
    'pt-6 pb-6 pr-8 pl-8 shadow-[2px_3px_2px_0px_#00000014] bg-white rounded';
  return (
    <div className="pt-4 pb-4 pr-10 pl-10 bg-[#E2E9F1] flex gap-8">
      <div className={statsBoxClass}>
        <Label variant={'sm'} className="text-[#6A7289] mb-4">
          TOTAL TASKS
        </Label>
        <Heading variant={'card'}>{taskStats?.[0]?.value}</Heading>
      </div>
      {taskStats?.length > 0 && (
        <div className={statsBoxClass}>
          <div className="flex gap-20">
            {taskStats
              ?.filter((data) => data.key !== 'CANCELLED' && data.key !== 'ALL')
              ?.map((typeStat) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div>
                    <Label variant={'sm'} className="text-[#6A7289] mb-4">
                      {typeStat?.label}
                    </Label>
                    <Heading variant={'card'}>{typeStat?.value}</Heading>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className={statsBoxClass}>
        <div className="flex gap-10">
          <div>
            <Label
              variant={'sm'}
              className="font-medium text-sm text-[#6A7289] mb-4"
            >
              CANCELLED
            </Label>
            <Heading variant={'card'} className="text-error">
              {taskStats?.[4]?.value}
            </Heading>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect, memo } from 'react';
import { DataGrid, GridRow } from '@mui/x-data-grid';
import _ from 'lodash';
import BoxIcon from '../../assets/icons/box.svg';
import orderPickingColumns from '../orders/picking/orderPickingColumns';
import globalOrderPickingColumns from '../orders/picking/globalOrderPickingColumns';
import ProductGroupSubTable from './ProductGroupSubTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse } from '@mui/material';

const PickingOrderTable = ({
  orders,
  setSelectedOrdersPayload,
  setEnableStartPicking,
  isGlobalPickingModal,
  setSelectedOrders,
  selectedOrders,
  tableHeight = '49vh'
}) => {
  // const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState(selectedOrders);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if (selectionModel?.length) {
      let arr = [...selectionModel];

      selectedOrders.forEach((element) => {
        if (!selectionModel.includes(element)) {
          arr.push(element);
        }
      });
      arr = _.uniq(arr);
      setSelectedOrders(arr);
    }
    let newSelected = orders.filter((order) =>
      selectionModel.includes(order.id)
    );
    setSelectedOrdersPayload(newSelected);
  }, [orders, selectionModel]);

  const CustomNoRowsOverlay = () => (
    <div className="flex justify-center items-center flex-col h-full">
      <img src={BoxIcon} alt="No Orders" />
      <p className="text-dark400 mt-3">
        Select the warehouse to see the order list
      </p>
    </div>
  );

  const MemoizedRows = (props) => {
    return (
      <>
        <GridRow {...props} />
        <Collapse in={expandedRows.includes(props.row.id)}>
          <Box sx={{ margin: 2 }}>
            <ProductGroupSubTable
              orderProducts={props?.row?.OrderProductGroups}
            />
          </Box>
        </Collapse>
      </>
    );
  };

  return (
    <DataGrid
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setEnableStartPicking(Boolean(newSelectionModel.length));
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      rows={orders}
      disableSelectionOnClick
      disableVirtualization
      columns={
        isGlobalPickingModal
          ? [
              ...globalOrderPickingColumns,
              {
                field: 'actions',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
                renderHeader: () => {
                  return <p className="w-100 parentActions routeheader"></p>;
                },
                renderCell: (params) => (
                  <span className="table-cell-trucate">
                    {expandedRows.includes(params.id) ? (
                      <KeyboardArrowUpIcon
                        className="cursor-pointer"
                        onClick={() =>
                          setExpandedRows((prev) =>
                            prev.filter((id) => id !== params.id)
                          )
                        }
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        className="cursor-pointer"
                        onClick={() =>
                          setExpandedRows((prev) => [...prev, params.id])
                        }
                      />
                    )}
                  </span>
                )
              }
            ]
          : orderPickingColumns
      }
      className="parentDataTable customDataGrid mr-[20px] border-0"
      hideFooterPagination={true}
      style={{ height: tableHeight, width: '100%' }}
      sx={{
        '.MuiDataGrid-columnSeparator': {
          display: 'none'
        },
        '&.MuiDataGrid-root': {
          border: 'none'
        },
        '.MuiDataGrid-columnHeadersInner': {
          background: '#F6F8FB !importants',
          fontWeight: '500'
        },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'white',
          border: 'none',
          '& div': {
            visibility: 'visible !important'
          }
        },
        '& .MuiDataGrid-cell': {
          border: 'none',
          borderTop: '1px solid rgba(224, 224, 224, 1)',
          '&:focus': {
            border: 'none',
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            outline: 'none'
          }
        }
      }}
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
        Row: memo(MemoizedRows)
      }}
    />
  );
};

export default PickingOrderTable;

/*eslint-disable*/
/* eslint-disable no-unused-vars */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DEBOUNCE_CONST } from '../../../Config';
import '../../../assets/css/style.css';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { checkPermission } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import {
  isChar,
  isEmail,
  isRequired,
  isUsername,
  isValidPassword
} from '../../../utils/validators';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles(() => ({
  textBox: {
    height: '50px !important',
    width: '100% !important',
    boxSizing: ' border-box !important',
    padding: ' 0 62px 0 10px !important'
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  labelPadding: {
    paddingTop: 5
  },
  selectBox: {
    height: 55
  }
}));

export const phoneNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export default function AddUserView({
  addUser,
  roles,
  portals,
  open,
  handleClose,
  selectedUser,
  formErrors,
  attemptedAlready,
  setAttemptedAlready
}) {
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [validation, setValidation] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [portal, setPortal] = useState('');
  const [roleId, setRoleId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [customer, setCustomer] = useState({});
  const [fetchedCustomers, setFetchedCustomers] = useState([]);
  const [changePortals, setChangePortals] = useState(true);
  const [selectedPortal, setSelectedPortal] = useState({});
  const [selectedRole, setSelectedRole] = useState({});
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [isActive, setActive] = useState(false);
  const { currentUser, setAPILoader } = useContext(SharedContext);
  const isCurrentUser = () => selectedUser && currentUser.id == selectedUser.id;

  useEffect(() => {
    if (selectedUser) {
      setFirstName(selectedUser.firstName || '');
      setLastName(selectedUser.lastName || '');
      setEmail(selectedUser.email || '');
      setUsername(selectedUser.username || '');
      setPhone(selectedUser.phone || '');
      setPortal(selectedUser.UserRoles[0].Role.allowedApps || '');
      setRoleId(selectedUser.UserRoles[0].Role.id || '');
      setCompanyId(selectedUser.companyId || '');
      setSelectedPortal(selectedUser.UserRoles[0].Role.allowedApps || '');
      setSelectedRole(selectedUser.UserRoles[0].Role || '');
      setActive(!!selectedUser.isActive);
    } else {
      setFirstName('');
      setLastName('');
      setEmail('');
      setUsername('');
      setPassword('');
      setPhone('');
      setRoleId('');
      setPortal('');
      setCompanyId('');
      setActive(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  useEffect(() => {
    _getCustomers();
  }, [open]);

  useEffect(() => {
    if (!portal) return setFilteredRoles([]);
    setFilteredRoles(roles.filter((role) => role.portal == portal));
  }, [portal]);

  useEffect(async () => {
    await _getCustomersById(companyId);
  }, [companyId]);

  const resetStates = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setUsername('');
    setPassword('');
    setPhone('');
    setRoleId('');
    setPortal('');
    setCompanyId('');
    setActive(true);
  };

  const changePortal = (portal) => {
    setChangePortals(false);
    setRoleId('');
    setCompanyId('');
    setPortal(portal);
  };

  const handleSubmit = async () => {
    const newUser = {
      firstName,
      lastName,
      username,
      email,
      roleId,
      companyId,
      phone: phone.replace('-', ''),
      isActive,
      password
    };

    setValidation({
      firstName: true,
      lastName: true,
      username: true,
      portal: true,
      roleId: true,
      companyId: portal === 'CUSTOMER',
      password: !selectedUser || validation.password,
      phone: true,
      email: true
    });

    if (
      isRequired(firstName) &&
      isRequired(lastName) &&
      isRequired(portal) &&
      isRequired(roleId) &&
      isRequired(phone) &&
      (portal != 'CUSTOMER' || isRequired(companyId)) &&
      isRequired(username) &&
      (!!selectedUser || isRequired(password)) &&
      isEmail(email) &&
      (password ? isValidPassword(password) : true)
    ) {
      if (portal != 'CUSTOMER') {
        delete newUser.companyId;
      }
      setAttemptedAlready(true);
      const response = await addUser(newUser);

      if (response) {
        setValidation({});
        resetStates();
        mixpanel.track('Add User', { email, username });
      }
    }
  };

  const _getCustomers = async (e) => {
    // if (e.target.value) {
    try {
      setAPILoader(true);
      let colVal = {};

      colVal = {
        relationType: 'CUSTOMER',
        isActive: '1'
      };

      if (e?.target?.value) {
        colVal['name'] = e?.target?.value;
      }

      let filters = {
        colVal
      };
      let columns = ['id', 'name'];
      const customers = await API.get(`/companies`, {
        params: { filters, columns }
      });
      setFetchedCustomers(customers.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
    // }
  };

  const _getCustomersById = async (companyId) => {
    if (companyId) {
      try {
        setAPILoader(true);
        let colVal = {
          id: companyId
        };
        let filters = {
          colVal
        };
        let columns = ['id', 'name'];
        const customerById = await API.get(`/companies`, {
          params: { filters, columns }
        });

        const { id, name } = customerById.data.rows[0];
        const checkCustom = customerById.data.rows.find((c) => c.id === id);
        if (checkCustom) {
          setCustomer({ id, name });
        } else {
          setCustomer({ id, name });
          setFetchedCustomers((fetchedCustomers) => [
            ...fetchedCustomers,
            { id, name }
          ]);
        }
      } catch (err) {
        // onError(err);
      } finally {
        setAPILoader(false);
      }
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(_getCustomers, DEBOUNCE_CONST),
    []
  );

  return (
    <div style={{ display: 'inline' }}>
      <form autoComplete="off">
        <Dialog
          open={open}
          onClose={(event = 'backdropClick') => {
            setValidation({});
            resetStates();
            handleClose();
          }}
          aria-labelledby="form-dialog-title"
          // onBackdropClick={(event, reason = "backdropClick") => {setValidation('')}}
        >
          <DialogTitle disableTypography className="title">
            {!selectedUser ? 'Add User' : 'Edit User'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container spacing={2} justify="space-around">
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    // autoFocus
                    margin="dense"
                    id="firstName"
                    label="First Name *"
                    type="text"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => {
                      // const regex = /^[a-zA-Z ]*$/;
                      // if (regex.test(e.target.value))
                      setFirstName(e.target.value);
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, firstName: true })
                    }
                  />
                  {validation.firstName && !isRequired(firstName) ? (
                    <Typography color="error">
                      First name is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                  {/* {validation.firstName && !isChar(firstName) ? (
                    <Typography color="error">
                      First name is only characters!
                    </Typography>
                  ) : (
                    ''
                  )} */}
                  {validation.firstName && firstName.length < 3 ? (
                    <Typography color="error">
                      First name should be minimum 3 characters!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="lastName"
                    label="Last Name *"
                    type="text"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => {
                      // const regex = /^[a-zA-Z]*$/;
                      // if (regex.test(e.target.value))
                      setLastName(e.target.value);
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, lastName: true })
                    }
                  />
                  {validation.lastName && !isRequired(lastName) ? (
                    <Typography color="error">
                      Last name is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                  {/* {validation.lastName && !isChar(lastName) ? (
                    <Typography color="error">
                      Last name is only characters!
                    </Typography>
                  ) : (
                    ''
                  )} */}
                  {validation.lastName && lastName.length < 3 ? (
                    <Typography color="error">
                      Last name should be minimum 3 characters!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth={true}
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                  margin="dense"
                  id="username"
                  disabled={!!selectedUser}
                  label="Username"
                  type="text"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onBlur={() =>
                    setValidation({ ...validation, username: true })
                  }
                />
                {validation.username && !isRequired(username) ? (
                  <Typography color="error">Username is required!</Typography>
                ) : (
                  ''
                )}
                {/* {validation.username && !isUsername(username) ? (
                  <Typography color="error">
                    Username should contain only letters and numbers!
                  </Typography>
                ) : (
                  ''
                )} */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth={true}
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                  margin="dense"
                  disabled={!!selectedUser}
                  id="email"
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => setValidation({ ...validation, email: true })}
                />
                {validation.email && !isRequired(email) ? (
                  <Typography color="error">Email is required!</Typography>
                ) : (
                  ''
                )}
                {validation.email && !isEmail(email) ? (
                  <Typography color="error">Incorrect email!</Typography>
                ) : (
                  ''
                )}
              </Grid>
              {!isCurrentUser() ? (
                <Grid item xs={12}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="portal"
                      key={portals}
                      options={portals}
                      defaultValue={
                        selectedUser
                          ? selectedUser.UserRoles[0].Role.allowedApps
                          : ''
                      }
                      value={selectedPortal}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Portal"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(portal) => {
                        return portal.label
                          ? portal.label || ''
                          : portal
                          ? portal
                          : '';
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, portal: true })
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          changePortal(newValue.id);
                          setSelectedPortal(newValue);
                          setSelectedRole({});
                          setValidation({
                            ...validation,
                            roleId: false
                          });
                        } else {
                          setSelectedPortal({});
                          setSelectedRole({});
                          changePortal({});
                          setFilteredRoles([]);
                          setValidation({
                            ...validation,
                            portal: false,
                            roleId: false
                          });
                        }
                      }}
                    />
                    {validation.portal && !isRequired(portal) ? (
                      <Typography color="error">
                        Please select a portal!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}
              {!isCurrentUser() ? (
                <Grid item xs={12}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="portal"
                      key={filteredRoles}
                      options={filteredRoles}
                      defaultValue={
                        selectedUser && changePortals
                          ? selectedUser.UserRoles[0].Role
                          : ''
                      }
                      value={selectedRole}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Role"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(role) => {
                        return role && role.name ? role.name || '' : '';
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, roleId: true })
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setRoleId(newValue.roleId);
                          setSelectedRole(newValue);
                        } else {
                          setRoleId(null);
                          setSelectedRole({});
                        }
                      }}
                    />
                    {validation.roleId && !isRequired(roleId) ? (
                      <Typography color="error">Role is required!</Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}
              {!isCurrentUser() && portal == 'CUSTOMER' ? (
                <Grid item xs={12}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="customers"
                      key={!!customer && customer.id}
                      options={fetchedCustomers?.rows || []}
                      defaultValue={
                        selectedUser && selectedUser.companyId ? customer : ''
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Company"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(customer) => {
                        return customer && customer.name
                          ? customer.name || ''
                          : customer.Company
                          ? customer.Company.name
                          : '';
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, companyId: true })
                      }
                      onChange={(event, newValue) => {
                        if (newValue) setCompanyId(newValue.id);
                      }}
                      onKeyPress={debouncedChangeHandler}
                    />
                    {validation.companyId && !isRequired(companyId) ? (
                      <Typography color="error">
                        Customer is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} className="parentCompany user-input mt-2">
                <PhoneInput
                  country={'pk'}
                  value={phone}
                  onChange={(phone) => {
                    setPhone(phone);
                  }}
                  inputStyle={{ width: '100%', height: '56px' }}
                  onBlur={() => setValidation({ ...validation, phone: true })}
                  variant="outlined"
                />
                {validation.phone && !isRequired(phone) ? (
                  <Typography color="error">
                    Phone number is required!
                  </Typography>
                ) : (
                  ''
                )}
                {/* {validation.phone && !isRequired(phone) ? <Typography color="error">Incorrect phone number!</Typography> : ''} */}
              </Grid>
              <Grid item sm={12}>
                <TextField
                  required
                  fullWidth={true}
                  inputProps={{
                    className: classes.textBox,
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off'
                    }
                  }}
                  className={classes.labelBox}
                  margin="dense"
                  id="password"
                  label={(selectedUser ? 'Change ' : '') + 'Password'}
                  type="password"
                  variant="outlined"
                  value={password}
                  autocomplete="false"
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() =>
                    setValidation({ ...validation, password: true })
                  }
                />
                {!selectedUser &&
                validation.password &&
                !isRequired(password) ? (
                  <Typography color="error">Password is required!</Typography>
                ) : (
                  ''
                )}
                {validation.password && !isValidPassword(password) ? (
                  <Typography color="error">
                    Password must have upper and lower case letters, numbers,
                    and symbols like {'@ ! ? $ * % &'}.
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
              {checkPermission(currentUser, 'OPS_USER_READ') &&
              !isCurrentUser() ? (
                <Grid item sm={12}>
                  <Checkbox
                    checked={isActive}
                    onChange={(e) => setActive(e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  Active
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  setValidation({});
                  resetStates();
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title={!selectedUser ? 'Add User' : 'Update User'}>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={attemptedAlready}
              >
                {!selectedUser ? 'Add User' : 'Update User'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead
} from '@material-ui/core';

import TextField from '../../core-components/atoms/TextField';
import ReleaseOrHoldPopover from '../inventoryRevamped/components/ReleaseOrHoldPopOver';
import { ActionType, PopoverOrigin } from '../inventoryRevamped/constants';
import { useMutation } from 'react-query';
import { removeHoldFromInventory } from '../../apis/wms';
import { toaster } from '../../utils/toaster';
import { onError } from '../../libs/errorLib';
import { Tooltip } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import { dateFormatSlashDivider } from '../../utils/common';
import { CircularProgress } from '@mui/material';

const pickingBatchesColumns = [
  'LOCATION DETAILS',
  'BATCH NO',
  'MFG. & EXP. DATE',
  'PALLET ID',
  'MRP',
  'AVAILABLE',
  'HOLD',
  'PICKED QTY',
  ''
];

const PickingBatchesTable = ({
  updateSelectedIds,
  getSelectedIds,
  getBatches,
  productId,
  changeOccur,
  setPickQuantity,
  releaseHoldQuantity,
  page,
  setPage,
  pageCount,
  getNewBatches,
  order,
  updateRecord
}) => {
  const tableContainerRef = useRef(null);
  const [batches, setBatches] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [releaseBatchId, setReleaseBatchId] = useState(null);
  const [contentHover, setContentHover] = useState(false);
  const [autoPickedSelection, setAutoPickedSelection] = useState({});

  useEffect(() => {
    const handleScrolling = (event) => {
      if (tableContainerRef) {
        const tableBottom =
          tableContainerRef.current?.getBoundingClientRect().bottom;
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (contentHover === false && tableBottom + 68 === viewportHeight) {
          tableContainerRef.current.scrollTop += event.deltaY;
        }
      }
    };
    window.addEventListener('wheel', handleScrolling);
    return () => {
      window.removeEventListener('wheel', handleScrolling);
    };
  }, [contentHover, tableContainerRef.current]);

  useEffect(() => {
    setSelectedIds(getSelectedIds(productId));
    setBatches(getBatches(productId));
  }, [productId, changeOccur]);

  const {
    isLoading: loadingRemoveHoldInventory,
    mutate: mutateRemoveHoldInventory
  } = useMutation(removeHoldFromInventory, {
    onSuccess: () => {
      releaseHoldQuantity(productId, releaseBatchId);
      toaster('success', 'Hold removed from Batch');
    },
    onError: (error) => {
      onError({ response: error.response });
    }
  });

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      page < pageCount
    ) {
      getNewBatches(page + 1);
      setPage(page + 1);
    }
  };

  return (
    <TableContainer>
      <Table aria-label="sticky table" className="mt-6">
        <TableBody>
          <TableContainer
            onMouseEnter={() => {
              setContentHover(true);
            }}
            onMouseLeave={() => {
              setContentHover(false);
            }}
            style={{ overflow: 'auto' }}
            ref={tableContainerRef}
            onScroll={handleTableScroll}
            className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
          >
            <Table stickyHeader>
              <TableHead className="z-40 relative">
                <TableRow>
                  <TableCell
                    key={'checkbox'}
                    className="bg-white text-dark300 text-base py-2 px-6"
                  >
                    <Checkbox
                      sx={{
                        '& .Mui-checked.MuiSvgIcon-root': {
                          color: '#0C6BD7 !important'
                        }
                      }}
                      checked={autoPickedSelection[order?.product?.id] ?? false}
                      onChange={(_, val) => {
                        if (val) {
                          updateRecord(
                            order?.product?.id,
                            structuredClone(order?.batches) ?? [],
                            order?.batches?.map((v) => v.id) ?? []
                          );
                          setAutoPickedSelection((prev) => ({
                            ...prev,
                            [order?.product?.id]: true
                          }));
                        } else {
                          updateRecord(
                            order?.product?.id,
                            structuredClone(order?.batches) ?? [],
                            []
                          );
                          setAutoPickedSelection((prev) => ({
                            ...prev,
                            [order?.product?.id]: undefined
                          }));
                        }
                      }}
                    />
                  </TableCell>
                  {pickingBatchesColumns.map((v) => {
                    return (
                      <TableCell
                        key={v}
                        className="bg-white text-dark300 py-2 px-6"
                      >
                        <p className="text-xs font-bold text-headerCell parentActions">
                          {v}
                        </p>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {batches.map((row) => (
                  <TableRow
                    key={row.id}
                    className={
                      row.holdQuantity > 0
                        ? 'bg-snow hover:shadow-elevation-1 hover:z-20 hover:relative'
                        : 'hover:shadow-elevation-1 hover:z-20 hover:relative'
                    }
                  >
                    <TableCell
                      key={`checkbox-${row.id}`}
                      className="rounded-none text-dark300 py-2 px-6"
                    >
                      <Checkbox
                        sx={{
                          '& .Mui-checked.MuiSvgIcon-root': {
                            color: '#0C6BD7 !important'
                          }
                        }}
                        checked={selectedIds.includes(row.id)}
                        onChange={(_, val) => {
                          let cur = structuredClone(selectedIds);

                          if (val) {
                            cur = [...new Set([...cur, row.id])];
                          } else {
                            cur = cur.filter((r) => r !== row.id);
                          }

                          setSelectedIds(cur);

                          updateSelectedIds(productId, cur);
                        }}
                      />
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <Tooltip title={`${row?.WarehouseHall?.name}`}>
                        <span className="table-cell-trucate">
                          <p className="mb-1">{row?.WarehouseHall?.name}</p>
                          <div className="text-[10px]">
                            <span className="font-medium">Aisle:</span>{' '}
                            {row?.WarehouseHall?.Aisle?.name}{' '}
                            <span className="font-medium">Bay:</span>{' '}
                            {row?.WarehouseHall?.Bay?.name}
                          </div>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <Tooltip title={row?.batchNumber}>
                        <span className="table-cell-trucate">
                          {row?.batchNumber}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <Tooltip
                        title={`${dateFormatSlashDivider(
                          row?.manufacturingDate
                        )}`}
                      >
                        <span className="table-cell-trucate text-center">
                          {row?.manufacturingDate &&
                            dateFormatSlashDivider(row?.manufacturingDate)}
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={`${dateFormatSlashDivider(row?.expiryDate)}`}
                      >
                        <span className="table-cell-trucate text-center">
                          {row?.expiryDate &&
                            `-${dateFormatSlashDivider(row?.expiryDate)}`}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <Tooltip title={row?.palletId}>
                        <span className="table-cell-trucate text-center">
                          {row?.pickupNode?.palletId}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <Tooltip title={row?.MRP}>
                        <span className="table-cell-trucate text-center">
                          {row?.MRP}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <Tooltip title={row?.availableQuantity}>
                        <span className="table-cell-trucate">
                          {row?.availableQuantity}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <Tooltip title={row?.holdQuantity}>
                        <span className="table-cell-trucate">
                          {row?.holdQuantity}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      <TextField
                        id={`pickQuantity-${row.id}`}
                        variant="outlined"
                        size="small"
                        value={row.pickQuantity}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          if (!newValue) {
                            setPickQuantity(productId, row.id, newValue);
                          } else if (
                            newValue > 0 &&
                            newValue <= row.availableQuantity
                          ) {
                            setPickQuantity(productId, row.id, newValue);
                          }
                        }}
                        type="number"
                        sx={{
                          borderRadius: '8px',
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            height: '7px',
                            maxWidth: '100px'
                          }
                        }}
                        disabled={row?.availableQuantity === 0}
                      />
                    </TableCell>
                    <TableCell className="rounded-none text-dark300 py-1 px-6">
                      {row?.holdQuantity > 0 ? (
                        <ReleaseOrHoldPopover
                          origin={PopoverOrigin.BATCH}
                          type={ActionType.REMOVE_HOLD}
                          inventoryAmount={row?.holdQuantity}
                          location={row?.WarehouseHall?.name}
                          loading={loadingRemoveHoldInventory}
                          actionCallback={() => {
                            mutateRemoveHoldInventory([
                              {
                                inventoryDetailId: row?.id,
                                quantity: row?.holdQuantity
                              }
                            ]);
                          }}
                          enterCallBack={() => {
                            setReleaseBatchId(row?.id);
                          }}
                        />
                      ) : (
                        <span></span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {page < pageCount && (
                  <TableRow>
                    <TableCell className="py-2 h-12" colSpan={12}>
                      <div className="flex w-full justify-center">
                        <CircularProgress size="14px" />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableBody>
      </Table>
      <Table
        aria-label="collapsible table"
        className="border h-10 rounded-b-lg py-2"
      >
        <TableBody>
          <TableRow className="flex justify-between py-2 px-5">
            {selectedIds.length} rows selected
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PickingBatchesTable;

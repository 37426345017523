import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px',
    width: '100% !important',
    '@media (max-width: 767px)': {
      padding: '10px 10px'
    }
  },
  tabs: {
    '& button': {
      minWidth: 82
    }
  },
  printPage: {
    height: '100%',
    padding: '16px'
  },
  heading: {
    fontWeight: 'bolder'
  },
  tableText: {
    fontWeight: 400,
    fontSize: '9px',
    color: '#696F80',
    textTransform: 'uppercase'
  },
  tableTextCopy: {
    fontWeight: 400,
    fontSize: '8px',
    color: '#696F80',
    textTransform: 'uppercase'
  },
  tableTextDesp2: {
    fontWeight: 400,
    fontSize: '10px',
    color: '#0B1940'
  },
  headDetail2: {
    backgroundColor: '#F6F8FB',
    border: '1px dashed #0B1940',
    borderRadius: '10px'
  },
  tableTexDesp: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#0B1940'
  },
  pageMainHeading: {
    fontWeight: 600,
    fontSize: '16px'
  },
  pageHeading: {
    fontWeight: 600,
    fontSize: '16px'
  },
  baseColor: {
    color: '#4B89C8'
  },
  head: {
    background: '#FAFAFC',
    position: 'relative',
    display: 'table-row-group'
  },
  padding5mm: {
    // padding: '5mm 5mm 0mm 5mm'
  },
  borderBottom: {
    borderBottom: '2px dashed lightgrey'
  },
  DataGridParent: {
    width: '100%',
    justifyContent: 'center',
    margin: 'auto',
    height: '600px'
  },
  ParentContainer: {
    justifyContent: 'center',
    width: '90%',
    margin: 'auto'
  },
  leftBorder: {
    'border-left': '2px solid #4B89C8'
  },
  tableBorder: {
    border: '1px solid #D4D4D4'
  },
  tableBorder2: {
    border: '1px solid #E1E5F0',
    borderRadius: '8px'
  },
  tableHead: {
    display: 'table-row-group'
  },
  tableRow: {
    breakInside: 'avoid'
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  tableMidContainer: {
    width: '100%',
    backgroundColor: '#F6F8FB',
    overflow: 'hidden'
  },
  topColumnWrapper: {
    width: '100%',
    margin: '7px',
    fontWeight: 400,
    fontSize: '10px',
    paddingTop: '7px',
    paddingBottom: '7px'
  },
  topColumnWrapperBold: {
    fontWeight: 500,
    fontSize: '14px',
    marginLeft: '2px'
  },
  topColumnWrapperSimple: {
    fontWeight: 400,
    fontSize: '14px',
    marginLeft: '2px'
  },
  visibilityNone: {
    visibility: 'hidden'
  },
  padding0: {
    padding: '0px !important'
  },
  columnPadding: {
    padding: '18px'
  },
  margin0: {
    margin: '0 5px 0 0 !important'
  },
  root: {
    width: '100%',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      overflow: 'auto'
    }
  },
  container: {
    padding: 20,
    '@media (max-width: 767px)': {
      padding: 10,
      paddingTop: '10px !important'
    }
  },
  taskManagementContainer: {
    margin: '40px 15px',
    '@media (max-width: 767px)': {
      margin: '10px 0px'
    }
  },
  pt1: {
    paddingTop: 1
  },

  completedStatus: {
    color: '#4EAD5B'
  },
  inProgressStatus: {
    color: '#F6C142'
  },
  notAssignedStatus: {
    color: '#DF8244'
  },
  exportButton: {
    padding: '10px',
    borderTop: '1px solid lightgrey',
    marginRight: '20px'
  },
  printPutaway: {
    // position: 'absolute',
    // right: '15px',
    // top: '10px'
  },
  createButton: {
    color: 'white',
    backgroundColor: '#4B89C8',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    }
  },
  cancelButton: {
    border: '1px solid #4B89C8',
    color: '#4B89C8',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    }
  },
  paddingTop0: {
    paddingTop: 0
  },
  paddingTop12: {
    paddingTop: 12
  },
  padding12: {
    padding: '12px 12px 0px !important'
  },
  hidden: {
    visibility: 'hidden'
  },
  marginRight8: {
    marginRight: 8
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  backdropGrid: {
    backgroundColor: 'white',
    padding: '18px 18px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: 'black'
  },
  popupFooter: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    marginRight: '3%'
  },
  cancelBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '90%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #D9DFE5',
    background: '#D9DFE5',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#979B9F',
    marginLeft: '24px !important',
    '@media (max-width: 767px)': {
      width: '70%'
    }
  },
  submitBtn: {
    padding: '8px 20px',
    gap: '10px',
    width: '92%',
    height: '32px',
    left: '924px',
    top: '573px',
    border: '1px solid #4B89C8',
    background: '#4B89C8',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#FFFFFF',
    '@media (max-width: 767px)': {
      width: '70%'
    }
  },
  editIcon: {
    cursor: 'pointer',
    marginRight: '8px'
  },
  assignTO: {
    justifyContent: 'space-between',
    width: '100%',
    display: 'flex'
  },
  floatRight: {
    float: 'right'
  },
  pagination: {
    marginTop: 10
  },
  dataGrid: {
    height: 'calc(82vh - 198px)',
    width: '100%'
  },
  taskIdStyles: {
    color: '#4B89C8',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row !important'
  },
  parentWidth: {
    width: '100%',
    'padding-right': 5
  },
  marginBottom: {
    '@media (max-width: 767px)': {
      marginBottom: '10px'
    }
  },
  createCycleButton: {
    '@media (min-width: 769px) and (max-width: 992px)': {
      fontSize: '8px !important'
    }
  },
  iconClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterView: {
    '@media (max-width: 767px)': {
      left: '0px !important'
    }
  },
  gridCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  orderDetailTag: {
    color: '#0D99FF',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  imageIcon: {
    cursor: 'pointer',
    width: '18px',
    marginRight: '5px'
  },
  ml23: {
    marginLeft: '23px'
  },
  width130px: {
    width: '130px'
  },
  titleBar: {
    background: theme.palette.secondary.main,
    color: 'white'
  },
  customBtn: {
    border: '1px solid #4B89C8',
    color: '#4B89C8',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#4B89C8',
      color: 'white'
    },
    lineHeight: '15px',
    fontWeight: 'bold'
  },
  actionDiv: {
    width: '96%',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '10px'
  },
  closeButton: {
    cursor: 'pointer',
    float: 'right',
    color: 'white'
  },
  logo: {
    cursor: 'pointer',
    marginLeft: '10px'
  }
}));

export default useStyles;

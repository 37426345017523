import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Title } from '../../../core-components/atoms/Text';
import {
  ALICE_BLUE,
  DARK_800,
  OASIS_PRIMARY_500,
  OASIS_PRIMARY_600,
  PRIMARY_BLUE
} from '../../../constants/colors';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import GraphSkeleton from '../components/GraphSkeleton';
import { decimalDisplay } from '../../../utils/common';
import { MenuItem, Select } from '@mui/material';

const noDataPlugin = {
  id: 'noDataPlugin',
  afterDraw: (chart) => {
    if (
      chart.data.datasets.length === 0 ||
      chart.data.datasets.every((dataset) =>
        dataset.data.every((value) => value === 0)
      )
    ) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = "16px normal 'Helvetica Nueue'";
      ctx.fillStyle = '#666';
      ctx.fillText('No data to display', width / 2, height / 2);
      ctx.restore();
    }
  }
};

const WarehouseCapcity = ({ nodeIds, endDate, startDate }) => {
  const [capcity, setCapcity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = React.useState('');

  useEffect(() => {
    getOrgNodes();
  }, []);

  const getOrgNodes = async (search) => {
    try {
      const response = await API.get(`nodes`, {
        params: { search: search, nodeClass: 'INTERNAL' }
      });
      setWarehouses(response?.data);
    } catch (err) {
      onError(err);
    }
  };

  const handleChange = (event) => {
    setWarehouse(event.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    getDelayedShipments(startDate, endDate, nodeIdsMemo);
  }, [startDate, endDate, nodeIdsMemo]);

  const getDelayedShipments = useCallback(
    debounce(
      async (startDate, endDate, nodeIds) => {
        getShipments(startDate, endDate, nodeIds);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getShipments = async (startDate, endDate, nodeIds) => {
    try {
      const data = await API.get(
        `intelligence/warehouse-capacity-utilization`,
        {
          params: {
            startDate: startDate,
            endDate: endDate,
            nodeIds: nodeIds?.length <= 0 ? [] : nodeIds
          }
        }
      );
      setCapcity(data);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const maxValue = Math.max(...capcity.map((c) => c?.total));
  const data = {
    labels: capcity?.map((value) => value?.date),
    datasets: [
      {
        label: 'Utilization',
        data: capcity?.map((value) => value?.total),
        fill: false,
        borderColor: OASIS_PRIMARY_500,
        borderWidth: 0.5,
        tension: 0.4,
        borderRadius: 4,
        pointRadius: 4,
        pointBackgroundColor: OASIS_PRIMARY_600,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: DARK_800,
        pointHoverBorderWidth: 1,
        pointHoverBorderColor: OASIS_PRIMARY_500
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += (context.parsed.y / maxValue) * 100 + '%';
            }
            return label;
          }
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false
        },
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return decimalDisplay(value);
          }
        }
      }
    }
  };

  return (
    <div className="p-4">
      <div className={'flex justify-between items-center'}>
        <Title variant={'md'}>Warehouse Capacity Utilization</Title>
        {nodeIds?.length <= 0 && (
          <Select
            value={warehouse}
            onChange={handleChange}
            size={'small'}
            sx={{
              '&& .MuiSelect-select': {
                paddingTop: '2px',
                paddingBottom: '2px',
                fontSize: '12px',
                color: PRIMARY_BLUE
              }
            }}
            MenuProps={{
              sx: {
                '.MuiPaper-root': {
                  minWidth: 200,
                  maxHeight: 300
                },
                '.MuiMenu-list': {
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 0
                },
                '.MuiMenuItem-root': {
                  justifyContent: 'flex-start',
                  padding: '8px',
                  fontSize: '14px',
                  color: PRIMARY_BLUE
                }
              }
            }}
            className="bg-white focus:bg-oasisPrimary100"
          >
            {warehouses.map((value, index) => {
              return (
                <MenuItem
                  key={index}
                  value={value?.id}
                  sx={{
                    '&.Mui-selected': {
                      justifyContent: 'flex-start',
                      padding: '8px',
                      fontSize: '14px',
                      color: OASIS_PRIMARY_500,
                      backgroundColor: ALICE_BLUE,
                      fontWeight: 'bold'
                    }
                  }}
                >
                  {value?.name}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </div>
      <div className={'h-[240px]'} style={{ width: '100%' }}>
        {isLoading ? (
          <GraphSkeleton />
        ) : (
          <Line data={data} options={options} plugins={[noDataPlugin]} />
        )}
      </div>
    </div>
  );
};

export default WarehouseCapcity;

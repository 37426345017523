import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Grid } from '@mui/material';
import Dialogue from '../atomicComponents/Dialogue';
import DraggableImage from './DraggableImage';

import addIconBig from '../assets/icons/addIconBig.svg';

const EditableImagesList = ({
  formik,
  disableUpload = false,
  removedFiles
}) => {
  const inputRef = useRef(null);
  const [imagesArr, setImagesArr] = useState(formik?.values?.images || []);
  const [open, setOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const swap = (arr, indexA, indexB) => {
    const temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };

  const moveImage = (dragIndex, dropIndex) => {
    const moveArray = swap(imagesArr, dragIndex, dropIndex);
    const supportArray = swap(
      formik?.values?.supportingDocuments,
      dragIndex,
      dropIndex
    );
    formik?.setFieldValue('images', moveArray);
    formik?.setFieldValue('supportingDocuments', supportArray);
    return moveArray;
  };

  useEffect(() => {
    formik?.values?.images?.length && setImagesArr(formik?.values?.images);
  }, [formik?.values?.images]);

  const validateImage = async (event) => {
    const checkFile = event.target.files[0];
    if (!checkFile.name.toLowerCase().match(/\.(jpg|jpeg|png|pdf)$/)) {
      alert('Only images or PDF file allowed!');
      return false;
    }
    const isLt2M = checkFile.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      alert('File must be smaller than 1MB!');
      return false;
    }

    const imgs = [...imagesArr, checkFile];
    setImagesArr(imgs);
    formik?.setFieldValue('images', imgs);
  };

  const removeImage = async () => {
    const index = imagesArr?.findIndex((i) => i.id === selectedId);
    const imgs = [...imagesArr];
    const supportingDocuments = [
      ...(formik?.values?.supportingDocuments || [])
    ];
    imgs.splice(index, 1);
    supportingDocuments.splice(index, 1);
    formik?.setFieldValue('supportingDocuments', supportingDocuments);
    setImagesArr(imgs);
    removedFiles.push(selectedId);
  };

  return (
    <Grid container spacing={1}>
      {imagesArr?.length
        ? imagesArr?.map((image, index) => (
            <div
              key={image.id}
              className="imagePreviewSec"
              style={{ marginRight: '10px' }}
            >
              <DraggableImage
                key={image.id}
                id={image.id}
                item={image}
                index={index}
                moveImage={moveImage}
                imagesArr={imagesArr}
                setIsConfirm={setIsConfirm}
                setSelectedId={setSelectedId}
                setImagePreview={setImagePreview}
                setOpen={setOpen}
              />
            </div>
          ))
        : null}
      <div className="imagePreviewSec">
        <button
          type="button"
          className="upload-image-btn"
          onClick={() => inputRef.current.click()}
          disabled={disableUpload}
        >
          <img src={addIconBig} alt="add-icon" className="mx-auto" />
        </button>
        <input
          ref={inputRef}
          hidden
          type="file"
          onChange={(e) => validateImage(e)}
          accept=".jpg,.png,.jpeg,.pdf"
        />
      </div>
      <Dialogue
        open={isConfirm}
        handleClose={() => {
          setIsConfirm(false);
        }}
        submitHandler={() => {
          removeImage();
          setIsConfirm(false);
        }}
        dialogueText="Are you Sure you want to delete?"
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <img src={imagePreview} alt={`Image`} />
      </Dialog>
    </Grid>
  );
};

export default EditableImagesList;

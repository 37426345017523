import React from 'react';
import { Table as MuiTable } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import clsx from 'clsx';

const Table = ({
  data,
  columns,
  customClasses,
  rowClasses,
  className = 'mr-4 mt-1'
}) => {
  return (
    <div className={className}>
      <TableContainer>
        <MuiTable className={clsx('w-full', customClasses)}>
          <TableHead>
            <TableRow className={rowClasses}>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  className={clsx(column.headerClasses)}
                >
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column) => (
                  <TableCell
                    key={column.key}
                    className={clsx(
                      rowIndex === data.length - 1 ? 'border-b' : 'border-b-0',
                      column.cellClasses
                    )}
                  >
                    {column.render ? column.render(row) : row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </div>
  );
};

export default Table;

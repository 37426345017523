/*eslint-disable*/
import { useContext, useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
// import { useNavigate } from 'react-router';
import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import { useCallback } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { getDrivers } from '../../../apis/tms';
import { ORGANIZATION_TYPES } from '../../../constants';
import { DEBOUNCE_CONST } from '../../../Config';
import { SharedContext } from '../../../utils/common';
import { TableBody, Tooltip } from '@mui/material';
import Header from '../components/Header';
import EditIcon from '../../../assets/icons/editIconDark.svg';
import AddDriverPopup from './components/driverPopup';
import API from '../../../libs/axios';

const DriverView = () => {
  const { setCurrentPageTitle, organizationType, setAPILoader } =
    useContext(SharedContext);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [drivers, setDrivers] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [contentHover, setContentHover] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [addDriverView, setAddDriverView] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [columnNames, setColumnNames] = useState([
    'Driver Name',
    'Carrier Name',
    'Nic',
    'Driving License Number',
    'Driving License Expiry Date'
  ]);

  const tableContainerRef = useRef(null);

  useEffect(() => {
    setCurrentPageTitle('Drivers');
    setColumnNames([
      'Driver Name',
      'Carrier Name',
      'Nic',
      'Driver License Number',
      'Driving License Expiry Date'
    ]);
  }, [organizationType]);

  const { refetch, isFetching: driverLoading } = useQuery(
    ['fetchDrivers', {}],
    () => {
      const payload = {
        page,
        searchKeyword
      };
      return getDrivers(payload, setAPILoader);
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data) {
          if (page === 1) {
            setDrivers(data.data);
            setPageCount(data.pages);
          } else {
            setDrivers([...drivers, ...data.data]);
            setPageCount(data.pages);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const _getDrivers = useCallback(
    debounce(() => {
      refetch();
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    _getDrivers();
  }, [page, searchKeyword]);

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !driverLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
    }
  };

  const fetchDriver = async (id) => {
    try {
      setAPILoader(true);
      const driver = await API.get(`drivers/${id}`);
      setSelectedDriver(driver.driver);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  // const classes = useStyles();

  const tableCellClass = 'py-2 h-12 pl-0 pr-2';
  const cellValue = 'table-cell-trucate  text-normal';
  const cellSubValue = clsx(cellValue, 'text-[10px]');

  return (
    <div className="no-scrollbar overflow-hidden">
      <div className="w-full mt-10 pl-8 pr-6">
        <Header
          onClick={() => setAddDriverView(true)}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          addBtnLabel={'Add Driver'}
          headerText={'All Drivers'}
          searchPlaceHolder={'Search Driver'}
        />
        <div className="mb-5">
          <Paper className="no-scrollbar rounded-t-lg shadow-none">
            <TableContainer
              onMouseEnter={() => {
                setContentHover(true);
              }}
              onMouseLeave={() => {
                setContentHover(false);
              }}
              style={{ overflow: 'auto' }}
              ref={tableContainerRef}
              onScroll={handleTableScroll}
              className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
            >
              <Table stickyHeader>
                <TableHead className="z-40 relative">
                  <TableRow>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className={`text-[10px] text-dark500 bg-white py-2 pr-2 font-normal h-[40px] ${
                          index == 0 ? 'pl-2' : 'pl-0'
                        }`}
                      >
                        {columnName}
                      </TableCell>
                    ))}
                    <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drivers.map((row, index) => (
                    <TableRow
                      key={index}
                      onMouseEnter={() => {
                        setHoveredRow(index);
                      }}
                      onMouseLeave={() => {
                        setHoveredRow(-1);
                      }}
                      className={clsx(
                        'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                      )}
                      onClick={() => {}}
                    >
                      <TableCell
                        className={`${tableCellClass} pl-2 group-hover:text-primaryBlue`}
                      >
                        <Tooltip title={`${row?.name}`}>
                          <p className={clsx(cellSubValue)}>
                            <span>{row?.name}</span>
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip title={`${row?.Vendor?.name}`}>
                          <p className={clsx(cellSubValue)}>
                            {`${row?.Vendor?.name}`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip title={`${row?.cnicNumber}`}>
                          <p className={clsx(cellSubValue)}>
                            {`${row?.cnicNumber}`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip title={`${row?.drivingLicenseNumber}`}>
                          <p className={clsx(cellSubValue)}>
                            {`${row?.drivingLicenseNumber || '-'}`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        <Tooltip
                          title={`${
                            row?.drivingLicenseExpiryDate
                              ? moment(row.drivingLicenseExpiryDate).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'
                          }`}
                        >
                          <p className={clsx(cellSubValue)}>
                            {`${
                              row?.drivingLicenseExpiryDate
                                ? moment(row.drivingLicenseExpiryDate).format(
                                    'DD/MM/YYYY'
                                  )
                                : '-'
                            }`}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={`${tableCellClass} group-hover:text-primaryBlue`}
                      >
                        {hoveredRow == index && (
                          <div
                            className="flex gap-2"
                            onClick={async () => {
                              // setSelectedDriver(row);
                              await fetchDriver(row.id);
                              setAddDriverView(true);
                            }}
                          >
                            <img src={EditIcon} />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      {addDriverView && (
        <AddDriverPopup
          open={addDriverView}
          setOpen={setAddDriverView}
          organizationType={organizationType}
          selectedDriver={selectedDriver}
          refetch={_getDrivers}
        />
      )}
    </div>
  );
};

export default DriverView;

/*eslint-disable*/
import Button from '../../../core-components/atoms/Button';
import { decimalDisplay } from '../../../utils/common';

const PromoConfirmation = ({
  type,
  removeQuantity = 0,
  selectedInventoryDetails,
  handleSubmit,
  setOpen
}) => {
  return (
    <div className="p-8 pt-0 flex flex-col justify-between h-full">
      <div>
        <p className="text-primaryBlue mb-4">
          This will remove Promo{' '}
          <span>{selectedInventoryDetails?.promoName}</span> from{' '}
          {removeQuantity === selectedInventoryDetails?.promoQuantity
            ? 'All'
            : ''}{' '}
          {decimalDisplay(removeQuantity)} items. You can always reapply the
          promo at any time.
        </p>
      </div>

      <div className="w-full flex justify-end gap-4">
        <Button
          size="small"
          variant="transparent"
          label="Cancel"
          className="h-9"
          labelClass="font-medium text-sm"
          onClick={() => {
            setOpen(false);
          }}
        />
        <Button
          size="small"
          variant="primary"
          className="h-9"
          label="Remove Promo"
          labelClass="font-medium text-sm"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default PromoConfirmation;

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { GoogleApiWrapper } from 'google-maps-react';
import { React, useContext, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import GoogleMap from '../../../components/GoogleMap';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import nodeClasses from '../../../utils/enums/nodeClasses';
import nodeTypes from '../../../utils/enums/nodeTypes';
import { toaster } from '../../../utils/toaster';
import { SharedContext } from '../../../utils/common';
import { GOOGLE_MAP_API_KEY } from '../../../constants';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  }
}));

const valSchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  cityId: Yup.string().required('City is required'),
  nodeTypeId: Yup.string().required('Node Type is required'),
  nodeClassId: Yup.string().required('Node Class is required'),
  locationLatLng: Yup.object().shape({
    lng: Yup.string().required('Longitude is required'),
    lat: Yup.string().required('Latitude is required')
  }),
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required')
});

const AddOrderNodeView = ({
  addNode,
  open,
  handleClose,
  dialogTitle,
  initialValues,
  source
}) => {
  const ref = useRef(null);
  const classes = useStyles();
  const [address, setAddress] = useState('');
  const [locationLatLng, setLocationLatLng] = useState({ lng: '', lat: '' });
  const [singleLocationLatlng, setSingleLocationLatlng] = useState({});
  const [singleLocationAddress, setSingleLocationAddress] = useState('');
  const [cities, setCities] = useState([]);
  const [attemptedAlready, setAttemptedAlready] = useState(false); // to handle submit only once
  const { setAPILoader } = useContext(SharedContext);
  //const [nodeClasses, setNodeClasses] = useState([]);

  const _getCities = async () => {
    try {
      const city = await API.get(`cities`);
      setCities(city.data);
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    if (open) _getCities();
  }, [open]);

  useEffect(() => {
    setAddress(singleLocationAddress);
    ref.current.values.address = singleLocationAddress;
    setLocationLatLng(
      singleLocationAddress && singleLocationLatlng
        ? singleLocationLatlng
        : { lat: '', lng: '' }
    );
    ref.current.values.locationLatLng =
      singleLocationAddress && singleLocationLatlng
        ? singleLocationLatlng
        : { lat: '', lng: '' };
  }, [singleLocationAddress]);

  useEffect(() => {
    setLocationLatLng(
      singleLocationAddress && singleLocationLatlng
        ? singleLocationLatlng
        : { lat: '', lng: '' }
    );
    ref.current.values.locationLatLng =
      singleLocationAddress && singleLocationLatlng
        ? singleLocationLatlng
        : { lat: '', lng: '' };
  }, [singleLocationLatlng]);

  useEffect(() => {
    if (initialValues && initialValues.id) {
      setSingleLocationLatlng(initialValues.locationLatLng);
      setSingleLocationAddress(initialValues.address);
    } else {
      ref.current.values.cityId = '';
      ref.current.values.cityName = '';
      ref.current.values.nodeClassId = '';
      ref.current.values.nodeClass = '';
      ref.current.values.nodeTypeId = '';
      ref.current.values.nodeType = '';
      ref.current.values.pocName = '';
      ref.current.values.pocContact = '';
      setAddress('');
      setLocationLatLng({ lat: '', lng: '' });
      setSingleLocationLatlng('');
      setSingleLocationAddress('');
      ref.current.values.name = '';
      ref.current.values.description = '';
    }
  }, [initialValues]);

  const addSubmitHandler = async () => {
    if (attemptedAlready) return;
    try {
      setAttemptedAlready(true);
      setAPILoader(true);
      if (ref.current.values.customId) {
        await API.put(
          `orders/${ref.current.values.customId}/node`,
          ref.current.values
        );
        addNode({
          id: ref.current.values.id,
          name: ref.current.values.name,
          code: ref.current.values.code,
          source: source,
          address: ref.current.values.address,
          pocContact: ref.current.values.pocContact,
          pocName: ref.current.values.pocName,
          isActive: ref.current.values.isActive
        });
        toaster('success', 'Node updated successfully');
      } else {
        var resNode = await API.post(`orders/node`, ref.current.values);
        resNode.data.source = source;
        addNode(resNode.data);
        toaster('success', 'Node created successfully');
      }
      setAddress('');
      setLocationLatLng({ lat: '', lng: '' });
    } catch (err) {
      onError(err);
    } finally {
      setAttemptedAlready(false);
      setAPILoader(false);
    }
  };

  const phoneNumberMask = '0399-9999999';
  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={valSchema}
      enableReinitialize={true}
      onSubmit={addSubmitHandler}
    >
      {({ values, setFieldValue, errors, touched, handleChange }) => {
        return (
          <div style={{ display: 'inline' }}>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
              fullWidth
            >
              <DialogTitle disableTypography className="title">
                {dialogTitle}
              </DialogTitle>
              <Form>
                <DialogContent>
                  {/* {formErrors} */}
                  <Grid container spacing={2}>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      style={{ position: 'relative', minHeight: 300 }}
                    >
                      <GoogleMap
                        setSingleLocationLatlng={setSingleLocationLatlng}
                        singleLocationLatlng={singleLocationLatlng}
                        showSingleSearchField={true}
                        setSingleLocationAddress={setSingleLocationAddress}
                        editable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <Autocomplete
                          id="nodeClasses"
                          key={nodeClasses}
                          options={nodeClasses}
                          defaultValue={
                            values?.nodeClassId
                              ? {
                                  nodeClassId: values?.nodeClassId,
                                  nodeClass: values?.nodeClass
                                }
                              : ''
                          }
                          value={
                            values?.nodeClassId
                              ? {
                                  nodeClassId: values?.nodeClassId,
                                  nodeClass: values?.nodeClass
                                }
                              : ''
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Node Class"
                              variant="outlined"
                            />
                          )}
                          getOptionLabel={(nodeClass) => {
                            if (nodeClass && nodeClass.nodeClass)
                              return nodeClass.nodeClass;
                            else {
                              return nodeClass || '';
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (newValue?.nodeClassId) {
                              setFieldValue(
                                'nodeClassId',
                                newValue.nodeClassId
                              );
                              setFieldValue('nodeClass', newValue.nodeClass);
                            } else {
                              setFieldValue('nodeClassId', '');
                              setFieldValue('nodeClass', '');
                            }
                            setFieldValue('nodeType', '');
                            setFieldValue('nodeTypeId', '');
                          }}
                          onKeyUp={() => {
                            //setCityVal(event.target.value);
                          }}
                        />
                        {errors?.nodeClassId && touched?.nodeClassId ? (
                          <Typography color="error">
                            Node Class is required!
                          </Typography>
                        ) : (
                          ''
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <Autocomplete
                          id="nodeTypes"
                          key={nodeTypes}
                          options={
                            values?.nodeClassId
                              ? nodeTypes.filter(
                                  (nodeType) =>
                                    nodeType.nodeClassId == values?.nodeClassId
                                )
                              : []
                          }
                          defaultValue={
                            values?.nodeTypeId
                              ? {
                                  nodeType: values?.nodeType,
                                  nodeTypeId: values?.nodeTypeId
                                }
                              : ''
                          }
                          value={
                            values?.nodeTypeId
                              ? {
                                  nodeType: values?.nodeType,
                                  nodeTypeId: values?.nodeTypeId
                                }
                              : ''
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Node Type"
                              variant="outlined"
                            />
                          )}
                          getOptionLabel={(nodeType) => {
                            if (nodeType && nodeType.nodeType)
                              return nodeType.nodeType;
                            else {
                              return nodeType || '';
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (newValue?.nodeTypeId) {
                              setFieldValue('nodeType', newValue.nodeType);
                              setFieldValue('nodeTypeId', newValue.nodeTypeId);
                            } else {
                              setFieldValue('nodeType', '');
                              setFieldValue('nodeTypeId', '');
                            }
                          }}
                          onKeyUp={() => {
                            //setCityVal(event.target.value);
                          }}
                        />
                        {errors?.nodeTypeId && touched?.nodeTypeId ? (
                          <Typography color="error">
                            Node Type is required!
                          </Typography>
                        ) : (
                          ''
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <Autocomplete
                          // id="cities"
                          options={cities}
                          defaultValue={
                            values?.cityId
                              ? { name: values?.cityName, id: values?.cityId }
                              : ''
                          }
                          value={
                            values?.cityId
                              ? { name: values?.cityName, id: values?.cityId }
                              : ''
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              variant="outlined"
                            />
                          )}
                          getOptionLabel={(city) => {
                            if (city && city.name) return city.name;
                            else {
                              return city || '';
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (newValue?.id) {
                              setFieldValue('cityId', newValue.id);
                              setFieldValue('cityName', newValue.name);
                            } else {
                              setFieldValue('cityId', '');
                              setFieldValue('cityName', '');
                            }
                          }}
                          filterOptions={(options, state) => {
                            if (state.inputValue) {
                              return options.filter(
                                (option) =>
                                  option.name
                                    .toLowerCase()
                                    .indexOf(state.inputValue.toLowerCase()) !==
                                  -1
                              );
                            }
                            return options;
                          }}
                        />
                        {errors?.cityId && touched?.cityId ? (
                          <Typography color="error">
                            City is required!
                          </Typography>
                        ) : (
                          ''
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          className={classes.labelBox}
                          margin="dense"
                          id="address"
                          name="address"
                          autoComplete="off"
                          label="Address"
                          type="text"
                          variant="outlined"
                          value={address}
                        />
                        {errors.address && touched.address ? (
                          <div>
                            <Typography color="error">
                              {errors.address}
                            </Typography>
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          className={classes.labelBox}
                          margin="dense"
                          id="longitude"
                          name="locationLatLng.lng"
                          autoComplete="off"
                          label="Longitude"
                          type="text"
                          variant="outlined"
                          value={
                            locationLatLng && locationLatLng.lng
                              ? locationLatLng.lng
                              : ''
                          }
                          onChange={handleChange}
                        />
                        {errors &&
                        errors.locationLatLng &&
                        errors.locationLatLng.lng &&
                        touched &&
                        touched.locationLatLng &&
                        touched.locationLatLng.lng ? (
                          <div>
                            <Typography color="error">
                              {errors.locationLatLng.lng}
                            </Typography>
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          className={classes.labelBox}
                          margin="dense"
                          id="latitude"
                          name="locationLatLng.lat"
                          autoComplete="off"
                          label="Latitude"
                          type="text"
                          variant="outlined"
                          value={locationLatLng ? locationLatLng.lat : ''}
                          onChange={handleChange}
                        />
                        {errors &&
                        errors.locationLatLng &&
                        errors.locationLatLng.lat &&
                        touched &&
                        touched.locationLatLng &&
                        touched.locationLatLng.lat ? (
                          <div>
                            <Typography color="error">
                              {errors.locationLatLng.lat}
                            </Typography>
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          className={classes.labelBox}
                          margin="dense"
                          id="pocName"
                          name="pocName"
                          autoComplete="off"
                          label="POC Name"
                          type="text"
                          variant="outlined"
                          value={values.pocName || ''}
                          onChange={handleChange}
                        />
                        {errors &&
                        errors.pocName &&
                        touched &&
                        touched.pocName ? (
                          <div>
                            <Typography color="error">
                              {errors.pocName}
                            </Typography>
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} md={6}>
                      <FormControl fullWidth={true} variant="outlined">
                        <InputMask
                          className={clsx({ ['mask-text']: true })}
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          mask={phoneNumberMask}
                          margin="dense"
                          id="pocContact"
                          autoComplete="off"
                          name="pocContact"
                          label="POC Contact"
                          type="text"
                          variant="outlined"
                          value={values.pocContact || ''}
                          onChange={(e) => {
                            setFieldValue('pocContact', e.target.value);
                          }}
                          style={{
                            height: '17%',
                            width: '90%',
                            marginLeft: 0,
                            marginTop: 6,
                            borderColor: '#c4c4c4',
                            color: '#2f2727',
                            fontWeight: 600,
                            padding: '17px 12px'
                          }}
                        >
                          {() => (
                            <TextField
                              fullWidth={true}
                              inputProps={{ className: classes.textBox }}
                              className={classes.labelBox}
                              margin="dense"
                              name="phone"
                              label="POC Contact"
                              id="companyPhone"
                              autoComplete="off"
                              type="text"
                              variant="outlined"
                            />
                          )}
                        </InputMask>

                        {errors &&
                        errors.pocContact &&
                        touched &&
                        touched.pocContact ? (
                          <div>
                            <Typography color="error">
                              {errors.pocContact}
                            </Typography>
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} style={{ position: 'relative' }}>
                      <h3>Description</h3>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} md={6}>
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          className={classes.labelBox}
                          margin="dense"
                          id="name"
                          name="name"
                          autoComplete="off"
                          label="Location Name"
                          type="text"
                          variant="outlined"
                          value={values.name || ''}
                          onChange={(e) => {
                            setFieldValue(
                              'name',
                              e.target.value ? e.target.value : ''
                            );
                          }}
                        />
                        {errors.name && touched.name ? (
                          <div>
                            <Typography color="error">{errors.name}</Typography>
                          </div>
                        ) : null}
                      </FormControl>
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          fullWidth={true}
                          inputProps={{ className: classes.textBox }}
                          className={classes.labelBox}
                          margin="dense"
                          id="code"
                          name="code"
                          autoComplete="off"
                          label="Location Code"
                          type="text"
                          variant="outlined"
                          value={values.code || ''}
                          onChange={(e) => {
                            setFieldValue(
                              'code',
                              e.target.value ? e.target.value : ''
                            );
                          }}
                        />
                        {errors.code && touched.code ? (
                          <div>
                            <Typography color="error">{errors.code}</Typography>
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} md={6}>
                      <FormControl fullWidth={true} variant="outlined">
                        <TextField
                          fullWidth={true}
                          multiline
                          rows={6}
                          inputProps={{
                            inputProps: { maxLength: 1000 },
                            className: classes.memoBox
                          }}
                          className={classes.labelBox}
                          margin="dense"
                          id="description"
                          name="description"
                          label="Description"
                          type="text"
                          variant="outlined"
                          value={values.description}
                          onChange={(e) => {
                            setFieldValue(
                              'description',
                              e.target.value ? e.target.value : ''
                            );
                          }}
                        />
                        {errors.description && touched.description ? (
                          <div>
                            <Typography color="error">
                              {errors.description}
                            </Typography>
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <Checkbox
                        checked={values.isActive}
                        onChange={(e) => {
                          setFieldValue(
                            'isActive',
                            e.target.checked ? e.target.checked : ''
                          );
                        }}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      Active
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="groupBtn">
                  <Tooltip title="Cancel">
                    <Button
                      onClick={() => {
                        handleClose();
                        // setValidation("");
                        // reset()
                      }}
                      color="default"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Save">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={attemptedAlready}
                      className="notificationBtn"
                    >
                      Save
                    </Button>
                  </Tooltip>
                </DialogActions>
              </Form>
            </Dialog>
          </div>
        );
      }}
    </Formik>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY
})(AddOrderNodeView);

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { BACKDROP } from '../../../constants/colors';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
import { decimalDisplay } from '../../../utils/common';
import { Tooltip } from '@material-ui/core';
import useStyles from '../../inventory/inventoryStyles';
import AlertIcon from '../../../assets/icons/error.svg';

const NodeCard = ({ node }) => {
  const classes = useStyles();

  const getReasonStr = (reason) => {
    switch (reason) {
      case 'STOCK_IS_SHORT':
        return 'Stock is Short';
      case 'DAMAGED':
        return 'Damaged';
      case 'DIFFERENT_BATCH':
        return 'Different batch';
      default:
        return reason;
    }
  };

  return (
    <TableRow
      sx={{
        '& > *': { borderBottom: 'unset' }
      }}
    >
      <TableCell colSpan={7}>
        <Grid
          container
          className={`mt-2 rounded ${'w-[98%]'} bg-[#F6F8FB] px-4 my-4 ml-2 group border border-[#0B1940] border-dashed`}
          spacing={2}
        >
          <Grid item xs={3}>
            <Label variant={'xs'}>Location Details</Label>
          </Grid>
          <Grid item xs={2}>
            <Label variant={'xs'}>Batch No</Label>
          </Grid>
          <Grid item xs={1}>
            <Label variant={'xs'}>Order ID</Label>
          </Grid>
          <Grid item xs={2}>
            <Label variant={'xs'}>Mfg. & Exp. Date</Label>
          </Grid>
          <Grid item xs={1}>
            <Label variant={'xs'}>Pallet iD</Label>
          </Grid>
          <Grid item xs={1}>
            <Label variant={'xs'}>MRP</Label>
          </Grid>
          <Grid item xs={1}>
            <Label variant={'xs'}>available</Label>
          </Grid>
          <Grid item xs={1}>
            <Label variant={'xs'}>Picked QTY</Label>
          </Grid>
          {node?.WarehouseTaskProductDetails?.map((product) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <>
                <Grid item xs={3}>
                  <Title variant={'sm'}>
                    {product?.InventoryDetail?.WarehouseHall?.name}
                  </Title>
                  <Paragraph variant={'xs'} className={'flex'}>
                    <b>{'Aisle:'}</b>
                    {product?.InventoryDetail?.WarehouseHall?.Aisle?.name}
                    <b className={'ml-2'}>{'Bay:'} </b>
                    {product?.InventoryDetail?.WarehouseHall?.Bay?.name}
                  </Paragraph>
                </Grid>
                <Grid item xs={2}>
                  <Paragraph variant={'sm'} className="flex">
                    {product?.InventoryDetail?.batchNumber || '-'}
                    {product?.physicalQuantity < product?.cocQuantity && (
                      <Tooltip
                        title={
                          <>
                            <p>
                              Reason: {getReasonStr(product?.reason) || 'N/A'}
                            </p>
                            <p>Comment: {product?.comment || 'N/A'}</p>
                          </>
                        }
                        placement="top"
                        classes={{
                          tooltip: classes.customTooltip,
                          arrow: classes.arrow
                        }}
                        arrow
                        className="ms-1"
                      >
                        <span>
                          <img src={AlertIcon} alt="alert" />
                        </span>
                      </Tooltip>
                    )}
                  </Paragraph>
                </Grid>
                <Grid item xs={1}>
                  <Paragraph variant={'sm'}>
                    {product?.OrdersTask?.Order?.customId ?? '-'}
                  </Paragraph>
                </Grid>
                <Grid item xs={2}>
                  <Label variant={'xs'}>
                    {product?.InventoryDetail?.manufacturingDate
                      ? moment(
                          product?.InventoryDetail?.manufacturingDate
                        ).format('DD/MM/YY')
                      : 'N/A'}{' '}
                    -{' '}
                    {product?.InventoryDetail?.expiryDate
                      ? moment(product?.InventoryDetail?.expiryDate).format(
                          'DD/MM/YY'
                        )
                      : 'N/A'}
                  </Label>
                </Grid>
                <Grid item xs={1}>
                  <Label variant={'xs'}>
                    {product?.InventoryDetail?.Pallet
                      ? product?.InventoryDetail?.Pallet
                      : '-'}
                  </Label>
                </Grid>
                <Grid item xs={1}>
                  <Label variant={'xs'}>
                    {product?.InventoryDetail?.MRP
                      ? product?.InventoryDetail?.MRP
                      : '-'}
                  </Label>
                </Grid>
                <Grid item xs={1}>
                  <Label variant={'xs'}>{product?.cocQuantity}</Label>
                </Grid>
                <Grid item xs={1}>
                  <Label variant={'xs'}>{product?.physicalQuantity}</Label>
                </Grid>
              </>
            );
          })}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export const TaskDetail = ({ taskDetails }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  return (
    <div>
      <Grid className="box-border w-full border-[1px] mt-[8px] rounded-md border-solid border-dark300 mt-16">
        <Table aria-label="collapsible table" className="parent-DataTable">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ width: '15%', padding: '16px 6px 16px 10px' }}
                className={`w-[240px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  SKU & CODE NAME
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '18%', padding: '16px 4px' }}
                className={`w-[120px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  Location
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '15%', padding: '16px 4px' }}
                className={`w-[140px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  ORDERS
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '13%', padding: '16px 4px' }}
                className={`w-[150px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  ORDER QTY
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '13%', padding: '16px 4px' }}
                className={`w-[150px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  QTY TO PICK
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '13%', padding: '16px 4px' }}
                className={`w-[150px]`}
              >
                <Label variant={'xs'} className={'text-[#696F80]'}>
                  PICK ACTUAL QUANTITY
                </Label>
              </TableCell>
              <TableCell
                sx={{ width: '13%', padding: '16px 4px' }}
                className={`w-[150px]`}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskDetails?.WarehouseTaskProducts?.map((order) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <>
                  <TableRow
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom:
                          open === order?.id ? 'none' : '1px solid #E7E6E6'
                      }
                    }}
                    className="group hover:cursor-pointer"
                    onClick={() =>
                      order.WarehouseTaskProductDetails?.length > 0 &&
                      setOpen(open === order?.id ? null : order?.id)
                    }
                  >
                    <TableCell
                      sx={{ width: '13%', padding: '16px 6px 16px 10px' }}
                    >
                      <Title variant={'sm'} className="flex">
                        {order?.Product?.name +
                          '-' +
                          order?.Product?.description}
                        {!!(order?.WarehouseTaskProductDetails ?? []).find(
                          (p) => p.physicalQuantity < p.cocQuantity
                        ) && (
                          <Tooltip
                            title="Short Picked"
                            placement="top"
                            classes={{
                              tooltip: classes.customTooltip,
                              arrow: classes.arrow
                            }}
                            arrow
                            className="ms-1"
                          >
                            <span>
                              <img src={AlertIcon} alt="alert" />
                            </span>
                          </Tooltip>
                        )}
                      </Title>
                      <Paragraph variant={'xs'}>
                        SKU: {order?.Product?.id}
                      </Paragraph>
                    </TableCell>
                    <TableCell sx={{ width: '15%', padding: '16px 4px' }}>
                      <Paragraph variant={'sm'}>
                        {order?.WarehouseTaskProductDetails?.length}
                      </Paragraph>
                    </TableCell>
                    <TableCell sx={{ width: '15%', padding: '16px 4px' }}>
                      <Paragraph variant={'sm'}>
                        {taskDetails?.order
                          .map(
                            (val, index) => (index ? ', ' : '') + val.customId
                          )
                          ?.join('')}
                      </Paragraph>
                    </TableCell>
                    <TableCell sx={{ width: '13%', padding: '16px 4px' }}>
                      <Paragraph variant={'sm'}>
                        {taskDetails?.productGroups
                          ?.filter((val) => val.productId === order?.productId)
                          ?.reduce(
                            (accumulator, val) =>
                              accumulator + val.originalOrderedQuantity,
                            0
                          )}
                      </Paragraph>
                    </TableCell>
                    <TableCell sx={{ width: '13%', padding: '16px 4px' }}>
                      <Paragraph variant={'sm'}>
                        {order?.totalCOCQty
                          ? decimalDisplay(order?.totalCOCQty)
                          : '-'}
                      </Paragraph>
                    </TableCell>
                    <TableCell sx={{ width: '20%', padding: '16px 4px' }}>
                      <Paragraph variant={'sm'}>
                        {order?.totalPhysicalQty
                          ? decimalDisplay(order?.totalPhysicalQty)
                          : '-'}
                      </Paragraph>
                    </TableCell>
                    <TableCell sx={{ width: '20%', padding: '16px 4px' }}>
                      {order.WarehouseTaskProductDetails?.length > 0 && (
                        <div className={`flex items-center justify-end`}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setOpen(open === order?.id ? null : order?.id)
                            }
                          >
                            {open === order?.id ? (
                              <KeyboardArrowUp style={{ color: BACKDROP }} />
                            ) : (
                              <KeyboardArrowDown style={{ color: BACKDROP }} />
                            )}
                          </IconButton>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                  {open === order?.id &&
                    order.WarehouseTaskProductDetails?.length > 0 && (
                      <NodeCard node={order} taskDetails={taskDetails} />
                    )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </div>
  );
};

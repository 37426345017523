import React, { useEffect, useContext, useState, useCallback } from 'react';
import { checkPermission } from '../../../utils/auth';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdSearch,
  MdCancel,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import classNames from 'classnames/bind';
import { DataGrid } from '@mui/x-data-grid';

import {
  makeStyles,
  Grid,
  Paper,
  InputBase,
  Button,
  Table,
  TableBody,
  Tooltip,
  TableContainer
} from '@material-ui/core';
import TableHeader from '../../../components/TableHeader';
import Filter from '../../../components/Filter';

import axios from 'axios';
import { getURL, SharedContext } from '../../../utils/common';
import AddUserView from './AddUserView';
import EditGroupAccessView from './EditGroupAccessView';
import { Alert, Pagination } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/EditOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ConfirmDelete from '../../../components/ConfirmDelete';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import MessageSnackbar from '../../../components/MessageSnackbar';
import '../../../assets/css/style.css';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import WarehouseUserView from './WarehouseUserView';
import { toaster } from '../../../utils/toaster';
import FilterButton from '../../../components/stats/FilterButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px'
  },
  container: {
    padding: 20
  },
  pagination: {
    border: 'none',
    display: 'block',
    alignItems: 'right'
  },
  active: {
    color: theme.palette.success.main
  },
  searchInput: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  addUserButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold',
    '@media (max-width:1024px)': {
      marginRight: '10px !important'
    },
    '@media only screen and (max-width: 992px) and (min-width:769px)': {
      marginRight: '0px !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      marginRight: '0px !important'
    }
  }
}));

export default function UserView() {
  const classes = useStyles();
  const isPhone = useMediaQuery('(max-width:768px)');
  const { isFeatureEnabled } = useFeatureFlags();

  const columnsNew = [
    {
      field: 'isActive',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      width: 50,
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'firstName',
      headerName: 'First name',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            First Name
            {fetchState && firstNameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setFirstNameVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('firstName');
              }}
              className={
                sortable.col == 'firstName' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'firstName' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.firstName}>
          <span className="table-cell-trucate">{params?.row?.firstName}</span>
        </Tooltip>
      )
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Last Name
            {fetchState && lastNameval ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setLastNameVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('lastName');
              }}
              className={
                sortable.col == 'lastName' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'lastName' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.lastName}>
          <span className="table-cell-trucate">{params?.row?.lastName}</span>
        </Tooltip>
      )
    },
    {
      field: 'username',
      headerName: 'User Name',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            User Name
            {fetchState && userNameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setUserNameVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('username');
              }}
              className={
                sortable.col == 'username' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'username' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.username}>
          <span className="table-cell-trucate">{params?.row?.username}</span>
        </Tooltip>
      )
    },
    {
      field: 'email',
      headerName: 'Email',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Email
            {fetchState && emailVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setEmailVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('email');
              }}
              className={
                sortable.col == 'email' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'email' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.email}>
          <span className="table-cell-trucate">{params?.row?.email}</span>
        </Tooltip>
      )
    },
    {
      field: 'name',
      headerName: 'Role',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.UserRoles?.[0]?.Role) {
          if (params?.row?.UserRoles?.[0]?.Role?.name) {
            result.push(params?.row?.UserRoles?.[0]?.Role?.name);
          }
        }
        return result.join(', ');
      },
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Role
            {fetchState && role ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setRole('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Role.name');
              }}
              className={
                sortable.col == 'Role.name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Role.name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.Role?.name}>
          <span className="table-cell-trucate">
            {params?.row?.UserRoles?.[0]?.Role?.name}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'phone',
      headerName: 'Phone',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Phone
            {fetchState && phoneVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  type="button"
                  onClick={() => {
                    setPhoneVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('phone');
              }}
              className={
                sortable.col == 'phone' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'phone' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.phone}>
          <span className="table-cell-trucate">{params?.row?.phone}</span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      width: 100,
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_USER_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={params}
                portals={portals}
                onClick={() => openEditView(params.row)}
              />
            </Tooltip>
          ) : (
            ''
          )}
          {isFeatureEnabled(FLAGS.USER_PERMISSION_ACCESS) &&
          checkPermission(currentUser, 'OPS_GROUPACCESS_READ') ? (
            <Tooltip title="Role Permissions">
              <VpnKeyIcon
                key="permissions"
                onClick={() => openGroupAccessView(params.row)}
              />
            </Tooltip>
          ) : (
            ''
          )}
          {isFeatureEnabled(FLAGS.USER_PERMISSION_ACCESS) &&
          checkPermission(currentUser, 'OPS_GROUPACCESS_READ') ? (
            <Tooltip title="Warehouse Permissions">
              <BusinessIcon
                key="permissions"
                onClick={() => openWarehouseView(params.row)}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [portals, setPortals] = useState([]);
  const [roles, setRoles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [formErrors, setFormErrors] = useState('');
  const [addUserViewOpen, setAddUserViewOpen] = useState(false);
  const [filterView, setFilterView] = useState(false);
  const [deleteUserViewOpen, setDeleteUserViewOpen] = useState(false);
  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  const [firstNameVal, setFirstNameVal] = useState('');
  const [lastNameval, setLastNameVal] = useState('');
  const [userNameVal, setUserNameVal] = useState('');
  const [emailVal, setEmailVal] = useState('');
  const [phoneVal, setPhoneVal] = useState('');
  const [role, setRole] = useState('');
  const [fetchState, setFetchState] = useState(false);
  const [sortable, setSortable] = useState('');
  const [warehouseViewOpen, setWarehouseViewOpen] = useState(false);
  const [attemptedAlready, setAttemptedAlready] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  useEffect(() => {
    setCurrentPageTitle('User(s)');
    mixpanel.track('Users Page', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes();
  }, [
    firstNameVal,
    lastNameval,
    userNameVal,
    emailVal,
    role,
    phoneVal,
    sortable
  ]);
  const [groupAccessViewOpen, setGroupAccessViewOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showMessage, setShowMessage] = useState(null);
  const { currentUser, subdomain, setAPILoader } = useContext(SharedContext);

  const addUser = async (data) => {
    let users = null;
    try {
      setAttemptedAlready(true);
      setAPILoader(true);
      if (!selectedUser) {
        users = await API.post(`organizations/${subdomain}/users`, data);
        toaster('success', 'User has been created successfully');
      } else {
        users = await API.put(
          `organizations/${subdomain}/users/${selectedUser.id}`,
          data
        );
        toaster('success', 'User has been updated successfully');
      }

      users && closeAddUserView();
      getUsers(page);
      return true;
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAttemptedAlready(false);
      setAPILoader(false);
    }
  };

  const addWarehoseToUser = async (data) => {
    setFormErrors('');
    setAPILoader(true);
    try {
      await API.post('permission-accesses/user-warehouse', data);
      closeWarehouseView();
      getUsers(page);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      toaster('success', 'Warehouse list updated successfully.');
      setAPILoader(false);
    }
  };

  const openWarehouseView = (user) => {
    setSelectedUser(user);
    setWarehouseViewOpen(true);
  };

  const closeWarehouseView = async () => {
    setSelectedUser(null);
    setWarehouseViewOpen(false);
    getUsers();
  };

  const deleteUser = () => {
    axios.delete(getURL(`user/${selectedUser.id}`)).then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      closeDeleteUserView();
      getUsers();
    });
  };

  const openEditView = (user) => {
    setSelectedUser(user);
    setAddUserViewOpen(true);
  };
  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const editWarehouseModal = (
    <WarehouseUserView
      key={10}
      formErrors={formErrors}
      selectedUser={selectedUser}
      open={warehouseViewOpen}
      addWarehoseToUser={addWarehoseToUser}
      currentUser={currentUser}
      handleClose={() => closeWarehouseView()}
    />
  );

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const closeAddUserView = () => {
    setSelectedUser(null);
    setAddUserViewOpen(false);
  };

  const openGroupAccessView = (user) => {
    setSelectedUser(user);
    setGroupAccessViewOpen(true);
  };

  const closeGroupAccessView = () => {
    setGroupAccessViewOpen(false);
    setSelectedUser(null);
  };

  const closeDeleteUserView = () => {
    setSelectedUser(null);
    setDeleteUserViewOpen(false);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (role) {
      colVal = { '$UserRoles.Role.name$': role };
    }
    if (firstNameVal) {
      colVal.firstName = firstNameVal;
    }
    if (lastNameval) {
      colVal.lastName = lastNameval;
    }
    if (userNameVal) {
      colVal.username = userNameVal;
    }
    if (emailVal) {
      colVal.email = emailVal;
    }
    if (phoneVal) {
      colVal.phone = phoneVal;
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = [
      'firstName',
      'lastName',
      'username',
      'email',
      'phone',
      '$UserRoles.Role.name$'
    ];

    _getUsers(page, searchKeyword, filters, columns);
  };

  const _getUsers = async (page, searchKeyword, filters, columns) => {
    try {
      setMuiLoader(true);
      const users = await API.get(`organizations/${subdomain}/users`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setPageCount(users.pages);
      setUsers(users.data);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    } finally {
      setMuiLoader(false);
    }
  };

  const _getRolesAndPortals = async () => {
    try {
      const rolesAndPortals = await API.get(
        `organizations/${subdomain}/users/user-portal-roles`
      );
      setRoles(rolesAndPortals.roles);
      setPortals(rolesAndPortals.portals);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    }
  };

  const _getCustomers = async () => {
    try {
      const customers = await API.get(`/companies`, {
        params: { type: 'CUSTOMER' }
      });
      setCustomers(customers.data);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    }
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const getUsers = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        sortable
      };
      let columns = [
        'firstName',
        'lastName',
        'username',
        'email',
        'phone',
        '$UserRoles.Role.name$'
      ];
      _getUsers(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );
  useEffect(() => {
    getUsers(page, searchKeyword);
  }, [page, searchKeyword]);

  const addPermissionToGroupAccess = async (data) => {
    try {
      setAPILoader(true);
      if (data.userId) await API.post('group-accesses', data);
      toaster('success', 'Group access new permissions attached successfully.');
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const removePermissionGroupAccess = async (data) => {
    try {
      if (data.userId) await API.delete('group-accesses', { data });
      toaster(
        'success',
        'Group access new permissions unattached successfully.'
      );
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  useEffect(() => {
    fetchRes();
  }, [
    role,
    firstNameVal,
    lastNameval,
    userNameVal,
    emailVal,
    phoneVal,
    sortable
  ]);

  useEffect(() => {
    _getCustomers();
  }, []);

  useEffect(() => {
    if (subdomain) _getRolesAndPortals();
  }, [subdomain]);

  const addUserButton = (
    <Tooltip title="Add User">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addUserButton}
        // color="primary"
        size="small"
        onClick={() => {
          setAddUserViewOpen(true);
          setSearchKeyword('');
        }}
      >
        ADD USER
      </Button>
    </Tooltip>
  );

  const searchInput = (
    <div className="search-box">
      {searchKeyword ? (
        <span className="search-cancel" onClick={() => setSearchKeyword('')}>
          <MdCancel className="searchIcons" />
        </span>
      ) : null}
      <InputBase
        className={mainClass}
        id="search"
        label="Search"
        type="text"
        variant="outlined"
        value={searchKeyword}
        key={3}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          setPage(1);
        }}
      />
      <span className="search-btn">
        <MdSearch className="searchIcons" />
      </span>
    </div>
  );

  const addUserModal = (
    <AddUserView
      key={4}
      formErrors={formErrors}
      portals={portals}
      roles={roles}
      customers={customers}
      selectedUser={selectedUser}
      open={addUserViewOpen}
      addUser={addUser}
      attemptedAlready={attemptedAlready}
      setAttemptedAlready={setAttemptedAlready}
      handleClose={() => closeAddUserView()}
    />
  );

  const deleteUserModal = (
    <ConfirmDelete
      key={5}
      confirmDelete={deleteUser}
      open={deleteUserViewOpen}
      handleClose={closeDeleteUserView}
      selectedEntity={
        selectedUser && selectedUser.firstName + ' ' + selectedUser.lastName
      }
      title={'User'}
    />
  );

  const groupAccessModal = (
    <EditGroupAccessView
      key={5}
      formErrors={formErrors}
      portals={portals}
      roles={roles}
      customers={customers}
      selectedUser={selectedUser}
      open={groupAccessViewOpen}
      createdPermission={
        checkPermission(currentUser, 'OPS_GROUPACCESS_CREATE') ? true : false
      }
      updatedPermission={
        checkPermission(currentUser, 'OPS_GROUPACCESS_UPDATE') ? true : false
      }
      addPermissionToGroupAccess={addPermissionToGroupAccess}
      removePermissionToGroupAccess={removePermissionGroupAccess}
      addUser={addUser}
      handleClose={closeGroupAccessView}
    />
  );

  let firstNameObj = {
    type: 'text',
    className: 'textField',
    label: 'First Name',
    field: 'firstName',
    value: firstNameVal ? firstNameVal : '',
    setNameVal: (e) => setNameVal(setFirstNameVal, e)
  };

  let lastNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Last Name',
    field: 'lastName',
    value: lastNameval ? lastNameval : '',
    setNameVal: (e) => setNameVal(setLastNameVal, e)
  };

  let userNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Username',
    field: 'username',
    value: userNameVal ? userNameVal : '',
    setNameVal: (e) => setNameVal(setUserNameVal, e)
  };

  let emailObj = {
    type: 'text',
    className: 'textField',
    label: 'Email',
    field: 'email',
    value: emailVal ? emailVal : '',
    setNameVal: (e) => setNameVal(setEmailVal, e)
  };

  let phoneObj = {
    type: 'text',
    className: 'textField',
    label: 'Phone',
    field: 'phone',
    value: phoneVal ? phoneVal : '',
    setNameVal: (e) => setNameVal(setPhoneVal, e)
  };

  let roleObj = {
    type: 'text',
    className: 'textField',
    label: 'Role',
    field: 'Role.name',
    value: role ? role : '',
    setNameVal: (e) => setNameVal(setRole, e)
  };

  const filterCols = [
    firstNameObj,
    lastNameObj,
    userNameObj,
    emailObj,
    phoneObj,
    roleObj
  ];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'User'}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const headerButtons = [
    isFeatureEnabled(FLAGS.USER_CREATION) &&
    checkPermission(currentUser, 'OPS_USER_CREATE')
      ? addUserButton
      : null,
    checkPermission(currentUser, 'OPS_USER_CREATE') ||
    checkPermission(currentUser, 'OPS_USER_UPDATE')
      ? addUserModal
      : null,
    checkPermission(currentUser, 'OPS_USER_DELETE') ? deleteUserModal : null,
    checkPermission(currentUser, 'OPS_USER_READ') ? filterModal : null,
    checkPermission(currentUser, 'OPS_GROUPACCESS_READ')
      ? editWarehouseModal
      : null,
    checkPermission(currentUser, 'OPS_GROUPACCESS_READ')
      ? groupAccessModal
      : null,
    checkPermission(currentUser, 'OPS_USER_READ') ? searchInput : null
  ];
  // Custom toolbar
  function CustomToolbar() {
    return (
      <FilterButton searchKeyword openFilterView={() => openFilterView()} />
    );
  }
  // Custom toolbar
  const visibleColumns = {
    firstName: true,
    id: false,
    lastName: true,
    username: true,
    email: true
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table" className="tableDiv">
          <TableBody>
            <div style={{ height: 1100, width: '100%' }}>
              <DataGrid
                rows={users}
                hiddenColumnModel={['id']}
                visibleColumns={visibleColumns}
                columns={columnsNew}
                components={{ Toolbar: CustomToolbar }}
                pageSize={20}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
                loading={muiLoader}
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="space-between">
        <Grid item></Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            className={classes.pagination}
            onChange={(e, newPage) => setPage(newPage)}
          />
        </Grid>
      </Grid>
      <MessageSnackbar showMessage={showMessage} />
    </Paper>
  );
}

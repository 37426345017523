import React from 'react';
import PrintDetail from './PrintDetail';

const PrintPdfLedgerDetail = ({
  data,
  productDetails,
  isManufacturer,
  startDate,
  endDate,
  setIsExportPopoverOpen
}) => {
  return (
    <PrintDetail
      data={data}
      productDetails={productDetails}
      isManufacturer={isManufacturer}
      startDate={startDate}
      endDate={endDate}
      setIsExportPopoverOpen={setIsExportPopoverOpen}
    />
  );
};

export default PrintPdfLedgerDetail;

/*eslint-disable*/
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState, useContext } from 'react';
import {
  Paper,
  Grid,
  InputBase,
  Button,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import '../../../assets/css/style.css';
import { SharedContext, getURL } from '../../../utils/common';
import TableHeader from '../../../components/TableHeader';
import axios from 'axios';
import { Alert, Pagination } from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/EditOutlined';
import ConfirmDelete from '../../../components/ConfirmDelete';
import Filter from '../../../components/Filter';
import AddCompanyView from './AddCompanyView';
import CompanyDetailsView from './CompanyDetailsView';
import { capitalize, debounce } from 'lodash';
import MessageSnackbar from '../../../components/MessageSnackbar';
import { DEBOUNCE_CONST } from '../../../Config';
import moment from 'moment-timezone';
import FileDownload from 'js-file-download';
import { checkPermission } from '../../../utils/auth';
import { useNavigate } from 'react-router';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdSearch,
  MdCancel,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import classNames from 'classnames/bind';
import { toaster } from '../../../utils/toaster';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    padding: 20,
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: 12
    }
  },
  CircularProgress: {
    height: 'calc(100% - 60px)',
    position: 'fixed',
    left: '100%',
    width: 'calc(100% - 256px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3051
  },
  active: {
    color: theme.palette?.success?.main || ''
  },
  searchInput: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  exportBtn: {
    marginLeft: 5
  },
  placeholderText: {
    color: '#CAC9C9',
    '& .MuiSelect-outlined': {
      paddingTop: '6px',
      paddingBottom: '6px'
    },
    marginRight: 5
  },
  addCompanyButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  },
  filterView: {
    '@media (max-width: 992px)': {
      width: '380px !important',
      left: ' 10px !important'
    },
    // "@media (min-width: 576px)": {
    //   width: "455px !important",
    // },
    '@media (max-width: 768px)': {
      width: '350px !important',
      left: '45px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '270px !important'
    }
  },
  tableHeader: {
    '@media (max-width: 992px)': {
      marginTop: '50px'
    }
  },
  toolBar: {
    '@media (max-width: 992px)': {
      top: '-150px !important'
    }
  },
  tooltipButton: {
    marginRight: '5px'
  },
  responsiveVendor: {
    '@media only screen and (max-width: 992px) and (min-width:769px)': {
      marginTop: '-42px'
    },
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      marginTop: '-42px'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      position: 'relative',
      top: '-44px',
      left: '10px'
    }
  }
}));

export default function CompanyView({ relationType }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [muiLoader, setMuiLoader] = useState(false);

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };
  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (company) {
      colVal.name = company;
    }
    if (type) {
      colVal.type = type;
    }

    if (contactName) {
      let compObj = { '$Contact.firstName$': contactName };
      Object.assign(colVal, compObj);
    }

    if (contactEmail) {
      let compEmailObj = { '$Contact.email$': contactEmail };
      Object.assign(colVal, compEmailObj);
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = ['name', 'type', '$Contact.firstName$', '$Contact.email$'];
    _getCompanies(page, searchKeyword, filters, columns);
  };

  const isPhone = useMediaQuery('(max-width:360px)');

  const columnsNew = [
    {
      field: 'isActive',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      width: 50,
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: relationType === 'CUSTOMER' ? 'ID' : 'Carrier ID',
      disableColumnMenu: true,
      sortable: false,
      width: 100,
      renderHeader: () => {
        return (
          <p className="w-100">
            {relationType === 'CUSTOMER' ? 'ID' : 'Carrier ID'}
            <button
              type="button"
              onClick={() => {
                sortBy('internalIdForBusiness');
              }}
              className={
                sortable.col == 'internalIdForBusiness' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'internalIdForBusiness' &&
              sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row.internalIdForBusiness) {
          result.push(params.row.internalIdForBusiness);
          return result.join(', ');
        }
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.internalIdForBusiness}`}>
          <span className="table-cell-trucate">
            {params.row.internalIdForBusiness.length > 20
              ? `${params.row.internalIdForBusiness.substring(0, 20)}...`
              : `${params.row.internalIdForBusiness}`}
          </span>
        </Tooltip>
      )
    },
    {
      field: relationType == 'CUSTOMER' ? 'Company' : 'Carrier Name',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            {relationType == 'CUSTOMER' ? 'Company' : 'Carrier Name'}{' '}
            {fetchState && company ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setCompany('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row.name) {
          result.push(params.row.name);
          return result.join(', ');
        }
      },
      renderCell: (params) => (
        <Tooltip title={`${params.row.name}`}>
          <span className="table-cell-trucate">
            {params.row.name.length > 20
              ? `${params.row.name.substring(0, 20)}...`
              : `${params.row.name}`}
          </span>
        </Tooltip>
      )
    },
    relationType === 'CUSTOMER' && {
      field: 'Company Type',
      disableColumnMenu: true,
      sortable: false,
      headerClassName: relationType == 'CUSTOMER' ? '' : 'display-none',
      cellClassName: relationType == 'CUSTOMER' ? '' : 'display-none',
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Company Type
            {fetchState && type ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setType('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('type');
              }}
              className={
                sortable.col == 'type' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'type' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row.type) {
          result.push(params.row.type);
          return result.join(', ');
        }
      }
    },
    {
      field: 'Contact Name',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Contact Name
            {fetchState && contactName ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setContactName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Contact.firstName');
              }}
              className={
                sortable.col == 'Contact.firstName' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Contact.firstName' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row.Contact) {
          result.push(
            params.row.Contact.firstName + '   ' + params.row.Contact.lastName
          );
          return result.join(', ');
        }
      }
    },
    {
      field: 'Contact Email',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Contact Email
            {fetchState && contactEmail ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setContactEmail('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('Contact.email');
              }}
              className={
                sortable.col == 'Contact.email' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'Contact.email' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row.Contact) {
          result.push(params.row.Contact.email);
          return result.join(', ');
        }
      }
    },
    {
      field: 'Company NTN',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            {relationType === 'VENDOR' ? 'Carrier' : 'Company'} Tax
            Identification Number (TIN)
          </p>
        );
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          result.push(params.row.ntn);
          return result.join(', ');
        }
      }
    },
    {
      field: 'billingAddress',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Billing Address</p>;
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          result.push(params.row.billingAddress);
          return result.join(', ');
        }
      }
    },
    {
      field: 'shippingAddress',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Shipping Address</p>;
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          result.push(params.row.shippingAddress);
          return result.join(', ');
        }
      }
    },
    {
      field: 'paymentTerms',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Payment Terms</p>;
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          result.push(params.row.paymentTerms);
          return result.join(', ');
        }
      }
    },
    {
      field: 'creditLimit',
      disableColumnMenu: true,
      sortable: false,
      flex: isPhone ? null : 1,
      width: isPhone ? 140 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Credit Limit</p>;
      },
      valueGetter: (params) => {
        let result = [];
        if (params.row) {
          result.push(
            `${
              params.row.creditLimit
                ? (params.row.currency || '') + ' ' + params.row.creditLimit
                : '-'
            } `
          );
          return result.join(', ');
        }
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      width: 100,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_COMPANY_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={params}
                onClick={() => _getCompany(params.row.id, 'edit')}
              />
            </Tooltip>
          ) : (
            ''
          )}
          {checkPermission(currentUser, 'OPS_COMPANY_READ') ? (
            <Tooltip title="View">
              <VisibilityIcon
                key="permissions"
                onClick={() =>
                  relationType == 'CUSTOMER'
                    ? navigate(
                        `/administration/company-details/${params.row.id}`
                      )
                    : _getCompany(params.row.id, 'detail')
                }
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ].filter(Boolean);

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formErrors, setFormErrors] = useState('');
  const [addCompanyViewOpen, setAddCompanyViewOpen] = useState(false);
  const [deleteCompanyViewOpen, setDeleteCompanyViewOpen] = useState(false);
  const [showMessage] = useState(null);
  const [companyDetailsView, setCompanyDetailsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [sortable, setSortable] = useState('');
  const [fetchState, setFetchState] = useState('');
  const [company, setCompany] = useState('');
  const [type, setType] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [filterView, setFilterView] = useState(false);
  const [dateFilterView, setDateFilterView] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { currentUser, subdomain, settings } = useContext(SharedContext);
  const [attemptedAlready, setAttemptedAlready] = useState(false);

  const [setSelectedDay] = useState(null);
  const [setOpenDialog] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(false); // bool

  const removeCurrentLogoId = () => {
    const _selectedCompany = { ...selectedCompany };
    _selectedCompany.logoId = null;
    setSelectedCompany(_selectedCompany);
  };

  const addCompany = async (data) => {
    setFormErrors('');
    setAttemptedAlready(true);
    setAPILoader(true);
    try {
      if (!selectedCompany) await API.post(`companies`, data);
      else await API.put(`companies/${selectedCompany.id}`, data);

      toaster(
        'success',
        !selectedCompany
          ? 'New Company has been created.'
          : 'Company updated successfully.'
      );
      closeAddCompanyView();
      getCompanies(page);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAttemptedAlready(false);
      setAPILoader(false);
    }
  };

  const addVendor = async (data) => {
    setFormErrors('');
    try {
      setAttemptedAlready(true);
      setAPILoader(true);
      if (!selectedCompany) await API.post(`vendors`, data);
      else await API.put(`vendors/${selectedCompany.id}`, data);
      toaster(
        'success',
        !selectedCompany
          ? 'New' + relationType == 'CUSTOMER'
            ? 'Company'
            : 'Carrier ' + ' has been created.'
          : relationType == 'CUSTOMER'
          ? 'Company '
          : 'Carrier ' + ' ' + 'updated successfully.'
      );
      closeAddCompanyView();
      getCompanies(page);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAttemptedAlready(false);
      setAPILoader(false);
    }
  };

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer
        {...props}
        className={`toolbarContainer userToolbarContainer ${classes.toolBar}`}
      >
        {(relationType == 'CUSTOMER' &&
          checkPermission(currentUser, 'OPS_COMPANY_EXPORT')) ||
        (relationType == 'VENDOR' &&
          checkPermission(currentUser, 'OPS_CARRIER_EXPORT')) ? (
          <Tooltip
            title={`Export ${
              relationType == 'CUSTOMER' ? 'Company' : 'Carrier'
            }`}
          >
            <Button
              className={classes.tooltipButton}
              key={2}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => exportCompanies()}
            >
              {' '}
              EXPORT TO EXCEL
            </Button>
          </Tooltip>
        ) : null}
        <Tooltip title="Filter">
          <Button
            className={classes.tooltipButton}
            id="search"
            label="Search"
            variant="contained"
            value={searchKeyword}
            key={5}
            onClick={() => openFilterView()}
          >
            FILTER
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  const deleteCompany = () => {
    axios
      .delete(getURL(`company/${relationType}/${selectedCompany.id}`))
      .then((res) => {
        if (!res.data.success) {
          setFormErrors(
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={() => setFormErrors('')}
            >
              {res.data.message}
            </Alert>
          );
          return;
        }
        closeDeleteCompanyView();
        getCompanies();
      });
  };

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };
  const openViewDetails = (company) => {
    setSelectedCompany(company);
    setCompanyDetailsView(true);
  };

  const closeCompanyDetailsView = () => {
    setCompanyDetailsView(false);
    setSelectedCompany(null);
  };

  const openEditView = (customer) => {
    setSelectedCompany(customer);
    setAddCompanyViewOpen(true);
  };

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const checkDate = () => {
    if (!endDate) {
      var today = new Date();
      let end =
        today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();
      setEndDate(end);
    }
    if (startDate) {
      fetchRes();
    }
  };
  const closeAddCompanyView = () => {
    setSelectedCompany(null);
    setAddCompanyViewOpen(false);
  };

  const closeDeleteCompanyView = () => {
    setSelectedCompany(null);
    setDeleteCompanyViewOpen(false);
  };

  const _getCompanies = useCallback(
    debounce(async (page, searchKeyword, filters, columns) => {
      try {
        setMuiLoader(true);
        const companies = await API.get(`companies`, {
          params: {
            page: page,
            search: searchKeyword,
            filters,
            columns,
            type: relationType
          }
        });
        setPageCount(companies.pages);
        setCompanies(companies.data.rows);
        setCustomerTypes(companies.data.types);
      } catch (err) {
        let errors = err.response.data.error.errors;
        errors.map((data) => {
          onError(data);
        });
      } finally {
        setMuiLoader(false);
      }
    }, DEBOUNCE_CONST),
    []
  );

  const _getUsers = async (page, searchKeyword, filters, columns) => {
    try {
      const users = await API.get(`organizations/${subdomain}/users`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setUsers(users?.data?.filter((user) => user.isActive) || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const exportCompanies = async () => {
    let startingDate = new Date(startDate);
    let endingDate = new Date(endDate);
    let colVal = {};
    if (company) {
      colVal = { name: company };
    }
    if (type) {
      colVal = { type: type };
    }
    if (contactName) {
      colVal = { '$Contact.firstName$': contactName };
    }
    if (contactEmail) {
      colVal = { '$Contact.email$': contactEmail };
    }
    let filters = {
      colVal,
      sortable
    };
    let columns = ['name', 'type', '$Contact.firstName$', '$Contact.email$'];
    try {
      const companies = await API.get('companies/export', {
        responseType: 'blob',
        params: {
          page,
          search: searchKeyword,
          filters,
          columns,
          type: relationType,
          startDate: selectedDateRange ? startingDate : null,
          endDate: selectedDateRange ? endingDate : null,
          client_Tz: moment.tz.guess()
        }
      });
      FileDownload(
        companies,
        relationType == 'CUSTOMER'
          ? `Company ${moment().format(
              settings?.dateFormat || 'DD-MM-yyyy'
            )}.xlsx`
          : `Carrier ${moment().format(
              settings?.dateFormat || 'DD-MM-yyyy'
            )}.xlsx`
      );
    } catch (err) {
      onError(err);
    }
  };

  const _getCompany = async (id, type) => {
    try {
      const company = await API.get(`companies/${id}`);
      type == 'edit'
        ? openEditView(company.company)
        : openViewDetails(company.company);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getCompanies = useCallback(
    debounce((page, searchKeyword) => {
      let colVal = {};
      if (company) {
        colVal = { name: company };
      }
      if (type) {
        colVal = { type: type };
      }
      if (contactName) {
        colVal = { '$Contact.firstName$': contactName };
      }
      if (contactEmail) {
        colVal = { '$Contact.email$': contactEmail };
      }
      let filters = {
        colVal,
        sortable
      };
      let columns = ['name', 'type', '$Contact.firstName$', '$Contact.email$'];
      _getCompanies(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    fetchRes();
  }, [company, type, contactName, sortable, contactEmail, page, searchKeyword]);

  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  useEffect(() => {
    let title = relationType === 'CUSTOMER' ? 'Company' : 'Carrier(s)';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    let colVal = {
      '$UserRoles.Role.allowedApps$': 'OPERATIONS'
    };
    let filters = {
      colVal
    };
    let columns = ['name', 'type', '$Contact.firstName$', '$Contact.email$'];
    _getUsers(page, searchKeyword, filters, columns);
  }, []);

  const addCompanyButton = (
    <Tooltip title={relationType == 'CUSTOMER' ? 'Add Company' : 'Add Carrier'}>
      <Button
        className={clsx({
          [classes.addCompanyButton]: true,
          [classes.responsiveVendor]: true
        })}
        key={2}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setAddCompanyViewOpen(true);
          setIsEdit(false);
        }}
      >
        {relationType == 'CUSTOMER' ? 'ADD COMPANY' : 'ADD CARRIER'}
      </Button>
    </Tooltip>
  );
  const addCompanyModal = (
    <AddCompanyView
      key={3}
      formErrors={formErrors}
      users={users}
      relationType={relationType}
      customerTypes={customerTypes}
      selectedCompany={selectedCompany}
      open={addCompanyViewOpen}
      attemptedAlready={attemptedAlready}
      setAttemptedAlready={setAttemptedAlready}
      addCompany={(data) =>
        relationType == 'CUSTOMER' ? addCompany(data) : addVendor(data)
      }
      handleClose={() => closeAddCompanyView()}
      removeLogoId={() => removeCurrentLogoId()}
      isEdit={isEdit}
    />
  );
  const deleteCompanyModal = (
    <ConfirmDelete
      key={4}
      confirmDelete={deleteCompany}
      open={deleteCompanyViewOpen}
      handleClose={closeDeleteCompanyView}
      selectedEntity={selectedCompany && selectedCompany.name}
      title={'Company'}
    />
  );
  const companyDetailsViewModal = (
    <CompanyDetailsView
      relationType={relationType}
      selectedCompany={selectedCompany}
      open={companyDetailsView}
      handleClose={closeCompanyDetailsView}
    />
  );
  //Input Objects for filters

  const searchInput = (
    <div className="search-box">
      {searchKeyword ? (
        <span className="search-cancel" onClick={() => setSearchKeyword('')}>
          <MdCancel className="searchIcons" />
        </span>
      ) : null}
      <InputBase
        className={mainClass}
        id="search"
        label="Search"
        type="text"
        variant="outlined"
        value={searchKeyword}
        key={3}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          setPage(1);
        }}
      />
      <span className="search-btn">
        <MdSearch className="searchIcons" />
      </span>
    </div>
  );

  const hideCompanyCols = {
    billingAddress: relationType === 'CUSTOMER',
    shippingAddress: relationType === 'CUSTOMER',
    paymentTerms: relationType === 'CUSTOMER',
    creditLimit: relationType === 'CUSTOMER',
    currency: relationType === 'CUSTOMER'
  };

  let compNameObj = {
    type: 'text',
    className: 'textField',
    label: relationType == 'CUSTOMER' ? 'Company' : 'Carrier',
    field: 'name',
    value: company ? company : '',
    setNameVal: (e) => setNameVal(setCompany, e)
  };

  let compTypeObj = {
    type: 'text',
    className: relationType == 'CUSTOMER' ? 'textField' : 'display-none',
    label: 'Company Type',
    field: 'type',
    value: type ? type : '',
    setNameVal: (e) => setNameVal(setType, e)
  };

  let contactNameObj = {
    type: 'text',
    className: 'textField',
    label: 'Contact Name',
    field: 'Contact.firstName',
    value: contactName ? contactName : '',
    setNameVal: (e) => setNameVal(setContactName, e)
  };

  let contactEmailObj = {
    type: 'text',
    className: 'textField',
    label: 'Contact Email',
    field: 'Contact.email',
    value: contactEmail ? contactEmail : '',
    setNameVal: (e) => setNameVal(setContactEmail, e)
  };
  //Custom modal for filters...
  const filterCols = [compNameObj, contactNameObj, contactEmailObj];
  if (relationType == 'CUSTOMER') {
    filterCols.push(compTypeObj);
  }
  const filterModal = (
    <div
      className={
        filterView ? `filterViewSect ${classes.filterView}` : 'display-none'
      }
    >
      <Filter
        title={'Driver'}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const dateFilterModal = (
    <div className={dateFilterView ? 'dateFilterViewSec' : 'display-none'}>
      <TextField
        id="startdate"
        label="Start Date"
        className={classes.textFieldRange}
        type="date"
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={(e) => setStartDate(e.target.value)}
        value={startDate}
      />

      <TextField
        id="date"
        label="End Date"
        className={classes.textFieldRange}
        type="date"
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={(e) => setEndDate(e.target.value)}
        value={endDate}
      />

      <button onClick={checkDate()} className="dateBtn">
        <b>Apply</b>
      </button>
    </div>
  );
  const headerButtons = [
    (relationType == 'CUSTOMER' &&
      checkPermission(currentUser, 'OPS_COMPANY_CREATE')) ||
    (relationType == 'VENDOR' &&
      checkPermission(currentUser, 'OPS_CARRIER_CREATE'))
      ? addCompanyButton
      : null,

    (relationType == 'CUSTOMER' &&
      checkPermission(currentUser, 'OPS_COMPANY_READ')) ||
    (relationType == 'VENDOR' &&
      checkPermission(currentUser, 'OPS_CARRIER_READ'))
      ? searchInput
      : null,

    (relationType == 'CUSTOMER' &&
      checkPermission(currentUser, 'OPS_COMPANY_CREATE')) ||
    checkPermission(currentUser, 'OPS_COMPANY_UPDATE') ||
    (relationType == 'VENDOR' &&
      checkPermission(currentUser, 'OPS_CARRIER_CREATE')) ||
    checkPermission(currentUser, 'OPS_CARRIER_UPDATE')
      ? addCompanyModal
      : null,

    (relationType == 'CUSTOMER' &&
      checkPermission(currentUser, 'OPS_COMPANY_DELETE')) ||
    (relationType == 'VENDOR' &&
      checkPermission(currentUser, 'OPS_CARRIER_DELETE'))
      ? deleteCompanyModal
      : null,

    (relationType == 'CUSTOMER' &&
      checkPermission(currentUser, 'OPS_COMPANY_READ')) ||
    (relationType == 'VENDOR' &&
      checkPermission(currentUser, 'OPS_CARRIER_READ'))
      ? companyDetailsViewModal
      : null,

    (relationType == 'CUSTOMER' &&
      checkPermission(currentUser, 'OPS_COMPANY_READ')) ||
    (relationType == 'VENDOR' &&
      checkPermission(currentUser, 'OPS_CARRIER_READ'))
      ? filterModal
      : null,

    (relationType == 'CUSTOMER' &&
      checkPermission(currentUser, 'OPS_COMPANY_READ')) ||
    (relationType == 'VENDOR' &&
      checkPermission(currentUser, 'OPS_CARRIER_READ'))
      ? dateFilterModal
      : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <div className={classes.tableHeader}>
          <TableHeader buttons={headerButtons} />
        </div>
        <Table aria-label="sticky table">
          <TableBody>
            <div style={{ height: 1100, width: '100%' }}>
              <DataGrid
                rows={companies}
                hiddenColumnModel={['id']}
                columnVisibilityModel={hideCompanyCols}
                columns={columnsNew}
                components={{ Toolbar: CustomToolbar }}
                pageSize={20}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
                loading={muiLoader}
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="space-between">
        <Grid item></Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            className={classes.pagination}
            onChange={(e, newPage) => {
              setPage(newPage);
            }}
          />
        </Grid>
      </Grid>
      <MessageSnackbar showMessage={showMessage} />
    </Paper>
  );
}

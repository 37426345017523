import clsx from 'clsx';

/**
 * Heading Component
 *
 * A component to display a heading with optional styling variants.
 *
 * @component
 * @example
 * // Example usage of Heading component
 * <Heading variant="main">Main Heading</Heading>
 *
 * @param {Object} props - The properties of the component.
 * @param {ReactNode} props.children - The content to be displayed inside the heading.
 * @param {string} props.variant - The variant of the heading. Options: 'main', 'page', 'section', 'card'.
 * @param {boolean} props.capitalize - Whether to capitalize the text.
 * @param {boolean} props.uppercase - Whether to make the text uppercase.
 * @param {string} props.className - Additional CSS class names to apply to the heading.
 * @returns {JSX.Element} The rendered Heading component.
 */
export const Heading = ({
  children,
  variant,
  capitalize,
  uppercase,
  className
}) => {
  return (
    <div
      className={clsx(
        {
          'font-bold text-hd-3xl': variant === 'main',
          'font-bold text-2xl': variant === 'page',
          'font-bold text-hd-xl': variant === 'section',
          'font-semibold text-hd-lg': variant === 'card',
          uppercase,
          capitalize
        },
        className
      )}
    >
      {children}
    </div>
  );
};

/**
 * Title Component
 *
 * A component to display a title with optional styling variants.
 *
 * @component
 * @example
 * // Example usage of Title component
 * <Title variant="lg">Large Title</Title>
 *
 * @param {Object} props - The properties of the component.
 * @param {ReactNode} props.children - The content to be displayed inside the title.
 * @param {string} props.variant - The variant of the title. Options: 'lg' (large), 'md' (medium), 'sm' (small), 'xs' (extra small).
 * @param {boolean} props.capitalize - Whether to capitalize the text.
 * @param {boolean} props.uppercase - Whether to make the text uppercase.
 * @param {string} props.className - Additional CSS class names to apply to the title.
 * @returns {JSX.Element} The rendered Title component.
 */
export const Title = ({
  children,
  variant,
  capitalize,
  uppercase,
  className
}) => {
  return (
    <div
      className={clsx(
        'font-medium',
        {
          'text-base': variant === 'lg',
          'text-sm': variant === 'md',
          'text-xs': variant === 'sm',
          'text-xxs': variant === 'xs',
          uppercase,
          capitalize
        },
        className
      )}
    >
      {children}
    </div>
  );
};

/**
 * Paragraph Component
 *
 * A component to display a paragraph of text with optional styling variants.
 *
 * @component
 * @example
 * // Example usage of Paragraph component
 * <Paragraph variant="xl">Extra Large Paragraph</Paragraph>
 *
 * @param {Object} props - The properties of the component.
 * @param {ReactNode} props.children - The content to be displayed inside the paragraph.
 * @param {string} props.variant - The variant of the paragraph. Options: 'xl' (extra large), 'lg' (large), 'md' (medium), 'sm' (small), 'xs' (extra small).
 * @param {boolean} props.capitalize - Whether to capitalize the text.
 * @param {boolean} props.uppercase - Whether to make the text uppercase.
 * @param {string} props.className - Additional CSS class names to apply to the paragraph.
 * @returns {JSX.Element} The rendered Paragraph component.
 */
export const Paragraph = ({
  children,
  variant,
  capitalize,
  uppercase,
  className
}) => {
  return (
    <div
      className={clsx(
        'font-normal',
        {
          'text-pg-lg': variant === 'xl',
          'text-pg-base': variant === 'lg',
          'text-pg-sm': variant === 'md',
          'text-xs': variant === 'sm',
          'text-xxs': variant === 'xs',
          uppercase,
          capitalize
        },
        className
      )}
    >
      {children}
    </div>
  );
};

/**
 * Label Component
 *
 * A component to display a label with optional styling variants.
 *
 * @component
 * @example
 * // Example usage of Label component
 * <Label variant="lg">Large Label</Label>
 *
 * @param {Object} props - The properties of the component.
 * @param {ReactNode} props.children - The content to be displayed inside the label.
 * @param {string} props.variant - The variant of the label. Options: 'lg' (large), 'sm' (small), 'xs' (extra small).
 * @param {string} props.className - Additional CSS class names to apply to the label.
 * @returns {JSX.Element} The rendered Label component.
 */
export const Label = ({ children, variant, className }) => {
  return (
    <div
      className={clsx(
        'uppercase font-normal',
        {
          'text-lb-sm': variant === 'lg',
          'text-xs': variant === 'sm',
          'text-xxs': variant === 'xs'
        },
        className
      )}
    >
      {children}
    </div>
  );
};

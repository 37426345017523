/*eslint-disable*/
import { useEffect, useMemo, useState, useContext } from 'react';
import moment from 'moment';
import BackIcon from '../../../assets/icons/unifiedDashboard/Back.svg';
import NextIcon from '../../../assets/icons/unifiedDashboard/Next.svg';
import clsx from 'clsx';
import ShipmentStatsOfDate from './shipmentStats';
import ShipmentSummaryBox from './shipmentSummaryBox';
import StatHeader from './statHeader';
import { useQuery } from 'react-query';
import {
  fetchCalendarShipments,
  fetchNodes,
  fetchShipmentStats,
  fetchTrackingDetails
} from '../../../apis/dashboard';
import WeekNavigationCTA from './weekNavigationCTA';
import FiltersCTA from './filtersCTA';
import NodeShipments from '../NodeShipments';
import { ShipmentFilterType, ShipmentsDirection } from '../constants';
import { isDayToday } from './utils';
import ShipmentDetail from '../ShipmentDetail';
import { SharedContext } from '../../../utils/common';
import { ORGANIZATION_TYPES } from '../../../constants';

const SHIPMENT_TYPES_MANUFACTURER = {
  ALL: 'All',
  PURCHASE: 'Purchase',
  SALES: 'Sales',
  TRANSFER: 'Transfer',
  SALES_RETURN: 'Sales Return',
  LOGISTIC: 'Logistic'
};

const SHIPMENT_TYPES_3PL = {
  ALL: 'All',
  INBOUND: 'Inbound',
  OUTBOUND: 'Outbound',
  TRANSFER: 'Transfer',
  LOGISTIC: 'Logistic'
};

const getWeek = (baseDate) => {
  const startOfWeek = baseDate.clone().isoWeekday(1);
  const datesInWeek = [0, 1, 2, 3, 4, 5, 6].map((index) => {
    if (index === 6) {
      return startOfWeek.clone().endOf('day').add(index, 'day');
    }
    return startOfWeek.clone().add(index, 'day').startOf('day');
  });
  return datesInWeek;
};

const getCurrentWeek = () => {
  const currentDate = moment();
  return getWeek(currentDate);
};

const getNextWeek = (baseDate) => {
  const startOfWeek = baseDate.clone().add(1, 'day');
  return getWeek(startOfWeek);
};

const getPreviousWeek = (baseDate) => {
  const startOfWeek = baseDate.clone().subtract(1, 'day');
  return getWeek(startOfWeek);
};

const allNodes = { value: '*', label: 'All Nodes' };

const CalendarView = () => {
  const [week, setWeek] = useState([]);
  const [selectedShipmentType, setSelectedShipmentType] = useState(
    SHIPMENT_TYPES_3PL.ALL
  );
  const [selectedNode, setSelectedNode] = useState(allNodes.value);
  const [weekStats, setWeekStats] = useState([]);
  const [showShipmentsList, setShowShipmentsList] = useState(null);
  const [showTodayShipments, setShowTodayShipments] = useState(false);
  const [showDayShipments, setShowDayShipments] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayShipments, setSelectedDayShipments] = useState([]);
  const [shipmentDetailPopup, setShipmentDetailPopup] = useState(false);
  const [shipmentData, setShipmentData] = useState(null);
  const [trackingData, setTrackingData] = useState([]);
  const { data: nodesData } = useQuery('nodes', fetchNodes);
  const [todayShipmentCount, setTodayShipmentCount] = useState(0);
  const [shipmentCount, setShipmentCount] = useState(0);
  const [trackingLoading, setTrackingLoading] = useState(true);
  const [todayStats, setTodayStatsData] = useState([]);
  const [currentDayPage, setCurrentDayPage] = useState(1);
  const [currentDayPageCount, setCurrentDayPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [currentTypes, setCurrentTypes] = useState({});
  const { organizationType } = useContext(SharedContext);

  const { isLoading } = useQuery(
    ['shipmentStats', week, selectedNode, selectedShipmentType],
    () => {
      const [fromObj, ...rest] = week;
      const toObj = rest[rest.length - 1];
      const from = fromObj.utc().format('YYYY-MM-DD HH:mm:ss');
      const to = toObj.utc().clone().format('YYYY-MM-DD HH:mm:ss');
      return fetchShipmentStats({
        from,
        to,
        nodeId: selectedNode === '*' ? null : selectedNode,
        moveType:
          selectedShipmentType === 'All'
            ? null
            : selectedShipmentType === 'Inbound'
            ? 'INWARD'
            : selectedShipmentType.toUpperCase()
      });
    },
    {
      enabled: week.length > 0,
      onSuccess: (data) => {
        loadShipmentStats(data);
      }
    }
  );

  const { isFetching: isTodayStatsLoading } = useQuery(
    [week, selectedShipmentType, selectedNode],
    () => {
      const date = moment().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      return fetchCalendarShipments({
        date,
        page: 1,
        nodeId: selectedNode === '*' ? null : selectedNode,
        moveType:
          selectedShipmentType === 'All'
            ? null
            : selectedShipmentType === 'Inbound'
            ? 'INWARD'
            : selectedShipmentType.toUpperCase()
      });
    },
    {
      enabled: week.length > 0,
      onSuccess: (data) => {
        setCurrentDayPage(data.count);
        setTodayStatsData(data.data);
        setCurrentDayPageCount(data.pages);
      }
    }
  );

  const { isFetching: isDayLoading } = useQuery(
    [selectedDay, selectedShipmentType, selectedNode],
    () => {
      if (!selectedDay) {
        return;
      }
      const date = selectedDay.clone().utc().format('YYYY-MM-DD HH:mm:ss');
      return fetchCalendarShipments({
        date,
        page: 1,
        nodeId: selectedNode === '*' ? null : selectedNode,
        moveType:
          selectedShipmentType === 'All'
            ? null
            : selectedShipmentType === 'Inbound'
            ? 'INWARD'
            : selectedShipmentType.toUpperCase()
      });
    },
    {
      enabled: Boolean(selectedDay),
      onSuccess: (data) => {
        setSelectedDayShipments(data?.data);
        setShipmentCount(data?.count);
        setPageCount(data?.pages);
      }
    }
  );
  useEffect(() => {
    setCurrentWeek();
  }, []);

  const loadShipmentStats = (stats) => {
    const weekStatsData = Object.keys(stats).map((statKey) => {
      const stat = stats[statKey];
      return {
        momentObj: moment(statKey),
        stat
      };
    });

    setWeekStats(weekStatsData);
  };

  const setCurrentWeek = () => {
    const currentWeek = getCurrentWeek();
    setWeek(currentWeek);
  };

  const updateWeek = (getWeek, initialDay) => {
    if (!week || week.length === 0) {
      return;
    }

    const updatedWeek = getWeek(initialDay);
    setWeek(updatedWeek);
  };

  const [rangeStart, rangeEnd] = useMemo(() => {
    if (week && week.length > 0) {
      const startMonth = week[0].month();
      const startYear = week[0].year();

      const endMonth = week[week.length - 1].month();
      const endYear = week[week.length - 1].year();

      if (startYear !== endYear) {
        const start = week[0].format('DD MMMM YYYY');
        const end = week[week.length - 1].format('DD MMMM YYYY');

        return [start, end];
      } else if (startMonth !== endMonth) {
        const start = week[0].format('DD MMMM');
        const end = week[week.length - 1].format('DD MMMM YYYY');

        return [start, end];
      } else {
        const start = week[0].format('DD');
        const end = week[week.length - 1].format('DD MMMM YYYY');

        return [start, end];
      }
    }

    return [];
  }, [week]);

  useEffect(() => {
    if (organizationType) {
      setCurrentTypes(
        organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? SHIPMENT_TYPES_MANUFACTURER
          : organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
          ? SHIPMENT_TYPES_3PL
          : {}
      );
    }
  }, [organizationType]);

  const shipmentTypes = useMemo(() => {
    if (currentTypes && Object.keys(currentTypes).length > 0) {
      const keys = Object.keys(currentTypes);
      console.log(currentTypes)
      return keys.map((typeKey) => ({
        label: currentTypes[typeKey],
        isActive:
          selectedShipmentType === currentTypes[typeKey].replace(' ', '_')
      }));
    } else {
      return [];
    }
  }, [currentTypes, selectedShipmentType]);

  const nodes = useMemo(() => {
    if (!nodesData) {
      return [{ ...allNodes }];
    }
    return [
      { ...allNodes },
      ...nodesData.map((node) => {
        return {
          label: node?.name,
          value: node?.id
        };
      })
    ];
  }, [nodesData]);

  const weekDates = useMemo(() => {
    return weekStats.map((stat) => stat.momentObj);
  }, [weekStats]);

  const setTodayStats = (newVal) => {
    const updatedShipmentsList = { ...showShipmentsList };
    updatedShipmentsList.todayStats = newVal;
    setShowShipmentsList(updatedShipmentsList);
  };

  const handleShipmentClick = async (record) => {
    setShipmentData(record);
    setTrackingLoading(true);
    try {
      const response = await fetchTrackingDetails(record?.customId);
      setTrackingData(response);
      setShipmentDetailPopup(true);
      setTrackingLoading(false);
    } catch (error) {
      console.error('Error fetching tracking details:', error);
    }
  };

  return (
    <div className="flex flex-col w-50">
      {showShipmentsList?.weekDay && (
        <NodeShipments
          open={showTodayShipments}
          setOpen={setShowTodayShipments}
          title={`${showShipmentsList?.weekDay?.format('DD MMMM')} Shipments`}
          dayShipments={true}
          shipments={showShipmentsList?.todayStats}
          startDate={showShipmentsList?.weekDay
            ?.clone()
            ?.startOf('day')
            ?.utc()
            ?.format('YYYY-MM-DD HH:mm:ss')}
          endDate={showShipmentsList?.weekDay
            ?.clone()
            ?.startOf('day')
            ?.utc()
            ?.add(1, 'day')
            ?.subtract(1, 'second')
            ?.format('YYYY-MM-DD HH:mm:ss')}
          setNodeShipmentData={setTodayStats}
          filterType={ShipmentFilterType.WAREHOUSE}
          nodes={nodes}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          moveType={selectedShipmentType}
          shipmentsCount={todayShipmentCount}
          setShipmentsCount={setTodayShipmentCount}
          isFetchingInitial={isTodayStatsLoading}
          shipmentCount={shipmentCount}
          page={currentDayPage}
          setPage={setCurrentDayPage}
          pageCount={currentDayPageCount}
          setPageCount={setCurrentDayPageCount}
        />
      )}
      {Boolean(selectedDay) && (
        <NodeShipments
          open={showDayShipments}
          setOpen={setShowDayShipments}
          title={`${selectedDay.format('DD MMMM')} Shipments`}
          dayShipments={true}
          shipments={selectedDayShipments}
          startDate={selectedDay
            .clone()
            .startOf('day')
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')}
          endDate={selectedDay
            .clone()
            .startOf('day')
            .utc()
            .add(1, 'day')
            .subtract(1, 'second')
            .format('YYYY-MM-DD HH:mm:ss')}
          setNodeShipmentData={setSelectedDayShipments}
          filterType={ShipmentFilterType.WAREHOUSE}
          nodes={nodes}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          moveType={selectedShipmentType}
          shipmentsCount={shipmentCount}
          setShipmentsCount={setShipmentCount}
          isFetchingInitial={isDayLoading}
          shipmentCount={shipmentCount}
          setShipmentCount={setShipmentCount}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
        />
      )}

      <ShipmentDetail
        shipmentDetailPopup={shipmentDetailPopup}
        setShipmentDetailPopup={setShipmentDetailPopup}
        data={shipmentData}
        setOpen={() => {}}
        trackingData={trackingData}
        loading={trackingLoading}
        setLoading={setTrackingLoading}
      />
      <div className="flex justify-between items-center pt-[25px] pl-[24px]">
        <h1 className="text-xl font-semibold text-woodsmoke">
          {rangeStart} - {rangeEnd}
        </h1>

        <WeekNavigationCTA
          BackIcon={BackIcon}
          NextIcon={NextIcon}
          week={weekDates}
          setCurrentWeek={setCurrentWeek}
          updateWeek={updateWeek}
          getPreviousWeek={getPreviousWeek}
          getNextWeek={getNextWeek}
        />
      </div>

      <FiltersCTA
        shipmentTypes={shipmentTypes}
        nodes={nodes}
        selectedNode={selectedNode}
        setSelectedShipmentType={setSelectedShipmentType}
        setSelectedNode={setSelectedNode}
      />

      <div className="flex flex-row h-full">
        {organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER &&
          weekStats.map(({ momentObj: weekDay, stat }, index) => {
            const {
              activeShipments,
              inboundShipments,
              outboundShipments,
              transferShipments
            } = stat || {};

            const dateString = weekDay.format('ddd DD');
            const [day, date] = dateString.split(' ');
            const today = moment();
            const isToday = today.isSame(weekDay, 'day');

            return (
              <div
                key={dateString}
                className={clsx('flex flex-col', {
                  'w-2/12': isToday,
                  'flex-1': !isToday
                })}
              >
                <StatHeader
                  date={date}
                  index={index}
                  day={day}
                  isToday={isToday}
                />

                <div
                  key={index}
                  className={clsx('flex flex-1 flex-col items-center px-2', {
                    'border-l': index > 0,
                    'pt-2': isToday,
                    'pt-6': !isToday
                  })}
                >
                  {Boolean(!isLoading && !isToday && stat) && (
                    <ShipmentStatsOfDate
                      stats={{
                        total: activeShipments,
                        outbounds: outboundShipments,
                        inbounds: inboundShipments,
                        transfers: transferShipments
                      }}
                      onClick={() => {
                        if (weekDay) {
                          setSelectedDay(weekDay);
                          setShowDayShipments(true);
                        }
                      }}
                    />
                  )}

                  {Boolean(
                    !isLoading && !isTodayStatsLoading && isToday && todayStats
                  ) && (
                    <div className="w-100">
                      {todayStats &&
                        todayStats.slice(0, 5).map((shipment) => {
                          const { Order: order } = shipment || {};
                          if (order?.moveType !== 'TRANSFER') {
                            return (
                              <ShipmentSummaryBox
                                onClick={() => {
                                  handleShipmentClick(shipment);
                                }}
                                key={order?.id}
                                nodeName={
                                  order?.moveType === 'INWARD'
                                    ? order?.warehouseDropOff?.name
                                    : order?.moveType === 'OUTBOUND'
                                    ? order?.warehousePickUp?.name
                                    : null
                                }
                                status={shipment?.status}
                                moveType={order?.moveType}
                              />
                            );
                          } else {
                            let direction;
                            let name;
                            if (
                              isDayToday(shipment?.pickupDate) &&
                              isDayToday(shipment?.dropoffDate)
                            ) {
                              direction = ShipmentsDirection.BOTH;
                              name = `${order?.warehousePickUp?.name} / ${order?.warehouseDropOff?.name}`;
                            } else if (isDayToday(shipment?.dropoffDate)) {
                              direction = ShipmentsDirection.INWARD;
                              name = order?.warehouseDropOff?.name;
                            } else if (isDayToday(shipment?.pickupDate)) {
                              direction = ShipmentsDirection.OUTBOUND;
                              name = order?.warehousePickUp?.name;
                            }
                            return (
                              <ShipmentSummaryBox
                                key={order?.id}
                                nodeName={name}
                                status={shipment?.status}
                                moveType={direction}
                                onClick={() => {
                                  handleShipmentClick(shipment);
                                }}
                              />
                            );
                          }
                        })}

                      {todayStats.length > 5 && (
                        <div
                          className="text-base font-medium underline text-navyBlue text-center mb-3 cursor-pointer"
                          onClick={() => {
                            setShowShipmentsList({ weekDay, todayStats });
                            setShowTodayShipments(true);
                          }}
                        >
                          +{todayStats.length - 5} More
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
          weekStats.map(({ momentObj: weekDay, stat }, index) => {
            const {
              activeShipments,
              purchaseShipments,
              salesShipments,
              transferShipments,
              returnShipments
            } = stat || {};

            const dateString = weekDay.format('ddd DD');
            const [day, date] = dateString.split(' ');
            const today = moment();
            const isToday = today.isSame(weekDay, 'day');

            return (
              <div
                key={dateString}
                className={clsx('flex flex-col', {
                  'w-2/12': isToday,
                  'flex-1': !isToday
                })}
              >
                <StatHeader
                  date={date}
                  index={index}
                  day={day}
                  isToday={isToday}
                />

                <div
                  key={index}
                  className={clsx('flex flex-1 flex-col items-center px-2', {
                    'border-l': index > 0,
                    'pt-2': isToday,
                    'pt-6': !isToday
                  })}
                >
                  {Boolean(!isLoading && !isToday && stat) && (
                    <ShipmentStatsOfDate
                      stats={{
                        total: activeShipments,
                        purchase: purchaseShipments,
                        sales: salesShipments,
                        salesReturns: returnShipments,
                        transfers: transferShipments
                      }}
                      onClick={() => {
                        if (weekDay) {
                          setSelectedDay(weekDay);
                          setShowDayShipments(true);
                        }
                      }}
                    />
                  )}

                  {Boolean(
                    !isLoading && !isTodayStatsLoading && isToday && todayStats
                  ) && (
                    <div className="w-100">
                      {todayStats &&
                        todayStats.slice(0, 5).map((shipment) => {
                          const { Order: order } = shipment || {};
                          if (order?.moveType !== 'TRANSFER') {
                            return (
                              <ShipmentSummaryBox
                                onClick={() => {
                                  handleShipmentClick(shipment);
                                }}
                                key={order?.id}
                                nodeName={
                                  order?.moveType === 'INWARD'
                                    ? order?.warehouseDropOff?.name
                                    : order?.moveType === 'OUTBOUND'
                                    ? order?.warehousePickUp?.name
                                    : null
                                }
                                status={shipment?.status}
                                moveType={order?.moveType}
                              />
                            );
                          } else {
                            let direction;
                            let name;
                            if (
                              isDayToday(shipment?.pickupDate) &&
                              isDayToday(shipment?.dropoffDate)
                            ) {
                              direction = ShipmentsDirection.BOTH;
                              name = `${order?.warehousePickUp?.name} / ${order?.warehouseDropOff?.name}`;
                            } else if (isDayToday(shipment?.dropoffDate)) {
                              direction = ShipmentsDirection.INWARD;
                              name = order?.warehouseDropOff?.name;
                            } else if (isDayToday(shipment?.pickupDate)) {
                              direction = ShipmentsDirection.OUTBOUND;
                              name = order?.warehousePickUp?.name;
                            }
                            return (
                              <ShipmentSummaryBox
                                key={order?.id}
                                nodeName={name}
                                status={shipment?.status}
                                moveType={direction}
                                onClick={() => {
                                  handleShipmentClick(shipment);
                                }}
                              />
                            );
                          }
                        })}

                      {todayStats.length > 5 && (
                        <div
                          className="text-base font-medium underline text-navyBlue text-center mb-3 cursor-pointer"
                          onClick={() => {
                            setShowShipmentsList({ weekDay, todayStats });
                            setShowTodayShipments(true);
                          }}
                        >
                          +{todayStats.length - 5} More
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CalendarView;

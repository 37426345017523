/*eslint-disable*/

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Grid
} from '@mui/material';
import Button from '../../../core-components/atoms/Button';
import { decimalDisplay } from '../../../utils/common';

const Summary = ({
  formik,
  occupied,
  extraQuantity = 0,
  total,
  type,
  setOpen,
  submitHandler,
  draft,
  saving
}) => {
  return (
    <div>
      <div className="flex gap-14">
        <div>
          <p className="text-xs font-medium">TOTAL SKUS:</p>
          <p className="font-semibold text-sm">
            {formik.values.Order?.products.length}
          </p>
        </div>
        <div>
          <p className="text-xs color-headerCell font-medium">
            REQUIRED QUANTITY:
          </p>
          <p className="text-sm font-semibold">{decimalDisplay(total)}</p>
        </div>
        <div>
          <p className="text-xs color-headerCell font-medium">
            {type === 'GRN' ? 'RECEIVED' : 'DISPATCHED'} QUANTITY:
          </p>
          <p className="text-sm font-semibold">{decimalDisplay(occupied)}</p>
        </div>
        <div>
          <p className="text-xs color-headerCell font-medium">
            REMAINING QUANTITY:
          </p>
          <p className="text-sm font-semibold">
            {total - occupied + extraQuantity < 0
              ? 0
              : decimalDisplay(total - occupied + extraQuantity)}
          </p>
        </div>
      </div>
      <div className="mt-10">
        <TableContainer
          component={Paper}
          className="mt-10"
          sx={{
            borderRadius: '16px',
            overflow: 'hidden',
            border: '1px solid rgba(75, 137, 200, 0.2) !important',
            boxShadow: '4px 4px 16px rgb(75 137 200 / 12%) !important',
            '& .MuiTable-root': {
              borderCollapse: 'unset'
            },
            width: '700px'
          }}
        >
          <Grid className="scroll-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className="w-2/4 text-xs color-headerCell">
                    SKU CODE & NAME
                  </TableCell>
                  <TableCell className="text-xs color-headerCell">
                    REQUIRED QUANTITY
                  </TableCell>
                  <TableCell className="text-xs color-headerCell">
                    {type === 'GRN' ? 'RECEIVED' : 'DISPATCHED'} QUANTITY
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formik.values.Order?.products?.map((row) => {
                  const occQty = row[`${type.toUpperCase()}GroupBatches`]
                    ?.reduce(
                      (acc, obj) =>
                        acc +
                        (+obj[
                          type === 'GRN'
                            ? 'receivedQuantity'
                            : 'actualDispatchedQuantity'
                        ] || 0),
                      0
                    )
                    .toFixed(3);

                  return (
                    <TableRow>
                      <TableCell className="text-base truncate w-[180px]">
                        {row?.Product.name}-{row?.Product.description}
                      </TableCell>
                      <TableCell className="text-base">
                        {decimalDisplay(row.orderedQuantity)}
                      </TableCell>
                      <TableCell
                        className={`text-base ${occQty == 0 && 'text-error'}`}
                      >
                        {decimalDisplay(occQty) || 0}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </TableContainer>
        <Grid item xs={12} className="flex justify-end mt-5">
          <Button
            size="large"
            label="Cancel"
            variant="tertiary"
            onClick={() => setOpen(false)}
            className="mr-2"
          />
          <Button
            size="large"
            label={`Create ${draft ? 'Draft ' : ''}${type.toUpperCase()}`}
            variant="primary"
            disabled={saving}
            onClick={() => submitHandler(formik.values, !!draft)}
          />
        </Grid>
      </div>
    </div>
  );
};

export default Summary;

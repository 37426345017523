export default Object.freeze({
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  COMPLETED: 'COMPLETED',
  DOCK_ASSIGNED: 'DOCK_ASSIGNED',
  FULLY_COMPLETE: 'FULLY_COMPLETE',
  LOAD_DISPATCHED: 'LOAD_DISPATCHED',
  PARTIALLY_COMPLETED_CLOSED: 'PARTIALLY_COMPLETED_CLOSED',
  PARTIALLY_COMPLETE: 'PARTIALLY_COMPLETE'
});

/*eslint-disable*/
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendarIconSmall.svg';
import { FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '../../core-components/atoms/TextField';
import Autocomplete from '../../core-components/atoms/Autocomplete';

import API from '../../libs/axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { onError } from '../../libs/errorLib';
import { DEBOUNCE_CONST } from '../../Config';
import { debounce } from 'lodash';
import { checkPermission } from '../../utils/auth';
import {
  SharedContext,
  getCurrencySymbol,
  toSuperscript
} from '../../utils/common';
import { ORGANIZATION_TYPES } from '../../constants';
import clsx from 'clsx';
import { PRIMARY } from '../../constants/colors';
import AddcarrierPopup from '../logisticsRevamped/carrier/components/carrierPopup';
import { NumericFormat } from 'react-number-format';

const AirShipmentForm = ({ formik, edit, editable }) => {
  const [carriers, setCarriers] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [carrierSearchKeyword, setCarrierSearchKeyword] = useState('');
  const [vehicleTypeSearchKeyword, setVehicleTypeSearchKeyword] = useState('');
  const [vehicleSearchKeyword, setVehicleSearchKeyword] = useState('');
  const [driverSearchKeyword, setDriverSearchKeyword] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [selectedVehicleType, setVehicleType] = useState();
  const [addNewCarrier, setAddNewCarrier] = useState(false);
  const { currentUser, organizationType, organization, settings } =
    useContext(SharedContext);

  const _getVehicles = async (
    vehicleSearchKeyword,
    selectedVehicleType,
    selectedCarrier
  ) => {
    let columns = [
      'registrationNumber',
      '$Vendor.name$',
      '$`Car->CarMake`.name$'
    ];
    try {
      const vehicles = await API.get(`vehicles`, {
        params: {
          search: vehicleSearchKeyword,
          columns,
          carId: selectedVehicleType,
          companyId: selectedCarrier
        }
      });
      setVehicles(vehicles?.data);
    } catch (err) {
      if (err?.response?.data?.message) {
        onError(err);
        return;
      }

      let errors = err?.response?.data?.error?.errors || [];

      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getVehicleTypes = async (vehicleTypeSearchKeyword) => {
    const response = await API.get(`vehicles/types`, {
      params: { search: vehicleTypeSearchKeyword }
    });
    setVehicleTypes(response?.data);
  };

  const _getCarriers = async (carrierSearchKeyword) => {
    let colVal = {};
    colVal = {
      isActive: 1
    };
    let filters = {
      colVal
    };
    let columns = ['id', 'name'];
    try {
      const vendors = await API.get(`companies`, {
        params: {
          filters,
          columns,
          search: carrierSearchKeyword,
          type: 'VENDOR',
          logisticOnly: true,
          mode: 'AIR'
        }
      });
      setCarriers(vendors?.data?.rows || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getDrivers = async (driverSearchKeyword, selectedCarrier) => {
    try {
      const drivers = await API.get(`drivers`, {
        params: {
          search: driverSearchKeyword,
          companyId: selectedCarrier
        }
      });
      setDrivers(drivers?.data);
    } catch (err) {
      if (err?.response?.data?.message) {
        onError(err);
        return;
      }
      let errors = err?.response?.data?.error?.errors || [];
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getCarriers = useCallback(
    debounce((carrierSearchKeyword) => {
      _getCarriers(carrierSearchKeyword);
    }, DEBOUNCE_CONST),
    []
  );

  const getVehicleTypes = useCallback(
    debounce((vehicleTypeSearchKeyword) => {
      _getVehicleTypes(vehicleTypeSearchKeyword);
    }, DEBOUNCE_CONST),
    []
  );

  const getVehicles = useCallback(
    debounce((vehicleSearchKeyword, selectedVehicleType, selectedCarrier) => {
      _getVehicles(vehicleSearchKeyword, selectedVehicleType, selectedCarrier);
    }, DEBOUNCE_CONST),
    []
  );

  const getDrivers = useCallback(
    debounce((driverSearchKeyword, selectedCarrier) => {
      _getDrivers(driverSearchKeyword, selectedCarrier);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    if (carrierSearchKeyword) {
      getCarriers(carrierSearchKeyword);
    }
  }, [carrierSearchKeyword]);

  useEffect(() => {
    if (vehicleTypeSearchKeyword) {
      getVehicleTypes(vehicleTypeSearchKeyword);
    }
  }, [vehicleTypeSearchKeyword]);

  useEffect(() => {
    if (vehicleSearchKeyword || selectedVehicleType) {
      getVehicles(vehicleSearchKeyword, selectedVehicleType, selectedCarrier);
    }
  }, [vehicleSearchKeyword, selectedVehicleType, selectedCarrier]);

  useEffect(() => {
    if (driverSearchKeyword || selectedCarrier) {
      getDrivers(driverSearchKeyword, selectedCarrier);
    }
  }, [driverSearchKeyword, selectedCarrier]);

  useEffect(() => {
    _getVehicleTypes();
    _getCarriers();
  }, []);

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '10px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& label': {
      fontSize: '14px'
    }
  };

  const listBoxProps = {
    fontSize: '14px !important'
  };

  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  return (
    <div className="w-[590px] h-[300px] mt-10 number-input-container">
      <div className="w-full flex items-center gap-2 mb-6">
        <FormControl fullWidth variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              components={{
                OpenPickerIcon: CalendarIcon
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={formik.handleBlur}
                  size="small"
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px !important'
                    },
                    '& input': {
                      fontSize: '14px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '20px',
                      marginLeft: '8px'
                    },
                    '& label': {
                      fontSize: '14px'
                    },
                    '& .MuiIconButton-root': {
                      padding: 0
                    },
                    '& .MuiIconButton-edgeEnd': {
                      marginRight: '-6px'
                    },
                    ...(formik?.values?.pickupDate
                      ? {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #0B1940 !important'
                          }
                        }
                      : [])
                  }}
                  placeholder="Pick Up Date"
                />
              )}
              inputFormat="dd/MM/yyyy"
              value={formik?.values?.pickupDate || null}
              label="Pick Up Date"
              onChange={(newValue) => {
                formik.setFieldValue('pickupDate', newValue);
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              components={{
                OpenPickerIcon: CalendarIcon
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={formik.handleBlur}
                  size="small"
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px'
                    },
                    '& input': {
                      fontSize: '14px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '20px',
                      marginLeft: '8px'
                    },
                    '& label': {
                      fontSize: '14px'
                    },
                    '& .MuiIconButton-root': {
                      padding: 0
                    },
                    '& .MuiIconButton-edgeEnd': {
                      marginRight: '-6px'
                    },
                    ...(formik?.values?.dropoffDate
                      ? {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #0B1940 !important'
                          }
                        }
                      : [])
                  }}
                  placeholder="Drop Off Date"
                />
              )}
              inputFormat="dd/MM/yyyy"
              value={formik?.values?.dropoffDate || null}
              label="Drop Off Date"
              onChange={(newValue) => {
                formik.setFieldValue('dropoffDate', newValue);
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <NumericFormat
            customInput={TextField}
            value={formik.values.carrierCost}
            onValueChange={(e) =>
              formik.setFieldValue('carrierCost', parseFloat(e.value))
            }
            label="Carrier Cost"
            thousandSeparator=","
            decimalSeparator="."
            sxProps={sxProps}
            size="small"
            InputProps={{
              endAdornment: `${getCurrencySymbol(
                organization?.defaultCurrency
              )}`
            }}
          />
        </FormControl>
      </div>
      <div className="w-full flex items-center gap-2 mb-6">
        <FormControl fullWidth variant="outlined">
          <Autocomplete
            options={
              [...carriers, { type: 'add', label: 'Add new Carrier' }] || []
            }
            onChange={async (e, value) => {
              formik.setFieldValue('carrier', value);
              formik.setFieldValue('driver', {});
              formik.setFieldValue('vehicle', {});
              formik.setFieldValue('trackingLink', null);
              setSelectedCarrier(value?.id);
              setCarrierSearchKeyword('');
            }}
            resetValue={() => {
              if (formik.values.carrier) {
                formik.setFieldValue('carrier', {});
                setSelectedCarrier(null);
                setCarrierSearchKeyword('');
              }
            }}
            value={formik.values?.carrier || {}}
            inputValue={carrierSearchKeyword}
            onInputChange={(event, newValue) => {
              setCarrierSearchKeyword(newValue);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(props, option) => {
              const { className, ...rest } = props;
              return option?.type == 'add' ? (
                <li
                  className="auto-complete-option"
                  style={{
                    color: PRIMARY,
                    fontSize: '14px',
                    fontWeight: 500
                  }}
                  onClick={() => {
                    setAddNewCarrier(true);
                  }}
                >
                  + {option.label}
                </li>
              ) : (
                <li {...rest} className="auto-complete-option-small">
                  <span>
                    {option.code || option.name ? `${option.name}` : ''}
                  </span>
                  <span style={{ color: '#67718C' }}>
                    {option?.code ? ` • ${option?.code}` : ''}
                  </span>
                </li>
              );
            }}
            label="Carrier Name"
            sxProps={{
              '& input::placeholder': {
                fontSize: '14px'
              },
              '& textarea::placeholder': {
                fontSize: '14px'
              },
              '& label': {
                fontSize: '14px'
              }
            }}
            clearOnBlur
            customInputProps={inputProps}
            listBoxProps={listBoxProps}
            disabled={edit && !editable}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.mblNumber}
            onChange={(e) => formik.setFieldValue('mblNumber', e.target.value)}
            label="AWB Number"
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.hblNumber}
            onChange={(e) => formik.setFieldValue('hblNumber', e.target.value)}
            label="HAWB Number"
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
          />
        </FormControl>
      </div>
      <div className="w-full flex items-center gap-2 mb-6">
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.flightNumber}
            onChange={(e) =>
              formik.setFieldValue('flightNumber', e.target.value)
            }
            label="Airline Number"
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.containerNumber}
            onChange={(e) =>
              formik.setFieldValue('containerNumber', e.target.value)
            }
            label="Container Number"
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.trackingLink}
            onChange={(e) =>
              formik.setFieldValue('trackingLink', e.target.value)
            }
            label="Tracking Link"
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
          />
        </FormControl>
      </div>
      <div
        className={clsx('flex items-center gap-2 mb-6', {
          'w-full': organizationType !== ORGANIZATION_TYPES.MANUFACTURER,
          'w-2/3 pr-1': organizationType === ORGANIZATION_TYPES.MANUFACTURER
        })}
      >
        <FormControl fullWidth variant="outlined">
          <NumericFormat
            customInput={TextField}
            value={formik.values.weight || ''}
            disabled={edit && !editable}
            onValueChange={(e) => {
              formik.setFieldValue('weight', parseFloat(e.value));
            }}
            label="Weight"
            placeholder="Weight"
            sxProps={sxProps}
            size="small"
            InputProps={{
              endAdornment: (
                <span className="text-xs">{settings?.weight?.symbol}</span>
              )
            }}
            thousandSeparator=","
            decimalSeparator="."
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <NumericFormat
            customInput={TextField}
            value={formik.values.volume || ''}
            onChange={(e) =>
              formik.setFieldValue('volume', parseFloat(e.target.value))
            }
            label={`Total Volume (m${toSuperscript(3)})`}
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
            thousandSeparator=","
            decimalSeparator="."
          />
        </FormControl>
        {organizationType !== ORGANIZATION_TYPES.MANUFACTURER && (
          <FormControl fullWidth variant="outlined">
            <NumericFormat
              customInput={TextField}
              value={formik.values.sellingPrice || ''}
              onChange={(e) =>
                formik.setFieldValue('sellingPrice', parseFloat(e.target.value))
              }
              label="Selling Price"
              sxProps={sxProps}
              size="small"
              InputProps={{
                endAdornment: `${getCurrencySymbol(
                  organization?.defaultCurrency
                )}`
              }}
              thousandSeparator=","
              decimalSeparator="."
            />
          </FormControl>
        )}
      </div>
      {addNewCarrier && (
        <AddcarrierPopup
          open={addNewCarrier}
          setOpen={setAddNewCarrier}
          organizationType={organization.type}
          refetch={_getCarriers}
          shipmentOnlyScreen={true}
        />
      )}
    </div>
  );
};

export default AirShipmentForm;

import { useState, useEffect } from 'react';

const usePickingForm = (ordersData, setValid) => {
  const [collection, setCollection] = useState({});
  const [changeOccur, setChangeOccur] = useState('');
  const [totalOrderedCount, setTotalOrderedCount] = useState(0);
  const [totallyAutoPicked, setTotallyAutoPicked] = useState(false);

  useEffect(() => {
    const current = {};
    let sum = 0;

    ordersData.forEach((d) => {
      d.OrderProductGroups.forEach((g) => {
        if (!current[g.ProductId]) {
          current[g.ProductId] = {};
        }

        current[g.ProductId].orderedCount =
          (current[g.ProductId]?.orderedCount ?? 0) + g.quantity;

        sum += g.quantity;
      });
    });

    setTotalOrderedCount(sum);
    setCollection(current);
  }, []);

  const setChangeOccurRandomly = () => {
    setChangeOccur((Math.random() + 1).toString(36).substring(7));
  };

  const updateRecord = (id, batches, selectedIds, totalAutoPicked = false) => {
    setCollection((prev) => {
      const current = prev;
      const hash = {};

      current[id].selectedIds = [...new Set(selectedIds)];

      current[id].batches = [
        ...batches,
        ...(current[id]?.batches ?? [])
      ].reduce((acc, v) => {
        if (hash[v.id]) return acc;

        hash[v.id] = true;
        return [...acc, v];
      }, []);
      return current;
    });

    setTotallyAutoPicked(totalAutoPicked);

    setChangeOccurRandomly();
  };

  const updateSelectedIds = (id, selectedIds) => {
    setCollection((prev) => {
      const current = prev;

      if (!current[id]) {
        current[id] = {};
      }

      const newSelectedIds = [...new Set(selectedIds)];

      const requiredCount = current[id].orderedCount - current[id].pickedCount;

      current[id].batches = current[id].batches.map((b) => {
        if (newSelectedIds.includes(b.id) && !b.pickQuantity) {
          b.pickQuantity =
            b.availableQuantity > requiredCount
              ? requiredCount
              : b.availableQuantity;
        }

        return b;
      });

      current[id].selectedIds = newSelectedIds;
      return current;
    });

    setTotallyAutoPicked(false);
    setChangeOccurRandomly();
  };

  const updateBatches = (id, batches) => {
    setCollection((prev) => {
      const current = prev;
      const hash = {};
      if (!current[id]) {
        current[id] = {};
      }

      current[id].batches = [
        ...batches,
        ...(current[id]?.batches ?? [])
      ].reduce((acc, v) => {
        if (hash[v.id]) return acc;

        hash[v.id] = true;
        return [...acc, v];
      }, []);
      return current;
    });

    setChangeOccurRandomly();
  };

  const getSelectedIds = (id) => {
    return collection[id]?.selectedIds ?? [];
  };

  const getBatches = (id) => {
    return collection[id]?.batches ?? [];
  };

  const setPickQuantity = (id, batchId, value) => {
    setCollection((prev) => {
      const current = prev;

      current[id].batches = current[id].batches.map((v) => {
        if (v.id === batchId) v.pickQuantity = value;
        return v;
      });

      return current;
    });

    setTotallyAutoPicked(false);
    setChangeOccurRandomly();
  };

  const releaseHoldQuantity = (id, batchId) => {
    setCollection((prev) => {
      const current = prev;

      current[id].batches = current[id].batches.map((v) => {
        if (v.id === batchId) {
          v.availableQuantity += v.holdQuantity;
          v.holdQuantity = 0;
        }
        return v;
      });

      return current;
    });

    setTotallyAutoPicked(false);
    setChangeOccurRandomly();
  };

  const getSelectedProductCount = () => {
    return Object.keys(collection).reduce((acc, k) => {
      return collection[k].selectedIds?.length ? acc + 1 : acc;
    }, 0);
  };

  const refreshPickedCounts = () => {
    const result = {};
    let isValid = true;
    let allEmpty = true;

    setCollection((prev) => {
      const current = prev;
      Object.keys(current).forEach((key) => {
        const selectedBatches = current[key].batches.filter(
          (b) => current[key]?.selectedIds?.includes(b.id) && b.pickQuantity
        );

        if (allEmpty && !selectedBatches?.length) allEmpty = false;

        current[key].pickedCount = selectedBatches.reduce(
          (sum, b) => sum + parseFloat(b.pickQuantity),
          0
        );

        isValid &&= current[key].pickedCount <= current[key].orderedCount;

        result[key] = current[key].pickedCount;
      });

      return current;
    });

    setValid(Boolean(Object.keys(collection).length) && isValid && allEmpty);

    return result;
  };

  const resetBatches = () => {
    setCollection((prev) => {
      const current = prev;
      Object.keys(current).forEach((key) => {
        current[key].batches = [];
      });

      return current;
    });
  };

  const generatePayload = (orderData, orderProductData) => {
    const sortedOrders = orderData.sort((a, b) => a.id - b.id);
    const payload = orderProductData.reduce((acc, v) => {
      const selectedBatches = getBatches(v.product.id).filter(
        (b) => getSelectedIds(v.product.id).includes(b.id) && b.pickQuantity
      );

      const batches = selectedBatches.reduce((arr, b) => {
        let pickQuantity = parseFloat(b.pickQuantity);
        const curBatches = [];
        for (let i = 0; i < sortedOrders.length; i++) {
          if (pickQuantity <= 0) break;

          const productGroup = sortedOrders[i].OrderProductGroups.find((p) => {
            return p.ProductId === v.product.id;
          });

          if (!productGroup) continue;
          let quantity = productGroup.quantity;
          if (!quantity) continue;

          const newQuantity = quantity < pickQuantity ? quantity : pickQuantity;

          sortedOrders[i].OrderProductGroups = sortedOrders[
            i
          ].OrderProductGroups.reduce((a, p) => {
            if (p.ProductId === v.product.id) {
              p.quantity -= newQuantity;
            }

            return [...a, p];
          }, []);

          curBatches.push({
            ...b,
            pickQuantity: newQuantity,
            orderId: sortedOrders[i].id,
            customId: sortedOrders[i].customId
          });

          pickQuantity -= newQuantity;
        }

        return [...arr, ...curBatches];
      }, []);

      if (batches.length) {
        return [
          ...acc,
          {
            ...v,
            batches,
            orderedCount: collection[v.product.id]?.orderedCount ?? 0
          }
        ];
      }

      return acc;
    }, []);

    return payload;
  };

  return [
    collection,
    updateSelectedIds,
    updateBatches,
    getSelectedIds,
    getBatches,
    updateRecord,
    changeOccur,
    setPickQuantity,
    generatePayload,
    getSelectedProductCount,
    refreshPickedCounts,
    totalOrderedCount,
    resetBatches,
    totallyAutoPicked,
    releaseHoldQuantity
  ];
};

export default usePickingForm;

import React, { useContext, useRef } from 'react';
import CloudUploadIcon from '../../../../assets/icons/cloudUploadLight.svg';
import { upload } from '../../../../utils/upload';
import { toaster } from '../../../../utils/toaster';
import { onError } from '../../../../libs/errorLib';
import { SharedContext } from '../../../../utils/common';
import API from '../../../../libs/axios';

export const FileUploadMain = ({ data, getProducts }) => {
  const inputRef = useRef(null);
  const { setAPILoader } = useContext(SharedContext);

  const uploadFiles = async (event) => {
    setAPILoader(true);
    const files = event.target.files;
    const filesArr = [];
    for (let file of files) {
      filesArr.push(file);
    }
    try {
      let uploadedData = await upload(
        filesArr.filter((file) => !file?.id),
        'products'
      );
      if (uploadedData) {
        try {
          const newObject = {
            image: uploadedData[0],
            description: data ? data?.description : '',
            name: data ? data?.name : null,
            barcode: data ? data?.barcode : null,
            hsCode: data ? data?.hsCode : null,
            productType: data ? data?.productType : null,
            supplierId: data ? data?.Supplier?.id : null,
            selectedSupplier: data ? data?.Supplier : null,
            shelfLife: data ? data?.shelfLife : null,
            productLife: data ? data?.productLife : null,
            height: data ? data?.height : null,
            length: data ? data?.length : null,
            breadth: data ? data?.breadth : null,
            weight: data ? data?.weight : null,
            dimensionsCBM: data ? data?.dimensionsCBM : null,
            perPallet: data ? data?.perPallet : null,
            selectedCustomer: data?.Company,
            companyId: data?.Company?.id,
            selectedUom: data ? data?.UOM : '',
            uomId: data ? data?.UOM?.id : '',
            selectedCategory: data ? data?.Category : null,
            categoryId: data ? data?.Category?.id : null,
            brandId: data ? data?.Brand?.id : null,
            selectedBrand: data ? data?.Brand : null,
            isActive: data ? data?.isActive : false,
            MRPEnabled: data ? data?.MRPEnabled : false,
            batchEnabled: data ? data?.batchEnabled : false,
            leadTime: data ? data?.leadTime : null,
            stockHandlingType: data?.stockHandlingType,
            additionalInformation: data ? data?.additionalInformation : null,
            minOrderedQuantity: data ? data?.minOrderedQuantity : null,
            maxOrderedQuantity: data ? data?.maxOrderedQuantity : null,
            costPrice: data ? data?.costPrice : null,
            sellingPrice: data ? data?.sellingPrice : null,
            reOrderPoint: data ? data?.reOrderPoint : null,
            uoms: data ? data?.ProductUOMs : null
          };
          await API.put(`products/${data.id}`, newObject);
          toaster('success', 'Product Updated Successfully');
          getProducts();
        } catch (err) {
          onError(err);
        } finally {
          setAPILoader(false);
        }
      } else {
        toaster('error', 'File not uploaded');
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <>
      <div
        className={
          'flex h-[28px] w-[28px] bg-oasisPrimary200 rounded border border-oasisPrimary500 items-center justify-center'
        }
        onClick={() => inputRef.current.click()}
      >
        <img src={CloudUploadIcon} alt="Paperclip Icon" />
      </div>
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={(e) => uploadFiles(e)}
        accept=".jpg,.png,.jpeg"
      />
    </>
  );
};

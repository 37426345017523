import * as React from 'react';
import SwitchComponent from 'react-switch';

export default function Switch({ checked, onChange, label, disabled }) {
  return (
    <div className="flex display-flex">
      <SwitchComponent
        checked={checked}
        onChange={onChange}
        onColor="#fff"
        offColor="#fff"
        onHandleColor="#0C71D7"
        offHandleColor="#0B1940"
        handleDiameter={10}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="none"
        activeBoxShadow="none"
        height={22}
        width={33}
        className={`react-switch ${checked && 'react-switch-checked'}`}
        disabled={disabled}
      />
      <span className={`text-sm ml-2 ${disabled && 'text-lightGray'}`}>
        {label}
      </span>
    </div>
  );
}

/*eslint-disable*/
import React, { useEffect, useState, useCallback } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  TextField,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
  Checkbox
} from '@material-ui/core';
import { isRequired } from '../../../utils/validators';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { upload } from '../../../utils/upload';
import { Autocomplete } from '@material-ui/lab';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  }
}));

function AddVehicleView({
  selectedVehicle,
  formErrors,
  open,
  handleClose,
  addVehicle,
  cars,
  setCars,
  attemptedAlready,
  setAttemptedAlready
}) {
  const [validation, setValidation] = useState({});
  const [vendorName, setVendorName] = useState('');
  const [vendorId, setVendorId] = useState(null);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [cost, setCost] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [carName, setCarName] = useState('');
  const [carVal, setCarVal] = useState('');
  const [carId, setCarId] = useState('');
  const [runningPaperImage, setRunningPaperImage] = useState(null);
  const [routePermitImage, setRoutePermit] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [changeVendor, setChangeVendor] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [searchVendor, setSearchVendor] = useState([]);
  const classes = useStyles();
  const [isDedicated, setIsDedicated] = useState(false);

  useEffect(() => {
    if (selectedVehicle) {
      setVendorName(selectedVehicle.Vendor ? selectedVehicle.Vendor.name : '');
      setVendorId(selectedVehicle.Vendor ? selectedVehicle.Vendor.id : '');
      setRegistrationNumber(
        selectedVehicle ? selectedVehicle.registrationNumber : ''
      );
      setCost(selectedVehicle ? selectedVehicle.cost : '');
      setCarName(
        selectedVehicle.Car &&
          selectedVehicle.Car.CarMake &&
          selectedVehicle.Car.CarModel &&
          selectedVehicle.Car.Category
          ? selectedVehicle?.Car?.CarMake?.name +
              ' ' +
              selectedVehicle?.Car?.CarModel?.name +
              ' ' +
              selectedVehicle?.Car?.Category?.name
          : ''
      );
      setCarId(selectedVehicle.Car ? selectedVehicle.Car.id : '');
      setIsDedicated(selectedVehicle ? selectedVehicle.isDedicated : false);
    } else {
      resetLocalStates();
    }
  }, [open]);

  useEffect(() => {
    getDelayedCars(carVal);
  }, [carVal]);

  const getDelayedCars = useCallback(
    debounce((value) => {
      getNewCars(value).then((newCars) => {
        setCars(newCars.data);
      });
    }, DEBOUNCE_CONST),
    []
  );

  const getNewCars = async (value) => {
    try {
      let columns = ['$CarMake.name$', '$CarModel.name$', '$Category.name$'];
      let vehicles = await API.get(`vehicles/types`, {
        params: { search: value, columns }
      });
      return vehicles;
    } catch (err) {
      onError(err);
    }
  };

  async function getVendors(value) {
    try {
      const { data } = await API.get(`companies`, {
        params: {
          search: value,
          filters: { colVal: { isActive: '1' }, sortable: '' },
          columns: ['name'],
          type: 'VENDOR'
        }
      });

      setVendors(data.rows);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  }

  const getVendorsAPI = useCallback(
    debounce((searchVendor) => {
      getVendors(searchVendor);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getVendorsAPI(searchVendor);
  }, [searchVendor, selectedVehicle, open]);

  const resetLocalStates = () => {
    setValidation({});
    setVendorName('');
    setVendorId(null);
    setRegistrationNumber('');
    setCost('');
    setCarName('');
    setCarId(null);
    setRunningPaperImage(null);
    setRoutePermit(null);
    setIsDedicated(false);
  };

  const handleSubmit = async () => {
    let newVehicle = {
      companyId: vendorId,
      registrationNumber: registrationNumber,
      cost,
      carId: carId,
      runningPaperId: selectedVehicle && selectedVehicle.RunningPaper.id,
      routePermitId: selectedVehicle && selectedVehicle.RoutePermit.id,
      isDedicated
    };
    setValidation({
      vendorId: true,
      registrationNumber: true,
      make: true,
      model: true,
      runningPaperImage: true,
      routePermitImage: true
    });

    if (
      isRequired(vendorId) &&
      isRequired(registrationNumber) &&
      isRequired(carId)
    ) {
      setAttemptedAlready(true);
      if (runningPaperImage)
        [newVehicle.runningPaperId] = await upload(
          [runningPaperImage],
          'vehicle'
        );
      if (routePermitImage)
        [newVehicle.routePermitId] = await upload(
          [routePermitImage],
          'vehicle'
        );
      if (
        !isRequired(newVehicle.runningPaperId) ||
        !isRequired(newVehicle.routePermitId)
      ) {
        setAttemptedAlready(false);
        return;
      }
      try {
        await addVehicle(newVehicle);
      } catch (err) {
        // setFormErrors(<Alert elevation={6} variant="filled" severity="error" onClose={() => setFormErrors('')}>{res.data.message}</Alert>);
      }
    }
  };

  const validateRunningPaperImage = (event) => {
    const checkFile = event.target.files[0];
    // let dimentions
    if (!checkFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert('Running Paper image must be only image file!');
      return false;
    }
    const isLt2M = checkFile.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      alert('Running Paper image must smaller than 1MB!');
      return false;
    }
    setRunningPaperImage(checkFile);
  };

  const validatePermitFileImage = (event) => {
    const checkFile = event.target.files[0];
    if (!checkFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert('Route Permit image must be only image file!');
      return false;
    }
    const isLt2M = checkFile.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      alert('Route Permit image must smaller than 1MB!');
      return false;
    }
    setRoutePermit(checkFile);
  };
  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle disableTypography className="title">
            {!selectedVehicle ? 'Add Vehicle' : 'Edit Vehicle'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="vendorId"
                      key={'vendorId'}
                      options={vendors}
                      defaultValue={
                        selectedVehicle
                          ? {
                              name: selectedVehicle?.Vendor?.name,
                              id: selectedVehicle?.Vendor?.id
                            }
                          : ''
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vendor"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(vendor) =>
                        vendor?.name || vendorName || ''
                      }
                      onBlur={() =>
                        setValidation({ ...validation, vendorId: true })
                      }
                      onInputChange={(event, newValue) => {
                        setSearchVendor(newValue);
                      }}
                      onFocus={() => {
                        setSearchVendor('');
                      }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setChangeVendor(false);
                          setVendorId(newValue?.id);
                        }
                      }}
                    />
                    {validation.vendorId && !isRequired(vendorId) ? (
                      <Typography color="error">Vendor is required!</Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="cost"
                    label="Montly Cost"
                    type="number"
                    variant="outlined"
                    value={cost}
                    onChange={(e) => {
                      setCost(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="vehicleView">
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="RegistrationNumber"
                    label="Registration Number"
                    type="text"
                    variant="outlined"
                    value={registrationNumber}
                    onChange={(e) => {
                      // const regex = /^[a-zA-Z0-9_-]*$/;
                      // if (regex.test(e.target.value))
                      setRegistrationNumber(e.target.value);
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, registrationNumber: true })
                    }
                  />
                  {validation.registrationNumber &&
                  !isRequired(registrationNumber) ? (
                    <Typography color="error">
                      Registraion Number is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="carId"
                      key={'cars'}
                      options={cars}
                      defaultValue={
                        selectedVehicle
                          ? {
                              name: `${selectedVehicle?.Car?.CarMake?.carMake} ${selectedVehicle?.Car?.CarModel?.name} ${selectedVehicle?.Car?.Category?.name}`,
                              id: selectedVehicle.Car.id
                            }
                          : ''
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vehicle Type"
                          variant="outlined"
                          onKeyUp={(event) => {
                            if (event.target.value) {
                              setCarVal(event.target.value);
                            }
                          }}
                        />
                      )}
                      getOptionLabel={(car) => {
                        return car && car.name
                          ? car.name
                          : car && car.CarMake && car.CarModel
                          ? `${car?.CarMake?.name} ${car?.CarModel?.name} ${car?.Category?.name}`
                          : '';
                      }}
                      onBlur={() =>
                        setValidation({ ...validation, modelId: true })
                      }
                      onChange={(event, newValue) => {
                        if (newValue) setCarId(newValue.id);
                      }}
                    />
                    {validation.carId && !isRequired(carId) ? (
                      <Typography color="error">
                        Vehicle type is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="vehicleButtons">
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Tooltip title="Upload Running Paper Image">
                      <Button
                        variant="contained"
                        component="label"
                        color={
                          (selectedVehicle &&
                            selectedVehicle?.RunningPaper?.key) ||
                          runningPaperImage
                            ? 'primary'
                            : 'default'
                        }
                        startIcon={<CloudUploadIcon />}
                      >
                        Running Paper{' '}
                        {(selectedVehicle &&
                          selectedVehicle?.RunningPaper?.key) ||
                        runningPaperImage
                          ? 'Uploaded'
                          : ''}
                        <input
                          type="file"
                          hidden
                          onChange={(e) => validateRunningPaperImage(e)}
                          accept=".jpg,.png,.jpeg"
                        />
                      </Button>
                    </Tooltip>
                    {!(selectedVehicle && selectedVehicle.RunningPaper.id) &&
                    validation.runningPaperImage &&
                    !isRequired(runningPaperImage) ? (
                      <Typography color="error">
                        Running paper is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Tooltip title="Upload Route Permit Image">
                      <Button
                        variant="contained"
                        component="label"
                        color={
                          (selectedVehicle &&
                            selectedVehicle.RoutePermit.key) ||
                          routePermitImage
                            ? 'primary'
                            : 'default'
                        }
                        startIcon={<CloudUploadIcon />}
                      >
                        Route Permit{' '}
                        {(selectedVehicle && selectedVehicle.RoutePermit.key) ||
                        routePermitImage
                          ? 'Uploaded'
                          : ''}
                        <input
                          type="file"
                          hidden
                          onChange={(e) => validatePermitFileImage(e)}
                          accept=".jpg,.png,.jpeg"
                        />
                      </Button>
                    </Tooltip>
                    {validation.routePermitImage &&
                    !isRequired(routePermitImage) ? (
                      <Typography color="error">
                        Route Permit is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Checkbox
                  checked={isDedicated}
                  onChange={(e) => setIsDedicated(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Dedicated
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="vehicleTypeBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={handleClose}
                color="default"
                variant="contained"
                className="vehicleCloseBtn"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip
              title={!selectedVehicle ? 'Add Vehicle' : 'Update Vehicle'}
            >
              <Button
                onClick={() => handleSubmit()}
                color="primary"
                variant="contained"
                disabled={attemptedAlready}
              >
                {!selectedVehicle ? 'Add Vehicle' : 'Update Vehicle'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

export default AddVehicleView;

import React from 'react';

const PricingTable = () => {
  return (
    <>
      <stripe-pricing-table
        pricing-table-id="prctbl_1PBul7EOmr4NAq7igVmRXC07"
        publishable-key="pk_test_51OSZwgEOmr4NAq7ijdjvj3rZymUbxIePxZyLmQIVoIrFKGrzh3hfADsNKvLhqiNGqApnWhUn4Ej008X7Kr7Xc1rc00L26k0Xce"
      ></stripe-pricing-table>
    </>
  );
};

export default PricingTable;

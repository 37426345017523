import { useEffect, useContext, useState } from 'react';
import { Grid } from '@material-ui/core';

import useStyles from '../invoiceStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';

export default function Preview({ data, setHtml }) {
  const classes = useStyles();
  const { setAPILoader } = useContext(SharedContext);
  const [body, setBody] = useState('');

  const preview = async () => {
    try {
      setAPILoader(true);
      const res = await API.post(`/invoices/preview`, data);
      setBody(res.html);
      setHtml(res.html);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    preview();
  }, []);

  return (
    <Grid container spacing={3} className="mt-10 mb-10">
      <div className={classes.previewContainer}>
        <div
          dangerouslySetInnerHTML={{ __html: body }}
          style={{ width: '90%' }}
          id="pdf-view"
        ></div>
      </div>
    </Grid>
  );
}

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .Mui-selected': {
      borderBottom: '4px solid #0C71D7 !important',
      color: '#0C6BD7 !important',
      paddingBottom: '12px !important'
    },
    '& .MuiTab-textColorPrimary': {
      height: '48px',
      minWidth: '0px',
      borderBottom: 'none',
      color: '#4B5369',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      textTransform: 'none',
      // paddingBottom: '20px',
      padding: '16px'
    },
    '& .MuiTouchRipple-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left'
    }
  }
}));

export default useStyles;

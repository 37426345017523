import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Grid,
  Typography
} from '@material-ui/core';
import { SharedContext } from '../../utils/common';
import '../../assets/css/style.css';
import MagicBell, {
  FloatingNotificationInbox
} from '@magicbell/magicbell-react';
import config from '../../config/';
import UserProfilePic from '../../core-components/molecules/UserProfilePic';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)'
  },
  avatar: {
    width: 60,
    height: 60
  },
  userLink: {
    color: theme.palette.primary.dark,
    fontWeight: 'bolder',
    cursor: 'pointer',
    '@media only screen and (max-width: 1200px) and (min-width: 992px)': {
      marginLeft: '9.5% !important',
      transition: '0.2s'
    }
  },
  logout: {
    color: 'black',
    width: '100%',
    justifyContent: 'left',
    padding: '10px 25px'
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    color: '#0B1940'
  },
  dashboardRoot: {
    background: '#030B1F'
  },
  dashboardTitle: {
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 700,
    color: '#FFFFFF'
  }
}));

const TopBar = ({ className, ...rest }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const classes = useStyles();
  const { currentUser, currentPageTitle, currentPageIcon, sidebar } =
    useContext(SharedContext);
  const location = useLocation();

  const [externalId, setExternalId] = useState(null);

  const fetchUserDataFromMagicBell = async (email) => {
    try {
      const response = await fetch(
        `${
          config.REACT_APP_MAGIC_BELL_API_ROUTE
        }/users/find_by_email?email=${email.replace('+', '%2B')}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${config.REACT_APP_MAGICBELL_BEARER_TOKEN}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.ok) {
        const userData = await response.json();
        if (userData) {
          setExternalId(String(userData.id));
        }
      } else {
        throw new Error(
          `An error occurred in fetching user: ${response.status}`
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    fetchUserDataFromMagicBell(currentUser.email);
  }, currentUser);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <AppBar
      className={clsx(
        location.pathname.split('/').pop() === 'control-tower' ||
          location.pathname.split('/').pop() === 'intelligence'
          ? 'bg-blackPurple'
          : 'bg-white border-b border-platinum',
        className,
        {
          'w-[84vw]': sidebar && window.innerWidth >= 768,
          'w-full': !sidebar || window.innerWidth < 768
        },
        'topBar h-[48px]'
      )}
      elevation={0}
      {...rest}
    >
      <Toolbar className="h-[44px] min-h-[44px]">
        <Box flexGrow={1} />
        <Grid container align="right" className="flex items-center">
          <Grid
            item
            xs={8}
            align="left"
            className={`${classes.titleBox}`}
            id="titleGRN"
          >
            <Box mr={4} mt={0}>
              <Typography
                id={sidebar ? 'currentTitle' : 'setCurrentTitleSet'}
                component="div"
                style={{ display: 'flex', alignItems: 'center' }}
                className={clsx(
                  classes.userLink,
                  isMobile && 'w-100 ',
                  'text-2xl',
                  location.pathname.split('/').pop() === 'control-tower' ||
                    location.pathname.split('/').pop() === 'intelligence'
                    ? classes.dashboardTitle
                    : 'text-2xl text-primaryBlue font-bold'
                )}
              >
                {currentPageTitle}
                {currentPageTitle === 'GRN Detail' ||
                currentPageTitle === 'GDN Detail'
                  ? currentPageIcon
                  : null}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} className="preventOverflow">
            <Box className="flex items-center mr-0 justify-end">
              <div className="mr-4 z-[1600]">
                {externalId &&
                isFeatureEnabled(FLAGS.TRIGGER_BASED_NOTIFICATIONS) ? (
                  <MagicBell
                    apiKey={config.REACT_APP_MAGICBELL_API_KEY}
                    userExternalId={externalId}
                    locale="en"
                    theme={
                      location.pathname.split('/').pop() === 'control-tower' ||
                      location.pathname.split('/').pop() === 'intelligence'
                        ? { icon: { borderColor: '#FFF' } }
                        : { icon: { borderColor: '#003F87' } }
                    }
                  >
                    {(props) => (
                      <FloatingNotificationInbox
                        width={400}
                        height={500}
                        placement="bottom"
                        onNotificationClick={(notification) => {
                          mixpanel.track('Notification Clicked', {
                            notificationId: notification?.id,
                            timestamp: new Date().toISOString()
                          });
                        }}
                        {...props}
                      />
                    )}
                  </MagicBell>
                ) : (
                  ''
                )}
              </div>

              <UserProfilePic />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

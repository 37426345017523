import { makeStyles } from '@material-ui/core';

const warehouseBookingStyles = makeStyles(() => ({
  root: {
    width: '95%',
    margin: '32px auto 0px auto',
    borderRadius: '8px'
  },
  dataGridContainer: {
    marginTop: '26px'
  },
  paginationContainer: {
    marginTop: '20px'
  },
  menuItems: {
    fontWeight: '500',
    fontSize: '16px'
  }
}));

export default warehouseBookingStyles;

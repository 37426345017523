export const companyTypes = {
  '3PL': {
    DISTRIBUTER: 'Distributer',
    RETAILER: 'Retailer',
    RESELLER: 'Reseller',
    MANUFACTURER: 'Manufacturer',
    OTHERS: 'Others'
  },
  MANUFACTURER: {
    DISTRIBUTOR: 'Distributor',
    RETAILER: 'Retailer',
    RESELLER: 'Reseller',
    MANUFACTURER: 'Manufacturer',
    IMPORTER: 'Importer',
    TRADER: 'Trader',
    WHOLESALER: 'Wholesaler',
    OTHERS: 'Others'
  }
};

export const supplierTypes = {
  INDIVIDUAL: 'Individual',
  CUSTOMER: 'Company'
};

export const organizationTypes = {
  THIRD_PARTY_SERVICE_PROVIDER: '3PL',
  MANUFACTURER: 'Manufacturer'
};

export const nodeTypes = {
  '3PL_INTERNAL': {
    WAREHOUSE: 'Warehouse',
    PLANT: 'Company/Plant',
    DARKSTORE: 'Dark Store'
  },
  EXTERNAL: {
    WAREHOUSE: 'Warehouse',
    PLANT: 'Company/Plant',
    DARKSTORE: 'Dark Store'
  },
  MANUFACTURER_INTERNAL: {
    WAREHOUSE: 'Warehouse',
    PLANT: 'Company/Plant',
    DARKSTORE: 'Dark Store'
  },
  SUPPLIER_NODES: {
    WAREHOUSE: 'Warehouse',
    PLANT: 'Company/Plant',
    DARKSTORE: 'Dark Store'
  }
};

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Tooltip, Pagination, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { debounce } from 'lodash';

import SearchBar from '../../../atomicComponents/SearchBar';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import warehouseBookingStyles from './warehouseBookingStyles';
import listingColumns from './listingColumns';
import sortableIcon from '../../../assets/icons/sortableIcon.svg';
import { SharedContext } from '../../../utils/common';
import { DEBOUNCE_CONST } from '../../../Config';
import ActionMenu from './BookingActionMenu';
import AddWarehouseBooking from './AddWarehouseBooking';
import useMediaQuery from '@mui/material/useMediaQuery';

const WarehouseBookingList = () => {
  const classes = warehouseBookingStyles();
  const [bookings, setBookings] = useState([]);
  const [sortable, setSortable] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPagesCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const recordLimit = 10;

  const { currentPageTitle, setCurrentPageTitle, apiLoader, setAPILoader } =
    useContext(SharedContext);

  useEffect(() => {
    let title = 'Warehouse Booking Management';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  const isLaptop = useMediaQuery('(max-width:1400px)');
  const bookingsColumns = [
    {
      field: 'customer',
      flex: isLaptop ? null : 1,
      width: isLaptop ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          <button
            type="button"
            onClick={() => {
              sortBy('Customer.name');
            }}
            className="customHeaderIcon"
          >
            <img src={sortableIcon} alt="sortable" />
          </button>
          Customer
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.Customer?.name}`}>
          <p>{params.row?.Customer?.name}</p>
        </Tooltip>
      )
    },
    {
      field: 'warehouse',
      flex: isLaptop ? null : 1,
      width: isLaptop ? 180 : null,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <p className="w-100 parentActions detailText">
          <button
            type="button"
            onClick={() => {
              sortBy('Warehouse.businessWarehouseCode');
            }}
            className="customHeaderIcon"
          >
            <img src={sortableIcon} alt="sortable" />
          </button>
          Warehouse
        </p>
      ),
      renderCell: (params) => (
        <Tooltip title={`${params.row?.Warehouse?.businessWarehouseCode}`}>
          <p>{params.row?.Warehouse?.businessWarehouseCode}</p>
        </Tooltip>
      )
    },
    ...listingColumns,
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <p className="w-100 parentActions detailText">Actions</p>
      ),
      renderCell: (params) => {
        return (
          <ActionMenu
            booking={params?.row}
            setSelectedBooking={setSelectedBooking}
            getBookings={getBookings}
            openModal={setOpen}
          />
        );
      }
    }
  ];

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const getBookings = async (page, searchValue, filters, columns) => {
    try {
      setAPILoader(true);
      const res = await API.get(`warehouses/bookings`, {
        params: {
          page: page,
          search: searchValue,
          limit: recordLimit,
          filters,
          columns
        }
      });
      setBookings(res?.data);
      setPagesCount(res?.pages);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const getDelayedBookings = useCallback(
    debounce(
      (page, searchValue, sortable) => {
        let filters = {
          sortable
        };
        let columns = [
          '$Customer.name$',
          '$Warehouse.name$',
          'bookingStartDate',
          'bookingEndDate',
          '$Warehouse.businessWarehouseCode$'
        ];
        getBookings(page, searchValue, filters, columns);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedBookings(page, searchValue, sortable);
  }, [page, searchValue, sortable]);

  const handleClose = () => {
    setOpen(false);
    setSelectedBooking(null);
  };

  return (
    <div className={classes.root}>
      {open ? (
        <AddWarehouseBooking
          handleClose={handleClose}
          open={open}
          booking={selectedBooking}
          getBookings={getBookings}
        />
      ) : null}
      <div className={classes.searchBarContainer}>
        <div className={classes.floatContainer}>
          <SearchBar
            width={classes.searchBar}
            placeholder="Search warehouse here"
            setSearchValue={(e) => {
              setPage(1);
              setSearchValue(e);
            }}
          />
        </div>
        <div className="float-right">
          <button
            className={classes.addNewBooking}
            onClick={() => setOpen(true)}
          >
            ADD
          </button>
        </div>
      </div>
      <div className={classes.dataGridContainer}>
        <Grid className="warehouseScroll-table">
          <DataGrid
            className="bookingGrid"
            loading={apiLoader}
            autoHeight={true}
            rows={bookings}
            columns={bookingsColumns}
            sx={{
              '.MuiDataGrid-columnSeparator': {
                display: 'none'
              },
              '&.MuiDataGrid-root': {
                border: 'none'
              }
            }}
          />
        </Grid>
      </div>
      <div className={classes.paginationContainer}>
        <Pagination
          component="div"
          shape="rounded"
          count={pageCount}
          color="primary"
          page={page}
          onChange={(e, page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default WarehouseBookingList;

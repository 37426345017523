import React from 'react';
import { Grid, Paper, makeStyles, Button } from '@material-ui/core';
import classNames from 'classnames/bind';
import MoveTypeIcon from '../../views/orders/moveTypeIcon';
import { OrderTypeMapping } from '../../constants/index';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '3px'
  },
  pageHeader: {
    padding: '15px 0'
  },
  val: {
    fontWeight: 'bolder'
  },
  statsButton: {
    background: '#FFFFFF',
    border: '1.5px solid #4B89C8',
    borderRadius: '8px',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      width: '100% !important'
    }
  }
}));

function OrderManagementStats({
  stats,
  icons,
  setCurrentFilter,
  currentFilter
}) {
  const classes = useStyles();
  var ButtonClass = classNames({
    [classes.statsButton]: true,
    customOrderStatsBtn: true,
    normalCase: true
  });

  return (
    <Paper elevation={0} square className={classes.root}>
      <div className={classes.pageHeader}>
        <Grid container justifyContent="flex-start" spacing={1}>
          {stats
            ? stats?.map((stat) => {
                return (
                  <Grid item key={stat?.key} className="statsWidth">
                    <Button
                      key={stat?.key}
                      variant="contained"
                      className={
                        currentFilter == stat?.key
                          ? 'currentStat normalCase'
                          : ButtonClass
                      }
                      onClick={() => {
                        setCurrentFilter(stat?.key);
                      }}
                      color={
                        stat?.key === currentFilter ? 'primary' : 'default'
                      }
                      style={{ textTransform: 'capitalize' }}
                    >
                      {icons && stat?.key !== 'ALL' ? (
                        <MoveTypeIcon type={stat?.key} />
                      ) : (
                        ''
                      )}
                      {OrderTypeMapping[stat?.key]
                        ? OrderTypeMapping[stat?.key]
                        : stat?.label}
                      &nbsp;
                      {stat?.value ? <strong>({stat?.value})</strong> : null}
                    </Button>
                  </Grid>
                );
              })
            : ''}
        </Grid>
      </div>
    </Paper>
  );
}

export default OrderManagementStats;

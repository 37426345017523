import React from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';

import { getPropByString } from '../utils/common';

const FormikInputField = ({
  placeholder,
  name,
  formik,
  title,
  type = 'text',
  IconStart,
  IconEnd,
  disabled,
  size = 'medium',
  max = ''
}) => {
  const value = getPropByString(formik.values, name);
  const errors = getPropByString(formik.errors, name);
  const touched = getPropByString(formik.touched, name);

  return (
    <>
      <TextField
        className="formikInputField"
        id={name}
        name={name}
        label={title}
        placeholder={placeholder || ''}
        value={value || ''}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        disabled={disabled}
        type={type}
        size={size}
        InputProps={{
          ...(IconStart && {
            startAdornment: (
              <InputAdornment position="start">{IconStart}</InputAdornment>
            )
          }),
          ...(IconEnd && {
            endAdornment: (
              <InputAdornment position="end">{IconEnd}</InputAdornment>
            )
          })
        }}
        inputProps={{
          maxLength: max,
          form: {
            autocomplete: 'off'
          }
        }}
      />
      {errors && touched && <Typography color="error">{errors}</Typography>}
    </>
  );
};

export default FormikInputField;

export default Object.freeze([
  'LOAD_DISPATCHED',
  'COMPLETED',
  'UNLOADING_IN_PROGRESS',
  'UNLOADING_COMPLETE',
  'PARTIALLY_COMPLETE',
  'PARTIALLY_COMPLETED_CLOSED',
  'COMPLETED',
  'FULLY_COMPLETE'
]);

/*eslint-disable*/
import React, { useEffect, useRef } from 'react';
import { Popover, List, ListItem, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';
import TextField from '../../core-components/atoms/TextField';
import { makeStyles } from '@material-ui/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

function MultiSelect({
  options,
  value,
  setValue,
  placeholder,
  formik,
  name,
  className
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const mainRef = useRef();
  const secondaryRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else {
      handleClose();
    }
  };

  const handleSubMenuClick = (event, index) => {
    setSubMenuAnchorEl(event.currentTarget.parentNode);
    setActiveIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSearch('');
  };

  const useStyles = makeStyles((theme) => ({
    ListItem: {
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer',
      display: 'flex',
      gap: '8px'
    },
    popOver: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      pointerEvents: 'none'
    },
    search: {
      margin: '1rem auto',
      width: '90%',
      boxSizing: 'border-box',
      display: 'block'
    },
    paper: {
      pointerEvents: 'auto',
      border: '1px solid #E7E6E6',
      marginTop: '12px',
      marginRight: '16px'
    },
    subMenuPaper: {
      pointerEvents: 'auto',
      border: '1px solid #E7E6E6',
      height: '300px'
    }
  }));

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        secondaryRef.current &&
        !secondaryRef.current.contains(event.target)
      ) {
        handleClose();
        handleSubMenuClose();
      }

      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        !secondaryRef.current
      ) {
        handleClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className={clsx(
          `p-[8.5px] flex justify-between border border-solid rounded`,
          formik.touched[name] && formik.errors[name]
            ? 'border-error'
            : 'border-black',
          className
        )}
        onClick={handleClick}
      >
        <span onClick={(e) => e.preventDefault()}>
          {value?.name || placeholder}
        </span>
        <div onClick={(e) => e.preventDefault()}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>

      {formik.touched[name] && formik.errors[name]?.value && (
        <p className="text-error mt-2">{formik.errors[name].value}</p>
      )}

      <Popover
        open={open && anchorEl}
        anchorEl={anchorEl}
        ref={mainRef}
        style={{ pointerEvents: 'none', zIndex: 1500 }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <List>
          {options.map((option, i) => (
            <ListItem
              className={classes.ListItem}
              style={{
                width: anchorEl?.clientWidth
              }}
              onMouseEnter={(e) =>
                option.options?.length > 0
                  ? handleSubMenuClick(e, i)
                  : handleSubMenuClose()
              }
              onClick={(e) => {
                if (!option.options || option.options.length == 0) {
                  setValue({
                    ...option,
                    label: option.type
                  });
                  handleClose();
                  handleSubMenuClose();
                }
              }}
            >
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Popover
        open={subMenuOpen}
        anchorEl={subMenuAnchorEl}
        onClose={handleSubMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={classes.popOver}
        classes={{
          paper: classes.subMenuPaper
        }}
        PaperProps={{
          onMouseLeave: handleSubMenuClose
        }}
        ref={secondaryRef}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        style={{ zIndex: 1500 }}
      >
        <List style={{ width: '300px' }}>
          {activeIndex &&
          (options[activeIndex]?.label === 'Customer' ||
            options[activeIndex]?.label === 'Supplier') ? (
            <ListItem>
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </ListItem>
          ) : null}
          {(activeIndex || activeIndex == 0) &&
            options[activeIndex]?.options.map((option, i) => (
              <ListItem
                className={classes.ListItem}
                onClick={() => {
                  setValue({
                    ...option,
                    entityType: options[activeIndex]?.entityType
                  });
                  handleClose();
                  handleSubMenuClose();
                }}
              >
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
}

export default MultiSelect;

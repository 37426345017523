import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState, useContext } from 'react';
import TextField from '../../../core-components/atoms/TextField';
import { useFormik } from 'formik';
import Button from '../../../core-components/atoms/Button';
import { initialValues, validationSchema } from './validationSchema/AddBatch';
import ProductSubTable from './ProductSubTable';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { DEBOUNCE_CONST } from '../../../Config';
import { debounce } from 'lodash';
import { SharedContext, productLabelFormat } from '../../../utils/common';
import { ORGANIZATION_TYPES } from '../../../constants';

const AddProduct = ({ addProduct, setOpen, companyId }) => {
  const [batches, setBatches] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState('');
  const [closeModal, setCloseModal] = useState(false);
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [productSearchKeyword, setProductSearchKeyword] = useState('');
  const { organizationType } = useContext(SharedContext);

  const _getProducts = React.useCallback(
    debounce((companyId, productSearchKeyword) => {
      getProducts(companyId, productSearchKeyword);
    }, DEBOUNCE_CONST),
    [companyId]
  );

  useEffect(() => {
    if (companyId) {
      _getProducts(companyId, productSearchKeyword);
    }
  }, [productSearchKeyword]);

  useEffect(() => {
    if (companyId) {
      getProducts(companyId);
    }
  }, [companyId]);

  const options = products.filter(
    (p) => products?.map((p) => p.product?.id).indexOf(p.id) === -1
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (!product) {
        setError('Please Select a product');
        return;
      } else if (
        product.batchEnabled &&
        (!values.manufacturingDate || !values.expiryDate)
      ) {
        setError('Dates are mandatory');
        return;
      } else if (
        product.batchEnabled &&
        new Date(values.manufacturingDate) > new Date(values.expiryDate)
      ) {
        setError('Manufacturing Date must be lesser than expiry');
        return;
      }

      if (
        (values.promoName && !values.promoQuantity) ||
        (!values.promoName && values.promoQuantity)
      ) {
        setError(`Required both Promo Name and Promo Quantity or none`);
        return;
      }

      setError('');
      const row = {
        ProductId: product.id,
        quantity: 0,
        orderedQuantity: 0,
        receivedQuantity: 0
      };
      row.Product = product;
      setBatches([{ values }]);
      if (!closeModal) setShowTable(true);
      addProduct(row, values);
      if (closeModal) setOpen(false);
      else actions.resetForm();
    }
  });

  const getProducts = async (companyId, search = '') => {
    try {
      let columns = [
        'name',
        'description',
        'barcode',
        'dimensionsCBM',
        'weight',
        '$Category.name$',
        '$Brand.name$',
        '$UOM.name$',
        'perPallet'
      ];

      let colVal = {
        isActive: '1'
      };

      let filters = {
        colVal
      };
      const res = await API.get(`products`, {
        params: {
          search,
          columns,
          filters,
          ...(organizationType ===
          ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
            ? { companyId }
            : {})
        }
      });
      setProducts(res?.data || []);
    } catch (error) {
      onError(error);
    }
  };

  const autoPopulateExpiry = (date) => {
    if (date) {
      const expDate = new Date(date);
      expDate.setDate(expDate.getDate() + (+product?.productLife || 0));
      formik.setFieldValue('expiryDate', expDate);
    }
  };

  return (
    <div className="mt-2">
      {showTable && (
        <Box sx={{ marginBottom: 2 }}>
          <ProductSubTable batches={batches} />
        </Box>
      )}
      <form onSubmit={formik.handleSubmit} className="number-input-container">
        <p className="text-error mb-4">{error}</p>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              label="Select Product"
              variant="outlined"
              size="small"
              fullWidth
              options={options}
              onChange={(e, val) => {
                setProduct(val);
                setProductSearchKeyword();
              }}
              value={product}
              isOptionEqualToValue={(option, value) =>
                option.product?.id === value.product?.id
              }
              onKeyUp={(e) => {
                setProductSearchKeyword(e.target.value);
              }}
              onBlur={() => {
                _getProducts(companyId, '');
              }}
              getOptionLabel={(option) => productLabelFormat(option)}
            />
          </Grid>
          {product.batchEnabled && (
            <>
              <Grid item xs={3}>
                <TextField
                  name="batchNumber"
                  label="Batch #"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formik.values.batchNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="text-error">
                  {formik.touched.batchNumber && formik.errors.batchNumber}
                </span>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="manufacturingDate"
                    label="Mfg. Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.manufacturingDate}
                    onChange={(date) => {
                      formik.setFieldValue('manufacturingDate', date);
                      autoPopulateExpiry(date);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                <span className="text-error">
                  {formik.touched.manufacturingDate &&
                    formik.errors.manufacturingDate}
                </span>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="expiryDate"
                    label="Exp. Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.expiryDate}
                    onChange={(date) =>
                      formik.setFieldValue('expiryDate', date)
                    }
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                {formik.touched.expiryDate && formik.errors.expiryDate}
              </Grid>
            </>
          )}
          {product.MRPEnabled && (
            <Grid item xs={3}>
              <TextField
                name="MRP"
                label="MRP per Pack (Rs)"
                variant="outlined"
                size="small"
                fullWidth
                value={formik.values.MRP}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="text-error">
                {formik.touched.MRP && formik.errors.MRP}
              </span>
            </Grid>
          )}
          <Grid item xs={3}>
            <TextField
              name="receivedQuantity"
              label="Received Qty"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.receivedQuantity}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
              type="number"
            />
            <span className="text-error">
              {formik.touched.receivedQuantity &&
                formik.errors.receivedQuantity}
            </span>
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="recoverableDamageQuantity"
              label="Damaged"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.recoverableDamageQuantity}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
              type="number"
            />
            <span className="text-error">
              {formik.touched.recoverableDamageQuantity &&
                formik.errors.recoverableDamageQuantity}
            </span>
          </Grid>
          {/* <Grid item xs={3}>
            <TextField
              name="unsellableDamageQuantity"
              label="Unsellable"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.unsellableDamageQuantity}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
              type="number"
            />
            <span className="text-error">
              {formik.touched.unsellableDamageQuantity &&
                formik.errors.unsellableDamageQuantity}
            </span>
          </Grid> */}
          <Grid item xs={3}>
            <TextField
              name="promoName"
              label="Promo Code"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.promoName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className="text-error">
              {formik.touched.promoName && formik.errors.promoName}
            </span>
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="promoQuantity"
              label="Promo Qty"
              variant="outlined"
              size="small"
              fullWidth
              value={formik.values.promoQuantity || 0}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
              type="number"
            />
            <span className="text-error">
              {formik.touched.promoQuantity && formik.errors.promoQuantity}
            </span>
          </Grid>
          <Grid item xs={12} className="flex justify-end">
            <Button
              size="large"
              label="Save & Add New Batch"
              type="submit"
              onClick={() => {
                setCloseModal(false);
              }}
              variant="secondary"
              className="w-48 mr-2"
            />
            <Button
              size="large"
              label="Save Changes"
              onClick={() => {
                setCloseModal(true);
              }}
              type="submit"
              variant="primary"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddProduct;

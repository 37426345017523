import clsx from 'clsx';

const StatHeader = ({ date, index, isToday, day }) => {
  return (
    <div
      key={date}
      className={clsx(
        'flex flex-col mt-2 py-1 border-y items-center justify-center no-select',
        {
          'border-l': index > 0
        }
      )}
    >
      <div className="font-normal text-dark600 text-xxs">{day}</div>
      <div
        className={clsx('font-bold text-xl text-black', {
          'text-primary': isToday
        })}
      >
        {date}
      </div>
    </div>
  );
};

export default StatHeader;

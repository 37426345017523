import * as React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '../../../assets/icons/edit.svg';
import Button from '../../../core-components/atoms/Button';
import { Grid, Popover } from '@mui/material';
import clsx from 'clsx';
import { SharedContext } from '../../../utils/common';
import TextField from '../../../core-components/atoms/TextField';
import { useState } from 'react';
import moment from 'moment/moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFormik } from 'formik';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import { onError } from '../../../libs/errorLib';
import FLAGS from '../../../constants/featureFlags';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

export default function EditBatch({
  closeCallback,
  selectedInventoryDetails,
  product,
  refetch
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setAPILoader } = React.useContext(SharedContext);
  const [error, setError] = useState('');
  const open = Boolean(anchorEl);
  const { isFeatureEnabled } = useFeatureFlags();

  const handleClose = () => {
    setAnchorEl(null);
    closeCallback && closeCallback();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const formik = useFormik({
    initialValues: {
      batchNumber: selectedInventoryDetails?.batchNumber,
      manufacturingDate: selectedInventoryDetails?.manufacturingDate,
      expiryDate: selectedInventoryDetails?.expiryDate,
      MRP: selectedInventoryDetails?.MRP
    },
    // validationSchema: Yup.object().shape({
    //   unsellableDamageQuantity: Yup.number().min(
    //     0,
    //     'Unsellable must be a positive number'
    //   )
    // }),
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (
        product.batchEnabled &&
        (!values.manufacturingDate || !values.expiryDate)
      ) {
        setError('Dates are mandatory');
        return;
      } else if (
        product.batchEnabled &&
        new Date(values.manufacturingDate) > new Date(values.expiryDate)
      ) {
        setError('Manufacturing Date must be lesser than expiry');
        return;
      }
      if (product.batchEnabled && !values.batchNumber) {
        setError('Please check your batch number');
        return;
      }

      setError('');
      editBatch(selectedInventoryDetails, {
        ...values
      });
      actions.resetForm();
      formik.setFieldValue('batchNumber', '');
    }
  });

  const editBatch = async (selectedBatch, data) => {
    const payload = {
      batchDetail: {
        hallId: selectedBatch?.hallId,
        manufacturingDate: new Date(data?.manufacturingDate),
        expiryDate: new Date(data?.expiryDate),
        MRP: data?.MRP,
        batchNumber: data?.batchNumber
      }
    };
    try {
      setAPILoader(true);
      await API.put(`inventories/update-batch/${selectedBatch?.id}`, payload);
      toaster('success', 'Batch has updated successfully!');
      refetch();
    } catch (err) {
      onError(err);
    } finally {
      handleClose();
      setAPILoader(false);
    }
  };

  const autoPopulateExpiry = (date) => {
    if (date && product?.productLife) {
      const expDate = new Date(date);
      formik.setFieldValue(
        'expiryDate',
        moment(expDate, 'dd/MM/yyyy').add(product?.productLife, 'days')
      );
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div className="flex gap-[6px]" onClick={(e) => handleClick(e)}>
          <img src={EditIcon} className="w-4" />
          <span className="text-xs text-primaryBlue">Edit Batch</span>
        </div>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 0
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={0}
      >
        <div
          className={clsx(
            'w-[310px] p-4 rounded-[8px] bg-white z-10 transition-all shadow-elevation-2 overflow-hidden'
          )}
        >
          <p className="text-primaryBlue text-base font-medium mt-2">
            Edit Batch
          </p>

          <form
            onSubmit={formik.handleSubmit}
            className="number-input-container"
          >
            <p className="text-error mb-4">{error}</p>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="batchNumber"
                  label="Batch #"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={formik.values.batchNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="text-error">
                  {formik.touched.batchNumber && formik.errors.batchNumber}
                </span>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="manufacturingDate"
                    label="Mfg. Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.manufacturingDate}
                    onChange={(date) => {
                      formik.setFieldValue('manufacturingDate', date);
                      autoPopulateExpiry(date);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled
                        onKeyDown={(e) => e.preventDefault()}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                <span className="text-error">
                  {formik.touched.manufacturingDate &&
                    formik.errors.manufacturingDate}
                </span>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="expiryDate"
                    label="Exp. Date"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.expiryDate}
                    onChange={(date) =>
                      formik.setFieldValue('expiryDate', date)
                    }
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled
                        onKeyDown={(e) => e.preventDefault()}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                {formik.touched.expiryDate && formik.errors.expiryDate}
              </Grid>

              {isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) && (
                <Grid item xs={12}>
                  <TextField
                    name="MRP"
                    label="MRP per Pack (Rs)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.MRP}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="text-error">
                    {formik.touched.MRP && formik.errors.MRP}
                  </span>
                </Grid>
              )}

              <Grid item xs={12} className="flex justify-end">
                <Button
                  size="small"
                  variant="text"
                  label="Cancel"
                  className={'py-2 px-3 h-8'}
                  overrideSize={true}
                  labelClass="font-medium text-xs"
                  onClick={() => {
                    handleClose();
                  }}
                />
                <Button
                  size="small"
                  variant="primary"
                  label="Confirm"
                  className={
                    'py-2 px-3 ml-1 h-8 border-solid border border-Primary'
                  }
                  disabled={!(formik.isValid && formik.dirty)}
                  overrideSize={true}
                  labelClass="font-medium text-xs"
                  type="submit"
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </Popover>
    </React.Fragment>
  );
}

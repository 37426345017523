/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../core-components/atoms/Button';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import { Modal } from '@mui/material';
import { ChevronLeft, CloseOutlined } from '@material-ui/icons';

import { useFormik } from 'formik';
import CustomerForm from './AddCustomerForm';
import { companyTypes, nodeTypes, supplierTypes } from './constants';
import { useNavigate, useParams } from 'react-router';
import { SharedContext } from '../../utils/common';
import { toaster } from '../../utils/toaster';
import { onError } from '../../libs/errorLib';
import { upload } from '../../utils/upload';
import API from '../../libs/axios';
import NodeForm from './AddNodeForm';
import Popup from '../../core-components/atoms/Popup';
import NodeSummaryView from './NodeSummaryView';
import { customerSchema } from './validationSchemas/customerSchema';
import { getSuffix, nodeInitialValues } from './AddCustomer';
import { nodeSchema } from './validationSchemas/nodeSchema';
import { nodeValidation, resetNodeValidation } from './AddNode';
import { convertToTitleCase } from '../revampedOrder/AddOrder';
import NodeList from './NodeList';

function AddSupplier({ edit = false }) {
  const { setAPILoader } = useContext(SharedContext);
  const [addNode, setAddNode] = useState(false);
  const [users, setUsers] = useState([]);
  const [summaryView, setSummaryView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [search, setSearch] = useState('');
  const [supplier, setSupplier] = useState();
  const [nodePage, setNodePage] = useState(1);
  const [nodePages, setNodePages] = useState(0);
  const [nodes, setNodes] = useState([]);

  const { id } = useParams();

  const supplierFormik = useFormik({
    initialValues: {
      supportingDocuments: [],
      name: '',
      code: '',
      type: '',
      ntn: '',
      categoryName: null,
      category: {},
      paymentTerm: {},
      strn: '',
      pocName: '',
      pocId: '',
      pocEmail: '',
      pocContact: '',
      additionalInfo: '',
      paymentTermTitle: '',
      creditLimit: null,
      shippingAddress: '',
      billingAddress: '',
      sameAsBilling: false,
      email: '',
      isSupplier: true,
      isActive: true
    },
    validationSchema: customerSchema,
    onSubmit: async (values) => {
      await submitHandler(values);
    },
    validateOnMount: true
  });

  const nodeFormik = useFormik({
    initialValues: nodeInitialValues,
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const editNodeFormik = useFormik({
    initialValues: nodeInitialValues,
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const addCompany = async (data) => {
    let company = {};
    setAPILoader(true);
    try {
      company = await API.post(`companies`, data);
      toaster('success', 'New Supplier has been created.');
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }

    return company;
  };

  const getUsers = async (value) => {
    try {
      let columns = [
        'firstName',
        'lastName',
        'username',
        'email',
        'phone',
        '$UserRoles.Role.name$'
      ];

      let colVal = {
        '$UserRoles.Role.allowedApps$': 'OPERATIONS',
        isActive: '1'
      };

      let filters = {
        colVal
      };

      const { data } = await API.get(`organizations/${subdomain}/users`, {
        params: { search: value || '', filters, columns }
      });

      setUsers(data);
    } catch (err) {
      onError(err);
    }
  };

  const addNodes = async (companyId) => {
    let payload = [...savedNodes];
    if (payload.length == 0) {
      payload = [
        {
          ...nodeFormik.values,
          nodeType: nodeFormik.values.nodeType.value,
          locationLatLng: nodeFormik.values.latLng,
          companyId
        }
      ];
    } else {
      payload = savedNodes.map((node) => ({
        ...node,
        nodeType: node.nodeType.value,
        locationLatLng: node.latLng,
        companyId
      }));
      payload.push({
        ...nodeFormik.values,
        nodeType: nodeFormik.values.nodeType.value,
        locationLatLng: nodeFormik.values.latLng,
        companyId
      });
    }
    setAPILoader(true);
    try {
      await API.post(`nodes/bulk?companyId=${companyId}`, payload);
      toaster('success', 'New Node has been created.');
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getSupplier = async (id) => {
    try {
      const company = await API.get(`companies/${id}`);
      setSupplier(company.company);

      supplierFormik.setValues({ ...company.company });
      supplierFormik.setFieldValue('customerType', {
        label: convertToTitleCase(company.company.type),
        value: company.company.type
      });
      supplierFormik.setFieldValue(
        'category',
        company.company.CustomerCategory
      );
      supplierFormik.setFieldValue('paymentTerm', company.company.PaymentTerm);
      if (company.company.supportingDocuments?.length > 0) {
        supplierFormik.setFieldValue(
          'supportingDocuments',
          company.company.files
        );
      } else {
        supplierFormik.setFieldValue('supportingDocuments', []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitHandler = async () => {
    let payload = {
      ...supplierFormik.values,
      currency: 'PKR',
      type: supplierFormik.values.customerType?.value,
      relationType: 'SUPPLIER'
    };
    if (supplierFormik.values.supportingDocuments) {
      let fileIds = await upload(
        supplierFormik.values.supportingDocuments,
        'customer'
      );
      payload.supportingDocuments = [...fileIds];
    }
    const company = await addCompany(payload);
    if (addNode) await addNodes(company.company.id);
  };

  const { subdomain } = useContext(SharedContext);

  const orgCompanyTypes = Object.keys(supplierTypes).map((key) => ({
    label: supplierTypes[key],
    value: key
  }));

  const nodeTypeOptions = Object.keys(nodeTypes.SUPPLIER_NODES).map((key) => ({
    value: key,
    label: nodeTypes.SUPPLIER_NODES[key]
  }));

  const navigate = useNavigate();

  let currentNode = {
    ...nodeFormik.values,
    nodeType: nodeFormik.values.nodeType,
    locationLatLng: nodeFormik.values.latLng
  };

  const savedNodes = nodeFormik.values.savedNodes;

  const entity = {
    name: supplierFormik.values.name,
    address: supplierFormik.values.billingAddress,
    code: supplierFormik.values.code,
    type: supplierFormik.values.customerType?.label,
    label: 'Supplier',
    entityType: 'SUPPLIER'
  };

  const supplierValidation = async () => {
    await supplierFormik.setFieldTouched('name', true);
    await supplierFormik.setFieldTouched('customerType', true);
    await supplierFormik.setFieldTouched('billingAddress', true);
    await supplierFormik.setFieldTouched('email', true);
    await supplierFormik.setFieldTouched('phone', true);
    const errs = await supplierFormik.validateForm();
    return errs;
  };

  const updateCompany = async () => {
    let payload = {
      ...supplierFormik.values,
      currency: 'PKR',
      type: supplierFormik.values.customerType?.value
    };
    if (supplierFormik.values.supportingDocuments) {
      let alreadyUploaded = supplierFormik.values.supportingDocuments?.filter(
        (d) => d.id
      );
      let newDocs = supplierFormik.values.supportingDocuments?.filter(
        (d) => !d.id
      );
      let fileIds = [];
      if (newDocs?.length > 0) {
        fileIds = await upload(newDocs, 'customer');
      }
      payload.supportingDocuments = [
        ...alreadyUploaded.map((d) => d.id),
        ...fileIds
      ];
    }
    setAPILoader(true);
    try {
      await API.put(`companies/${id}`, payload);
      toaster('success', 'Supplier has been updated.');
      navigate('/administration/supply-chain-network');
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const getCustomerNodes = async (search, companyId) => {
    try {
      let response = {};
      response = await API.get(`nodes`, {
        params: {
          page: nodePage,
          companyId: companyId || null,
          limit: 5,
          search: search
        }
      });
      setNodes(response.data);
      setNodePages(response.pages);
    } catch (err) {
      onError(err);
    } finally {
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (id) {
      getSupplier(id);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getCustomerNodes(search, id);
    }
  }, [id, search, nodePage]);

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <form>
          <FixedLayout
            header={
              <>
                <div className="flex justify-between w-full items-center">
                  <div className="flex gap-2">
                    {addNode && (
                      <div className="flex items-center">
                        <ChevronLeft
                          onClick={() => setAddNode(false)}
                          className="cursor-pointer"
                        />
                      </div>
                    )}
                    <p className="font-bold text-2xl">
                      {addNode
                        ? `Create ${
                            savedNodes?.length > 0
                              ? savedNodes.length +
                                1 +
                                getSuffix(savedNodes.length + 1)
                              : ''
                          } node for ${supplierFormik.values.name}`
                        : edit
                        ? 'Update Supplier'
                        : 'Create Supplier'}
                    </p>
                  </div>
                  <div>
                    <CloseOutlined
                      onClick={() => {
                        navigate('/administration/supply-chain-network');
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </>
            }
            content={
              <div className="m-auto w-[60%] mt-10">
                {!addNode ? (
                  <>
                    <CustomerForm
                      formik={supplierFormik}
                      customerTypes={orgCompanyTypes}
                      isSupplier={true}
                    />
                    <NodeList
                      nodes={nodes}
                      refetch={() => getCustomerNodes(search, id)}
                      search={search}
                      setSearch={setSearch}
                      customer={{
                        name: supplierFormik.values.name,
                        id,
                        value: id,
                        nodeClass: 'EXTERNAL',
                        entityType: 'Supplier',
                        type:
                          supplierFormik.values?.customerType &&
                          convertToTitleCase(
                            supplierFormik.values.customerType.value
                          )
                      }}
                      pages={nodePages}
                      setPage={setNodePage}
                      page={nodePage}
                    />
                  </>
                ) : (
                  <NodeForm
                    formik={nodeFormik}
                    nodeTypes={nodeTypeOptions}
                    users={users}
                    isEntity={false}
                    search={search}
                    setSearch={setSearch}
                    entity={entity}
                  />
                )}
                <Popup
                  open={summaryView}
                  setOpen={setSummaryView}
                  title={
                    activeIndex || activeIndex == 0
                      ? 'Edit this Node'
                      : 'Create This Supplier?'
                  }
                  content={
                    summaryView &&
                    (activeIndex || activeIndex == 0 ? (
                      <NodeForm
                        formik={editNodeFormik}
                        nodeTypes={nodeTypeOptions}
                        users={users}
                        isEntity={false}
                        edit={true}
                        search={search}
                        setSearch={setSearch}
                      />
                    ) : (
                      <NodeSummaryView
                        nodes={addNode ? [...savedNodes, currentNode] : []}
                        entity={entity}
                        onEdit={(id) => {
                          setActiveIndex(id);
                          if (id == [...savedNodes, currentNode].length - 1)
                            editNodeFormik.setValues({
                              ...nodeFormik.values
                            });
                          else
                            editNodeFormik.setValues({
                              ...savedNodes[id]
                            });
                        }}
                      />
                    ))
                  }
                  onClose={() => {
                    setSummaryView(false);
                    setActiveIndex(null);
                  }}
                  dialogContentClasses="w-[700px]"
                  actions={
                    activeIndex || activeIndex == 0 ? (
                      <div className="flex">
                        <Button
                          label="Cancel"
                          onClick={() => setActiveIndex(null)}
                          variant="tertiary"
                        />
                        <Button
                          label="Update Node"
                          onClick={async () => {
                            if (
                              activeIndex ==
                              [...savedNodes, currentNode].length - 1
                            ) {
                              nodeFormik.setValues({
                                ...editNodeFormik.values,
                                savedNodes
                              });
                            } else
                              nodeFormik.setFieldValue(
                                `savedNodes[${activeIndex}]`,
                                { ...editNodeFormik.values }
                              );
                            setActiveIndex(null);
                          }}
                          variant="primary"
                          disabled={!editNodeFormik.isValid}
                        />
                      </div>
                    ) : (
                      <div className="flex gap-2">
                        <Button
                          label="Cancel"
                          onClick={() => setSummaryView(false)}
                          variant="tertiary"
                        />
                        <Button
                          label="Create Supplier"
                          onClick={async () => {
                            await submitHandler(nodeFormik.values);
                            setSummaryView(false);
                            navigate('/administration/supply-chain-network');
                          }}
                          variant="primary"
                        />
                      </div>
                    )
                  }
                />
              </div>
            }
            footer={
              <div className="flex justify-between w-full">
                <Button
                  label="Cancel"
                  variant="tertiary"
                  onClick={() =>
                    navigate('/administration/supply-chain-network')
                  }
                />

                {edit ? (
                  <Button
                    label="Update Supplier"
                    variant="primary"
                    onClick={async () => {
                      await updateCompany();
                      navigate('/administration/supply-chain-network');
                    }}
                    disabled={!supplierFormik.isValid}
                  />
                ) : (
                  <div className="flex justify-between mr-4 gap-4">
                    <Button
                      label={
                        !addNode
                          ? 'Create Supplier & Add Node'
                          : 'Save & Add Another Node'
                      }
                      variant="secondary"
                      disabled={
                        !addNode ? !supplierFormik.isValid : !nodeFormik.isValid
                      }
                      onClick={async () => {
                        if (!addNode) {
                          const errs = await supplierValidation();
                          if (Object.keys(errs).length == 0) setAddNode(true);
                        } else {
                          const errs = await nodeValidation(nodeFormik);
                          if (Object.keys(errs).length == 0) {
                            let payload = {
                              ...nodeFormik.values,
                              nodeType: nodeFormik.values.nodeType,
                              locationLatLng: nodeFormik.values.latLng
                            };
                            nodeFormik.setValues({
                              ...nodeInitialValues,
                              savedNodes: [...savedNodes, payload]
                            });
                            setSearch('');
                            await resetNodeValidation(nodeFormik);
                          }
                        }
                      }}
                    />
                    <Button
                      label={
                        !addNode ? 'Create Supplier' : 'Create Supplier & Node'
                      }
                      variant="primary"
                      disabled={
                        !addNode ? !supplierFormik.isValid : !nodeFormik.isValid
                      }
                      onClick={async () => {
                        if (!addNode) {
                          const errs = await supplierValidation();
                          if (Object.keys(errs).length == 0) {
                            setSummaryView(true);
                          }
                        } else {
                          const errs = await nodeValidation(nodeFormik);
                          if (Object.keys(errs).length == 0)
                            setSummaryView(true);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            }
          />
        </form>
      </div>
    </Modal>
  );
}

export default AddSupplier;

import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import owareLogo from '../../../assets/icons/O360DarkLogo.svg';
import {
  dividerDateFormatForFilter,
  SharedContext
} from '../../../utils/common';
import PickingDetailTable from './PrintPickingDetailTable';
import Print from '@mui/icons-material/PrintOutlined';
import { Paragraph } from '../../../core-components/atoms/Text';
import Button from '../../../core-components/atoms/Button';
import PrintStyles from '../../../components/printGrnGdn/PrintStyles';
import clsx from 'clsx';

const PrintTask = ({ data, isIcon }) => {
  const classes = PrintStyles();
  const componentRef = useRef();
  const [selectedPicking, setSelectedPicking] = useState(null);
  const [companyNames, setCompanyNames] = useState(null);

  const { darkOrganizationLogoFile } = useContext(SharedContext);

  const getPickingDetails = async () => {
    try {
      const PickingDetail = await API.get(`tasks/${data.customId}`);
      setSelectedPicking(PickingDetail.warehouseTask);
      const res = [
        ...new Set(
          PickingDetail?.warehouseTask?.order?.map(
            (order) => order.Company.name
          )
        )
      ];
      setCompanyNames(res);
    } catch (err) {
      onError(err);
    }
  };

  const handlePrint = async () => {
    await getPickingDetails();
    handlePrintTaskDetails();
  };
  const handlePrintTaskDetails = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <Box
        display="none"
        className={clsx(classes.pageContainer, classes.root)}
        displayPrint="block"
        ref={componentRef}
      >
        <Box display="none" displayPrint="block" className={classes.padding5mm}>
          <div>
            {darkOrganizationLogoFile ? (
              <img
                src={darkOrganizationLogoFile.location}
                alt="Logo"
                style={{
                  width: '100px',
                  height: 'auto',
                  maxHeight: '100px',
                  marginTop: '20px'
                }}
              />
            ) : (
              <img
                style={{
                  width: 'auto',
                  height: '18px',
                  marginLeft: '8px',
                  marginTop: '20px'
                }}
                src={owareLogo}
              />
            )}
          </div>
          <div style={{ marginTop: 16, marginLeft: 8 }}>
            <Typography variant="h2" className={classes.pageSubHeading}>
              Goods Picking Note
            </Typography>
          </div>
        </Box>
        <Box
          display="none"
          displayPrint="block"
          className={classes.borderBottom}
        >
          <Grid container spacing={2}>
            <Grid item md={2} xs={2}>
              <Grid item xs={12} className={classes.label}>
                PICKING ID
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {selectedPicking?.customId}
              </Grid>
            </Grid>
            <Grid item md={2} xs={2}>
              <Grid item xs={12} className={classes.label}>
                SUPPORTING DOC ID
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {selectedPicking?.order
                  ?.map((order) => order.referenceId)
                  ?.join(', ')}
              </Grid>
            </Grid>
            <Grid item md={2} xs={2}>
              <Grid item xs={12} className={classes.label}>
                WAREHOUSE
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {selectedPicking?.Warehouse?.businessWarehouseCode}
              </Grid>
            </Grid>
            <Grid item md={3} xs={3}>
              <Grid item xs={12} className={classes.label}>
                CUSTOMER
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {companyNames
                  ? companyNames?.length > 1
                    ? 'Multiple Companies'
                    : companyNames[0]
                  : '-'}
              </Grid>
            </Grid>
            <Grid item md={3} xs={3}>
              <Grid item xs={12} className={classes.label}>
                PICKING QTY WEIGHT
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {selectedPicking?.totalEstimatedWeight + ' Kgs'}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid item xs={12} className={classes.label}>
                CREATED BY
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {selectedPicking?.CreatedBy?.firstName +
                  ' ' +
                  selectedPicking?.CreatedBy?.lastName}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid item xs={12} className={classes.label}>
                ASSIGNED TO
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {selectedPicking?.AssignedTo?.firstName &&
                selectedPicking?.AssignedTo?.lastName
                  ? selectedPicking?.AssignedTo?.firstName +
                    ' ' +
                    selectedPicking?.AssignedTo?.lastName
                  : ''}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid item xs={12} className={classes.label}>
                PICKING DATE
              </Grid>
              <Grid item xs={12} className={classes.basicFieldValue}>
                {dividerDateFormatForFilter(selectedPicking?.scheduledDate)}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12} className={classes.label}>
                SIGNATURE
              </Grid>
              <div style={{ borderBottom: '1px solid black', marginTop: 20 }} />
            </Grid>
          </Grid>
        </Box>
        <Divider className={classes.dividerProperties} />
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <PickingDetailTable values={selectedPicking} />
        </Grid>
      </Box>
      {isIcon ? (
        <div className="flex gap-3 items-center" onClick={handlePrint}>
          <Print color={'disabled'} />
          <Paragraph>Print Task</Paragraph>
        </div>
      ) : (
        <Button
          color="default"
          variant="contained"
          label={'Print'}
          onClick={handlePrint}
          icon={<Print fontSize={'small'} className={'mr-2'} />}
        />
      )}
    </>
  );
};

export default PrintTask;

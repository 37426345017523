/*eslint-disable*/
export const NodeType = {
  ALL_NODES: '',
  WAREHOUSE: 'WAREHOUSE',
  PLANT: 'PLANT',
  STORE: 'DARKSTORE',
  OTHER: 'OTHER',
  MULTI: 'Multi'
};

export const NodeTypeOptions = {
  ALL_NODES: 'All Nodes',
  WAREHOUSE: 'Warehouses',
  PLANT: 'Plants',
  STORE: 'Dark Stores',
  OTHER: 'Others'
};

export const ActivityLevel = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low'
};

export const ShipmentsViewOptions = {
  SHOW_ALL: 'Show All',
  WAREHOUSE: 'Warehouse',
  SHIPMENTS: 'Shipments',
  DISTRIBUTERS: 'Distributers',
  RETAILERS: 'Retailers',
  DARK_STORES: 'Dark Stores',
  OTHER: 'Other'
};

export const ShipmentStatus = {
  ALL: 'All',
  CREATED: 'Created',
  IN_TRANSIT: 'In Transit',
  RECEIVED: 'Received',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  DOCK_ASSIGNED: 'Dock Assigned',
  CARRIER_ASSIGNED: 'Carrier Assigned',
  AWAITING_CARRIER: 'Awaiting Carrier'
};

export const TopBarMetricTypes = {
  ORDERS: 'ORDERS',
  SHIPMENTS: 'SHIPMENTS',
  SALES_REVENUE: 'SALES_REVENUE'
};

export const TopBarMetric = {
  ORDERS: 'Orders',
  SHIPMENTS: 'Shipments',
  SALES_REVENUE: 'Sales Revenue'
};

export const TopBarMetricCondensed = {
  ORDERS: 'Orders',
  SHIPMENTS: 'Shipments',
  SALES_REVENUE: 'Sales Revenue'
};

export const MetricStatus = {
  UP: 'UP',
  DOWN: 'DOWN',
  NONE: 'NONE'
};

export const SymbolType = {
  PERCENTAGE: '%',
  RUPPEE: 'Rs',
  NONE: ''
};

export const ShipmentsDirection = {
  INWARD: 'INWARD',
  OUTBOUND: 'OUTBOUND',
  BOTH: 'BOTH',
  NONE: 'NONE'
};

export const DateFilterOptions = {
  CHOOSE: 'Choose Interval',
  NEXT_SEVEN: 'Next 7 Days',
  NEXT_THIRTY: 'Next 30 Days',
  CUSTOM: 'Custom'
};

export const ExceptionImpactTypeMap = {
  'Stock Condition': 'Damages',
  'Late Shipment': 'Time Overdue',
  'GDN Reversed': 'Inventory',
  'GRN Reversed': 'Inventory',
  'Stock Adjustment': 'Inventory'
};

export const ShipmentFilterType = {
  NODE_TYPE: 'NODE_TYPE',
  STATUS: 'STATUS',
  WAREHOUSE: 'WAREHOUSE'
};

export const StatusFilterOptions = {
  SHOW_ALL: 'Show All',
  CREATED: 'Ready for Pickup',
  IN_TRANSIT: 'In Transit'
};

export const CalendarStatusDetails = {
  IN_TRANSIT: {
    label: 'In Transit',
    key: 'IN_TRANSIT',
    class: 'bg-navyBlue',
    icon: 'Light',
    color: 'text-white'
  },
  COMPLETED: {
    label: 'Completed',
    key: 'COMPLETED',
    class: 'bg-normalGreen',
    icon: 'Light',
    color: 'text-white'
  },
  ARRIVED: {
    label: 'Arrived',
    key: 'ARRIVED',
    class: 'bg-normalGreen',
    icon: 'Light',
    color: 'text-white'
  },
  READY_FOR_PICKUP: {
    label: 'Ready For Pickup',
    key: 'READY_FOR_PICKUP',
    class: 'bg-dark100 border-dark400 border-[1px]',
    icon: 'Dark',
    color: 'text-primaryBlue'
  },
  CREATED: {
    label: 'Ready For Pickup',
    key: 'CREATED',
    class: 'bg-dark100 border-dark400 border-[1px]',
    icon: 'Dark',
    color: 'text-primaryBlue'
  },
  CANCELLED: {
    label: 'Cancelled',
    key: 'CANCELLED',
    class: 'bg-fadedRed',
    icon: 'Light',
    color: 'text-white'
  }
};

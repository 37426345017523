import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { Box, Grid } from '@mui/material';
import addIcon from '../../../../assets/icons/addCard.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DEBOUNCE_CONST } from '../../../../Config';
import DockListComponent from '../components/DockListComponent';
import { makeStyles, Tooltip } from '@material-ui/core';
import AddDockView from '../components/AddDocksView';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

const DocksView = ({ warehouse, searchValue }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const limit = 10;
  const [docks, setDocks] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const toolTipClasses = useStylesBootstrap();
  const [open, setOpen] = useState(null);
  const [selectedDock, setSelectedDock] = useState();

  const getWarehouseDocks = async (page, searchValue, oldData = []) => {
    try {
      const cols = ['name', 'type'];
      const res = await API.get(`warehouses/${warehouse?.id}/docks`, {
        params: {
          page: page,
          limit: limit,
          search: searchValue,
          columns: cols
        }
      });
      const newArr = res?.data;
      setDocks([...oldData, ...newArr]);
      setPageCount(res?.pages);
    } catch (error) {
      onError(error);
    }
  };

  const getDelayedWarehouseDocks = useCallback(
    debounce(
      async (page, searchValue, oldData) => {
        getWarehouseDocks(page, searchValue, oldData);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedWarehouseDocks(page, searchValue, []);
  }, [searchValue]);

  const handleClick = (event) => {
    setOpen(open ? null : event.currentTarget);
  };

  return searchValue && docks.length <= 0 ? (
    <></>
  ) : (
    <Grid
      item
      flex={1}
      className="bg-dark200 h-full rounded"
      style={{ boxSizing: 'border-box' }}
    >
      <Box>
        <AddDockView
          handleClose={() => {
            setOpen(null);
          }}
          dock={selectedDock}
          warehouseId={warehouse?.id}
          anchorEl={open}
          getWarehouseDocks={getWarehouseDocks}
        />
        <div className="mx-4 mt-4 mb-2 items-center">
          <div className="flex justify-between">
            <p>DOCKS</p>
            {isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) && (
              <div className="flex">
                <Tooltip
                  arrow
                  title="Add Docks"
                  aria-label="add"
                  placement="top"
                  classes={toolTipClasses}
                >
                  <button
                    className="flex"
                    onClick={(e) => {
                      handleClick(e);
                      setSelectedDock();
                    }}
                  >
                    <img src={addIcon} alt="add-icon" />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div
          id={'scrollableDivDocks'}
          className="items-center overflow-auto h-[60vh] no-scrollbar"
        >
          <InfiniteScroll
            dataLength={docks?.length} //This is important field to render the next data
            next={() => {
              setPage(page + 1);
              getDelayedWarehouseDocks(page + 1, searchValue, docks);
            }}
            hasMore={page < pageCount}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>Loading</b>
              </p>
            }
            refreshFunction={() => {
              setPage(1);
              setDocks([]);
              getDelayedWarehouseDocks(1, searchValue, []);
            }}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8595; Pull down to refresh
              </h3>
            }
            scrollableTarget="scrollableDivDocks"
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {docks?.length > 0 &&
              docks.map((dock, id) => {
                return (
                  <DockListComponent
                    data={dock}
                    searchValue={searchValue}
                    warehouse={warehouse}
                    key={id}
                    getWarehouseDocks={getWarehouseDocks}
                    onClick={(event) => {
                      setSelectedDock(dock);
                      if (
                        isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)
                      ) {
                        handleClick(event);
                      }
                    }}
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </Box>
    </Grid>
  );
};

export default DocksView;

/*eslint-disable*/
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Grid, Tooltip, Button } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';

import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import {
  SharedContext,
  bulkDateFormator,
  checkDates,
  exportToCSV
} from '../../utils/common';
import { DEBOUNCE_CONST } from '../../Config';
import BulkUploadScreen from './BulkUploadScreen';
import BulkValidationScreen from './BulkValidationScreen';
import TemplateLoader from '../../atomicComponents/TemplateLoader';
import useStyles from './makeStyles';
import { ORGANIZATION_TYPES, OrderTypeMapping } from '../../constants/index';

const AddBulkOrder = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    currentPageTitle,
    setCurrentPageTitle,
    organizationType,
    setAPILoader
  } = useContext(SharedContext);
  const IS_MANUFACTURER = organizationType === ORGANIZATION_TYPES.MANUFACTURER;
  const [openForm, setOpenForm] = useState({
    tab1: true,
    tab2: false,
    tab3: false
  });
  const [tabClasses, setClasses] = useState({
    tab1: 'selected-tab',
    tab2: '',
    tab3: ''
  });
  const [moveTypes, setMoveTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [orderPayload, setOrderPayload] = useState([]);
  const [verifiedOrders, setVerfiedOrders] = useState([]);
  const [ordersWithIssues, setOrdersWithIssues] = useState([]);
  const [moveType, setMoveType] = useState(null);
  const [companyId, setCompanyID] = useState(null);
  const [company, setCompany] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [correctUploadFlag, setCorrectUploadFlag] = useState(false);
  const [dataloaded, setDataLoaded] = useState(true);
  const [correctOrders, setCorrectOrders] = useState(0);
  const [inCorrectOrders, setInCorrectOrders] = useState(0);

  const header = [
    'Pickup Date',
    'Dropoff Date',
    'Pickup Location',
    'Dropoff Location',
    'Supporting Document',
    'SKU Code/Description',
    'Quantity',
    'Logistics By',
    'Palletized/Non-Palletized',
    'Vehicle Type (make-model (type))',
    'Weight',
    'Addditional Info',
    'Record Level',
    'Order Level',
    'Payment Terms'
  ];

  useEffect(() => {
    getMoveTypes();
  }, []);

  useEffect(() => {
    let title = 'Bulk Order Upload';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    setVerifyFlag(false);
  }, [moveType, companyId, selectedFile]);

  useEffect(() => {
    if (verifiedOrders?.length || verifyFlag) {
      changeForm('tab2');
    }
  }, [verifiedOrders]);

  useEffect(() => {
    if (correctUploadFlag) {
      changeForm('tab3');
    }
  }, [correctUploadFlag]);

  useEffect(() => {
    getCompaniesCallback({ searchCompanyValue });
  }, [searchCompanyValue]);

  const getCompaniesCallback = useCallback(
    debounce(({ searchCompanyValue }) => {
      getCompanies({ searchKeyword: searchCompanyValue });
    }, DEBOUNCE_CONST),
    []
  );

  const getCompanies = async ({ searchKeyword }) => {
    let colVal = { isActive: 1 };
    const response = await API.get(`companies`, {
      params: { search: searchKeyword, filters: { colVal } }
    });
    setCompanies(response.data.rows);
  };

  const validateDatesAndData = (orders) => {
    try {
      const newOrders = orders.map((order) => {
        const dropOfDate = order['Dropoff Date']
          .toString()
          .replaceAll(/\D/g, '/');
        const pickUpDate = order['Pickup Date']
          .toString()
          .replaceAll(/\D/g, '/');

        if (!checkDates(dropOfDate) || !checkDates(pickUpDate)) {
          throw new Error(
            'Dates are not following a consistent format, all dates should either be DD/MM/YYYY or MM/DD/YYYY'
          );
        }

        const newOrder = {
          pickUpDate: bulkDateFormator(pickUpDate),
          dropOffDate: bulkDateFormator(dropOfDate),
          pickUpLocation: order['Pickup Location Code/Name'],
          dropOffLocation: order['Dropoff Location Code/Name'],
          moveType: order['Order Type'],
          referenceID: order['Supporting Document ID'],
          product: order['SKU Code/Description'],
          company: order['Customer ID/Name'],
          calculatedWeight: order['Weight'] ? order['Weight'] : 0,
          costPrice: order['Purchase Price'],
          sellingPrice: order['Selling Price'],
          tax: order['Tax'],
          // uom: order['UOM'],
          quantity: +order['Quantity'],
          logisticBy: order['Logistics By']
            ? order['Logistics By']
            : 'Customer',
          pallet: order['Palletized/Non-Palletized']
            ? order['Palletized/Non-Palletized']
            : 'Non-Palletized',
          weight: order['Weight'] ? order['Weight'] : 0,
          additionalInfo: order['Addditional Info'],
          paymentTermTitle: order['Payment Terms']
        };
        return newOrder;
      });
      setOrderPayload(newOrders);
    } catch (error) {
      onError(error);
    }
  };

  const bulkUpload = (orders) => {
    if (orders?.length) {
      validateDatesAndData(orders);
    }
  };

  const getMoveTypes = async () => {
    try {
      const response = await API.get(`orders/movetype`);

      if (!response?.data) {
        return;
      }

      const moves = Object.keys(response.data)
        .filter((move) => move !== 'SALES_RETURN')
        .map((move) => {
          return {
            id: move,
            type: OrderTypeMapping[move] || response.data[move]
          };
        });
      setMoveTypes(moves);
    } catch (error) {
      onError(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setDataLoaded(false);
      setAPILoader(true);
      const payload = {
        orders: [...orderPayload]
      };

      const response = await API.post(`orders/bulk-upload-validation`, payload);
      if (response.orders.length) {
        setVerifyFlag(true);
        setVerfiedOrders(response.orders);
      }
      setCorrectOrders(response.correctOrders);
      setInCorrectOrders(response.inCorrectOrders);

      setDataLoaded(true);
    } catch (error) {
      setDataLoaded(true);
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const handleCreation = async () => {
    try {
      setDataLoaded(false);
      setAPILoader(true);
      const correctOrders = [];
      const faultyOrders = [];

      verifiedOrders.forEach((order) => {
        delete order.uom;
        if (order.orderLevel != 'Correct') {
          faultyOrders.push(order);
        } else {
          correctOrders.push(order);
        }
      });

      setOrdersWithIssues(faultyOrders);

      if (!correctOrders.length) {
        throw new Error('No Correct orders');
      }

      const payload = {
        moveType,
        companyId,
        orders: [...correctOrders]
      };

      await API.post(`orders/bulk-upload`, payload);
      setCorrectUploadFlag(true);
      setDataLoaded(true);
    } catch (error) {
      setDataLoaded(true);
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const extractFaultyData = () => {
    setDataLoaded(false);
    if (
      ordersWithIssues?.length &&
      verifyFlag &&
      moveType &&
      companyId &&
      selectedFile
    ) {
      let title = `Faulty Orders`;
      let arr = [...ordersWithIssues];
      arr.unshift(header);
      exportToCSV(null, arr, title);
    }
    setDataLoaded(true);
  };

  const exportToExcel = () => {
    try {
      if (verifyFlag && selectedFile) {
        let title = `Verified Orders`;
        let arr = [...verifiedOrders];
        arr.unshift(header);
        exportToCSV(null, arr, title);
      }
    } catch (error) {
      onError(error);
    }
  };

  const changeForm = (form) => {
    if (verifyFlag && selectedFile) {
      let formstate = { ...openForm };
      let classnames = { ...tabClasses };
      Object.keys(formstate).forEach((key) => {
        if (key === form) {
          formstate[key] = true;
          classnames[key] = 'selected-tab';
        } else {
          formstate[key] = false;
          classnames[key] = '';
        }
      });
      setOpenForm(formstate);
      setClasses(classnames);
    }
  };

  const nextButtons = () => {
    if (!verifyFlag) {
      return (
        <Button
          disabled={selectedFile ? false : true}
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            handleSubmit();
          }}
          fullWidth
        >
          VERIFY ORDERS
        </Button>
      );
    }

    if (verifyFlag && !correctUploadFlag) {
      return (
        <Button
          disabled={selectedFile ? false : true}
          variant="contained"
          className={classes.createButton}
          onClick={() => {
            handleCreation();
          }}
          fullWidth
        >
          UPLOAD CORRECT ORDERS
        </Button>
      );
    }

    return (
      <Button
        disabled={moveType && companyId && selectedFile ? false : true}
        variant="contained"
        className={classes.createButton}
        onClick={() => {
          extractFaultyData();
        }}
        fullWidth
      >
        EXTRACT ORDERS WITH ISSUES
      </Button>
    );
  };

  return (
    <Grid className={classes.gridContainer}>
      <div className={!dataloaded || 'display-none'}>
        <TemplateLoader />
      </div>
      <div className="position-relative mt-10">
        <div className="border">
          <Tooltip title="Bulk Order Upload">
            <div
              className={`order-form-tabs first-tab ${tabClasses.tab1}`}
              onClick={() => changeForm('tab1')}
            ></div>
          </Tooltip>
          <Tooltip title="Validation Screen">
            <div
              className={`order-form-tabs second-tab ${tabClasses.tab2}`}
              onClick={() => changeForm('tab2')}
            ></div>
          </Tooltip>
          <Tooltip title="Summary of Upload">
            <div
              className={`order-form-tabs third-tab ${tabClasses.tab3}`}
              onClick={() => changeForm('tab3')}
            ></div>
          </Tooltip>
        </div>
      </div>

      <div className={`${classes.mainContent} mt-10`}>
        {openForm.tab1 && (
          <BulkUploadScreen
            setMoveType={setMoveType}
            setCompanyID={setCompanyID}
            searchCompanyState={{ searchCompanyValue, setSearchCompanyValue }}
            bulkUpload={bulkUpload}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            moveTypes={moveTypes}
            companies={companies}
            moveType={moveType}
            companyId={companyId}
            company={company}
            setCompany={setCompany}
          />
        )}
        {verifyFlag && selectedFile && openForm.tab2 && (
          <>
            <BulkValidationScreen orders={verifiedOrders} />
            <Grid container item xs={12} alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <div className={classes.exportButton}>
                  <button className="exportCustomBtn" onClick={exportToExcel}>
                    Export
                  </button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
        {correctUploadFlag && verifyFlag && selectedFile && openForm.tab3 && (
          <>
            <h3 className={classes.margin20tb}>
              {correctOrders} Orders have been created
            </h3>
            <h4 className={classes.margin20tb}>
              Remaining Data with issues
              <span className={classes.faulty}>{`(${inCorrectOrders})`}</span>
            </h4>
            <BulkValidationScreen tableHeight={680} orders={ordersWithIssues} />
          </>
        )}
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={2}
          className="bulkBtns"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.paddingTop12}
          >
            <Button
              variant="contained"
              className={classes.cancelButton}
              onClick={() => navigate('/routing/order-management')}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {nextButtons()}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default AddBulkOrder;

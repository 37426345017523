/*eslint-disable*/
import Popup from '../../../../core-components/atoms/Popup';
import Button from '../../../../core-components/atoms/Button';
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { SharedContext } from '../../../../utils/common';
import CarrierForm from './carrierForm';
import { upload } from '../../../../utils/upload';
import AddCarrierValidation from './validationSchema';
import AddCarrierValidationWms from './validationSchemaWms';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';

const AddcarrierPopup = ({
  open,
  setOpen,
  organizationType,
  selectedcarrier,
  setSelectedCarrier,
  shipmentOnlyScreen,
  refetch
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { setAPILoader } = useContext(SharedContext);

  const addSubmitHandler = async (values) => {
    const payload = {
      name: values.name,
      relationType: 'VENDOR',
      contactEmail: values.email,
      contactName: values.contactName,
      notes: values.notes,
      ntn: values.ntn,
      isActive: true
    };

    let fields = ['name', 'email', 'contactName', 'ntn'];

    let joinedType = '';
    if (values.type.Logistics) {
      joinedType += 'Logistics,';
      if (!values.type.Land && !values.type.Sea && !values.type.Air) {
        toaster(
          'error',
          'Please select one of Land, Sea or Air type for Logistics carrier'
        );
        return;
      }
    }
    if (values.type.Warehousing) {
      joinedType += 'Warehousing,';
    }
    if (values.type.Technology) {
      joinedType += 'Technology,';
    }
    if (values.type.Land) {
      joinedType += 'Land,';
    }
    if (values.type.Sea) {
      joinedType += 'Sea,';
    }
    if (values.type.Air) {
      joinedType += 'Air,';
    }

    if (
      !values.type.Logistics &&
      (values.type.Land || values.type.Sea || values.type.Air)
    ) {
      toaster(
        'error',
        'Please select Logistics type for Land, Sea or Air carrier types'
      );
      return;
    }

    payload.type = joinedType;

    const err = await formik.validateForm(values);

    for (let i = 0; i < fields.length; i++) {
      formik.setFieldTouched(fields[i], true);
    }

    if (Object.keys(err).length > 0) {
      return;
    }

    try {
      setAPILoader(true);
      if (!selectedcarrier) await API.post(`companies`, payload);
      else await API.put(`companies/${selectedcarrier.id}`, payload);
      toaster(
        'success',
        !selectedcarrier
          ? 'Carrier has been created.'
          : 'Carrier updated successfully.'
      );
      await refetch();
      setOpen(false);
      formik.resetForm();
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const handleClose = async () => {
    formik.resetForm();
    formik.setValues({});
    setSelectedCarrier && setSelectedCarrier(null);
    setOpen(false);
  };

  let valSchema;

  const initialValues = {};

  const formik = useFormik({
    validationSchema: isFeatureEnabled(FLAGS.TMS)
      ? AddCarrierValidation
      : AddCarrierValidationWms,
    initialValues,
    onSubmit: addSubmitHandler,
    validateOnChange: true
  });

  useEffect(() => {
    if (selectedcarrier && selectedcarrier?.id) {
      let type =
        (selectedcarrier.type?.length > 0 && selectedcarrier.type.split(',')) ||
        [];

      type = {
        Logistics: type.indexOf('Logistics') != -1,
        Warehousing: type.indexOf('Warehousing') != -1,
        Technology: type.indexOf('Technology') !== -1,
        Land: type.indexOf('Land') != -1,
        Sea: type.indexOf('Sea') != -1,
        Air: type.indexOf('Air') !== -1
      };

      formik.setValues({
        carrierId: selectedcarrier?.Vendor?.id,
        carrier: selectedcarrier?.Vendor,
        name: selectedcarrier?.name,
        email: selectedcarrier?.contactEmail,
        contactName: selectedcarrier?.contactName,
        notes: selectedcarrier?.notes,
        ntn: selectedcarrier?.ntn,
        type
      });
    }
  }, [selectedcarrier]);

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      content={
        <CarrierForm
          open
          openPopup={open}
          organizationType={organizationType}
          formik={formik}
          edit={!!selectedcarrier}
          shipmentOnlyScreen={shipmentOnlyScreen}
        />
      }
      title={selectedcarrier ? `Edit Carrier` : `Add Carrier`}
      headerClass="px-8 pt-8 pb-4"
      titleClass="text-primaryBlue text-lg font-semibold"
      noPadding={true}
      closeIconSize={24}
      paperProps={{
        style: {
          borderRadius: '8px',
          height: '793px',
          maxWidth: '780px',
          width: '780px'
        }
      }}
      dialogContentClasses={'dark-scrollbar'}
      onClose={() => {
        formik.resetForm();
        setSelectedCarrier && setSelectedCarrier(null);
      }}
      actions={
        <div className="flex justify-end gap-2 pt-3 pb-6 px-6">
          <Button label="Cancel" onClick={handleClose} variant="text" />
          <Button
            label={selectedcarrier ? 'Update Carrier' : 'Add Carrier'}
            className="rounded"
            onClick={async () => {
              await addSubmitHandler(formik.values);
            }}
            variant="primary"
            size="large"
            disabled={
              Object.keys(formik.errors).length > 0 ||
              !formik.values.name ||
              !formik.values.type
            }
          />
        </div>
      }
    />
  );
};

export default AddcarrierPopup;

import { useState } from 'react';

export const useCapcha = () => {
  const [token, setToken] = useState();
  const CAPCHA_SITE_KEY = process.env.REACT_APP_RECAPCHA_SITE_KEY;

  const onChange = (value) => {
    setToken(value);
  };

  const validate = () => {
    return Boolean(token);
  };

  return [validate, onChange, CAPCHA_SITE_KEY];
};

import React, { useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useNavigate } from 'react-router-dom';
import { GOOGLE_MAP_API_KEY } from '../constants';

const MulitpleMarkerMap = ({ google, markers, icon }) => {
  const navigate = useNavigate();
  const [locLatLng] = useState({
    lat: markers[0]?.latLng.lat,
    lng: markers[0]?.latLng.lng
  });
  const mapZoom = 8;

  return (
    <Map
      google={google}
      zoom={mapZoom}
      initialCenter={{ lat: locLatLng?.lat, lng: locLatLng?.lng }}
      center={{ lat: locLatLng?.lat, lng: locLatLng?.lng }}
      style={{ position: 'relative', height: '100%', width: '100%' }}
    >
      {markers?.map((marker) => (
        <Marker
          key={marker.id}
          name={marker.name}
          title={marker.markerTitle}
          position={marker.latLng}
          icon={
            icon && {
              url: icon,
              anchor: new google.maps.Point(17, 46),
              scaledSize: new google.maps.Size(50, 50)
            }
          }
          onClick={() => navigate(`/onexus/warehouse/${marker.id}`)}
        />
      ))}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY
})(MulitpleMarkerMap);

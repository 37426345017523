import { useState, useEffect, useContext } from 'react';
import { SharedContext } from '../utils/common';
import { onError } from '../libs/errorLib';
import API from '../libs/axios';

const TrialExpiryBanner = () => {
  const { organization } = useContext(SharedContext);
  const [showBanner, setShowBanner] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);

  const checkTrialSubscription = async () => {
    try {
      const trialSubscription = await API.get(
        `organizations/${organization.subdomain}/subscription/check-near-expiry`
      );

      setDaysLeft(trialSubscription.daysLeft);
      setShowBanner(!!trialSubscription.daysLeft);
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    if (!organization || !organization.selfCreated) return;

    checkTrialSubscription();
  }, [organization]);

  const bannerStyle = {
    position: 'relative',
    top: '50px',
    left: '25px',
    width: '100%',
    backgroundColor: '#f4f186',
    padding: '15px',
    textAlign: 'center',
    zIndex: '1000',
    fontSize: '20px'
  };

  const message = (
    <>
      Your trial will expire in <strong>{daysLeft}</strong> days.
      <br />
      Please take action to avoid any interruptions.
    </>
  );

  return (
    showBanner && (
      <div className="banner" style={bannerStyle}>
        <p>{message}</p>
      </div>
    )
  );
};

export default TrialExpiryBanner;

import React, { useEffect, useRef } from 'react';
import { Button, Popover, List, ListItem, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  makeStyles
} from '@material-ui/core';

function NestedMenu({
  options,
  getCompanies,
  setFilter,
  number,
  filters,
  getWarehouses
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const mainRef = useRef();
  const secondaryRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event, index) => {
    setSubMenuAnchorEl(event.currentTarget.parentNode);
    setActiveIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSearch('');
  };

  const useStyles = makeStyles(() => ({
    ListItem: {
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer',
      display: 'flex',
      gap: '8px'
    },
    popOver: {
      maxHeight: '350px',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      pointerEvents: 'none'
    },
    search: {
      margin: '1rem auto',
      width: '90%',
      boxSizing: 'border-box',
      display: 'block'
    },
    paper: {
      pointerEvents: 'auto',
      width: '210px'
    },
    subMenuPaper: {
      pointerEvents: 'auto'
    }
  }));

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  useEffect(() => {
    if (
      activeIndex &&
      (options[activeIndex].label === 'Customer' ||
        options[activeIndex].label === 'Vendor') &&
      search
    ) {
      getCompanies(search);
    } else if (
      activeIndex &&
      options[activeIndex].label === 'Warehouse' &&
      search
    ) {
      getWarehouses(search);
    } else {
      getCompanies();
      getWarehouses();
    }
  }, [search]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        secondaryRef.current &&
        !secondaryRef.current.contains(event.target)
      ) {
        handleClose();
        handleSubMenuClose();
      }

      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        !secondaryRef.current
      ) {
        handleClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Button
        onClick={handleClick}
        variant="outlined"
        style={{ textTransform: 'none', background: number > 0 && '#DFF1FF' }}
      >
        <FilterListIcon sx={{ marginRight: '5px' }} fontSize="small" />
        {number == 0 ? 'Filters' : `${number} filters applied`}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        ref={mainRef}
        style={{ pointerEvents: 'none' }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <List>
          {options.map((option, i) => (
            <ListItem
              key={i}
              className={classes.ListItem}
              onMouseEnter={(e) => handleSubMenuClick(e, i)}
            >
              <img src={option.icon} />
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Popover
        open={subMenuOpen}
        anchorEl={subMenuAnchorEl}
        onClose={handleSubMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className={classes.popOver}
        classes={{
          paper: classes.subMenuPaper
        }}
        PaperProps={{
          onMouseLeave: handleSubMenuClose
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        ref={secondaryRef}
      >
        <List style={{ width: '300px' }}>
          {activeIndex &&
          (options[activeIndex].label === 'Customer' ||
            options[activeIndex].label === 'Warehouse' ||
            options[activeIndex].label === 'Vendor') ? (
            <TextField
              label="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              className={classes.search}
              fullWidth
            />
          ) : null}
          {(activeIndex || activeIndex == 0) &&
            options[activeIndex]?.options.map((option, index) => (
              <ListItem
                key={index}
                className={classes.ListItem}
                onClick={() => {
                  let checked = filters?.[options[activeIndex].label].reduce(
                    (a, b) => a || b.value == option.value,
                    false
                  );
                  if (checked) {
                    setFilter(options[activeIndex].label, option, 'remove');
                  } else {
                    setFilter(options[activeIndex].label, option, 'add');
                  }
                }}
              >
                <Checkbox
                  className="text-primary"
                  onChange={(e) => {
                    e.stopPropagation();
                    if (e.target.checked) {
                      setFilter(options[activeIndex].label, option, 'add');
                    } else {
                      setFilter(options[activeIndex].label, option, 'remove');
                    }
                  }}
                  checked={filters?.[options[activeIndex].label].reduce(
                    (a, b) => a || b.value == option.value,
                    false
                  )}
                />
                <div className={option.icon} />
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
}

export default NestedMenu;

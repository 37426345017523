import { makeStyles, Paper, Popper } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AttachmentIcon from '../../../assets/icons/AttachmentIcon.svg';
import AttachmentBlueIcon from '../../../assets/icons/AttachmentActive.svg';
import ExpenseIcon from '../../../assets/icons/expense_icon.svg';
import ExpenseBlueIcon from '../../../assets/icons/ExpenseActive.svg';
import EmojiIcon from '../../../assets/icons/emoji_icon.svg';
import EmojiBlueIcon from '../../../assets/icons/emoji_icon_blue.svg';
import MentionIcon from '../../../assets/icons/MentionIcon.svg';
import MentionBlueIcon from '../../../assets/icons/MentionBlueIcon.svg';
import SendIcon from '../../../assets/icons/send_icon.svg';
import clsx from 'clsx';
import EmojiPicker from 'emoji-picker-react';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { ClickAwayListener } from '@mui/base';
import { Mention, MentionsInput } from 'react-mentions';
import { Paragraph, Title } from '../../../core-components/atoms/Text';
import { checkPermission, isOwareSuperAdmin } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import FLAGS from '../../../constants/featureFlags';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

const CommentInput = ({
  message,
  setMessage,
  files,
  setFiles,
  selectedFile,
  setSelectedFile,
  handleFileUpload,
  setSelectedUsers,
  postComment,
  setOpenExpense,
  postLoader
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const inputRef = useRef(null);
  const uploadRef = useRef(null);
  const { currentUser, subdomain } = useContext(SharedContext);

  const [page] = useState(1);
  const anchorRef = useRef(null);
  const [poperOpen, setPopperOpen] = React.useState(false);
  const [showEmoji, setShowEmoji] = useState(false);

  useEffect(() => {
    const files = selectedFile.filter((file) => !file?.id);
    if (files?.length > 0) {
      handleFileUpload(files);
      const fileMessage = files?.map((value) => `“${value?.name}”`).join('\n');
      setMessage(message + `\n${fileMessage}`);
    }
  }, [selectedFile]);

  const useStyles = makeStyles(() => ({
    popper: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      zIndex: 1300
    },
    paper: {
      pointerEvents: 'auto',
      width: '100%',
      border: 'none',
      borderRadius: '8px',
      marginTop: '2px',
      boxShadow:
        '0px 6.400000095367432px 14.399999618530273px 0px #00000021,0px 1.2000000476837158px 3.5999999046325684px 0px #0000001A',
      overflowY: 'auto',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& input': {
        fontSize: '12px !important'
      }
    }
  }));
  const classes = useStyles();

  async function fetchUsers(query, callback) {
    try {
      let columns = ['firstName', 'lastName', 'username'];
      const users = await API.get(`organizations/${subdomain}/users`, {
        params: { offset: page, search: query?.toLowerCase()?.trim(), columns }
      });
      const data = users.data.map((user) => ({
        display: user.username,
        id: user.id,
        user: user
      }));
      callback(data);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
      callback();
    }
  }

  function changeIconFile(isHovered) {
    const img = document.getElementById('fileIcon');
    if (isHovered) {
      img.src = AttachmentBlueIcon;
    } else {
      img.src = AttachmentIcon;
    }
  }

  function changeIconExpense(isHovered) {
    const img = document.getElementById('expenseIcon');
    if (isHovered) {
      img.src = ExpenseBlueIcon;
    } else {
      img.src = ExpenseIcon;
    }
  }

  function changeIconEmoji(isHovered) {
    const img = document.getElementById('emojiIcon');
    if (isHovered) {
      img.src = EmojiBlueIcon;
    } else {
      img.src = EmojiIcon;
    }
  }

  function changeIconMention(isHovered) {
    const img = document.getElementById('mentionIcon');
    if (isHovered) {
      img.src = MentionBlueIcon;
    } else {
      img.src = MentionIcon;
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if ((message.length > 0 || files.length > 0) && !postLoader) {
        postComment();
      }
    }
  };

  let style = {
    input: {
      overflow: 'auto',
      height: 56
    },
    highlighter: {
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 56
    }
  };

  return (
    <>
      <div className={'h-full border border-[#0B1940] rounded'} ref={anchorRef}>
        <MentionsInput
          inputRef={inputRef}
          aria-label="Demo input"
          fullWidth={true}
          className={'mr-2 ml-2 mt-2 font-normal text-md text-[#0B1940]'}
          style={style}
          allowSuggestionsAboveCursor={true}
          forceSuggestionsAboveCursor={true}
          placeholder="Write a message…"
          a11ySuggestionsListLabel={''}
          value={message}
          allowSpaceInQuery={true}
          onChange={(event) => {
            setMessage(event.target.value);
            if (selectedFile?.length > 0) {
              let message = event.target.value;
              const filesNotFoundInMessage = selectedFile.filter(
                (file) => !message.includes(file.name)
              );

              if (filesNotFoundInMessage.length > 0) {
                const filesToRemoveIds = filesNotFoundInMessage.map(
                  (file) => file.id
                );
                const newFiles = files.filter(
                  (file) => !filesToRemoveIds.includes(file)
                );
                setFiles(newFiles);
              } else {
                console.log('All files found in message');
              }
            }
          }}
          onKeyDown={handleKeyDown}
          customSuggestionsContainer={(children) => (
            <Paper className={classes.paper}>
              <div
                className={
                  'max-h-[240px] w-[345px] rounded no-scrollbar overflow-auto'
                }
              >
                {children}
              </div>
            </Paper>
          )}
        >
          <Mention
            trigger="@"
            data={fetchUsers}
            onAdd={(id) => {
              setSelectedUsers((prevSearchUser) => [...prevSearchUser, id]);
            }}
            className={'mb-10 bg-lightPrimary p-px'}
            appendSpaceOnAdd={true}
            markup={'@[__display__]'}
            displayTransform={(id, display) => '@' + display}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => (
              <div
                className={clsx(
                  'flex border-b border-[#E7E6E6] py-2 px-4',
                  focused ? 'bg-lightPrimary' : 'bg-white'
                )}
              >
                <Title variant={'md'}>{suggestion?.user?.name}</Title>
                <Paragraph variant={'sm'} className={'text-[#0B1940] ml-4'}>
                  ({suggestion?.display})
                </Paragraph>
              </div>
            )}
          />
        </MentionsInput>
        <div className={'flex justify-between px-2 pb-2 items-center'}>
          <div className={'flex gap-2 items-center'}>
            {(isOwareSuperAdmin(currentUser) ||
              checkPermission(currentUser, 'OPS_ORDER_DOCUMENT_UPLOAD')) &&
              isFeatureEnabled(FLAGS.DOCUMENT_MANAGEMENT_MODULE) && (
                <div
                  onMouseOver={() => {
                    changeIconFile(true);
                  }}
                  onMouseOut={() => {
                    changeIconFile(false);
                  }}
                >
                  <img
                    className={clsx(
                      'h-5 w-5',
                      files?.length > 0 && 'bg-[#F0F7FF]'
                    )}
                    id="fileIcon"
                    src={
                      files?.length > 0 ? AttachmentBlueIcon : AttachmentIcon
                    }
                    alt="attachment"
                    onClick={(e) => {
                      e.preventDefault();
                      uploadRef.current.click();
                    }}
                  />
                </div>
              )}
            {(isOwareSuperAdmin(currentUser) ||
              checkPermission(currentUser, 'OPS_ORDER_EXPENSE_CREATE')) &&
              isFeatureEnabled(FLAGS.EXPENSE_MODULE) && (
                <div
                  onMouseOver={() => {
                    changeIconExpense(true);
                  }}
                  onMouseOut={() => {
                    changeIconExpense(false);
                  }}
                >
                  <img
                    className={'h-5 w-5'}
                    id="expenseIcon"
                    src={ExpenseIcon}
                    alt="expense"
                    onClick={() => {
                      setOpenExpense(true);
                    }}
                  />
                </div>
              )}
            <div
              onMouseOver={() => {
                changeIconEmoji(true);
              }}
              onMouseOut={() => {
                changeIconEmoji(false);
              }}
            >
              <img
                className={'h-5 w-5'}
                src={EmojiIcon}
                id="emojiIcon"
                alt="emoji"
                onClick={() => {
                  setPopperOpen(true);
                  setShowEmoji(true);
                }}
              />
            </div>
            <div
              onMouseOver={() => {
                changeIconMention(true);
              }}
              onMouseOut={() => {
                changeIconMention(false);
              }}
            >
              <img
                className={'h-5 w-5 hover:bg-[#F0F7FF]'}
                src={MentionIcon}
                id="mentionIcon"
                alt="mention"
                onClick={() => {
                  const updatedMessage = message.trim();
                  setMessage(
                    updatedMessage +
                      (updatedMessage.length === 0 ||
                      updatedMessage.endsWith(' ')
                        ? ''
                        : ' ') +
                      '@'
                  );
                  inputRef?.current?.focus();
                }}
              />
            </div>
          </div>
          <input
            ref={uploadRef}
            hidden
            type="file"
            multiple
            onClick={(e) => {
              setSelectedFile([]);
              e.target.value = null;
            }}
            onChange={(e) => {
              const files = e.target.files;
              const filesArr = [];
              for (let file of files) {
                filesArr.push(file);
              }
              setSelectedFile(filesArr);
            }}
            accept=".jpg,.png,.jpeg,.pdf,.csv,.xlsx,.xls,.doc,.docx,.txt"
          />
          <button
            type="button"
            disabled={(message.length > 0 || files.length > 0) && postLoader}
            className={clsx(
              'h-8 w-8 rounded z-[100]',
              `${
                (message.length > 0 || files.length > 0) && !postLoader
                  ? 'bg-[#0C6BD7]'
                  : 'bg-[#9DA3B3]'
              }`
            )}
            onClick={(e) => {
              e.stopPropagation();
              postComment();
            }}
          >
            <img src={SendIcon} className="p-2" />
          </button>
        </div>
      </div>
      <Popper
        open={poperOpen}
        anchorEl={anchorRef.current}
        placement={'top-start'}
        disablePortal
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport'
          }
        }}
      >
        {showEmoji && (
          <ClickAwayListener
            onClickAway={() => {
              setPopperOpen(false);
              setShowEmoji(false);
            }}
          >
            <Paper className={classes.paper}>
              <EmojiPicker
                onEmojiClick={(e) => {
                  setMessage(message + e.emoji);
                  setPopperOpen(false);
                  setShowEmoji(false);
                }}
              />
            </Paper>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
export default CommentInput;

import React from 'react';
import moment from 'moment/moment';
import { convertToTitleCase } from '../AddOrder';
import { constants } from '../constants';
import { decimalDisplay } from '../../../utils/common';
import { Label, Paragraph } from '../../../core-components/atoms/Text';
import { FiUser, FiCalendar, FiType, FiTruck, FiBox } from 'react-icons/fi';

const OrderDetailStatsMobile = ({ order, getTotalByKey }) => {
  return (
    <div className="w-full p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg shadow-lg space-y-6">
      <div className="flex items-center space-x-4">
        <FiBox className="text-blue-500" size={24} />
        <div>
          <Label variant="sm" className="text-gray-700">
            REF NO.
          </Label>
          <Paragraph variant="lg" className="text-black font-semibold">
            {order?.referenceId || '-'}
          </Paragraph>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <FiUser className="text-blue-500" size={24} />
        <div>
          <Label variant="sm" className="text-gray-700">
            CREATED BY
          </Label>
          <Paragraph variant="lg" className="text-black font-semibold">
            {order?.User?.firstName + ' ' + order?.User?.lastName}
          </Paragraph>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <FiCalendar className="text-blue-500" size={24} />
        <div>
          <Label variant="sm" className="text-gray-700">
            CREATED ON
          </Label>
          <Paragraph variant="lg" className="text-black font-semibold">
            {moment(order?.createdAt).format('DD/MM/YYYY')}
          </Paragraph>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <FiType className="text-blue-500" size={24} />
        <div>
          <Label variant="sm" className="text-gray-700">
            ORDER TYPE
          </Label>
          <Paragraph variant="lg" className="text-black font-semibold">
            {order?.moveType &&
              convertToTitleCase(
                constants.ORDER_TYPES[order?.moveSubType || order?.moveType]
              )}
          </Paragraph>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <FiTruck className="text-blue-500" size={24} />
        <div>
          <Label variant="sm" className="text-gray-700">
            {order?.moveSubType === 'PURCHASE' ||
            order?.moveSubType === 'SALES_RETURN'
              ? 'SUPPLIER'
              : 'CUSTOMER'}
          </Label>
          <Paragraph variant="lg" className="text-black font-semibold">
            {order?.Company?.name}
          </Paragraph>
        </div>
      </div>

      {['SALES', 'PURCHASE'].includes(order?.moveSubType) && (
        <div className="flex items-center space-x-4">
          <FiCalendar className="text-blue-500" size={24} />
          <div>
            <Label variant="sm" className="text-gray-700">
              PAYMENT TERMS
            </Label>
            <Paragraph variant="lg" className="text-black font-semibold">
              {order?.PaymentTerm ? order?.PaymentTerm?.title : '-'}
            </Paragraph>
          </div>
        </div>
      )}

      {order.moveType !== 'LOGISTIC' && (
        <div className="flex items-center space-x-4">
          <FiBox className="text-blue-500" size={24} />
          <div>
            <Label variant="sm" className="text-gray-700">
              {order.moveType !== 'OUTBOUND'
                ? 'TOTAL RECEIVED QUANTITY'
                : 'TOTAL DISPATCHED QUANTITY'}
            </Label>
            <Paragraph variant="lg" className="text-black font-semibold">
              {order.moveType !== 'OUTBOUND'
                ? `${decimalDisplay(
                    getTotalByKey('received')
                  )} / ${decimalDisplay(getTotalByKey('total'))}`
                : `${decimalDisplay(
                    getTotalByKey('dispatched')
                  )} / ${decimalDisplay(getTotalByKey('total'))}`}
            </Paragraph>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetailStatsMobile;

import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    fontSize: '24px !important',
    lineHeight: '20px !important',
    letterSpacing: '0.0025em !important',
    color: '#000000 !important',
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      fontSize: '20px !important'
    },
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      fontSize: '17px !important'
    },
    '@media only screen and (max-width: 1024px) and (min-width: 993px)': {
      fontSize: '22px !important'
    },
    '@media only screen and (max-width: 360px) and (min-width: 320px)': {
      fontSize: '22px !important'
    }
  }
}));

export default function DetailPageTitle({ text }) {
  const classes = useStyles();
  return <Typography className={classes.dialogTitle}>{text}</Typography>;
}

/*eslint-disable*/
import { useQuery } from 'react-query';
import StatCard from './StatCard';
import { useEffect, useState } from 'react';
import { getInventoriesStats } from '../../../apis/wms';
import { CircularProgress } from '@mui/material';
import { decimalDisplay, formatNumberToKAndM } from '../../../utils/common';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const InventoryStats = ({ refetchStats, setRefetchStats }) => {
  const [stats, setStats] = useState();
  const { refetch } = useQuery(
    ['inventoryStats'],
    () => {
      return getInventoriesStats();
    },
    {
      enabled: true,
      onSuccess: (data) => {
        setStats(data[0]);
      }
    }
  );

  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    if (refetchStats) {
      refetch();
      setRefetchStats(false);
    }
  }, [refetchStats]);

  return (
    <div className="w-full h-[84px] bg-dark300 pl-6 pr-2 py-1 flex shadow-sm">
      {stats ? (
        <>
          <StatCard>
            <div>
              <p className="text-xs text-dark600">TOTAL QTY</p>
              <p className="text-lg text-primaryBlue font-semibold">
                {+stats?.availableQuantity +
                  +stats?.reservedQuantity +
                  +stats?.holdQuantity +
                  +stats?.recoverableDamageQuantity <
                1000
                  ? decimalDisplay(
                      +stats?.availableQuantity +
                        +stats?.reservedQuantity +
                        +stats?.holdQuantity +
                        +stats?.recoverableDamageQuantity
                    )
                  : formatNumberToKAndM(
                      +stats?.availableQuantity +
                        +stats?.reservedQuantity +
                        +stats?.holdQuantity +
                        +stats?.recoverableDamageQuantity
                    )}
              </p>
            </div>
          </StatCard>
          <StatCard>
            <div className="mr-6">
              <p className="text-xs text-dark600">AVAILABLE</p>
              <p className="text-lg text-jade700 font-semibold">
                {+stats?.availableQuantity < 1000
                  ? decimalDisplay(+stats?.availableQuantity)
                  : formatNumberToKAndM(+stats?.availableQuantity)}
              </p>
            </div>
            <div className="mr-6">
              <p className="text-xs text-dark600">ON HOLD</p>
              <p className="text-lg text-primaryBlue font-semibold">
                {+stats?.holdQuantity < 1000
                  ? decimalDisplay(+stats?.holdQuantity)
                  : formatNumberToKAndM(+stats?.holdQuantity)}
              </p>
            </div>
            {isFeatureEnabled(FLAGS.PICKING) && (
              <div className="mr-6">
                <p className="text-xs text-dark600">RESERVED</p>
                <p className="text-lg text-primaryBlue font-semibold">
                  {+stats?.reservedQuantity < 1000
                    ? decimalDisplay(+stats?.reservedQuantity)
                    : formatNumberToKAndM(+stats?.reservedQuantity)}
                </p>
              </div>
            )}
          </StatCard>
          <StatCard>
            <div className="mr-6">
              <p className="text-xs text-dark600">DAMAGED</p>
              <p className="text-lg text-persianRed font-semibold">
                {+stats?.recoverableDamageQuantity < 1000
                  ? decimalDisplay(+stats?.recoverableDamageQuantity)
                  : formatNumberToKAndM(+stats?.recoverableDamageQuantity)}
              </p>
            </div>
            {isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) && (
              <>
                <div className="mr-6">
                  <p className="text-xs text-dark600">EXPIRED</p>
                  <p className="text-lg text-primaryBlue font-semibold">
                    {+stats?.expiredQuantity < 1000
                      ? decimalDisplay(+stats?.expiredQuantity)
                      : formatNumberToKAndM(+stats?.expiredQuantity)}
                  </p>
                </div>
                <div className="mr-6">
                  <p className="text-xs text-dark600">NEAR EXPIRY</p>
                  <p className="text-lg text-primaryBlue font-semibold">
                    {+stats?.nearExpiryQuantity < 1000
                      ? decimalDisplay(+stats?.nearExpiryQuantity)
                      : formatNumberToKAndM(+stats?.nearExpiryQuantity)}
                  </p>
                </div>
              </>
            )}
          </StatCard>
        </>
      ) : (
        <div className="h-full w-full flex items-center justify-center">
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
};

export default InventoryStats;

/*eslint-disable*/
export const constants = {
  INTELLIGENCE_KPIS_KEYS: {
    TOTAL_REVENUE: 'TOTAL_REVENUE',
    TOTAL_PURCHASE_COST: 'TOTAL_PURCHASE_COST',
    TOTAL_SHIPMENT_COST: 'TOTAL_SHIPMENT_COST',
    NET_MARGIN: 'NET_MARGIN',
    LOSS_SALES: 'LOSS_SALES',
    TOP_ACTIVE_LOCATIONS: 'TOP_ACTIVE_LOCATIONS',
    POTENTIAL_REVENUE: 'POTENTIAL_REVENUE',
    DELAYED_SHIPMENTS: 'DELAYED_SHIPMENTS',
    ON_TIME_IN_FULL: 'ON_TIME_IN_FULL',
    ORDER_CYCLE_TIME: 'ORDER_CYCLE_TIME',
    INVENTORY_TURN_OVER: 'INVENTORY_TURN_OVER',
    TOP_CUSTOMERS: 'TOP_COMPANIES',
    TOP_SUPPLIERS: 'TOP_SUPPLIERS',
    TOP_PRODUCTS: 'TOP_PRODUCTS',
    TOTAL_SHIPMENTS: 'TOTAL_SHIPMENTS',
    PRODUCT_PERFORMANCE: 'PRODUCT_PERFORMANCE',
    LANDED_COST: 'LANDED_COST',
    BEST_FLOOR_OPERATORS: 'BEST_FLOOR_OPERATORS',
    WAREHOUSE_CAPACITY_UTILIZATION: 'WAREHOUSE_CAPACITY_UTILIZATION',
    CARRIER_PERFORMANCE: 'CARRIER_PERFORMANCE'
  },
  INTELLIGENCE_KPIS_VALUES: {
    TOTAL_REVENUE: 'Total Revenue',
    TOTAL_PURCHASE_COST: 'Total Purchase Cost',
    TOTAL_SHIPMENT_COST: 'Total Shipment Cost',
    NET_MARGIN: 'Net Margin',
    LOSS_SALES: 'Loss Sales',
    TOP_ACTIVE_LOCATIONS: 'Top Active Locations',
    POTENTIAL_REVENUE: 'Potential Revenue',
    DELAYED_SHIPMENTS: 'Delayyed Shipments',
    ON_TIME_IN_FULL: 'On Time in Fulll',
    ORDER_CYCLE_TIME: 'Order Cycle Time',
    INVENTORY_TURN_OVER: 'Inventory Turn Over',
    TOP_CUSTOMERS: 'Top Customers',
    TOP_SUPPLIERS: 'Top Suppliers',
    TOP_PRODUCTS: 'Top Products',
    TOTAL_SHIPMENTS: 'Total Shipments',
    PRODUCT_PERFORMANCE: 'Product Performance',
    LANDED_COST: 'Landed Cost',
    BEST_FLOOR_OPERATORS: 'Best Floor Operators',
    WAREHOUSE_CAPACITY_UTILIZATION: 'Warehouse Capacity Utilization',
    CARRIER_PERFORMANCE: 'Carrier Performance',
    SHRINKAGE: 'Shrinkage'
  }
};

import { useFormik } from 'formik';
import React from 'react';
import { Button, TextField, Typography, makeStyles } from '@material-ui/core';
import { toaster } from '../../../utils/toaster';

export default function CancelInvoice({ onCancel, closePopUp, invoice }) {
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1rem',
      '& .MuiFormControl-root': {
        width: '100% !important'
      }
    },
    btns: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1.5rem',
      gap: '1rem',
      '& button': {
        textTransform: 'none'
      }
    },
    cancelBtn: {
      fontWeight: 700,
      background: '#0C71D7'
    },
    textArea: {
      width: '100%'
    },
    btnText: {
      color: '#0C71D7'
    }
  }));

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      cancellationReason: ''
    },
    onSubmit: async (values) => {
      if (values.cancellationReason) {
        onCancel(values, invoice.id);
        closePopUp();
      } else {
        toaster('warning', 'Please enter cancellation reason');
      }
    }
  });
  return (
    <div style={{ width: '650px' }}>
      <Typography style={{ fontWeight: 600, fontSize: '1.5rem' }}>
        Why are you cancelling this invoice ?
      </Typography>

      <form onSubmit={formik.handleSubmit} className="mt-10">
        <div className={classes.container}>
          <div style={{ width: '100%' }}>
            <TextField
              value={formik.values.cancellationReason}
              onChange={(e) =>
                formik.setFieldValue('cancellationReason', e.target.value)
              }
              variant="outlined"
              multiline
              rows={4}
              sx={{ width: '100%' }}
            />
          </div>
        </div>
        <div className={classes.btns}>
          <Button
            variant="text"
            className={classes.btnText}
            onClick={closePopUp}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.cancelBtn}
          >
            Cancel Invoice
          </Button>
        </div>
      </form>
    </div>
  );
}

import {
  FormControl,
  Select,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const CustomMenuItem = styled(MenuItem)(() => ({
  '& .MuiListItemIcon-root': {
    marginRight: '12px',
    minWidth: '20px',
    disply: 'flex',
    justifyContent: 'center'
  }
}));

const Dropdown = ({
  className,
  variant,
  size,
  label,
  value,
  items,
  disabled,
  onChange,
  showIcon = false,
  showSelectedIcon = false,
  labelClasses,
  selectClasses
}) => {
  return (
    <FormControl
      className={clsx(className)}
      variant={variant}
      size={size}
      disabled={disabled}
    >
      <Select
        className={selectClasses}
        value={value}
        label={label}
        onChange={({ target: { value } }) => {
          onChange(value);
        }}
      >
        {items?.map((item) => {
          const { value: itemValue, label: itemLabel, icon: itemIcon } = item;
          return (
            <CustomMenuItem key={itemLabel} value={itemValue}>
              {itemIcon && showIcon && (
                <ListItemIcon className={clsx(!showSelectedIcon && 'hidden')}>
                  <img src={itemIcon} alt={itemLabel} />
                </ListItemIcon>
              )}
              <ListItemText className={labelClasses}>{itemLabel}</ListItemText>
            </CustomMenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Dropdown;

/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { FormControl, Grid, List, ListItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import * as XLSX from 'xlsx';

import Button from '../../core-components/atoms/Button';
import { SharedContext } from '../../utils/common';
import { addCardIcon } from './icons';
import PopupButton from '../../core-components/molecules/PopupButton';
import SupplyChainTable from './SupplyChainTable';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import CustomPagination from '../invoiceandbilling/invoiceManagement/CustomPagination';
import CreateCustomer from './AddEntities/CreateCustomer';
import CreateSupplier from './AddEntities/CreateSupplier';
import CompanySearch from './supplierSearchBar';
import CreateNode from './AddEntities/CreateNode';
import SettingIcon from '../../assets/icons/setting.svg';
import BulkIcon from '../../assets/icons/blue-bulk-upload.svg';
import DownloadIcon from '../../assets/icons/download-template-light.svg';
import BulkFileAttacher from '../productMaster/ListViews/components/BulkFileAttacher';
import Popup from '../../core-components/atoms/Popup';
import WarningIcon from '../../assets/icons/warning.svg';
import downloadFileIcon from '../../assets/icons/templateDownload.svg';
import AdvancedBulkUploadTemplate from '../../assets/files/AdvancedBulkUploadTemplate.xlsx';
import { toaster } from '../../utils/toaster';
import { checkPermission } from '../../utils/auth';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import mixpanel from 'mixpanel-browser';

const SupplyChainNetwork = () => {
  const [entityTypeOpen, setEntityTypeOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [organizationNodes, setOrganizationNodes] = useState({});
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [companyNameFilter, setCompanyNameFilter] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [filters, setFilters] = useState([]);
  const [nodeAddOpen, setNodeAddOpen] = useState(false);
  const [customerAddOpen, setCustomerAddOpen] = useState(null);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [correctEntries, setCorrectEntries] = useState([]);
  const [incorrectEntries, setIncorrectEntries] = useState([]);
  const [bulkBtnOpen, setBulkBtnOpen] = useState(false);
  const [parsed, setParsed] = useState({});
  const [entityInfo, setEntityInfo] = useState(null);
  const [transitNodePopup, setTransitNodePopup] = useState(false);
  const [transitNodes, setTransitNodes] = useState([]);
  const [transitNodeSearch, setTransitNodeSearch] = useState('');
  const [transitNodeValues, setTransitNodeValues] = useState([]);
  const [submittingTransitNodes, setSubmittingTransitNodes] = useState(false);

  const {
    setCurrentPageTitle,
    organizationType,
    organization,
    setAPILoader,
    currentUser
  } = useContext(SharedContext);

  const getCustomers = async (value, params, isSearch) => {
    try {
      const nextPage = isSearch ? 1 : page;

      const response = await API.get(`companies`, {
        params: {
          limit: page == 1 ? 9 : 10,
          offset: page === 1 ? 0 : (nextPage - 1) * 10 - 1,
          page: nextPage,
          type: filters.length > 0 ? filters : ['CUSTOMER', 'SUPPLIER'],
          ...params
        }
      });

      setCustomers(response);
      setPages(response.pages);
    } catch (err) {
      onError(err);
    }
  };

  const getOrgNodes = async () => {
    try {
      const response = await API.get(`nodes`);
      setOrganizationNodes(response);
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    setCurrentPageTitle('Supply Chain Network');
    getOrgNodes();
    mixpanel.track('Supply Chain Network', { orgType: organization?.type });
  }, []);

  useEffect(() => {
    getTransitNodes();
  }, [transitNodeSearch]);

  useEffect(() => {
    getCustomers();
  }, [page]);

  useEffect(() => {
    if (companyNameFilter) {
      getCustomers(companyNameFilter, { companyIds: [customerId] }, true);
    } else {
      getCustomers();
    }
  }, [companyNameFilter, filters]);

  let filterTypes =
    organization?.type == 'MANUFACTURER'
      ? ['Organization', 'Customer', 'Supplier']
      : ['Organization', 'Customer'];

  const handleClickAddCustomer = () => {
    setCustomerAddOpen('customer');
  };

  const handleClickAddSupplier = () => {
    setCustomerAddOpen('supplier');
  };

  const fileUpload = async (values) => {
    setAPILoader(true);
    const payload = values.map((value) => ({
      entity: value['Entity'],
      entityName: value['Entity Name'],
      entityCode: value['Entity Code'],
      entityType: value['Entity Type'],
      entityBillingAddress: value['Entity Billing Address'],
      entityShippingAddress: value['Entity Shipping Address'],
      email: value['Email'],
      contact: value['Contact'],
      paymentTermTitle: value['Payment Terms'],
      creditLimit: value['Credit Limit'] || null,
      nodeName: value['Node Name'],
      nodeType: value['Node Type']?.replace(/ /g, ''),
      nodeCode: value['Node Code'],
      nodeAddress: value['Node Address'],
      ntn: value['TIN'],
      pocName: value['POC Name'],
      pocId: value['POC ID'],
      pocEmail: value['POC Email'],
      pocContact: value['POC Contact'],
      strn: value['STRN'],
      categoryName: value['Category'],
      additionalInfo: value['Additional Info'],
      additionalDetail: value['Additional Detail'],
      capacity: value['Capacity (Sq fts)']
        ? Number(value['Capacity (Sq fts)'])
        : 32,
      pallets: value['Capacity (Pallets)']
        ? Number(value['Capacity (Pallets)'])
        : 2,
      IMS: value['IMS'] == 'Yes' ? true : false,
      WMS: value['WMS'] == 'Yes' ? true : false,
      isActive: value['status']
        ? value['status'].toLowerCase() == 'active'
          ? true
          : false
        : true
    }));

    let incorrectCase1 = [];
    let correctCase1 = [];
    let correctCase2 = [];
    let incorrectCase2 = [];
    let correctCase3 = [];
    let incorrectCase3 = [];
    let temp = [];
    let parsed = {};

    const case1 = payload.filter((val) => !val.nodeName && !val.nodeType);

    if (case1.length > 0) {
      for (let i = 0; i < case1.length; i++) {
        if (
          temp.find(
            (prev) =>
              prev['entityName'] === case1[i]['entityName'] &&
              prev['entity'] == case1[i]['entity']
          )
        ) {
          incorrectCase1.push({ ...case1[i], reason: 'Duplicate entity name' });
        } else {
          temp.push(case1[i]);
        }
      }

      let resp = await API.post(
        `organizations/${organization.subdomain}/bulk?validateOnly=true`,
        temp
      );

      correctCase1 = temp.filter((item, id) => !resp[id + 2]);

      for (let i = 0; i < temp.length; i++) {
        if (resp[i + 2]) {
          incorrectCase1.push({ ...temp[i], reason: resp[i + 2] });
        }
      }

      for (let i = 0; i < correctCase1.length; i++) {
        parsed[`${correctCase1[i]['entityName']}${correctCase1[i]['entity']}`] =
          {
            type: correctCase1[i]['entity'],
            name: correctCase1[i]['entityName'],
            nodes: []
          };
      }
    }

    const case2 = payload.filter(
      (val) => val.nodeName && val.nodeType && val.entity
    );
    temp = [];

    if (case2.length > 0) {
      for (let i = 0; i < case2.length; i++) {
        temp.push(case2[i]);
      }

      let resp = await API.post(
        `organizations/${organization.subdomain}/bulk?validateOnly=true`,
        temp
      );

      correctCase2.push(...temp.filter((item, id) => !resp[id + 2]));

      for (let i = 0; i < temp.length; i++) {
        if (resp[i + 2]) {
          incorrectCase2.push({ ...temp[i], reason: resp[i + 2] });
        }
      }

      for (let i = 0; i < correctCase2.length; i++) {
        const entityKey = `${correctCase2[i]['entityName']}_${correctCase2[i]['entity']}`;
        if (parsed[entityKey]) {
          parsed[entityKey].nodes.push({
            name: correctCase2[i]['nodeName'],
            type: correctCase2[i]['nodeType']
          });
        } else {
          parsed[entityKey] = {
            type: correctCase2[i]['entity'],
            name: correctCase2[i]['entityName'],
            nodes: [
              {
                name: correctCase2[i]['nodeName'],
                type: correctCase2[i]['nodeType']
              }
            ]
          };
        }
      }
    }

    const case3 = payload.filter(
      (val) => !val.entity && val.entityName && val.nodeType && val.nodeName
    );
    temp = [];

    if (case3.length > 0) {
      for (let i = 0; i < case3.length; i++) {
        temp.push(case3[i]);
      }

      let resp = await API.post(
        `organizations/${organization.subdomain}/bulk?validateOnly=true`,
        temp
      );

      correctCase3.push(...temp.filter((item, id) => !resp[id + 2]));

      for (let i = 0; i < temp.length; i++) {
        if (resp[i + 2]) {
          incorrectCase3.push({ ...temp[i], reason: resp[i + 2] });
        }
      }

      for (let i = 0; i < correctCase3.length; i++) {
        if (parsed[correctCase3[i]['entityName']]) {
          parsed[correctCase3[i]['entityName']].nodes.push({
            name: correctCase3[i]['nodeName'],
            type: correctCase3[i]['nodeType']
          });
        } else {
          parsed[correctCase3[i]['entityName']] = {
            type: correctCase3[i]['entity'],
            name: correctCase3[i]['entityName'],
            nodes: [
              {
                name: correctCase3[i]['nodeName'],
                type: correctCase3[i]['nodeType']
              }
            ]
          };
        }
      }
    }

    setCorrectEntries([...correctCase1, ...correctCase2, ...correctCase3]);
    setIncorrectEntries([
      ...incorrectCase1,
      ...incorrectCase2,
      ...incorrectCase3
    ]);
    setParsed(parsed);
    setBulkUploadOpen(true);
    setBulkBtnOpen(false);
    setAPILoader(false);
  };

  const onSubmit = async (payload) => {
    setAPILoader(true);
    try {
      let resources = payload.length;

      if (resources > 10) {
        for (let i = 0; i < Math.ceil(resources / 5); i++) {
          await API.post(
            `organizations/${organization.subdomain}/bulk`,
            payload.slice(i * 10, i * 10 + 10)
          );
        }
      } else
        await API.post(`organizations/${organization.subdomain}/bulk`, payload);

      await getOrgNodes();
      await getCustomers();
      toaster('success', 'Entities have been uploaded successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setAPILoader(false);
      setBulkUploadOpen(false);
      setSelectedFile(null);
    }
  };

  const getTransitNodes = async () => {
    try {
      setAPILoader(true);
      const transitNodes = await API.get('nodes/transit', {
        params: { search: transitNodeSearch }
      });
      setTransitNodes(transitNodes.data);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const exportToXlsx = () => {
    let nodes = incorrectEntries?.map((node) => [
      node.entity,
      node.entityCode,
      node.entityName,
      node.entityType,
      node.entityBillingAddress,
      node.entityShippingAddress,
      node.email,
      node.contact,
      node.paymentTerms,
      node.creditLimit,
      node.nodeName,
      node.nodeType ?? node.nodeType == 'Darkstore'
        ? 'Dark store'
        : node.nodeType,
      node.nodeCode,
      node.nodeAddress,
      node.capacity ? node.capacity : '',
      node.pallets ? node.pallets : '',
      node.pocName,
      node.pocContact,
      node.IMS == false ? 'No' : 'Yes',
      node.WMS == false ? 'No' : 'Yes',
      node.isActive == true ? 'Active' : 'Inactive',
      node?.reason
    ]);
    const data = [
      [
        'Entity',
        'Entity Code',
        'Entity Name',
        'Entity Type',
        'Entity Billing Address',
        'Entity Shipping Address',
        'Email',
        'Contact',
        'Payment Terms',
        'Credit Limit',
        'Node Name',
        'Node Type',
        'Node Code',
        'Node Address',
        'Capacity (sq fts)',
        'Capacity (Pallets)',
        'POC Name',
        'POC Contact',
        'IMS',
        'WMS',
        'status',
        'Reason'
      ],
      ...nodes
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Incorrect nodes');

    // Save the workbook as an XLSX file
    XLSX.writeFile(wb, 'incorrect_nodes.xlsx');
  };

  const totalNodes = () => {
    const keys = Object.keys(parsed);
    let total = 0;
    if (keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        if (parsed[keys[i]].nodes.length > 0) {
          total += parsed[keys[i]].nodes.length;
        }
      }
    }

    return total;
  };

  const createBulkNodes = async () => {
    const payload = transitNodeValues.map((node) => ({
      name: node?.name,
      locationLatLng: node?.locationLatLng,
      portType: node?.portType,
      address: node.address,
      nodeClass: 'TRANSIT',
      nodeType: 'OTHERS'
    }));
    try {
      setAPILoader(true);
      await API.post('nodes/bulk', payload, {
        params: { skipValidation: false }
      });
      await getTransitNodes();
      await getOrgNodes();
      toaster('success', 'Nodes created successfully !');
      mixpanel.track('Nodes Bulk Upload', {});
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between mr-4 mt-10">
        <p className="text-2xl font-medium ml-4">All Entities</p>
        <div className="flex gap-2">
          <PopupButton
            open={transitNodePopup}
            setOpen={setTransitNodePopup}
            btn={
              <div>
                <Button
                  label="Add Transit Node"
                  onClick={() => {
                    setTransitNodePopup(!transitNodePopup);
                  }}
                  icon={
                    <div className={'mr-1.5'}>{addCardIcon('#0C71D7')}</div>
                  }
                  variant="secondary"
                ></Button>
              </div>
            }
            content={
              <div>
                <div className="w-[500px] p-4">
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      multiple
                      options={transitNodes}
                      renderOption={(props, option) => {
                        const { className, ...rest } = props;
                        return (
                          <li {...rest} className="auto-complete-option-small">
                            <span>
                              {option.code || option.name
                                ? `${option.name}`
                                : ''}
                            </span>
                            <span style={{ color: '#67718C' }}>
                              {option?.code ? ` • ${option?.code}` : ''}
                            </span>
                          </li>
                        );
                      }}
                      getOptionLabel={(node) =>
                        node.code || node.name ? `${node.name}` : ''
                      }
                      onChange={(e, val) => {
                        setTransitNodeValues(val);
                      }}
                      value={transitNodeValues}
                      placeholder={
                        transitNodeValues.length > 0 ? '' : 'Transit nodes'
                      }
                      inputValue={transitNodeSearch || ''}
                      onInputChange={(event, newValue) => {
                        setTransitNodeSearch(newValue);
                      }}
                    />
                  </FormControl>
                  <div className="flex justify-end mt-4">
                    <Button
                      label="Add Nodes"
                      onClick={async (e) => {
                        e.preventDefault();
                        setSubmittingTransitNodes(true);
                        await createBulkNodes();
                        setTransitNodeValues([]);
                        setTransitNodeSearch('');
                        setTransitNodePopup(false);
                        setSubmittingTransitNodes(false);
                      }}
                      className={
                        'py-0 text-oasisPrimary500 border-oasisPrimary500'
                      }
                      variant="primary"
                      disabled={
                        transitNodeValues.length == 0 || submittingTransitNodes
                      }
                    />
                  </div>
                </div>
              </div>
            }
          />
          {checkPermission(currentUser, 'OPS_NODES_BULK_UPLOAD') && (
            <PopupButton
              open={bulkBtnOpen}
              setOpen={setBulkBtnOpen}
              btn={
                <Button
                  label="Bulk Upload"
                  onClick={(e) => {
                    e.preventDefault();
                    setBulkBtnOpen(!bulkBtnOpen);
                  }}
                  className={'py-0 text-oasisPrimary500 border-oasisPrimary500'}
                  variant="transparent"
                  icon={<img src={BulkIcon} className={`mr-[2px]`} />}
                />
              }
              content={
                <List className="py-4">
                  <ListItem className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer">
                    <a href={AdvancedBulkUploadTemplate} className="flex gap-2">
                      <img src={DownloadIcon} className={`mr-[2px]`} />
                      <span>Download Template</span>
                    </a>
                  </ListItem>
                  <BulkFileAttacher
                    bulkUpload={(vals) => fileUpload(vals)}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                </List>
              }
            />
          )}
          {checkPermission(currentUser, 'OPS_NODE_CREATE') && (
            <PopupButton
              open={nodeAddOpen}
              setOpen={setNodeAddOpen}
              btn={
                <Button
                  label="Add Node"
                  variant="secondary"
                  onClick={() => {
                    setNodeAddOpen(!nodeAddOpen);
                    setEntityInfo(null);
                  }}
                  icon={
                    <div className={'mr-1.5'}>{addCardIcon('#0C71D7')}</div>
                  }
                />
              }
              content={
                <CreateNode
                  setOpen={setNodeAddOpen}
                  getCustomer={getCustomers}
                  getNodes={getOrgNodes}
                  entityInfo={entityInfo}
                />
              }
            />
          )}
          {checkPermission(currentUser, 'OPS_COMPANY_CREATE') ? (
            organizationType === 'THIRD_PARTY_SERVICE_PROVIDER' ? (
              <PopupButton
                open={customerAddOpen}
                setOpen={setCustomerAddOpen}
                btn={
                  <Button
                    label="Add Customer"
                    variant="primary"
                    onClick={() => {
                      setCustomerAddOpen(!customerAddOpen);
                    }}
                    icon={<div className={'mr-1.5'}>{addCardIcon('#FFF')}</div>}
                  />
                }
                content={
                  <CreateCustomer
                    setOpen={setCustomerAddOpen}
                    setEntityTypeOpen={setEntityTypeOpen}
                    getCustomer={getCustomers}
                    getNodes={getOrgNodes}
                  />
                }
              />
            ) : (
              <PopupButton
                open={entityTypeOpen}
                setOpen={(res) => {
                  setEntityTypeOpen(res);
                  setCustomerAddOpen(res);
                }}
                btn={
                  <div>
                    <Button
                      label="Add Entity"
                      variant="primary"
                      onClick={() => {
                        setEntityTypeOpen(!entityTypeOpen);
                      }}
                    >
                      <span className="ml-2">
                        {entityTypeOpen ? <ExpandLess /> : <ExpandMore />}
                      </span>
                    </Button>
                  </div>
                }
                content={
                  <div>
                    {customerAddOpen === 'customer' ? (
                      <CreateCustomer
                        setOpen={setCustomerAddOpen}
                        setEntityTypeOpen={setEntityTypeOpen}
                        getCustomer={getCustomers}
                        getNodes={getOrgNodes}
                      />
                    ) : customerAddOpen === 'supplier' ? (
                      <CreateSupplier
                        setOpen={setCustomerAddOpen}
                        setEntityTypeOpen={setEntityTypeOpen}
                        getCustomer={getCustomers}
                        getNodes={getOrgNodes}
                      />
                    ) : (
                      <List className="py-4">
                        <ListItem
                          className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                          onClick={handleClickAddCustomer}
                        >
                          <span>{addCardIcon()}</span>
                          <span>Create Customer</span>
                        </ListItem>
                        <ListItem
                          className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                          onClick={handleClickAddSupplier}
                        >
                          <span>{addCardIcon()}</span>
                          <span>Create Supplier</span>
                        </ListItem>
                      </List>
                    )}
                  </div>
                }
              />
            )
          ) : null}
        </div>
      </div>
      <div className="mt-4 mr-4 ml-4 border border-solid border-[#E7E6E6] rounded-lg">
        <div className="flex gap-2 border-solid border-0 border-b border-[#E7E6E6]">
          <div className="px-2 py-4 w-full flex flex-wrap gap-3 items-center">
            <div className="border-solid border-0 border-r border-[#BDBDBD] inline-block text-sm text-[#6A7289] cursor-pointer px-2 py-1">
              <div className="flex items-center gap-2">
                <img src={SettingIcon} />
                <span>Filters</span>
              </div>
            </div>
            {filterTypes.map((filter, id) => {
              let isSelected = filters.indexOf(filter) !== -1;
              let className = isSelected ? 'bg-[#274E77] text-white' : '';
              return (
                <div
                  className={`px-3 py-2 border-solid cursor-pointer border border-[#67718C] inline-block ${className} rounded`}
                  onClick={() => {
                    if (filters.indexOf(filter) !== -1) {
                      setFilters(filters.filter((f) => f !== filter));
                    } else {
                      setFilters([...filters, filter]);
                    }
                  }}
                  key={id}
                >
                  <span className="text-sm">{filter}</span>
                  <span
                    className={`ml-3 ${
                      isSelected ? 'text-white' : 'text-[#172B61E5]'
                    } font-bold`}
                  >
                    +
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <SupplyChainTable
          staticRow={
            organization?.id &&
            !companyNameFilter &&
            (filters.length == 0 || filters.indexOf('Organization') !== -1)
              ? {
                  name: organization?.name,
                  type: 'Organization',
                  paymentTerms: null,
                  ntn: organization?.ntn,
                  billingAddress: organization?.address,
                  numberOfNodes: organizationNodes?.count,
                  nodes: organizationNodes?.data || [],
                  activeNodes: organizationNodes?.activeNodeCount
                }
              : {}
          }
          setNodeAddOpen={setNodeAddOpen}
          setEntityInfo={setEntityInfo}
          rows={customers?.data?.rows || []}
          page={page}
          setPage={setPage}
          pages={pages}
          showHighlight={!!companyNameFilter}
          refetch={async () => {
            await getOrgNodes();
            await getCustomers();
          }}
        />
      </div>
      <div className="mr-4 ml-4">
        <CustomPagination page={page} pages={pages} setPage={setPage} />
      </div>
      <div className="fixed top-[4px] right-[122px] z-[1100]">
        <CompanySearch
          companyFiterName={companyNameFilter}
          setCompanyNameFilter={setCompanyNameFilter}
          setCustomerId={setCustomerId}
          onClear={async () => {
            await getCustomers();
            setCustomerId('');
            setCompanyNameFilter('');
          }}
        />
      </div>
      <Popup
        open={bulkUploadOpen}
        setOpen={setBulkUploadOpen}
        title={'Bulk Upload'}
        dialogContentClasses={`w-[800px]`}
        onClose={() => setSelectedFile(null)}
        content={
          parsed && (
            <div>
              <div className="mt-8">
                {incorrectEntries?.length > 0 && (
                  <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
                    <div className="flex gap-2">
                      <img src={WarningIcon} />
                      <p className="text-sm">
                        {incorrectEntries?.length}{' '}
                        {incorrectEntries?.length > 1
                          ? 'nodes are '
                          : 'node is '}
                        not uploaded because of formatting issues in file.
                      </p>
                    </div>
                    <div
                      onClick={async () => {
                        await exportToXlsx();
                      }}
                      className="flex gap-2 cursor-pointer"
                    >
                      <img src={downloadFileIcon} />
                      <p className="text-sm">Download File</p>
                    </div>
                  </div>
                )}
                <p className="text-sm font-medium mt-[30px]">
                  {Object.keys(parsed).length} Entities & {totalNodes()} Nodes
                  Detected
                </p>
                {Object.keys(parsed).map((key) => {
                  const nodes = parsed[key].nodes;

                  return (
                    <div className="mt-[32px]">
                      <div className="flex gap-2">
                        <div className="flex gap-2 mr-2 items-center">
                          <span className="text-[#67718C] text-sm">NAME</span>
                          <span className="text-xs">{parsed[key].name}</span>
                        </div>
                        {parsed[key].type && (
                          <div className="flex gap-2 mr-2 items-center">
                            <span className="text-[#67718C] text-sm">TYPE</span>
                            <span className="text-xs">{parsed[key].type}</span>
                          </div>
                        )}
                        <div className="flex gap-2 items-center">
                          <span className="text-[#67718C] text-sm">NODES</span>
                          <span className="text-xs">{nodes.length}</span>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-4">
                        {nodes?.map((node) => {
                          return (
                            <div className="p-4 bg-[#F0F7FF] mt-4">
                              <div className="flex gap-2 items-center">
                                <div
                                  className={`h-3 w-3 rounded-full border border-solid border-[#1AAE2E] bg-[#1AAE2E]`}
                                />
                                <div>
                                  <p className="text-sm">{node.name}</p>
                                  <p className="text-sm text-[#4B5369]">
                                    {node.type}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        }
        actions={
          <div className="flex gap-2">
            <Button
              label="Cancel"
              onClick={() => {
                setBulkUploadOpen(false);
                setSelectedFile(null);
              }}
              variant="secondary"
            />
            <Button
              label={'Create All'}
              onClick={async () => {
                await onSubmit(correctEntries);
              }}
              disabled={correctEntries?.length == 0}
              variant="primary"
            />
          </div>
        }
      />
    </div>
  );
};

export default SupplyChainNetwork;

import React from 'react';
import Autocomplete from '../atoms/Autocomplete';

const FormikAutocomplete = ({ formik, name, ...rest }) => {
  return (
    <div className="flex flex-col gap-2">
      <Autocomplete
        value={formik.values[name]}
        onChange={async (e, value) => {
          formik.setFieldValue(name, value);
        }}
        error={formik.touched[name] && formik.errors[name]?.value}
        {...rest}
      />
      {formik.touched[name] && formik.errors[name]?.value && (
        <span className="text-error text-xs">{formik.errors[name]?.value}</span>
      )}
    </div>
  );
};

export default FormikAutocomplete;

import * as Yup from 'yup';

export const initialVals = (booking) => {
  return {
    warehouseId: booking?.Warehouse?.id || '',
    customerId: booking?.Customer?.id || '',
    bookingStartDate: booking?.bookingStartDate || '',
    bookingEndDate: booking?.bookingEndDate || '',
    pallets: booking?.pallets || '',
    minimumChargeableAmount: booking?.minimumChargeableAmount || 0,
    minimumChargeableStatus: booking?.minimumChargeableStatus || false,
    status: booking?.status || 'INACTIVE'
  };
};

export const valSchema = Yup.object().shape({
  customerId: Yup.string().required('Required'),
  warehouseId: Yup.string().required('Required'),
  bookingStartDate: Yup.date().required('Required'),
  bookingEndDate: Yup.date().required('Required'),
  pallets: Yup.number().required('Required'),
  minimumChargeableAmount: Yup.number(),
  minimumChargeableStatus: Yup.bool(),
  status: Yup.string()
});

import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  DialogTitle,
  Grid,
  Checkbox,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import warehouseStyles from './warehouseMakeStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import MessageSnackbar from '../../../components/MessageSnackbar';
import { SharedContext } from '../../../utils/common';

const AddDockView = ({
  open,
  dock,
  handleClose,
  warehouseId,
  getWarehouseDocks
}) => {
  const classes = warehouseStyles();
  const [showMessage, setShowMessage] = useState(null);

  const { setAPILoader } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: {
      name: dock?.name || '',
      type: dock?.type || '',
      isActive: !!dock?.isActive
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Dock name is required'),
      type: Yup.string().required('Dock type is required'),
      isActive: Yup.bool().required('Dock active status is required')
    }),
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  const submitForm = async (values) => {
    try {
      setAPILoader(true);
      if (dock && dock.id) {
        await API.put(`warehouses/${warehouseId}/docks/${dock.id}`, {
          ...values
        });
        setShowMessage({
          message: 'Dock updated successfully.'
        });
      } else {
        await API.post(`warehouses/${warehouseId}/docks`, {
          ...values
        });
        setShowMessage({
          message: 'Dock created successfully.'
        });
      }
      getWarehouseDocks();
      handleClose();
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '448px', maxWidth: '100% !important' } }}
    >
      <Grid
        container
        className={classes.backdropGrid}
        justifyContent="flex-start"
      >
        <Grid item xs={12} className="dockTitle">
          <DialogTitle>
            <Typography className="dockHeading">
              {dock?.id ? 'Edit a Dock:' : 'Add a Dock:'}
            </Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={12}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <DialogContent className={classes.dockDialog}>
              <DetailTitle text="Dock Name" />
              <input
                className={classes.dockInputField}
                id="name"
                placeholder="Type here"
                value={formik.values.name || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name && (
                <Typography color="error">{formik.errors.name}</Typography>
              )}
              <DetailTitle text="Dock Type" />
              <select
                className={classes.dockInputField}
                id="type"
                value={formik.values.type || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option hidden>Select a type</option>
                <option value="PRIMARY">Primary</option>
                <option value="SECONDARY">Secondary</option>
              </select>
              {formik.errors.type && formik.touched.type && (
                <Typography color="error">{formik.errors.type}</Typography>
              )}
              <Grid item sm={12}>
                <Checkbox
                  checked={formik.values.isActive}
                  id="isActive"
                  name="isActive"
                  value={formik.values.isActive}
                  onChange={formik.handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Active
              </Grid>
            </DialogContent>
            <DialogActions
              className={classes.btnGroup}
              style={{ justifyContent: 'center' }}
            >
              <Tooltip title="Cancel">
                <button
                  className={classes.cancelBtn}
                  type="button"
                  onClick={handleClose}
                >
                  CANCEL
                </button>
              </Tooltip>
              <Tooltip title="Add Dock">
                <button className={classes.submitBtn} type="submit">
                  {dock?.id ? 'SAVE EDIT' : 'ADD DOCK'}
                </button>
              </Tooltip>
            </DialogActions>
          </form>
        </Grid>
      </Grid>
      <MessageSnackbar showMessage={showMessage} />
    </Dialog>
  );
};

export default AddDockView;

import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

import DetailTitle from '../../../atomicComponents/DetailTitle';
import DetailText from '../../../atomicComponents/DetailText';
import { constants } from '../invoiceManagement/constants';
import { formatPrice } from './InvoiceForm';

export const calculateTotal = (tax = false, invoiceItems) => {
  if (invoiceItems?.length > 0) {
    if (!tax)
      return invoiceItems
        .reduce(
          (a, b) => (b?.rate && b?.unit ? a + b?.rate * b?.unit : a + 0),
          0
        )
        .toFixed(2);
    else {
      return invoiceItems
        .reduce(
          (a, b) =>
            b.rate && b.unit
              ? a + b?.rate * b?.unit * ((b.tax?.rate || 0) / 100 + 1)
              : a + 0,
          0
        )
        .toFixed(2);
    }
  } else return 0;
};

const cumulativeTax = (invoiceItems, style) => {
  let taxes = {};
  invoiceItems.forEach((item) => {
    if (item.tax?.name && item.unit && item.rate) {
      if (taxes[`${item.tax.name}`]) {
        taxes[`${item.tax.name}`] =
          taxes[`${item.tax.name}`] +
          Number((item.rate * item.unit * (item?.tax?.rate / 100)).toFixed(2));
      } else {
        taxes[`${item.tax.name}`] = Number(
          (
            (item?.rate || 0) *
            (item?.unit || 0) *
            (item?.tax?.rate / 100)
          ).toFixed(2)
        );
      }
    }
  });

  const taxesKeys = Object.keys(taxes);

  if (taxesKeys.length == 0) {
    return (
      <Grid item container className="mt-6">
        <Grid item xs={6}>
          <Typography style={style}>Tax</Typography>
        </Grid>
        <Grid item xs={6} className="text-right">
          -
        </Grid>
      </Grid>
    );
  }

  if (taxesKeys.length == 1) {
    return (
      <Grid container className="mt-6">
        <Grid item xs={6}>
          <Typography style={style}>Tax</Typography>
        </Grid>
        <Grid item xs={6} className="text-right">
          <DetailText text={formatPrice(taxes[taxesKeys[0]])} />
        </Grid>
      </Grid>
    );
  } else {
    return taxesKeys.map((key, id) => (
      <Grid container className="mt-6" key={id}>
        <Grid item xs={6}>
          <Typography style={style}>{key}</Typography>
        </Grid>
        <Grid item xs={6} className="text-right">
          <DetailText text={formatPrice(taxes[key])} />
        </Grid>
      </Grid>
    ));
  }
};

export default function InvoiceSummary({ formik, invoiceItems, billing }) {
  let style = { fontWeight: 'bold', fontSize: '0.9rem' };
  return (
    <>
      <div className="rightBar ml-5">
        <DetailTitle text={billing ? 'Expense Summary' : 'Invoice Summary'} />
        <Grid item container className="mt-10">
          <Grid item xs={6}>
            <Typography style={style}>Type</Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <DetailText text={formik?.values?.invoiceType || '-'} />
          </Grid>
        </Grid>

        <Grid item container className="mt-6">
          <Grid item xs={6}>
            <Typography style={style}>Contract Type</Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <DetailText text={formik?.values?.contractType || '-'} />
          </Grid>
        </Grid>

        <Grid item container className="mt-6">
          <Grid item xs={6}>
            <Typography style={style}>Warehouse</Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <DetailText
              text={formik?.values?.warehouseID?.businessWarehouseCode || '-'}
            />
          </Grid>
        </Grid>

        <hr className="line-break mt-4 mb-4" />

        <Grid item container className="mt-6">
          <Grid item xs={4}>
            <Typography style={style}>
              {billing ? 'Vendor' : 'Customer'}
            </Typography>
          </Grid>
          <Grid item xs={8} className="text-right">
            <DetailText text={formik?.values?.company?.name || '-'} />
          </Grid>
        </Grid>
        {!billing && (
          <Grid item container className="mt-6">
            <Grid item xs={6}>
              <Typography style={style}>Billing Address</Typography>
            </Grid>
            <Grid item xs={6} className="text-right">
              <DetailText
                text={formik?.values?.company?.billingAddress || '-'}
              />
            </Grid>
          </Grid>
        )}

        <Grid item container className="mt-6">
          <Grid item xs={6}>
            <Typography style={style}>
              {billing
                ? 'Vendor Tax Identification Number (TIN)'
                : 'Customer Tax Identification Number (TIN)'}
            </Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <DetailText text={formik?.values?.company?.ntn || '-'} />
          </Grid>
        </Grid>

        <hr className="line-break mt-4 mb-4" />

        <Grid item container className="mt-6">
          <Grid item xs={6}>
            <Typography style={style}>
              {billing ? 'Bill ID' : 'Invoice ID'}
            </Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <DetailText text={formik?.values?.invoiceID || '-'} />
          </Grid>
        </Grid>

        <Grid item container className="mt-6">
          <Grid item xs={5}>
            <Typography style={style}>
              {billing ? 'Billing Period' : 'Invoice Period'}
            </Typography>
          </Grid>
          <Grid item xs={7} className="text-right">
            <DetailText
              text={
                formik?.values?.invoiceStartPeriod &&
                formik?.values?.invoiceEndPeriod
                  ? `${moment(formik?.values?.invoiceStartPeriod).format(
                      'DD/MM/yyyy'
                    )} - ${moment(formik?.values?.invoiceEndPeriod).format(
                      'DD/MM/yyyy'
                    )}`
                  : '-'
              }
            />
          </Grid>
        </Grid>

        <Grid item container className="mt-6">
          <Grid item xs={6}>
            <Typography style={style}>Due Date</Typography>
          </Grid>
          <Grid item xs={6} className="text-right date-font">
            <DetailText
              text={
                formik?.values?.due
                  ? moment(formik?.values?.due).format('DD-MM-yyyy')
                  : '-'
              }
            />
          </Grid>
        </Grid>

        <Grid item container className="mt-6">
          <Grid item xs={6}>
            <Typography style={style}>Reference ID</Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <DetailText text={formik?.values?.referenceID || '-'} />
          </Grid>
        </Grid>

        <hr className="dashedLineBreak mt-4 mb-4" />

        <Grid item container className="mt-6">
          <Grid item xs={6}>
            <Typography style={style}>Sub-Total</Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <DetailText
              text={formatPrice(calculateTotal(false, invoiceItems))}
            />
          </Grid>
        </Grid>

        {(invoiceItems && invoiceItems?.length == 0) ||
        !invoiceItems[0].tax?.name ? (
          <Grid item container className="mt-6">
            <Grid item xs={6}>
              <Typography style={style}>Tax</Typography>
            </Grid>
            <Grid item xs={6} className="text-right">
              -
            </Grid>
          </Grid>
        ) : (
          <>{cumulativeTax(invoiceItems, style)}</>
        )}

        <Grid item container className="mt-6" alignItems="center">
          <Grid item xs={6}>
            <Typography style={style}>Discount</Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <input
              value={formik?.values?.discount}
              onChange={(e) => {
                formik.setFieldValue('discount', e.target.value);
              }}
              type="number"
              className="number-input"
            />
          </Grid>
        </Grid>
        <Grid item container className="mt-6" alignItems="center">
          <Grid item xs={6}>
            <Typography style={style}>Adjustment</Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <input
              value={formik?.values?.adjustment}
              onChange={(e) => {
                formik.setFieldValue('adjustment', e.target.value);
              }}
              type="number"
              className="number-input"
            />
          </Grid>
        </Grid>

        <hr className="line-break mt-4 mb-4" />

        <Grid item container className="mt-6">
          <Grid item xs={7}>
            <Typography style={style}>Total (Tax Inclusive)</Typography>
          </Grid>
          <Grid item xs={5} className="text-right">
            <span>
              {formik.values.currency &&
                constants.CURRENCY[formik.values.currency]}{' '}
            </span>
            <span>
              {formatPrice(
                calculateTotal(true, invoiceItems) -
                  Number(formik.values.discount || 0) +
                  Number(formik.values.adjustment || 0)
              )}
            </span>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

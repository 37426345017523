import React, { useCallback, useEffect, useState, useContext } from 'react';
import { debounce } from 'lodash';
import { SharedContext } from '../../../../utils/common';
import { DEBOUNCE_CONST } from '../../../../Config';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { Box, Grid } from '@mui/material';
import addIcon from '../../../../assets/icons/addCard.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import CategoryListComponent from '../components/CategoryListComponent';
import { makeStyles, Tooltip } from '@material-ui/core';
import AddCategoryView from '../components/AddCategoryView';
import { checkPermission } from '../../../../utils/auth';
import { Paragraph } from '../../../../core-components/atoms/Text';
import CategoryView from '../components/CategoryView';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

const CategoryListView = ({
  searchValue,
  categories,
  setCategories,
  companyId
}) => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const toolTipClasses = useStylesBootstrap();
  const [open, setOpen] = React.useState(null);
  const [openView, setOpenView] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = useState();
  const { currentUser } = useContext(SharedContext);

  const getCategories = async (page, searchValue, oldData = []) => {
    try {
      const cols = ['name'];
      const res = await API.get(`categories`, {
        params: {
          page: page,
          limit: limit,
          search: searchValue,
          columns: cols,
          company_id: companyId
        }
      });
      const newArr = res?.data;
      setCategories([...oldData, ...newArr]);
      setPageCount(res?.pages);
    } catch (error) {
      onError(error);
    }
  };

  const getDelayedCategories = useCallback(
    debounce(
      async (page, searchValue, oldData) => {
        getCategories(page, searchValue, oldData);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedCategories(page, searchValue, []);
  }, [searchValue]);

  const handleClick = (event) => {
    setOpen(open ? null : event.currentTarget);
  };

  const handleClickView = (event) => {
    setOpenView(openView ? null : event.currentTarget);
  };

  return searchValue && categories.length <= 0 ? (
    <></>
  ) : (
    <Grid
      item
      flex={1}
      className={'border border-dark300 rounded-md h-[calc(100vh-280px)]'}
      style={{ boxSizing: 'border-box' }}
    >
      <Box>
        <AddCategoryView
          type="Category"
          route="categories"
          handleClose={() => {
            setOpen(null);
          }}
          item={selectedCategory}
          anchorEl={open}
          getCategories={getCategories}
          companyId={companyId}
          currentUser={currentUser}
        />
        <CategoryView
          anchorEl={openView}
          categoryDetail={selectedCategory}
          isBrand={false}
          handleClose={() => {
            setOpenView(null);
          }}
        />
        <div className="px-4 py-3 items-center border-b">
          <div className="flex justify-between items-center">
            <Paragraph
              variant={'md'}
              className={'text-dark500 flex min-w-[187px]'}
            >
              Category Name
            </Paragraph>
            <Paragraph
              variant={'md'}
              className={'text-dark500 flex min-w-[187px]'}
            >
              Products(s)
            </Paragraph>
            <div className="flex w-20 justify-end">
              {checkPermission(currentUser, 'OPS_CATEGORY_CREATE') && (
                <Tooltip
                  arrow
                  title="Add Category"
                  aria-label="add"
                  placement="top"
                  classes={toolTipClasses}
                >
                  <button
                    className="flex"
                    onClick={(event) => {
                      setSelectedCategory();
                      handleClick(event);
                    }}
                  >
                    <img src={addIcon} alt="add-icon" />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div
          id={'scrollableDiv'}
          className={
            'items-center overflow-auto no-scrollbar h-[calc(100vh-326px)]'
          }
        >
          <InfiniteScroll
            dataLength={categories?.length} //This is important field to render the next data
            next={() => {
              setPage(page + 1);
              getDelayedCategories(page + 1, searchValue, categories);
            }}
            hasMore={page < pageCount}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>Loading</b>
              </p>
            }
            refreshFunction={() => {
              setPage(1);
              setCategories([]);
              getDelayedCategories(1, searchValue, []);
            }}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8595; Pull down to refresh
              </h3>
            }
            scrollableTarget="scrollableDiv"
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {categories?.length > 0 &&
              categories.map((category, id) => {
                return (
                  <CategoryListComponent
                    data={category}
                    key={id}
                    searchValue={searchValue}
                    getCategories={getCategories}
                    onClick={(event) => {
                      setSelectedCategory(category);
                      handleClick(event);
                    }}
                    onClickView={(event) => {
                      setSelectedCategory(category);
                      handleClickView(event);
                    }}
                    editOpen={open}
                    viewOpen={openView}
                    currentUser={currentUser}
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </Box>
    </Grid>
  );
};

export default CategoryListView;

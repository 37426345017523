import { makeStyles } from '@material-ui/core';
import { PRIMARY, PRIMARY_DARK, PRIMARY_LIGHT } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  parentContainer: {
    boxSizing: 'border-box',
    width: '100% !important',
    padding: '0px 24px 40px 40px'
  },
  parentContainerForPrint: {
    boxSizing: 'border-box',
    padding: '30px 0px'
  },
  pageHeading: {
    fontWeight: 600
  },
  pageSubHeading: {
    fontWeight: 300
  },
  noWrap: {
    whiteSpace: 'break-spaces'
  },
  heading: {
    fontWeight: 'bolder'
  },
  shadedTableHeader: {
    backgroundColor: 'rgba(202,201,201,0.3)'
  },
  tableHeadText: {
    background: 'transparent',
    fontWeight: 'bolder',
    fontSize: '12px'
  },
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0
    }
  },
  customWidth: {
    maxWidth: 500,
    fontSize: 14
  },
  commentWrapper: {
    width: 105,
    maxWidth: 105,
    wordWrap: 'break-word'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  textBlue: {
    color: '#0C70D7',
    fontWeight: '700'
  },
  textGreen: {
    color: '#009514',
    fontWeight: '700'
  },
  smallButton: {
    padding: 'unset !important',
    width: 34,
    height: 34,
    borderRadius: '4px',
    border: '1px solid #CACBCD',
    minWidth: '0px',
    marginLeft: '8px',
    '&:hover': {
      background: '#EBF5FF',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    }
  },
  changeConditionDamagedBtn: {
    width: 160,
    fontSize: 11,
    marginLeft: 0
  },
  changeConditionDamagedBtnImg: {
    marginRight: 5
  },
  activeSmallButton: {
    padding: 'unset !important',
    width: 34,
    height: 34,
    border: '0px',
    background: '#0C70D7',
    minWidth: '0px',
    marginLeft: '8px',
    '&:hover': {
      background: '#0C70D7',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    },
    '& span': {
      filter: 'contrast(5) invert(1)'
    }
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    visibility: 'hidden'
  },
  actionButton: {
    padding: '8px 16px',
    borderRadius: '4px',
    border: '1px solid #67718C',
    color: '#0B1940',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    background: 'none',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    width: '107px',
    height: '40px',
    marginLeft: '8px'
  },
  header: {
    margin: '32px 24px 32px 40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dataActions: {
    display: 'flex',
    alignItems: 'center',
    width: '367px'
  },
  tableTitle: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.45px'
  },
  tabContainer: {
    marginLeft: 40,
    marginRight: 20,
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #C1D6EC'
    },
    '& .Mui-selected': {
      paddingBottom: '0px !important',
      borderBottom: '4px solid #0C71D7 !important'
    },
    '& .MuiTab-textColorPrimary': {
      height: '56px',
      minWidth: '0px',
      borderBottom: 'none',
      padding: '0px',
      color: 'black',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: 'capitalize',
      paddingBottom: '4px'
    },
    '& .MuiTouchRipple-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left'
    },
    '& .MuiTab-root': {
      marginRight: '24px'
    }
  },
  warehouseHall: {
    color: '#0B1940',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '0.17px',
    marginBottom: '4px'
  },
  warehouseSection: {
    color: '#0B1940',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '143%',
    letterSpacing: '0.17px',
    marginRight: '16px',
    '& span': {
      fontWeight: 400
    }
  },
  tabLabel: {
    display: 'flex'
  },
  blueDot: {
    marginLeft: 4,
    marginRight: 4,
    height: 6,
    width: 6,
    borderRadius: 6,
    backgroundColor: '#0C70D7;',
    animation: 'pulseAnimation 0.8s infinite'
  },
  primaryButton: {
    backgroundColor: `${PRIMARY} !important`,
    marginLeft: '4px',
    color: 'white !important',
    '&:hover': {
      backgroundColor: `${PRIMARY_DARK} !important`
    },
    '&:disabled': {
      backgroundColor: `${PRIMARY_LIGHT} !important`,
      color: 'white'
    },
    '&:disabled:hover': {
      backgroundColor: `${PRIMARY_LIGHT} !important`,
      color: 'white'
    }
  },
  modalBody: {
    width: '552px',
    padding: '32px !important'
  },
  alignRight: {
    marginLeft: 'auto !important'
  },
  closetn: {
    minWidth: '0px !important',
    position: 'absolute',
    width: '24px',
    height: '24px',
    right: '16px',
    top: '16px'
  },
  modalHeading: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '157%',
    letterSpacing: '0.1px',
    textTransform: 'capitalize'
  },
  subHeading: {
    color: '#6D6D6D',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '0.17px',
    marginBottom: '4px'
  },
  amount: {
    color: '#0B1940 !important',
    fontWeight: 500
  },
  inputBox: {
    display: 'flex',
    width: '100%',
    margin: '50px 0px',
    justifyContent: 'space-between',
    gap: '12px'
  },
  textField: {
    '& .MuiInputLabel-root': {
      marginTop: '0px',
      marginLeft: '0px',
      color: '#0B1940',
      // fontSize: "12px",
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400
    },
    '& .MuiFormLabel-root': {
      color: '#6D6D6D',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.15px',
      marginTop: '-7px !important',
      marginLeft: '10px !important '
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0C70D7',
      // fontSize: "12px",
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500
    },
    '& .MuiInputLabel-shrink': {
      marginTop: '0px !important',
      marginLeft: '0px !important',
      color: '#0B1940',
      // fontSize: "12px",
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #0B1940 !important'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #0C70D7 !important'
      }
    }
  },
  input: {
    borderRadius: '4px !important',
    // border: '1px solid #6D6D6D !important',
    background: '#FFF !important',
    // display: 'flex !important',
    padding: '8px 24px 8px 4px !important',
    // alignItems: 'center !important',
    width: '236px !important',
    height: '40px !important',
    color: '#0B1940 !important',
    fontSize: '16px !important',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: '400  !important',
    letterSpacing: '0.15px !important'
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  actions: {
    display: 'flex'
  },
  button: {
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'capitalize',
    padding: '4px 24px !important',
    borderRadius: '4px'
  },
  cancelButton: {
    color: PRIMARY,
    marginRight: '7px !important'
  },
  label: {
    '& .MuiFormControlLabel-label': {
      color: '#0B1940',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      marginLeft: '-4px'
    }
  },
  red: {
    color: 'red'
  },
  bold: {
    fontWeight: 600
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  summaryList: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 25
  },
  summaryItem: {
    marginBottom: 15
  },
  promoModalInputBox: {
    display: 'flex',
    width: '100%',
    margin: '40px 0px',
    justifyContent: 'space-between',
    gap: '12px'
  },
  promoInput: {
    borderRadius: '4px !important',
    // border: '1px solid #6D6D6D !important',
    background: '#FFF !important',
    // display: 'flex !important',
    padding: '8px 24px 8px 24px !important',
    // alignItems: 'center !important',
    width: '236px !important',
    height: '40px !important',
    color: '#0B1940 !important',
    fontSize: '16px !important',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontWeight: '400  !important',
    letterSpacing: '0.15px !important'
  },
  promoDialogFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  removeButton: {
    color: '#FF3B3B',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
  headingBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      marginRight: '4px',
      marginLeft: '-7px'
    }
  },
  text: {
    color: '#0B1940',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    marginTop: '16px',
    marginBottom: '40px'
  }
}));

export default useStyles;

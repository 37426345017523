import React, { useContext } from 'react';

import { SharedContext } from '../utils/common';

const LazyLoader = () => {
  const { apiLoader } = useContext(SharedContext);
  return (
    <>
      {apiLoader ? (
        <div className="spinner-container">
          <div className="loading-spinner" />
        </div>
      ) : null}
    </>
  );
};

export default LazyLoader;

import { Tooltip } from '@mui/material';
import { dateFormatSlashDivider } from '../../../utils/common';
import { BOOKING_STATUS, SOURCE } from '../../../utils/enums/bookingEnums';

const listingColumns = [
  {
    field: 'startEndDate',
    headerName: 'Start & End Date',
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Start & End Date</p>
    ),
    renderCell: (params) => {
      const startDate = dateFormatSlashDivider(params.row?.bookingStartDate);
      const endDate = dateFormatSlashDivider(params.row?.bookingEndDate);
      return (
        <Tooltip title={`${startDate} - ${endDate}`}>
          <span>{`${startDate} - ${endDate}`}</span>
        </Tooltip>
      );
    }
  },
  {
    field: 'pallets',
    headerName: 'Booked (Pallets)',
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Booked (Pallets)</p>
    ),
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.pallets}`}>
        <span>{`${params?.row?.pallets}`}</span>
      </Tooltip>
    )
  },
  {
    field: 'capacity',
    headerName: 'Booked (Sq ft)',
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Booked (Sq ft)</p>
    ),
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.pallets * 25}`}>
        <span>{`${params?.row?.pallets * 25}`}</span>
      </Tooltip>
    )
  },
  {
    field: 'minimumChargeableAmount',
    headerName: 'Minimum Chargeable %',
    flex: 2.5,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <div className="display-block">
        <p className="display-block">
          <p className="w-100 parentActions detailText">Minimum Chargeable %</p>
        </p>
        {/* <p className="display-block responsiveBookingTitle">
          <DetailTitle text="Chargeable %" />
        </p> */}
      </div>
    ),
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.minimumChargeableAmount || '-'}`}>
        <span>{`${params?.row?.minimumChargeableAmount || '-'}`}</span>
      </Tooltip>
    )
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Source</p>
    ),
    renderCell: (params) => (
      <Tooltip title={`${SOURCE[params?.row?.source]}`}>
        <span>{`${SOURCE[params?.row?.source]}`}</span>
      </Tooltip>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <p className="w-100 parentActions detailText">Status</p>
    ),
    renderCell: (params) => {
      const status = BOOKING_STATUS[params?.row?.status] || '-';
      let color = '';
      switch (params?.row?.status) {
        case 'ACTIVE':
          color = '#4EAD5B';
          break;
        case 'INACTIVE':
          color = '#FF3B3B';
          break;
        case 'CONFIRMATION_REQUIRED':
          color = '#DF8244';
          break;
        case 'CANCELLED':
          color = '#828081';
          break;
        default:
          color = 'black';
      }
      return (
        <Tooltip title={status}>
          <span style={{ color: color }}>{status}</span>
        </Tooltip>
      );
    }
  }
];

export default listingColumns;

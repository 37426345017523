/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  IconButton,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '../../../core-components/atoms/Button';
import TextField from '../../../core-components/atoms/TextField';
import { makeStyles } from '@material-ui/styles';

function Filters({
  options,
  onSearch,
  onFilterChange,
  filters,
  icon,
  buttonLabel = 'Filters',
  maxHeight = '300px'
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [search, setSearch] = useState('');
  const mainRef = useRef();
  const secondaryRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else handleClose();
  };

  const handleSubMenuClick = (event, index) => {
    setSubMenuAnchorEl(event.currentTarget.parentNode);
    setActiveIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
    setSearch('');
  };

  const useStyles = makeStyles((theme) => ({
    ListItem: {
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer',
      display: 'flex',
      gap: '8px'
    },
    popOver: {
      maxHeight,
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      pointerEvents: 'none'
    },
    search: {
      margin: '1rem auto',
      width: '90%',
      boxSizing: 'border-box',
      display: 'block'
    },
    paper: {
      pointerEvents: 'auto',
      width: '210px',
      border: '1px solid #E7E6E6',
      height: maxHeight,
      marginTop: '12px',
      marginRight: '16px'
    },
    subMenuPaper: {
      pointerEvents: 'auto',
      border: '1px solid #E7E6E6',
      height: maxHeight
    }
  }));

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  useEffect(() => {
    if (!open || (!activeIndex && activeIndex !== 0)) return;

    if (onSearch && search) {
      onSearch(options[activeIndex]?.label, search);
    }
  }, [search, open, activeIndex]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        secondaryRef.current &&
        !secondaryRef.current.contains(event.target)
      ) {
        handleClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Button
        onClick={handleClick}
        className="p-2 h-8 rounded border-solid"
        variant="transparent"
        overrideSize={true}
        labelClass="font-medium text-xs"
        label={buttonLabel}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        ref={mainRef}
        style={{ pointerEvents: 'none' }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <List>
          <p className="text-xs font-medium text-[#67718C] px-4 py-2">
            APPLY FILTERS TO THE TABLE
          </p>
          {options.map((option, i) => (
            <ListItem
              key={i}
              className={classes.ListItem}
              onMouseEnter={(e) => handleSubMenuClick(e, i)}
            >
              {option.icon && <img src={option.icon} alt={option.label} />}
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Popover>

      <Popover
        open={subMenuOpen}
        anchorEl={subMenuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className={classes.popOver}
        classes={{
          paper: classes.subMenuPaper
        }}
        PaperProps={{
          onMouseLeave: handleClose
        }}
        ref={secondaryRef}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <List style={{ width: '300px' }}>
          {activeIndex !== null && options[activeIndex]?.searchable && (
            <ListItem>
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </ListItem>
          )}
          {activeIndex !== null &&
            options[activeIndex]?.options.map((option, i) => (
              <ListItem
                key={i}
                className={classes.ListItem}
                onClick={() =>
                  onFilterChange(options[activeIndex].label, option)
                }
              >
                <Checkbox
                  onChange={(e) => {
                    e.stopPropagation();
                    onFilterChange(options[activeIndex].label, option);
                  }}
                  checked={
                    filters[options[activeIndex].label]?.some(
                      (f) => f.value === option.value
                    ) || false
                  }
                />
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
}

export default Filters;

/*eslint-disable*/
import {
  makeStyles,
  TextField,
  Grid,
  Button,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Checkbox
} from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { isRequired } from '../../../utils/validators';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { PRIMARY } from '../../../constants/colors';
import { toaster } from '../../../utils/toaster';
import { SharedContext } from '../../../utils/common';
import AddModalView from './AddPopupModal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    // maxHeight: 450,
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  searchInput: {
    border: '1px solid grey',
    borderRadius: 4,
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    height: 30
  },
  textBox: {
    '@media only screen and (max-width: 1400px) and (min-width:1200px)': {
      height: '30px'
    },
    '@media (min-width: 993px) and (max-width:1024px)': {
      height: '30px'
    },
    '@media only screen and (max-width: 992px) and (min-width:769px)': {
      height: '30px'
    },
    '@media only screen and (max-width: 768px) and (min-width:578px)': {
      height: '30px'
    }
  }
}));

function AddVehicleTypeView({
  selectedVehicleType,
  formErrors,
  open,
  handleClose,
  addVehicleType,
  attemptedAlready,
  setAttemptedAlready
}) {
  const [validation, setValidation] = useState({});
  const classes = useStyles();

  const [makeId, setCarMakeId] = useState('');
  const [carMakes, setCarMakes] = useState([]);

  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);

  const [subCategoryId, setSubCategoryId] = useState('');
  const [subCategories, setSubCategories] = useState([]);

  const [modelId, setCarModelId] = useState('');
  const [carModels, setCarModels] = useState([]);

  const [searchMakers, setSearchMakers] = useState('');
  const [searchModels, setSearchModels] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchSubCategory, setSearchSubCategory] = useState('');

  const [mileage, setMileage] = useState('');
  const [volume, setVolume] = useState('');
  const [weight, setWeight] = useState('');
  const [speed, setSpeed] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [selectedMake, setSelectedMake] = useState({});
  const [selectedModel, setSelectedModel] = useState({});
  const [selectCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const [addMakeViewOpen, setAddMakeViewOpen] = useState(false);
  const [addModelViewOpen, setAddModelViewOpen] = useState(false);
  const [addCategoryViewOpen, setAddCategoryViewOpen] = useState(false);
  const [addSubCategoryViewOpen, setAddSubCategoryViewOpen] = useState(false);

  const { setAPILoader, settings } = useContext(SharedContext);

  useEffect(() => {
    getMakers();
    getModels();
    getVehicleSubCategory();
    getVehicleCategory();

    if (open) resetLocalStates();
    if (selectedVehicleType) {
      const {
        CarMake,
        CarModel,
        VehicleType,
        isActive,
        SubCategory,
        Category
      } = selectedVehicleType;
      const { averageSpeed, mileage, volume, weight } = VehicleType;
      setCarMakeId(CarMake?.id || '');
      setSelectedMake(CarMake || '');
      setSelectedModel(CarModel || '');
      setCarModelId(CarModel?.id || '');
      setMileage(mileage || '');
      setSelectedCategory(Category || '');
      setCategoryId(Category?.id || '');
      setIsActive(isActive || false);
      setSpeed(averageSpeed || '');
      setVolume(volume || '');
      setWeight(weight || '');
      setSubCategoryId(SubCategory?.id || '');
      setSelectedSubCategory(SubCategory || '');
    } else {
      resetLocalStates();
    }
  }, [open]);

  const getMakers = async (search) => {
    try {
      const carMakers = await API.get(`vehicles/makers`, {
        params: { search: search || '' }
      });
      setCarMakes(
        carMakers.data.filter((carMake) => carMake.isActive === true)
      );
    } catch (err) {
      onError(err);
    }
  };

  const addMake = async (data) => {
    try {
      setAPILoader(true);
      setAddMakeViewOpen(false);
      await API.post(`vehicles/makers`, data);
      setAPILoader(false);
      toaster('success', 'Vehicle Make Created Successfully');
      getMakers();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  const getModels = async (search) => {
    try {
      const carModels = await API.get(`vehicles/models`, {
        params: { search: search || '' }
      });
      setCarModels(
        carModels.data.filter((carMake) => carMake.isActive === true)
      );
    } catch (err) {
      onError(err);
    }
  };
  const addModel = async (data) => {
    try {
      setAPILoader(true);
      setAddModelViewOpen(false);
      await API.post(`vehicles/models`, data);
      setAPILoader(false);
      toaster('success', 'Model Created Successfully');
      getModels();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  const getVehicleSubCategory = async (search) => {
    try {
      const vehicleSubCategories = await API.get(`vehicles/categories`, {
        params: { isSubCategory: true, search: search || '' }
      });
      setSubCategories(
        vehicleSubCategories?.data.filter(
          (category) => category.isActive === true
        )
      );
    } catch (err) {
      onError(err);
    }
  };
  const addSubCategory = async (data) => {
    try {
      setAPILoader(true);
      setAddSubCategoryViewOpen(false);
      await API.post(`vehicles/categories`, { ...data, isSubCategory: true });
      setAPILoader(false);
      toaster('success', 'Vehicle Sub Category Created Successfully!');
      getVehicleSubCategory();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  const getVehicleCategory = async (search) => {
    try {
      const vehicleCategories = await API.get(`vehicles/categories`, {
        params: { search: search || '' }
      });
      setCategories(
        vehicleCategories?.data.filter((category) => category.isActive === true)
      );
    } catch (err) {
      onError(err);
    }
  };

  const addCategory = async (data) => {
    try {
      setAPILoader(true);
      setAddCategoryViewOpen(false);
      await API.post(`vehicles/categories`, { ...data });
      setAPILoader(false);
      toaster('success', 'Vehicle Category Created Successfully!');
      getVehicleCategory();
    } catch (err) {
      setAPILoader(false);
      onError(err);
    }
  };

  useEffect(() => {
    getMakers(searchMakers);
  }, [searchMakers]);

  useEffect(() => {
    getModels(searchModels);
  }, [searchModels]);

  useEffect(() => {
    getVehicleCategory(searchCategory);
  }, [searchCategory]);

  useEffect(() => {
    getVehicleSubCategory(searchSubCategory);
  }, [searchSubCategory]);

  const resetLocalStates = () => {
    setCarMakeId('');
    setCarModelId('');
    setSelectedCategory({});
    setSelectedSubCategory({});
    setSelectedMake({});
    setSelectedModel({});
    setMileage('');
    setSpeed('');
    setVolume('');
    setWeight('');
    setValidation({});
  };

  const handleSubmit = async () => {
    const newVehicleType = {
      makeId: makeId,
      modelId: modelId,
      mileage,
      weight,
      volume,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      averageSpeed: speed,
      isActive
    };

    setValidation({
      makeId: true,
      modelId: true,
      categoryId: true,
      subCategoryId: true,
      mileage: true,
      volume: true,
      weight: true,
      speed: true
    });

    if (isRequired(makeId) && isRequired(modelId)) {
      setAttemptedAlready(true);
      try {
        if (selectedVehicleType)
          newVehicleType['vehicleTypeId'] = selectedVehicleType.VehicleType.id;
        await addVehicleType(newVehicleType);
      } catch (err) {
        // setFormErrors(<Alert elevation={6} variant="filled" severity="error" onClose={() => setFormErrors('')}>{res.data.message}</Alert>);
      }
    }
  };
  return (
    <div style={{ display: 'inline' }}>
      <AddModalView
        handleClose={() => {
          setAddMakeViewOpen(false);
        }}
        open={addMakeViewOpen}
        postData={addMake}
        type={'Make'}
      />
      <AddModalView
        handleClose={() => {
          setAddModelViewOpen(false);
        }}
        open={addModelViewOpen}
        postData={addModel}
        type={'Model'}
      />
      <AddModalView
        handleClose={() => {
          setAddCategoryViewOpen(false);
        }}
        open={addCategoryViewOpen}
        postData={addCategory}
        type={'Category'}
      />
      <AddModalView
        handleClose={() => {
          setAddSubCategoryViewOpen(false);
        }}
        open={addSubCategoryViewOpen}
        type={'Sub Category'}
        postData={addSubCategory}
      />
      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle disableTypography className="title">
            {!selectedVehicleType ? 'Add Vehicle Type' : 'Edit Vehicle Type'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="makeid"
                      options={[
                        ...carMakes,
                        {
                          id: '',
                          name: 'addMake',
                          isActive: true
                        }
                      ]}
                      defaultValue={
                        !!selectedVehicleType && !!selectedVehicleType?.CarMake
                          ? selectedVehicleType?.CarMake
                          : ''
                      }
                      renderOption={(props, option) => {
                        const { className, ...rest } = props;
                        return option?.name === 'addMake' ? (
                          <li
                            className="auto-complete-option"
                            style={{
                              color: PRIMARY,
                              fontSize: '16px',
                              fontWeight: 500
                            }}
                            onClick={() => {
                              setAddMakeViewOpen(true);
                            }}
                          >
                            + {'Add New Make'}
                          </li>
                        ) : (
                          <li {...rest} className="auto-complete-option">
                            <span>{option.name}</span>
                          </li>
                        );
                      }}
                      placeholder={'Vehicle Make'}
                      getOptionLabel={(carmake) => carmake.name || ''}
                      onBlur={() =>
                        setValidation({ ...validation, makeId: true })
                      }
                      value={selectedMake}
                      inputValue={searchMakers}
                      onInputChange={(event, newValue) => {
                        setSearchMakers(newValue);
                      }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setCarMakeId(newValue.id);
                          setSelectedMake(newValue);
                        }
                      }}
                    />
                    {validation.makeId && !isRequired(makeId) ? (
                      <Typography color="error">
                        Vehicle Make is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="modelid"
                      options={[
                        ...carModels,
                        { id: '', name: 'addModel', isActive: true }
                      ]}
                      defaultValue={
                        !!selectedVehicleType && !!selectedVehicleType?.CarModel
                          ? selectedVehicleType?.CarModel
                          : ''
                      }
                      renderOption={(props, option) => {
                        const { className, ...rest } = props;
                        return option?.name === 'addModel' ? (
                          <li
                            className="auto-complete-option"
                            style={{
                              color: PRIMARY,
                              fontSize: '16px',
                              fontWeight: 500
                            }}
                            onClick={() => {
                              setAddModelViewOpen(true);
                            }}
                          >
                            + {'Add New Model'}
                          </li>
                        ) : (
                          <li {...rest} className="auto-complete-option">
                            <span>{option.name}</span>
                          </li>
                        );
                      }}
                      value={selectedModel}
                      placeholder={'Vehicle Model'}
                      getOptionLabel={(carmodel) => carmodel.name || ''}
                      onBlur={() =>
                        setValidation({ ...validation, modelId: true })
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setCarModelId(newValue.id);
                          setSelectedModel(newValue);
                        }
                      }}
                      onInputChange={(event, newValue) => {
                        setSearchModels(newValue);
                      }}
                    />
                    {validation.modelId && !isRequired(modelId) ? (
                      <Typography color="error">
                        Vehicle Model is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="vehicleType">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="category"
                      options={[
                        ...categories,
                        { id: '', name: 'addCategory', isActive: true }
                      ]}
                      renderOption={(props, option) => {
                        const { className, ...rest } = props;
                        return option.name === 'addCategory' ? (
                          <li
                            className="auto-complete-option"
                            style={{
                              color: PRIMARY,
                              fontSize: '16px',
                              fontWeight: 500
                            }}
                            onClick={() => {
                              setAddCategoryViewOpen(true);
                            }}
                          >
                            + {'Add New Category'}
                          </li>
                        ) : (
                          <li {...rest} className="auto-complete-option">
                            <span>{option.name}</span>
                          </li>
                        );
                      }}
                      getOptionLabel={(category) => category.name || ''}
                      onBlur={() =>
                        setValidation({ ...validation, categoryId: true })
                      }
                      onInputChange={(event, newValue) => {
                        setSearchCategory(newValue);
                      }}
                      value={selectCategory}
                      placeholder={'Category'}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setCategoryId(newValue.id);
                          setSelectedCategory(newValue);
                        }
                      }}
                    />
                    {validation.categoryId && !isRequired(categoryId) ? (
                      <Typography color="error">
                        category is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    margin="dense"
                    fullWidth={true}
                    variant="outlined"
                  >
                    <Autocomplete
                      id="subCategory"
                      options={[
                        ...subCategories,
                        { id: '', name: 'addSubCategory', isActive: true }
                      ]}
                      renderOption={(props, option) => {
                        const { className, ...rest } = props;
                        return option.name === 'addSubCategory' ? (
                          <li
                            className="auto-complete-option"
                            style={{
                              color: PRIMARY,
                              fontSize: '16px',
                              fontWeight: 500
                            }}
                            onClick={() => {
                              setAddSubCategoryViewOpen(true);
                            }}
                          >
                            + {'Add New Sub Category'}
                          </li>
                        ) : (
                          <li {...rest} className="auto-complete-option">
                            <span>{option.name}</span>
                          </li>
                        );
                      }}
                      defaultValue={
                        !!selectedVehicleType &&
                        !!selectedVehicleType?.SubCategory
                          ? selectedVehicleType?.SubCategory
                          : ''
                      }
                      placeholder={'Sub Category'}
                      getOptionLabel={(subtype) => subtype.name || ''}
                      onBlur={() =>
                        setValidation({ ...validation, subCategoryId: true })
                      }
                      onInputChange={(event, newValue) => {
                        setSearchSubCategory(newValue);
                      }}
                      value={selectedSubCategory}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSubCategoryId(newValue.id);
                          setSelectedSubCategory(newValue);
                        }
                      }}
                    />
                    {validation.subCategoryId && !isRequired(subCategoryId) ? (
                      <Typography color="error">
                        Sub Category is required!
                      </Typography>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="vehicleType">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="weight"
                    label="Capacity In weight Kgs *"
                    type="number"
                    variant="outlined"
                    value={weight}
                    onChange={(e) => {
                      setWeight(e.target.value);
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, weight: true })
                    }
                  />
                  {validation.weight && !isRequired(weight) ? (
                    <Typography color="error">
                      Capacity In weight Kgs is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="volume"
                    label={`Capacity in Volume ${
                      ` ${settings?.volume?.symbol}` || ' CBM'
                    } *`}
                    type="number"
                    variant="outlined"
                    value={volume}
                    onChange={(e) => {
                      setVolume(e.target.value);
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, volume: true })
                    }
                  />
                  {validation.volume && !isRequired(volume) ? (
                    <Typography color="error">
                      Capacity in Volume is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} className="vehicleType">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="speed"
                    label="Average Speed *"
                    type="number"
                    variant="outlined"
                    value={speed}
                    onChange={(e) => {
                      setSpeed(e.target.value);
                    }}
                    onBlur={() => setValidation({ ...validation, speed: true })}
                  />
                  {validation.speed && !isRequired(speed) ? (
                    <Typography color="error">
                      Average Speed is required!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    margin="dense"
                    id="mileage"
                    label="Mileage *"
                    type="number"
                    variant="outlined"
                    value={mileage}
                    onChange={(e) => {
                      setMileage(e.target.value);
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, mileage: true })
                    }
                  />
                  {validation.mileage && !isRequired(mileage) ? (
                    <Typography color="error">Mileage is required!</Typography>
                  ) : (
                    ''
                  )}
                </Grid>

                <Grid item sm={12}>
                  <Checkbox
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  Active
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="vehicleTypeBtn">
            <Button
              onClick={handleClose}
              color="default"
              variant="contained"
              className="vehicleCancelBtn"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              disabled={attemptedAlready}
            >
              {!selectedVehicleType
                ? 'Add Vehicle Type'
                : 'Update Vehicle Type'}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

export default AddVehicleTypeView;

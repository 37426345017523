import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete
} from '@mui/material';
import { Typography } from '@material-ui/core';
import { debounce } from 'lodash';
import { useFormik } from 'formik';
import PercentIcon from '@mui/icons-material/Percent';
import moment from 'moment';

import warehouseBookingStyles from './warehouseBookingStyles';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { DEBOUNCE_CONST } from '../../../Config';
import { initialVals, valSchema } from './warehouseBookingFormik';
import DatePicker from '../../../components/DatePicker';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import FormikInputField from '../../../atomicComponents/FormikInputField';
import { toaster } from '../../../utils/toaster';
import { SharedContext, commaSeparator } from '../../../utils/common';
import clsx from 'clsx';

const AddWarehouseBooking = ({ open, handleClose, booking, getBookings }) => {
  const classes = warehouseBookingStyles();
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseSearchVal, setWarehouseSearchVal] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companySearchVal, setCompanySearchVal] = useState('');
  const [singleCompany, setSingleCompany] = useState(null);
  const [clickedAlready, setClickedAlready] = useState(false);

  const { setAPILoader } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: initialVals(booking),
    enableReinitialize: true,
    validationSchema: valSchema,
    onSubmit: (values) => submitForm(values)
  });

  const [dateFilter, setDateFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [range, setRange] = useState([
    {
      startDate: '',
      endDate: '',
      key: 'selection'
    }
  ]);

  useEffect(() => {
    if (formik?.values?.bookingStartDate && formik?.values?.bookingEndDate) {
      setStartDate(
        moment(formik?.values?.bookingStartDate).format('YYYY-MM-DD')
      );
      setEndDate(moment(formik?.values?.bookingEndDate).format('YYYY-MM-DD'));
    }
  }, [formik?.values?.bookingEndDate]);

  const getWarehouses = async (searchVal) => {
    try {
      const res = await API.get('warehouses', {
        params: {
          search: searchVal
        }
      });
      setWarehouses(res?.data);
    } catch (error) {
      onError(error);
    }
  };

  const getDelayedWarehouses = useCallback(
    debounce(
      async (warehouseSearchVal) => {
        getWarehouses(warehouseSearchVal);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedWarehouses(warehouseSearchVal);
  }, [warehouseSearchVal]);

  const getCompanies = async (searchVal) => {
    try {
      const res = await API.get('companies', {
        params: {
          search: searchVal,
          filters: {
            colVal: {
              isActive: 1
            }
          }
        }
      });
      setCompanies(res?.data?.rows);
      setSingleCompany(null);
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    if (companies?.length === 1 && !booking?.id && !companySearchVal.length) {
      formik.setFieldValue('customerId', companies[0].id);
      setSingleCompany(companies[0]);
    }
  }, [companies]);

  const getDelayedCompanies = useCallback(
    debounce(
      async (companySearchVal) => {
        getCompanies(companySearchVal);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedCompanies(companySearchVal);
  }, [companySearchVal]);

  const submitForm = async (values) => {
    const warehouseId = values?.warehouseId;
    const newBooking = {
      ...values
    };
    delete newBooking.warehouseId;
    if (booking?.id) {
      try {
        setAPILoader(true);
        await API.put(`warehouses/${warehouseId}/bookings/${booking?.id}`, {
          customerId: newBooking?.customerId,
          bookingStartDate: newBooking?.bookingStartDate,
          bookingEndDate: newBooking?.bookingEndDate,
          status: newBooking?.status
        });
        getBookings && getBookings();
        handleClose();
        toaster('success', 'Warehouse booking updated successfully');
      } catch (error) {
        onError(error);
      }
    } else {
      try {
        setClickedAlready(true);
        await API.post(`warehouses/${warehouseId}/bookings`, newBooking);
        getBookings && getBookings();
        handleClose();
        toaster('success', 'New Warehouse booking has been created.');
      } catch (error) {
        onError(error);
      } finally {
        setClickedAlready(false);
        setAPILoader(false);
      }
    }
  };

  return (
    <Dialog
      className={classes.backdrop}
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{ sx: { width: '898px' } }}
    >
      <Grid container>
        <Grid item xs={12} className="addBooking">
          <DialogTitle
            sx={{ marginTop: '32px', marginLeft: '5px' }}
            className="headingBooking"
          >
            <Typography className={classes.title}>
              {booking?.id ? 'Edit Booking' : 'Add Booking'}
            </Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={12}>
          <DialogContent className="bookingDialogContent">
            <form noValidate onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    className="formikAutocompleteField"
                    id="warehouseId"
                    options={warehouses}
                    getOptionLabel={(options) => {
                      return options?.businessWarehouseCode || '';
                    }}
                    defaultValue={
                      booking?.id
                        ? booking.Warehouse
                        : {
                            name:
                              warehouses?.find(
                                (el) => el?.id === formik.values['warehouseId']
                              )?.businessWarehouseCode || ''
                          }
                    }
                    onBlur={formik?.handleBlur}
                    onChange={(e, val) => {
                      if (val) {
                        formik.setFieldValue(
                          'warehouseId',
                          val !== null && val.id
                        );
                        setWarehouseSearchVal('');
                      } else {
                        formik.setFieldValue('warehouseId', '');
                        setWarehouseSearchVal('');
                      }
                    }}
                    onKeyUp={(event) => {
                      setWarehouseSearchVal(event.target.value);
                    }}
                    disabled={booking?.Warehouse?.id ? true : false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Warehouse"
                        placeholder="Select"
                      />
                    )}
                  />
                  {formik?.errors['warehouseId'] &&
                    formik?.touched['warehouseId'] && (
                      <Typography color="error">
                        Warehouse is required!
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    className="formikAutocompleteField"
                    id="customerId"
                    options={companies}
                    getOptionLabel={(options) => {
                      return options?.name || '';
                    }}
                    value={
                      booking?.id ? booking?.Customer : singleCompany || null
                    }
                    defaultValue={''}
                    onBlur={formik?.handleBlur}
                    onChange={(e, val) => {
                      if (val) {
                        setSingleCompany(val);
                        formik.setFieldValue(
                          'customerId',
                          val !== null && val.id
                        );
                      } else {
                        setSingleCompany(null);
                        formik.setFieldValue('customerId', null);
                      }
                    }}
                    onKeyUp={(event) => {
                      setCompanySearchVal(event.target.value);
                    }}
                    disabled={booking?.id ? true : false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Company"
                        placeholder="Select"
                      />
                    )}
                  />
                  {formik?.errors['customerId'] &&
                    formik?.touched['customerId'] && (
                      <Typography color="error">
                        Company is required!
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={8.5} md={6} lg={6}>
                  <div className={classes.itemContainer}>
                    <DetailTitle text="Start & End Date" />
                    <DatePicker
                      bookingContainer={true}
                      smallContainer={false}
                      setDateFilter={(value) => setDateFilter(value)}
                      setStartDate={(value) => {
                        setStartDate(value);
                        formik?.setFieldValue('bookingStartDate', value);
                      }}
                      setEndDate={(value) => {
                        setEndDate(value);
                        formik?.setFieldValue('bookingEndDate', value);
                      }}
                      setRange={(value) => setRange(value)}
                      dateFilter={dateFilter}
                      startDate={startDate}
                      endDate={endDate}
                      range={range}
                    />
                    {formik?.errors?.bookingEndDate &&
                      formik?.touchederrors?.bookingEndDate && (
                        <Typography color="error">
                          {formik?.errors?.bookingEndDate}
                        </Typography>
                      )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.sectionSeparator}>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <Typography
                          className={`${classes.heading} ${
                            booking?.id && classes.disabled
                          }`}
                        >
                          <DetailTitle text="Capacity Booked (Pallets)" />
                        </Typography>
                        <FormikInputField
                          formik={formik}
                          name="pallets"
                          type="number"
                          disabled={booking?.id ? true : false}
                          size="small"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{ marginLeft: '12px' }}
                        className="bookingCapacity"
                      >
                        <Typography
                          className={`${classes.heading} ${
                            booking?.id && classes.disabled
                          }`}
                        >
                          Capacity Booked (Sq ft)
                        </Typography>
                        <p
                          className={`${classes.capacityText} ${
                            booking?.id && classes.disabled
                          }`}
                        >
                          {commaSeparator(formik?.values?.pallets * 25 || 0)}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography
                          className={`${classes.heading} ${
                            booking?.id && classes.disabled
                          }`}
                        >
                          Minimum Chargeable %
                        </Typography>
                        <div className={classes.minimumChargeableContainer}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={booking?.id ? true : false}
                                checked={
                                  formik?.values?.minimumChargeableStatus
                                }
                                onChange={() => {
                                  formik.setFieldValue(
                                    'minimumChargeableStatus',
                                    !formik.values.minimumChargeableStatus
                                  );
                                }}
                              />
                            }
                            label=""
                            labelPlacement="end"
                          />
                          <FormikInputField
                            name="minimumChargeableAmount"
                            formik={formik}
                            size="small"
                            disabled={
                              booking?.id
                                ? true
                                : !formik.values.minimumChargeableStatus
                            }
                            type="number"
                            IconEnd={<PercentIcon />}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4} md={1} className="ml-2">
                        <Typography className={classes.heading}>
                          Status
                        </Typography>
                        <div className="ml-2 mt-3">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formik?.values?.status === 'ACTIVE'
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  let value =
                                    formik?.values?.status === 'ACTIVE'
                                      ? 'INACTIVE'
                                      : 'ACTIVE';
                                  formik.setFieldValue('status', value);
                                }}
                              />
                            }
                            label="Active"
                            labelPlacement="end"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid container className={classes.actionBtnContainer}>
                <Grid item xs={12} sm={6}>
                  <button
                    className={classes.cancelBtn}
                    type="button"
                    onClick={handleClose}
                  >
                    CANCEL
                  </button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <button
                    className={clsx('text-[13.5px]', {
                      'submitBtn no-drop': clickedAlready,
                      submitBtn: !clickedAlready
                    })}
                    type="submit"
                    disabled={clickedAlready}
                  >
                    {booking?.id ? 'SAVE' : 'CONFIRM BOOKING'}
                  </button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddWarehouseBooking;

import React, { useEffect, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import * as XLSX from 'xlsx';

import addIconBig from '../assets/icons/addIconBig.svg';
import fileIcon from '../assets/icons/fileIcon.svg';

function BulkFileAttacher({ bulkUpload, selectedFile, setSelectedFile }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectedFile) handleFileUpload(selectedFile);
  }, [selectedFile]);

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    const uploads = list;
    bulkUpload(uploads);
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div className="w-100px">
      <Tooltip title="Upload Bulk">
        <div>
          <button
            type="button"
            className="upload-image-btn"
            onClick={() => inputRef.current.click()}
          >
            <img src={selectedFile ? fileIcon : addIconBig} alt="add-icon" />
          </button>
          <input
            ref={inputRef}
            hidden
            type="file"
            onClick={(e) => {
              setSelectedFile(null);
              e.target.value = null;
            }}
            onChange={(e) => {
              setSelectedFile(e);
            }}
            accept=".csv,.xlsx,.xls"
          />
        </div>
      </Tooltip>
    </div>
  );
}

export default BulkFileAttacher;

/*eslint-disable*/
import { useEffect, useState, useContext, useRef } from 'react';
import {
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { SharedContext } from '../../../utils/common';
import clsx from 'clsx';
import { convertToTitleCase } from '../../revampedOrder/AddOrder';
import { useQuery } from 'react-query';
import CustomTextField from '../../../core-components/atoms/TextField';
import { Search } from '@material-ui/icons';
import { getProducts, getRelocations } from './apis';
import Filters from './Filter';
import Button from '../../../core-components/atoms/Button';
import { useNavigate } from 'react-router';
import bulkIcon from '../../../assets/icons/bulk-upload.svg';

const usableColumnNames = [
  'Warehouse',
  'Product',
  'Type',
  'Source Section',
  'Destination Section',
  'Batch',
  'Quantity'
];

const StockRelocationRevamped = () => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [relocations, setRelocations] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({
    Product: [],
    Warehouse: []
  });
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');

  const { setCurrentPageTitle, setAPILoader } = useContext(SharedContext);
  const tableContainerRef = useRef();

  const { refetch, isFetching } = useQuery(
    ['fetchRelocations'],
    () => {
      return _getSectionRelocations();
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data) {
          if (page === 1) {
            setRelocations(data.data);
            setPageCount(data.pages);
          } else {
            setRelocations([...relocations, ...data.data]);
            setPageCount(data.pages);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const fetchProducts = async (search) => {
    const prods = await getProducts(search);
    setProducts(prods.data);
  };

  const _getSectionRelocations = async () => {
    let columns = [
      '$Company.name$',
      '$Warehouse.businessWarehouseCode$',
      '$Product.description$',
      '$sourceHall.name$',
      '$destinationHall.name$',
      'batchName'
    ];

    const filters = {
      productIds: currentFilters?.Product?.map((p) => p.value) || [],
      warehouseIds: currentFilters?.Warehouse?.map((w) => w.value) || []
    };
    return await getRelocations(page, search, columns, filters);
  };

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !isFetching &&
      page < pageCount
    ) {
      setPage(page + 1);
    }
  };

  const handleSearch = (label, searchQuery) => {
    if (label === 'Product') {
      fetchProducts(searchQuery);
    } else if (label === 'Warehouse') {
      // fetchWarehouses(searchQuery);
    }
  };

  const handleFilterChange = (label, option) => {
    let clonedFilters = {
      ...currentFilters
    };

    const existingFilters = clonedFilters[label] || [];
    const isSelected = existingFilters.some((f) => f.value === option.value);

    setCurrentFilters({
      ...clonedFilters,
      [label]: isSelected
        ? existingFilters.filter((f) => f.value !== option.value)
        : [...existingFilters, option]
    });
  };

  const tableCellClass = 'py-2 h-12 pl-0 pr-2';
  const cellValue = 'table-cell-trucate  text-normal';
  const cellSubValue = clsx(cellValue, 'text-[10px]');
  const topValue = clsx(cellValue, 'mb-[2px]', 'text-xs');

  const filterOptions = [
    {
      label: 'Product',
      searchable: true,
      options: products.map((prod) => ({
        label: prod?.name || prod?.description,
        value: prod?.id
      }))
    }
  ];

  useEffect(() => {
    setCurrentPageTitle('Section Relocations');
    fetchProducts();
  }, []);

  useEffect(() => {
    if (isFetching) {
      setAPILoader(true);
    } else {
      setAPILoader(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (
      page > 1 ||
      Object.keys(currentFilters).reduce(
        (acc, curr) => acc || curr.length > 0,
        false
      )
    )
      refetch();
  }, [page, search, currentFilters]);

  const navigate = useNavigate();

  return (
    <Paper className="no-scrollbar rounded-t-lg shadow-none p-8">
      <div className="flex justify-end mr-4 gap-2">
        <Button
          label="Bulk Upload"
          onClick={() => {
            navigate('/operations/section-relocation/bulk-create');
          }}
          className="p-2 h-8 rounded border-solid"
          variant="transparent"
          overrideSize={true}
          labelClass="font-medium text-xs"
          icon={<img src={bulkIcon} className="mr-1" />}
        />
        <Filters
          options={filterOptions}
          onSearch={handleSearch}
          onFilterChange={handleFilterChange}
          filters={currentFilters}
          buttonLabel="Filters"
        />
        <CustomTextField
          placeholder="Search table"
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sxProps={{
            '& .MuiInputBase-root': {
              padding: '4px 16px !important',
              fontSize: '12px',
              height: 32
            },
            '& input::placeholder': {
              fontSize: '12px'
            },
            '& input': {
              padding: '0px !important'
            },
            '& textarea::placeholder': {
              fontSize: '12px'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search
                  style={{
                    width: '16px',
                    height: '16px',
                    marginRight: '8px'
                  }}
                />
              </InputAdornment>
            )
          }}
        />
      </div>
      <TableContainer
        style={{ overflow: 'auto' }}
        className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
        onScroll={handleTableScroll}
        ref={tableContainerRef}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {usableColumnNames.map((columnName, index) => (
                <TableCell
                  key={index}
                  className={`text-[10px] text-dark500 bg-white py-2 ${
                    index == 0 ? 'pl-4' : 'pl-0'
                  } pr-2 font-normal`}
                >
                  {columnName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {relocations?.map((row, index) => (
              <TableRow
                key={index}
                className={clsx(
                  'hover:shadow-elevation-1 hover:z-20 hover:relative'
                )}
              >
                <TableCell
                  className={`${tableCellClass} group-hover:text-primaryBlue`}
                >
                  <p className="text-xs">
                    <span className={clsx('mr-2 pl-4', 'text-primaryBlue')}>
                      {row?.Warehouse?.name}
                    </span>{' '}
                  </p>
                  <p className={clsx(cellSubValue)}>
                    <span
                      className={clsx('mr-2 pl-4 mt-2', 'text-primaryBlue')}
                    >
                      {row?.Warehouse?.businessWarehouseCode}
                    </span>{' '}
                  </p>
                </TableCell>
                <TableCell
                  className={`${tableCellClass} group-hover:text-primaryBlue`}
                >
                  <p className={clsx(topValue, 'text-primaryBlue')}>
                    {row?.Product?.name}
                  </p>
                  <p className={clsx(cellSubValue)}>
                    <span className={clsx('mr-2 mt-2', 'text-primaryBlue')}>
                      {row?.Product?.description}
                    </span>{' '}
                  </p>
                </TableCell>
                <TableCell className={`${tableCellClass} text-primaryBlue`}>
                  <p className={'text-xs'}>{convertToTitleCase(row?.type)}</p>
                </TableCell>
                <TableCell className={`${tableCellClass} text-primaryBlue`}>
                  <p className={'text-xs'}>{row?.sourceHall?.name}</p>
                </TableCell>
                <TableCell className={`${tableCellClass} text-primaryBlue`}>
                  <p className={'text-xs'}>{row?.destinationHall?.name}</p>
                </TableCell>
                <TableCell className={`${tableCellClass} text-primaryBlue`}>
                  <p className={'text-xs'}>{row?.batchNumber || '-'}</p>
                </TableCell>
                <TableCell className={`${tableCellClass} text-primaryBlue`}>
                  <p className={'text-xs'}>{row?.quantity}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StockRelocationRevamped;

export default {
  INBOUND_ORDER_CREATION: 'inboundOrderCreation',
  OUTBOUND_ORDER_CREATION: 'outboundOrderCreation',
  TRANSFER_ORDER_CREATION: 'transferOrderCreation',
  LOGISTICS_ORDER_CREATION: 'logisticsOrderCreation',
  PURCHASE_ORDER_CREATION: 'purchaseOrderCreation',
  SALES_ORDER_CREATION: 'salesOrderCreation',
  SALES_RETURN_ORDER_CREATION: 'salesReturnOrderCreation',
  ORDERS_BULK_UPLOAD: 'ordersBulkUpload',
  BASIC_SHIPMENT_CREATION: 'basicShipmentCreation',
  CLOSE_ORDER: 'closeOrder',
  ORDER_AND_SHIPMENT_STATUS_TRACKING: 'orderAndShipmentStatusTracking',
  ORDER_CANCELLATION: 'orderCancellation',
  SHIPMENT_CANCELLATION: 'shipmentCancellation',
  ADD_ORDER_SUPPORTING_DOCUMENT: 'addOrderSupportingDocument',
  DOCUMENT_MANAGEMENT_MODULE: 'documentsManagementModule',
  EXPENSE_MODULE: 'expenseModule',
  COLLABORATION_TOOL: 'collaborationTool',
  ADVANCED_SHIPMENT_CREATION: 'advancedShipmentCreation',
  STOCK_ADJUSTMENT: 'stockAdjustment',
  STOCK_CONDITION_MANAGEMENT: 'stockCondition',
  CREATING_CUSTOMER_MASTER: 'creatingCustomerMaster',
  WAREHOUSE_CREATION: 'warehouseCreation',
  PRODUCT_HIERARCHY_CREATION: 'productHierarchyCreation',
  INVENTORY_DETAILS_TABLE: 'inventoryDetailsTable',
  GRN_CREATION: 'grnCreation',
  GDN_CREATION: 'gdnCreation',
  UNIFIED_DASHBOARD: 'unifiedDashboard',
  SUPPLY_CHAIN_NETWORK_MAP: 'supplyChainNetworkMap',
  INVENTORY_LEDGER_REPORT: 'inventoryLedgerReport',
  STOCK_RELOCATION: 'stockRelocation',
  PRODUCT_QR_GENERATOR: 'productQrGenerator',
  DOCK_CREATION_AND_ASSIGNMENT: 'dockCreationAndAssignment',
  PICKING: 'picking',
  PUTAWAY: 'putaway',
  PICKING_TASK_COMPLETION: 'pickingTaskCompletion',
  LOCATION_HIERARCHY: 'locationHierarchy',
  CYCLE_COUNT: 'cycleCount',
  QUICK_BOOKS_INTEGRATION: 'quickNooksIntegration',
  LAND_TRACKING_INTEGRATION: 'landTrackerIntegration',
  BILLING_AND_INVOICING: 'billingAndInvoicing',
  USER_CREATION: 'userCreation',
  ROLES_CREATION: 'rolesCreation',
  USER_PERMISSION_ACCESS: 'userPermissionAccess',
  TRIGGER_BASED_NOTIFICATIONS: 'triggerBasedNotifications',
  O_FRIEND: 'oFriend',
  INVENTORY_HOLD: 'inventoryHold',
  INTELLIGENCE_MODULE: 'intelligenceModule',
  NOTIFICATION_CONSOLE: 'notificationConsole',
  BATCH_AND_EXPIRY_TRACKING: 'batchAndExpiryTracking',
  OMS: 'oms',
  TMS: 'tms',
  WMS: 'wms'
};

export const statuses = {
  IN_PROGRESS: 'In Progress',
  PARTIALLY_COMPLETE: 'Partially Complete',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  CLOSED: 'Closed',
  DRAFT: 'Draft',
  DOCK_ASSIGNED: 'In Progress'
};

export const OrderTypeMapping = {
  INWARD: 'Inbound',
  OUTBOUND: 'Outbound',
  TRANSFER: 'Transfer',
  LOGISTIC: 'Logistic',
  SALES: 'Sales',
  PURCHASE: 'Purchase',
  SALES_RETURN: 'Sales Return'
};

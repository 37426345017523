import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '30px 30px',
    width: '100% !important',
    border: 'none'
  },
  padding12: {
    padding: '12px 12px 0px !important'
  },
  hidden: {
    visibility: 'hidden'
  },
  previewHeading: {
    height: '15vh'
  },
  padding5mm: {
    padding: '5mm 5mm 0mm 5mm'
  },
  pageHeading: {
    fontWeight: 600,
    fontSize: '16px'
  },
  baseColor: {
    color: '#4B89C8'
  },
  warningColor: {
    color: '#FF3B3B'
  },
  printHeaders: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.0025em',
    color: '#000000',
    marginRight: '5px'
  },
  head: {
    background: '#EDF3FA',
    width: '100%'
  },
  visibilityNone: {
    visibility: 'hidden'
  },
  leftBorder: {
    'border-left': 'none'
  },
  padding0: {
    padding: '0 0 0 16px !important'
  },
  margin0: {
    margin: '0 5px 0 0 !important'
  },
  disableGrid: {
    pointerEvents: 'none',
    opacity: 0.4
  },
  soon: {
    position: 'absolute',
    top: '125px',
    fontSize: 'x-large',
    left: '67%'
  },
  buttonPosition: {
    position: 'absolute',
    bottom: '8px',
    right: '22px'
  },
  maxHeight: {
    maxHeight: '500px'
  }
}));

export default useStyles;

import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@mui/material';
import { Title } from '../../../core-components/atoms/Text';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { toaster } from '../../../utils/toaster';
import { checkValidationErr } from '../../../services/BulkUploadServices';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import { companyTypes } from '../constants';
import FormikTextField from '../../../core-components/molecules/FormikTextField';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px #E8ECF1',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: '16px 16px',
    alignItems: ' flex-start',
    boxShadow:
      '0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.10)'
  },
  dockDialog: {
    // padding: '10px 0px 0px !important'
  },
  btnGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  dockInputField: {
    width: '270px'
  },
  switchButton: {
    marginTop: 16
  },
  cancelBtn: {
    padding: '8px 12px',
    // gap: '6px',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#0B1940'
  },

  submitBtn: {
    padding: '8px 12px',
    background: '#0C6BD7',
    borderRadius: 4,
    cursor: 'pointer',
    color: '#FFFFFF',
    '&:disabled': {
      background: 'rgba(12,107,215,0.5)'
    }
  }
}));

const CreateCustomer = ({
  setOpen,
  getCustomer,
  getNodes,
  setEntityTypeOpen,
  customerOnly = false
}) => {
  const classes = useStyles();
  const { setAPILoader, organizationType } = useContext(SharedContext);
  const formik = useFormik({
    initialValues: {
      name: '',
      customerType: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(`Customer name is required`),
      customerType: Yup.object().required(`Customer type is required`)
    }),
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  const submitForm = async (values) => {
    setAPILoader(true);
    try {
      let payload = {
        ...values,
        type: values.customerType?.value,
        isSupplier: false,
        isActive: true
      };
      await API.post(`companies`, payload);
      toaster('success', `New Customer has been created.`);
      formik.resetForm();
      setOpen(false);
      getCustomer();
      if (!customerOnly) {
        setEntityTypeOpen(false);
        getNodes();
      }
    } catch (error) {
      checkValidationErr(
        error,
        `unique_customer_warehouseId`,
        `Customer already exists it must be unique`
      );
    } finally {
      setAPILoader(false);
    }
  };

  const orgCompanyTypes = organizationType
    ? organizationType == 'THIRD_PARTY_SERVICE_PROVIDER'
      ? Object.keys(companyTypes['3PL']).map((key) => ({
          value: key,
          label: companyTypes['3PL'][key]
        }))
      : Object.keys(companyTypes.MANUFACTURER).map((key) => ({
          value: key,
          label: companyTypes.MANUFACTURER[key]
        }))
    : [];

  return (
    <div className={classes.paper}>
      <div className="mb-4">
        <Title className={'text-base'}>Create New Customer</Title>
      </div>
      <div className={classes.dockDialog}>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className={classes.dockInputField}
        >
          <FormControl fullWidth>
            <FormikTextField
              placeholder="Customer Name"
              name="name"
              formik={formik}
              sxProps={{
                '& .MuiInputBase-root': {
                  fontSize: '14px',
                  height: 36
                },
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                }
              }}
            />
          </FormControl>

          <Autocomplete
            className="mt-5"
            id="customerType"
            options={orgCompanyTypes}
            onChange={async (e, value) => {
              if (value) {
                formik.setFieldValue('customerType', value);
              } else {
                formik.setFieldValue('customerType', '');
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values?.customerType || ''}
            getOptionLabel={(type) => type?.label || ''}
            placeholder="Customer Type"
          />
          {formik.touched?.customerType &&
            formik.errors?.customerType?.value && (
              <span className="text-error text-xs">
                {formik.errors?.customerType?.value}
              </span>
            )}

          <div className={classes.btnGroup}>
            <button
              className={classes.cancelBtn}
              type="button"
              onClick={() => {
                setOpen(false);
                if (!customerOnly) setEntityTypeOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className={classes.submitBtn}
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
            >
              {`Create`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCustomer;

/*eslint-disable*/
import React from 'react';
import TextField from '../../core-components/atoms/TextField';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';

export const cancellationReasons = [
  'Incomplete documentation',
  'Customer not prepared',
  'Customer QC not met',
  'Cancelled by Driver',
  'Unforeseen circumstances(Accident, conditions of the city etc)',
  'Vehicle not available',
  'Rates are not competitive',
  'Short deadline'
];

const CancelShipment = ({ formik }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            column
            value={formik.values.cancelReason}
            onChange={(e) =>
              formik.setFieldValue('cancelReason', e.target.value)
            }
          >
            {cancellationReasons.map((reason) => (
              <FormControlLabel
                value={reason}
                sx={{ mr: '1rem', paddingLeft: '8px', mt: '0.5rem' }}
                control={<Radio size="small" />}
                label={reason}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} className="mt-10">
        <FormControl fullWidth>
          <TextField
            value={formik.values.cancelReasonComment}
            onChange={(e) =>
              formik.setFieldValue('cancelReasonComment', e.target.value)
            }
            placeholder={'Add Any other reasons here'}
            multiline
            rows={4}
            maxLength={100}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CancelShipment;

import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import { checkPermission } from '../../../utils/auth';

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import Filter from '../../../components/Filter';
import TableHeader from '../../../components/TableHeader';

import EditIcon from '@material-ui/icons/EditOutlined';
import { debounce } from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { DEBOUNCE_CONST } from '../../../Config';
import '../../../assets/css/style.css';
import CustomToolbar from '../../../components/CustomToolbar';
import PaginationGrid from '../../../components/PaginationGrid';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';
import { toaster } from '../../../utils/toaster';
import AddCityView from './AddCityView';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px'
  },
  container: {
    padding: 20
  },
  searchInputCity: {
    opacity: 0.6,
    padding: '0px 8px',
    marginRight: 7,
    transition: '0.5s',
    width: '45px',
    height: '36px',
    borderRadius: '20px',
    border: '1px solid lightgray'
  },
  addCityButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold'
  },
  active: {
    color: theme.palette.success.main
  }
}));

export default function CityView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { country_id, region_id } = useParams();

  const columnsNew = [
    {
      field: 'isActive',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      headerName: 'City name',
      flex: 1,
      disableColumnMenu: true,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      sortable: false,
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            City Name
            {fetchState && nameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  onClick={() => {
                    setName('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',

      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_CITY_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon key={params} onClick={() => openEditView(params.row)} />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [cities, setCities] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterView, setFilterView] = useState(false);
  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  const [nameVal, setName] = useState('');
  const [fetchState, setFetchState] = useState(false);
  const [sortable, setSortable] = useState('');
  const [addCityViewOpen, setAddCityViewOpen] = useState(false);
  const [formErrors, setFormErrors] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    setCurrentPageTitle('City');
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    fetchRes();
  }, [nameVal, sortable]);
  const { currentUser } = useContext(SharedContext);

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (nameVal) {
      colVal = { name: nameVal };
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = ['name'];

    _getCities(page, searchKeyword, filters, columns);
  };

  const _getCities = async (page, searchKeyword, filters, columns) => {
    try {
      const cities = await API.get(
        `countries/${country_id}/regions/${region_id}/cities`,
        { params: { offset: page, search: searchKeyword, filters, columns } }
      );
      setPageCount(cities.pages);
      setCities(cities.data);
    } catch (err) {
      onError(err);
    }
  };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
  };

  const openEditView = (region) => {
    setSelectedCity(region);
    setAddCityViewOpen(true);
  };

  const addCity = async (data) => {
    setFormErrors('');
    try {
      setAPILoader(true);
      if (!selectedCity) await API.post(`cities`, data);
      else await API.put(`cities/${selectedCity.id}`, data);

      toaster(
        'success',
        !selectedCity
          ? 'New City has been created.'
          : 'City updated successfully.'
      );
      closeAddCityView();
      _getCities(page, nameVal);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const closeAddCityView = () => {
    setSelectedCity(null);
    setAddCityViewOpen(false);
  };

  const addCityModal = (
    <AddCityView
      key={4}
      formErrors={formErrors}
      selectedCity={selectedCity}
      open={addCityViewOpen}
      addCity={addCity}
      region_id={region_id}
      handleClose={() => closeAddCityView()}
    />
  );

  useEffect(() => {
    getCities(page, searchKeyword);
  }, [page, searchKeyword]);

  const getCities = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        name: searchKeyword ? searchKeyword : '',
        sortable
      };

      let columns = ['name'];
      _getCities(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  let nameObj = {
    type: 'text',
    className: 'textField',
    label: 'City Name',
    field: 'name',
    value: nameVal ? nameVal : '',
    setNameVal: (e) => setNameVal(setName, e)
  };

  const filterCols = [nameObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'User'}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const addCityButton = (
    <Tooltip title="Add City">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addCityButton}
        // color="primary"
        size="small"
        onClick={() => setAddCityViewOpen(true)}
      >
        ADD City
      </Button>
    </Tooltip>
  );

  const backButton = (
    <Tooltip title="Back to Region">
      <Button
        key={4}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addCityButton}
        // color="primary"
        size="small"
        onClick={() =>
          navigate(`/administration/country/${country_id}/regions`)
        }
      >
        Back
      </Button>
    </Tooltip>
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_CITY_CREATE') ? addCityButton : null,
    backButton,
    // checkPermission(currentUser, "OPS_CITY_READ") ? searchInputCity : null,
    checkPermission(currentUser, 'OPS_CITY_CREATE') ||
    checkPermission(currentUser, 'OPS_CITY_UPDATE')
      ? addCityModal
      : null,
    checkPermission(currentUser, 'OPS_CITY_READ') ? filterModal : null
  ];

  // Custom toolbar
  function CustomCityToolbar(props) {
    return (
      <CustomToolbar
        props={props}
        searchKeyword={searchKeyword}
        openFilterView={openFilterView}
      />
    );
  }
  // Custom toolbar
  const visibleColumns = { name: true };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Table aria-label="sticky table" className="tableDiv">
          <TableBody>
            <div style={{ height: 750, width: '100%' }}>
              <DataGrid
                rows={cities}
                hiddenColumnModel={['id']}
                visibleColumns={visibleColumns}
                columns={columnsNew}
                components={{ Toolbar: CustomCityToolbar }}
                pageSize={15}
                rowsPerPageOptions={[6]}
                className="parentDataTable"
              />
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}

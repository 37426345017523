import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { getPropByString } from '../utils/common';

const FormikCheckbox = ({ formik, name, label }) => {
  const value = getPropByString(formik.values, name);
  const errors = getPropByString(formik.errors, name);
  const touched = getPropByString(formik.touched, name);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={formik.handleChange}
            name={name}
          />
        }
        label={label}
        labelPlacement="end"
      />
      {errors && touched && <Typography color="error">{errors}</Typography>}
    </>
  );
};

export default FormikCheckbox;

import React from 'react';
import { MdSearch, MdCancel } from 'react-icons/md';
import { InputBase } from '@material-ui/core';

const SearchInput = ({
  mainClass,
  searchKeyword,
  setSearchKeyword,
  setPage,
  boxStyle
}) => {
  return (
    <div className={`search-box ${boxStyle}`}>
      {searchKeyword ? (
        <span className="search-cancel" onClick={() => setSearchKeyword('')}>
          <MdCancel className="searchIcons" />
        </span>
      ) : null}
      <InputBase
        className={mainClass}
        id="search"
        label="Search"
        type="text"
        variant="outlined"
        value={searchKeyword}
        key={3}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          if (setPage) setPage(1);
        }}
      />
      <span className="search-btn">
        <MdSearch className="searchIcons" />
      </span>
    </div>
  );
};

export default SearchInput;

/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Popover, Select, Box } from '@mui/material';
import { ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';

import Button from '../../../core-components/atoms/Button';
import StockAdjustmentIcon from '../../../assets/icons/stockAdjustment.svg';
import API from '../../../libs/axios';
import CustomTextField from '../../../core-components/atoms/TextField';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { decimalDisplay } from '../../../utils/common';
import { NumericFormat } from 'react-number-format';
import ConditionIcon from '../../../assets/icons/stockConditionIconFaded.svg';

const StockConditionPopover = ({
  actionCallback,
  closeCallback,
  available,
  damaged,
  uoms,
  stockCondition
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [uom, setUom] = useState(uoms[0]);
  const [qty, setQty] = useState(null);
  const [qtyType, setQtyType] = useState(stockCondition || 'GOOD');
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    if (closeCallback) closeCallback();
  };

  const handleQtyTypeChange = (e) => {
    setQtyType(e.target.value);
  };

  const handleUOMChange = (e) => {
    setUom(e.target.value);
  };

  const handleConfirmClick = () => {
    let convertedQty = uom.primary
      ? qty
      : Number(qty * (uom.conversionFactor || 1)).toFixed(3);
    actionCallback(
      qtyType,
      qtyType == 'GOOD' ? available : convertedQty,
      qtyType == 'RECOVERABLE' ? damaged : convertedQty
    );
    handlePopoverClose();
    setQty(0);
    setUom(uoms[0]);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div
          className="flex gap-[6px] items-center w-full"
          onClick={handlePopoverOpen}
        >
          <img src={ConditionIcon} alt="Stock Adjustment" />
          <span className="text-primaryBlue text-xs">Stock Condition</span>
        </div>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 0,
            boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)'
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div
          className={clsx(
            'min-w-[310px] p-4 rounded-[8px] bg-white z-10 transition-all shadow-elevation-2 overflow-hidden'
          )}
        >
          <p className="text-primaryBlue text-base font-medium my-2">
            Stock Condition
          </p>
          <div className="mt-8 flex justify-between gap-8">
            <FormControl variant="standard">
              <Select
                value={qtyType}
                onChange={handleQtyTypeChange}
                disabled={stockCondition}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5
                  }
                }}
                MenuProps={{
                  BackdropProps: { style: { backgroundColor: 'transparent' } }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                renderValue={(val) => (
                  <span className="text-[#9DA3B3] text-sm">
                    {val === 'GOOD' ? 'Available Quantity' : 'Damaged Quantity'}
                  </span>
                )}
              >
                <MenuItem
                  value="GOOD"
                  className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
                >
                  Available
                </MenuItem>
                <MenuItem
                  value="RECOVERABLE"
                  className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
                >
                  Damaged
                </MenuItem>
              </Select>
            </FormControl>
            <div className="flex gap-2 items-center">
              <span className="text-sm">
                {decimalDisplay(
                  (qtyType === 'GOOD' ? available : damaged) /
                    (uom?.conversionFactor || 1)
                )}
              </span>
              <FormControl variant="standard">
                <Select
                  value={uom}
                  onChange={handleUOMChange}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5
                    }
                  }}
                  MenuProps={{
                    BackdropProps: { style: { backgroundColor: 'transparent' } }
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  renderValue={(val) => (
                    <span className="text-xs">{val?.name}</span>
                  )}
                >
                  {uoms.map((uom, index) => (
                    <MenuItem
                      key={index}
                      value={uom}
                      className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
                    >
                      {uom?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mt-4 mb-4">
            <NumericFormat
              customInput={CustomTextField}
              placeholder="Qty"
              variant="outlined"
              size="small"
              decimalScale={3}
              fullWidth
              value={qty}
              onValueChange={(e) => {
                setQty(e.value);
              }}
              InputProps={{ style: { fontSize: '12px', width: '100%' } }}
              disabled={qtyType === 'GOOD' ? available === 0 : damaged === 0}
              isAllowed={(values) => {
                const { floatValue } = values;

                const maxQuantity = qtyType === 'GOOD' ? available : damaged;
                const uomConvertedValue = uom?.primary
                  ? maxQuantity
                  : Number((maxQuantity / uom?.conversionFactor).toFixed(3));

                if (uomConvertedValue - floatValue < 0) {
                  return false;
                }
                return true;
              }}
            />
          </div>
          <div className="w-full flex justify-end">
            <Button
              size="small"
              variant="text"
              label="Cancel"
              className="py-2 px-3 h-8"
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={handlePopoverClose}
            />
            <Button
              size="small"
              variant="primary"
              label="Confirm"
              className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"
              overrideSize={true}
              labelClass="font-medium text-xs"
              disabled={!qty || qty === 0}
              onClick={handleConfirmClick}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default StockConditionPopover;

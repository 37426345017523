import { Grid, makeStyles, Paper, Button, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState, useCallback } from 'react';
import API from '../../libs/axios';
import { Formik } from 'formik';
import validationSchema from './validationSchema';
import { toaster } from '../../utils/toaster';
import OwareLogo from '../../assets/icons/O360DarkLogo.svg';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PasswordResetSuccessPopup from './PasswordResetSuccessPopup';
import PasswordField from './PasswordField';

const useStyles = makeStyles(() => ({
  paperStyle: {
    marginBottom: '20px',
    padding: 5,
    height: '55vh',
    maxWidth: '350px',
    minWidth: 'auto',
    margin: '10% auto'
  },
  errors: {
    color: 'red'
  }
}));

export default function PasswordResetForm() {
  const [formErrors, setFormErrors] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [user, setUser] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassResetSuccess, setShowPassResetSuccess] = useState(false);

  useEffect(() => {
    const fetchUserByToken = async () => {
      try {
        const res = await API.get(`users/user-by-token/${token}`);

        if (res) {
          setUser(res.user);
        }
      } catch (err) {
        toaster('error', err?.response?.data?.message);
        navigate('/login');
      }
    };

    fetchUserByToken().catch((error) => {
      // Handle any errors that occur during the execution of the promise
      console.error(error);
    });
  }, [token]);

  const handleSubmit = useCallback(
    async (values) => {
      setFormErrors(null);
      try {
        // Submit form
        const response = await API.put(`users/reset-password/${token}`, {
          password: values.newPassword
        });

        if (response && !response.error) {
          setShowPassResetSuccess(true);
        }
      } catch (err) {
        setFormErrors(
          <Alert variant="filled" severity="error">
            {err.errors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </Alert>
        );
      }
    },
    [setFormErrors, user]
  );

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePopupClose = () => {
    setShowPassResetSuccess(false);
  };

  return (
    <Formik
      initialValues={{
        newPassword: '',
        confirmPassword: ''
      }}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values).finally(() => setSubmitting(false));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Paper elevation={0} className={classes.paperStyle}>
              <Grid align="center">
                <img
                  src={OwareLogo}
                  alt="Logo"
                  width="255px"
                  height="66.33px"
                />
              </Grid>
              <h3>Reset Password</h3>
              {formErrors}
              <PasswordField
                name="newPassword"
                label="New Password"
                placeholder="********"
                fullWidth
                required
                type={showNewPassword ? 'text' : 'password'}
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                showPassword={showNewPassword}
                handleShowPassword={handleShowNewPassword}
                error={touched.newPassword && errors.newPassword}
              />
              <PasswordField
                name="confirmPassword"
                label="Confirm New Password"
                placeholder="********"
                fullWidth
                required
                type={showConfirmPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                showPassword={showConfirmPassword}
                handleShowPassword={handleShowConfirmPassword}
                error={touched.confirmPassword && errors.confirmPassword}
              />
              <Box mt={2}>
                Hint: Password must be at least 8 characters long and contain at
                least one uppercase letter, one lowercase letter, one number,
                and one special character {'@, !, ?, $, *, %, &'}
              </Box>
              <Box mt={3} display="flex" justifyContent="text-center">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  fullWidth
                >
                  {isSubmitting ? 'Saving...' : 'Save Changes'}
                </Button>
                {showPassResetSuccess && (
                  <PasswordResetSuccessPopup onClose={handlePopupClose} />
                )}
              </Box>
            </Paper>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import {
  calculateDateRangeFromTwoDates,
  SharedContext
} from '../../utils/common';
import TopBarStats from './TopBarStats';
import TopSellingProducts from './TopSellingProducts';
import TopCustomers from './TopCustomers';
import ProductPerformance from './ProductPerformance';
import LandedCost from './LandedCost';
import TopSuppliers from './TopSuppliers';
import TopActiveLocations from './TopActiveLocations';
import CarrierPerformance from './CarrierPerformance';
import TotalShipments from './TotalShipments';
import WarehouseCapcity from './WarehouseCapcity';
import Shrinkage from './Shrinkage';
import BestFloorOperator from './BestFloorOperator';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography } from '@mui/material';
import mixpanel from 'mixpanel-browser';

const Intelligence = () => {
  const {
    setCurrentPageTitle,
    startDateIntelligence,
    endDateIntelligence,
    nodeIdsIntelligence
  } = useContext(SharedContext);
  const rangeType = calculateDateRangeFromTwoDates(
    startDateIntelligence,
    endDateIntelligence
  );
  const [intelligencePermissions, setIntelligencePermissions] = useState([]);
  const [totalProfit, setTotalProfit] = useState(0);
  useEffect(() => {
    setCurrentPageTitle(`Intelligence`);
    mixpanel.track('Intelligence', {});
  });

  useEffect(() => {
    getIntelligencePermissions();
  }, []);

  const getIntelligencePermissions = async () => {
    try {
      const response = await API.get(`intelligence-kpis`);
      setIntelligencePermissions(response?.data);
    } catch (err) {
      onError(err);
    }
  };

  const filterIds = nodeIdsIntelligence.map((value) => value.id);

  return (
    <div className={'mx-10'}>
      <Box className="flex items-center bg-sandstorm200 border-l-4 border-sandstorm700 p-3 w-full text-sandstorm700 mb-2">
        <InfoIcon className="mr-2" />
        <Box>
          <Typography variant="h6" className="font-semibold">
            Beta Notice
          </Typography>
          <Typography variant="body2">
            Try our new Intelligence Module in beta! Your feedback is essential
            to help us improve. Please share your thoughts!
          </Typography>
        </Box>
      </Box>
      <TopBarStats
        range={'Current Month'}
        setTotalProfit={setTotalProfit}
        intelligencePermissions={intelligencePermissions}
      />
      {(rangeType !== 'This Month' || nodeIdsIntelligence.length > 0) && (
        <div className={'mt-2'}>
          <TopBarStats
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            range={rangeType}
            nodeIds={filterIds}
            setTotalProfit={setTotalProfit}
            intelligencePermissions={intelligencePermissions}
          />
        </div>
      )}
      <div className="border-y border-primaryBlue mt-8 flex flex-col md:flex-col lg:flex-row">
        <div className="flex-1 lg:border-r border-primaryBlue basis-2/3 pb-2">
          <TopSellingProducts
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
        <div className="flex-1 basis-1/2 pb-2">
          <TopCustomers
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
      </div>
      <div className="border-b border-primaryBlue flex flex-col md:flex-col lg:flex-row">
        <div className="flex-1 lg:border-r border-primaryBlue basis-1/2 pb-2">
          <ProductPerformance
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
            totalProfit={totalProfit}
          />
        </div>
        <div className="flex-1 basis-1/2 pb-2">
          <LandedCost
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
      </div>
      <div className="border-b border-primaryBlue flex flex-col md:flex-col lg:flex-row">
        <div className="flex-1 lg:border-r border-primaryBlue basis-2/3 pb-6">
          <TopSuppliers
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
        <div className="flex-1 basis-1/2 pb-2">
          <TopActiveLocations
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
      </div>
      <div className="border-b border-primaryBlue flex flex-col md:flex-col lg:flex-row">
        <div className="flex-1 lg:border-r border-primaryBlue basis-1/2 pb-2">
          <TotalShipments
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
        <div className="flex-1 basis-2/3 pb-2">
          <CarrierPerformance
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
      </div>
      <div className="border-b border-primaryBlue flex flex-col md:flex-col lg:flex-row">
        <div className="flex-1 lg:border-r border-primaryBlue basis-2/3 pb-2">
          <WarehouseCapcity
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
        <div className="flex-1 basis-1/3 pb-2">
          <Shrinkage
            startDate={startDateIntelligence}
            endDate={endDateIntelligence}
            nodeIds={filterIds}
          />
        </div>
      </div>
      <div className="border-b border-primaryBlue">
        <BestFloorOperator
          startDate={startDateIntelligence}
          endDate={endDateIntelligence}
          nodeIds={filterIds}
        />
      </div>
    </div>
  );
};

export default Intelligence;

/*eslint-disable*/
import React, { useContext, useRef, useState } from 'react';
import { Box, Divider, Grid, MenuItem, Menu } from '@mui/material';
import BasicDetail from './BasicDetail';
import PrintStyles from './PrintStyles';
import owareLogo from '../../../../assets/icons/O360DarkLogo.svg';
import { SharedContext } from '../../../../utils/common';
import moment from 'moment';
import ProductDetail from './ProductDetail';
import PrintIcon from '../../../../assets/icons/printNew.svg';
import { useReactToPrint } from 'react-to-print';
import { OrderTypeMapping, ORGANIZATION_TYPES } from '../../../../constants';
import Button from '../../../../core-components/atoms/Button';
import clsx from 'clsx';

const PrintDetail = ({ order, orderSummary, expenseTotal, loading }) => {
  const classes = PrintStyles();
  const componentRef = useRef();
  const { currentUser } = useContext(SharedContext);
  const { darkOrganizationLogoFile, organizationType } =
    useContext(SharedContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [watermark, setWaterMark] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = async () => {
    handlePrintTaskDetails();
  };
  const handlePrintTaskDetails = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      watermark === ''
        ? `Order Detail ${order.customId}`
        : watermark === ''
        ? `Receiving List ${order.customId}`
        : `Delivery Challan ${order.customId}`
  });

  return (
    <>
      {!loading && (
        <Box
          displayPrint="block"
          className={clsx(classes.pageContainer, classes.root)}
          display="none"
          ref={componentRef}
        >
          <table>
            <thead>
              <tr>
                <td>
                  <div className={classes.topTextContainer} />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box display="none" displayPrint="block">
                    <Grid container spacing={2} className="mb-4">
                      <Grid item xs={6}>
                        {darkOrganizationLogoFile ? (
                          <img
                            src={darkOrganizationLogoFile.location}
                            alt="Logo"
                            style={{
                              width: '100px',
                              height: 'auto',
                              maxHeight: '100px'
                            }}
                          />
                        ) : (
                          <img
                            style={{ width: 'auto', height: '18px' }}
                            src={owareLogo}
                          />
                        )}
                      </Grid>
                      {watermark && (
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <div
                            className={classes.copyTypeButton}
                          >{`${watermark}`}</div>
                        </Grid>
                      )}
                    </Grid>

                    {watermark ? (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <p className={classes.pageSubHeading}>
                            {order?.Company?.name}
                          </p>
                          <p className={classes.shipmentIdText}>
                            <span className="mx-1 text-[12px] font-normal">
                              Date:{' '}
                            </span>
                            {moment(order?.createdAt).format(
                              'DD-MM-YY hh:mm A'
                            )}
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridItem}
                          justifyContent="flex-end"
                        >
                          <p className={classes.referenceIdText}>
                            {`${
                              order?.moveSubType
                                ? order?.moveSubType
                                : OrderTypeMapping[order?.moveType] ||
                                  order?.moveType
                            } - ${order?.customId}`}
                          </p>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        {order && (
                          <BasicDetail order={order} watermark={watermark} />
                        )}
                      </>
                    )}

                    <Divider className={classes.dividerProperties} />
                    {watermark ? (
                      <Box className={classes.additionalInstructionsContainer}>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeadingX}>
                            Warehouse
                          </p>
                          {watermark === 'Delivery Chalan' ? (
                            <p className={classes.additionalInstructionsBodyX}>
                              {order?.pickupNode
                                ? order?.pickupNode?.name ??
                                  order?.pickupNode?.code
                                : '-'}
                              {order?.pickupNode?.address
                                ? ` - ${order?.pickupNode?.address || ''}`
                                : ''}
                            </p>
                          ) : (
                            <p className={classes.additionalInstructionsBodyX}>
                              {order?.dropOffNode
                                ? order?.dropOffNode?.name ??
                                  order?.dropOffNode?.code
                                : '-'}
                              {order?.dropOffNode?.address
                                ? ` - ${order?.dropOffNode?.address || ''}`
                                : ''}
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeadingX}>
                            POC detail(s)
                          </p>
                          {watermark === 'Delivery Chalan' ? (
                            <p className={classes.additionalInstructionsBodyX}>
                              {`${order?.pickupNode?.pocName || '-'}`}
                              {` | ${order?.pickupNode?.pocContact || '-'}`}
                            </p>
                          ) : (
                            <p className={classes.additionalInstructionsBodyX}>
                              {`${order?.dropOffNode?.pocName || '-'}`}
                              {` | ${order?.dropOffNode?.pocContact || '-'}`}
                            </p>
                          )}
                        </div>
                      </Box>
                    ) : (
                      <Box className={classes.additionalInstructionsContainer}>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            ORIGIN
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.pickupNode
                              ? order?.pickupNode?.name ??
                                order?.pickupNode?.code
                              : '-'}
                            {order?.pickupNode?.address
                              ? ` - ${order?.pickupNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            DESTINATION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.dropOffNode
                              ? order?.dropOffNode?.name ??
                                order?.dropOffNode?.code
                              : '-'}
                            {order?.dropOffNode?.address
                              ? ` - ${order?.dropOffNode?.address || ''}`
                              : ''}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '16px'
                          }}
                        >
                          <p className={classes.additionalInstructionsHeading}>
                            INSTRUCTION
                          </p>
                          <p className={classes.additionalInstructionsBody}>
                            {order?.memo ?? '-'}
                          </p>
                        </div>
                      </Box>
                    )}
                    <Divider className={classes.dividerProperties} />
                    {order && (
                      <ProductDetail
                        order={order}
                        orderSummary={orderSummary}
                        watermark={watermark}
                        expenseTotal={expenseTotal}
                      />
                    )}
                  </Box>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className={classes.footerSpace}>
                    <Box
                      className={classes.bottomTextContainer}
                      display="none"
                      displayPrint="block"
                    >
                      <div>
                        <p className={classes.bottomText}>
                          {`This is an electronically generated document and does not require a signature.`}
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '7px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex'
                            }}
                          >
                            <p className={classes.bottomTextNormal}>
                              Printed By:
                            </p>
                            <p
                              className={classes.bottomText}
                            >{` ${currentUser?.firstName} ${currentUser.lastName}`}</p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: '36px'
                            }}
                          >
                            <p className={classes.bottomTextNormal}>
                              Printed Date & Time:
                            </p>
                            <p className={classes.bottomText}>
                              {` ${moment().format('DD/MM/YYYY hh:mm A')}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </Box>
      )}
      <Button
        variant="transparent"
        size="large"
        label="Print"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
        icon={<img src={PrintIcon} />}
        className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
        overrideSize={true}
        labelClass="font-medium text-xs "
        iconClass="h-[14px] w-[14px] mr-[6px]"
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.12))',
            mt: 2
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={0}
      >
        <MenuItem
          className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
          onClick={async (e) => {
            e.stopPropagation();
            handleClose();
            await setWaterMark('');
            await handlePrint();
          }}
        >
          <div className="flex gap-3">
            <img src={PrintIcon} className="w-4" />
            <span className={'font-medium text-xs'}>
              {['SALES', 'PURCHASE'].includes(order?.moveSubType)
                ? `${order?.moveSubType.toTitleCase()} Order`
                : 'Print Order'}
            </span>
          </div>
        </MenuItem>
        {organizationType === ORGANIZATION_TYPES.MANUFACTURER && (
          <MenuItem
            className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
            onClick={async (e) => {
              e.stopPropagation();
              handleClose();
              await setWaterMark(
                order.moveSubType === 'PURCHASE'
                  ? 'Receiving List'
                  : 'Delivery Challan'
              );
              await handlePrint();
            }}
          >
            <div className="flex gap-3">
              <img src={PrintIcon} className="w-4" />
              <span className={'font-medium text-xs'}>
                {order.moveSubType === 'PURCHASE'
                  ? 'Receiving List'
                  : 'Delivery Challan'}
              </span>
            </div>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default PrintDetail;

/*eslint-disable*/
import { GoogleApiWrapper } from 'google-maps-react';
import React, { useEffect, useState, useRef } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { makeStyles } from '@material-ui/core';
import Geocode from 'react-geocode';
import { isNumber } from 'lodash';
import TextField from '../../core-components/atoms/TextField';
import MarkerIcon from '../../assets/icons/mapicon/red_MarkerP.png';
import LocationIcon from '../../assets/icons/mapMarker.svg';
import { FormControl, IconButton } from '@mui/material';
import Search from '../../assets/icons/magnify.svg';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import AdvancedMarker from '../../components/AdvancedMarker';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(GOOGLE_MAP_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage('en');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
// Geocode.setRegion('pk');
Geocode.enableDebug();

function GoogleMap(props) {
  const reverseGeocoding = async (latlng) => {
    if (isNumber(latlng.lat) && isNumber(latlng.lng)) {
      const response = await Geocode.fromLatLng(latlng.lat, latlng.lng);
      const address = response.results[0].formatted_address;
      return address;
    }
    return null;
  };

  const {
    setSingleLocationLatlng,
    singleLocationLatlng,
    setSingleLocationAddress,
    address
  } = props;

  const [singleLocationSearchBox, setSingleLocationSearchBox] = useState('');
  const [map, setMap] = useState(null);

  const ref = useRef();

  useEffect(() => {
    if (address) {
      setSingleLocationAddress(address);
      setSingleLocationSearchBox(address);
      handleSingleLocationSelect(address)
    }
  }, [address]);

  const handleChangeSingleLocation = async (singleLocationSearchBox) => {
    setSingleLocationSearchBox(singleLocationSearchBox);
    setSingleLocationLatlng(
      reverseGeocoding(singleLocationSearchBox)
        ? await reverseGeocoding(singleLocationSearchBox)
        : null
    );
  };

  const handleSingleLocationSelect = (singleLocationAddress) => {
    geocodeByAddress(singleLocationAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let zoom, mapCenter;

        setSingleLocationLatlng({
          lat: latLng.lat,
          lng: latLng.lng
        });
        setSingleLocationAddress(singleLocationAddress);
      })
      .catch((err) => console.log(err));
    if (setSingleLocationSearchBox)
      setSingleLocationSearchBox(singleLocationAddress);
  };

  const searchOptions = {
    // componentRestrictions: { country: ['pk'] }
  };

  useEffect(() => {
    setMap(new window.google.maps.Map(ref.current, mapOptions));
  }, []);

  useEffect(() => {
    if (map && singleLocationLatlng) {
      map.setCenter({
        lat: singleLocationLatlng.lat,
        lng: singleLocationLatlng.lng
      });
      map.setZoom(15);
    }
  }, [singleLocationLatlng]);

  return (
    <div className="w-full h-72 relative border">
      <div ref={ref} id="map" className="w-full h-full"></div>

      {singleLocationLatlng?.lat && singleLocationLatlng?.lng ? (
        <AdvancedMarker
          map={map}
          position={{
            lat: singleLocationLatlng.lat,
            lng: singleLocationLatlng.lng
          }}
          addMarkerRef={() => {}}
          onClick={() => {}}
          showInfoWindow={false}
        >
          <div className="w-10 h-10 mb-[-4px]">
            <img src={MarkerIcon} />
          </div>
        </AdvancedMarker>
      ) : (
        <div></div>
      )}

      {props.showSingleSearchField && props.editable ? (
        <div className="absolute top-2 left-2 z-100">
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={singleLocationSearchBox}
            onChange={handleChangeSingleLocation}
            onSelect={handleSingleLocationSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <div className="md:w-[400px] sm:w-full">
                  <FormControl fullWidth>
                    <TextField
                      {...getInputProps({
                        placeholder: 'Search for a place...',
                        className: 'bg-white'
                      })}
                      InputProps={{
                        startAdornment: (
                          <IconButton onClick={() => {}}>
                            <img src={Search} className="mr-2" />
                          </IconButton>
                        )
                      }}
                      defaultValue={props.address || ''}
                    />
                  </FormControl>
                </div>

                {suggestions?.length > 0 && (
                  <div className="z-50 w-[400px] h-[200px] overflow-auto map">
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? '#F1F8FF'
                          : '#ffffff'
                      };

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style
                          })}
                          key={suggestion.placeId}
                          className="px-3 py-4 hover:bg-[#F1F8FF] cursor-pointer flex items-center gap-2"
                        >
                          <img src={LocationIcon} />
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

// export default GoogleMap
export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY,
  mapId: 'f821a93ab9a91eb9n'
})(GoogleMap);

const mapOptions = {
  mapId: 'f821a93ab9a91eb9',
  center: { lat: 29.600087, lng: 70.008634 },
  zoom: 4,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  scrollwheel: false
};

import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  Button,
  Box,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { SharedContext } from '../../utils/common';
import API from '../../libs/axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { checkPermission, setUser } from '../../utils/auth';
import DetailTitle from '../../atomicComponents/DetailTitle';
import ProfilePicture from './ProfilePicture';
import profilePicture from '../../assets/icons/profileIcon.png';
import validationSchema from './validationSchema';
import { toaster } from '../../utils/toaster';
import { useNavigate } from 'react-router';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles(() => ({
  paperStyle: {
    margin: '20px',
    padding: 5,
    height: '55vh',
    maxWidth: '350px',
    minWidth: 'auto'
  },
  userDetails: {
    paddingTop: '50px',
    paddingLeft: '5px',
    position: 'relative',
    height: '80%'
  },
  userName: {
    fontSize: '20px',
    marginBottom: '10px'
  },
  errors: {
    color: 'red'
  }
}));

export default function PasswordResetForm() {
  const [formErrors, setFormErrors] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const classes = useStyles();
  const {
    currentPageTitle,
    setCurrentPageTitle,
    setCurrentUser,
    currentUser,
    setAPILoader
  } = useContext(SharedContext);

  const navigate = useNavigate();

  useEffect(() => {
    let title = 'Profile Settings';
    setCurrentPageTitle(title);
    mixpanel.track('Profile Settings Page', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  const handleSubmit = useCallback(
    async (values) => {
      setFormErrors(null);
      const schema = Yup.object().shape({
        currentPassword: Yup.string()
          .required('Current password is required.')
          .test(
            'check-current-password',
            'Current password is incorrect.',
            async function (currentPassword) {
              // Make the API call to check if the password is correct
              setAPILoader(true);
              const response = await API.post(
                `users/${currentUser?.id}/validate-user-password`,
                {
                  currentPassword: currentPassword
                }
              );
              setAPILoader(false);
              return response.isMatch;
            }
          )
      });

      try {
        await schema.validate(values, { abortEarly: false });
        // Submit form
        const response = await API.put(`users/change-password`, {
          password: values.newPassword
        });

        if (response && !response.error) {
          toaster('success', 'Your password has been updated successfully.');
          mixpanel.track('Password Updated', { userId: currentUser?.id });
          const res = await API.get(`me`);
          if (res) {
            setCurrentUser(res.user);
            setUser(res.user);

            if (checkPermission(res.user, 'OPS_CONTROL_TOWER_VIEW')) {
              navigate(`/control-tower`);
            } else {
              navigate(`/routing/order-management`);
            }
          }
        }
      } catch (err) {
        setFormErrors(
          <Alert variant="filled" severity="error">
            {err.errors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </Alert>
        );
      }
    },
    [currentUser, setFormErrors, setCurrentUser]
  );

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }}
      validationSchema={validationSchema(currentUser)}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values).finally(() => setSubmitting(false));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Grid container item xs={12}>
              <Grid>
                <ProfilePicture image={profilePicture} />
              </Grid>
              <Grid item xs={6}>
                <div className={classes.userDetails}>
                  <div>
                    <DetailTitle
                      className={classes.userName}
                      text={`${currentUser?.firstName} ${currentUser.lastName}`}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Paper elevation={0} className={classes.paperStyle}>
              <h2>Change Password</h2>
              {formErrors}
              <Box mt={4}>
                <TextField
                  name="currentPassword"
                  label="Current Password"
                  variant="outlined"
                  placeholder="*****"
                  fullWidth
                  type={showCurrentPassword ? 'text' : 'password'}
                  value={values.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowCurrentPassword}>
                          {showCurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {touched.currentPassword && errors.currentPassword && (
                  <div className={classes.errors}>{errors.currentPassword}</div>
                )}
              </Box>
              <Box mt={4}>
                <TextField
                  name="newPassword"
                  label="New Password"
                  variant="outlined"
                  placeholder="*****"
                  fullWidth
                  type={showNewPassword ? 'text' : 'password'}
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowNewPassword}>
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {touched.newPassword && errors.newPassword && (
                  <div className={classes.errors}>{errors.newPassword}</div>
                )}
              </Box>
              <Box mt={3}>
                <TextField
                  name="confirmPassword"
                  label="Confirm New Password"
                  variant="outlined"
                  placeholder="*****"
                  fullWidth
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowConfirmPassword}>
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <div className={classes.errors}>{errors.confirmPassword}</div>
                )}
              </Box>
              <Box mt={3} display="flex" justifyContent="text-center">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  fullWidth
                >
                  {isSubmitting ? 'Saving...' : 'Save Changes'}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

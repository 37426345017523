import React from 'react';
import { Table, TableBody, TableContainer, Grid } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

import useStyles from './sectionRelocationStyles';
import relocationVerificationColumns from './relocationVerificationColumns';

const BulkValidationScreen = ({ relocations, tableHeight = '730px' }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Grid className="bulkRelocation-scroll">
        <Table aria-label="sticky table">
          <TableBody>
            <DataGrid
              getRowId={(row) =>
                row.sourceSection +
                row.product +
                row.destinationSection +
                row.quantity +
                row.recordLevel
              }
              rows={relocations}
              columns={relocationVerificationColumns}
              className="parentDataTable customDataGrid"
              pageSize={20}
              pagination
              style={{ height: tableHeight, width: '100%' }}
            />
          </TableBody>
        </Table>
      </Grid>
    </TableContainer>
  );
};

export default BulkValidationScreen;

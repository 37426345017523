/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { Popover, List, ListItem, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import SettingIcon from '../../assets/icons/setting.svg';
import Button from '../../core-components/atoms/Button';
import TextField from '../../core-components/atoms/TextField';
import PartiallyCompleteIcon from '../../assets/icons/partial_complete.svg';
import { icons } from './statusIcons';
import { Paragraph } from '../../core-components/atoms/Text';

function Filters({
  options,
  getCompanies,
  setFilter,
  filters,
  saveQuickFilters
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const mainRef = useRef();
  const secondaryRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event, index) => {
    setSubMenuAnchorEl(event.currentTarget.parentNode);
    setActiveIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSearch('');
  };

  const useStyles = makeStyles((theme) => ({
    ListItem: {
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer',
      display: 'flex',
      gap: '8px'
    },
    popOver: {
      maxHeight: '350px',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      pointerEvents: 'none'
    },
    search: {
      margin: '1rem auto',
      width: '90%',
      boxSizing: 'border-box',
      display: 'block'
    },
    paper: {
      pointerEvents: 'auto',
      // width: '210px',
      border: '1px solid #E7E6E6',
      height: '280px',
      marginRight: '20px',
      marginTop: '16px'
      // position: 'relative'
    },
    subMenuPaper: {
      pointerEvents: 'auto',
      border: '1px solid #E7E6E6',
      height: '280px'
      // position: 'relative'
    }
  }));

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  useEffect(() => {
    if (!open) return;

    if (activeIndex && options[activeIndex].label === 'Customer' && search) {
      getCompanies(search);
    } else {
      getCompanies();
    }
  }, [search, open]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        secondaryRef.current &&
        !secondaryRef.current.contains(event.target)
      ) {
        handleClose();
        handleSubMenuClose();
      }

      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        !secondaryRef.current
      ) {
        handleClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className="border-solid border-0 border-r border-[#BDBDBD] inline-block text-sm text-[#6A7289] cursor-pointer px-2 py-1"
        onClick={handleClick}
      >
        <div className="flex items-center gap-2">
          <img src={SettingIcon} />
          <Paragraph variant={'sm'}>Quick Filters</Paragraph>
        </div>
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        ref={mainRef}
        style={{ pointerEvents: 'none' }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        disableScrollLock
        disablePortal
      >
        <List>
          <p className="font-semibold text-xs text-[#67718C] px-4 py-3">
            ADD QUICK FILTERS HERE
          </p>
          {options.map((option, i) => (
            <ListItem
              className={classes.ListItem}
              onMouseEnter={(e) => handleSubMenuClick(e, i)}
            >
              <img src={option.icon} />
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
          <div className="flex gap-2 px-4 py-3">
            <Button
              label="Cancel"
              onClick={() => {
                handleClose();
                handleSubMenuClose();
              }}
              size="medium"
            />
            <Button
              label="Save Changes"
              onClick={async () => {
                await saveQuickFilters();
                handleClose();
                handleSubMenuClose();
              }}
              size="large"
              variant="primary"
            />
          </div>
        </List>
      </Popover>
      <Popover
        open={subMenuOpen}
        anchorEl={subMenuAnchorEl}
        onClose={handleSubMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={classes.popOver}
        classes={{
          paper: classes.subMenuPaper
        }}
        PaperProps={{
          onMouseLeave: handleSubMenuClose
        }}
        ref={secondaryRef}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        disableScrollLock
        disablePortal
      >
        <List style={{ width: '300px' }}>
          {activeIndex && options[activeIndex]?.label === 'Customer' ? (
            <ListItem>
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </ListItem>
          ) : null}
          {(activeIndex || activeIndex == 0) &&
            options[activeIndex]?.options.map((option, i) => (
              <ListItem
                className={classes.ListItem}
                onClick={(e) => {
                  let checked = filters?.[options[activeIndex].label]?.reduce(
                    (a, b) => a || b.value == option.value,
                    false
                  );
                  if (checked) {
                    setFilter(
                      options[activeIndex].label,
                      option,
                      'remove',
                      'quick'
                    );
                  } else {
                    setFilter(
                      options[activeIndex].label,
                      option,
                      'add',
                      'quick'
                    );
                  }
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    e.stopPropagation();
                    if (e.target.checked) {
                      setFilter(
                        options[activeIndex].label,
                        option,
                        'add',
                        'quick'
                      );
                    } else {
                      setFilter(
                        options[activeIndex].label,
                        option,
                        'remove',
                        'quick'
                      );
                    }
                  }}
                  checked={filters?.[options[activeIndex].label]?.reduce(
                    (a, b) => a || b.value == option.value,
                    false
                  )}
                />
                {options[activeIndex]?.label == 'Status' &&
                  (option.value == 'PARTIALLY_COMPLETE' ||
                  option.value == 'LOAD_DISPATCHED' ? (
                    <img src={PartiallyCompleteIcon} />
                  ) : (
                    <div className={icons[option.value]} />
                  ))}

                <ListItemText primary={option.label} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
}

export default Filters;

/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Popover, Select, Box } from '@mui/material';
import { ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';

import Button from '../../../core-components/atoms/Button';
import StockAdjustmentIcon from '../../../assets/icons/stockAdjustment.svg';
import API from '../../../libs/axios';
import CustomTextField from '../../../core-components/atoms/TextField';
import { decimalDisplay } from '../../../utils/common';
import ReleaseFadedIcon from '../../../assets/icons/releaseFadedIcon.svg';

const ReleaseInventoryPopover = ({
  actionCallback,
  closeCallback,
  holdQuantity,
  uoms
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [uom, setUom] = useState(uoms[0]);
  const [releaseQty, setReleaseQty] = useState(0);
  const [holdQty, setHoldQty] = useState(holdQuantity);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    if (closeCallback) closeCallback();
  };

  const handleReleaseQtyChange = (e) => {
    setReleaseQty(e.target.value);
  };

  const handleUOMChange = (e) => {
    setUom(e.target.value);
  };

  const handleConfirmClick = () => {
    let convertedReleaseQuantity = uom.primary
      ? Number(releaseQty)
      : Number(releaseQty / uom.conversionFactor).toFixed(3);
    actionCallback(convertedReleaseQuantity);
    handlePopoverClose();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div
          className="flex gap-[6px] items-center w-full"
          onClick={handlePopoverOpen}
        >
          <img src={ReleaseFadedIcon} alt="Stock Adjustment" />
          <span className="text-primaryBlue text-xs">Release</span>
        </div>
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 0,
            boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.50)'
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div
          className={clsx(
            'w-[310px] p-4 rounded-[8px] bg-white z-10 transition-all shadow-elevation-2 overflow-hidden'
          )}
        >
          <p className="text-primaryBlue text-base font-medium my-2">
            Release Inventory
          </p>
          <div className="mt-4 flex justify-between">
            <div className="flex justify-between items-center w-full">
              <div className="flex gap-2">
                <span className="text-sm text-[#9DA3B3]">Total Quantity: </span>
                <span className="text-sm font-medium">
                  {decimalDisplay(holdQty / (uom?.conversionFactor || 1))}
                </span>
              </div>
              <FormControl variant="standard">
                <Select
                  value={uom}
                  onChange={handleUOMChange}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5
                    }
                  }}
                  MenuProps={{
                    BackdropProps: { style: { backgroundColor: 'transparent' } }
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  renderValue={(val) => (
                    <span className="text-xs">{val?.name}</span>
                  )}
                >
                  {uoms.map((uom, index) => (
                    <MenuItem
                      key={index}
                      value={uom}
                      className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
                    >
                      {uom?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mt-4 mb-4">
            <div className="mt-4">
              <CustomTextField
                value={releaseQty}
                onChange={handleReleaseQtyChange}
                fullWidth
                label="Release Qty"
                disabled={holdQty == 0}
              />
            </div>
          </div>
          <div className="w-full flex justify-end">
            <Button
              size="small"
              variant="text"
              label="Cancel"
              className="py-2 px-3 h-8"
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={handlePopoverClose}
            />
            <Button
              size="small"
              variant="primary"
              label="Confirm"
              className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"
              overrideSize={true}
              labelClass="font-medium text-xs"
              disabled={!holdQty || holdQty <= 0 || !releaseQty}
              onClick={handleConfirmClick}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default ReleaseInventoryPopover;

/*eslint-disable*/
import React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip
} from '@mui/material';
import { dateFormatSlashDivider, decimalDisplay } from '../../../utils/common';
import PrintStyles from './PrintStyles';

const ProductDetail = ({ data, productDetails }) => {
  const classes = PrintStyles();

  const [columnNames] = useState([
    'DATE',
    'ID',
    'TYPE',
    'CREATED BY',
    'ORIGIN & DESTINATION',
    'BATCH',
    'QTY'
  ]);

  return (
    <Box className={classes.productDetailSectionContainer}>
      <Box className={classes.productDetailSectionHeading}>Product Detail</Box>

      <Box displayPrint="block">
        <div>
          <Paper className="no-scrollbar rounded-t-lg shadow-none mt-[32px] bg-dark800">
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ border: 'none' }}
              >
                <TableHead className={classes.tableHead}>
                  <TableRow className={classes.tableRow}>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className={classes.tableCellHeader}
                        style={{
                          padding: '0px',
                          paddingLeft: '24px',
                          paddingRight: '12px'
                        }}
                      >
                        {columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={{ padding: '0px' }}>
                  <TableRow style={{ padding: '0px' }}>
                    <TableCell
                      colSpan={7}
                      style={{ padding: '0px', borderRadius: '0px' }}
                    >
                      <div className={classes.customRow}>
                        <p
                          style={{
                            fontSize: '10px',
                            marginRight: '24px'
                          }}
                        >
                          Opening Stock:{' '}
                          <span style={{ fontWeight: 500, fontSize: '12px' }}>
                            {decimalDisplay(productDetails?.openingStock)}
                          </span>{' '}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                  {data.length > 0 &&
                    data.map((row, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            key={index}
                            className={clsx(' h-[48px] border-0')}
                          >
                            <TableCell
                              className={classes.tableCellBody}
                              style={{ padding: '8px 12px 8px 24px' }}
                            >
                              <Tooltip title={row?.createdAt}>
                                <p
                                  style={{
                                    fontSize: '10px',
                                    padding: '0px'
                                  }}
                                >
                                  {dateFormatSlashDivider(row?.createdAt)}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={classes.tableCellBody}
                              style={{ padding: '8px 12px 8px 24px' }}
                            >
                              <Tooltip title={row.referenceId}>
                                <p
                                  style={{
                                    fontSize: '10px',
                                    padding: '0px'
                                  }}
                                >
                                  {row.referenceId}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={classes.tableCellBody}
                              style={{ padding: '8px 12px 8px 24px' }}
                            >
                              <Tooltip title={row?.transactionType}>
                                <p
                                  style={{
                                    fontSize: '10px',
                                    padding: '0px'
                                  }}
                                >
                                  {row?.transactionType === 'INWARD'
                                    ? 'Inbound'
                                    : row?.transactionType === 'GRN_REVERSAL'
                                    ? 'GRN Reversal'
                                    : row?.transactionType === 'GDN_REVERSAL'
                                    ? 'GDN Reversal'
                                    : row?.transactionType === 'SALES_RETURN'
                                    ? 'Sales Return'
                                    : row?.transactionType ===
                                      'STOCK_ADJUSTMENT'
                                    ? 'Adjustment'
                                    : row?.transactionType.toTitleCase()}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={classes.tableCellBody}
                              style={{ padding: '8px 12px 8px 24px' }}
                            >
                              <Tooltip
                                title={`${row?.User?.firstName} - ${row?.User?.lastName}`}
                              >
                                <p
                                  style={{
                                    fontSize: '10px',
                                    padding: '0px'
                                  }}
                                >{`${row?.User?.firstName} - ${row?.User?.lastName}`}</p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={classes.tableCellBody}
                              style={{ padding: '8px 12px 8px 24px' }}
                            >
                              <Tooltip title>
                                <p
                                  style={{
                                    fontSize: '10px',
                                    padding: '0px'
                                  }}
                                >
                                  {row?.originNode?.name}
                                  {row?.transactionType !==
                                    'STOCK_ADJUSTMENT' && (
                                    <>
                                      <span className="w-[8%] text-[24px] leading-[50%]">
                                        &#8594;
                                      </span>
                                      {row?.destinationNode?.name}
                                    </>
                                  )}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={classes.tableCellBody}
                              style={{ padding: '8px 12px 8px 24px' }}
                            >
                              <Tooltip title={row.batchNumber}>
                                <p
                                  style={{
                                    fontSize: '10px',
                                    padding: '0px'
                                  }}
                                >
                                  <div>
                                    <div>{row.batchNumber}</div>
                                    <div>
                                      {`${dateFormatSlashDivider(
                                        row?.InventoryDetail?.manufacturingDate
                                      )} - ${dateFormatSlashDivider(
                                        row?.InventoryDetail?.expiryDate
                                      )}` || ''}
                                    </div>
                                  </div>
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={classes.tableCellBody}
                              style={{ padding: '8px 12px 8px 24px' }}
                            >
                              <Tooltip title={row.quantity}>
                                <p
                                  style={{
                                    fontSize: '10px',
                                    padding: '0px',
                                    color:
                                      row.operation === 'ADD'
                                        ? '#008712'
                                        : '#D63232'
                                  }}
                                >
                                  {row.operation === 'ADD'
                                    ? decimalDisplay(row.quantity)
                                    : `-${decimalDisplay(row.quantity)}`}
                                </p>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  <TableRow style={{ padding: '0px' }}>
                    <TableCell
                      colSpan={7}
                      style={{ padding: '0px', borderRadius: '0px' }}
                    >
                      <div className={classes.customRow}>
                        <p
                          style={{
                            fontSize: '10px',
                            marginRight: '24px'
                          }}
                        >
                          Closing Stock:{' '}
                          <span style={{ fontWeight: 500, fontSize: '12px' }}>
                            {decimalDisplay(productDetails?.closingStock)}
                          </span>{' '}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Box>
    </Box>
  );
};

export default ProductDetail;

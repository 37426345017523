import React, { useCallback, useEffect, useMemo, useState } from 'react';
import 'chart.js/auto';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { Title } from '../../../core-components/atoms/Text';
import ReactSpeedometer from 'react-d3-speedometer';
import { decimalDisplay } from '../../../utils/common';

const Shrinkage = ({ nodeIds, endDate, startDate }) => {
  const [shrinkageData, setSrinkageData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);

  useEffect(() => {
    setIsLoading(true);
    getDelayedShrinkageData(startDate, endDate, nodeIdsMemo);
  }, [startDate, endDate, nodeIdsMemo]);

  const getDelayedShrinkageData = useCallback(
    debounce(
      async (startDate, endDate, nodeIds) => {
        getShrinkageData(startDate, endDate, nodeIds);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getShrinkageData = async (startDate, endDate, nodeIds) => {
    try {
      const data = await API.get(`intelligence/shrinkage`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          nodeIds: nodeIds
        }
      });
      setSrinkageData(data);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <Title variant={'md'} className={'mb-4'}>
        Shrinkage
      </Title>
      <div>
        {!isLoading && (
          <ReactSpeedometer
            maxValue={shrinkageData?.totalQuantity}
            value={shrinkageData?.damagedQuantity}
            minValue={0}
            customSegmentLabels={[
              { text: '0%', position: 'OUTSIDE' },
              { text: '25%', position: 'OUTSIDE' },
              { text: '50%', position: 'OUTSIDE' },
              { text: '75%', position: 'OUTSIDE' },
              { text: '100%', position: 'OUTSIDE' }
            ]}
            forceRender={true}
            needleColor={'#0C70D7'}
            startColor={'#A9D3FE'}
            segments={5}
            height={240}
            width={400}
            endColor={'#0B1940'}
            segmentColors={[
              '#A9D3FE',
              '#0C70D7',
              '#0C70D7',
              '#0C70D7',
              '#0B1940'
            ]}
            needleTransitionDuration={4000}
            currentValueText={`Shrinkage: ${decimalDisplay(
              (shrinkageData?.damagedQuantity / shrinkageData?.totalQuantity) *
                100
            )}%`}
          />
        )}
      </div>
    </div>
  );
};

export default Shrinkage;

/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../core-components/atoms/Button';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import { Modal } from '@mui/material';
import { CloseOutlined } from '@material-ui/icons';

import { useNavigate } from 'react-router';
import { SharedContext } from '../../utils/common';
import { onError } from '../../libs/errorLib';
import API from '../../libs/axios';
import NodeList from './NodeList';
import OrganizationDetails from './OrganizationDetails';

export const getSuffix = (n) => {
  if (n == 2) {
    return 'nd';
  } else if (n == 3) {
    return 'rd';
  } else return 'th';
};

function EditOrganization({}) {
  const [nodes, setNodes] = useState([]);
  const [search, setSearch] = useState('');
  const [nodePages, setNodePages] = useState(0);
  const [nodePage, setNodePage] = useState(1);
  const { organization } = useContext(SharedContext);

  const navigate = useNavigate();

  const getNodes = async (search) => {
    try {
      let response = {};
      response = await API.get(`nodes`, {
        params: {
          page: nodePage,
          limit: 5,
          search: search
        }
      });
      setNodes(response.data);
      setNodePages(response.pages);
    } catch (err) {
      onError(err);
    } finally {
    }
  };

  useEffect(() => {
    if (search) {
      getNodes(search);
    } else {
      getNodes('');
    }
  }, [search, nodePage]);

  const Header = (
    <div className="flex justify-between w-full items-center">
      <div></div>
      <div className="flex gap-2">
        <div>
          <CloseOutlined
            onClick={() => {
              navigate('/administration/supply-chain-network');
            }}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <form>
          <FixedLayout
            header={Header}
            content={
              <div className="m-auto w-[60%] mt-10 mb-10">
                <>
                  <OrganizationDetails />
                  <hr className="mb-8 mt-8" />
                  <NodeList
                    nodes={nodes}
                    refetch={async () => await getNodes(search)}
                    search={search}
                    setSearch={setSearch}
                    customer={{
                      name: organization?.name,
                      nodeClass: 'INTERNAL',
                      entityType: 'Organization',
                      label: 'Organization'
                    }}
                    page={nodePage}
                    pages={nodePages}
                    setPage={setNodePage}
                  />
                </>
              </div>
            }
            footer={
              <div className="flex justify-between w-full">
                <Button
                  label="Cancel"
                  variant="tertiary"
                  onClick={() =>
                    navigate('/administration/supply-chain-network')
                  }
                />

                <div className="flex justify-between mr-4 gap-4">
                  <Button
                    label="Save Changes"
                    variant="primary"
                    onClick={async () => {
                      navigate('/administration/supply-chain-network');
                    }}
                  />
                </div>
              </div>
            }
          />
        </form>
      </div>
    </Modal>
  );
}

export default EditOrganization;

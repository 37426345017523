import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextareaAutosize,
  Typography,
  Autocomplete,
  TextField
} from '@mui/material';
import { useFormik } from 'formik';
import PercentIcon from '@mui/icons-material/Percent';
import { useNavigate, useParams } from 'react-router';
import { debounce } from 'lodash';

import DetailPageTitle from '../../../../atomicComponents/DetailPageTitle';
import FormikInputField from '../../../../atomicComponents/FormikInputField';
import warehouseStyles from '../warehouseMakeStyles';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import FormikRadioGroup from '../../../../atomicComponents/FormikRadioGroup';
import { initialValues, validationSchema } from './formikFields';
import GoogleMap from '../../../../components/GoogleMap';
import radioOptions from '../../../../utils/enums/radioOptions';
import FormikCheckbox from '../../../../atomicComponents/FormikCheckbox';
import DetailTitle from '../../../../atomicComponents/DetailTitle';
import EditableImagesList from '../../../../atomicComponents/EditableImageList';
import DatePicker from '../../../../components/DatePicker';
import {
  SharedContext,
  addArrItem,
  dateToPickerEndFormat,
  getPropByString,
  removeArrItem
} from '../../../../utils/common';
import { toaster } from '../../../../utils/toaster';
import { DEBOUNCE_CONST } from '../../../../Config';
import { upload } from '../../../../utils/upload';

const AddWarehouse = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const { subdomain, setAPILoader } = useContext(SharedContext);
  const classes = warehouseStyles();
  const [initVals, setInitVals] = useState(initialValues());
  const formik = useFormik({
    initialValues: initVals,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitForm(values);
    }
  });

  const [attemptedAlready, setAttemptedAlready] = useState(false);
  const [managers, setManagers] = useState([]);
  const [cities, setCities] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [singleLocationLatlng, setSingleLocationLatlng] = useState(null);
  const [dateFilter, setDateFilter] = useState(false);
  const [isLocationLoaded, setIsLocationLoaded] = useState(false);
  const [removedFiles] = useState([]);
  const [startDate, setStartDate] = useState(
    dateToPickerEndFormat(
      formik?.values?.configurations?.contractDate?.startDate || null
    )
  );
  const [endDate, setEndDate] = useState(
    dateToPickerEndFormat(
      formik?.values?.configurations?.contractDate?.endDate || null
    )
  );
  const [range, setRange] = useState([
    {
      startDate: formik?.values?.configurations?.contractDate?.startDate || '',
      endDate: formik?.values?.configurations?.contractDate?.endDate || '',
      key: 'selection'
    }
  ]);
  const [citySearchVal, setCitySearchVal] = useState('');
  const [managerSearchVal, setManagerSearchVal] = useState('');

  useEffect(() => {
    if (formik?.values?.configurations?.contractDate?.endDate) {
      setStartDate(formik?.values?.configurations?.contractDate?.startDate);
      setEndDate(formik?.values?.configurations?.contractDate?.endDate);
    }
  }, [formik?.values?.configurations?.contractDate]);

  const submitForm = async (values) => {
    let fileIds = [];
    fileIds = values?.images?.length
      ? await upload(
          values?.images?.filter((img) => !img?.id),
          'warehouse'
        )
      : [];

    values.supportingDocuments = [...values.supportingDocuments, ...fileIds];
    try {
      setAttemptedAlready(true);
      setAPILoader(true);
      if (uid) {
        if (removedFiles?.length) {
          await API.post('uploads/delete/multiple', {
            file_ids: removedFiles
          });
        }
        await API.put(`warehouses/${uid}`, values);
        navigate('/administration/warehouse');
      } else {
        await API.post(`warehouses`, values);
        navigate('/administration/warehouse');
      }
      toaster(
        'success',
        uid
          ? 'Warehouse updated successfully'
          : 'New Warehouse has been created.'
      );
    } catch (err) {
      onError(err);
    } finally {
      setAttemptedAlready(false);
      setAPILoader(false);
    }
  };

  const getWarehouse = async (uid) => {
    const res = await API.get(`warehouses/${uid}`);
    setInitVals(initialValues(res?.data));
    setSingleLocationLatlng(res?.data?.locationLatlng);
    setIsLocationLoaded(true);
  };

  const getManagers = async (searchValue) => {
    try {
      const res = await API.get(`organizations/${subdomain}/users`, {
        params: {
          search: searchValue,
          columns: ['firstName', 'lastName', 'email', 'username']
        }
      });
      setManagers(res?.data);
    } catch (error) {
      onError(error);
    }
  };

  const getCities = async (searchValue) => {
    try {
      const res = await API.get(`cities`, {
        params: {
          search: searchValue
        }
      });
      setCities(res?.data);
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    getDelayedManagers(managerSearchVal);
  }, [managerSearchVal]);

  const getDelayedManagers = useCallback(
    debounce(
      async (managerSearchVal) => {
        getManagers(managerSearchVal);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    getDelayedCities(citySearchVal);
  }, [citySearchVal]);

  const getDelayedCities = useCallback(
    debounce(
      async (citySearchVal) => {
        getCities(citySearchVal);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  useEffect(() => {
    if (uid) {
      getWarehouse(uid);
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className="warehouseDiv">
        <Typography className="updateHeading">
          {uid ? 'Update Warehouse' : 'Add Warehouse'}
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} className={classes.addWarehouseContainer}>
            <Grid item xs={12}>
              <DetailTitle text="Warehousing Details:" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikInputField
                title="Warehouse Name *"
                formik={formik}
                name="name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikInputField
                title="Business Warehouse Code *"
                formik={formik}
                name="businessWarehouseCode"
                max="15"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikInputField
                title="Capacity (Sq. ft.) *"
                formik={formik}
                name="capacity"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikInputField
                title="Capacity (Pallets) *"
                formik={formik}
                name="pallets"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Autocomplete
                className="formikAutocompleteField"
                id="managerId"
                options={managers}
                getOptionLabel={(options) => {
                  return options?.name || '';
                }}
                value={formik?.values?.manager || null}
                defaultValue={''}
                onBlur={formik?.handleBlur}
                onChange={(e, val) => {
                  if (val) {
                    formik.setFieldValue('manager', val);
                    formik.setFieldValue('managerId', val?.id);
                  } else {
                    formik.setFieldValue('manager', {});
                    formik.setFieldValue('managerId', '');
                  }
                }}
                onKeyUp={(event) => {
                  setManagerSearchVal(event.target.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Manager *"
                    placeholder="Select"
                  />
                )}
              />
              {formik?.errors['managerId'] && formik?.touched['managerId'] && (
                <Typography color="error">Required!</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikInputField
                title="Address *"
                formik={formik}
                name="address"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Autocomplete
                className="formikAutocompleteField"
                id="cityId"
                options={cities}
                getOptionLabel={(options) => {
                  return options?.name || '';
                }}
                value={formik?.values?.city || null}
                defaultValue={''}
                onBlur={formik?.handleBlur}
                onChange={(e, val) => {
                  if (val) {
                    formik.setFieldValue('city', val);
                    formik.setFieldValue('cityId', val?.id);
                  } else {
                    formik.setFieldValue('city', {});
                    formik.setFieldValue('cityId', '');
                  }
                }}
                onKeyUp={(event) => {
                  setCitySearchVal(event.target.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="City *"
                    placeholder="Select"
                  />
                )}
              />
              {formik?.errors['cityId'] && formik?.touched['cityId'] && (
                <Typography color="error">Required!</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikInputField
                title="Humidity"
                formik={formik}
                name="humidity"
                type="number"
                IconEnd={<PercentIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikInputField
                title="Height of Warehouse (ft.) *"
                formik={formik}
                name="height"
                type="number"
              />
            </Grid>
            <Grid item xs={12} className={classes.googleMap}>
              {(uid && isLocationLoaded) || !uid ? (
                <GoogleMap
                  showSingleSearchField={true}
                  editable={true}
                  setSingleLocationLatlng={(location) => {
                    // setSingleLocationLatlng(location || {})
                    formik.setFieldValue('locationLatlng', location || {});
                    formik.setFieldTouched('locationLatlng', true);
                  }}
                  singleLocationLatlng={formik.values.locationLatlng || {}}
                  setSingleLocationAddress={(addr) =>
                    formik.setFieldValue('address', addr)
                  }
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {formik.errors.locationLatlng &&
                formik.touched.locationLatlng && (
                  <Typography color="error">Required!</Typography>
                )}
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <FormikRadioGroup
                formik={formik}
                title="Elevated Docks"
                options={radioOptions}
                name="configurations.elevatedDocks"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <FormikRadioGroup
                formik={formik}
                title="Racking Availability"
                options={radioOptions}
                name="configurations.racking"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <FormikRadioGroup
                formik={formik}
                title="Office Space for Clients"
                options={radioOptions}
                name="configurations.officeSpace"
              />
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <DetailTitle text="Temperature *" />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="ambient"
                        checked={formik?.values?.configurations?.temperature?.includes(
                          'ambient'
                        )}
                        onChange={(e) => {
                          e.target.checked
                            ? formik?.setFieldValue(
                                'configurations.temperature',
                                addArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'ambient'
                                )
                              )
                            : formik.setFieldValue(
                                'configurations.temperature',
                                removeArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'ambient'
                                )
                              );
                        }}
                      />
                    }
                    label="Ambient (15 to 30 degrees)"
                    labelPlacement="end"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="cold"
                        checked={formik?.values?.configurations?.temperature?.includes(
                          'cold'
                        )}
                        onChange={(e) => {
                          e.target.checked
                            ? formik?.setFieldValue(
                                'configurations.temperature',
                                addArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'cold'
                                )
                              )
                            : formik.setFieldValue(
                                'configurations.temperature',
                                removeArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'cold'
                                )
                              );
                        }}
                      />
                    }
                    label="Cold (8 to 15 degrees)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="refrigeration"
                        checked={formik?.values?.configurations?.temperature?.includes(
                          'refrigeration'
                        )}
                        onChange={(e) => {
                          e.target.checked
                            ? formik?.setFieldValue(
                                'configurations.temperature',
                                addArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'refrigeration'
                                )
                              )
                            : formik.setFieldValue(
                                'configurations.temperature',
                                removeArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'refrigeration'
                                )
                              );
                        }}
                      />
                    }
                    label="Refrigeration (2 to 8 degrees)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="frozen"
                        checked={formik?.values?.configurations?.temperature?.includes(
                          'frozen'
                        )}
                        onChange={(e) => {
                          e.target.checked
                            ? formik?.setFieldValue(
                                'configurations.temperature',
                                addArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'frozen'
                                )
                              )
                            : formik.setFieldValue(
                                'configurations.temperature',
                                removeArrItem(
                                  formik?.values?.configurations?.temperature,
                                  'frozen'
                                )
                              );
                        }}
                      />
                    }
                    label="Frozen (Less than 0 degrees)"
                    labelPlacement="end"
                  />
                </Grid>
                {formik?.errors?.configurations?.temperature ? (
                  <Typography color="error">Required</Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <DetailTitle text="Type of Products Stored" />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.typeOfProds.dangerous"
                    label="Dangerous goods"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.typeOfProds.fmcg"
                    label="FMCG"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.typeOfProds.manufacturing"
                    label="Manufacturing"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <FormikRadioGroup
                formik={formik}
                title="Structure *"
                options={[
                  { value: 'Fiber', label: 'Fiber' },
                  { value: 'Steel', label: 'Steel' },
                  { value: 'Cemented', label: 'Cemented' }
                ]}
                name="configurations.structure"
              />
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormikInputField
                title="Transformer KVA"
                formik={formik}
                name="configurations.transformerVoltAmps"
              />
            </Grid>
            <Grid item xs={3} sm={2}></Grid>
            <Grid item xs={12} sm={6}>
              <FormikRadioGroup
                formik={formik}
                title="Electricity Arrangement"
                options={[
                  { value: 'single', label: 'Single Phase' },
                  { value: 'three', label: '3 Phase' }
                ]}
                name="configurations.electricPhase"
              />
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} className="warehouseTitle">
                  <span
                    className="spanWarehouse"
                    style={{ display: 'flex', width: '100%' }}
                  >
                    <DetailPageTitle text="Warehouse Contract Terms:" />
                    <DetailTitle
                      className={classes.dialogTitle}
                      style={{
                        width: '50%',
                        color: '#909090',
                        fontSize: '20px',
                        fontWeight: '600'
                      }}
                      text="&nbsp;(Internal Use only)"
                    />
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8.4}
                  md={5}
                  lg={4.2}
                  xl={2.8}
                  className="warehouseCalender"
                >
                  <DetailTitle text="Contract Date Range" />
                  <DatePicker
                    setDateFilter={(value) => setDateFilter(value)}
                    setStartDate={(value) => {
                      setStartDate(value);
                      formik?.setFieldValue(
                        'configurations.contractDate.startDate',
                        value
                      );
                    }}
                    setEndDate={(value) => {
                      setEndDate(value);
                      formik?.setFieldValue(
                        'configurations.contractDate.endDate',
                        value
                      );
                    }}
                    setRange={(value) => setRange(value)}
                    dateFilter={dateFilter}
                    startDate={startDate}
                    endDate={endDate}
                    range={range}
                  />
                  {getPropByString(
                    formik.errors,
                    'configurations.contractDate.endDate'
                  ) &&
                    getPropByString(
                      formik.touched,
                      'configurations.contractDate.endDate'
                    ) && <Typography color="error">Required</Typography>}
                </Grid>
                <Grid item xs={12} sm={3} md={3} xl={2.8} className="mt-5">
                  <FormikInputField
                    title="Rent *"
                    formik={formik}
                    name="configurations.rent"
                    type="number"
                    IconEnd={<p>PKR</p>}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} className="warehouseFurtherDetails">
                  <DetailPageTitle text="Further Details:" />
                </Grid>
                <Grid item xs={12}>
                  <DetailTitle text="Operations" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.operations.followHeight"
                    label="Maximum Stacking Height Followed"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.operations.ventilation"
                    label="Ventilation Availability"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.operations.canopy"
                    label="Canopy Available"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.operations.adequateLighting"
                    label="Adequate Lighting"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.operations.forkliftAvail"
                    label="Forklift Available"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DetailTitle text="Building & Infrastructure" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.buildingAndInfrastructure.officeAreaAvail"
                    label="Office Area is Available"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.buildingAndInfrastructure.powerBackup"
                    label="Power Backup Available"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.buildingAndInfrastructure.internet"
                    label="Internet Connection"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.buildingAndInfrastructure.concealedWiring"
                    label="Concealed Electric Wires"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.buildingAndInfrastructure.noWaterLeakage"
                    label="No Water Leakages "
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={2}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.buildingAndInfrastructure.noCeilingHolesAndWraps"
                    label="Ceiling is Free of Holes and Wraps"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DetailTitle text="Housekeeping" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.housekeeping.garbageCollection"
                    label="Garbage Collection Availiability"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.housekeeping.pestControl"
                    label="Pest Control"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.housekeeping.dailyCleaning"
                    label="Daily Cleaning"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DetailTitle text="Safety and Security" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.safety.fireSafety"
                    label="Fire & Safety"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.safety.guards"
                    label="Guards"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.safety.cctv"
                    label="CCTV Camera Installed"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DetailTitle text="Licenses and Certifications" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.licAndCert.fa"
                    label="Food Authority License (FA)"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.licAndCert.iso"
                    label="ISO Certified"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.licAndCert.drap"
                    label="DRAP Certified"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormikCheckbox
                    formik={formik}
                    name="configurations.licAndCert.epa"
                    label="Environmental Protection Authority (EPA)"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <DetailTitle text="Warehouse Status" />
            </Grid>
            <Grid item xs={12}>
              <FormikCheckbox formik={formik} name="isActive" label="Active" />
            </Grid>
            <Grid item xs={12}>
              <hr className="lineSpaceWarehouse" />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DetailTitle text="Additional Instructions" />
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    className={`additionalTextArea ${classes.textArea}`}
                    id="additionalInstructions"
                    placeholder="Write here"
                    minRows={5}
                    style={{ width: '100%' }}
                    value={formik.values.additionalInstructions}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DetailTitle text="Attach Pictures" />
            </Grid>
            <Grid item xs={12} className="imageBar">
              <EditableImagesList
                removedFiles={removedFiles}
                formik={formik}
                className={classes.attachBtn}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <button
                className={classes.cancelBtn}
                type="button"
                onClick={() => navigate('/administration/warehouse')}
              >
                CANCEL
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <button
                className={attemptedAlready ? 'submitBtn no-drop' : 'submitBtn'}
                type="submit"
                disabled={attemptedAlready}
              >
                {uid ? 'UPDATE WAREHOUSE' : 'ADD WAREHOUSE'}
              </button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default AddWarehouse;

/*eslint-disable*/
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  fetchOrdersCount,
  fetchShipmentsCount,
  fetchSalesRevenue
} from '../../apis/dashboard';
import {
  decimalDisplay,
  formatNumberToKAndM,
  getCurrencySymbol,
  SharedContext
} from '../../utils/common';
import StatsDropDown from './StatsDropDown';
import { MetricStatus, SymbolType, TopBarMetricTypes } from './constants';
import Button from '../../core-components/atoms/Button';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';

const SubTopBar = () => {
  const { organization, sidebar, dashboardSelected, setDashboardSelected } =
    useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();
  const { data: ordersData } = useQuery('ordersCount', fetchOrdersCount, {
    onSuccess: (data) => {
      data.specificStats = data.moveTypeCount?.map((item) => ({
        title: item.moveType,
        value: formatNumberToKAndM(item.ordersCount),
        status: 'NONE'
      }));
    }
  });

  const { data: shipmentsData } = useQuery(
    'shipmentsCount',
    fetchShipmentsCount,
    {
      onSuccess: (data) => {
        data.specificStats = data.nodeShipmentsCount.map((item) => ({
          title: item.nodeName,
          value: formatNumberToKAndM(item.count),
          status: 'NONE'
        }));
      }
    }
  );

  const { data: salesRevenueData } = useQuery(
    'sales-revenue',
    fetchSalesRevenue,
    {
      onSuccess: (data) => {
        data.specificStats = data.nodesRevenue.map((item) => ({
          title: item.nodeName,
          value: `${getCurrencySymbol(
            organization?.defaultCurrency
          )} ${formatNumberToKAndM(item?.ordersTotalRevenue)}`,
          status: 'NONE'
        }));
      }
    }
  );

  return (
    <div
      className={clsx(
        'w-screen fixed top-[44px] h-[44px] bg-blackPurple pr-6 z-[200] transition-all',
        sidebar ? 'max-[1366px]:pl-[340px] pl-[calc(16vw+18px)]' : 'pl-[87px]'
      )}
    >
      <div className="flex justify-start w-100 h-[44px] justify-between">
        <div className={'flex items-center'}>
          <Button
            label="Dashboard"
            className={'py-2 px-3 h-8 mb-0 rounded-full border-0'}
            variant={dashboardSelected === 1 ? 'primary' : 'transparent'}
            overrideSize={true}
            labelClass="font-medium text-xs text-white"
            iconClass="mr-0"
            onClick={() => {
              setDashboardSelected(1);
            }}
          />
          {isFeatureEnabled(FLAGS.COLLABORATION_TOOL) && (
            <Button
              label="Conversations"
              className={clsx('py-2 px-3 ml-2 h-8 mb-0 rounded-full border-0')}
              overrideSize={true}
              variant={dashboardSelected === 2 ? 'primary' : 'transparent'}
              labelClass="font-medium text-xs text-white"
              iconClass="mr-0"
              onClick={() => {
                setDashboardSelected(2);
              }}
            />
          )}
        </div>
        <div className="flex bg-blue-400">
          {ordersData && (
            <StatsDropDown
              title={TopBarMetricTypes.ORDERS}
              status={MetricStatus.NONE}
              value={formatNumberToKAndM(ordersData.totalCount)}
              symbol={SymbolType.NONE}
              specificStats={ordersData.specificStats}
              showBreaker={false}
              showOnRight={false}
            />
          )}
          {shipmentsData && (
            <StatsDropDown
              title={TopBarMetricTypes.SHIPMENTS}
              status={MetricStatus.NONE}
              value={formatNumberToKAndM(shipmentsData.totalShipmentsCount)}
              symbol={SymbolType.NONE}
              specificStats={shipmentsData.specificStats}
              showOnRight={organization?.type !== 'MANUFACTURER'}
            />
          )}
          {salesRevenueData && organization?.type === 'MANUFACTURER' && (
            <StatsDropDown
              title={TopBarMetricTypes.SALES_REVENUE}
              status={MetricStatus.NONE}
              value={`${getCurrencySymbol(
                organization?.defaultCurrency
              )} ${formatNumberToKAndM(
                salesRevenueData?.totalOrganizationRevenue
              )}`}
              symbol={SymbolType.NONE}
              specificStats={salesRevenueData.specificStats}
              showOnRight={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubTopBar;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Tab, Tabs } from '@material-ui/core';
import { useNavigate } from 'react-router';

import { SharedContext } from '../../../utils/common';
import DetailText from '../../../atomicComponents/DetailText';
import InvoicesTab from './InvoicesTab';
import DraftsTab from './DraftsTab';
import CancelledTab from './CancelledTab';
import {
  InvoiceFiltersInitialValue,
  cancelledFilterInitialValue
} from '../expenseManagement/ExpenseManagement';
import API from '../../../libs/axios';
import { checkPermission } from '../../../utils/auth';
import useStyles from '../invoiceStyles';
import mixpanel from 'mixpanel-browser';

export default function InvoiceManagement() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [filterStateInvoices, setFilterStateInvoices] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterStateDrafts, setFilterStateDrafts] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterCancelledInvoice, setFilterCancelInvoice] = useState(
    cancelledFilterInitialValue
  );
  const [invoicesLength, setInvoicesLength] = useState(0);
  const [draftLength, setDraftLength] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const navigate = useNavigate();

  const { setCurrentPageTitle, setAPILoader, currentUser } =
    useContext(SharedContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStats = async () => {
    try {
      setAPILoader(true);
      const stats = await API.get('invoices/stats', {
        params: { billing: false }
      });
      setInvoicesLength(stats.invoices);
      setDraftLength(stats.drafts);
      setCancelled(stats.cancelled);
    } catch (e) {
      // onError(e)
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    setCurrentPageTitle('Receivables');
    mixpanel.track('Receivables Page', {});
    getStats();
  }, []);

  return (
    <Grid
      item
      container
      className="mt-10"
      style={{ width: '95%', margin: 'auto' }}
    >
      <Box className={classes.borderBottom} mt={5}>
        <Box className={classes.tabsContainer}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={
                <DetailText
                  text={`Invoices (${invoicesLength})`}
                  style={{ textTransform: 'none', fontSize: '1rem' }}
                  active={value == 0}
                />
              }
            />
            <Tab
              label={
                <DetailText
                  text={`Drafts (${draftLength})`}
                  style={{ textTransform: 'none', fontSize: '1rem' }}
                  active={value == 1}
                />
              }
            />
            <Tab
              label={
                <DetailText
                  text={`Cancelled (${cancelled})`}
                  style={{ textTransform: 'none', fontSize: '1rem' }}
                  active={value == 2}
                />
              }
            />
          </Tabs>
        </Box>
        {checkPermission(currentUser, 'OPS_INVOICE_CREATE') && (
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.invoiceBtn}
              onClick={() => navigate('/billing-invoice/receivables/create')}
            >
              <span className={classes.addIcon}>+</span>
              <span>Add Invoice</span>
            </Button>
          </div>
        )}
      </Box>
      {value === 0 && (
        <InvoicesTab
          tab={value}
          billing={false}
          filterStateInvoices={filterStateInvoices}
          setFilterStateInvoices={setFilterStateInvoices}
          getStats={getStats}
        />
      )}
      {value === 1 && (
        <DraftsTab
          tab={value}
          billing={false}
          filterStateDrafts={filterStateDrafts}
          setFilterStateDrafts={setFilterStateDrafts}
        />
      )}
      {value === 2 && (
        <CancelledTab
          tab={value}
          billing={false}
          filterCancelledInvoice={filterCancelledInvoice}
          setFilterCancelInvoice={setFilterCancelInvoice}
        />
      )}
    </Grid>
  );
}

/*eslint-disable*/
import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';

import { ORGANIZATION_TYPES, CURRENCIES } from '../../../constants';
import timezones from '../../../utils/timezones.json';
import TextField from '../../../core-components/atoms/TextField';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { dateFormats, distances, sizes, volumes, weights } from './config';
import Switch from '../../../core-components/atoms/Switch';
import { SharedContext } from '../../../utils/common';
import Button from '../../../core-components/atoms/Button';
import { onError } from '../../../libs/errorLib';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import { useNavigate } from 'react-router';

const SettingsForm = ({ formik, cities, countries, isContact }) => {
  const borderClass = 'mt-6 number-input-container';

  const { organization } = useContext(SharedContext);
  const navigate = useNavigate();

  const removeDummyData = async () => {
    try {
      await API.delete(`organizations/${organization.subdomain}/dummy-data`);

      toaster('success', 'Dummy Data is successfully removed');

      navigate(0);
    } catch (e) {
      onError(e);
    }
  };

  return (
    <div className="p-8 w-[80%]">
      <p className="font-bold text-[24px]">Organization Details</p>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Name & Sub domain</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="name"
            type="text"
            title="Organization Name"
            disabled
            max={255}
            value={formik.values.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="subdomain"
            type="text"
            title="Subdomain"
            disabled
            max={255}
            value={formik?.values.subdomain}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Industry & Type</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="industry"
            type="text"
            max={255}
            value={formik.values.industry}
            onChange={(e) => formik.setFieldValue('industry', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={Object.keys(ORGANIZATION_TYPES).map(
              (key) => ORGANIZATION_TYPES[key]
            )}
            value={formik.values?.type}
            placeholder="Organization Type"
            getOptionLabel={(option) =>
              option == ORGANIZATION_TYPES.MANUFACTURER ? 'Manufacturer' : '3PL'
            }
            onChange={(e, value) => formik.setFieldValue('type', value)}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Email & Contact</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="email"
            type="email"
            title="Organization Email"
            disabled
            value={formik.values.email}
            max={255}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneInput
            country={
              isContact
                ? undefined
                : formik.values.country?.isoCode?.toLowerCase()
            }
            value={formik.values.contactNumber}
            onChange={(phone) => {
              formik.setFieldValue('contactNumber', phone);
            }}
            inputStyle={{ width: '100%', height: '40px' }}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Address</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="address"
            type="text"
            label="address"
            value={formik.values.address}
            onChange={(e) => formik.setFieldValue('address', e.target.value)}
            max={255}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Country & City</p>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            value={formik.values.country || {}}
            onChange={(e, value) => {
              formik.setFieldValue('country', value);
              formik.setFieldValue('city', {});
            }}
            options={countries}
            placeholder="Select Country"
            getOptionLabel={(option) => option.name || ''}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            value={formik.values.city || {}}
            onChange={(e, value) => formik.setFieldValue('city', value)}
            options={cities}
            placeholder="Select City"
            getOptionLabel={(option) =>
              option?.name ? `${option.name} (${option.stateCode})` : ''
            }
            disabled={!formik.values.country?.name}
          />
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Additional Information</p>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="additonalInfo"
            type="text"
            placeholder="Type here"
            value={formik.values?.additionalInformation}
            onChange={(e) =>
              formik.setFieldValue('additionalInformation', e.target.value)
            }
            max={255}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      <hr className="mt-8 h-[2px]" />

      <p className="font-bold text-[24px] mt-8">Admin Settings</p>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">First & Last Name</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="text"
            placeholder="First Name"
            value={formik.values?.firstName}
            onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
            max={255}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="text"
            placeholder="Last Name"
            value={formik.values?.lastName}
            onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
            max={255}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Email & Contact</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="text"
            placeholder="Email"
            value={formik.values?.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
            max={255}
            fullWidth
            multiline
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneInput
            country={'pk'}
            value={formik.values.phone}
            onChange={(phone) => {
              formik.setFieldValue('phone', phone);
            }}
            inputStyle={{ width: '100%', height: '40px' }}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Username</p>
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="text"
            placeholder="User Name"
            value={formik.values?.username}
            onChange={(e) => formik.setFieldValue('username', e.target.value)}
            max={255}
            fullWidth
            multiline
            disabled
          />
        </Grid>
      </Grid>

      <hr className="mt-8 h-[2px]" />

      <p className="font-bold text-[24px] mt-8">Regional Settings</p>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Time Zone</p>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={timezones}
            getOptionLabel={(tz) => tz.label || ''}
            onChange={(e, value) => formik.setFieldValue('timeZone', value)}
            value={formik.values.timeZone}
            placeholder="Select Timezone"
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={dateFormats}
            value={formik.values.dateFormat}
            onChange={(e, value) => formik.setFieldValue('dateFormat', value)}
            placeholder="Select Date Format"
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Default Currency</p>
        </Grid>
        <Grid item xs={8}>
          <Autocomplete
            options={Object.keys(CURRENCIES).map((key) => key)}
            onChange={(e, value) => formik.setFieldValue('currency', value)}
            value={formik.values.currency}
            placeholder="Select Currency"
          />
        </Grid>
      </Grid>

      {organization?.type == ORGANIZATION_TYPES.MANUFACTURER && (
        <Grid container className={borderClass} spacing={1}>
          <Grid item xs={4} className="flex items-center">
            <p className="text-sm font-semibold">Tax</p>
          </Grid>
          <Grid item xs={8}>
            <Switch
              label="Enable Tax for Purchase & Sales Orders"
              checked={formik.values.taxEnabled}
              onChange={() =>
                formik.setFieldValue('taxEnabled', !formik.values.taxEnabled)
              }
            />
          </Grid>
        </Grid>
      )}

      <hr className="mt-8 h-[2px]" />

      <p className="font-bold text-[24px] mt-8">Metrics</p>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Weight & Volume</p>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={weights}
            getOptionLabel={(weight) => weight.label || ''}
            onChange={(e, value) => formik.setFieldValue('weight', value)}
            value={formik.values.weight}
            placeholder="Select Weight"
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={volumes}
            value={formik.values.volume}
            onChange={(e, value) => formik.setFieldValue('volume', value)}
            placeholder="Select Volume"
          />
        </Grid>
      </Grid>

      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="text-sm font-semibold">Size & Distance</p>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={sizes}
            getOptionLabel={(size) => size.label || ''}
            onChange={(e, value) => formik.setFieldValue('size', value)}
            value={formik.values.size}
            placeholder="Select Size"
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={distances}
            value={formik.values.distance}
            onChange={(e, value) => formik.setFieldValue('distance', value)}
            placeholder="Select Distance"
          />
        </Grid>
      </Grid>

      {organization.hasDummyData && (
        <>
          <p className="font-bold text-[24px] mt-8">Dummy Data</p>
          <Grid container className={borderClass} spacing={1}>
            <Grid item xs={4} className="flex items-center">
              <p className="text-sm font-semibold">Remove Dummy Data</p>
            </Grid>
            <Grid item xs={4}>
              <Button
                label="Remove"
                variant="primary"
                overrideSize={true}
                labelClass="font-medium text-xs"
                size="large"
                onClick={removeDummyData}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
export default SettingsForm;

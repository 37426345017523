import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  InputBase,
  Tooltip,
  FormControl,
  TextField
} from '@material-ui/core';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import classNames from 'classnames/bind';
import * as Yup from 'yup';
import { Autocomplete, InputAdornment } from '@mui/material';

import { onError } from '../../../libs/errorLib';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import warehouseStyles from './warehouseMakeStyles';
import sectionTypes from '../../../utils/enums/sectionTypes';
import sectionClassifications from '../../../utils/enums/sectionClassifications';
import { capitalize } from '../../../utils/common';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';

export default function AddHallDialog(props) {
  const { open, handleClose, addHall, selectedHall } = props;
  const classes = warehouseStyles();
  const { uid } = useParams();
  const sectionTypesCapitalized = sectionTypes.map((type) => capitalize(type));

  const [coordinates, setCoordinates] = useState({ x: '', y: '', z: '' });
  const [aisles, setAisles] = useState([]);
  const [bays, setBays] = useState([]);
  const [aisleSearch, setAisleSearch] = useState('');
  const [baySearch, setBaySearch] = useState('');

  const coordinatesClass = classNames({
    coordinatesField: true,
    [classes.textFieldBox]: true
  });

  const [formikInitVals, setInitVals] = useState({
    name: '',
    sectionType: 'GROUNDED',
    classifications: 'A',
    coordinates: {},
    pallets: null,
    aisleId: {},
    bayId: {},
    aisle: '',
    bay: '',
    capacity: null,
    isActive: false
  });
  const formik = useFormik({
    initialValues: formikInitVals,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      sectionType: Yup.string().required('Section Type is required'),
      aisle: Yup.object().required('Aisle is required'),
      bay: Yup.object().required('Bay is required'),
      pallets: Yup.number().required('Pallets is required'),
      capacity: Yup.number().required('Capacity is required')
    }),
    onSubmit: (values) => {
      submitHandler(values);
    }
  });

  const submitHandler = (values) => {
    formik.values.coordinates = {
      x: coordinates?.x || 0,
      y: coordinates?.y || 0,
      z: coordinates?.z || 0
    };
    const check = validateCapacity(values);
    if (check) {
      addHall(values);
    }
  };

  const validateCapacity = (value) => {
    if (
      (value?.sectionType?.toLowerCase() === 'racked' ||
        value?.sectionType?.toLowerCase() === 'grounded') &&
      Number(value.capacity) + Number(props?.capacity?.capacity) >
        props?.warehouseCapacity?.capacity
    ) {
      toaster(
        'warning',
        'Halls Capacity(Sq.Ft) cannot be greater than Warehouse Capacity(Sq.Ft)'
      );
      return false;
    } else if (
      (value?.sectionType?.toLowerCase() === 'racked' ||
        value?.sectionType?.toLowerCase() === 'grounded') &&
      Number(value.pallets) + Number(props?.capacity?.pallets) >
        props?.warehouseCapacity?.pallets
    ) {
      toaster(
        'warning',
        'Halls Capacity(Pallets) cannot be greater than Warehouse Capacity(Pallets)'
      );
      return false;
    } else {
      return true;
    }
  };

  const getAisles = async (search) => {
    if (!uid) {
      return;
    }
    const colVal = {
      isActive: true
    };
    const filters = {
      colVal
    };
    try {
      const res = await API.get(`warehouses/${uid}/aisles`, {
        params: { filters, search }
      });
      setAisles(res?.data);
    } catch (error) {
      onError(error);
    }
  };

  const getBays = async (search) => {
    if (!uid) {
      return;
    }
    const colVal = {
      isActive: true
    };
    const filters = {
      colVal
    };
    try {
      const res = await API.get(`warehouses/${uid}/bays`, {
        params: { filters, search }
      });
      setBays(res?.data);
    } catch (error) {
      onError(error);
    }
  };

  const getDelayedAisles = useCallback(
    debounce((value) => {
      setAisles([]);
      getAisles(value);
    }, DEBOUNCE_CONST),
    []
  );

  const getDelayedBays = useCallback(
    debounce((value) => {
      setBays([]);
      getBays(value);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    let hallObj = {
      name: selectedHall?.name,
      sectionType: selectedHall?.sectionType,
      classifications: selectedHall?.classifications,
      coordinates: selectedHall?.coordinates,
      pallets: selectedHall?.pallets,
      capacity: selectedHall?.capacity,
      isActive: selectedHall?.isActive,
      aisle: selectedHall?.Aisle,
      bay: selectedHall?.Bay
    };
    setCoordinates({
      x: selectedHall?.coordinates?.x,
      y: selectedHall?.coordinates?.y,
      z: selectedHall?.coordinates?.z
    });
    setInitVals({ ...hallObj });
  }, [selectedHall]);

  useEffect(() => {
    getDelayedAisles(aisleSearch);
  }, [aisleSearch]);

  useEffect(() => {
    getDelayedBays(baySearch);
  }, [baySearch]);

  return (
    <div style={{ display: 'inline' }}>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <Grid
            container
            className={classes.backdropGrid}
            justifyContent="flex-start"
          >
            <DialogTitle className={classes.sectionHeading}>
              <Typography className={classes.title}>
                {!selectedHall ? 'Add a Section:' : 'Edit a Section:'}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} className={classes.uploadDetails}>
                  <DetailTitle text="Section Name" />
                  <InputBase
                    fullWidth={true}
                    margin="dense"
                    id="name"
                    disabled={
                      formik?.values?.sectionType === 'STAGING' ||
                      formik?.values?.sectionType === 'QUARANTINE'
                    }
                    type="text"
                    variant="outlined"
                    value={formik?.values?.name || ''}
                    inputProps={{ className: classes.textBox }}
                    className={classes.textFieldBox}
                    onChange={(e) =>
                      formik.setFieldValue('name', e.target.value)
                    }
                  />
                  {formik?.errors?.name && formik?.touched?.name && (
                    <Typography color="error">{formik.errors.name}</Typography>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <DetailTitle text="Section Type" />
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="sectionType"
                      value={capitalize(formik?.values?.sectionType)}
                      options={sectionTypesCapitalized}
                      className="hallsAutoCompleteField"
                      disabled={
                        formik?.values?.sectionType === 'STAGING' ||
                        formik?.values?.sectionType === 'QUARANTINE'
                      }
                      getOptionLabel={(type) => type || ''}
                      getOptionDisabled={(option) =>
                        option === 'Staging' || option === 'Quarantine'
                      }
                      onChange={(e, value) => {
                        if (value) {
                          formik.setFieldValue('sectionType', value);
                        } else {
                          formik.setFieldValue('sectionType', '');
                        }
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className="inputRadius"
                        />
                      )}
                    />
                    {formik?.errors?.sectionType &&
                      formik?.touched?.sectionType && (
                        <div>
                          <Typography color="error">
                            {formik?.errors.sectionType}
                          </Typography>
                        </div>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DetailTitle text="Aisles" />
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="aisleId"
                      value={formik?.values?.aisle || ''}
                      options={aisles}
                      className="hallsAutoCompleteField"
                      getOptionLabel={(aisle) => {
                        return aisle?.name || '';
                      }}
                      onChange={(e, value) => {
                        if (value) {
                          formik.setFieldValue('aisle', value);
                          formik.setFieldValue('aisleId', value?.id);
                          setAisleSearch('');
                        } else {
                          formik.setFieldValue('aisle', '');
                          formik.setFieldValue('aisleId', '');
                          setAisleSearch('');
                        }
                      }}
                      onKeyUp={(e) => setAisleSearch(e.target.value)}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className="inputRadius"
                        />
                      )}
                    />
                    {formik?.errors?.aisle && formik?.touched?.aisle && (
                      <div>
                        <Typography color="error">
                          {formik?.errors.aisle}
                        </Typography>
                      </div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DetailTitle text="Bays" />
                  <FormControl fullWidth={true} variant="outlined">
                    <Autocomplete
                      id="bayId"
                      value={formik?.values?.bay || ''}
                      options={bays}
                      className="hallsAutoCompleteField"
                      getOptionLabel={(bay) => {
                        return bay?.name || '';
                      }}
                      onChange={(e, value) => {
                        if (value) {
                          formik.setFieldValue('bay', value);
                          formik.setFieldValue('bayId', value?.id);
                          setBaySearch('');
                        } else {
                          formik.setFieldValue('bay', '');
                          formik.setFieldValue('bayId', '');
                          setBaySearch('');
                        }
                      }}
                      onKeyUp={(e) => setBaySearch(e.target.value)}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className="inputRadius"
                        />
                      )}
                    />
                    {formik?.errors?.bay && formik?.touched?.bay && (
                      <div>
                        <Typography color="error">
                          {formik?.errors.bay}
                        </Typography>
                      </div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} className={classes.uploadDetails}>
                  <DetailTitle text="Capacity" />
                  <InputBase
                    fullWidth={true}
                    margin="dense"
                    id="pallets"
                    type="number"
                    value={
                      formik?.values?.pallets &&
                      Math.max(0, formik?.values?.pallets)
                    }
                    inputProps={classes.textBox}
                    min="0"
                    endAdornment={
                      <InputAdornment
                        sx={{
                          backgroundColor: '#EDF3FA',
                          padding: '20px 14px',
                          marginRight: '-10px',
                          border: '1.5px solid #C1D6EC',
                          borderRadius: '0 7px 7px 0'
                        }}
                        position="end"
                      >
                        Pallets
                      </InputAdornment>
                    }
                    className={classes.textFieldBox}
                    onChange={(e) =>
                      formik.setFieldValue('pallets', +e.target.value)
                    }
                  />
                  {formik?.errors?.pallets && formik?.touched?.pallets && (
                    <Typography color="error">
                      {formik.errors.pallets}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6} className={classes.uploadDetails}>
                  <DetailTitle text="Capacity" />
                  <InputBase
                    fullWidth={true}
                    margin="dense"
                    id="capacity"
                    type="number"
                    value={
                      formik?.values?.capacity &&
                      Math.max(0, formik?.values?.capacity)
                    }
                    min="0"
                    inputProps={classes.textBox}
                    endAdornment={
                      <InputAdornment
                        sx={{
                          backgroundColor: '#EDF3FA',
                          padding: '20px 14px',
                          marginRight: '-10px',
                          border: '1.5px solid #C1D6EC',
                          borderRadius: '0 7px 7px 0'
                        }}
                        position="end"
                      >
                        Sq.ft.
                      </InputAdornment>
                    }
                    className={classes.textFieldBox}
                    onChange={(e) =>
                      formik.setFieldValue('capacity', +e.target.value)
                    }
                  />
                  {formik?.errors?.capacity && formik?.touched?.capacity && (
                    <Typography color="error">
                      {formik.errors.capacity}
                    </Typography>
                  )}
                </Grid>

                <Grid item container>
                  <Grid item xs={12} lg={6}>
                    <DetailTitle text="Section Classification" />
                    <FormControl fullWidth={true} variant="outlined">
                      <Autocomplete
                        id="classifications"
                        value={formik?.values?.classifications}
                        options={sectionClassifications}
                        className="hallsAutoCompleteField"
                        getOptionLabel={(classifications) =>
                          classifications || ''
                        }
                        onChange={(e, value) => {
                          if (value) {
                            formik.setFieldValue('classifications', value);
                          } else {
                            formik.setFieldValue('classifications', '');
                          }
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            className="inputRadius"
                          />
                        )}
                      />
                      {formik?.errors?.classifications &&
                        formik?.touched?.classifications && (
                          <div>
                            <Typography color="error">
                              {formik?.errors.classifications}
                            </Typography>
                          </div>
                        )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6} className="responsive-mt">
                    <FormControl fullWidth={true} variant="outlined">
                      <Grid item container className="ml-4 responsive-div">
                        <DetailTitle text="Coordinates" />
                        <Grid item xs={12} lg={4}>
                          <InputBase
                            fullWidth={true}
                            margin="dense"
                            id="coordinates"
                            type="number"
                            value={coordinates?.x}
                            min="0"
                            inputProps={classes.textBox}
                            endAdornment={
                              <InputAdornment
                                sx={{
                                  backgroundColor: '#EDF3FA',
                                  padding: '20px 14px',
                                  marginRight: '-10px',
                                  border: '1.5px solid #C1D6EC',
                                  borderRadius: '0 7px 7px 0'
                                }}
                                position="end"
                              >
                                X
                              </InputAdornment>
                            }
                            className={coordinatesClass}
                            onChange={(e) => {
                              if (Number(e.target.value) < 0) {
                                setCoordinates({ ...coordinates, x: 0 });
                              } else {
                                setCoordinates({
                                  ...coordinates,
                                  x: e.target.value
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <InputBase
                            fullWidth={true}
                            margin="dense"
                            id="coordinates"
                            type="number"
                            value={coordinates?.y}
                            min="0"
                            inputProps={classes.textBox}
                            endAdornment={
                              <InputAdornment
                                sx={{
                                  backgroundColor: '#EDF3FA',
                                  padding: '20px 14px',
                                  marginRight: '-10px',
                                  border: '1.5px solid #C1D6EC',
                                  borderRadius: '0 7px 7px 0'
                                }}
                                position="end"
                              >
                                Y
                              </InputAdornment>
                            }
                            className={coordinatesClass}
                            onChange={(e) => {
                              if (Number(e.target.value) < 0) {
                                setCoordinates({ ...coordinates, y: 0 });
                              } else {
                                setCoordinates({
                                  ...coordinates,
                                  y: e.target.value
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <InputBase
                            fullWidth={true}
                            margin="dense"
                            id="coordinates"
                            type="number"
                            value={coordinates?.z}
                            min="0"
                            inputProps={classes.textBox}
                            endAdornment={
                              <InputAdornment
                                sx={{
                                  backgroundColor: '#EDF3FA',
                                  padding: '20px 14px',
                                  marginRight: '-10px',
                                  border: '1.5px solid #C1D6EC',
                                  borderRadius: '0 7px 7px 0'
                                }}
                                position="end"
                              >
                                Z
                              </InputAdornment>
                            }
                            className={coordinatesClass}
                            onChange={(e) => {
                              if (Number(e.target.value) < 0) {
                                setCoordinates({ ...coordinates, z: 0 });
                              } else {
                                setCoordinates({
                                  ...coordinates,
                                  z: e.target.value
                                });
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <Checkbox
                    checked={formik?.values?.isActive}
                    name=""
                    onChange={(e) =>
                      formik.setFieldValue('isActive', e.target.checked)
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  Active
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              className={classes.btnGroup}
              style={{ justifyContent: 'center' }}
            >
              <Tooltip title="Cancel">
                <Button
                  onClick={handleClose}
                  color="#D9DFE5"
                  variant="contained"
                  className={classes.hallCancelBtn}
                >
                  Cancel
                </Button>
              </Tooltip>
              <Tooltip title={!selectedHall ? 'Add Section' : 'Save Edit'}>
                <Button
                  type="submit"
                  onClick={formik.handleSubmit}
                  variant="contained"
                  className={classes.hallSubmitBtn}
                >
                  {!selectedHall ? 'ADD SECTION' : 'SAVE EDIT'}
                </Button>
              </Tooltip>
            </DialogActions>
          </Grid>
        </Dialog>
      </form>
    </div>
  );
}

import { Button as MuiButton } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const Button = ({
  variant,
  size,
  children,
  type,
  className,
  disabled,
  href,
  ref,
  component,
  onClick,
  onMouseEnter,
  onMouseLeave,
  label,
  icon,
  overrideSize = false,
  labelClass,
  iconClass
}) => {
  const variantClass = clsx({
    'bg-primary text-white hover:bg-darkPrimary focus:opacity-80':
      variant === 'primary',
    'text-primary border-primary bg-white hover:bg-secondary focus:opacity-80 active:bg-darkSecondary':
      variant === 'secondary',
    'text-primary border-none bg-white hover:bg-secondary focus:opacity-80 active:bg-darkSecondary':
      variant === 'text',
    'text-black border-none hover:bg-oasisPrimary200':
      variant === 'transparent-text',
    'text-black border-none hover:bg-oasisPrimary200 rounded-full':
      variant === 'transparent-rounded-text',
    'text-black border-black bg-transparent': variant === 'transparent',
    'text-black bg-transparent hover:bg-dark100':
      variant === 'tiny-transparent',
    'text-black border-black bg-white hover:bg-tertiary active:bg-tertiary': ![
      'primary',
      'secondary',
      'text',
      'transparent',
      'transparent-text',
      'transparent-rounded-text',
      'tiny-transparent'
    ].includes(variant)
  });
  const sizeClass =
    size == 'large' ? clsx('px-4', 'py-2') : clsx('px-4', 'py-1.5');

  return (
    <MuiButton
      className={clsx(
        variantClass,
        overrideSize || sizeClass,
        'normal-case',
        'border',
        'border-solid',
        'rounded h-10',
        'flex gap-2 flex-nowrap',
        'text-black',
        'w-auto',
        'whitespace-nowrap',
        'font-normal',
        'cursor-pointer',
        'flex',
        'items-center',
        className,
        disabled && 'bg-opacity-50'
      )}
      type={type}
      size={size}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      href={href}
      component={component}
      innerRef={ref}
    >
      <span className={iconClass}>{icon}</span>
      <span className={labelClass}>{label}</span>
      {children}
    </MuiButton>
  );
};

export default Button;

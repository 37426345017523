export const PRIMARY = '#0C71D7';
export const PRIMARY_LIGHT = '#85B8EB';
export const PRIMARY_DARK = '#0260BF';
export const BLACK = '#000000';
export const MIDTABLEBG = '#F5F5F5';
export const SECONDARY = '#F2F9FF';
export const DARK_SECONDARY = '#DFF0FE';
export const TERTIARY = '#EEF7FF';
export const LIGHT_GRAY = '#C4C6CB';
export const PRIMARY_BLUE = '#0B1940';
export const WOODSMOKE = '#17191B';
export const STORMGRAY = '#67718C';
export const FADEDRED = '#D83A52';
export const DEEPCOVE = '#0B1940';
export const PLATINUM = '#E7E6E6';
export const NAVY_BLUE = '#0C70D7';
export const NORMAL_GREEN = '#0FA04F';
export const PERSIAN_RED = '#D63232';
export const DARK_BLUE_GRAY = '#67718C';
export const SANTA_GRAY = '#9DA3B3';
export const ALICE_BLUE = '#F0F7FF';
export const BLUE_CHARCOAL = '#030B1F';
export const BLUE_EYES = '#0C71D7';
export const SHAMROCK_GREEN = '#0FA04F';
export const RICH_BLACK = '#1F1703';
export const INDIGO = '#172B61';
export const SLATE_GRAY = '#707A93';
export const PLATINUM_GRAY = '#DDE1E9';
export const CADET_GRAY = '#9DA3B3';

export const DARK_100 = '#F0F7FF';
export const DARK_200 = '#F6F7FB';
export const DARK_300 = '#E1E5F0';
export const DARK_400 = '#9DA3B3';
export const DARK_500 = '#696F80';
export const DARK_600 = '#4B5369';
export const DARK_800 = '#0B1940';
export const DARK_900 = '#030A1F';

export const OASIS_PRIMARY_100 = '#F0F7FF';
export const OASIS_PRIMARY_200 = '#D9EBFF';
export const OASIS_PRIMARY_300 = '#B8D9FF';
export const OASIS_PRIMARY_500 = '#0C6BD7';
export const OASIS_PRIMARY_600 = '#003F87';

export const DUSK_100 = '#FFF6F0';
export const DUSK_200 = '#FFE9D9';
export const DUSK_300 = '#FFD6B8';
export const DUSK_500 = '#FF6B00';
export const DUSK_600 = '#873800';

export const JADE_100 = '#EBFFEE';
export const JADE_200 = '#D9FFDE';
export const JADE_300 = '#B8FFC1';
export const JADE_500 = '#1AAE2E';
export const JADE_700 = '#008712';

export const CRIMSON_100 = '#FFEBEB';
export const CRIMSON_200 = '#FFD9D9';
export const CRIMSON_300 = '#D69A9A';
export const CRIMSON_500 = '#D63232';
export const CRIMSON_700 = '#870000';

export const SANDSTORM_100 = '#FFFAEB';
export const SANDSTORM_200 = '#FFF6D9';
export const SANDSTORM_300 = '#FFEDB8';
export const SANDSTORM_500 = '#FFC000';
export const SANDSTORM_700 = '#876600';

export const INFO = OASIS_PRIMARY_500;
export const SUCCESS = JADE_500;
export const BLACK_PURPLE = '#030B1F';
export const SOLTITUDE = '#E1E4E8';
export const YANKEES_BLUE = '#131E39';
export const DEEP_WATER = '#6A7289';
export const ERROR = '#D83A52';
export const BORDER = '#CCCCCC';
export const LIGHT = '#F6F8FB';
export const BG_CREATED = '#F0F7FF';
export const BG_CANCELLED = '#FFEBEB';
export const BACKDROP = '#0B1940';
export const NAVITEM_TITLE = '#8b96a8';
export const TABLE_HEADER_CELL = '#67718C';
export const IN_PROGRESS = '#FF6B00';
export const PENDING = '#FFC000';
export const COMPLETED = '#0FA04F';
export const WARNING = '#E3915A';
export const BLUE = '#0C70D7';
export const HIGHLIGHT = '#FFF0C3';
export const WHITE = '#FFFFFF';
export const SNOW = '#fffbf9';
export const AUROMETAL = '#696F80';

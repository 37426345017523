/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { InputAdornment, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { decimalDisplay } from '../../../utils/common';
import CustomTextField from '../../../core-components/atoms/TextField';
import { NumericFormat } from 'react-number-format';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import UomPopup from '../../revampedOrder/components/UomPopup';
import { PRIMARY } from '../../../constants/colors';

const O360GrnProductsTableRowMobile = (props) => {
  const {
    row,
    parentIndex,
    rows,
    setRows,
    settleRemainingQty,
    GRNview,
    enableActions
  } = props;

  const uomValue = row?.secondaryUomId
    ? {
        primary: false,
        conversionFactor:
          row?.Product?.ProductUOMs?.find((u) => u.uomId == row?.secondaryUomId)
            ?.conversionFactor || 1,
        ...row?.SecondaryUOM
      }
    : {
        ...row?.Product?.UOM,
        conversionFactor: 1,
        primary: true
      };

  const [remainingQty, setRemainingQty] = useState(0);
  const [dispatchedQty, setDispatchedQty] = useState(0);
  const [receivedQuantity, setReceivedQuantity] = useState(
    rows[parentIndex].receivedQuantity / uomValue?.conversionFactor
  );
  const [damagedQuantity, setDamagedQuantity] = useState(
    rows[parentIndex]?.GRNGroupBatches?.[0]?.recoverableDamageQuantity || 0
  );
  const [uom, setUom] = useState(uomValue);
  const [uomView, setUomView] = useState(false);
  const [primaryUom, setPrimaryUom] = useState({
    ...row?.Product?.UOM,
    conversionFactor: 1,
    primary: true
  });
  const [secondaryUoms, setSecondaryUoms] = useState(
    row?.Product?.ProductUOMs?.map((uom) => ({
      id: uom?.id,
      name: uom?.UOM?.name,
      conversionFactor: uom?.conversionFactor,
      uomId: uom?.uomId
    }))
  );

  const uoms = [primaryUom, ...secondaryUoms];

  useEffect(() => {
    const [dispatchedQty] = row?.GDNGroupBatches?.reduce(
      (acc, obj) => {
        return [
          acc[0] + (+obj?.actualDispatchedQuantity || 0),
          acc[1] +
            (+obj?.recoverableDamageQuantity || 0) +
            (+obj?.unsellableDamageQuantity || 0)
        ];
      },
      [0, 0]
    ) || [0, 0];

    setDispatchedQty(parseFloat(dispatchedQty.toFixed(3)));
  }, [rows]);

  useEffect(() => {
    const remainingQty = row?.orderedQuantity
      ? row?.orderedQuantity - (row?.receivedQuantity || 0)
      : 0;
    setRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));
    settleRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));

    let toggleData = [...rows];

    if (!GRNview) {
      if (!uom?.primary) {
        toggleData[parentIndex].secondaryUomId = uom?.id || uom?.uomId;
        toggleData[parentIndex].secondaryUomQuantity = receivedQuantity;
      } else {
        toggleData[parentIndex].secondaryUomId = null;
        toggleData[parentIndex].secondaryUomQuantity = null;
      }
      setRows(toggleData);
    }
  }, [receivedQuantity, damagedQuantity, row?.orderedQuantity]);

  useEffect(() => {
    if (row?.secondaryUomId) {
      setUom(uoms?.find((uom) => uom?.uomId == row?.secondaryUomId));
    }
  }, [row]);

  return (
    <>
      <div className="card group mb-4 p-4 shadow-lg border rounded-lg bg-white mt-2">
        <div className="card-header">
          <Tooltip title={`${row?.Product?.name}`}>
            <p className="text-base font-bold">
              {row?.Product.name} - {row?.Product.description}
            </p>
          </Tooltip>
          {row?.Product?.barcode && (
            <div className="text-xs text-gray-600 truncate">
              <span>BARCODE: {row?.Product?.barcode}</span>
            </div>
          )}
        </div>

        <div className="uom-section mt-4">
          <p className="text-sm font-bold mb-2">Unit of Measure</p>
          <Autocomplete
            value={uom}
            onChange={(e, value) => {
              setReceivedQuantity(
                (receivedQuantity * uom?.conversionFactor) /
                  value.conversionFactor
              );
              setDamagedQuantity(
                (damagedQuantity * uom?.conversionFactor) /
                  value.conversionFactor
              );
              setUom(value);
            }}
            options={[
              ...uoms,
              ...(!GRNview ? [{ name: '+ Create UoM', type: 'add' }] : [])
            ]}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                padding: '0 8px',
                fontSize: '0.875rem'
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #e0e0e0'
              },
              width: '100%'
            }}
            renderOption={(props, option) =>
              option?.type === 'add' ? (
                <li
                  className="auto-complete-option"
                  style={{
                    color: PRIMARY,
                    fontSize: '16px',
                    fontWeight: 500
                  }}
                  onClick={() => setUomView(true)}
                >
                  {option.name}
                </li>
              ) : (
                <li {...props} className="auto-complete-option-small">
                  <span>
                    {option.name} {option.primary && '(primary)'}
                  </span>
                </li>
              )
            }
            getOptionLabel={(uom) => uom.name}
            placeholder="Select UoM"
          />
        </div>

        <div className="quantity-info mt-4">
          <p className="text-sm font-bold mb-2">Quantities</p>
          <div className="text-base">
            <p>
              <span className="font-bold">Ordered: </span>
              {decimalDisplay(
                row.orderedQuantity / (uom?.conversionFactor || 1)
              )}
            </p>
          </div>

          <div className="text-base mt-2">
            <p>
              <span className="font-bold">Received: </span>
              {GRNview ? (
                decimalDisplay(receivedQuantity)
              ) : (
                <NumericFormat
                  value={receivedQuantity || null}
                  onValueChange={(e) => {
                    let toggleData = [...rows];
                    setReceivedQuantity(e.value);
                    if (toggleData[parentIndex].GRNGroupBatches?.length > 0) {
                      toggleData[
                        parentIndex
                      ].GRNGroupBatches[0].receivedQuantity =
                        e.value * uom?.conversionFactor;
                    } else {
                      toggleData[parentIndex].GRNGroupBatches = [
                        {
                          receivedQuantity: e.value * uom?.conversionFactor
                        }
                      ];
                    }
                    setRows(toggleData);
                  }}
                  customInput={CustomTextField}
                  placeholder="Received Qty"
                  variant="outlined"
                  size="small"
                  decimalScale={3}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    const maxQuantity = row.quantity;
                    return maxQuantity - floatValue >= 0;
                  }}
                />
              )}
            </p>
          </div>

          <div className="text-base mt-2">
            <p>
              <span className="font-bold">Damaged: </span>
              <NumericFormat
                value={damagedQuantity || null}
                onValueChange={(e) => {
                  let toggleData = [...rows];
                  setDamagedQuantity(e.value);
                  if (toggleData[parentIndex].GRNGroupBatches?.length > 0) {
                    toggleData[
                      parentIndex
                    ].GRNGroupBatches[0].recoverableDamageQuantity =
                      +e.value * (uom?.conversionFactor || 1);
                  } else {
                    toggleData[parentIndex].GRNGroupBatches = [
                      {
                        recoverableDamageQuantity:
                          +e.value * (uom?.conversionFactor || 1)
                      }
                    ];
                  }
                  setRows(toggleData);
                }}
                customInput={CustomTextField}
                placeholder="Damaged Qty"
                variant="outlined"
                size="small"
                decimalScale={3}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  const maxQuantity = row.quantity;
                  return maxQuantity - floatValue >= 0;
                }}
              />
            </p>
          </div>

          <div className="text-base mt-2">
            <p>
              <span className="font-bold">Remaining: </span>
              {decimalDisplay(remainingQty / uom.conversionFactor)}
            </p>
          </div>
        </div>

        <UomPopup
          open={uomView}
          setOpen={setUomView}
          primaryUom={primaryUom}
          secondaryUoms={secondaryUoms}
          setPrimaryUom={setPrimaryUom}
          setSecondaryUoms={setSecondaryUoms}
          refetech={() => {}}
          value={{ id: row?.Product?.id }}
        />
      </div>
    </>
  );
};

export default O360GrnProductsTableRowMobile;

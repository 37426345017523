import clsx from 'clsx';
import { CalendarStatusDetails } from '../constants';
import { getDirectionArrow } from './utils';

const ShipmentSummaryBox = ({
  nodeName,
  status,
  className,
  textClassName,
  moveType,
  onClick
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        'rounded flex flex-row items-center py-[6px] px-2 mb-2 cursor-pointer',
        {
          'bg-navyBlue': status.key === 'IN_TRANSIT',
          'bg-fadedRed': status.key === 'CANCELLED',
          'bg-normalGreen':
            status.key === 'COMPLETED' || status.key === 'ARRIVED',
          'bg-dark100 border-dark400 border-[1px]':
            status.key === 'READY_FOR_PICKUP' || status.key === 'CREATED'
        },
        className
      )}
    >
      <div className="max-w-[20px] w-full max-h-[20px] h-full flex justify-center items-center">
        <img
          src={getDirectionArrow(
            moveType,
            CalendarStatusDetails[status.key]?.icon
          )}
        />
      </div>
      <div
        className={clsx(
          'font-normal text-xs text-primaryBlue truncate',
          {
            primaryBlue:
              status.key === 'READY_FOR_PICKUP' || status.key === 'CREATED',
            'text-white':
              status.key !== 'READY_FOR_PICKUP' && status.key !== 'CREATED'
          },
          textClassName
        )}
      >
        <div className="truncate">{nodeName}</div>
        <div className="truncate capitalize">
          {CalendarStatusDetails[status.key]?.label}
        </div>
      </div>
    </div>
  );
};

export default ShipmentSummaryBox;

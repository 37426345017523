import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import Phyramid from '../assets/icons/pyramid.svg';

const useStyles = makeStyles(() => ({}));

const RouteDetails = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
        className="dialogParent"
        aria-labelledby="form-dialog-title"
      >
        <Grid className={classes.dialogContainer}>
          <DialogContent className="infomodalimg">
            <img src={Phyramid} />
            <div className="mt-5">
              <p
                style={{
                  fontSize: '20px',
                  fontWeight: 600,
                  paddingBottom: '14px'
                }}
              >
                Are you sure you want to remove it from route!
              </p>
            </div>
          </DialogContent>

          <DialogActions className="buttonstyle">
            <Button
              onClick={() => {
                props.handleClose();
              }}
              color="default"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              className="rightbutton"
              onClick={() => {
                props.setGoHead(true);
                props?.handleClose();
              }}
              color="primary"
              variant="contained"
            >
              YES
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
};

export default RouteDetails;

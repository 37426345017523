import { useContext, useEffect } from 'react';
import { SharedContext } from '../../../../utils/common';
import Button from '../../../../core-components/atoms/Button';
import PermissionArtwork from '../../../../assets/icons/permissionArtwork.svg';

const AccessScreen = () => {
  const getTimeOfDay = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return 'Morning';
    } else if (currentHour >= 12 && currentHour < 17) {
      return 'Afternoon';
    } else {
      return 'Evening';
    }
  };
  const { setCurrentPageTitle, currentUser } = useContext(SharedContext);

  useEffect(() => {
    setCurrentPageTitle(
      <div className="ml-[-20px]">
        Good {getTimeOfDay()}, {currentUser.firstName}
      </div>
    );
  });

  return (
    <div className="w-full h-[calc(100vh-64px)] flex px-8 items-center">
      <div className="w-full flex flex-col items-center">
        <img src={PermissionArtwork} alt="art" />

        <p className="mt-6 mb-3 text-center text-primaryBlue font-bold text-2xl">
          This is a restricted page
        </p>
        <p className="text-center text-dark500 text-base max-w-[636px] mb-[34px]">
          Opps! It looks like you’ve reached a valid URL, but you don’t have
          permission to view this content. Please reach out to the administrator
          or contact support to request access.
        </p>
        <Button
          variant="primary"
          label="Previous Page"
          onClick={() => {
            window.history.back();
          }}
        />
      </div>
    </div>
  );
};

export default AccessScreen;

/*eslint-disable*/
import * as Yup from 'yup';

export const cusomterInitialValues = {
  supportingDocuments: [],
  name: '',
  code: '',
  customerType: '',
  ntn: '',
  paymentTerms: '',
  shippingAddress: '',
  billingAddress: '',
  sameAsBilling: false,
  email: ''
};

export const customerSchema = Yup.object().shape({
  name: Yup.string().required('Name is Required'),
  customerType: Yup.object().when('isSupplier', {
    is: false,
    then: Yup.object().shape({
      value: Yup.string().required('Customer type is required')
    }),
    otherwise: Yup.object().shape({
      value: Yup.string().required('Supplier type is required')
    })
  }),
  // billingAddress: Yup.string().required('Billing Address is Required'),
  // email: Yup.string()
  //   .required('Email is Required')
  //   .email('Invalid Email format'),
  // phone: Yup.string()
  //   .required('Phone Number is Required')
  //   .min(11, 'Phone number length must be 11'),
  isSupplier: Yup.boolean()
  // shippingAddress: Yup.string().when('isSupplier', {
  //   is: false,
  //   then: Yup.string().required('Shipping Address is Required')
  // })
});

import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchShipments } from '../../../../apis/dashboard';
import AdvancedMarker from '../../../../components/AdvancedMarker';
import NodeShipments from '../../NodeShipments';
import {
  ShipmentFilterType,
  ShipmentStatus,
  NodeType as NodeCategory
} from '../../constants';
import ShipmentFilters from '../ShipmentFilters';
import {
  convertLatLng,
  getLinks,
  getNodeActivityColor,
  getNodeIcon,
  setMapBounds
} from '../utils';

const NodesOverlay = ({
  nodeData,
  map,
  setSource,
  setLinks,
  setMarkers,
  startDate,
  endDate,
  statuses,
  nodeType,
  highlight,
  setHighlight,
  fromNode,
  toNode,
  searchKeyword,
  setSearchKeyword,
  shipmentsModal,
  setShipmentsModal
}) => {
  const [statusFilter, setStatusFilter] = useState(ShipmentStatus.ALL);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [shipmentCount, setShipmentCount] = useState(0);

  const [, setCurrentDirections] = useState({
    INWARD: [],
    OUTBOUND: [],
    BOTH: []
  });
  const [selectedNode, setSelectedNode] = useState({});
  const [nodeShipmentData, setNodeShipmentData] = useState(null);
  const { refetch, isFetching } = useQuery(
    ['shipments', selectedNode],
    () => {
      return fetchShipments({ ...selectedNode, search: searchKeyword });
    },
    {
      enabled: true,
      onSuccess: (data) => {
        setNodeShipmentData(data.data);
        setPageCount(data.pages);
        setShipmentCount(data.count);
      }
    }
  );
  const addMarkerRef = (markerRef) => {
    setMarkers((prev) => [...prev, markerRef]);
  };

  useEffect(() => {
    refetch();
  }, [selectedNode]);

  const getNodeShipments = (id, type, name, extraCode) => {
    setSelectedNode({
      nodeType: NodeCategory[nodeType],
      nodeId: id,
      from: startDate,
      to: endDate,
      name: name,
      extraCode: extraCode,
      active: true,
      filterType: ShipmentFilterType.STATUS,
      exactNodeType: NodeCategory[nodeType],
      page
    });
    setShipmentsModal(true);
  };

  useEffect(() => {
    setMapBounds(map, nodeData, 0.1);
  }, [nodeData, map]);

  return (
    <>
      <NodeShipments
        open={shipmentsModal}
        setOpen={setShipmentsModal}
        title={`${
          selectedNode.extraCode
            ? `${selectedNode.extraCode}: ${selectedNode.name}`
            : selectedNode.name
        }`}
        shipments={nodeShipmentData}
        startDate={startDate}
        endDate={endDate}
        filterType={selectedNode.filterType}
        setNodeShipmentData={setNodeShipmentData}
        currentNodeType={selectedNode.nodeType}
        currentNodeId={selectedNode.nodeId}
        status={selectedNode.status}
        initialNodeType={nodeType}
        isFetchingInitial={isFetching}
        shipmentsCount={shipmentCount}
        setShipmentsCount={setShipmentCount}
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        setPageCount={setPageCount}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
      />

      <ShipmentFilters
        statuses={statuses}
        setStatusFilter={setStatusFilter}
        setShipmentsModal={setShipmentsModal}
        setSelectedNode={setSelectedNode}
        startDate={startDate}
        endDate={endDate}
        nodeType={nodeType}
      />
      {Object.keys(nodeData).map((key, index) => (
        <AdvancedMarker
          key={index}
          map={map}
          position={nodeData[key].position}
          onClick={() => {
            getNodeShipments(
              nodeData[key].id,
              nodeData[key].type,
              nodeData[key].name,
              nodeData[key].extraCode
            );
          }}
          addMarkerRef={addMarkerRef}
          hovered={highlight === key}
        >
          <div
            className={clsx({
              'flex w-fit py-2 px-3 h-[50px] rounded-lg shadow-[0_4px_4px_0px_rgba(0,0,0,0.16)] bg-white items-center transition-all cursor-pointer mb-[-10px]':
                highlight === key,
              'flex w-fit justify-between rounded-full shadow-[0_4px_4px_0px_rgba(0,0,0,0.16)] items-center cursor-pointer mb-[-10px]':
                highlight !== key,
              'opacity-20':
                nodeData[key]?.statuses[statusFilter] === 0 ||
                (!nodeData[highlight]?.links.includes(key) &&
                  highlight !== key &&
                  highlight)
            })}
            onMouseEnter={() => {
              if (!fromNode && !toNode) {
                setHighlight(key);
                setSource(
                  convertLatLng(nodeData[key].position || { lat: 0, lng: 0 })
                );
                setLinks(getLinks(key, nodeData));
                setCurrentDirections(nodeData[key].directions);
              }
            }}
            onMouseLeave={() => {
              if (!fromNode && !toNode) {
                setHighlight(null);
                setSource([]);
                setLinks([]);
                setCurrentDirections({
                  INWARD: [],
                  OUTBOUND: [],
                  BOTH: []
                });
              }
            }}
            data-active-shipments={nodeData[key].activeShipments}
            data-node-type={nodeData[key].nodeType}
            data-node-index={index}
            data-node-key={key}
            data-node-statuses={JSON.stringify(nodeData[key].statuses)}
          >
            <div
              className={clsx(
                `rounded-full flex p-1 ${getNodeActivityColor(
                  nodeData[key].activityLevel
                )}`,
                highlight === key && 'mr-2 h-fit px-2 py-1.5'
              )}
            >
              <img
                src={getNodeIcon(nodeData[key].nodeType)}
                alt="icon"
                className={highlight !== key && 'h-2 w-2'}
              />
            </div>
            <div>
              {highlight === key && (
                <p className="text-primaryBlue text-base h-fit self-center font-medium">
                  {nodeData[key].name}
                  <span className="ml-1.5">{nodeData[key].nodeType}</span>
                </p>
              )}
              <p
                className={clsx(
                  highlight === key &&
                    'text-primaryBlue text-base h-fit self-center font-medium -mt-2 transition-all',
                  highlight !== key &&
                    'text-primaryBlue text-base h-fit self-center font-medium transition-all flex'
                )}
              >
                {highlight === key && nodeData[key].activeShipments}
                {highlight === key && (
                  <span className="text-darkBlueGray text-xs ml-1">
                    Active Shipments
                  </span>
                )}
              </p>
            </div>
          </div>
        </AdvancedMarker>
      ))}
    </>
  );
};

export default NodesOverlay;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import API from '../../../libs/axios';
import TextField from '../../../core-components/atoms/TextField';
import {
  FormControl,
  InputAdornment,
  List,
  ListItem,
  Popper
} from '@mui/material';
import { debounce } from 'lodash';
import { onError } from '../../../libs/errorLib';
import { DEBOUNCE_CONST } from '../../../Config';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Close, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { Paragraph, Title } from '../../../core-components/atoms/Text';
import moment from 'moment/moment';
import clsx from 'clsx';

const CommentSearchBar = ({
  order,
  shipment,
  setSelectedSearch,
  selectedSearch,
  setChatSearch
}) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [chat, setChat] = useState([]);
  const [selection, setSelection] = useState(false);

  const anchorRef = useRef(null);

  const useStyles = makeStyles(() => ({
    popper: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      }
    },
    paper: {
      pointerEvents: 'auto',
      width: '300px',
      border: '1px solid #E7E6E6',
      marginTop: '2px',
      maxHeight: '220px',
      boxShadow: '0px 8px 12px 0px #0000001F',
      overflowY: 'auto',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& input': {
        fontSize: '12px !important'
      }
    },
    heading: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#67718C',
      padding: '16px 8px',
      marginLeft: '12px'
    },
    option: {
      display: 'flex',
      gap: '16px',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer'
    }
  }));

  const handleClose = (e) => {
    if (
      document
        ?.getElementsByClassName('container-search-order')[0]
        ?.contains(e.target)
    ) {
      e.preventDefault();
    } else {
      setOpen(false);
      setSelectedSearch('');
    }
  };

  useEffect(() => {
    if (search) {
      getDelayedUserComments(1, order, shipment, search);
    }
  }, [search]);

  const getDelayedUserComments = useCallback(
    debounce(
      async (page, orderDetail, shipmentDetail, searching) => {
        getUserComments(page, orderDetail, shipmentDetail, searching);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getUserComments = async (
    page,
    orderDetail,
    shipmentDetail,
    searching
  ) => {
    try {
      const res = await API.get(`comments`, {
        params: {
          page: page,
          search: searching,
          columns: ['content'],
          limit: 50,
          ...(orderDetail?.id ? { orderId: orderDetail?.id } : {}),
          ...(shipmentDetail?.id ? { shipmentId: shipmentDetail?.id } : {})
        }
      });
      const newArr = res?.data;
      setChat(newArr);
    } catch (error) {
      onError(error);
    }
  };

  const classes = useStyles();

  return (
    <div className="w-[300px] h-8">
      {selection ? (
        <div
          className="flex gap-1 text-xs w-[300px] h-8 text-black border border-solid border-black py-1 px-4 items-center justify-between rounded"
          onClick={() => {
            setSelection(false);
            setSelectedSearch('');
            setChatSearch(false);
          }}
        >
          <div className="flex items-center gap-3 overflow-hidden whitespace-nowrap overflow-ellipsis">
            <Search
              style={{ width: '20px', height: '20px', cursor: 'pointer' }}
            />
            <span>{selectedSearch}</span>
          </div>
          <Close
            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
            onClick={() => {
              setSearch('');
            }}
          />
        </div>
      ) : (
        <FormControl fullWidth ref={anchorRef}>
          <TextField
            placeholder="Search comments"
            variant="outlined"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            // size="medium"
            fullWidth
            inputProps={{
              onFocus: () => setOpen(true)
            }}
            sxProps={{
              '& .MuiInputBase-root': {
                padding: '4px 16px !important',
                fontSize: '12px',
                height: 32
              },
              '& input::placeholder': {
                fontSize: '12px'
              },
              '& input': {
                padding: '0px !important'
              },
              '& textarea::placeholder': {
                fontSize: '12px'
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '8px'
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      )}
      {search && !selection && open && (
        <Popper
          open={open}
          anchorEl={
            anchorRef.current ||
            document.getElementsByClassName('container-search-order')[0]
          }
          placement="bottom-start"
          disablePortal
          className={classes.popper}
          modifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: true,
              boundariesElement: 'viewport'
            }
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={classes.paper}>
              <List>
                <p className={classes.heading}>SEARCH RESULTS</p>
                <>
                  {chat?.map((result) => (
                    <ListItem
                      key={result}
                      className={classes.option}
                      onClick={() => {
                        setSearch(result.content);
                        setSelection(true);
                        setSelectedSearch(result?.content);
                      }}
                    >
                      <div className={'flex flex-col overflow-hidden'}>
                        <div className={'flex gap-2 items-center'}>
                          <Title variant={'sm'}>
                            {result?.createdBy?.name}
                          </Title>
                          <Paragraph
                            variant={'xs'}
                            className={'text-[#696F80]'}
                          >
                            {moment(result?.updatedAt)?.fromNow()}
                          </Paragraph>
                        </div>
                        <Paragraph
                          variant={'sm'}
                          className={clsx('mt-1.5 w-[264px] truncate')}
                        >
                          {result?.content}
                        </Paragraph>
                      </div>
                    </ListItem>
                  ))}
                </>
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

export default CommentSearchBar;

import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback
} from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TextField,
  Tooltip,
  TableContainer,
  Box
} from '@material-ui/core';
import classNames from 'classnames/bind';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrintIcon from '@material-ui/icons/Print';
import { Autocomplete, Pagination } from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useReactToPrint } from 'react-to-print';
import { debounce } from 'lodash';

import {
  SharedContext,
  dateWithYearFirst,
  dividerDateFormatForFilter,
  dividerDateFormatForYear
} from '../../../utils/common';
import PalletsQR from './PalletsQR';
import useStyles from './palletStyles';
import { checkPermission } from '../../../utils/auth';
import '../../../assets/css/style.css';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';
import PalletLists from './PalletLists';
import { DEBOUNCE_CONST } from '../../../Config';
import CustomFilter from '../../../components/CustomFilter';
import SearchBar from '../../../atomicComponents/SearchBar';
import mixpanel from 'mixpanel-browser';

export default function PalletView() {
  const classes = useStyles();
  const isPhone = useMediaQuery('(max-width:768px)');

  const palletColumns = [
    {
      field: 'businessWarehouseCode',
      headerName: 'Warehouse',
      disableColumnMenu: true,
      sortable: true,
      flex: isPhone ? null : 1,
      width: isPhone ? 180 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Warehouse</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.businessWarehouseCode}>
          <span className="table-cell-trucate">
            {params?.row?.businessWarehouseCode}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'palletIdStart',
      headerName: 'Pallet ID Start',
      disableColumnMenu: true,
      sortable: true,
      flex: isPhone ? null : 1,
      width: isPhone ? 220 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Pallet ID Start</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.palletIdStart}>
          <span className="table-cell-trucate">
            {params?.row?.palletIdStart}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'palletIdEnd',
      headerName: 'Pallet ID End',
      disableColumnMenu: true,
      sortable: true,
      flex: isPhone ? null : 1,
      width: isPhone ? 220 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Pallet ID End</p>;
      },
      renderCell: (params) => (
        <Tooltip title={params?.row?.palletIdEnd}>
          <span className="table-cell-trucate">{params?.row?.palletIdEnd}</span>
        </Tooltip>
      )
    },
    {
      field: 'creationDate',
      headerName: 'Generated On',
      disableColumnMenu: true,
      sortable: true,
      flex: isPhone ? null : 1,
      width: isPhone ? 150 : null,
      renderHeader: () => {
        return <p className="w-100 parentActions">Generated On</p>;
      },
      renderCell: (params) => (
        <Tooltip title={dividerDateFormatForFilter(params?.row?.creationDate)}>
          <span className="table-cell-trucate">
            {dividerDateFormatForFilter(params?.row?.creationDate)}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      width: 100,
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_PALLET_READ') ? (
            <Tooltip title="Detail">
              <VisibilityIcon
                className="cursor-pointer"
                key={params}
                onClick={() => {
                  setSelectedPallet(params?.row);
                  setOpen(true);
                }}
              />
            </Tooltip>
          ) : (
            ''
          )}

          {checkPermission(currentUser, 'OPS_PALLET_READ') ? (
            <Tooltip title="Print">
              <PrintIcon
                className="cursor-pointer ml-3"
                key={params}
                onClick={() => {
                  handlePrint(params?.row);
                  setSelectedPallet(params?.row);
                }}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [pallets, setPallets] = useState([]);
  const [allPallets, setAllPallets] = useState([]);
  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);
  const [warehouses, setWarehouses] = useState([]);
  const [generatePallet, setGeneratePallet] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [selectedPallet, setSelectedPallet] = useState('');
  const [filterView, setFilterView] = useState(false);
  const [warehouseSearch, setWarehouseSearch] = useState('');
  const [warehouseKeyword, setWarehouseKeyword] = useState('');
  const [palletKeyword, setPalletKeyword] = useState('');
  const [creationDateKeyword, setCreationDateKeyword] = useState('');
  const [filter, setFilter] = useState(false);
  const { currentUser } = useContext(SharedContext);
  const [clickedAlready, setClickedAlready] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);

  const palletActions = classNames({
    'mt-5': true,
    [classes.container]: true
  });

  const filterActions = classNames({
    'mb-5': true,
    [classes.container]: true
  });

  const generateAction = classNames({
    'no-drop': !selectedWarehouse || !generatePallet || clickedAlready,
    [classes.generatePalletBtn]: true,
    'display-none': !checkPermission(currentUser, 'OPS_PALLET_CREATE')
  });

  const warehouseObj = {
    type: 'text',
    className: 'textField',
    label: 'Warehouse',
    field: 'businessWarehouseCode',
    value: warehouseKeyword || '',
    setNameVal: (e) => setNameVal(setWarehouseKeyword, e)
  };

  const palletObj = {
    type: 'text',
    className: 'textField',
    label: 'Pallet ID',
    field: 'palletId',
    value: palletKeyword || '',
    setNameVal: (e) => setNameVal(setPalletKeyword, e)
  };

  const creationDateObj = {
    type: 'datePicker',
    className: 'textField',
    label: 'Generated On',
    field: 'creationDate',
    value: creationDateKeyword || '',
    setNameVal: (e) => setNameVal(setCreationDateKeyword, e)
  };

  const filterCols = [warehouseObj, palletObj, creationDateObj];

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const closeFilter = () => {
    setFilterView(false);
  };

  const clearFilter = () => {
    setCreationDateKeyword('');
    setWarehouseKeyword('');
    setPalletKeyword('');
    getPallets(searchValue, true);
  };

  const getPallets = async (search, clearFilter) => {
    try {
      setMuiLoader(true);
      setAPILoader(true);
      const columns = [
        'palletId',
        'creationDate',
        '$Warehouse.businessWarehouseCode$'
      ];
      let colVal = {};
      if (!clearFilter && palletKeyword) {
        colVal.palletId = palletKeyword;
      }
      if (!clearFilter && warehouseKeyword) {
        colVal['$Warehouse.businessWarehouseCode$'] = warehouseKeyword;
      }
      if (!clearFilter && creationDateKeyword) {
        colVal['$creationDate$'] = `${dateWithYearFirst(
          creationDateKeyword
        )} 00:00:00`;
      }

      if (
        !clearFilter &&
        (palletKeyword || warehouseKeyword || creationDateKeyword)
      ) {
        setFilter(true);
      } else {
        setFilter(false);
      }

      const filters = { colVal };
      const pallets = await API.get(`pallets/list`, {
        params: { search, filters, columns, page }
      });
      setPageCount(pallets.pages);
      setPallets(pallets.data);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    } finally {
      setMuiLoader(false);
      setAPILoader(false);
    }
  };

  const getDelayedWarehouses = useCallback(
    debounce((value) => {
      setWarehouses([]);
      getWarehouses(value);
    }, DEBOUNCE_CONST),
    []
  );

  const getWarehouses = async (search, columns) => {
    try {
      setAPILoader(true);
      const warehouses = await API.get(`warehouses`, {
        params: { search, columns }
      });
      setWarehouses(warehouses.data);
    } catch (err) {
      if (err?.response?.data?.error?.errors) {
        let errors = err?.response?.data?.error?.errors;
        errors?.map((data) => {
          onError(data);
        });
      } else {
        onError(err);
      }
    } finally {
      setAPILoader(false);
    }
  };

  const componentRef = useRef();

  const handlePrint = async (selectedPallet) => {
    await getAllPallets(selectedPallet);
    handleReactPrint();
  };
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    trigger: () => componentRef.current.QRPrtints
  });

  const resetCredentials = () => {
    setGeneratePallet('');
    setSelectedWarehouse('');
  };

  const filterModal = (
    <div className={filterView ? 'customFilterViewSec' : 'display-none'}>
      <CustomFilter
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        clearFilter={clearFilter}
        applyFilter={getPallets}
        handleClose={() => closeFilter()}
        handleOpen={() => openFilterView()}
        columns={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const generatePallets = async () => {
    try {
      setClickedAlready(true);
      setAPILoader(true);
      const data = {
        warehouseId: selectedWarehouse?.id,
        palletsNumber: Number(generatePallet),
        date: dateWithYearFirst(Date.now())
      };
      await API.post(`pallets`, data);
      toaster('success', 'Pallets generated successfully.');
      mixpanel.track('Pallets generated', {});
      resetCredentials();
      getPallets(searchValue, false);
    } catch (err) {
      if (err?.response?.data?.error?.errors) {
        let errors = err?.response?.data?.error?.errors;
        errors?.map((data) => {
          onError(data);
        });
      } else {
        onError(err);
      }
    } finally {
      setClickedAlready(false);
      setAPILoader(false);
    }
  };

  const getAllPallets = async (selectedPallet) => {
    try {
      setAPILoader(true);
      const limit = selectedPallet?.palletIdEnd?.substr(
        selectedPallet?.palletIdEnd?.length - 5
      );
      const colVal = {
        [`$creationDate$`]: `${dividerDateFormatForYear(
          selectedPallet?.creationDate
        )} 00:00:00`,
        warehouseId: selectedPallet?.warehouseId,
        usedDate: ''
      };
      const filters = {
        colVal
      };
      const pallets = await API.get(`pallets`, {
        params: { filters, limit }
      });
      setAllPallets(pallets.data);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors?.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    getWarehouses();
    getPallets(searchValue, false);
  }, [searchValue, page]);

  useEffect(() => {
    setCurrentPageTitle('Pallet IDs Generator');
    mixpanel.track('Pallets Page', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    getDelayedWarehouses(warehouseSearch);
  }, [warehouseSearch]);

  const filterGenerateNumber = (e) => {
    if (e.target.value && e.target.value > 0) {
      setGeneratePallet(e.target.value);
    } else {
      setGeneratePallet(0);
    }
  };

  return (
    <Paper className={classes.root}>
      {open ? (
        <PalletLists
          handleClose={() => {
            setOpen(false);
          }}
          type="Aisle"
          route="aisles"
          selectedPallet={selectedPallet}
          key="AddAisleViewComponent"
          open={open}
        />
      ) : (
        ''
      )}
      {allPallets ? (
        <Box display="none" displayPrint="block" ref={componentRef}>
          <PalletsQR pallets={allPallets} />
        </Box>
      ) : (
        ''
      )}
      <Grid item container className={palletActions}>
        <Grid item xs={12} sm={4} lg={4} className="mt-8">
          <Autocomplete
            id="size-small-outlined"
            size="small"
            key="warehouses"
            margin="dense"
            className="palletActionAutocomplete"
            onKeyPress={(e) => setWarehouseSearch(e.target.value)}
            options={warehouses}
            value={selectedWarehouse}
            renderInput={(params) => (
              <TextField {...params} label="Warehouse *" variant="outlined" />
            )}
            getOptionLabel={(warehouse) =>
              warehouse.businessWarehouseCode || warehouse.name
                ? `${warehouse.name} ${
                    warehouse?.businessWarehouseCode
                      ? `- ${warehouse.businessWarehouseCode}`
                      : ''
                  }`
                : ''
            }
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedWarehouse(newValue);
                setWarehouseSearch('');
              } else {
                setWarehouseSearch('');
                setSelectedWarehouse('');
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <TextField
            required
            fullWidth={true}
            inputProps={{ className: classes.textBox, max: 9999, maxLength: 4 }}
            onInput={(object) => {
              if (object.target.value.length > object.target.maxLength) {
                object.target.value = object.target.value.slice(
                  0,
                  object.target.maxLength
                );
              }
            }}
            className="palletActionFieled"
            margin="dense"
            id="GeneratePallet"
            label="Pallets Number"
            type="number"
            variant="outlined"
            value={generatePallet}
            onChange={(e) => filterGenerateNumber(e)}
          />
        </Grid>
        <Grid item xs={12} sm={4} className="mt-8">
          <button
            className={generateAction}
            disabled={!selectedWarehouse || !generatePallet || clickedAlready}
            onClick={() => generatePallets()}
          >
            Generate Pallets
          </button>
        </Grid>
      </Grid>

      <Grid item container className={filterActions}>
        <Grid item xs={12} sm={7} lg={7} md={7} className="orderFilter">
          <SearchBar
            setSearchValue={(e) => {
              setPage(1);
              setSearchValue(e);
            }}
            filter={filter}
            setFilterView={() => openFilterView()}
            className="searchWithFilter"
          />
          <div className="position-relative">{filterModal}</div>
        </Grid>
      </Grid>
      <TableContainer className={classes.container}>
        <Grid className="palletScroll-table">
          <Table aria-label="sticky table">
            <TableBody>
              <div style={{ height: 1100, width: '100%' }}>
                <DataGrid
                  rows={pallets}
                  hiddenColumnModel={['id']}
                  columns={palletColumns}
                  pageSize={20}
                  getRowId={(row) => row.palletIdStart + row.palletIdEnd}
                  rowsPerPageOptions={[6]}
                  className="parentDataTable"
                  loading={muiLoader}
                />
              </div>
            </TableBody>
          </Table>
        </Grid>
      </TableContainer>
      <Grid container justify="space-between">
        <Grid item></Grid>
        <Grid item>
          <Pagination
            component="div"
            shape="rounded"
            count={pageCount}
            color="primary"
            page={page}
            className={classes.pagination}
            onChange={(e, newPage) => setPage(newPage)}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { SharedContext } from '../../utils/common';
import { Heading, Paragraph, Title } from '../../core-components/atoms/Text';
import Button from '../../core-components/atoms/Button';
import API from '../../libs/axios';
import clsx from 'clsx';
import { onError } from '../../libs/errorLib';

function Plans() {
  const { setCurrentPageTitle, subdomain } = useContext(SharedContext);
  const [planType, setPlanType] = useState(1);

  const createCheckoutSession = async (
    subdomain,
    subscriptionPlanKey,
    duration
  ) => {
    try {
      const response = await API.post(
        `organizations/${subdomain}/stripe/checkout-session`,
        {
          subscriptionPlanKey,
          duration,
          trial: false
        }
      );

      return response.checkoutUrl;
    } catch (e) {
      onError(e);
    }
  };

  const openCheckoutPage = async (plan) => {
    const checkoutUrl = await createCheckoutSession(
      subdomain,
      plan,
      planType === 1 ? 'Monthly' : 'Yearly'
    );
    window.location.href = checkoutUrl;
  };

  const monthlyPlan = [
    {
      name: 'Seed',
      price: '99',
      yearlyPrice: '951',
      currency: 'USD',
      currencySign: '$',
      details: [
        '1200 Monthly Orders',
        'Up to 5 Users Accounts',
        'Standard Reporting',
        '4 Support Hours',
        'Control Tower, Order Management, Inventory Management'
      ],
      onPress: () => {
        openCheckoutPage('SEED');
      }
    },
    {
      name: 'Growth',
      price: '149',
      yearlyPrice: '1430',
      currency: 'USD',
      currencySign: '$',
      details: [
        '5000 Monthly Orders',
        'Up to 10 Users Accounts',
        'Standard Reporting + 3 Custom Reporting',
        '10 Support Hours',
        'Stock 360'
      ],
      onPress: () => {
        openCheckoutPage('GROWTH');
      }
    },
    {
      name: 'Harvest',
      price: '499',
      yearlyPrice: '4790',
      currency: 'USD',
      currencySign: '$',
      details: [
        'Unlimited Monthly Orders',
        'Up to 50 Users Accounts',
        'Standard Reporting + 5 Custom Reporting',
        '40 Support Hours',
        'Transit 360'
      ],
      onPress: () => {
        openCheckoutPage('HARVEST');
      }
    }
  ];

  useEffect(() => {
    setCurrentPageTitle(`Plans`);
  });

  const Plan = ({
    name,
    price,
    yearlyPrice,
    currencySign,
    // currency,
    details,
    onPress
  }) => {
    return (
      <div
        className={'flex-col border w-full ml-4 justify-center p-3 rounded-lg'}
      >
        <Title variant={'lg'} className={'text-center'}>
          {name}
        </Title>
        <Heading variant={'page'} className={'text-center'}>
          {`${currencySign}${
            planType === 1 ? price + '/mon' : yearlyPrice + '/year'
          }`}
        </Heading>
        {planType === 1 && (
          <Title variant={'md'} className={'text-center text-primary mt-2'}>
            {`${currencySign}${yearlyPrice}/year`}
          </Title>
        )}
        <div className={'mt-8'}>
          {details.map((val, index) => (
            <Paragraph variant={'sm'} key={index} className={'mt-1'}>
              ✔ {val}
            </Paragraph>
          ))}
        </div>
        <Button
          label="Purchase Plan"
          className={clsx('w-100 py-2 mt-8 px-5 h-8 border-0 rounded')}
          variant={'primary'}
          overrideSize={true}
          labelClass="font-medium text-xs"
          onClick={onPress}
        />
      </div>
    );
  };

  return (
    <div className={'flex flex-col mt-32'}>
      <div>
        <Heading variant={'main'} className={'text-center'}>
          Pricing that grows with you.
        </Heading>
        <Heading variant={'main'} className={'text-center'}>
          Simple. Transparent
        </Heading>
        <Paragraph variant={'md'} className={'text-center mt-4'}>
          Simple, transparent pricing that grow with you. Try any plan for 30
          days.
        </Paragraph>
      </div>
      <div id="btn-group" className={'justify-center flex mt-4'}>
        <Button
          label="Monthly"
          className={clsx(
            'py-2 px-5 ml-[7px] h-8 border-0 rounded',
            planType === 2 && 'border-2'
          )}
          variant={planType === 1 ? 'primary' : 'secondary'}
          overrideSize={true}
          labelClass="font-medium text-xs"
          onClick={() => {
            setPlanType(1);
          }}
        />
        <Button
          label="Yearly"
          className={clsx(
            'py-2 px-5 ml-[7px] h-8 border-0 rounded',
            planType === 1 && 'border-2'
          )}
          variant={planType === 2 ? 'primary' : 'secondary'}
          overrideSize={true}
          labelClass="font-medium text-xs"
          onClick={() => {
            setPlanType(2);
          }}
        />
      </div>
      <div className={'w-9/12 place-self-center mt-8'}>
        <div className={'flex justify-between'}>
          {monthlyPlan.map((value, index) => {
            return (
              <Plan
                key={index}
                name={value.name}
                price={value.price}
                yearlyPrice={value.yearlyPrice}
                currencySign={value.currencySign}
                currency={value.currency}
                details={value.details}
                onPress={value.onPress}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Plans;

import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Typography
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { toaster } from '../../utils/toaster';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { debounce } from 'lodash';

import {
  dateToPickerFormatYear,
  productLabelFormat,
  SharedContext
} from '../../utils/common';
import API from '../../libs/axios';
import { isRequired } from '../../utils/validators';
import { DEBOUNCE_CONST } from '../../Config';
import useStyles from './taskStyles';
import { ORGANIZATION_TYPES } from '../../constants';
import mixpanel from 'mixpanel-browser';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AddCycleCountView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setAPILoader, subdomain, organization } = useContext(SharedContext);
  const [warehouses, setWarehouses] = useState([]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [validation, setValidation] = useState({});
  const [shipmentDate, setShipmentDate] = useState('');
  const [isBlind, setisBlind] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [userId, setUserId] = useState('');
  const [warehouseId, setWarehouseId] = useState('');
  const [productIds, setProductIds] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [searchWarehouseValue, setSearchWarehouseValue] = useState('');
  const [searchUserValue, setSearchUserValue] = useState('');
  const [searchProductValue, setSearchProductValue] = useState('');
  const [singleCustomer, setSingleCustomer] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [clickedAlready, setClickedAlready] = useState(false);
  const IS_MANUFACTURER =
    organization?.type === ORGANIZATION_TYPES.MANUFACTURER;

  useEffect(() => {
    getWarehouseCallback({ searchWarehouseValue });
  }, [searchWarehouseValue]);

  const getWarehouseCallback = useCallback(
    debounce(({ searchWarehouseValue }) => {
      getWarehouses({ searchKeyword: searchWarehouseValue });
    }, DEBOUNCE_CONST),
    []
  );

  const getWarehouses = async ({ searchKeyword }) => {
    setAPILoader(true);
    const response = await API.get(`warehouses`, {
      params: { search: searchKeyword }
    });
    setWarehouses(response.data);
    setAPILoader(false);
  };

  useEffect(() => {
    if (IS_MANUFACTURER && isRequired(warehouseId)) {
      getCompaniesCallback({ warehouseId, searchCompanyValue });
    }
  }, [searchCompanyValue]);

  const getCompaniesCallback = useCallback(
    debounce(({ warehouseId, searchCompanyValue }) => {
      getCompanies({ warehouseId, searchCompanyValue });
    }, DEBOUNCE_CONST),
    []
  );

  const getCompanies = async (params) => {
    let { warehouseId: warehouseId, searchCompanyValue: searchKeyword } =
      params;
    let colVal = { isActive: 1 };
    setAPILoader(true);
    const response = await API.get(`warehouses/${warehouseId}/companies`, {
      params: { search: searchKeyword, filters: { colVal } }
    });
    setCustomers(response.data.rows);
    setSingleCustomer(null);
    setAPILoader(false);
  };

  useEffect(() => {
    if (customers?.length === 1 && !searchCompanyValue.length) {
      setSingleCustomer(customers?.[0]);
      setCustomerId(customers?.[0].id);
    }
  }, [customers]);

  useEffect(() => {
    if (
      isRequired(warehouseId) &&
      (IS_MANUFACTURER || isRequired(customerId))
    ) {
      getProductCallback({ warehouseId, customerId, searchProductValue });
    }
  }, [searchProductValue]);

  const getProductCallback = useCallback(
    debounce(({ warehouseId, customerId, searchProductValue }) => {
      getProducts({
        customerId,
        warehouseId,
        search: searchProductValue,
        columns: ['description']
      });
    }, DEBOUNCE_CONST),
    []
  );

  const getProducts = async (params) => {
    let { warehouseId, customerId } = params;
    setAPILoader(true);
    const response = await API.get(
      `dispatch-orders/warehouses/${warehouseId}/products`,
      { params: { ...params, companyId: customerId } }
    );
    setProducts(response.data);
    setAPILoader(false);
  };

  useEffect(() => {
    if (isRequired(warehouseId)) {
      getUserCallback({ warehouseId, searchUserValue });
    }
  }, [searchUserValue]);

  const getUserCallback = useCallback(
    debounce(({ warehouseId, searchUserValue }) => {
      getWarehouseUsers({
        warehouseId,
        search: searchUserValue,
        columns: ['firstName']
      });
    }, DEBOUNCE_CONST),
    []
  );

  const getWarehouseUsers = async (params) => {
    let { warehouseId } = params;
    setAPILoader(true);
    setUsers([]);
    const response = await API.get(
      `organizations/${subdomain}/warehouses/${warehouseId}/users`,
      { params }
    );
    setUsers(response.data);
    setAPILoader(false);
  };

  useEffect(() => {
    if (!warehouseId) return;
    if (!IS_MANUFACTURER) {
      getCompanies({ warehouseId });
    }
    getWarehouseUsers({ warehouseId });
  }, [warehouseId]);

  useEffect(() => {
    if (!warehouseId || (!IS_MANUFACTURER && !customerId)) return;
    getProducts({ warehouseId, customerId });
  }, [warehouseId, customerId]);

  const handleUserSearch = (userId) => {
    setUserId(userId);
  };

  const handleSelection = (productIds) => {
    let products = productIds;
    setProductIds(products);
  };

  const changeType = (event) => {
    const value = event.target.value === 'true';
    setisBlind(value);
  };

  const handleSubmit = async () => {
    const newCycleCount = {
      warehouseId,
      companyId: customerId,
      assignedTo: userId ? userId : null,
      taskStatus: userId ? 'ASSIGNED' : 'NOT_ASSIGNED',
      scheduledDate: new Date(shipmentDate),
      products: productIds,
      isBlind,
      taskType: 'CYCLE_COUNT'
    };
    try {
      setAPILoader(true);
      setClickedAlready(true);
      await API.post(`tasks`, {
        ...newCycleCount,
        hostUrl: window.location.href
      });
      toaster('success', 'New Cycle Count has been created successfully.');
      mixpanel.track('Cycle Count Created', {});
      setTimeout(() => {
        navigate('/routing/task-management');
      }, 2000);
    } catch (error) {
      let errors = error?.response?.data?.error?.errors;
      errors?.map((data) => {
        toaster('warning', data.message);
      });
    } finally {
      setAPILoader(false);
      setClickedAlready(false);
    }
  };

  return (
    <Grid container className={classes.parentContainer} spacing={3}>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={12} sm={11}>
          <Typography variant="h3" className={classes.heading}>
            Cycle Count Creation
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding12}>
        <FormControl fullWidth={true} variant="outlined">
          <Autocomplete
            id="warehouse"
            key={'warehouses'}
            options={warehouses}
            getOptionLabel={(warehouse) =>
              warehouse.businessWarehouseCode || warehouse.name
                ? `${warehouse.name} ${
                    warehouse?.businessWarehouseCode
                      ? `- ${warehouse.businessWarehouseCode}`
                      : ''
                  }`
                : ''
            }
            onChange={(event, newValue) => {
              handleSelection([]);
              setProducts([]);
              setSelectedProducts([]);
              setProductIds([]);
              if (newValue) {
                setWarehouseId(newValue.id);
              } else {
                setWarehouseId(null);
                setSelectedUser({});
                handleUserSearch('');
              }
            }}
            onInputChange={(event, newValue) => {
              setSearchWarehouseValue(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Warehouse" variant="outlined" />
            )}
            onBlur={() => setValidation({ ...validation, warehouseId: true })}
          />
          {validation.warehouseId && !isRequired(warehouseId) ? (
            <Typography color="error">Warehouse is required!</Typography>
          ) : (
            <Typography color="error" className={classes.hidden}>
              Dummy
            </Typography>
          )}
        </FormControl>
      </Grid>
      {IS_MANUFACTURER || (
        <Grid item xs={12} sm={6} className={classes.padding12}>
          <FormControl fullWidth={true} variant="outlined">
            <Autocomplete
              id="customer"
              options={customers}
              getOptionLabel={(customer) => customer.name || ''}
              value={singleCustomer}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCustomerId(newValue.id);
                  setSingleCustomer(newValue);
                } else {
                  setCustomerId(null);
                  setSingleCustomer(null);
                  handleSelection([]);
                  setSelectedProducts([]);
                }
              }}
              onKeyUp={(event) => {
                setSearchCompanyValue(event.target.value);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Company" variant="outlined" />
              )}
              onBlur={() =>
                setValidation({
                  ...validation,
                  ...(!IS_MANUFACTURER ? { customerId: true } : {})
                })
              }
            />
            {validation.customerId && !isRequired(customerId) ? (
              <Typography color="error">Company is required!</Typography>
            ) : (
              <Typography color="error" className={classes.hidden}>
                Dummy
              </Typography>
            )}
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={6} className={classes.paddingTop12}>
        <FormControl fullWidth={true} variant="outlined">
          <Autocomplete
            id="assignedTo"
            options={users}
            value={selectedUser}
            getOptionLabel={(user) => user.firstName || ''}
            onChange={(event, newValue) => {
              if (newValue) {
                handleUserSearch(newValue.id);
                setSelectedUser(newValue);
              } else {
                handleUserSearch('');
                setSelectedUser({});
              }
            }}
            onInputChange={(event, newValue) => {
              setSearchUserValue(newValue);
            }}
            onFocus={() => {
              setSearchUserValue('');
            }}
            renderInput={(params) => (
              <TextField {...params} label="Assigned To" variant="outlined" />
            )}
            onBlur={() => setValidation({ ...validation, userId: true })}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.paddingTop12}>
        <TextField
          fullWidth={true}
          id="scheduledDate"
          label="Scheduled Date"
          inputProps={{ min: new Date().toISOString().slice(0, 16) }}
          InputLabelProps={{
            shrink: true
          }}
          placeholder="Scheduled Date"
          type="datetime-local"
          variant="outlined"
          value={shipmentDate}
          onChange={(e) =>
            e.target.value != ''
              ? setShipmentDate(dateToPickerFormatYear(e.target.value))
              : setShipmentDate(new Date())
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.paddingTop12}>
        <FormControl margin="dense" fullWidth={true} variant="outlined">
          <Autocomplete
            multiple
            id="product"
            options={products}
            key={warehouseId}
            disableCloseOnSelect
            getOptionLabel={(product) => product.description || ''}
            onChange={(event, value) => {
              handleSelection(value.map((product) => product.id));
              setSelectedProducts(value);
            }}
            getOptionSelected={(option, value) => option.value === value.value}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  className={classes.marginRight8}
                  checked={productIds?.includes(option.id)}
                />
                {productLabelFormat(option)}
              </li>
            )}
            onInputChange={(event, newValue) => {
              setSearchProductValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Products"
                variant="outlined"
                placeholder="Products"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.paddingTop12}>
        <FormControl fullWidth={true} variant="outlined">
          <FormLabel id="demo-customized-radios">Count type</FormLabel>
          <RadioGroup
            defaultValue="false"
            name="isBlind"
            onChange={changeType}
            className={classes.flexRow}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Standard"
            />
            <FormControlLabel value="true" control={<Radio />} label="Blind" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container item xs={12} alignItems="center" spacing={1}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            className={classes.cancelButton}
            onClick={() => navigate('/routing/task-management')}
            // color="primary"
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            className={classes.createButton}
            onClick={() => {
              handleSubmit();
            }}
            disabled={clickedAlready}
            // color="primary"
            fullWidth
          >
            Create Cycle Count
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

/*eslint-disable*/
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Paragraph, Title } from '../../../core-components/atoms/Text';
import TrendingDownIcon from '../../../assets/icons/TrendingDownIcon.svg';
import TrendingDownReverseIcon from '../../../assets/icons/TrendingDownReverseIcon.svg';
import TrendingDown from '../../../assets/icons/trending_down.svg';
import TrendingDownReverse from '../../../assets/icons/trending_down_reverse.svg';
import TrendingUpIcon from '../../../assets/icons/TrendingUpIcon.svg';
import TrendingUpReverseIcon from '../../../assets/icons/TrendingUpReverseIcon.svg';
import TrendingUp from '../../../assets/icons/trending_up.svg';
import TrendingUpReverse from '../../../assets/icons/trending_up_reverse.svg';
import ShipmentIcon from '../../../assets/icons/ShipmentIcon.svg';
import InfoIcon from '@mui/icons-material/Info';

import clsx from 'clsx';
import { IconButton, Tooltip } from '@mui/material';
import { decimalDisplay } from '../../../utils/common';

const StatCard = ({
  title,
  value,
  trending,
  trend,
  trendText,
  loss,
  textColor,
  shipment
}) => {
  function isNegative(value) {
    return value < 0;
  }
  return (
    <Card
      className={clsx(
        'shadow-none rounded-lg border-dark300 border',
        trendText !== 'Current Month' && 'bg-dark100'
      )}
    >
      <CardContent className={'flex flex-col justify-between py-2 h-full'}>
        <div className={'flex justify-between items-center'}>
          <Paragraph variant="sm" className="text-dark800" gutterBottom>
            {title}
          </Paragraph>
          {(title === 'Potential Revenue' || title === 'Loss Sales') && (
            <Tooltip
              title={
                title === 'Potential Revenue'
                  ? 'The amount of revenue that can be generate by fulfilling pending or partially completed sales order.'
                  : 'The amount of sales lost due to cancellation of orders.'
              }
              placement="top"
              arrow
            >
              <IconButton>
                <InfoIcon color={'disabled'} fontSize={'small'} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="flex items-end justify-between mt-2">
          <div>
            <Title variant="md" className={textColor}>
              {value}
            </Title>
            {trending && (
              <div className="flex items-center mt-1">
                <div className={'text-jade500'}>
                  {title === 'Total Purchase Cost' ? (
                    !isNegative(trend) ? (
                      <img src={TrendingUpReverseIcon} />
                    ) : (
                      <img src={TrendingDownReverseIcon} />
                    )
                  ) : isNegative(trend) ? (
                    <img src={TrendingDownIcon} />
                  ) : (
                    <img src={TrendingUpIcon} />
                  )}
                </div>
                <Title
                  variant={'xs'}
                  className={
                    title === 'Total Purchase Cost'
                      ? !isNegative(trend)
                        ? 'text-crimson500 ml-1'
                        : 'text-jade500 ml-1'
                      : isNegative(trend)
                      ? 'text-crimson500 ml-1'
                      : 'text-jade500 ml-1'
                  }
                >
                  {decimalDisplay(trend)}%
                </Title>
                <Paragraph variant={'xs'} className={'text-dark500 ml-1'}>
                  {trendText}
                </Paragraph>
              </div>
            )}
          </div>
          <div className={`px-1 rounded-full`}>
            {trending &&
              (title === 'Total Purchase Cost' ? (
                isNegative(trend) ? (
                  <img src={TrendingDownReverse} />
                ) : (
                  <img src={TrendingUpReverse} />
                )
              ) : isNegative(trend) ? (
                <img src={TrendingDown} />
              ) : (
                <img src={TrendingUp} />
              ))}
            {loss && <img src={TrendingDown} />}
            {shipment && <img src={ShipmentIcon} />}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default StatCard;

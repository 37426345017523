import React, { useCallback, useContext, useEffect, useState } from 'react';
import CommentInput from './CommentInput';
import CommentList from './CommentList';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import DeleteConfirmation from './components/DeleteConfirmation';
import clsx from 'clsx';
import { upload } from '../../../utils/upload';
import { toaster } from '../../../utils/toaster';
import { getCurrencySymbol, SharedContext } from '../../../utils/common';
import Loader from './components/Loader';
import ExpenseAddListing from '../expense/ExpenseAddListing';

const Conversation = ({
  order,
  shipment,
  className,
  inputClassName,
  commentListClass,
  customClassText,
  customClassEmpty,
  selectedSearch,
  resetOrderFileListing,
  setResetExpense = () => {},
  openExpense,
  setOpenExpense,
  conversationUpdate = () => {},
  refetch = () => {}
}) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [chat, setChat] = useState([]);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);

  const [expenses, setExpenses] = useState([
    {
      name: '',
      cost: 0,
      shipmentId: null,
      fileIds: null
    }
  ]);
  const [postLoader, setPostLoader] = useState(false);
  const { organization } = useContext(SharedContext);

  useEffect(() => {
    if (chat.length <= 0 && order?.id) {
      setLoader(true);
      getDelayedUserComments(1, [], order, shipment, false);
    }

    if (chat.length > 0) {
      setLoader(false);
    }
  }, [chat, order, shipment]);

  const getDelayedUserComments = useCallback(
    debounce(
      async (page, oldData, orderDetail, shipmentDetail, refresh) => {
        getUserComments(page, oldData, orderDetail, shipmentDetail, refresh);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getUserComments = async (
    page,
    oldData = [],
    orderDetail,
    shipmentDetail,
    refresh
  ) => {
    try {
      const res = await API.get(`comments`, {
        params: {
          page: page,
          limit: 20,
          ...(orderDetail?.id ? { orderId: orderDetail?.id } : {})
        }
      });
      const newArr = res?.data;

      if (newArr && newArr.length > 0) {
        const newDataToAppend = newArr.filter((newItem) => {
          return !oldData.some((oldItem) => oldItem.id === newItem.id);
        });

        if (refresh) {
          setChat([...newDataToAppend, ...oldData]);
        } else {
          setChat([...oldData, ...newDataToAppend]);
        }
      }
      setPageCount(res?.pages);
    } catch (error) {
      onError(error);
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getDelayedUserComments(1, chat, order, shipment, true);
  //   }, 60000);
  //
  //   // Cleanup function to clear the interval when the component unmounts or dependencies change
  //   return () => clearInterval(intervalId);
  // }, [chat, order, shipment]);

  // handle file upload
  const handleFileUpload = async (fileArr) => {
    setPostLoader(true);
    try {
      let uploadedData = await upload(fileArr, 'orders');
      if (uploadedData) {
        const filteredFiles = fileArr.map((file, idIndex) => {
          file.id = uploadedData[idIndex];
          return file;
        });
        setSelectedFile(filteredFiles);
        setFiles((prevFiles) => [...prevFiles, ...uploadedData]);
      } else {
        toaster('error', 'File not uploaded');
      }
    } catch (err) {
      onError(err);
    } finally {
      setPostLoader(false);
    }
  };

  const postComment = async () => {
    setPostLoader(true);
    const newText = removeFileName(message);
    const modifiedMessage = removeBracketsFromMention(newText);
    const payload = {
      content: modifiedMessage.trim(),
      fileIds: files,
      // Expenses: expenses,
      userIds: selectedUsers,
      ...(order?.id ? { orderId: order?.id } : {})
    };
    try {
      if (selectedComment) {
        const response = await API.put(
          `comments/${selectedComment?.id}`,
          payload
        );
        let updatedChatMessages = chat.map((message) => {
          if (message.id === response?.data?.id) {
            return response?.data; // Replace with the new message
          } else {
            return message; // Keep the original message
          }
        });
        setChat(updatedChatMessages);
      } else {
        const response = await API.post(`comments`, payload);
        setChat([response?.data, ...chat]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (files?.length > 0) {
        resetOrderFileListing();
      }
      setMessage('');
      setSelectedUsers([]);
      setFiles([]);
      setSelectedComment(null);
      setPostLoader(false);
      conversationUpdate();
      await refetch();
    }
  };

  const removeBracketsFromMention = (message) => {
    let modifiedMessage = message;
    let atIndex = modifiedMessage.indexOf('@[');
    while (atIndex !== -1) {
      const closingBracketIndex = modifiedMessage.indexOf(']', atIndex);
      if (closingBracketIndex !== -1) {
        modifiedMessage =
          modifiedMessage.slice(0, atIndex) +
          '@' +
          modifiedMessage.slice(atIndex + 2, closingBracketIndex) +
          modifiedMessage.slice(closingBracketIndex + 1);
      }
      atIndex = modifiedMessage.indexOf('@[', closingBracketIndex);
    }
    return modifiedMessage;
  };

  function removeFileName(text) {
    // Regular expression to match quoted file names with any extension
    const fileNameRegex = /“([^”]+?)\.\w+”/g;

    // Replace file name with an empty string
    return text.replace(fileNameRegex, '');
  }

  const editComment = async (comment) => {
    setSelectedComment(comment);
    setFiles(comment?.Files);
    setMessage(comment?.content);
    setSelectedUsers(comment?.MentionedUsers?.map((user) => user.id));
  };

  const removeComment = async (comment) => {
    setSelectedComment(comment);
    setDeletePopupOpen(true);
  };

  const deleteComment = async () => {
    setDeletePopupOpen(false);
    setPostLoader(true);
    if (selectedComment) {
      try {
        await API.delete(`comments/${selectedComment?.id}`);
        let newChat = chat.filter((obj) => obj.id !== selectedComment?.id);
        setChat(newChat);
        setSelectedComment(null);
      } catch (error) {
        console.log(error);
      } finally {
        setPostLoader(false);
      }
    }
  };

  const postExpense = async () => {
    if (!organization?.defaultCurrency) return;
    setPostLoader(true);
    const message =
      'Expenses added for ' +
      expenses
        .map(
          (value) =>
            `${value.name} ${
              getCurrencySymbol(organization?.defaultCurrency) + value?.cost
            }`
        )
        .join(' & ');
    const modifiedMessage = removeBracketsFromMention(message);
    const modifiedExpense = expenses.map((item) => {
      const newItem = { ...item }; // Clone the original item
      newItem.orderId = order?.id;
      if (newItem.shipmentId && newItem.shipmentId.id !== null) {
        newItem.shipmentId = newItem.shipmentId.id; // Replace shipmentId object with id
      } else {
        delete newItem.shipmentId; // Remove shipmentId if id is null
      }
      return newItem;
    });
    const payload = {
      content: modifiedMessage,
      fileIds: files,
      Expenses: modifiedExpense,
      userIds: selectedUsers,
      ...(order?.id ? { orderId: order?.id } : {})
    };
    try {
      const response = await API.post(`comments`, payload);
      setChat([response?.data, ...chat]);
      toaster('success', `Expenses has been added successfully`);
    } catch (e) {
      console.log(e);
    } finally {
      setMessage('');
      setSelectedUsers([]);
      setFiles([]);
      setPostLoader(false);
      setOpenExpense(false);
      setExpenses([
        {
          name: '',
          cost: 0,
          shipmentId: null,
          fileIds: null
        }
      ]);
      const expensesWithFiles = modifiedExpense?.filter(
        (item) => item.fileIds && item.fileIds.length > 0
      );
      if (expensesWithFiles.length > 0) {
        resetOrderFileListing();
      }
      conversationUpdate();
      setResetExpense(true);
      refetch();
    }
  };

  return (
    <div className={clsx('flex flex-col h-screen', className)}>
      <Loader loader={postLoader} className={customClassEmpty} />
      <ExpenseAddListing
        expenses={expenses}
        setExpenses={setExpenses}
        open={openExpense}
        setOpen={setOpenExpense}
        order={order}
        shipment={shipment}
        postExpense={postExpense}
      />
      <DeleteConfirmation
        handleClose={() => setDeletePopupOpen(false)}
        open={deletePopupOpen}
        setOpen={setDeletePopupOpen}
        confirmDelete={deleteComment}
        message={`This will delete your comment permanently from this order. Are you sure you want to delete?`}
      />
      <CommentList
        order={order}
        shipment={shipment}
        loader={loader}
        page={page}
        setPage={setPage}
        getUserComments={getUserComments}
        pageCount={pageCount}
        chatList={chat}
        editComment={editComment}
        deleteComment={removeComment}
        className={commentListClass}
        customClassText={customClassText}
        customClassEmpty={customClassEmpty}
        selectedSearch={selectedSearch}
      />
      <div
        className={clsx('h-28 py-1 bg-white inset-x-4 mb-14', inputClassName)}
      >
        <CommentInput
          message={message}
          setMessage={setMessage}
          files={files}
          setFiles={setFiles}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          handleFileUpload={handleFileUpload}
          setSelectedUsers={setSelectedUsers}
          postComment={postComment}
          setOpenExpense={setOpenExpense}
          postLoader={postLoader}
        />
      </div>
    </div>
  );
};

export default Conversation;

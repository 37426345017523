/*eslint-disable*/
import Button from '../../../core-components/atoms/Button';
import BulkIcon from '../../../assets/icons/bulkUploadSolidIcon.svg';
import AddIcon from '../../../assets/icons/plusSymbolIcon.svg';
import SearchBar from '../../../atomicComponents/SearchBar';
import { makeStyles } from '@material-ui/core';
import Filters from './Filters';
import StatusIcon from '../../../assets/icons/list-status.svg';
import Batch from '../../../assets/icons/Batch.svg';
import QRCODE from '../../../assets/icons/qr_code_scanner.svg';
import Brand from '../../../assets/icons/branding_watermark.svg';
import Category from '../../../assets/icons/category.svg';
import Warehouse from '../../../assets/icons/warehouseFilter.svg';
import UoM from '../../../assets/icons/archiveNew.svg';
import React, { useEffect, useContext } from 'react';
import { SharedContext } from '../../../utils/common';
import clsx from 'clsx';
import { CircularProgress, List, ListItem } from '@mui/material';
import BulkFileAttacher from '../../productMaster/ListViews/components/BulkFileAttacher';
import BulkUploadTemplate from '../../../assets/files/InventoryBulkUploadTemplate.xlsx';
import O360BulkUploadTemplate from '../../../assets/files/O360InventoryBulkUpload.xlsx';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import PopupButton from '../../../core-components/molecules/PopupButton';
import { checkPermission } from '../../../utils/auth';
import EditableColumns from './EditableColumns';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const useStyles = makeStyles(() => ({
  searchBar: {
    maxWidth: '188px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));
const inventryStatus = {
  DAMAGED: 'Damaged',
  HOLD: 'Hold',
  RESERVED: 'Reserved',
  NEAR_EXPIRY: 'Near Expiry',
  AVAILABLE: 'Available',
  EXPIRED: 'Expired',
  IN_TRANSIT: 'In Transit'
};
const InventoryHead = ({
  searchKeyword,
  setSearchKeyword,
  addProductView,
  setAddProductView,
  filters,
  setFilter,
  setFilters,
  loading,
  bulkBtnOpen,
  setBulkBtnOpen,
  fileUpload,
  selectedFile,
  setSelectedFile,
  brands,
  categories,
  organizationNodes,
  UoMs,
  products,
  getOrgNodes,
  getBrands,
  getCategories,
  getProducts,
  getUoms,
  updatePreference,
  userPreferences
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [areAnyFilterActive, setAreAnyFilterActive] = React.useState(false);
  const { currentUser } = useContext(SharedContext);

  const batchExpiryFlag = isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING);
  const locationFlag = isFeatureEnabled(FLAGS.LOCATION_HIERARCHY);

  useEffect(() => {
    const res = Object.values(filters).some((filter) => filter.length > 0);
    setAreAnyFilterActive(res);
  }, [filters]);

  let filterOptions = [
    {
      label: 'Status',
      name: 'Status',
      options: Object.keys(inventryStatus)
        .filter((status) => {
          return (
            (!batchExpiryFlag
              ? status !== 'NEAR_EXPIRY' && status !== 'EXPIRED'
              : true) && (!locationFlag ? status !== 'RESERVED' : true)
          );
        })
        .map((key, id) => ({
          value: key,
          label: inventryStatus[key],
          id
        })),
      icon: StatusIcon
    },
    {
      options: products.map(({ id, name, description }) => ({
        value: id,
        label: name ? `${name} - ${description}` : description,
        id
      })),
      label: 'SKU Code / Name',
      name: 'SKU Code / Name',
      icon: QRCODE
    },
    {
      options: brands.map(({ id, name }) => ({
        value: id,
        label: name,
        id
      })),
      label: 'Brand(s)',
      name: 'Brands',
      icon: Brand
    },
    {
      options: categories.map(({ id, name }) => ({
        value: id,
        label: name,
        id
      })),
      label: 'Category(s)',
      name: 'Categories',
      icon: Category
    },
    {
      options: organizationNodes.map(({ id, code }) => ({
        value: id,
        label: code,
        id
      })),
      label: 'Node(s)',
      name: 'Nodes',
      icon: Warehouse
    },
    {
      options: UoMs.map(({ id, name }) => ({
        value: id,
        label: name,
        id
      })),
      label: 'UoM',
      name: 'UOM',
      icon: UoM
    },
    ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
      ? [
          {
            label: 'Batch(es)',
            name: 'Batches',
            icon: Batch
          }
        ]
      : [])
  ];
  const classes = useStyles();

  return (
    <div>
      <div className="w-full flex justify-between">
        <div className="flex items-center gap-2">
          <p className="text-lg text-primaryBlue font-semibold">
            All Inventory
          </p>
          {loading && <CircularProgress size={16} />}
        </div>
        <div className="flex items-center">
          <SearchBar
            className={classes.searchBar}
            iconClass="h-4 w-4"
            setSearchValue={setSearchKeyword}
            placeholder="Search Inventory"
            searchKeyword={searchKeyword}
          />
          <Filters
            options={filterOptions}
            filters={filters}
            setFilter={setFilter}
            height={400}
            getBrands={getBrands}
            getCategories={getCategories}
            getOrgNodes={getOrgNodes}
            getProducts={getProducts}
            getUoms={getUoms}
            isPrimary={true}
          />
          <PopupButton
            open={bulkBtnOpen}
            setOpen={setBulkBtnOpen}
            btn={
              <Button
                label="Bulk Upload"
                className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
                variant="transparent"
                overrideSize={true}
                icon={<img src={BulkIcon} className={`mr-2`} />}
                labelClass="font-medium text-xs "
                iconClass="h-[14px] w-[14px] mr-1"
                onClick={(e) => {
                  e.preventDefault();
                  setBulkBtnOpen(!bulkBtnOpen);
                }}
              />
            }
            content={
              <List className="py-4">
                <ListItem className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer">
                  <a
                    href={
                      !locationFlag && !batchExpiryFlag
                        ? O360BulkUploadTemplate
                        : BulkUploadTemplate
                    }
                    className="flex gap-2"
                  >
                    <img src={downloadFileIcon} className={`mr-[2px]`} />
                    <span>Download Template</span>
                  </a>
                </ListItem>
                <BulkFileAttacher
                  bulkUpload={(vals) => fileUpload(vals)}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                />
              </List>
            }
          />
          <EditableColumns
            editableColumns={
              userPreferences?.payload?.columns
                ? userPreferences?.payload?.columns
                : []
            }
            updatePreference={updatePreference}
          />
          {checkPermission(currentUser, 'OPS_PRODUCT_CREATE') && (
            <Button
              label="Add Product"
              className={'py-2 px-3 ml-[7px] h-8 border-0 rounded w-[115px]'}
              variant="primary"
              overrideSize={true}
              icon={<img src={AddIcon} className={`mr-2`} />}
              labelClass="font-medium text-xs"
              iconClass="h-[14px] w-[14px] mr-1 self-center mt-1"
              onClick={() => {
                setAddProductView(!addProductView);
              }}
            />
          )}
        </div>
      </div>
      {areAnyFilterActive && (
        <div className="w-full flex pt-[20px] text-dark500 text-sm font-medium">
          <div className="pt-[4px]">Applied Filters</div>
          <div className="ml-6 flex">
            {filters?.Brands.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    Brands: []
                  }))
                }
              >
                <span className="text-sm">Brands:</span>
                <span className="text-sm">{filters?.Brands[0]?.label}</span>
                {filters?.Brands?.length > 1 && (
                  <span className="text-sm ml-1">
                    +{filters?.Brands?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {filters?.Status.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    Status: []
                  }))
                }
              >
                <span className="text-sm">Status:</span>
                <span className="text-sm">{filters?.Status[0]?.label}</span>
                {filters?.Status?.length > 1 && (
                  <span className="text-sm ml-1">
                    +{filters?.Status?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {filters?.Batches?.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    Batches: []
                  }));
                }}
              >
                <span className="text-sm">Batches:</span>
                <span className="text-sm">
                  {filters?.Batches?.[0]?.[0]?.value}
                </span>
                {filters?.Batches?.[0].length > 1 && (
                  <span className="text-sm ml-1">
                    +
                    {filters?.Batches.reduce(
                      (acc, batch) => acc + batch.length,
                      0
                    ) - 1}{' '}
                    more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {filters?.['SKU Code / Name']?.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    'SKU Code / Name': []
                  }))
                }
              >
                <span className="text-sm">SKU:</span>
                <span className="text-sm">
                  {filters?.['SKU Code / Name']?.[0]?.label}
                </span>
                {filters?.['SKU Code / Name']?.length > 1 && (
                  <span className="text-sm ml-1">
                    +{filters?.['SKU Code / Name']?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {filters?.Nodes.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    Nodes: []
                  }))
                }
              >
                <span className="text-sm">Nodes:</span>
                <span className="text-sm">{filters?.Nodes[0]?.label}</span>
                {filters?.Nodes?.length > 1 && (
                  <span className="text-sm ml-1">
                    +{filters?.Nodes?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {filters?.Categories.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    Categories: []
                  }))
                }
              >
                <span className="text-sm">Categories:</span>
                <span className="text-sm">{filters?.Categories[0]?.label}</span>
                {filters?.Categories?.length > 1 && (
                  <span className="text-sm ml-1">
                    +{filters?.Categories?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {filters?.UOM?.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    UOM: []
                  }))
                }
              >
                <span className="text-sm">UoM:</span>
                <span className="text-sm">{filters?.UOM[0]?.label}</span>
                {filters?.UOM?.length > 1 && (
                  <span className="text-sm">
                    +{filters?.UOM?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {(filters?.Batches?.length > 0 ||
              filters?.Brands?.length > 0 ||
              filters?.Categories?.length > 0 ||
              filters?.Nodes?.length > 0 ||
              filters?.['SKU Code / Name']?.length > 0 ||
              filters?.UOM?.length > 0 ||
              filters?.Status?.length > 0) && (
              <div
                className="px-3 pt-[4px] cursor-pointer border-[#BDBDBD] border-solid border-0 border-l"
                onClick={(e) => {
                  e.preventDefault();
                  setFilters({
                    Status: [],
                    'SKU Code / Name': [],
                    Brands: [],
                    Categories: [],
                    Nodes: [],
                    UOM: [],
                    Batches: []
                  });
                }}
              >
                <span className="text-primary font-medium">Clear All</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryHead;

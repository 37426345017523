import React from 'react';
import { Grid, Typography } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router-dom';

import onexusStyles from '../onexusMakeStyles';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import sqFtIcon from '../../../assets/icons/sqFtIcon.svg';
import palletsIcon from '../../../assets/icons/palletsIcon.svg';
import locationIcon from '../../../assets/icons/locationIcon.svg';

const ListingItem = ({ warehouse }) => {
  const navigate = useNavigate();
  const classes = onexusStyles();
  return (
    <div className={classes.listingItemContainer}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <DetailTitle text={warehouse?.name} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.businessCodeText}>
            {warehouse?.businessWarehouseCode}
          </Typography>
        </Grid>
        <Grid item xs={10} md={9}>
          <Grid container>
            <Grid item xs={1}>
              <img src={locationIcon} alt="sqft-icon" />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.smallText}>
                {warehouse?.address}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <img src={sqFtIcon} alt="sqft-icon" />
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.smallText}
              >{`${warehouse?.capacity} sq. ft`}</Typography>
            </Grid>
            <Grid item xs={1}>
              <img src={palletsIcon} alt="sqft-icon" />
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.smallText}
              >{`${warehouse?.pallets} Pallets`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.arrowButton}>
          <button
            className={classes.viewButton}
            type="button"
            onClick={() => navigate(`/onexus/warehouse/${warehouse?.id}`)}
          >
            <ArrowForwardOutlinedIcon fontSize="small" />
          </button>
        </Grid>
      </Grid>
      <hr className="lineSpaceWarehouse" />
    </div>
  );
};

export default ListingItem;

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import { isRequired } from '../../../utils/validators';
import CustomTextField from '../../../core-components/atoms/TextField';
import { makeStyles } from '@material-ui/styles';

export default function AddModalView({
  open,
  handleClose,
  formErrors,
  postData,
  type
}) {
  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [isActive, setActive] = useState(true);

  const useStyles = makeStyles(() => ({
    btnGroup: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: 16
    },
    cancelBtn: {
      padding: '8px 12px',
      // gap: '6px',
      borderRadius: 4,
      cursor: 'pointer',
      color: '#0B1940'
    },

    submitBtn: {
      padding: '8px 12px',
      background: '#0C6BD7',
      borderRadius: 4,
      cursor: 'pointer',
      color: '#FFFFFF',
      '&:disabled': {
        background: 'rgba(12,107,215,0.5)'
      }
    }
  }));

  const resetStates = () => {
    setValidation({});
    setName('');
  };

  const handleSubmit = () => {
    const newObject = {
      name,
      isActive
    };

    setValidation({
      name: true,
      isActive: true
    });
    if (isRequired(name)) {
      postData(newObject);
      resetStates();
    }
  };

  const classes = useStyles();

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}
          onBackdropClick={() => {
            resetStates();
          }}
          aria-labelledby="form-dialog-title"
          className="w-[300px]"
        >
          <DialogTitle
            disableTypography
            style={{ fontWeight: 600 }}
          >{`Add ${type}`}</DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth={true}
                  margin="dense"
                  id="name"
                  label={type}
                  type="text"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {validation.name && !isRequired(name) ? (
                  <Typography color="error" style={{ fontSize: '14px' }}>
                    {type} is required!
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item sm={12} hidden>
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setActive(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Active
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <div className={classes.btnGroup}>
              <button
                className={classes.cancelBtn}
                type="button"
                onClick={() => {
                  resetStates();
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button className={classes.submitBtn} onClick={handleSubmit}>
                Add
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

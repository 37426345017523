import React from 'react';
import { Grid, Checkbox } from '@material-ui/core';

const ActiveCheckbox = ({ isActive, setActive, label = 'Active' }) => {
  return (
    <Grid item sm={12}>
      <Checkbox
        checked={isActive}
        onChange={(e) => setActive(e.target.checked)}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      {label}
    </Grid>
  );
};

export default ActiveCheckbox;

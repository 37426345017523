/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  Button as MUIButton,
  FormControl,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import * as XLSX from 'xlsx';
import UPLOAD_ICON from '../assets/icons/bulk-upload.svg';
import {
  isDate,
  regexSplit,
  regexSplitCarriageReturn,
  convertSlashFormatDate
} from '../utils/common';
import moment from 'moment';
import Button from '../core-components/atoms/Button';
import BulkUploadMenu from '../views/orders/grn/BulkUploadMenu';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '30px',
    cursor: 'pointer'
  },
  button: {
    marginRight: '5px',
    width: 'fit-content'
  }
}));

function ProductDetailsInwardCsvReaderNew({
  bulkUpload,
  selectedProductFile,
  setSelectedProductFile,
  customerId,
  warehouseId,
  orderType,
  template
}) {
  const [disableUploadButton, setDisableUploadButton] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setDisableUploadButton(true);
    if (customerId && warehouseId) {
      setDisableUploadButton(false);
    }
    orderType && setDisableUploadButton(false);
  }, [customerId, warehouseId, disableUploadButton, orderType]);

  useEffect(() => {
    if (selectedProductFile) handleProductFileUpload(selectedProductFile);
  }, [selectedProductFile]);

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = regexSplitCarriageReturn(dataString);
    const headersProduct = regexSplit(dataStringLines[0]);

    const list = [];
    for (let i = 1; i < dataStringLines.length - 1; i++) {
      const rowProduct = regexSplit(dataStringLines[i]);
      if (headersProduct && rowProduct.length == headersProduct.length) {
        const objProduct = {};
        for (let j = 0; j < headersProduct.length; j++) {
          let d = rowProduct[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headersProduct[j]) {
            objProduct[headersProduct[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(objProduct).filter((x) => x).length > 0) {
          objProduct['Manufacturing Date'] = objProduct['Manufacturing Date']
            ? isDate(
                moment(objProduct['Manufacturing Date']).format('DD-MM-yyyy')
              )
              ? moment(objProduct['Manufacturing Date']).format('DD-MM-yyyy')
              : convertSlashFormatDate(objProduct['Manufacturing Date'])
            : null;
          objProduct['Expiry Date'] = objProduct['Expiry Date']
            ? isDate(moment(objProduct['Expiry Date']).format('DD-MM-yyyy'))
              ? moment(objProduct['Expiry Date']).format('DD-MM-yyyy')
              : convertSlashFormatDate(objProduct['Expiry Date'])
            : null;
          list.push(objProduct);
        }
      }
    }

    // prepare columns list from headers

    const uploadedProducts = list;
    const modifiedProduct = [];

    if (orderType === 'gdn') {
      for (let product of uploadedProducts) {
        modifiedProduct.push({
          name: product['SKU Name/SKU Code'],
          batchNumber: product['Batch #'],
          manufacturingDate: product['Manufacturing Date'],
          expiryDate: product['Expiry Date'],
          dispatchedQuantity: product['Dispatched Qty'],
          promoName: product['Promo Code'],
          promoQuantity: product['Promo Qty']
        });
      }
    } else if (orderType === 'grn') {
      for (let product of uploadedProducts) {
        modifiedProduct.push({
          name: product['SKU Name/SKU Code'] ?? null,
          batchNumber: product['Batch #'] ?? null,
          manufacturingDate: product['Manufacturing Date'] ?? null,
          expiryDate: product['Expiry Date'] ?? null,
          receivedQuantity: product['Received Qty'] ?? 0,
          recoverableDamageQuantity:
            (product['Damaged Qty'] || product['Damaged']) ?? 0,
          // unsellableDamageQuantity: product['Damaged Qty Unsellable'] ?? 0,
          promoName: product['Promo Code'],
          promoQuantity: product['Promo Qty'],
          MRP: product['MRP (Per Pack) Rs'] ?? null,
          uom: product['UoM'] ?? null
        });
      }
    } else {
      for (let product of uploadedProducts) {
        modifiedProduct.push({
          name: product['SKU Name'],
          section: product['Section'],
          batchNumber: product['Batch Number'],
          manufacturingDate: product['Manufacturing Date'],
          expiryDate: product['Expiry Date'],
          customerCommittedQuantity: product['Planned Quantity']
            ? product['Planned Quantity']
            : 0,
          quantity: +product['Actual Quantity'],
          promoName: product['Promo Code'],
          promoQuantity: product['Promo Qty']
        });
      }
    }

    bulkUpload({ products: modifiedProduct });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // handle file upload
  const handleProductFileUpload = (e) => {
    const productFile = e.target.files[0];
    const productReader = new FileReader();
    productReader.onload = (evt) => {
      /* Parse data */
      const bstring = evt.target.result;
      const wb = XLSX.read(bstring, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    productReader.readAsBinaryString(productFile);
  };

  return (
    <FormControl fullWidth={orderType ? false : true} variant="outlined">
      <Tooltip title="Upload Bulk Product">
        {orderType ? (
          <Button
            component="label"
            icon={
              <img src={UPLOAD_ICON} alt="Bulk Upload Icon" className="mr-2" />
            }
            key={12}
            variant="contained"
            label="Bulk Upload"
            onClick={handleClick}
          />
        ) : (
          <MUIButton
            key={12}
            variant="contained"
            color="primary"
            component="label"
            style={{ marginRight: 5 }}
            disabled={disableUploadButton}
          >
            Upload
            <input
              type="file"
              hidden
              onClick={(e) => {
                setSelectedProductFile(null);
                e.target.value = null;
              }}
              onChange={(e) => {
                setSelectedProductFile(e);
              }}
              accept=".xls,.csv,.xlsx,"
            />
          </MUIButton>
        )}
      </Tooltip>
      <BulkUploadMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        setSelectedProductFile={setSelectedProductFile}
        template={template}
      />
    </FormControl>
  );
}

export default ProductDetailsInwardCsvReaderNew;

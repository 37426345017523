import { makeStyles } from '@material-ui/core';
import { BLACK, STORMGRAY } from '../../constants/colors';

const PrintStyles = makeStyles(() => ({
  root: {
    '@media print': {
      height: 'initial !important',
      overflow: 'initial !important',
      pageBreakAfter: 'always'
    }
  },
  pageContainer: {
    paddingLeft: '32px',
    paddingRight: '32px'
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center'
  },
  pageHeading: {
    fontWeight: 600,
    fontSize: '16px'
  },
  copyTypeButton: {
    backgroundColor: BLACK,
    color: 'white',
    paddingBottom: '2px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    width: '105px',
    height: '28px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    justifyContent: 'center',
    textTransform: 'capitalize',
    lineHeight: '16.41px'
  },
  pageSubHeading: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    height: '20px'
  },
  orderIdText: {
    fontWeight: 400,
    fontSize: '15px',
    color: '#4B89C8',
    fontFamily: 'Roboto'
  },
  shipmentIdText: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
    height: '16px'
  },
  shipmentIdDate: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
    height: '16px'
  },
  referenceIdText: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#202021',
    lineHeight: '16.41px',
    textTransform: 'capitalize',
    font: 'Roboto'
  },
  additionalInstructionsContainer: {
    margin: '10px 0px'
  },
  additionalInstructionsHeading: {
    fontWeight: 400,
    fontSize: '8px',
    color: BLACK,
    width: '56px',
    lineHeight: '18px'
  },
  additionalInstructionsBody: {
    fontWeight: 400,
    fontSize: '10px',
    color: STORMGRAY,
    marginLeft: '10px'
  },
  topTextContainer: {
    height: '24px'
  },

  bottomTextContainer: {
    position: 'fixed',
    bottom: 10,
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
    // '&::after': {
    //   content: '"Page "counter(page)"/"counter(pages)',
    //   display: 'block',
    //   fontSize: 10,
    //   fontWeight: 'bolder',
    //   color: 'black',
    //   width: 'fit-content',
    //   counterIncrement: 'page',
    //   pageBreakAfter: 'always'
    // }
  },
  footerSpace: {
    height: '70px'
  },
  bottomText: {
    fontSize: 10,
    fontWeight: 'bolder',
    color: 'black',
    width: 'fit-content'
  },
  bottomTextNormal: {
    fontSize: 10,
    fontWeight: 'normal',
    color: 'black',
    width: 'fit-content'
  },
  basicDetailsContainer: {
    fontSize: '16px',
    marginTop: '20px 0px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  label: {
    fontWeight: '400',
    minWidth: '160px',
    padding: '3px 8px',
    fontSize: '8px',
    lineHeight: '16px',
    font: 'Roboto',
    height: '16px',
    color: '#696F80'
  },
  basicFieldValue: {
    fontWeight: '500',
    minWidth: '160px',
    padding: '3px 8px',
    fontSize: '10px',
    lineHeight: '14px',
    font: 'Roboto',
    paddingRight: '32px'
  },

  productDetailSectionContainer: {
    marginTop: '15px'
  },
  productDetailSectionHeading: {
    fontWeight: '500',
    fontSize: '16px',
    marginBottom: '16px'
  },
  tableContainer: {
    border: '0.5px solid #CCCCCC',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  midTableRow: {
    border: '1px !important'
  },
  tableMidContainer: {
    width: '100%',
    backgroundColor: '#F6F8FB',
    overflow: 'hidden'
  },
  tableClass: {
    borderCollapse: 'collapse',
    overflow: 'hidden'
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  topColumnWrapper: {
    width: '100%',
    margin: '7px',
    fontWeight: 400,
    fontSize: '10px',
    paddingTop: '7px',
    paddingBottom: '7px'
  },
  topColumnWrapperBold: {
    fontWeight: 500,
    fontSize: '12px',
    marginLeft: '2px'
  },
  topColumnWrapperSimple: {
    fontWeight: 400,
    fontSize: '9px',
    marginLeft: '2px',
    marginTop: '1.5px'
  },
  dividerProperties: {
    border: '0.5px dashed #CCCCCC',
    marginTop: '16px'
  },
  table: {
    display: 'table-header-row',
    tableLayout: 'auto'
  },
  tableHeadDark: {
    display: 'table-row-group'
  },
  tableHead: {
    display: 'table-row-group'
  },
  tableRow: {
    breakInside: 'avoid'
  },
  headerTableRow: {
    borderBottom: '0.5px dashed #0B1940'
  },
  value: {
    fontSize: '10px',
    overflow: 'hidden',
    lineHeight: '16px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: 'none',
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC'
  },
  valueMid: {
    fontSize: '10px',
    color: '#0B1940',
    fontWeight: 700,
    overflow: 'hidden',
    paddingTop: '7px',
    paddingBottom: '7px',
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC'
  },
  topColumn: {
    fontWeight: 400,
    fontSize: '8px',
    position: 'relative',
    lineHeight: '0.5px',
    color: '#696F80',
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
    backgroundColor: 'white'
  },
  topMidColumn: {
    fontWeight: 700,
    fontSize: '9px'
  },
  centerText: {
    textAlign: 'center'
  },
  noPaddingColumn: {
    padding: '0',
    position: 'relative'
  },
  damagedQuantityContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0'
  },
  damagedQuantitySubHeadingContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: '5px',
    alignItems: 'flex-end',
    justifyContent: 'space-around'
  },
  columnSubHeadingText: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#979797'
  },
  recoverableCell: {
    width: '70px',
    backgroundColor: '#FFF3EB',
    color: '#DF8244',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '5px solid white'
  },
  unsellableCell: {
    width: '70px',
    backgroundColor: '#FFF1F1',
    color: '#FF3B3B !important',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  goodQuantityCellContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '5px solid white'
  },
  goodQuantityCell: {
    width: '70px',
    backgroundColor: '#E3FFE7',
    color: '#4EAD5B !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
}));

export default PrintStyles;

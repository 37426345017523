/*eslint-disable*/
import { useContext, useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
// import { useNavigate } from 'react-router';
import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import { useCallback } from 'react';
import clsx from 'clsx';

import { getCarriers } from '../../../apis/tms';
import { DEBOUNCE_CONST } from '../../../Config';
import { SharedContext } from '../../../utils/common';
import { TableBody, Tooltip } from '@mui/material';
import Header from '../components/Header';
import EditIcon from '../../../assets/icons/editIconDark.svg';
import AddcarrierPopup from './components/carrierPopup';
import API from '../../../libs/axios';

const CarrierView = () => {
  const { setCurrentPageTitle, organizationType, setAPILoader } =
    useContext(SharedContext);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [carriers, setcarriers] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [contentHover, setContentHover] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [addcarrierView, setAddcarrierView] = useState(false);
  const [selectedcarrier, setSelectedcarrier] = useState(null);
  const [columnNames, setColumnNames] = useState([
    'Name',
    'Type',
    'Contact Name',
    'Contact Email',
    'Tax Id'
  ]);

  const tableContainerRef = useRef(null);

  useEffect(() => {
    setCurrentPageTitle('Carriers');
    setColumnNames(['Name', 'Type', 'Contact Name', 'Contact Email', 'Tax Id']);
  }, [organizationType]);

  const { refetch, isFetching: carrierLoading } = useQuery(
    ['fetchcarriers', {}],
    () => {
      const payload = {
        page,
        searchKeyword
      };
      return getCarriers(payload, setAPILoader);
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data) {
          if (page === 1) {
            setcarriers(data.data.rows);
            setPageCount(data.pages);
          } else {
            setcarriers([...carriers, ...data.data.rows]);
            setPageCount(data.pages);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const _getcarriers = useCallback(
    debounce(() => {
      refetch();
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    _getcarriers();
  }, [page, searchKeyword]);

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !carrierLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
    }
  };

  const fetchcarrier = async (id) => {
    try {
      setAPILoader(true);
      const carrier = await API.get(`companies/${id}`, {
        params: { relationType: 'VENDOR' }
      });
      setSelectedcarrier(carrier.company);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  // const classes = useStyles();

  const tableCellClass = 'py-2 h-12 pl-0 pr-2';
  const cellValue = 'table-cell-trucate  text-normal';
  const cellSubValue = clsx(cellValue, 'text-[10px]');

  return (
    <div className="no-scrollbar overflow-hidden">
      <div className="w-full mt-10 pl-8 pr-6">
        <Header
          onClick={() => setAddcarrierView(true)}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          addBtnLabel={'Add Carriers'}
          headerText={'All Carriers'}
          searchPlaceHolder={'Search Carriers'}
        />
        <div className="mb-5">
          <Paper className="no-scrollbar rounded-t-lg shadow-none">
            <TableContainer
              onMouseEnter={() => {
                setContentHover(true);
              }}
              onMouseLeave={() => {
                setContentHover(false);
              }}
              style={{ overflow: 'auto' }}
              ref={tableContainerRef}
              onScroll={handleTableScroll}
              className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
            >
              <Table stickyHeader>
                <TableHead className="z-40 relative">
                  <TableRow>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className={`text-[10px] text-dark500 bg-white py-2 pr-2 font-normal h-[40px] ${
                          index == 0 ? 'pl-2' : 'pl-0'
                        }`}
                      >
                        {columnName}
                      </TableCell>
                    ))}
                    <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {carriers.map((row, index) => {
                    let type =
                      (row.type?.length > 0 && row.type.split(',')) || [];
                    type = {
                      Logistics: type.indexOf('Logistics') != -1,
                      Warehousing: type.indexOf('Warehousing') != -1,
                      Technology: type.indexOf('Technology') !== -1,
                      Land: type.indexOf('Land') !== -1,
                      Air: type.indexOf('Air') !== -1,
                      Sea: type.indexOf('Sea') !== -1
                    };

                    const typeValue =
                      (type.Logistics || type.Land || type.Air || type.Sea
                        ? 'Carrier'
                        : '') +
                      ((type.Logistics || type.Land || type.Air || type.Sea) &&
                      (type.Warehousing || type.Technology)
                        ? ' , '
                        : '') +
                      (type.Warehousing || type.Technology ? 'Vendor' : '');

                    return (
                      <TableRow
                        key={index}
                        onMouseEnter={() => {
                          setHoveredRow(index);
                        }}
                        onMouseLeave={() => {
                          setHoveredRow(-1);
                        }}
                        className={clsx(
                          'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                        )}
                        onClick={() => {}}
                      >
                        <TableCell
                          className={`${tableCellClass} pl-2 group-hover:text-primaryBlue`}
                        >
                          <Tooltip title={`${row?.name}`}>
                            <p className={clsx(cellSubValue)}>
                              <span>{row?.name}</span>
                            </p>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <Tooltip title={`${typeValue}`}>
                            <p className={clsx(cellSubValue)}>
                              {`${typeValue}`}
                            </p>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <p className={clsx(cellSubValue)}>
                            {`${row?.contactName || '-'}`}
                          </p>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <p className={clsx(cellSubValue)}>
                            {`${row?.contactEmail || '-'}`}
                          </p>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <p className={clsx(cellSubValue)}>
                            {row?.ntn || '-'}
                          </p>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          {hoveredRow == index && (
                            <div
                              className="flex gap-2"
                              onClick={async () => {
                                // setSelectedcarrier(row);
                                await fetchcarrier(row.id);
                                setAddcarrierView(true);
                              }}
                            >
                              <img src={EditIcon} />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      {addcarrierView && (
        <AddcarrierPopup
          open={addcarrierView}
          setOpen={setAddcarrierView}
          organizationType={organizationType}
          selectedcarrier={selectedcarrier}
          refetch={_getcarriers}
          setSelectedCarrier={setSelectedcarrier}
        />
      )}
    </div>
  );
};

export default CarrierView;

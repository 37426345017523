/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Checkbox, FormControl, Grid, Radio, RadioGroup } from '@mui/material';
import TextField from '../../core-components/atoms/TextField';
import SupportingDocumentUpload from '../../core-components/molecules/SupportingDocumentsUpload';
import FormikTextField from '../../core-components/molecules/FormikTextField';
import FormikAutocomplete from '../../core-components/molecules/FormikAutocomplete';
import clsx from 'clsx';
import Switch from '../../core-components/atoms/Switch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { SharedContext, getCurrencySymbol } from '../../utils/common';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { NumericFormat } from 'react-number-format';
import mixpanel from 'mixpanel-browser';

const CustomerForm = ({ formik, customerTypes, isSupplier, edit = true }) => {
  const { setAPILoader, organization } = useContext(SharedContext);
  const [categorySearchValue, setCategorySearchValue] = useState('');
  const [paymentTermSearchValue, setPaymentTermSearchValue] = useState('');
  const [categories, setCategories] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  2;
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  useEffect(() => {
    getCategories(categorySearchValue);
    mixpanel.track('Add/View Customer', {});
  }, [categorySearchValue]);

  useEffect(() => {
    getPaymentTerms(paymentTermSearchValue);
  }, [paymentTermSearchValue]);

  const getCategories = async (search) => {
    try {
      setAPILoader(true);
      const { data } = await API.get('companies/categories', {
        params: { search, columns: ['name'] }
      });

      setCategories(data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getPaymentTerms = async (search) => {
    try {
      setAPILoader(true);
      const { data } = await API.get('paymentTerms', {
        params: { search, columns: ['title'] }
      });

      setPaymentTerms(data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const borderClass = 'mt-4 number-input-container';

  return (
    <div>
      <p className="text-xl font-bold">Please fill the required fields</p>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Name & Type
            <span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <FormikTextField
              placeholder="Enter Name"
              name="name"
              formik={formik}
              disabled={!edit}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormikAutocomplete
            options={customerTypes}
            formik={formik}
            name="customerType"
            placeholder="Select Type"
            size={'small'}
            disabled={!edit}
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Code & Tax Identification Number (TIN)
          </p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              value={formik.values.code}
              onChange={(e) => formik.setFieldValue('code', e.target.value)}
              placeholder="Enter Code"
              disabled={!edit}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              value={formik.values.ntn}
              onChange={(e) => formik.setFieldValue('ntn', e.target.value)}
              placeholder="Enter Tax Identification Number (TIN)"
              disabled={!edit}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Billing Address
            <span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <FormikTextField
              name="billingAddress"
              formik={formik}
              value={formik.values.billingAddress}
              onChange={(e) => {
                formik.setFieldValue('billingAddress', e.target.value);
                if (formik.values.sameAsBilling) {
                  formik.setFieldValue('shippingAddress', e.target.value);
                }
              }}
              disabled={!edit}
              placeholder="Enter here"
            />
          </FormControl>
        </Grid>
      </Grid>
      {!isSupplier && (
        <Grid container className={borderClass} spacing={1}>
          <Grid item xs={4} className="flex items-center">
            <p className="font-semibold">
              Shipping Address
              <span className="text-error ml-2">*</span>
            </p>
          </Grid>
          <Grid item xs={8} className="flex flex-col gap-2 justify-center">
            <div className="flex gap-2 items-center">
              <Checkbox
                checked={formik.values.sameAsBilling}
                value={formik.values.sameAsBilling}
                onChange={(e, checked) => {
                  formik.setFieldValue('sameAsBilling', checked);
                  if (checked) {
                    formik.setFieldValue(
                      'shippingAddress',
                      formik.values.billingAddress
                    );
                  }
                }}
                disabled={!edit}
              />
              <span className="text-sm">Same as billing address</span>
            </div>
            {!formik.values.sameAsBilling && (
              <FormControl fullWidth>
                <FormikTextField
                  formik={formik}
                  name="shippingAddress"
                  value={formik.values.shippingAddress}
                  onChange={(e) =>
                    formik.setFieldValue('shippingAddress', e.target.value)
                  }
                  placeholder="Enter here"
                  disabled={!edit}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Email & Contact Number
            <span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <FormikTextField
              formik={formik}
              name="email"
              placeholder="Enter Email"
              type="email"
              disabled={!edit}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <PhoneInput
              country={'pk'}
              value={formik?.values?.phone || ''}
              onChange={(phone) => {
                formik.setFieldValue('phone', phone);
              }}
              inputStyle={{ width: '100%', height: '40px' }}
              disabled={!edit}
              variant="outlined"
            />
            {/* <FormikTextField
              type="text"
              placeholder="Enter Contact"
              name="phone"
              formik={formik}
              inputProps={{
                maxLength: 13,
                inputMode: 'numeric'
              }}
              disabled={!edit}
              onChange={(event) => {
                const newValue = event.target.value.replace(/[^0-9]/g, '');

                if (newValue.length <= 13) {
                  formik.setFieldValue('phone', newValue);
                }
              }}
            /> */}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Payment Terms & Credit Limit</p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            {
              <FormikAutocomplete
                options={paymentTerms}
                value={formik.values.paymentTerm}
                formik={formik}
                freeSolo
                name="paymentTerm"
                placeholder="Enter Payment Terms"
                size={'small'}
                getOptionLabel={(option) => option.title || ''}
                disabled={!edit}
                onChange={(_, newValue) => {
                  if (newValue)
                    formik.setFieldValue('paymentTermTitle', newValue.title);
                  formik.setFieldValue('paymentTerm', newValue);
                }}
                onInputChange={(_, newValue) => {
                  if (newValue) {
                    formik.setFieldValue('paymentTermTitle', newValue);
                    setPaymentTermSearchValue(newValue);
                  }
                }}
                onFocus={() => {
                  setPaymentTermSearchValue('');
                }}
              />
            }
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <NumericFormat
              customInput={TextField}
              value={formik.values.creditLimit}
              onValueChange={(e) =>
                formik.setFieldValue('creditLimit', parseFloat(e.value))
              }
              placeholder="Enter here"
              size="small"
              InputProps={{
                endAdornment: (
                  <span className="text-xs">
                    {getCurrencySymbol(organization?.defaultCurrency)}
                  </span>
                )
              }}
              thousandSeparator=","
              decimalSeparator="."
              disabled={!edit}
            />
          </FormControl>
        </Grid>
      </Grid>
      {showAdditionalFields && (
        <>
          <Grid container className={borderClass} spacing={1}>
            <Grid item xs={4} className="flex items-center">
              <p className="font-semibold">Category & Secondary Tax ID</p>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                {
                  <FormikAutocomplete
                    options={categories}
                    value={formik.values.category}
                    formik={formik}
                    freeSolo
                    name="category"
                    placeholder="Enter Category"
                    size={'small'}
                    getOptionLabel={(option) => option.name || ''}
                    disabled={!edit}
                    onChange={(event, newValue) => {
                      if (newValue)
                        formik.setFieldValue('categoryName', newValue.name);
                      formik.setFieldValue('category', newValue);
                    }}
                    onInputChange={(event, newValue) => {
                      if (newValue) {
                        formik.setFieldValue('categoryName', newValue);
                        setCategorySearchValue(newValue);
                      }
                    }}
                    onFocus={() => {
                      setCategorySearchValue('');
                    }}
                  />
                }
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  value={formik.values.strn}
                  onChange={(e) => formik.setFieldValue('strn', e.target.value)}
                  placeholder="Enter Secondary Tax ID"
                  disabled={!edit}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={borderClass} spacing={1}>
            <Grid item xs={4} className="flex items-center">
              <p className="font-semibold">Additional Info</p>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <FormikTextField
                  name="additionalInfo"
                  formik={formik}
                  value={formik.values.additionalInfo}
                  onChange={(e) => {
                    formik.setFieldValue('additionalInfo', e.target.value);
                  }}
                  placeholder="Enter Additional Info"
                  multiline
                  rows={2}
                  disabled={!edit}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      <div className={clsx(borderClass, !edit && 'pointer-events-none')}>
        <SupportingDocumentUpload
          formik={formik}
          propertyName="supportingDocuments"
          title="Contract/Supporting Documents"
        />
      </div>
      <Grid container className={`${borderClass}`} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Status</p>
        </Grid>
        <Grid item xs={8}>
          <Switch
            checked={formik.values.isActive}
            onChange={() =>
              formik.setFieldValue('isActive', !formik.values.isActive)
            }
            label="Active"
            disabled={!edit}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={`${borderClass} border-none mb-16`}
        spacing={1}
      >
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Show Additional Fields</p>
        </Grid>
        <Grid item xs={8}>
          <Switch
            checked={showAdditionalFields}
            onChange={() => setShowAdditionalFields(!showAdditionalFields)}
            label=""
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerForm;

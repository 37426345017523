/*eslint-disable */
import React, { useContext, useState } from 'react';
import moment from 'moment';
import { SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import Button from '../../../core-components/atoms/Button';
import ProductDetailsInwardCsvReaderNew from '../../../components/ProductDetailsInwardCsvReaderNew';
import CALENDAR_ICON from '../../../assets/icons/calendar.svg';
import REVERSE_ICON from '../../../assets/icons/reverse.svg';
import ReversalForm from './ReversalForm';
import Popup from '../../../core-components/atoms/Popup';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import template from '../../../assets/files/GRNBulkUploadTemplate.xlsx';
import O360Template from '../../../assets/files/GRNBulkUploadTemplate.O360 .xlsx';
import { toaster } from '../../../utils/toaster';
import PrintOrder from '../../../components/printGrnGdn/PrintOrder';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const GRNBulkUpload = ({
  setErrorDialog,
  setErrorData,
  formik,
  rows,
  setRows,
  getValidDate,
  errorData,
  GRNview,
  reversed,
  setData,
  vehicleType,
  totalOrderedQuantity,
  totalDispatchedQuantity,
  totalReceivedQuantity,
  setReinitialize
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { setAPILoader, organization } = useContext(SharedContext);
  const [productFile, setProductFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const bulkUpload = (data) => {
    if (!(Array.isArray(data.products) && data.products.length > 0)) {
      return;
    }

    let count = 2;
    let errorsArray = [];
    for (let prod of data.products) {
      if (!prod.name) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : SKU Name/SKU Code should not be null.`
        ];
      }
      if (prod.batchNumber && prod.batchNumber.length > 255) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : Batch Number should be less than 255 characters.`
        ];
      }
      if (prod.receivedQuantity < 0) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : Received Quantity should not be less than 0.`
        ];
      }
      if (prod.recoverableDamageQuantity < 0) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : Damaged Recoverable Quantity should not be less than 0.`
        ];
      }
      if (prod.unsellableDamageQuantity < 0) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : Damaged Unsellable Quantity should not be less than 0.`
        ];
      }
      if (prod.batchNumber && !prod.expiryDate) {
        toaster(
          'warning',
          `Row ${count} : Expiry Date is required for batch enable products.`
        );
      }
      if (prod.MRP < 0) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : MRP should not be less than 0.`
        ];
      }
      count++;
    }
    if (errorsArray.length > 0) {
      setErrorDialog(true);
      setErrorData(errorsArray);
    } else {
      fetchProducts(data.products);
      setData(data.products);
    }
  };

  const fetchProducts = async (fileProducts) => {
    const companyId = formik?.initialValues?.Order?.companyId;
    if (fileProducts?.length <= 0) return;
    let endpoint = 'products';
    await API.get(endpoint, {
      params: {
        limit: 100000,
        companyId: organization?.type == 'MANUFACTURER' ? null : companyId
      }
    })
      .then((res) => {
        if (res.data) {
          validateProductsData(res.data, fileProducts);
        }
      })
      .finally(() => setAPILoader(false));
  };

  const validateProductsData = (products, fileProducts) => {
    let foundProducts = [];
    const errorFound = [];

    fileProducts.map((fileProduct, index) => {
      let isProductVerified = true;
      const foundProduct = products.find(
        (product) =>
          product.name === fileProduct.name ||
          product.description === fileProduct.name
      );
      if (foundProduct) {
        isProductVerified = validateProduct(fileProduct, foundProduct, index);
        foundProducts.push({ fileProduct, foundProduct });
      } else {
        errorData.push({
          message: `Row ${index + 1} : Product ${
            fileProduct.name
          } not found in Company.`,
          row: index + 2
        });
        isProductVerified = false;
      }
      errorFound.push(isProductVerified);
    });

    foundProducts = foundProducts.filter((p, i) => errorFound[i]);
    foundProducts = foundProducts.filter((p) => {
      const found = rows.find((r) => r.Product.id == p.foundProduct.id);
      return !!found;
    });

    let updatedProducts = [...rows];
    for (let i = 0; i < Math.ceil(foundProducts.length / 10) + 1; i++) {
      updatedProducts = [
        ...addProductFromFile(
          foundProducts.slice(i * 10, i * 10 + 10),
          updatedProducts
        )
      ];
    }

    setRows([...updatedProducts]);
  };

  const validateProduct = (fileProduct, foundProduct, index) => {
    let isBatch = true;
    let isExpiry = true;

    if (foundProduct?.batchEnabled && !fileProduct.expiryDate?.length > 0) {
      errorData.push({
        message: `Row ${index + 1} : Product ${
          fileProduct?.name
        } must have expiry date`,
        row: index + 2
      });
      isExpiry = false;
    }
    if (
      foundProduct?.batchEnabled &&
      moment(fileProduct?.expiryDate) < moment(fileProduct?.manufacturingDate)
    ) {
      errorData.push({
        row: index + 2,
        message: `Row ${index + 1} : Product ${
          fileProduct?.name
        } expiry date cannot be before manufacturing date`
      });
      isExpiry = false;
    }
    if (
      foundProduct?.batchEnabled &&
      moment(fileProduct?.expiryDate) < moment(fileProduct?.manufacturingDate)
    ) {
      errorData.push(
        `Row ${index + 1} : Product ${
          fileProduct?.name
        } expiry date cannot be before manufacturing date`
      );
      isExpiry = false;
    }
    if (foundProduct?.batchEnabled && !fileProduct.batchNumber?.length > 0) {
      errorData.push({
        row: index + 2,
        message: `Row ${index + 1} : Product ${
          fileProduct?.name
        } must have a batch number`
      });
      isExpiry = false;
    }
    return isBatch && isExpiry;
  };

  const addProductFromFile = (newProducts, products) => {
    newProducts.map((newProduct) => {
      const { fileProduct, foundProduct } = newProduct;

      const uoms = [
        { ...foundProduct.UOM, conversionFactor: 1 },
        ...foundProduct.ProductUOMs?.map((u) => ({
          name: u?.UOM?.name,
          id: u.uomId,
          conversionFactor: u.conversionFactor
        }))
      ];

      const uom = uoms.find((u) => u.name == fileProduct.uom) || {
        ...foundProduct.UOM,
        conversionFactor: 1
      };

      if (!handleSameProduct(newProduct, products, uom)) {
        products = [
          ...products,
          {
            id: 0,
            ProductId: foundProduct?.id,
            orderedQuantity: 0,
            receivedQuantity:
              Number(fileProduct?.receivedQuantity) * uom?.conversionFactor,
            secondaryUomId: uom.id,
            Product: { ...foundProduct },
            GRNGroupBatches: [
              {
                id: 0,
                batchNumber: foundProduct.batchEnabled
                  ? fileProduct.batchNumber
                  : null,
                manufacturingDate: foundProduct.batchEnabled
                  ? getValidDate(fileProduct.manufacturingDate)
                  : null,
                expiryDate: foundProduct.batchEnabled
                  ? getValidDate(fileProduct.expiryDate)
                  : null,
                receivedQuantity:
                  Number(fileProduct?.receivedQuantity) * uom?.conversionFactor,
                recoverableDamageQuantity:
                  fileProduct?.recoverableDamageQuantity
                    ? Number(fileProduct?.recoverableDamageQuantity) *
                      uom?.conversionFactor
                    : 0,
                promoName: fileProduct?.promoName || '',
                promoQuantity: fileProduct?.promoQuantity || 0,
                MRP: foundProduct.MRPEnabled ? fileProduct?.MRP : null
              }
            ]
          }
        ];

        return products;
      }
    });

    // Initialize an empty object to store merged orders
    const mergedOrders = {};

    // Iterate over each order in the data array
    products.forEach((order) => {
      // Create a unique key based on orderId and ProductId
      const key = `${order.orderId}-${order.ProductId}`;

      // Check if the order already exists in mergedOrders
      if (!mergedOrders[key]) {
        // If not exists, initialize with the order and an empty GRNGroupBatches array
        mergedOrders[key] = { ...order, GRNGroupBatches: [] };
      }

      // Iterate over GRNGroupBatches in the current order
      order.GRNGroupBatches.forEach((batch) => {
        // Check if the batch already exists in mergedOrders[key].GRNGroupBatches
        const existingBatchIndex = mergedOrders[key].GRNGroupBatches.findIndex(
          (b) =>
            b.batchNumber === batch.batchNumber &&
            b.manufacturingDate === batch.manufacturingDate &&
            b.expiryDate === batch.expiryDate
        );

        if (existingBatchIndex === -1) {
          // If batch does not exist, push a copy of it to mergedOrders[key].GRNGroupBatches
          mergedOrders[key].GRNGroupBatches.push({ ...batch });
        } else {
          // If batch exists, merge quantities
          mergedOrders[key].GRNGroupBatches[
            existingBatchIndex
          ].receivedQuantity += batch.receivedQuantity;
          mergedOrders[key].GRNGroupBatches[
            existingBatchIndex
          ].recoverableDamageQuantity += batch.recoverableDamageQuantity;
          mergedOrders[key].GRNGroupBatches[
            existingBatchIndex
          ].unsellableDamageQuantity += batch.unsellableDamageQuantity;
          mergedOrders[key].GRNGroupBatches[existingBatchIndex].promoQuantity +=
            batch.promoQuantity;
        }
      });
    });

    // Convert mergedOrders object back to an array
    setReinitialize(true);
    return Object.values(mergedOrders);
  };

  const handleSameProduct = (foundProduct, products, uom) => {
    let foundProductIndex = null;
    let newBatch = true;

    for (let i = 0; i < products.length; i++) {
      if (products[i].Product.name === foundProduct.foundProduct.name) {
        const orderedQuantitySum = foundProduct.fileProduct.orderedQuantity
          ? Number(foundProduct.fileProduct.orderedQuantity) +
            Number(products[i]?.quantity)
          : Number(products[i].orderedQuantity);
        const receivedQuantitySum =
          Number(products[i]?.receivedQuantity) +
          Number(foundProduct.fileProduct?.receivedQuantity);
        products[i].orderedQuantity = orderedQuantitySum;
        products[i].receivedQuantity =
          receivedQuantitySum || products[i].receivedQuantity || 0;
        foundProductIndex = i;
      }

      if (foundProductIndex || foundProductIndex == 0) {
        i = products.length + 1;
      }
    }

    if (foundProductIndex || foundProductIndex == 0) {
      const product = products[foundProductIndex];

      if (product?.Product?.batchEnabled) {
        product.GRNGroupBatches?.map((batch, index) => {
          if (
            batch.batchNumber === foundProduct.fileProduct.batchNumber &&
            moment(batch.expiryDate).format('DD-MM-YYYY') ===
              moment(foundProduct.fileProduct.expiryDate).format('DD-MM-YYYY')
          ) {
            const MRP = foundProduct.fileProduct.MRP ? batch?.MRP : null;
            products[foundProductIndex].GRNGroupBatches[index] = {
              ...batch,
              receivedQuantity:
                Number(batch?.receivedQuantity) +
                Number(foundProduct.fileProduct.receivedQuantity) *
                  uom.conversionFactor -
                Number(foundProduct.fileProduct.promoQuantity) *
                  uom.conversionFactor,
              // eslint-disable-next-line no-unused-expressions
              recoverableDamageQuantity:
                Number(batch?.recoverableDamageQuantity) +
                  Number(foundProduct.fileProduct.recoverableDamageQuantity) *
                    uom.conversionFactor ??
                Number(foundProduct.fileProduct.recoverableDamageQuantity) *
                  uom.conversionFactor ??
                0,
              promoQuantity:
                Number(batch?.promoQuantity) +
                Number(foundProduct.fileProduct.promoQuantity) *
                  uom.conversionFactor,
              MRP: product?.Product?.MRPEnabled ? MRP : null
            };
            newBatch = false;
          }
        });

        if (newBatch) {
          products[foundProductIndex].GRNGroupBatches = [
            ...products[foundProductIndex].GRNGroupBatches,
            {
              id: 0,
              batchNumber: product?.Product?.batchEnabled
                ? foundProduct.fileProduct.batchNumber
                : null,
              manufacturingDate: getValidDate(
                foundProduct.fileProduct.manufacturingDate
              ),
              expiryDate: getValidDate(foundProduct.fileProduct.expiryDate),
              receivedQuantity:
                Number(foundProduct.fileProduct?.receivedQuantity) -
                Number(foundProduct.fileProduct.promoQuantity),
              recoverableDamageQuantity:
                Number(foundProduct.fileProduct?.recoverableDamageQuantity) ??
                0,
              promoName: foundProduct.fileProduct?.promoName,
              promoQuantity: Number(foundProduct.fileProduct.promoQuantity),
              MRP: product.Product.MRPEnabled
                ? foundProduct.fileProduct?.MRP
                  ? foundProduct.fileProduct.MRP
                  : null
                : null
            }
          ];
        }
      } else {
        const details = products[foundProductIndex].GRNGroupBatches[0];
        if (details) {
          products[foundProductIndex].receivedQuantity =
            Number(foundProduct.fileProduct?.receivedQuantity) *
              uom.conversionFactor +
              Number(details?.receivedQuantity) || 0;

          products[foundProductIndex].unsellableDamageQuantity =
            Number(foundProduct.fileProduct?.unsellableDamageQuantity) *
              uom.conversionFactor +
              Number(details?.unsellableDamageQuantity) || 0;

          products[foundProductIndex].secondaryUomQuantity = uom?.primary
            ? null
            : products[foundProductIndex].secondaryUomQuantity +
              Number(foundProduct.fileProduct?.receivedQuantity);
          products[foundProductIndex].GRNGroupBatches[0] = {
            id: 0,
            batchNumber: null,
            manufacturingDate: null,
            expiryDate: null,
            receivedQuantity:
              Number(foundProduct.fileProduct?.receivedQuantity) *
                uom.conversionFactor +
              details?.receivedQuantity
                ? Number(details?.receivedQuantity)
                : 0,
            recoverableDamageQuantity:
              Number(foundProduct.fileProduct?.recoverableDamageQuantity) *
                uom.conversionFactor +
                Number(details?.recoverableDamageQuantity) ?? 0,
            unsellableDamageQuantity:
              Number(foundProduct.fileProduct?.unsellableDamageQuantity) *
                uom.conversionFactor +
                details?.unsellableDamageQuantity ?? 0,
            MRP: null
          };
        } else {
          products[foundProductIndex].receivedQuantity =
            Number(foundProduct.fileProduct?.receivedQuantity) *
            uom.conversionFactor;
          products[foundProductIndex].unsellableDamageQuantity =
            Number(foundProduct.fileProduct?.recoverableDamageQuantity) *
            uom.conversionFactor;
          products[foundProductIndex].secondaryUomId = uom?.primary
            ? null
            : uom?.id;
          products[foundProductIndex].secondaryUomQuantity = uom?.primary
            ? null
            : Number(foundProduct.fileProduct?.receivedQuantity);
          products[foundProductIndex].GRNGroupBatches[0] = {
            id: 0,
            batchNumber: null,
            manufacturingDate: null,
            expiryDate: null,
            receivedQuantity:
              Number(foundProduct.fileProduct?.receivedQuantity) *
              uom.conversionFactor,
            recoverableDamageQuantity:
              Number(foundProduct.fileProduct?.recoverableDamageQuantity) *
              uom.conversionFactor,
            unsellableDamageQuantity:
              Number(foundProduct.fileProduct?.unsellableDamageQuantity) *
              uom.conversionFactor,
            MRP: null
          };
        }
      }
      return products;
    }

    return false;
  };

  return (
    <div className="flex">
      {reversed ||
        (GRNview && (
          <Button
            label="Reverse"
            icon={<img src={REVERSE_ICON} alt="Reverse" className="mr-2" />}
            className="ml-1"
            onClick={() => setOpen(true)}
          />
        )) || (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                open={datepickerOpen}
                onClose={() => setDatepickerOpen(false)}
                value={formik.values.Order.activityDate}
                onChange={(date) =>
                  formik.setFieldValue('Order.activityDate', date)
                }
                PopperProps={{
                  placement: 'bottom-end',
                  anchorEl: anchorEl
                }}
                renderInput={({
                  ref,
                  inputProps,
                  disabled,
                  onChange,
                  value,
                  ...other
                }) => (
                  <div ref={ref} {...other}>
                    <input
                      style={{ display: 'none' }}
                      value={value}
                      onChange={onChange}
                      disabled={disabled}
                      {...inputProps}
                    />
                    <Button
                      label="Date"
                      icon={
                        <img
                          src={CALENDAR_ICON}
                          alt="Calendar Icon"
                          className="mr-2"
                        />
                      }
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setDatepickerOpen((isOpen) => !isOpen);
                      }}
                      className="mr-1"
                      ref={ref}
                    />
                  </div>
                )}
              />
            </LocalizationProvider>
            <ProductDetailsInwardCsvReaderNew
              bulkUpload={bulkUpload}
              selectedProductFile={productFile}
              setSelectedProductFile={setProductFile}
              orderType="grn"
              template={
                !isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) &&
                !isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)
                  ? O360Template
                  : template
              }
            />
          </>
        )}
      {GRNview && (
        <PrintOrder
          formik={formik}
          orderType={'GRN'.toLowerCase()}
          vehicleType={vehicleType}
          tms={isFeatureEnabled(FLAGS.TMS)}
          totalOrderedQuantity={totalOrderedQuantity}
          totalDispatchedQuantity={totalDispatchedQuantity}
          totalReceivedQuantity={totalReceivedQuantity}
        />
      )}
      <Popup
        title="Why are you reversing this GRN?"
        open={open}
        setOpen={setOpen}
        content={
          <ReversalForm
            setOpen={setOpen}
            type="GRN"
            id={formik?.values?.Order?.GRNId}
          />
        }
      />
    </div>
  );
};

export default GRNBulkUpload;

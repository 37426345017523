/*eslint-disable*/
import React from 'react';
import { Table, TableBody, TableContainer, Grid } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import classNames from 'classnames/bind';

import useStyles from './makeStyles';
import ordersVerificationColumns from './ordersVerificationColumns';
import aisleVerificationColumns from '../administration/warehouse/aisleVerificationColumns';
import bayVerificationColumns from '../administration/warehouse/bayVerificationColumns';

const BulkValidationScreen = ({
  orders,
  aisles,
  bays,
  status,
  tableHeight = '730px'
}) => {
  const classes = useStyles();

  const dataTableClass = classNames({
    parentDataTable: true,
    customDataGrid: true,
    aisleDataTable: status === 'aisles' ? true : false,
    bayDataTable: status === 'bays' ? true : false,
    orderDataTable: status !== 'aisles' ? true : false
  });

  return (
    <TableContainer className={classes.container}>
      <Grid className="baysScroll-table">
        <Table aria-label="sticky table">
          <TableBody>
            <DataGrid
              getRowId={(row) =>
                ['aisles', 'bays'].includes(status)
                  ? row.name
                  : row.referenceID +
                    row.product +
                    row.pickUpCode +
                    row.dropOffCode +
                    row.pickUpLocation +
                    row.dropOffLocation +
                    row.quantity
              }
              rows={
                status === 'aisles' ? aisles : status === 'bays' ? bays : orders
              }
              columns={
                status === 'aisles'
                  ? aisleVerificationColumns
                  : status === 'bays'
                  ? bayVerificationColumns
                  : ordersVerificationColumns
              }
              className={dataTableClass}
              pageSize={20}
              pagination
              style={{ height: tableHeight }}
            />
          </TableBody>
        </Table>
      </Grid>
    </TableContainer>
  );
};

export default BulkValidationScreen;

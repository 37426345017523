import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  profilePicture: {
    height: '100px',
    width: '100px',
    borderRadius: '50px',
    margin: '20px'
  },
  userImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '48px'
  }
}));

const ProfilePicture = ({ image }) => {
  const classes = useStyles();

  return (
    <div className={classes.profilePicture}>
      <img alt="profile" className={classes.userImage} src={image} />
    </div>
  );
};

export default ProfilePicture;

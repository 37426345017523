import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router';

import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import useStyles from './palletizeStyles';
import {
  dividerDateFormatForFilter,
  SharedContext
} from '../../../utils/common';
import DetailText from '../../../atomicComponents/DetailText';
import DetailTitle from '../../../atomicComponents/DetailTitle';
import SectionHeading from '../../../atomicComponents/SectionHeading';

const DetailPalletizeStock = () => {
  const classes = useStyles();
  const { uid } = useParams();

  const [palletStockDetail, setPalletStockDetail] = useState('');
  const { currentPageTitle, setCurrentPageTitle, setAPILoader } =
    useContext(SharedContext);

  useEffect(() => {
    getPalletStock();
  }, [uid]);

  useEffect(() => {
    let title = 'Palletize Stock';
    setCurrentPageTitle(title);
  }, [currentPageTitle, setCurrentPageTitle]);

  const getPalletStock = async () => {
    try {
      setAPILoader(true);
      const palletStockDetail = await API.get(`pallets/pallet-stock/${uid}`);
      setPalletStockDetail(palletStockDetail.data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <Grid container className={classes.detailContainer} spacing={3}>
      <Grid item xs={4} sm={12} lg={4}>
        <Grid item container>
          <DetailText text="Order ID:" />
          <DetailTitle text={palletStockDetail?.orderId} />
        </Grid>

        <Grid item container className="mt-10">
          <DetailText text="Company:" />
          <DetailTitle text={palletStockDetail?.customer?.name} />
        </Grid>

        <Grid item container className="mt-10">
          <DetailText text="Batch # / Exp. Date:" />
          <DetailTitle
            text={`${
              palletStockDetail?.InventoryDetail?.batchNumber
            } / ${dividerDateFormatForFilter(
              palletStockDetail?.InventoryDetail?.expiryDate
            )}`}
          />
        </Grid>

        <Grid item container className="mt-10">
          <DetailText text="Available Qty:" />
          <DetailTitle
            text={palletStockDetail?.InventoryDetail?.availableQuantity}
          />
        </Grid>

        <Grid item container className="mt-10">
          <DetailText text="Pallet Qty:" />
          <DetailTitle text={palletStockDetail?.quantity} />
        </Grid>
      </Grid>

      <Grid item xs={4} sm={12} lg={4}>
        <Grid item container>
          <DetailText text="Warehouse:" />
          <DetailTitle text={palletStockDetail?.Warehouse?.name} />
        </Grid>

        <Grid item container className="mt-10 truncate w-[180px]">
          <DetailText text="SKU Code & Name:" />
          <DetailTitle
            text={`${palletStockDetail?.InventoryDetail?.Inventory?.Product?.name} - ${palletStockDetail?.InventoryDetail?.Inventory?.Product?.description}`}
          />
        </Grid>

        <Grid item container className="mt-10">
          <DetailText text="MRP:" />
          <DetailTitle text={palletStockDetail?.InventoryDetail?.MRP} />
        </Grid>

        <Grid item container className="mt-10">
          <DetailText text="UPP:" />
          <DetailTitle
            text={
              palletStockDetail?.InventoryDetail?.Inventory?.Product?.perPallet
            }
          />
        </Grid>

        <Grid item container className="mt-10">
          <DetailText text="Pallet ID:" />
          <DetailTitle text={palletStockDetail?.Pallet?.palletId} />
        </Grid>
      </Grid>

      <Grid item xs={4} sm={12} lg={4}>
        <SectionHeading title="Serials" className="coming-soon-title" />

        <div className="comming-soon-box text-center">
          <h2>Coming Soon!</h2>
        </div>
      </Grid>
    </Grid>
  );
};
export default DetailPalletizeStock;

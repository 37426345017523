import { IconButton as MuiIconButton } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const IconButton = ({ children, onClick, type = 'button', disabled }) => {
  return (
    <MuiIconButton
      className={clsx('hover:bg-oasisPrimary200')}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </MuiIconButton>
  );
};

export default IconButton;

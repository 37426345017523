import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import TableSkelton from '../components/TableSkelton';
import EmptyChart from '../components/EmptyChart';
import {
  decimalDisplay,
  getCurrencySymbol,
  SharedContext
} from '../../../utils/common';

const LandedCost = ({ nodeIds, endDate, startDate }) => {
  const { organization } = useContext(SharedContext);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);

  useEffect(() => {
    setIsLoading(true);
    getDelayedTopBarStats(startDate, endDate, nodeIdsMemo);
  }, [startDate, endDate, nodeIdsMemo]);

  const getDelayedTopBarStats = useCallback(
    debounce(
      async (startDate, endDate, nodeIds) => {
        getTopBarStats(startDate, endDate, nodeIds);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getTopBarStats = async (startDate, endDate, nodeIds) => {
    try {
      const data = await API.get(`intelligence/landed-cost`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          nodeIds: nodeIds
        }
      });
      setProducts(data);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="p-4">
      <Title variant={'md'}>Landed Cost</Title>
      {isLoading ? (
        <TableSkelton />
      ) : (
        <TableContainer
          component={Paper}
          className="border border-dark300 rounded overflow-hidden mt-4"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="border-b border-dark300 m-0">
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    Product Code & Name
                  </Label>
                </TableCell>
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                  align="left"
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    Product Cost
                  </Label>
                </TableCell>
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                  align="left"
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    Additional cost
                  </Label>
                </TableCell>
                <TableCell
                  className={'border-r border-dark300 py-1 bg-dark200'}
                  align="left"
                >
                  <Label className={'text-dark500'} variant={'xs'}>
                    Landed Cost / unit
                  </Label>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length <= 0 ? (
                <TableRow>
                  <TableCell colSpan={4} className="text-center p-0">
                    <div className="flex justify-center items-center w-full h-full">
                      <EmptyChart />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                products.map((row) => (
                  <TableRow
                    key={row.id}
                    className="border-b border-dark300"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      scope="row"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {row?.['Product.name']} - {row?.['Product.description']}
                      </Paragraph>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {`${getCurrencySymbol(organization?.defaultCurrency)} 
                          ${decimalDisplay(row?.cost)}`}
                      </Paragraph>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {`${getCurrencySymbol(organization?.defaultCurrency)} 
                          ${decimalDisplay(row?.additionalCost)}`}
                      </Paragraph>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={'border-r border-dark300 py-2'}
                    >
                      <Paragraph variant={'sm'}>
                        {`${getCurrencySymbol(organization?.defaultCurrency)} 
                          ${decimalDisplay(row?.landedCost)}`}
                      </Paragraph>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default LandedCost;

import clsx from 'clsx';
import { getNodeIcon } from './utils';

const NodesCluster = ({ activeShipments, clusterType }) => {
  const outerClasses = clsx(
    'flex w-fit justify-between py-1 px-2 rounded-full shadow-[0_4px_4px_0px_rgba(0,0,0,0.16)] bg-white items-center transition-all cursor-pointer mb-[-10px]'
  );
  return (
    <div className={outerClasses} data-is-cluster={true}>
      <div className="flex">
        <div className="flex w-8 h-[27px] box-border bg-richBlack rounded-full border border-solid border-white overflow-hidden justify-center items-center relative left-0 z-10">
          <img src={getNodeIcon(clusterType)} alt="icon" />
        </div>
        <div className="flex w-8 h-[27px] box-border bg-richBlack rounded-full mr-2 border border-solid border-white overflow-hidden justify-center items-center opacity-20 relative -ml-5"></div>
      </div>
      <div>
        <p className="text-primaryBlue text-xs h-fit self-center font-medium transition-all">
          {activeShipments}
        </p>
      </div>
    </div>
  );
};

export default NodesCluster;

import React from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

const Loader = ({ loader, className }) => {
  return (
    <>
      {loader ? (
        <div
          className={clsx(
            'loader-container2 flex items-center justify-center',
            className
          )}
        >
          <CircularProgress size={30} />
        </div>
      ) : null}
    </>
  );
};

export default Loader;

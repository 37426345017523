/*eslint-disable*/
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Map from '../revampedOrder/Map';
import { nodeTypes } from './constants';
import { numberToWords } from '../../utils/common';
import PopupButton from '../../core-components/molecules/PopupButton';
import Button from '../../core-components/atoms/Button';

const CustomerDetails = ({ entity }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <div className="flex flex-col gap-1">
          <span className="text-[#67718C] text-xs font-medium">
            {entity.label == 'Customer' || entity.entityType == 'CUSTOMER'
              ? 'CUSTOMER NAME'
              : 'SUPPLIER NAME'}
          </span>
          <span className="text-sm">{entity.name}</span>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className="flex flex-col gap-1">
          <span className="text-[#67718C] text-xs font-medium">
            {entity.label == 'Customer' || entity.entityType == 'CUSTOMER'
              ? 'CUSTOMER CODE'
              : 'SUPPLIER CODE'}
          </span>
          <span className="text-sm">{entity.code || '-'}</span>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className="flex flex-col gap-1">
          <span className="text-[#67718C] text-xs font-medium">
            {entity.label == 'Customer' || entity.entityType == 'CUSTOMER'
              ? 'CUSTOMER TYPE'
              : 'SUPPLIER TYPE'}
          </span>
          <span className="text-sm">{entity.type}</span>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="flex flex-col gap-1">
          <span className="text-[#67718C] text-xs font-medium">ADDRESS</span>
          <span className="text-sm">{entity.address}</span>
        </div>
      </Grid>
    </Grid>
  );
};

const OrganizationDetails = ({ entity }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <div className="flex flex-col gap-1">
          <span className="text-[#67718C] text-xs font-medium">
            ORGANIZATION NAME
          </span>
          <span className="text-sm">{entity.name}</span>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="flex flex-col  gap-1">
          <span className="text-[#67718C] text-xs font-medium">
            ORGANIZATION TYPE
          </span>
          <span className="text-sm">{entity.type}</span>
        </div>
      </Grid>
      <Grid item xs={5}>
        <div className="flex flex-col  gap-1">
          <span className="text-[#67718C] text-xs font-medium">ADDRESS</span>
          <span className="text-sm">{entity.address}</span>
        </div>
      </Grid>
    </Grid>
  );
};

const NodeSummaryView = ({
  nodes,
  onEdit,
  setDeletedNodes,
  deletedNodes,
  // submitHandler,
  // cancelHandler,
  entity
}) => {
  const displayNodes =
    deletedNodes?.length > 0
      ? [...nodes.filter((n, idx) => deletedNodes?.indexOf(idx) == -1)]
      : [...nodes];

  const DeleteButton = ({ onDelete }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    return (
      <PopupButton
        open={deleteOpen}
        setOpen={setDeleteOpen}
        btn={
          <DeleteOutlineIcon
            onClick={() => {
              setDeleteOpen(!deleteOpen);
            }}
          />
        }
        content={
          <div className="p-4">
            <p className="font-medium">Deleting Node</p>
            <p className="text-sm mt-2">
              Are you sure you want to delete this Node?
            </p>
            <div className="flex gap-2 justify-end mt-4">
              <Button label="Cancel" onClick={() => setDeleteOpen(false)} />
              <Button variant="primary" label="Delete" onClick={onDelete} />
            </div>
          </div>
        }
      />
    );
  };

  return (
    <div className="w-full">
      {(entity.label == 'Customer' ||
        entity.label == 'Supplier' ||
        entity.entityType == 'CUSTOMER' ||
        entity.entityType == 'SUPPLIER') && <CustomerDetails entity={entity} />}
      {(entity.label == 'Organization' ||
        entity.entityType == 'ORGANIZATION') && (
        <OrganizationDetails entity={entity} />
      )}
      {nodes?.length > 0 && (
        <div className="mt-10">
          <p className="font-medium text-sm">
            {displayNodes.length > 1 && numberToWords(displayNodes.length)}{' '}
            Nodes added for {entity?.name}
          </p>
          <div className="mt-10">
            {nodes?.length > 0 &&
              nodes.map((node, id) => {
                const showNode = deletedNodes.indexOf(id) == -1;
                return showNode ? (
                  <Grid
                    className="p-4 bg-[#F0F7FF] mb-3 min-h-[120px] w-full"
                    container
                    spacing={2}
                    key={id}
                  >
                    <Grid item xs={1} className="flex items-center">
                      <div
                        className={`h-3 w-3 rounded-full border border-solid border-[#9DA3B3] ${
                          node.isActive && 'border-[#1AAE2E] bg-[#1AAE2E]'
                        }`}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <div className="w-[100%] h-full relative">
                        <Map
                          singleLocationLatlng={node.latLng}
                          editable={false}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="flex flex-col gap-1 pt-2">
                        <span className="font-medium text-xs break-words">
                          {node.code ? `${node.code} - ` : ''} {node.name}
                        </span>
                        <span className="text-[10px]">
                          {nodeTypes.EXTERNAL[node.nodeType.value]}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      {node.nodeClass == 'INTERNAL' ? (
                        <div className="flex flex-col gap-1 pt-2">
                          <span className="text-xs">
                            {node.capacity} Sq.Ft.
                          </span>
                          <span className="text-[10px]">
                            <span className="font-medium">Pallets: </span>
                            <span>{node.pallets}</span>
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-col gap-1 pt-2 whitespace-normal">
                          <span className="font-medium text-xs break-words">
                            {node.pocName || '-'}
                          </span>
                          <span className="text-[10px] inline-block">
                            {node.pocContact || '-'}
                          </span>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <div>
                        <span className="text-xs">{node.address}</span>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className="flex gap-2 items-center h-full">
                        <div className="cursor-pointer">
                          <EditIcon onClick={() => onEdit(id)} />
                        </div>
                        <div className="cursor-pointer">
                          <DeleteButton
                            onDelete={() =>
                              setDeletedNodes([...deletedNodes, id])
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : null;
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default NodeSummaryView;

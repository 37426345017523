export function DEBOUNCE_CONST() {}

// Default to dev if not set
// eslint-disable-next-line no-undef
const config = process.env;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};

/*eslint-disable*/
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { makeStyles } from '@material-ui/core';
import { SharedContext } from '../../utils/common';
import Geocode from 'react-geocode';
import { isNumber } from 'lodash';
import TextField from '../../core-components/atoms/TextField';
import MarkerIcon from '../../assets/icons/mapMarker.svg';
import { FormControl, IconButton } from '@mui/material';
import Search from '../../assets/icons/magnify.svg';
import { GOOGLE_MAP_API_KEY } from '../../constants';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(GOOGLE_MAP_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage('en');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
// Geocode.setRegion('pk');
Geocode.enableDebug();

const useStyles = makeStyles((theme) => ({
  placeInputDiv: {
    width: '250%',
    position: 'absolute',
    zIndex: 100,
    top: '130%',
    left: '50%',
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    transform: 'translateX(-50%)',
    fontSize: 12,
    color: 'black'
  },
  placeDropoffInputDiv: {
    position: 'absolute',
    zIndex: 100,
    top: '80%',
    left: '80%',
    [theme.breakpoints.up('md')]: {
      width: '25%'
    },
    [theme.breakpoints.down('md')]: {
      width: '40%'
    },
    transform: 'translateX(-50%)',
    fontSize: 12,
    color: 'black'
  },
  singleInputDiv: {
    position: 'absolute',
    zIndex: 100,
    top: '145%',
    left: '50%',
    [theme.breakpoints.up('md')]: {
      width: '88% !important',
      left: '46%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    transform: 'translateX(-50%)',
    fontSize: 12,
    color: 'black',
    '@media only screen and (max-width: 1200px) and (min-width:993px)': {
      width: '80% !important'
    },
    '@media only screen and (max-width: 768px) and (min-width:575px)': {
      width: '70% !important',
      left: '48% !important'
    },
    '@media only screen and (max-width: 575px) and (min-width:360px)': {
      width: '69% !important',
      left: '40% !important'
    }
  },
  placeInput: {
    width: '100%',
    borderRadius: '10px',
    padding: '10px 5px',
    border: 0,
    transition: '.1s',
    outline: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    position: 'relative',
    bottom: '200px'
  }
}));

function GoogleMap(props) {
  const reverseGeocoding = async (latlng) => {
    if (isNumber(latlng.lat) && isNumber(latlng.lng)) {
      const response = await Geocode.fromLatLng(latlng.lat, latlng.lng);
      const address = response.results[0].formatted_address;
      return address;
    }
    return null;
  };
  const classes = useStyles();
  const input = useRef(Map);

  const calcZoomAndMapCenter = (pickup, dropoff) => {
    let zoom = null;
    if (
      Math.abs(pickup.lat - dropoff.lat) > 17 ||
      Math.abs(pickup.lng - dropoff.lng) > 17
    ) {
      zoom = 1.5;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 14 ||
      Math.abs(pickup.lng - dropoff.lng) > 14
    ) {
      zoom = 2.5;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 11.8 ||
      Math.abs(pickup.lng - dropoff.lng) > 11.8
    ) {
      zoom = 4.5;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 8 ||
      Math.abs(pickup.lng - dropoff.lng) > 8
    ) {
      zoom = 4.5;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 4 ||
      Math.abs(pickup.lng - dropoff.lng) > 4
    ) {
      zoom = 5.25;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 3 ||
      Math.abs(pickup.lng - dropoff.lng) > 3
    ) {
      zoom = 7;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 2 ||
      Math.abs(pickup.lng - dropoff.lng) > 2
    ) {
      zoom = 8;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 0.5 ||
      Math.abs(pickup.lng - dropoff.lng) > 0.5
    ) {
      zoom = 8;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 0.1 ||
      Math.abs(pickup.lng - dropoff.lng) > 0.1
    ) {
      zoom = 10;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 0.06 ||
      Math.abs(pickup.lng - dropoff.lng) > 0.06
    ) {
      zoom = 11;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 0.04 ||
      Math.abs(pickup.lng - dropoff.lng) > 0.04
    ) {
      zoom = 12;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 0.025 ||
      Math.abs(pickup.lng - dropoff.lng) > 0.025
    ) {
      zoom = 13;
    } else if (
      Math.abs(pickup.lat - dropoff.lat) > 0.01 ||
      Math.abs(pickup.lng - dropoff.lng) > 0.01
    ) {
      zoom = 14;
    }
    const midPointLat = (pickup.lat + dropoff.lat) / 2;
    const midPointLng = (pickup.lng + dropoff.lng) / 2;
    const mapCenter = { lat: midPointLat, lng: midPointLng };
    return { mapCenter, zoom };
  };
  const {
    setDropOff,
    setPickUp,
    pickupLocation,
    pickupAddress,
    dropoffLocation,
    setSingleLocationLatlng,
    singleLocationLatlng,
    setSingleLocationAddress,
    editable,
    icon,
    markerTitle,
    address
  } = props;

  const [pickupSearchBox, setpickupSearchBox] = useState('');
  const [dropoffSearchBox, setDropoffSearchBox] = useState('');
  const [singleLocationSearchBox, setSingleLocationSearchBox] = useState('');

  let zoom, mapCenter;

  useEffect(() => {
    if (
      pickupLocation &&
      pickupLocation.lat &&
      dropoffLocation &&
      dropoffLocation.lat
    ) {
      const calc = calcZoomAndMapCenter(pickupLocation, dropoffLocation);
      zoom = calc.zoom;
      mapCenter = calc.mapCenter;
      setState({ ...state, zoom, mapCenter });
    }
    if (!pickupSearchBox && !dropoffSearchBox && !singleLocationSearchBox) {
      if (pickupLocation && pickupLocation.lat) {
        Geocode.fromLatLng(pickupLocation.lat, pickupLocation.lng)
          .then((addresses) => addresses.results[0].formatted_address)
          .then((result) => {
            // setpickupSearchBox(result);
            setpickupSearchBox(pickupAddress);
          })
          .catch((err) => console.log(err));
      } else if (dropoffLocation && dropoffLocation.lat) {
        Geocode.fromLatLng(dropoffLocation.lat, dropoffLocation.lng)
          .then((addresses) => addresses.results[0].formatted_address)
          .then((result) => {
            setDropoffSearchBox(result);
          })
          .catch((err) => console.log(err));
      } else if (singleLocationLatlng?.lng && singleLocationLatlng?.lat) {
        setState({ ...state, zoom: 14, mapCenter: singleLocationLatlng });
        Geocode.fromLatLng(singleLocationLatlng.lat, singleLocationLatlng.lng)
          .then((addresses) => addresses.results[0].formatted_address)
          .then((result) => {
            setSingleLocationSearchBox(result);
            setSingleLocationAddress && setSingleLocationAddress(result);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [props]);

  const [state, setState] = useState({
    pickupMarker: {
      lat: pickupLocation ? pickupLocation.lat : null,
      lng: pickupLocation ? pickupLocation.lng : null
    },
    dropoffMarker: {
      lat: dropoffLocation ? dropoffLocation.lat : null,
      lng: dropoffLocation ? dropoffLocation.lng : null
    },
    singleLocationMarker: {
      lat: singleLocationLatlng ? singleLocationLatlng.lat : null,
      lng: singleLocationLatlng ? singleLocationLatlng.lng : null
    },
    mapCenter: mapCenter
      ? mapCenter
      : {
          lat: 30.2919928,
          lng: 64.8560693
        },
    zoom: zoom ? zoom : 5.5
  });

  const sharedContext = useContext(SharedContext);

  useEffect(() => {
    sharedContext.setSelectedMapLocation(state.pickupMarker);
  }, [state.pickupMarker]);

  useEffect(() => {
    if (address) {
      setSingleLocationAddress(address);
      setSingleLocationSearchBox(address);
    }
  }, [address]);

  const handleChangeSingleLocation = async (singleLocationSearchBox) => {
    setState({ ...state, singleLocationSearchBox });
    setSingleLocationSearchBox(singleLocationSearchBox);
    setSingleLocationLatlng(
      reverseGeocoding(singleLocationSearchBox)
        ? await reverseGeocoding(singleLocationSearchBox)
        : null
    );
  };

  const handleLatLngChange = (lngLat) => {
    if (lngLat.lat && lngLat.lng) {
      setState({ ...state, zoom: 14, mapCenter: singleLocationLatlng });
      Geocode.fromLatLng(singleLocationLatlng.lat, singleLocationLatlng.lng)
        .then((addresses) => addresses.results[0].formatted_address)
        .then((result) => {
          setSingleLocationSearchBox(result);
          setSingleLocationAddress && setSingleLocationAddress(result);
        })
        .catch((err) => {
          console.log('error: ', err);
        });
    }
  };

  const handleSingleLocationSelect = (singleLocationAddress) => {
    geocodeByAddress(singleLocationAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let zoom, mapCenter;
        if (state.singleLocationMarker.lat && state.singleLocationMarker.lng) {
          zoom = 14;
          mapCenter = state.singleLocationMarker;
        }

        setState({
          ...state,
          singleLocationMarker: {
            lat: latLng.lat,
            lng: latLng.lng
          },
          mapCenter: {
            lat: latLng.lat,
            lng: latLng.lng
          },
          zoom: zoom ? zoom : 14
        });
        setSingleLocationLatlng({
          lat: latLng.lat,
          lng: latLng.lng
        });
        setSingleLocationAddress(singleLocationAddress);
      })
      .catch((err) => console.log(err));
    if (setSingleLocationSearchBox)
      setSingleLocationSearchBox(singleLocationAddress);
  };

  const onSingleLocationMarkerDragEnd = async (coord) => {
    const { latLng } = coord;
    const updatedLat = latLng.lat();
    const updatedLng = latLng.lng();
    const addr = await reverseGeocoding({
      lat: latLng.lat(),
      lng: latLng.lng()
    });
    setSingleLocationSearchBox(addr);
    setSingleLocationAddress(addr);
    setSingleLocationLatlng({
      lat: updatedLat,
      lng: updatedLng
    });
    setState({
      ...state,
      singleLocationMarker: {
        lat: updatedLat,
        lng: updatedLng
      },
      mapCenter: {
        lat: updatedLat,
        lng: updatedLng
      },
      zoom: 14
    });
    return { lat: updatedLat, lng: updatedLng };
  };

  useEffect(() => {
    // if (state.pickupMarker.lat && state.pickupMarker.lng) {
    //   if (setPickUp) setPickUp(state.pickupMarker);
    // } else {
    //   setPickUp(null);
    // }
    if (setPickUp) setPickUp(state.pickupMarker);
    if (setDropOff) setDropOff(state.dropoffMarker);
  }, [state.pickupMarker, state.dropoffMarker]);

  useEffect(() => {
    //   // if (state.pickupMarker.lat && state.pickupMarker.lng) {
    //   //   // setPickUp(state.pickupMarker)
    //   // } else setPickUp(null);
    //   setPickUp(null);
    if (setPickUp) setPickUp(state.pickupMarker);
    if (setDropOff) setDropOff(state.dropoffMarker);
  }, [pickupSearchBox]);

  const searchOptions = {
    // componentRestrictions: { country: ['pk'] }
  };

  return (
    <Map
      google={props.google}
      initialCenter={{
        lat: state.pickupMarker.lat,
        lng: state.pickupMarker.lng
      }}
      center={{
        lat: state.mapCenter.lat,
        lng: state.mapCenter.lng
      }}
      zoom={state.zoom}
      style={{
        position: 'relative',
        height: props.height || '100%',
        width: props.width || '100%',
        border: '1px solid #0B194033'
      }}
      disableDefaultUI={true}
      ref={input}
    >
      {(state.singleLocationMarker.lat && state.singleLocationMarker.lng) ||
      (singleLocationLatlng?.lat && singleLocationLatlng?.lng) ? (
        <Marker
          position={{
            lat: state.singleLocationMarker.lat || singleLocationLatlng.lat,
            lng: state.singleLocationMarker.lng || singleLocationLatlng.lng
          }}
          icon={
            icon && {
              url: icon
            }
          }
          name={'Your Location'}
          title={markerTitle}
          draggable={editable}
          onDragend={(t, map, coord) => onSingleLocationMarkerDragEnd(coord)}
        />
      ) : (
        <div></div>
      )}

      {props.showSingleSearchField && props.editable ? (
        <div className="absolute top-2 left-2 z-50">
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={singleLocationSearchBox}
            onChange={handleChangeSingleLocation}
            onSelect={handleSingleLocationSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <div className="md:w-[400px] sm:w-full">
                  <FormControl fullWidth>
                    <TextField
                      {...getInputProps({
                        placeholder: 'Search for a place...',
                        className: 'bg-white'
                      })}
                      InputProps={{
                        startAdornment: (
                          <IconButton onClick={() => {}}>
                            <img src={Search} className="mr-2" />
                          </IconButton>
                        )
                      }}
                      defaultValue={props.address || ''}
                    />
                  </FormControl>
                </div>

                {suggestions?.length > 0 && (
                  <div className="z-50 w-[400px] h-[200px] overflow-auto map">
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? '#F1F8FF'
                          : '#ffffff'
                      };

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style
                          })}
                          key={suggestion.placeId}
                          className="px-3 py-4 hover:bg-[#F1F8FF] cursor-pointer flex items-center gap-2"
                        >
                          <img src={MarkerIcon} />
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      ) : (
        <div></div>
      )}

      {props.editable && (
        <div className="absolute bottom-0 w-[40%] right-0 bg-white shadow-[0px -2px 4px 0px #00000014] border border-solid border-[#0B194033]">
          <div className="flex gap-2 p-3">
            <input
              type="text"
              value={singleLocationLatlng?.lat}
              onChange={(e) => {
                setSingleLocationLatlng({
                  lng: singleLocationLatlng.lng,
                  lat: e.target.value
                });
                handleLatLngChange({
                  lng: singleLocationLatlng.lng,
                  lat: e.target.value
                });
              }}
              className="p-2 border border-solid border-[#0B194033] w-[45%]"
              placeholder="Latitude"
            />
            <input
              type="text"
              value={singleLocationLatlng?.lng}
              onChange={(e) => {
                setSingleLocationLatlng({
                  lat: singleLocationLatlng.lat,
                  lng: e.target.value
                });
                handleLatLngChange({
                  lat: singleLocationLatlng.lat,
                  lng: e.target.value
                });
              }}
              className="p-2 border border-solid border-[#0B194033] w-[45%]"
              placeholder="Longitude"
            />
          </div>
        </div>
      )}
    </Map>
  );
}

// export default GoogleMap
export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY
})(GoogleMap);

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Title } from '../../../core-components/atoms/Text';
import StatCard from '../components/StandardCard';
import StatCardSkeleton from '../components/SkeltonCard';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { onError } from '../../../libs/errorLib';
import API from '../../../libs/axios';
import { endOfMonth, startOfMonth } from 'date-fns';
import {
  decimalDisplay,
  getCurrencySymbol,
  getPreviousPeriod,
  SharedContext
} from '../../../utils/common';

const initialStartDate = startOfMonth(new Date());
const initialEndDate = endOfMonth(new Date());
const TopBarStats = ({
  startDate = initialStartDate,
  endDate = initialEndDate,
  nodeIds,
  range,
  intelligencePermissions,
  setTotalProfit
}) => {
  const { organization } = useContext(SharedContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const nodeIdsMemo = useMemo(() => nodeIds, [nodeIds]);

  const previousDates = getPreviousPeriod(startDate, endDate);

  useEffect(() => {
    setIsLoading(true);
    getDelayedTopBarStats(startDate, endDate, nodeIdsMemo);
  }, [startDate, endDate, nodeIdsMemo]);

  const getDelayedTopBarStats = useCallback(
    debounce(
      async (startDate, endDate, nodeIds) => {
        getTopBarStats(startDate, endDate, nodeIds);
      },
      [DEBOUNCE_CONST]
    ),
    [DEBOUNCE_CONST]
  );

  const getTopBarStats = async (startDate, endDate, nodeIds) => {
    try {
      const data = await API.get(`intelligence/top-bar`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          nodeIds: nodeIds,
          prevStartDate: previousDates?.previousStartDate,
          prevEndDate: previousDates?.previousEndDate
        }
      });
      setData(data);
      setTotalProfit(data?.netMargin);
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const isPotentialRevenueEnabled = intelligencePermissions.find(
    (value) => value?.key === 'POTENTIAL_REVENUE' && value?.enabled
  );

  const isInventoryTurnoverEnabled = intelligencePermissions.find(
    (value) => value?.key === 'INVENTORY_TURN_OVER' && value?.enabled
  );

  const numberOfColumns =
    isPotentialRevenueEnabled && isInventoryTurnoverEnabled ? 2 : 1;

  return (
    <div className={'gap-10'}>
      <Title variant={'md'}>{range}</Title>
      <div className={'grid'}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2">
          {isLoading ? (
            <>
              <StatCardSkeleton />
              <StatCardSkeleton />
              <StatCardSkeleton />
              <StatCardSkeleton />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <StatCardSkeleton trend={false} image={false} />
                <StatCardSkeleton trend={false} image={false} />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <StatCardSkeleton trend={false} image={false} />
                <StatCardSkeleton trend={false} image={false} />
              </div>
              <StatCardSkeleton trend={false} />
              <StatCardSkeleton trend={false} />
            </>
          ) : (
            <>
              {intelligencePermissions.find(
                (value) => value?.key === 'TOTAL_REVENUE' && value?.enabled
              ) && (
                <StatCard
                  title="Total Revenue"
                  value={`${getCurrencySymbol(
                    organization?.defaultCurrency
                  )} ${decimalDisplay(data?.totalRevenue)}`}
                  trend={`${data?.totalRevenuePercentage}`}
                  trendText={range}
                  iconBgColor="bg-green-200"
                  trending={true}
                  textColor={'text-dark900'}
                />
              )}
              {intelligencePermissions.find(
                (value) =>
                  value?.key === 'TOTAL_PURCHASE_COST' && value?.enabled
              ) && (
                <StatCard
                  title="Total Purchase Cost"
                  value={`${getCurrencySymbol(
                    organization?.defaultCurrency
                  )} ${decimalDisplay(data?.totalPurchaseCost)}`}
                  trend={`${data?.totalPurchaseCostPercentage}`}
                  trendText={range}
                  iconBgColor="bg-green-200"
                  trending={true}
                  textColor={'text-dark900'}
                />
              )}
              {intelligencePermissions.find(
                (value) =>
                  value?.key === 'TOTAL_SHIPMENT_COST' && value?.enabled
              ) && (
                <StatCard
                  title="Total Shipment Cost"
                  value={`${getCurrencySymbol(
                    organization?.defaultCurrency
                  )} ${decimalDisplay(data?.totalShipmentCost)}`}
                  trend={`${data?.totalShipmentCostPercentage}`}
                  trendText={range}
                  iconBgColor="bg-green-200"
                  trending={true}
                  textColor={'text-dark900'}
                />
              )}
              {intelligencePermissions.find(
                (value) => value?.key === 'NET_MARGIN' && value?.enabled
              ) && (
                <StatCard
                  title="Net Margin"
                  value={`${getCurrencySymbol(
                    organization?.defaultCurrency
                  )} ${decimalDisplay(data?.netMargin)}`}
                  trend={`${data?.netMarginPercentage}`}
                  trendText={range}
                  iconBgColor="bg-green-200"
                  trending={true}
                  textColor={'text-dark900'}
                />
              )}
              <div
                className={`grid grid-cols-1 md:grid-cols-${numberOfColumns} lg:grid-cols-${numberOfColumns} gap-4`}
              >
                {intelligencePermissions.find(
                  (value) =>
                    value?.key === 'POTENTIAL_REVENUE' && value?.enabled
                ) && (
                  <StatCard
                    title="Potential Revenue"
                    value={`${getCurrencySymbol(
                      organization?.defaultCurrency
                    )} ${decimalDisplay(data?.potentialRevenue)}`}
                    trending={false}
                    trendText={range}
                    textColor={'text-dark900'}
                  />
                )}
                {intelligencePermissions.find(
                  (value) =>
                    value?.key === 'INVENTORY_TURN_OVER' && value?.enabled
                ) && (
                  <StatCard
                    title="Inventory Turnover"
                    value={`${decimalDisplay(data?.inventoryTurnOver)}`}
                    trending={false}
                    trendText={range}
                    textColor={'text-dark900'}
                  />
                )}
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-${numberOfColumns} lg:grid-cols-${numberOfColumns} gap-4`}
              >
                {intelligencePermissions.find(
                  (value) => value?.key === 'ORDER_CYCLE_TIME' && value?.enabled
                ) && (
                  <StatCard
                    title="Order Cycle Time"
                    value={`${decimalDisplay(data?.orderCycleTime)} Days`}
                    trending={false}
                    trendText={range}
                    textColor={'text-dark900'}
                  />
                )}
                {intelligencePermissions.find(
                  (value) => value?.key === 'ON_TIME_IN_FULL' && value?.enabled
                ) && (
                  <StatCard
                    title="On Time In Full"
                    value={`${decimalDisplay(data?.onTimeInFull)}%`}
                    trending={false}
                    trendText={range}
                    textColor={'text-dark900'}
                  />
                )}
              </div>
              {intelligencePermissions.find(
                (value) => value?.key === 'DELAYED_SHIPMENTS' && value?.enabled
              ) && (
                <StatCard
                  title="Delayed Shipments"
                  value={`${data?.delayedShipments}`}
                  shipment={true}
                  trendText={range}
                  textColor={
                    data?.delayedShipments > 0
                      ? 'text-crimson500'
                      : 'text-dark900'
                  }
                />
              )}
              {intelligencePermissions.find(
                (value) => value?.key === 'LOSS_SALES' && value?.enabled
              ) && (
                <StatCard
                  title="Loss Sales"
                  value={`${getCurrencySymbol(
                    organization?.defaultCurrency
                  )} ${decimalDisplay(data?.lossSales)}`}
                  loss={true}
                  trendText={range}
                  textColor={
                    data?.lossSales > 0
                      ? 'text-crimson500 mb-0.5'
                      : 'text-dark900 mb-0.5'
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBarStats;

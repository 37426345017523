/*eslint-disable*/
import Button from '../../../../core-components/atoms/Button';
import ProductForm from './ProductForm';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { SharedContext } from '../../../../utils/common';
import { checkPermission } from '../../../../utils/auth';
import { Modal } from '@mui/material';
import FixedLayout from '../../../../core-components/molecules/FixedLayout';
import BackIcon from '../../../../assets/icons/chevronLeft.svg';
import { CloseOutlined } from '@material-ui/icons';
import mixpanel from 'mixpanel-browser';

const AddProductPopup = ({
  open,
  setOpen,
  product,
  setProduct = () => {},
  setCompany = () => {},
  getProducts = () => {},
  organizationType,
  companyName,
  company,
  setPage
}) => {
  const [customers, setCustomers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [productType] = useState([
    'Raw Material',
    'Work In Progress',
    'Semi Finished Goods',
    'Finished Goods'
  ]);
  const [stockHandlingType, setStockHandlingType] = useState('FIFO');
  const [stockHandlingMechanism] = useState(['FIFO', 'LIFO', 'FEFO']);
  const [customerSearchKeyword, setCustomerSearchKeyword] = useState('');
  const [categorySearchKeyword, setCategorySearchKeyword] = useState('');
  const [brandSearchKeyword, setBrandSearchKeyword] = useState('');
  const [productTypeSearchKeyword, setProductTypeSearchKeyword] = useState('');
  const [supplierSearchKeyword, setSupplierSearchKeyword] = useState('');
  const [primaryUomSearchKeyword, setPrimaryUomSearchKeyword] = useState('');
  const [secondaryUomSearchKeyword, setSecondaryUomSearchKeyword] =
    useState('');

  const { setAPILoader, currentUser } = useContext(SharedContext);

  const addSubmitHandler = async (values) => {
    if (!values.name) values.name = null;
    if (!values.barcode) values.barcode = null;
    if (!values.leadTime) values.leadTime = null;
    if (!values.dimensionsCBM) values.dimensionsCBM = 0;
    if (!values.length) values.length = null;
    if (!values.breadth) values.breadth = null;
    if (!values.height) values.height = null;
    if (!values.weight) values.weight = null;
    if (!values.leadTime) values.leadTime = null;
    if (!values.shelfLife) values.shelfLife = null;
    if (!values.productLife) values.productLife = null;
    if (!values.perPallet) values.perPallet = null;
    if (!values.brandId) values.brandId = null;
    if (!values.categoryId) values.categoryId = null;

    try {
      if (!product) {
        await API.post(`products`, values);
        handleClose();
        toaster('success', 'Product Created Successfully');
        getProducts();
        setPage(1);
        mixpanel.track('Product Created', {});
      } else {
        const { uoms, ...rest } = values;
        const newObject = { uoms, ...rest };
        await API.put(`products/${product.id}`, newObject);
        handleClose();
        toaster('success', 'Product Updated Successfully');
        getProducts();
        setPage(1);
        mixpanel.track('Product Updated', {
          productId: product.id
        });
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const handleClose = async () => {
    formik.resetForm();
    setCustomers([]);
    setCategories([]);
    setBrands([]);
    setStockHandlingType('FIFO');
    setOpen(false);
    setProduct(null);
    setCompany(null);
  };

  let valSchema;
  if (organizationType === 'MANUFACTURER') {
    valSchema = Yup.object().shape({
      uomId: Yup.string().required('UoM is required'),
      description: Yup.string().required('Name is Required')
    });
  } else {
    valSchema = Yup.object().shape({
      uomId: Yup.string().required('UoM is required'),
      description: Yup.string().required('Name is Required'),
      companyId: Yup.string().required('Entity is required')
    });
  }

  const initialValues = {
    description: product ? product?.description : '',
    name: product ? product?.name : null,
    barcode: product ? product?.barcode : null,
    hsCode: product ? product?.hsCode : null,
    productType: product ? product?.productType : null,
    supplierId: product ? product?.Supplier?.id : null,
    selectedSupplier: product ? product?.Supplier : null,
    shelfLife: product ? product?.shelfLife : null,
    productLife: product ? product?.productLife : null,
    height: product ? product?.height : null,
    length: product ? product?.length : null,
    breadth: product ? product?.breadth : null,
    weight: product ? product?.weight : null,
    dimensionsCBM: product ? product?.dimensionsCBM : null,
    perPallet: product ? product?.perPallet : null,
    selectedCustomer:
      !company && !product ? null : company ? company : product?.Company,
    companyId:
      !company && !product ? null : company ? company.id : product?.Company?.id,
    selectedUom: product ? product?.UOM : '',
    uomId: product ? product?.UOM?.id : '',
    selectedCategory: product ? product?.Category : null,
    categoryId: product ? product?.Category?.id : null,
    brandId: product ? product?.Brand?.id : null,
    selectedBrand: product ? product?.Brand : null,
    isActive: product ? product?.isActive : false,
    MRPEnabled: product ? product?.MRPEnabled : false,
    batchEnabled: product ? product?.batchEnabled : false,
    leadTime: product ? product?.leadTime : null,
    stockHandlingType: product ? product?.stockHandlingType : stockHandlingType,
    additionalInformation: product ? product?.additionalInformation : null,
    image: product ? product?.image : null,
    minOrderedQuantity: product ? product?.minOrderedQuantity : null,
    maxOrderedQuantity: product ? product?.maxOrderedQuantity : null,
    costPrice: product ? product?.costPrice : null,
    sellingPrice: product ? product?.sellingPrice : null,
    reOrderPoint: product ? product?.reOrderPoint : null,
    uoms: product ? product?.ProductUOMs : null
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: valSchema,
    onSubmit: addSubmitHandler
  });

  const getCustomers = async (value, params) => {
    try {
      const response = await API.get(`companies`, {
        params: {
          search: value,
          type: ['Customer'],
          ...params
        }
      });
      setCustomers(response.data.rows);
    } catch (err) {
      onError(err);
    }
  };

  const getCategories = async (searchKeyword, page) => {
    let url = `categories`;
    if (company?.id) {
      url = `categories?company_id=${company.id}`;
    }
    const categories = await API.get(url, {
      params: { offset: page, search: searchKeyword }
    });
    setCategories(categories?.data.filter((b) => b.isActive === true) || []);
  };

  const getBrands = async (searchKeyword, page) => {
    let url = `brands`;
    if (company?.id) {
      url = `brands?company_id=${company.id}`;
    }
    const brands = await API.get(url, {
      params: { offset: page, search: searchKeyword }
    });

    setBrands(brands?.data.filter((b) => b.isActive === true) || []);
  };

  const getSupplier = async (searchKeyword, page) => {
    const supplier = await API.get(`companies`, {
      params: {
        page,
        search: searchKeyword,
        type: 'SUPPLIER'
      }
    });
    setSupplier(supplier?.data?.rows?.filter((b) => b.isActive === true) || []);
  };

  useEffect(() => {
    if (open) {
      if (!company) {
        getCustomers(customerSearchKeyword);
      }
    }
  }, [open, customerSearchKeyword]);

  useEffect(() => {
    if (open) {
      getBrands(brandSearchKeyword);
    }
  }, [open, customerSearchKeyword, brandSearchKeyword]);

  useEffect(() => {
    if (open) {
      getCategories(categorySearchKeyword);
    }
  }, [open, customerSearchKeyword, categorySearchKeyword]);

  useEffect(() => {
    if (open) {
      getSupplier(supplierSearchKeyword);
    }
  }, [open, customerSearchKeyword, supplierSearchKeyword]);

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open={open}
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <FixedLayout
        showFooter={true}
        header={
          <>
            <div className="flex justify-between w-full items-center px-6">
              <div className="flex items-center">
                <img
                  src={BackIcon}
                  alt="back"
                  onClick={() => {
                    handleClose();
                  }}
                  className="mr-1"
                />
                <p className="font-semibold text-lg text-primaryBlue">
                  {product
                    ? companyName
                      ? `Edit Product for ${companyName}`
                      : 'Eidt Product'
                    : companyName
                    ? `Add Product for ${companyName}`
                    : 'Add Product'}
                </p>
              </div>
              <div>
                <CloseOutlined
                  onClick={() => {
                    handleClose();
                  }}
                  className="cursor-pointer text-[20px] text-dark400"
                />
              </div>
            </div>
          </>
        }
        content={
          <ProductForm
            open
            openPopup={open}
            selectedCompany={company}
            organizationType={organizationType}
            formik={formik}
            customers={customers}
            categories={categories}
            setBrands={setBrands}
            setCategories={setCategories}
            brands={brands}
            supplier={supplier}
            productType={productType}
            customerSearchKeyword={customerSearchKeyword}
            setCustomerSearchKeyword={setCustomerSearchKeyword}
            stockHandlingMechanism={stockHandlingMechanism}
            categorySearchKeyword={categorySearchKeyword}
            setCategorySearchKeyword={setCategorySearchKeyword}
            brandSearchKeyword={brandSearchKeyword}
            setBrandSearchKeyword={setBrandSearchKeyword}
            supplierSearchKeyword={supplierSearchKeyword}
            setSupplierSearchKeyword={setSupplierSearchKeyword}
            productTypeSearchKeyword={productTypeSearchKeyword}
            setProductTypeSearchKeyword={setProductTypeSearchKeyword}
            primaryUomSearchKeyword={primaryUomSearchKeyword}
            setPrimaryUomSearchKeyword={setPrimaryUomSearchKeyword}
            secondaryUomSearchKeyword={secondaryUomSearchKeyword}
            setSecondaryUomSearchKeyword={setSecondaryUomSearchKeyword}
            isEdit={product}
          />
        }
        footer={
          <div className="flex justify-end gap-2 w-full">
            <Button
              label="Cancel"
              className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
              variant="transparent"
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={handleClose}
            />
            {product
              ? checkPermission(currentUser, 'OPS_PRODUCT_UPDATE') && (
                  <Button
                    label="Edit Product"
                    className={
                      'py-2 px-3 ml-2 h-8 rounded border-solid border-oasisPrimary500'
                    }
                    variant="primary"
                    overrideSize={true}
                    labelClass="font-medium text-xs"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  />
                )
              : checkPermission(currentUser, 'OPS_PRODUCT_CREATE') && (
                  <Button
                    label="Add Product"
                    className={
                      'py-2 px-3 ml-2 h-8 rounded border-solid border-oasisPrimary500'
                    }
                    disabled={!(formik.isValid && formik.dirty)}
                    variant="primary"
                    overrideSize={true}
                    labelClass="font-medium text-xs"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  />
                )}
          </div>
        }
      />
    </Modal>
  );
};

export default AddProductPopup;

/*eslint-disable*/
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { isChar, isRequired } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  },
  labelPadding: {
    paddingTop: 5
  },
  selectBox: {
    height: 55
  }
}));

export default function AddRoleView({
  addRole,
  portals,
  open,
  handleClose,
  selectedUser,
  formErrors,
  clickedAlready,
  setClickedAlready
}) {
  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [portal, setPortal] = useState('');
  const [roleId, setRoleId] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (selectedUser) {
      setName(selectedUser.name || '');
      setType(selectedUser.type || '');
      setPortal(selectedUser.portal || '');
      setRoleId(selectedUser.roleId || '');
    } else {
      setName('');
      setType('');
      setRoleId('');
      setPortal('');
    }
  }, [selectedUser]);

  const changePortal = (portal) => {
    setPortal(portal);
  };

  const handleSubmit = () => {
    const newRole = {
      roleId,
      name,
      type,
      allowedApps: portal
    };

    if (isRequired(name) && isRequired(portal)) {
      newRole['type'] = `${name}_test_${portal}`;
      setClickedAlready(true);
      addRole(newRole);
    }
  };

  useEffect(() => {
    if (!open) {
      setName('');
      setType('');
      setRoleId('');
      setPortal('');
    }
  }, [open]);

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          onBackdropClick={() => {
            setValidation('');
          }}
        >
          <DialogTitle disableTypography className="title">
            {!selectedUser ? 'Add Role' : 'Edit Role'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid container spacing={2} justify="space-around">
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    inputProps={{ className: classes.textBox }}
                    className={classes.labelBox}
                    // autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onBlur={() => setValidation({ ...validation, name: true })}
                  />
                  {validation.name && !isRequired(name) ? (
                    <Typography color="error">Name is required!</Typography>
                  ) : (
                    ''
                  )}
                  {validation.name && !isChar(name) ? (
                    <Typography color="error">
                      Name is only characters!
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormControl margin="dense" fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="portal"
                    key={portals}
                    options={portals}
                    defaultValue={portal ? portal : ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Portal"
                        variant="outlined"
                      />
                    )}
                    getOptionLabel={(portal) => {
                      return portal.label
                        ? portal.label || ''
                        : portal
                        ? portal
                        : '';
                    }}
                    onBlur={() =>
                      setValidation({ ...validation, portal: true })
                    }
                    onChange={(event, newValue) => {
                      if (newValue) changePortal(newValue.id);
                    }}
                  />
                  {validation.portal && !isRequired(portal) ? (
                    <Typography color="error">
                      Please select a portal!
                    </Typography>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  // setExplicitReRender(!explicitReRender);
                  setValidation('');
                  handleClose();
                }}
                color="default"
                variant="contained"
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title={!selectedUser ? 'Add Role' : 'Update Role'}>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={clickedAlready}
              >
                {!selectedUser ? 'Add Role' : 'Update Role'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}

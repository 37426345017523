import moment from 'moment/moment';
import { convertToTitleCase } from '../AddOrder';
import { constants } from '../constants';
import React from 'react';
import { decimalDisplay } from '../../../utils/common';
import { Label, Paragraph } from '../../../core-components/atoms/Text';

const OrderDetailStats = ({ order, getTotalByKey }) => {
  return (
    <div className="flex gap-4  w-full">
      <div className="border border-[#E7E6E6] border-solid rounded py-3 px-4 gap-1">
        <Label variant={'xs'} className="text-[#6A7289]">
          REF NO.
        </Label>
        <Paragraph variant={'sm'}>{order?.referenceId || '-'}</Paragraph>
      </div>
      <div className="border border-[#E7E6E6] border-solid rounded py-3 px-5 gap-1">
        <Label variant={'xs'} className="text-[#6A7289]">
          CREATED BY
        </Label>
        <Paragraph variant={'sm'}>
          {order?.User?.firstName + ' ' + order?.User?.lastName}
        </Paragraph>
      </div>
      <div className="border border-[#E7E6E6] border-solid rounded py-3 px-5 gap-1">
        <Label variant={'xs'} className="text-[#6A7289]">
          CREATED ON
        </Label>
        <Paragraph variant={'sm'}>
          {' '}
          {moment(order?.createdAt).format('DD/MM/YYYY')}
        </Paragraph>
      </div>
      <div className="border border-[#E7E6E6] border-solid rounded py-3 px-5 gap-1">
        <Label variant={'xs'} className="text-[#6A7289]">
          ORDER TYPE
        </Label>
        <Paragraph variant={'sm'}>
          {order?.moveType &&
            convertToTitleCase(
              constants.ORDER_TYPES[order?.moveSubType || order?.moveType]
            )}
        </Paragraph>
      </div>
      <div className="border border-[#E7E6E6] border-solid rounded py-3 px-5 gap-1">
        <Label variant={'xs'} className="text-[#6A7289]">
          {order?.moveSubType === 'PURCHASE' ||
          order?.moveSubType === 'SALES_RETURN'
            ? 'SUPPLIER'
            : 'CUSTOMER'}
        </Label>
        <Paragraph variant={'sm'}>{order?.Company?.name}</Paragraph>
      </div>
      {['SALES', 'PURCHASE'].includes(order?.moveSubType) && (
        <div className="border border-[#E7E6E6] border-solid rounded py-3 px-5 gap-1">
          <Label variant={'xs'} className="text-[#6A7289]">
            PAYMENT TERMS
          </Label>
          <Paragraph variant={'sm'}>
            {order?.PaymentTerm ? order?.PaymentTerm?.title : '-'}
          </Paragraph>
        </div>
      )}
      {order.moveType !== 'LOGISTIC' && (
        <div className="border border-[#E7E6E6] border-solid rounded py-3 px-5 gap-1">
          <Label variant={'xs'} className="text-[#6A7289]">
            {order.moveType != 'OUTBOUND'
              ? 'TOTAL RECEIVED QUANTITY'
              : 'TOTAL DISPATCHED QUANTITY'}
          </Label>
          <Paragraph variant={'sm'}>
            {order.moveType != 'OUTBOUND'
              ? `${decimalDisplay(
                  getTotalByKey('received')
                )} / ${decimalDisplay(getTotalByKey('total'))}`
              : `${decimalDisplay(
                  getTotalByKey('dispatched')
                )} / ${decimalDisplay(getTotalByKey('total'))}`}
          </Paragraph>
        </div>
      )}
    </div>
  );
};

export default OrderDetailStats;
